import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
	Image,
	List,
    ListItem,
    Label
} from 'svift-ui';

const GoogleGsuiteVerificationCodeAnswer = () => {
    
 return(
    <List divided ordered size="large" style={{ textAlign: 'left' }}>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 1 settings google gsuite where to find verification" />:<br />
                <a
                    href="https://www.google.com/webmasters/verification/"
                    title="Google Webmaster Central"
                    target="_blank"
                >
                    <FormattedMessage id="support.faq answer 2 settings google gsuite where to find verification" />
                </a>
            </p>
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 3 settings google gsuite where to find verification" />
            </p>
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 4 settings google gsuite where to find verification" />
            </p>
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 5 settings google gsuite where to find verification" />
            </p>
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 6 settings google gsuite where to find verification" />
            </p>
            <Image src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/support/faq/google-gsuite-verification-code-screenshot.png" alt="Google G Suite verification code dummy screenshot" />
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 7 settings google gsuite where to find verification" />
            </p>
            <ol style={{ lineHeight: 2 }}>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>TXT</span>
                    </Label>
                    google-site-verification=YOUR-VERIFICATION-CODE
                </li>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>MX</span>
                    </Label>
                    ASPMX.L.GOOGLE.COM
                </li>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>MX</span>
                    </Label>
                    ALT1.ASPMX.L.GOOGLE.COM
                </li>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>MX</span>
                    </Label>
                    ALT2.ASPMX.L.GOOGLE.COM
                </li>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>MX</span>
                    </Label>
                    ALT3.ASPMX.L.GOOGLE.COM
                </li>
                <li>
                    <Label basic className="info" style={{ marginRight: 8 }}>
                        {/* <FormattedMessage id="general.type" />:  */}
                        <span style={{ fontWeight: 500 }}>MX</span>
                    </Label>
                    ALT4.ASPMX.L.GOOGLE.COM
                </li>
            </ol>
            <p>
                <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                    <FormattedMessage id="general.obs label" />
                </span>
                <FormattedMessage id="support.faq answer 7b settings google gsuite where to find verification" />
            </p>
        </ListItem>
        <ListItem>
            <p>
                <FormattedMessage id="support.faq answer 8 settings google gsuite where to find verification" />
            </p>
        </ListItem>
        <ListItem>
            <p>	
                <FormattedMessage id="support.faq answer 9 settings google gsuite where to find verification" />
            </p>
            <p>
                <FormattedMessage id="support.faq answer 10 settings google gsuite where to find verification" />:<br />
                <a
                    href="https://gsuite.google.dk/intl/da/products/admin/"
                    title="Google Administrator Console"
                    target="_blank"
                >
                    <FormattedMessage id="support.faq answer 11 settings google gsuite where to find verification" />
                </a>
            </p>
        </ListItem>
    </List>

);
    
}

export default GoogleGsuiteVerificationCodeAnswer;