exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports
exports.push([module.id, "@import url(https://storage.googleapis.com/svift-net-master-bucket/assets/icons/site-core-icons/style.css);", ""]);

// module
exports.push([module.id, ".loader__load-spinner--1Dt_w{width:100%;text-align:center}.loader__load-spinner--1Dt_w .loader__load-icon--3KjQ6{margin:5px;font-size:inherit;line-height:1;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;text-shadow:0 0 3px rgba(255,255,255,0.95)}@-webkit-keyframes loader__rotating--2wr0v{from{-webkit-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader__rotating--2wr0v{from{-ms-transform:rotate(0deg);-moz-transform:rotate(0deg);-webkit-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}to{-ms-transform:rotate(360deg);-moz-transform:rotate(360deg);-webkit-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}}.loader__rotating--2wr0v{-webkit-animation:loader__rotating--2wr0v 0.5s linear infinite;-moz-animation:loader__rotating--2wr0v 0.5s linear infinite;-ms-animation:loader__rotating--2wr0v 0.5s linear infinite;-o-animation:loader__rotating--2wr0v 0.5s linear infinite;animation:loader__rotating--2wr0v 0.5s linear infinite}\n", ""]);

// exports
exports.locals = {
	"load-spinner": "loader__load-spinner--1Dt_w",
	"load-icon": "loader__load-icon--3KjQ6",
	"rotating": "loader__rotating--2wr0v"
};