import React, { PureComponent } from 'react';
import { Icon } from 'svift-ui';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import Tooltip from 'components/tooltip/Tooltip';
import Tooltip from 'svift-library/client/tooltip';

class OptionPanel extends PureComponent {
	render() {
		const { title, icon } = this.props;

		// const children = React.Children.map(child => {
		// 	return React.cloneElement(child, {
		// 		updateOptionPanel: this.forceUpdate
		// 	});
		// });

		return (
			<Tooltip
				noClass
				open={this.props.open}
				// popup
				zIndex="997"
				target={this.props.target}
				// nodeAttributes={{
				// 	onClick: e => {
				// 		console.log(e, '??')
				// 	}
				// }}
				// onClick={e => console.log(e, 'heh')}
				content={
					<div style={{ padding: '6px 16px', backgroundColor: 'white', border: '#AAA solid 1px', borderRadius: 4, boxShadow: '0 2px 4px 0 #666' }}>
						{/* {this.props.title || this.props.icon ? <span className="editor-bar-title">
								{this.props.icon ? <Icon name={this.props.icon} className="primary" style={{ marginRight: 8 }} /> : ''}
								{this.props.title ? <FormattedMessage id={this.props.title} /> : ''}
							</span>
						} */}
						{this.props.children || this.props.options}
					</div>
				}
			>
				<div style={{ padding: '6px 16px', backgroundColor: 'white', border: '#AAA solid 1px', borderRadius: 4, boxShadow: '0 2px 4px 0 #666' }}>
					{/* {this.props.title || this.props.icon ? <span className="editor-bar-title">
							{this.props.icon ? <Icon name={this.props.icon} className="primary" style={{ marginRight: 8 }} /> : ''}
							{this.props.title ? <FormattedMessage id={this.props.title} /> : ''}
						</span>
					} */}
					{this.props.children || this.props.options}
				</div>
			</Tooltip>
		);
	}
}

// OptionPanel.propTypes = {
// 	title: PropTypes.string,
// 	icon: PropTypes.string,
// };

export default injectIntl(OptionPanel);
