import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

const Text = props => {
    return(
        <p style={{ lineHeight: 1.5 }}>
            <FormattedMessage id={props.text} />
        </p>
    );
}

export default injectIntl(Text);