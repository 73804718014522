// import React, { PureComponent } from 'react';
// import ReactDOM, { unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer } from 'react-dom'; // https://twitter.com/soprano/status/737316379712331776
// import PropTypes from 'prop-types';

// class Portal extends PureComponent {
// 	componentDidMount() {
// 		this.node = document.createElement('div');
// 		this.node.className = this.props.noClass ? 'svift-tooltip-no-class' : 'svift-tooltip';
// 		document.body.appendChild(this.node);
// 		this.renderPortal(this.props);
// 	}

// 	componentWillReceiveProps(nextProps) {
// 		this.renderPortal(nextProps);
// 	}

// 	componentWillUnmount() {
// 		ReactDOM.unmountComponentAtNode(this.node);
// 		document.body.removeChild(this.node);
// 	}

// 	renderPortal = (props) => {
// 		renderSubtreeIntoContainer(this, props.children, this.node);
// 	}

// 	render() {
// 		return null;
// 	}
// }

// class Tooltip extends PureComponent {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			targetNode: null,
// 			container: null
// 		};

// 		this.onResize = () => {
// 			if (this.props.open || this.props.hover) {
// 				this.forceUpdate();
// 			}
// 		};
// 	}

// 	getChildContext() {
// 		return {
// 			tooltip: () => this.forceUpdate()
// 		};
// 	}

// 	componentDidMount() {
// 		window.addEventListener('resize', this.onResize);
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.onResize);
// 	}

// 	componentWillReceiveProps(nextProps) {
// 		this.setState({
// 			targetNode: ReactDOM.findDOMNode(nextProps.target),
// 			container: null // May be able to optimize this more if needed; right now the tooltip will rerender every single time its props change, which may not be needed depending on the use case
// 		});
// 	}

// 	componentDidUpdate(prevProps, prevState) {
// 		const containerChanged = prevState.container !== this.state.container;

// 		if (containerChanged) {
// 			this.forceUpdate();
// 		}
// 	}

// 	calculatePosition(newPosition) {
// 		const targetNode = this.state.targetNode;

// 		if (!targetNode) return {};

// 		const { height: tooltipHeight, width: tooltipWidth, right: tooltipRight } = this.state.container.getBoundingClientRect();

// 		const { top: targetTop, bottom: targetBottom, left: targetLeft, right: targetRight, width: targetWidth, height: targetHeight } = targetNode.getBoundingClientRect();

// 		const scrollTop = window.pageYOffset; //document.body.scrollTop;
// 		const viewportWidth = document.body.clientWidth; //window.innerWidth;
// 		const windowHeight = document.body.scrollHeight;

// 		let offsetTop;
// 		let offsetLeft;

// 		const position = newPosition || this.props.position;

// 		switch (position) {
// 			case 'left':
// 				offsetTop = targetTop + ((targetHeight - tooltipHeight) / 2) + scrollTop;
// 				offsetLeft = targetLeft - tooltipWidth;

// 				break;

// 			case 'right':
// 				offsetTop = targetTop + ((targetHeight - tooltipHeight) / 2) + scrollTop;
// 				offsetLeft = targetLeft + targetWidth;

// 				break;

// 			case 'bottom':
// 				offsetTop = targetTop + targetHeight + scrollTop;
// 				offsetLeft = targetLeft + (targetWidth / 2) - (tooltipWidth / 2);

// 				break;

// 			case 'top':
// 			default:
// 				offsetTop = (targetTop - tooltipHeight) + scrollTop;
// 				offsetLeft = targetLeft + (targetWidth / 2) - (tooltipWidth / 2);
// 		}

// 		if (this.props.calculateOffsetTop) {
// 			offsetTop = this.props.calculateOffsetTop({
// 				tooltipHeight,
// 				tooltipWidth,
// 				targetTop,
// 				targetBottom,
// 				targetLeft,
// 				targetRight,
// 				targetWidth,
// 				targetHeight,
// 				scrollTop,
// 				defaultOffset: offsetTop
// 			});
// 		}

// 		if (this.props.calculateOffsetLeft) {
// 			offsetLeft = this.props.calculateOffsetLeft({
// 				tooltipHeight,
// 				tooltipWidth,
// 				targetTop,
// 				targetBottom,
// 				targetLeft,
// 				targetRight,
// 				targetWidth,
// 				targetHeight,
// 				scrollTop,
// 				defaultOffset: offsetLeft
// 			});
// 		}

// 		/* Collision detection */
// 		const minimumClearDistance = 10;

// 		offsetLeft = Math.max(minimumClearDistance, offsetLeft);
// 		offsetLeft = Math.min(offsetLeft, viewportWidth - tooltipWidth - minimumClearDistance);
// 		//offsetTop = Math.max(minimumClearDistance, offsetTop);
// 		//offsetTop = Math.min(offsetTop, windowHeight - tooltipHeight - minimumClearDistance);

// 		/*
// 			Only call this is newPosition is not defined; otherwise it will calculate forever if neither position is within bounds
// 		*/
// 		if (!newPosition) {
// 			if (offsetTop < minimumClearDistance) {
// 				return this.calculatePosition('bottom');
// 			}

// 			if (offsetTop > windowHeight - tooltipHeight - minimumClearDistance) {
// 				return this.calculatePosition('top');
// 			}

// 			// if (offsetLeft < minimumClearDistance) {
// 			// 	return this.calculatePosition('right');
// 			// }

// 			// if (offsetLeft > viewportWidth - tooltipWidth - minimumClearDistance) {
// 			// 	return this.calculatePosition('left');
// 			// }
// 		}

// 		const style = {
// 			position: 'absolute',
// 			zIndex: this.props.zIndex,
// 			// background: 'white',
// 			top: offsetTop,
// 			left: offsetLeft,
// 			...this.props.style
// 		};

// 		return {
// 			style,
// 			newPosition
// 		};
// 	}

// 	getContainerStyles() {
// 		if (!this.state.container) {
// 			return {
// 				style: { position: 'absolute', top: 0, left: 0, opacity: 0, ...this.props.style }
// 			}; // Rendering an empty container that we use to calculate the dimensions of the tooltip
// 		}

// 		return this.calculatePosition();
// 	}

// 	renderContent() {
// 		const content = this.props.open ? this.props.content : this.props.hoverContent;

// 		const { style: containerStyles, newPosition } = this.getContainerStyles();

// 		return (
// 			<div
// 				key="tooltip"
// 				className={newPosition || this.props.position}
// 				style={containerStyles}
// 				onClick={this.props.onClick}
// 				ref={(ref) => this.setState({ container: ref })}
// 			>
// 				{content}
// 			</div>
// 		);
// 	}

// 	render() {
// 		if (!this.props.open && !this.props.hover) return null;
// 		if (!this.props.target) return null;

// 		return (
// 			<Portal
// 				noClass={this.props.noClass}
// 			>
// 				{this.renderContent()}
// 			</Portal>
// 		);
// 	}
// }

// Tooltip.childContextTypes = {
// 	tooltip: PropTypes.func
// };

// Tooltip.defaultProps = {
// 	// offsetY: 0,
// 	padding: 8,
// 	zIndex: 907,
// 	position: 'top',
// 	noClass: false
// };

// Tooltip.propTypes = {
// 	// offsetY: PropTypes.number,
// 	target: PropTypes.object,
// 	position: PropTypes.string,
// 	open: PropTypes.bool,
// 	hover: PropTypes.bool,
// 	content: PropTypes.node,
// 	hoverContent: PropTypes.node,
// 	style: PropTypes.object,
// 	calculateOffsetTop: PropTypes.func,
// 	calculateOffsetLeft: PropTypes.func
// };

// // cool little utility to make it easy to assign tooltips to elements
// function tooltip() {
// 	const _this = this;
// 	let target = null;

// 	return {
// 		setTarget: (element) => {
// 			target = element;
// 			_this.forceUpdate();
// 		},
// 		getTarget: () => target
// 	};
// }

// export { tooltip };

// export default Tooltip;

import React, { PureComponent } from 'react';
import ReactDOM, { createPortal, unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer } from 'react-dom'; // https://twitter.com/soprano/status/737316379712331776
import PropTypes from 'prop-types';

// Attempted to deprecate this one (renderSubtreeIntoContainer -> createPortal), but it ruined deeply nested tooltips for whatever reason. Work in progress is commented out below; fix when time permits. 
class Portal extends PureComponent {
	componentDidMount() {
		this.node = document.createElement('div');
		this.node.className = this.props.noClass ? 'svift-tooltip-no-class' : 'svift-tooltip';
		document.body.appendChild(this.node);
		this.renderPortal(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.renderPortal(nextProps);
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this.node);
		document.body.removeChild(this.node);
	}

	renderPortal = (props) => {
		renderSubtreeIntoContainer(this, props.children, this.node);
	}

	render() {
		return null;
	}
}

// class Portal extends PureComponent {
// 	constructor(props) {
// 		super(props);

// 		this.node = document.createElement('div');
// 		this.node.className = props.noClass ? 'svift-tooltip-no-class' : 'svift-tooltip';
// 		document.body.appendChild(this.node);
// 	}

// 	componentWillUnmount() {
// 		document.body.removeChild(this.node);
// 	}

// 	render() {
// 		return createPortal(
// 			this.props.children,
// 			this.node
// 		);
// 	}
// }

class Tooltip extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			targetNode: ReactDOM.findDOMNode(props.target) || null,
			container: null
		};

		this.onResize = () => {
			if (this.props.open || this.props.hover) {
				this.forceUpdate();
			}
		};
	}

	getChildContext() {
		return {
			tooltip: () => this.forceUpdate()
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onResize);
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onResize);
		window.removeEventListener('resize', this.onResize);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			targetNode: ReactDOM.findDOMNode(nextProps.target),
			container: null // May be able to optimize this more if needed; right now the tooltip will rerender every single time its props change, which may not be needed depending on the use case
		});
	}

	componentDidUpdate(_, prevState) {
		const containerChanged = prevState.container !== this.state.container;

		if (containerChanged) {
			this.forceUpdate();
		}
	}

	calculatePosition(newPosition) {
		const targetNode = this.state.targetNode;

		if (!targetNode) return {};

		const { height: tooltipHeight, width: tooltipWidth, right: tooltipRight } = this.state.container.getBoundingClientRect();

		const { top: targetTop, bottom: targetBottom, left: targetLeft, right: targetRight, width: targetWidth, height: targetHeight } = targetNode.getBoundingClientRect();

		// if (this.props.logs) {
		// 	console.log(targetNode)
		// 	console.log(targetNode.getBoundingClientRect(), 'rect')
		// 	debugger;
		// }

		const scrollTop = window.pageYOffset; //document.body.scrollTop;
		const viewportWidth = document.body.clientWidth; //window.innerWidth;
		const windowHeight = document.body.scrollHeight;

		let offsetTop;
		let offsetLeft;

		const position = newPosition || this.props.position;

		switch (position) {
			case 'left':
				offsetTop = targetTop + ((targetHeight - tooltipHeight) / 2) + scrollTop;
				offsetLeft = targetLeft - tooltipWidth;

				break;

			case 'right':
				offsetTop = targetTop + ((targetHeight - tooltipHeight) / 2) + scrollTop;
				offsetLeft = targetLeft + targetWidth;

				break;

			case 'bottom':
				offsetTop = targetTop + targetHeight + scrollTop;
				offsetLeft = targetLeft + (targetWidth / 2) - (tooltipWidth / 2);

				break;

			case 'top':
			default:
				offsetTop = (targetTop - tooltipHeight) + scrollTop;
				offsetLeft = targetLeft + (targetWidth / 2) - (tooltipWidth / 2);
		}

		if (this.props.calculateOffsetTop) {
			offsetTop = this.props.calculateOffsetTop({
				tooltipHeight,
				tooltipWidth,
				targetTop,
				targetBottom,
				targetLeft,
				targetRight,
				targetWidth,
				targetHeight,
				scrollTop,
				defaultOffset: offsetTop
			});
		}

		if (this.props.calculateOffsetLeft) {
			offsetLeft = this.props.calculateOffsetLeft({
				tooltipHeight,
				tooltipWidth,
				targetTop,
				targetBottom,
				targetLeft,
				targetRight,
				targetWidth,
				targetHeight,
				scrollTop,
				defaultOffset: offsetLeft
			});
		}

		/* Collision detection */
		const minimumClearDistance = 10;

		offsetLeft = Math.max(minimumClearDistance, offsetLeft);
		offsetLeft = Math.min(offsetLeft, viewportWidth - tooltipWidth - minimumClearDistance);
		//offsetTop = Math.max(minimumClearDistance, offsetTop);
		//offsetTop = Math.min(offsetTop, windowHeight - tooltipHeight - minimumClearDistance);

		/*
			Only call this is newPosition is not defined; otherwise it will calculate forever if neither position is within bounds
		*/
		if (!newPosition) {
			if (offsetTop < minimumClearDistance) {
				return this.calculatePosition('bottom');
			}

			if (offsetTop > windowHeight - tooltipHeight - minimumClearDistance) {
				return this.calculatePosition('top');
			}

			// if (offsetLeft < minimumClearDistance) {
			// 	return this.calculatePosition('right');
			// }

			// if (offsetLeft > viewportWidth - tooltipWidth - minimumClearDistance) {
			// 	return this.calculatePosition('left');
			// }
		}

		const style = {
			position: 'absolute',
			zIndex: this.props.zIndex,
			// background: 'white',
			top: offsetTop,
			left: offsetLeft,
			...this.props.style
		};

		return {
			style,
			newPosition
		};
	}

	getContainerStyles() {
		if (!this.state.container) {
			 // Rendering an empty container that we use to calculate the dimensions of the tooltip
			return {
				style: { position: 'absolute', top: 0, left: 0, opacity: 0, ...this.props.style }
			};
		}

		return this.calculatePosition();
	}

	renderContent() {
		const content = this.props.open ? this.props.content : this.props.hoverContent;

		const { style: containerStyles, newPosition } = this.getContainerStyles();

		return (
			<div
				key="tooltip"
				className={newPosition || this.props.position}
				style={containerStyles}
				onClick={this.props.onClick}
				ref={ref => this.setState({ container: ref })}
			>
				{content}
			</div>
		);
	}

	render() {
		if (!this.props.open && !this.props.hover) return null;
		if (!this.props.target) return null;

		// if (this.props.logs) debugger; //console.log('tooltip render')

		return (
			<Portal
				noClass={this.props.noClass}
			>
				{this.renderContent()}
			</Portal>
		);
	}
}

Tooltip.childContextTypes = {
	tooltip: PropTypes.func
};

Tooltip.defaultProps = {
	// offsetY: 0,
	padding: 8,
	zIndex: 907,
	position: 'top',
	noClass: false
};

Tooltip.propTypes = {
	// offsetY: PropTypes.number,
	target: PropTypes.object,
	position: PropTypes.string,
	open: PropTypes.bool,
	hover: PropTypes.bool,
	content: PropTypes.node,
	hoverContent: PropTypes.node,
	style: PropTypes.object,
	calculateOffsetTop: PropTypes.func,
	calculateOffsetLeft: PropTypes.func
};

// cool little utility to make it easy to assign tooltips to elements
function tooltip() {
	const _this = this;
	let target = null;

	return {
		setTarget: (element) => {
			target = element;
			_this.forceUpdate();
		},
		getTarget: () => target
	};
}

export { tooltip };

export default Tooltip;