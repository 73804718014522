const removeAtIndex = (array, index) => {
    return [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ];
};

export const removeEmptyParents = (nodeMap, path) => {
    const nodeID = path[path.length - 1];
    const parentNodeID = path[path.length - 2];
    
    if (!parentNodeID) return;

	nodeMap[parentNodeID] = {
		...nodeMap[parentNodeID],
		children: removeAtIndex(
            nodeMap[parentNodeID].children, 
            nodeMap[parentNodeID].children.findIndex(childID => childID === nodeID)
        )
    };

    if (nodeMap[parentNodeID].children.length === 0) {
        removeEmptyParents(nodeMap, path.slice(0, -1));

        delete nodeMap[parentNodeID]; 
    }
}

export const removeChildrenRecursively = (nodeMap, nodeID) => {
    if (nodeMap[nodeID].children) {
        nodeMap[nodeID].children.forEach(childID => removeChildrenRecursively(nodeMap, childID));
    }

    delete nodeMap[nodeID]; 
};

/*
    TO-DO:

    Copy paste logic - problems:
        0) Insertion logic (mimic DND)
        1) New ID's when pasting
        2) Restrict pastable content to the context (e.g. can't paste menus into forms)
        3) Restrict what can be copied (e.g. you can't copy "content placeholder" in layouts)
        4) Can't paste Container into Grid and similar problems with different levels
*/