import constants from 'store/constants';
// import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	view: 'list',
	activeFolder: null,
	hoveredFolder: null,
	foldersLoading: false,
	files: {},
	folders: [],
	buckets: {}
});

const defaultState = getDefaultState();

const filesReducer = (state = defaultState, action, siteChanged) => {
	switch (action.type) {
		case constants.SET_FOLDER_HOVERED: {
			return {
				...state,
				hoveredFolder: action.data
			};
		}

		case constants.TOGGLE_FOLDERS_LOADING: {
			return {
				...state,
				foldersLoading: action.data
			};
		}

		case constants.FILES_SET_ACTIVE_FOLDER: {
			return {
				...state,
				activeFolder: action.data
			};
		}

		case constants.TOGGLE_FILESITE_VIEW: {
			return {
				...state,
				view: action.data
			};
		}

		case constants.SET_FILES: {
			let files;

			if (action.data && action.data.files) {
				files = action.data.files.reduce((acc, file) => {
					acc[file._id] = {
						...file
					};

					return acc;
				}, {});
			}

			return {
				...state,
				files: {
					...state.files,
					...files || action.data
				}
			};
		}

		case constants.SET_BUCKET: {
			return {
				...state,
				buckets: {
					...state.buckets,
					[action.data.siteid]: action.data.files
				}
			};
		}

		case constants.SET_FOLDERS: {
			let files;

			if (action.data && action.data.files) {
				files = action.data.files.reduce((acc, file) => {
					acc[file._id] = {
						...file
					};

					return acc;
				}, {});
			}

			const newState = {
				...state,
				folders: action.data.folders,
				hoveredFolder: null
			};

			if (files) newState.files = files;

			const deletedActiveFolder = action.data.folderid === state.activeFolder;

			if (state.activeFolder === null || deletedActiveFolder) {
				newState.activeFolder = action.data.folders.find(folder => folder.parent === "")._id
			}

			return newState;
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			return {
				...state,
				activeFolder: null
			}
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default filesReducer;
