import React, { PureComponent } from 'react';
import Pagination from './Pagination';
import FeedItem from './FeedItem';
import { withRouter } from 'react-router';
import {
    searchImage,
    searchDescription
} from 'svift-library/client/renderer/utils/searchNodes';
import { FormattedDate } from 'react-intl';
import key from 'svift-library/key';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import Radium from 'radium';

const RadiumButton = Radium(props => {
    return (
        <button {...props}>
            {props.children}
        </button>
    );
});

const formatItems = props => {
    const { node } = props;

    // console.log(props, 'props feed');

    switch (node.props.type) {
        case 'BlogPosts':
            // if (!blogs) return [];

            const { files, blogs } = props;
            let activeBlogs = blogs.order.map(id => blogs.lookup[id]);

            const filter = node.props.filter;

            if (filter) {
                const { authors, tags } = filter;

                if (authors && authors.length > 0) {
                    activeBlogs = activeBlogs.filter(blog =>
                        authors.includes(blog.author)
                    );
                }

                if (tags && tags.length > 0) {
                    activeBlogs = activeBlogs.filter(blog =>
                        tags.some(tag => blog.tags && blog.tags.includes(tag))
                    );
                }
            }

            activeBlogs = activeBlogs
                .filter(blog => {
                    if (typeof blog.publishAt === 'undefined') return false;
                    if (!blog.content.live) return false;

                    return new Date(blog.publishAt) < new Date();
                })
                .sort((a, b) => {
                    if (typeof a.publishAt === 'undefined') return 1;
                    if (typeof b.publishAt === 'undefined') return -1;

                    return new Date(b.publishAt) - new Date(a.publishAt);
                });

            return activeBlogs.map(blog => {
                // let thumbnailFile = files.lookup[blog.thumbnail]; //resolveFile(files, blog.thumbnail);
                // let description = blog.description;

                // if (!thumbnailFile) {
                //     thumbnailFile = files.lookup[searchImage(blog.content.live.nodeMap)];
                // }

                // if (!description) {
                //     description = blog.description || searchDescription(blog.content.live.nodeMap)
                // }
                // thumbnailFile =
                //     files.lookup[findImage(blog.content.live.nodeMap)]; //resolveFile(files, findImage(blog.content.live.tree));

                // const thumbnailSrc = resolveFileUrl(thumbnailFile, ['p']);

                const author = props.users && props.users.lookup[blog.author]; //find(user => user._id === blog.author);; //site.users && site.users.find(user => user._id === blog.author);
                let authorName;

                if (author) {
                    authorName = `${author.firstName} ${author.lastName}`;
                } else {
                    authorName = props.siteName; //site.name;
                }

                return {
                    id: blog._id,
                    url: blog.url,
                    title: blog.title,
                    author: authorName,
                    publishAt: blog.publishAt && (
                        <FormattedDate
                            value={new Date(blog.publishAt)}
                            year="numeric"
                            month="long"
                            day="2-digit"
                        />
                    )
                    // thumbnail: thumbnailSrc,
                    // description
                    // blog.description || searchDescription(blog.content.live.nodeMap)
                    // // findNormalText(
                    // //     blog.content.live && blog.content.live.nodeMap
                    // // )
                };
            });
    }
};

class Feed extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            paginationOffset: 0,
            items: formatItems(props)
        };
    }

    static mapContextToProps = (props, state) => {
        const { theme, blogs, files, users, siteName } = props.context;
        const { onNavigate, computeLink } = props.context.extend.Feed;

        return shallowCompare(
            {
                theme,
                files,
                blogs,
                // site,
                users,
                siteName,
                computeLink,
                onNavigate
                // onRedirect
            },
            state
        );
    };

    componentWillReceiveProps(nextProps) {
        /* 
			EXPAND WHEN BLOGS ARENT THE ONLY ITEMS IN FEED
		*/
        if (
            nextProps.blogs !== this.lastItems ||
            nextProps.node !== this.props.node
        ) {
            this.setState({
                items: formatItems(nextProps)
            });
        }
    }

    setPaginationOffset = offset => {
        this.setState({
            paginationOffset: offset
        });
    };

    renderItems = () => {
        const { computePreset, node, theme } = this.props;
        const startIndex = this.state.paginationOffset;

        const itemsInRange =
            node.props.itemsPerPage === 'unlimited'
                ? this.state.items
                : this.state.items.slice(
                      startIndex,
                      startIndex + node.props.itemsPerPage
                  );

        return itemsInRange.map((item, i) => {
            const isLastItem = i === itemsInRange.length - 1;
            const { title, author, publishAt } = item;

            const blog = this.props.blogs.lookup[
                item && item.id && item.id.toString()
            ];
            const computedLink =
                this.props.computeLink &&
                this.props.computeLink(item.id, this.props.blogs);
            const description =
                blog.description ||
                searchDescription(key('content.live.nodeMap')(blog));

            let thumbnail = this.props.files.lookup[
                blog.thumbnail && blog.thumbnail.toString()
            ];

            if (!thumbnail) {
                thumbnail = searchImage(
                    key('content.live.nodeMap')(blog),
                    this.props.files
                );
            }

            const thumbnailStyle = thumbnail &&
                node.props.thumbnail.visible && {
                    ...computePreset(
                        theme.presets.image[node.props.thumbnail.preset],
                        theme
                    ),
                    ...computePreset(node.props.thumbnail.style, theme)
                };

            const linkStyle = {
                ...computePreset(theme.presets.text.Link, theme),
                cursor: 'pointer'
            };

            const titleStyle = title &&
                node.props.postTitle.visible && {
                    ...computePreset(
                        theme.presets.text[node.props.postTitle.preset],
                        theme
                    ),
                    ...computePreset(node.props.postTitle.style, theme),
                    ...linkStyle
                };

            const descriptionStyle = description &&
                node.props.postDescription.visible && {
                    ...computePreset(
                        theme.presets.text[node.props.postDescription.preset],
                        theme
                    ),
                    ...computePreset(node.props.postDescription.style, theme)
                };

            const authorStyle = author &&
                node.props.postAuthor.visible && {
                    ...computePreset(
                        theme.presets.text[node.props.postAuthor.preset],
                        theme
                    ),
                    ...computePreset(node.props.postAuthor.style, theme)
                };

            const publicationDateStyle = publishAt &&
                node.props.postPublicationDate.visible && {
                    ...computePreset(
                        theme.presets.text[
                            node.props.postPublicationDate.preset
                        ],
                        theme
                    ),
                    ...computePreset(
                        node.props.postPublicationDate.style,
                        theme
                    )
                };

            // const onClick = () => {
            //     // console.log(item, 'item', this.props, 'props')
            //     this.props.onRedirect(item.id, this.props.blogs);
            //     // redirect(this.props.history, item.url, 'blog');
            // };

            // console.log(item, 'item');
            // console.log(titleStyle, 'title style');

            return (
                // This component is getting absolutely bonkers - way too many props
                <FeedItem
                    key={item.id}
                    nodeProps={node.props}
                    isLastItem={isLastItem}
                    // onClick={onClick}
                    onNavigate={this.props.onNavigate}
                    computedLink={computedLink}
                    thumbnailStyle={thumbnailStyle}
                    authorStyle={authorStyle}
                    linkStyle={linkStyle}
                    readMoreText={node.props.readMoreText}
                    descriptionStyle={descriptionStyle}
                    titleStyle={titleStyle}
                    publicationDateStyle={publicationDateStyle}
                    theme={theme}
                    history={this.props.history}
                    description={description}
                    thumbnail={thumbnail}
                    {...item}
                />
            );
        });
    };

    renderPaginationHandle = (direction, canClick, onClick) => {
        const { theme, computePreset } = this.props;
        const buttonStyle = computePreset(
            theme.presets.button.Primary, //Secondary,
            theme
        );

        // console.log(buttonStyle, 'buttonStyle');

        return (
            <RadiumButton
                // TO DO: transl8 title + aria-label
                id={
                    direction === 'left'
                        ? 'navigateToEarlierPosts'
                        : 'navigateToLaterPosts'
                }
                title={direction === 'left' ? 'EarlierPosts' : 'LaterPosts'}
                aria-label={
                    direction === 'left'
                        ? 'Go to earlier posts'
                        : 'Go to later posts'
                }
                style={{
                    ...buttonStyle,
                    padding: '8px 12px',
                    fontSize: '9px',
                    boxShadow: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginTop: '15px',
                    marginLeft: direction === 'left' ? '0px' : '10px'
                }}
                onClick={onClick}
                disabled={!canClick}
            >
                <i
                    className={
                        direction === 'left'
                            ? 'icon-arrow-left3'
                            : 'icon-arrow-right3'
                    }
                    style={{ margin: 0 }}
                />
            </RadiumButton>
        );
    };

    // TO DO: revisit pagination page number(s) + add more pages as shortcuts
    renderPageNumber = number => {
        const { theme, computePreset } = this.props;
        const buttonStyle = computePreset(
            theme.presets.button.Secondary,
            theme
        );
        const style = {
            padding: '8px 15px',
            color: 'white',
            fontSize: theme.measurements.fontSize,
        };

        return (
            <div
                style={{
                    ...style,
                    ...buttonStyle,
                    ...buttonStyle[':active'],
                    borderRadius: '50%',
                    margin: 0
                }}
            >
                {number}
            </div>
        );
    };

    renderFeed = () => {
        return (
            <div style={{ width: '100%' }}>
                {this.renderItems()}

                <Pagination
                    itemCount={this.state.items.length}
                    offset={this.state.paginationOffset}
                    onOffsetChange={this.setPaginationOffset}
                    itemsPerPage={this.props.node.props.itemsPerPage}
                    renderHandle={this.renderPaginationHandle}
                    renderPageNumber={this.renderPageNumber}
                />
            </div>
        );
    };

    render() {
        const { computePreset, node, theme } = this.props;
        const titleStyle = {
            ...computePreset(
                theme.presets.text[node.props.postTitle.preset],
                theme
            ),
            ...computePreset(node.props.postTitle.style, theme)
        };
        const messageStyle = {
            ...computePreset(
                theme.presets.text[node.props.postDescription.preset],
                theme
            ),
            ...computePreset(node.props.postDescription.style, theme)
        };
        const negativeColor = theme.colors.error;

        // console.log(this.state.items, 'items in feed');

        if (this.state.items.length === 0)
            return (
                <div
                    style={{
                        padding: '32px 24px 64px 24px',
                        textAlign: 'center',
                        border: `2px ${negativeColor} solid`,
                        borderRadius: '5px'
                    }}
                >
                    {/* TO DO: transl8 fallback message */}
                    <h3 style={titleStyle}>
                        <span
                            style={{ color: negativeColor, display: 'block' }}
                        >
                            ( ! )
                        </span>
                        [ No Blog posts ]<br />
                        ...
                    </h3>
                    <hr style={{ backgroundColor: negativeColor }} />
                    <p style={messageStyle}>
                        - Publish new posts or filter the feed in a different
                        way.
                    </p>
                </div>
            );

        // FIX THIS LATER WHEN FEED HAS MORE THAN BLOGS
        this.lastItems = this.props.blogs;

        return this.renderFeed();
    }
}

export default withRouter(Feed);
