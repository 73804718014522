import constants from './constants';

const getInitialState = () => {
    return {
        notifications: []
    };
};

const initialState = getInitialState();

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case constants.PUSH_NOTIFICATION: 
            const notification = action.data;
            if (notification.position === "topCenter" || notification.postion === "topLeft" || notification.postion === "topRight"){
                return {
                    ...state,
                    notifications: [
                        ...state.notifications,notification
                    ]
                };
            } else {
                return {
                    ...state,
                    notifications: [
                        notification, ...state.notifications   
                    ]   
                }; 
            }
           
        case constants.DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(({ id } ) => id !== action.data)
            };
        case 'LOGOUT':
            return getInitialState();
        default:
            return state;
    }
};

export default reducer;