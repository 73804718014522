import { push, replace } from 'connected-react-router';
import store from 'store';
import { matchPath } from 'react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import Alert from 'components/Alert';

// Handle all the cases where you want to check whether or not routing will cause data to be lost, so we can warn the user
const routeAlerts = {
    theme: route => {
        const state = store.getState();

        const { siteid } = state.sites.params;
        const { stagedTheme, stagedFrom, params } = state.sites.themes;
        const { themeid } = params;

        if (!themeid) return; // dont have to perform any further checks if themeid is null

        const match = matchPath(route, '/dashboard/:siteid/design/:themeid?');

        // If there is no match or the new theme id doesn't match the existing one
        const themeIDChanged = !match || match.params.themeid !== themeid;
        const siteIDChanged = !match || match.params.siteid !== siteid;

        if (
            (siteIDChanged || themeIDChanged) &&
            (stagedTheme !== null && stagedTheme !== stagedFrom)
        ) {
            return {
                title: window.__intlRef.formatMessage({
                    id: 'design.unsaved changes alert title'
                }),
                message: window.__intlRef.formatMessage({
                    id: 'design.unsaved changes alert description'
                }),
                confirm: window.__intlRef.formatMessage({
                    id: 'design.unsaved changes alert confirm'
                }),
                cancel: window.__intlRef.formatMessage({
                    id: 'design.unsaved changes alert cancel'
                })
            };
        }
    },
    // blog: route => {
    //     const state = store.getState();

    //     const { stagedContent, stagedFrom } = state.sites.blogs;

    //     if (stagedContent !== null && stagedContent !== stagedFrom) {
    //         return {
    //             title: window.__intlRef.formatMessage({
    //                 id: 'blog.unsaved changes alert title'
    //             }),
    //             message: window.__intlRef.formatMessage({
    //                 id: 'blog.unsaved changes alert description'
    //             }),
    //             confirm: window.__intlRef.formatMessage({
    //                 id: 'blog.unsaved changes alert confirm'
    //             }),
    //             cancel: window.__intlRef.formatMessage({
    //                 id: 'blog.unsaved changes alert cancel'
    //             })
    //         };
    //     }
    // },
    // content: route => {
    //     const state = store.getState();

    //     const { stagedContent, stagedFrom } = state.sites.content;

    //     if (stagedContent !== null && stagedContent !== stagedFrom) {
    //         return {
    //             title: window.__intlRef.formatMessage({
    //                 id: 'pages.unsaved changes alert title'
    //             }),
    //             message: window.__intlRef.formatMessage({
    //                 id: 'pages.unsaved changes alert description'
    //             }),
    //             confirm: window.__intlRef.formatMessage({
    //                 id: 'pages.unsaved changes alert confirm'
    //             }),
    //             cancel: window.__intlRef.formatMessage({
    //                 id: 'pages.unsaved changes alert cancel'
    //             })
    //         };
    //     }
    // },
    // layout: route => {
    //     const state = store.getState();

    //     const { stagedLayout, stagedFrom } = state.sites.layout;

    //     if (stagedLayout !== null && stagedLayout !== stagedFrom) {
    //         return {
    //             title: window.__intlRef.formatMessage({
    //                 id: 'layouts.unsaved changes alert title'
    //             }),
    //             message: window.__intlRef.formatMessage({
    //                 id: 'layouts.unsaved changes alert description'
    //             }),
    //             confirm: window.__intlRef.formatMessage({
    //                 id: 'layouts.unsaved changes alert confirm'
    //             }),
    //             cancel: window.__intlRef.formatMessage({
    //                 id: 'layouts.unsaved changes alert cancel'
    //             })
    //         };
    //     }
    // },
    // form: route => {
    //     const state = store.getState();

    //     const { stagedForm, stagedFrom } = state.sites.forms;

    //     if (stagedForm !== null && stagedForm !== stagedFrom) {
    //         return {
    //             title: window.__intlRef.formatMessage({
    //                 id: 'forms.unsaved changes alert title'
    //             }),
    //             message: window.__intlRef.formatMessage({
    //                 id: 'forms.unsaved changes alert description'
    //             }),
    //             confirm: window.__intlRef.formatMessage({
    //                 id: 'forms.unsaved changes alert confirm'
    //             }),
    //             cancel: window.__intlRef.formatMessage({
    //                 id: 'forms.unsaved changes alert cancel'
    //             })
    //         };
    //     }
    // },
    navigation: route => {
        const state = store.getState();

        const { stagedNavigation, stagedFrom } = state.sites.navigation;

        // Auto can never be modified, so we make an exception to avoid a scenario where user is alerted about unsaved changes when creating new navigation from the auto navigation
        const isAuto = window.location.href.endsWith('design/navigation'); 

        if (!isAuto && stagedNavigation !== null && stagedNavigation !== stagedFrom) {
            return {
                title: window.__intlRef.formatMessage({
                    id: 'menus.navigation unsaved changes alert title'
                }),
                message: window.__intlRef.formatMessage({
                    id: 'menus.navigation unsaved changes alert description'
                }),
                confirm: window.__intlRef.formatMessage({
                    id: 'menus.navigation unsaved changes alert confirm'
                }),
                cancel: window.__intlRef.formatMessage({
                    id: 'menus.navigation unsaved changes alert cancel'
                })
            };
        }
    }
};

const renderAlert = ({ onConfirm, message, title, confirm, cancel }) => {
    const node = document.createElement('div');

    document.body.appendChild(node);

    const removeAlert = () => {
        ReactDOM.unmountComponentAtNode(node);

        document.body.removeChild(node);
    };

    ReactDOM.render(
        <Alert
            show={true}
            title={title}
            text={message}
            onConfirm={() => {
                onConfirm();

                removeAlert();
            }}
            onCancel={removeAlert}
            confirmTitle={confirm}
            confirmIcon="checkmark"
            confirmClass="negative"
            cancelTitle={cancel}
        />,
        node
    );
};

const performRouteChange = (type, route, ...args) => {
    const alertMessage = Object.values(routeAlerts)
    	.map(routeAlert => routeAlert(route))
    	.find(status => typeof status !== 'undefined');

    const dispatchRouteChange = () => {
        switch (type) {
            case 'replace':
                store.dispatch(
                    replace(route, {
                        //			currentState: state,
                        data: args
                    })
                );

                break;
            case 'push':
                store.dispatch(
                    push(route, {
                        //			currentState: state,
                        data: args
                    })
                );

                break;
        }
    };

    if (window.__EDITOR_UNSAVED_CONTENT || alertMessage) {
        // renderAlert({
        // 	onConfirm: dispatchRouteChange,
        // 	...alertMessage
        // });

        renderAlert({
            onConfirm: dispatchRouteChange,
            title: window.__intlRef.formatMessage({
                id: 'general.unsaved changes alert title'
            }),
            message: window.__intlRef.formatMessage({
                id: 'general.unsaved changes alert description'
            }),
            confirm: window.__intlRef.formatMessage({
                id: 'general.unsaved changes alert confirm'
            }),
            cancel: window.__intlRef.formatMessage({
                id: 'general.unsaved changes alert cancel'
            })
        });
    } else {
        dispatchRouteChange();
    }
};

const setRoute = (route, ...args) => performRouteChange('push', route, ...args);
const replaceRoute = (route, ...args) =>
    performRouteChange('replace', route, ...args);

/*
const replaceHistory = route => {
	//window.history.replaceState({}, null, route);
	//browserHistory.replace({}, null, route);
};*/

export { setRoute, replaceRoute /*, replaceHistory*/ };
