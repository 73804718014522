import React from 'react';
import Button from './elements/button';
import Text from './elements/text';
import Feed from './elements/feed';
import Form from './elements/form';
import Map from './elements/map';
import IFrame from './elements/iframe';
import Gallery from './elements/gallery';
import Menu from './elements/menu';
import Image from './elements/image';
import Logo from './elements/logo';
import Video from './elements/video';
import Row from './elements/row/row';
import SmartText from './elements/smart-text';
import CropResize from './crop/ResizeHandle';
// import useCropHeight from './crop/useCropHeight';
import Selectable from './selectable/Selectable';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import Draggable from './drag-and-drop/Draggable';
// import dragTracker from './drag-and-drop/dragTracker';
import createDragState from './drag-and-drop/dragState';
import dragAndDropInterface from './drag-and-drop/interface';

import EmptyTree from 'components/editor-v2/extend/drag-and-drop/drop-zone/DropZoneEmptyTree';
import Keybindings from 'components/editor-v2/extend/keybindings/Keybindings';
// import DragLayer from 'components/editor-v2/extend/drag-and-drop/drag-layer/DragLayer';
import ScrollTargets from 'components/editor-v2/extend/drag-and-drop/scroll-targets/ScrollTargets';
import UndoRedo from 'components/editor-v2/editor-interface/controls/undo-redo/UndoRedo';
import SaveOrPublish from 'components/editor-v2/editor-interface/controls/save-publish/SaveOrPublish';
import EditorOptions from 'components/editor-v2/editor-interface/options';
import BuildingBlocks from 'components/editor-v2/editor-interface/building-blocks';

export default (isLive = null, extraArgs = {}) => {
    const dragState = createDragState();
    
    return {
        dragState,
        renderAtRoot: rendererProps => {
            return (
                <React.Fragment>
                    <UndoRedo 
                        ContextConsumer={rendererProps.RendererContext.Consumer} 
                    />               

                    <EditorOptions
                        ContextConsumer={rendererProps.RendererContext.Consumer} 
                    />

                    <SaveOrPublish 
                        ContextConsumer={rendererProps.RendererContext.Consumer} 
                    />

                    <BuildingBlocks
                        dragState={dragState}
                        isBlogLayout={extraArgs.isBlogLayout}
                        ContextConsumer={rendererProps.RendererContext.Consumer}
                    />

                    <ScrollTargets />

                    <Keybindings rendererProps={rendererProps} />

                    {/* <DragLayer /> */}

                    <dragState.renderCollisionStack />

                    {(!rendererProps.rendererState.nodeMap || !rendererProps.rendererState.nodeMap.root) &&
                        <EmptyTree updateState={rendererProps.rendererState.updateState} />
                    }
                </React.Fragment>
            );
        },
        extendAll: {
            hocs: [Selectable, Draggable(dragAndDropInterface)],
            mapContextToProps: (props, state, node) => {
                const { hoveredNode, selectedNode, editableNode, device } = props.context;

                return shallowCompare({
                    isHovered: node.id === hoveredNode,
                    isSelected: node.id === selectedNode,
                    isEditable: node.id === editableNode,
                    suspend: props.context.suspend,
                    device,
                    dragState,
                    ResizeHandle: CropResize
                }, state);
            }
        },
        extend: {
            Row: Row(isLive),
            Button: Button(isLive),
            Text: Text(isLive),
            Menu: Menu(isLive),
            Map: Map(isLive),
            Form: Form(isLive),
            Feed: Feed(isLive),
            SmartText: SmartText(isLive),
            Video: Video(isLive),
            Logo: Logo(isLive),
            Image: Image(isLive),
            IFrame: IFrame(isLive),
            Gallery: Gallery(isLive),
        }
    }
};