// Has own file because we'll likely have some filtering logic to get only authors that can actually write blogs (as in we should exclude non-writers on sites from this list)
export const getAuthors = authors => {
    return authors.order.map(authorID => authors.lookup[authorID]);
};

export const resolveAuthor = ({ siteName, authors }) => userId => {
    const users = getAuthors(authors);

    const author = users.find(user => user._id === userId);

    if (!author) return siteName;

    return `${author.firstName} ${author.lastName}`;
};