import uuid from 'uuid';

export const createContainer = (children, overrides) => {
    const node = {
        id: 'root',
        component: 'Container',
        props: {
            fluid: true,
        },
        children,
        ...overrides
    };

    return node;
};

export const createColumn = (children, overrides) => {
    const node = {
        id: uuid(),
        component: 'Column',
        width: 4,
        props: {
            preset: 'Compact',
        },
        children,
        ...overrides
    };

    return node;
};

export const createRow = (children, overrides) => {
    const node = {
        id: uuid(),
        component: 'Row',
        props: {
            preset: 'Compact',
            columnDividerWidth: 16
        },
        children,
        ...overrides
    };

    return node;
};

export const createGrid = (children, overrides) => {
    const node = {
        id: uuid(),
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children,
        ...overrides
    };

    return node;
};