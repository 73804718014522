/*
	improvements:
	https://developer.mozilla.org/en-US/docs/Web/CSS/background-origin

*/
import './background.scss';

const getBackgroundImageStyling = (backgroundSettings, url) => {
    if (!url) return;

	const backgroundStyle = {};

    backgroundStyle.backgroundImage = `url(${url})`;

    let backgroundAlignment;

    if (typeof backgroundSettings.backgroundHorizontalAlignment !== 'undefined') {
        backgroundAlignment = `${backgroundSettings.backgroundHorizontalAlignment}%`;
    } else {
        backgroundAlignment = 'center';
    }

    if (typeof backgroundSettings.backgroundVerticalAlignment !== 'undefined') {
        backgroundAlignment = `${backgroundAlignment} ${backgroundSettings.backgroundVerticalAlignment}%`;
    } else {
        backgroundAlignment = backgroundAlignment + ' center';
    }

    backgroundStyle.backgroundPosition = backgroundAlignment;

    const backgroundSizeUnitY = backgroundSettings.backgroundHeightUnit || 'px';
    const backgroundSizeUnitX = backgroundSettings.backgroundWidthUnit || 'px';

    if (backgroundSettings.backgroundWidth && !backgroundSettings.backgroundHeight) {
        backgroundStyle.backgroundSize = `${backgroundSettings.backgroundWidth}${backgroundSizeUnitX}`;
    } else if (backgroundSettings.backgroundWidth && backgroundSettings.backgroundHeight) {
        backgroundStyle.backgroundSize = `${backgroundSettings.backgroundWidth}${backgroundSizeUnitX} ${backgroundSettings.backgroundHeight}${backgroundSizeUnitY}`;
    } else if (backgroundSettings.backgroundHeight) {
        backgroundStyle.backgroundSize = `auto ${backgroundSettings.backgroundHeight}${backgroundSizeUnitY}`;
    }

    if (backgroundSettings.backgroundCover || typeof backgroundSettings.backgroundCover === 'undefined') backgroundStyle.backgroundSize = 'cover';

    if (backgroundSettings.backgroundRepeatX) backgroundStyle.backgroundRepeat = 'repeat-x';
    if (backgroundSettings.backgroundRepeatY) backgroundStyle.backgroundRepeat = 'repeat-y';
    if (backgroundSettings.backgroundRepeatX && backgroundSettings.backgroundRepeatY) {
        backgroundStyle.backgroundRepeat = 'repeat';
    } else if (!backgroundSettings.backgroundRepeatX && !backgroundSettings.backgroundRepeatY) {
        backgroundStyle.backgroundRepeat = 'no-repeat';
    }

    if (backgroundSettings.backgroundFixed) {
        backgroundStyle.backgroundAttachment = 'fixed';
    }

    if (typeof backgroundSettings.backgroundOpacity !== 'undefined') {
        backgroundStyle.opacity = backgroundSettings.backgroundOpacity / 100;
    }

    // Prepends "--" because we are working with CSS variables here
    return Object.keys(backgroundStyle).reduce((acc, key) => {
        acc[`--${key}`] = backgroundStyle[key];

        return acc;
    }, {
        '--backgroundSize': 'cover',
        '--backgroundPosition': 'center center',
        '--backgroundAttachment': 'local',
        '--backgroundRepeat': 'no-repeat',
        '--opacity': '1'
    });
};

export default getBackgroundImageStyling;
