import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/sites';
import { Loader } from 'svift-ui';
import { FormattedMessage } from 'react-intl';

const mapStateToProps = state => {
	return {
		// siteFound: state.lol.memes.haha
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchSite: siteid => dispatch(actions.fetchSite(siteid))
	};
};

class Site extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: !!props.siteID
		};
	}

	componentDidMount() {
		if (this.state.loading) {
			this.props.fetchSite(this.props.siteID).then(() => {
				this.setState({
					loading: false
				});
			});
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<Loader size="big" style={{ fontSize: 20, marginTop: 80, opacity: 0.7 }}>
					<p style={{ fontSize: 32, marginTop: 0 }}><FormattedMessage id="general.loading" /></p>
				</Loader>
			);
		}

		return this.props.children;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Site);