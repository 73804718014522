const addParameterToURL = (url, params) => {    
    for (let key in params) {
        const isFirstParam = !url.split('?')[1];
        const prefix = isFirstParam ? '?' : '&';
        const param = params[key];

        url += `${prefix}${encodeURIComponent(key)}=${encodeURIComponent(param)}`;
    }

    return url;
}

module.exports = addParameterToURL;