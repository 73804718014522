/*
    The problem:

    if (
        action.payload &&
        action.payload.location &&
        action.payload.location.state &&
        action.payload.location.state.data &&
        action.payload.location.state.data[0] &&
        action.payload.location.state.data[0].isLogout
    ) {
        ...
    }

    The solution:

    const getLogoutAction = createSafeGet('payload.location.state.data.0.isLogout');

    if (getLogoutAction(action)) {
        ...
    }
*/


// Returns value if it exists (even if the value is false or null), otherwise it returns undefined
const createSafeGet = keyPath => target => {
    const pathAsArray = Array.isArray(keyPath) ? keyPath : keyPath.split('.');

    const result = pathAsArray.reduce((target, key) => {
        if (!target) return target;

        return target[key];
    }, target);

    return result;
};

export default createSafeGet;
