import React, { PureComponent } from 'react';
import { GridColumn, Segment, Grid, Button, Icon, FormField, FormCheckbox, Popup } from 'svift-ui';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import { StandaloneSlider } from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import { colorResolver } from 'components/preset/util';
import InfoIcon from 'components/InfoIcon';

class ColorPicker extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			colorHover: null
		};

		this.tooltip = tooltip.call(this);

		this.colorTooltips = {};

		Object.keys(props.colors).forEach(color => {
			this.colorTooltips[color] = tooltip.call(this);
		});

		this.onChange = this.onChange.bind(this);
	}

	onChange(key, value) {
		const newColor = {
			...this.props.value,
			[key]: value
		};

		if (value === null) {
			delete newColor[key];
		}

		this.props.onChange(newColor);
	}

	render() {
		const { title, colors, value = {}, option, onToggle, selectedOption, icons, intl, style } = this.props;

		const selected = option === selectedOption;
		const valueBrightness = value.brightness >= 0 ? '+' + Math.floor(value.brightness*2) + '%': '-' + Math.floor(Math.abs(value.brightness)*2) + '%' ;
		const valueSaturation = value.saturation >= 0 ? '+' + Math.floor(value.saturation*2) + '%': '-' + Math.floor(Math.abs(value.saturation)*2) + '%';
		const valueOpacity = Math.floor(value.opacity*100) +'%';

		return (
			<div className="option" style={{ display: 'inline-block' }}>
				<Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
					open={selected}
					content={
						<div className="picker-content" style={{ maxWidth: 196 }}>
							<p className="option-title" style={{ marginTop: -8, marginBottom: 24, textAlign: 'center' }}>
								<Icon name={icons[0]} /> {/* Probably fine - change if needed */}
								<FormattedMessage id={title || intl.formatMessage({ id: 'options.color title' })} /> <InfoIcon description={intl.formatMessage({ id: 'options.color option description' })} style={{ position: 'absolute', top: 14, right: 8 }} />
							</p>

							<Grid columns={4} style={{ paddingBottom: 8, marginBottom: 16 }} className="color-palette">
								{Object.keys(colors).map(color => {
									return (
										<GridColumn key={color}>
											<Popup
												trigger={
													<Segment
														ref={this.colorTooltips[color].setTarget}
														onClick={() => this.onChange('key', color)}
														circular
														onMouseEnter={() => this.setState({ colorHover: color })}
														onMouseLeave={() => this.setState({ colorHover: null })}
														style={{ backgroundColor: colors[color] }}
														className={value.key === color ? 'active-pick' : ''}
													/>
												}
												content={`${intl.formatMessage({ id: `options.color ${color} title` })} ${intl.formatMessage({ id: 'options.color title' }).toLowerCase()}`}
												position="top center"
											/>
											
										</GridColumn>
									);
								})}
							</Grid>

							<StandaloneSlider
								outsidePanel
								optIn
								optionalDisabled={typeof value.brightness === 'undefined'}
								onClear={() => this.onChange('brightness', null)}
								icons={['sn-droplet-contrast']}
								title =	{`${intl.formatMessage({ id: 'options.darken-lighten' })} (${typeof value.brightness === 'undefined'? '0' : valueBrightness })`}
								value={value.brightness || 1} // // RC-slider does not properly reset if the default value is 0, so we set it to 1 instead (wont have any visible impact)
								min={-50}
								max={50}
								onChange={d => this.onChange('brightness', d)}
							/>

							<StandaloneSlider
								outsidePanel
								optIn
								optionalDisabled={typeof value.saturation === 'undefined'}
								onClear={() => this.onChange('saturation', null)}
								icons={['sn-palette']}
								title =	{`${intl.formatMessage({ id: 'options.desaturate-saturate' })} (${typeof value.saturation === 'undefined'? '0' : valueSaturation})`}
								value={value.saturation || 1} // RC-slider does not properly reset if the default value is 0, so we set it to 1 instead (wont have any visible impact)
								min={-50}
								max={50}
								onChange={d => this.onChange('saturation', d)}
							/>

							<StandaloneSlider
								outsidePanel
								optIn
								optionalDisabled={typeof value.opacity === 'undefined'}
								onClear={() => this.onChange('opacity', null)}
								icons={['sn-eye']}
								title =	{`${intl.formatMessage({ id: 'options.opacity' })} (${typeof value.opacity === 'undefined'? '100%': valueOpacity}) `}
								value={(typeof value.opacity === 'undefined' ? 1 : value.opacity) * 100}
								min={0}
								max={100}
								onChange={d => this.onChange('opacity', d / 100)}
							/>

							<FormField style={{ paddingTop: 4 }}>
								<FormCheckbox
									checked={value.greyscale}
									label={intl.formatMessage({ id: 'options.greyscale' })}
									style={{ fontSize: 12 }}
									onChange={(e, data) => this.onChange('greyscale', data.checked)}
								/>
							</FormField>
						</div>
					}
					hover={this.state.hover}
					hoverContent={`${title || intl.formatMessage({ id: 'options.color title' })} (${intl.formatMessage({ id: `options.color ${value.key} title` })})`}
					// Old method for displaying color value: {value.key.substring(0, 1).toUpperCase() + value.key.substring(1) || 'No hover content specified'}
					style={{ zIndex: 999 }}
				/>

				<Button
					ref={this.tooltip.setTarget}
					size="tiny"
					icon
					circular
					style={Object.assign({ backgroundColor: colorResolver({ colors }, value)/*colors[value.key]*/ }, style)}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				>
					{icons.map((icon, index) => {
						if (index === 0) {
							return <Icon key={index} name={icon} />;
						}

						return (
							<Icon key={index} corner name={icon} />
						);
					})}
				</Button>
			</div>
		);
	}
}

ColorPicker.propTypes = {
	colors: PropTypes.object,
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func
};

export default injectIntl(ColorPicker);
