import React, { PureComponent } from 'react';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';

class Icon extends PureComponent {
    static mapContextToProps = (props, state) => {
		const { theme } = props.context;
	
        return shallowCompare({
			theme,
        }, state);
    }
    
    render() {
        return (
            <div style={{ padding: '30px' }}>
                Iconic
            </div>
        );
    }
}

export default Icon;