export default rendererMode => {
    return {
        onSubmit: (formObj, state, setState) => {
            console.log('admin form submit');
            // e.preventDefault();
    
            // const valid = formObj.fields.map(f => {
            //     return f.validate();
            // });
    
            // if (valid.every(entry => entry) && !state.submitted /* dont wanna submit twice */) {
            //     setState({
            //         submitted: true
            //     });
            // }
        }
    };
};