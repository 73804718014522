export default {
	// LOCATION
	// connected-react-router uses this action type to describe a location change;
	// we hijack it to make sure our state updates occur in the same update; this means
	// we never have to dispatch a secondary action in response to a route change - greatly reducing complexity in our components
	ROUTE_CHANGE: '@@router/LOCATION_CHANGE',
	SETUP: 'SETUP',

	SET_SYSTEM_SETTINGS: 'SET_SYSTEM_SETTINGS',

	/* USER */
	SET_USER_SETTINGS: 'SET_USER_SETTINGS',
	LOGOUT: 'LOGOUT',
	ABLE_TO_DELETE_USER_ACCOUNT: 'ABLE_TO_DELETE_USER_ACCOUNT',

	/* WIZARD */
	SET_WIZARD_STEP: 'SET_WIZARD_STEP',
	SET_SUBSCRIPTION_TYPES: 'SET_SUBSCRIPTION_TYPES',
	SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
	SET_COMPANY_INPUT_TEXT: 'SET_COMPANY_INPUT_TEXT',
	TOGGLE_AGREE_TERMS: 'TOGGLE_AGREE_TERMS',

	/* DOMAIN SEARCH */
	SET_AVAILABLE_TLDS: 'SET_AVAILABLE_TLDS',
	SET_CHECKED_DOMAINS: 'SET_CHECKED_DOMAINS',
	SET_CHECKED_SUBDOMAINS: 'SET_CHECKED_SUBDOMAINS',
	SET_PICKED_DOMAIN: 'SET_PICKED_DOMAIN',
	CLEAR_DOMAIN_SEARCH_RESULTS: 'CLEAR_DOMAIN_SEARCH_RESULTS',
	SET_AGREE_TRANSFER_TERMS: 'SET_AGREE_TRANSFER_TERMS',

	/* SITES */
	SET_SITES: 'SET_SITES',
	SET_SITE: 'SET_SITE',
	DELETE_SITE: 'DELETE_SITE',
	UPDATE_SITE: 'UPDATE_STE',
	//SET_THEMES: 'SET_THEMES',
	//SELECT_THEME: 'SELECTI_THEME',
	UPDATE_SITE_SETTIMGS: 'UPDATE_SITE_SETTIMGS',

	/* PAGES */
	CREATE_PAGE: 'CREATE_PAGE',
	UPDATE_PAGE: 'UPDATE_PAGE',
	DELETE_PAGE: 'DELETE_PAGE',
	UPDATE_PAGE_CONTENT: 'UPDATE_PAGE_CONTENT',
	SET_PAGES: 'SET_PAGES',

	/* BLOGS */
	CREATE_BLOG: 'CREATE_BLOG',
	UPDATE_BLOG: 'UPDATE_BLOG',
	DELETE_BLOG: 'DELETE_BLOG',
	SET_BLOGS: 'SET_BLOGS',
	UPDATE_BLOG_CONTENT: 'UPDATE_BLOG_CONTENT',
	SAVE_BLOG_CONTENT: 'SAVE_BLOG_CONTENT',
	PUBLISH_BLOG_CONTENT: 'PUBLISH_BLOG_CONTENT',
	// STAGE_BLOG_CONTENT: 'STAGE_BLOG_CONTENT',
	TOGGLE_BLOG_CONTENT_VIEW: 'TOGGLE_BLOG_CONTENT_VIEW',

	/* PAGES/CONTENT */
	SET_PAGES_CONTENT: 'SET_PAGES_CONTENT',
	SAVE_CONTENT: 'SAVE_CONTENT',
	PUBLISH_CONTENT: 'PUBLISH_CONTENT',
	STAGE_CONTENT: 'STAGE_CONTENT',
	TOGGLE_CONTENT_VIEW: 'TOGGLE_CONTENT_VIEW',

	/* THEMES */
	CREATE_THEME: 'CREATE_THEME',
	PUBLISH_THEME: 'PUBLISH_THEME',
	PUBLISH_SYSTEM_THEME: 'PUBLISH_SYSTEM_THEME',
	SAVE_THEME: 'SAVE_THEME',
	STAGE_THEME: 'STAGE_THEME',
	CLEAR_STAGED_THEME: 'CLEAR_STAGED_THEME',
	UPDATE_PRESET: 'UPDATE_PRESET',
	UPDATE_THEME_SETTING: 'UPDATE_THEME_SETTING',
	SET_THEMES: 'SET_THEMES',
	TOGGLE_THEME_VIEW: 'TOGGLE_THEME_VIEW',

	DELETE_THEME: 'DELETE_THEME',
	SET_GOOGLE_FONTS: 'SET_GOOGLE_FONTS',

	/* MODULES */
	SET_MODULES: 'SET_MODULES',
	SET_MODULES_LOADING: 'SET_MODULES_LOADING',

	/* LAYOUT */
	SET_LAYOUTS: 'SET_LAYOUTS',
	UPDATE_LAYOUT_CONTENT: 'UPDATE_LAYOUT_CONTENT',
	CREATE_LAYOUT: 'CREATE_LAYOUT',
	STAGE_LAYOUT: 'STAGE_LAYOUT',
	PUBLISH_LAYOUT: 'PUBLISH_LAYOUT',
	SAVE_LAYOUT: 'SAVE_LAYOUT',
	DELETE_LAYOUT: 'DELETE_LAYOUT',
	TOGGLE_LAYOUT_VIEW: 'TOGGLE_LAYOUT_VIEW',

	/* FORM */
	PUBLISH_FORM: 'PUBLISH_FORM',
	SAVE_FORM: 'SAVE_FORM',
	SET_FORM_EDIT_MODE: 'SET_FORM_EDIT_MODE',
	SET_FORMS: 'SET_FORMS',
	STAGE_FORM: 'STAGE_FORM',
	UPDATE_FORM_CONTENT: 'UPDATE_FORM_CONTENT',
	TOGGLE_FORM_VIEW: 'TOGGLE_FORM_VIEW',

	/* TOP NAVIGATION */
	TOGGLE_USER_MENU: 'TOGGLE_USER_MENU',
	TOGGLE_SIDE_MENU: 'TOGGLE_SIDE_MENU',
	TOGGLE_STEP_BY_STEP: 'TOGGLE_STEP_BY_STEP',

	/* DND */
	DND_SET_SNAPSHOT: 'DND_SET_SNAPSHOT',
	DND_CLEAR_SNAPSHOT: 'DND_CLEAR_SNAPSHOT',

	/* SIZE */
	SET_SIZE: 'SET_SIZE',

	/* FILES */
	SET_FILES: 'SET_FILES',
	SET_BUCKET: 'SET_BUCKET',
	TOGGLE_FILESITE_VIEW: 'TOGGLE_FILESITE_VIEW',
	FILES_SET_ACTIVE_FOLDER: 'FILES_SET_ACTIVE_FOLDER',

	/*FOLDERS*/
	SET_FOLDERS: 'SET_FOLDERS',
	SET_FOLDER_HOVERED: 'SET_FOLDER_HOVERED',
	CREATE_FOLDER: 'CREATE_FOLDER',
	TOGGLE_FOLDERS_LOADING: 'TOGGLE_FOLDERS_LOADING',

	/* NAVIGATION / MENUS */
	SET_NAVIGATIONS: 'SET_NAVIGATIONS',
	UPDATE_NAVIGATION_CONTENT: 'UPDATE_NAVIGATION_CONTENT',
	STAGE_NAVIGATION: 'STAGE_NAVIGATION',
	SAVE_NAVIGATION: 'SAVE_NAVIGATION',

	/* EDITOR */
	SET_EDITOR_CONTEXT: 'SET_EDITOR_CONTEXT'
};