import React from 'react';
import NotificationGrid from './components/NotificationGrid';
import { connect } from 'react-redux';
import { deleteNotificationAction } from './actions';

class Notifications extends React.Component {
    render() {
        return (
            <NotificationGrid
                notifications={this.props.notifications}
                onClick={this.props.deleteNotification}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deleteNotification: notificationId => dispatch(deleteNotificationAction(notificationId))  
});

const mapStateToProps = (state, ownProps) => ({
    notifications: state.notifications.notifications
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
