import React from 'react';
import {
    Grid,
    GridColumn,
    Image,
    Header,
    Icon,
    Label,
    Divider
} from 'svift-ui';
import Modules from '../../Modules';
import { FormattedMessage } from 'react-intl';

const circularBlogAppTileScreenshot = "https://storage.googleapis.com/svift-net-master-bucket/assets/img/modules/blog/blog-app-tile-circle.png";
const blogShortcutScreenshot = "https://storage.googleapis.com/svift-net-master-bucket/assets/img/modules/blog/blog-app-tile-shortcut-section.png";
const feedElementScreenshot = "https://storage.googleapis.com/svift-net-master-bucket/assets/img/modules/blog/feed-element-circle.png";

export default {
    blog: {
        id: 'blog',
        icon: 'sn-blog',
        color: '#0D4554',
        intl: 'blog.app title',
        descriptionIntl: 'modules.blog description',
        actionNameIntl: 'modules.activate button',
        actionDisabled: false,
        renderContent: () => {
            return (
                <Grid padded>
                    <GridColumn computer={16}>
                        <Label basic size="small" className="primary"><Icon name="sn-subscription-general" />
                          <FormattedMessage id="modules.module title" />
                        </Label>
                        <Label basic size="small" className="info">
                            <FormattedMessage id="general.free title" />
                        </Label>
                        <Header as="h1" style={{ marginTop: 8, marginBottom: 24 }}>
                            <Icon name="sn-blog" />
                            <FormattedMessage id="blog.app title" />
                        </Header>
                        <Divider style={{ margin: 0 }} />
                    </GridColumn>
                    <GridColumn computer={10} tablet={16} mobile={16}>
                        <p><FormattedMessage id="modules.blog app description" /></p>
                        <Divider />
                        
                        <p style={{ fontWeight: 'bold' }}><FormattedMessage id="modules.blog app after activation description" />:</p>
                        <Image
                            src={blogShortcutScreenshot}
                            style={{ margin: 'auto', width: '100%', maxWidth: 400 }}
                        />
                        <p><FormattedMessage id="modules.blog app top shortcut description" /></p>
                    </GridColumn>
                    <GridColumn computer={6} tablet={16} mobile={16}>
                        <Image
                            src={circularBlogAppTileScreenshot}
                            style={{ margin: 'auto', marginBottom: 8, width: '100%', maxWidth: 400 }}
                        />
                        <p className="small-text" style={{ textAlign: 'center' }}>
                            <FormattedMessage id="modules.blog app dashboard shortcut description" />
                        </p>
                    </GridColumn>

                    <GridColumn computer={16}>
                        <Divider />
                    </GridColumn>
                    <GridColumn computer={6} tablet={16} mobile={16}>
                        <Image
                            src={feedElementScreenshot}
                            style={{ margin: 'auto', width: '100%', maxWidth: 400 }}
                        />
                    </GridColumn>
                    <GridColumn computer={10} tablet={16} mobile={16}>
                        <Header as="h2"><FormattedMessage id="modules.new element label" />: <FormattedMessage id="elements.feed title" /></Header>
                        <p><FormattedMessage id="modules.feed element description" /></p>
                    </GridColumn>
                    
                    <GridColumn computer={16}>
                        <Divider />
                        <Header as="h3" textAlign="center"><FormattedMessage id="modules.extra stuff title" /></Header>
                    </GridColumn>
                    
                    <GridColumn computer={8}>
                        <Icon circular name="user" style={{ margin: 0, marginLeft: 8, float: 'right', fontSize: 32, opacity: 0.6 }} />
                        <FormattedMessage id="modules.new user role blogger description" />
                    </GridColumn>
                    <GridColumn computer={8}>
                        <Icon circular name="sn-web" style={{ margin: 0, marginLeft: 8, float: 'right', fontSize: 32, opacity: 0.6 }} />
                        <FormattedMessage id="modules.blog layout description" />
                    </GridColumn>
                </Grid>
            )
        }
    },
    future: {
        id: 'future',
        icon: 'flask',
        color: '#AAA',
        intl: 'modules.future title',
        descriptionIntl: 'modules.future description',
        actionDisabled: true,
        actionNameIntl: 'modules.future action',
        amount: '0',
        currency: 'dkk'
    }
    // shop: {
    //     id: 'shop',
    //     icon: 'sn-cart5',
    //     color: '#36828E',
    //     intl: 'shop.app title',
    //     descriptionIntl: 'modules.shop description',
    //     actionDisabled: true,
    //     actionNameIntl: 'modules.coming in version',
    //     amount: '0',
    //     currency: 'dkk'
    // },
    // booking: {
    //     id: 'booking',
    //     icon: 'sn-calendar4',
    //     color: '#557431',
    //     intl: 'booking.app title',
    //     descriptionIntl: 'modules.booking description',
    //     actionDisabled: true,
    //     actionNameIntl: 'modules.coming in version',
    //     amount: '0',
    //     currency: 'dkk'
    // }  
};