import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
// import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import ImageFader from 'svift-library/client/renderer/nodes/components/library/__components/image-helpers/ImageFader';
// import ImageSizerHOC from 'svift-library/client/renderer/nodes/components/library/__components/image-helpers/imageSizerHOC';
import { withRouter } from 'react-router';
import SmartImage from 'svift-library/client/image';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import css from './logo.scss';

const getImageAttributes = file => {
	const attributes = {};

	if (!file) return attributes;

	const {
		description,
		credit,
		name
	} = file.meta;

	attributes.title = name.substring(0, name.lastIndexOf('.'));
	attributes.alt = attributes.title;

	if (description) attributes.alt += ` - ${description}`;
	if (credit) attributes.alt += ` | ${credit}`;

	return attributes;
}

class SmartLogo extends PureComponent {
	static mapContextToProps = (props, state) => {
		const {
			theme,
			logo,
			logoHorizontal,
			files,
			pages,
			extend,
			frontPage
		} = props.context;
		
		return shallowCompare({
			theme,
			logo,
			logoHorizontal,
			frontPage,
			files,
			pages,
			computeLink: extend.Logo && extend.Logo.computeLink,
			onNavigate: extend.Logo && extend.Logo.onNavigate
		}, state);
	}

	constructor(props) {
		super(props);

		this.state = {
			srcNotFound: false
		};

		// this.onClick = this.onClick.bind(this);
	}

	// onClick() {
	// 	// onRedirectClick()
	// 	// redirect(this.props.history, '');
	// }

	renderLogoImage() {
        const { node } = this.props;
        
        const file = this.props.files.lookup[node.props.type === 'horizontalLogo' ? this.props.logoHorizontal : this.props.logo];
		let defaultSrc;
		
		// console.log(this.props, 'props lol');
        
        switch (node.props.type) {
			case 'favicon':
				defaultSrc = 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/favicon.png';
				
				break;
			case 'logo':
				defaultSrc = 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.png';
			
				break;
			case 'horizontalLogo':
				defaultSrc = 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/horizontal-logo.png';

                break;
                
            default:
				defaultSrc = 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg';
        }

		const imageAttributes = getImageAttributes(file);

		const alignmentStyles = {
			justifyContent: node.props.alignment
		};
		
		const style = {
			...this.props.computedStyling,
			position: 'relative',
			width: '100%',
			maxWidth: '100%',
			objectFit: node.props.objectFit || 'cover',
			cursor: 'pointer'
		};

		if (node.props.custom) {
			let objectXY = ['50%', '50%']; // centered;

			if (!isNaN(node.props.objectPositionX)) {
				objectXY[0] = node.props.objectPositionX + '%';
			} 
			if (!isNaN(node.props.objectPositionY)) {
				objectXY[1] = node.props.objectPositionY + '%';
			}

			style.objectPosition = objectXY.join(' ');
	
			if (node.props.height) style.height = node.props.height + (node.props.heightType || 'px');
			if (node.props.width) style.width = node.props.width + (node.props.widthType || 'px');
        }
		
		const computedLink = this.props.computeLink && this.props.computeLink(this.props);

		if (computedLink) {
			return (
				<div className={css['img-wrapper']} style={alignmentStyles}>
					<a href={computedLink.url} title={computedLink.title} {...computedLink.anchorAttributes} style={{ width: '100%' }}>
						<SmartImage 
							file={file}
							src={defaultSrc}
							imageProps={node.props}
							style={style}
							onClick={e => {
								if (event.ctrlKey || event.metaKey || event.shiftKey) {
									return;
								}
		
								e.preventDefault();
							
								this.props.onNavigate(computedLink)
							}}
						/>
					</a>
				</div>
			)
		}

        return (
			<div className={css['img-wrapper']} style={alignmentStyles}>
				<SmartImage 
                    file={file}
                    src={defaultSrc}
					imageProps={node.props}
					style={style}
					onClick={this.props.onNavigate}
				/>
			</div>
		);

		return (
			<React.Fragment>
				<img
					ref={ref => {
						if (this.props.setImageSizerRef) this.props.setImageSizerRef.current = ref;
						if (this.props.cropProps && this.props.cropProps.ref) this.props.cropProps.ref.current = ref;
					}}
					{...imageAttributes}
					onClick={this.onClick}
					draggable="false"
					style={style}
					src={src || defaultSrc}
					onLoad={e => {
						this.setState({
							imageLoadedSuccesfully: true
						});
					}}
					onError={() => {
						this.setState({
							srcNotFound: true
						});
					}}
				/>

				<ImageFader 
					file={file}
					src={src}
					imageLoadedSuccesfully={this.state.imageLoadedSuccesfully}
				/>
			</React.Fragment>
		);
	}

	render() {
		return this.renderLogoImage();
	}
}

export default withRouter(SmartLogo); /*ImageSizerHOC(SmartLogo, props => {
	const files = props.files;

	let fileId;

	if (props.node.props.type === 'logo') {
		fileId = props.logo;
	} else {
		fileId = props.logoHorizontal;
	}

	return files.lookup[fileId];
}, props => props.node.props)));
*/
