import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import shallowCompare, { deriveAndCompare } from 'svift-library/client/renderer/nodes/shallowCompare';
import WithBackground from 'svift-library/client/image/WithBackground';
import css from './column.scss';
import VideoBackground from 'svift-library/client/renderer/nodes/components/library/__components/background-video';

const widthByDevice = {
	tablet: 16,
	phone: 16
};

const getWidth = props => {
	const { node, columns, isWrapped } = props;

	// to-do: when doing breakpoints, remember to make node.width take presedence if defiend in breakpoints
	const columnsUsedByNode = widthByDevice[props.device] || node.width;

	// console.log(props.device, 'device column');

	if (isWrapped) return '100%';

	let widthPercentage;
	
	if (props.rowDividers && props.rowDividers.dividers > 0) {
		// const columnCountInRow = (props.rowDividers.lastColumn - props.rowDividers.firstColumn) + 1;
		const pixelsTakenByDividers = props.dividerWidth * props.rowDividers.dividers;
		const proportionedSizeOffset = (pixelsTakenByDividers / 16 /* columns in a row */) * columnsUsedByNode;
		// const pixelsTakenByDividers = proportionateSpaceTakenByColumns * props.columns;

		// console.log(proportionedSizeOffset, pixelsTakenByDividers, props.rowDividers, props.dividerWidth, columnsUsedByNode, 'lol')
		
		// widthPercentage = `calc(${(columnsUsedByNode / columns) * 100}% - ${(pixelsTakenByDividers) / columnCountInRow}px)`;
		widthPercentage = `calc(${(columnsUsedByNode / columns) * 100}% - ${proportionedSizeOffset}px)`;
	} else {
		widthPercentage = `${(columnsUsedByNode / columns) * 100}%`;
	}

	return widthPercentage;
};

class EditorColumn extends PureComponent {
    static mapContextToProps = (props, state) => {
		const { theme, files, device } = props.context;

		return shallowCompare({
			theme,
			device,
			files,
			rowDividers: deriveAndCompare.object(() => props.rowDividers, state.rowDividers),
			dividerWidth: props.dividerWidth
		}, state);
    }

    getStyling = () => {
		const { isWrapped, computedStyling, cropStyling, node } = this.props;

		const styling = {
			width: getWidth(this.props),
			flexDirection: 'column', // only becomes relevant when crop sets "display: flex;"
			...computedStyling,
			...cropStyling
		};

		if (this.props.cropAlignment) styling.justifyContent = this.props.cropAlignment;

		if (!isWrapped && node.props.floated) {
			styling.marginLeft = 'auto';
		}

        return styling;
    }

	render() {
		const { node, files } = this.props;

		const backgroundFile = this.props.files.lookup[node.props.backgroundImage]; // resolveFile(this.props.files, node.props.backgroundImage); 
		
		return (
			<WithBackground
				nodeProps={node.props}
				file={backgroundFile}
				render={(backgroundStyles, imageRef) => {
					let className = backgroundStyles ? 'editor-background-image' : '';

					// if (this.props.isWrapped) className = className + ` ${css['collapsing-margin-fix']}`;

					return (
						<div 
							data-type="column" 
							className={`${css['column']} ${className}`}
							style={{ ...this.getStyling(), ...backgroundStyles }} 
							ref={ref => {
								if (imageRef) imageRef.current = ref;
								if (this.props.cropRef) this.props.cropRef.current = ref;
							}}
						>
							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}

							<VideoBackground {...this.props} />
							
							{
								node.children.map(childID => {
									return (
										<Node
											freezeDragPath={this.props.freezeDragPath}
											key={childID}
											nodeID={childID}
											ContextConsumer={this.props.ContextConsumer}
											getPath={this.props.getPath}
										/>
									);
								})
							}		
							
							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}
						</div>
					);
				}}
			/>
		);
	}
}

export { getWidth };

// export default Styling(Crop(EditorColumn, false));
export default Crop(EditorColumn, false);