const getType = value => {
	if (Array.isArray(value)) return 'array';
	if (typeof value === 'object' && value !== null) return 'object';

	return 'primitive';
}

// Doesn't have to be perfect, just has to produce a unique and persistent identifier for the update path for whatever we are updating
const computeDiffIdentifier = (source, copy, changes = {}) => {
	if (typeof source === 'undefined') return 'source undefined';
	if (typeof copy === 'undefined') return 'copy undefined';

	const copyType = getType(copy);
	const sourceType = getType(source);

	if (copyType !== sourceType) return copyType;
	
	if (sourceType === 'object') {
		const sourceKeys = Object.keys(source);
	
		const changedKeys = sourceKeys.filter(sourceKey => {
			const a = source[sourceKey];
			const b = copy[sourceKey];
	
			return a !== b;
		});
	
		changedKeys.forEach(changedKey => {
			changes = {
				...changes,
				[changedKey]: computeDiffIdentifier(source[changedKey], copy[changedKey], {})
			};
		});
	} else if (sourceType === 'array') {
		const longestArray = Math.max(source.length, copy.length);
		const changedIndexes = [];

		for (let i = 0; i < longestArray; i++) {
			if (copy[i] !== source[i]) changedIndexes.push(i);
		}

		changedIndexes.forEach(changedIndex => {
			// const id = `__arrayIndex${changedIndex}`; 

			changes = {
				...changes,
				[changedIndex]: computeDiffIdentifier(source[changedIndex], copy[changedIndex], {})
			};
		});
	}

    return changes;
}

/*
	1) First pass: 
		Check if array or object, if array then store changes[changedIndex]

		const testDataPrior =  [
			{
				props: {
					text: 'not changed'
				}
			}
		]

		const testData = [
			{
				props: {
					text: 'changed'
				}
			}
		]

		{
			__arrayIndex0: {

			}
		}

		changes: {
			__arrayIndex0: {
				props: {
					text: 'changed'
				}
			}
		}

*/

let lockedDictionary = new Set();

export const clearLocks = () => lockedDictionary = new Set();

export const lock = (id, duration = 3000) => {
	lockedDictionary.add(id);

	setTimeout(() => lockedDictionary.delete(id), duration)
};

export const isLocked = id => lockedDictionary.has(id);

export default computeDiffIdentifier;