exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".row__divider--m6NGd{border-radius:4px;background:repeating-linear-gradient(-55deg, rgba(95,69,117,0.15), rgba(95,69,117,0.05) 4px, rgba(247,243,240,0.15) 4px, rgba(247,243,240,0.05) 8px);box-shadow:inset 0 0 4px 0 rgba(247,243,240,0.3),inset 0 0 2px 0 rgba(95,69,117,0.4)}\n", ""]);

// exports
exports.locals = {
	"divider": "row__divider--m6NGd"
};