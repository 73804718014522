import React from 'react';
import {
	Message,
	Icon,
	Header,
	Button
} from 'svift-ui';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import { setRoute } from 'utils/routing';
import { FormattedMessage } from 'react-intl';

export default props => {
	const { siteid } = props.match.params;

	return (
		<AppContentSection>
			{/* TO DO: revisit aftar domain order retry */}
			<Message className="error" style={{ textAlign: "center", paddingTop: 32, paddingBottom: 40, maxWidth: 1200, margin: 'auto' }}>
				<Icon name="sn-warning" className="info-color" size="huge" style={{ marginBottom: 16, marginRight: 0, opacity: 0.6 }} />
				<Header as="h1">
					<FormattedMessage id="activation.order failed title" />
				</Header>
				<p style={{ marginBottom: 32, textAlign: 'left', maxWidth: 520, marginLeft: 'auto', marginRight: 'auto' }}>
					<FormattedMessage id="activation.order failed description" />
				</p>
				<Header as="h2" style={{ marginBottom: 24 }}>
					<FormattedMessage id="activation.order failed sub title" />
				</Header>
				<p style={{ marginBottom: 32, textAlign: 'left', maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }}>
					<FormattedMessage id="activation.order failed support description" />
				</p>
				<Button
					onClick={() => setRoute(`/dashboard/${siteid}`)}
					primary
					size="big"
					style={{ marginBottom: 24 }}
				>
					<Icon name="sn-logo" style={{ fontSize: 24, float: 'left' }} />
					<FormattedMessage id="activation.order failed go to dashboard" />
				</Button>
				{/* <Button size="big" style={{ marginBottom: 24, marginLeft: 16 }}><Icon name="sn-info" style={{ fontSize: 24, float: 'left' }} /> Contact support</Button> */}
			</Message>
		</AppContentSection>
	);
};