import { useRef } from 'react';

/* 
    History helper is a utility for supressing pushes to history until an interaction is complete

    The most obvious example is typing into an input field; you don't want to push every single keystroke to the history state, you only want the final value, skipping the rest

    This can be pretty cumbersome to do manually each time, so we use this utility to assist with those kind of interactions
*/

// EXAMPLE:
// const historyHelper = useHistoryHelper(
//     props.getState, // function that gets latest state
//     context => context.nodeMap[column.current.id].width, // function that gets the value being updated in the interaction
//     value => context => { // function that returns a function that updates the state (historyhelper will call this one when appropriate)
//         return {
//             ...context,
//             nodeMap: {
//                 ...context.nodeMap,
//                 [column.current.id]: {
//                     ...context.nodeMap[column.current.id],
//                     width: value
//                 }
//             }
//         };
//     },
//     'resize horizontal' // the name of the history action
// ); 
// "onValueChange" historyHelper(value);
// "onInteractionComplete" historyHelper.commit();

export const createHistoryHelper = (getContext, getValue, update, undoDescription) => {
    const { updateState } = getContext();
    // let currentValue;
    let initialContext;
    let initialValue;
    let timeout;

    const historyHelper = nextValue => {
        if (typeof initialValue === 'undefined') {
            initialContext = getContext();
            initialValue = getValue(initialContext);
        }

        // currentValue = nextValue;

        // if (!timeout && frequency !== null) {
        //     timeout = setTimeout(() => {
        //         if (nextValue !== currentValue) {
        //             update(currentValue, true);
        //         }

        //         timeout = null;
        //     }, frequency);
        // }

        updateState(update(nextValue), 'skip');
    };

    historyHelper.commit = () => { 
        const endValue = getValue(getContext());

        // If initial context isn't defined, it means we didn't actually make any state changes before calling commit (making us unable to commit anything at all)
        if (initialContext && (initialValue !== endValue)) {
            /* 
                Have to "commit" the initialState to make sure it goes into the history - has to happen before pushing the end value of the interaction

                Happens synchronously so it doesn't lead to multiple renders
            */
            updateState(context => {
                return {
                    ...context,
                    nodeMap: initialContext.nodeMap
                }
            }, 'skip'); 
            updateState(update(endValue), undoDescription);
        }
        
        initialContext = undefined;
        initialValue = undefined;
        clearTimeout(timeout);
    };

    return historyHelper;
};

/*
    abc 0 // current

    abc 2 3 4 // skipped

    abc 5 // next

    abc 6 7 8 // skipped

    // commit

    history = 0 5 8
*/

export const useHistoryHelper = (...args) => {
    const ref = useRef(null);

    const getHistoryHelper = () => {
        let historyHelper = ref.current;

        if (historyHelper !== null) {
            return historyHelper;
        }

        historyHelper = createHistoryHelper(...args);

        ref.current = historyHelper;

        return historyHelper;
    }

    return getHistoryHelper();
};