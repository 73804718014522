import constants from 'store/constants';
import createApi from 'api';
import { setRoute } from 'utils/routing';
import sites from 'store/actions/sites';

export default {
	stageNavigation: ({ navigation }) => (dispatch, getState) => {
		dispatch({
			type: constants.STAGE_NAVIGATION,
			data: navigation
		});
	},

	toggleView: (on) => {
		return {
			type: constants.TOGGLE_NAVIGATION_VIEW,
			data: on
		};
	},

	createNavigation: (siteID, newNavigation) => async (dispatch, getState) => {
		const api = createApi();

		const createdNavigation = await api.navigation.post({ siteID, navigation: newNavigation });

		//lazy way

		await sites.fetchSite(siteID)(dispatch, getState);

		setRoute(`/dashboard/${siteID}/design/navigation/${createdNavigation._id}`);
	},

	deleteNavigation: (siteID, navID) => async (dispatch, getState) => {
		const api = createApi();

		await api.navigation.remove({ siteID, navID });

		// const state = getState();

		// const site = state.sites.sites[siteID];
		// const navigations = state.sites.navigation.navigations;

		// const { navigations: navIds } = site;

		// setRoute(`/dashboard/${siteID}/design/navigation`);

		// const defaultPageNavigation = navIds.find(id => navigations[id].type === 'page' && navigations[id].default);

		setRoute(`/dashboard/${siteID}/design/navigation`, { delete: constants.DELETE_NAVIGATION });

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	updateNavigation: ({ siteID, navID, newNavigation }) => async (dispatch, getState) => {
		const api = createApi();

		await api.navigation.update({ siteID, navID, navigation: newNavigation });

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	setNavigations: data => ({
		type: constants.SET_NAVIGATIONS,
		data
	}),

	updateNavigationContent: ({ newNavigation }) => ({
		type: constants.UPDATE_NAVIGATION_CONTENT,
		data: newNavigation
	}),

	save: (siteid, navid) => async (dispatch, getState) => {
		const api = createApi();
		const entries = getState().sites.navigation.stagedNavigation;

		await api.navigation.save({ siteID: siteid, navID: navid, entries });

		dispatch({
			type: constants.SAVE_NAVIGATION,
			data: {
				entries,
				siteid,
				navid
			}
		});
	}
};
