module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Kontakt os',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 9,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Form',
                                props: {
                                    selectedForm: null
                                }
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 7,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Hjælp besøgende på din hjemmeside med at komme i kontakt med dig ude i den virkelige verden. Tlf.nr., adresse og måske kort, der viser vej.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Map',
                                props: {
                                    style: {},
                                    preset: 'Primary',
                                    address: 'KBH'
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
