import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	layoutContent: {},
	layouts: {},
	view: 'draft',
	params: {
		layoutid: null,
		siteid: null
	},
	stagedLayout: null,
	stagedFrom: null
});

const defaultState = getDefaultState();

const layoutsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.CREATE_LAYOUT: {
			const { newLayout } = action.data;

			const newState = {
				...state,
				layoutContent: {
					...state.layoutContent,
					[newLayout._id]: newLayout.content
				},
				layouts: {
					...state.layouts,
					[newLayout._id]: {
						...newLayout,
						content: newLayout._id
					}
				}
			};

			return newState;
		}

		case constants.SET_MODULES: {
			const { defaultBlogLayout } = action.data;

			if (!defaultBlogLayout) return state;

			const newState = {
				...state,
				layoutContent: {
					...state.layoutContent,
					[defaultBlogLayout._id]: defaultBlogLayout.content
				},
				layouts: {
					...state.layouts,
					[defaultBlogLayout._id]: {
						...defaultBlogLayout,
						content: defaultBlogLayout._id
					}
				}
			};

			return newState;
		}

		case constants.TOGGLE_LAYOUT_VIEW: {
			return {
				...state,
				view: action.data
			};
		}

		case constants.SET_LAYOUTS: {
			const { layouts, layoutContent } = action.data;

			const newState = {
				...state,
				layouts: {
					...state.layouts,
					...layouts
				},
				layoutContent: {
					...state.layoutContent,
					...layoutContent
				}
			};

			return newState;
		}

		case constants.UPDATE_LAYOUT_CONTENT: {
			const { /*layoutID, */tree } = action.data;

			const newState = {
				...state,
				stagedLayout: {
					...state.stagedLayout,
					tree
				},
			};

			return newState;
		}

		case constants.STAGE_LAYOUT: {
			return {
				...state,
				stagedFrom: action.data,
				stagedLayout: action.data
			};
		}

		case constants.PUBLISH_LAYOUT: {
			const { draft, layoutid } = action.data;

			return {
				...state,
				layoutContent: {
					...state.layoutContent,
					[layoutid]: {
						...state.layoutContent[layoutid],
						draft,
						live: draft
					}
				},
				stagedFrom: draft,
				stagedLayout: draft
			};
		}

		case constants.SAVE_LAYOUT: {
			const { draft, layoutid } = action.data;

			return {
				...state,
				layoutContent: {
					...state.layoutContent,
					[layoutid]: {
						...state.layoutContent[layoutid],
						draft
					}
				},
				stagedFrom: draft,
				stagedLayout: draft
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/design/layouts/:layoutid?') || { layoutid: null, siteid: null };

			let stagedLayout = state.stagedLayout;

			if (params.layoutid !== state.params.layoutid || params.siteid !== state.params.siteid) {
				stagedLayout = null;
			}

			return {
				...state,
				stagedLayout,
				stagedFrom: stagedLayout,
				view: 'draft',
				params: {
					layoutid: params.layoutid,
					siteid: params.siteid
				}
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default layoutsReducer;
