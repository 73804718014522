import mime from 'mime-types';

const sizeToReadable = sizeInBytes => {
	if (sizeInBytes < 1000) {
		return `${Math.floor(sizeInBytes)} B`;
	} else if (sizeInBytes <= 1000 * 1000) {
		return `${Math.floor(sizeInBytes / 1000)} KB`;
	}
	return `${(Math.floor(sizeInBytes / 1000)) / 1000} MB`;
};

const createUrl = ({ bucket, name }) => `https://storage.googleapis.com/${bucket}/${name}`;

const getFileExtByMimeType = mimeType => {
	return mime.extension(mimeType);
}

const getIconByMimeType = mimeType => {
	const ext = mime.extension(mimeType);

	switch (ext) {
		case 'png':
		case 'jpg':
		case 'jpeg':
		case 'tif':
		case 'tiff':
		case 'svg':
		case 'gif':
		case 'bmp':
			return 'file image outline';
		case 'mp4':
		case 'avi':
		case 'mpg':
		case 'mpeg':
		case 'mkv':
		case 'wmw':
			return 'file video outline';
		case 'pdf':
			return 'file pdf outline';
		case 'doc':
		case 'docx':
			return 'file word outline';
		case 'xlr':
		case 'xls':
		case 'xlsx':
		case 'csv':
			return 'file excel outline';
		case 'pwt':
			return 'file powerpoint outline';
		case 'txt':
		//case 'text':
			return 'file text outline';
		case 'mp3':
		case 'wma':
		case 'wav':
			return 'file audio outline';
		default:
			return 'file outline';
	}
};

const foldersToMenuTree = (folders, /* targetParentPage = null*/) => {
	//const relevantFolders = folders.filter(folder=> folder.parentPage === targetParentPage);

	const formatted = folders.map(folder => ({
		text: folder.folder,
		data: folder,
		items: foldersToMenuTree(folder.folders)
	}));

	return formatted;
};

export {
	sizeToReadable,
	createUrl,
	getIconByMimeType,
	getFileExtByMimeType,
	foldersToMenuTree
};
