import React, { Component } from 'react';
import { Icon, Button } from 'svift-ui';
import { ChromePicker } from 'react-color';
import tinycolor from 'tinycolor2';

class ColorSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.sketchPicker = this.sketchPicker.bind(this);
	}

	sketchPicker() {
		if (this.state.open) {
			return (
				<div className="svift-color-picker">
					<div
						className="cover"
						onClick={e => this.setState({ open: !this.state.open })}
					/>

					<ChromePicker
						disableAlpha={true}
						color={this.props.data}
						onChange={(color, event) => {
							const data = { value: color.hex };
							this.props.onChange(event, data);
						}}
					/>
				</div>
			);
		}
		return null;
	}

	render() {
		const { data, name } = this.props;

		return (
			<div style={{ padding: '4px 0', width: '100%' }}>
				<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
					<Button
						circular
						icon
						className="color-item-frame"
						style={{ backgroundColor: data }}
						onClick={() => this.setState({ open: !this.state.open })}
					>
						<Icon
							name="sn-droplet2"
							style={{
								color: tinycolor(data).isDark() ? tinycolor(data).lighten(40) : tinycolor(data).darken(40)
							}} />
					</Button>
					{this.sketchPicker()}
				</div>
				<div
					style={{ marginLeft: 4, display: 'inline-block', verticalAlign: 'middle' }}
					onClick={() => this.setState({ open: !this.state.open })}
				>
					<p style={{ marginBottom: 0, fontSize: 14, color: tinycolor(data).isDark() ? data : tinycolor(data).darken(30) }}>
						<span style={{ fontWeight: 'bold' }}>{name}</span>
					</p>
					<p style={{ textTransform: 'uppercase' }} className="hex-code">{data}</p>
				</div>
			</div>
		);
	}
}

export default ColorSelector;
