import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';
import WithBackground from 'svift-library/client/image/WithBackground';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import VideoBackground from 'svift-library/client/renderer/nodes/components/library/__components/background-video';
class LayoutContainer extends PureComponent {
    static mapContextToProps = (props, state) => {
        const { files, theme } = props.context;

        return shallowCompare(
            {
                files,
                theme
            },
            state
        );
    };

    getStyling = () => {
        const styling = {
            position: 'relative',
            width: '100%',
            alignSelf: 'flex-start',
            ...this.props.computedStyling
        };

        if (!this.props.isWrapped) {
            if (this.props.node.props.maxWidth) {
                // Have to have this combination of maxWidth and width; if we only set max width then content isn't guaranteed to be whatever width is specified
                styling.width =
                    this.props.node.props.maxWidth +
                        this.props.node.props.widthType || 'px';
                styling.maxWidth = '100%';
            }
        }

        return styling;
    };

    render() {
        const { node } = this.props;

        const file = this.props.files.lookup[node.props.backgroundImage];

        return (
            <WithBackground
                nodeProps={node.props}
                file={file}
                render={(backgroundStyles, imageRef) => {
                    let className = ''; //css['grid'];

                    if (backgroundStyles) className = 'editor-background-image';

                    return (
                        <div
                            data-type="layout-container"
                            className={className}
                            ref={imageRef}
                            style={{
                                ...backgroundStyles,
                                ...this.getStyling()
                            }}
                        >
                            <VideoBackground {...this.props} />
                            
                            {node.children.map(childID => {
                                return (
                                    <Node
                                        key={childID}
                                        nodeID={childID}
                                        ContextConsumer={
                                            this.props.ContextConsumer
                                        }
                                        freezeDragPath={
                                            this.props.freezeDragPath
                                        }
                                        getPath={this.props.getPath}
                                    />
                                );
                            })}
                        </div>
                    );
                }}
            />
        );
    }
}

export default LayoutContainer;
