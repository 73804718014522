const resolveElementIcon = (component, type) => {
    switch (component && component.toLowerCase()) {
        case 'grid': {
            return 'sn-grid-2';
        }

        case 'row': {
            return 'sn-columns';
        }

        case 'column': {
            return 'sn-marquee';
        }
        
        case 'image': {
            return 'sn-image';
        }
        
        case 'text': {
            return 'sn-typography';
        }
        
        case 'video': {
            return 'sn-film4';
        }
        
        case 'button': {
            return 'sn-pointer';
        }
        
        case 'feed': {
            return 'sn-podcast2';
        }
        
        case 'map': {
            return 'sn-location';
        }
        
        case 'menu': {
            return 'sn-direction';
        }
        
        case 'form': {
            return 'send outline';
        }
        
        case 'forminput': 
        case 'formtextarea': {
            return 'sn-pencil7';
        }
        
        case 'formcheckbox': {
            return 'sn-checkbox-checked2';
        }
        
        case 'formradio': {
            return 'sn-radio-checked';
        }
        
        case 'iframe': {
            return 'sn-markup';
        }

        case 'container': {
            return 'sn-enlarge5';
        }

        case 'layoutcontainer': {
            return 'sn-enlarge';
        }
        
        case 'body': {
            return 'sn-web_asset';
        }
        
        case 'divider': {
            return 'sn-page-break2';
        }

        case 'smarttext': {
            switch (type && type.toLowerCase()) {
                case 'blogpublicationdate': {
                    return 'sn-calendar4';
                }

                case 'blogdescription': {
                    return 'sn-quotes-left';
                }

                case 'blogauthor': {
                    return 'sn-user3';
                }

                case 'blogtitle': {
                    return 'sn-magazine';
                }

                case 'sitetitle': {
                    return 'sn-sphere';
                }

                case 'pagetitle': {
                    return 'sn-file-text';
                }
            }
        }
        
        case 'sitetitle': {
            return 'sn-sphere';
        }
        
        case 'logo': {
            return 'sn-star-full';
        }

        case 'pagetitle': {
            return 'sn-file-text';
        }

        case 'blogtitle': {
            return 'sn-magazine';
        }

        case 'blogdescription': {
            return 'sn-quotes-left';
        }

        case 'blogauthor': {
            return 'sn-user3';
        }

        case 'blogpublicationdate': {
            return 'sn-calendar4';
        }
        
        case 'placeholder': {
            return 'sn-quill4';
        }

        case 'follow': {
            return 'sn-bubble-heart';
        }

        case 'share': {
            return 'sn-share';
        }

        case 'gallery': {
            return 'sn-images3';
        }

        case 'icon': {
            return 'fa fa-star-o';
        }
        
        // no match fallback
        default: {
            return 'sn-marquee-plus';
        }
    }
};

export default resolveElementIcon;