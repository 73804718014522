import { normalize, denormalize, schema } from 'normalizr';

// const navigationEntries = new schema.Entity('navigationEntries', {}, {
// 	idAttribute: (entity, parent) => {
// 		if (!parent) return ''; // Circumventing a suspected bug in normalizr library occuring when denormalizing state; will make githib issue later

// 		return `${parent._id}`;
// 	},
// });

const content = new schema.Entity('content', {}, {
	idAttribute: (entity, parent) => {
		if (!parent) return ''; // Circumventing a suspected bug in normalizr library occuring when denormalizing state; will make githib issue later

		return `${parent._id}`;
	},
});

const layoutContent = new schema.Entity('layoutContent', {}, {
	idAttribute: (entity, parent) => {
		if (!parent) return ''; // Circumventing a suspected bug in normalizr library occuring when denormalizing state; will make githib issue later

		return `${parent._id}`;
	},
});

const blogContent = new schema.Entity('blogContent', {}, {
	idAttribute: (entity, parent) => {
		if (!parent) return ''; // Circumventing a suspected bug in normalizr library occuring when denormalizing state; will make githib issue later

		return `${parent._id}`;
	},
});

const formContent = new schema.Entity('formContent', {}, {
	idAttribute: (entity, parent) => {
		if (!parent) return ''; // Circumventing a suspected bug in normalizr library occuring when denormalizing state; will make githib issue later

		return `${parent._id}`;
	},
});

const page = new schema.Entity('pages', {
	content,
}, { idAttribute: '_id' });

const navigation = new schema.Entity('navigation', {

}, { idAttribute: '_id' });

const theme = new schema.Entity('themes', {

}, { idAttribute: '_id' });

const file = new schema.Entity('files', {

}, { idAttribute: '_id' });

const form = new schema.Entity('forms', {
	content: formContent
}, { idAttribute: '_id' });

const layout = new schema.Entity('layouts', {
	content: layoutContent,
}, { idAttribute: '_id' });

const blog = new schema.Entity('blogs', {
	content: blogContent,
}, { idAttribute: '_id' });

const site = new schema.Entity('sites', {
	pages: [page],
	navigation: [navigation],
	layouts: [layout],
	themes: [theme],
	files: [file],
	forms: [form],
	blogs: [blog]
}, { idAttribute: '_id' });

const normalizeSites = raw => {
/*	console.log(raw, 'raw?')
	console.log(normalize(raw.slice(0, 1), [site]))*/

	return normalize(raw, [site]);
};
const denormalizeSites = (index, entities) => denormalize(index, [site], entities);

export {
	normalizeSites,
	denormalizeSites,
};

// http://stackoverflow.com/questions/34954726/how-do-you-add-remove-to-a-redux-store-generated-with-normalizr