import React, { Component } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router';

const RadiumLink = Radium(class Link extends React.PureComponent {
    render() {
        return (
			<a 
				{...this.props.aProps}
			>
				{this.props.children}
            </a>
        );
    }
});

const linkStrategy = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
			const entityKey = character.getEntity();

			if (entityKey === null) {
				return false;
			}

			return contentState.getEntity(entityKey).type === 'LINK';
		},
		callback
	);
};

class LinkDecorator extends Component {
	// constructor() {
	// 	super();

	// 	this.onClick = this.onClick.bind(this);
	// 	// this.getDescription = this.getDescription.bind(this);
	// }

	// getDescription() {
	// 	return this.props.getLinkDescription();
	// }

	// onClick(e) {
	// 	if (event.ctrlKey || event.metaKey || event.shiftKey) {
	// 		return;
	// 	}
		
	// 	event.preventDefault();

	// 	const link = this.props.contentState.getEntity(this.props.entityKey).getData();

	// 	this.props.onLinkClick(link, this.props.pages, this.props.blogs, this.props.files);
	// }

	render() {
		const link = this.props.contentState.getEntity(this.props.entityKey).getData();
		// const { newWindow } = link;
		const computedLink = this.props.computeLink && this.props.computeLink(link, this.props);

		const { theme } = this.props;

		const style = this.props.computePreset(theme.presets.text.Link, theme);
		
		style.cursor = 'pointer';

		let aProps = {
			// title: computedLink && computedLink.title,
			// href: computedLink && computedLink.url,
			// onClick: computedLink && (}),
			style
			// title: this.props.getLinkTitle && this.props.getLinkTitle(link, this.props),
			// description: this.props.getLinkDescription && this.props.getLinkDescription(link, this.props),
			// href: this.props.getLink && this.props.getLink(link, this.props),
			// onClick: this.onClick,
			// style
		};

		if (computedLink) {
			aProps.title = computedLink.title;
			aProps.href = computedLink.url;
			aProps.onClick = event => {
				if (event.ctrlKey || event.metaKey || event.shiftKey) {
					return;
				}
				
				event.preventDefault();

				this.props.onNavigate(computedLink); //onLinkClick(computedLink)
			};

			aProps = {
				...aProps,
				...computedLink.anchorAttributes
			};
		}

		return (
			<RadiumLink
				aProps={aProps}
			>
				{this.props.children}
			</RadiumLink>
		);
	}
}

export { linkStrategy };

export default withRouter(LinkDecorator);