exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "#app-container__app-container--1JchW,.app-container__container--cFmVT{width:100%;max-width:100%;min-height:100%;display:flex;background-image:url(\"https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/app-bg.svg\");background-position:center bottom;background-repeat:no-repeat;background-attachment:fixed;background-color:#f7f3f0}\n", ""]);

// exports
exports.locals = {
	"app-container": "app-container__app-container--1JchW",
	"container": "app-container__container--cFmVT"
};