exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".image__img-wrapper--29GgC{display:flex;justify-content:center;align-items:center}\n", ""]);

// exports
exports.locals = {
	"img-wrapper": "image__img-wrapper--29GgC"
};