import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'FormInput';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {
			style: {},
			preset: 'Primary',
			placeholder: 'Placeholder',
			mode: 'text',
			label: 'Input label'
		}
	})
};