/*
    More general version of Resize; should replace existing Resize function used by Selectable when we implement new Editor
*/

import React, { PureComponent } from 'react';
import { Label, Icon } from 'svift-ui';
import PropTypes from 'prop-types';
// import Tooltip from 'svift-library/client/tooltip';
import css from '../../selectable/resize.scss';
// import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';

class Resize extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};

        this.buttonRef = React.createRef();
    }

    componentWillUnmount = () => this.methods.removeListeners();

    methods = {
        attachCursorListener: (e, direction) => {
            const { clientX: initialX, clientY: initialY } = e;
            const initialHeight = parseInt(this.props.height);
        
            this.onCursorMove = e => {
                const { clientX: newX, clientY: newY } = e;
                const deltaX = newX - initialX;
                const deltaY = newY - initialY;

                let nextSize;

                switch (direction) {
                    case 'bottom':
                        nextSize = initialHeight + deltaY;

                        break;
                    case 'top':
                        nextSize = initialHeight - deltaY;

                        break;
                    case 'left':
                        nextSize = initialHeight - deltaX;

                        break;
                    case 'right':
                        nextSize = initialHeight + deltaX;

                        break;
                }

                this.props.onResize({ direction, nextSize });
            };
    
            this.onCursorUp = e => {
                if (this.props.onResizeDone) this.props.onResizeDone();
                
                this.methods.removeListeners();
            };
    
            document.addEventListener('mouseup', this.onCursorUp);
            document.addEventListener('mousemove', this.onCursorMove);

            this.props.onResizeBegin();
    
            e.stopPropagation();
            e.preventDefault();
        },
        removeListeners: () => {
            if (this.onCursorUp) {
                document.removeEventListener('mouseup', this.onCursorUp);
                document.removeEventListener('mousemove', this.onCursorMove);
            }
        }     
    }

    renderHandle = direction => {
        let className;
        let icon;

        switch (direction) {
            case 'left':
                className = 'left-handle';
                icon = 'sn-arrow-resize3';
                
                break;
            case 'right':
                className = 'right-handle';
                icon = 'sn-arrow-resize3';
                    
                break;
            case 'bottom':
                className = 'bottom-handle';
                icon = 'sn-arrow-resize4';
                    
                break;
            case 'top':
                className = 'top-handle';      
                icon = 'sn-arrow-resize4';
        }

        return (
            <React.Fragment>
                {this.props.element ?
                    <React.Fragment>
                        {/* transl8 */}
                        {/* TO DO: add Tooltip + info about height type */}
                        {/* <Tooltip 
                            target={this.buttonRef.current}
                            open={this.state.tooltipOpen}
                            popup
                            zIndex={996}
                            clearance={10}
                        >
                            <Icon name="sn-height2" style={{ marginRight: 4 }} />
                            {resolveElementName(this.props.element)} crop indicator (<span style={{ fontWeight: 'bold' }}>{Math.round(this.props.height)}</span> px)
                        </Tooltip> */}

                        <div
                            style={{
                                marginTop: '-12px',
                            }}
                        >
                            <Label
                                // ref={this.buttonRef}
                                basic
                                size="mini"
                                className="warning"
                                style={{ margin: 0, padding: '2px 4px', cursor: 'help' }}
                                onMouseEnter={() => this.setState({ tooltipOpen: true })}
                                onMouseLeave={() => this.setState({ tooltipOpen: false })}
                            >
                                <Icon name={this.props.icon} style={{ marginRight: 4 }} />
                                <Icon name="sn-plus3" style={{ marginRight: 4, opacity: 0.6 }} />
                                <Icon name="sn-height2" style={{ marginRight: 0 }} />
                            </Label>
                        </div>
                        
                    </React.Fragment>
                :
                    <Icon
                        circular
                        name={this.props.icon || icon}
                        data-ignore-click="true"
                        key={direction}
                        // ref={el => this[`el-${direction}`] = el}
                        className={css[className]}
                        onMouseDown={this.props.icon ? () => {} : e => this.methods.attachCursorListener(e, direction)}
                        style={{ cursor: 's-resize' }}
                    />
                }
            </React.Fragment>
        );
    }

    render = () => {
        const { left, top, right, bottom } = this.props;

        return this.renderHandle('bottom'); /*(
            <React.Fragment>
                {}
                {left && this.renderHandle('left')}
                {top && this.renderHandle('top')}
                {right && this.renderHandle('right')}
                {bottom && this.renderHandle('bottom')}
            </React.Fragment>
        );*/
    }
}

Resize.propTypes = {
    onResize: PropTypes.func.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    left: PropTypes.bool,
    right: PropTypes.bool,
    top: PropTypes.bool,
    bottom: PropTypes.bool
};

export default Resize;
 