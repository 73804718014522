import React from 'react';
import css from './label.scss';
import InfoIcon from 'components/InfoIcon';
import ClearValue from 'components/editor-v2/editor-interface/options/common/clear-value/ClearValue';
import {
    Icon
} from 'svift-ui';

export default props => {
    return (
        <div className={props.collapsed ? `${css['option-wrapper']} ${css['collapsed']}` : css['option-wrapper']}>
            {props.label &&
                <label
                    className={css['option-label']}
                    style={{
                        fontSize: props.fontSize || 14,
                        textAlign: props.textAlign || 'left',
                        ...props.style
                    }}
                >
                    {props.labelIcon &&
                        <Icon
                            name={props.labelIcon}
                            rotated={props.labelIconRotated}
                            className={css['option-icon']}
                        />
                    }

                    <span style={{ verticalAlign: 'middle' }}>
                        {props.label}
                        
                        {props.labelDescription &&
                            <InfoIcon description={props.labelDescription} position="right center" /> 
                        }
                    </span>
                    
                    {props.clear &&
                        <ClearValue {...props.clear} />
                    }
                </label>
            }

            {props.children}
        </div>
    );
};