import React, { memo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import Accordion, {
    useAccordion
} from 'svift-library/client/accordion/Accordion'
import Border from './border/Border';
import Margin from './margin/Margin';
import Padding from './padding/Padding';
import MaxWidth from './max-width/MaxWidth';
import ColumnSpacing from './column-spacing/ColumnSpacing';
import BorderRadius from './border-radius/BorderRadius';
import BoxShadow from './box-shadow/BoxShadow';
import LetterSpacing from './letter-spacing/LetterSpacing';
import LineHeight from './line-height/LineHeight';
import FontWeight from './font-weight/FontWeight';
import FontSize from './font-size/FontSize';
import Font from './font/Font';
import Color from 'components/editor-v2/editor-interface/options/common/color/ColorPaletteWrapper';
import LineType from './line-type/LineType';
import DividerLineHeight from './divider-height/DividerHeight';
import Height from './height/Height';
import { colorResolver } from 'svift-library/client/renderer/utils/computePreset';

// TRANSL8 - not a translation - adjust order
const optionOrder = {
	borderRadius: 1,
	padding: 2,
	font: 3,
	fontWeight: 4,
	fontSize: 5,
	variant: 6,
	lineHeight: 7,
	letterSpacing: 8,
	color: 9,
	backgroundColor: 10,
	border: 11,
	boxShadow: 12,
	margin: 13
};

const orderOptions = options => options.slice().sort((a, b) => {
	if (optionOrder[a] > optionOrder[b]) return 1;

	return -1;
});

export default injectIntl(memo(props => {
	// console.log(props, 'props in styling overrides')
	// console.log(props.value, 'STYLE VALUE');

	const { intl, theme } = props;
	const accordionState = useAccordion();
	// const [state, dispatch] = accordionState;

	/*
		dropdown addition test, can safely be ignored/deleted
	*/
	// const [items, setItems] = React.useState([
	// 	{
	// 		value: 'Taiwan numbah one',
	// 		text: 'Taiwan numbah one very long text wow holy shit breaking all text boundaries',
	// 		key: 'taiwan'
	// 	}
	// ]);

    const values = props.value || {};

    // const onChange = (key, value, action, shouldSuspend) => {
    //     const newOverrides = {
    //         ...values,
    //         [key]: value
	// 	};
		
	// 	if (typeof value === 'undefined') delete newOverrides[key];

	// 	if (Object.keys(newOverrides).length === 0) {
	// 		props.onChange(undefined, action, shouldSuspend);
	// 	} else {
	// 		props.onChange(newOverrides, action, shouldSuspend);
	// 	}
	// };

	const onChange = (key, value, ...args) => {
        const newOverrides = {
            ...values,
            [key]: value
		};
		
		if (typeof value === 'undefined') delete newOverrides[key];

		if (Object.keys(newOverrides).length === 0) {
			props.onChange(undefined, ...args);
		} else {
			props.onChange(newOverrides, ...args);
		}
	};

	// const mergedStyling = deepmerge.all([props.preset || {}, props.multipleStatesDefaultStyling || {}, values]);
	// const computedMergedStyling = computePreset(mergedStyling, theme);
	
	return orderOptions(props.keys).map(valueType => {
		switch(valueType) {
			case 'borderRadius': {
				return (
					<BorderRadius 
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'padding': {
				return (
					<Padding
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'font': {
				return (
					<Font
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'fontWeight': {
				return (
					<FontWeight
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>	
				)
			}

			case 'fontSize': {
				return (
					<FontSize 
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}
			
			case 'variant': {
				// Add when variants are working in Design
				return null;
			}

			// Used by divider
			case 'lineType': {
				return (
					<LineType
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'height': {
				if (props.element === 'divider') {
					return (
						<DividerLineHeight 
							key={valueType}
							theme={props.theme}
							inlineStyling={values[valueType]}
							presetStyling={props.preset[valueType]}
							onChange={(...args) => onChange(valueType, ...args)}
							accordionState={accordionState}
						/>
					);
				}

				return (
					<Height
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'lineHeight': {
				return (
					<LineHeight 
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'maxWidth': {
				return (
					<MaxWidth 
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);				
			}

			case 'columnSpacing': {
				return (
					<ColumnSpacing 
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);				
			}

			case 'letterSpacing': {
				return (
					<LetterSpacing
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);						
			}

			case 'color': {				
				return (
					<Color
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						computedStyling={colorResolver(props.theme, values[valueType] || {})}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling[valueType]}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'backgroundColor': {
				return (
					<Color
						// suspend={props.suspend}
						key={valueType}
						icon="sn-paint-format"
						title={props.intl.formatMessage({ id: 'options.background color title' })}
						theme={props.theme}
						presetStyling={props.preset[valueType]}
						inlineStyling={values[valueType]}
						computedStyling={colorResolver(props.theme, values[valueType] || {})}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling[valueType]}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'border': {
				return (
					<Border 
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);		
			}

			case 'boxShadow': {
				return (
					<BoxShadow 
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			case 'margin': {
				return (
					<Margin
						// suspend={props.suspend}
						key={valueType}
						theme={props.theme}
						inlineStyling={values[valueType]}
						presetStyling={props.preset[valueType]}
						// mergedStyling={mergedStyling[valueType]}
						// computedStyling={computedMergedStyling}
						// onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
						onChange={(...args) => onChange(valueType, ...args)}
						accordionState={accordionState}
					/>
				);
			}

			default: {
				return <p key={valueType}> no attribute handler for {valueType} </p>;
			}
		}
	});

	// return (
	// 	<Accordion state={state} dispatch={dispatch}>
	// 		<Section
	// 			nested
	// 			// transl8
	// 			title="Override Type ( ! )"
	// 			icon="sn-bucket"
	// 			clear={{
	// 				onClear: () => props.onChange(),
	// 				enabled: typeof props.value !== 'undefined' && Object.keys(props.value).length > 0 // Just some "soft" protection from cases where style is {} instead of undefined (legacy reasons)
	// 			}}
	// 		>
	// 			{props.keys.map(valueType => {
	// 				switch(valueType) {
	// 					case 'borderRadius': {
	// 						return (
	// 							<BorderRadius 
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'padding': {
	// 						return (
	// 							<Padding
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'font': {
	// 						return (
	// 							<Font
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'fontSize': {
	// 						return (
	// 							<FontSize 
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'lineHeight': {
	// 						return (
	// 							<LineHeight 
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'letterSpacing': {
	// 						return (
	// 							<LetterSpacing
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);						
	// 					}

	// 					case 'variant': {
	// 						// Add when variants are working in Design
	// 						return null;
	// 					}

	// 					case 'margin': {
	// 						return (
	// 							<Margin
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'color': {
	// 						return (
	// 							<Color
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
	// 								computedStyling={computedMergedStyling[valueType]}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'backgroundColor': {
	// 						return (
	// 							<Color
	// 								key={valueType}
	// 								theme={props.theme}
	// 								presetStyling={props.preset[valueType]}
	// 								inlineStyling={values[valueType]}
									// mergedStyling={mergedStyling[valueType]}
	// 								computedStyling={computedMergedStyling[valueType]}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					case 'border': {
	// 						return (
	// 							<Border 
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);		
	// 					}

	// 					case 'boxShadow': {
	// 						return (
	// 							<BoxShadow 
	// 								key={valueType}
	// 								theme={props.theme}
	// 								inlineStyling={values[valueType]}
	// 								presetStyling={props.preset[valueType]}
									// mergedStyling={mergedStyling[valueType]}
									// computedStyling={computedMergedStyling}
	// 								onChange={(newValue, action, shouldSuspend) => onChange(valueType, newValue, action, shouldSuspend)}
	// 								accordionState={accordionState}
	// 							/>
	// 						);
	// 					}

	// 					default: {
	// 						return <p key={valueType}> no attribute handler for {valueType} </p>;
	// 					}
	// 				}
	// 			})}
	// 		</Section>
	// 	</Accordion>
	// );
}));