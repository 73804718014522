const excludeProp = (obj, propName) => Object.keys(obj).reduce((acc, key) => {
	if (propName === key) {
		return acc;
	}

	acc[key] = obj[key];

	return acc;
}, {});

// if (area === 'rightBottom') {
// 	navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetPath.length - 1, {
// 		id: sourceItem.data._id,
// 		parent: targetItem.data._id,
// 		hidden: sourceItem.data.hidden
// 	}, 'after');

const immutableArrayInsertion = (array, index, value, direction) => {
	if (direction === 'replace') {
		return [
			...array.slice(0, index),
			value,
			...array.slice(index + 1)
		];
	}

	if (direction === 'after') {
		index = index + 1;
	}

	const before = array.slice(0, index);
	const after = array.slice(index, array.length);

	before.push(value);

	return before.concat(after);
};



const formatToDropdown = (array, value, text) => array.map(obj => ({ value: obj[value], text: obj[text] }));

const swapImmutable = (array, a, b) => {
	const newArray = array.slice();

	newArray[a] = array[b];
	newArray[b] = array[a];

	return newArray;
};

export default {
	excludeProp,
	immutableArrayInsertion,
	formatToDropdown,
	swapImmutable
};
