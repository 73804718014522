import { useState, useEffect } from 'react';
import addUrlParams from 'svift-library/client/routing/addUrlParams';

const fetchRendererData = ({ host = '', basePath = '/sites/renderer', siteID, contentID, contentType, isAdmin }) => {
    const url = addUrlParams(`${host}${basePath}/${siteID}/${contentID}`, { contentType, isAdmin });

    return fetch(url, { credentials: 'include' })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return Promise.reject('Couldnt fetch renderer data :(');
            }
        });
}

const useRendererData = props => {
    const {
        refetchOnValueChange = []
    } = props;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refetch, setRefetch] = useState(1);

    useEffect(() => {
        // setLoading(true);
        // setError(null);
        // setData(null);

        let cancelled;

        fetchRendererData(props)
            .then(data => {
            // console.log('here we go');
                /* 
                    If we start refetching in fast succession, we can't be sure of the order the requests arrive, 
                    so we have to make sure that new calls take presedence by cancelling any queued updates
                */
                if (cancelled) return Promise.reject('cancelled');

                return data;
            })
            // .then(data => console.log(data, 'data') || data)
            .then(data => {
                // console.log('fetch complete')
                setData(data);
                setLoading(false);
            })
            .catch(err => {
                if (err !== 'cancelled') {
                    setError(err);
                    setLoading(false);
                }
            })

        return () => cancelled = true;
    }, [...refetchOnValueChange, refetch]);

    if (error) {
        console.warn(error, 'err when fetching renderer data');

        return {
            error,
            errorMessage: error.message
        };
    }

    return {
        data,
        loading,
        forceRefetch: () => setRefetch(refetch + 1)
    };
}

export default useRendererData;