import React from 'react';
import css from './background-video.scss';

export default props => {
    const { node, files } = props;
    const { backgroundVideo } = node.props;

    const file = backgroundVideo && files.lookup[backgroundVideo.src];

    if (!file) return null;

    // console.log(file, 'file me up scotty');
    const style = {
        opacity: backgroundVideo.opacity ? backgroundVideo.opacity / 100 : 1,
    }

    // if (backgroundVideo.fixed) // do something crazy here to make it fixed and respect container :)

    return (
        <div className={css['background-video-container']}>
            <video 
                autoPlay 
                muted 
                loop 
                className={css['background-video']}
                style={style}
            >
                <source src={file.url} type="video/mp4"></source>
            </video>
        </div>
    );
}