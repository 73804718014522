import { SubmissionError } from 'redux-form';

const validate = fields => values => {
	// if (Object.keys(fields).length !== Object.keys(values).length) {
	// 	console.error('DEVELOPER ERROR: FIELDS AND VALUES LENGTH SHOULD MATCH');
	// }

	const errors = {};

	Object.keys(fields).forEach(key => {
		const validator = fields[key].validate;
		const value = values[key];

		const error = validator(value);

		if (error) {
			errors[key] = error;
		}
	});

	if (Object.keys(errors).length > 0) {
		throw new SubmissionError(errors);
	}

	return values;
};

export default validate;