import constants from 'store/constants';
import { matchPath } from 'react-router';

const getDefaultState = () => ({
	sideMenuVisible: true,
	userMenuVisible: false,
	stepByStepVisible: false
});

const defaultState = getDefaultState();

const uiReducer = (state = defaultState, action) => {
	let newState;

	switch (action.type) {
		case constants.TOGGLE_SIDE_MENU:
			newState = {
				...state,
				sideMenuVisible: action.data,
			};

			return newState;

		case constants.TOGGLE_USER_MENU:
			newState = {
				...state,
				userMenuVisible: action.data
			};

			return newState;

		case constants.TOGGLE_STEP_BY_STEP:
			newState = {
				...state,
				stepByStepVisible: action.data
			};

			return newState;


		case constants.ROUTE_CHANGE: {
			return {
				...state,
				stepByStepVisible: false
			}
		}

		case constants.LOGOUT:
			return getDefaultState();

		default: return state;
	}
};

export default uiReducer;
