import React, { Component } from 'react';
import { MenuItem, Icon, Popup, Button } from 'svift-ui';
import actions from 'store/actions/ui';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const mapStateToProps = state => ({
	sideMenuVisible: state.ui.sideMenuVisible,
});

const mapDispatchToProps = dispatch => ({
	toggleSideMenu: bool => dispatch(actions.toggleSideMenu(bool)), // data => dispatch(toggleSideNav(data)),
});

@connect(mapStateToProps, mapDispatchToProps)
class SideMenuButton extends Component {

	render() {
		const { toggleSideMenu, sideMenuVisible, intl } = this.props;
		const content = `${sideMenuVisible ? 'general.hide side navigation' : 'general.show side navigation'}`;

		return (
			<Popup
				trigger={
					<MenuItem
						className="shade-item"
						onClick={() => toggleSideMenu(!sideMenuVisible)}
					>
						<Icon
							cic
							name={!sideMenuVisible ? 'sn-arrow-right8' : 'sn-arrow-left8'}
							className={!sideMenuVisible ? 'tint-color' : 'light-tint-color'}
							style={{
								fontSize: 16,
								marginRight: 0
							}}
						/>
					</MenuItem>
				}
				content={intl.formatMessage({ id: content })}
				positioning="bottom left"
			/>
		);
	}
}

export default injectIntl(SideMenuButton);