import React, { PureComponent } from 'react';
import createApi from 'api';
import {
    Loader,
    Button,
    Message,
    Icon,
    Header,
    Grid,
    GridColumn,
    FormInput,
    Label,
    Menu,
    MenuItem,
    Popup,
    Divider
} from 'svift-ui';
// import OptionLabel from 'components/option-library/label';
import AppContainer from 'components/app-layout/app-container/AppContainer';
import AppContent from 'components/app-layout/app-content/AppContent';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import { objFromQueryString } from 'utils/routing/extractParams';
import { replaceRoute } from 'utils/routing';
import Notification from 'components/hoc/notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setRoute } from 'utils/routing';
import SviftGA from 'utils/svift-ga';

const api = createApi();

// To-do: create validator library and add this one there
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ForgotPassword extends PureComponent {
    constructor(props) {
        super(props);

        const { ticket } = objFromQueryString(window.location.search);

        this.state = {
            email: '',
            newPassword: '',
            newPasswordRepeat: '',
            resetToken: ticket
        };
    }

    setNewPassword = async () => {
        if (this.state.newPassword !== this.state.newPasswordRepeat) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id:
                        'registration.make sure to repeat your password correctly'
                })
            });

            return;
        }

        this.setState({
            submitting: true
        });

        try {
            SviftGA.AUTHORIZATION.RESET_PASSWORD_STARTED();

            await api.setNewPassword({
                newPassword: this.state.newPassword,
                resetToken: this.state.resetToken
            });

            this.props.createNotification({
                content: this.props.intl.formatMessage({
                    id: 'profile.reset password notification'
                }),
                className: 'positive',
                position: 'topCenter',
                icon: 'lock'
            });

            replaceRoute('/');

            return;
        } catch (e) {
            // Just an extremely simple error in case server is dead
            alert(
                this.props.intl.formatMessage({
                    id: 'general.generic server error message'
                })
            );
        } finally {
            this.setState({
                submitting: false
            });
        }
    };

    sendResetToken = async () => {
        if (!emailRegex.test(this.state.email)) {
            this.setState({
                error: this.props.intl.formatMessage({
                    id: 'validation.not valid email'
                })
            });

            return;
        }

        this.setState({
            submitting: true
        });

        try {
            // Always returns 200 even if no email is found; we don't want to give the user any information to help them verify which emails exist in our system
            await api.createResetToken({ email: this.state.email });

            this.setState({
                resetTokenCreated: true // Might not be created, but we can't tell the user that. We just assume the supplied email exists.
            });
        } catch (e) {
            // Just an extremely simple error in case server is dead
            alert(
                this.props.intl.formatMessage({
                    id: 'general.generic server error message'
                })
            );
        } finally {
            this.setState({
                submitting: false
            });
        }
    };

    renderDecideNewPassword = () => {
        if (this.state.submitting) {
            return <Loader />;
        }

        return (
            <Message className="warning">
                {/* set and confirm new password */}
                <div
                    style={{
                        textAlign: 'center',
                        paddingTop: 32,
                        paddingBottom: 40
                    }}
                >
                    <Icon
                        name="sn-key"
                        className="info-color"
                        size="huge"
                        style={{ marginBottom: 16, opacity: 0.6 }}
                    />
                    <Header as="h1" style={{ marginBottom: 24 }}>
                        <FormattedMessage id="profile.reset password title" />
                    </Header>
                    <p style={{ marginBottom: 32 }}>
                        <FormattedMessage id="profile.enter new password" />
                    </p>

                    <Grid centered>
                        <GridColumn computer={10} tablet={12} mobile={16}>
                            {/* new password input */}
                            <FormInput
                                icon="sn-key"
                                iconPosition="left"
                                size="big"
                                type="password"
                                style={{ marginBottom: 8 }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'profile.new password'
                                })}
                                fluid
                                label={this.props.intl.formatMessage({
                                    id: 'profile.new password label'
                                })}
                                type="password"
                                onChange={(e, { value }) =>
                                    this.setState({
                                        newPassword: value,
                                        error: null
                                    })
                                }
                                value={this.state.newPassword}
                            />
                            {/* confirm new password input */}
                            <FormInput
                                icon="sn-key"
                                iconPosition="left"
                                size="big"
                                type="password"
                                style={{ marginBottom: 24 }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'profile.confirm new password'
                                })}
                                fluid
                                label={this.props.intl.formatMessage({
                                    id: 'profile.confirm new password label'
                                })}
                                type="password"
                                onChange={(e, { value }) =>
                                    this.setState({
                                        newPasswordRepeat: value,
                                        error: null
                                    })
                                }
                                value={this.state.newPasswordRepeat}
                            />
                        </GridColumn>
                    </Grid>

                    {/* save new password button */}
                    <Button
                        icon
                        positive
                        size="big"
                        style={{ marginBottom: 24 }}
                        onClick={this.setNewPassword}
                    >
                        <Icon name="sn-checkmark" />{' '}
                        <FormattedMessage id="profile.change password button" />
                    </Button>

                    {this.state.error && (
                        <Message negative>{this.state.error}</Message>
                    )}
                </div>
            </Message>
        );
    };

    renderRequestPasswordReset = () => {
        if (this.state.resetTokenCreated) {
            return (
                <Message className="info">
                    {/* mail notification with reset token sent */}
                    <div
                        style={{
                            textAlign: 'center',
                            paddingTop: 32,
                            paddingBottom: 40
                        }}
                    >
                        <Icon
                            name="send outline"
                            className="info-color"
                            size="huge"
                            style={{ marginBottom: 16, opacity: 0.6 }}
                        />
                        <Header as="h1" style={{ marginBottom: 24 }}>
                            <FormattedMessage id="profile.reset password confirmation sent title" />
                        </Header>
                        <p style={{ marginBottom: 32 }}>
                            <FormattedMessage id="profile.reset password confirmation sent description" />
                        </p>
                        <Label
                            basic
                            className="info"
                            size="big"
                            style={{ fontWeight: 400 }}
                        >
                            <FormattedMessage id="profile.reset password confirmation sent label" />
                        </Label>
                        <p>
                            <FormattedMessage id="profile.reset password confirmation sent disclaimer" />
                        </p>
                    </div>
                </Message>
            );
        }

        if (this.state.submitting) {
            return <Loader />;
        }

        return (
            <Message className="info" style={{ maxWidth: 520, margin: 'auto' }}>
                {/* request password reset */}
                <div
                    style={{
                        textAlign: 'center',
                        paddingTop: 32,
                        paddingBottom: 40
                    }}
                >
                    <Icon
                        name="sn-lifebuoy"
                        className="info-color"
                        size="huge"
                        style={{
                            marginBottom: 16,
                            marginRight: 0,
                            opacity: 0.6
                        }}
                    />
                    <Header as="h1" style={{ marginBottom: 24 }}>
                        <FormattedMessage id="profile.request password reset title" />
                    </Header>
                    <Divider horizontal>
                        <Icon
                            circular
                            name="sn-envelop"
                            className="dark-secondary-color"
                            style={{ fontSize: 24, opacity: 0.6 }}
                        />
                    </Divider>
                    <p style={{ marginBottom: 16 }}>
                        <FormattedMessage id="profile.request password reset description" />
                    </p>
                    {/* profile email input */}
                    <FormInput
                        icon="at"
                        iconPosition="left"
                        size="massive"
                        style={{ marginBottom: 8 }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'profile.email input placeholder'
                        })}
                        type="email"
                        fluid
                        label={this.props.intl.formatMessage({
                            id: 'profile.email input label'
                        })}
                        value={this.state.email}
                        onChange={(e, { value }) =>
                            this.setState({ email: value, error: null })
                        }
                    />

                    {/* request password reset button */}
                    <Button
                        icon
                        fluid
                        size="huge"
                        className="warning"
                        type="submit"
                        style={{ marginBottom: 16 }}
                        onClick={this.sendResetToken}
                    >
                        <FormattedMessage id="profile.request password reset button" />
                    </Button>

                    {this.state.error && (
                        <Message negative>{this.state.error}</Message>
                    )}
                </div>
            </Message>
        );
    };

    render() {
        return (
            <AppContainer>
                <Menu id="top-navigation">
                    <MenuItem onClick={() => setRoute('/')}>
                        <Popup
                            trigger={
                                <Icon
                                    name="lock"
                                    className="primary"
                                    style={{ fontSize: 32, margin: 0 }}
                                />
                            }
                            content={this.props.intl.formatMessage({
                                id: 'general.back to login'
                            })}
                            position="bottom left"
                        />
                    </MenuItem>
                </Menu>
                <AppContent>
                    <AppContentSection>
                        {this.state.resetToken
                            ? this.renderDecideNewPassword()
                            : this.renderRequestPasswordReset()}
                    </AppContentSection>
                </AppContent>
            </AppContainer>
        );
    }
}

export default injectIntl(Notification(ForgotPassword));
