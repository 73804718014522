import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import { Container, Icon, Label } from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'components/preset/util';
// import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import themeActions from 'store/actions/sites/themes';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { connect } from 'react-redux';
import OverviewSection from './OverviewSection';

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
class OverviewContainer extends PureComponent {
    constructor(props){
        super(props);
        this.state ={
            selectedPreset: null,
            selectedPresetOption: null
        }
        // this.optionTooltip = tooltip.call(this);

        this.optionTooltipRef = React.createRef();
    }
    
    selectPreset = (preset) => {
        this.setState({ selectedPreset: preset || null })
        e.stopPropagation()
    };
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });
   
    getPresetOptions = style => {
        const {presetTitle, preset, containerSelectedPresetOption, containerSelectPresetOption, theme} = this.props;
       
        const options = [
            <span key="container-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-web" /> <FormattedMessage id="elements.container title" />: <FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} />
            </span>,
            <ColorPicker
                title={this.props.intl.formatMessage({ id: 'options.background color title' })}
                key="color-picker-background"
                icons={['sn-paint-format']}
                colors={theme.colors}
                onChange={(newColor) => this.props.updatePreset('container', presetTitle, 'backgroundColor', newColor)}
                value={preset.backgroundColor}

                option="color-picker-background"
                selectedOption={containerSelectedPresetOption}
                onToggle={(select, e) => {
                    containerSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Border
                key="border-style"
                value={preset.border}
                onChange={newBorder => this.props.updatePreset('container', this.props.presetTitle, 'border', newBorder)}
                colors={theme.colors}

                option="border-style"
                selectedOption={containerSelectedPresetOption}
                onToggle={(select, e) => {
                    containerSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <BorderRadius
                max={240}
                key="border-radius"
                value={preset.borderRadius}
                onChange={(key, newValue) => {
                    if (key === 'generalBorderRadius') {
                        if (newValue === null) {
                            this.props.updatePreset('container', presetTitle, 'borderRadius', {});
                        } else {
                            this.props.updatePreset('container', presetTitle, 'borderRadius', {
                                generalBorderRadius: newValue
                            });
                        }

                        return;
                    }

                    const newBorderRadius = {
                        ...preset.borderRadius,
                        [key]: newValue
                    };

                    if (newValue === null) {
                        delete newBorderRadius[key];
                    }

                    delete newBorderRadius.generalBorderRadius;

                    this.props.updatePreset('container', presetTitle, 'borderRadius', newBorderRadius);
                }}
                colors={theme.colors}

                option="border-radius"
                selectedOption={containerSelectedPresetOption}
                onToggle={(select, e) => {
                    containerSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Shadow
                key="shadow"
                value={preset.boxShadow}
                colors={theme.colors}
                onChange={(newShadow) => {
                    this.props.updatePreset('container', presetTitle, 'boxShadow', newShadow);
                }}

                option="Shadow"
                selectedOption={containerSelectedPresetOption}
                onToggle={(select, e) => {
                    containerSelectPresetOption(select);

                    e.stopPropagation();
                }}
            />
        ]   
         return options;
    }

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.selectedPreset === 'container') {
			this.props.clearSelectPreset();
		}
    }

    // onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.selectedPreset === 'container') {
    //         this.props.clearSelectPreset();
    //     }
    // }

    render(){
        const { intl, presetTitle, selectedPreset, preset, theme } = this.props;
        const selected = selectedPreset === 'container';
        const className = selected ? 'preset-target active-preset-target' : 'preset-target';

        const computedStyles = computePreset(preset, theme);

        const labelStyling = {
            position: 'absolute',
            top: -52,
            left: 94,
            zIndex: 3
        };
        
        const pointingStyle = {
            position: 'absolute',
            top: -32,
            left: 112,
            width: 4,
            height: 32,
            background: 'lightgrey'
        };

        return(
            <Container
                className={className}
                style={Object.assign(computedStyles, { position: 'relative' })}
                selected={selected}
                onClick={this.props.selectPreset('container')}
                // ref={this.optionTooltip.setTarget}
                ref={this.optionTooltipRef}
            >
                <OptionPanel
                    icon="sn-bucket"
                    title="elements.row title"
                    target={this.optionTooltipRef.current} //.getTarget()}
                    open={selected}
                    options={this.getPresetOptions(computedStyles)}
                />
                <Label basic size="large" className="primary" style={labelStyling}
                    onClick={() => this.props.selectPreset('container')}
                    // ref={this.optionTooltip.setTarget}
                >
                    <Icon name="sn-web" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                    <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.container title" /></span>
                </Label>
                <OverviewSection 
                    intl={intl}
                    theme={theme}

                    clearSelectPreset={this.props.clearSelectPreset}
                    selectPreset={this.props.selectPreset}
                    selectedPreset={this.props.selectedPreset}

                    sectionSelectPresetOption={this.selectPresetOption}
                    sectionSelectedPresetOption={this.state.selectedPresetOption}

                    presetTitle='Normal'
                    preset={theme.presets.grid.Normal}
                />
                <div style={pointingStyle} />
            </Container>
        )
    }
}

export default connect(null, mapDispatchToProps) (injectIntl(onClickOutside(OverviewContainer)));