import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Node from './nodes/Node';
import widthRecorderHOC from './breakpoints/widthRecorderHOC';
import '../css/renderer.scss';

/*
    May not be needed, can probably just inline it (do benchmarks first though)
*/
class Provider extends PureComponent {
    render() {
        return (
            <this.props.ContextProvider
                value={this.props.context}
                // className={css['renderer']}
            >
                {this.props.children}
            </this.props.ContextProvider>
        );
    }
}

/*
    Problems:
        - External state is a suffer to manage; produces constant bugs and synchronization errors
        - Data management confusing:
            - Library:
                query.js (the database query, modified by props (confusing))
                route.js (attached to existing server at arbitrary route (confusing))
                transformData.js (transforms data before sending it to client (confusing))
            - useRendererData hook handles refetch logic 
            - useRendererState merges props into state and provides a Context object for that state to be propagated through
        - How to merge props from Admin into state and propagate into Renderer state without causing bugs:
            value X on PAGE OBJ updates in Admin (active layout)
                => Trigger refetch of renderer data (ok, but redundant + resets current editable content (lol bug central))
            
            value Y on PAGE OBJ updates in Admin (page title)
                => Merge into renderer state so listeners can respond to it appropriately

        - Client should be able to deal with null values for content to facilitate legacy use
*/

class Renderer extends PureComponent {
    render() {
        const noContent =
            !this.props.rendererState.nodeMap ||
            !this.props.rendererState.nodeMap.root;

        return (
            <Provider
                context={this.props.rendererState}
                ContextProvider={this.props.RendererContext.Provider}
            >
                {this.props.renderAtRoot && this.props.renderAtRoot(this.props)}

                {noContent ? null : (
                    <Node
                        nodeID="root"
                        onClickOutsideRef={this.props.rendererContainerRef}
                        // onClickOutsideRef={this.onClickOutsideRef}
                        ContextConsumer={this.props.RendererContext.Consumer}
                        getPath={this.props.getPath}
                        getParent={this.props.getParent}
                    />
                )}
            </Provider>
        );
    }
}

Renderer.propTypes = {
    id: PropTypes.string,
    context: PropTypes.object,
    data: PropTypes.object,
    nodeMap: PropTypes.object,
    breakpoints: PropTypes.object
};

export default widthRecorderHOC(Renderer);
