import { createSelector } from 'reselect';
import safeGet from 'utils/key';

// const imageExtensions = ['jpg', 'png', 'svg', 'gif'];

const selectFileIds = (state, siteid) => state.sites.sites[siteid].files;
// const selectLayouts = state => state.sites.layout.layouts;
const selectFiles = (state, siteid) => state.sites.files.files;

// const imageSelector = createSelector(
// 	selectFiles,
// 	files => !files ? [] : files.filter(file => imageExtensions.some(ext => ext === file.name.split('.').pop()))
// );

const filesSelector = createSelector(
	selectFileIds,
	selectFiles,
	(fileIds, files) => fileIds.map(fileId => files[fileId])
);

const filesDictionarySelector = createSelector(
	selectFileIds,
	selectFiles,
	(fileIds, files) => {
		return fileIds.reduce((dict, fileID) => {
			dict[fileID] = files[fileID]; // May seem redundant but just to be safe we only gets the files for the current siteid

			return dict;
		}, {});
	}
)

const filesInActiveFolder = createSelector(
	state => state.sites.files.activeFolder,
	state => state.sites.sites[state.sites.params.siteid].files,
	state => state.sites.files.folders,
	state => state.sites.files.files,
	(activeFolderId, fileIds, folders, files) => {
		const activeFolder = folders.find(folder => folder._id === activeFolderId) || folders.find(folder => !folder.parent);
		const fileIdsInFolder = activeFolder ? activeFolder.files.filter(file => fileIds.includes(file)) : fileIds;
		const activeFiles = fileIdsInFolder.map(id => files[id]);

		return activeFiles;
	}
);

const storageCapacity = createSelector(
	state => state.system.subscriptions,
	state => safeGet(`sites.sites.${state.sites.params.siteid}.subscription.type`)(state),
	(subscriptions, subscriptionType) => {
		const freeStorageLimit = subscriptions.free.storage * 1000 * 1000 * 1000;

        if (!subscriptionType) return freeStorageLimit;
		
		const subscription = subscriptions[subscriptionType.toLowerCase()];
		
		if (!subscription) {
			return freeStorageLimit;
		}

        return subscription.storage * 1000 * 1000 * 1000;
	}
);

const storageLeft = createSelector(
	(_, capacity) => capacity,
	state => filesSelector(state, state.sites.params.siteid),
	(capacity, files) => {
		const bytesUsed = files.reduce((sum, file) => {
			const size = parseInt(file.meta.size, 10);

			return size + sum;
		}, 0);

		return capacity - bytesUsed;
	}
);

const selectFolders = createSelector(
	state => state.sites.files.folders,
	
);

export { storageCapacity, storageLeft, filesSelector, filesDictionarySelector, filesInActiveFolder };