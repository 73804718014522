import React from 'react';
import { setRoute } from 'utils/routing';
import {
	Icon,
    ListItem,
    Button,
	Label
} from 'svift-ui';
import { connect } from 'react-redux';
import { createFilter } from 'react-search-input';
import ListMenu from 'components/list-menu';
import { injectIntl, FormattedMessage } from 'react-intl';
import layoutsSelector from 'store/selectors/sites/layouts';
import InfoIcon from 'components/InfoIcon';
import css from 'components/app-layout/app-navigation/app-navigation.scss'

const LayoutList = injectIntl(props => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const filteredLayouts = props.layouts.filter(createFilter(searchTerm, ['title']));

    return (
        <ListMenu
            dataSource={props.layouts}
            handleFilter={(e, d) => setSearchTerm(e.target.value)}
            onClose={() => props.setListOpen(false)}
            modalText={props.intl.formatMessage({ id: 'layouts.create new layout' })}
            // onModalClick={toggleLayoutModal}
            icon="sn-web"
            title={props.intl.formatMessage({ id: 'layouts.title' })}
        >
            {filteredLayouts.length === 0 ? 'No layouts found' // TRANSL8 eller indsæt NoResult komponenten
                :
                filteredLayouts.map(layout => (
                    <ListItem 
                        key={layout._id} 
                        onClick={e => {
                            if (layout._id !== props.layoutid) {
                                const siteid = props.siteid;
                    
                                setRoute(`/dashboard/${siteid}/design/layouts/${layout._id}`);
                            }
                        }}
                        className={layout._id === props.layoutid ? 'active-item' : ''}
                    >
                        <span>
                            <Icon name="sn-web" style={{ verticalAlign: 'middle' }} />
                            <span style={{ verticalAlign: 'middle', marginRight: 8 }}>
                                {layout.title}
                            </span>

                            <span style={{ float: 'right', paddingRight: 8 }}>
                                {/* layout type */}
                                <Label
                                    size="mini"
                                    className="primary"
                                    style={{ marginRight: 4 }}
                                >
                                    <Icon name={layout.type === 'blog' ? 'sn-magazine' : 'sn-file-text'} style={{ marginRight: 6 }} />
                                    {layout.type === 'blog'
                                        ?
                                            <FormattedMessage id="blog.posts title" />
                                        :
                                            <FormattedMessage id="pages.pages title" />
                                    }
                                </Label>
                                
                                {/* standard layout */}
                                {layout.default && 
                                    <Label basic size="mini" className="info" style={{ marginRight: 0 }}>
                                        <Icon name="sn-web" style={{ marginRight: 6 }} />
                                        {props.intl.formatMessage({ id: 'layouts.default layout' })}
                                    </Label>
                                }

                                {/* TO DO: insert offline/draft/online labels here */}
                            </span>
                        </span>
                    </ListItem>
                ))
            }
        </ListMenu>
    );
});

const SelectLayoutButton = props => {
    const [listOpen, setListOpen] = React.useState(false);
    const selectedLayout = props.layouts.find(layout => layout._id === props.layoutid);

    const draftVersion = props.draftContent && props.draftContent.version;
    const liveVersion = props.liveContent && props.liveContent.version; 
    const isUnpublished = !props.liveContent;

    return (
        <React.Fragment>
            <Button 
                icon 
                basic 
                size="small"
                id="layouts-layout-menu"
                onClick={() => setListOpen(true)}
                className={!listOpen ? 'primary' : 'primary active-button'}
                style={{ marginRight: 8, padding: '6px 8px' }}
            >
                <Icon
                    name="sn-web" 
                    className="primary-color"
                    style={{ fontSize: 16, verticalAlign: 'middle', height: 16 }}
                />
                <span style={{ marginLeft: 6, marginRight: 4, verticalAlign: 'middle' }}>
                    {selectedLayout ? selectedLayout.title : ''}
                </span>
                <Icon
                    name={!listOpen ? 'sn-arrow-down2' : 'sn-arrow-up2'}
                    style={{ verticalAlign: 'middle', fontSize: 10, height: 8 }}
                />
            </Button>

            {listOpen && 
                <LayoutList setListOpen={setListOpen} {...props} /> 
            }

            {/* layout status labels */}
            {/* layout type */}
            <Label
                size="mini"
                className="primary"
                style={{ marginRight: 4 }}
            >
                <Icon name={selectedLayout.type === 'blog' ? 'sn-magazine' : 'sn-file-text'} style={{ marginRight: 6 }} />
                {selectedLayout.type === 'blog'
                    ?
                        <FormattedMessage id="blog.posts title" />
                    :
                        <FormattedMessage id="pages.pages title" />
                    }
            </Label>
            
            {/* default layout */}
            {selectedLayout.default && 
                <Label basic size="mini" className="info" style={{ marginRight: 4 }}>
                    <Icon name="sn-web" style={{ marginRight: 6 }} />
                    <FormattedMessage id="layouts.default layout" />
                </Label>
            } 

            {/* draft status label */}
            {(props.hasChanged || draftVersion !== liveVersion) &&
                <Label className="info" size="mini" style={{ marginRight: 4 }}>
                    <Icon name="sn-quill3" style={{ marginRight: 4 }} />
                    <FormattedMessage id="general.draft title" />
                </Label>
            }
            
            {/* online/offline status label */}
            <Label size="mini" className={!isUnpublished ? 'positive' : 'warning'} style={{ margin: 0 }}>
                <Icon
                    name={!isUnpublished ? 'sn-earth2' : 'sn-eye-blocked'}
                    style={{ marginRight: 4 }}
                />
                <FormattedMessage id={`general.${!isUnpublished ? 'online' : 'offline'} title`}/>
            </Label>
            {!isUnpublished &&
                <InfoIcon
                    description={<FormattedMessage id="general.online description" />}
                    position="bottom center"
                    className="positive-color"
                    style={{ pointerEvents: 'auto' }}
                />
            }
        </React.Fragment>
    );
}

export default connect(state => {
    const layoutid = state.sites.layout.params.layoutid;
	const siteid = state.sites.params.siteid;
    const layouts = layoutsSelector(state, siteid, null);
    
    return {
		siteid,
		layoutid,
		layouts,
    };
})(SelectLayoutButton);
