import React, { PureComponent } from 'react';
import { GridRow, GridColumn, Button, Icon, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ChromePicker } from 'react-color';
import tinycolor from 'tinycolor2';

// used for translating color keys
const createColorTranslationFromKey = key => {
    return `options.color ${key.toLowerCase()} title`;
};

class OverviewColor extends PureComponent{
    constructor(props){
        super(props)
        this.state={
            open: false
        }
    }
    sketchPicker() {
		if (this.state.open) {
			return (
				<div className="svift-color-picker">
					<div
						className="cover"
						onClick={e => this.setState({ open: !this.state.open })}
					/>

					<ChromePicker
						disableAlpha={true}
						color={this.props.themeColor.color}
						onChange={(color, event) => {
							const data = { value: color.hex };
							this.props.onChange(event, data);
						}}
					/>
				</div>
			);
		}
		return null;
	}
    render(){
        const {themeColor, onChange, intl, className} = this.props;
        
        const paletteFrameStyle = {
            display: 'inline-block',
            width: '33.33%',
            position: 'relative'
        }

        const paletteBoxShadow = tinycolor(themeColor.color).isDark() ? 'none' : `inset 0 0 0 1px ${tinycolor(themeColor.color).darken(40).setAlpha(.2)}`
        const paletteStyle = {
            height: 16,
            width: '100%',
            boxShadow: paletteBoxShadow
        }

        const paletteIconStyle = {
            color: tinycolor(themeColor.color).isDark() ? tinycolor(themeColor.color).lighten(40) : tinycolor(themeColor.color).darken(40),
            position: 'absolute',
            zIndex: 2,
            opacity: 0.8,
            fontSize: 12,
            left: '50%',
            marginLeft: -12,
            top: '50%',
            marginTop: -12
        }

        return(
            <GridColumn
                computer={2}
                tablet={4}
                mobile={8}
                style={{ padding: 4, backgroundColor: 'white', textAlign: 'center' }}
            >
                <p
                    style={{
                        marginBottom: 4,
                        fontSize: 16,
                        fontWeight: 700,
                        color: tinycolor(themeColor.color).isDark() ? themeColor.color : tinycolor(themeColor.color).darken(30)
                    }}
                >
                    <FormattedMessage id={createColorTranslationFromKey(themeColor.id) || themeColor.id} />
                </p>
                <Button
                    circular
                    icon="sn-droplet2"
                    size="massive"
                    className="color-item-frame"
                    style={{
                        lineHeight: 0.9,
                        color: tinycolor(themeColor.color).isDark() ? tinycolor(themeColor.color).lighten(40) : tinycolor(themeColor.color).darken(40),
                        backgroundColor: !this.state.open ? themeColor.color : tinycolor(themeColor.color).darken(10),
                        boxShadow: this.state.open && 'inset 0 0 0 2px white',
                        margin: 0
                    }}
                    onClick={() => this.setState({ open: !this.state.open })}
                />
                <p
                    style={{
                        marginTop: 4,
                        marginBottom: 4,
                        fontSize: 14,
                        fontWeight: 200,
                        opacity: 0.8,
                        color: tinycolor(themeColor.color).isDark() ? themeColor.color : tinycolor(themeColor.color).darken(30)
                    }}
                >
                    {themeColor.color}
                </p>
                
                {this.sketchPicker()}
                
                <div>
                    {/* Lighten/Darken palette showcase */}
                    <Popup
                        trigger={
                            <div style={paletteFrameStyle}>
                                <Icon circular name="sn-droplet-contrast" style={paletteIconStyle} />
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).darken(20) }, paletteStyle)}/>
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).darken(10) }, paletteStyle)}/>
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).lighten(10) }, paletteStyle)} />
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).lighten(20) }, paletteStyle)} />
                            </div>
                        }
                        content={intl.formatMessage({ id: 'options.color palette examples lighten darken description' })}
                        position="top center"
                    />

                    {/* Saturation palette showcase */}
                    <Popup
                        trigger={
                            <div style={paletteFrameStyle}>
                                <Icon circular name="sn-palette" style={paletteIconStyle} />
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).desaturate(20) }, paletteStyle)}/>
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).desaturate(10) }, paletteStyle)}/>
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).saturate(10) }, paletteStyle)} />
                                <div style={Object.assign({ backgroundColor: tinycolor(themeColor.color).saturate(20) }, paletteStyle)} />
                            </div>
                        }
                        content={intl.formatMessage({ id: 'options.color palette examples saturation description' })}
                        position="top center"
                    />

                    {/* Opacity palette showcase */}
                    <Popup
                        trigger={
                            <div style={paletteFrameStyle}>
                                <Icon circular name="sn-eye" style={paletteIconStyle} />
                                <div style={Object.assign({ backgroundColor: themeColor.color, opacity: 0.25 }, paletteStyle)}/>
                                <div style={Object.assign({ backgroundColor: themeColor.color, opacity: 0.5 }, paletteStyle)} />
                                <div style={Object.assign({ backgroundColor: themeColor.color, opacity: 0.75 }, paletteStyle)} />
                                <div style={Object.assign({ backgroundColor: themeColor.color, opacity: 1 }, paletteStyle)} />
                            </div>
                        }
                        content={intl.formatMessage({ id: 'options.color palette examples opacity description' })}
                        position="top right"
                    />
                </div>
            </GridColumn>
        );
    }
    

}

export default injectIntl(OverviewColor);
