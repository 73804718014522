import React, { PureComponent } from 'react';
import { Menu, Dropdown, DropdownMenu, DropdownItem, Icon } from 'svift-ui';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import onClickOutside from 'components/hoc/onClickOutside';

class DropdownWrapper extends PureComponent {
	render() {
		const { children, unwrapped, style } = this.props;

		if (unwrapped) {
			return <div> {children} </div>;
		}

		return (
			<Menu size="small" compact style={{ ...style, marginRight: 8 }}>
				{children}
			</Menu>
		);
	}
}

class DropdownOption extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			open: false
		};

		this.uniqueId = `${props.option}dropdown`; // Defining a uniquely identifying string to be used if component is controlled (you could store 'fontpickerdropdown' as 'openOption' for example)

		this.tooltip = tooltip.call(this);

		this.onTriggerClick = this.onTriggerClick.bind(this);
		this.onSelectOption = this.onSelectOption.bind(this);
	}

	onClickOutside() {
		this.setState({
			open: false
		});
	}

	onTriggerClick(e) {
		const { uncontrolled, onToggle } = this.props;

		if (uncontrolled) {
			this.setState({
				open: !this.state.open
			});
		} else {
			onToggle(this.uniqueId, e);
		}
	}

	onSelectOption(option, e) {
		const { uncontrolled, onToggle, onChange } = this.props;

		if (!uncontrolled) {
			onToggle(null, e);
		}

		onChange(option);

		e.stopPropagation(); // Gotta stop propagation or the containing dropdown clickhandler triggers too
	}

	render() {
		const { unwrapped, options, value, selectedOption, icon, hoverContent, style } = this.props;

		const selected = this.uniqueId === selectedOption;
		
		let formattedValue;
		
		if (this.props.multiple) {
			formattedValue = Array.isArray(value)
				?
					value.map((item, index) => {
						const formattedItem = item ? `${item.substring(0, 1).toUpperCase()}${item.substring(1)}` : '';

						return (
							<span  style={{ marginRight: '5px' }}>
								{formattedItem}

								<Icon 
									name="sn-cross"
									onClick={() => this.props.onDeselect(index)} 
								/>
							</span>
						);
					})
				:
					''
		} else {
			const valueIsNum = !isNaN(value);

			formattedValue = valueIsNum ? value : (value ? `${value.substring(0, 1).toUpperCase()}${value.substring(1)}` : '');
		}

		return (
			<DropdownWrapper unwrapped={unwrapped} style={style}>
				<Tooltip
					target={this.tooltip.getTarget()}
					hover={this.state.hover}
					hoverContent={hoverContent || formattedValue}
				/>
				
				<Dropdown
					ref={this.tooltip.setTarget}
					multiple={this.props.multiple}
					item
					open={selected || this.state.open}
					text={
						<div>
							{icon && <Icon name={icon} />}
							{formattedValue}
						</div>
					}
					onClick={this.onTriggerClick}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				>
					<DropdownMenu>
						{options.map((option, index) => {
							const { text, value } = option;

							const textIsNum = !isNaN(text);
							const formattedText = textIsNum ? text : (text ? `${text.substring(0, 1).toUpperCase()}${text.substring(1)}` : '');
							
							const displayText = formattedText;

							return (
								<DropdownItem key={index} onClick={(e) => this.onSelectOption(option, e)}>
									{displayText}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</Dropdown>
			</DropdownWrapper>
		);
	}
}

DropdownOption.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func,

	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func
};

export default onClickOutside(DropdownOption);