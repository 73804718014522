import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	SideNav,
	// Icon,
	// ButtonGroup,
	// Button,
	// Divider
} from 'svift-ui';

import Design from 'containers/dashboard/side-navigation/sidenavigation-content/design/Design';
import Pages from 'containers/dashboard/side-navigation/sidenavigation-content/pages-new/Pages';
import Blogs from 'containers/dashboard/side-navigation/sidenavigation-content/blogs-new';
import Layouts from 'containers/dashboard/side-navigation/sidenavigation-content/design/layouts-new/Layouts';
import Forms from 'containers/dashboard/side-navigation/sidenavigation-content/design/forms-new/Forms';
import Settings from 'containers/dashboard/side-navigation/sidenavigation-content/settings/Settings';
import Navigation from 'containers/dashboard/side-navigation/sidenavigation-content/design/navigation/Navigation';
import Files from 'containers/dashboard/side-navigation/sidenavigation-content/files/folders/Folders';

import RendererTest from 'containers/dashboard/side-navigation/sidenavigation-content/renderer-test/Settings';

import { Switch, Route, matchPath } from 'react-router';

const mapStateToProps = state => {
	return {
		sideMenuVisible: state.ui.sideMenuVisible,
		pathname: state.router.location.pathname
	};
};

class Sidenavigation extends Component {
	render() {
		const { sideMenuVisible } = this.props;

		// dont want to render sidenav at all if we are on one of these two routes
		const match = matchPath(this.props.pathname, '/dashboard/:siteid') || matchPath(this.props.pathname, '/dashboard');

		const routeIsDashboardIndex = match && match.isExact;

		if (matchPath(this.props.pathname, '/dashboard/:siteid/activation')) return null;

		// const optionsStyle = {
		// 	position: 'absolute',
		// 	textAlign: 'center',
		// 	paddingTop: 100,
		// 	paddingLeft: 4,
		// 	paddingRight: 4,
		// 	paddingBottom: 16,
		// 	borderBottomLeftRadius: 4,
		// 	color: 'black',
		// 	zIndex: 2,
		// 	right: 0,
		// 	top: 0,
		// 	// bottom: 0,
		// 	width: 'auto',
		// 	height: 'auto',
		// 	backgroundColor: 'white',
		// 	boxShadow: '0px 0px 8px 2px #222',
		// 	content: ''
		// };

		// const labelStyle = {
		// 	opacity: 0.6,
		// 	fontSize: 12,
		// 	marginBottom: 4
		// }

		if (sideMenuVisible && !routeIsDashboardIndex) {
			return (
				<SideNav>
					{/* <div style={optionsStyle}>
						<Icon circular name="sn-quill3" style={{ margin: 0, marginBottom: 6, fontSize: 20, opacity: 0.3 }} /><br />
						<div style={{ marginBottom: 16 }}><Icon name="sn-grid-2" style={{ marginRight: 0, opacity: 0.4 }} /> Section</div>
						<ButtonGroup basic size="mini">
							<Button icon>
								<Icon name="sn-arrow-left2" style={{ fontSize: 8 }} />
							</Button>
							<Button icon style={{ fontSize: 8 }}>
								C (i) <Icon name="sn-arrow-down2" />
							</Button>
							<Button icon>
								<Icon name="sn-arrow-right2" style={{ fontSize: 8 }} />
							</Button>
						</ButtonGroup>
						<Divider style={{ marginBottom: 4 }} />
						<div>
							<div style={labelStyle}>Alignment (i)</div>
							<div style={labelStyle}>Vertical</div>
							<ButtonGroup
								basic
								size="tiny"
								style={{ marginBottom: 8 }}
							>
								<Button icon="sn-vertical_align_top" className="primary active-button" />
								<Button icon="sn-vertical_align_center" />
								<Button icon="sn-vertical_align_bottom" />
							</ButtonGroup>
							<div style={labelStyle}>Horizontal</div>
							<ButtonGroup basic size="tiny">
								<Button icon className="primary active-button">
									<Icon name="sn-vertical_align_top" rotated="counterclockwise" style={{ margin: 0 }} />
								</Button>
								<Button icon>
									<Icon name="sn-vertical_align_center" rotated="clockwise" style={{ margin: 0 }} />
								</Button>
								<Button icon>
									<Icon name="sn-vertical_align_top" rotated="clockwise" style={{ margin: 0 }} />
								</Button>
							</ButtonGroup>
						</div>
						<Divider style={{ marginBottom: 4 }} />
						<div>
							<div style={labelStyle}>BG Image (i)</div>
							<Button fluid className="positive" icon size="tiny">
								<Icon name="sn-image" style={{ marginRight: 6 }} />
								<Icon name="sn-plus2" />
							</Button>
						</div>
						<Divider style={{ marginBottom: 4 }} />
						<div>
							<div style={labelStyle}>Design (i)</div>
							<Button basic fluid size="tiny" icon className="primary" style={{ marginBottom: 8 }}>
								<Icon name="sn-bucket" style={{ marginRight: 6 }} /> / <Icon name="sn-grid-2" style={{ marginLeft: 6, marginRight: 6 }} /> 
								<Icon name="sn-arrow-right8" />
							</Button>
							<div style={labelStyle}>
								<Icon name="sn-bucket" style={{ margin: 0, verticalAlign: 'middle' }} />
								<span style={{ verticalAlign: 'middle' }}>Override (i)</span>
								<Icon name="sn-minus-circle" style={{ marginLeft: 4, marginRight: 0, verticalAlign: 'middle' }} />
							</div>

						</div>
					</div> */}

					<Switch>
						<Route path="/dashboard/:siteid/pages" component={Pages} />
						<Route path="/dashboard/:siteid/blog-posts" component={Blogs} />
						<Route path="/dashboard/:siteid/files" component={Files} />
						<Route path="/dashboard/:siteid/settings" component={Settings} />
						<Route path="/dashboard/:siteid/renderer" component={RendererTest} />

						{/*
							Slightly weird structure because Menus (Navigation) and Layouts live under design, but are unrelated to theme editing
							Could be worth cleaning up later so it becomes more intuitive to work with
						*/}
						
						<Route path="/dashboard/:siteid/design/layouts/:layoutid?" component={Layouts} />
						<Route path="/dashboard/:siteid/design/navigation/:navid?" component={Navigation} />
						<Route path="/dashboard/:siteid/design/forms/:formid?" component={Forms} />
						<Route path="/dashboard/:siteid/design" component={Design} />
					</Switch>
				</SideNav>
			);
		}

		return null;
	}
}

export default connect(mapStateToProps)(Sidenavigation);
