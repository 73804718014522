import React, { PureComponent } from 'react';
import { Grid, GridColumn } from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';

import actions from 'store/actions';
import { connect } from 'react-redux';
import SiteForm from 'components/forms/site';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import SviftGA from 'utils/svift-ga';

function mapStateToProps(state) {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		siteid,
		site,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		deleteSite: id => dispatch(actions.sites.deleteSite(id)),
		copySite: ({ siteID, newSiteName }) => dispatch(actions.sites.copySite({ siteID, newSiteName })),
		updateSite: (siteid, data) => dispatch(actions.sites.updateSite(siteid, data))
	};
}

class Site extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false
		};

		this.updateSite = this.updateSite.bind(this);
		this.deleteSite = this.deleteSite.bind(this);
		this.copySite = this.copySite.bind(this);
	}

	async updateSite(data) {
		this.setState({
			loading: true
		});
		try {
			await this.props.updateSite(this.props.siteid, data);

			SviftGA.SITES.MODIFIED();
			//this.props.updateSite(this.props.siteid, data).then(result => {	
			//})
			this.setState({
				loading: false
			});

			this.props.createNotification({
				content: this.props.intl.formatMessage({ id: 'settings.save changes notification' }),
				className: 'positive',
				position: 'topCenter',
				icon: 'sn-cloud-upload',
				deleteTime: 2000
			});
	
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async copySite(newSiteName) {
		this.setState({
			loading: true
		});

		try {
			await this.props.copySite({ siteID: this.props.siteid, newSiteName });

			SviftGA.SITES.COPIED();
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deleteSite() {
		const { siteid, deleteSite } = this.props;

		this.setState({
			loading: true
		});

		try {
			await deleteSite(siteid);

			SviftGA.SITES.DELETED();
		} catch (err) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(err);
		}
	}

	render() {
		const { site } = this.props;

		return (
			<SiteForm
				form="site_update"
				update
				loading={this.state.loading}
				enableReinitialize
				initialValues={{
					name: site.name,
					timeZone: site.timeZone,
					standardLanguage: site.standardLanguage,
					maintenanceMode: site.maintenanceMode,
					blockCrawlers: site.blockCrawlers,
					frontPage: site.frontPage,
					contactEmail: site.contactEmail
				}}
				delete={this.deleteSite}
				copy={this.copySite}
				onSubmit={this.updateSite}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(Site)));