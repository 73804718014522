import React, { PureComponent } from 'react';
import createApi from 'api';
import Stripe from './Stripe';
import {
    Loader,
    Button,
    Icon,
    Grid,
    GridColumn,
    Dropdown,
    DropdownDivider,
    DropdownMenu,
    DropdownHeader,
    DropdownItem,
    Message,
    FormCheckbox,
} from 'svift-ui';
import TermsAndConditions from 'components/TermsAndConditions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
const api = createApi();

const mapStateToProps = (state) => {
    return {
        siteID: state.sites.params.siteid,
    };
};

const formatLast4 = ({ last4, brand }) => `${brand}: XXXX XXXX XXXX ${last4}`;

const resolveIcon = (card) => {
    switch (card && card.brand.toLowerCase()) {
        case 'mastercard':
            return 'mastercard';
        case 'visa':
            return 'visa';

        default:
            return 'credit card outline';
    }
};

/*
    To do:

    Very messy state handling in this component (and the places it's used) - clean up later
*/

class CardPicker extends PureComponent {
    constructor(props) {
        super(props);

        props.setCard(null);

        this.state = {
            cards: null,
            selectedCard: null,
            error: null,
        };
    }

    async syncCards() {
        try {
            const cards = await api.activation.getCards({
                siteID: this.props.siteID,
            });

            const selectedCard = this.props.initialCard
                ? cards.find((card) => card.id === this.props.initialCard)
                : cards[cards.length - 1];

            this.props.setCard((selectedCard && selectedCard.id) || null);

            this.setState({
                cards,
                selectedCard: selectedCard || null,
            });
        } catch (e) {
            if (this.props.handleErrors) {
                this.props.handleErrors(e);
            } else {
                if (e.type === 'ClientError') {
                    this.setState({
                        error: e.intlReadable,
                    });
                } else {
                    this.setState({
                        error: 'activation.unknown error',
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.syncCards();
    }

    renderCardDropdown = () => {
        return (
            <Dropdown
                fluid
                labeled
                button
                text={
                    this.state.selectedCard
                        ? formatLast4(this.state.selectedCard)
                        : this.props.intl.formatMessage({
                              id:
                                  'activation.order add new payment method title',
                          })
                }
                icon={resolveIcon(this.state.selectedCard)}
                className="basic icon primary"
                style={{ fontSize: 18, marginBottom: 8 }}
            >
                <DropdownMenu>
                    <DropdownHeader
                        icon="credit card outline"
                        content={this.props.intl.formatMessage({
                            id: 'activation.order saved payment methods title',
                        })}
                    />

                    {this.state.cards.length > 0 && (
                        <React.Fragment>
                            <DropdownDivider />

                            {this.state.cards.map((card) => {
                                return (
                                    <DropdownItem
                                        key={card.id}
                                        onClick={() => {
                                            this.props.setCard(card.id);

                                            this.setState({
                                                selectedCard: card,
                                            });
                                        }}
                                        icon={resolveIcon(card)}
                                        text={formatLast4(card)}
                                    />
                                );
                            })}
                        </React.Fragment>
                    )}

                    <DropdownDivider />

                    <DropdownItem
                        icon="plus"
                        onClick={() => {
                            this.props.setCard(null);

                            this.setState({ selectedCard: null });
                        }}
                        text={this.props.intl.formatMessage({
                            id: 'activation.order add new payment method title',
                        })}
                    />
                </DropdownMenu>
            </Dropdown>
        );
    };

    render() {
        if (this.state.error) {
            return (
                <Message
                    className="error"
                    size="large"
                    style={{ textAlign: 'left' }}
                    icon="sn-warning"
                    content={<FormattedMessage id={this.state.error} />}
                />
            );
        }

        if (this.state.cards === null) {
            return <Loader size="massive" style={{ fontSize: 40 }} />;
        }

        return (
            <Grid centered>
                <GridColumn computer={16} textAlign="center">
                    <div style={{ marginBottom: 8 }}>
                        <label>
                            <Icon
                                name="credit card outline"
                                style={{ opacity: 0.6 }}
                            />
                            <FormattedMessage id="activation.order select payment method label" />
                        </label>
                    </div>

                    {/* payment methods dropdown */}
                    {this.renderCardDropdown()}

                    {/* stripe payment form */}
                    {this.state.selectedCard === null && (
                        <div>
                            <Stripe
                                onError={console.log}
                                setTokenGenerator={this.props.setTokenGenerator}
                            />

                            {/* new payment disclaimer */}
                            <p
                                className="small-text"
                                style={{ marginTop: 8, textAlign: 'left' }}
                            >
                                <Icon name="sn-info" />
                                <FormattedMessage id="activation.order payment disclaimer" />
                            </p>
                        </div>
                    )}
                </GridColumn>

                {typeof this.props.toggleAgreeTerms !== 'undefined' && (
                    <GridColumn
                        computer={16}
                        textAlign="left"
                        style={{ paddingTop: 0 }}
                    >
                        {/* Terms and conditions */}
                        <div style={{ marginBottom: 12 }}>
                            <TermsAndConditions />
                        </div>

                        {/* Agree to terms */}
                        <FormCheckbox
                            label={this.props.intl.formatMessage({
                                id: 'activation.order accept order',
                            })}
                            checked={this.props.agreeTerms}
                            onChange={this.props.toggleAgreeTerms}
                            style={{ marginLeft: 2 }}
                        />
                    </GridColumn>
                )}

                {this.props.handleSetCard && (
                    <Button
                        className="positive"
                        onClick={this.props.handleSetCard}
                        disabled={
                            !this.state.selectedCard
                                ? false
                                : this.props.initialCard ===
                                  this.state.selectedCard.id
                        }
                    >
                        <Icon name="sn-cloud-upload" />
                        <FormattedMessage id="settings.subscription update payment method button" />
                    </Button>
                )}
            </Grid>
        );
    }
}

export default connect(mapStateToProps)(injectIntl(CardPicker));
