import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { Grid, GridRow, GridColumn, IconGroup, Icon, Divider, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

class SectionPresets extends PureComponent {
	constructor() {
		super();

		this.getPresetOptions = (style) => [
			<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-grid-2" /><FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} /></span>,
			<ColorPicker
				title={this.props.intl.formatMessage({ id: 'options.background color title' })}
				key="color-picker-background"
				icons={['sn-paint-format']}
				colors={this.props.theme.colors}
				onChange={(newColor) => this.props.updatePresetOption(this.props.presetTitle, 'backgroundColor', newColor)}
				value={this.props.preset.backgroundColor}

				option="color-picker-background"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<Padding
				key="padding"
				value={this.props.preset.padding}
				preset={this.props.preset}
				theme={this.props.theme}
				onChange={(key, newValue) => {
					if (key === 'generalPadding') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								paddingBottom: 1,
								paddingTop: 1
							});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								generalPadding: newValue / 100
							});
						}

						return;
					}

					const newPadding = {
						...this.props.preset.padding,
						[key]: newValue / 100
					};

					if (newValue === null) {
						delete newPadding[key];
					}

					delete newPadding.generalPadding;

					if (typeof newPadding.paddingBottom === 'undefined') {
						newPadding.paddingBottom = 1;
						newPadding.paddingTop = 1;
					}

					this.props.updatePresetOption(this.props.presetTitle, 'padding', newPadding);
				}}

				option="Padding"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}

				optIn={{
					paddingBottom: false,
					paddingTop: false,
					paddingLeft: true,
					paddingRight: true,
					generalPadding: true
				}}
			/>,
			<Shadow
				key="shadow"
				value={this.props.preset.boxShadow || {}}
				colors={this.props.theme.colors}
				onChange={(newShadow) => {
					this.props.updatePresetOption(this.props.presetTitle, 'boxShadow', newShadow); //onChange('boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);

					e.stopPropagation();
				}}
			/>
		];

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const { intl } = this.props;
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const computedStyles = computePreset(this.props.preset, this.props.theme);

		return (
			<div style={{ paddingBottom: 16, marginBottom: 24, borderBottom: '1px dashed lightgrey' }}>
				<OptionPanel
					icon="sn-bucket"
					title="elements.section title"
					options={this.getPresetOptions(computedStyles)}
					open={selected}
					target={this.optionTooltipRef.current}
				/>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
					ref={this.optionTooltipRef}
				>
					<Grid padded centered className="sub-target" style={computedStyles}>
						<Popup
							trigger={
								<IconGroup style={{ position: 'absolute', top: 32, left: 0, zIndex: 2 }}>
									<Icon name="sn-grid-2" className="primary" style={{ fontSize: 48 }} />
									<Icon corner name="sn-info" className="primary" style={{ fontSize: 24, bottom: -10, right: 4, borderRadius: '50%', background: 'white' }} />
								</IconGroup>
							}
							content={intl.formatMessage({ id: 'elements.section dummy content' })}
							position="bottom left"
						/>
						{[...Array(2)].map((x, i) => (
							<GridRow key={i} style={{ border: '1px dashed grey' }}>
								<Popup
									trigger={
										<IconGroup style={{ opacity: 0.1, margin: '0 0 40px 0' }}>
											<Icon name="sn-columns" style={{ fontSize: 64, margin: 0 }} />
											<Icon corner name="sn-info" style={{ fontSize: 32, bottom: -8, right: -18, borderRadius: '50%', background: 'white' }} />
										</IconGroup>
									}
									content={intl.formatMessage({ id: 'elements.section dummy row description' })}
									position="bottom center"
								/>
							</GridRow>
						))}
					</Grid>
				</PresetContainer>
			</div>
		);
	}
}

SectionPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(SectionPresets));