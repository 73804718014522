import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { IconGroup, Icon, Divider, Popup, Message, Grid, GridRow, GridColumn } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import { tooltip } from 'components/tooltip/Tooltip';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';

class ColumnPresets extends PureComponent {
	constructor() {
		super();

		this.getPresetOptions = style => [
			<ColorPicker
				title={this.props.intl.formatMessage({ id: 'options.background color title' })}
				key="color-picker-background"
				icons={['sn-paint-format']}
				colors={this.props.theme.colors}
				onChange={(newColor) => this.props.updatePresetOption(this.props.presetTitle, 'backgroundColor', newColor)}
				value={this.props.preset.backgroundColor}

				option="color-picker-background"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<Border
				key="border-style"
				value={this.props.preset.border}
				onChange={newBorder => this.props.updatePresetOption(this.props.presetTitle, 'border', newBorder)}
				colors={this.props.theme.colors}

				option="border-style"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<BorderRadius
				max={240}
				key="border-radius"
				value={this.props.preset.borderRadius}
				onChange={(key, newValue) => {
					if (key === 'generalBorderRadius') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', {});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', {
								generalBorderRadius: newValue
							});
						}

						return;
					}

					const newBorderRadius = {
						...this.props.preset.borderRadius,
						[key]: newValue
					};

					if (newValue === null) {
						delete newBorderRadius[key];
					}

					delete newBorderRadius.generalBorderRadius;

					this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', newBorderRadius);
				}}
				colors={this.props.theme.colors}

				option="border-radius"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<Shadow
				key="shadow"
				value={this.props.preset.boxShadow}
				colors={this.props.theme.colors}
				onChange={(newShadow) => {
					this.props.updatePresetOption(this.props.presetTitle, 'boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);

					e.stopPropagation();
				}}
			/>
		];

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const { intl } = this.props;
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const computedStyles = computePreset(this.props.preset, this.props.theme);

		return (
			<div style={{ paddingBottom: 16, marginBottom: 24, borderBottom: '1px dashed lightgrey' }}>
				<div style={Object.assign({ position: 'relative', width: '100%', maxWidth: 960, textAlign: 'center', margin: 'auto' }, computedStyles)}>
					<OptionPanel
						icon="sn-bucket"
						title="elements.row title"
						target={this.optionTooltipRef.current}
						open={selected}
						options={this.getPresetOptions(computedStyles)}
					/>

					<PresetContainer
						selected={selected}
						onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
						ref={this.optionTooltipRef}
					>
						<Popup
							trigger={
								<IconGroup style={{ position: 'absolute', top: 38, left: 24, zIndex: 2 }}>
									<Icon name="sn-enlarge" className="primary" style={{ fontSize: 48 }} />
									<Icon corner name="sn-info" className="primary" style={{ fontSize: 28, bottom: -8, right: -8, borderRadius: '50%', background: 'white' }} />
								</IconGroup>
							}
							content={intl.formatMessage({ id: 'elements.container dummy content' })}
							position="bottom left"
						/>

						<div className="sub-target">
							{[...Array(3)].map((x, i) => (
								<Grid padded key={i} columns={1} centered style={{ border: '1px dashed grey' }}>
									<GridRow>
										<GridColumn textAlign="center">
											<Popup
												trigger={
													<IconGroup style={{ opacity: 0.1, margin: '0 0 40px 0' }}>
														<Icon name="sn-grid-2" style={{ fontSize: 72, margin: 0 }} />
														<Icon corner name="sn-info" style={{ fontSize: 32, bottom: -8, right: -14, borderRadius: '50%', background: 'white' }} />
													</IconGroup>
												}
												content={intl.formatMessage({ id: 'elements.container dummy section description' })}
												position="bottom center"
											/>
										</GridColumn>
									</GridRow>
								</Grid>
							))}
						</div>
					</PresetContainer>
				</div>
			</div>
		);
	}
}

ColumnPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(ColumnPresets));