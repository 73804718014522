import React, { memo, useCallback } from 'react';
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Radio from 'components/option-library/primitives/radio';
import NumberPicker from 'components/option-library/primitives/input/number';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import Label from 'components/option-library/label';
import FilePicker from 'components/option-library/primitives/file';
import Checkbox from 'components/option-library/primitives/checkbox';
import Section from 'components/editor-v2/editor-interface/options/Section';
import { injectIntl } from 'react-intl';
// import key from 'svift-library/key';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();
    const currentBackgroundVideo = node.props.backgroundVideo || {}; //key('node.props.backgroundVideo')(context);

    const onChange = useCallback((key, value, ...args) => { 
        updateState(context => {
            const newBackgroundVideo = {
                ...context.nodeMap[node.id].props.backgroundVideo,
                [key]: value
            };

            if (typeof value === 'undefined') {
                delete newBackgroundVideo[key];
            }

            const nextNodeProps = {
                ...context.nodeMap[node.id].props,
                backgroundVideo: newBackgroundVideo
            };
            
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: nextNodeProps
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                icon="sn-play"
                title={intl.formatMessage({ id: 'options.background video title' })}
            >
                <Label>
                    <FilePicker 
                        tabs={['files']}
                        filter={file => file.meta.mimeType.startsWith('video')}
                        onChange={file => {
                            onChange('src', file, 'Set background video', false);
                        }}
                        value={currentBackgroundVideo.src}
                    />
                </Label>

                <Label
                    collapsed
                    labelIcon="sn-eye"
                    label={intl.formatMessage({ id: 'options.background video opacity title' })}
                >
                    <NumberPicker 
                        name={intl.formatMessage({ id: 'options.background video opacity title' })}
                        disableUndoRedo
                        unit="%"
                        min={0}
                        max={100}
                        value={currentBackgroundVideo.opacity}
                        placeholder={intl.formatMessage({ id: 'options.background video opacity input placeholder' })}
                        onChange={val => onChange('opacity', val, 'background video opacity')}
                    />
                </Label>

                {/* Not simple to do */}
                {/* <Label
                    labelIcon="sn-grab"
                    label={intl.formatMessage({ id: 'options.background video fixed label' })}
                >
                    <Checkbox
                        icon="sn-grab"
                        label={intl.formatMessage({ id: 'options.background video fixed title' })}
                        value={currentBackgroundVideo.fixed}
                        onChange={val => onChange('fixed', val, 'background video fixed', false)}
                    />
                </Label> */}
            </Section>
        </Accordion>
    );
}));