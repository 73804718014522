import React, { useState, useMemo, useRef } from 'react';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { Icon, Message, Header, Loader, Divider } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import config from 'config';
import uuid from 'uuid/v4';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import useRendererData from 'svift-library/client/renderer/data/client/useRendererData';
import useRendererState from 'svift-library/client/renderer/useRendererState';
import useDimensions from 'svift-library/client/renderer/useDimensions';
import PreviewContainer from 'components/editor-v2/editor-interface/preview/PreviewContainer';
import ToggleDraftAndLivePreview from 'components/editor-v2/editor-interface/preview/ToggleDraftAndLivePreview';
import OpenPreview from 'components/editor-v2/editor-interface/preview/OpenPreview';
import createExtensions from 'components/editor-v2/extend';
import { connect } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import layoutActions from 'store/actions/sites/layouts';
import SelectLayout from './SelectLayout';
import LayoutSettings from './LayoutSettings';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import Notification from 'components/hoc/notification';

// import fileActions from 'store/actions/sites/files';
import { filesInActiveFolder } from 'store/selectors/sites/files';

const mapStateToProps = (state) => {
    return {
        files: filesInActiveFolder(state)
    };
};

const RendererData = connect(mapStateToProps)(props => {
    const [previewMode, setPreviewMode] = useState(null);

    const { data, error, errorMessage, loading } = useRendererData({
        siteID: props.siteID,
        contentID: props.layoutID, 
        contentType: 'layout',
        isAdmin: true,
        host: config.coreDomain,
        basePath: '/sites/renderer',
        refetchOnValueChange: [props.siteID, props.layoutID, props.files]
    });

    // console.log('refetching')
    
    const deviceRef = useRef();
    const device = useDimensions(deviceRef);

    if (loading) return (
        <Message size="massive" className="info" style={{ padding: '64px 24px 96px 24px', textAlign: 'center' }}>
            {/* loading message */}
            <div style={{ marginTop: 0, marginBottom: 40 }}>
                <div>
                    <Icon
                        name="sn-web"
                        style={{
                            margin: '0 0 24px 0',
                            fontSize: 96,
                            width: 96,
                            opacity: 0.5
                        }}
                    />
                </div>
                <span style={{ fontSize: 40 }}>
                    <FormattedMessage id="layouts.loading layout" />
                </span>
            </div>
            {/* load spinner */}
            <Loader size="massive" className="info" />
        </Message>
    );
    if (error) return errorMessage;

    // console.log(data, 'data');

    return React.cloneElement(props.children, {
        // data: syncedData,
        data,
        previewMode,
        device,
        deviceRef,
        setPreviewMode,
        ...props
    });
});

const mapDispatchToProps = dispatch => ({
	publishDraft: (siteid, layoutid, nodeMap) => dispatch(layoutActions.publishDraft(siteid, layoutid, nodeMap)),
	saveDraft: (siteid, layoutid, nodeMap) => dispatch(layoutActions.saveDraft(siteid, layoutid, nodeMap))
});

const LayoutRenderer = Notification(injectIntl(connect(null, mapDispatchToProps)(props => {
    const { createNotification, data, device, deviceRef, previewMode, setPreviewMode, publishDraft, saveDraft, intl, layoutID, siteID } = props;
    const { extend, extendAll, renderAtRoot } = useMemo(() => {
        return createExtensions(previewMode, { isBlogLayout: data.content.type === 'blog' });
    }, [previewMode]); 

    const layoutProps = useMemo(() => {
        const props = {
            ...data,
            device,
            staticKeys: ['content'],
            resolveContent: state => state.content.content,
            resolvePlaceholder: () => {
                return (
                    <div style={{ padding: 0, backgroundColor: 'white' }}>
                        <Message
                            id="content-frame"
                            style={{ padding: '40px 32px 96px 32px', minHeight: 320, textAlign: 'center', border: '4px solid rgb(27, 115, 171)' }}
                            className="info"
                        >
                            <Icon name="sn-quill4" style={{ fontSize: 80, margin: 0, marginBottom: 24 }} />

                            <Header as="h1">
                                <FormattedMessage id="layouts.content message header" />
                            </Header>

                            <div style={{ maxWidth: 440, margin: 'auto' }}>
                                <Divider />

                                <p>
                                    <FormattedMessage id="layouts.content message description" />
                                </p>
                            </div>
                        </Message>
                    </div>
                );
            },
            onSave: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await saveDraft(siteID, layoutID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.LAYOUTS.DRAFT_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'layouts.save notification' }),
                    className: 'positive',
                    position: 'topCenter',
                    icon: 'sn-cloud-upload',
                    deleteTime: 2000
                });

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            onPublish: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await publishDraft(siteID, layoutID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.LAYOUTS.LIVE_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'layouts.publish notification' }),
                    className: 'warning',
                    position: 'topCenter',
                    icon: 'sn-sphere',
                    deleteTime: 2000
                });

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            },
                            live: {
                                ...state.content.content.live,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            extend
        };

        if (previewMode === null) {
            props.extend = {
                ...extend,
                all: extendAll,
            };
        }

        if (previewMode === 'draft' || previewMode === null) {
            props.useDraft = true;
        }

        return props;
    }, [data, previewMode, device]);

    const layoutState = useRendererState(layoutProps);

    const renderPreview = React.useCallback(() => {
        const { all, ...rest } = layoutState.rendererState.extend;

        if (previewMode === 'live') {
            return (
                <Renderer
                    key={previewMode}
                    {...{
                        ...layoutState,
                        rendererState: {
                            ...layoutState.rendererState,
                            nodeMap: layoutState.rendererState.content.content.live.nodeMap,
                            breakpoints: layoutState.rendererState.content.content.live.breakpoints,
                            extend: rest
                        }
                    }}
                />
            );
        }

        return (
            <Renderer
                key={previewMode}
                {...{
                    ...layoutState,
                    rendererState: {
                        ...layoutState.rendererState,
                        extend: rest
                    }
                }}
            />
        );
    }, [previewMode, layoutState])

    const layoutTitle = data.content.title;
    const createLayoutPopup = useTooltip();
    const closePreviewPopup = useTooltip();
    const layoutSettingsPopup = useTooltip();

    // console.log(previewMode, 'preview mode')
    // console.log(layoutState, 'layout state')

    return (
        <PreviewContainer ref={deviceRef} active={previewMode}>
            {/* create layout / close preview popups */}
            <Popup
                target={createLayoutPopup.current}
                disabled={previewMode !== null}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="layouts.create new layout" />
            </Popup>
            <Popup
                target={closePreviewPopup.current}
                disabled={previewMode === null}
                position="bottom center"
                zIndex={999999} // above preview container
            >
                <FormattedMessage id="general.close preview popup" />
            </Popup>

            <AppNavigation
                previewMode={previewMode}
                appIcon={previewMode === null ? 'sn-web' : 'sn-eye'}
                appTitle={intl.formatMessage({ id: `${previewMode === null ? 'layouts.title' : 'general.preview title'}`})}
                appActionClassName={previewMode === null ? 'positive' : 'info'}
                appActionStyle={{ padding: 0 }} // fix to facilitate the alternative rendering method of create new page button (padding is set on each button)
                appAction={
                    previewMode === null
                        ?
                            // create new layout button
                            <LayoutSettings
                                renderButton={toggleFormOpen => {
                                    return (
                                        <div
                                            id="layouts-new-layout-button"
                                            ref={createLayoutPopup}
                                            onClick={() => toggleFormOpen('create')}
                                            style={{ padding: 6 }}
                                        >
                                            <Icon name="sn-plus3" style={{ fontSize: 12, height: 12, verticalAlign: 'middle' }} />
                                            <span style={{ marginLeft: 4, marginRight: 4, verticalAlign: 'middle' }}>
                                                <FormattedMessage id="layouts.new" />
                                            </span>
                                            <Icon name="sn-web" style={{ fontSize: 16, height: 16, verticalAlign: 'middle' }} />
                                        </div>
                                    );
                                }}
                            />
                        :
                            <div
                                ref={closePreviewPopup}
                                onClick={() => {setPreviewMode(null)}}
                                style={{ padding: 6 }}
                            >
                                <Icon name="sn-cross2" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                {/* <span style={{ verticalAlign: 'middle' }}>
                                    <FormattedMessage id="general.close" />
                                </span> */}
                                <Icon name="sn-eye" style={{ fontSize: 16, height: 16, marginRight: 0, verticalAlign: 'middle' }} />
                            </div>
                }
            >
                <div className="left-panel">
                {previewMode === null
                    ?
                        <SelectLayout 
                            draftContent={layoutState.rendererState.content.content.draft}
                            liveContent={layoutState.rendererState.content.content.live}
                            hasChanged={layoutState.rendererState.hasChanged}
                        />
                    :
                        // active layout title + draft/live (preview mode)
                        <React.Fragment>
                            {/* active layout title */}
                            <Icon name="sn-web" className="primary-color" style={{ fontSize: 24, marginRight: 6, opacity: 0.6 }} />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginRight: 16
                                }}
                            >
                                {layoutTitle}
                            </span>
                            
                            {/* preview toggle */}
                            <ToggleDraftAndLivePreview
                                onChange={setPreviewMode}
                                value={previewMode}
                                hasLiveContent={!!layoutState.rendererState.content.content.live}
                            />
                        </React.Fragment>
                }

                {/* <EditLayout /> */}
                {previewMode === null &&
                    <React.Fragment>
                        {/* layout settings */}
                        <Popup
                            target={layoutSettingsPopup.current}
                            position="bottom center"
                            zIndex={997}
                        >
                            <FormattedMessage id="layouts.settings" />
                        </Popup>
                        <LayoutSettings
                            renderButton={toggleFormOpen => {
                                return (
                                    <Icon
                                        link
                                        name="setting"
                                        id="layouts-layout-menu-settings"
                                        ref={layoutSettingsPopup}
                                        onClick={() => toggleFormOpen('update')}
                                        className="primary-color"
                                        style={{ fontSize: 24, marginLeft: 0, marginRight: 16 }}
                                    />
                                );
                            }}
                        />

                        {/* undo + redo */}
                        <PortalHook id="undo-redo-portal" />
                    </React.Fragment>
                }
                </div>
                
                <div className="right-panel">
                {/* device picker */}
                <PortalHook id="device-picker-portal" style={{ marginLeft: 'auto' }} />

                {/* <ToggleDraftAndLivePreview 
                    onChange={setPreviewMode}
                    value={previewMode}
                /> */}

                {/* open preview + save and publish */}
                {previewMode === null && 
                    <React.Fragment>
                        <OpenPreview
                            onChange={setPreviewMode}
                            value={previewMode}
                            style={{ marginRight: 16 }}
                        />
                        <PortalHook id="save-or-publish-portal" />
                    </React.Fragment>
                }
                </div>
            </AppNavigation>
            
            {/* renderer (edit vs. preview mode) */}
            {previewMode !== null 
                ?
                    renderPreview()
                :
                    <Renderer
                        {...layoutState}
                        // key={layoutID}
                        renderAtRoot={renderAtRoot}
                    />
            }
        </PreviewContainer>
    );
})));

export default props => {
    return (
        <RendererData {...props}>
            <LayoutRenderer />
        </RendererData>
    );
};