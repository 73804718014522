import React, { PureComponent } from 'react';
import { Box, Icon, Header } from 'svift-ui';
import PropTypes from 'prop-types';
import InfoIcon from 'components/InfoIcon';

class Section extends PureComponent {
	getY() {
		const { top } = this.container.getBoundingClientRect();
		const scrollContainer = document.getElementById('app-container');
		const { scrollTop } = scrollContainer;

		const compensateForTopNav = 53; // Top navigation is fixed, so our page actually begins 53 pixels lower than it would otherwise
		const compensateForIcon = 40; // Icon is round and extends outside the bounds of the container, so we push it a bit further
		const compensate = compensateForTopNav + compensateForIcon;

		const targetY = top + scrollTop - compensate;

		return targetY;
	}

	getTop() {
		const { top } = this.container.getBoundingClientRect();

		return top;
	}

	goToSection() {
		const y = this.getY();

		window.scrollTo(0, y);
	}

	render() {
		const { icon, name, infoIcon, children, anchor } = this.props;

		return (
			<div
				ref={el => {
					this.container = el;
				}}
				id={anchor}
			>
				<Box style={{ maxWidth: 800 }}>
					{icon ?
						<div className="box-icon-container">
							<Icon circular name={icon} className="box-icon" />
						</div>
						:
						null
					}

					{name ?
						<Header as="h3">
							{name} {infoIcon ? <InfoIcon description={infoIcon} />  : null}
						</Header>
						:
						null
					}
					{children}
				</Box>
			</div>
		);
	}
}

export default Section;