import React, { PureComponent } from 'react';
import {
    Input,
    Message,
    Icon,
    Button,
    Label
} from 'svift-ui';
// import key from 'utils/key';
import uuid from 'uuid/v4';
import youtube from 'utils/youtube';
import { injectIntl, FormattedMessage } from 'react-intl';

class YoutubeTab extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            userInput: '',
            videoID: null
        };
    }

    onChange = (e, { value }) => {
        const id = youtube.extractIDFromString(value);

        this.setState({
            userInput: value,
            videoID: id || null
        });

        // if (id) {
        //     this.props.select({
        //         video_id: id,
        //         provider: 'youtube'
        //     });
        // } else {
        //     this.props.select(null);

        //     return;           
        // }
    }

    onSelectVideo = () => {
        this.props.select({
            provider: 'youtube',
            video_id: this.state.videoID,
            id: uuid() //this.state.videoID
        });
    }

    render() {
        // const value = this.state.videoID; //key('selected.video_id')(this.props) || '';
        const invalidValue = this.state.userInput && !this.state.videoID;

        return (
            <div>
                <label style={{ display: 'block', marginBottom: 8, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Icon name="sn-youtube" style={{ fontSize: 24, opacity: 0.5, verticalAlign: 'middle' }} />
                    <FormattedMessage id="elements.video youtube url input label" />
                    {/* <InfoIcon description="Here will be a description of how to insert a youtube URL" /> */}
                </label>
                <Input
                    size="massive"
                    placeholder={this.props.intl.formatMessage({ id: 'elements.video url input placeholder' })}
                    onChange={this.onChange}
                    icon="sn-link"
                    iconPosition="left"
                    value={this.state.userInput}
                    style={{ width: '100%', marginBottom: 16, zIndex: 1 }}
                />

                {invalidValue &&
                    // transl8
                    <p>Input does not match a youtube video - please ensure you put a valid url address to a youtube video</p> // TRANSL8
                }

                {this.state.videoID &&
                    <React.Fragment>
                        <Icon
                            circular name="sn-arrow-down8"
                            style={{ fontSize: 24, opacity: 0.5, display: 'block', marginTop: -24, marginBottom: 12, marginLeft: 'auto', marginRight: 'auto', zIndex: 0 }}
                        />
                        <Message
                            className="info"
                            style={{ textAlign: 'center', maxWidth: 640, margin: 'auto' }}
                        >
                            <div style={{ marginBottom: 16 }}>
                                <Label
                                    basic
                                    size="large"
                                    className="secondary"
                                    style={{ textAlign: 'left' }}
                                >
                                    <Icon name="sn-youtube" style={{ fontSize: 32, float: 'left', marginRight: 8 }} /> 
                                    <FormattedMessage id="elements.video preview message description" />
                                </Label>
                            </div>
                            <div 
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    paddingTop: '56.25%',
                                    marginBottom: 8
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0
                                    }}
                                >
                                    <iframe 
                                        width="100%"
                                        height="100%" 
                                        src={`https://www.youtube.com/embed/${this.state.videoID}`}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                            <Button
                                size="large"
                                className="secondary"
                                onClick={this.onSelectVideo}
                            >
                                <Icon name="sn-plus2" style={{ marginRight: 6 }} />
                                <FormattedMessage id="elements.video preview message button" />
                            </Button>
                        </Message>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default injectIntl(YoutubeTab);