import { PureComponent } from 'react';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';

class Placeholder extends PureComponent {
    static mapContextToProps = (props, state) => {
        return shallowCompare({
            resolvePlaceholder: props.context.resolvePlaceholder
        }, state);
    }
    
    render() {
        return this.props.resolvePlaceholder();
    }
}

export default Placeholder;