import { createSelector } from 'reselect';

const selectPages = state => state.sites.pages.pages;
const selectContent = state => state.sites.content.content;
const selectPageIds = (state, siteid) => {
	const site = state.sites.sites[siteid];

	return site ? state.sites.sites[siteid].pages : [];
};

const pagesSelector = createSelector(
	selectPages,
	selectPageIds,
	selectContent,
	(pages, pageIds, content) => {
		return pageIds.map(pageid => {
			const page = pages[pageid];

			return {
				...page,
				content: content[page.content]
			};
		});
	},
);

// Takes a series of arguments corresponding to keys in the page object; then it returns a memoized version of the page object including only the properties matching the given keys
// This is useful for getting very 'focused' objects that only contain the data we need, making us able to avoid excessive rerendering
// Disclaimer: memoization doesnt work until we've implemented a custom equality check (probably just a shallow comparison on each key in the object)
const makePageSelector = (...args) => {
	return createSelector(
		page => {
			if (!page) return page;

			return args.reduce((acc, key) => {
				acc[key] = page[key];

				return acc;
			}, {});
		},
		page => page,
	);
};

export { makePageSelector, pagesSelector };