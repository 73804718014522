import { EditorState, RichUtils, Modifier } from 'draft-js';

export const toggleInlineStyle = (inlineType, editorState) => {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineType);

    return newState;
};

export const toggleLink = (value, editorState, entityData, block, linkKey) => {
    let currentEditorState = editorState;
    let selection = editorState.getSelection();

    if (entityData) {
        let entityRange;

        block.findEntityRanges(
            value => value.get('entity') === linkKey,
            (start, end) => {
                entityRange = {
                    start,
                    end,
                    text: block.get('text').slice(start, end)
                };
            }
        );

        if (entityRange) {
            selection = selection.merge({
                anchorOffset: entityRange.start,
                focusOffset: entityRange.end,
            });
        }
        
        currentEditorState = EditorState.forceSelection(editorState, selection.merge({
            anchorOffset: entityRange.start,
            focusOffset: entityRange.end,
        }));
    }

    if (typeof value === 'undefined') {
        return RichUtils.toggleLink(currentEditorState, selection, null);
    } else {
        const content = editorState.getCurrentContent();

        const withEntity = content.createEntity(
            'LINK',
            'MUTABLE',
            value
        );
    
        const entityKey = withEntity.getLastCreatedEntityKey();
    
        const contentStateWithLink = Modifier.applyEntity(
            withEntity,
            selection,
            entityKey
        );
    
        const newEditorState = EditorState.push(editorState, contentStateWithLink);

        return newEditorState;
    }
};

export const toggleBlockType = (value, editorState) => {
    return RichUtils.toggleBlockType(
        editorState,
        value
    )
};