import 'css/globals.scss';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
//import { Router, browserHistory } from 'react-router';
import Routes from './Routes';
import store from './store';
import determineLocale from 'utils/i18n/determineLocale';
import prepareTranslations from 'utils/i18n/prepareTranslations';
import constants from 'store/constants';
import userActions from 'store/actions/user';
import { ConnectedRouter } from 'connected-react-router';
import Root from 'containers/Root';
import history from 'utils/routing/browserHistory';

import createApi from './api';

const api = createApi();

const setupTranslations = (settings) => {
	const translations = prepareTranslations(settings.translations);

	settings.locale = settings.locale || determineLocale();
	settings.translations = translations;
};

const setupRouteState = () => {
	store.dispatch({
		type: constants.SETUP,
		payload: {
			method: 'setup',
			location: history.location
		}
	});
};

api.getSystemSettings().then(async ({ systemSettings, user }) => {
	// Prepare translations takes the raw translations from the system and converts them to a usable format
	setupTranslations(systemSettings);

	store.dispatch({
		type: 'SET_SYSTEM_SETTINGS',
		data: systemSettings
	});

	const pathname = history.location.pathname;

	const isRegistering = pathname.startsWith('/register') && user;

	if (isRegistering && confirm('You are trying to register a new user while you are logged in. Press yes to log out and register, but keep in mind any unsaved changes will be lost.')) {
		await api.logout();
	} else {
		store.dispatch(userActions.setUser(user));
	}

	// If reducers need to react to the route on mount, they can listen to SETUP and do pre-mount adjustments to the state
	setupRouteState();

	render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Root>
					<Routes />
				</Root>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	);
}).catch(console.log.bind('Error - couldnt get system settings: '));