import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/OneColumnText';

export default {
    name: 'templates.template one column text',
    metaDescription: 'one (1) columns with text + headline',
    imageUrl:
        'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/1-column-text-template.png',
    type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
}
