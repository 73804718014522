import constants from '../constants';

const getDefaultState = () => ({
    device: 'computer',
    width: window.screen.width,
});

const defaultState = getDefaultState();

const sizeReducer = (state = defaultState, action) => {
	let newState;

	switch (action.type) {
		case constants.SET_SIZE:
			newState = {
				...state,
			};

			return newState;

		default: return state;
	}
};

export default sizeReducer;