import React, { PureComponent } from 'react';
import { Icon, Menu, MenuItem, ItemContent } from 'svift-ui';
import InfoIcon from 'components/InfoIcon';

import { setRoute } from 'utils/routing';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Label from 'components/option-library/label';

const mapStateToProps = (state) => {
	return {
		siteid: state.sites.params.siteid,
		themeid: state.sites.themes.params.themeid
	};
};

class DesignNavigation extends PureComponent {
	render() {
		const { siteid, themeid, intl } = this.props;

		return (
			<Label
				label={intl.formatMessage({ id: 'options.design categories title' })}
				labelIcon="sn-equalizer"
				labelDescription={intl.formatMessage({ id: 'options.design categories description' })}
			>
				{/* <p className="sidenav-subheader"><Icon name="sn-equalizer" /> <FormattedMessage id="options.design categories title" /><InfoIcon description={intl.formatMessage({ id: 'options.design categories description' })} /></p> */}

				<Menu size="large" vertical fluid>

					{/* Elements */}
					<MenuItem
						link
						id="design-elements-menu-item"
						onClick={() => setRoute(`/dashboard/${siteid}/design/${themeid}/elements/text`)}
					>
						<ItemContent>
							<Icon name="sn-marquee-plus" />
							<FormattedMessage id="elements.title" />
							<Icon name="arrow right" style={{ marginLeft: 'auto', marginRight: 0 }} />
						</ItemContent>
					</MenuItem>

					{/* Layouts */}
					<MenuItem
						link
						id="design-layout-menu-item"
						onClick={() => setRoute(`/dashboard/${siteid}/design/layouts`)}
					>
						<ItemContent>
							<Icon name="sn-web" />
							<FormattedMessage id="layouts.title" />
							<Icon name="arrow right" style={{ marginLeft: 'auto', marginRight: 0 }} />
						</ItemContent>
					</MenuItem>

					{/* Navigation */}
					<MenuItem
						link
						id="design-navigation-menu-item"
						onClick={() => setRoute(`/dashboard/${siteid}/design/navigation`)}
					>
						<ItemContent>
							<Icon name="sn-direction" />
							<FormattedMessage id="menus.title" />
							<Icon name="arrow right" style={{ marginLeft: 'auto', marginRight: 0 }} />
						</ItemContent>
					</MenuItem>

					{/* Forms */}
					<MenuItem
						link
						id="design-forms-menu-item"
						onClick={() => setRoute(`/dashboard/${siteid}/design/forms`)}
					>
						<ItemContent>
							<Icon name="send outline" />
							<FormattedMessage id="forms.title" />
							<Icon name="arrow right" style={{ marginLeft: 'auto', marginRight: 0 }} />
						</ItemContent>
					</MenuItem>

				</Menu>
			</Label>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(DesignNavigation));