import { createSelector } from 'reselect';

const selectBlogs = state => state.sites.blogs.blogs;
const selectBlogContent = state => state.sites.blogs.blogContent;
const selectBlogIds = (state, siteid) => {
	const site = state.sites.sites[siteid];
	
	// console.log(site.blogs, 'BLOGS IN SELECTOR')

	return site && site.blogs || [];
};

const blogsSelector = createSelector(
	selectBlogs,
	selectBlogContent,
	selectBlogIds,
	(blogs, blogContent, blogIds) => {
		return blogIds.map(blogid => {
			const blog = blogs[blogid];

			return {
				...blog,
				content: blogContent[blog.content]
			};
		});
	}
);

const tagsSelector = createSelector(
	selectBlogs,
	selectBlogIds,
	(blogs, IDs) => {
		const validBlogs = IDs.map(ID => {
			const blog = blogs[ID];

			return blog;
		});

		const tags = validBlogs.reduce((acc, blog) => {
			if (!Array.isArray(blog.tags)) return acc;

			blog.tags.forEach(tag => acc.add(tag));

			return acc;
		}, new Set());

		return Array.from(tags);
	}
);

export { blogsSelector, tagsSelector };