import React from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import ColorPalette from './ColorPalette';
import { injectIntl } from 'react-intl';
// import ColorPicker, { ColorPalette } from 'components/option-library/primitives/color-picker';

export default React.memo(injectIntl(props => {
    const [state, dispatch] = props.accordionState;

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                icon={props.icon || 'sn-color-sampler'}
                title={props.title || props.intl.formatMessage({ id: 'options.color title' })}
                nested
                clear={{
                    enabled: typeof props.inlineStyling !== 'undefined',
                    onClear: () => props.onChange()
                }}
            >
                <ColorPalette
                    icon={props.icon}
                    colors={props.theme.colors}
                    presetStyling={props.preset}
                    inlineStyling={props.inlineStyling}
                    // mergedStyling={props.mergedStyling}
                    computedStyling={props.computedStyling}
                    onChange={props.onChange}
                />
            </Section>
        </Accordion>
    );
}));
