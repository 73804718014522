import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import key from 'utils/key';

//Target

@DropTarget(
	['ITEM'],
	{
		drop: (props, monitor, component) => {
			if (props.didDrop) props.didDrop(monitor.getItem());

			return undefined;
		}
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver({ shallow: true }),
		isOverNotShallow: monitor.isOver(),
		dragItem: monitor.getItem()
	})
)
class TargetZone extends PureComponent {
	componentDidUpdate(prevProps) {
		const didLeave = !this.props.isOver && prevProps.isOver;
		const didEnter = this.props.isOver && !prevProps.isOver;

		if (didLeave && this.props.didLeave) {
			this.props.didLeave(
				this.props.dragItem,
				{
					item: this.props.item,
					path: this.props.path,
					index: this.props.index
				},
				this.props.area
			);
		}

		if (didEnter && this.props.didEnter) {
			this.props.didEnter(
				this.props.dragItem,
				{
					item: this.props.item,
					path: this.props.path,
					index: this.props.index
				},
				this.props.area
			);
		}
	}

	render() {
		const { y, x, color, manualOffsets, zIndex } = this.props;

		const [x1, x2] = x.split('-');
		const [y1, y2] = y.split('-');

		let style = {
			position: 'absolute',
			top: `${y1}%`,
			left: `${x1}%`,
			height: `${y2 - y1}%`,
			width: `${x2 - x1}%`,
			// background: color || 'blue',
			// color: 'white',
			// opacity: 0.2
		};


		if (zIndex) {
			style.zIndex = zIndex;
		}

		if (manualOffsets) {
			style = {
				...style,
				...manualOffsets
			};

			delete style.height;
			delete style.width;
		}

		return (
			<div
				ref={this.props.connectDropTarget}
				style={style}
			>

			</div>
		);
	}
}

//sources

const source = {
	beginDrag: props => {
		props.onDragBeginCallback(props);  
		return {
			item: props.item,
			path: props.path,
			index: props.index
		};
	},
	endDrag: (props, monitor) => {
		props.onDragEndCallback(monitor.didDrop(), props.item);
	},
	canDrag: props => {
		return !!props.item; // If you drag and drop super fast sometimes the draggedItem won't exist - I can't figure out how or why this happens. Problem not reproduced since adding this line.
	}
};

@DragSource('ITEM', source, (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		draggedItem: monitor.getItem()
	};
})
class ItemDragWrapper extends PureComponent {
	constructor(props) {
		super(props);

		this.assignRefs = ref => {
			const node = findDOMNode(ref);

			props.connectDragSource(node);
			this.node = node;
		};
	}

	render() {
		// if (this.props.isDragging) return null;
		if (!this.props.dnd) return this.props.children;

		const isDragging = this.props.draggedItem && this.props.item.data._id === key('draggedItem.item.data._id')(this.props);

		const childProps = {
			ref: this.assignRefs,
			...this.props,
			isDragging
		};

		delete childProps.children;

		return React.cloneElement(this.props.children, childProps);
	}
}

class Tree extends PureComponent {
	constructor() {
		super();

		this.renderItem = this.renderItem.bind(this);
	}

	renderItem(item, index) {
		const { text, items } = item;

		return (
			<ItemDragWrapper
				key={item.data._id}
				dnd={this.props.dnd}
				index={index}
				path={this.props.path}
				item={item}
				onDragBeginCallback={this.props.onDragBeginCallback}
				onDragEndCallback={this.props.onDragEndCallback}
			>
				<this.props.ItemComponent
					key={item.data._id}
					index={index}
					path={this.props.path}
					item={item}
					context={this.props.context}
				>
					<this.props.TextComponent
						text={text}
						index={index}
						item={item}
						context={this.props.context}
					/>

					{this.props.targetZones && this.props.targetZones.map((targetZone) => {
						// if (items.length > 0 && targetZone.area === 'right') return null;

						return (
							<TargetZone
								key={targetZone.area}
								item={item}
								index={index}
								path={this.props.path}
								onOver={this.props.onOverTargetZone}

								{...targetZone}
							/>
						);
					})}

					{(this.props.shouldRenderSubtree ? this.props.shouldRenderSubtree(item) : items.length > 0) &&
						<Tree
							{...this.props}
							
							nested
							path={!this.props.noPath && this.props.path.concat({ item, index })}
							items={items}
						/>
					}
				</this.props.ItemComponent>
			</ItemDragWrapper>
		);
	}

	render() {
		const isRoot = !this.props.nested;
		const hasChildren = this.props.items.length;

		return (
			<this.props.ItemGroupComponent isRoot={isRoot} hasChildren={hasChildren} context={this.props.context}>
				{this.props.items.map(this.renderItem)}
			</this.props.ItemGroupComponent>
		);
	}
}

Tree.defaultProps = {
	depth: 0,
	items: [
		{
			data: { _id: '1' },
			text: 'Layer 1',
			items: [
				{
					data: { _id: '2' },
					text: 'Layer 2',
					items: [
						{
							data: { _id: '3' },
							text: 'Layer 3',
							items: []
						},
						{
							data: { _id: '4' },
							text: 'Layer 3',
							items: []
						}
					]
				}
			]
		},
		{
			data: { _id: '5' },
			text: 'Layer 1',
			items: [
				{
					data: { _id: '6' },
					text: 'Layer 2',
					items: []
				}
			]
		}
	],
	onItemClick: () => null,
	onOverTargetZone: () => null,
	path: []
};

Tree.propTypes = {
	items: PropTypes.array,
	ItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]) // stateless or regular component
};

export default Tree;
