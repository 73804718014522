import React, { Component } from 'react';
import { MenuItem, Icon, Popup, Flag } from 'svift-ui';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import resolveFlagIconName from 'utils/i18n/resolveFlagIconName';

const mapStateToProps = (state) => {
	const { sites } = state.sites;
	const siteid = state.sites.params.siteid;
	const site = sites && sites[siteid];

	return {
		site,
	};
};

@connect(mapStateToProps)
class LanguageList extends Component {
	render() {
		const { site } = this.props;
		if (!site) {
			return null;
		}

		const language = site.standardLanguage;

		return (
			<Popup
				trigger={
					<MenuItem link disabled>
						{/* <Icon
							// link
							name="sn-earth3"
							className="primary-color"
							style={{ marginRight: 8, fontSize: 24, opacity: 0.6 }}
						/> */}
						<span style={{ textTransform: 'uppercase', fontSize: 14 }}>
							<Flag name={resolveFlagIconName(language)} style={{ marginRight: 6 }} />
							<span style={{ fontWeight: 500 }}>
								{language}
							</span>
						</span>
					</MenuItem>
				}
				content={this.props.intl.formatMessage({ id: 'general.active language' })}
				position="bottom center"
			/>
		);
	}
}

export default injectIntl(LanguageList);