import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import {
	Header,
	HeaderSubheader,
	Form,
	FormInput,
	Button,
	Grid,
	GridColumn,
	Message,
	Icon,
	FormSelect,
	Divider,
	Image,
	List,
	ListItem,
	Box,
	Label,
	HeaderContent,
	Table,
	TableHeader,
	TableBody,
	TableCell,
	TableRow,
	TableHeaderCell,
	Input,
	ButtonGroup,
	Popup
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import { replaceRoute } from 'utils/routing';
import createApi from 'api';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import SviftGA from 'utils/svift-ga';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

const api = createApi();

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		siteid,
		domains: site.domains,
		failedActivation: site.failedActivation
	};
};

const typeOptions = [
	{ text: 'A', value: 'A' },
	{ text: 'CNAME', value: 'CNAME' },
	{ text: 'TXT', value: 'TXT' },
	{ text: 'MX', value: 'MX' }
];

const ttlOptions = [
	{ text: '10 minutter (600)', value: 600 },
	{ text: '1 time (3600)', value: 3600 },
	{ text: '6 timer (21600)', value: 21600 },
	{ text: '12 timer (43200)', value: 43200 },
	{ text: '24 timer (86400)', value: 86400 }
];

class Dns extends PureComponent {
	constructor(props) {
		super(props);

		this.loadDomain = this.loadDomain.bind(this);
		this.addRecord = this.addRecord.bind(this);
		this.updateRecord = this.updateRecord.bind(this);
		this.deleteRecord = this.deleteRecord.bind(this);
		this.toggleRecords = this.toggleRecords.bind(this);

		const domains = props.domains || [];

		const formattedDomains = domains.map(domain => {
			return {
				text: domain.name,
				value: domain.name
			};
		});

		const firstDomain = formattedDomains[0];

		this.state = {
			domains: formattedDomains,
			
			// Default input/dropdown values
			currentDomain: firstDomain && firstDomain.value,
			selectedType: typeOptions[0].value,
			selectedTtl: ttlOptions[1].value,
			recordName: '',
			recordValue: '',
			priority: '',
			
			// Edit existing records
			selectedRecord: null,
			showRecords: false
		};
	}

	toggleRecords() {
		this.setState({
			showRecords: !this.state.showRecords
		});
	}

	componentDidMount() {
		if (this.state.currentDomain) this.getRecords();
	}

	async loadDomain(domain) {
		this.setState({
			currentDomain: domain
		}, this.getRecords);
	}

	async getRecords() {
		const records = await api.dns.getRecords(this.props.siteid, this.state.currentDomain);

		this.setState({
			records: records
		});
	}

	async addRecord() {
		const record = {
			name: this.state.recordName.trim(),
			type: this.state.selectedType,
			ttl: this.state.selectedTtl,
			data: this.state.recordValue.trim(),
			priority: this.state.priority
		};
		if (record.type === 'TXT' || record.type === 'MX') {
			record.name = '@';
		} 

		if (record.type !== 'MX') delete record.priority;

		try {
			await api.dns.addRecord(this.props.siteid, this.state.currentDomain, record);

			SviftGA.SITES.DNS_RECORD_CREATED();

			this.getRecords();
			this.setState({
				error: null,
				recordName: '',
				selectedType: 'A',
				selectedTtl: 3600,
				recordValue: '',
				priority: ''
			});
		} catch (error) {
			if (error.intlReadable) {
				this.setState({
					error: this.props.intl.formatMessage({id: error.intlReadable})
				});
			}
		}		
	}

	async updateRecord() {
		const updatedRecord = {
			...this.state.selectedRecord
		};
		
		try {
			const dnsResponse = await api.dns.editRecord(this.props.siteid, this.state.currentDomain, updatedRecord.record_id, updatedRecord);

			SviftGA.SITES.DNS_RECORD_MODIFIED();

			this.setState({
				selectedRecord: null,
				error: null
			});
			this.getRecords();
		} catch (error) {
			if (error.intlReadable) {
				this.setState({
					error: this.props.intl.formatMessage({id: error.intlReadable})
				});
			}
		}		
	}

	async deleteRecord(recordId) {
		try {
			const dnsResponse = await api.dns.deleteRecord(this.props.siteid, this.state.currentDomain, recordId);

			SviftGA.SITES.DNS_RECORD_DELETED();

			this.setState({
				selectedRecord: null,
				error: null
			});
			this.getRecords();
		} catch (error) {
			if (error.intlReadable) {
				this.setState({
					error: this.props.intl.formatMessage({id: error.intlReadable})
				});
			}
		}	
	}

	renderTableRow = (record, index) => {
		const {
			record_id,
			name,
			type,
			ttl,
			data,
			priority
		} = record;

		const cellStyling = {
			wordWrap: 'break-word',
			overflowWrap: 'break-word'
		};

		const isEditing = this.state.selectedRecord && this.state.selectedRecord.record_id === record_id;

		return (
			<TableRow verticalAlign="top" key={record_id}>
				<TableCell style={cellStyling}>
					{isEditing
						?
							<Button
								size="mini"
								negative
								onClick={() => {
									this.deleteRecord(record_id)
								}}
							>
								<Icon name="sn-bin" />
								<FormattedMessage id="settings.domain delete dns record button" />
							</Button>
						:
							<span style={{ fontSize: 14 }}>
								<span style={{ fontWeight: 'bold' }}>{name !== '@' && `${name}.`}</span>{this.state.currentDomain}
							</span>
					}
				</TableCell>

				<TableCell textAlign="left">
					{isEditing
						?
							<div>
								<FormSelect 
									fluid 
									placeholder="Type"
									disabled={true}
									options={typeOptions}
									value={this.state.selectedRecord.type}
									style={{ width: '100%' }} 
								/>

								{this.state.selectedRecord.type === 'MX' 
									?
										<Input 
											type="number"
											fluid
											placeholder={this.props.intl.formatMessage({ id: 'settings.domain dns record priority placeholder'})}
											value={this.state.selectedRecord.priority}
											onChange={(e, { value }) => {
												this.setState({
													selectedRecord: {
														...this.state.selectedRecord,
														priority: value
													}
												});
											}} 
											size="mini" 
											style={{ marginTop: 4 }}
										/>
									: 
										null
								}
							</div>
						:
							<Label circular basic size="tiny" className="info">
								{type === 'MX' ? `${type} (${priority})` : type}
							</Label>
					}
				</TableCell>

				<TableCell>
					{isEditing
						?
							<FormSelect 
								fluid 
								placeholder="TTL"
								options={ttlOptions}
								value={this.state.selectedRecord.ttl}
								onChange={(e, { value }) => {
									this.setState({
										selectedRecord: {
											...this.state.selectedRecord,
											ttl: value
										}
									});
								}}
								style={{ width: '100%' }} 
							/>
						:
							ttl
					}
				</TableCell>

				<TableCell style={cellStyling}>
					{isEditing
						?
							<Input 
								fluid 
								placeholder={this.props.intl.formatMessage({ id: 'settings.domain dns record value placeholder' })}
								value={this.state.selectedRecord.data}
								onChange={(e, { value }) => {
									this.setState({
										selectedRecord: {
											...this.state.selectedRecord,
											data: value
										}
									});
								}} 
								size="mini" 
							/>
						:
							<span style={{ fontSize: 14 }}>{data}</span>
					}
				</TableCell>

				<TableCell textAlign="center">
					{isEditing 
						?
							<ButtonGroup size="mini">
								<Popup
									trigger={
										<Button positive onClick={this.updateRecord} icon="sn-checkmark" />
									}
									content={this.props.intl.formatMessage({ id: 'settings.domain update dns record popup' })}
									position="top center"
								/>
								<Popup
									trigger={
										<Button 
											basic 
											onClick={() => {
												this.setState({
													selectedRecord: null
												});
											}} 
											icon="sn-cross2" 
										/>
									}
									content={this.props.intl.formatMessage({ id: 'general.cancel' })}
									position="top center"
								/>
							</ButtonGroup>
						:
							<Popup
								trigger={
									<Icon 
										circular
										name="sn-quill"
										size="small"
										className="primary" 
										link="#"
										onClick={() => {
											this.setState({
												selectedRecord: {
													...record
												}
											});
										}}
										style={{ fontSize: 14 }} 
									/>
								}
								content={this.props.intl.formatMessage({ id: 'settings.domain edit dns record button' })}
								position="bottom right"
							/>
					}
				</TableCell>
			</TableRow>
		);
	}

	renderRecords = () => {
		const records = this.state.records;
		const cellStyling = {
			wordWrap: 'break-word',
			overflowWrap: 'break-word'
		};

		return (
			<React.Fragment>
				{records.map(this.renderTableRow)}
				<TableRow verticalAlign="top">
					<TableCell style={cellStyling}>
						{/* <Icon name="plug" style={{ fontSize: 18, opacity: 0.3 }} /> */}
						<Input 
							fluid 
							placeholder={this.props.intl.formatMessage({ id: 'settings.domain dns record name placeholder' })}
							disabled={this.state.selectedType === 'TXT' || this.state.selectedType === 'MX'}
							value={this.state.recordName}
							onChange={(e, { value }) => {
								this.setState({
									recordName: value
								});
							}} 
							size="mini" 
							style={{ marginTop: 4 }}
						/>
						{this.state.selectedType === 'A' || this.state.selectedType === 'CNAME' ?
							<p style={{ fontSize: 14, marginTop: 2 }}>
								.{this.state.currentDomain}
								<InfoIcon
									description={this.props.intl.formatMessage({ id: 'settings.domain dns record name description' })}
									position="bottom left"
								/>
							</p>
						:
							null
						}
					</TableCell>
					<TableCell>
						<Form size="mini">
							<FormSelect 
								fluid 
								placeholder="type"
								options={typeOptions}
								value={this.state.selectedType}
								onChange={(e, { value }) => {
									console.log(value);
									this.setState({
										selectedType: value
									});
								}}
								style={{ maxWidth: '100%' }} 
							/>
							{this.state.selectedType === 'MX' ?
								<Input 
									type="number"
									fluid 
									placeholder={this.props.intl.formatMessage({ id: 'settings.domain dns record priority placeholder'})}
									value={this.state.priority}
									onChange={(e, { value }) => {
										this.setState({
											priority: value
										});
									}} 
									size="mini" 
									style={{ marginTop: -8 }}
								/>
							:
								null
							}
						</Form>
					</TableCell>
					<TableCell textAlign="left">
						<Form size="mini">
							<FormSelect 
								fluid 
								placeholder="TTL"
								options={ttlOptions}
								value={this.state.selectedTtl}
								onChange={(e, { value }) => {
									this.setState({
										selectedTtl: value
									});
								}}
								style={{ width: '100%' }} 
							/>
						</Form>
					</TableCell>
					<TableCell style={cellStyling}>
						<Input 
							fluid 
							placeholder={this.props.intl.formatMessage({ id: 'settings.domain dns record value placeholder' })}
							value={this.state.recordValue}
							onChange={(e, { value }) => {
								this.setState({
									recordValue: value
								});
							}} 
							size="mini" 
						/>
					</TableCell>
					<TableCell textAlign="center">
						<Popup
							trigger={
								<Button
									icon
									size="mini"
									positive
									onClick={this.addRecord}
								>
									<Icon name="sn-plus2" />
									<Icon name="plug" style={{ opacity: 0.5 }} />
								</Button>
							}
							content={this.props.intl.formatMessage({ id: 'settings.domain add dns record button' })}
							position="bottom right"
						/>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}

	renderDomains() {
		const { showRecords } = this.state;

		return (
			<React.Fragment>
				{/* <Message
					warning
					icon="sn-warning"
					content={
						<div style={{ display: 'inline-block' }}>
							<Label key="warning-label" size="large" basic className="warning" style={{ marginBottom: 8 }}>
								<FormattedMessage id="settings.domain section warning label" />
							</Label>
							<div>
								<FormattedMessage id="settings.domain section warning description" />
							</div>
						</div>
					}
					style={{ textAlign: 'left' }}
				/> */}

				<Grid verticalAlign="middle" style={{ marginTop: 8, marginBottom: 0 }}>
					<GridColumn computer={16} tablet={16} mobile={16} textAlign="left">
						<Icon name="plug" className="positive" style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 16, opacity: 0.6 }} />
						<FormattedMessage id="settings.domain section chosen domain label" />:
						<Form size="small" style={{ padding: 0, marginLeft: 8, display: 'inline-block' }}>
							<FormSelect
								// placeholder={this.props.intl.formatMessage({ id: 'settings.domain select domain placeholder' })}
								options={this.state.domains}
								value={this.state.currentDomain}
								onChange={(e, { value }) => {							
									this.loadDomain(value);
								}}
							/>
						</Form>
					</GridColumn>
					{/* <GridColumn computer={5}>
						<Button basic positive fluid size="small"><Icon name="plus" /> Add domain</Button>
					</GridColumn> */}
				</Grid>

				<div className="section-shade" style={{ padding: '0 16px 24px 16px' }}>
						
					<Divider horizontal style={{ marginBottom: 24 }}>
						<Button
							basic
							circular
							size="large"
							className={!showRecords ? 'warning' : 'warning active-button'}
							onClick={this.toggleRecords}
						>
							<Icon
								name={!showRecords ? 'sn-plus-circle' : 'sn-minus-circle2'}
								style={{ marginRight: 8 }} />
							<FormattedMessage id={`settings.domain section ${!showRecords ? 'show' : 'hide'} dns records`} />
						</Button>
					</Divider>
					
					{/* DNS Records table */}
					{showRecords &&
						<Table selectable style={{ marginTop: 0, marginBottom: 32, tableLayout: 'fixed', width: '100%' }}>
							<TableHeader>
								<TableRow verticalAlign="top">
									<TableHeaderCell width={4}>
										<FormattedMessage id="settings.domain dns record name label" />
										{/* <InfoIcon
											description={this.props.intl.formatMessage({ id: 'settings.domain dns record name description' })}
											position="top left"
										/> */}
									</TableHeaderCell>
									<TableHeaderCell width={2}>
										<FormattedMessage id="settings.domain dns record type label" />
									</TableHeaderCell>
									<TableHeaderCell width={3}>
										<FormattedMessage id="settings.domain dns record ttl label" />
									</TableHeaderCell>
									<TableHeaderCell width={5}>
										<FormattedMessage id="settings.domain dns record value label" />
									</TableHeaderCell>
									<TableHeaderCell width={2} textAlign="center">
										<FormattedMessage id="general.edit" />
									</TableHeaderCell>
								</TableRow>
							</TableHeader>

							<TableBody>
								{this.renderRecords()}
							</TableBody>
						</Table>
					}
				</div>
			</React.Fragment>
		);
	}

	renderNotActivated() {
		return (
			<Message
				key="domain-not-activated"
				className="info"
				size="large"
				icon={<Icon name="sn-info" style={{ fontSize: 28 }} />}
				content={
					<Header as="h4">
						<FormattedMessage id="settings.domain section offline message title" />
						<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
							<FormattedMessage id="settings.domain section offline message description" />
						</HeaderSubheader>
					</Header>
				}
				style={{ textAlign: 'left', marginBottom: 32 }}
			/>
		)
	}

	renderError() {
		if (!this.state.error) return null;

		return (
			<Message key="error" className="error">
				{this.state.error}
			</Message>
		);
	}

	render() {
		if (!this.props.domains || this.props.domains.length === 0 || this.props.failedActivation) return this.renderNotActivated();

		if (!this.state.records) return null;

		return (
			<React.Fragment key="domains-fragment">
				<p><FormattedMessage id="settings.domain section description" /></p>
				{this.renderDomains()}
				{this.renderError()}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(Dns));