import React, { Component } from 'react';
import DashboardHeader from './DashboardHeader';
import DashboardContent from './DashboardContent';

export default class Dashboard extends Component {
	render() {
		return (
			<React.Fragment>
				<DashboardHeader />
				<DashboardContent />
			</React.Fragment>
		);
	}
}
