import React from 'react';
import { useDrag, useDrop } from 'react-dnd'
import { useEffect } from 'react';

const ITEM_CONST = 'SORTABLE_ITEM';

const DragSource = props => {
    const ref = React.useRef(null);
    const [{ isDragging }, drag] = useDrag({
        item: { 
            type: ITEM_CONST,
            item: props.item,
            position: props.position
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
            // opacity: monitor.isDragging() ? 0.5 : 1
        }),
        end: props.dragEnd
    });

    const [_, drop] = useDrop({
        accept: ITEM_CONST,
        hover: (data, monitor) => {
            const { position } = data;

            if (!ref.current) {
                return
            }

            const dragIndex = position;
            const hoverIndex = props.position;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            props.moveItem(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            data.position = hoverIndex; 
        }
    });

    drag(drop(ref));

    return props.renderItem({
        item: props.item,
        dragRef: ref,
        isDragging,
        position: props.position
    }, props.position);
}

const Sortable = props => {
    // We sort in internal Sortable state before we commit to the "outside world" - this prevents having to write optimizations everywhere Sortable has expensive sibling components that update when items move position
    const [items, setItems] = React.useState(props.items);

    const moveItem = React.useCallback((currentIndex, newIndex) => {
        const newArray = items.slice();
        const tmp = items[newIndex];

        newArray[newIndex] = items[currentIndex];
        newArray[currentIndex] = tmp;

        setItems(newArray);
    }, [items]);

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    // Commit moved items when dragging ends
    const commitMoves = React.useCallback(() => {
        props.onChange(items);
    }, [items]);

    return items.map((item, position) => {
        return (
            <DragSource
                key={props.resolveItemID ? props.resolveItemID(item) : item.id}
                item={item} 
                moveItem={moveItem} 
                dragEnd={commitMoves}
                position={position} 
                renderItem={props.renderItem} 
            />
        );
    });
};

export default Sortable;
