exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".app-content-section__app-content-section--XIsJM{position:relative;padding:32px 16px 48px 16px}.app-content-section__app-content-section--XIsJM.app-content-section__light-content-section--3b8ys{background:rgba(252,251,250,0.6) !important}\n", ""]);

// exports
exports.locals = {
	"app-content-section": "app-content-section__app-content-section--XIsJM",
	"light-content-section": "app-content-section__light-content-section--3b8ys"
};