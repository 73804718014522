import React, { PureComponent } from 'react';
import { Segment, Icon } from 'svift-ui';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import { FormattedMessage, injectIntl } from 'react-intl';

class SegmentWrapper extends PureComponent {
	constructor() {
		super();

		this.state = {
			hovered: false
		};
	}

	onEnter = e => {
		this.setState({
			hovered: true
		});
	}

	onLeave = e => {
		this.setState({
			hovered: false
		});
	}

	componentDidUpdate() {
		if (this.state.delayRerender) {
			setTimeout(() => {
				this.setState({
					delayRerender: false
				});
			}, 0);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isDragging && !this.props.isDragging) {
			this.setState({
				delayRerender: true
			});
		}
	}

	render() {
		const isDragging = this.props.isDragging;

		let className = '';

		const isHovered = this.props.context.hovered === this.props.item.data._id;

		if (isHovered) {
			className = 'drop-folder-highlight';
		}

		if (this.state.hovered) {
			className += ' hover-item';
		}

		if (isDragging && !this.state.delayRerender) {
			className += ' drop-segment';
		}

		const isActive = this.props.context.active === this.props.item.data._id;

		if (isActive) className += ' active-item';

		return (
			<Segment
				ref={this.props.assignRef}
				className={className}
				onMouseEnter={this.onEnter}
				onMouseLeave={this.onLeave}
				onClick={e => {
					this.props.context.setActive(this.props.item.data._id);

					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{this.props.children}
			</Segment>
		);
	}
}
export default SegmentWrapper;