import React, { PureComponent } from 'react';
import {
	Form,
	Icon,
	Button,
	Message,
	Grid,
	GridRow,
	GridColumn,
	Divider,
	Dimmer,
	Loader,
	Label
} from 'svift-ui';
// import InfoIcon from 'components/InfoIcon';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import OptionLabel from 'components/option-library/label';
import css from 'components/editor-v2/editor-interface/options/options.scss';
import input from 'components/forms/__util/field-components/input';
import dropdown from 'components/forms/__util/field-components/dropdown';
import validate, { string, valid } from 'components/forms/__util/validate';
import AdaptedCardSelector from 'components/forms/__util/field-components/AdaptedCardSelector';

import { connect } from 'react-redux';

const createLayoutCards = intl => [
	// {
	// 	id: 'empty-fallback-layout',
	// 	style: { padding: 6 },
	// 	noLabel: true,
	// 	name: intl.formatMessage({ id: 'templates.empty layout' }),
	// 	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/fallback-layout.png'
	// },
	{
		id: 'standard-post-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.post standard layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-post-layout.png'
	},
	{
		id: 'standard-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page standard layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-page-layout.png'
	},
	{
		id: 'standard-page-with-sidebar-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page standard with sidebar layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-page-with-sidebar-layout.png'
	},
	{
		id: 'sidebar-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page sidebar layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/sidebar-page-layout.png'
	},
	{
		id: 'centered-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page centered layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/centered-page-layout.png'
	},
	{
		id: 'fluid-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page fluid layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/fluid-page-layout.png'
	}
];

const fields = {
	title: {
		component: input({
			type: 'text',
		}),
		validate: string(1, 170)
	},
	type: {
		component: dropdown({
			icon: 'sn-web'
		}),
		validate: string(1, 255)
	},
	layoutTemplate: {
		validate: valid
	}
};

// const validateFields = validate(fields);

const mapStateToProps = state => ({
	layoutTemplateValue: state.form && state.form.layout && state.form.layout.values && state.form.layout.values.layoutTemplate || '',
	layoutType: state.form && state.form.layout && state.form.layout.values && state.form.layout.values.type || '',
});

// const mapStateToProps = (state, ownProps) => {
// 	const siteid = state.sites.params.siteid;

// 	const layouts = layoutsSelector(state, siteid);
// 	const defaultLayout = layouts.find(layout => layout.default);

// 	return {
// 		layouts,
// 		pages: pagesSelector(state, siteid),
// 		initialValues: ownProps.initialValues || {
// 			author: state.user._id,
// 			parentPage: '__noParentPage__', // Dropdown component or redux-form (not sure which) cant handle null value in the dropdown, so we use this string to represent a null value and convert it to null on submit if its selected
// 			hidden: false,
// 			layout: defaultLayout._id,
// 			description: ''
// 		},
// 		users: [
// 			{
// 				text: state.user.fullname,
// 				value: state.user._id
// 			}
// 		]
// 	};
// };

class LayoutForm extends PureComponent {
	constructor() {
		super();

		this.state = {
			confirmDelete: false
		};

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		const relevantFields = {
			...fields
		};

		const modifiedValues = {
			...values
		};

		if (!this.props.update) {
			// console.log(this.props.layoutType, 'type')
			// if (this.props.layoutType === 'page') {
			// 	if (this.props.layoutTemplate === 'standard-post-layout') modifiedValues.layoutTemplate = 'standard-page-layout';
			// } else {
			// 	if (this.props.layoutTemplate === 'standard-page-layout') modifiedValues.layoutTemplate = 'standard-post-layout';
			// }
		} else {
			delete relevantFields.layoutTemplate;
			delete modifiedValues.layoutTemplate;
		}

		validate(relevantFields)(modifiedValues);

		await this.props.onSubmit(modifiedValues);
	}

	renderButtons() {
		const { update, handleSubmit, intl } = this.props;

		if (!update) {
			return (
				<Grid>
					<GridColumn mobile={8} textAlign="left">
						<Button
							positive
							onClick={handleSubmit(this.submit)}
						>
							<Icon name="plus" style={{ marginRight: 8 }} /> <FormattedMessage id="layouts.create new layout" />
						</Button>
					</GridColumn>
					<GridColumn mobile={8} textAlign="right">
						<Button
							basic
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<Icon name="sn-cross2" style={{ marginRight: 8 }} /> <FormattedMessage id="general.cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle">
				<GridColumn mobile={16} computer={5} textAlign="left">
					<Button
						positive
						onClick={handleSubmit(this.submit)}
					>
						<Icon name="sn-cloud-upload" /> <FormattedMessage id="settings.save changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={6} textAlign="center">
					<Button
						basic
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
					>
						<Icon name="sn-cross2" /> <FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={5} textAlign="right">
					<Button
						basic
						negative
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'layouts.delete layout alert title' })}
							text={intl.formatMessage({ id: 'layouts.delete layout alert description' })}
							confirmTitle={intl.formatMessage({ id: 'layouts.delete layout alert confirm' })}
							confirmIcon="trash"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						<Icon name="sn-bin" /> <FormattedMessage id="layouts.delete layout" /> <Icon name="sn-web" style={{ marginLeft: 4 }} />
					</Button>
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const { intl, update } = this.props;
		const cards = createLayoutCards(intl);
		const selectedCard = cards.find(card => card.id === this.props.layoutTemplateValue);

		return (
			<Form>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}><Icon name="sn-web" /> <FormattedMessage id="general.saving changes" /></Loader>
					</Dimmer>
				}

				<Divider horizontal>
					<Icon circular name="setting" style={{ opacity: 0.6 }} />
				</Divider>

				<Grid padded className="section-shade">
					<GridRow>
						<GridColumn computer={10}>
							{/* layout title input */}
							<OptionLabel
								collapsed
								labelIcon="sn-web"
								label={intl.formatMessage({ id: 'layouts.layout title' })}
								// labelDescription="Here will be description..."
							>
								<Field
									fluid
									size="huge"
									name="title"
									placeholder={intl.formatMessage({ id: 'layouts.layout title input placeholder' })}
									intl={intl}
									component={fields.title.component}
								/>
							</OptionLabel>
						</GridColumn>
						<GridColumn computer={6}>
							{/* layout type dropdown */}
							<OptionLabel
								collapsed
								labelIcon="sn-web"
								label={intl.formatMessage({ id: 'layouts.layout type' })}
								labelDescription={intl.formatMessage({ id: 'layouts.layout type description' })}
							>
								<Field
									name="type"
									disabled={update}
									onChangeListener={val => {
										if (val === 'page') {
											this.props.change('layoutTemplate', 'standard-page-layout');
										} else {
											this.props.change('layoutTemplate', 'standard-post-layout');
										}
									}}
									values={[
										{
											text: intl.formatMessage({ id: 'pages.pages title' }),
											value: 'page'
										},
										{
											text: intl.formatMessage({ id: 'blog.posts title' }),
											value: 'blog'
										}
									]}
									intl={intl}
									component={fields.type.component}
								/>
							</OptionLabel>
						</GridColumn>
					</GridRow>
				</Grid>

				{!update &&
					<React.Fragment>
						<Divider horizontal key="starter-layout-section">
							<Icon circular name="sn-web" style={{ opacity: 0.6, fontSize: 24 }} />
							<p style={{ marginTop: 4, marginBottom: 16 }}>
								<FormattedMessage id="layouts.starter layout" />
							</p>
						</Divider>

						<div
							key="starter-layout-cards"
							className="section-shade"
							style={{
								padding: '16px 8px',
								maxHeight: 264,
								overflowY: 'auto',
								overflowX: 'hidden',
								border: '1px solid #ccc'
							}}
						>
							<Field
								name="layoutTemplate"
								key="starter-layout-template"
								itemsPerRow={3}
								cards={this.props.layoutType === 'page' ? cards.slice(1) : cards}
								component={AdaptedCardSelector}
							/>
						</div>

						{selectedCard && 
							<Message key="seleceted-starter-layout-message" className="primary" style={{ marginTop: 8, padding: 8, textAlign: 'center' }}>
								<span style={{ marginRight: 8 }}>
									<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
									<FormattedMessage id="general.you have chosen" />:
								</span>
								<Label className="primary" size="small">
									<Icon name="sn-web" style={{ marginRight: 6 }} />

									{selectedCard.name}
								</Label>
							</Message>
						}
					</React.Fragment>
				}

				<Divider />

				{this.props.error &&
					<Message negative>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				{this.renderButtons()}
			</Form>
		);
	}
}

const ReduxLayoutForm = reduxForm({
	form: 'layout'
})(injectIntl(LayoutForm));

ReduxLayoutForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

ReduxLayoutForm.defaultProps = {
	initialValues: {
		layoutTemplate: 'standard-page-layout',
		type: 'page'
	}
};

export default connect(mapStateToProps)(ReduxLayoutForm);
