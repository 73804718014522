import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox';

const RadioOptions = memo(props => {
    return (
        <div role="radiogroup" aria-labelledby={props.labelledBy}>
            {props.options.map(option => {
                return (
                    <Checkbox
                        radio // radio button mode
                        key={option.label}
                        value={props.value === option.value}
                        label={option.label}
                        onChange={e => props.onChange(option.value, e)}
                    />
                );
            })}
        </div>
    )

    // return props.options.map(option => {
    //     return (
    //         <Radio
    //             key={option.value}
    //             label={option.label}
    //             checked={props.value === option.value}
    //             onChange={e => props.onChange(option.value, e)}
    //         />
    //     );
    // });
});

RadioOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any}))
};

export default RadioOptions;