import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setRoute } from 'utils/routing';
import {
	Button,
	Message,
	Label,
	Icon,
	Divider
} from 'svift-ui';
import createApi from 'api';
import Alert from 'components/Alert';
import { injectIntl, FormattedMessage } from 'react-intl';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;

	return {
		siteid,
		siteName: state.sites.sites[siteid].name,
		roles: state.user.roles,
		email: state.user.email
	};
};

class MemberSection extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showConfirmDelete: false,
			error: null
		};

		this.leaveSite = this.leaveSite.bind(this);
	}

	async leaveSite() {
		const {
			email,
			siteid
		} = this.props;

		const api = createApi();

		try {
			await api.siteUsers.deleteUser({
				siteID: siteid,
				email
			});

			setRoute('/');
		} catch (e) {
			console.log(e);

			this.setState({
				error: e.readable
			});
		}
	}

	renderError() {
		if (!this.state.error) return null;

		return (
			<Message warning>
				{this.state.error}
			</Message>
		);
	}


	render() {
		const { intl, roles, siteid } = this.props;

		let role = roles.find(({ scope }) => {
			return scope === `sites/${siteid}`;
		});

		if (!role) {
			role = roles.find(({ type }) => type === 'superadmin');
		}

		const translatedRole = intl.formatMessage({
			id: (() => {
				switch (role.type) {
					case 'siteOwner':
						return 'settings.users user role site owner';
					case 'siteWriter':
						return 'settings.users user role writer';
					case 'siteBlogger':
						return 'settings.users user roler blogger'; // TRANSL8
					case 'siteDesigner':
						return 'settings.users user role designer';
					case 'siteAdmin':
						return 'settings.users user role administrator';
					default:
						return 'N/A';
				}
			})()
		});

		const getRoleIcon = (() => {
			switch (role.type) {
				case 'siteOwner':
					return 'sn-star-full';
				case 'siteWriter':
				case 'siteBlogger': // TRANSL8 (not really but maybe you want a different icon?)
					return 'sn-quill4';
				case 'siteDesigner':
					return 'sn-bucket2';
				case 'siteAdmin':
					return 'setting';
				default:
					return 'N/A';
			}
		})();

		return (
			<div>
				<Message
					className="info"
					style={{ fontSize: 20, padding: 32, textAlign: 'left', marginBottom: 16 }}
					icon={
						<Icon name="user" circular style={{ fontSize: 48, opacity: 0.3 }} />
					}
					content={
						<div>
							<div style={{ marginBottom: 8 }}>
								<FormattedMessage id="settings.membership section role label" />:
								<Label basic size="huge" className="info" style={{ marginLeft: 12 }}>
									<Icon name={getRoleIcon} style={{ marginRight: 8 }} />
									{translatedRole}
								</Label>
							</div>
							<Divider />
							<div>
								<FormattedMessage id="settings.membership section site label" />: 
								<Label basic size="huge" className="positive" style={{ marginLeft: 12 }}>
									<Icon name="sn-sphere" style={{ marginRight: 8 }} />
									{this.props.siteName}
								</Label>
							</div>
						</div>
					}
				/>
				<p className="small-text" style={{ textAlign: 'left', marginBottom: 24 }}>
					<FormattedMessage id="settings.membership section description" />
				</p>
				<Button
					basic
					className="negative"
					onClick={() => {
						this.setState({
							showConfirmDelete: true
						});
					}}
					style={{ marginBottom: 16 }}
				>
					<Icon name="log out" />
					<FormattedMessage id="settings.membership leave site button title" />
				</Button>

				{this.renderError()}

				<Alert
					show={this.state.showConfirmDelete}
					title={intl.formatMessage({ id: 'settings.leave site alert title' })}
					text={intl.formatMessage({ id: 'settings.leave site alert description' })}
					confirmTitle={intl.formatMessage({ id: 'settings.leave site alert confirm button' })}
					confirmIcon="logout"
					confirmClass="negative"
					cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
					onConfirm={() => {
						this.setState({
							showConfirmDelete: false
						});

						this.leaveSite();
					}}
					showCancelButton
					onCancel={() => {
						this.setState({
							showConfirmDelete: false
						});
					}}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(MemberSection));
