exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".clear-value__clear-option-icon--3CZNt{color:#CF8034;float:right;margin-right:0 !important;margin-left:auto !important}\n", ""]);

// exports
exports.locals = {
	"clear-option-icon": "clear-value__clear-option-icon--3CZNt"
};