import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { injectIntl } from 'react-intl';
import Label from 'components/option-library/label';
import Radio from 'components/option-library/primitives/radio';
import NumberPicker from 'components/option-library/primitives/input/number';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import css from '../options.scss';
import Crop from '../common/Crop';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
				<Label>
					<Dropdown
						wings
						items={[
							{
								text: 'Logo',
								value: 'logo'
							},
							{
								text: 'Horizontal Logo',
								value: 'horizontalLogo'
							}
						]}
						value={node.props.type}
						onChange={value => onChange('type', value, 'blabla', false)}
					/>
				</Label>

                <Preset
                    presets={props.theme.presets.image}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <Section
                    title={intl.formatMessage({ id: 'options.dimensions' })}
                    description={intl.formatMessage({ id: 'options.image dimensions description' })}
                    icon="sn-photo_size_select_large"
                >
                    <Label
                        labelIcon="sn-rulers"
                        label={intl.formatMessage({ id: 'options.image dimensions label' })}
                    >
                        <Radio 
                            options={[
                                {
                                    value: undefined,
                                    label: intl.formatMessage({ id: 'general.default'})
                                },
                                {
                                    value: true,
                                    label: intl.formatMessage({ id: 'general.custom'})
                                }
                            ]}
                            onChange={nextVal => onChange('custom', nextVal, 'image setting', false)}
                            value={node.props.custom}
                        />
                    </Label>

                    {node.props.custom &&
                        <React.Fragment>
                            <div className={css['filter-outline']} style={{ display: 'block' }}>
                                {/* <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}> */}
                                        <Label
                                            collapsed
                                            labelIcon="sn-width"
                                            label={intl.formatMessage({ id: 'options.image width' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker
                                                name={intl.formatMessage({ id: 'options.image width' })}
                                                unit={node.props.widthType}
                                                units={['px', '%']}
                                                onUnitChange={val => onChange('widthType', val)}
                                                min={0}
                                                max={node.props.widthType === '%' ? 100 : 1400}
                                                value={node.props.width}
                                                onChange={val => onChange('width', val)}
                                            />
                                        </Label>
                                    {/* </div>
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}> */}
                                        <Label
                                            collapsed
                                            labelIcon="sn-height2"
                                            label={intl.formatMessage({ id: 'options.image height' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image height' })}
                                                unit={node.props.heightType}
                                                units={['px', '%']}
                                                onUnitChange={val => onChange('heightType', val)}
                                                min={0}
                                                max={node.props.heightType === '%' ? 100 : 1400}
                                                value={node.props.height}
                                                onChange={val => onChange('height', val)}
                                            />
                                        </Label>
                                    {/* </div>
                                </div> */}
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>
                                        <Label
                                            collapsed
                                            labelIcon="sn-arrow-resize3"
                                            label={intl.formatMessage({ id: 'options.image crop position horizontal' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image crop position horizontal' })}
                                                unit="%"
                                                min={0}
                                                max={100}
                                                value={node.props.objectPositionX}
                                                onChange={val => onChange('objectPositionX', val)}
                                            />
                                        </Label>
                                    </div>
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>
                                        <Label
                                            collapsed
                                            labelIcon="sn-arrow-resize4"
                                            label={intl.formatMessage({ id: 'options.image crop position vertical' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image crop position vertical' })}
                                                unit="%"
                                                min={0}
                                                max={100}
                                                value={node.props.objectPositionY}
                                                onChange={val => onChange('objectPositionY', val)}
                                            />
                                        </Label>
                                    </div>
                                </div>

                                <Label
                                    label={intl.formatMessage({ id: 'options.image alignment horizontal' })}
                                    labelIcon="sn-arrow-resize3"
                                >
                                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                                        <Toggle
                                            icon="sn-vertical_align_top"
                                            rotated="counterclockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                                            tooltipPosition="top center"
                                            value={node.props.alignment === 'flex-start'}                            
                                            onChange={() => onChange('alignment', 'flex-start', 'image alignment', false)}
                                        />
                                        <Toggle
                                            icon="sn-vertical_align_center"
                                            rotated="clockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                                            tooltipPosition="top center"
                                            value={['flex-start', 'flex-end'].indexOf(node.props.alignment) === -1}
                                            onChange={() => onChange('alignment', 'center', 'image alignment', false)}
                                        />
                                        <Toggle
                                            icon="sn-vertical_align_top"
                                            rotated="clockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                                            tooltipPosition="top center"
                                            value={node.props.alignment === 'flex-end'}
                                            onChange={() => onChange('alignment', 'flex-end', 'image alignment', false)}
                                        />
                                    </ToggleGroup>
                                </Label>
                            </div>
                        </React.Fragment>
                    }
                </Section>
{/* 				
                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                /> */}

                {/* <BackgroundOptions {...props} /> */}
            </Accordion>
        </React.Fragment>
    );
}));
