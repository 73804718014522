import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/BackgroundImageHero';

export default {
	name: 'templates.template background image hero',
	metaDescription: 'hero section with text and background image + button + headline',
	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/background-image-hero-template.png',
	type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
};
