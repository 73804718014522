import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import Text from 'components/option-library/primitives/input/text';
import Checkbox from 'components/option-library/primitives/checkbox';
import Dropdown from 'components/option-library/primitives/dropdown/simple';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    multipleStates
                    presets={props.theme.presets.radio}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label
                    labelIcon="sn-typography"
                    label={intl.formatMessage({ id: 'forms.radio label title' })}
                    // labelDescription="Something about Radio labels"
                >
                    <Text
                        value={node.props.label}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('label', nextValue);
                        }}
                        placeholder={intl.formatMessage({ id: 'forms.radio label placeholder' })}
                    />
                </Label>

				<Label
                    labelIcon="sn-more2"
                    label={intl.formatMessage({ id: 'forms.radio options label' })}
                    // labelDescription="Something about radio options"
                >
                    <Text
                        value={node.props.options}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('options', nextValue);
                        }}
                        placeholder={intl.formatMessage({ id: 'forms.radio options placeholder' })}
                    />
                </Label>
                
                <Label>
                    <Checkbox
                        icon="sn-warning"
                        label={intl.formatMessage({ id: 'forms.required field label' })}
                        value={node.props.required}
                        onChange={val => onChange('required', val, 'toggle required', false)}
                    />
                </Label>
            </Section>
        </React.Fragment>
    );
}));