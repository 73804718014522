import React, { PureComponent, Component } from 'react';
import getComponent from './components/exports';
import PropTypes from 'prop-types';
import Styling from './Styling';

const applyHocs = (Component, componentType, extend, extendAll) => {
    let C = Component;

    /*
        Applies general HOCs (HOCS that target all nodes)
    */
    if (extendAll && extendAll.hocs) {
        C = extendAll.hocs.reduce((c, hoc) => {
            if (hoc.exceptions && hoc.exceptions.includes(componentType)) {
                return c;
            }

            return hoc(c);
        }, C);
    }

    /*
        Applies HOCs to specific nodes
    */
    if (extend && extend.hocs) {
        C = extend.hocs.reduce((c, hoc) => {
            return hoc(c);
        }, C);
    }

    return Styling(C);
}

/*
    Mapping context to props; necessary to be able to access context in <Node /> via props
*/
const ContextProvider = Component => {
    return class Composer extends PureComponent {
        render() {
            const { ContextConsumer } = this.props;
    
            return (
                <ContextConsumer>
                    {context => (
                        <Component 
                            context={context}
                            {...this.props}
                        />
                    )}
                </ContextConsumer>
            );
        }
    }
}

class Node extends Component {
    constructor(props) {
        super(props);

        const { nodeID, context } = props;
        const { nodeMap } = context;

        const node = nodeMap[nodeID];

        const Component = getComponent(node.component);

        this.state = {
            node: null,
            updateState: props.context.updateState,
            getState: props.context.getState,
            /*
                Could memoize the created HOCs for Live performance (might be too marginal to care, but everything counts!)
            */
            Component: applyHocs(Component, node.component, context.extend[node.component], context.extend && context.extend.all)
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { nodeID, context } = props;
        const { nodeMap, activeBreakpoint } = context;

        const currentNode = nodeMap[nodeID];
        let stateMutations = null;

        const breakpointProps = activeBreakpoint && activeBreakpoint[nodeID];
        const breakpointPropsChanged = breakpointProps !== state.breakpointProps;
        const nodeChanged = currentNode !== state.currentNode;

        if (breakpointPropsChanged || nodeChanged) {
            stateMutations = {
                node: {
                    ...currentNode,
                    ...breakpointProps
                },
                currentNode,
                breakpointProps
            };
        }

        const Component = getComponent(currentNode.component);

        if (context.extend && context.extend.all && context.extend.all.mapContextToProps) {
            const customComponentProps = context.extend.all.mapContextToProps(props, state, currentNode);

            if (customComponentProps) {
                stateMutations = {
                    ...stateMutations,
                    ...customComponentProps
                };
            }
        }

        if (Component.mapContextToProps) {
            const customComponentProps = Component.mapContextToProps(props, state, currentNode);
    
            if (customComponentProps) {
                stateMutations = {
                    ...stateMutations,
                    ...customComponentProps
                };
            }
        }

        return stateMutations;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // We are only interested in checking if the current node has changed (and possibly certain props later but we'll cross that road)
        if (nextState !== this.state) {   
            console.log('%cnode update: ' + this.state.node.component, 'color: white; background: black');

            return true;
        }

        return false;
    }

    getPath = (path = []) => {
        // if (this.state.node.component === 'Container') return path;

        path.unshift(this);

        if (!this.props.getPath) return path;

        return this.props.getPath(path);
    }

    // getNodeInstance = () => this;
 
    render() {
        return (
            <this.state.Component
                {...this.props}
                {...this.state} // = context mapped to state
                // getNodeInstance={this.getNodeInstance}
                getPath={this.getPath}
            />
        );
    }
}

Node.propTypes = {
    nodeID: PropTypes.string.isRequired,
    ContextConsumer: PropTypes.any.isRequired
};

export default ContextProvider(Node);

{/* <Node>
    <Selectable>
        <Component />
    </Selectable>
</Node> */}