exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".column__collapsing-margin-fix--21LV5{clear:both;content:\"\";display:table;background:transparent;margin-top:-1px;height:0}.column__column--1jjbW{box-sizing:border-box;position:relative}\n", ""]);

// exports
exports.locals = {
	"collapsing-margin-fix": "column__collapsing-margin-fix--21LV5",
	"column": "column__column--1jjbW"
};