exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".background-video__background-video-container--3x8Rw{position:absolute;overflow:hidden;top:0;left:0;bottom:0;right:0}.background-video__background-video-container--3x8Rw .background-video__background-video--2F--H{position:absolute;min-width:100%;min-height:100%;left:50%;transform:translateX(-50%)}\n", ""]);

// exports
exports.locals = {
	"background-video-container": "background-video__background-video-container--3x8Rw",
	"background-video": "background-video__background-video--2F--H"
};