import React, { PureComponent } from 'react';
import makeDebounce from '../../../debounce';

const debounce = makeDebounce(500);

const getScreenWidth = (elemId = null) => {
    const container = elemId ? document.getElementById(elemId) : document.body;

    const { width } = container.getBoundingClientRect();

    return width;
}

export default Component => {
    return class WidthListener extends PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                containerWidth: process.env.SSR ? 500 : getScreenWidth() // Plug some "best guess" logic in here for what to render on the server =)
            };
        }

        componentDidMount() {
            this.resizeHandler = () => {
                debounce(() => this.setState({
                    containerWidth: getScreenWidth()
                }));
            }

            window.addEventListener('resize', this.resizeHandler);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.resizeHandler);
        }

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                />
            )
        }
    }
}
