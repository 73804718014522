import React, { useMemo, useState, useRef, useEffect } from 'react';
import computeDiff, { lock, isLocked, clearLocks } from './computeDiff';
import suspendHistory from 'components/editor-v2/suspend';

// const extractContextFromProps = (state, data) => {
//     const dataTypes = Object.keys(data);

//     const newContext = dataTypes.reduce((acc, type) => {
//         if (data[type] === state[type]) return acc;

//         return {
//             ...acc,
//             [type]: data[type]
//         };
//     }, state);

//     return newContext;
// }

const createUpdateMethods = (rendererState, setRendererState) => {
    const updateState = (fn, action = 'Modification made (TRANSL8)', autoHistory = true, unlock = false) => {
        setRendererState(state => {
            if (!unlock && state.locked) return state;

            const newState = fn(state);

            if (!newState) return state; // can optionally return a falsy value and updateState won't fire
    
            const update = newState;

            if (action !== 'skip' && autoHistory) {
                const diffIdentifier = JSON.stringify(computeDiff(state.nodeMap, newState.nodeMap));
    
                if (isLocked(diffIdentifier)) {
                    action = 'skip';
                } else {
                    lock(diffIdentifier)
                }
            }

            if (action !== 'skip') {
                if (!state.hasChanged) update.hasChanged = true; // We use this to locally determine if draft can be saved
                
                update.past = [...state.past, state.nodeMap];
                update.future = [];
                update.currentAction = {
                    description: action,
                    forward: true
                },
                update.pastActions = [...state.pastActions, action];
                update.futureActions = [];
            }

            return {
                ...state,
                ...update
            };
        });
    };

    const undo = () => {
        setRendererState(state => {
            if (state.past.length === 0 || state.locked) return state;
            
            clearLocks();

            const lastState = state.past[state.past.length - 1];

            // const backToBeginning = state.past.length === 1;

            return {
                ...state,
                hasChanged: true,
                // hasChanged: !backToBeginning, <-- this is a pretty nice experience but has edge case where if you publish and then go back you cant republish without making a change
                nodeMap: lastState,
                past: state.past.slice(0, -1),
                future: [...state.future, state.nodeMap],
                currentAction: {
                    description: state.pastActions[state.pastActions.length - 1],
                    forward: false
                },
                pastActions: state.pastActions.slice(0, -1),
                futureActions: [...state.futureActions, state.pastActions[state.pastActions.length - 1]]
            };
        });
    };

    const redo = () => {        
        setRendererState(state => {
            if (state.future.length === 0 || state.locked) return state;
    
            clearLocks();

            return {
                ...state,
                hasChanged: true,
                nodeMap: state.future[state.future.length - 1],
                past: [...state.past, state.nodeMap],
                future: state.future.slice(0, -1),
                currentAction: {
                    description: state.futureActions[state.futureActions.length - 1],
                    forward: true
                },
                pastActions: [...state.pastActions, state.futureActions[state.futureActions.length - 1]],
                futureActions: state.futureActions.slice(0, -1)
            };
        });
    };

    const suspend = suspendHistory({ 
        getState: rendererState.getState, 
        updateState 
    });

    return {
        undo,
        redo,
        updateState,
        suspend
    };
}

const onClickOutside = (e, containerElement, updateState) => {
    // if (!this.props.portals) return;

    const target = e.target;
    const rootNode = containerElement;
    const clickedOnRootNode = rootNode === target;
    // Pretty ugly but no time to rewrite
    const portalContainerElements = [
        'editor-sidenav-portal', 
        'undo-redo-portal', 
        'save-or-publish-portal',
        'device-picker-portal'
    ].map(portal => document.getElementById(portal));
    const clickedOnAPortal = portalContainerElements.some(portalContainer => portalContainer && portalContainer.contains(target));

    const clickExceptions = document.body.querySelectorAll('[data-ignore-click]');

    const clickedOnAnException = [].slice.call(clickExceptions).some(container => container.contains(target));

    if (rootNode && !rootNode.contains(target) && !clickedOnRootNode && !clickedOnAPortal && !clickedOnAnException) {
        e.stopImmediatePropagation();

        updateState(state => { 
            return {
                ...state,
                selectedNode: null,
                editableNode: null
            } 
        }, 'skip');
    }
}

const mergeChangedKeys = (currentObj, newObj, excludedKeys = []) => {
    return Object.entries(newObj).reduce((acc, [key, value]) => {
        const noChanges = currentObj[key] === newObj[key];

        if (noChanges || excludedKeys.includes(key)) return acc;

        acc[key] = value;

        return acc;
    }, {})
}

const useRendererContext = (props, debug) => {
    const rendererStateRef = useRef();
    const rendererContainerRef = useRef();
    const RendererContext = useMemo(() => React.createContext(), []);

    const setupInitialState = props => {
        let nodeMap, breakpoints, state;
        const { live, draft } = props.resolveContent(props);

        if (props.useDraft) {
            nodeMap = draft ? draft.nodeMap : {};
            breakpoints = draft ? draft.breakpoints: {};
        } else {
            nodeMap = live ? live.nodeMap : {};
            breakpoints = live ? live.breakpoints : {};
        }

        state = {
            getState: () => rendererStateRef.current,
            RendererContext,
            nodeMap,
            breakpoints,
            past: [],
            future: [],
            pastActions: [],
            futureActions: [],
            ...props,
            lastProps: props
        };

        return state;
    };

    const [rendererState, setRendererState] = useState(() => setupInitialState(props));

    if (typeof rendererState.updateState === 'undefined') {
        // Putting methods in state so the context Provider can disperse them
        setRendererState(state => ({
            ...state,
            ...createUpdateMethods(rendererState, setRendererState)
        }));
    }

    // Merge in props whenever they change - important that props passed to useRendererState are stable (use useMemo in the caller)
    // We merge props in so they become globally available through the context Provider (see Renderer)
    if (rendererState.lastProps !== props) {
        // Mimicking getDerivedStateFromProps...
        // console.count('making new state...' + debug);

        // In some cases we allow nodeMap changes to override the currently held nodeMap; this is needed in cases where a Renderer surrounds
        // the Editor, such as in the case of a layout around a page - swapping the pages layout should cause the nodeMap to completely refresh
        // if (props.shouldSwapNodemap && props.shouldSwapNodemap(state, props)) {
        //     const { live } = props.resolveContent(props);
            
        //     setRendererState(state => {
        //         return {
        //             ...state,
        //             nodeMap: live ? live.nodeMap : {},
        //             breakpoints: live ? live.breakpoints : {}
        //         };
        //     });
        // } 

        setRendererState(state => {
            const newState = {
                ...state,
                ...mergeChangedKeys(state, props, props.staticKeys),
                lastProps: props
            }

            // If we are editing content we will never allow nodeMaps to get overriden by props
            // if (props.editor) {
            //     newState.nodeMap = state.nodeMap;
            //     newState.breakpoints = state.breakpoints;
            // }

            if (props.shouldSwapNodeMap && props.shouldSwapNodeMap(state, props)) {
                const { live } = props.resolveContent(props);
                
                newState.nodeMap = live ? live.nodeMap : {};
                newState.breakpoints = live ? live.breakpoints : {};
            } 

            return newState;

            // return { 
            //     ...state,
            //     ...console.log(mergeChangedKeys(state, props), 'yeeee merge') || mergeChangedKeys(state, props),
            //     // ...state,
            //     // ...props, // TO-DO: Only merge data that changed - fixes live preview problem being out of sync
            //     lastProps: props
            // }
        });
    }

    useEffect(() => {
        const handler = e => onClickOutside(e, rendererContainerRef.current, rendererStateRef.current.updateState);

        document.addEventListener('mousedown', handler);

        return () => document.removeEventListener('mousedown', handler);
    }, []);

    // Not 100% sure if this works in all cases
    // https://overreacted.io/how-are-function-components-different-from-classes/ <--- some reading here toward the bottom of the link
    rendererStateRef.current = rendererState;

    return React.useMemo(() => {
        return {
            RendererContext,
            rendererState,
            rendererContainerRef,
            updateState: rendererState.updateState
        };
    }, [rendererState])
}

export default useRendererContext;
