import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'svift-ui'; // TO-DO: replace SUI
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';

/*
    We first planned to write a toggle component for the different cases:

        1) Toggle one of multiple values
        2) Toggle zero or more of multiple values
    
    But really it makes no sense to think down this line, because if you have a single togglable button, you can easily build
    the 2 above constellations by implementing your onChange logic correctly - it's arguably easier that way in the first place because you 
    aren't dealing with array return values (in the case of multiple things being togglable) and option arrays
*/
const Toggle = memo(props => {
    /*
        In the normal case props.value is just a boolean, this is basically a checkbox scenario where you can toggle something on or off
    */
    let toggled = props.value;
    const togglePopup = useTooltip();

    /*
        if toggleValue is defined it means we are dealing with a "radio" type scenario where each toggle in a group wants to write to the same
        key - in that case we use toggleValue to differentiate between them

        Example use case: alignment should have one of these values: 'left' || 'center' || 'right'
    */
    if (props.toggleValue) toggled = props.value === props.toggleValue;

    return (
        <React.Fragment>
            {props.tooltipLabel && 
                <Popup
                    popup
                    target={togglePopup.current}
                    position={props.tooltipPosition}
                    clearance={8}
                    zIndex={1000000}
                >
                    {/* transl8? */}
                    {props.tooltipLabel}

                    {props.toggled &&
                        // transl8
                        <span style={{ marginLeft: 4 }}>(active)</span>
                    }
                </Popup>
            }

            <Button
                icon
                primary={toggled}
                basic={toggled ? false : true}
                circular
                className={toggled ? 'active-button' : ''}
                ref={togglePopup} 
                onClick={e => {
                    if (props.toggleValue) {
                        props.onChange(toggled ? undefined : props.toggleValue, e);
                    } else {
                        props.onChange(!toggled, e);
                    }
                }}
            >
                {props.icon && <Icon name={props.icon} rotated={props.rotated} />}
                {props.children}
            </Button>
        </React.Fragment>
    );
});

Toggle.propTypes = {
    onChange: PropTypes.func.isRequired,
    toggleKey: PropTypes.string,
    toggleValue: PropTypes.any,
    value: PropTypes.any
};

export default Toggle;

// import React, { memo, useCallback } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'svift-ui'; // TO-DO: replace SUI
// // import css from './single-toggle.scss';

// const SingleToggle = memo(props => {
//     const onChange = useCallback(e => {
//         return props.onChange(e.target.value, e);
//     }, []);

//     return props.options.map(option => {
//         const toggled = option.value === props.value;

//         return (
//             <Button
//                 icon
//                 primary={toggled}
//                 basic
//                 circular
//                 className={toggled ? 'active-button' : ''}
//                 size="tiny"
//                 onClick={e => onChange(option.value, e)}
//             >
//                 {option.text}
//             </Button>
//         );
//     });
// });

// SingleToggle.propTypes = {
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.any,
//     options: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, value: PropTypes.any }))
// };

// export default SingleToggle;