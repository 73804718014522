import React, { Component } from 'react';
import {
	Grid,
	GridRow,
	GridColumn
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';

const stripe = Stripe((process.env.NODE_ENV === 'production' && !process.env.DEV_PRODUCTION) ? 'pk_live_v0T32s3r3FJxgIMEggDSKcfo' : 'pk_test_he13Cq9EK7Oj8s1CG6rK8vqE');

const addChangeListener = (stripeElement, listener) => stripeElement.addEventListener('change', listener);

export default class StripeElements extends Component {
	// https://stripe.com/docs/elements/reference API here for stripe Elements
	constructor() {
		super();

		this.listeners = [];

		this.createChargeToken = this.createChargeToken.bind(this);
		this.errorHandler = this.errorHandler.bind(this);
	}

	shouldComponentUpdate() {
		return false;
	}

	/* I think its fairly unsafe to have any internal state here; this component should NEVER rerender */
	componentDidMount() {
		const elements = stripe.elements();

		const style = {
			base: {
				fontSize: '24px',
				lineHeight: '24px',
				fontFamily: 'Ubuntu'
			},
		};

		this.cardNumber = elements.create('cardNumber', { style });
		this.cardExpiry = elements.create('cardExpiry', { style });
		this.cardCvc = elements.create('cardCvc', { style });

		this.cardNumber.mount('#stripe-card-number');
		this.cardExpiry.mount('#stripe-card-expiry');
		this.cardCvc.mount('#stripe-card-cvc');

		this.listeners.push(addChangeListener(this.cardNumber, this.errorHandler));
		this.listeners.push(addChangeListener(this.cardExpiry, this.errorHandler));
		this.listeners.push(addChangeListener(this.cardCvc, this.errorHandler));

		if (this.props.setTokenGenerator) {
			this.props.setTokenGenerator(this.createChargeToken);
		}
	}

	componentWillUnmount() {
		this.listeners.forEach((listener) => {
			listener.removeListener();
		});
	}

	errorHandler(event) {
		const displayError = document.getElementById('card-errors');

		if (event.error) {
			//displayError.textContent = event.error.message;

			this.props.onError(event);
		} else {
			displayError.textContent = '';
		}
	}

	createChargeToken() {
		return stripe.createToken(this.cardNumber);
	}

	renderPaymentInputs() {
		return (
			<Grid>
				<GridRow>
					<GridColumn computer={16}>
						<label><FormattedMessage id="activation.order payment card number label" /></label>
						<label htmlFor="stripe-card-number">
							<div id="stripe-card-number"></div>
						</label>
					</GridColumn>
				</GridRow>
				<GridRow style={{ paddingTop: 0 }}>
					<GridColumn computer={10}>
						<label><FormattedMessage id="activation.order payment card expiration date label" /></label>
						<label htmlFor="stripe-card-expiry">
							<div id="stripe-card-expiry"></div>
						</label>
					</GridColumn>
					<GridColumn computer={6}>
						<label><FormattedMessage id="activation.order payment card cvc label" /></label>
						<label htmlFor="stripe-card-cvc">
							<div id="stripe-card-cvc"></div>
						</label>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn computer={16}>
						<div id="card-errors"></div>
					</GridColumn>
				</GridRow>
			</Grid>
		);
	}

	render() {
		return (
			<div>
				{this.renderPaymentInputs()}
			</div>
		);
	}
}
