import React, { Component } from 'react';
import { MenuItem, Icon, Popup } from 'svift-ui';
import { setRoute } from 'utils/routing';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import config from '../../../../config';

const mapStateToProps = (state) => {
	return {
		siteid: state.sites.params.siteid,
		active: state.router.location.pathname === `/dashboard/${state.sites.params.siteid}`
	};
};

class SideMenuButton extends Component {
	setRoute() {
		if (this.props.admin) {
			window.location.replace(config.superadminDomain);
		} else {
			setRoute(`/dashboard/${this.props.siteid}`);
		}
	}

	render() {
		const notClickable = this.props.active && 'not-clickable';
		const active = this.props.active && 'active-button';

		return (
			<Popup
				trigger={
					<MenuItem
						link
						onClick={() => {
							this.setRoute();
						}}
					>
						<div>
									<Icon
										name={this.props.admin ? 'dashboard' : 'sn-logo'}
										className={`${active} ${notClickable} tint-color`}
										style={{ cursor: 'pointer', fontSize: 28, margin: 0 }}
									/>
						</div>
					</MenuItem>
				}
				content={this.props.intl.formatMessage({
					id: this.props.admin ? 'general.system dashboard title' : 'general.dashboard app title'
				})
				}
				position="bottom left"
			/>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(SideMenuButton));