exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".number__range-indicator--3-FyC{position:relative;z-index:1;display:flex;min-width:200px;width:100%;justify-content:space-between;margin-bottom:-4px}.number__range-indicator--3-FyC:after{content:' ';width:100%;height:0;position:absolute;z-index:0;top:50%;left:4;right:4;margin-top:-1px;border-bottom:dashed 2px #d8d4d0}\n", ""]);

// exports
exports.locals = {
	"range-indicator": "number__range-indicator--3-FyC"
};