import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import Section from '../Section';
import Text from 'components/option-library/primitives/input/text';
import { injectIntl } from "react-intl";
import Preset from 'components/editor-v2/editor-interface/options/common/preset';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.video}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label
                    labelIcon="sn-link"
                    label={intl.formatMessage({ id: 'options.link url title' })}
                    // labelDescription={intl.formatMessage({ id: 'options.link url title' })}
                >
                    <Text
                        value={node.props.src}
                        disableUndoRedo
                        onChange={nextValue => {
                            onChange('src', nextValue, 'IFrame url address')
                        }}
                        placeholder={intl.formatMessage({ id: 'options.iframe url placeholder' })}
                    />
                </Label>
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                />
            </Accordion>
        </React.Fragment>
    );
}));