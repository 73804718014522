import { createGrid, createRow, createColumn } from './createStandardContainers';

const removeRecursive = (nodeMap, sourcePath, nodeIDToRemove, targetAndSourceSameParent, removingEmptyParent) => {
	const parentNodeID = sourcePath[sourcePath.length - 2];
	const parentNode = nodeMap[parentNodeID];

	const indexToRemove = parentNode.children.findIndex(id => id === nodeIDToRemove);

	const node = nodeMap[parentNodeID] = {
		...parentNode,
		children: remove(parentNode.children, indexToRemove)
    };

    if (removingEmptyParent) delete nodeMap[nodeIDToRemove]; // If the parent is empty, we make sure to delete it so it doesn't clutter our data

	// If target and source is the same parent the source is simply being moved (added in the next step after removal above) - we skip the recursion in this case
	if (node.children.length === 0 && !targetAndSourceSameParent) removeRecursive(nodeMap, sourcePath.slice(0, -1), parentNodeID, false, true);
}

/*
    If we have an element that is alone in a column, which is alone in a row, which is alone in a grid, a drop *anywhere* on any level
    would result in nothing (+ it breaks some assumptions in later insertion/removal steps)
*/
const isElementDropIrrelevant = (nodeMap, sourceID, targetID) => {
    const target = nodeMap[targetID];

    if (!target.children) return false;

    const targetContainsSource = target.children.some(childID => childID === sourceID);
    const targetHasOneChild = target.children.length === 1;

    if (targetContainsSource && targetHasOneChild) return true;
    if (targetHasOneChild) return isElementDropIrrelevant(nodeMap, sourceID, target.children[0]);
    return false;
}

const standardHandler = (direction = 'before', description = 'move item') => (droppedItem, zone, updateState) => {
	const { nodeID: targetID, getParentPath: getParentPathTarget } = zone;
	const { id: sourceID, source, getParentPath: getParentPathSource } = droppedItem;

	const parentPathTarget = getParentPathTarget().map(node => node.props.nodeID);
	const parentPathSource = getParentPathSource().map(node => node.props.nodeID);
	const targetParentID = parentPathTarget[parentPathTarget.length - 2];
    const sourceParentID = parentPathSource[parentPathSource.length - 2];

	return updateState(context => {     
        if (isElementDropIrrelevant(context.nodeMap, sourceID, targetID)) return;
        
        const newNodeMap = {
            ...context.nodeMap
        };
        /*
        STEP ONE:
        Generate new source, either:
        - Use existing source if dropping on same level (no container generation needed)
        - Generate wrappers up the tree until the target is met, insert the source at the bottom
        - If wrappers were generated, at each of them to the nodemap
        */
        const finalSource = generateSource(newNodeMap, newNodeMap[sourceID], newNodeMap[targetID], sourceParentID);
        
        
        /*
        STEP TWO:
        - Remove source from it's parent (so it can be inserted into the target instead)
        - if the parent has no children after removing the source, delete the parent (recursively all the way up)
        */
        const targetAndSourceSameParent = targetParentID === sourceParentID;

        removeRecursive(newNodeMap, parentPathSource, sourceID, targetAndSourceSameParent);
        
        /*
        STEP THREE:
        Insert into target's parent
        */
        const insertHandler = direction === 'before' ? insertBefore : insertAfter;
    
        if (targetAndSourceSameParent) {
            const targetIndex = newNodeMap[sourceParentID].children.findIndex(nodeID => nodeID === targetID);

            newNodeMap[sourceParentID] = {
                ...newNodeMap[sourceParentID], 
                children: insertHandler(newNodeMap[sourceParentID].children, targetIndex, sourceID)
            }
        } else {
            const targetIndex = newNodeMap[targetParentID].children.findIndex(nodeID => nodeID === targetID);

            newNodeMap[targetParentID] = {
                ...newNodeMap[targetParentID],
                children: insertHandler(newNodeMap[targetParentID].children, targetIndex, finalSource.id)
            }
        }

        return {
            ...context,
            nodeMap: newNodeMap
        };
    }, description);
};

// const addBuildingBlockNodesToNodeMap = (nodeMap, node) => {
//     nodeMap[node.id] = node;

//     if (node.children) {
//         // Add all the child nodes (and their children) to the nodemap
//         node.children.forEach(childNode => addBuildingBlockNodesToNodeMap(nodeMap, childNode));

//         // Change the ids from full-blown nodes to ids (we keep our data flat)
//         node.children = node.children.map(childNode => childNode.id);
//     }
// };

const buildingBlockHandler = (direction = 'before', action = 'add item') => (droppedItem, zone, updateState) => {
	const { nodeID: targetID, getParentPath: getParentPathTarget } = zone;
	const { createBuildingBlock } = droppedItem;

	const parentPathTarget = getParentPathTarget().map(node => node.props.nodeID);
	const targetParentID = parentPathTarget[parentPathTarget.length - 2];

	return updateState(context => {
        let newNodeMap = {
            ...context.nodeMap
        };

        // Should be a flat nodemap
        const newNode = createBuildingBlock();

        if (newNode.rootID) { // If it's a template
            newNodeMap = {
                ...newNodeMap,
                ...newNode.nodes // merging in template nodes
            };
        } else {
            newNodeMap[newNode.id] = newNode;
        }

        // addBuildingBlockNodesToNodeMap(newNodeMap, newNode);

        const finalSource = generateSource(newNodeMap, newNode.nodes ? newNode.nodes[newNode.rootID] : newNode, newNodeMap[targetID]);

        const insertHandler = direction === 'before' ? insertBefore : insertAfter;

        const targetIndex = newNodeMap[targetParentID].children.findIndex(nodeID => nodeID === targetID);

        newNodeMap[targetParentID] = {
            ...newNodeMap[targetParentID],
            children: insertHandler(newNodeMap[targetParentID].children, targetIndex, finalSource.id)
        };

        return {
            ...context,
            nodeMap: newNodeMap
        };
    }, action);    
};

const addContainerToNodeMap = (nodeMap, container) => {
    nodeMap[container.id] = container;
};

const insertBefore = (array, index, value) => {
    return [
        ...array.slice(0, index),
        value,
        ...array.slice(index)
    ];
}

const insertAfter = (array, index, value) => {
    return [
        ...array.slice(0, index + 1),
        value,
        ...array.slice(index + 1)
    ];
}

// const insertAt = (array, index, value) => {
//     const indexAfterEndOfArray = index > array.length - 1;

//     if (indexAfterEndOfArray) {
//         return [...array.slice(), value];
//     } else {
//         return [
//             ...array.slice(0, index),
//             value,
//             ...array.slice(index)
//         ];
//     }
// }

// const swap = (array, firstIndex, secondIndex) => {
//     const newArray = [...array];
//     const tmpFirst = newArray[firstIndex];
//     newArray[firstIndex] = newArray[secondIndex];
//     newArray[secondIndex] = tmpFirst;

//     return newArray;
// }

const remove = (array, index) => {
    return [
        ...array.slice(0, index),
        ...array.slice(index + 1)
    ];
}

const generateSource = (nodeMap, source, target, sourceParentID) => {
    const sourceID = source.id;

    let finalSource = source; 

    if (source.component === 'Grid') return finalSource;

    switch (source.component) {
        case 'Row': {
            switch (target.component) {
                case 'Grid': {
                    const grid = createGrid([sourceID]);
                    addContainerToNodeMap(nodeMap, grid);

                    finalSource = grid;

                    break;
                }
            }
            
            break; // forgot this fucking thing and it took FOREVER to find - never nesting switch statements again :-/
        }

        case 'Column': {
            switch (target.component) {
                case 'Row': {
                    const row = createRow([sourceID]);
                    addContainerToNodeMap(nodeMap, row);

                    finalSource = row;

                    break;
                }   
                case 'Grid': {
                    const row = createRow([sourceID]);
                    addContainerToNodeMap(nodeMap, row);

                    const grid = createGrid([row.id]);
                    addContainerToNodeMap(nodeMap, grid);

                    finalSource = grid;

                    break;
                }
            }

            break;
        }

        case 'ColumnElement': 
        default: {
            switch (target.component) {
                case 'Column': {
                    const column = createColumn([sourceID]);

                    if (sourceParentID) column.width = nodeMap[sourceParentID].width || column.width;

                    addContainerToNodeMap(nodeMap, column);

                    finalSource = column;

                    break;
                }
                case 'Row': {
                    const column = createColumn([sourceID]);

                    /* Preserves width when moving elements to new containers - might be too confusing, not sure? */ 
                    if (sourceParentID) column.width = nodeMap[sourceParentID].width || column.width;

                    addContainerToNodeMap(nodeMap, column);

                    const row = createRow([column.id]);
                    addContainerToNodeMap(nodeMap, row);

                    finalSource = row;

                    break;
                }   
                case 'Grid': {
                    const column = createColumn([sourceID]);

                    /* Preserves width when moving elements to new containers - might be too confusing, not sure? */ 
                    if (sourceParentID) column.width = nodeMap[sourceParentID].width || column.width;

                    addContainerToNodeMap(nodeMap, column);

                    const row = createRow([column.id]);
                    addContainerToNodeMap(nodeMap, row);

                    const grid = createGrid([row.id]);
                    addContainerToNodeMap(nodeMap, grid);

                    finalSource = grid;

                    break;
                }
            }
        }
    }

    return finalSource;
}

const dndInteractions = {
    ElementBuildingBlock: {
        targets: {
            gridZoneTop: buildingBlockHandler('before', 'add element'),
            gridZoneBottom: buildingBlockHandler('after', 'add element'),
            rowZoneTop: buildingBlockHandler('before', 'add element'),
            rowZoneBottom: buildingBlockHandler('after', 'add element'),
            columnZoneLeft: buildingBlockHandler('before', 'add element'),
            columnZoneRight: buildingBlockHandler('after', 'add element'),
            columnElementZoneTop: buildingBlockHandler('before', 'add element'),
            columnElementZoneBottom: buildingBlockHandler('after', 'add element')                 
        }
    },
    // Naming is a bit awkward but just trying to be future proof in case we add ColumnTemplateBuildingBlock, RowTemplateBuldingBlock, etc. later
    SectionTemplateBuildingBlock: {
        targets: {
            gridZoneTop: buildingBlockHandler('before', 'add element'),
            gridZoneBottom: buildingBlockHandler('after', 'add element'),             
        }
    },
    Grid: {
        sources: ['SectionTemplateBuildingBlock', 'ColumnElement', 'ElementBuildingBlock', 'Grid', 'Row', 'Column'],
        targets: {
            gridZoneTop: standardHandler('before', 'move column element'),
            gridZoneBottom: standardHandler('after', 'move column element'),
        },
        cursorZones: [
            {
                id: 'gridZoneTop',
                type: 'Grid',
                handler: 'gridZoneTop',
                xStart: 0,
                xEnd: 100,
                yStart: 0,
                yEnd: 50
            },
            {
                id: 'gridZoneBottom',
                type: 'Grid',
                handler: 'gridZoneBottom',
                xStart: 0,
                xEnd: 100,
                yStart: 50,
                yEnd: 100
            },
            {
                id: 'gridZoneTopEscape',
                type: 'GridEscape',
                handler: 'gridZoneTop',
                escape: true,
                xStart: 0,
                xEnd: 100,
                yStart: 0,
                yEnd: 10,
                maxLength: {
                    position: 'top',
                    px: 24
                }
            },
            {
                id: 'gridZoneBottomEscape',
                type: 'GridEscape',
                handler: 'gridZoneBottom',
                escape: true,
                xStart: 0,
                xEnd: 100,
                yStart: 90,
                yEnd: 100,
                maxLength: {
                    position: 'bottom',
                    px: 24
                }
            }
        ]
    },
    Row: {
        sources: ['ColumnElement', 'ElementBuildingBlock', 'Row', 'Column'],
        targets: {
            gridZoneTop: standardHandler('before', 'move column element'),
            gridZoneBottom: standardHandler('after', 'move column element'),
            rowZoneTop: standardHandler('before', 'move column element'),
            rowZoneBottom: standardHandler('after', 'move column element'),
        },
        cursorZones: [
            // {
            //     id: 'rowZoneLeft',
            //     type: 'RowHorizontal',
            //     handler: 'rowZoneLeft',
            //     xStart: 0,
            //     xEnd: 15,
            //     yStart: 0,
            //     yEnd: 100
            // },
            // {
            //     id: 'rowZoneRight',
            //     type: 'RowHorizontal',
            //     handler: 'rowZoneRight',
            //     xStart: 85,
            //     xEnd: 100,
            //     yStart: 0,
            //     yEnd: 100
            // },
            {
                id: 'rowZoneTop',
                type: 'Row',
                handler: 'rowZoneTop',
                xStart: 0,
                xEnd: 100,
                yStart: 0,
                yEnd: 50
            },
            {
                id: 'rowZoneBottom',
                type: 'Row',
                handler: 'rowZoneBottom',
                xStart: 0,
                xEnd: 100,
                yStart: 50,
                yEnd: 100
            },
            {
                id: 'rowZoneTopEscape',
                type: 'RowEscape',
                handler: 'rowZoneTop',
                escape: true,
                xStart: 0,
                xEnd: 100,
                yStart: 0,
                yEnd: 15,
                maxLength: {
                    position: 'top',
                    px: 32
                }
            },
            {
                id: 'rowZoneBottomEscape',
                type: 'RowEscape',
                handler: 'rowZoneBottom',
                escape: true,
                xStart: 0,
                xEnd: 100,
                yStart: 85,
                yEnd: 100,
                maxLength: {
                    position: 'bottom',
                    px: 32
                }
            }
        ]
    },
    Column: {
        sources: ['ColumnElement', 'ElementBuildingBlock', 'Column'], 
        cursorZones: [
            {
                id: 'columnZoneLeft',
                handler: 'columnZoneLeft',
                type: 'Column',
                xStart: 0,
                xEnd: 20,
                yStart: 0,
                yEnd: 100
            },
            {
                id: 'columnZoneRight',
                handler: 'columnZoneRight',
                type: 'Column',
                xStart: 80,
                xEnd: 100,
                yStart: 0,
                yEnd: 100
            },
            {
                id: 'columnZoneLeft',
                handler: 'columnZoneLeft',
                type: 'ColumnInner',
                xStart: 20,
                xEnd: 50,
                yStart: 0,
                yEnd: 100
            },
            {
                id: 'columnZoneRight',
                handler: 'columnZoneRight',
                type: 'ColumnInner',
                xStart: 50,
                xEnd: 80,
                yStart: 0,
                yEnd: 100
            },            
            // {
            //     id: 'columnZoneLeftEscape',
            //     escape: true,
            //     handler: 'columnZoneLeft',
            //     type: 'ColumnEscape',
            //     xStart: 0,
            //     xEnd: 20,
            //     yStart: 0,
            //     yEnd: 100,
            //     maxLength: {
            //         position: 'left',
            //         px: 24
            //     }
            // },
            // {
            //     id: 'columnZoneRightEscape',
            //     escape: true,
            //     handler: 'columnZoneRight',
            //     type: 'ColumnEscape',
            //     xStart: 80,
            //     xEnd: 100,
            //     yStart: 0,
            //     yEnd: 100,
            //     maxLength: {
            //         position: 'right',
            //         px: 24
            //     }
            // }
        ],
        targets: {
            gridZoneTop: standardHandler('before', 'move column element'),
            gridZoneBottom: standardHandler('after', 'move column element'),
            rowZoneTop: standardHandler('before', 'move column element'),
            rowZoneBottom: standardHandler('after', 'move column element'),
            columnZoneLeft: standardHandler('before', 'move column element'),
            columnZoneRight: standardHandler('after', 'move column element'),
        }
    },
    ColumnElement: {
        sources: ['ColumnElement', 'ElementBuildingBlock'],
        cursorZones: [
            {
                id: 'columnElementZoneTop',
                handler: 'columnElementZoneTop',
                type: 'ColumnElement',
                xStart: 0,
                xEnd: 100,
                yStart: 0,
                yEnd: 50
            },
            {
                id: 'columnElementZoneBottom',
                handler: 'columnElementZoneBottom',
                type: 'ColumnElement',
                xStart: 0,
                xEnd: 100,
                yStart: 50,
                yEnd: 100
            }
        ],
        targets: {
            gridZoneTop: standardHandler('before', 'move column element'),
            gridZoneBottom: standardHandler('after', 'move column element'),
            rowZoneTop: standardHandler('before', 'move column element'),
            rowZoneBottom: standardHandler('after', 'move column element'),
            columnZoneLeft: standardHandler('before', 'move column element'),
            columnZoneRight: standardHandler('after', 'move column element'),
            columnElementZoneTop: standardHandler('before', 'move column element'),
            columnElementZoneBottom: standardHandler('after', 'move column element')       
        }           
    }
}

export default dndInteractions;