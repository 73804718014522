import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    const {
        presetValue,
        min,
        max,
    } = props;

    // const valueIsNumber = !isNaN(props.value);
    // const valueIsNotDefault = valueIsNumber && props.value !== presetValue;

    return (
        <Label
            label={props.label}
            labelIcon={props.icon}
            collapsed
            fontSize={12}
            clear={{
                onClear: () => props.onChange(),
                enabled: props.hasValue //valueIsNotDefault
            }}
        >
            <NumberPicker
                disableUndoRedo
                size="mini"
                name={props.label}
                min={min}
                max={max}
                // placeholder={presetValue}
                tooltipPosition={props.tooltipPosition}
                value={props.value}
                onChange={props.onChange}					
            />
        </Label>
    );
});