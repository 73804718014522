import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import key from 'svift-library/key';

class IFrame extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, extend } = props.context;
	
        return shallowCompare({
			theme,
			IFrameStyling: extend.IFrame && extend.IFrame.styling
        }, state);
    }
	
	constructor(props) {
		super(props);

		this.state = {
			iframeSrc: props.node.props.src || ''
		};
	}

	checkSrcIsValid = src => {
		if (!src) return false;
 
		// Don't know if this check is too naive, but I'm pretty sure it's fine
		if (!(src.startsWith('http://') || src.startsWith('https://'))) return false;

		return true;
	}

	renderIFrame = () => {
		const { node } = this.props;

		const style = { ...this.props.computedStyling, ...this.props.cropStyling, ...this.props.IFrameStyling }

		// const autoHeightEnabled = key('selected.autoHeight')(node.props);
		// let height;

		// if (!autoHeightEnabled) {
		// 	height = key('selected.height')(node.props);
		// }

		if (!this.checkSrcIsValid(node.props.src)) {
			return (
				<img
					ref={ref => {
						if (this.props.cropRef) this.props.cropRef.current = ref
					}}
					width="100%"
					// height="100%"
					// height={height || '100%'}
					style={style}
					src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-iframe.svg"
				/>
			);
		}

		// if (autoHeightEnabled || !height) {
			// return (
			// 	<div 
			// 		{...this.props.cropProps}
			// 		style={{
			// 			position: 'relative',
			// 			width: '100%',
			// 			paddingTop: '87.5%',
			// 		}}
			// 	>
			// 		<div
			// 			style={{
			// 				position: 'absolute',
			// 				overflow: 'hidden',
			// 				top: 0,
			// 				left: 0,
			// 				right: 0,
			// 				bottom: 0,
			// 				...style
			// 			}}
			// 		>
			// 			<iframe
			// 				width="100%"
			// 				height="100%"
			// 				src={node.props.src}
			// 			/>	
			// 		</div>
			// 	</div>
			// )
		// }
	
		return (
			<iframe
				ref={ref => {
					if (this.props.cropRef) this.props.cropRef.current = ref
				}}
				width="100%"
				height="100%"
				style={style}
				src={node.props.src}
			/>	
		);
	}

	render() {
		return this.renderIFrame();
	}
}

export default Crop(IFrame);