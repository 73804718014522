/*
    SmartImage is primarily intended for cases where a file uploaded to a site is used

    SmartImage will handle lazy loading a high resolution version of the picture, while showing a blurred image initially

    SmartImage can also work like a regular img tag (with loading/fallback/etc)
*/
import React, { useState, useCallback } from 'react';
import useServingUrl from './useServingUrl';
import { resolveFileUrl } from 'svift-library/client/fileHelper';

const Image = React.forwardRef((props, ref) => {
    // const [loading, setLoading] = useState();

    // if (props.showSpinner) {

    // }

    // const onLoad = useCallback(() => {
    //     return () => setLoading(false);
    // });

    return (
        <img
            {...props}
            // onLoad={onLoad}
            ref={ref}
        />
    );
});

const SmartImage = props => {
    const { /*imageProps, */ file, ...rest } = props;
    const { state, imgRef } = useServingUrl(file, props);

    const src = resolveFileUrl(file, state.urlOptions);
    const srcFinal = state.urlOptionsFinal && resolveFileUrl(file, state.urlOptionsFinal);

    return (
        <Image
            {...rest}
            src={srcFinal || src}
            ref={imgRef}
        />
    );

    // return (
    //     <div ref={imgRef} style={{ position: 'relative', background: 'blue', width: '100%', height: '100%' }}>
    //         <Image
    //             {...rest}
    //             // style={{ position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}
    //             src={src}
    //             // ref={imgRef}
    //         />

    //         {srcFinal &&
    //             <Image
    //                 {...rest}
    //                 style={{ position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}
    //                 src={srcFinal}
    //                 // ref={imgRef}
    //             />
    //         }
    //     </div>
    // );
};

const SmartImageHandler = props => {
    const { file, ...rest } = props; // Just removing keys that shouldn't touch the DOM; we pass all other props along in the "...rest" destructuring

    if (!file) {
        if (props.src) {
            return (
                <Image
                    {...rest}
                />
            );
        }

        return (
            <Image
                src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg"
                {...rest}
            />
        );
    }

    let altDescription = `Name: ${file.meta.name}.`;

    if (file.meta.description) altDescription = `Name: ${file.meta.name}. Description: ${file.meta.description}.`
    if (file.meta.credit) altDescription = altDescription + ' Credit: ' + file.meta.credit + '.';

    if (file.serving_url) {
        return (
            <SmartImage 
                key={file.serving_url} // Remount when serving url changes
                // imageProps={imageProps}
                alt={altDescription}
                file={file}
                {...rest}
            />
        );
    }

    // When no serving url is defined we fall back to the cloud storage url (handles the SVG case too, which cannot have a serving url)
    return (
        <Image
            alt={altDescription}
            src={file.url}
            {...rest}
        />
    );
}

export default SmartImageHandler;