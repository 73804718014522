const trim = val => {
	while (val.startsWith('-')) {
		return trim(val.substring(1));
	}

	while (val.endsWith('-')) {
		return trim(val.substring(0, val.length - 1));
	}

	return val;
};

const urlConverter = (val = '') => {
	let url = val.toLowerCase();

	url = url.replace(/[ø]/gi, 'oe');
	url = url.replace(/[æ]/gi, 'ae');
	url = url.replace(/[å]/gi, 'aa');
	url = url.replace(/[^a-z0-9]/gi, '-');
	url = url.replace(/-+/gi, '-'); // Replace multiple adjacent dashes with one dash

	return trim(url); // Remove dashes from start and end
};

export default urlConverter;