import React, { PureComponent } from 'react';
import {
	Icon,
	Label,
	Segment,
	SegmentGroup,
    Button,
    Menu,
    MenuItem
} from 'svift-ui';
import Tree from 'components/tree/Tree';
import PageForm from 'components/forms/page';
import { pagesToMenuTree } from 'utils/pages/format';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setRoute } from 'utils/routing';
import { connect } from 'react-redux';
import actions from 'store/actions/sites/pages';
import onClickOutside from 'components/hoc/onClickOutside';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import SviftGA from 'utils/svift-ga';
import InfoIcon from 'components/InfoIcon';
import css from 'components/app-layout/app-navigation/app-navigation.scss'

import Modal from 'svift-library/client/modal';

import { pagesSelector } from 'store/selectors/sites/pages';
import makeKeywordsSelector  from 'store/selectors/sites/keywords';
const keywordsSelector = makeKeywordsSelector('page');

// const mapStateToProps = (state) => {
// 	const { sites } = state.sites;
// 	const siteid = state.sites.params.siteid;
// 	const pageid = state.sites.pages.params.pageid;
// 	const frontPage = state.sites.pages.pages[sites[siteid].frontPage];

// 	return {
// 		siteid,
// 		pageid,
// 		frontPageId: frontPage._id,
// 		keywords: keywordsSelector(state, siteid),
// 		pages: pagesSelector(state, siteid)
// 	};
// };

// const mapDispatchToProps = (dispatch) => ({
// 	createPage: (site, page) => dispatch(actions.createPage(site, page))
// });
@injectIntl
@onClickOutside
class PageList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
            // createPageModalOpen: false,
            listOpen: false
		};

		// this.formatPages = this.formatPages.bind(this);
		// this.createPage = this.createPage.bind(this);
		this.onClickOutside = this.onClickOutside.bind(this);
		this.setSelectedPage = this.setSelectedPage.bind(this);
	}

	// async createPage(data) {
	// 	const { createPage, siteid } = this.props;

	// 	try {
	// 		await createPage(siteid, data);

	// 		SviftGA.PAGES.CREATED();

	// 		this.setState({ showCreatePageModal: false });
	// 	} catch (e) {
	// 		handleSubmitError(this.props.intl)(e);
	// 	}
	// }

	formatPages() {
		const activePages = this.props.pages.filter(page => !page.hidden);

		return pagesToMenuTree(activePages);
	}

	setSelectedPage(id) {
		const { siteid, pageid } = this.props;

		if (id !== pageid) {
			setRoute(`/dashboard/${siteid}/pages/${id}`);
		}
	}

	onClickOutside() {
		if (!this.state.createPageModalOpen) {
            this.props.setListOpen(false);
        // if (this.state.listOpen) {
        //     this.setState({ listOpen: false });
        // }
			// this.props.toggleOpen();
		}
	}

	render() {
		const { intl, frontPageId } = this.props;

		return (
            <div
                className={`svift-menu-content ${css['animated']} ${css['fadeIn']} ${css['faster']}`}
                style={{ paddingBottom: '0px' }}
            >
                <Menu size="mini">
                    <MenuItem className="svift-menu-title">
                        <Icon name="sn-stack-text" className="primary-color" />
                        <FormattedMessage id="pages.pages title" />
                    </MenuItem>
                    <MenuItem position="right">
                        {/* TO DO: Add create page button */}
                        {/* <Button 
                            // onClick={() => this.setState({ createPageModalOpen: true })}
                            className="positive"
                        >
                            <Icon name="plus" />
                            <FormattedMessage id="pages.create page" />
                        </Button> */}
                    </MenuItem>
                </Menu>
                
                <Tree
                    className="svift-pages-list"
                    items={this.formatPages()}
                    TextComponent={props => {
                        const isFrontPage = props.item.data._id === frontPageId;
                        const isUnpublished = props.item.data.content.live === null;
                        const unsavedChanges = props.item.data.content.live && props.item.data.content.live.version !== props.item.data.content.draft.version;
                        
                        return (
                            <React.Fragment>
                                <Icon name="sn-file-text" style={{ verticalAlign: 'middle' }} />
                                <span style={{ verticalAlign: 'middle', marginRight: 8 }}>
                                    {props.text}
                                </span>

                                <span style={{ float: 'right', paddingRight: 8 }}>
                                    {isFrontPage && 
                                        <Label
                                            size="mini" 
                                            className="primary" 
                                            style={{ marginRight: 4 }}
                                        >
                                            <Icon name="sn-sphere" style={{ marginRight: 4 }} />
                                            <FormattedMessage id="general.homepage" />
                                        </Label>
                                    }

                                    {/* has draft content (and is online) */}
                                    {(unsavedChanges && !isUnpublished) &&
                                        <Label
                                            size="mini"
                                            className="info"
                                            style={{ marginRight: 4 }}
                                        >
                                            <Icon name="sn-quill3" style={{ marginRight: 6 }} />
                                            <FormattedMessage id="general.draft title" />
                                        </Label>									
                                    }


                                    {isUnpublished
                                        ?
                                            <Label
                                                size="mini" 
                                                className="warning" 
                                                style={{ marginRight: 0 }}
                                            >
                                                <Icon name="sn-eye-blocked" style={{ marginRight: 6 }} />
                                                <FormattedMessage id="general.offline title" />
                                            </Label>
                                        :
                                            <React.Fragment>
                                                <Label
                                                    size="mini" 
                                                    className="positive" 
                                                    style={{ marginRight: 0 }}
                                                >
                                                    <Icon name="sn-earth2" style={{ marginRight: 0 }} />
                                                    {/* <FormattedMessage id="general.online title" /> */}
                                                </Label>
                                                <InfoIcon
                                                    description={<FormattedMessage id="general.online description" />}
                                                    position="right center"
                                                    className="positive-color"
                                                    style={{ pointerEvents: 'auto' }}
                                                />
                                            </React.Fragment>
                                    }
                                </span>
                            </React.Fragment>
                        );
                    }}
                    ItemGroupComponent={props => <SegmentGroup className="svift-pages-list" {...props} />}
                    ItemComponent={props => {
                        const { path, item } = props;

                        return (
                            <Segment
                                {...props}
                                onClick={e => (e.stopPropagation(), this.setSelectedPage(item.data._id))}
                                className={item.data._id === this.props.pageid ? 'active-item' : ''}
                            />
                        );
                    }}
                />

                {/* {this.state.createPageModalOpen &&
                    <Modal
                        title={props.intl.formatMessage({ id: 'pages.create page' })}
                        width={1400}
                        toggleModal={() => this.setState({ createPageModalOpen: false })}
                        nodeAttributes={{
                            'data-ignore-click': true
                        }}
                    >
                        <PageForm
                            keywords={this.props.keywords}
                            onClose={() => this.setState({ createPageModalOpen: false })}
                            onSubmit={this.createPage}
                        />
                    </Modal>
                } */}
            </div>
		);
	}
}

// const ConnectedPageList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(onClickOutside(PageList)));

const SelectPageButton = injectIntl(props => {
    const [listOpen, setListOpen] = React.useState(false);
    const selectedPage = props.pages.find(page => page._id === props.pageid);
    const isHomePage = selectedPage._id === props.frontPageId;
    // const isPublished = selectedPage.content.live;
    // const hasDraft = selectedPage.content.live && selectedPage.content.live.version !== selectedPage.content.draft.version;

    const draftVersion = props.draftContent && props.draftContent.version;
    const liveVersion = props.liveContent && props.liveContent.version; 
    const isUnpublished = !props.liveContent;

    return (
        <React.Fragment>
            <Button
                icon
                basic 
                id="pages-page-menu-title"
                onClick={() => setListOpen(true)}
                size="small" 
                className={!listOpen ? 'primary medium-text' : 'primary active-button'}
                style={{ marginRight: 8, padding: '6px 8px' }}
            >
                <Icon name="sn-file-text" className="primary-color" style={{ fontSize: 16, verticalAlign: 'middle', height: 16 }} />

                <span style={{ marginLeft: 6, marginRight: 4, verticalAlign: 'middle' }}>
                    {selectedPage ? selectedPage.title : ''}
                </span>

                <Icon
                    name={!listOpen ? 'sn-arrow-down2' : 'sn-arrow-up2'}
                    style={{ verticalAlign: 'middle', fontSize: 10, height: 8 }} />
            </Button>

            {/* page status labels */}
            {isHomePage &&
                <Label className="primary" size="mini">
                    <Icon name="sn-sphere" style={{ marginRight: 4 }} />
                    <FormattedMessage id="general.homepage" />
                </Label>
            }

            {(props.hasChanged || draftVersion !== liveVersion) &&
                <Label className="info" size="mini">
                    <Icon name="sn-quill3" style={{ marginRight: 4 }} />
                    <FormattedMessage id="general.draft title" />
                </Label>
            }
            
            <Label size="mini" className={!isUnpublished ? 'positive' : 'warning'}>
                <Icon
                    name={!isUnpublished ? 'sn-earth2' : 'sn-eye-blocked'}
                    style={{ marginRight: 4 }}
                />
                <FormattedMessage id={`general.${!isUnpublished ? 'online' : 'offline'} title`}/>
            </Label>

            {!isUnpublished &&
                <InfoIcon
                    description={props.intl.formatMessage({ id: 'general.online description'})}
                    position="bottom center"
                    className="positive-color"
                    style={{ pointerEvents: 'auto' }}
                />
            }

            {listOpen && 
                <PageList setListOpen={setListOpen} {...props} /> 
            }
        </React.Fragment>
    );
});

export default connect((state) => {
	const { sites } = state.sites;
	const siteid = state.sites.params.siteid;
	const pageid = state.sites.pages.params.pageid;
	const frontPage = state.sites.pages.pages[sites[siteid].frontPage];

	return {
		siteid,
		pageid,
		frontPageId: frontPage._id,
		keywords: keywordsSelector(state, siteid),
		pages: pagesSelector(state, siteid)
	};
})(SelectPageButton);



