import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import { Icon } from 'svift-ui';
import Checkbox from 'components/option-library/primitives/checkbox';
import Section from '../Section';
// import StylingOverrides from 'components/editor-v2/editor-interface/options/common/styling-overrides';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background'
import { intlShape, injectIntl } from "react-intl";
import BackgroundVideoOptions from 'components/editor-v2/editor-interface/options/common/background-video';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.column}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />
                <Label
                    icon="sn-arrow-right8"
                    label={intl.formatMessage({ id: 'options.split column title' })}
                    labelDescription={intl.formatMessage({ id: 'options.split column description' })}
                >
                    <Checkbox
                        icon="sn-arrow-right8"
                        label={intl.formatMessage({ id: 'options.split column title' })}
                        value={node.props.floated}
                        onChange={val => onChange('floated', val, 'push column right', false)}
                    />
                </Label>
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <BackgroundVideoOptions {...props} />
                <BackgroundOptions {...props} />
                
                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                />
            </Accordion>
        </React.Fragment>
    );
}));