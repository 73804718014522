exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".checkbox__checkbox--3dSxY,.checkbox__radio--2b6Xo{vertical-align:middle}.checkbox__checkbox--3dSxY:hover,.checkbox__checkbox--3dSxY:hover label,.checkbox__radio--2b6Xo:hover,.checkbox__radio--2b6Xo:hover label{cursor:pointer;color:#5f4575;text-decoration:underline}.checkbox__radio--2b6Xo{margin-bottom:4px}.checkbox__radio--2b6Xo:last-child{margin-bottom:0}\n", ""]);

// exports
exports.locals = {
	"checkbox": "checkbox__checkbox--3dSxY",
	"radio": "checkbox__radio--2b6Xo"
};