import React, { Component } from 'react';
import {
	Message,
	Icon,
	Header,
	Button,
	Grid,
	GridRow,
	GridColumn,
	Divider,
	Box,
	Label
} from 'svift-ui';

import ModalWrapper from 'components/modal';
import { connect } from 'react-redux';
import actions from 'store/actions';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
// import ContactSupport from 'components/forms/contact-support';

import handleSubmitError from 'components/forms/__util/handleSubmitError';
import SiteForm from 'components/forms/site';
import SviftGA from 'utils/svift-ga';

const mapDispatchToProps = dispatch => {
	return {
		createSite: data => dispatch(actions.sites.createSite(data)),
	};
};

@connect(null, mapDispatchToProps)
class NoSitesContainer extends Component {
	constructor() {
		super();

		this.state = {
			sitesModalOpen: false,
			loading: false
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	handleClose = () => {
		this.setState({
			sitesModalOpen: false
		});
	}

	handleOpen = (e) => {
		e.nativeEvent.stopImmediatePropagation();

		this.setState({
			sitesModalOpen: true
		});
	}

	async onSubmit(data) {
		this.setState({
			loading: true
		});

		try {
			await this.props.createSite(data);

			SviftGA.SITES.CREATED();

			this.setState({
				loading: false,
				sitesModalOpen: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	render() {
		const { sitesModalOpen, site } = this.state;
		const { intl } = this.props;

		return (
			<Box style={{ marginTop: -48, maxWidth: 800, marginBottom: 64 }}>
				<Message
					style={{
						padding: '16px 24px 32px 24px',
						minHeight: 540
					}}
				>
					<Grid padded centered>
						<GridRow>
							<GridColumn mobile={16} tablet={16} computer={16} textAlign="center">
								<Icon
									name="sn-sphere"
									className="light-info-color"
									style={{
										fontSize: 88,
										marginRight: 0,
										marginBottom: 12,
										opacity: 0.6
									}}
								/>
								<Header as="h1" className="dark-secondary-color">
									<FormattedMessage id="messages.no site title" />
								</Header>
							</GridColumn>
							<GridColumn mobile={16} tablet={16} computer={16}>
								<div
									style={{
										marginTop: 24,
										padding: '12px 24px 32px 12px',
										textAlign: 'center',
										backgroundColor: 'rgba(255, 255, 255, 0.9)',
										borderRadius: 8,
										border: '2px #347193 solid',
										// maxWidth: 520,
										// margin: 'auto'
									}}
								>
									<Divider horizontal>
										<Icon
											circular
											name="plug"
											className="light-secondary-color"
											style={{ margin: 0, fontSize: 24 }}
										/>
									</Divider>

									<p style={{ color: '#347193', fontWeight: 400, fontSize: 20, marginBottom: 24 }}>
										<FormattedMessage id="messages.no site description" />
									</p>
									<Button
										size="huge"
										className="positive"
										onClick={this.handleOpen}
										style={{ margin: 0 }}
									>
										<span>
											<Icon name="plus" style={{ marginRight: 6 }} />
											<FormattedMessage id="messages.no site create new site" />
											<Icon name="sn-sphere" style={{ marginLeft: 8 }} />
										</span>
									</Button>
								</div>
							</GridColumn>
						</GridRow>
						<GridRow style={{ padding: 0 }}>
							<GridColumn computer={16}>
								<Divider horizontal>
									<Icon name="sn-plus2" style={{ fontSize: 20,  margin: 0, opacity: 0.4 }} />
								</Divider>
							</GridColumn>
						</GridRow>
						<GridRow>
							<GridColumn mobile={16} tablet={8} computer={8} textAlign="left">
								<Message className="primary" style={{ paddingBottom: 32 }}>
									<Header
										as="h3"
										icon="sn-envelop2"
										className="dark-primary-color"
										content={intl.formatMessage({ id: 'support.invite message header' })}
										style={{ fontSize: 22 }}
									/>
									<Divider />
									{/* <div style={{ marginBottom: 6 }}>
										<Icon name="sn-account_circle" style={{ fontSize: 32, opacity: 0.4, margin: 0 }} />
									</div> */}
									<p>
										<FormattedMessage id="support.invite message description" />
									</p>
								</Message>
							</GridColumn>
							<GridColumn mobile={16} tablet={8} computer={8} textAlign="left">
								<Message className="secondary" style={{ paddingBottom: 32 }}>
									<Header
										as="h3"
										icon="handshake outline"
										className="dark-secondary-color"
										content={intl.formatMessage({ id: 'support.expert assistance message header' })}
										style={{ fontSize: 22 }}
									/>
									<Divider />
									<p>
										<FormattedMessage id="support.expert assistance message description" />
									</p>
									<Label
										basic
										size="big"
										className="secondary"
										style={{ marginTop: 8, marginLeft: 0, textAlign: 'center', width: '100%' }}
									>
										<FormattedMessage id="general.send mail to" />:<br />
										<a
											href="mailto:contact@svift.net"
											// transl8 - add more description about contact link
											title="contact@svift.net"
											style={{ display: 'block', marginTop: 6, opacity: 1 }}
										>
											<Icon name="envelope outline" style={{ marginLeft: 8, marginRight: 6 }} />
											contact@svift.net
										</a>
									</Label>
								</Message>
							</GridColumn>
						</GridRow>
						
						{/* company footer */}
						<GridRow>
							<GridColumn computer={16}>
								<Divider horizontal style={{ margin: 0, textTransform: 'lowercase' }}>
									<Icon
										name="sn-logo"
										className="primary-color"
										style={{ fontSize: 20, marginRight: 6 }}
									/>
									{/* company title */}
									<span style={{ fontWeight: 400 }}>
										<FormattedMessage id="general.company title" /> | <span style={{ textTransform: 'capitalize', fontWeight: 400 }}><FormattedMessage id="countries.denmark" /></span>
									</span>

									<Label
										basic
										size="small"
										style={{ marginLeft: 8, padding: 8 }}
									>
										{/* copyright symbol + current year */}
										&copy; <FormattedDate value={new Date()} year="numeric" />
									</Label>
								</Divider>
							</GridColumn>
						</GridRow>
					</Grid>

					<ModalWrapper
						open={sitesModalOpen}
						handleClose={this.handleClose}
						header={intl.formatMessage({ id: 'general.new site title' })}
						style={{ maxWidth: 800 }}
					>
						<SiteForm
							loading={this.state.loading}
							onClose={this.handleClose}
							onSubmit={this.onSubmit}
						/>
					</ModalWrapper>
				</Message>
			</Box>
		);
	}
}

export default injectIntl(NoSitesContainer);
