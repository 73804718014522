import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import PropTypes from 'prop-types';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';

class FormRadio extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, formObj } = props.context;
		
		return shallowCompare({
			theme,
			setField: formObj && formObj.setField
		}, state);
	}

	constructor(props) {
		super(props);

		this.state = {
			checked: false,
			validationError: null
		};

		this.renderElement = this.renderElement.bind(this);
		this.check = this.check.bind(this);
		this.setError = this.setError.bind(this);
	}

	componentDidMount() {
		if (this.props.setField) this.props.setField(this);
	}

	setError(error) {
		this.setState({
			validationError: error
		});
	}

	validate() {
		if (!this.state.checked && this.props.node.props.required) {
			this.setError('Dette felt er påkrævet');

			return false;
		}

		return true;
	}

	check(checked) {
		this.setState({
			checked,
			validationError: null
		});
	}

	renderElement() {
		const { theme, node, computePreset } = this.props;
		
		const {
			label,
			options
		} = node.props;

		let labelStyle = computePreset({ ...theme.presets.radio[node.props.preset].label, ...node.props && node.props.style && node.props.style.label }, theme);

		const style = {
			...this.props.computedStyling
		};

		if (this.state.validationError) {
			const errorLabelStyle = computePreset(theme.presets.radio[node.props.preset].error.label, theme);

			labelStyle = {
				...labelStyle,
				...errorLabelStyle
			};
		}

		const radioOptions = options
			.replace(/,+/gi, ',')
			.split(',');

		return (
			<div style={{ width: '100%' }}>
				<p
					style={labelStyle}
				>
					{label}<br />

					{this.state.validationError && `*${this.state.validationError}`}
				</p>

				{radioOptions.map((opt, index) => {
					if (opt.length === 0 && index === 0) return null;

					const optElement = [
						<label
							htmlFor={opt}
							key={opt}
						>
							<input
								id={opt}
								name={label}
								type="radio"
								checked={this.state.checked === opt}
								style={style}
								value={opt}
								onChange={() => this.check(opt)}
							/>

							<span style={labelStyle}>
								{opt.trim()}
							</span>
						</label>,
						<br
							key="break"
						/>
					];

					return optElement;
				})}
			</div>
		);
	}
	render() {
		// const { node } = this.props;

		return this.renderElement();
	}
}

export default FormRadio;
