import React, { PureComponent } from 'react';
import { Message, Icon, Header, Divider } from 'svift-ui';
import PropTypes from 'prop-types';

class NoItems extends PureComponent {
	render() {
		const { title, content, icon, setRef, className } = this.props;

		return (
			<Message
				ref={setRef}
				className={className || 'info'}
				style={{
					padding: '48px 24px 72px 24px',
					textAlign: 'center'
				}}
			>
				
				<Icon
					name={icon}
					className={`${className}-color` || 'info-color'}
					style={{
						fontSize: 64,
						marginBottom: 16,
						marginRight: 0,
						opacity: 0.6
					}}
				/>

				<Header
					as="h2"
					className={`${className}-color` || 'info-color'}
					style={{ marginBottom: 8 }}
				>
					{title}
				</Header>
				
				{content &&
					<div
						style={{
							fontSize: 20,
							lineHeight: 1.4,
							maxWidth: 480,
							margin: 'auto'
						}}>
						<Divider />
						
						{content}
					</div>
				}
			</Message>
		);
	}
}

NoItems.propTypes = {
	padded: PropTypes.bool,
	icon: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string
};

NoItems.defaultProps = {
	icon: 'cube',
	padded: true
};

export default NoItems;
