module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Bannerbilleder',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Image',
                                props: {
                                    style: {},
                                    preset: 'Normal',
                                    crop: {
                                        autoHeight: false,
                                        height: 250,
                                        heightMode: 'height'
                                    },
                                    custom: true
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Kreditering af fotografen.',
                                                type: 'Small',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'right'
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 11,
                        props: {},
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Lad billederne tale, de tilføjer teksten betydning og hjælper budskabet frem.',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Budskabet står stærkt, når billeder og tekster supplerer hinanden. Illustrationer hjælper med at fange og fastholde læserens opmærksomhed.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
