import React, { Component } from 'react'
import { Icon, Popup, Button, Message, Input, Divider } from 'svift-ui'
import InfoIcon from 'components/InfoIcon'
import { connect } from 'react-redux'
import fileActions from 'store/actions/sites/files'
import FoldersContainer from './__components/FoldersContainer'
import { injectIntl, FormattedMessage } from 'react-intl'
import notification from 'components/hoc/notification'
import StorageIndicator from './StorageIndicator'
import SviftGA from 'utils/svift-ga'

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid
    const folders = state.sites.files.folders
    const activeFolderId = state.sites.files.activeFolder
    const activeFolder = folders.filter(
        folder => folder._id === activeFolderId
    )[0]

    return {
        siteid,
        activeFolderId,
        activeFolder,
        folders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createFolder: (name, parent, siteid) =>
            dispatch(fileActions.createFolder(name, parent, siteid))
    }
}

// @connect(mapStateToProps, null)
class FilesSidenavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            regExp: false
        }
    }

    updateFolderState = e => {
        const regex1 = RegExp(/[^A-Za-z0-9-._ ]/g)
        const regex2 = RegExp(/[^A-Za-z0-9-._ ]/g)
        const testBool = regex1.test(e.target.value)
        const errorLetter = e.target.value.match(regex2)

        this.setState({
            regExp: testBool,
            errorLetter,
            name: e.target.value
        })
    }

    createFolder = async e => {
        try {
            await this.props.createFolder(
                this.state.name.trim(),
                this.props.activeFolderId,
                this.props.siteid
            )

            SviftGA.FILES.FOLDER_CREATED()

            this.props.createNotification({
                content: `${this.props.intl.formatMessage({
                    id: 'files.folder title'
                })} "${this.state.name}" ${this.props.intl.formatMessage({
                    id: 'general.was created label'
                })}`,
                className: 'positive',
                position: 'topLeft',
                icon: 'sn-folder-plus2',
                deleteTime: 2000
            })
        } catch (e) {
            console.log(e, 'e')

            alert('error')
        } finally {
            this.setState({
                name: ''
            })
        }
    }

    render() {
        const { name } = this.state
        const disabled =
            this.state.name === '' || this.state.regExp === true ? true : false

        return (
            <div
                style={{
                    minHeight: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* New folder input */}
                <label style={{ marginBottom: 4 }}>
                    <Icon
                        name="sn-folder-plus2"
                        style={{
                            opacity: 0.3,
                            marginRight: 4,
                            verticalAlign: 'middle'
                        }}
                    />
                    <span style={{ verticalAlign: 'middle' }}>
                        <FormattedMessage id="files.new folder label" />
                    </span>
                    <InfoIcon
                        description={this.props.intl.formatMessage({
                            id: 'files.create new folder description'
                        })}
                        position="top left"
                        style={{ verticalAlign: 'middle' }}
                    />
                </label>
                <div style={{ display: 'flex', marginBottom: 6 }}>
                    <Input
                        size="mini"
                        icon="plus"
                        iconPosition="left"
                        type="text"
                        placeholder={this.props.intl.formatMessage({
                            id: 'files.new folder name placeholder'
                        })}
                        style={{ flexGrow: 1 }}
                        value={this.state.name}
                        onChange={this.updateFolderState}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                if (
                                    this.state.regExp === false &&
                                    name !== ''
                                ) {
                                    this.createFolder()
                                }
                            }
                        }}
                    ></Input>
                    <Popup
                        trigger={
                            <Button
                                icon
                                size="mini"
                                className="positive"
                                onClick={this.createFolder}
                                disabled={disabled}
                                style={{ marginLeft: 2 }}
                            >
                                <Icon name="sn-folder-plus2" />
                            </Button>
                        }
                        content="create folder"
                        position="top right"
                    />
                </div>

                {/* input validation message */}
                {this.state.regExp === true && (
                    <Message
                        className="error"
                        style={{ marginTop: -2, marginBottom: 6 }}
                    >
                        <span style={{ fontWeight: 'bold' }}>
                            {this.state.errorLetter}
                        </span>{' '}
                        -{' '}
                        <FormattedMessage id="files.message not valid folder name characters" />
                    </Message>
                )}

                {/* Folder tree */}
                <FoldersContainer />

                <StorageIndicator />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(notification(FilesSidenavigation)))
