import React from 'react';
import Label from 'components/option-library/label';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { injectIntl } from 'react-intl';

const sizeOptions = intl => [
	{
		text: `${intl.formatMessage({ id: 'options.border style solid title' })}`,
		value: 'solid'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style dashed title' })}`,
		value: 'dashed'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style dotted title' })}`,
		value: 'dotted'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style double title' })}`,
		value: 'double'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style groove title' })}`,
		value: 'groove'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style ridge title' })}`,
		value: 'ridge'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style inset title' })}`,
		value: 'inset'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style outset title' })}`,
		value: 'outset'
	}
];

export default injectIntl(props => {
    const text = props.intl.formatMessage({ id: 'options.border type title' });

    return (
		<Label
			collapsed
			labelIcon="sn-border_clear"
			label={text}
			fontSize={12}
			clear={{
				onClear: () => props.onChange(),
				enabled: typeof props.inlineStyling !== 'undefined'
			}}
		>
			<Dropdown
				wings
				items={sizeOptions(props.intl)}
				value={props.inlineStyling}
				onChange={newValue => props.onChange(newValue)}	
			/>
		</Label>
    );
});