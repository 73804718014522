import React from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';
import key from 'utils/key';
import css from '../../../../options.scss';
// import { useHistoryHelper } from '../historyHelper';

/*
borderTopLeftRadius
generalBorderRadius
*/

const BorderRadius = props => {
    const { accordionState, intl } = props;
    const [state, dispatch] = accordionState;

    // const onChange = (key, value, action, shouldSuspend) => {
    //     const nextRadius = {
    //         ...props.inlineStyling,
    //         [key]: value
    //     };

    //     if (typeof value === 'undefined') delete nextRadius[key];
    //     if (Object.keys(nextRadius).length === 0) {
    //         props.onChange(undefined, action, shouldSuspend);
    //     } else {
    //         props.onChange(nextRadius, action, shouldSuspend);
    //     }
    // };

    const onChange = (key, value, ...args) => {
        let nextRadius = {
            ...props.inlineStyling,
            [key]: value
        };

        if (key === 'generalBorderRadius') {
            nextRadius = {
                [key]: value
            };
        } else {
            delete nextRadius.generalBorderRadius;
        }

        if (typeof value === 'undefined') delete nextRadius[key];
        
        if (Object.keys(nextRadius).length === 0) {
            props.onChange(undefined, ...args);
        } else {
            props.onChange(nextRadius, ...args);
        }
    };

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                nested
                icon="sn-enlarge"
                title={intl.formatMessage({ id: 'options.border radius title' })}
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <Label
                    labelIcon="sn-enlarge2"
                    label={intl.formatMessage({ id: 'options.border radius general title' })}
                    clear={{
                        onClear: () => onChange('generalBorderRadius'),
                        enabled: typeof key('inlineStyling.generalBorderRadius')(props) !== 'undefined'
                    }}
                >
                    <NumberPicker
                        disableUndoRedo
                        name={intl.formatMessage({ id: 'options.border radius general title' })}
                        unit="px"
                        min={0}
                        max={500}
                        tooltipPosition="right center"
                        value={key('inlineStyling.generalBorderRadius')(props)}
                        onChange={val => onChange('generalBorderRadius', val)}						
                    />
                </Label>
                
                <div className={css['filter-outline']} style={{ display: 'block', borderRadius: 16 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>  
                            <Label
                                collapsed
                                label={intl.formatMessage({ id: 'options.border radius top left title' })}
                                labelIcon="sn-arrow-up-left3"
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('borderTopLeftRadius'),
                                    enabled: typeof key('inlineStyling.borderTopLeftRadius')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.border radius top left title' })}
                                    unit="px"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.borderTopLeftRadius')(props)}
                                    // onBlur={props.suspend.flush}
                                    onChange={val => onChange('borderTopLeftRadius', val, 'border radius', true)}						
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>  
                            <Label
                                collapsed
                                label={intl.formatMessage({ id: 'options.border radius top right title' })}
                                labelIcon="sn-arrow-up-right3"
                                fontSize={12}
                                textAlign="right"
                                clear={{
                                    onClear: () => onChange('borderTopRightRadius'),
                                    enabled: typeof key('inlineStyling.borderTopRightRadius')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.border radius top right title' })}
                                    unit="px"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.borderTopRightRadius')(props)}
                                    // onBlur={props.suspend.flush}
                                    onChange={val => onChange('borderTopRightRadius', val, 'border radius', true)}						
                                />
                            </Label>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>  
                            <Label
                                collapsed
                                label={intl.formatMessage({ id: 'options.border radius bottom left title' })}
                                labelIcon="sn-arrow-down-left3"
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('borderBottomLeftRadius'),
                                    enabled: typeof key('inlineStyling.borderBottomLeftRadius')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.border radius bottom left title' })}
                                    unit="px"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.borderBottomLeftRadius')(props)}
                                    // onBlur={props.suspend.flush}
                                    onChange={val => onChange('borderBottomLeftRadius', val, 'border radius', true)}						
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>  
                            <Label
                                collapsed
                                label={intl.formatMessage({ id: 'options.border radius bottom right title' })}
                                labelIcon="sn-arrow-down-right3"
                                fontSize={12}
                                textAlign="right"
                                clear={{
                                    onClear: () => onChange('borderBottomRightRadius'),
                                    enabled: typeof key('inlineStyling.borderBottomRightRadius')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.border radius bottom right title' })}
                                    unit="px"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.borderBottomRightRadius')(props)}
                                    // onBlur={props.suspend.flush}
                                    onChange={val => onChange('borderBottomRightRadius', val, 'border radius', true)}						
                                />
                            </Label>
                        </div>
                    </div>
                </div>
            </Section>
        </Accordion>
    );
};

export default injectIntl(BorderRadius);