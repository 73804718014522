import React from 'react';
import css from './loader.scss';

const Loader = props => {
    return (
        <div className={css['load-spinner']}>
            <i
                className={`${props.icon || 'icon-spinner2'} ${
                    css['load-icon']
                } ${css['rotating']}`}
                style={{
                    fontSize: props.size || 30,
                    width: props.size || 30,
                    height: props.size || 30,
                    ...props.style
                }}
            />
            
            {props.label && (
                <div
                    className={css['load-label']}
                    style={{
                        fontFamily: props.fontFamily || 'Georgia',
                        fontSize: props.size ? props.size * 0.5 : 15,
                        color: props.shadeColor || 'black'
                    }}
                >
                    <label>{props.label}</label>
                </div>
            )}
        </div>
    );
};

export default Loader;
