import React, { useState } from 'react';
import BuildingBlock from './BuildingBlock';
import AccordionContentComponent from 'components/accordion/AccordionContentComponent';
import blockDictionary from './blockDictionary';
import {
    Input,
    Message,
    Icon,
} from 'svift-ui';
import css from './building-blocks.scss';
import InfoIcon from 'components/InfoIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import Label from 'components/option-library/label';

export default ({ elements = [], sectionTemplates = [] }) => injectIntl(props => {
    const { intl } = props;
    const [elementsAccordionOpen, setElementsAccordionOpen] = useState(true);
    const [templatesAccordionOpen, setTemplatesAccordionOpen] = useState(true);

    return (
        <React.Fragment>
            {/* drag info label */}
            {/* TO DO: finish styling + transl8 */}
            {/* <Label
                label={intl.formatMessage({ id: 'general.drag content label' })}
                labelIcon="sn-move"
                labelDescription={intl.formatMessage({ id: 'general.drag content description' })}
            > */}
                {/* <Icon name="sn-move" className="primary-color" style={{ marginRight: 6 }} />
                <FormattedMessage id="general.drag content label" />
                <InfoIcon
                    description={props.intl.formatMessage({ id: 'general.drag content description' })}
                    position="right center"
                /> */}

                {/* elements accordion */}
                <AccordionContentComponent
                    id="elements-accordion-title"
                    // id="pages-elements-accordion-title" // make general instead of specific
                    handleOpen={() => setElementsAccordionOpen(!elementsAccordionOpen)}
                    active={elementsAccordionOpen}
                    icon="sn-marquee-plus"
                    title={
                        <span>
                            <FormattedMessage id="elements.title" />
                            <InfoIcon description={props.intl.formatMessage({ id: 'elements.elements description' })} />
                        </span>
                    }
                >
                    {elements.map((elementID, index) => {
                        const element = blockDictionary[elementID];

                        return (
                            <BuildingBlock
                                key={index}
                                elementID={elementID}
                                dragState={props.dragState}
                                {...element}
                            />
                        );
                    })}
                </AccordionContentComponent>

                {/* templates accordion */}
                <AccordionContentComponent
                    id="templates-accordion-title"
                    // id="pages-templates-accordion-title" // make general instead of specific
                    handleOpen={() => setTemplatesAccordionOpen(!templatesAccordionOpen)}
                    active={templatesAccordionOpen}
                    icon="sn-grid8"
                    title={
                        <span>
                            <FormattedMessage id="templates.title" />
                            <InfoIcon description={props.intl.formatMessage({ id: 'templates.templates description' })} />
                        </span>
                    }
                >

                    {/* TO DO: add search (filter) option to templates */}
                    {/* search input for templates */}
                    {/* transl8 */}
                    {/* <div className={css['sticky-search-input']}>
                        <Input
                            fluid
                            size="mini"
                            icon="sn-search3"
                            placeholder="search templates..."
                        />
                    </div> */}

                    {/* no search result message */}
                    {/* <Message size="small" className="info">
                        <Icon name="sn-search3" style={{ marginRight: 4 }} />
                        <span>No  search results...</span>
                    </Message> */}

                    {sectionTemplates.map((sectionTemplateID, index) => {
                        const sectionTemplate = blockDictionary[sectionTemplateID];

                        return (
                            <BuildingBlock
                                key={index}
                                elementID={sectionTemplateID}
                                dragState={props.dragState}
                                imageUrl={sectionTemplate.imageUrl}
                                {...sectionTemplate}
                                className="big-block"
                            />
                        );
                    })}
                </AccordionContentComponent>
            {/* </Label> */}
        </React.Fragment>
    );
});