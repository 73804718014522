import constants from './constants';

const actions = {
	setSections: ({sections = [], currentSection = null}) => ({
		type: constants.SET_SECTIONS,
		data: {
			sections,
			currentSection
		}
	}),
	selectSection: (section = null) => ({
		type: constants.SELECT_SECTION,
		data: section
	}),
	setCurrentSection: (section = null) => ({
		type: constants.SET_CURRENT_SECTION,
		data: section
	})
};

export default actions;