import React, { PureComponent } from 'react';

class ImageFader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initialSrc: props.src
        };
    }
    
    componentWillReceiveProps(nextProps) {
        if (!this.state.imageReady) {
            this.setState({
                imageReady: nextProps.imageLoadedSuccesfully
            });
        }
    }

    render() {
        const isSvg = this.props.file && this.props.file.meta.mimeType.includes('svg');

        if (!this.props.src || isSvg) return null;

        if (this.props.backgroundMode) {
            return (
                <React.Fragment>
                    {/* we have no onLoad event for css background image, so we have to load it with an img tag as well */ }
                    <img 
                        src={this.props.src}
                        alt={`Image ${this.props.file.meta.name} loading placeholder`}
                        onLoad={e => {
                            this.setState({
                                imageReady: true
                            })
                        }}
                        style={{
                            display: 'none'
                        }}	
                    /> 

                    <div
                        className={!this.state.imageReady ? 'opacity1' : 'opacity1 opacity0'}
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            ...this.props.backgroundStyle,
                            backgroundImage: `url(${this.state.initialSrc})`
                        }}
                    />
                </React.Fragment>
            );
        }

        return (
            <img 
                alt={`Image ${this.props.file.meta.name} loading placeholder`}
                src={this.state.initialSrc}
                className={!this.state.imageReady ? 'opacity1' : 'opacity1 opacity0'}
                style={{
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}	
            /> 
        );
    }
}

export default ImageFader;