import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button, ButtonGroup, Icon } from 'svift-ui';
import { injectIntl } from 'react-intl';

class PreviewButton extends PureComponent {
	render() {
		const { open, style, intl } = this.props;
		const viewType = this.props.view;
		// const previewView = this.props.previewView;

		//const versionContent = `Currently editing: ${liveView ? 'Live' : 'Draft'} version`;

		return (
			<React.Fragment>
				{open
					?
					<Popup
						trigger={
							<Button
								id="design-preview-button"
								size={this.props.size || 'mini'}
								className="secondary"
								style={{ padding: '6px 8px', fontSize: 16, marginRight: 16, ...style }}
								onClick={() => this.props.toggleView('preview')}
							>
								<Icon name="sn-eye" style={{ marginRight: 8 }} />
								<Icon name="sn-enlarge2" style={{ marginRight: 0 }} />
							</Button>
						}
						content={intl.formatMessage({ id: 'design.header switch preview popup' })}
						position="bottom center"
					/>
						
					:
						<React.Fragment>
							<ButtonGroup
								size={this.props.size || 'mini'}
								style={{ marginRight: 8, ...style }}
							>

								{/* <Popup
									trigger={
										<Button icon className={viewType === 'dashboard' ? 'active-button' : null} style={{ verticalAlign: 'middle' }} onClick={() => this.props.toggleView('dashboard')}>
											<Icon name={this.props.dashboardIcon} /> {this.props.dashboardLabel}
										</Button>
									}
									content={this.props.dashboardPopup}
									position="bottom center"
								/> */}
								
								{/* {!this.props.noDraft && <Popup
									trigger={
										<Button
											icon
											className={viewType === 'dashboard' ? 'active-button' : null}
											style={{ verticalAlign: 'middle' }}
											onClick={() => this.props.toggleView('draft')}
										>
											<Icon name={this.props.draftIcon} />  {this.props.draftLabel}
										</Button>
									}
									content={this.props.draftPopup}
									position="bottom center"
								/>} */}

							
								<Popup
									trigger={
										<Button icon className={viewType === 'preview' ? 'active-button' : null} style={{ verticalAlign: 'middle' }} onClick={() => this.props.toggleView('preview')}>
											<Icon name={this.props.previewIcon} />
											{/* {this.props.previewLabel} */}
										</Button>
									}
									content={this.props.previewPopup}
									position="bottom center"
								/>
								
								<Popup
									trigger={
										<Button icon className={viewType === 'live' ? 'active-button' : null} style={{ verticalAlign: 'middle' }} onClick={() => this.props.toggleView('live')}>
											<Icon name={this.props.liveIcon} /> {this.props.liveLabel}
										</Button>
									}
									content={this.props.livePopup}
									position="bottom center"
								/>
							</ButtonGroup>
							
							<Popup
								trigger={
									<Button
										size={this.props.size || 'mini'}
										className="secondary"
										style={{ padding: '8px', marginRight: 0 }}
										onClick={() => this.props.toggleView('dashboard')}
									>
										<Icon name="sn-eye" style={{ marginRight: 8 }} />
										<Icon name="sn-cross2" style={{ marginRight: 0 }} />
									</Button>
								}
								content={intl.formatMessage({ id: 'options.design settings title' })}
								position="bottom center"
							/>
						</React.Fragment>
				}
			</React.Fragment>
		);
	}
}

PreviewButton.propTypes = {
	draftIcon: PropTypes.string,
	draftLabel: PropTypes.string,
	draftPopup: PropTypes.string,
	previewIcon: PropTypes.string,
	previewLabel: PropTypes.string,
	previewPopup: PropTypes.string,
	liveIcon: PropTypes.string,
	liveLabel: PropTypes.string,
	livePopup: PropTypes.string,
};

PreviewButton.defaultProps = {
	draftIcon: 'sn-quill3',
	// draftLabel: 'Draft',
	draftPopup: 'Draft version',
	previewIcon: 'sn-eye',
	// previewLabel: 'Preview',
	previewPopup: 'Preview of draft version',
	liveIcon: 'sn-earth2',
	// liveLabel: 'Live',
	livePopup: 'Live version',
};

export default injectIntl(PreviewButton);
