import React from 'react';
import GoogleGsuiteVerificationCodeAnswer from './answerComponents/GoogleGsuiteVerificationCodeAnswer';
import Text from './answerComponents/Text';
import ImageRight from './answerComponents/ImageRight';
// import VideoCenter from './answerComponents/VideoCenter';

const faqData = () => [
	{
		"id": "contentSection",
		"icon": "sn-stack-text",
		"title": "pages.app title",
		"panels": [
			{
				"id": "how-is-content-structured",
				"icon": "sn-grid-2",
				"question": "support.faq question how is content structured",
				"meta": "support.faq answer how is content structured",
				"component": <Text text="support.faq answer how is content structured" />
			},
			{
				"id": "what-is-an-element",
				"icon": "sn-marquee-plus",
				"question": "support.faq question content what is an element",
				"meta": "support.faq answer content what is an element",
				"component": <Text text="support.faq answer content what is an element" />
			},
			{
				"id": "how-to-edit-an-element",
				"icon": "sn-marquee-plus",
				"question": "support.faq question how to edit an element",
				"meta": "support.faq answer how to edit an element",
				"component": <Text text="support.faq answer how to edit an element" />
			},
			{
				"id": "how-to-move-an-element",
				"icon": "sn-align-center-horizontal",
				"question": "support.faq question how to align elements",
				"meta": "support.faq answer how to align elements",
				"component": <ImageRight text="support.faq answer how to align elements" imageSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/support/faq/editor-alignments.png" />
			},
			{
				"id": "what-is-a-grid",
				"icon": "sn-columns",
				"question": "support.faq question content what is a grid",
				"meta": "support.faq answer content what is a grid",
				"component": <Text text="support.faq answer content what is a grid" />
			},
			{
				"id": "how-to-insert-an-image",
				"icon": "sn-image",
				"question": "support.faq question how to insert an image",
				"meta": "support.faq answer how to insert an image",
				"component": <Text text="support.faq answer how to insert an image" />
			},
			{
				"id": "how-to-add-a-button",
				"icon": "hand pointer",
				"question": "support.faq question how to insert a button",
				"meta": "support.faq answer how to insert a button",
				"component": <Text text="support.faq answer how to insert a button" />
			},
			{
				"id": "how-to-add-background-image",
				"icon": "sn-image",
				"question": "support.faq question how to add background image",
				"meta": "support.faq answer how to add background image",
				"component": <Text text="support.faq answer how to add background image" />
			},
			{
				"id": "how-to-insert-a-video",
				"icon": "video",
				"question": "support.faq question how to insert a video",
				"meta": "support.faq answer how to insert a video",
				"component": <Text text="support.faq answer how to insert a video" />
			},
			{
				"id": "how-to-insert-a-map",
				"icon": "sn-location",
				"question": "support.faq question how to insert a map",
				"meta": "support.faq answer how to insert a map",
				"component": <Text text="support.faq answer how to insert a map" />
			},
			{
				"id": "how-to-change-background-color",
				"icon": "sn-droplet",
				"question": "support.faq question how to change background color",
				"meta": "support.faq answer how to change background color",
				"component": <ImageRight text="support.faq answer how to change background color" imageSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/support/faq/editor-edit-background-color.png" />
			},
			{
				"id": "how-do-page-templates-work",
				"icon": "sn-file-text",
				"question": "support.faq question content how do page templates work",
				"meta": "support.faq answer content how do page templates work",
				"component": <Text text="support.faq answer content how do page templates work" />
			},
			{
				"id": "how-to-insert-iframe",
				"icon": "sn-markup",
				"question": "support.faq question how to insert iframe",
				"meta": "support.faq answer how to insert iframe",
				"component": <Text text="support.faq answer how to insert iframe" />
			}
		]
	},
	{
		"id": "designSection",
		"icon": "sn-bucket",
		"title": "design.app title",
		"panels": [
			{
				"id": "what-is-a-theme",
				"icon": "sn-color-sampler",
				"question": "support.faq question what is a theme",
				"meta": "support.faq answer what is a theme",
				"component": <ImageRight text="support.faq answer what is a theme" imageSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/support/faq/design-themes-dropdown.jpg" />
			},
			{
				"id": "am-i-stuck-with-a-theme",
				"icon": "sn-color-sampler",
				"question": "support.faq question design am i stuck with a theme",
				"meta": "support.faq answer design am i stuck with a theme",
				"component": <Text text="support.faq answer design am i stuck with a theme" />
			},
			{
				"id": "what-is-design-variables",
				"icon": "sn-palette",
				"question": "support.faq question design what are design variables",
				"meta": "support.faq answer design what are design variables",
				"component": <Text text="support.faq answer design what are design variables" />
			},
			{
				"id": "how-to-change-colors",
				"icon": "sn-droplet",
				"question": "support.faq question how to change colors",
				"meta": "support.faq answer how to change colors",
				"component": <Text text="support.faq answer how to change colors" />
			},
			{
				"id": "how-do-layouts-work",
				"icon": "sn-web",
				"question": "support.faq question design how do layouts work",
				"meta": "support.faq answer design how do layouts work",
				"component": <Text text="support.faq answer design how do layouts work" />
			},
			{
				"id": "how-to-edit-layouts",
				"icon": "sn-web",
				"question": "support.faq question how to edit layouts",
				"meta": "support.faq answer how to edit layouts",
				"component": <Text text="support.faq answer how to edit layouts" />
			},
			{
				"id": "how-to-insert-logo",
				"icon": "sn-star-full",
				"question": "support.faq question how to insert logo",
				"meta": "support.faq answer how to insert logo",
				"component": <ImageRight text="support.faq answer how to insert logo" imageSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/support/faq/design-branding-options.jpg" />
			},
			{
				"id": "how-to-change-navigation",
				"icon": "sn-direction",
				"question": "support.faq question how to change navigation",
				"meta": "support.faq answer how to change navigation",
				"component": <Text text="support.faq answer how to change navigation" />
			},
			{
				"id": "how-to-change-forms",
				"icon": "send",
				"question": "support.faq question how to change forms",
				"meta": "support.faq answer how to change forms",
				"component": <Text text="support.faq answer how to change forms" />
			},
			{
				"id": "what-is-body",
				"icon": "sn-web_asset",
				"question": "support.faq question what is body",
				"meta": "support.faq answer what is body",
				"component": <Text text="support.faq answer what is body" />
			},
			{
				"id": "how-to-edit-body",
				"icon": "sn-web_asset",
				"question": "support.faq question how to edit body",
				"meta": "support.faq answer how to edit body",
				"component": <Text text="support.faq answer how to edit body" />
			}
		]
	},
	{
		"id": "blogSection",
		"icon": "sn-blog",
		"title": "blog.app title",
		"panels": [
			{
				"id": "how-to-add-a-blog",
				"icon": "sn-blog",
				"question": "support.faq question how to add a blog",
				"meta": "support.faq answer how to add a blog",
				"component": <Text text="support.faq answer how to add a blog" />
			},
			{
				"id": "where-do-blog-posts-appear",
				"icon": "sn-podcast2",
				"question": "support.faq question where do blog posts appear",
				"meta": "support.faq answer where do blog posts appear",
				"component": <Text text="support.faq answer where do blog posts appear" />
			}
		]
	},
	{
		"id": "filesSection",
		"icon": "sn-folder6",
		"title": "files.app title",
		"panels": [
			{
				"id": "where-is-my-files-stored",
				"icon": "sn-cloud-upload",
				"question": "support.faq question files where is my files stored",
				"meta": "support.faq answer files where is my files stored",
				"component": <Text text="support.faq answer files where is my files stored" />
			},
			{
				"id": "what-about-backup",
				"icon": "sn-cloud-check2",
				"question": "support.faq question what about backup",
				"meta": "support.faq answer what about backup",
				"component": <Text text="support.faq answer what about backup" />
			}
		]
	},
	{
		"id": "settingsSection",
		"icon": "sn-cog3",
		"title": "settings.app title",
		"panels": [
			{
				"id": "how-to-invite-users",
				"icon": "users",
				"question": "support.faq question how to invite users",
				"meta": "support.faq answer how to invite users",
				"component": <Text text="support.faq answer how to invite users" />
			},
			{
				"id": "google-analytics-integration",
				"icon": "sn-stats-bars2",
				"question": "support.faq question settings google analytics",
				"meta": "support.faq answer settings google analytics",
				"component": <Text text="support.faq answer settings google analytics" />
			},
			{
				"id": "google-gsuite-verification",
				"icon": "google",
				"question": "support.faq question settings google gsuite where to find verification",
				"meta": "support.faq meta settings google gsuite where to find verification",
				"component": <GoogleGsuiteVerificationCodeAnswer />
			},
			{
				"id": "duplicate-site",
				"icon": "copy",
				"question": "support.faq question how to duplicate site",
				"meta": "support.faq answer how to duplicate site",
				"component": <Text text="support.faq answer how to duplicate site" />
			},
			{
				"id": "how-to-activate-site",
				"icon": "sn-sphere",
				"question": "support.faq question how to activate site",
				"meta": "support.faq answer how to activate site",
				"component": <Text text="support.faq answer how to activate site" />
			}
		]
	},
	{
		"id": "generalSection",
		"icon": "sn-question4",
		"title": "general.title",
		"panels": [
			{
				"id": "transfer-existing-domain",
				"icon": "plug",
				"question": "support.faq question general transfer existing domain",
				"meta": "support.faq answer general transfer existing domain",
				"component": <Text text="support.faq answer general transfer existing domain" />
			},
			{
				"id": "seo-ranking",
				"icon": "search",
				"question": "support.faq question general search optimization",
				"meta": "support.faq answer general search optimization",
				"component": <Text text="support.faq answer general search optimization" />
			},
			{
				"id": "who-receives-form-notifications",
				"icon": "send",
				"question": "support.faq question general who receives form notifications",
				"meta": "support.faq answer general who receives form notifications",
				"component": <Text text="support.faq answer general who receives form notifications" />
			}
		]
	}
]

export default faqData;