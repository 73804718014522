import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Menu, MenuItem, Icon, Popup } from 'svift-ui';
import AppContainer from 'components/app-layout/app-container/AppContainer';
import AppContent from 'components/app-layout/app-content/AppContent';
import Registration from 'containers/authorize/registration/Registration';
import Login from 'containers/authorize/Login';
import { setRoute } from 'utils/routing';
import { Route, Switch, matchPath } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

const mapStateToProps = state => ({
    user: state.user,
    pathname: state.router.location.pathname
});

class AuthorizationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeRoute: matchPath(props.pathname, '/').isExact
                ? 'login'
                : 'register'
        };

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount() {
        if (Object.keys(this.props.user).length) {
            setRoute('/dashboard');
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            activeRoute: matchPath(nextProps.pathname, '/').isExact
                ? 'login'
                : 'register'
        });
    }

    navigator() {
        const { activeItem } = this.state;

        if (activeItem === 'login') {
            return <Login header="Login" />;
        } else if (activeItem === 'register') {
            return <Registration header="user registration" />;
        }
    }

    handleItemClick(e, { name }) {
        if (name === 'login' && this.state.activeRoute !== 'login') {
            setRoute('/');
        } else if (
            name === 'register' &&
            this.state.activeRoute !== 'register'
        ) {
            setRoute('/register');
        }
    }

    render() {
        const { intl } = this.props;

        return (
            <AppContainer>
                <Menu id="top-navigation">
                    <MenuItem link>
                        <Popup
                            trigger={
                                <Icon
                                    name="sn-logo"
                                    className="tint-color"
                                    style={{ fontSize: 28 }}
                                    href="http://svift.net"
                                />
                            }
                            content={intl.formatMessage({
                                id: 'general.back to svift-net'
                            })}
                            position="bottom left"
                        />
                    </MenuItem>
                </Menu>

                <AppContent>
                    <Box
                        style={{
                            maxWidth: 440,
                            padding: 0,
                            margin: '-32px auto 0 auto'
                        }}
                    >
                        <Icon circular name="lock" className="box-icon" />

                        <Menu tabular style={{ marginTop: 52 }}>
                            <MenuItem
                                name="login"
                                active={this.state.activeRoute === 'login'}
                                onClick={this.handleItemClick}
                            >
                                <Icon
                                    name="sn-key"
                                    style={{ marginRight: 6 }}
                                />
                                <FormattedMessage id="login.login" />
                            </MenuItem>
                            <MenuItem
                                name="register"
                                active={this.state.activeRoute === 'register'}
                                onClick={this.handleItemClick}
                            >
                                <Icon
                                    name="sn-user3"
                                    style={{ marginRight: 6 }}
                                />
                                <FormattedMessage id="registration.registration title" />
                            </MenuItem>
                        </Menu>

                        {/* login form */}
                        <div style={{ padding: '0 32px 32px 32px' }}>
                            <Switch>
                                <Route
                                    path="/register"
                                    component={Registration}
                                />
                                <Route path="/" component={Login} />
                            </Switch>
                        </div>
                    </Box>
                </AppContent>
            </AppContainer>
        );
    }
}

export default connect(
    mapStateToProps,
    null
)(injectIntl(AuthorizationContainer));
