import React from 'react';
import { Header, Icon, Grid, GridRow, GridColumn } from 'svift-ui';

const SidenavigationHeader = props => (
    <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <Icon name={props.icon} size="big" id="sidenav-icon" />
        <Header as="h3" id="sidenav-title">{props.header}</Header>
        {props.children}
        {/* <p className="sidenav-subheader">{props.subheader}</p> */}
    </div>
);

export default SidenavigationHeader;
