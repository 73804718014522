import React, { Component } from 'react';
import { Icon, Grid, GridRow, GridColumn, Label, Image, Input, Button, Popup, Loader } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import Alert from 'components/Alert';
import { sizeToReadable, getIconByMimeType, getFileExtByMimeType } from 'utils/files/format';

@injectIntl
class FileSettings extends Component {
    constructor(props){
        super(props);

        const initialState = {
            loadingImage: true,
            showAlert: false
        };

        // if (props.file) {
        //     initialState.update = {
        //         size: 
        //     };
        // }

        this.state = {
            loadingImage: true, 
            showAlert: false
		}
    }

    fileRender = (loadingImage, src, mimeType) => {        
		if (mimeType.startsWith('image')){
			if (loadingImage){
				return <Loader style={{ margin: '32px auto 32px auto' }} /> 
			} else {
				return <Image src={src} style={{ marginBottom: 16, marginLeft: 'auto', marginRight: 'auto', maxHeight: 160 }}/>
			}
		} else {
            const iconName = getIconByMimeType(mimeType);
            
			return <Icon name={iconName} style={{ width: '100%', fontSize: 80, opacity: 0.3, marginBottom: 16 }} />
		}
		
    }
    
    overrideFile = (e, ext) =>{
        const fileName = e.target.files[0].name;
        const fileExt = fileName.split('.').pop().toLowerCase(); 
        const alertType = ext.toLowerCase() === fileExt ? 'accept' : 'reject';

        this.setState({ alertType, showAlert: true })
    }

    render() {
        const { url, name, size, mimeType, description, credit, fileName, onChange, onDelete } = this.props;
        const ext = getFileExtByMimeType(mimeType);
        const icon = getIconByMimeType(mimeType);
        const readableSize = sizeToReadable(size);
        const alertTitle = this.state.alertType === 'accept' ? 'Overwrite file' : 'Wrong extension';
        const alertText = this.state.alertType === 'accept'
            ? 
                'when you overwrite the file it cannot be recovered' // LATER - TRANSL8
            : 
                'extension of the file that you will upload is different from previous file'; // LATER - TRANSL8

        return (
            <GridRow>
                <GridColumn computer={6} tablet={8} mobile={16} textAlign="center" style={{ paddingTop: 16 }}>
                    {this.state.loadingImage &&
                        <Image
                            style={{ display: 'none', marginBottom: 16, marginLeft: 'auto', marginRight: 'auto', maxHeight: 160 }}
                            src={url}
                            onLoad={() => this.setState({loadingImage: false}) }
                        />
                    }

                    { this.fileRender(this.state.loadingImage, url, mimeType) }

                    <Label basic className="shade">
                        <Icon name="sn-database4" /> 
                        {readableSize}
                    </Label>

                    <Label basic className="info">{ext}</Label>
                </GridColumn>

                <GridColumn computer={10} tablet={9} mobile={16} textAlign="left" style={{ height: '100%', padding: '16px 32px 24px 32px' }}>
                    {fileName &&
                        <div style={{ marginBottom: 8, opacity: 0.6, fontSize: 14, textAlign: 'left', overflowX: 'hidden' }}>
                            <Icon name="sn-upload2" />
                            {fileName}
                        </div>
                    }

                    <div style={{ textAlign: 'left', marginBottom: 4 }}>
                        <label>
                            <FormattedMessage id="files.file name" /> 
                            <InfoIcon description={this.props.intl.formatMessage({ id: 'files.file name description' })} style={{ marginLeft: 0 }} />
                        </label>

                        <Input
                            name="name"
                            value={name}
                            onChange={onChange}
                            fluid
                            size="large"
                            placeholder={this.props.intl.formatMessage({ id: 'files.file name placeholder' })}
                            icon={icon}
                            iconPosition="left"
                        />
                    </div>
                    
                    {mimeType.startsWith('image') &&
                        <div style={{ marginTop: 8, paddingTop: 8, paddingLeft: 8, paddingRight: 8, paddingBottom: 12, backgroundColor: '#EEE', borderRadius: 4, borderTop: '1px dashed #999' }}>
                            <label style={{ fontSize: 12, marginBottom: 4 }}>
                                <FormattedMessage id="files.file image description label" />
                            </label>
                            <Input
                                name="description"
                                value={description || ''}
                                onChange={onChange}
                                fluid
                                size="mini"
                                icon="sn-bubble-quote"
                                placeholder={this.props.intl.formatMessage({ id: 'files.file image description placeholder' })}
                                iconPosition="left"
                                style={{ marginBottom: 4 }}
                            />

                            <label style={{ fontSize: 12, marginBottom: 4 }}>
                                <FormattedMessage id="files.credit label" />                             
                            </label>
                            <Input
                                name="credit"
                                value={credit || ''}
                                onChange={onChange}
                                fluid
                                size="mini"
                                icon="sn-bubble-star"
                                placeholder={this.props.intl.formatMessage({ id: 'files.credit placeholder' })}
                                iconPosition="left"
                                style={{ marginBottom: 4 }}
                            />
                        </div>
                    }

                    {/* transl8 */}
                    {/* TO DO: implement overwrite */}
                    {/* there is the start point of overwrite file, it will only show if the file needed to be overwrite */}
                    {/* {this.props.update && 
                        <span> */}
                            {/* <input
                                hidden="true"
                                ref={ref => this.filesInput = ref}
                                name="uploadFile"
                                id="overwriteFile"
                                type="file"
                                accept={`.${ext}`}
                                onChange={e => this.overrideFile(e, ext)}
                            /> */}
                            {/* transl8 */}
                            {/* <Popup
                                trigger={
                                    <label
                                        htmlFor="overwriteFile"        
                                        className="ui small button basic warning"
                                        style={{ marginTop: 8 }}
                                    >
                                        <Icon name="sn-cloud-upload"/>
                                        Replace file
                                    </label>
                                }
                                content="Overwrite this file with another of the same type."
                                position="bottom left"
                            /> */}
                        {/* </span>
                    } */}

                    {/* TRANSL8 */}
                    {/* <Alert
                        show={this.state.showAlert}
                        onCancel={() => this.setState({ showAlert: false })}
                        showCancelButton
                        title={alertTitle}
                        text={alertText}
                        // LATER TRANSL8
                        confirmTitle="overwrite the file"
                        confirmIcon="check"
                        confirmClass="negative"
                        confirmStyle={ this.state.alertType === 'reject' && { display: 'none' }}
                        cancelStyle={this.state.alertType === 'reject' && { marginLeft: 'auto', marginRight: 'auto' }}
                        cancelTitle={this.props.intl.formatMessage({ id: 'general.cancel' })}
                        onConfirm={() => {
                            this.setState({
                                showAlert: false
                            });
                        }}
                    /> */}

                    <Popup
                        trigger={
                            <Button
                                basic
                                icon="sn-bin"
                                size="small"
                                className="negative"
                                onClick={onDelete}
                                style={{ marginTop: 8, marginRight: 0, float: 'right' }}
                            />
                        }
                        content={this.props.intl.formatMessage({ id: 'files.delete file' })}
                        position="bottom right"
                    />  
                </GridColumn>
            </GridRow>             
        );
    }
}

export default FileSettings;