import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'svift-ui';
import AppContainer from 'components/app-layout/app-container/AppContainer';
import AppContent from 'components/app-layout/app-content/AppContent';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import AppFooter from 'components/app-layout/app-footer/AppFooter';
import actions from 'store/actions/sites';
import { setRoute, replaceRoute } from 'utils/routing';
import HTML5Backend from 'react-dnd-html5-backend';
// import { DragDropContext } from 'react-dnd';
import { DndProvider } from 'react-dnd';
// import HTML5Backend from 'react-dnd-touch-backend';
import { withRouter } from 'react-router';
import DashboardIndex from 'containers/dashboard/dashboard-index';
import UserNavigation from 'containers/dashboard/user-navigation';
import SideNavigation from 'containers/dashboard/side-navigation';
import NoSitesContainer from 'containers/dashboard/dashboard-index/NoSitesContainer';
import TopNavigation from 'containers/dashboard/top-navigation/TopNavigation';
import { FormattedMessage, injectIntl } from 'react-intl';
import { matchPath } from 'react-router';
import Site from './Site';

// import ReactGA from 'react-ga';

const siteExists = (sitesList, targetID) => sitesList.some(site => site._id === targetID);

const mapStateToProps = (state) => {
	return {
		siteList: state.sites.siteList,
		siteid: state.sites.params.siteid,
		roles: state.user.roles,
		// siteFound: state.sites.sites[state.sites.params.siteid],
		pathname: state.router.location.pathname
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchSites: () => dispatch(actions.fetchSites()),
		fetchSite: siteid => dispatch(actions.fetchSite(siteid))
	};
};

// @DragDropContext(HTML5Backend)
@connect(mapStateToProps, mapDispatchToProps)
class DashboardContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			fetchingSites: true
		};

		this.adjustRoute = this.adjustRoute.bind(this);
		this.getRoleOnSite = this.getRoleOnSite.bind(this);
	}

	getRoleOnSite() {
		const { roles, siteid } = this.props;

		const siteScope = `sites/${siteid}`;

		let role = roles.find(({ scope }) => {
			return scope === siteScope;
		});

		if (!role) {
			role = roles.find(({ type }) => type === 'superadmin');
		}

		return role.type;
	}

	adjustRoute() {
		const {
			siteid,
			roles,
			pathname,
			siteList
		} = this.props;

		const noSites = siteList.length === 0;

		if (noSites) {
			setRoute('/dashboard');
		} else if (!siteExists(siteList, siteid)) {
			const firstSite = siteList[0];

			setRoute(`/dashboard/${firstSite._id}`);
		} else {
			const selectedSection = pathname.split('/').slice(3)[0];

			const role = this.getRoleOnSite();

			const hasPermission = (() => {
				switch (selectedSection) {
					case 'design':
						return ['siteOwner', 'siteAdmin', 'siteDesigner'].includes(role);
					//case 'files':
					// case 'settings':
					// 	return ['siteOwner', 'siteAdmin', 'siteDesigner'].includes(role);

					default:
						return true;
				}
			})();

			if (!hasPermission) replaceRoute(`/dashboard/${siteid}`);
		}

		this.setState({
			fetchingSites: false
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.siteList !== prevProps.siteList) {
			this.adjustRoute();
		}
	}

	componentDidMount() {
		this.props.fetchSites();
	}

	renderChildren() {
		const { children, pathname } = this.props;

		if (matchPath(pathname, '/dashboard/:siteid').isExact) {
			return <DashboardIndex />;
		}

		if (children) {
			return children;
		}

		return null;
	}

	render() {
		// System version number - displayed in app footer
		const version = '0.8.3';
		const { pathname } = this.props;

		// if (window.location.pathname === '/') return null;

		if (this.state.fetchingSites || (this.props.siteList.length > 0 && !siteExists(this.props.siteList, this.props.siteid))) {
			return (
				<AppContentSection>
					<Loader size="big" style={{ fontSize: 20, marginTop: 80, opacity: 0.7 }}>
						<p style={{ fontSize: 32, marginTop: 0 }}><FormattedMessage id="general.loading" /></p>
					</Loader>
				</AppContentSection>
			);
		}

		if (this.props.siteList.length === 0 || this.props.pathname === '/dashboard') {
			return (
				<AppContainer>
					<TopNavigation />

					<SideNavigation />

					<AppContent>
						<NoSitesContainer />

						<AppFooter versionNumber={version} />
					</AppContent>

					<UserNavigation />
				</AppContainer>
			);
		}

		return (
			<DndProvider backend={HTML5Backend}>
				<AppContainer>
					<Site
						key={this.props.siteid} // Setting key to equal the siteid to force a remount, much easier to do it this way, because we know componentDidMount will always fire when the site changes - <Site> only needs to fetch inside componentDidMount, and it doesn't need to handle broken intermediate state in subroutes when switching between sites
						siteID={this.props.siteid}
					>
						<TopNavigation />

						<SideNavigation />

						<AppContent>
							{ this.renderChildren() }
							<AppFooter versionNumber={version} />
						</AppContent>

						<UserNavigation />
					</Site>
				</AppContainer>
			</DndProvider>
		);
	}
}

export default injectIntl(withRouter(DashboardContainer));
