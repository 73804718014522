module.exports = createNode =>
    createNode({
        id: '8ab7d0da-7f3e-432f-b3e6-e14739047a46',
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                id: '28ee5dbd-7fd7-4797-ab6e-220dc9fc45bf',
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        id: 'efe9aca2-38f3-47c6-8480-5246089d18ac',
                        component: 'Column',
                        width: 4,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                id: '09bfd6a7-276a-46e1-9ee1-b1f2e798259b',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Stor tekst',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                id: '28e85da6-3221-4653-8218-6be068d55fd5',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Valg af farver og skrifttyper har stor betydning for, hvordan teksten på hjemmesiden opleves. Sørg altid for at baggrunden fremhæver ordene. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        id: '953839b7-b0ad-49c2-b565-4999451a1d0c',
                        component: 'Column',
                        width: 4,
                        props: {},
                        children: [
                            createNode({
                                component: 'Text',
                                id: 'a2be8377-47ae-4d36-9378-1610ec4f82ad',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Stor tekst',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                id: '4bfa8c2a-c578-4238-aa5a-f7650a8c24c2',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Brug de rigtige ord og bliv fundet på nettet. Flet søgeord ind i teksten og hjælp dem, der leder efter dit produkt, med at finde din hjemmeside.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        id: '86025ba5-faa0-44a1-9bbe-0895a29bcdc9',
                        component: 'Column',
                        width: 4,
                        props: {},
                        children: [
                            createNode({
                                component: 'Text',
                                id: '1ecb2dac-cf80-4729-a28d-a05ece8f2dca',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Stor tekst',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                id: '28851d09-9e0d-4462-ba99-86b9435d1da9',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Når du skriver tekst til en hjemmeside, kan det være en god idé at gå lige til sagen. Begynd med det vigtigste og hold fokus på kernebudskabet.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        id: '8d2221a7-f206-41e6-903b-befc907f43c6',
                        component: 'Column',
                        width: 4,
                        props: {},
                        children: [
                            createNode({
                                component: 'Text',
                                id: 'b2cd97f5-838c-4b76-9b7b-db0b0f9c2883',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Stor tekst',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                id: '406fe660-3c68-4216-85f3-c6ffbdc1beaf',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Vi skimmer ofte en hjemmeside for hurtigt at afgøre om den er relevant for vores søgning. Teksten må derfor gerne være konkret og let at afkode. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
