module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Big'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    horizontalAlign: 'center'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Overskrift 2',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'center',
                                    style: {
                                        margin: {
                                            marginBottom: 0.01
                                        }
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Big',
                    horizontalAlign: 'center'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 7,
                        props: {
                            preset: 'Big',
                            style: {
                                padding: {
                                    paddingTop: 0.1
                                }
                            }
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Underrubrik.',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'center'
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Teksten må forsøge at fastholde læserens interesse, vække nysgerrighed, give lyst til at klikke videre og søge flere informationer på hjemmesiden. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Button',
                                props: {
                                    style: {},
                                    size: 'huge',
                                    preset: 'Primary',
                                    text: 'Link til noget andet',
                                    alignment: 'center'
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
