import React from 'react';
import Tooltip from 'svift-library/client/tooltip';
import iconList from './iconList';
import Label from 'components/option-library/label';
import { Message, Input, Divider, Button } from 'svift-ui';
import css from './icon-picker.scss';
import { injectIntl, FormattedMessage } from 'react-intl';

const SelectedIcon =(props) => {
    return (
        <div
            className={css['selected-icon']}
            // ref={ref}
            onClick={props.onClick}
        >
            <i className={props.icon} />
        </div>
    )
};

const IconPreview = React.memo((props, ref) => {
    return (
        <div
            onClick={() => props.onClick(props.icon)} 
            className={`${css['icon-preview']} ${props.selected ? css['selected'] : ''}`}
        >
            <i className={props.icon} />

            <span className={css['icon-text']}> 
                {props.icon.slice(6)} 
            </span>
        </div>
    )
});

const IconList = props => {
    const icons = props.searchText ? iconList.filter(icon => icon.includes(props.searchText)) : iconList;

    return (
        <div className={css['icon-list']}>
            {icons.map(icon => {
                return (
                    <IconPreview 
                        key={icon}
                        icon={icon} 
                        selected={props.value === icon}
                        onClick={props.onChange}
                    />
                );
            })}
        </div>
    );
};

const Search = injectIntl(props => {
    return (
        <Input
            fluid
            size="small"
            className={css['search']}
            type="text"
            icon="search"
            iconPosition="left"
            placeholder={props.intl.formatMessage({ id: 'options.icon search placeholder' })}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            style={{ marginBottom: 8 }}
        />
    );
})

const IconPicker = props => {
    const [searchText, setSearchText] = React.useState('');
    
    return (
        <React.Fragment>
            <Search 
                value={searchText} 
                onChange={setSearchText} 
            />

            <IconList {...props} searchText={searchText} />

            <Divider style={{ marginTop: 8, marginBottom: 4 }} />
            <a target="_blank" href="https://fontawesome.com/v4.7.0/icons">
                <FormattedMessage id="options.font awesome link" /> (Font Awesome 4.7.0)
            </a>
        </React.Fragment>
    )
};

const IconPickerTooltip = injectIntl(props => {
    const tooltipRef = React.useRef();
    const [iconPickerOpen, setIconPickerOpen] = React.useState(false);

    return (
        <React.Fragment>
            <Label
                collapsed
                labelIcon="sn-atom2"
                label={props.intl.formatMessage({ id: 'options.icon label' })}
                labelDescription={props.intl.formatMessage({ id: 'options.icon description' })}
            >
                <Message
                    size="small"
                    className={props.value ? 'info' : 'shade'}
                    style={{ padding: 16, textAlign: 'center' }}
                >
                    {props.value &&
                        <React.Fragment>
                            <SelectedIcon 
                                // ref={tooltipRef}
                                icon={props.value}
                                onClick={() => setIconPickerOpen(!iconPickerOpen)}
                            />

                            <div style={{ marginBottom: 8 }}>
                                {props.value.slice(6)} 
                            </div>
                        </React.Fragment>
                    }       
                    
                    <Button
                        fluid
                        className="warning"
                        ref={tooltipRef}
                        size="mini"
                        icon="sn-atom2"
                        content={props.value 
                            ? 
                                props.intl.formatMessage({ id: 'options.select another icon button' })
                            :
                                props.intl.formatMessage({ id: 'options.select icon button' })
                        }
                        onClick={() => setIconPickerOpen(!iconPickerOpen)}
                    />
                </Message>
            </Label>

            <Tooltip
                popup
                zIndex={100000}
                open={iconPickerOpen}
                target={tooltipRef.current}
                position="right center"
                nodeAttributes={{ 
                    'data-ignore-click': true
                }}
            >
                <IconPicker
                    {...props} 
                />
            </Tooltip>
        </React.Fragment>
    );
});

export { IconPicker };
export default IconPickerTooltip;