import React from 'react';
import Line from './Line';
import Preview from './Preview';
import Icon from './Icon';
import Label from './Label';

export default props => {
    return (
        <React.Fragment>
            <Preview presetTitle={props.presetTitle} theme={props.theme} />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                    <Line {...props} />
                </div>
                <div style={{ flexGrow: 1, marginLeft: 16, marginRight: 16 }}>
                    <Icon {...props} />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <Label {...props} />
                </div>
            </div>
        </React.Fragment>
    );
}

/*
    wtf design :'(

    PREVIEW
    LINE
        
    LABEL
    ICON

    node.props = {
        preset: 'Primary',
        labelAlignment: 1 | 2 | 3,
        line: {
            style:
        },
        label: {
            style:
        },
        icon: {
            style:
        }
    }
*/

/*
    "icon": {
        "margin": {
            "marginLeft": 0.5,
            "marginRight": 0.5
        },
        "color": {
            "key": "shade"
        },
        "fontSize": 1
    },
    "line": {
        "margin": {
            "marginBottom": 1
        },
        "color": {
            "key": "shade"
        },
        "lineWidth": 1,
        "lineType": "solid"
    },
    "label": {
        "margin": {
            "marginLeft": 0.5,
            "marginRight": 0.5
        },
        "color": {
            "key": "shade"
        },
        "font": "primary",
        "fontSize": 1,
        "lineHeight": 1,
        "letterSpacing": 0
    }
*/