import React from 'react';
import DatePicker from 'components/date-picker';
// import { FormField, FormInput, Message, Icon } from 'svift-ui';
// import InfoIcon from 'components/InfoIcon';

const FieldDatePicker = props => {
	// const { icon, type, placeholder, name } = options;
	const {
        input,
        meta: { error },
        style
    } = props; // Provided by Field component from redux-form;

    // console.log(input.value, 'time value')

	return (
        <DatePicker
            selected={input.value ? new Date(input.value) : undefined} ///*input.value*/ undefined}
            onChange={e => console.log(e) || input.onChange(e)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            showYearDropdown
            dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption="time"
            style={style}
        />
	);
};

export default FieldDatePicker;