import React, { Component } from 'react';
import { Message } from 'svift-ui';
import { connect } from 'react-redux';
// import { Alert } from 'svift-ui';
import { replaceRoute } from 'utils/routing';
import contentActions from 'store/actions/sites/content';

// import PagesHeader from './header/PagesHeader';
import PageEditor from './PageEditor';

// Internet Explorer 6-11 detection for legacy browser warning
// const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// Firefox 1.0+
// const isFirefox = typeof InstallTrigger !== 'undefined';

const stickyHeaderStyling = {
	position: 'sticky',
	top: 56,
	left: 0,
	right: 0,
	height: 0,
	pointerEvents: 'none'
}

function mapStateToProps(state) {
	const { pages, sites } = state.sites;
	const siteid = state.sites.params.siteid;
	const pageid = state.sites.pages.params.pageid;

	const pageIds = sites[siteid].pages;

	const pageExists = pageIds.some(id => id === pageid);

	const page = pageExists ? pageid : sites[siteid].frontPage;

	const pagesList = pageIds.map(pageId => pages[pageId]);

	const site = state.sites.sites[siteid];

	return {
		siteid,
        pageid,
        pageExists,
		frontPage: site.frontPage,
		page,
		pageIds,
		pages: pagesList, // this should also be reduced to only ids and not a full object.
		stagedContent: state.sites.content.stagedContent,
		stagedFrom: state.sites.content.stagedFrom
	};
}

const mapDispatchToProps = (dispatch) => ({
	stageContent: (pageid) => dispatch(contentActions.stageContent(pageid))
});

class PagesContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pageSettingsModalOpen: false,
			deleteAlertIsOpen: false,
			listIsVisible: false,
			warningModalOpen: false,
			liveMode: false
		};

		this.renderPagesList = this.renderPagesList.bind(this);
	}

	handleRoute() {
        const { siteid, pageid, pageIds, frontPage } = this.props;
        
        // const 

		// const pageExists = pageIds.some(id => id === pageid);

		if (!this.props.pageExists) {
			replaceRoute(`/dashboard/${siteid}/pages/${frontPage}`);

			return;
		}

	}

	componentDidUpdate() {
		this.handleRoute();
	}

	componentDidMount() {
		this.handleRoute();
	}

	renderPagesList() {
		this.setState({ listIsVisible: !this.state.listIsVisible });
	}

	render() {
		if (!this.props.pageid || !this.props.siteid) return null;

		return (
			<PageEditor 
				key={this.props.pageid}
				siteID={this.props.siteid}
				pageID={this.props.pageid}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PagesContainer);