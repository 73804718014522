import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button, ButtonGroup, Icon } from 'svift-ui';

class ListAndThumb extends PureComponent {
	render() {
		const viewType = this.props.view;

		return (
			<ButtonGroup basic size="mini">
				<Popup
					trigger={
						<Button icon className={viewType === 'list' ? 'active-button' : null} style={{ verticalAlign: 'middle' }} onClick={() => this.props.toggleView('list')}>
							<Icon name={this.props.listIcon} />
						</Button>
					}
					content={this.props.listPopup}
					position="bottom center"
				/>
				<Popup
						trigger={
							<Button icon className={viewType === 'thumb' ? 'active-button' : null} style={{ verticalAlign: 'middle' }} onClick={() => this.props.toggleView('thumb')}>
								<Icon name={this.props.thumbIcon} />
							</Button>
						}
						content={this.props.thumbPopup}
						position="bottom center"
				/>
			</ButtonGroup>
		);
	}
}

ListAndThumb.propTypes = {
    listIcon: PropTypes.string,
    thumbIcon: PropTypes.string,
	listPopup: PropTypes.string,
	thumbPopup: PropTypes.string   
};


ListAndThumb.defaultProps = {
	listIcon: 'sn-list',
	listPopup: 'List view',
    thumbIcon: 'sn-view_module',
	thumbPopup: 'Thumbnail view'
};

export default ListAndThumb;
