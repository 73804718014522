import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import { GridRow, Icon, Label } from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'components/preset/util';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import themeActions from 'store/actions/sites/themes';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { connect } from 'react-redux';
import OverviewColumn from './OverviewColumn';

// Replacing later anyway
const TempInput = ({ title, value, onChange }) => {
    return (
		<div style={{ display: 'inline-block' }}>
			{title}

			<input
				type="number"
				onChange={e => onChange(e.target.value)}
				value={value}
			/>  
		</div>
    )
}

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
class OverviewRow extends PureComponent {
    constructor(props){
        super(props);
        this.state ={
            selectedPreset: null,
            selectedPresetOption: null
        }
        // this.optionTooltip = tooltip.call(this);
        this.optionTooltipRef = React.createRef();
    }
    
    selectPreset = (preset) => this.setState({ selectedPreset: preset || null });
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });
   
    getPresetOptions = style => {
        const {presetTitle, preset, rowSelectedPresetOption, rowSelectPresetOption, theme} = this.props;
        
        const options = [
            <span key="row-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-columns" /> <FormattedMessage id="elements.row title" />: <FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} />
            </span>,
            <ColorPicker
                title={this.props.intl.formatMessage({ id: 'options.background color title' })}
                key="color-picker-background"
                icons={['sn-paint-format']}
                colors={theme.colors}
                onChange={(newColor) => this.props.updatePreset('row', presetTitle, 'backgroundColor', newColor)}
                value={preset.backgroundColor}

                option="color-picker-background"
                selectedOption={rowSelectedPresetOption}
                onToggle={(select, e) => {
                    rowSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Padding
                key="padding"
                value={preset.padding}
                preset={preset}
                theme={theme}
                onChange={(key, newValue) => {
                    if (key === 'generalPadding') {
                        if (newValue === null) {
                            this.props.updatePreset('row', presetTitle, 'padding', {
                                paddingBottom: 1,
                                paddingTop: 1
                            });
                        } else {
                            this.props.updatePreset('row', presetTitle, 'padding', {
                                generalPadding: newValue / 100
                            });
                        }

                        return;
                    }

                    const newPadding = {
                        ...preset.padding,
                        [key]: newValue / 100
                    };

                    if (newValue === null) {
                        delete newPadding[key];
                    }

                    delete newPadding.generalPadding;

                    if (typeof newPadding.paddingBottom === 'undefined') {
                        newPadding.paddingBottom = 1;
                        newPadding.paddingTop = 1;
                    }

                    this.props.updatePreset('row', presetTitle, 'padding', newPadding);
                }}

                option="Padding"
                selectedOption={rowSelectedPresetOption}
                onToggle={(select, e) => {
                    rowSelectPresetOption(select);
                    e.stopPropagation();
                }}

                optIn={{
                    paddingBottom: false,
                    paddingTop: false,
                    paddingLeft: true,
                    paddingRight: true,
                    generalPadding: true
                }}
            />,
            <Shadow
				key="shadow"
				value={preset.boxShadow || {}}
				colors={theme.colors}
				onChange={(newShadow) => {
					this.props.updatePreset('row', presetTitle, 'boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={rowSelectedPresetOption}
				onToggle={(select, e) => {
					rowSelectPresetOption(select);

					e.stopPropagation();
				}}
            />,
            <TempInput
                key="columnSpacing"
                title={`${this.props.intl.formatMessage({
                    id: 'options.space between columns label'
                })} (%)`}
                value={preset.columnSpacing === 0 ? undefined : Math.floor(preset.columnSpacing * 100)}
                onChange={val => {
                    let nextVal = val;

                    if (val === '' || val === 'undefined') {
                        nextVal = 0;
                    }

                    this.props.updatePreset('row', presetTitle, 'columnSpacing', parseInt(nextVal) / 100);
                }}               
            />,
            <TempInput
                key="maxWidth"
                title={`${this.props.intl.formatMessage({
                    id: 'options.maximum width'
                })} (px)`}
                value={preset.maxWidth === 0 ? undefined : preset.maxWidth}
                onChange={val => {
                    let nextVal = val;

                    if (val === '' || val === 'undefined') {
                        nextVal = 0;
                    }

                    this.props.updatePreset('row', presetTitle, 'maxWidth', parseInt(nextVal));
                }}               
            />
        ]   
        
        return options;
    }

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.selectedPreset === 'row') {
			this.props.clearSelectPreset();
		}
    }

    // onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.selectedPreset === 'row') {
	// 		this.props.clearSelectPreset();
	// 	}
    // }

    render(){
        const { intl, presetTitle, selectedPreset, preset, theme } = this.props;
        const selected = selectedPreset === 'row';
        const className = selected ? 'preset-target active-preset-target' : 'preset-target';

        const labelStyle = {
            position: 'absolute',
            top: -48,
            left: 312,
            zIndex: 5
        };
        const pointerStyle = {
            position: 'absolute',
            left: 330,
            width: 4,
            height: 16,
            top: -16,
            background: 'lightgrey'
        }

		const computedStyles = computePreset(preset, theme);
        return(
            <GridRow
                className={className}
                style={computedStyles}
                selected={selected}
                onClick={this.props.selectPreset('row')}
                // ref={this.optionTooltip.setTarget}
                ref={this.optionTooltipRef}
            >
                <OptionPanel
                    icon="sn-bucket"
                    title="elements.row title"
                    // target={this.optionTooltip.getTarget()}
                    target={this.optionTooltipRef.current}
                    open={selected}
                    options={this.getPresetOptions(computedStyles)}
                />
                <Label basic size="large" className="shade" style={labelStyle}>
                    <Icon name="sn-columns" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                    <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.row title" /></span>
                </Label>
                <div style={pointerStyle} />
                <OverviewColumn
                    intl={intl}
                    theme={theme}

                    clearSelectPreset={this.props.clearSelectPreset}
                    selectPreset={this.props.selectPreset}
                    selectedPreset={this.props.selectedPreset}

                    columnSelectPresetOption={this.selectPresetOption}
                    columnSelectedPresetOption={this.state.selectedPresetOption}

                    presetTitle='Normal'
                    preset={theme.presets.column.Normal}
                />
            </GridRow>
        )
    }
}

export default connect(null, mapDispatchToProps) (injectIntl(onClickOutside(OverviewRow)));