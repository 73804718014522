import constants from './constants';

let id = 0;

const createNotificationAction = notification => {
    notification.id = id++;

    return {
        type: constants.PUSH_NOTIFICATION,
        data: notification
    };
}

const deleteNotificationAction = notificationId => {
    return {
        type: constants.DELETE_NOTIFICATION,
        data: notificationId
    };
};

export {
    createNotificationAction,
    deleteNotificationAction
};
