import { addLocaleData } from 'react-intl';

import da from 'react-intl/locale-data/da';
import en from 'react-intl/locale-data/en';

const supportedLocales = ['da', 'en'];

addLocaleData([
	...da,
	...en
]);

// Converts translations to a usable format
const formatTranslations = (translations) => {
	const locales = supportedLocales.reduce((transObj, localeKey) => {
		transObj[localeKey] = {};

		return transObj;
	}, {});

	Object.keys(translations).forEach(translateOuterKey => {
		const translation = translations[translateOuterKey];

		Object.keys(translation).forEach(translateInnerKey => {
			const innerTranslation = translation[translateInnerKey];

			supportedLocales.forEach(supportedLocale => {
				locales[supportedLocale][`${translateOuterKey}.${translateInnerKey}`] = innerTranslation[supportedLocale];
			});
		});
	});

	return locales;
};

const prepare = (translations) => {
	const formattedTranslations = formatTranslations(translations);

	return formattedTranslations;
};

export default prepare;