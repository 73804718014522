import React from 'react';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import { Icon, Button} from 'svift-ui';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';
import { FormattedMessage } from 'react-intl';

const ParentSelectionPath = ({ path, select, renderButton, renderIcon }) => {
    return path.slice(0, path.length - 1).map((node, i) => {
        // const isLast = i === path.length - 1;
        const { context, nodeID } = node.props;
        const nodeInstance = context.nodeMap[nodeID];
        const selectParentElementPopup = useTooltip();

        const buttonProps = {
            basic: true,
            circular: true,
            ref: selectParentElementPopup,
            icon: resolveElementIcon(nodeInstance.component, nodeInstance.props && nodeInstance.props.type),
            onClick: e => {
                e.stopPropagation();

                select(e, nodeID);
            },
            className: 'secondary',
            style: {
                fontSize: 12,
                padding: 8,
                marginRight: 4,
            }
        };

        const iconProps = {
            name: 'sn-arrow-right3',
            style: {
                fontSize: 6,
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: 4,
            }
        };

        // if (['Container', 'LayoutContainer', 'Body'].includes(nodeInstance.component)) return null;

        return (
            <React.Fragment key={nodeID}>
                <Popup target={selectParentElementPopup.current} zIndex={1009}>
                    <div style={{ marginBottom: 2 }}>
                        <span style={{ verticalAlign: 'middle' }}>
                            <FormattedMessage id="options.select outer popup" />
                        </span>
                        <span style={{ textTransform: 'lowercase', fontWeight: 'bold', verticalAlign: 'middle' }}>
                            <Icon name={resolveElementIcon(nodeInstance.component, nodeInstance.props && nodeInstance.props.type)} style={{ marginLeft: 6, marginRight: 4, verticalAlign: 'middle' }} />
                            {resolveElementName(nodeInstance.component, nodeInstance.props && nodeInstance.props.type)}
                        </span>
                    </div>
                </Popup>

                {renderButton 
                    ? 
                        renderButton(buttonProps)
                    :
                        <Button
                            {...buttonProps}
                        />
                }

                {renderIcon 
                    ? 
                        renderIcon(iconProps)
                    :
                        <Icon
                            {...iconProps}
                        />
                }
            </React.Fragment>
        );
    });
}

export default ParentSelectionPath;