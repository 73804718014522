import React from 'react'
import Accordion, {
    useAccordion
} from 'svift-library/client/accordion/Accordion'
import Label from 'components/option-library/label'
import Section from '../../../Section'
import Dropdown from 'components/option-library/primitives/dropdown/simple'
import getTags from './getTags'
import { getAuthors } from './getAuthors'

export default props => {
    const [state, dispatch] = useAccordion()
    const { editableNode: node, updateState, intl } = props;

    const tagOptions = React.useMemo(() => {
        return getTags(props.blogs).map(tag => ({
            text: tag,
            value: tag,
            key: tag
        }));
    }, [props.blogs]);

    const authorOptions = React.useMemo(() => {
        return getAuthors(props.users).map(author => ({
            text: `${author.firstName} ${author.lastName}`,
            value: author._id,
            key: author._id
        }));
    }, [props.users]);

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                nested
                icon="filter"
                title={props.intl.formatMessage({
                    id: 'options.filter title'
                })}
                description={props.intl.formatMessage({
                    id: 'options.filter description'
                })}
                infoIconClassName="primary-color"
            >
                <Label
                    label={props.intl.formatMessage({
                        id: 'options.post keywords'
                    })}
                    // labelDescription="Something about filter"
                >
                    <Dropdown
                        allowMultiple
                        items={tagOptions}
                        value={node.props.filter && node.props.filter.tags}
                        onChange={value => {
                            // console.log(value, 'value on change tags')
                            props.onChange(
                                'filter',
                                {
                                    ...node.props.filter,
                                    tags: value
                                }
                            );
                        }}
                    />
                </Label>
                <Label
                    label={props.intl.formatMessage({
                        id: 'options.post author'
                    })}
                    // labelDescription="Something about filter"
                >
                    <Dropdown
                        allowMultiple
                        items={authorOptions}
                        value={node.props.filter && node.props.filter.authors}
                        onChange={value => {
                            console.log(value, 'value on change authors')
                            props.onChange(
                                'filter',
                                {
                                    ...node.props.filter,
                                    authors: value
                                }
                            );
                        }}
                    />
                </Label>
            </Section>
        </Accordion>
    )
}
