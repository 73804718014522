import { useState, useEffect } from 'react';

const addElement = (id, attributes) => {
	const element = document.createElement('div');
	
	if (id) element.setAttribute('data-id', id);

	if (attributes) {
		Object.entries(attributes).forEach(([key, value]) => {
			element.setAttribute(key, value);
		});
	}	

    document.body.appendChild(element);

    return element;
};

const removeElement = element => {
    document.body.removeChild(element);
};

export default (id, attributes) => {
	// const [containerElement, setContainerElement] = useState(null);
	// const [containerElement, setContainerElement] = useState(addElement(id, attributes));

	const [containerElement] = useState(() => addElement(id, attributes));

	useEffect(() => {
		// const element = addElement(id, type);
	
		// setContainerElement(element);
		
		return () => removeElement(containerElement);
	}, []);

	return containerElement;
};