import React, { useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { DropTarget } from 'react-dnd';
import { Icon } from 'svift-ui';
import css from './collision-stack.scss';
import resolveIcon from './resolveIcon';
import Tooltip from 'svift-library/client/tooltip';
import resolveDropTargetTranslation from '../resolveDropTargetTranslation';

const sources = props => Object.keys(props.dndInterface);
const spec = {
    drop: (props, monitor) => {

    },
    hover: (props, monitor) => {

    }
};
const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver()
    };
};

const Zone = ({ zone, connectDropTarget, isOver, dragState }) => {
    const className = isOver ? css['stack-zone-active'] : css['stack-zone'];
    const tooltipRef = useRef();

    useEffect(() => {
        if (isOver && dragState.getActiveZone() !== zone) {
            dragState.setActiveZone(zone);
        }
    }, [isOver])

    return (
        <div
            ref={ref => {
                tooltipRef.current = ref;
                connectDropTarget(findDOMNode(ref))
            }}
            className={`${className}`}
        >
            <Tooltip
                target={tooltipRef.current}
                popup
                clearance={10}
                zIndex={10000000}
                open={isOver}
            >
                {resolveDropTargetTranslation(zone.type)}
            </Tooltip>  

            <Icon name={resolveIcon(zone.id)} />
        </div>
    );
}

export default DropTarget(sources, spec, collect)(Zone);