import React, { PureComponent } from 'react';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import css from './follow.scss';

class Follow extends PureComponent {
    static mapContextToProps = (props, state) => {
		const { theme } = props.context;
	
        return shallowCompare({
			theme,
        }, state);
    }
    
    render() {
        return (
            <div style={{ padding: '30px' }}>
                SOCIAL MEDIA FOLLOW INTEGRATIONS GO HERE
            </div>
        );
    }
}

export default Follow;