import React, { memo } from 'react';
import { Icon, Button } from 'svift-ui';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import css from './app-navigation.scss';

export default memo(props => {
    const actionButtonPopup = useTooltip();

    return (
        <div className={`${css['app-navigation']} ${css['animated']} ${css['fadeIn']} ${css['ultra-fast']} ${props.previewMode && css['preview-mode']}`}>
            <div className={`${css['app-title-section']} ${css['animated']} ${css['fadeInDown']} ${css['faster']}`}>
                {/* app icon + title */}
                {props.appIcon &&
                    <Icon name={props.appIcon} className={css['app-icon']} />
                }
                <span className={css['app-title']}>
                    {props.appTitle || 'No app title...'}
                </span>
                
                {/* app action button */}
                {(props.appAction && !props.rawAppAction)
                    ?
                        <React.Fragment>
                            {props.actionButtonPopup &&
                                <Popup
                                    target={actionButtonPopup.current}
                                    position="bottom center"
                                    zIndex={999999} // above preview container
                                >
                                    {props.appActionPopup}
                                </Popup>
                            }
                            <Button
                                ref={actionButtonPopup && actionButtonPopup}
                                size="mini"
                                className={
                                    !props.appActionClassName
                                        ?
                                            `positive ${css['app-action']}`
                                        :
                                            `${props.appActionClassName} ${css['app-action']}`
                                }
                                style={{ padding: '6px', boxShadow: '0 0 0 1px white', ...props.appActionStyle }}
                            >
                                {props.appAction}
                            </Button>
                        </React.Fragment>
                    :
                        props.appAction
                }
            </div>

            {/* app controls section */}
            <div className={`${css['app-controls-section']} ${css['animated']} ${css['fadeInDown']} ${css['fast']}`}>
                {props.children}
            </div>
        </div>
    );
});
