import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'FormRadio';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {
			style: {},
			label: 'Radio label',
			options: 'Option 1, Option 2, Option 3',
			preset: 'Primary',
		}
	})
};