import React, { PureComponent } from 'react';
import { 
    Message,
    Segment,
    Image,
    Button,
    ButtonGroup,
    Icon,
    Popup,
    Label,
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import FilePicker from 'components/option-library/primitives/file';
import { FormattedMessage } from 'react-intl';
// import FileModal from 'components/option-panel/options/file-picker/modal/FileModal';

class AdaptedFilePicker extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false
        };
    }

	// clearImg = (fileType) => {
	// 	this.props.setBranding({
	// 		fileID: null,
	// 		siteID: this.props.siteid,
	// 		type: fileType
	// 	});
	// }

    render() {
        const {
            siteid,
            input,
            intl,
            fallBackImgSrc,
            selectionTitle,
            selectionDescription,
            clearSelectionDescription,
            style,
            meta: { error }
        } = this.props;

        // const file = this.props.files.find(({ _id }) => _id === input.value);

        return (
            <React.Fragment>
                <FilePicker 
                    // title={<FormattedMessage id="options.branding title" />}
                    tabs={['files']}
                    filter={file => file.meta.mimeType.startsWith('image')}
                    onChange={(files) => input.onChange(files || null)}
                    value={input.value}
                />
                    {/* <FileModal
                        value={input.value}
                        tabs={['files']}
                        toggleModal={() => this.setState({ modalOpen: false })}
                        onChange={files => {
                            const file = Object.values(files)[0];

                            this.setState({
                                modalOpen: false
                            });
        
                            input.onChange(file && file._id);
                        }}
                        siteid={siteid}
                        filter={file => file.meta.mimeType.startsWith('image')} */}

                
                {/* Looks like branding specific stuff here; TRANSL8 
                <Segment className="branding-preview" style={style}>
                    { fallback label when not defined }
                    {!(file && file.url) &&
                        <Label
                            size="mini"
                            className="info"
                            style={{ margin: 0, marginBottom: 6 }}
                        >
                            <FormattedMessage id="general.not defined" />
                        </Label>
                    }
    
                    <Image
                        src={(file && file.url) ? file.url : fallBackImgSrc}
                        style={{ display: 'inline-block', marginBottom: 8 }} 
                    />

                    <p>
                        <FormattedMessage id={selectionTitle} />
                        {selectionDescription &&
                            <InfoIcon description={intl.formatMessage({ id: selectionDescription })} />
                        }
                    </p>
                </Segment>
                */}
                
                {/* <ButtonGroup size="tiny" fluid>
                    <Popup
                        trigger={
                            <Button 
                                basic 
                                className="positive" 
                                onClick={() => this.setState({ modalOpen: true })}
                            >
                                <Icon name="sn-star-empty" />
                                <FormattedMessage id="general.change" />
                            </Button>
                        }
                        content={intl.formatMessage({ id: this.props.buttonInfo })}
                        position="right center"
                    />

                    <Popup
                        trigger={
                            <Button
                                basic
                                icon="sn-cross"
                                className="negative"
                                style={{ maxWidth: 40 }}
                                onClick={() => input.onChange(null)}
                            />
                        }
                        content={intl.formatMessage({ id: clearSelectionDescription })}
                        position="right center"
                    />
                </ButtonGroup> */}
    
                {error && 
                    <Message negative>
                        {intl.formatMessage({ id: error })}
                    </Message>
                }
            </React.Fragment>
        );
    }
}

export default AdaptedFilePicker;