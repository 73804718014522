import constants from 'store/constants';
import createApi from 'api';
import siteActions from 'store/actions/sites';
import { setRoute } from 'utils/routing';
import { themesSelector } from 'store/selectors/sites/themes';
import uuid from 'uuid';

const actions = {
	stageTheme: (theme) => {
		return {
			type: constants.STAGE_THEME,
			data: theme
		};
	},
	updateThemeSetting: (outerKey, innerKey, value) => {
		return {
			type: constants.UPDATE_THEME_SETTING,
			data: {
				outerKey,
				innerKey,
				value
			}
		};
	},

	toggleView: view => {
		return {
			type: constants.TOGGLE_THEME_VIEW,
			data: view
		};
	},

	updatePreset: (presetType = 'text', presetElement = 'h1', presetElementOption = 'fontSize', value) => ({
		type: constants.UPDATE_PRESET,
		data: {
			presetType,
			presetElement,
			presetElementOption,
			value
		}
	}),

	createTheme: (newTheme, siteid, shouldPublish) => async (dispatch, getState) => {
		const api = createApi();

		const insertedTheme = await api.createTheme(newTheme, siteid, shouldPublish);

		await siteActions.fetchSite(siteid)(dispatch, getState);

		dispatch({
			type: constants.CLEAR_STAGED_THEME
		});

		setRoute(`/dashboard/${siteid}/design/${insertedTheme._id}`);
	},

	updateTheme: (newTheme, siteid, themeid) => async (dispatch, getState) => {
		const api = createApi();

		await api.updateTheme(newTheme, siteid, themeid);

		await siteActions.fetchSite(siteid)(dispatch, getState);
	},

	saveTheme: (draft, siteid, themeid) => async (dispatch, getState) => {
		const api = createApi();
		const state = getState();
		const version = uuid();

		const newTheme = {
			...themesSelector(state, siteid)[themeid]
		};

		newTheme.draft = {
			...draft,
			version
		};

		delete newTheme.draft.name;
		delete newTheme.draft._id;
		delete newTheme._id;
		delete newTheme.createdAt;
		delete newTheme.live;
		delete newTheme.updatedAt;

		await api.updateTheme(newTheme, siteid, themeid, false);

		dispatch({
			type: constants.SAVE_THEME,
			data: {
				theme: {
					...state.sites.themes.stagedTheme,
					version
				},
				siteid,
				themeid
			}
		});
	},

	publishTheme: (draft, siteid, themeid) => async (dispatch, getState) => {
		const api = createApi();
		const state = getState();
		const version = uuid();

		const newTheme = {
			...themesSelector(state, siteid)[themeid]
		};

		newTheme.draft = {
			...draft,
			version
		};

		delete newTheme.draft.name;
		delete newTheme.draft._id;
		delete newTheme._id;
		delete newTheme.createdAt;
		delete newTheme.updatedAt;
		delete newTheme.live;

		await api.updateTheme(newTheme, siteid, themeid, true);

		dispatch({
			type: constants.PUBLISH_THEME,
			data: {
				theme: {
					...state.sites.themes.stagedTheme,
					version
				},
				siteid,
				themeid
			}
		});
	},

	publishSystemTheme: (siteid, themeid) => async (dispatch) => {
		const api = createApi();

		await api.publishSystemTheme(siteid, themeid);

		dispatch({
			type: constants.PUBLISH_SYSTEM_THEME,
			data: {
				themeid,
				siteid
			}
		});
	},

	deleteTheme: (themeID, siteID) => async (dispatch, getState) => {
		const api = createApi();

		await api.deleteTheme(siteID, themeID);

		const state = getState();

		const deletedTheme = state.sites.themes.themes[themeID];
		const selectedThemeId = state.sites.themes.params.themeid;
		
		const shouldRedirect = selectedThemeId === deletedTheme._id;

		if (shouldRedirect) {
			setRoute(`/dashboard/${siteID}/design/${deletedTheme.origin}`, { delete: constants.DELETE_THEME, data: { themeID, siteID } });	
		} else {		
			dispatch({
				type: constants.DELETE_THEME,
				data: {
					themeID,
					siteID
				}
			});
		}
	}
};

export default actions;
