import React, { PureComponent } from 'react';
import { Button, Icon, Message } from 'svift-ui';
import { FormattedMessage } from 'react-intl';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';

class SideNavBlogs extends PureComponent {
	render() {
		return (
			<React.Fragment>
				<PortalHook id="editor-sidenav-portal" />

				<PortalHook id="building-blocks-sidenav-portal" />
			</React.Fragment>
		);
	}
}

export default SideNavBlogs;
