import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownHeader, DropdownDivider, DropdownItem, Menu, Icon, Label, Popup } from 'svift-ui';
import ModalWrapper from 'components/modal';
import { themesSelector } from 'store/selectors/sites/themes';
import { setRoute } from 'utils/routing';
import { injectIntl, FormattedMessage } from 'react-intl';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import ThemeForm from 'components/forms/theme';
import themeActions from 'store/actions/sites/themes';
import InfoIcon from 'components/InfoIcon';
import notification from 'components/hoc/notification';
import SviftGA from 'utils/svift-ga';
import ThemeSettings from './ThemeSettings';
import OptionLabel from 'components/option-library/label';
import Portal from 'components/editor-v2/editor-interface/Portal';

const isSuperAdmin = roles => !!roles.find(role => role.type === 'superadmin');

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themeid = state.sites.themes.params.themeid;

	return {
		siteid,
		themeid,
		pathname: state.router.location.pathname,
		themes: themesSelector(state, siteid),
		liveTheme: state.sites.sites[siteid].activeTheme,
		stagedFrom: state.sites.themes.stagedFrom,
		stagedTheme: state.sites.themes.stagedTheme,
		roles: state.user.roles
	};
};

const mapDispatchToProps = (dispatch) => ({
	updateTheme: (newTheme, siteid, themeid) => dispatch(themeActions.updateTheme(newTheme, siteid, themeid)),
	deleteTheme: (themeID, siteID) => dispatch(themeActions.deleteTheme(themeID, siteID))
});

class ThemeDropdown extends PureComponent {
	constructor() {
		super();

		this.state = {
			themeModalOpen: false
		};

		this.navigate = this.navigate.bind(this);
		this.toggleThemeModal = this.toggleThemeModal.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	commitDelete = async () => {
		const { siteid, intl } = this.props;
		const { themeid } = this.state;

		this.setState({
			loading: true
		});

		try {
			await this.props.deleteTheme(themeid, siteid);

			SviftGA.THEMES.DELETED();

			this.setState({
				loading: false,
				themeModalOpen: false
			});
			
			this.props.createNotification({
				content: this.props.intl.formatMessage({ id: 'design.delete theme notification' }),
				className: 'info',
				position: 'topCenter',
				icon: 'trash',
				deleteTime: 2000
			});

		} catch (e) {
			this.setState({
				loading: false,
				themeModalOpen: false
			});

			handleSubmitError(intl)(e);
		}
	}

	navigate(selectedTheme) {
		const { themeid, pathname } = this.props;

		const newPathname = pathname.replace(themeid, selectedTheme);

		setRoute(newPathname);
	}

	async onSubmit(data, x, themeid) {
		const { siteid } = this.props;

		try {
			this.props.updateTheme(data, siteid, themeid);

			SviftGA.THEMES.MODIFIED();

			this.toggleThemeModal();
		} catch (e) {
			handleSubmitError(this.props.intl)(e);
		}
	}

	toggleThemeModal(themeid) {
		this.setState({
			themeModalOpen: !this.state.themeModalOpen,
			themeid: !this.state.themeModalOpen ? themeid : null
		});
	}

	renderDropdown() {
		const { themes, liveTheme, stagedTheme, intl } = this.props;

		const customThemes = Object.values(themes).filter(theme => typeof theme.origin !== 'undefined');
		const systemThemes = Object.values(themes).filter(theme => typeof theme.origin === 'undefined');

		return (
			<React.Fragment>
				<Dropdown
					id="theme-dropdown-menu"
					item
					fluid
					text={themes[stagedTheme._id].name}
					style={{ width: '100%' }}
				>
					<DropdownMenu>
						{customThemes.length > 0 && <DropdownHeader icon="sn-bucket" content={intl.formatMessage({ id: 'design.custom themes' })} />}
							{
								customThemes.map(el => {
									return (
										<DropdownItem
											key={el._id}
											value={el._id}
											onClick={() => this.navigate(el._id)}
											className={el._id === stagedTheme._id ? 'active-item' : ''}
										>
											<Icon name="sn-color-sampler" />{el.name}
											<div style={{ marginTop: 6, marginBottom: 4 }}>
												<Popup
													trigger={
														<Icon
															link
															name="setting"
															className="primary"
															style={{ margin: 0 }}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();

																this.toggleThemeModal(el._id);
															}}
														/>
													}
													content={intl.formatMessage({ id: 'design.theme settings title' })}
													position="bottom left"
												/>
												{el._id === liveTheme &&
													<Popup
														trigger={
															<Label circular basic size="tiny" className="info" style={{ marginLeft: 6 }}><FormattedMessage id="design.active theme label" /></Label>
														}
														content={intl.formatMessage({ id: 'design.active theme description' })}
														position="bottom left"
													/>
												}
											</div>
										</DropdownItem>
									);
								})
							}
						<DropdownDivider />

						<DropdownHeader icon="sn-bucket" content={intl.formatMessage({ id: 'design.system themes' })} />
							{
								systemThemes.map(el => {
									return (
										<DropdownItem
											key={el._id}
											value={el._id}
											onClick={() => this.navigate(el._id)}
											className={el._id === stagedTheme._id ? 'active' : ''}
										>
											<Icon name="sn-color-sampler" />{el.name}
											{el._id === liveTheme &&
												<div style={{ marginTop: 6, marginBottom: 4 }}>
													<Popup
														trigger={
															<Label circular basic size="tiny" className="info"><FormattedMessage id="design.active theme label" /></Label>
														}
														content={intl.formatMessage({ id: 'design.active theme description' })}
														position="bottom left"
													/>
												</div>
											}
										</DropdownItem>
									);
								})
							}
						
					</DropdownMenu>
				</Dropdown>
				
				<Portal target="theme-dropdown-menu">
					<ThemeSettings
						size="large"
						style={{
							marginLeft: 'auto',
							marginRight: -8,
							verticalAlign: 'middle'
						}}
					/>
				</Portal>
			</React.Fragment>
		);
	}

	render() {
		const { themes, intl } = this.props;

		if (themes.length === 0) return null;

		return (
			<OptionLabel
				label={
					<React.Fragment>
						<FormattedMessage id="options.theme title" />
						<span style={{ position: 'absolute', right: 8, fontSize: 12, marginTop: 6 }}>
							<Icon name="sn-palette" style={{ marginRight: 6 }} />
							<FormattedMessage id="options.design settings title" />:
						</span>
					</React.Fragment>
				}
				labelDescription={intl.formatMessage({ id: 'options.theme description' })}
				labelIcon="sn-color-sampler"
			>
				{/* <p className="sidenav-subheader"><Icon name="sn-color-sampler" style={{ marginTop: 0 }} /> <FormattedMessage id="options.theme title" /><InfoIcon description={intl.formatMessage({ id: 'options.theme description' })} /></p> */}
				{/* TO DO: switch out with winged dropdown */}
				<Menu compact fluid>
					{this.renderDropdown()}

					<ModalWrapper open={this.state.themeModalOpen} handleClose={this.toggleThemeModal} header={intl.formatMessage({ id: 'design.theme settings title' })}>
						<ThemeForm
							initialValues={{
								name: themes[this.state.themeid] && themes[this.state.themeid].name
							}}
							themeid={this.state.themeid}
							update
							loading={this.state.loading}
							delete={this.commitDelete}
							onSubmit={this.onSubmit}
							onClose={this.toggleThemeModal}
						/>
					</ModalWrapper>
				</Menu>
			</OptionLabel>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(ThemeDropdown)));