import constants from 'store/constants';
import createApi from 'api';
import { setRoute } from 'utils/routing';
import sites from 'store/actions/sites';
import uuid from 'uuid';

export default {
	stageLayout: layoutid => (dispatch, getState) => {
		const layout = getState().sites.layout.layoutContent[layoutid];

		dispatch({
			type: constants.STAGE_LAYOUT,
			data: layout.draft
		});
	},
	toggleView: view => {
		return {
			type: constants.TOGGLE_LAYOUT_VIEW,
			data: view
		};
	},
	createLayout: (siteID, newLayout) => async (dispatch, getState) => {
		const api = createApi();

		// try {
		const createdLayout = await api.createLayout(siteID, newLayout);

		// dispatch({
		// 	type: constants.CREATE_LAYOUT,
		// 	data: {
		// 		newLayout: createdLayout,
		// 		siteID
		// 	}
		// });

		await sites.fetchSite(siteID)(dispatch, getState);

		setRoute(`/dashboard/${siteID}/design/layouts/${createdLayout._id}`);
		// } catch (e) {
		// 	console.log(e);
		// }
	},

	deleteLayout: (siteID, layoutID) => async (dispatch, getState) => {
		const api = createApi();

		await api.deleteLayout(siteID, layoutID);

		const state = getState();

		const site = state.sites.sites[siteID];
		const layouts = state.sites.layout.layouts;

		const { layouts: layoutIds } = site;

		const defaultPageLayout = layoutIds.find(id => layouts[id].type === 'page' && layouts[id].default);

		setRoute(`/dashboard/${siteID}/design/layouts/${defaultPageLayout._id}`, { delete: constants.DELETE_LAYOUT });

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	updateLayout: (siteID, layoutID, newValues) => async (dispatch, getState) => {
		const api = createApi();

		await api.updateLayout(siteID, layoutID, newValues);

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	setLayouts: data => ({
		type: constants.SET_LAYOUTS,
		data
	}),
	updateLayoutContent: (layoutID, tree) => ({
		type: constants.UPDATE_LAYOUT_CONTENT,
		data: {
			layoutID,
			tree
		}
	}),
	publishDraft: (siteid, layoutid, draft) => async (dispatch, getState) => {
		const api = createApi();
		// const draft = getState().sites.layout.stagedLayout;

		// draft.version = uuid();

		await api.publishLayoutDraft(draft, siteid, layoutid);

		dispatch({
			type: constants.PUBLISH_LAYOUT,
			data: {
				draft,
				siteid,
				layoutid
			}
		});
	},

	saveDraft: (siteid, layoutid, draft) => async (dispatch, getState) => {
		const api = createApi();
		// const draft = getState().sites.layout.stagedLayout;

		// draft.version = uuid();

		await api.saveLayoutDraft(draft, siteid, layoutid);

		dispatch({
			type: constants.SAVE_LAYOUT,
			data: {
				draft,
				siteid,
				layoutid
			}
		});
	}
};
