import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'Feed';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {
			type: 'BlogPosts',
			viewType: 'List', // List/Cards/Slider
			itemsPerPage: 3,
			filter: null,
			thumbnail: {
				preset: 'Normal',
				visible: true,
				style: {
					margin: {
						marginRight: 1
					}
				}
			},
			postTitle: {
				preset: 'Heading 3',
				visible: true,
				style: {
					margin: {
						marginBottom: 0.2
					}
				}
			},
			postPublicationDate: {
				preset: 'Small',
				visible: true,
				style: {
					
				}
			},
			postDescription: {
				preset: 'Normal',
				visible: true,
				style: {
					margin: {
						marginBottom: 0.75
					}
				}
			},
			postAuthor: {
				preset: 'Small',
				visible: true,
				style: {
					margin: {
						marginBottom: 0.5
					}
				}
			}
		}
	})
};