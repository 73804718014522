import React, { PureComponent } from 'react';
import {
	Header,
	HeaderSubheader,
	Form,
	Table,
	TableHeader,
	TableRow,
	TableHeaderCell,
	Message,
	TableCell,
	Icon,
	HeaderContent,
	TableBody,
	Label,
	Input,
	Button,
	Loader,
	Popup,
	ButtonGroup,
	FormSelect
} from 'svift-ui';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import createApi from 'api';
import Alert from 'components/Alert';
import notification from 'components/hoc/notification';
import InfoIcon from 'components/InfoIcon';
import SviftGA from 'utils/svift-ga';

const userRoles = intl => [
	{ value: 'siteAdmin', text: intl.formatMessage({ id: 'settings.users user role administrator' }) },
	{ value: 'siteDesigner', text: intl.formatMessage({ id: 'settings.users user role designer' }) },
	{ value: 'siteWriter', text: intl.formatMessage({ id: 'settings.users user role writer' }) },
	{ value: 'siteBlogger', text: intl.formatMessage({ id: 'settings.users user role blogger' }) }
];

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		siteid,
		siteName: site.name,
		subscription: site.subscription,
		subscriptionTypes: state.system.subscriptions
	};
};

const api = createApi();

class Users extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			users: null,
			selectedRole: '',
			email: ''
		};

		this.renderUserRows = this.renderUserRows.bind(this);
		this.renderInviteCount = this.renderInviteCount.bind(this);
		this.renderInviteRow = this.renderInviteRow.bind(this);
		this.fetchUsers = this.fetchUsers.bind(this);
		this.getInvitesLeft = this.getInvitesLeft.bind(this);
		this.sendInvite = this.sendInvite.bind(this);
		this.renderError = this.renderError.bind(this);
		this.swapRole = this.swapRole.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
	}

	async componentDidMount() {
		const users = await api.siteUsers.fetch({ siteID: this.props.siteid });

		this.props.setUsers(users);

		this.setState({
			loading: false
		});
	}

	fetchUsers() {
		this.setState({
			loading: true
		}, async () => {
			const users = await api.siteUsers.fetch({ siteID: this.props.siteid });

			this.props.setUsers(users);

			this.setState({
				loading: false,
				selectedUser: null
			});
		});
	}

	swapRole() {
		this.setState({
			loading: true
		}, async () => {
			try {
				await api.siteUsers.swapRole({
					email: this.state.selectedUser.id,
					newRole: this.state.selectedUser.type,
					siteID: this.props.siteid
				});

				this.fetchUsers();
				
				this.props.createNotification({
					content: this.props.intl.formatMessage({ id: 'settings.users update role notification' }),
					className: 'positive',
					position: 'topCenter',
					icon: 'user',
					deleteTime: 2000
				});
			} catch (e) {
				console.log(e);

				this.setState({
					error: e.readable,
					loading: false,
					selectedUser: null
				});
			}
		});
	}

	deleteUser() {
		this.setState({
			loading: true
		}, async () => {	
			try {
				await api.siteUsers.deleteUser({
					email: this.state.selectedUser.id,
					siteID: this.props.siteid
				});

				this.fetchUsers();

				this.props.createNotification({
					content: this.props.intl.formatMessage({ id: 'settings.users remove user notification' }),
					className: 'info',
					position: 'topCenter',
					icon: 'user',
					deleteTime: 2000
				});
			} catch (e) {
				console.log(e);

				this.setState({
					error: e.readable,
					loading: false,
					selectedUser: null
				});
			}
		});
	}

	sendInvite() {
		if (!(this.state.email && this.state.selectedRole)) {
			this.setState({
				error: this.props.intl.formatMessage({ id: 'settings.users user invite error message' })
			});

			return;
		}

		this.setState({
			loading: true
		}, async () => {
			try {
				SviftGA.SITES.USER_INVITE_STARTED()

				await api.siteUsers.grantRole({
					email: this.state.email,
					role: this.state.selectedRole,
					siteID: this.props.siteid
				});

				this.fetchUsers();
				
				this.props.createNotification({
					content: this.props.intl.formatMessage({ id: 'settings.users send invite notification' }),
					className: 'positive',
					position: 'topCenter',
					icon: 'send outline',
					deleteTime: 2000
				});
			} catch (e) {
				console.log(e);

				this.setState({
					error: e.readable,
					loading: false,
					selectedUser: null
				});
			}
		});
	}

	renderUserRows() {
		const { intl, users } = this.props;

		return users.map(user => {
			const {
				email,
				firstName,
				lastName,
				roles
			} = user;

			const {
				confirmationID, // If this is present, the user has not confirmed his invitation yet
				type
			} = roles[0];

			const translatedRole = intl.formatMessage({
				id: (() => {
					switch (type) {
						case 'siteOwner':
							return 'settings.users user role site owner';
						case 'siteWriter':
							return 'settings.users user role writer';
						case 'siteDesigner':
							return 'settings.users user role designer';
						case 'siteAdmin':
							return 'settings.users user role administrator';
						case 'siteBlogger':
							return 'settings.users user role blogger'; 
						default:
							return 'N/A';
					}
				})()
			});

			const isSelected = this.state.selectedUser && this.state.selectedUser.id === email;

			return (
				<TableRow key={email}>
					<TableCell>
						<Header as="h6" className="medium-text">
							<Icon
								name="sn-account_circle"
								className="primary-color"
								style={{ fontSize: 32 }}
							/>
							{isSelected
								?
									<HeaderContent>
										<Button
											size="mini"
											negative
											onClick={() => {
												this.setState({
													showConfirmDelete: true
												});
											}}
										>
											<FormattedMessage id="settings.users remove user button" />
										</Button>

										<Alert
											show={this.state.showConfirmDelete}
											title={intl.formatMessage({ id: 'settings.users delete user alert title' })}
											text={intl.formatMessage({ id: 'settings.users delete user alert description' })}
											confirmTitle={intl.formatMessage({ id: 'settings.users delete user alert confirm button' })}
											confirmIcon="log out"
											confirmClass="negative"
											cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
											onConfirm={() => {
												this.setState({
													showConfirmDelete: false
												});

												this.deleteUser();
											}}
											showCancelButton
											onCancel={() => {
												this.setState({
													showConfirmDelete: false
												});
											}}
										/>
									</HeaderContent>
								:
									<HeaderContent>
										{`${firstName} ${lastName}`}
										<HeaderSubheader>{email}</HeaderSubheader>
									</HeaderContent>
							}
						</Header>
					</TableCell>

					<TableCell>
						{isSelected
							?
								<Form size="mini" style={{ minWidth: 124 }}>
									<FormSelect
										fluid
										placeholder={intl.formatMessage({ id: 'settings.users user role select placeholder' })}
										options={userRoles(intl)}
										value={this.state.selectedUser.type}
										onChange={(e, { value }) => {
											this.setState({
												selectedUser: {
													...this.state.selectedUser,
													type: value
												}
											});
										}}
									/>
								</Form>
							:
								<Label basic circular size="tiny" style={{ marginLeft: 4 }} color="blue">
									<Icon name="sn-quill3" />
									{translatedRole}
								</Label>
						}
					</TableCell>

					<TableCell>
						<Label basic circular size="tiny" className={confirmationID ? 'warning' : 'positive'}>
							<FormattedMessage id={`settings.users user status ${confirmationID ? 'invited' : 'active'}`} />
						</Label>
					</TableCell>

					<TableCell textAlign="center">
						{type !== 'siteOwner' &&
							<div>
								{isSelected
									?
										<ButtonGroup size="mini">
											<Popup
												trigger={
													<Button 
														positive 
														onClick={this.swapRole} 
														icon="sn-checkmark" 
													/>
												}
												content={intl.formatMessage({ id: 'settings.users update role button' })}
												position="top center"
											/>
											<Popup
												trigger={
													<Button 
														basic 
														onClick={() => {
															this.setState({
																selectedUser: null
															});
														}}
														icon="sn-cross2" 
													/>
												}
												content={intl.formatMessage({ id: 'general.cancel' })}
												position="top center"
											/>
										</ButtonGroup>
									:
										<Icon
											onClick={() => {
												this.setState({
													selectedUser: {
														id: email,
														type
													}
												});
											}}
											name="setting"
											size="small"
											link="#"
											style={{ fontSize: 16 }}
										/>
								}
							</div>
						}
					</TableCell>
				</TableRow>
			);
		});
	}

	getInvitesLeft() {
		const {
			subscription,
			subscriptionTypes
		} = this.props;

		const maxAllowedUsers = (subscriptions => {
			if (!subscription || !subscription.type) return subscriptions.free.users;

			if (subscription.type === 'Light') {
				return subscriptions.light.users;
			} else if (subscription.type === 'Pro') {
				return subscriptions.pro.users;
			}

			return subscriptions.free.users;
		})(subscriptionTypes);

		const invitesLeft = (maxAllowedUsers + 1) - this.props.users.length;

		return invitesLeft;
	}

	renderInviteCount() {
		const invitesLeft = this.getInvitesLeft();

		return (
			<Message info>
				<Header>
					<FormattedMessage id="settings.users invites message title 1" /> {invitesLeft} <FormattedMessage id="settings.users invites message title 2" />
				</Header>

				{invitesLeft <= 0 &&
					<p><FormattedMessage id="settings.users upgrade message description" /></p>
				}
			</Message>
		);
	}

	renderInviteRow() {
		const invitesLeft = this.getInvitesLeft();
		const { intl } = this.props;

		return (
			<TableRow verticalAlign="top">
				<TableCell>
					<Header as="h5">
						<Icon
							name="sn-account_circle"
							className="positive-color"
							style={{ fontSize: 32 }}
						/>
						<HeaderContent style={{ minWidth: 132, width: '100%' }}>
							<Input
								fluid
								type="email"
								value={this.state.email}
								onChange={(e, { value }) => {
									this.setState({
										email: value
									});
								}}
								placeholder={intl.formatMessage({ id: 'settings.users user email input placeholder' })}
								size="mini"
								style={{ marginTop: 4 }}
							/>
						</HeaderContent>
					</Header>
					<Message className="warning" style={{ fontSize: 12 }}>
						{/* transl8 */}
						OBS: Så længe svift.net er i beta kræver invitation af brugere, at de allerede er registreret. Du skal derfor sikre dig at den e-mail du inviterer har anmodet om at blive betatester, og har været gennem registreringsprocessen.
						<InfoIcon description="Hvis du vil vide mere om hvordan brugere inviteres er du velkommen til at kontakte vores support via menuen øverst til højre." />
					</Message>
				</TableCell>
				<TableCell textAlign="center">
					<Form size="mini" style={{ minWidth: 124 }}>
						<FormSelect
							fluid
							placeholder={intl.formatMessage({ id: 'settings.users user role select placeholder' })}
							options={userRoles(intl)}
							value={this.state.selectedRole}
							onChange={(e, { value }) => {
								this.setState({
									selectedRole: value
								});
							}}
						/>
					</Form>
				</TableCell>
				<TableCell>
					<Button
						fluid
						disabled={invitesLeft <= 0}
						size="mini"
						positive
						onClick={this.sendInvite}
					>
						<Icon name="sn-paperplane" />
						<FormattedMessage id="settings.users send invite button" />
					</Button>
				</TableCell>

				<TableCell />
			</TableRow>
		);
	}

	renderError() {
		if (!this.state.error) return null;

		return (
			<Message warning>
				{this.state.error}
			</Message>
		);
	}

	render() {
		if (this.state.loading || this.state.sendingInvite) {
			return <Loader size="massive" style={{ marginTop: 24, marginBottom: 32 }}> <FormattedMessage id="settings.users loading" /> </Loader>;
		}

		return (
			<div>
				<Table stackable selectable>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>
								<FormattedMessage id="settings.users table header name" />
							</TableHeaderCell>
							<TableHeaderCell>
								<FormattedMessage id="settings.users table header role" />
							</TableHeaderCell>
							<TableHeaderCell>
								<FormattedMessage id="settings.users table header status" />
							</TableHeaderCell>
							<TableHeaderCell>
								<FormattedMessage id="settings.users table header edit" />
							</TableHeaderCell>
						</TableRow>
					</TableHeader>

					<TableBody>
						{this.renderUserRows()}
						{this.renderInviteRow()}
					</TableBody>
				</Table>

				{this.renderInviteCount()}
				{this.renderError()}
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(injectIntl(notification(Users)));