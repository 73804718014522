import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import ImageFader from 'svift-library/client/renderer/nodes/components/library/__components/image-helpers/ImageFader';
// import ImageSizerHOC from 'svift-library/client/renderer/nodes/components/library/__components/image-helpers/imageSizerHOC';
// import { resolveFileUrl, resolveFile } from 'svift-library/client/fileHelper';
import SmartImage from 'svift-library/client/image';
import css from './image.scss';

const getImageAttributes = file => {
	const attributes = {};

	if (!file) return attributes;

	const {
		description,
		credit,
		name
	} = file.meta;

	attributes.title = name.substring(0, name.lastIndexOf('.'));
	attributes.alt = attributes.title;

	if (description) attributes.alt += ` - ${description}`;
	if (credit) attributes.alt += ` | ${credit}`;

	return attributes;
}

class Image extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			srcNotFound: false
		};
	}

	static mapContextToProps = (props, state) => {
		const { theme, files, pages, blogs, extend, frontPage } = props.context;
		const { computeLink, onNavigate } = extend.Image;

		return shallowCompare({
			theme,
			files,
			pages,
			blogs,
			frontPage,
			// onClick,
			// getTitle,
			computeLink,
			onNavigate
		}, state);
	}

	render() {
		const { node } = this.props;

		// console.log(this.props.files, 'files');

		// const defaultSrc = 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg';
		const file = this.props.files.lookup[node.props.src && node.props.src.toString()]; //resolveFile(this.props.files, node.props.src); 
		// const src = (!this.state.srcNotFound && resolveFileUrl(file, this.props.urlOptions));

		// console.log(file, 'file hello');

		const computedLink = this.props.computeLink && this.props.computeLink(this.props);

		const imageAttributes = getImageAttributes(file);

		const alignmentStyles = {
			// alignItems: node.props.verticalAlignment,
			justifyContent: node.props.alignment
		};
		
		// let height; //= src === defaultSrc || 1050;
		// let width; // = src === defaultSrc || 1200;

		// if (file) {
		// 	height = file.meta.dimensions.height;
		// 	width = file.meta.dimensions.width;
		// } else {
		// 	// Fallback dimensions
		// 	height = 1050; 
		// 	width = 1250;
		// }

		const style = {
			...this.props.computedStyling,
			position: 'relative',
			width: '100%',
			maxWidth: '100%',
			objectFit: node.props.objectFit || 'cover'
			// maxWidth: 'none',
			// maxHeight: 'none',
			// flexShrink: 0,
			// flexGrow: 0
		};

		if (node.props.custom) {
			// Might be worth experimenting with object-fit and object-position css properties (couldn't make it work this time around)
			// if (node.props.objectFit /*&& notIE */) {
	
			let objectXY = ['50%', '50%']; // centered;

			if (!isNaN(node.props.objectPositionX)) {
				objectXY[0] = node.props.objectPositionX + '%';
			} 
			if (!isNaN(node.props.objectPositionY)) {
				objectXY[1] = node.props.objectPositionY + '%';
			}

			style.objectPosition = objectXY.join(' ');
			// }
	
			if (node.props.height) style.height = node.props.height + (node.props.heightType || 'px');
			if (node.props.width) style.width = node.props.width + (node.props.widthType || 'px');
		}

		// if (node.props.vertical) {
		// 	if (node.props.auto || isNaN(node.props.height)) {
		// 		// style.width = 'auto';
		// 		style.height = `${100 * (width / height)}px`;
		// 		// style.maxHeight = `${height}px`;
		// 	} else {
		// 		// style.width = 'auto';
		// 		style.height = `${node.props.height}px`;
		// 		// style.maxHeight = `${height}px`;
		// 	}
		// } else {
		// 	if (node.props.auto || isNaN(node.props.width)) {
		// 		style.width = '100%';
		// 		// style.height = `${100 * (height / width)}%`;
		// 	} else {
		// 		style.width = `${node.props.width}px`;
		// 		// style.maxWidth = '100%';
		// 		// style.height = `${100 * (height / width)}%`;
		// 	}
		// }

		if (computedLink) {
			return (
				<div className={css['img-wrapper']} style={alignmentStyles}>
					<a href={computedLink.url} title={computedLink.title} {...computedLink.anchorAttributes} style={{ width: '100%' }}>
						<SmartImage 
							file={file}
							onClick={event => {
								if (event.ctrlKey || event.metaKey || event.shiftKey) {
									return;
								}
								
								event.preventDefault();
					
								this.props.onNavigate(computedLink);
							}}
							style={style}
						/>	
					</a>
				</div>
			)
		}

		return (
			<div className={css['img-wrapper']} style={alignmentStyles}>
				<SmartImage 
					file={file}
					style={style}
				/>
			</div>
		);

		return (
			<React.Fragment>
				<img
					// {...this.props.cropProps} // Sets a ref to find the size of the element 
					ref={ref => {
						if (this.props.setImageSizerRef) this.props.setImageSizerRef.current = ref;
						if (this.props.cropProps) this.props.cropProps.ref.current = ref;
					}}
					{...imageAttributes}
					style={style}
					// circular={computedProps.preset === 'Circular'}
					// rounded={computedProps.preset === 'Rounded'}
					draggable="false"
					src={src || defaultSrc}
					onLoad={e => {
						this.setState({
							imageLoadedSuccesfully: true
						});
					}}
					onError={e => {
						this.setState({
							srcNotFound: true
						});
					}}
				/>

				<ImageFader
					file={file}
					src={src}
					imageLoadedSuccesfully={this.state.imageLoadedSuccesfully}
				/>
			</React.Fragment>
		);
	}
}

export default Image;

// export default Styling(Crop(ImageSizerHOC(Image, props => [props.files, props.node.src], props => props.node)));