import React from 'react';
import css from './preview.scss';

export default React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className={`${css['preview-container']} ${props.active ? css['active'] : ''}`}>
            {props.children}
        </div>
    );
});

/*
    1) Preview ("Live mode")
    2) When preview is selected, allow toggle between versions:


    <PreviewContainer>

    </PreviewContainer>
*/