import React, { PureComponent } from 'react';
import {
    Icon,
    Dropdown,
    DropdownHeader,
    DropdownDivider,
    DropdownMenu,
    DropdownItem,
    Loader,
    ModalContent
} from 'svift-ui';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import accordionsData from './faq-data.js';
import FAQ from './_components/FAQ';
import ContactSupport from 'components/forms/contact-support';
import ModalWrapper from 'components/modal';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import createApi from 'api';
import notification from 'components/hoc/notification';
import TermsAndConditions from 'components/TermsAndConditions';
import StepByStepButton from 'containers/dashboard/top-navigation/__components/StepByStepButton';
// import { Switch, Route } from 'react-router';
import SviftGA from 'utils/svift-ga';

const api = createApi();

const resolveAccordions = ({ accordionDescriptors, intl }) => {
    return accordionDescriptors.map(descriptor => {
        return {
            ...descriptor,
            title: intl.formatMessage({ id: descriptor.title }),
            panels: descriptor.panels.map(panel => {
                return {
                    ...panel,
                    meta: intl.formatMessage({ id: panel.meta }),
                    question: intl.formatMessage({ id: panel.question })
                };
            })
        };
    });
};

const data = accordionsData();

class SupportMenu extends PureComponent {
    constructor(props, context) {
        super(props, context);

        const accordions = resolveAccordions({ accordionDescriptors: data, intl: props.intl });

        this.state = {
            loading: false,
            FAQModalOpen: false,
            contactModalOpen: false,
            initialAccordions: accordions,
            accordions,
            openPanels: {},
            keyword: ""
        };
    }

    FAQModalOpen = () => {
        SviftGA.SUPPORT.OPEN_FAQ_STARTED();

        this.setState({ FAQModalOpen: true});
    }

    contactModalOpen = () => {
        this.setState({ contactModalOpen: true});
    }

    handleModalClose = () => {
        this.setState({ FAQModalOpen: false, contactModalOpen: false });
    }

    onSubmit = ({ subject, question }) => {
        this.setState({
            submittingContactForm: true
        }, async () => {
            try {
                SviftGA.SUPPORT.CONTACT_SUPPORT_STARTED();

                await api.submitContactForm({ 
                    subject, 
                    question, 
                    siteID: this.props.siteID,
                    siteName: this.props.siteName
                });
    
                this.setState({
                    submittingContactForm: false,
                    contactModalOpen: false
                });

                this.props.createNotification({
                    content: this.props.intl.formatMessage({ id: 'support.contact support notification' }),
                    className: 'positive',
                    position: 'topCenter',
                    icon: 'sn-question3',
                    deleteTime: 5000
                });

            } catch(e) {
                this.setState({
                    submittingContactForm: false
                });

                handleSubmitError(this.props.intl)(e);
            }
        });
    }

    search = (e) => {
        const keyword = e.target.value;
        this.setState ({ keyword: keyword, openPanels: {} })

        const accordions = keyword
            ? this.state.initialAccordions.filter(initialAccordion => {
                if (!keyword) return true;
                return initialAccordion.panels.some(({ question, meta }) => {
                    return [question.toLowerCase(), meta.toLowerCase()].some(text => text.includes(keyword));
                });
            })
            : this.state.initialAccordions;

        this.setState({accordions});
    }

    togglePanel = (panelId) => {
        const openPanels = Object.assign({}, this.state.openPanels);
        const isAlreadyOpen = openPanels[panelId];

        if (isAlreadyOpen) {
            openPanels[panelId] = false
        } else {
            openPanels[panelId] = true;
        }
        this.setState({openPanels});     
    }
    
    deleteKeyword = (e) => {
        e.preventDefault();

        this.setState({ keyword: "", accordions: this.state.initialAccordions, openPanels: {} })
    }

    render() {
        return (
            <Dropdown
                item
                id="support-menu-dropdown"
                icon={
                    <React.Fragment>
                        <Icon name="sn-question3" className="tint-color" style={{ margin: 0, fontSize: 28 }} />
                        {/* TO DO: add arrow for support dropdown */}
                        {/* <Icon
                            name="sn-arrow-down2"
                            className="primary-color"
                            style={{ fontSize: 12, marginLeft: 8, marginRight: 0 }}
                        /> */}
                    </React.Fragment>
                }
                direction="right"
                className="icon"
            >   
                <DropdownMenu style={{ right: 0, left: 'auto' }}>
                    <DropdownHeader
                        icon="sn-question4"
                        content={this.props.intl.formatMessage({ id: 'support.need help question' })}
                    />
                    
                    <DropdownDivider />

                    <StepByStepButton />

                    {/* <Switch>
                        <Route
                            exact
                            path="/dashboard/:siteid"
                            render={() => (
                                <StepByStepButton />
                            )}
                        />
                        
                        
                        <Route
                            path="/dashboard/:siteid/pages/"
                            render={() => (
                                <StepByStepButton />
                            )}
                        />

                        
                        <Route
                            path="/dashboard/:siteid/design/"
                            render={() => (
                                <StepByStepButton />
                            )}
                        />
                    </Switch> */}

                    <DropdownItem onClick={this.FAQModalOpen}>
                        <Icon name="sn-bubbles10" />
                        <FormattedMessage id="support.faq title" />
                    </DropdownItem>

                    <ModalWrapper
                        open={this.state.FAQModalOpen}
                        handleClose={this.handleModalClose} 
                        header="support.faq title"
                        style={{ maxWidth: 640 }}
                    >
                        <FAQ
                            keyword = {this.state.keyword}
                            search = {this.search}
                            togglePanel = {this.togglePanel} 
                            deleteKeyword = {this.deleteKeyword}
                            accordions = {this.state.accordions}
                            openPanels = {this.state.openPanels}
                        />
                    </ModalWrapper>

                    <TermsAndConditions>
                        <DropdownItem>
                            <Icon name="sn-vcard" />
                            <FormattedMessage id="terms.customer and user conditions title" />
                        </DropdownItem>
                    </TermsAndConditions>

                    <DropdownItem onClick={this.contactModalOpen}>
                        <Icon name="sn-lifebuoy" />
                        <FormattedMessage id="support.contact support title" />
                    </DropdownItem>

                    <ModalWrapper 
                        open={this.state.contactModalOpen} 
                        handleClose={this.handleModalClose} 
                        header="support.contact support title"
                        style={{ maxWidth: 520 }}
                    >
                        {this.state.submittingContactForm 
                            ?
                                <Loader size="massive" style={{ marginTop: 24, marginBottom: 32 }}> 
                                    <FormattedMessage id="general.sending loader title" />
                                </Loader>
                            :
                                <ContactSupport                                  
                                    onSubmit={this.onSubmit}
                                />
                        }
                    </ModalWrapper>
                </DropdownMenu>
            </Dropdown>
        );
    };
}

const mapStateToProps = (state) => {
    const siteID = state.sites.params.siteid;
    const site = state.sites.sites[siteID];
    
	return {
        siteid: state.sites.params.siteid,
        siteName: site && site.name,
		pathname: state.router.location.pathname,
		user: state.user
	};
};

export default connect(mapStateToProps)(injectIntl(notification(SupportMenu)));