import React, {PureComponent} from 'react';
import { Container, Icon, Menu } from 'svift-ui';
import OptionPanel from 'components/option-panel';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import themeActions from 'store/actions/sites/themes';
import { connect } from 'react-redux';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import { tooltip } from 'components/tooltip/Tooltip';
import Dropdown from 'components/option-panel/options/Dropdown';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Slider from 'components/option-panel/options/Slider';
import Margin from 'components/option-panel/options/advanced/options/Margin';

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
const createDummyContentFromPreset = presetTitle => {
	return `elements.${presetTitle.toLowerCase()} text dummy content`;
}
class OverviewText extends PureComponent {
    constructor(props){
        super(props);
        this.state={
                    selectedPresetOption: null
                    }
        this.elementResolver = (key) => {
			switch (key) {
				case 'title':
				case 'h1':
					return 'h1';
				case 'h2':
					return 'h2';
				case 'h3':
					return 'h3';
				case 'h4':
					return 'h4';
				case 'h5':
					return 'h5';
				case 'h6':
					return 'h6';
				case 'link':
					return 'a';
				default:
					return 'p';
			}
		};
        // this.optionTooltip = tooltip.call(this);

        this.optionTooltipRef = React.createRef();
    }

    

    //selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });
    //selectPreset = (preset) => this.setState({ selectedPreset: preset || null, selectedPresetOption: null });

    getPresetOptions = (presetType, presetTitle, theme, computedStyles, text) => {
        const { intl } = this.props;
        //const paddingBottom = props.preset.paddingBottom;
        
        const font = text.textProperty.font;
        const color = text.textProperty.color;
        const fontWeight = text.textProperty.fontWeight; // Convert to variant later
        const fontSize = text.textProperty.fontSize;
        const lineHeight = text.textProperty.lineHeight;
        const letterSpacing = text.textProperty.letterSpacing;
        const margin = text.textProperty.margin;
        const noop = () => undefined;
        const noLetterSpacing = typeof letterSpacing === 'undefined';
        
        const options = [
            <span key="text-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-typography" /> <FormattedMessage id="elements.text title" />: <FormattedMessage id={createTranslationFromKey(presetTitle)} />
            </span>,
            <Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
                <Dropdown
                    unwrapped
                    key="font-type"
                    icon="sn-typography"
                    options={Object.keys(theme.fonts).map(fontKey => {
                        return {
                            text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${theme.fonts[fontKey].family})`,
                            value: fontKey
                        };
                    })}
                    onChange={(newFont) => {
                        this.props.updatePreset( presetType, text.id, 'font', newFont.value );
                    }}
                    value={font}

                    hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${theme.fonts[font].family})`}

                    option="font-type"
                    selectedOption={this.props.selectedPresetOption}
                    onToggle={(select) => {
                        this.props.selectPresetOptionfunc(select);
                    }}
                />

                <Dropdown
                    unwrapped
                    key="font-weight"
                    options={Array(9).fill().map((_, i) => {
                        const fontWeight = (i + 1) * 100;
                        
                        return {
                            text: fontWeight,
                            value: fontWeight
                        };
                    })}
                    onChange={(newFontWeight) => {
                        this.props.updatePreset( presetType, text.id, 'fontWeight', newFontWeight.value );
                        // props.updatePresetOption(props.presetTitle, 'fontWeight', newFontWeight.value);
                    }}
                    value={fontWeight}

                    hoverContent={intl.formatMessage({ id: 'options.font weight' })}

                    option="font-weight"
                    selectedOption={this.props.selectedPresetOption}
                    onToggle={(select) => {
                        this.props.selectPresetOptionfunc(select);
                        //e.stopPropagation();
                    }}
                />
            </Menu>,
            <ColorPicker
                title={intl.formatMessage({ id: 'options.font color title' })}
                key="color-picker"
                icons={['sn-typography']}
                colors={theme.colors}
                onChange={(newColor) => {
                    this.props.updatePreset( presetType, text.id, 'color', newColor );
                }}
                value={color}

                option="color-picker"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOptionfunc(select);
                    e.stopPropagation();
                }}
            />,
            <Slider
                key="font-size"
                icons={['sn-font-size', 'sn-arrow-resize4']}
                title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
                onChange={(newValue) => {
                   this.props.updatePreset(presetType, text.id, 'fontSize', newValue / 100);
                }}
                value={fontSize * 100}
                min={25}
                max={350}

                option="fontSize"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOptionfunc(select);
                    e.stopPropagation();
                }}
            />,
            <Slider
                key="line-height"
                icons={['sn-line_weight', 'sn-arrow-resize4']}
                title={`${intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${computedStyles.lineHeight})`}
                onChange={(newValue) => {
                    this.props.updatePreset(presetType, text.id, 'lineHeight', newValue / 100);
                }}
                value={lineHeight * 100}
                min={50}
                max={300}

                option="options.lineheight title"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOptionfunc(select);
                    e.stopPropagation();
                }}
            />,
            <Slider
                key="letter-spacing"
                icons={['sn-typography', 'sn-arrow-resize3']}
                title={`${intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
                onChange={(newValue) => {
                    this.props.updatePreset(presetType, text.id, 'letterSpacing', newValue);
                }}
                value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
                min={0}
                max={50}
                optIn
                onClear={noLetterSpacing ? noop : () => {
                    this.props.updatePreset(presetType, text.id, 'letterSpacing', null);
                }}

                option="letterSpacing"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOptionfunc(select);
                    e.stopPropagation();
                }}
            />,
            <Margin
					key="margin"
                    value={margin}
                    preset={text.textProperty}
                    theme={theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.props.updatePreset(presetType, text.id, 'margin', {
									marginBottom: 1
								});
							} else {
								this.props.updatePreset(presetType, text.id, 'margin', {
									generalMargin: newValue / 100
								});
							}

							return;
						}

						const newMargin = {
							...margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') {
							newMargin.marginBottom = 1;
						}

						this.props.updatePreset(presetType, text.id, 'margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOptionfunc(select);
						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>
        ];

        return options;
    };

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
            this.props.clearSelectedPreset(null)
			// this.props.clearSelectPreset();
		}
    }

    // onClickOutside = (e) => {
    //     const {  presetTitle, selectedPreset, selectPresetfunc } = this.props;
    //     if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

    //     if (presetTitle === selectedPreset) {
            
    //         this.props.clearSelectedPreset(null)
    //     }
    // }

    render(){
        const { theme, text, presetTitle, selectedPreset, selectPresetfunc, selectedPresetOption, selectPresetOptionfunc} = this.props;

        const presetType = 'text';
        const computedStyles = computePreset(text.textProperty, theme);
        const selected = presetTitle === selectedPreset;
        const className = selected ? 'preset-target active-preset-target' : 'preset-target';
        const element = React.createElement(this.elementResolver(presetTitle),
            { style: computedStyles },
            <FormattedMessage id={createDummyContentFromPreset(presetTitle)} />
        );
        
        return(
 
            <div className="preset-section">
                <Container fluid className="preset-target" textAlign="left"
                    selected={selected} //true or false
                    onClick={selectPresetfunc}
                    // ref={this.optionTooltip.setTarget}
                    ref={this.optionTooltipRef}
                    className={className}
                >
                    <OptionPanel
                        icon="sn-bucket"
                        title="elements.text title"
                        target={this.optionTooltipRef.current}
                        // target={this.optionTooltip.getTarget()}
                        open={selected}
                        options={this.getPresetOptions(presetType, presetTitle, theme, computedStyles, text)} //show all options
                    />
                    {element}
                </Container>
            </div>
        )
    }
}

Text.propTypes = {
    text: PropTypes.object,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};


export default connect(null, mapDispatchToProps)(injectIntl(onClickOutside(OverviewText)));