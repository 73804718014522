import React, { useEffect, useState, useLayoutEffect, useRef, useMemo, useImperativeHandle } from 'react';
import { createPortal, findDOMNode } from 'react-dom';
import css from './tooltip.scss';

/*
		<div style={tooltipContainerTopStyle}>
			<div style={tooltipFrameStyle}>
				<div style={tooltipContentStyle}>
					Tooltip up here!
				</div>
				// bottom left pointer 
				<div style={tooltipPointerBottomLeftStyle} />
				<div style={tooltipPointerBottomLeftMaskStyle} />
				// bottom center pointer 
				<div style={tooltipPointerBottomCenterStyle} />
				<div style={tooltipPointerBottomCenterMaskStyle} />
				// bottom right pointer 
				<div style={tooltipPointerBottomRightStyle} />
				<div style={tooltipPointerBottomRightMaskStyle} />
			</div>
		</div>
*/

/*
	<Tooltip
		open
		target
		repositionStrategy="[push-away | new-direction]"
		repositionThreshold={10}
		distanceFromTarget={10}
		customPosition={(contentRect, targetRect) => {

		}}
	/>
*/

/*
	Position calculation flow:

		0) Calculate default position (including transformations/options provided by the caller)
		1) Check if there is room for the tooltip
		2) Apply repositioning strategy - push away from edge or change direction?
*/

const getPointerStyles = (position) => {
	switch (position) {
		case 'right top': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					top: '4px',
					left: '0px'
				},
				pointer: {
					top: '8px',
					left: '-4px'
				},
				animation: 'fadeInRight',
			}
		}

		case 'right center': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					top: '50%',
					marginTop: '-8px',
					left: '0px'
				},
				pointer: {
					top: '50%',
					marginTop: '-4px',
					left: '-4px'
				},
				animation: 'fadeInRight',
			};
		}

		case 'right bottom': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					bottom: '4px',
					left: '0px'
				},
				pointer: {
					bottom: '8px',
					left: '-4px'
				},
				animation: 'fadeInRight',
			};			
		}

		case 'left top': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					top: '4px',
					right: '0px'
				},
				pointer: {
					top: '8px',
					right: '-4px'
				},
				animation: 'fadeInLeft',
			};
		}

		case 'left center': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					top: '50%',
					marginTop: '-8px',
					right: '0px'
				},
				pointer: {
					top: '50%',
					marginTop: '-4px',
					right: '-4px'
				},
				animation: 'fadeInLeft',
			};
		}

		case 'left bottom': {
			return {
				mask: {
					width: '8px',
					height: '16px',
					bottom: '4px',
					right: '0px'
				},
				pointer: {
					bottom: '8px',
					right: '-4px'
				},
				animation: 'fadeInLeft',
			};
		}

		case 'bottom left': {
			return {
				mask: {
					width: '16px',
					height: '8px',
					top: '0px',
					left: '4px'
				},
				pointer: {
					top: '-4px',
					left: '8px'
				},
				animation: 'fadeInUp',
			};
		}

		case 'bottom center': {
			return {
				mask: {
					width: '16px',
					height: '8px',
					top: '0px',
					left: '50%',
					marginLeft: '-8px'
				},
				pointer: {
					top: '-4px',
					left: '50%',
					marginLeft: '-4px'
				},
				animation: 'fadeInUp',
			};
		}

		case 'bottom right': {
			return {
				mask: {
					width: '16px',
					height: '8px',
					top: '0px',
					right: '4px'
				},
				pointer: {
					top: '-4px',
					right: '8px'
				},
				animation: 'fadeInUp',
			};
		}

		case 'top left': {
			return {
				mask: {
					width: '16px',
					height: '8px',
					bottom: '0px',
					left: '4px'
				},
				pointer: {
					bottom: '-4px',
					left: '8px'
				},
				animation: 'fadeInDown',
			};
		}

		case 'top right': {
			return {
				mask: {
					width: '16px',
					height: '8px',
					bottom: '0px',
					right: '4px'
				},
				pointer: {
					bottom: '-4px',
					right: '8px'
				},
				animation: 'fadeInDown',
			};
		}
		
		case 'top center':
		default: {
			return {
				mask: {
					width: '16px',
					height: '8px',
					bottom: '0px',
					left: '50%',
					marginLeft: '-8px'
				},
				pointer: {
					bottom: '-4px',
					left: '50%',
					marginLeft: '-4px'
				},
				animation: 'fadeInDown',
			};
		}
	}
}

// const DOMRectToPlainObject = rect => {
// 	return {
// 		top: rect.top,
// 		right: rect.right,
// 		bottom: rect.bottom,
// 		left: rect.left,
// 		width: rect.width,
// 		height: rect.height,
// 		x: rect.x,
// 		y: rect.y
// 	};
// }

const calculatePosition = (...args) => {
	const [position = 'top center', clearance = 10, contentRect, targetRect, repositioning] = args;

	let left;
	let right;
	let top;
	let bottom;

	switch (position) {
		case 'bottom left': {
			left = targetRect.left; 
			top = targetRect.bottom + window.pageYOffset + clearance;

			if (!repositioning) {
				const noRoomRight = (contentRect.width + targetRect.left) > document.body.clientWidth;
				const noRoomBottom = (document.body.scrollHeight - (window.pageYOffset + targetRect.bottom)) < (contentRect.height - clearance);

				if (noRoomRight && noRoomBottom) {
					return calculatePosition('top right', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('bottom right', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('top left', ...args.slice(1), true);
				}
			}

			break;
		}

		case 'bottom center': {
			left = targetRect.left + (targetRect.width / 2) - (contentRect.width / 2);
			top = targetRect.bottom + window.pageYOffset + clearance;

			if (!repositioning) {
				const noRoomRight = (contentRect.width / 2 - targetRect.width / 2) > (document.body.clientWidth - (targetRect.left + targetRect.width));
 				const noRoomLeft = (contentRect.width / 2 - targetRect.width / 2) > targetRect.left;
				const noRoomBottom = (document.body.scrollHeight - (window.pageYOffset + targetRect.bottom)) < (contentRect.height - clearance);

				if (noRoomLeft && noRoomRight && !noRoomBottom) {
					break; // If the tooltip is so large it doesn't fit in any direction, we'll do nothing
				} else if (noRoomLeft && noRoomBottom) {
					return calculatePosition('top left', ...args.slice(1), true);
				} else if (noRoomRight && noRoomBottom) {
					return calculatePosition('top right', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('bottom left', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('top center', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('bottom right', ...args.slice(1), true);
				}
			}

			break;
		}

		case 'bottom right': {
			right = document.body.clientWidth - targetRect.right;
			top = targetRect.bottom + window.pageYOffset + clearance;

			if (!repositioning) {
				const noRoomLeft = contentRect.width > (targetRect.left + targetRect.width);
				const noRoomBottom = (document.body.scrollHeight - (window.pageYOffset + targetRect.bottom)) < (contentRect.height - clearance);

				if (noRoomLeft && noRoomBottom) {
					return calculatePosition('top left', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('bottom left', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('top right', ...args.slice(1), true);
				}
			}

			break;
		}

		case 'right bottom': {
			left = targetRect.right + clearance;
			bottom = document.body.offsetHeight - targetRect.bottom - window.pageYOffset;

			if (!repositioning) {
				const noRoomRight = (document.body.clientWidth - targetRect.right) < (contentRect.width - clearance);
				const noRoomTop = contentRect.height > (targetRect.bottom + window.pageYOffset);

				if (noRoomTop && noRoomRight) {
					return calculatePosition('left top', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('left bottom', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('right top', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'right center': {
			left = targetRect.right + clearance;
			top = targetRect.bottom - (targetRect.height / 2) - (contentRect.height / 2) + window.pageYOffset;

			if (!repositioning) {
				const noRoomRight = (document.body.clientWidth - targetRect.right) < (contentRect.width - clearance);
				const noRoomTop = ((contentRect.height / 2) - (targetRect.height / 2)) > (targetRect.top + window.pageYOffset);
				const noRoomBottom = ((contentRect.height / 2) - (targetRect.height / 2)) > (document.body.scrollHeight - (window.pageYOffset + targetRect.top + targetRect.height));

				if (noRoomTop && noRoomBottom && !noRoomRight) {
					break; // If the tooltip is so large it doesn't fit in any direction, we'll do nothing
				} else if (noRoomTop && noRoomRight) {
					return calculatePosition('left top', ...args.slice(1), true);
				} else if (noRoomBottom && noRoomRight) {
					return calculatePosition('left bottom', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('right top', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('left center', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('right bottom', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'right top': {
			left = targetRect.right + clearance;
			top = targetRect.top + window.pageYOffset;

			if (!repositioning) {
				const noRoomRight = (document.body.clientWidth - targetRect.right) < (contentRect.width - clearance);
				const noRoomBottom = (top + contentRect.height) > document.body.scrollHeight;

				if (noRoomBottom && noRoomRight) {
					return calculatePosition('left bottom', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('left top', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('right bottom', ...args.slice(1), true);
				}
			}

			break;
		}

		case 'left bottom': {
			right = document.body.clientWidth - targetRect.left + clearance;
			bottom = document.body.offsetHeight - targetRect.bottom - window.pageYOffset;

			if (!repositioning) {
				const noRoomLeft = (contentRect.width - clearance) > targetRect.left;
				const noRoomTop = (targetRect.top + window.pageYOffset) < targetRect.height;

				if (noRoomTop && noRoomLeft) {
					return calculatePosition('right top', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('right bottom', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('left top', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'left center': {
			right = document.body.clientWidth - targetRect.left + clearance;
			top = targetRect.bottom - (targetRect.height / 2) - (contentRect.height / 2) + window.pageYOffset;

			if (!repositioning) {
				const noRoomLeft = (contentRect.width - clearance) > targetRect.left;
				const noRoomTop = ((contentRect.height / 2) - (targetRect.height / 2)) > (targetRect.top + window.pageYOffset);
				const noRoomBottom = ((contentRect.height / 2) - (targetRect.height / 2)) > (document.body.scrollHeight - (window.pageYOffset + targetRect.top + targetRect.height));

				if (noRoomTop && noRoomBottom && !noRoomLeft) {
					break; // If the tooltip is so large it doesn't fit in any direction, we'll do nothing
				} else if (noRoomTop && noRoomLeft) {
					return calculatePosition('right top', ...args.slice(1), true);
				} else if (noRoomBottom && noRoomLeft) {
					return calculatePosition('right bottom', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('left top', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('right center', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('left bottom', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'left top': {
			right = document.body.clientWidth - targetRect.left + clearance;
			top = targetRect.top + window.pageYOffset;

			if (!repositioning) {
				const noRoomLeft = (contentRect.width - clearance) > targetRect.left;
				const noRoomBottom = (top + contentRect.height) > document.body.scrollHeight;

				if (noRoomBottom && noRoomLeft) {
					return calculatePosition('right bottom', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('right top', ...args.slice(1), true);
				} else if (noRoomBottom) {
					return calculatePosition('left bottom', ...args.slice(1), true);
				}
			}
			
			break;
		}

		case 'top left': {
			left = targetRect.left; 
			top = targetRect.top + window.pageYOffset - contentRect.height - clearance;
	
			if (!repositioning) {
				const noRoomRight = (contentRect.width + targetRect.left) > document.body.clientWidth; 
				const noRoomTop = contentRect.height > (targetRect.top + window.pageYOffset - clearance);

				if (noRoomRight && noRoomTop) {
					return calculatePosition('bottom right', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('top right', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('bottom left', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'top right': {
			right = document.body.clientWidth - targetRect.right;
			top = targetRect.top + window.pageYOffset - contentRect.height - clearance;

			if (!repositioning) {
				const noRoomLeft = contentRect.width > (targetRect.left + targetRect.width);
				const noRoomTop = contentRect.height > (targetRect.top + window.pageYOffset - clearance);

				if (noRoomLeft && noRoomTop) {
					return calculatePosition('bottom left', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('top left', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('bottom right', ...args.slice(1), true);
				}
			}

			break;
		}
		case 'top center':
		default: {
			left = targetRect.left + (targetRect.width / 2) - (contentRect.width / 2);
			top = targetRect.top + window.pageYOffset - contentRect.height - clearance;

			if (!repositioning) {
				const noRoomRight = (contentRect.width / 2 - targetRect.width / 2) > (document.body.clientWidth - (targetRect.left + targetRect.width));
 				const noRoomLeft = (contentRect.width / 2 - targetRect.width / 2) > targetRect.left;
				const noRoomTop = contentRect.height > (targetRect.top + window.pageYOffset - clearance);

				if (noRoomLeft && noRoomRight && !noRoomTop) {
					break; // If the tooltip is so large it doesn't fit in any direction, we'll do nothing
				} else if (noRoomLeft && noRoomTop) {
					return calculatePosition('bottom left', ...args.slice(1), true);
				} else if (noRoomRight && noRoomTop) {
					return calculatePosition('bottom right', ...args.slice(1), true);
				} else if (noRoomLeft) {
					return calculatePosition('top left', ...args.slice(1), true);
				} else if (noRoomTop) {
					return calculatePosition('bottom center', ...args.slice(1), true);
				} else if (noRoomRight) {
					return calculatePosition('top right', ...args.slice(1), true);
				}
			}
		}
	}

	return {
		position,
		styles: {
			position: 'absolute',
			left: !repositioning ? left : Math.max(clearance, left),
			top: !repositioning ? top : Math.max(clearance, top),
			right: !repositioning ? right : Math.max(clearance, right),
			bottom: !repositioning ? bottom : Math.max(clearance, bottom)
		}
	};
}

const addElement = (id, attributes) => {
	const element = document.createElement('div');

	element.setAttribute('data-type', 'tooltip-container');
	
	if (id) element.setAttribute('data-id', id);

	if (attributes) {
		Object.entries(attributes).forEach(([key, value]) => {
			element.setAttribute(key, value);
		});
	}	

    document.body.appendChild(element);

    return element;
}

const removeElement = element => {
    document.body.removeChild(element);
}

const usePortal = (id, attributes) => {
	const [containerElement, setContainerElement] = useState(null);

	useLayoutEffect(() => {
		const element = addElement(id, attributes);

		setContainerElement(element);
		
		return () => removeElement(element);
	}, []);

	return containerElement;
};

const Tooltip = React.forwardRef(({ rect: targetRect, x, children, id, position, clearance, popup, style, zIndex, nodeAttributes, shadeColor, tintColor, secondaryFont, noPadding }, ref) => {
	const [contentRect, setContentRect] = useState(null);
	const containerElement = usePortal(id, nodeAttributes);
	const contentRef = useRef();

	useImperativeHandle(ref, () => {
		return contentRef;
	});

	useLayoutEffect(() => {
		if (contentRef.current) {
			// Known shortcoming: If tooltip contents change size calculations will likely be off beacuse the rect doesn't update
			setContentRect(contentRef.current.getBoundingClientRect());
		}
	}, [targetRect, containerElement, x]);

	const { styles: contentStyle, position: finalPosition } = useMemo(() => {
		if (!contentRect) {
			return {
				styles: {
					...style,
					display: 'inline-block',
					opacity: 0
				}
			};
		}

		return calculatePosition(position, clearance, contentRect, targetRect);
	}, [contentRect, targetRect]);

	if (!containerElement) return null;

	const contentStyleWithZIndex = {
		...contentStyle,
		...style,
		zIndex
	};

	if (popup) {
		const { pointer, mask, animation } = getPointerStyles(finalPosition);

		return createPortal(
			<div ref={contentRef} style={contentStyleWithZIndex}>
				<div
					className={`tooltip ${css['tooltip']} ${css['animated']} ${css[animation]} ${css['ultra-fast']}`}
					style={{
						color: shadeColor ? shadeColor : 'black',
						backgroundColor: tintColor ? tintColor : 'white',
						border: `1px solid ${shadeColor ? shadeColor : 'grey'}`
					}}
				>
					{/* tooltip pointer */}
					<div
						className={`tooltip-pointer ${css['tooltip-pointer']}`}
						style={{
							...pointer,
							backgroundColor: tintColor ? tintColor : 'white',
							border: `solid 1px ${shadeColor ? shadeColor : 'grey'}`
							// boxShadow: `inset 0 0 0 1px ${shadeColor ? shadeColor : 'grey'}`
						}}
					/>
					{/* tooltip pointer mask */}
					<div
						className={`tooltip-pointer-mask ${css['tooltip-pointer-mask']}`}
						style={{
							...mask,
							backgroundColor: tintColor ? tintColor : 'white'
						}}
					/>
					{/* tooltip content */}
					<div
						className={`tooltip-content ${!noPadding ? css['tooltip-content'] : (css['tooltip-content'], css['no-padding'])}`}
						style={{
							fontFamily: secondaryFont ? secondaryFont : 'Ubuntu, Arial, Helvetica, sans-serif'
						}}
					> 
						{children} 
					</div>
				</div>
			</div>,
			containerElement
		);
	}

	return createPortal(
		<div ref={contentRef} style={contentStyleWithZIndex}>
			{children}
		</div>,
		containerElement
	);
});

const TooltipRectProvider = React.forwardRef(({ target, disabled, open, style, children, id, popup, hover, position, clearance, zIndex, nodeAttributes, shadeColor, tintColor, secondaryFont, noPadding }, ref) => {
	const [rect, setRect] = useState(null);
	const [hovered, setHovered] = useState(false);
	const [x, forceUpdate] = useState(0);
	const tooltipRef = useRef();

	useImperativeHandle(ref, () => ({
		forceUpdate: () => forceUpdate(x + 1),
		getContainer: () => tooltipRef.current.current // Has to be a better way o_O
	}));

	useLayoutEffect(() => {
		if (target) {
			let targetEl = target.getBoundingClientRect ? target : findDOMNode(target);
			const nextRect = targetEl.getBoundingClientRect();
			const keys = ['top', 'left', 'bottom', 'right', 'height', 'width', 'x', 'y'];

			const rectChanged = !rect || keys.some(key => nextRect[key] !== rect[key]);
	
			if (rectChanged) setRect(nextRect);
		}
	}, [open, hovered, x]);

	useEffect(() => {
		if (open) {
			const onScroll = () => {
				forceUpdate(x => x + 1);
			};
	
			window.addEventListener('scroll', onScroll, true);
	
			return () => window.removeEventListener('scroll', onScroll, true)
		}
	}, [open, hovered])

	useEffect(() => {
		if (hover) {
			let mouseLeaveListener;
			let mouseEnterListener;

			if (target) {
				let targetEl = target.getBoundingClientRect ? target : findDOMNode(target);

				mouseEnterListener = targetEl.addEventListener('mouseenter', e => {
					setHovered(true);
				});
				
				mouseLeaveListener = targetEl.addEventListener('mouseleave', e => {
					setHovered(false);
				});
			}
	
			return () => {
				if (mouseLeaveListener) targetEl.removeEventListener('mouseleave', mouseLeaveListener);
				if (mouseEnterListener) targetEl.removeEventListener('mouseenter', mouseEnterListener);
				// if (mouseOverListener) target.removeEventListener('mouseover', mouseOverListener);
			}
		}
	}, [target, /*hovered*/]);

	if (!rect) return null;
	if ((!open && !hovered) || disabled) return null;

	return (
		<Tooltip
			x={x}
			ref={tooltipRef}
			rect={rect}
			id={id}
			popup={popup}
			position={position}
			clearance={clearance}
			style={style}
			noPadding={noPadding}
			zIndex={zIndex}
			nodeAttributes={nodeAttributes}
			tintColor={tintColor}
			shadeColor={shadeColor}
			secondaryFont={secondaryFont}
		>
			{children}
		</Tooltip>
	);
});

/*
	Reconfigure to use imperative handle for better performance (low priority)

	^ would ensure the tooltip refreshes rather than the parent container (which could potentially cause lag)
*/
const useTooltip = () => {
	const ref = useRef();
	const [refFound, setRefFound] = useState();
	useLayoutEffect(() => {
		if (!refFound && ref.current) setRefFound(true);
	});

	return ref;
};
const DefaultTooltip = React.forwardRef((props, ref) => <TooltipRectProvider {...props} clearance={10} popup hover ref={ref} />);

export { DefaultTooltip as Popup, useTooltip };
export default TooltipRectProvider;