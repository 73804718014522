import React, { PureComponent } from 'react';
import { Icon, Message } from 'svift-ui';
import { FormattedMessage } from 'react-intl';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import BackButton from 'containers/dashboard/side-navigation/sidenavigation-content/__components/BackButton';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { themesSelector } from 'store/selectors/sites/themes';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const themes = themesSelector(state, siteid);

	return {
		siteid,
		defaultTheme: themes[state.sites.sites[siteid].activeTheme]._id,
	};
}

class SideNavBlogs extends PureComponent {
	renderBackButton() {
		const { siteid, defaultTheme, intl } = this.props;

		return (
			<BackButton
				text={intl.formatMessage({ id: 'design.back to design' })}
				route={`/dashboard/${siteid}/design/${defaultTheme}`}
			/>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderBackButton()}

				<PortalHook id="editor-sidenav-portal" />

				<PortalHook id="building-blocks-sidenav-portal" />
				
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(SideNavBlogs));
