import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import Tooltip from 'svift-library/client/tooltip';
import createDebounce from 'svift-library/debounce';
import CollisionStack from './drop-zone/collision-stack/CollisionStack';
import dndInterface from './interface';

// Higher === more priority
const zoneHierachy = {
    GridEscape: 0,
    RowEscape: 1,
    ColumnEscape: 2,
    ColumnElementEscape: 3,
    Grid: 4,
    Row: 5,
    RowHorizontal: 6,
    ColumnInner: 7,
    ColumnElement: 8,
    Column: 9
};

const handleZoneDuplicates = zones => {
    const found = {};

    return zones.filter((zone, i) => {
        const isLast = i === zones.length - 1;

        if (!zone.escape && !isLast) return false;
        if (found[zone.handler]) return false;
        found[zone.handler] = true;

        return true;
    });
}

const orderZones = zones => {
    return zones.sort((z1, z2) => {
        return zoneHierachy[z1.type] > zoneHierachy[z2.type] ? 1 : -1;
    });
}

export default () => {
    const debounce = createDebounce(0); 
    const listeners = new Set();
    let collisionStackTimeout;
    let lastCollisionStack;
    let collisionStackFrozen = false;
    let zoneListeners = {};
    let activeZone;
    let hovered = [];
    let batch = [];
    let dragging = false;

    const methods = {
        isFrozen: () => collisionStackFrozen,
        freeze: () => {
            collisionStackFrozen = true;
            
            listeners.forEach(listener => listener(lastCollisionStack));
        },
        unfreeze: () => {
            collisionStackFrozen = false;
            lastCollisionStack = null;

            listeners.forEach(listener => listener(null));
            
            methods.refresh();
        },
        subscribeDropZone: (id, fn) => {
            zoneListeners[id] = fn;

            return () => delete zoneListeners[id];
        },
        setActiveZone: zone => {
            const previousZoneListener = zoneListeners[activeZone && (activeZone.id + activeZone.nodeID)];
            const zoneListener = zoneListeners[zone && (zone.id + zone.nodeID)];
            activeZone = zone;

            if (previousZoneListener && previousZoneListener !== zoneListener) previousZoneListener(false); // Tell last active zone to disappear
            if (zoneListener) zoneListener(true); // Tell zone to show
        },
        getActiveZone: () => activeZone,
        beginDrag: () => {
            dragging = true;
        },
        isDragging: () => dragging,
        endDrag: (droppedItem, dropHandlers) => {
            dragging= false;
            hovered = [];
            batch = [];
            collisionStackFrozen = false;

            // console.log('end drag');

            if (activeZone) {
                if (activeZone.nodeID !== droppedItem.id) { // Can't drop on itself 
                    const handler = dropHandlers[activeZone.handler];

                    // console.log('handler')
    
                    handler(droppedItem, activeZone, activeZone.updateState);
                }; 
            }

            // console.log(activeZone, 'a zone')

            // debugger;

            methods.setActiveZone(null)
            methods.refresh();
        },
        register: (nodeID, dropzones, getParentPath, updateState) => {
            batch.push(() => {
                for (let i = 0; i < hovered.length; i++) {
                    if (hovered[i].nodeID === nodeID) {
                        hovered.splice(i, 1);
                        i--;
                    }
                }

                hovered.push(...dropzones.map(dropzone => ({ ...dropzone, nodeID, getParentPath, updateState }))); 
            });

            // We debounce so if multiple components register hover zones simultaneously, batch will still only trigger once
            debounce(methods.applyBatch); 
        },
        applyBatch: () => {
            if (batch.length === 0) return;

            batch.forEach(action => action());

            batch = [];

            methods.refresh();

            // console.log(hovered.slice(), 'hovered zones')
        },
        // Refresh takes currently gathered state and notifies listeners that need to respond to changes in that state
        refresh: () => {
            if (methods.isFrozen()) return;

            const sortedHoveredZones = orderZones(hovered);

            const collisionStack = handleZoneDuplicates(sortedHoveredZones); 

            if (collisionStack.length > 1) {
                let collistionStackUnchanged;

                if (lastCollisionStack) {
                    collistionStackUnchanged = collisionStack.length === lastCollisionStack.length && collisionStack.every((zone, i) => zone.id === lastCollisionStack[i].id);
                }

                if (!collistionStackUnchanged) {
                    clearTimeout(collisionStackTimeout);

                    collisionStackTimeout = setTimeout(methods.freeze, 500); // How long to wait before showing collision stack

                    lastCollisionStack = collisionStack;
                }
            } else {
                listeners.forEach(listener => listener(null));

                clearTimeout(collisionStackTimeout);
                
                lastCollisionStack = null;
            }

            const lastZone = collisionStack[collisionStack.length - 1]

            methods.setActiveZone(lastZone);
        },
        renderCollisionStack: () => {
            const [collisionStack, setCollisionStack] = useState(null);
            const [node, setNode] = useState(null);
            const [nodePositioned, setNodePositioned] = useState(1);
            const cursorCoords = useRef(null);

            // Add node that will be positioned based on cursor position
            useLayoutEffect(() => {
                const node = document.createElement('div');

                node.className = 'collision-stack-target';
                node.style.display = 'none';
                node.style.position = 'fixed';
                node.style['z-index'] = 100000;

                document.body.appendChild(node);

                setNode(node);

                return () => {
                    document.body.removeChild(node);
                };
            }, []);
            
            // Record cursor x and y to handle positioning of collision stack tooltip
            useEffect(() => {
                const isFirefox = navigator.userAgent.indexOf("Firefox") > 0;

                if (!isFirefox) {
                    const listener = e => {
                        cursorCoords.current = {
                            x: e.clientX,
                            y: e.clientY
                        };
                    };

                    document.addEventListener('drag', listener);

                    return () => document.removeEventListener('drag', listener);
                } else { 
                    // Firefox drag listener doesn't work, clientX and Y are always 0 - below is the workaround
                    const firefoxListener = e => {
                        cursorCoords.current = {
                            x: e.clientX || e.pageX,
                            y: e.clientY || e.pageY
                        };
                    };
    
                    document.addEventListener('dragover', firefoxListener);

                    return () => document.removeEventListener('dragover', firefoxListener);
                }
            }, []);

            // Listen to collision stack changes
            useLayoutEffect(() => {
                const handleNewStack = stack => {
                    if (collisionStack && stack) {
                        if (collisionStack.length !== stack.length) return;
                        if (collisionStack.every((zone, i) => zone.id === stack[i].id)) return;   
                    }

                    // console.log(stack, 'new stack boi');

                    setCollisionStack(stack);
                };

                listeners.add(handleNewStack);

                return () => listeners.delete(handleNewStack);
            }, [collisionStack]);

            useLayoutEffect(() => {
                if (node) {
                    // console.log(cursorCoords, 'cusor coors')
                    node.style.display = 'initial';
                    node.style.top = `${cursorCoords.current.y}px`;
                    node.style.left = `${cursorCoords.current.x}px`;

                    // setTimeout(() => setCollisionStack([...collisionStack]), 0)
    
                    if (!collisionStack) {
                        node.style.display = 'none';
                    }

                    // since we are imperatively changing the node dimensions we need a way to tell tooltip to update itself
                    setNodePositioned(x => x + 1);
                }
            }, [collisionStack])

            if (!node || !collisionStack) return null;

            return ReactDOM.createPortal(
                <Tooltip
                    key={nodePositioned}
                    target={node}
                    bounceCheckStrategy="exact"
                    open
                    clearance={0}
                    zIndex={999998} // just below DragLayer
                >
                    <CollisionStack 
                        target={node}
                        zones={collisionStack} 
                        dndInterface={dndInterface} 
                        dragState={methods}
                    />
                </Tooltip>,
                node
            );
        }
    };

    return methods;
}