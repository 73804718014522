import React, { useMemo } from 'react';
import StylingOverrides from './styling-overrides';
import Label from 'components/option-library/label';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import { injectIntl } from 'react-intl';
import key from 'utils/key';
import resolvePresetName from 'utils/editor/resolvePresetName';

export default injectIntl(props => {
    const accordionState = useAccordion();
	const [state, dispatch] = accordionState;
    const preset = props.presets[props.presetKey];

    const presetOptions = useMemo(() => {
        const options = Object.keys(props.presets).map(presetKey => {

            return {
                text: resolvePresetName(presetKey),
                value: presetKey
            };
        });

        if (props.excludePresets) {
            return options.filter(option => !props.excludePresets.includes(option.value));
        }

        return options;
    }, []);

    return (
        <Label
            // TO DO: resolve element icon (extend with element/type prop)
            // labelIcon="sn-typography"
            collapsed
            label={props.intl.formatMessage({ id: 'options.select type title' })}
        >
            <Dropdown
                wings
                items={presetOptions}
                value={props.presetKey}
                onChange={value => props.onChange('preset', value)}
            />

            <Accordion state={state} dispatch={dispatch}>
                <Section
                    nested
                    title={props.intl.formatMessage({ id: 'editor.styling overrides title' })}
                    icon="sn-bucket"
                    clear={{
                        onClear: () => props.onChange('style'),
                        enabled: props.inlineStyling && Object.keys(props.inlineStyling).length > 0 // Just some "soft" protection from cases where style is {} instead of undefined (legacy reasons)
                    }}
                >
                    {!props.multipleStates 
                        ?
                            <StylingOverrides
                                // suspend={props.suspend}
                                // onChange={(value, action, shouldSuspend) => {
                                //     if (shouldSuspend) {
                                //         props.suspend.onChange({ currentValue: props.inlineStyling, nextValue: value }, 'style', action);
                                //     } else {
                                //         props.onChange('style', value, action)
                                //     }
                                // }}
                                keys={Object.keys(preset)}
                                preset={preset}
                                theme={props.theme} 
                                onChange={(...args) => {
                                    props.onChange('style', ...args);
                                }}
                                value={props.inlineStyling}
                            />
                        :
                            <Accordion state={state} dispatch={dispatch}>
                                {/* If default preset is defined it will be rendered outside accordions for secondary states (see below)*/ }
                                {preset && preset.default && 
                                    <StylingOverrides
                                        // suspend={props.suspend}
                                        keys={Object.keys(preset && preset.default)}
                                        preset={preset && preset.default}
                                        theme={props.theme} 
                                        onChange={(value, ...args) => {
                                            const newInlineStyling = {
                                                ...props.inlineStyling,
                                                default: value
                                            };

                                            if (typeof value === 'undefined') delete newInlineStyling.default;

                                            if (Object.keys(newInlineStyling).length === 0) {
                                                props.onChange('style', undefined, ...args);
                                            } else {
                                                props.onChange('style', newInlineStyling, ...args);
                                            }
                                        }}
                                        value={key('inlineStyling.default')(props)}
                                    />
                                }

                                {[
                                    'focus', 
                                    'active', 
                                    'hover', 
                                    'disabled', 
                                    'container' /* menu */, 
                                    'line',
                                    'label' /* form + divider */,  // There's a key clash here; if it ever becomes a problem we should just prepend the element name to differentiate (e.g. dividerlabel vs formlabel)
                                    'icon' 
                                ].map(stateType => {
                                    if (preset && !preset[stateType]) return () => null;
                                    
                                    return (
                                        <Accordion key={stateType} state={state} dispatch={dispatch}>
                                            <Section
                                                nested
                                                // title={stateType}
                                                title={(() => {
                                                    switch(stateType) {
                                                        case 'active':
                                                        case 'focus':
                                                            return props.intl.formatMessage({ id: 'general.state active' });
                                                        case 'hover':
                                                            return props.intl.formatMessage({ id: 'general.state hover' });
                                                        case 'disabled':
                                                            return props.intl.formatMessage({ id: 'general.state disabled' });
                                                        case 'container':
                                                            return props.intl.formatMessage({ id: 'general.state container' });
                                                        case 'line': 
                                                            return props.intl.formatMessage({ id: 'elements.divider title' });
                                                        case 'icon':
                                                            return props.intl.formatMessage({ id: 'elements.icon title' });
                                                        case 'label': 
                                                            return props.intl.formatMessage({ id: 'elements.label title' });
                                                    }
                                                })()}
                                                // icon="sn-bucket"
                                                icon={(() => {
                                                    switch(stateType) {
                                                        case 'active':
                                                        case 'focus':
                                                            return 'sn-mouse-left';
                                                        case 'hover':
                                                            return 'mouse pointer';
                                                        case 'disabled':
                                                            return 'sn-cross2';
                                                        case 'container':
                                                            return 'sn-marquee';
                                                        case 'line':
                                                            return 'sn-page-break';
                                                        case 'icon':
                                                            return 'sn-atom2'
                                                        case 'label':
                                                            return 'sn-typography';
                                                    }
                                                })()}
                                                clear={{
                                                    onClear: () => {
                                                        const newInlineStyling = {
                                                            ...props.inlineStyling
                                                        };

                                                        delete newInlineStyling[stateType];

                                                        if (Object.keys(newInlineStyling).length === 0) {
                                                            props.onChange('style');
                                                        } else {
                                                            props.onChange('style', newInlineStyling);
                                                        }
                                                    },
                                                    enabled: typeof key(`inlineStyling.${stateType}`)(props) !== 'undefined'
                                                }}
                                            >
                                                <StylingOverrides
                                                    // suspend={props.suspend}
                                                    keys={['default', 'active', 'hover', 'disabled', 'focus'].includes(stateType) 
                                                        ?
                                                            Object.keys(preset.default)
                                                        :
                                                            Object.keys(preset[stateType])
                                                    }
                                                    preset={{
                                                        // ...preset.default,
                                                        ...preset && preset[stateType],
                                                    }}
                                                    element={props.element}
                                                    theme={props.theme} 
                                                    onChange={(value, ...args) => {
                                                        const newInlineStyling = {
                                                            ...props.inlineStyling,
                                                            [stateType]: value
                                                        };

                                                        if (typeof value === 'undefined') delete newInlineStyling[stateType];

                                                        if (Object.keys(newInlineStyling).length === 0) {
                                                            props.onChange('style', undefined, ...args);
                                                        } else {
                                                            props.onChange('style', newInlineStyling, ...args);
                                                        }
                                                    }}
                                                    value={key(`inlineStyling.${stateType}`)(props)}
                                                    multipleStatesDefaultStyling={key('inlineStyling.default')(props)}
                                                />
                                            </Section>
                                        </Accordion>
                                    );
                                })}
                            </Accordion>
                    }
                </Section>
		    </Accordion>
        </Label>
    );
});