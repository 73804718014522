const deriveAndCompare = {
    object: (getDerivedValue, lastDerivedValue) => {
        const nextValue = getDerivedValue();

        if (!nextValue) return nextValue;
        if (!lastDerivedValue) return nextValue;

        const amountOfKeysDiffer = Object.keys(nextValue).length !== Object.keys(lastDerivedValue).length;

        if (amountOfKeysDiffer) return nextValue;

        const anyValueChanged = Object.keys(nextValue).some(key => {
            const valueChanged = lastDerivedValue[key] !== nextValue[key];
    
            return valueChanged;
        });

        if (anyValueChanged) return nextValue;

        return lastDerivedValue;
    },
    // array: (getDerivedValue, lastDerivedValue) => {
    //     const nextValue = getDerivedValue();
        
    //     if (lastDerivedValue) {

    //     }
    // }
}

export { deriveAndCompare };

export default (values, lastValues) => {
    return Object.keys(values).reduce((acc, key) => {
        const valueChanged = lastValues[key] !== values[key];

        if (valueChanged) {
            if (acc === null) acc = {};

            acc[key] = values[key];
        }

        return acc;
    }, null);
}