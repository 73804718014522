module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    horizontalAlign: 'center'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 5,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Image',
                                props: {
                                    style: {},
                                    preset: 'Circular',
                                    custom: true
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Vælg hjemmesidens ord med omhu og bliv fundet. ',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            },
                                            {
                                                key: '4rrpf',
                                                text:
                                                    'Brug de rigtige ord og bliv fundet på nettet. Flet søgeord ind i teksten og hjælp dem, der leder efter dit produkt, med at finde din hjemmeside. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 5,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Image',
                                props: {
                                    style: {},
                                    preset: 'Circular',
                                    custom: true
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Uden for mange omsvøb og lige til sagens kerne.  ',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            },
                                            {
                                                key: '8vffp',
                                                text:
                                                    'Når du skriver tekst til en hjemmeside, kan det være en god idé at gå lige til sagen. Begynd med det vigtigste og hold fokus på kernebudskabet. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 5,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Image',
                                props: {
                                    style: {},
                                    preset: 'Circular',
                                    custom: true
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Vær konkret og kom den utålmodige læser i møde. ',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            },
                                            {
                                                key: '7229c',
                                                text:
                                                    'Vi skimmer ofte en hjemmeside for hurtigt at afgøre om den er relevant for vores søgning. Teksten må derfor gerne være konkret og let at afkode. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
