module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Normal',
            style: {
                backgroundColor: {
                    key: 'secondary'
                }
            }
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    horizontalAlign: 'center',
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 10,
                        props: {
                            style: {
                                padding: {
                                    generalPadding: 1
                                },
                                backgroundColor: {
                                    key: 'primary'
                                }
                            }
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Overskrift 3',
                                                type: 'Heading 3',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'center',
                                    style: {
                                        color: {
                                            key: 'tint'
                                        }
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Image',
                                props: {
                                    style: {},
                                    auto: true,
                                    alignment: 'center',
                                    horizontal: true,
                                    preset: 'Normal'
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Som supplement til billedet fungerer det godt med en kort beskrivende tekst, som fx krediterer fotografen eller tegneren. ',
                                                type: 'Small',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    style: {
                                        color: {
                                            key: 'tint'
                                        }
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
