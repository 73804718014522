import React from 'react';
import { Message, Icon } from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import Text from 'components/option-library/primitives/input/text';

const generateInput = options => props => {
	const { type, name } = options;
	const {
		input,
		size,
		intl,
		infoIcon,
		meta: { error },
		disabled,
		textAlign,
		fluid,
		icon,
		iconPosition,
		iconClassName,
		placeholder
	} = props; // Provided by Field component from redux-form

	const className = error && 'error';

	return (
		<React.Fragment>
			{/* {icon &&
				<Icon
					name={icon}
					className={!error ? ('primary' || iconClassName) : 'negative'} 
				/>
			} */}

			<Text
				{...input}
				size={size}
				fluid={fluid}
				icon={icon}
				iconPosition={iconPosition}
				placeholder={placeholder}
				className={className}
				type={type || null}
				disabled={disabled}
				label={
					<label style={{ textAlign: textAlign }}>
						{name && intl.formatMessage({ id: name })}
						{infoIcon ? <InfoIcon description={infoIcon} /> : null}
					</label>
				}
				style={{ marginBottom: 4 }}
			/>
			
			{error &&
				<Message negative>
					{error(intl)}
				</Message>
			}
		</React.Fragment>
	);

	// return (
	// 	<FormField>
	// 		<FormInput
	// 			{...input}
	// 			className={className}
	// 			size={size ? size : "big"}
	// 			icon={<Icon name={icon} className="primary" /> || null}
	// 			iconPosition="left"
	// 			type={type || null}
	// 			disabled={disabled}
	// 			label={
	// 				<label style={{ textAlign: textAlign }}>
	// 					{name && intl.formatMessage({ id: name })}{infoIcon ? <InfoIcon description={infoIcon} /> : null}
	// 				</label>
	// 			}
	// 			placeholder={intl.formatMessage({ id: placeholder })}
	// 			style={{ marginBottom: 4 }}
	// 		/>
			
	// 		{error &&
	// 			<Message negative>
	// 				{error(intl)}
	// 			</Message>
	// 		}
	// 	</FormField>
	// );
};

export default generateInput;