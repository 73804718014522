import React, { PureComponent } from 'react';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import './text.scss';
import {
    Editor,
    EditorState,
    getDefaultKeyBinding,
    KeyBindingUtil,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,
    DefaultDraftBlockRenderMap
} from 'draft-js';
import { Map as immutableMap } from 'immutable';
import Link, { linkStrategy } from './decorators/Link';

const { hasCommandModifier } = KeyBindingUtil;

const compositeDecorator = extraProps => {
    return new CompositeDecorator([
        {
            strategy: linkStrategy,
            component: props => <Link {...props} {...extraProps} />
        }
    ]);
};

const PresetRenderer = props => {
    const { id, preset, presetOverrides, theme, computePreset } = props;

    const style = {
        ...computePreset(preset, theme),
        ...computePreset(presetOverrides, theme)
    };

    const modifiedChildren = props.children.map(child =>
        React.cloneElement(child, { style })
    );

    return (
        <div
            id={id}
            onDragStart={e => {
                e.preventDefault();
            }}
        >
            {modifiedChildren}
        </div>
    );
};

const generateBlockMap = (
    id,
    presets,
    presetOverrides,
    theme,
    computePreset
) => {
    const presetTypes = {
        unstyled: {
            element: 'div',
            wrapper: (
                <PresetRenderer
                    id={id}
                    unstyled
                    computePreset={computePreset}
                    preset={presets.Normal}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        Quote: {
            element: 'div',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets.Quote}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        Small: {
            element: 'div',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets.Small}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        Normal: {
            element: 'div',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets.Normal}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        Big: {
            element: 'div',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets.Big}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        Title: {
            element: 'h1',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets.Title}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        'Heading 2': {
            element: 'h2',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets['Heading 2']}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        'Heading 3': {
            element: 'h3',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets['Heading 3']}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        'Heading 4': {
            element: 'h4',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets['Heading 4']}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        'Heading 5': {
            element: 'h5',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets['Heading 5']}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        },
        'Heading 6': {
            element: 'h6',
            wrapper: (
                <PresetRenderer
                    id={id}
                    computePreset={computePreset}
                    preset={presets['Heading 6']}
                    presetOverrides={presetOverrides}
                    theme={theme}
                />
            )
        }
    };

    return immutableMap(presetTypes);
};

class CustomEditor extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            editorState: this.load(props)
        };
    }

    static mapContextToProps = (props, state) => {
        const { theme, pages, blogs, files, editableNode, frontPage } = props.context;

        // console.log(shallowCompare({
        // 	theme,
        // 	pages,
        // 	// editableNode,
        // 	onLinkClick: props.context.extend.Text.onLinkClick,
        // 	getLinkDescription: props.context.extend.Text.getLinkDescription,
        // 	getEditorProps: props.context.extend.Text.getEditorProps,
        // 	renderTextOptions: props.context.extend.Text.renderTextOptions
        // }, state), 'yea')

        return shallowCompare(
            {
                theme,
                pages,
                blogs,
                files,
                frontPage,
                // editableNode,
                onNavigate: props.context.extend.Text.onNavigate,
                computeLink: props.context.extend.Text.computeLink,
                // onLinkClick: props.context.extend.Text.onLinkClick,
                // getLink: props.context.extend.Text.getLink,
                // getLinkTitle: props.context.extend.Text.getLinkDescription,
                // getLinkDescription: props.context.extend.Text.getLinkDescription,
                getEditorProps: props.context.extend.Text.getEditorProps,
                renderTextOptions: props.context.extend.Text.renderTextOptions
            },
            state
        );
    };

    load(props) {
        const editorState =
            Object.keys(props.node.props.text).length === 0
                ? EditorState.createEmpty(
                      compositeDecorator({
                          ...props
                          // theme: props.theme,
                          // computePreset: props.computePreset,
                          // getLinkDescription: props.getLinkDescription,
                          // onLinkClick: props.onLinkClick
                      })
                  )
                : EditorState.createWithContent(
                      convertFromRaw(props.node.props.text),
                      compositeDecorator({
                          ...props
                          // theme: props.theme,
                          // computePreset: props.computePreset,
                          // getLinkDescription: props.getLinkDescription,
                          // onLinkClick: props.onLinkClick
                      })
                  );

        return editorState;
    }

    componentWillReceiveProps(nextProps) {
        // Can't merge our styling into draftjs without forcing a full rerender, so we rotate the key and force a rerender every time styling changes
        if (
            nextProps.theme !== this.theme ||
            nextProps.node.props.style !== this.props.node.props.style ||
            nextProps.node.props.preset !== this.props.node.props.preset
        ) {
            this.key = this.key === 'hehe' ? 'haha' : 'hehe';
            this.theme = nextProps.theme;
        }

        if (nextProps.node.props.text !== this.props.node.props.text) {
            if (nextProps.isEditable) return;
            // if (nextProps.editableNode) {
            // 	// If the node is currently editable we ignore any updates coming from props to prevent interfering with draftjs...
            // 	if (nextProps.editableNode === this.props.node.id) return;
            // }

            // ...Otherwise we allow outside changes (undo/redo triggers these) to override text
            this.setState({
                editorState: this.load(nextProps)
            });
        }
    }

    render() {
        const { node, theme, computePreset } = this.props;

        const presetOverrides = node.props.style;
        const presets = theme.presets.text;

        const editorProps =
            this.props.getEditorProps && this.props.getEditorProps(this);

        // console.log(this.props.node, 'node')

        // const selection = this.state.editorState.getSelection();
        // const block = contentState.getBlockForKey(selection.getStartKey());
        // const blockType = block.getType();

        // console.log(this.props.node.props, 'node props');
        // console.log(this.key, 'key?')

        // console.log(presets, presetOverrides, theme, computePreset);

        // console.log(this.props.computedStyling, 'computed styling text')

        // console.log(this.props.renderTextOptions, 'render text options');

        // console.log(this.props.node.props.text, 'text');

        // return <i className="icon-quotes-left" style={{color: 'red' }}></i>

        // console.log('text render', node.id)

        return (
            <React.Fragment>
                <Editor
                    {...this.props.cropProps}
                    readOnly
                    key={this.key}
                    editorKey={node.id}
                    ariaDescribedBy={node.id}
                    {...editorProps}
                    editorState={this.state.editorState}
                    keyBindingFn={e => {
                        if (e.keyCode === 83 && hasCommandModifier(e)) {
                            return 'save';
                        }

                        return getDefaultKeyBinding(e);
                    }}
                    handleKeyCommand={this.handleKeyCommand}
                    blockRenderMap={DefaultDraftBlockRenderMap.merge(
                        generateBlockMap(
                            node.id,
                            presets,
                            presetOverrides,
                            theme,
                            computePreset
                        )
                    )}
                    textAlignment={node.props.textAlignment}
                />

                {/* 
					Have to render the side options on this level rather than at Renderer Root because we need editorState to be avaialble in the side bar 

					Could be done by lifting state but that would have performance penalties
				*/}
                {this.props.renderTextOptions &&
                    this.props.renderTextOptions({
                        isEditable: this.props.isEditable,
                        editorState: this.state.editorState,
                        setEditorState: editorProps.onChange,
                        ContextConsumer: this.props.ContextConsumer,
                        updateState: this.props.updateState
                        // editableNode: node,
                        // theme: this.props.theme,
                    })}
            </React.Fragment>
        );
    }
}

export default CustomEditor;

/*
	renderOptionPanel() {
		const {
			getEditorGlobals,
			path,
			node,
			renderSelectable
		} = this.props;

		const contentState = this.state.editorState.getCurrentContent();
		const theme = getEditorGlobals().theme;
		const presets = theme.presets[node.component.toLowerCase()];
		const selection = this.state.editorState.getSelection();
		const block = contentState.getBlockForKey(selection.getStartKey());

		// console.log(block)

		const blockType = block.getType();

		const currentStyle = this.state.editorState.getCurrentInlineStyle();

		/
			https://draftjs.org/docs/advanced-topics-entities.html
		/

		// const startKey = this.state.editorState.getSelection().getStartKey();
		const startOffset = this.state.editorState.getSelection().getStartOffset();
		const linkKey = block.getEntityAt(startOffset);

		let entityData;

		// if (!selection.isCollapsed()) {
			if (linkKey) {
				const linkInstance = contentState.getEntity(linkKey);
	
				entityData = linkInstance.getData();
			}
		// }

		return renderSelectable({
			selected: [

			],
			editable: [
				// <Icon key="quill" circular name="sn-quill3" className="primary" style={{ marginRight: 16, opacity: '0.7' }} />,
				<Dropdown
					uncontrolled
					key="presets"
					option="preset"
					icon="sn-typography"
					hoverContent={this.props.intl.formatMessage({ id: 'design.type' }) + ` (${blockType})`}
					options={Object.keys(presets).filter(preset => !['Link', 'Unordered list', 'Ordered list'].includes(preset)).map(preset => {
						return {
							text: preset,
							value: preset
						};
						
					})}
					onChange={preset => {
						this.toggleBlockType(preset.value);
					}}
					value={blockType}
				/>,
				<ButtonGroup key="inline-styles1" size="tiny">
					<Toggle
						key="bold"
						hoverContent={this.props.intl.formatMessage({ id: 'options.bold text' })}
						icons={['bold']}
						toggled={currentStyle.has('BOLD')}
						onToggle={() => this.toggleInlineStyle('BOLD')}
					/>
					<Toggle
						key="italic"
						hoverContent={this.props.intl.formatMessage({ id: 'options.italic text' })}
						icons={['italic']}
						toggled={currentStyle.has('ITALIC')}
						onToggle={() => this.toggleInlineStyle('ITALIC')}
					/>
					<Toggle
						key="underline"
						hoverContent={this.props.intl.formatMessage({ id: 'options.underline text' })}
						icons={['underline']}
						toggled={currentStyle.has('UNDERLINE')}
						onToggle={() => this.toggleInlineStyle('UNDERLINE')}
					/>
					<Toggle
						key="strikethrough"
						hoverContent={this.props.intl.formatMessage({ id: 'options.strikethrough text' })}
						icons={['strikethrough']}
						toggled={currentStyle.has('STRIKETHROUGH')}
						onToggle={() => this.toggleInlineStyle('STRIKETHROUGH')}
					/>
				</ButtonGroup>,
				<Basic
					key="alignment"
					icons={[
						{
							name: (() => {
								switch (node.props.textAlignment) {
									case 'center':
										return 'sn-paragraph-center3';
									case 'right':
										return 'sn-paragraph-right3';
									case 'left':
									default:
										return 'sn-paragraph-left3';
								}
							})()
						}
					]}
					hoverContent={this.props.intl.formatMessage({ id: 'options.text alignment' })}
				>
					<ButtonGroup>
						<Toggle
							key="left"
							hoverContent={this.props.intl.formatMessage({ id: 'options.text alignment left' })}
							icons={['sn-paragraph-left3']}
							toggled={['center', 'right'].indexOf(node.props.textAlignment) === -1}
							onToggle={(toggled, e) => this.toggleAlignment(e, 'left')}
						/>
						<Toggle
							key="center"
							hoverContent={this.props.intl.formatMessage({ id: 'options.text alignment center' })}
							icons={['sn-paragraph-center3']}
							toggled={node.props.textAlignment === 'center'}
							onToggle={(toggled, e) => this.toggleAlignment(e, 'center')}
						/>
						<Toggle
							key="underline"
							hoverContent={this.props.intl.formatMessage({ id: 'options.text alignment right' })}
							icons={['sn-paragraph-right3']}
							toggled={node.props.textAlignment === 'right'}
							onToggle={(toggled, e) => this.toggleAlignment(e, 'right')}
						/>
					</ButtonGroup>
				</Basic>,
				<LinkOption
					key="link"
					urlData={entityData}
					onToggle={(link, { remove = false }) => {
						let currentEditorState = this.state.editorState;
						let selection = this.state.editorState.getSelection();

						if (entityData) {
							let entityRange;

							block.findEntityRanges(
								value => value.get('entity') === linkKey,
								(start, end) => {
									entityRange = {
										start,
										end,
										text: block.get('text').slice(start, end)
									};
								}
							);

							if (entityRange) {
								selection = selection.merge({
									anchorOffset: entityRange.start,
									focusOffset: entityRange.end,
								});
							}
							
							currentEditorState = EditorState.forceSelection(this.state.editorState, selection.merge({
								anchorOffset: entityRange.start,
								focusOffset: entityRange.end,
							}));
						}

						if (remove) {
							this.setEditorState(RichUtils.toggleLink(currentEditorState, selection, null));
						} else {
							this.setLink(link, selection);
						}
					}}
				/>
			],
			container: this.tooltip.getTarget(),
			setDragHandleRef: this.props.setDragHandleRef,
			path
		});

*/

/*
		if (getEditorGlobals().disabled) {
			return (
				<ElementWrapper
					setSelectedContainer={this.props.setSelectedContainer}
					minimized={computedProps.collapsed}
					computedProps={computedProps}
					disableHorizontalAlignment
				>
					<Editor
						ref={ref => this.editorRef = ref}
						key={this.key}
						readOnly
						editorState={this.state.editorState} // Want to move this to listen to edtiorState directly from props eventually but have to solve problem with cursor resetting first...
						onChange={this.setEditorState}
						keyBindingFn={(e) => {
							// if (e.keyCode === 76 && hasCommandModifier(e)) {
							// 	return 'link';
							// }

							if (e.keyCode === 83 && hasCommandModifier(e)) {
								return 'save';
							}

							return getDefaultKeyBinding(e);
						}}
						handleKeyCommand={this.handleKeyCommand}
						blockRenderMap={DefaultDraftBlockRenderMap.merge(generateBlockMap(presets, presetOverrides, theme))}
						textAlignment={node.props.textAlignment || 'left'}
					/>
				</ElementWrapper>
			);
		}

		return [
			this.renderOptionPanel(),
			<ElementWrapper
				setSelectedContainer={this.props.setSelectedContainer}
				key="ayyy"
				minimized={computedProps.collapsed}
				computedProps={computedProps}
				disableHorizontalAlignment
			>
				<Editor
					// style={{ 'user-select': 'text' }}
					// onMouseDown={e => console.log(e, 'did it work')}
					ref={ref => this.editorRef = ref}
					key={this.key}
					readOnly={editState !== 'editable'} // Have to force readOnly when we aren't editing, or text becomes hard to drag (dragging selects the text instead)
					editorState={this.state.editorState} // Want to move this to listen to edtiorState directly from props eventually but have to solve problem with cursor resetting first...
					onChange={this.setEditorState}
					keyBindingFn={(e) => {
						if (e.keyCode === 76 && hasCommandModifier(e)) {
							return 'link';
						}

						if (e.keyCode === 83 && hasCommandModifier(e)) {
							return 'save';
						}

						return getDefaultKeyBinding(e);
					}}
					handleKeyCommand={this.handleKeyCommand}
					blockRenderMap={DefaultDraftBlockRenderMap.merge(generateBlockMap(presets, presetOverrides, theme))}
					textAlignment={node.props.textAlignment || 'left'}
					//blockStyleFn={myBlockStyleFn}
					//textAlignment={null}
					blockRendererFn={() => {
						return {
					      component: Block,
					      editable: false,
					      props: {
					        foo: 'bar',
					      },
					    };
					}}
					/>
					</ElementWrapper>,
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0
						}}
						key="select"
						ref={this.setRef}
					/>
				];
			}
		}
*/
