import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    const text = props.intl.formatMessage({ id: 'options.line thickness title' });

    return (
        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>
            <Label
                collapsed
                labelIcon="sn-text-height"
                label={text}
                fontSize={12}
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <NumberPicker 
                    disableUndoRedo
                    name={text}
                    unit="%"
                    min={1}
                    max={20}
                    tooltipPosition="right center"
                    value={props.inlineStyling}
                    onChange={newValue => props.onChange(newValue)}					
                />
            </Label>
        </div>
    );
});