import React, { memo, useEffect, PureComponent } from 'react';
import { Button, Icon } from 'svift-ui';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import addKeybinding from 'svift-library/client/utils/addKeybinding';
import Notification from 'components/hoc/notification';
import key from 'svift-library/key';
import Portal from 'components/editor-v2/editor-interface/Portal';
import { FormattedMessage, injectIntl } from 'react-intl';

const ContextProvider = Component => {
    return class Composer extends PureComponent {
        render() {
            const { ContextConsumer } = this.props;
    
            return (
                <ContextConsumer>
                    {context => {
                        const { past, future, currentAction, undo, redo, updateState } = context;
                        const explicitProps = { past, future, currentAction, undo, redo, updateState };

                        const isEditingText =  key(`nodeMap.${context.editableNode}.component`)(context) === 'Text';

                        return (
                            <Component 
                                {...explicitProps}
                                isEditingText={isEditingText}
                            />
                        );
                    }}
                </ContextConsumer>
            );
        }
    }
};

const UndoRedo = injectIntl(memo(Notification(props => {
    const { 
        past,
        future,
        undo,
        redo,
        currentAction,
        isEditingText
    } = props;

    const undoButtonPopup = useTooltip();
    const redoButtonPopup = useTooltip();

    useEffect(() => {
        if (!isEditingText) {
            return addKeybinding(['z', 90], ['ctrlKey'], e => {
                undo();
            });
        }
    }, [isEditingText]);

    useEffect(() => {   
        if (!isEditingText) {
            return addKeybinding(['y', 89], ['ctrlKey'], () => {
                redo();
            });
        }
    }, [isEditingText]);

    // useEffect(() => {
    //     if (currentAction) {
    //         props.createNotification({
    //             // content: currentAction.description,
    //             content: props.intl.formatMessage({ id: `general.${currentAction.forward ? 'redo' : 'undo'} action` }),
    //             className: currentAction.forward ? 'info' : 'warning',
    //             position: 'bottomRight',
    //             icon: currentAction.forward ? 'repeat' : 'undo',
    //             deleteTime: 1000
    //         });
    //     }
    // }, [currentAction]);

    const hasPast = past.length > 0;
    const hasFuture = future.length > 0;

    return (
        <Portal target="undo-redo-portal">
            {/* undo button */}
            <Popup
                target={undoButtonPopup.current}
                disabled={!hasPast || isEditingText}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="general.undo action" /> (Ctrl + Z)
            </Popup>
            <Button
                onClick={undo}
                circular
                icon
                size="mini"
                ref={undoButtonPopup}
                basic={!hasPast}
                disabled={!hasPast || isEditingText}
                className={hasPast ? 'primary' : ''}
                style={{ marginRight: 4, padding: 8 }}
            >
                <Icon name="undo" />
            </Button>

            {/* redo button */}
            <Popup
                target={redoButtonPopup.current}
                disabled={!hasFuture || isEditingText}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="general.redo action" /> (Ctrl + Y)
            </Popup>
            <Button
                circular
                icon
                onClick={redo}
                size="mini"
                ref={redoButtonPopup}
                basic={!hasFuture}
                disabled={!hasFuture || isEditingText}
                className={hasFuture ? 'primary' : ''}
                style={{ marginLeft: 0, padding: 8 }}
            >
                <Icon name="repeat" />
            </Button>
        </Portal>
    );
})));

export default ContextProvider(UndoRedo);