import React, { PureComponent } from 'react';
import {
	Label,
	Segment,
	SegmentGroup,
	Icon,
	Button,
	Popup,
	Message,
	ButtonGroup,
	Grid, GridRow, GridColumn, Divider, Box
} from 'svift-ui';
import { connect } from 'react-redux';
import { pagesSelector } from 'store/selectors/sites/pages';
import dataUtils from 'utils/dataManipulators';
import { pagesToMenuTree } from 'utils/pages/format';
import Tree from 'components/tree/Tree';
import NavigationHeader from './NavigationHeader';
import navigationActions from 'store/actions/sites/navigation';
import pageActions from 'store/actions/sites/pages';
import ModalWrapper from 'components/modal';
import PageForm from 'components/forms/page';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';

//segment is each segment has drag function
class SegmentWrapper extends PureComponent {
	constructor() {
		super();

		this.state = {
			hovered: false
		};
	}

	onEnter = e => {
		this.setState({
			hovered: true
		});
	}

	onLeave = e => {
		this.setState({
			hovered: false
		});
	}

	componentDidUpdate() {
		/* 
			what the fuck

			no idea what this is for, but it seems to work
		*/
		if (this.state.delayRerender) {
			setTimeout(() => {
				this.setState({
					delayRerender: false
				});
			}, 0);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isDragging && !this.props.isDragging) {
			this.setState({
				delayRerender: true
			});
		}
	}

	render() {
		const isDragging = this.props.isDragging;

		// this should be set as a class in the future to ensure that show menu title button is visible
		const style = {
			opacity: this.props.item.data.hidden ? 0.3 : '',
			cursor: 'move'
		};

		let className = '';

		if (this.state.hovered) {
			className = 'hover-item';
		}

		if (isDragging && !this.state.delayRerender) {
			className += ' drop-segment-line';
		}

		return (
			<Segment
				style={style} /*className={isDragging ? 'drop-segment-line' : ''}*/
				ref={this.props.assignRef}
				className={className}
				onMouseEnter={this.onEnter}
				onMouseLeave={this.onLeave}
			>
			{/* if there is in the process of drag and not rendered yet */}
				{(isDragging && !this.state.delayRerender) ? <Icon circular name="sn-plus2" className="drop-icon" /> : this.props.children}
			</Segment>
		);
	}
}

//it is necessary for the segment group
class SegmentGroupWrapper extends PureComponent {
	render() {
		return <SegmentGroup {...this.props} size="large" className="svift-dnd-list svift-pages-list" />;
	}
}

@connect(
	state => ({
		siteid: state.sites.params.siteid
	}),
	dispatch => ({
		updatePage: (page, siteid, pageid) => dispatch(pageActions.updatePage(page, siteid, pageid))
	})
)
//each text item text
class TextComponentWrapper extends PureComponent {
	constructor() {
		super();

		this.state = {
			loading: false
		};

		this.save = async item => {
			const { _id } = item;

			const editText = this.state[`${_id}input`];

			this.setState({
				loading: true
			});

			try {
				await this.props.updatePage({ navigationTitle: editText }, this.props.siteid, _id);
			} catch (e) {
				console.log(e);

				alert('error');
			} finally {
				this.setState({
					loading: false,
					[`${_id}input`]: undefined
				});
			}
		};
	}

	render() {
		const { text, item, intl } = this.props;

		if (this.state.loading) return 'loading';

		const isOffline = !item.data.content.live;

		return (
			<div key={item.data._id}>
				<Icon
					name="sn-file-text"
					style={{ marginRight: 8, position: 'relative', zIndex: 1 }}
				/>

				{typeof this.state[`${item.data._id}input`] === 'undefined' ? text : (
					<input
						key={item.data._id}
						style={{ position: 'relative', zIndex: 1, padding: 4 }}
						value={this.state[`${item.data._id}input}`]}
						onChange={e => {
							this.setState({
								[`${item.data._id}input`]: e.target.value
							});
						}}
					/>
				)}
				
				{isOffline && 
					<Label 
						basic 
						size="mini" 
						className="warning"
						style={{
							marginLeft: '16px',
							verticalAlign: 'middlecd '
						}}
					>
						<Icon name="sn-eye-blocked" />
						{intl.formatMessage({ id: 'general.offline title' })}
					</Label>
				}

				{typeof this.state[`${item.data._id}input`] === 'undefined' ?
					<Popup
						trigger={
							<Button
							circular
							basic
							icon
								size="mini"
								className="primary segment-option"
								style={{ marginLeft: 16, position: 'relative', zIndex: 1 }}
								onClick={() => {
									this.setState({
										[`${item.data._id}input`]: text
									});
								}}
								>
								<Icon name="sn-quill" />
							</Button>
						}
						content={intl.formatMessage({ id: 'menus.edit menu title' })}
						position="bottom center"
					/> : [
						<ButtonGroup size="mini" style={{ marginLeft: 16 }}>
							<Popup
								key="one"
								trigger={
									<Button
										icon
										className="positive segment-option"
										style={{ zIndex: 1, opacity: 1 }}
										onClick={() => this.save(item.data)}
										>
										<Icon name="sn-checkmark" />
									</Button>
								}
								content={intl.formatMessage({ id: 'menus.save menu title' })}
								position="bottom center"
								/>
							<Popup
								key="two"
								trigger={
									<Button
									basic
										icon
										className="negative segment-option"
										style={{ zIndex: 1, opacity: 1 }}
										onClick={() => {
											const currentState = {
												...this.state
											};
											
											currentState[`${item.data._id}input`] = undefined;
											
											this.setState(currentState);
										}}
										>
										<Icon name="sn-cross" />
									</Button>
								}
								content={intl.formatMessage({ id: 'menus.cancel edit menu title' })}
								position="bottom center"
							/>
						</ButtonGroup>
					]
				}

				<Popup
					trigger={
						<Button
							circular
							basic
							icon
							size="mini"
							className="shade segment-option"
							style={{ marginLeft: 8, position: 'relative', zIndex: 1 }}
							onClick={() => {
								const navigation = [...this.props.navigation];
								
								navigation[item.data.sort] = {
									...navigation[item.data.sort],
									hidden: !item.data.hidden
								};

								this.props.updateNavigation({
									navID: this.props.navid,
									siteID: this.props.siteid,
									newNavigation: navigation
								});
							}}
						>
							<Icon name={item.data.hidden ? 'sn-eye-blocked' : 'sn-eye'} />
						</Button>
					}
					content={!item.data.hidden ? intl.formatMessage({ id: 'menus.hide page in navigation' }) : intl.formatMessage({ id: 'menus.show page in navigation' })}
					position="bottom center"
				/>		
			</div>
		);
	}
}

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const pages = pagesSelector(state, siteid);
	const { stagedNavigation } = state.sites.navigation;

	return {
		pages,
		navigation: stagedNavigation,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateNavigation: ({ siteID, navID, newNavigation }) => dispatch(navigationActions.updateNavigationContent({ newNavigation }))
	};
};

const weaveEntriesWithPages = (entries, pages) => {
	return entries.reduce((acc, entry) => {
		const page = pages.find(page => page._id.toString() === entry.id.toString());

		if (!page) return acc;

		acc[entry.id] = {
			page,
			navigationEntry: entry
		};

		return acc;
	}, {});
};

class NavigationContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};

		this.sort = this.sort.bind(this);
	}

	formatPages = () => {
		const entriesAndPagesAsObject = weaveEntriesWithPages(this.props.navigation, this.props.pages);

		const formattedPages = Object.values(entriesAndPagesAsObject).
			map(({ page, navigationEntry: entry }, index) => {
				const parentPage = entriesAndPagesAsObject[entry.parent];

				return {
					...page,
					title: page.navigationTitle || page.title,
					parentPage: parentPage ? entry.parent : null,
					hidden: entry.hidden,
					sort: index
				};
			});
		
		return pagesToMenuTree(formattedPages);
		// const activePages = this.props.navigation
		// 	.map(({ id }) => this.props.pages.find(page => page._id === id))
		// 	.map((page, index) => ({
		// 		...page,
		// 		title: page.navigationTitle || page.title,
		// 		parentPage: this.props.navigation[index].parent,
		// 		hidden: this.props.navigation[index].hidden,
		// 		sort: index
		// 	}));

		// 	console.log(activePages, 'wat')

		// return pagesToMenuTree(activePages);
	}

	sort(source, target, area) {
		const {
			item: sourceItem,
			path: sourcePath
		} = source;
		const {
			item: targetItem,
			path: targetPath
		} = target;

		const { sort: sourceIndex } = source.item.data;
		let { sort: targetIndex } = target.item.data;

		if (sourceIndex < targetIndex) targetIndex = targetIndex - 1;

		const sourceIsTargetContainer = targetPath.find(({ item }) => item.data._id === sourceItem.data._id);

		if (sourceItem.data._id === targetItem.data._id || sourceIsTargetContainer) return;

		let navigation;

		if (area === 'rightBottom') {
			navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetPath.length - 1, {
				id: sourceItem.data._id,
				parent: targetItem.data._id,
				hidden: sourceItem.data.hidden
			}, 'after');
		} else if (area === 'right') {
			navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetIndex, {
				id: sourceItem.data._id,
				parent: targetItem.data._id,
				hidden: sourceItem.data.hidden
			}, 'after');
		} else if (
			targetPath.length === sourcePath.length &&
			targetPath.length > 0 && sourcePath.length > 0 &&
			targetPath[targetPath.length - 1] === sourcePath[targetPath.length - 1]
		) {
			if (area === 'bottom') {
				navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetIndex, {
					id: sourceItem.data._id,
					parent: sourceItem.data.parentPage,
					hidden: sourceItem.data.hidden
				}, 'after');
			} else if (area === 'top') {
				navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetIndex, {
					id: sourceItem.data._id,
					parent: sourceItem.data.parentPage,
					hidden: sourceItem.data.hidden
				}, 'before');
			}
		} else {
			const targetParent = targetPath[targetPath.length - 1] && targetPath[targetPath.length - 1].item;

			if (area === 'bottom') {
				navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetIndex, {
					id: sourceItem.data._id,
					parent: targetParent ? targetParent.data._id : null,
					hidden: sourceItem.data.hidden
				}, 'after');
			} else if (area === 'top') {
				navigation = dataUtils.immutableArrayInsertion(this.startingPoint, targetIndex, {
					id: sourceItem.data._id,
					parent: targetParent ? targetParent.data._id : null,
					hidden: sourceItem.data.hidden
				}, 'before');
			}
		}

		this.props.updateNavigation({
			navID: this.props.navid,
			siteID: this.props.siteid,
			newNavigation: navigation
		});
	}

	
	render() {
		if(this.props.navigation) console.log(this.formatPages(), 'formatedpages')
		return (
			//define dnd target
			<React.Fragment>
				<NavigationHeader />

				<Grid padded centered>
					<GridRow>
						<GridColumn computer={10} tablet={10} mobile={16}>
							{this.props.navigation &&
								<Box style={{ marginTop: 0, textAlign: 'left' }}>
									<Message className="secondary" style={{ padding: 8, marginBottom: 4 }}>
										<span style={{ fontWeight: 'bold', marginRight: 8 }}>
											<FormattedMessage id="general.obs label" />
										</span>
										<FormattedMessage id="menus.navigation editing message description" />
									</Message>

									<div style={{ padding: '16px 0 16px 16px', borderRadius: 8, border: '4px #1B73AB dashed' }}>
										<Tree
											dnd
											items={this.formatPages()}
											TextComponent={props => <TextComponentWrapper {...props} {...this.props} />}
											ItemGroupComponent={SegmentGroupWrapper}
											ItemComponent={SegmentWrapper}
											targetZones={[
												{
													area: 'top',
													y: '0-50',
													x: '0-100',
													color: 'pink',
													didEnter: this.sort
												},
												{
													area: 'bottom',
													y: '50-100',
													x: '0-100',
													color: 'red',
													didEnter: this.sort
												},
												{
													area: 'right',
													y: '20-80',
													x: '25-100',
													manualOffsets: {
														top: 20,
														bottom: 20,
														left: 0,
														right: 0
													},
													color: 'black',
													didEnter: this.sort
												}
											]}

											onDragBeginCallback={({ item }) => {
												this.startingPoint = this.props.navigation.filter((dud, index) => item.data.sort !== index);
												this.navigationAtDrag = this.props.navigation;
											}}
											onDragEndCallback={(dropWasHandled) => {
												delete this.startingPoint;

											}}
										/>
									</div>
								</Box>
							}
						</GridColumn>
						
						{/* TO DO: add preview to navigation builder */}
						{/* <GridColumn computer={1} tablet={1} mobile={16}>
							<Divider vertical>
								<div className="secondary-color" style={{ fontSize: 28 }}>
									<Icon name="sn-eye" style={{ marginLeft: 0, marginRight: 3 }} />
									<Icon name="sn-arrow-right8" style={{ marginLeft: 3, marginRight: 0 }} />
								</div>
							</Divider>
						</GridColumn>

						<GridColumn computer={8} tablet={8} mobile={16}>
							<Box style={{ marginTop: 0, textAlign: 'left', height: '100%' }}>
								PREVIEW OVER HERE!
							</Box>
						</GridColumn> */}
					</GridRow>
				</Grid>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NavigationContainer));
