import React, { useState } from 'react';
import {
	Button,
	Popup,
	Menu,
    MenuItem,
    MenuMenu,
    Input,
    Icon
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import FilesTab from './tabs/files-tab';
import YouTubeTab from './tabs/YouTube';
import css from './file-picker.scss';
import FileBlobs from 'containers/dashboard/files/Blobs';
import UploadField from 'containers/dashboard/files/__components/UploadField';

const getEnabledTabs = tabs => {
	const allTabs = ['files', 'youtube', 'onsplash', 'vimeo', 'etc'];
	const enabledTabs = allTabs.filter(tab => tabs.includes(tab));
	
	return enabledTabs;
};

const Tabs = ({ intl, selectedTab, setSelectedTab, tabs, searchTerm, setSearchTerm, blobProps }) => {
    return (
        <Menu size="small">
            {tabs.map(tab => {
                const isSelected = selectedTab === tab;

                switch (tab) {
                    case 'files':
                        return (
                            <MenuItem key={tab} onClick={() => setSelectedTab('files')}>
                                <Popup
                                    trigger={
                                        <Button icon className={isSelected ? 'primary active-button' : ' secondary basic'}>
                                            <Icon name="sn-folder6" />
                                            <span style={{ marginLeft: 6 }}>
                                                <FormattedMessage id="files.app title" />
                                            </span>
                                        </Button>
                                    }
                                    content={intl.formatMessage({ id: 'files.app title' })}
                                    position="top center"
                                />
                            </MenuItem>
                        );
                    case 'youtube':
                        return (
                            <MenuItem key={tab} onClick={() => setSelectedTab('youtube')}>
                                <Popup
                                    trigger={
                                        <Button icon className={isSelected ? 'primary active-button' : 'secondary basic'}>
                                            <Icon name="sn-youtube" />
                                            <span style={{ marginLeft: 6 }}>
                                                {/* transl8 */}
                                                YouTube
                                            </span>
                                        </Button>
                                    }
                                    content={intl.formatMessage({ id: 'general.youtube title' })}
                                    position="top center"
                                />
                            </MenuItem>									
                        );
                    default:
                        return null;
                }
            })}

            {selectedTab === 'files' &&        
                <React.Fragment>
                    <MenuItem position="right">
                        <Input
                            onChange={e => setSearchTerm(e.target.value)}
                            icon="search"
                            iconPosition="left"
                            className={searchTerm && 'active-input'}
                            value={searchTerm}
                            placeholder={intl.formatMessage({ id: 'files.search files placeholder' })}
                        />
                    </MenuItem>

                    <MenuItem>
                        <UploadField addBlobs={blobProps.addBlobs}>
                            <label htmlFor="uploadFile" className="ui button basic tiny positive">
                                <Icon name="upload" className="positive" />
                                <FormattedMessage id="files.upload files" className="positive" />
                            </label>
                        </UploadField>
                    </MenuItem>
                </React.Fragment>
            }
        </Menu>
    );
};

const TabContent = props => {
    switch (props.selectedTab) {
        case 'files': {
            return (
                <FilesTab
                    filter={props.filter}
                    blobProps={props.blobProps}
                    addBlobs={props.blobProps.addBlobs} // DropUploadHOC looks for this prop, so we have to add it explicitly
                    select={props.onSetFile}
                    searchTerm={props.searchTerm}
                    selectedFiles={props.selectedFiles}
                />
            );
        }

        case 'youtube': {
            return (
                <YouTubeTab
                    select={props.onSetFile}
                />
            );
        }

        default: {
            return 'Placeholder - Unhandled tab';
        }
    }
}

const FilePicker = FileBlobs(({ filter, multiple, selectedFiles, setSelectedFiles, tabs, intl, blobProps }) => {
    const [enabledTabs, _] = useState(() => getEnabledTabs(tabs));
    const [selectedTab, setSelectedTab] = useState(enabledTabs[0]);
    const [searchTerm, setSearchTerm] = useState('');

    const onSetFile = file => {
        if (multiple) {
            let fileIsAlreadySelected = false;

            if (typeof file === 'string') fileIsAlreadySelected = selectedFiles.includes(file);
            // } else {
            //     fileIsAlreadySelected = selectedFiles.some(selectedFile => selectedFile.id === file.id);
                
            //     // If adding a youtube video o
            //     if (fileIsAlreadySelected) return;
            // }
            
            if (fileIsAlreadySelected) {
                setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile !== file));
            } else {
                setSelectedFiles([
                    ...selectedFiles,
                    file
                ]);
            }
        } else {
            setSelectedFiles([file]);
        }
    };

    return (
        <div className={css['file-picker']}>
            <Tabs
                tabs={enabledTabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                intl={intl}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                blobProps={blobProps}
            />

            <TabContent
                searchTerm={searchTerm}
                filter={filter}
                selectedTab={selectedTab}
                selectedFiles={selectedFiles}
                onSetFile={onSetFile}
                blobProps={blobProps}
            />
        </div>
    );
});

export default injectIntl(FilePicker);