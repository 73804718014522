import React, { PureComponent } from 'react';
import DesignHeader from './DesignHeader';
import Index from './design-index/DesignIndex';
import { connect } from 'react-redux';
import { replaceRoute } from 'utils/routing';
import { themesSelector } from 'store/selectors/sites/themes';
import themeActions from 'store/actions/sites/themes';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themeid = state.sites.themes.params.themeid;

	const themes = themesSelector(state, siteid);

	return {
		siteid,
		themeid,
		themes,
		liveThemeId: state.sites.sites[siteid].activeTheme,
		stagedFrom: state.sites.themes.stagedFrom,
		stagedTheme: state.sites.themes.stagedTheme,
		pathname: state.router.location.pathname
	};
};

const mapDispatchToProps = dispatch => ({
	stageTheme: theme => dispatch(themeActions.stageTheme(theme))
});

class Design extends PureComponent {
	_themeExists() {
		const { themes, themeid } = this.props;

		return !!themes[themeid];
	}

	handleRoute() {	
		const { themes, siteid, liveThemeId, stagedTheme, themeid } = this.props;

		if (!this._themeExists()) {
			const defaultTheme = themes[liveThemeId];

			replaceRoute(`/dashboard/${siteid}/design/${defaultTheme._id}`);

			return;
		}

		if (stagedTheme === null) {
			const originTheme = themes[themeid];

			let themeContent = originTheme.draft;

			if (!themeContent) {
				themeContent = originTheme;
			} else {
				themeContent.name = originTheme.name;
				themeContent._id = originTheme._id;
			}

			this.props.stageTheme(themeContent);
		}
	}

	componentDidUpdate() {
		this.handleRoute();
	}

	componentDidMount() {
		this.handleRoute();
	}

	render() {
		const stickyHeaderStyling = {
			position: 'sticky',
			top: 56,
			left: 0,
			right: 0,
			height: 0,
			pointerEvents: 'none'
		}

		return (
			<div>
				{/* TO DO: revisit this later! */}
				{/* sticky app header - used by portal in AppHeader */}
				{/* sticky header is raised but underneath top-navigation */}
				<div
					id="sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 997 }}
				/>
				{/* fullscreen raises sticky header above everything else */}
				<div
					id="fullscreen-sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 1011 }}
				/>

				{/* app header */}
				<DesignHeader themes={this.props.themes} />
				
				{/* design */}
				{this.props.children || <Index />}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Design);
