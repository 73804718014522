import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';

class ContentContainer extends PureComponent {
	render() {
		const { node } = this.props;

		return node.children.map(childID => {
            return (
                <Node
                    key={childID}
                    nodeID={childID}
                    ContextConsumer={this.props.ContextConsumer}
                    freezeDragPath={this.props.freezeDragPath}
                    getPath={this.props.getPath}
                />
            );
        });
	}
}

export default ContentContainer;