import React from 'react';
import css from './toggle.scss';
import { ButtonGroup } from 'svift-ui';

const ToggleGroup = props => {
    return (
        <ButtonGroup
            size={props.size}
            fluid={props.fluid}
            className={css['toggle-group']}
        >
            {props.children}
        </ButtonGroup>
    );
}

export default ToggleGroup;