import React, { memo, useState, useEffect, useCallback } from 'react';
import { Button, Icon, Input, Flag } from 'svift-ui';
import css from './dropdown.scss';

const Wing = props => {
    return (
        props.collapsed
            ?
                <Icon
                    link
                    circular
                    name={props.icon}
                    className={`${css['wing-icon']} ${css[props.className]}`}
                    onClick={props.onClick}
                />
            :
                <button
                    disabled={!props.active} 
                    className={`${css['wing']} ${css[props.className]}`}
                    onClick={props.onClick}
                >
                    <Icon name={props.icon} className={css['wing-icon']} />
                </button>
    );
};

const Wings = props => {
    if (!props.useWings) return props.children;

    const noSelection = props.selectedIndex === -1;
    const noItems = !props.items || !props.items.length === 0;

    const canGoBack = props.selectedIndex > -1 && !(props.allowDeselect && props.selectedIndex === 0);
    const canGoForward = !noItems && noSelection || props.selectedIndex < props.items.length - 1;

    const goBack = () => {
        if (props.selectedIndex === 0) { 
            /* 
                Empty selection - up to the calling component to handle the undefined argument appropriately

                Thought about using null as the "no selection callback argument", but then it's no longer possible to have a null value as a dropdown item (which I have used before!)
            */
            if (props.allowDeselect) {
                props.onChange(); 
            }
        } else {
            props.onChange(props.items[props.selectedIndex - 1].value);
        }
    };

    const goForward = () => {
        if (noSelection) {
            if (props.items[0]) {
                props.onChange(props.items[0].value);
            }
        } else {
            props.onChange(props.items[props.selectedIndex + 1].value);
        }
    };

    return (
        <div className={!props.collapsed ? css['wings'] :  `${css['wings']} ${css['collapsed']}`}>
            <Wing
                collapsed={props.collapsed}
                active={canGoBack}
                onClick={goBack}
                icon="sn-arrow-left2"
                className="left-arrow"
            />
            
            <div className={css['wings-dropdown']} onClick={props.onClick}>
                {props.flag && <Flag name={props.flag} style={{ marginRight: 8 }} />}
                {props.children}
            </div>

            <Wing 
                collapsed={props.collapsed}
                active={canGoForward}
                onClick={goForward}
                icon="sn-arrow-right2"
                className="right-arrow"
            />
        </div> 
    );
};

const AddListItem = props => {
    const [newItem, setNewItem] = useState('');

    return (
        <div className={css['add-list-item-input']}>
            <Input 
                size="mini"
                onChange={e => setNewItem(e.target.value)} 
                placeholder="Add"
                style={{ width: 'calc(100% - 34px' }}
            />

            <Button size="mini" style={{ marginRight: 0, marginLeft: 4 }} icon positive onClick={() => {
                if (newItem.length > 0) {
                    props.onAddItem(newItem);
                }
            }}>
                <Icon name="sn-plus2" />
            </Button>
        </div>
    );
}

const Item = memo(props => {
    let isSelected; 

    if (props.allowMultiple) {
        isSelected = props.value && props.value.some(selectedValue => selectedValue === props.item.value);
    } else {
        isSelected = props.value === props.item.value;
    }

    return (
        <div
            className={`${css['list-item']} ${isSelected && css['selected']}`}
            onClick={() => {
                if (props.allowMultiple) {
                    if (isSelected) {
                        props.onChange(props.value.filter(selectedValue => selectedValue !== props.item.value));
                    } else {
                        const valueAlreadySelected = props.value && props.value.includes(props.item.value);

                        if (valueAlreadySelected) return;

                        props.onChange(props.value ? props.value.concat(props.item.value) : [props.item.value]);
                    }
                } else if (isSelected) {
                    if (props.allowDeselect) {
                        props.onChange();
                    }
                } else {
                    props.onChange(props.item.value);
                }
            }}
        >
            {/* item icon/flag + text */}
            <div className={css['text']}>
                {!props.item.flag
                    ?
                        props.item.icon && <Icon name={props.item.icon} style={{ marginRight: 6 }} />
                    :
                        <Flag name={props.item.flag} style={{ marginRight: 8 }} />
                } 
                {props.item.text}
            </div>
            
            {/* select checkmark icon */}
            <Icon
                name={isSelected ? 'sn-checkmark-circle' : 'sn-checkmark'}
                className="tint-color"
                style={{
                    margin: 'auto 0 auto 4px',
                    fontSize: isSelected ? 16 : 12,
                    height: isSelected ? 16 : 12
                }}
            />
        </div>
    );
});

const List = props => {
    return (
        <div 
            className={`${css['list']} ${css['animated']} ${css['fadeIn']} ${css['ultra-fast']}`}
            onClick={e => e.stopPropagation()}
            style={{ minWidth: '100%' }}
        >
            {props.allowAdditions && <AddListItem onAddItem={props.onAddItem} />}

            {props.items.map((item, i) => {
                return (
                    <Item 
                        key={item.key || item.value}
                        item={item}
                        allowMultiple={props.allowMultiple}
                        allowDeselect={props.allowDeselect}
                        onChange={props.onChange}
                        value={props.value}
                    />
                );
            })}
        </div>
    );
};

const Header = props => {
    let selectedText;
    let hasActiveSelection = true;

    if (props.allowMultiple) {
        if (props.selected.length === 0) {
            hasActiveSelection = false;
        } else if (props.selected.length === 1) {
            selectedText = props.selected[0].text;
        } else {
            selectedText = props.formatMultipleTitle ? props.formatMultipleTitle(props.selected) : props.selected.map(({ text }) => text).join(',');
        }
    } else if (props.selected) {
        selectedText = props.selected.text;
    } else {
        hasActiveSelection = false;
    }

    return (
        <React.Fragment>
            <div
                className={props.useWings ? css['header'] : css['dropdown']}
                onClick={props.onClick}
            >
                <div
                    className={hasActiveSelection ? css['text'] : `${css['text']} ${css['faded']}`}
                    style={{
                        display: 'inline-block',
                        width: 'calc(100% - 20px)', //width without down arrow
                    }}
                >
                    {hasActiveSelection
                        ? 
                            // selected item
                            // IDEA: add clear selection extension
                            <span style={{ verticalAlign: 'middle' }}>
                                {!props.selected.flag
                                    ?
                                        props.selected.icon && <Icon name={props.selected.icon} style={{ marginRight: 6, verticalAlign: 'middle' }} />
                                    :
                                        <Flag name={props.selected.flag} style={{ marginRight: 8 }} />
                                }
                                <span style={{ verticalAlign: 'middle' }}>{selectedText}</span>
                            </span>
                        :
                            // fallback item (nothing selected)
                            props.title
                    }
                </div>
                
                {/* dropdown arrow */}
                <Icon
                    name={props.open ? 'sn-arrow-up3' : 'sn-arrow-down3'}
                    style={{ marginLeft: 8, marginRight: 0, marginTop: 'auto', marginBottom: 'auto', fontSize: 10, height: 10 }}
                />
            </div>
            
        </React.Fragment>
    );
};

const Dropdown = props => {
    const [open, setOpen] = useState(false);

    const onChange = useCallback((...args) => {
        if (!props.allowMultiple) setOpen(false);

        props.onChange(...args);
    }, [props.onChange])
    
    useEffect(() => {
        if (open) {
            const handler = () => setOpen(false);
            
            window.addEventListener('click', handler);
            
            return () => window.removeEventListener('click', handler);
        }
    }, [open]);

    let selected;
    let selectedIndex;

    if (props.allowMultiple) {
        selected = props.items.filter(item => props.value && props.value.includes(item.value));
    } else {
        selectedIndex = props.items.findIndex(item => item.value === props.value); // We are interested in storing the selected index for browsing items in "winged" mode
        selected = props.items[selectedIndex];
    }

    return (
        <div 
            className={css['dropdown-wrapper']} 
            style={props.style}
        >
            <Wings 
                collapsed={props.collapsed}
                useWings={props.wings}
                allowDeselect={props.allowDeselect}
                selectedIndex={selectedIndex}
                onChange={props.onChange}
                items={props.items}
                onClick={() => setOpen(!open)}
            >
                <Header
                    useWings={props.wings}
                    title={props.title}
                    allowMultiple={props.allowMultiple}
                    formatMultipleTitle={props.formatMultipleTitle}
                    items={props.items} 
                    value={props.value}
                    selected={selected}
                    selectedIndex={selectedIndex}
                    open={open}
                    onClick={props.useWings ? () => {} /* hackymchacker */: () => setOpen(!open)}
                />
            </Wings>

            {open && 
                <List
                    allowAdditions={props.allowAdditions}
                    allowMultiple={props.allowMultiple}
                    allowDeselect={props.allowDeselect}
                    onAddItem={props.onAddItem}
                    items={props.items} 
                    onChange={onChange}
                    selected={selected}
                    value={props.value}
                />
            }
        </div>
    );
};

// Dropdown.propTypes = {
//     text: ''
// }

Dropdown.defaultProps = {
    text: 'No title',
    allowAdditions: false,
    allowMultiple: false,
    allowDeselect: false,
    onAddItem: false,
    wings: false,
    items: [
        {
            value: 'Taiwan numbah one',
            text: 'Bla bla remember to provide dropdown items',
            key: 'taiwan'
        }					
    ]
}

export default Dropdown;

/*
    Dropdown With Input

    Items should be held internally in state so they can be added 

    // items / addItems

    <Dropdown 
        items={[
            {
                value: 'Taiwan numbah one',
                text: 'Bla bla remember to provide dropdown items',
                key: 'taiwan'
            }	
        ]}
        // addItem={

        // }
    />
*/

/*
    Dropdown (DONE)

    Colors
        Color picker
        Color palette

    // Inline slider 

    Input 
        Number picker (has slider always, ranges are "recommendations", but can be overruled)

    Checkbox

    Radio (forklarende tekst)  
    Switch (multiple select)
    Switch (single select)

    FilePicker (convert existing)
    MultipleFilePicker
    
    Link
*/