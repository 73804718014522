import { createSelector } from 'reselect';

const selectLayoutIds = (state, siteid) => state.sites.sites[siteid].layouts;
const selectLayouts = state => state.sites.layout.layouts;
const selectType = (state, siteid, type = 'page') => type; 

const selector = createSelector(
	selectLayoutIds,
	selectLayouts,
	selectType,
	(layoutIds, layouts, type) => {
		const allLayouts = layoutIds.map(layoutId => layouts[layoutId]);

		if (type) {
			return allLayouts.filter(layout => layout.type === type)
		}

		return allLayouts;
	}
);

export default selector;