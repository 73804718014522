import React, { PureComponent } from 'react';

// import Header from 'containers/dashboard/side-navigation/sidenavigation-content/__components/Header';
import DesignNav from './design-index/Index';
import Elements from './elements/Elements';
import ThemeSettings from './__components/ThemeSettings';
import ThemeDropdown from './__components/ThemeDropdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';

import { Switch, Route } from 'react-router';
import { replaceRoute } from 'utils/routing';
import { connect } from 'react-redux';
import { themesSelector } from 'store/selectors/sites/themes';
import BackButton from 'containers/dashboard/side-navigation/sidenavigation-content/__components/BackButton';

import themeActions from 'store/actions/sites/themes';
import { Icon, Message } from 'svift-ui';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themeid = state.sites.themes.params.themeid;

	const themes = themesSelector(state, siteid);

	return {
		siteid,
		themeid,
		themes,
		stagedTheme: state.sites.themes.stagedTheme
	};
};

class SidenavigationDesign extends PureComponent {
	_themeExists() {
		const { themes, themeid } = this.props;

		return !!themes[themeid];
	}

	renderBackButton() {
		const { siteid, themeid, intl } = this.props;

		return (
			<BackButton
				text={intl.formatMessage({ id: 'design.back to design' })}
				route={`/dashboard/${siteid}/design/${themeid}`}
			/>
		);
	}

	render() {
		const { intl } = this.props;

		if (!this._themeExists()) return null;
		if (!this.props.stagedTheme) return null;

		return (
			<div>
				{ /* Header */ }
				<Switch>
					{/* <Route
						exact
						path="/dashboard/:siteid/design/:themeid?"
						render={() => (
							<div id="sidenav-header-section">
								<Header
									icon="sn-bucket"
									header={
										<span className="active-sidebar-header">
											<FormattedMessage id="design.app title" />
										</span>
									}
								/>
							</div>
						)}
					/> */}

					{/* <Route
						path="/dashboard/:siteid/design/:themeid/elements"
						render={() => (
							<div id="sidenav-header-section">
								{this.renderBackButton()}

								<Header
									icon="sn-marquee-plus"
									header={
										<span className="active-sidebar-header">
											<FormattedMessage id="elements.title" />
											<InfoIcon description={intl.formatMessage({ id: 'elements.elements overview description' })} />
										</span>
									}
								/>
							</div>
						)}
					/> */}
				</Switch>

				<ThemeDropdown />

				{/* <ThemeSettings /> */}

				{ /* Subroute content */ }
				<Switch>
					<Route
						exact
						path="/dashboard/:siteid/design/:themeid?"
						component={DesignNav}
					/>

					<Route
						path="/dashboard/:siteid/design/:themeid/elements"
						component={Elements}
					/>
				</Switch>
			</div>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(SidenavigationDesign));
