exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".feed__feed-link--2Gm6h{text-decoration:none}.feed__feed-link--2Gm6h:hover{text-decoration:underline}\n", ""]);

// exports
exports.locals = {
	"feed-link": "feed__feed-link--2Gm6h"
};