import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import createDebounce from 'svift-library/debounce';
import calculateServingUrlOptions from './calculateServingUrlOptions';

const useOnWindowChanged = callback => {
    useEffect(() => {
        window.addEventListener('resize', callback);
        window.addEventListener('orientationchange', callback);
            
        return () => {
            window.removeEventListener('resize', callback);
            window.removeEventListener('orientationchange', callback);
        };
    }, []);
};

const useSmartImage = (file, { fixedWidth, fixedHeight, addedUrlOptions = [] } = {}) => {
    const imgRef = useRef();
    const debounce = useMemo(() => createDebounce(400));
    const [state, setState] = useState({
        lastAppliedResolution: 0,
        urlOptionsFinal: null,
        urlOptions: ['s16', 'fSoften=1,100,0', 'e364', ...addedUrlOptions] // These options will fetch a lightweight version of the image initially
    });

    // console.log(state.urlOptions, 'url options')
    // const [finalState, setFinalState] = useState(null);
    
    const refetch = useCallback(() => {
        let elementWidth;
        let elementHeight;

        if (fixedWidth && fixedHeight) {
            elementWidth = fixedWidth;
            elementHeight = fixedHeight;
        } else {
            const imageRect = imgRef.current.getBoundingClientRect();

            elementWidth = imageRect.width;
            elementHeight = imageRect.height;
        }

        const { lastAppliedResolution, urlOptions: finalUrlOptions } = calculateServingUrlOptions({
            file,
            elementWidth,
            elementHeight,
            lastAppliedResolution: state.lastAppliedResolution
        });
    
        setState(prevState => ({
            ...prevState,
            lastAppliedResolution,
            urlOptionsFinal: finalUrlOptions.concat(addedUrlOptions)
        }));
    }, []);
    
    // On initial mount we'll instantly go fetch the higher resolution picture
    useEffect(() => {
        refetch();
    }, [/*imageProps*/]);
    
    // When browser window changes size we want to check if we need to fetch a higher resolution picture. Debounced to avoid excessive fetching.
    useOnWindowChanged(() => {
        debounce(refetch);
    });

    return {
        state,
        imgRef
    };
};

export default useSmartImage;