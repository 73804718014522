import React, { useState, useMemo, useRef } from 'react';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { Icon, Message, Loader, Label } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import config from 'config';
import uuid from 'uuid/v4';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import useRendererData from 'svift-library/client/renderer/data/client/useRendererData';
import useRendererState from 'svift-library/client/renderer/useRendererState';
import PreviewContainer from 'components/editor-v2/editor-interface/preview/PreviewContainer';
import ToggleDraftAndLivePreview from 'components/editor-v2/editor-interface/preview/ToggleDraftAndLivePreview';
import OpenPreview from 'components/editor-v2/editor-interface/preview/OpenPreview';
import createExtensions from 'components/editor-v2/extend';
import { connect } from 'react-redux';
import SviftGA from 'utils/svift-ga';
// import pageActions from 'store/actions/sites/pages';
// import SelectPage from './SelectPage';
// import EditPage from './EditPage';
// import PageForm from './PageForm';
// import PageSettings from './PageSettings';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import useDimensions from 'svift-library/client/renderer/useDimensions';
import { filesInActiveFolder } from 'store/selectors/sites/files';

const mapStateToProps = (state, ownProps) => {
    const { stagedTheme, stagedFrom, view } = state.sites.themes;

    return {
        files: filesInActiveFolder(state),
		previewMode: view
    };
};

const RendererData = connect(mapStateToProps)(props => {
    const { data, error, errorMessage, loading } = useRendererData({
        siteID: props.siteID,
        contentID: props.pageID,
        contentType: 'page',
        isAdmin: true,
        host: config.coreDomain,
        basePath: '/sites/renderer',
        refetchOnValueChange: [props.siteID, props.pageID, props.files]
    });

    const deviceRef = useRef();
    const device = useDimensions(deviceRef);

    if (loading) return (
        <Message size="massive" className="info" style={{ padding: '64px 24px 96px 24px', textAlign: 'center' }}>
            {/* loading message */}
            <div style={{ marginTop: 0, marginBottom: 40 }}>
                <div>
                    <Icon
                        name="sn-eye"
                        style={{
                            margin: '0 0 24px 0',
                            fontSize: 96,
                            width: 96,
                            opacity: 0.5
                        }}
                    />
                </div>
                <span style={{ fontSize: 40 }}>
                    <FormattedMessage id="pages.loading page" />
                </span>
            </div>
            {/* load spinner */}
            <Loader size="massive" className="info" />
        </Message>
    );
    if (error) return errorMessage;

    return React.cloneElement(props.children, {
        data,
        device,
        deviceRef,
        ...props
    });
});

// const mapDispatchToProps = dispatch => ({
// 	publishDraft: (siteid, pageid, nodeMap) => dispatch(pageActions.publishDraft(siteid, pageid, nodeMap)),
// 	saveDraft: (siteid, pageid, nodeMap) => dispatch(pageActions.saveDraft(siteid, pageid, nodeMap))
// });

const ThemePreviewRenderer = injectIntl(({ data, previewMode, deviceRef, device, intl, theme }) => {
    const { extend, renderAtRoot } = useMemo(() => {
        return createExtensions(previewMode);
    }, [previewMode]);

    const contentProps = useMemo(() => {
        const props = {
            ...data,
            device,
            resolveContent: state => state.content.content,
            extend,
            theme
        };

        // if (previewMode === null) {
        //     props.extend = {
        //         ...extend,
        //         all: extendAll,
        //     };
        // }

        // if (previewMode === 'draft' || previewMode === null) {
        //     props.useDraft = true;
        // }

        return props;
    }, [data, previewMode, device, theme]);

    const contentState = useRendererState(contentProps);

    const resolvePlaceholder = useMemo(() => { 
        return () => (
            <Renderer 
                {...contentState}
                renderAtRoot={renderAtRoot}
            />
        );
    }, [contentState, previewMode, theme]);
    
    const layoutProps = useMemo(() => {
        return {
            ...data,
            theme,
            device,
            extend,
            resolveContent: state => state.layout.content,
            resolvePlaceholder
        };
    }, [data, resolvePlaceholder, device, theme]);

    // React.useEffect(() => console.log(theme, 'theme lol'), [theme]);
    const layoutState = useRendererState(layoutProps);

    const renderPreview = useMemo(() => {
        return () => (
            <Renderer
                key={previewMode}
                {...{
                    ...layoutState,
                    rendererState: {
                        ...layoutState.rendererState,
                        resolvePlaceholder: () => {
                            return (
                                <Renderer 
                                    {...{
                                        ...contentState,
                                        rendererState: {
                                            ...contentState.rendererState,
                                            nodeMap: data.content.content.live.nodeMap,
                                            breakpoints: data.content.content.live.breakpoints,
                                            // theme
                                        }
                                    }}
                                />
                            );
                        }
                    }
                }}
            />
        );
    }, [layoutState, contentState]);

    return (
        <PreviewContainer ref={deviceRef} active>
            {renderPreview()}
        </PreviewContainer>  
    );

    return 
    // const renderPreview = useMemo(() => {
    //     return () => (
    //         <Renderer
    //             key={previewMode}
    //             {...{
    //                 ...layoutState,
    //                 rendererState: {
    //                     ...layoutState.rendererState,
    //                     resolvePlaceholder: () => {
    //                         const { all, ...rest } = contentState.rendererState.extend;

    //                         if (previewMode === 'live') {
    //                             return (
    //                                 <Renderer 
    //                                     {...{
    //                                         ...contentState,
    //                                         rendererState: {
    //                                             ...contentState.rendererState,
    //                                             nodeMap: data.content.content.live.nodeMap,
    //                                             breakpoints: data.content.content.live.breakpoints,
    //                                             extend: rest
    //                                         }
    //                                     }}
    //                                 />
    //                             );
    //                         }

    //                         return (
    //                             <Renderer 
    //                                 {...{
    //                                     ...contentState,
    //                                     rendererState: {
    //                                         ...contentState.rendererState,
    //                                         extend: rest
    //                                     }
    //                                 }}
    //                             />
    //                         )
    //                     }
    //                 }
    //             }}
    //         />
    //     );
    // }, [contentState, previewMode])

    // return renderPreview();
});

export default props => {
    return (
        <RendererData {...props}>
            <ThemePreviewRenderer />
        </RendererData>
    );
};