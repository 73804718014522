import React, { PureComponent } from 'react';
import { Form, FormInput, FormField, Message, Button, Icon } from 'svift-ui';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate, { string } from 'components/forms/__util/validate';
import input from 'components/forms/__util/field-components/input';
import Label from 'components/option-library/label';

// import input from 'components/forms/__util/field-components/input';

const SmallInput = props => {
	const { placeholder, label, intl, meta: { error } } = props;

	const className = error && 'error';

	return (
		<FormField>
			<FormInput
				{...props.input}
				label={label && intl.formatMessage({ id: label })}
				className={className}
				type="password"
				icon="sn-key"
				size="small"
				iconPosition="left"
				placeholder={intl.formatMessage({ id: placeholder })}
			/>

			{error &&
				<Message negative>
					{error(intl)}
				</Message>
			}
		</FormField>
	);
};

const fields = {
	password: {
		component: input({
			type: 'password',
		}),
		validate: string(4, 255)
	},
	newPassword: {
		component: input({
			type: 'password',
		}),
		validate: string(4, 255)
	},
	newPasswordRepeat: {
		component: input({
			type: 'password',
		}),
		validate: string(4, 255)
	}
};

const validateFields = validate(fields);

class PasswordForm extends PureComponent {
	constructor() {
		super();

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validateFields(values);

		const { newPassword, newPasswordRepeat } = values;

		if (newPassword !== newPasswordRepeat) {
			throw new SubmissionError({
				_error: this.props.intl.formatMessage({ id: 'registration.make sure to repeat your password correctly' })
			});
		}

		await this.props.onSubmit(values);
	}

	render() {
		const { handleSubmit, intl } = this.props;

		return (
			<Form onSubmit={handleSubmit(this.submit)}>
				<Label
					label={intl.formatMessage({ id: 'profile.old password' })}
					labelIcon="sn-lock5"
				>
					<Field
						fluid
						size="mini"
						name="password"
						placeholder={intl.formatMessage({ id: 'profile.enter old password' })}
						icon="sn-key"
						iconPosition="left"
						intl={intl}
						component={fields.password.component}
					/>
				</Label>

				<Label
					label={intl.formatMessage({ id: 'profile.new password' })}
					labelIcon="sn-key"
				>
					<Field
						fluid
						size="mini"
						name="newPassword"
						placeholder={intl.formatMessage({ id: 'profile.enter new password' })}
						icon="sn-key"
						iconPosition="left"
						intl={intl}
						component={fields.newPassword.component}
						/>

					<Field
						fluid
						size="mini"
						name="newPasswordRepeat"
						placeholder={intl.formatMessage({ id: 'profile.confirm new password' })}
						icon="sn-key"
						iconPosition="left"
						intl={intl}
						component={fields.newPasswordRepeat.component}
					/>
				</Label>

				{this.props.error &&
					<Message negative>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				<Button fluid positive size="tiny"><Icon name="sn-cloud-upload" />
					<FormattedMessage id="profile.save changes" />
				</Button>
			</Form>
		);
	}
}

const ReduxPasswordForm = reduxForm({
	form: 'registration'
})(injectIntl(PasswordForm));

ReduxPasswordForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default ReduxPasswordForm;