import React, { useState, memo } from 'react';
import Section from '../../Section';
import Label from 'components/option-library/label';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Text from 'components/option-library/primitives/input/text';
import TextArea from 'components/option-library/primitives/input/text-area';
import Checkbox from 'components/option-library/primitives/checkbox';
import FilePicker from 'components/option-library/primitives/file';
import {
	Button,
	Icon,
	Segment,
	SegmentGroup,
	Message
} from 'svift-ui';
import Tree from 'components/tree/Tree';
import { pagesToMenuTree } from 'utils/pages/format';
import { FormattedMessage, FormattedDate, injectIntl, intlShape } from 'react-intl';
import { pagesSelector } from 'store/selectors/sites/pages';
import { blogsSelector } from 'store/selectors/sites/blogs';
import { connect } from 'react-redux';
import css from '../../options.scss';
import LinkPreview from './LinkPreview';
import { filesDictionarySelector } from 'store/selectors/sites/files';
import key from 'svift-library/key';
// import urlConverter from 'components/forms/__util/validate/urlConverter';

const createLinkTypes = intl => {
    return [
        {
			value: 'external',
			text: intl.formatMessage({ id: 'options.link url title' }),
			icon: 'sn-earth',
			key: 'external'
		},
		{
			value: 'page',
			text: intl.formatMessage({ id: 'options.link page title' }),
			icon: 'sn-stack-text',
			key: 'page'
		},
		{
			value: 'blogPost',
			text: intl.formatMessage({ id: 'options.link blog title' }),
			icon: 'sn-blog',
			key: 'blog'
		},
		{
			value: 'file',
			text: intl.formatMessage({ id: 'options.link file title' }),
			icon: 'sn-folder6',
			key: 'file'
		}
    ]
}

const getInitialState = value => {
	if (value) {
		return {
			type: 'external', // Legacy support - gets overriden if the value has a different type anyway
			...value
		};
	}

	return {
		type: 'external'
	};
};

const formatContentForTree = (contentType = 'page', items) => {
	if (contentType === 'page') {
		const activePages = items.filter(page => !page.hidden);

		return pagesToMenuTree(activePages, null, page => page.title);
	} else if (contentType === 'blogPost') {
		return items.sort((a, b) => {
			if (typeof a.publishAt === 'undefined') return 1;
			if (typeof b.publishAt === 'undefined') return -1;

			return new Date(b.publishAt) - new Date(a.publishAt);
		}).map(blog => {
			return {
				data: blog,
				items: []
			};
		});
	}
}

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		pages: pagesSelector(state, siteid),
		blogs: blogsSelector(state, siteid),
		filesDictionary: filesDictionarySelector(state, siteid),
		blogModuleEnabled: key('modules.blog')(site)
	};
};

const Link = injectIntl(memo(props => {
	// console.log(props.value, 'initial value');
	
	const initialState = getInitialState(props.value);
	// console.log(initialState, 'initial state');
	const [changingLink, setChangingLink] = useState(!props.value);
	const [linkType, setLinkType] = useState(initialState.type);
	const [linkState, setLinkState] = useState(initialState);
	
	const onSave = () => {
		const newLink = {
			type: linkType,
			...linkState[linkType]
		};

		props.onChange(newLink, props.intl.formatMessage({ id: 'options.add link' }), false);

		setChangingLink(false);
	}

	const removeLink = () => {
		props.onChange();
	};

	// const [lastValue, setLastValue] = useState(props.value);
    // 
    //     When we save we want to "solidify" the value in state (lastValue !== props.value is only true when we save)
    // 
    // if (lastValue !== props.value) {
		//     setLinkState(props.value);
		//     setLastValue(props.value);
		// }
		
	const showSelectedLink = props.value && !changingLink;

    return (
        <Section
            {...props} // Passing on accordion props (Link is wrapped in an accordion)
            title={props.intl.formatMessage({ id: 'options.link title' })}
            icon="sn-link"
        >
			{props.disabled
				?
					// link option disabled (if link is not possible to define)
					<Message className="info">
						<Icon name="sn-mouse-left" style={{ fontSize: 32, float: 'left', marginRight: 4 }} />
						{props.disabledMessage || props.intl.formatMessage({ id: 'options.link no text selected message' })}
					</Message>
				:
					showSelectedLink 
						? 
							// selected link message
							<LinkPreview
								value={props.value}
								pages={props.pages}
								blogs={props.blogs}
								filesDictionary={props.filesDictionary}
								setChangingLink={setChangingLink}
								removeLink={removeLink}
							/>
						:
							// link options
							<React.Fragment>
								<Label
									collapsed
									label={props.intl.formatMessage({ id: 'options.link type title' })}
									labelIcon="sn-link"
									labelDescription={props.intl.formatMessage({ id: 'options.link type description' })}
								>		
									<Dropdown
										wings
										title="Put your link here!!!" // never shows anyway
										items={createLinkTypes(props.intl)}
										value={linkType}
										onChange={type => setLinkType(type)}            
									/>
								</Label>

								{/* link type panel outline */}
								<div className={css['panel-outline']}>
									{(() => {
										switch (linkType) {
											case 'external': {
												return <ExternalLink linkType={linkType} linkState={linkState} setLinkState={setLinkState} />;
											}
											
											case 'page': {
												return <PageLink pages={props.pages} linkType={linkType} linkState={linkState} setLinkState={setLinkState} />;
											}

											case 'blogPost': {
												return <BlogLink blogModuleEnabled={props.blogModuleEnabled} blogs={props.blogs} linkType={linkType} linkState={linkState} setLinkState={setLinkState} />;
											}

											case 'file': {
												return <FileLink linkType={linkType} linkState={linkState} setLinkState={setLinkState} />;
											}
										} 
									})()}
								</div>
								
								{/* set link action */}
								<Button
									icon
									fluid
									size="small"
									className="positive"
									onClick={onSave}
								>
									<Icon name="sn-checkmark3" style={{ marginRight: 2 }} />
									<Icon name="sn-link" />
									<span style={{ marginLeft: 8 }}>
										<FormattedMessage id="options.add link" />
									</span>
								</Button>
								
								{/* cancel change link action */}
								{changingLink && props.value && 
									<Button
										icon
										fluid
										basic
										size="small"
										// onClick={onSave}
										onClick={() => setChangingLink(false)}
										style={{ marginTop: 4 }}
									>
										<Icon name="sn-cross3" />
										<span style={{ marginLeft: 8 }}>
											<FormattedMessage id="general.cancel" />
										</span>
									</Button>
								}
							</React.Fragment>
			}
        </Section>
    );
}));

const ExternalLink = injectIntl(({ linkState, linkType, setLinkState, intl }) => {
	const externalLinkState = linkState[linkType] || {};

    return (
        <React.Fragment>
            <Label
				labelIcon="sn-earth"
                label={intl.formatMessage({ id: 'options.link url address input label' })}
                labelDescription={intl.formatMessage({ id: 'options.link url address input description' })}
            >				
				<Text
					fluid
					size="mini"
					placeholder="https://, www, mailto..."
					value={externalLinkState.url}
					// value={state.external.url}
					onChange={value => {
						setLinkState({
							...linkState,
							[linkType]: {
								...externalLinkState,
								url: value
							}
						});
						// setState({
						//     external: {
						//         ...state.external,
						//         url: data.value
						//     }
						// });
					}}
				/>
			</Label>
			
			<Label
				labelIcon="sn-bubble-lines4"
				label={intl.formatMessage({ id: 'options.link summary' })}
				labelDescription={intl.formatMessage({ id: 'options.link summary description' })}
			>		
				<TextArea
					value={externalLinkState.description}
					// value={state.external.description}
					onChange={value => {
						setLinkState({
							...linkState,
							[linkType]: {
								...externalLinkState,
								description: value
							}
						});
						// setState({
						//     external: {
						//         ...state.external,
						//         description: data.value
						//     }
						// });
					}}
					// placeholder={intl.formatMessage({ id: 'options.add link summary' })}
					// style={{ width: '100%', minWidth: '100%', maxWidth: '100%', minHeight: 48, height: 48, padding: 8, fontSize: 12 }}
				/>
			</Label>

			<Checkbox
				icon="sn-new-tab"
				label={intl.formatMessage({ id: 'options.open link in new window' })}
				value={externalLinkState.newWindow}
				// value={state.external.newWindow}
				onChange={checked => {
					setLinkState({
						...linkState,
						[linkType]: {
							...externalLinkState,
							newWindow: checked
						}
					});
					// setState({
					//     external: {
					//         ...state.external,
					//         newWindow: checked
					//     }
					// });
				}}
				style={{ marginBottom: 8 }}
			/>
        </React.Fragment>
    );
})

// TO DO: do not display blog posts if Blog module is not activated
const BlogLink = injectIntl(({ intl, linkType, linkState, setLinkState, blogs, blogModuleEnabled }) => {
	const blogLinkState = linkState[linkType] || {};
	
	if (!blogModuleEnabled || blogs.length === 0) {
		return (
			<Message className="warning">
				<div style={{ textAlign: 'center' }}>
					<Icon name="sn-eye-blocked" style={{ fontSize: 24, opacity: 0.6, marginBottom: 6, marginRight: 0 }} />
				</div>
				<div>
					<FormattedMessage id="blog.no blog post links" />
				</div>
			</Message>
		);
	}

	// if (!blogModuleEnabled || blogs.length === 0) {
	// 	return ''
	// }
	// console.log(blogs, 'blogs');

    const selectedBlog = blogs.find(({ _id }) => _id === blogLinkState.blogPost);

    return (
		// <div
		// 	style={{
		// 		zIndex: 99,
		// 		position: 'sticky',
		// 		padding: 4,
		// 		top: 0,
		// 		left: 0,
		// 		right: 0,
		// 		backgroundColor: 'white',
		// 		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
		// 	}}
		// >
		// 	<Label
		// 		size="medium"
		// 		className="info"
		// 		style={{ width: '100%' }}
		// 	>
		// 		<Icon name="sn-link" style={{ marginRight: 4, verticalAlign: 'bottom' }} />
		// 		{selectedBlogTitle}<br />
		// 		<span
		// 			style={{
		// 				fontSize: 10,
		// 				fontWeight: 300,
		// 				opacity: 0.6,
		// 				marginLeft: 16
		// 				}}
		// 			>
		// 				.../{urlConverter(selectedBlogTitle)}
		// 		</span>
		// 	</Label>
		// </div>
		
		<Label
			labelIcon="sn-link"
			label={intl.formatMessage({ id: 'options.link blog label' })}
			labelDescription={intl.formatMessage({ id: 'options.link blog description' })}
		>	
			<div style={{ padding: '4px 4px 8px 4px', maxHeight: 160, overflowY: 'auto', border: '1px solid #AAA', borderRadius: 4 }}>
				<Tree
					items={formatContentForTree('blogPost', blogs)}
					TextComponent={props => {
						const publishAt = props.item.data.publishAt;
						// const isPublished = new Date(publishAt) > ;

						return (
							<span>
								<Icon name="sn-file-text" className="primary" />

								{props.item.data.title}
								
								{publishAt
									?
										<FormattedDate
											value={new Date(publishAt)}
											year='numeric'
											month='long'
											day='2-digit'
										/>
									:
										'ikke udgivet TRANSL8'
								}
							</span>
						);
					}}
					ItemGroupComponent={props => <SegmentGroup {...props} size="mini" className="svift-pages-list" />}
					ItemComponent={props => {
						const { path, item } = props;
						const blogPost = item.data;

						return (
							<Segment 
								{...props} 
								className={blogLinkState.blogPost === blogPost._id ? 'active-item' : ''}
								onClick={e => {
									e.stopPropagation();

									const blogPost = item.data;

									setLinkState({
										...linkState,
										[linkType]: {
											...blogLinkState,
											blogPost: blogPost._id
										}
									});
								}}
								
								// onClick={e => {
								// 	e.stopPropagation();

								// 	const blogPost = item.data;

								// 	this.setState({
								// 		internal: {
								// 			...this.state.internal,
								// 			type: 'blogPost',
								// 			blogPost: blogPost._id
								// 		}
								// 	});
								// }}
							/>
						);
					}}
				/>
			</div>

			{selectedBlog &&
				<Message className="primary" size="mini" style={{ padding: '6px 12px', marginTop: 4, marginBottom: 0 }}>
					<div>
						<FormattedMessage id="general.you have selected" />:
					</div>
					<Icon name="sn-magazine" style={{ marginRight: 6 }} />
					<span style={{ fontWeight: 500 }}>{selectedBlog.title}</span>
				</Message>
			}
		</Label>
    );
});

const PageLink = injectIntl(({ linkType, linkState, setLinkState, pages, intl }) => {
	const pageLinkState = linkState[linkType] || {};

	const selectedPage = pages.find(page => page._id === pageLinkState.page);

    return (
		// {selectedPageTitle &&
		//     <div
		//         style={{
		//             zIndex: 99,
		//             position: 'sticky',
		//             padding: 4,
		//             top: 0,
		//             left: 0,
		//             right: 0,
		//             backgroundColor: 'white',
		//             boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
		//         }}
		//     >
		//         <Label
		//             size="medium"
		//             className="info"
		//             style={{ width: '100%' }}
		//         >
		//             <Icon name="sn-link" style={{ marginRight: 4, verticalAlign: 'bottom' }} />

		//             {selectedPageTitle}<br />

		//             <span
		//                 style={{
		//                     fontSize: 10,
		//                     fontWeight: 300,
		//                     opacity: 0.6,
		//                     marginLeft: 16
		//                     }}
		//                 >
		//                     .../{urlConverter(selectedPageTitle)}
		//             </span>
		//         </Label>
		//     </div>
		// }
		

		<Label
			collapsed
			labelIcon="sn-link"
			label={intl.formatMessage({ id: 'options.link page label' })}
			labelDescription={intl.formatMessage({ id: 'options.link page description' })}
		>
			<div style={{ padding: '4px 4px 8px 4px', maxHeight: 160, overflowY: 'auto', border: '1px solid #AAA', borderRadius: 4 }}>
				<Tree
					items={formatContentForTree('page', pages)}
					TextComponent={props => <span><Icon name="sn-file-text" className="primary" /> {props.text}</span>}
					ItemGroupComponent={props => <SegmentGroup {...props} size="mini" className="svift-pages-list" />}
					ItemComponent={props => {
						const { path, item } = props;
						const page = item.data;

						// const url = path.reduce((acc, parentItem) => {
						// 	return `${acc}${parentItem.text}/`;
						// }, '/') + item.text;

						return (
							<Segment 
								{...props} 
								className={pageLinkState.page === page._id ? 'active-item' : ''}
								onClick={e => {
									e.stopPropagation();

									const page = item.data;

									setLinkState({
										...linkState,
										[linkType]: {
											...pageLinkState,
											page: page._id
										}
									});
								}}
								// onClick={e => {
								// 	e.stopPropagation();

								// 	const page = item.data;

								// 	this.setState({
								// 		internal: {
								// 			...this.state.internal,
								// 			type: 'page',
								// 			page: page._id
								// 		}
								// 	});
								// }}
							/>
						);
					}}
				/>
			</div>

			{selectedPage &&
				<Message className="primary" size="mini" style={{ padding: '6px 12px', marginTop: 4, marginBottom: 0 }}>
					<div>
						<FormattedMessage id="general.you have selected" />:
					</div>
					<Icon name="sn-file-text" style={{ marginRight: 6 }} />
					<span style={{ fontWeight: 500 }}>{selectedPage.title}</span>
				</Message>
			}
		</Label>
    );
});

const FileLink = ({ linkType, linkState, setLinkState }) => {
	const fileState = linkState[linkType];

	return (
		<FilePicker
			tabs={['files']}
			// multiple
			filter={file => true /*file.meta.mimeType.startsWith('image')*/}
			onChange={file => {
				setLinkState({
					...linkState,
					[linkType]: {
						id: file
					}
				});
			}}
			value={fileState && fileState.id}
		/>
	);
};

export default connect(mapStateToProps)(Link);