import React, { PureComponent, useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
// import Tooltip from 'components/tooltip/Tooltip';
import { Popup as SviftPopup } from 'svift-library/client/tooltip';
import {
    Button,
    Icon,
    Header,
    Divider,
    Grid,
    GridRow,
    GridColumn,
    Label
} from 'svift-ui';
import actions from 'store/actions/ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalWrapper from 'components/modal/ModalWrapper';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

// import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';

const setStepByStepKey = (id, value) => {
    return localStorage.setItem(`StepByStep_${id}`, value);
};

const getStepByStepKey = id => {
    return localStorage.getItem(`StepByStep_${id}`);
};

const mapStateToProps = state => {
    return {
        stepByStepVisible: state.ui.stepByStepVisible,
        pathname: state.router.location.pathname
    };
};

const mapDispatchToProps = dispatch => ({
    toggleStepByStep: bool => dispatch(actions.toggleStepByStep(bool))
});

const getInitialState = props => {
    return {
        currentStep: props.stepByStepVisible ? 0 : null,
        target: null,
        askUserToBegin: getStepByStepKey(props.id) !== 'seen',
        noBack: false
    };
};

// Ensures all required steps have rendered before starting the flow, and also filters away steps that aren't present
const StepFilter = Component => props => {
    const requiredSteps = useMemo(
        () => props.steps.filter(step => step.required),
        []
    );
    const [allStepsPresent, setAllStepsPresent] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const requiredStepsDidRender = requiredSteps.every(step => {
                const stepElementExists = Array.isArray(step.targetElementId)
                    ? step.targetElementId.some(
                          targetId => !!document.getElementById(targetId)
                      )
                    : !!document.getElementById(step.targetElementId);

                return stepElementExists;
            });

            if (requiredStepsDidRender) {
                setAllStepsPresent(true);

                clearInterval(interval);
            }
        }, 250);

        return () => clearInterval(interval);
    }, []);

    const filteredSteps = React.useMemo(() => {
        return props.steps.filter(step => {
            const stepElementExists = Array.isArray(step.targetElementId)
                ? step.targetElementId.some(
                      targetId => !!document.getElementById(targetId)
                  )
                : !!document.getElementById(step.targetElementId);

            return stepElementExists;
        });
    }, [allStepsPresent, props.stepByStepVisible]);

    // console.log(allStepsPresent, filteredSteps, '??')

    if (!allStepsPresent || filteredSteps.length === 0) return null;

    return <Component {...props} steps={filteredSteps} />;
};

class StepByStep extends PureComponent {
    constructor(props) {
        super(props);

        this.prevRef = React.createRef();
        this.nextRef = React.createRef();
        this.closeRef = React.createRef();

        this.state = getInitialState(props);
    }

    componentDidMount() {
        if (this.state.askUserToBegin && !this.props.stepByStepVisible)
            this.props.toggleStepByStep(true);

        this.handleAsyncTarget();
    }

    componentDidUpdate(_, lastState) {
        if (this.state.askUserToBegin && !this.props.stepByStepVisible)
            this.props.toggleStepByStep(true);
        if (lastState.target !== this.state.target) this.forceUpdate(); // Forces tooltip to rerender with the newly found target

        this.handleAsyncTarget();
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    // State slowly became excessively complicated in this component - if time permits (and if it's necessary) I suggest raising state out of StepByStep and handling it in one place (rather than reacting to stepByStepVisible flag changing in CWRP)
    componentWillReceiveProps(nextProps) {
        if (this.timeout) clearTimeout(this.timeout); // We don't want any potential queued timeouts to interfere with our logic, so for good measure we'll clear it here

        const routeChanged = this.props.pathname !== nextProps.pathname;
        const becameVisible =
            !this.props.stepByStepVisible && nextProps.stepByStepVisible;

        if (becameVisible || routeChanged) {
            this.setState({
                ...getInitialState(nextProps)
            });
        } else if (
            this.props.stepByStepVisible &&
            !nextProps.stepByStepVisible
        ) {
            this.setState({
                currentStep: null,
                target: null
            });
        }
    }

    toggleStepByStep = bool => {
        // Whenever we toggle step by step (except when read from localStorage - see constructor), we will treat this step as seen and save that in localStorage
        if (this.props.id) setStepByStepKey(this.props.id, 'seen');

        this.props.toggleStepByStep(bool);
    };

    getTarget = target => {
        if (Array.isArray(target)) {
            for (let i = 0; i < target.length; i++) {
                const element = document.getElementById(target[i]);

                if (element) return element;
            }

            return null;
        }

        return document.getElementById(target);
    };

    // We want step to step to function even if a step target hasnt rendered yet - this will retry every x ms until the target is found
    handleAsyncTarget = () => {
        if (this.state.currentStep !== null && !this.state.target) {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                const step = this.props.steps[this.state.currentStep];

                const target = this.getTarget(step.targetElementId);

                if (target) {
                    this.setState({
                        target
                    });
                } else {
                    this.handleAsyncTarget();
                }
            }, 50);
        }
    };

    setNextStep = (nextStep, { noBack } = {}) => {
        const step = this.props.steps[nextStep];

        // Some steps may need to be skipped depending on a conditional (e.g. Design tile not relevant in flow for a text writer)
        // if (step.conditional && !step.conditional()) {
        //     const goingBackwards = nextStep < this.state.currentStep;

        //     if (goingBackwards) {
        //         nextStep =  nextStep - 1; // Has to be more advanced to handle some edge cases like the first step returning false in conditional, causing us to go back to step -1 instead of 0
        //     } else {
        //         nextStep = nextStep + 1;
        //     }
        // }

        // console.log(this.props.steps, 'steps')

        // if (step) { //??
        this.setState({
            noBack,
            currentStep: nextStep,
            nextIconHovered: false,
            prevIconHovered: false,
            closeIconHovered: false,
            askUserToBegin: false,
            target: this.getTarget(step.targetElementId) //document.getElementById(step.targetElementId)
        });
        // }
    };

    renderControls = () => {
        const step = this.props.steps[this.state.currentStep];

        const isFirstStep = this.state.currentStep === 0;
        const isLastStep =
            this.props.steps.length - 1 === this.state.currentStep;
        // const prevStepPopup = useTooltip();
        // const nextStepPopup = useTooltip();

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                {/* Back button */}
                <SviftPopup
                    target={this.prevRef.current /*prevStepPopup.current*/}
                    open={this.state.prevButtonHovered}
                    position="bottom left"
                    zIndex={1099}
                >
                    <FormattedMessage id="general.back" />
                </SviftPopup>
                <Button
                    icon
                    circular
                    basic
                    size="mini"
                    className="info"
                    ref={this.prevRef /*prevStepPopup*/}
                    onMouseEnter={e =>
                        this.setState({ prevButtonHovered: true })
                    }
                    onMouseLeave={e =>
                        this.setState({ prevButtonHovered: false })
                    }
                    disabled={isFirstStep || this.state.noBack}
                    onClick={e => {
                        e.stopPropagation();
                        this.setNextStep(this.state.currentStep - 1);
                    }}
                    style={
                        isFirstStep || this.state.noBack
                            ? { display: 'none' }
                            : { marginLeft: -8 }
                    }
                >
                    <Icon name="sn-arrow-left8" />
                </Button>

                {/* Next button */}
                <div style={{ display: 'inline-block', marginLeft: 'auto' }}>
                    {/* {isLastStep && (
                        <Label
                            size="small"
                            basic
                            className="info"
                            style={{ marginRight: 8 }}
                        >
                            <span style={{ marginRight: 8 }}>
                                <FormattedMessage id="general.finish" />
                            </span>
                            <Icon
                                onClick={() => this.toggleStepByStep(false)}
                                name="sn-arrow-right8"
                                style={{ marginRight: 0 }}
                            />
                        </Label>
                    )} */}

                    <SviftPopup
                        target={this.nextRef.current /*nextStepPopup.current*/}
                        open={this.state.nextButtonHovered}
                        position="bottom right"
                        zIndex={1099}
                    >
                        <FormattedMessage
                            id={isLastStep ? 'general.finish' : 'general.next'}
                        />
                    </SviftPopup>
                    <Button
                        onMouseEnter={e =>
                            this.setState({ nextButtonHovered: true })
                        }
                        onMouseLeave={e =>
                            this.setState({ nextButtonHovered: false })
                        }
                        icon
                        circular
                        ref={this.nextRef /*StepPopup*/}
                        basic={isLastStep ? false : true}
                        size="mini"
                        className={isLastStep ? 'secondary' : 'primary'}
                        style={{ marginRight: -8 }}
                        onClick={e => {
                            e.stopPropagation();

                            if (isLastStep) {
                                this.toggleStepByStep(false);
                            } else {
                                this.setNextStep(this.state.currentStep + 1);
                            }
                        }}
                    >
                        <Icon
                            name={
                                isLastStep
                                    ? 'sn-eye-blocked'
                                    : 'sn-arrow-right8'
                            }
                        />
                        {isLastStep && (
                            <span style={{ marginLeft: 6 }}>
                                <FormattedMessage id="general.do not show again" />
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        );
    };

    // renderCoverPortal = () => {
    //     return createPortal(
    //         <div
    //             id="cover-step-by-step"
    //             onClick={(e) => {e.stopPropagation();e.nativeEvent.stopImmediatePropagation();}}
    //             style={{
    //                 position: 'fixed',
    //                 content: 'something',
    //                 zIndex: 1997, // just under tooltip,
    //                 width: '100%',
    //                 height: '100%',
    //                 background: 'transparent',
    //                 left: 0,
    //                 top: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 cursor: 'default'
    //             }}
    //         />,
    // 		document.getElementById('app-container')
    //     )
    // }

    renderAskUserToBeginModal = () => {
        const { intl, modalHeaderTranslationKey, modalContent } = this.props;

        return (
            <ModalWrapper
                open
                header={intl.formatMessage({ id: modalHeaderTranslationKey })}
                style={{ maxWidth: 640 }}
                handleClose={() => {
                    this.setNextStep(this.props.steps.length - 1, {
                        noBack: true
                    });
                }}
            >
                <div style={{ paddingBottom: 32, fontWeight: 400 }}>
                    {modalContent}
                </div>

                <Grid padded columns={2}>
                    <GridColumn textAlign="left">
                        <Button
                            size="big"
                            floated="left"
                            className="primary"
                            onClick={() => {
                                this.setNextStep(0);
                            }}
                        >
                            <Icon name="play" style={{ marginRight: 6 }} />
                            <FormattedMessage id="support.begin step by step button" />
                        </Button>
                    </GridColumn>
                    <GridColumn textAlign="right">
                        <Button
                            basic
                            size="big"
                            floated="right"
                            className="shade"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => {
                                this.setNextStep(this.props.steps.length - 1, {
                                    noBack: true
                                });
                            }}
                        >
                            <Icon name="sn-cross2" style={{ marginRight: 6 }} />
                            <FormattedMessage id="support.no thanks step by step button" />
                            {/* <FormattedMessage id="general.cancel" /> */}
                        </Button>
                    </GridColumn>
                </Grid>
            </ModalWrapper>
        );
    };

    render() {
        if (this.state.askUserToBegin) {
            return this.renderAskUserToBeginModal();
        }

        if (!this.state.target) {
            return null;
        }

        // reset scroll position
        // window.scrollTo(0,0);

        const step = this.props.steps[this.state.currentStep];
        const isLastStep =
            this.props.steps.length - 1 === this.state.currentStep;

        const tooltipTransitionStyling = {
            transitionTimingFunction: 'ease-in',
            // transitionTimingFunction: 'cubic-bezier(0.42, 0, 1, 1)',
            // transform: 'translateY(130%)',
            transition: '0.35s'
        };
        /*
             // <React.Fragment>
                /* {this.renderCoverPortal()} 
             </React.Fragment> */
        //  debugger;

        // console.log(this.state.currentStep, 'hello key')
        // const closeStepsPopup = useTooltip();

        return (
            <SviftPopup
                noPadding={true}
                key={this.state.currentStep}
                position={step.position}
                target={this.state.target}
                open={!!step}
                zIndex={1090}
                style={{
                    width: 320,
                    ...tooltipTransitionStyling
                }}
                // portalId="step-by-step-button"
                // style={{
                //     ...tooltipTransitionStyling,
                //     zIndex: 1998,
                //     width: 320
                // }}
                onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        zIndex: 2,
                        padding: '12px 16px 8px 16px',
                        borderRadius: 4
                    }}
                    // prevent support menu from opening
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <Header
                        as="h5"
                        style={{
                            fontFamily: 'ubuntu',
                            minWidth: '100%',
                            fontWeight: 500,
                            marginTop: 0,
                            marginBottom: 4,
                            textAlign: 'left',
                            verticalAlign: 'middle',
                            display: 'inline-block'
                        }}
                    >
                        {!isLastStep && (
                            <span
                                style={{
                                    verticalAlign: 'middle',
                                    float: 'right',
                                    marginTop: 6,
                                    marginLeft: 8
                                }}
                            >
                                {/* close steps icon */}
                                <SviftPopup
                                    target={this.closeRef.current}
                                    open={this.state.closeIconHovered}
                                    position="left center"
                                    zIndex={1099}
                                >
                                    <FormattedMessage id="support.cancel guided tour button" />
                                </SviftPopup>

                                <Icon
                                    link
                                    ref={this.closeRef}
                                    onMouseEnter={e =>
                                        this.setState({
                                            closeIconHovered: true
                                        })
                                    }
                                    onMouseLeave={e =>
                                        this.setState({
                                            closeIconHovered: false
                                        })
                                    }
                                    name="sn-cancel-circle"
                                    className="primary-color"
                                    style={{
                                        fontSize: 20,
                                        margin: 0
                                    }}
                                    onClick={() => {
                                        this.setNextStep(
                                            this.props.steps.length - 1,
                                            { noBack: true }
                                        );
                                    }}
                                />
                            </span>
                        )}

                        {step.stepCount && (
                            <span
                                style={{
                                    float: 'right',
                                    opacity: 0.6,
                                    fontWeight: 300
                                }}
                            >
                                {step.stepCount}
                            </span>
                        )}

                        {step.icon && (
                            <Icon
                                name={step.icon}
                                className="primary-color"
                                style={{
                                    marginRight: 6,
                                    verticalAlign: 'middle',
                                    display: 'inline-block'
                                }}
                            />
                        )}

                        <span style={{ verticalAlign: 'middle' }}>
                            {step.header}
                        </span>
                    </Header>

                    <Divider
                        style={{ margin: 0, marginTop: 4, marginBottom: 6 }}
                    />

                    <div
                        style={{
                            fontSize: 14,
                            lineHeight: 1.4,
                            marginBottom: 8,
                            fontWeight: 300
                        }}
                    >
                        {step.render()}
                    </div>

                    {this.renderControls()}
                </div>
            </SviftPopup>
        );
    }
}

StepByStep.props = {
    id: PropTypes.string.isRequired,
    modalHeaderTranslationKey: PropTypes.string.isRequired,
    modalContent: PropTypes.node,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            icon: PropTypes.string,
            targetElementId: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string)
            ]),
            direction: PropTypes.string,
            render: PropTypes.node
        })
    ).isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(StepFilter(StepByStep)));
