import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import { GridColumn, Icon, Label } from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'components/preset/util';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import themeActions from 'store/actions/sites/themes';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};

class OverviewColumn extends PureComponent {
    constructor(props){
        super(props);
        this.state ={
            selectedPreset: null,
            selectedPresetOption: null
        }
        // this.optionTooltip = tooltip.call(this);
        this.optionTooltipRef = React.createRef();
    }
    
    selectPreset = (preset) => {
        this.setState({ selectedPreset: preset || null })
    };
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });
   
    getPresetOptions = style => {
        const {presetTitle, preset, columnSelectedPresetOption, columnSelectPresetOption, theme} = this.props;
        
        const options = [
            <span key="column-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-marquee" /> <FormattedMessage id="elements.column title" />: <FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} /></span>,
            <Padding
                key="padding"
                value={preset.padding}
                preset={preset}
                theme={theme}
                onChange={(key, newValue) => {
                    if (key === 'generalPadding') {
                        if (newValue === null) {
                            this.props.updatePreset('column', presetTitle, 'padding', {
                                paddingBottom: 1
                            });
                        } else {
                            this.props.updatePreset('column', presetTitle, 'padding', {
                                generalPadding: newValue / 100
                            });
                        }

                        return;
                    }

                    const newPadding = {
                        ...preset.padding,
                        [key]: newValue / 100
                    };

                    if (newValue === null) {
                        delete newPadding[key];
                    }

                    delete newPadding.generalPadding;

                    if (typeof newPadding.paddingBottom === 'undefined') {
                        newPadding.paddingBottom = 1;
                    }

                    this.props.updatePreset('column', presetTitle, 'padding', newPadding);
                }}

                option="Padding"
                selectedOption={columnSelectedPresetOption}
                onToggle={(select, e) => {
                    columnSelectPresetOption(select);
                    e.stopPropagation();
                }}

                optIn={{
                    paddingBottom: false,
                    paddingTop: true,
                    paddingLeft: true,
                    paddingRight: true,
                    generalPadding: true
                }}
            />,
            <ColorPicker
                title={this.props.intl.formatMessage({ id: 'options.background color title' })}
                key="color-picker-background"
                icons={['sn-paint-format']}
                colors={theme.colors}
                onChange={(newColor) => this.props.updatePreset('column', presetTitle, 'backgroundColor', newColor)}
                value={preset.backgroundColor}

                option="color-picker-background"
                selectedOption={columnSelectedPresetOption}
                onToggle={(select, e) => {
                    columnSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Margin
                key="margin"
                value={preset.margin}
                preset={preset}
                theme={theme}
                onChange={(key, newValue) => {
                    const value = preset.margin;

                    if (key === 'generalMargin') {
                        if (newValue === null) {
                            this.props.updatePreset('column', presetTitle, 'margin', {
                                marginBottom: 1
                            });

                            return;
                        }

                        this.props.updatePreset('column', presetTitle, 'margin', {
                            generalMargin: newValue / 100
                        });

                        return;
                    }

                    const newMargin = {
                        ...value,
                        [key]: newValue / 100
                    };

                    if (newValue === null) {
                        delete newMargin[key];
                    }

                    delete newMargin.generalMargin;

                    if (typeof newMargin.marginBottom === 'undefined') newMargin.marginBottom = 1;

                    this.props.updatePreset('column', presetTitle, 'margin', newMargin);
                }}

                option="Margin"
                selectedOption={columnSelectedPresetOption}
                onToggle={(select, e) => {
                    columnSelectPresetOption(select);

                    e.stopPropagation();
                }}

                optIn={{
                    marginBottom: false,
                    marginTop: true,
                    marginLeft: true,
                    marginRight: true,
                    generalMargin: true
                }}
            />,
            <Shadow
                key="shadow"
                value={preset.boxShadow}
                colors={theme.colors}
                onChange={(newShadow) => {
                    this.props.updatePreset('column', presetTitle, 'boxShadow', newShadow);
                }}

                option="Shadow"
                selectedOption={columnSelectedPresetOption}
                onToggle={(select, e) => {
                    columnSelectPresetOption(select);

                    e.stopPropagation();
                }}
            />
        ]   
         return options;
    }

    // onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;
    //     if (clickedOnTooltip(e, 'svift-column')) return;
	// 	if (this.props.selectedPreset === 'column') {
	// 		this.props.clearSelectPreset();
	// 	}
    // }

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.selectedPreset === 'column') {
			this.props.clearSelectPreset();
		}
    }

    render(){
        const { selectedPreset, preset, theme } = this.props;

        const selected = selectedPreset === 'column';
        const className = selected ? 'preset-target active-preset-target svift-column' : 'preset-target svift-column';
        const computedStyles = computePreset(preset, theme);
        const dummyHeader3Styling = computePreset(theme.presets.text['Heading 3'], theme);
        const dummyHeader4Styling = computePreset(theme.presets.text['Heading 4'], theme);
        const dummyHeader5Styling = computePreset(theme.presets.text['Heading 5'], theme);
        const dummyTextStyling = computePreset(theme.presets.text.Normal, theme);
        const dummyTextBigStyling = computePreset(theme.presets.text.Big, theme);
        const dummyTextSmallStyling = computePreset(theme.presets.text.Small, theme);

        return(
            [
                <GridColumn
                    key="column-preview-1"
                    className={className}
                    computer={5} mobile={16} textAlign="center"
                    style={computedStyles}
                    selected={selected}
                    onClick={() => this.props.selectPreset('column')}
                >
                    {/* Label for text preview */}
                    <Label basic className="info" size="medium" style={{ position: 'absolute', zIndex: 2, bottom: -16, right: 8 }}>
                        <Icon name="sn-eye" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.only text preview label" /></span>
                    </Label>

                    <h4 className="preset-target" style={Object.assign(dummyHeader4Styling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.header 4 dummy content" />
                    </h4>
                    <p className="preset-target" style={Object.assign(dummyTextStyling, { textAlign: 'left' })}>
                        <FormattedMessage id="design.overview content structure column dummy text" />   
                    </p>
                    <Label basic size="medium" className="positive" style={{ position: 'absolute', top: 8, right: 8 }} >
                        <Icon name="sn-marquee" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.column title" /></span>
                    </Label>
                </GridColumn>,
                <GridColumn
                    key="column-preview-2"
                    className={className}
                    computer={6} mobile={16} textAlign="center" 
                    style={computedStyles}
                    selected={selected}
                    onClick={() => this.props.selectPreset('column')}
                    // ref={this.optionTooltip.setTarget}
                    ref={this.optionTooltipRef}
                >
                    <OptionPanel
                        icon="sn-bucket"
                        title="elements.row title"
                        // target={this.optionTooltip.getTarget()}
                        target={this.optionTooltipRef.current}
                        open={selected}
                        options={this.getPresetOptions(computedStyles)}
                    />
                    <h3 className="preset-target" style={Object.assign(dummyHeader3Styling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.header 3 dummy content" />
                    </h3>
                    <p className="preset-target"  style={Object.assign(dummyTextBigStyling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.big text dummy content" /> 
                    </p>
                    <p className="preset-target" style={Object.assign(dummyTextSmallStyling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.small text dummy content" />
                    </p>
                    <Label basic size="medium" className="positive" style={{ position: 'absolute', top: 8, right: 8 }} >
                        <Icon name="sn-marquee" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.column title" /></span>
                    </Label>
                </GridColumn>,
                <GridColumn
                    key="column-preview-3"
                    className={className}
                    computer={5} mobile={16} textAlign="center" 
                    style={computedStyles}
                    selected={selected}
                    onClick={() => this.props.selectPreset('column')}
                >
                    <h5 className="preset-target" style={Object.assign(dummyHeader5Styling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.header 5 dummy content" />
                    </h5>
                    <p className="preset-target" style={Object.assign(dummyTextStyling, { textAlign: 'left' })}>
                        <FormattedMessage id="elements.normal text dummy content" />
                    </p>
                    <Label basic size="medium" className="positive" style={{ position: 'absolute', top: 8, right: 8 }} >
                        <Icon name="sn-marquee" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.column title" /></span>
                    </Label>
                 </GridColumn>
            ]
        )
    }
}

export default connect(null, mapDispatchToProps) (injectIntl(onClickOutside(OverviewColumn)));