import React from 'react';
import BlogForm from 'components/forms/blog';
import Modal from 'svift-library/client/modal';
import { injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import { connect } from 'react-redux';
import { blogsSelector, tagsSelector } from 'store/selectors/sites/blogs';
import actions from 'store/actions/sites/blogs';
import makeKeywordsSelector  from 'store/selectors/sites/keywords';
import { replaceRoute } from 'utils/routing';
const keywordsSelector = makeKeywordsSelector('blog');

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
    const blogid = state.sites.blogs.params.blogid;

	return {
		siteid,
		blogid,
        keywords: keywordsSelector(state, siteid),
        tags: tagsSelector(state, siteid),
		blogs: blogsSelector(state, siteid)
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		createBlog: (siteID, newBlog) => dispatch(actions.createBlog(siteID, newBlog)),
		deleteBlog: (siteID, blogID) => dispatch(actions.deleteBlog(siteID, blogID)),
		updateBlog: (siteID, blogID, newValues) => dispatch(actions.updateBlog(siteID, blogID, newValues))
	};
};

class BlogsContainer extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			loading: false
		};

		this.createNewBlog = this.createNewBlog.bind(this);
		this.updateBlog = this.updateBlog.bind(this);
		this.deleteBlog = this.deleteBlog.bind(this);
	}

    async createNewBlog(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;

		try {
			await this.props.createBlog(siteid, values);

			SviftGA.BLOGS.CREATED();

			this.setState({
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
	
	async updateBlog(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;
		const blogid = this.props.blogid;

		try {
			await this.props.updateBlog(siteid, blogid, values);

			SviftGA.BLOGS.MODIFIED();

			this.setState({
				loading: false,
				modalOpen: false
			});

			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'blog.post settings updated notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'setting',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deleteBlog() {
		this.setState({
			loading: true
		});

		try {
			await this.props.deleteBlog(this.props.siteid, this.props.blogid);

			SviftGA.BLOGS.DELETED();

			this.setState({
				loading: false
            });
            
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'blogs.delete notification' }),
                className: 'info',
                position: 'topCenter',
                icon: 'trash',
                deleteTime: 2000
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
    // if (!modalOpen) return null;

    render() {
        const currentBlog = this.props.blogs.find(blog => blog._id === this.props.blogid);

        return (
            <React.Fragment>
                {this.props.renderButton(open => this.setState({ modalOpen: open }))}
    
                {this.state.modalOpen &&
                    <Modal
                        title={this.props.intl.formatMessage({ id: `blog.${(this.state.modalOpen === 'create') ? 'create new post' : 'post settings'} title`})}
                        toggleModal={open => this.setState({ modalOpen: open })}
                        nodeAttributes={{
                            'data-ignore-click': true
						}}
						style={{ maxWidth: 880 }}
                    >
                        {this.state.modalOpen === 'update' && currentBlog &&
                            <BlogForm
                                update
                                keywords={this.props.keywords}
                                tags={this.props.tags}
                                loading={this.state.loading}
                                blog={currentBlog._id}
                                enableReinitialize
                                initialValues={{
                                    title: currentBlog.title,
                                    layout: currentBlog.layout,
                                    author: currentBlog.author,
                                    description: currentBlog.description,
                                    tags: currentBlog.tags,
                                    keywords: currentBlog.keywords,
                                    thumbnail: currentBlog.thumbnail,
                                    publishAt: currentBlog.publishAt
                                }}
                                onClose={() => this.setState({ modalOpen: false })}
                                delete={this.deleteBlog}
                                onSubmit={this.updateBlog}
                            />
						}

                        {this.state.modalOpen === 'create' &&
							<BlogForm
                                keywords={this.props.keywords}
                                tags={this.props.tags}
								loading={this.state.loading}
								onSubmit={this.createNewBlog}
								onClose={() => this.setState({ modalOpen: false })}
							/>
						}
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(BlogsContainer)));