import React, { Component } from 'react';
import { portal } from './portal.scss';

export default class PortalHook extends Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <div
                className={portal}
                id={this.props.id}
                style={this.props.style}
            />
        );
    }
}