import constants from 'store/constants';
import createApi from 'api';
import { setRoute } from 'utils/routing';
import sites from 'store/actions/sites';
import uuid from 'uuid';

export default {
	stageForm: formid => (dispatch, getState) => {
		const form = getState().sites.forms.formContent[formid];

		dispatch({
			type: constants.STAGE_FORM,
			data: form.draft
		});
	},
	toggleView: view => {
		return {
			type: constants.TOGGLE_FORM_VIEW,
			data: view
		};
	},
	createForm: (siteID, newForm) => async (dispatch, getState) => {
		const api = createApi().forms;

		const createdForm = await api.post({
			siteID,
			form: newForm
		});

		await sites.fetchSite(siteID)(dispatch, getState);

		setRoute(`/dashboard/${siteID}/design/forms/${createdForm._id}`);
	},

	deleteForm: (siteID, formID) => async (dispatch, getState) => {
		const api = createApi().forms;

		await api.remove({
			siteID,
			formID
		});

		const state = getState();

		const site = state.sites.sites[siteID];
		//const forms = state.sites.forms.forms;

		const { forms: formIds } = site;

		const defaultForm = formIds[0]; //formIds.find(id => forms[id].type === 'page' && forms[id].default);

		setRoute(`/dashboard/${siteID}/design/forms/${defaultForm._id}`, { delete: constants.DELETE_FORM });

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	updateForm: (siteID, formID, newValues) => async (dispatch, getState) => {
		const api = createApi().forms;

		await api.update({
			siteID,
			formID,
			form: newValues
		});

		await sites.fetchSite(siteID)(dispatch, getState);
	},

	setForms: data => ({
		type: constants.SET_FORMS,
		data
	}),

	updateFormContent: (formID, tree) => ({
		type: constants.UPDATE_FORM_CONTENT,
		data: {
			formID,
			tree
		}
	}),

	publishDraft: (siteid, formid, draft) => async (dispatch, getState) => {
		const api = createApi().forms;
		// const draft = getState().sites.forms.stagedForm;

		draft.version = uuid();

		await api.publishDraft(draft, siteid, formid);

		dispatch({
			type: constants.PUBLISH_FORM,
			data: {
				draft,
				siteid,
				formid
			}
		});
	},

	saveDraft: (siteid, formid, draft) => async (dispatch, getState) => {
		const api = createApi().forms;
		// const draft = getState().sites.forms.stagedForm;

		draft.version = uuid();

		await api.saveDraft(draft, siteid, formid);

		dispatch({
			type: constants.SAVE_FORM,
			data: {
				draft,
				siteid,
				formid
			}
		});
	}
};
