import React from 'react';
import { Icon } from 'svift-ui';

const renderIcons = (icons = []) => {
    return icons.map((icon, index) => {
        let iconProps = {
            key: index,
            name: icon,
            corner: true
        };
        // if is object
        if (icon.name) {
            iconProps = {
                ...iconProps,
                ...icon
            };
        }

        if (index > 0) {
            iconProps.style = {
                marginLeft: 8
            };
        }

        return <Icon {...iconProps} />;
    });
}

/*
    Usage example:

    You must supply an array of icons; either a string or an object with the props that you want to set on Icon

    {renderIcons(icons)}

    TO-DO:

    Convert to following format:

    <Icons
        icons={[
            'font',
            'color',
            {
                name: 'arrow',
                rotated: 'clockwise'
            }
        ]}
    />

    Not currently possible, because wrapping icons in a div fucks up the buttons they're contained in
*/

export default renderIcons;