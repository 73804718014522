import React, { PureComponent } from 'react';
import {
	GridColumn,
	Grid,
	Button,
	Icon,
	Popup
} from 'svift-ui';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import InfoIcon from 'components/InfoIcon';
import { injectIntl, FormattedMessage } from 'react-intl';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';

class FilterMenu extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			IconHover: null, 
			open: false
		};
        this.optionTooltip = tooltip.call(this);
	}

	openOptionPanel =() => {
        this.setState({ open: true });
    }

	onClickOutside(e) {
		if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;
	
		if (this.state.open) {
			this.setState({ open: false });
		}
	}

	render() {
		const {
			columns,
			icons,
			setFilter,
			filter,
			size,
			style,
			intl
		} = this.props;

		const selected = this.state.open;

		return (
			<div
				className="option"
				style={Object.assign({ display: 'inline-block' }, style)}
			>
				<Tooltip
					target={this.optionTooltip.getTarget()}
					onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
					open={selected}
					content={
						<div className="picker-content" style={{ padding: '8px 0 8px 0', maxWidth: 180 }}>
							<p className="option-title" style={{ marginTop: -8, textAlign: 'center' }}>
								{/* Filter menu header */}
								<Icon name="sn-filter2" />
                                <FormattedMessage id="general.filter menu title" />

								{filter &&
									<Popup
										trigger={
											<Icon
												link
												circular
												name="sn-cross"
												className="shade"
												style={{ fontSize: 12, float: 'right', verticalAlign: 'middle' }}
												onClick={() => setFilter(null)}
											/>
										}
										content={intl.formatMessage({ id: 'general.clear filter button' })}
										position="top right"
									/>
								}

								<InfoIcon description={intl.formatMessage({ id: 'files.filter menu description' })} />
							</p>

							<Grid padded columns={columns ? columns : 4}>
								{icons.map((icon, i) => {
									return (
										<GridColumn key={i}>
											<Popup
												trigger={
													<Button
														basic
														size={size}
														className={filter === icon.filter ? 'primary active-button': 'primary' }
														icon={icon.icon}
														onClick={() => setFilter(icon.filter)}
														circular
														onMouseEnter={() => this.setState({ IconHover: icon })}
														onMouseLeave={() => this.setState({ IconHover: null })}
													/>
												}
												content={intl.formatMessage({ id: icon.translationKey })}
												position="bottom right"
											/>		
										</GridColumn>
									);
								})}
							</Grid>
						</div>
					}
					style={{ zIndex: 999 }}
				/>
				<div style={{display: 'flex'}}>
					{filter &&
						<span style={{ verticalAlign: 'middle', marginRight: 4, marginTop: 6 }}>
							<FormattedMessage id="general.filter active label" />:
						</span>
					}

					<Popup
						trigger={
							<Button
								circular
								icon = {filter ? icons.find(icon => icon.filter === filter).icon : "sn-filter2"}
								ref={this.optionTooltip.setTarget}
								size={size ? size : 'small'}
								basic={filter ? false : true}
								className={filter ? 'primary active-button' : 'primary'}
								style={{ verticalAlign: 'middle' }}
								onClick={this.openOptionPanel}
								onMouseEnter={() => this.setState({ hover: true })}
								onMouseLeave={() => this.setState({ hover: false })}
							/>
						}
						content={intl.formatMessage({ id: 'general.filter menu title' })}
						positon="top center"
					/>

					{filter &&
						<Popup
							trigger={
								<Button
									basic
									circular
									size={size ? size : 'small'}
									icon="sn-cross"
									className="primary"
									style={{ verticalAlign: 'middle' }}
									onClick={() => setFilter(null)}
									onMouseEnter={() => this.setState({ hover: true })}
									onMouseLeave={() => this.setState({ hover: false })}
								/>
							}
							content={intl.formatMessage({ id: 'general.clear filter button' })}
							position="top right"
						/>
					}
				</div>
			</div>
		);
	}
}

export default injectIntl(onClickOutside(FilterMenu));

