import React, { useEffect, useRef } from 'react';
import { Icon, Label } from 'svift-ui';
import { DropTarget } from 'react-dnd';
import StackZone from './StackZone';
import css from './collision-stack.scss';
import { injectIntl, FormattedMessage } from 'react-intl';

const sources = props => Object.keys(props.dndInterface);
const spec = {
    drop: (props, monitor) => {

    }
};
const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverShallow: monitor.isOver({ shallow: true })
    };
};

const CollisionStack = ({ zones, connectDropTarget, dndInterface, isOver, isOverShallow, dragState, intl }) => {
    const mounting = useRef(true);
    const defaultZone = useRef(dragState.getActiveZone());
    
    useEffect(() => {
        if (!isOver && !mounting.current) {
            dragState.unfreeze();
        }
    }, [isOver])

    useEffect(() => {
        if (isOverShallow && dragState.getActiveZone() !== defaultZone.current) {
            dragState.setActiveZone(defaultZone.current);
        }
    }, [isOverShallow])

    useEffect(() => {
        mounting.current = false;
    }, []);
    
    if (!zones) return null;

    return (
        <div
            ref={connectDropTarget}
            className={`${css['stack-container']} ${css['animated']} ${css['fadeIn']} ${css['faster']}`}
        >
            <Icon 
                name="plus" 
                circular 
                className={css['stack-plus-icon']}
            /> 
           
            <div style={{ position: 'absolute', bottom: '100%', marginBottom: -4, left: 0, right: 0, fontSize: 18, textAlign: 'center' }}>
                <Label
                    basic
                    size="tiny"
                    className="info"
                    style={{ backgroundColor: 'white' }}
                >
                    <Icon name="move" style={{ marginRight: 6 }} />
                    <FormattedMessage id="options.drag to location" />
                </Label>
            </div>

            <div className={css['stack-group']}>
                {zones.map(dropzone => {
                    return (
                        <StackZone 
                            zone={dropzone} 
                            dragState={dragState}
                            dndInterface={dndInterface} 
                            key={dropzone.id} 
                        />
                    );
                })} 
            </div>
        </div>
    );
}

export default DropTarget(sources, spec, collect)(injectIntl(CollisionStack))

