import React from 'react';
import { Message, FormField, FormCheckbox, FormGroup, Icon } from 'svift-ui';
import InfoIcon from 'components/InfoIcon';

const generateCheckbox = options => props => {
	const { name } = options;
	const { disabled, intl, infoIcon, meta: { error }, icon } = props; // Provided by Field component from redux-form

	const className = error && 'error';

	return (
		<FormField>
			<FormGroup inline style={{ marginTop: 16 }}>
				<FormCheckbox
					disabled={disabled}
					//{...props.input}
					className={className}
					checked={props.input.value}
					onChange={(param, data) => {
						props.input.onChange(data.checked);
					}}
					label={
						<label style={{ textAlign: 'left' }}>
							{icon && <Icon name={icon} className="shade-color" style={{ marginRight: 4 }} />}
							{intl.formatMessage({ id: name })}
							{infoIcon ? <InfoIcon description={infoIcon} />  : null}
						</label>
					}
				/>
			</FormGroup>

			{error &&
				<Message negative>
					{error(intl)}
				</Message>
			}
		</FormField>
	);
};

export default generateCheckbox;