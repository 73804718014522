import React, { PureComponent } from 'react'
import { Icon, Label, Button, Header } from 'svift-ui'
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import InfoIcon from 'components/InfoIcon'
import Message from 'svift-library/client/message'
import { setRoute } from 'utils/routing'

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid
    const site = state.sites.sites[siteid]
    const roles = state.user.roles

    return {
        roles,
        siteid,
        siteTitle: site.name,
        domains: site.domains,
        failedActivation: site.failedActivation
    }
}

class Section extends PureComponent {
    renderActivation() {
        const { siteid, domains, roles, intl, failedActivation } = this.props

        const rolesOnSite =
            roles &&
            roles.reduce((acc, { type, scope }) => {
                const scopeSiteMatch = scope === `sites/${siteid}`

                if (scopeSiteMatch) {
                    acc[type] = true
                }

                return acc
            }, {})

        const isLiveSite = failedActivation || (domains && domains.length > 0);

        return (
            <React.Fragment>
                <Label
                    basic
                    size="small"
                    className={isLiveSite ? 'positive' : 'warning'}
                    style={{ marginLeft: 0, marginRight: 8 }}
                >
                    <Icon
                        name={isLiveSite ? 'sn-earth2' : 'plug'}
                        style={{ marginRight: 6, verticalAlign: 'middle' }}
                    />
                    <span style={{ verticalAlign: 'middle' }}>
                        <FormattedMessage
                            id={`general.${
                                isLiveSite ? 'online' : 'offline'
                            } title`}
                        />
                    </span>
                </Label>
                {!isLiveSite && (
                    <React.Fragment>
                        <Button
                            size="small"
                            className="positive"
                            disabled={!rolesOnSite.siteOwner ? true : false}
                            onClick={() =>
                                setRoute(`/dashboard/${siteid}/activation`)
                            }
                            style={{ padding: '6px 12px' }}
                        >
                            <Icon name="sn-flag6" />
                            <FormattedMessage id="dashboard.site activation" />
                        </Button>

                        {!rolesOnSite.siteOwner && (
                            <InfoIcon
                                description={intl.formatMessage({
                                    id: 'settings.activate site warning message'
                                })}
                                position="right center"
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    render() {
        const { siteTitle, intl } = this.props

        return (
            <AppNavigation
                appIcon="sn-cog3"
                appTitle={intl.formatMessage({ id: 'settings.app title' })}
            >
                <div className="left-panel">
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            marginRight: 12,
                            marginTop: 8,
                            marginBottom: 12
                        }}
                    >
                        <Icon
                            name="sn-sphere"
                            className="primary-color"
                            style={{ marginRight: 6 }}
                        />
                        {siteTitle}
                    </div>

                    {/* render activation status + button */}
                    {this.renderActivation()}
                </div>
            </AppNavigation>
        )
    }
}

export default connect(mapStateToProps)(injectIntl(Section))
