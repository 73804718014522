import constants from 'store/constants';
/*import createApi from 'api';
import { setRoute } from 'utils/routing';
import sites from 'store/actions/sites';*/

const actions = {
	stageContent: (pageid) => (dispatch, getState) => {
		const content = getState().sites.content.content;

		dispatch({
			type: constants.STAGE_CONTENT,
			data: content[pageid].draft
		});
	},
	toggleView: (view) => {
		return {
			type: constants.TOGGLE_CONTENT_VIEW,
			data: view
		};
	}
};

export default actions;
