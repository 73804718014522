import React, { PureComponent } from 'react';
import { Form, Icon, Button, Message, Divider } from 'svift-ui';
import Label from 'components/option-library/label';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import input from 'components/forms/__util/field-components/input';
import validate, { string, email } from 'components/forms/__util/validate';
import { setRoute } from 'utils/routing';
// import setInitialRoute from '../../../utils/routing/setInitialRoute';

const fields = {
    email: {
        component: input({
            type: 'email'
        }),
        validate: email
    },
    password: {
        component: input({
            type: 'password'
        }),
        validate: string(1, 255)
    }
};

const validateFields = validate(fields);

class LoginForm extends PureComponent {
    constructor() {
        super();

        /*		this.state = {
			error: null
		};

		this.clearError = () => this.setState({ error: null });*/

        this.submit = this.submit.bind(this);
    }

    async submit(values) {
        validateFields(values);

        await this.props.onSubmit(values);
    }

    render() {
        const { handleSubmit, intl } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.submit)}>
                <Label
                    // labelIcon="sn-at-sign"
                    label={intl.formatMessage({
                        id: 'profile.email input label'
                    })}
                >
                    <Field
                        fluid
                        name="email"
                        placeholder={intl.formatMessage({
                            id: 'profile.email input placeholder'
                        })}
                        size="big"
                        intl={intl}
                        icon="sn-at-sign"
                        iconPosition="left"
                        component={fields.email.component}
                        style={{ marginBottom: 4 }}
                    />
                </Label>

                <Label
                    collapsed
                    // labelIcon="sn-key"
                    label={intl.formatMessage({
                        id: 'profile.password input label'
                    })}
                >
                    <Field
                        fluid
                        name="password"
                        placeholder={intl.formatMessage({
                            id: 'profile.password input placeholder'
                        })}
                        size="huge"
                        intl={intl}
                        icon="sn-key"
                        iconPosition="left"
                        component={fields.password.component}
                    />
                </Label>

                {this.props.error && (
                    <Message className="negative" style={{ marginBottom: 6 }}>
                        <FormattedMessage id={this.props.error} />
                    </Message>
                )}

                <Button
                    fluid
                    size="big"
                    type="submit"
                    className="primary"
                    style={{ marginTop: 12, marginBottom: 8 }}
                >
                    <Icon name="unlock" />
                    <FormattedMessage id="login.login" />
                </Button>

                <Divider horizontal>
                    <Icon
                        name="sn-lifebuoy"
                        className="light-primary-color"
                        style={{ fontSize: 24, opacity: 0.6 }}
                    />
                </Divider>

                <a
                    title={this.props.intl.formatMessage({
                        id: 'login.password reset'
                    })}
                    onClick={() => setRoute('/reset-password')}
                >
                    <FormattedMessage id="login.forgot password" />
                </a>
            </Form>
        );
    }
}

const ReduxLoginForm = reduxForm({
    form: 'login'
})(injectIntl(LoginForm));

ReduxLoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        email: PropTypes.string
    })
};

export default ReduxLoginForm;
