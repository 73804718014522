import React, { PureComponent, useState } from 'react';
import { Grid, ScrollBox, Icon, Label, Button, Message, Segment, Divider, Popup } from 'svift-ui';
import AccordionContentComponent from 'components/accordion/AccordionContentComponent';
import InfoIcon from 'components/InfoIcon';
import css from './theme-options.scss';

import { connect } from 'react-redux';
import ColorSelector from 'components/ColorSelector';
import { StandaloneSlider } from 'components/option-panel/options/Slider';
import { FormattedMessage, injectIntl } from 'react-intl';

import themeActions from 'store/actions/sites/themes';
import systemActions from 'store/actions/system';
import fontSelector from 'store/selectors/system/fonts';

import Branding from './branding/Branding';

import Portal from 'components/editor-v2/editor-interface/Portal';

const mapStateToProps = state => {

	return {
		fonts: fontSelector(state),
		theme: state.sites.themes.stagedTheme,
	};
};

const mapDispatchToProps = dispatch => ({
	updateThemeSetting: (outerKey, innerKey, value) => dispatch(themeActions.updateThemeSetting(outerKey, innerKey, value)),
	fetchGoogleFonts: () => dispatch(systemActions.fetchGoogleFonts())
});

class ThemeSettings extends PureComponent {
	constructor() {
		super();

		this.state = {
			activeOptions: false,
			accordions: {
				fonts: false,
				colors: false,
				measurements: false,
			}
		};
		
		this.openOptions = this.openOptions.bind(this);
		this.openAccordion = this.openAccordion.bind(this);
		this.renderFonts = this.renderFonts.bind(this);
		this.renderBranding = this.renderBranding.bind(this);
	}

	componentDidMount() {
		this.props.fetchGoogleFonts();
	}

	openAccordion(accordion) {
		this.setState({
			accordions: {
				...this.state.accordions,
				[accordion]: !this.state.accordions[accordion]
			}
		});
	}

	openOptions(newState) {
		this.setState({ activeOptions: newState });
	}

	renderFonts() {
		const { fonts, updateThemeSetting, theme, intl } = this.props;

		return (
			<div>
				<ScrollBox
					title={<span><Icon name="sn-typography" className="primary" style={{ margin: 0, fontSize: 14 }} /> <FormattedMessage id="options.font primary title" /> <InfoIcon description={intl.formatMessage({ id: 'options.font primary description' })} /></span>}
					options={fonts}
					mapBy="family"
					onChange={(e, d) => updateThemeSetting('fonts', 'primary', d)}
					activeItem={theme.fonts.primary.family}
				/>

				<ScrollBox
					title={<span><Icon name="sn-typography" className="primary" style={{ margin: 0, fontSize: 14 }} /> <FormattedMessage id="options.font secondary title" /> <InfoIcon description={intl.formatMessage({ id: 'options.font secondary description' })} /></span>}
					options={fonts}
					mapBy="family"
					onChange={(e, d) => updateThemeSetting('fonts', 'secondary', d)}
					activeItem={theme.fonts.secondary.family}
				/>

				<ScrollBox
					title={<span><Icon name="sn-typography" className="primary" style={{ margin: 0, fontSize: 14 }} /> <FormattedMessage id="options.font tertiary title" /> <InfoIcon description={intl.formatMessage({ id: 'options.font tertiary description' })} /></span>}
					options={fonts}
					mapBy="family"
					onChange={(e, d) => updateThemeSetting('fonts', 'tertiary', d)}
					activeItem={theme.fonts.tertiary.family}
				/>

				<p style={{ fontSize: 12 }}><FormattedMessage id="options.google font link description" /> <a href="https://fonts.google.com/" target="_blank"><FormattedMessage id="options.google font link title" /></a></p>
			</div>
		);
	}

	renderBranding() {
		return (
			<div>
				<Segment textAlign="center" className="branding-preview">
					<Icon name="sn-star-full" style={{ fontSize: 16 }} />
					<p><FormattedMessage id="options.branding favicon title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding favicon description' })} /></p>
				</Segment>

				<Popup
					trigger={
						<Button basic size="tiny" className="positive" fluid><Icon name="sn-star-empty" /> <FormattedMessage id="options.branding change favicon title" /></Button>
					}
					content={this.props.intl.formatMessage({ id: 'options.branding change favicon description' })}
					position="right center"
				/>

				<Divider />

				<Segment textAlign="center" className="branding-preview">
					<Icon name="sn-star-full" style={{ fontSize: 64 }} />
					<p><FormattedMessage id="options.branding square logo title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding square logo description' })} /></p>
				</Segment>

				<Popup
					trigger={
						<Button basic size="tiny" className="positive" fluid><Icon name="sn-star-empty" /> <FormattedMessage id="options.branding change square logo title" /></Button>
					}
					content={this.props.intl.formatMessage({ id: 'options.branding change square logo description' })}
					position="right center"
				/>

				<Divider />

				<Segment textAlign="center" className="branding-preview">
					<Icon name="sn-star-full" style={{ fontSize: 40 }} /><Icon name="sn-linear_scale" style={{ fontSize: 40 }} />
					<p><FormattedMessage id="options.branding horizontal logo title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding horizontal logo description' })} /></p>
				</Segment>

				<Popup
					trigger={
						<Button basic size="tiny" className="positive" fluid><Icon name="sn-star-empty" /> <FormattedMessage id="options.branding change horizontal logo title" /></Button>
					}
					content={this.props.intl.formatMessage({ id: 'options.branding change horizontal logo description' })}
					position="right center"
				/>
			</div>
		);
	}

	render() {
		const {
			theme,
			updateThemeSetting,
			intl,
			optionsPortalTarget,
			size,
			style,
			optionFrameStyle
		} = this.props;
		const { fonts, colors, measurements, branding } = this.state.accordions;

		if (!theme) return null;

		// console.log()

		return (
			<React.Fragment>
				<Popup
					trigger={
						<Button
							id="theme-variables-button"
							onMouseDown={() => this.openOptions(!this.state.activeOptions)}
							className={!this.state.activeOptions ? 'secondary' : 'active-button secondary'}
							size={size || "mini"}
							style={{
								marginRight: 0,
								padding: '6px 8px',
								boxShadow: this.state.activeOptions ? '0 0 0 1px white' : 'none',
								...style
							}}
						>
							<Icon name="sn-palette" style={{ marginRight: 8 }} />
							<Icon
								name={!this.state.activeOptions ? 'sn-arrow-down8' : 'sn-arrow-up8'}
								style={{ marginRight: 0 }}
							/>
						</Button>
					}
					content={this.props.intl.formatMessage({ id: 'options.design settings popup' })}
					position="right center"
				/>

				{this.state.activeOptions &&
					<Portal target={optionsPortalTarget || 'sidenav'}>
						<div
							style={{
								position: 'fixed',
								zIndex: 997,
								top: 0,
								left: 0,
								bottom: 0,
								width: 260,
								maxHeight: '100%',
								overflowY: 'auto',
								pointerEvents: 'auto',
								backgroundColor: 'rgba(20, 85, 127, 0.80)',
								padding: '109px 16px 8px 16px',
								color: 'white',
								...optionFrameStyle
							}}
							className={`${css['animated']} ${css['fadeInDown']} ${css['ultra-fast']}`}
						>
							<Popup
								trigger={
									<Icon
										link
										name="sn-cancel-circle2"
										className="tint-color"
										onMouseDown={() => this.openOptions(!this.state.activeOptions)}
										style={{ float: 'right', fontSize: 20, marginLeft: 6, marginRight: 0 }}
									/>
								}
								content={intl.formatMessage({ id: 'options.close design settings popup' })}
								position="right center"
							/>

							{/* variables options label */}
							<div style={{ color: 'white', fontSize: 16, marginBottom: 6 }}>
								{/* <Icon name="sn-arrow-left" style={{ opacity: 0.6, marginRight: 4 }} /> */}
								<Icon name="sn-palette" style={{ marginRight: 6 }} />
								<FormattedMessage id="options.design settings title" />
								<InfoIcon className="tint-color" description={intl.formatMessage({ id: 'options.design settings description' })} />
							</div>

							{/* font variables options */}
							<AccordionContentComponent
								handleOpen={() => this.openAccordion('fonts')}
								active={fonts}
								icon="sn-typography"
								title={
									<span>
										<FormattedMessage id="options.fonts title" /><InfoIcon description={intl.formatMessage({ id: 'options.fonts description' })} />
									</span>
								}
								>
								{this.renderFonts()}
							</AccordionContentComponent>

							{/* color variables options */}
							<AccordionContentComponent
								handleOpen={() => this.openAccordion('colors')}
								active={colors}
								icon="sn-droplet"
								title={
									<span>
										<FormattedMessage id="options.colors title" /><InfoIcon description={intl.formatMessage({ id: 'options.colors description' })} />
									</span>
								}
							>
								<Grid padded style={{ position: 'static' }}>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'primary', d.value)}
										data={theme.colors.primary}
										name={intl.formatMessage({ id: 'options.color primary title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'secondary', d.value)}
										data={theme.colors.secondary}
										name={intl.formatMessage({ id: 'options.color secondary title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'shade', d.value)}
										data={theme.colors.shade}
										name={intl.formatMessage({ id: 'options.color shade title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'tint', d.value)}
										data={theme.colors.tint}
										name={intl.formatMessage({ id: 'options.color tint title' })}
										className="light-color"
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'info', d.value)}
										data={theme.colors.info}
										name={intl.formatMessage({ id: 'options.color info title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'success', d.value)}
										data={theme.colors.success}
										name={intl.formatMessage({ id: 'options.color positive title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'warning', d.value)}
										data={theme.colors.warning}
										name={intl.formatMessage({ id: 'options.color warning title' })}
									/>
									<ColorSelector
										onChange={(e, d) => updateThemeSetting('colors', 'error', d.value)}
										data={theme.colors.error}
										name={intl.formatMessage({ id: 'options.color negative title' })}
									/>
								</Grid>
							</AccordionContentComponent>
							
							{/* measurements variables options */}
							<AccordionContentComponent
								handleOpen={() => this.openAccordion('measurements')}
								active={measurements}
								icon="sn-rulers"
								title={
									<span style={{ fontSize: 14 }}>
										<FormattedMessage id="options.measurements title" /><InfoIcon description={intl.formatMessage({ id: 'options.measurements description' })} />
									</span>
								}
							>
								<StandaloneSlider
									outsidePanel
									icons={["sn-text-height"]}
									title={
										<span style={{ fontSize: 14 }}>
											<FormattedMessage id="options.font size title" /><InfoIcon description={intl.formatMessage({ id: 'options.font size description' })} />
											<Label basic circular className="info" size="tiny" style={{ float: 'right' }}>{intl.formatNumber(theme.measurements.fontSize)} px</Label>
										</span>
									}
									value={theme.measurements.fontSize}
									min={12}
									max={64}
									onChange={d => updateThemeSetting('measurements', 'fontSize', d)}
									style={{ marginTop: 0, marginBottom: 16 }}
								/>

								<Divider style={{ marginTop: 0, marginBottom: 12 }} />

								<StandaloneSlider
									outsidePanel
									icons={["sn-shrink3"]}
									title={
										<span style={{ fontSize: 14 }}>
											<FormattedMessage id="options.padding title" /><InfoIcon description={intl.formatMessage({ id: 'options.padding description' })} />

											<Label basic circular className="info" size="tiny" style={{ float: 'right' }}>{intl.formatNumber(theme.measurements.padding)} px</Label>
										</span>}
									value={theme.measurements.padding}
									min={1}
									max={64}
									onChange={d => updateThemeSetting('measurements', 'padding', d)}
									style={{ marginTop: 0, marginBottom: 16 }}
								/>

								<Divider style={{ marginTop: 0, marginBottom: 12 }} />

								<StandaloneSlider
									outsidePanel
									icons={["sn-enlarge3"]}
									title={
										<span style={{ fontSize: 14 }}>
											<FormattedMessage id="options.margin title" /><InfoIcon description={intl.formatMessage({ id: 'options.margin description' })} />
											<Label basic circular className="info" size="tiny" style={{ float: 'right' }}>{intl.formatNumber(theme.measurements.margin)} px</Label>
										</span>}
									value={theme.measurements.margin}
									min={1}
									max={64}
									onChange={d => updateThemeSetting('measurements', 'margin', d)}
									style={{ marginTop: 0, marginBottom: 16 }}
								/>

								<Divider style={{ marginTop: 0, marginBottom: 12 }} />

								<StandaloneSlider
									outsidePanel
									icons={["sn-line_weight"]}
									title={
										<span style={{ fontSize: 14 }}>
											<FormattedMessage id="options.line height title" /><InfoIcon description={intl.formatMessage({ id: 'options.line height description' })} />
											<Label basic circular className="info" size="tiny" style={{ float: 'right' }}>{intl.formatNumber(theme.measurements.lineHeight)}</Label>
										</span>}
									value={theme.measurements.lineHeight * 10}
									min={10}
									max={25}
									onChange={d => updateThemeSetting('measurements', 'lineHeight', d / 10)}
									style={{ marginTop: 0, marginBottom: 16 }}
								/>
							</AccordionContentComponent>
							
							{/* branding variables options */}
							<AccordionContentComponent
								outsidePanel
								sliderIcon="sn-star-full"
								title={
									<span>
										<FormattedMessage id="options.branding title" /><InfoIcon description={intl.formatMessage({ id: 'options.branding description' })} />
									</span>
								}
								handleOpen={() => this.openAccordion('branding')}
								active={branding}
								icon="sn-star-full"
							>
								<Branding />
							</AccordionContentComponent>
						</div>
					</Portal>
				}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ThemeSettings));
