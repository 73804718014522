import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { findDOMNode } from 'react-dom';
import { getWidth as getColumnWidth } from 'svift-library/client/renderer/nodes/components/library/grid/row/column/Column';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import { Icon, Button, Label } from 'svift-ui';
import ResizeHorizontal from './ResizeHorizontal';
import css from './selectable.scss';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';
import ParentSelectionPath from './ParentSelectPath';
import key from 'svift-library/key';

const CropLine = props => {
	// const lineContainerRef = useTooltip();
	const { height, setHeight, node, resizingRef } = props;
	const position = { bottom: '0px' };
	
	const resolveHeightType = component => {
		switch (component) {
			case 'height': {
				return 'exact height';
			}
			case 'minHeight': {
				return 'minimum height';
			}
			case 'maxHeight': {
				return 'maximum height';
			}
			default: {
				return 'NO KEY';
			}
		}
	};

    if (height) {
		const heightType = key('props.crop.heightMode')(node);
		
		if (heightType === 'maxHeight') {
			position.top = '100%';
		} else {
			position.top = height;
		}
	}

    return (
        <div /*ref={lineContainerRef}*/ className={css['vertical-crop-line']} style={position}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{props.isEditable && 
					<Label
						basic
						className={`warning ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
						size="mini"
						style={{ padding: '2px 4px', marginTop: 10 }}
					>
						<Icon name={resolveElementIcon(node.component)} style={{ marginRight: 4 }} />
						<Icon name="sn-plus3" style={{ marginRight: 4, opacity: 0.6 }} />
						<Icon name="sn-height2" style={{ marginRight: 4 }} />
						{/* transl8 */}
						<div style={{ display: 'inline-block' }}>
							{resolveHeightType(node.props.crop.heightMode)}
							<Label size="mini" className="info" style={{ padding: '2px 4px', marginLeft: 4 }}>
								{Math.round(height)} px
							</Label>
						</div>
					</Label>
				}
            
				{/* <Tooltip
					position="top center"
					id="crop-handle"
					open 
					target={lineContainerRef.current}
				> */}
				<props.ResizeHandle
					device={props.device}
					element={!props.isEditable && props.node.component}
					height={height} 
					setHeightBegin={() => resizingRef.current = true}
					setHeight={setHeight}
					setHeightDone={() => {
						resizingRef.current = false;
						props.context.updateState(context => {
							const crop = context.nodeMap[props.nodeID].props.crop || {};
							
							return {
								...context,
								nodeMap: {
									...context.nodeMap,
									[props.nodeID]: {
										...context.nodeMap[props.nodeID],
										props: {
											...context.nodeMap[props.nodeID].props,
											crop: {
												...crop,
												height
											}
										}
									}
								}
							}
						}, 'crop height');
					}}
				/>
			</div>
            {/* </Tooltip> */}
        </div>
    );
};

const SelectableWrapper = props => {
	const crop = props.node.props.crop || {};
	const heightFromProps = crop.height; 

	const cropTargetRef = useRef();
	const cropContentRef = useRef();
    const resizingRef = useRef();
	const [height, setHeight] = useState(heightFromProps); 
	const [contentHeight, setContentHeight] = useState(); 
	const [closeHovered, setCloseHovered] = useState(false);
	const closeEditingFramePopup = useTooltip();
	const path = props.getPath && props.getPath().filter(instance => instance.state.node.component !== 'Container');
	const [shortcutHovered, setShortcutHovered] = useState(false);

	useLayoutEffect(() => {
		if (cropContentRef.current) {
			const target = cropContentRef.current.getBoundingClientRect ? cropContentRef.current : findDOMNode(cropContentRef.current);

			const rect = target.getBoundingClientRect();

			setContentHeight(rect.height);
		}
	});
	
	// static getDerivedStateFromProps (if height is set via side navigation it should override the internal state here)
	const propHeightDoesntMatchStateHeight = !resizingRef.current && (height !== heightFromProps);
	
	if (propHeightDoesntMatchStateHeight) setHeight(heightFromProps);
	
	let outlineClassName; 

	if (props.isSelected) {
		outlineClassName = css['element-outline-selected'];
	} else if (props.isEditable) {
		outlineClassName = css['element-outline-editable'];
	} else if (props.isDragging) {
		outlineClassName = css['element-outline-dragged'];
	}
	// TO DO: apply isLocked classnames + for upload (image etc.)
	// else if (props.isLocked) {
	// 	outlineClassName = css['element-outline-locked'];
	// } 
	// else if (props.isLockedHovered) {
	// 	outlineClassName = css['element-outline-locked-hovered'];
	// }
	// else if (props.isLockedUploading) {
	// 	outlineClassName = css['element-outline-upload'];
	// }
	else if (props.isHovered) {
		outlineClassName = css['element-outline-hovered'];
	} else {
		outlineClassName = css['element-outline'];
	}
	
	const style = {
		position: 'relative',
		width: '100%',
		...props.style
	};

	if (props.node.component === 'LayoutContainer') {
		if (props.node.props.maxWidth) {
			// Have to have this combination of maxWidth and width; if we only set max width then content isn't guaranteed to be whatever width is specified
			style.width =
				props.node.props.maxWidth +
					props.node.props.widthType || 'px';
			style.maxWidth = '100vw';
		}
	}

	if (props.node.component === 'Column') {
		style.width = getColumnWidth(props);

		if (props.node.props.floated) {
			style.marginLeft = 'auto';
		}
	}

	if (props.computedStyling && props.computedStyling.maxWidth) {
		style.maxWidth = props.computedStyling.maxWidth;
	}

	// if (props.node.component === 'Row') {
	// 	// console.log(props, 'props row selectable wrapper');
	// 	// const preset = props.theme.presets.row[props.currentNode.preset];

	// 	style.maxWidth = props.computedStyling.maxWidth;
	// 	// style.maxWidth = style.maxWidth; //props.node.
	// }

	const shouldRenderResizeHandle = !['Grid', 'Row', 'Container'].includes(props.node.component);

    return (
        <div 
			data-type="selectable-wrapper"
			draggable={props.draggable ? 'true' : 'false'}
			{...props.targetProps}
			ref={ref => {
				if (props.targetProps.ref) props.targetProps.ref.current = ref;
				if (props.onClickOutsideRef) props.onClickOutsideRef.current = ref;
				if (props.connectDragSource) props.connectDragSource(ref); 
				if (props.connectDropTarget) props.connectDropTarget(ref); 
				// if (props.connectDragPreview) props.connectDragPreview(ref); 
			}}
            className={outlineClassName}
            style={style}
        >
			{props.renderDropZones && props.renderDropZones(props)}

			{/* shortcuts on hover */}
			{(props.isHovered && !(props.isSelected || props.isEditable || props.isDragging)) && path && path.length > 1 &&
				<div
					onMouseEnter={e => setShortcutHovered(true)} 
					onMouseLeave={e => setShortcutHovered(false)}
					className={`${css['shortcut-container']} ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
					style={{ top: -8, left: -8 }}
				>
					<div
						className={css['shortcut-panel']}
						style={{
							padding: !shortcutHovered && 2
						}}
					>
						{shortcutHovered
							?
								<React.Fragment>
									<span style={{ marginRight: 6, fontSize: 12 }}>
										<FormattedMessage id="general.shortcuts" />:
									</span>
									<ParentSelectionPath
										path={path}
										select={props.select}
										renderButton={props => {
											delete props.style;

											return (
												<Button 
													{...props}
													className={`info ${css['shortcut-button']}`}
												/>
											);
										}}
										renderIcon={props => {
											delete props.style;

											return (
												<Icon 
													{...props} 
													className={css['shortcut-separator']}
												/>
											);
										}}
									/>
								</React.Fragment>
							:
								<Icon name="sn-menu" className="info-color" style={{ margin: 0, height: 0 }} />
						}
					</div>
				</div>
			}

			{/* close editable button */}
			{props.isEditable &&
				<React.Fragment>
					<Popup
						target={closeEditingFramePopup.current}
						position="top center"
						zIndex={995}
					>
						<FormattedMessage id="options.close editing popup" /> {resolveElementName(props.node.component, (props.node.props && props.node.props.type))}
					</Popup>
					
					<Button
						icon
						circular
						onClick={props.close}
						ref={closeEditingFramePopup}
						onMouseEnter={e => setCloseHovered(true)} 
						onMouseLeave={e => setCloseHovered(false)}
						className={`${css['close-button']} ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
					>
						<Icon
							name={closeHovered ? 'sn-cancel-circle2' : 'sn-cancel-circle'}
							style={{ fontSize: 16, width: 16, height: 16 }}
						/>
					</Button>
				</React.Fragment>
			}
			
			{crop.heightMode && 
				<CropLine 
					{...props}
					height={height || contentHeight}
					setHeight={setHeight}
					resizingRef={resizingRef}
				/>
			}

			{props.isSelected && shouldRenderResizeHandle &&
				<ResizeHorizontal 
					{...props}
					target={props.targetProps.ref.current}
				/>
			}
			
            {React.cloneElement(props.children, { isWrapped: true, cropTargetRef, cropContentRef, cropHeight: height, contentHeight })}
			
			{/* dragging icon indicator */}
			{props.isDragging && 
				<Icon
					circular
					name="sn-move"
					className={css['dragging-indicator-icon']}
				/>
			}
        </div>
    );
};

export default injectIntl(SelectableWrapper);