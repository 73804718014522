import React, { memo, useState } from 'react';
import { findDOMNode } from 'react-dom';
import css from './options.scss';
import { Icon, Button } from 'svift-ui';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';
import { FormattedMessage } from 'react-intl';
import ParentSelectionPath from './ParentSelectPath';

export default memo(props => {
    const { select, edit, clear, remove, getPath, component, type } = props;
    const [hovered, setHovered] = useState(false);
    const path = getPath && getPath().filter(instance => instance.state.node.component !== 'Container');

    // console.log(path, 'path')
    
    const elementSelectedPopup = useTooltip();
    const moveElementPopup = useTooltip();
    const editElementPopup = useTooltip();
    // const copyElementPopup = useTooltip();
    // const pasteElementPopup = useTooltip();
    const deleteElementPopup = useTooltip();
    const closeSelectedPopup = useTooltip();
    
    return (
        <div className={css['container']} onMouseMove={e => e.stopPropagation()} /*imperfect but will have to do*/>
            
            {/* secondary option panel for path selection */}
            {path && path.length > 1 &&
                <div className={`${css['animated']} ${css['fadeInUp']} ${css['fast']}`} style={{ zIndex: 996 }}>
                    <div
                        className={css['horizontal-panel-secondary']}
                        /*onMouseMove={e => e.stopPropagation()}*/
                        data-ignore-click="true"
                    >
						<ParentSelectionPath
							path={path}
							select={select}
						/>
                    </div>
                </div>
            }

            {/* primary option panel */}
            <div
                className={`${css['horizontal-panel']} ${css['animated']} ${css['fadeInUp']} ${css['faster']}`}
                /*onMouseMove={e => e.stopPropagation()}*/
                data-ignore-click="true"
            >

                {/* option panel title */}
                <div className={css['horizontal-panel-header']}>
                    <Popup target={elementSelectedPopup.current} zIndex={1007}>
                        <span><Icon name={resolveElementIcon(component, type)} style={{ marginRight: 6 }} />{resolveElementName(component, type)} <FormattedMessage id="options.selected popup" /></span>
                    </Popup>
                    <div ref={elementSelectedPopup} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {/* selected element icon */}
                        <Icon
                            circular
                            name={resolveElementIcon(component, type)}
                            className="primary-color"
                            style={{
                                margin: 0,
                                marginRight: 6,
                                fontSize: 16,
                                opacity: 0.8,
                                verticalAlign: 'middle',
                            }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>
                            {/* selected element title */}
                            {resolveElementName(component, type)}
                        </span>
                    </div>
                    
                    {/* move selected element */}
                    <Popup target={moveElementPopup.current} zIndex={1007}>
                        <span>
                            <Icon name="move" style={{ marginRight: 6 }} />
                            <FormattedMessage id="options.drag and move popup" /> <span style={{ textTransform: 'lowercase' }}>{resolveElementName(component, type)}</span>
                        </span>
                    </Popup>

                    {props.connectDragSourceHandle &&                 
                        <Icon
                            circular
                            name="move"
                            ref={ref => {
                                moveElementPopup.current = ref;
                                
                                props.connectDragSourceHandle(findDOMNode(ref));
                            }}
                            className={css['move-icon']}
                        />
                    }
                </div>

                {/* vertical divider */}
                <div className={css['vertical-divider']} />

                {/* edit element */}
                <Popup target={editElementPopup.current} zIndex={1007}>
                    <span>
                        <Icon name="sn-quill3" style={{ marginRight: 6 }} />
                        <FormattedMessage id="options.edit title" /> <span style={{ textTransform: 'lowercase' }}>{resolveElementName(component, type)}</span>
                    </span>
                </Popup>
                <Button
                    ref={editElementPopup}
                    onClick={edit}
                    size="tiny"
                    className="primary"
                    style={{ marginRight: 8 }}
                >
                    <Icon name="sn-quill3" style={{ marginRight: 8 }} />
                    <FormattedMessage id="options.edit title" />
                </Button>

                {/* copy/paste options */}
                {/* transl8 */}
                {/* TO DO: display name of element from clipboard */}
                {/* <ButtonGroup size="tiny" style={{ marginRight: 8 }}>
                    <Popup target={copyElementPopup.current} zIndex={1007}>
                        <span>
                            <Icon name="copy" style={{ marginRight: 6 }} />
                            <FormattedMessage id="options.copy popup" /> <span style={{ textTransform: 'lowercase' }}>{resolveElementName(component, type)}</span>
                        </span>
                    </Popup>
                    <Button
                        basic
                        icon="copy"
                        ref={copyElementPopup}
                        className="primary"
                    />
                    
                    <Popup target={pasteElementPopup.current} zIndex={1007}>
                        <span>
                            <Icon name="paste" style={{ marginRight: 6 }} />
                            <FormattedMessage id="options.paste popup" /> [X]
                        </span>
                    </Popup>
                    <Button
                        basic
                        icon="paste"
                        disabled={true} // TO DO: listen to if items copied to clipboard
                        ref={pasteElementPopup}
                        className="primary"
                    />
                </ButtonGroup> */}
                
                {/* lock element */}
                {/* <Button
                    icon="lock"
                    basic
                    size="tiny"
                    className="warning"
                    style={{ marginRight: 8 }}
                /> */}
                
                {/* delete element */}
                <Popup target={deleteElementPopup.current} zIndex={1007}>
                    <span>
                        <Icon name="trash" style={{ marginRight: 6 }} />
                        <FormattedMessage id="options.delete title" /> <span style={{ textTransform: 'lowercase' }}>{resolveElementName(component, type)}</span>
                    </span>
                </Popup>
                <Button
                    basic
                    icon="trash alternate"
                    ref={deleteElementPopup}
                    size="tiny"
                    className="error"
                    style={{ margin: 0 }}
                    onClick={remove}
                />

                {/* vertical divider */}
                <div className={css['vertical-divider']} />

                {/* close selection */}
                <Popup target={closeSelectedPopup.current} zIndex={1007}>
                    <span>
                        <Icon name="sn-cross3" style={{ marginRight: 6 }} />
                        <FormattedMessage id="options.close selection of popup" /> <span style={{ textTransform: 'lowercase' }}>{resolveElementName(component, type)}</span>
                    </span>
                </Popup>
                <Icon
                    link
                    ref={closeSelectedPopup}
                    name={hovered ? 'sn-cancel-circle2' : 'sn-cancel-circle'}
                    onMouseEnter={e => setHovered(true)} 
                    onMouseLeave={e => setHovered(false)}
                    onClick={clear}
                    className="primary-color"
                    style={{
                        margin: 0,
                        fontSize: 22,
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }}
                />
            </div>
        </div>
    );
});
