import { matchPath } from 'react-router';

const extractParams = (route, paramPath) => {
	const match = matchPath(route, paramPath);

	if (match !== null) {
		return match.params;
	}

	return null;
};

const objToQueryString = obj => Object.keys(obj).map(k => `${k}=${encodeURIComponent(obj[k])}`).join('&')

const objFromQueryString = queryString => {
	const query = {};

	const a = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

	for (let i = 0; i < a.length; i++) {
		const b = a[i].split('=');
		query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
	}
	return query;
};

export { objFromQueryString, objToQueryString };

export default extractParams;