import React, { memo, useCallback } from "react";
import { injectIntl } from 'react-intl';
import Checkbox from 'components/option-library/primitives/checkbox'
import Section from '../Section';
import IconPicker from 'components/option-library/primitives/icon-picker';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import Text from 'components/option-library/primitives/input/text';
import Label from 'components/option-library/label';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    // const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <Section>
            <Preset
                multipleStates
                presets={props.theme.presets.divider}
                onChange={(key, val, ...args) => {
                    onChange(key, val);
                }}
                presetKey={node.props.preset}
                element="divider"
                theme={props.theme}
                inlineStyling={node.props.style}
            />

            <Label>
                <Checkbox
                    label={props.intl.formatMessage({
                        id: 'options.icon toggle checkbox'
                    })}
                    // icon="sn-user5"
                    value={node.props.showIcon}
                    onChange={checked => {
                        onChange('showIcon', checked)
                    }}
                />

                {node.props.showIcon &&
                    <IconPicker 
                        value={node.props.icon.name}
                        onChange={icon => onChange('icon', { ...node.props.icon, name: icon } )}
                    />
                }
            </Label>

            <Label>
                <Checkbox
                    label={props.intl.formatMessage({
                        id: 'options.label toggle checkbox'
                    })}
                    // icon="sn-user5"
                    value={node.props.showLabel}
                    onChange={checked => {
                        onChange('showLabel', checked)
                    }}
                />

                {node.props.showLabel &&
                    <Label
                        label={intl.formatMessage({ id: 'elements.label title' })}
                        labelIcon="sn-typography"
                    >
                        <Text
                            fluid
                            disableUndoRedo
                            value={node.props.label.text}
                            onChange={newValue => {
                                onChange('label', {
                                    ...node.props.label,
                                    text: newValue
                                });
                            }}
                        />
                    </Label>
                }
            </Label>

            <Label
                label={intl.formatMessage({ id: 'options.image alignment horizontal' })}
                labelIcon="sn-arrow-resize3"
            >
                <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                    <Toggle
                        icon="sn-vertical_align_top"
                        rotated="counterclockwise"
                        tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                        tooltipPosition="top center"
                        value={node.props.labelAlignment === 'left'}                            
                        onChange={() => onChange('labelAlignment', 'left', 'image alignment', false)}
                    />
                    <Toggle
                        icon="sn-vertical_align_center"
                        rotated="clockwise"
                        tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                        tooltipPosition="top center"
                        value={['left', 'right'].indexOf(node.props.labelAlignment) === -1}
                        onChange={() => onChange('labelAlignment', 'center', 'image alignment', false)}
                    />
                    <Toggle
                        icon="sn-vertical_align_top"
                        rotated="clockwise"
                        tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                        tooltipPosition="top center"
                        value={node.props.labelAlignment === 'right'}
                        onChange={() => onChange('labelAlignment', 'right', 'image alignment', false)}
                    />
                </ToggleGroup>
            </Label>
        </Section>
    );
}));

/*

            {/* <StylingOverrides
                keys={Object.keys(props.theme.presets.divider[node.props.preset].icon)}
                preset={props.theme.presets.divider[node.props.preset].icon}
                theme={props.theme} 
                onChange={value => {
                    // value, args
                    const newIconStyling = {
                        ...node.props.icon,
                        style: value
                    };

                    onChange('icon', newIconStyling);
                }}
                value={node.props.icon.style}
            /> 
                    /* <Preset
                        presets={props.theme.presets.divider.icon}
                        onChange={(key, val) => {
                            const newIcon = {
                                ...node.props.icon,
                                style: {
                                    [key]: val
                                }
                            };

                            onChange('icon', newIcon);
                        }}
                        presetKey={node.props.preset}
                        theme={props.theme}
                        inlineStyling={key('props.icon.style')(node)}
                    /> 
                /* </Section> 
            /* </Accordion> 
        // </React.Fragment>
*/