import React, { Component } from 'react';
import Modal from 'svift-library/client/modal';

import { FormattedMessage, injectIntl } from 'react-intl';

class ModalWrapper extends Component {
	// constructor() {
	// 	super();

	// 	this.keyHandler = this.onEscape.bind(this);
	// }

	// componentDidMount() {
	// 	document.addEventListener('keydown', this.keyHandler);
	// }

	// componentWillUnmount() {
	// 	document.removeEventListener('keydown', this.keyHandler);
	// }

	// onEscape(e) {
	// 	if (this.props.open && this.props.handleClose && e.keyCode === 27) {
	// 		this.props.handleClose();
	// 	}
	// }

	// // https://github.com/Semantic-Org/Semantic-UI-React/issues/1157
	// // Semantic UI is great :-)
	// fixBody = () => {
	// 	const anotherModal = document.getElementsByClassName('ui page modals').length;
	// 	if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
	// }

	render() {
		const { open, children, handleClose, header, size, width, style } = this.props;

		if (!open) return null;
 
		return (
			<Modal
				title={this.props.intl.formatMessage({ id: header })}
				width={width}
				toggleModal={() => handleClose(false)}
				nodeAttributes={{
					'data-ignore-click': true
				}}
				style={style}
			>
				{children}
			</Modal>
		);

		// return (
		// 	<Modal
		// 		dimmer="inverted"
		// 		closeOnDimmerClick 
		// 		size={size ? size : "small"}
		// 		onUnmount={this.fixBody}
		// 		id="modal"
		// 		open={open}
		// 		closeIcon={
		// 			<Icon name="close" onClick={handleClose} />
		// 		}
		// 	>
		// 		<ModalHeader>
		// 			<FormattedMessage id={header} />
		// 		</ModalHeader>

		// 		{children}
		// 	</Modal>
		// );
	}
}

export default injectIntl(ModalWrapper);