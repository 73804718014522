import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import { Icon, Label } from 'svift-ui';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { connect } from 'react-redux';
import ModalWrapper from 'components/modal';
import SaveAndPublish from 'components/app-header-buttons/SaveAndPublish';
import notification from 'components/hoc/notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import themeActions from 'store/actions/sites/themes';
import ThemeForm from 'components/forms/theme';
import { themesSelector } from 'store/selectors/sites/themes';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import { Switch, Route, withRouter } from 'react-router';

import ThemeSettings from '../side-navigation/sidenavigation-content/design/__components/ThemeSettings';
import ThemeDropdown from '../side-navigation/sidenavigation-content/design/__components/ThemeDropdown';
import SviftGA from 'utils/svift-ga';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import PreviewButton from 'components/app-header-buttons/PreviewButton';

const getHeaderIcon = route => {
	switch (route) {
		case 'text':
			return resolveElementIcon('Text');
		case 'image':
			return resolveElementIcon('Image');
		case 'video':
			return resolveElementIcon('Video');
		case 'map':
			return resolveElementIcon('Map');
		case 'button':
			return resolveElementIcon('Button');
		case 'menu':
			return  resolveElementIcon('Menu');
		case 'iframe':
			return  resolveElementIcon('IFrame');
		case 'input':
			return  resolveElementIcon('FormInput');
		case 'checkbox':
			return  resolveElementIcon('FormCheckbox');
		case 'radio':
			return  resolveElementIcon('FormRadio');
		case 'column':
			return  resolveElementIcon('Column');
		case 'row':
			return  resolveElementIcon('Row');
		case 'section':
			return  resolveElementIcon('Grid');
		case 'container':
			return  resolveElementIcon('Container');
		case 'body':
			return  resolveElementIcon('Body');
		default:
			return 'cube';
	}
};

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themeid = state.sites.themes.params.themeid;
	const site = state.sites.sites[siteid];
	const frontPageID = site.frontPage;

	const themes = themesSelector(state, siteid);

	const { stagedTheme, stagedFrom, view } = state.sites.themes;

	const theme = themes[themeid];
	const themeName = theme && theme.name;

	const isCustom = theme && theme.origin;

	let canPublish = false;

	if (theme) {
		// CUSTOM THEME HANDLING
		if (isCustom) {
			if (theme.draft && theme.live) {
				canPublish = theme.draft.version !== theme.live.version;
			} else {
				canPublish = true;
			}

			if (theme._id !== site.activeTheme) {
				canPublish = true;
			}
		// SYSTEM THEME HANDLING
		} else {
			canPublish = true;

			if (theme._id === site.activeTheme && stagedTheme === stagedFrom) {
				canPublish = false;
			}
		}
	}

	return {
		siteid,
		themeName,
		themeid,
		canPublish,
		isCustom,
		stagedFrom,
		stagedTheme,
		view,
		site,
		page: state.sites.pages.pages[frontPageID]
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createTheme: (...args) => dispatch(themeActions.createTheme(...args)),
		saveTheme: (...args) => dispatch(themeActions.saveTheme(...args)),
		publishTheme: (...args) => dispatch(themeActions.publishTheme(...args)),
		publishSystemTheme: (...args) => dispatch(themeActions.publishSystemTheme(...args)),
		toggleView: view => dispatch(themeActions.toggleView(view))
	};
};

class DesignHeader extends Component {
	constructor() {
		super();

		this.state = {
			themeModalOpen: false,
			publishing: false
		};
		
		this.onSave = this.onSave.bind(this);
		this.onPublish = this.onPublish.bind(this);
		this.toggleThemeModal = this.toggleThemeModal.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit(data, shouldPublish) {
		try {
			data.origin = this.props.stagedTheme._id;
			data.draft = {
				...this.props.stagedTheme
			};

			delete data.draft.name;
			delete data.draft._id;

			await this.props.createTheme(data, this.props.siteid, shouldPublish);

			SviftGA.THEMES.CREATED();

			this.toggleThemeModal();
		} catch (e) {
			handleSubmitError(this.props.intl)(e);
		}
	}

	async onPublish() {
		const noChanges = this.props.stagedTheme === this.props.stagedFrom;

		if (!this.props.isCustom && !noChanges) {
			this.setState({
				themeModalOpen: true,
				publishing: true
			});
		} else if (!this.props.isCustom && noChanges) {
			await this.props.publishSystemTheme(this.props.siteid, this.props.themeid);
		} else {
			await this.props.publishTheme(this.props.stagedTheme, this.props.siteid, this.props.themeid);
		}

		SviftGA.THEMES.LIVE_CONTENT_MODIFIED();

		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'design.deploy theme notification' }),
			className: 'warning',
			position: 'topCenter',
			icon: 'sn-bucket',
			deleteTime: 2000
		});
	}

	async onSave() {
		if (!this.props.isCustom) {
			this.setState({
				themeModalOpen: true,
				publishing: false
			});
		} else {
			await this.props.saveTheme(this.props.stagedTheme, this.props.siteid, this.props.themeid);

			SviftGA.THEMES.DRAFT_CONTENT_MODIFIED();

			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'design.save theme notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'sn-cloud-upload',
               deleteTime: 2000
            });
		}
	}

	getHeaderIcon=(view) =>{
		if(view === 'dashboard'){
			return(
				<React.Fragment>
					<span style={{ fontSize: 16, fontWeight: 200, marginRight: 4 }}>
						<FormattedMessage id="design.header overview subheader label" />
						<span className="primary-color" style={{ marginLeft: 8, fontWeight: 500 }}>
							<Icon name="sn-color-sampler" />
							{this.props.themeName}
						</span>
					</span>
					{/* <Label basic size="large" className="primary" style={{ margin: '0 8px 0 4px' }}>
					</Label> */}
					{/* <Icon name="sn-arrow-right2" style={{ fontSize: 8, opacity: 0.6, marginRight: 8 }} />
					<span>
						<FormattedMessage id="general.site" />
					</span>
					<Label basic size="tiny" className="info" style={{ margin: '0 8px 0 4px' }}>
						<Icon name="sn-sphere" style={{ verticalAlign: 'middle' }} />
						<span style={{ verticalAlign: 'middle' }}>
							{this.props.site.name}
						</span>
					</Label> */}
				</React.Fragment>
			)
		} else if (view === 'preview'){
			return(
				<div>
					<Icon name="sn-eye" />
					<span id="design-overview-title">
						{/* TO DO: insert theme title */}
						{this.props.site.name}
					</span>
				</div>
			)
		} else {
			return(
				<div>
					<Icon name="sn-sphere" />
					<span>
						<FormattedMessage id="design.header live subheader label" />
					</span>
					<span id="design-overview-title">
						{this.props.site.name}
					</span>
				</div>
			)
		}
	}

	toggleThemeModal() {
		this.setState({
			themeModalOpen: !this.state.themeModalOpen,
			publishing: false
		});
	}

	renderSaveTheme = () => {
		return (
			<SaveAndPublish
				saveLabel={this.props.intl.formatMessage({ id: 'design.save theme' })}
				savePopup={this.props.intl.formatMessage({ id: 'design.save theme popup' })}
				publishLabel={this.props.intl.formatMessage({ id: 'design.deploy theme' })}
				publishPopup={this.props.intl.formatMessage({ id: 'design.deploy theme popup' })}
				saveDisabled={this.props.stagedTheme === this.props.stagedFrom}
				publishDisabled={!this.props.canPublish && this.props.stagedTheme === this.props.stagedFrom}
				onSave={this.onSave}
				onPublish={this.onPublish}
				style={{ }}
			/>
		);
	}

	// sticky app header
	renderStickyHeader = () => {

		const secondaryOptionsStyle = {
			display: 'inline-block',
			background: 'rgba(255, 255, 255, 0.95)',
			boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
			padding: 8,
			borderRadius: 4,
			pointerEvents: 'auto', // make sure lower editor options are clickable
		};

		const node = document.getElementById(['preview', 'live'].includes(this.props.view) ? 'fullscreen-sticky-app-header' : 'sticky-app-header');

		if (!node) return null;

		return createPortal(
			<React.Fragment>
				{['preview'].includes(this.props.view) &&
					<div style={{ ...secondaryOptionsStyle, position: 'fixed', top: 8, left: 8, maxHeight: '100%', overflowY: 'auto' }}>
						{/* TO DO: PREVENT PAGE RELOAD (route change) WHEN SWITCHING THEME */}

						{/* theme menu */}
						{/* <ThemeDropdown /> */}
						
						{/* theme variables */}
						<ThemeSettings optionsPortalTarget="fullscreen-sticky-app-header" optionFrameStyle={{ top: 0, paddingTop: 64 }} />
					</div>
				}

				<div
					// switch between draft and live/preview modes
					style={!['preview', 'live'].includes(this.props.view)
						?
							secondaryOptionsStyle
						:
							{ ...secondaryOptionsStyle, position: 'fixed', top: 8, right: 8 }
					}
				>
					{/* secondary design options */}
					<div style={{ marginLeft: 'auto' }}>
						<PreviewButton
							noDraft
							view={this.props.view}
							toggleView={this.props.toggleView}
							dashboardIcon="sn-color-sampler icon"
							dashboardPopup={this.props.intl.formatMessage({ id: 'design.back to design' })}
							previewPopup={this.props.intl.formatMessage({ id: 'design.header switch preview popup' })}
							livePopup={this.props.intl.formatMessage({ id: 'design.header switch live popup' })}
						/>
					</div>
					{/* <div id="design-save-publish-button" style={{ display: 'inline-block', marginLeft: 16 }}>
						{this.props.view === 'live' ?
							<div id="design-save-publish-button">
								<SaveAndPublish
									savePopup={this.props.intl.formatMessage({ id: 'design.save theme popup' })}
									publishPopup={this.props.intl.formatMessage({ id: 'design.deploy theme popup' })}
									saveDisabled
									publishDisabled
									onPublish={this.onPublish}
									size="mini"
								/>
								<Label basic size="mini" className="warning" style={{ position: 'absolute', width: '100%', top: '100%', marginTop: 4, right: 0, boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px' }}>
									<Icon name="sn-info" style={{ verticalAlign: 'middle' }} />
									<FormattedMessage id="design.save publish disabled in live message" />
								</Label>	
							</div>
						:
							<div id="design-save-publish-button">
								<SaveAndPublish
									savePopup={this.props.intl.formatMessage({ id: 'design.save theme popup' })}
									publishPopup={this.props.intl.formatMessage({ id: 'design.deploy theme popup' })}
									saveDisabled={this.props.stagedTheme === this.props.stagedFrom}
									publishDisabled={!this.props.canPublish && this.props.stagedTheme === this.props.stagedFrom}
									onSave={this.onSave}
									onPublish={this.onPublish}
									size="mini"
								/>
							</div>
						}
					</div> */}
				</div>
			</React.Fragment>,
			node
		);
	}

	render() {
		const { page, stagedTheme, stagedFrom, view, intl } = this.props;
		
		return (
			<AppNavigation
				// TO DO: switch icon / header title when editing elements
				appIcon="sn-bucket"
				appTitle={intl.formatMessage({ id: 'design.app title' })}
				rawAppAction
				appAction={
					<Route
						path="/dashboard/:siteid/design/:themeid/elements/:type"
						render={props => (
							<span style={{ color: 'white', fontSize: 16, opacity: 0.6, marginLeft: 'auto' }}>
								/ <Icon name="sn-marquee-plus" style={{ marginRight: 6, verticalAlign: 'middle' }} />
								<span style={{ verticalAlign: 'middle' }}>
									<FormattedMessage id={`elements.title`} />
								</span>
							</span>
						)}
					/>
				}
			>
				{/* sticky app header */}
				{/* TO DO: Fix preview in Design */}
				{(this.state.detachedHeader || ['preview', 'live'].includes(view)) && this.renderStickyHeader()}

				<Switch>
					<Route
						path="/dashboard/:siteid/design/:themeid/elements/:type"
						render={props => (
							<React.Fragment>
								<div className="left-panel">
									<span style={{ marginRight: 4 }}>
										<FormattedMessage id="design.your theme title" /> <span style={{ fontWeight: 'bold' }}>
										</span> 
									</span>

									<Label basic className="info" size="small" style={{ marginRight: 8 }}>
										<Icon name="sn-color-sampler" style={{ marginRight: 6 }} />
										{this.props.themeName}
									</Label>
									
									<span style={{ marginRight: 4 }}>
										<FormattedMessage id="general.on" />
									</span>
									
									<Label className="primary" size="small" style={{ marginRight: 6 }}>
										<Icon name={getHeaderIcon(props.match.params.type)} />
										<FormattedMessage id={`elements.${props.match.params.type}s title`} />
									</Label>
								</div>

									<div className="right-panel">
										<PreviewButton
											open
											noDraft // special case on element header switch to hide dashboard button
											view={this.props.view}
											toggleView={this.props.toggleView}
											previewPopup={this.props.intl.formatMessage({ id: 'design.header switch preview popup' })}
										/>

										{this.renderSaveTheme()}
									</div>
							</React.Fragment>									
						)}
					/>

					<Route
						render={
							() => (
								<React.Fragment>
									<div className="left-panel">
										{this.getHeaderIcon(view)}
									</div>

									<div className="right-panel">
										<PreviewButton
											open
											noDraft
											view={this.props.view}
											toggleView={this.props.toggleView}
											previewPopup={this.props.intl.formatMessage({ id: 'design.header switch preview popup' })}
										/>

										{this.renderSaveTheme()}
									</div>
								</React.Fragment>
							)
						}
					/>
				</Switch>

				<ModalWrapper
					open={this.state.themeModalOpen}
					handleClose={this.toggleThemeModal}
					header={this.props.intl.formatMessage({ id: 'design.save new theme title' })}
				>
					<ThemeForm
						publishing={this.state.publishing}
						onClose={this.toggleThemeModal}
						onSubmit={this.onSubmit}
					/>
				</ModalWrapper>
			</AppNavigation>
		);
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(DesignHeader))));