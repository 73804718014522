import React, { memo, useCallback, useMemo } from "react";
import { injectIntl } from 'react-intl';
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Link from '../common/link/Link';
import Radio from 'components/option-library/primitives/radio';
import NumberPicker from 'components/option-library/primitives/input/number';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import Label from 'components/option-library/label';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset/Preset';
import FilePicker from 'components/option-library/primitives/file';
import css from '../options.scss';
import Sortable from 'components/sortable';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => { 
        updateState(context => {
            let nextValue = {
                ...context.nodeMap[node.id].props,
                [key]: value
            }
    
            if (typeof value === 'undefined') {
                delete nextValue[key];
            }
            
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: nextValue
                    }
                }
            };
        }, ...args);
    }, []);

    const suspend = useMemo(() => props.suspend({ 
        onChange: (shouldSkip, value, key, action) => {
            onChange(key, value, shouldSkip ? 'skip' : action, false)
        }}
    ), []);

    const [sortableData, setSortableData] = React.useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21']);

    return (
        <React.Fragment>
            <Section>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Sortable
                        items={sortableData}
                        onChange={setSortableData}
                        resolveItemID={item => item}
                        renderItem={dndProps => {
                            const { dragRef, opacity, item } = dndProps;
                
                            return (
                                <div 
                                    ref={dragRef} 
                                    style={{ 
                                        backgroundColor: 'pink', 
                                        opacity, 
                                        padding: '10px', 
                                        cursor: 'pointer' 
                                    }}
                                >
                                    {item}
                                </div>
                            );
                        }}
                    />
                </div> */}

                {/* select(ed) image file + thumbnail */}
                <FilePicker 
                    tabs={['files']}
                    filter={file => file.meta.mimeType.startsWith('image')}
                    onChange={file => {
                        onChange('src', file, 'select image', false);
                    }}
                    value={node.props.src}
                />

                {/* select type */}
                <Preset
                    suspend={suspend}
                    presets={props.theme.presets.image}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <Link 
                    onChange={useCallback((...args) => onChange('link', ...args), [onChange])}
                    value={node.props.link}
                />

                <Section
                    title={intl.formatMessage({ id: 'options.dimensions' })}
                    description={intl.formatMessage({ id: 'options.image dimensions description' })}
                    icon="sn-photo_size_select_large"
                >
                    <Label
                        labelIcon="sn-rulers"
                        label={intl.formatMessage({ id: 'options.image dimensions label' })}
                    >
                        <Radio 
                            options={[
                                    {
                                        value: undefined,
                                        label: intl.formatMessage({ id: 'general.default'})
                                    },
                                    {
                                        value: true,
                                        label: intl.formatMessage({ id: 'general.custom'})
                                    }
                            ]}
                            onChange={nextVal => onChange('custom', nextVal, 'image setting', false)}
                            value={node.props.custom}
                        />
                    </Label>


                    {node.props.custom &&
                        <React.Fragment>
                            <div className={css['filter-outline']} style={{ display: 'block' }}>
                                {/* image width */}
                                {/* <div style={{ display: 'flex' }}> */}
                                    {/* <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}> */}
                                        <Label
                                            collapsed
                                            labelIcon="sn-width"
                                            label={intl.formatMessage({ id: 'options.image width' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker
                                                name={intl.formatMessage({ id: 'options.image width' })}
                                                unit={node.props.widthType}
                                                units={['px', '%']}
                                                onUnitChange={val => onChange('widthType', val)}
                                                min={0}
                                                max={node.props.widthType === '%' ? 100 : 1400}
                                                value={node.props.width}
                                                onChange={val => onChange('width', val)}
                                            />
                                        </Label>

                                        <Label
                                            collapsed
                                            labelIcon="sn-height2"
                                            label={intl.formatMessage({ id: 'options.image height' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image height' })}
                                                unit={node.props.heightType}
                                                units={['px', '%']}
                                                onUnitChange={val => onChange('heightType', val)}
                                                min={0}
                                                max={node.props.heightType === '%' ? 100 : 1400}
                                                value={node.props.height}
                                                onChange={val => onChange('height', val)}
                                            />
                                        </Label>
                                    {/* </div> */}
                                    {/* image height
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>

                                    </div> */}
                                {/* </div> */}
                                <div style={{ display: 'flex' }}>
                                    {/* horizontal position */}
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>
                                        <Label
                                            collapsed
                                            labelIcon="sn-arrow-resize3"
                                            label={intl.formatMessage({ id: 'options.image crop position horizontal' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image crop position horizontal' })}
                                                unit="%"
                                                min={0}
                                                max={100}
                                                value={node.props.objectPositionX}
                                                onChange={val => onChange('objectPositionX', val)}
                                            />
                                        </Label>
                                    </div>
                                    {/* vertical position */}
                                    <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>
                                        <Label
                                            collapsed
                                            labelIcon="sn-arrow-resize4"
                                            label={intl.formatMessage({ id: 'options.image crop position vertical' })}
                                            fontSize={12}
                                        >
                                            <NumberPicker 
                                                name={intl.formatMessage({ id: 'options.image crop position vertical' })}
                                                unit="%"
                                                min={0}
                                                max={100}
                                                value={node.props.objectPositionY}
                                                onChange={val => onChange('objectPositionY', val)}
                                            />
                                        </Label>
                                    </div>
                                </div>

                                <Label
                                    label={intl.formatMessage({ id: 'options.image alignment horizontal' })}
                                    labelIcon="sn-arrow-resize3"
                                >
                                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                                        <Toggle
                                            icon="sn-vertical_align_top"
                                            rotated="counterclockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                                            tooltipPosition="top center"
                                            value={node.props.alignment === 'flex-start'}                            
                                            onChange={() => onChange('alignment', 'flex-start', 'image alignment', false)}
                                        />
                                        <Toggle
                                            icon="sn-vertical_align_center"
                                            rotated="clockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                                            tooltipPosition="top center"
                                            value={['flex-start', 'flex-end'].indexOf(node.props.alignment) === -1}
                                            onChange={() => onChange('alignment', 'center', 'image alignment', false)}
                                        />
                                        <Toggle
                                            icon="sn-vertical_align_top"
                                            rotated="clockwise"
                                            tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                                            tooltipPosition="top center"
                                            value={node.props.alignment === 'flex-end'}
                                            onChange={() => onChange('alignment', 'flex-end', 'image alignment', false)}
                                        />
                                    </ToggleGroup>
                                </Label>
                            </div>
                        </React.Fragment>
                    }
                </Section>

                {/* <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                /> */}
            </Accordion>
        </React.Fragment>
    );
}));