import React, { useRef, useState } from 'react';
import css from './clear-value.scss';
import Tooltip from 'svift-library/client/tooltip/Tooltip';
import { Icon } from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';

export default injectIntl(props => {
    const tooltipRef = useRef();
    const [hovered, setHovered] = useState();

    if (!props.enabled) return null;

    return (
        <React.Fragment>
            <Icon
                link
                ref={tooltipRef}
                name={hovered ? 'sn-cancel-circle2' : 'sn-cancel-circle'}
                className={css['clear-option-icon']}
                onMouseEnter={e => setHovered(true)} 
                onMouseLeave={e => setHovered(false)} 
                onClick={e => {
                    e.stopPropagation();
                    props.onClear(e);
                }}
            />

            <Tooltip
                popup
                hover
                target={tooltipRef.current}
                // open={hovered}
                zIndex={1013404010}
            >
                <FormattedMessage id="options.clear option button" />
            </Tooltip>
        </React.Fragment>
    );
});

{/* <Clear
    enabled={props.value}
    onClear={() => onChange()}
/> */}