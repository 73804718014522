import { useState, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';

export default props => {
    const [targetElement, setTargetElement] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const element = document.getElementById(props.target);
            
            if (element) {
                setTargetElement(element);
            } else {
                setTargetElement(null);
            }
        }, 100);
        
        return () => clearInterval(interval);
    }, []);

    if (!targetElement) return null;

    return createPortal(props.children, targetElement);
};
