module.exports = {
    resolveFile: (files, fileID) => {
        const file = files.find(({ _id }) => _id.toString() === (fileID && fileID.toString()));

        return file;
    },
    
    resolveFileUrl: (file, servingOptions) => {
        if (!file) return file;
        
        if (file.serving_url) {
            let options = '';

            if (Array.isArray(servingOptions) && servingOptions.length > 0) {
                options = `=${servingOptions.join('-')}`;
            }
         
            return file.serving_url + options; // https://stackoverflow.com/questions/25148567/list-of-all-the-app-engine-images-service-get-serving-url-uri-options
        }

        return file.url;
    }
};