import React from 'react';
import {
    Grid,
    GridRow,
    GridColumn,
	Image
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';

const ImageRight = props => {
    return(
        <Grid>
            <GridRow>
                <GridColumn computer={8}>
                    <p style={{ lineHeight: 1.5 }}>
                        <FormattedMessage id={props.text} />
                    </p>
                </GridColumn>
                <GridColumn computer={8}>
                    <Image src={props.imageSrc} style={{ marginBottom: 8 }} />
                    {props.imageText &&
                        <p style={{ lineHeight: 1.2, fontSize: 14 }}>
                            <FormattedMessage id={props.imageText} />
                        </p>
                    }
                </GridColumn>
            </GridRow>
        </Grid>
    );
}

export default injectIntl(ImageRight);