import React, { PureComponent } from 'react';
import { Form, Icon, Button, Message, ModalContent, ModalActions, Grid, GridColumn, FormInput, Header, Box, HeaderSubheader, Divider, FormSelect, Loader } from 'svift-ui';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// import textarea from 'components/forms/__util/field-components/textarea';
import input from 'components/forms/__util/field-components/input';
// import checkbox from 'components/forms/__util/field-components/checkbox';
// import dropdown from 'components/forms/__util/field-components/dropdown';

import validate, { gaToken } from 'components/forms/__util/validate';

const fields = {
	gaToken: {
		component: input({
			placeholder: 'settings.google analytics input placeholder',
			name: 'settings.google analytics input label',
			icon: 'sn-stats-bars2',
			type: 'text',
		}),
		validate: gaToken
	}
};

const validateFields = validate(fields);

class AnalyticsForm extends PureComponent {
	constructor() {
		super();

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validateFields(values);

		await this.props.onSubmit(values);
	}

	renderDropDown() {
		<div>Hello world</div>
	}

	render() {
		const { intl, handleSubmit, handleGoogleConnect, handleGSuite, state } = this.props;
		
		return (
			<div>

				<Divider />
				<Header as="h3">
					<FormattedMessage id="settings.google analytics title" />
				</Header>
				<p style={{ textAlign: 'center' }}>
					<FormattedMessage id="settings.google analytics description" /> <a href="https://google.com/analytics/" target="_blank"><FormattedMessage id="settings.google analytics link title" /></a>
				</p>

				{state &&
					<div>
						<Message
							className="info"
							size="large"
							icon={<Icon name="sn-info" style={{ fontSize: 28 }} />}
							content={
								<Header as="h4">
									<FormattedMessage id="general.important title" />
									<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
										<FormattedMessage id="settings.google analytics message initial warning description" />
									</HeaderSubheader>
								</Header>
							}
							style={{ textAlign: 'left', marginBottom: 16 }}
						/>
					
						<Button size="huge" className="primary" style={{ marginBottom: 16 }} onClick={handleGoogleConnect}><Icon name="sn-google" />
							<FormattedMessage id="settings.google analytics button connect with analytics title" />
						</Button>
					</div>
				}

				{state.accounts &&
					<div>
						<Message
							className="info"
							size="large"
							icon={<Icon name="sn-warning" style={{ fontSize: 32 }} />}
							content={
								<div>
									<Header as="h4">
										<FormattedMessage id="settings.google analytics message complete connection title" />
									</Header>
									<p>
										<FormattedMessage id="settings.google analytics message complete connection description" />
									</p>
								</div>
							}
							style={{ textAlign: 'left', marginBottom: 16 }}
						/>
						<FormSelect
							fluid
							options={state.accounts}
							placeholder={intl.formatMessage({ id: 'settings.google analytics website select placeholder' })}
							style={{ fontSize: 26, marginBottom: 16 }}
						/>
						<Button positive size="massive" style={{ marginBottom: 32 }}>
							<Icon name="checkmark" /> <FormattedMessage id="settings.google analytics button connect site with analytics title" />
						</Button>
					</div>
				}

				<Divider />
				<Form>
					<Header as="h3">
						<FormattedMessage id="settings.google gsuite title" />
					</Header>
					<p style={{ textAlign: 'left' }}>
						<FormattedMessage id="settings.google gsuite description" /> <a href="https://gsuite.google.com/" target="_blank"><FormattedMessage id="settings.google gsuite link title" /></a>
					</p>
					<FormInput
						size="huge"
						label={intl.formatMessage({ id: 'settings.google gsuite verification input label' })}
						placeholder={intl.formatMessage({ id: 'settings.google gsuite verification input placeholder' })}
					/>
					<p>
						{/* INSERT ACCORDION */}
						<a href="#">(+) Where do I find the verification code?</a>
					</p>
					<Button size="huge" positive onClick={handleGSuite}>
						<FormattedMessage id="settings.google gsuite save button title" />
					</Button>
				</Form>
			</div>
		);
	}
}

const ReduxAnalyticsForm = reduxForm({
	form: 'layout'
})(injectIntl(AnalyticsForm));

ReduxAnalyticsForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default ReduxAnalyticsForm;
