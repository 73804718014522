import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Box, Icon, Message, Modal, Wizard, WizardStep, Label, Divider } from 'svift-ui';

/* Steps */
import VerifyOwner from './steps/1-VerifyOwner';
import CreateSiteDetails from './steps/2-CreateSiteDetails';
import CreateSiteOrder from './steps/3-CreateSiteOrder';
import CreateSiteComplete from './steps/4-CreateSiteComplete';
import { injectIntl, FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router';
import SviftGA from 'utils/svift-ga';

import actions from 'store/actions/domainWizard';

const mapStateToProps = (state) => {
	return {
		activeStep: state.wizard.step,
		siteName: state.sites.sites[state.sites.params.siteid].name
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setStep: step => {
			window.scrollTo(0, 0);

			dispatch(actions.setStep(step));
		}
	};
};

class ActivateSiteWizard extends PureComponent {
	constructor() {
		super();

		SviftGA.ACTIVATION.STARTED();

		this.onStepChange = this.onStepChange.bind(this);
	}

	onStepChange(currentStep, nextStep) {
		if (this.props.activeStep < 3) {
			this.props.setStep(nextStep);
		}
	}

	getSteps() {
		return [
			<WizardStep
				key="owner"
				title={this.props.intl.formatMessage({ id: 'activation.owner wizard step title' })}
				icon="empty star"
			>
				<VerifyOwner currentStep={this.props.activeStep} setStep={this.props.setStep} />
			</WizardStep>,
			<WizardStep
				key="subscription"
				title={this.props.intl.formatMessage({ id: 'activation.subscription wizard step title' })}
				icon="sn-subscription-base"
			>
				<CreateSiteDetails setStep={this.props.setStep} />
			</WizardStep>,
			<WizardStep
				key="order"
				title={this.props.intl.formatMessage({ id: 'activation.order wizard step title' })}
				icon="sn-credit-card"
			>
				<CreateSiteOrder setStep={this.props.setStep} />
			</WizardStep>,
			<WizardStep
				key="finalize"
				title={this.props.intl.formatMessage({ id: 'activation.complete wizard step title' })}
				icon="sn-flag6"
			>
				<CreateSiteComplete setStep={this.props.setStep} />
			</WizardStep>,
		];
	}

	render() {
		const steps = this.getSteps();

		return (
			<Box>
				<div
					style={{
						backgroundColor: 'white',
						position: 'sticky',
						top: 60,
						zIndex: 99,
						marginBottom: 4,
						borderRadius: 4,
						boxShadow: '0 2px 6px 0 #AAA'
					}}
				>
					<Message
						className="info"
						style={{
							fontSize: 18,
							marginBottom: 0,
							verticalAlign: 'middle',
							padding: '8px 16px'
						}}
					>
						<span style={{ marginRight: 8, verticalAlign: 'middle' }}>
							<FormattedMessage id="activation.wizard label" />:
						</span>
						<Icon name="sn-sphere" style={{ marginRight: 4, opacity: 0.6, verticalAlign: 'middle' }} />
						<span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>
							{this.props.siteName}
						</span>
					</Message>
				</div>
				<Wizard
					hideControls
					onStepChange={this.onStepChange}
					activeStep={this.props.activeStep}
				>
					{steps}
				</Wizard>
			</Box>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActivateSiteWizard));