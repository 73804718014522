import React, { PureComponent } from 'react'
import { Icon, Message, Button, Progress, Label, Divider } from 'svift-ui'
import InfoIcon from 'components/InfoIcon'
import safeGet from 'utils/key'
import { connect } from 'react-redux'
import { sizeToReadable } from 'utils/files/format'
import { storageCapacity, filesSelector } from 'store/selectors/sites/files'
import { injectIntl, FormattedMessage } from 'react-intl'

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid

    return {
        files: filesSelector(state, siteid),
        storageCapacity: storageCapacity(state)
    }
}

class StorageIndicator extends PureComponent {
    renderStorageSeverityClassName = usedStorage => {
        if (usedStorage >= 50 && usedStorage < 75) {
            return 'info'
        } else if (usedStorage >= 75 && usedStorage < 95) {
            return 'warning'
        } else if (usedStorage >= 95) {
            return 'error'
        } else {
            return 'positive'
        }
    }

    // return messages depending on data usage
    renderStorageMessage = usedStorage => {
        if (usedStorage >= 50 && usedStorage < 75) {
            // info message - over half storage used
            return (
                <Message className="info" style={{ lineHeight: 1.2 }}>
                    {/* <Icon name="sn-info" style={{ marginRight: 4 }} /> */}
                    <span>
                        <FormattedMessage id="files.storage indicator info message description" />
                        <InfoIcon
                            description={this.props.intl.formatMessage({
                                id: 'files.storage indicator info message popup'
                            })}
                            position="right center"
                        />
                    </span>
                </Message>
            )
        } else if (usedStorage >= 75 && usedStorage < 95) {
            // warning message - less than 25% storage
            return (
                <Message className="warning" style={{ lineHeight: 1.2 }}>
                    <Icon name="sn-warning" style={{ marginRight: 4 }} />
                    <span>
                        <FormattedMessage id="files.storage indicator warning message description" />
                        <InfoIcon
                            description={this.props.intl.formatMessage({
                                id:
                                    'files.storage indicator warning message popup'
                            })}
                            position="right center"
                        />
                    </span>
                    {/* TO DO: add link to upgrade subscription */}
                    {/* <Button basic size="mini" fluid className="positive" style={{ marginTop: 4 }}>
						Upgrade now
					</Button> */}
                </Message>
            )
        } else if (usedStorage >= 95 && usedStorage < 100) {
            // error message - storage critical
            return (
                <Message className="error" style={{ lineHeight: 1.2 }}>
                    <Icon name="sn-warning2" style={{ marginRight: 4 }} />
                    <span>
                        <FormattedMessage id="files.storage indicator severe warning message description" />
                        <InfoIcon
                            description={this.props.intl.formatMessage({
                                id:
                                    'files.storage indicator severe warning message popup'
                            })}
                            position="right center"
                        />
                    </span>
                    {/* TO DO: add link to upgrade subscription */}
                    {/* <Button basic size="mini" fluid className="positive" style={{ marginTop: 4 }}>
						Upgrade now
					</Button> */}
                </Message>
            )
        } else if (usedStorage >= 100) {
            // out of storage!
            return (
                <Message
                    className="error"
                    style={{ lineHeight: 1.2, textAlign: 'center' }}
                >
                    <Icon
                        name="sn-warning2"
                        style={{
                            display: 'block',
                            fontSize: 32,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: 8
                        }}
                    />
                    <span style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="files.storage indicator error message description" />
                        <InfoIcon
                            description={this.props.intl.formatMessage({
                                id:
                                    'files.storage indicator error message popup'
                            })}
                            position="right center"
                        />
                    </span>
                    {/* TO DO: add link to upgrade subscription */}
                    {/* <Button basic size="small" fluid className="positive" style={{ marginTop: 4 }}>
						Upgrade now
					</Button> */}
                </Message>
            )
        } else {
            // nothing to report
            return null
        }
    }

    // calculate + floor remaining data (GB)
    remainingStorage = total => {
        let remaining = Number(
            Math.round(((100 - this.props.storageUsage) / 100) * total + 'e2') +
                'e-2'
        )

        if (remaining < 0) {
            remaining = 0
            return remaining
        } else {
            return remaining
        }
    }

    getBytesUsed = () => {
        const { files } = this.props

        const bytesUsed = files.reduce((sum, file) => {
            const size = parseInt(file.meta.size, 10)

            return size + sum
        }, 0)

        return bytesUsed
    }

    render() {
        const bytesAvailable = this.props.storageCapacity
        const gbAvailable = bytesAvailable / 1000 / 1000 / 1000
        const bytesUsed = this.getBytesUsed()
        const bytesLeft = bytesAvailable - bytesUsed
        const percentagesUsed = ((bytesUsed / bytesAvailable) * 100).toFixed(1)

        return (
            <div style={{ marginTop: 'auto' }}>
                <Divider style={{ marginBottom: 8 }} />

                <div style={{ marginBottom: 6 }}>
                    <Icon
                        name="database"
                        style={{ marginRight: 4, opacity: 0.6 }}
                    />
                    <FormattedMessage id="files.storage title" />
                    <InfoIcon
                        description={this.props.intl.formatMessage({
                            id: 'files.storage popup'
                        })}
                        position="top left"
                    />
                </div>

                <Label
                    basic
                    className={this.renderStorageSeverityClassName(
                        percentagesUsed
                    )}
                    style={{ width: '100%', fontWeight: 300, marginBottom: 4 }}
                >
                    <Icon name="database" style={{ marginRight: 4 }} />
                    <span style={{ fontWeight: 'bold' }}>
                        {sizeToReadable(bytesUsed)}
                    </span>
                    ({percentagesUsed}%)
                    <FormattedMessage id="general.used of label" />
                    {gbAvailable}
                    <FormattedMessage id="general.details gb storage" />
                </Label>

                <Progress
                    size="small"
                    percent={Math.ceil(percentagesUsed)}
                    className={this.renderStorageSeverityClassName(
                        percentagesUsed
                    )}
                    style={{ margin: 0 }}
                />

                <p
                    style={{
                        fontSize: 10,
                        textAlign: 'center',
                        margin: '4px 0 8px 0',
                        opacity: 0.7
                    }}
                >
                    <span style={{ fontWeight: 'bold' }}>
                        {sizeToReadable(bytesLeft)}
                    </span>
                    <FormattedMessage id="general.available label" />
                </p>

                {this.renderStorageMessage(percentagesUsed)}
            </div>
        )
    }
}

export default connect(mapStateToProps)(injectIntl(StorageIndicator))
