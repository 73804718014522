import React from 'react';
import { getIconByMimeType } from 'utils/files/format';
import OptionLabel from 'components/option-library/label';
import { FormattedMessage, injectIntl, intlShape, FormattedDate } from 'react-intl';
import {
	Button,
    Icon,
    Label,
    Message
} from 'svift-ui';
// import FilePicker from 'components/option-library/primitives/file';

const LinkPreviewByType = injectIntl(props => {
    const { intl } = props;

    if (props.value.type === 'page') {
        const selectedPage = props.pages.find(page => page._id === props.value.page);

        if (!selectedPage) return 'Stale page link';

        const pageDescription = selectedPage.description;
        // const pageParent = selectedPage.parentPage;
        // const pageLastUpdated = selectedPage.updatedAt;

        return (
            <React.Fragment>
                <OptionLabel>
                    <div style={{ fontWeight: 500 }}>
                        <FormattedMessage id="pages.single page title" />:
                    </div>
                    <div className="ellipsis">
                        <Icon
                            name="sn-file-text"
                            style={{ marginRight: 6 }}
                        />
                        {selectedPage.title}
                    </div>
                </OptionLabel>

                {/* TO DO: retrieve parent page title */}
                {/* page description */}
                {/* {pageParent &&
                    <OptionLabel label="PARENT">
                        <div className="ellipsis">
                            <Icon
                                name="sn-file-text"
                                style={{ marginRight: 6 }}
                            />
                            {pageParent}
                        </div>
                    </OptionLabel>
                } */}

                {/* page description */}
                {pageDescription &&
                    <OptionLabel
                        labelIcon="sn-quill2"
                        label={intl.formatMessage({ id: 'pages.page description' })}
                    >
                        {pageDescription}
                    </OptionLabel>
                }

                {/* last updated - pageLastUpdated er ikke nødvendigvis reliable */}
                {/* {pageLastUpdated &&
                    <OptionLabel
                        labelIcon="sn-clock"
                        label={intl.formatMessage({ id: 'pages.page last updated' })}
                    >
                        <FormattedDate
                            value={Date(pageLastUpdated)}
                            year='numeric'
                            month='long'
                            day='2-digit'
                        />
                    </OptionLabel>
                } */}
            </React.Fragment>
        );
    } else if (props.value.type === 'blogPost') {
        const selectedBlog = props.blogs.find(blog => blog._id === props.value.blogPost);

        if (!selectedBlog) return 'Stale blog link';

        const postDescription = selectedBlog.description;
        // const postAuthor = selectedBlog.author;
        // const postLastUpdated = selectedBlog.updatedAt;

        return (
            <React.Fragment>
                <OptionLabel>
                    <div style={{ fontWeight: 500 }}>
                        <FormattedMessage id="blog.post title" />:
                    </div>
                    <div className="ellipsis">
                        <Icon
                            name="sn-magazine"
                            style={{ marginRight: 6 }}
                        />
                        {selectedBlog.title}
                    </div>
                </OptionLabel>

                {/* post description */}
                {postDescription &&
                    <OptionLabel
                        labelIcon="sn-quill2"
                        label={intl.formatMessage({ id: 'pages.page description' })}
                    >
                        {postDescription}
                    </OptionLabel>
                }

                {/* post author */}
                {/* {postAuthor &&
                    <OptionLabel label="AUTHOR">
                        <Label basic size="mini">
                            <Icon
                                name="sn-user3"
                                style={{ marginRight: 6 }}
                            />
                            {postAuthor}
                        </Label>
                    </OptionLabel>
                } */}

                {/* last updated - postLastUpdated er ikke nødvendigvis reliable */}
                {/* {postLastUpdated &&
                    <OptionLabel
                        labelIcon="sn-clock"
                        label={intl.formatMessage({ id: 'pages.page last updated' })}
                    >
                        <Label basic size="mini">
                            <FormattedDate
                                value={Date(postLastUpdated)}
                                year='numeric'
                                month='long'
                                day='2-digit'
                            />
                        </Label>
                    </OptionLabel>
                } */}
            </React.Fragment>
        );
    } else if (props.value.type === 'file') {
        const selectedFile = props.filesDictionary[props.value.id];
        if (!selectedFile) return 'Stale file link';

        const fileMimeType = selectedFile.meta.mimeType;
        const fileType = fileMimeType.split('/')[0];
        const fileExtension = fileMimeType.split('/')[1];
        const fileIcon = getIconByMimeType(fileMimeType);
        
        const fileLastUpdated = selectedFile.lastUpdated;
        let mediaWidth;
        let mediaHeight;

        if(fileType === 'image') {
            mediaWidth = selectedFile.meta.dimensions.width;
            mediaHeight = selectedFile.meta.dimensions.height;
        }
        
        // const fileUrl = selectedFile.url;

        return (
            <React.Fragment>
                <OptionLabel>
                    <span style={{ fontWeight: 500 }}><FormattedMessage id="files.file name label" />:</span><br />
                    <div className="ellipsis">
                        <Icon
                            name={fileIcon}
                            style={{ marginRight: 6 }}
                        />
                        {selectedFile && selectedFile.meta && selectedFile.meta.name}
                    </div>
                </OptionLabel>

                {/* file type */}
                <OptionLabel
                    labelIcon="sn-file"
                    label={intl.formatMessage({ id: 'files.file type' })}
                >
                    <Label basic size="mini" className="primary">{fileType}</Label>
                    <Label basic size="mini" className="secondary">{fileExtension}</Label>
                </OptionLabel>

                {/* media dimensions */}
                {fileType === 'image' &&
                    <OptionLabel
                        labelIcon="sn-rulers"
                        label={intl.formatMessage({ id: 'files.file dimensions' })}
                    >
                        {/* <span style={{ marginRight: 4 }}>Width:</span> */}
                        <Label basic size="mini" style={{ marginRight: 6 }}>{mediaWidth} px</Label>
                        <span>x</span>
                        {/* <span style={{ marginLeft: 6, marginRight: 4 }}>Height:</span> */}
                        <Label basic size="mini" style={{ marginLeft: 6, marginRight: 0 }}>{mediaHeight} px</Label>
                    </OptionLabel>
                }

                {/* Last Updated */}
                <OptionLabel
                    labelIcon="sn-upload2"
                    label={intl.formatMessage({ id: 'files.file last updated' })}
                >
                    <Label basic size="mini">
                        <FormattedDate
                            value={Date(fileLastUpdated)}
                            year='numeric'
                            month='long'
                            day='2-digit'
                        />
                    </Label>
                </OptionLabel>

                {/* file URL */}
                {/* <OptionLabel
                    labelIcon="sn-earth"
                    label={intl.formatMessage({ id: 'files.file url' })}
                >
                    <Label basic size="mini">{fileUrl}</Label>
                </OptionLabel> */}
            </React.Fragment>
        );
    } else {
        if (!props.value.url) return 'Invalid external url';

        return (
            <React.Fragment>
                <OptionLabel>
                    <div style={{ fontWeight: 500 }}>
                        <FormattedMessage id="options.link url title" />:
                    </div>
                    <div className="ellipsis" title={props.value.url}>
                        <Icon
                            name="sn-earth"
                            style={{ marginRight: 6 }}
                        />
                        {props.value.url}
                    </div>
                </OptionLabel>

                {props.value.description &&
                    <OptionLabel>
                        <div style={{ fontWeight: 500 }}>
                            <FormattedMessage id="options.link summary" />:
                        </div>
                        <div>
                            <Icon
                                name="sn-bubble-lines4"
                                style={{ marginRight: 6 }}
                            />
                            {props.value.description}
                        </div>
                    </OptionLabel>
                }

                {props.value.newWindow
                    &&
                        <OptionLabel>
                            <Icon name="sn-checkmark3" style={{ marginRight: 3 }} />
                            <Icon name="sn-new-tab" style={{ marginRight: 6 }} />
                            <span style={{ fontWeight: 500 }}><FormattedMessage id="options.open link in new window" /></span>
                        </OptionLabel>
                }
            </React.Fragment>
        );
    }
})

export default injectIntl(props => {
    const { setChangingLink, removeLink, value, blogs, pages, filesDictionary } = props;

    return (
        <Message className="info" size="small" style={{ padding: 8 }}>
            {/* selected link */}
            <div style={{ marginBottom: 16 }}>
                <OptionLabel>
                    <Icon name="sn-link" style={{ marginRight: 4 }} />
                    <span>
                        <FormattedMessage id="options.link added label" />:
                    </span>
                </OptionLabel>

                <LinkPreviewByType 
                    value={value} 
                    blogs={blogs}
                    pages={pages}
                    filesDictionary={filesDictionary}
                />

                {/* IDEA: create link (opens in new window) to review added link */}
            </div>

            {/* update link actions */}
            {/* TO DO: add popups to buttons */}
            <div style={{ display: 'inline-block', width: '50%', paddingRight: 4 }}>
                <Button
                    icon
                    fluid
                    size="mini"
                    className="info"
                    onClick={() => setChangingLink(true)}
                >
                    <Icon name="sn-loop3" />
                    <span style={{ marginLeft: 8 }}>
                        <FormattedMessage id="general.change" />
                    </span>
                </Button>
            </div>
            <div style={{ display: 'inline-block', width: '50%', paddingLeft: 4 }}>
                <Button
                    icon
                    fluid
                    size="mini"
                    className="negative"
                    onClick={removeLink} 
                >
                    <Icon name="sn-unlink" />
                    <span style={{ marginLeft: 8 }}>
                        <FormattedMessage id="general.remove" />
                    </span>
                </Button>
            </div>
        </Message>
    );
})
