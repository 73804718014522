import React from 'react';
import PageForm from 'components/forms/page';
import Modal from 'svift-library/client/modal';
import { injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import { connect } from 'react-redux';
import { pagesSelector } from 'store/selectors/sites/pages';
import actions from 'store/actions/sites/pages';
import makeKeywordsSelector  from 'store/selectors/sites/keywords';
const keywordsSelector = makeKeywordsSelector('page');

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
    const pageid = state.sites.pages.params.pageid;

	return {
		siteid,
		pageid,
		keywords: keywordsSelector(state, siteid),
		pages: pagesSelector(state, siteid)
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		createPage: (siteID, newPage) => dispatch(actions.createPage(siteID, newPage)),
		deletePage: (siteID, pageID) => dispatch(actions.deletePage(siteID, pageID)),
		updatePage: (siteID, pageID, newValues) => dispatch(actions.updatePage(siteID, pageID, newValues))
	};
};

class PagesContainer extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			loading: false
		};

		this.createNewPage = this.createNewPage.bind(this);
		this.updatePage = this.updatePage.bind(this);
		this.deletePage = this.deletePage.bind(this);
	}

    async createNewPage(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;

		try {
			await this.props.createPage(siteid, values);

			SviftGA.PAGES.CREATED();

			this.setState({
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
	
	async updatePage(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;
		const pageid = this.props.pageid;

		try {
			await this.props.updatePage(values, siteid, pageid);

			SviftGA.PAGES.MODIFIED();

			this.setState({
				loading: false,
				modalOpen: false
			});

			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'pages.settings notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'setting',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deletePage() {
		this.setState({
			loading: true
		});

		try {
			await this.props.deletePage(this.props.siteid, this.props.pageid);

			SviftGA.PAGES.DELETED();

			this.setState({
				loading: false
            });
            
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'pages.delete notification' }),
                className: 'info',
                position: 'topCenter',
                icon: 'trash',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
    // if (!modalOpen) return null;

    render() {
        const currentPage = this.props.pages.find(page => page._id === this.props.pageid);

        return (
            <React.Fragment>
                {this.props.renderButton(open => this.setState({ modalOpen: open }))}
    
                {this.state.modalOpen &&
                    <Modal
						title={this.props.intl.formatMessage({ id: `pages.${(this.state.modalOpen === 'create') ? 'new page' : 'settings'}`})}
						toggleModal={open => this.setState({ modalOpen: open })}
                        nodeAttributes={{
                            'data-ignore-click': true
						}}
						style={{maxWidth: 880 }}
                    >
                        {this.state.modalOpen === 'update' && currentPage &&
                            <PageForm
                                update
                                keywords={this.props.keywords}
                                loading={this.state.loading}
                                page={currentPage._id}
                                enableReinitialize
                                initialValues={{
                                    title: currentPage.title,
                                    parentPage: currentPage.parentPage || '__noParentPage__', // will be converted to null if submitted (see layout form for more details)
                                    layout: currentPage.layout,
                                    hidden: currentPage.hidden,
                                    author: currentPage.author,
                                    navigationTitle: currentPage.navigationTitle,
                                    description: currentPage.description,
                                    keywords: currentPage.keywords,
                                    thumbnail: currentPage.thumbnail
                                }}
                                onClose={() => this.setState({ modalOpen: false })}
                                delete={this.deletePage}
                                onSubmit={this.updatePage}
                            />
						}

                        {this.state.modalOpen === 'create' &&
							<PageForm
                                loading={this.state.loading}
                                keywords={this.props.keywords}
                                tags={this.props.tags}
								onSubmit={this.createNewPage}
								onClose={() => this.setState({ modalOpen: false })}
							/>
						}
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(PagesContainer)));