import React from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import NumberPicker from 'components/option-library/primitives/input/number';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
// import Dropdown from 'components/option-library/primitives/dropdown/simple';
// import { ColorPalette } from 'components/option-library/primitives/color-picker';
// import MarginDirection from './MarginDirection';
import { useHistoryHelper } from 'components/editor-v2/extend/historyHelper';
import key from 'utils/key';
import css from '../../../../options.scss';

// const historyHelper = useHistoryHelper(
//     props.getState,
//     'resize horizontal',
//     context => context.nodeMap[column.current.id].width,
//     value => context => {
//         return {
//             ...context,
//             nodeMap: {
//                 ...context.nodeMap,
//                 [column.current.id]: {
//                     ...context.nodeMap[column.current.id],
//                     width: value
//                 }
//             }
//         };
//     }
// );
// historyHelper.commit();

const Margin = injectIntl(React.memo(props => {
    const { accordionState, intl, value, getState, theme } = props;
    const [state, dispatch] = accordionState;

    const onChange = (key, value) => {
        let nextMargin = {
            ...props.inlineStyling,
            [key]: value
        };

        if (key === 'generalMargin') {
            nextMargin = {
               [key]: value
            };
        } else {
            delete nextMargin.generalMargin;
        }

        if (typeof value === 'undefined') delete nextMargin[key];

        if (Object.keys(nextMargin).length === 0) {
            props.onChange();
        } else {
            props.onChange(nextMargin);
        }
    };

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                // transl8 - add description to section (requires prop)
                icon="sn-enlarge3"
                title={intl.formatMessage({ id: 'options.margin title' })}
                nested
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
                >
                <Label
                    labelIcon="move"
                    label={intl.formatMessage({ id: 'options.margin general title' })}
                    clear={{
                        onClear: () => onChange('generalMargin'),
                        enabled: typeof key('inlineStyling.generalMargin')(props) !== 'undefined'
                    }}
                >
                    <NumberPicker
                        disableUndoRedo
                        fluid={false}
                        name={intl.formatMessage({ id: 'options.margin general title' })}
                        unit="%"
                        // TO DO: convert value to px:
                        // unit={`% (${})`}
                        min={0}
                        max={500}
                        tooltipPosition="right center"
                        // placeholder={Math.floor(defaultValue * 100)}
                        value={Math.round(key('inlineStyling.generalMargin')(props) * 100)}
                        onChange={newValue => onChange('generalMargin', typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
                    />
                </Label>
                
                <div className={css['filter-outline']} style={{ display: 'block' }}>
                    {/* top margin option */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-up8"
                                label={intl.formatMessage({ id: 'general.top' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('marginTop'),
                                    enabled: typeof key('inlineStyling.marginTop')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.margin top title' })}
                                    unit="%"
                                    min={-250}
                                    max={250}
                                    tooltipPosition="right center"
                                    // placeholder={Math.floor(defaultValue * 100)}
                                    value={Math.round(key('inlineStyling.marginTop')(props) * 100)}
                                    onChange={newValue => onChange('marginTop', typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
                                />
                            </Label>
                        </div>
                    </div>

                    {/* left / right margin options */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', paddingRight: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-left8"
                                label={intl.formatMessage({ id: 'general.left' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('marginLeft'),
                                    enabled: typeof key('inlineStyling.marginLeft')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.margin left title' })}
                                    unit="%"
                                    min={-250}
                                    max={250}
                                    tooltipPosition="right center"
                                    // placeholder={Math.floor(defaultValue * 100)}
                                    value={Math.round(key('inlineStyling.marginLeft')(props) * 100)}
                                    onChange={newValue => onChange('marginLeft', typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', paddingLeft: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-right8"
                                label={intl.formatMessage({ id: 'general.right' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('marginRight'),
                                    enabled: typeof key('inlineStyling.marginRight')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker
                                    disableUndoRedo
                                    name={intl.formatMessage({ id: 'options.margin right title' })}
                                    unit="%"
                                    min={-250}
                                    max={250}
                                    tooltipPosition="right center"
                                    // placeholder={Math.floor(defaultValue * 100)}
                                    value={Math.round(key('inlineStyling.marginRight')(props) * 100)}
                                    onChange={newValue => onChange('marginRight', typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
                                />
                            </Label>
                        </div>
                    </div>

                    {/* bottom margin option */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-down8"
                                label={intl.formatMessage({ id: 'general.bottom' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('marginBottom'),
                                    enabled: typeof key('inlineStyling.marginBottom')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker
                                    disableUndoRedo
                                    icon="sn-arrow-down8"
                                    name="bottom placeholder"
                                    unit="%"
                                    min={-250}
                                    max={250}
                                    tooltipPosition="right center"
                                    // placeholder={Math.floor(defaultValue * 100)}
                                    value={Math.round(key('inlineStyling.marginBottom')(props) * 100)}
                                    onChange={newValue => onChange('marginBottom', typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
                                />
                            </Label>
                        </div>
                    </div>
                </div>

                {/* <MarginDirection
                    type="generalMargin"
                    translationKey="options.margin general title"
                    preset={presetMargin}
                    theme={props.theme}
                    value={margin}
                    onChange={onChange}
                />

                <MarginDirection
                    type="marginLeft"
                    translationKey="options.margin left title"
                    preset={presetMargin}
                    theme={props.theme}
                    value={margin}
                    onChange={onChange}
                />

                <MarginDirection
                    type="marginRight"
                    translationKey="options.margin right title"
                    preset={presetMargin}
                    theme={props.theme}
                    value={margin}
                    onChange={onChange}
                />

                <MarginDirection
                    type="marginTop"
                    translationKey="options.margin top title"
                    preset={presetMargin}
                    theme={props.theme}
                    value={margin}
                    onChange={onChange}
                />

                <MarginDirection
                    type="marginBottom"
                    translationKey="options.margin bottom title"
                    preset={presetMargin}
                    theme={props.theme}
                    value={margin}
                    onChange={onChange}
                /> */}
            </Section>
        </Accordion>
    );
}));

export default Margin;