/*
	General validators go in this file.

	When a validator detects an error, it must return a function generated by calling 'generateTranslatedErrors'

	intl.formatMessage({id: amaerkmeakm})

	TO-DO:

	Make validation cases more composable; should probably move them into their own project/package so they can be reused by backend (and other future projects)
*/

const generateTranslatedErrors = (...translations) => intl => {
	if (translations.length === 0) return;

	return translations.map(translation => intl.formatMessage(...translation)).join(', ');
};

// Validators below
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const email = data => {
	if (!emailRegEx.test(data)) {
		return generateTranslatedErrors(
			[
				{ id: 'validation.not valid email' }
			]
		);
	}
};

const string = (min = 0, max = 100000) => data => {
	if (typeof data !== 'string') {
		return generateTranslatedErrors(
			[
				{ id: 'validation.field required' }
			]
		);
	}

	const withinRange = data.length >= min && data.length <= max;
	const isString = typeof data === 'string';

	if (!isString || !withinRange) {
		return generateTranslatedErrors(
			[
				{ id: 'validation.minimum number of characters' },
				{ minCount: min }
			],
			[
				{ id: 'validation.maximum number of characters' },
				{ maxCount: max }
			]
		);
	}
};

const gaRegex = /^ua-\d{4,9}-\d{1,4}$/i;
const gaToken = data => {
	if (typeof data !== 'string') {
		return generateTranslatedErrors(
			[
				{ id: 'validation.field required' }
			]
		);
	}

	if (!gaRegex.test(data)) {
		return generateTranslatedErrors(
			[
				{ id: 'validation.invalid google analytics token' }
			]
		);
	}
};

const valid = () => {

};

// export const timeZone = timeZone => !!momentTZ.tz.zone(timeZone) || 'invalid timezone (valid timezones: http://momentjs.com/timezone/) (more help: http://momentjs.com/timezone/docs/#/data-loading/checking-if-a-zone-exists/)';

export { email, emailRegEx, string, valid, gaToken };
export default generateTranslatedErrors;