import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'Text';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {
			text: {
				blocks: [
					{
						key: '32423',
						text: 'Her er et eksempel på noget tekst. Dette viser hvordan teksten fremstår i dit indhold. Du kan ændre dette til at passe til dit budskab, hvor du kan indsætte overskrifter, stor tekst, citater m.m.',
						type: 'Normal',
						inlineStyle: [],
						entityRanges: [],
						data: {}
					}
				],
				entityMap: {}
			}
		},
		width: 4
	})
};