import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { pagesSelector } from 'store/selectors/sites/pages';
import { filesSelector } from 'store/selectors/sites/files';
import { formsSelector } from 'store/selectors/sites/forms';
import { blogsSelector } from 'store/selectors/sites/blogs';
import DesignOverview from './_component/Overview';
import ThemePreview from './ThemePreview';

function mapStateToProps(state) {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];
	const page = state.sites.pages.pages[site.frontPage];
	const view = state.sites.themes.view;

	let theme = state.sites.themes.stagedTheme;

	if (view === 'live') {
		const systemThemes = state.system.themes;
		const customThemes = state.sites.themes.themes;

		theme = systemThemes[site.activeTheme] || customThemes[site.activeTheme].live;
	}

	const layout = state.sites.layout.layoutContent[page.layout];
	let defaultLayout;

	if (!layout) {
		const layoutContent = site.layouts.map(layoutId => state.sites.layout.layouts[layoutId]).find(lo => lo.type === 'page' && lo.default).content;

		defaultLayout = state.sites.layout.layoutContent[layoutContent];
	}

	return {
		view,
		site: state.sites.sites[siteid],
		pageID: page._id,
		siteID: siteid,
		pages: pagesSelector(state, siteid),
		files: filesSelector(state, siteid),
		blogs: blogsSelector(state, siteid),
		filesObj: state.sites.files.files,
		forms: formsSelector(state, siteid),
		stagedTheme: theme,
		content: state.sites.content.content[site.frontPage],
		layout: layout || defaultLayout
	};
}

class DesignIndex extends PureComponent {
	render() {
		const { stagedTheme, content, layout, view } = this.props;

		if (!stagedTheme) return null;
		if (!content) return null;
		if (!layout) return null;
		if (view === 'dashboard'){
			return <DesignOverview theme={stagedTheme}/>
		} 

		return (
			<ThemePreview 
				theme={stagedTheme} 
				siteID={this.props.siteID}
				pageID={this.props.pageID}	
				layoutID={this.props.layoutID}
			/>
		);
	}
}

export default connect(mapStateToProps)(DesignIndex);

