import React, { useRef, useMemo, useImperativeHandle, useEffect, useLayoutEffect } from 'react';
import { DropTarget } from 'react-dnd';
import createThrottle from 'svift-library/throttle';

const onHover = (targetInterface, props, monitor, ref) => {
	const clientOffset = monitor.getClientOffset();

	if (!clientOffset) return;

	const node = ref.getNode();
	const rect = node.getBoundingClientRect();

	const hoverClientX = clientOffset.x - rect.left;
	const hoverClientY = clientOffset.y - rect.top;

	const { cursorZones } = targetInterface;

	const activeZones = cursorZones.filter(({ xStart, xEnd, yStart, yEnd, maxLength, handler }, i) => {
		// const isAlreadyRegistered = 

		let xStartPx = asPixels(xStart, rect.width);
		let xEndPx = asPixels(xEnd, rect.width);
		let yStartPx = asPixels(yStart, rect.height);
		let yEndPx = asPixels(yEnd, rect.height);

		if (maxLength) {
			const { position, px } = maxLength;
	
			if (position === 'top') {
				yEndPx = Math.min(px, yEndPx);
			} else if (position === 'bottom') {
				yStartPx = Math.max(rect.height - px, yStartPx);
			} else if (position === 'left') {
				xEndPx = Math.min(px, xEndPx);
			} else if (position === 'right') {
				xStartPx = Math.max(rect.width - px, xStartPx);
			}
		}

		const withinX = hoverClientX > xStartPx && hoverClientX < xEndPx;
		const withinY = hoverClientY > yStartPx && hoverClientY < yEndPx;

		return withinX && withinY;
	});

	props.dragState.register(props.nodeID, activeZones, props.getPath, props.updateState);
}

const asPixels = (percentage, totalPixels) => totalPixels * (percentage / 100);

const createDropZoneCursorManager = props => {
	const throttle = createThrottle(75);
	
    const DropZone = React.forwardRef((props, ref) => {
		const nodeRef = useRef();
		// const lastZones = useRef(null);

        useImperativeHandle(ref, () => ({
			getNode: () => nodeRef.current,
			// lastZones
		}));

		useEffect(() => {
			if (props.isOver) {
				return () => {
					throttle.queue(() => props.dragState.register(props.nodeID, [])); ///*, lastZones.current*/);
					throttle.flush(true);	
				}
			}

			// if (!props.isOver) {
			// 	console.log('no longer over')
			// 	console.log(props.hasDropped)
			// 	throttle.queue(() => props.dragState.register(props.nodeID, [])); ///*, lastZones.current*/);
			// 	throttle.flush(true);
			// 	// console.log(props.nodeID, "LEAVING");
			// } /*else {
			// 	lastZones.current = null;
			// }*/
		}, [props.isOver]);

		// return React.Children.map(props.children, (child, index) => {
		// 	if (index === 0) {
		// 		return React.cloneElement(child, { 
		// 			connectDropTarget: ref =>  {
		// 				props.connectDropTarget(ref);
		// 				nodeRef.current = ref;
		// 			}
		// 		})
		// 	}

		// 	return child;
		// });
		
        return React.cloneElement(props.children, { 
            connectDropTarget: ref =>  {
                props.connectDropTarget(ref);
                nodeRef.current = ref;
			}
        })
    });

    const targetInterface = props.dndInterface[props.node.component] || props.dndInterface.ColumnElement;
    const sources = targetInterface.sources;
	const spec = {
		drop: (props, _, ref) => {
			// const lastZones = ref.lastZones;

			// throttle.queue(() => props.dragState.deregister(props.nodeID, lastZones.current));
			throttle.flush(false);
		}, // Making sure we don't call throttled hover functions after the interaction is complete
		hover: (...args) => throttle.queue(() => onHover(targetInterface, ...args))
	};
	const collect = (connect, monitor) => {
		return {
			connectDropTarget: connect.dropTarget(),
			hasDropped: monitor.didDrop(), // Don't touch this, seems like it does nothing, but it does force updates in some way that prevents a timing bug - not sure why, too many libraries involved 
			isOver: monitor.isOver()
		};
	};

	return DropTarget(sources, spec, collect)(DropZone);
}

export default props => {
    const DropZoneCursorManager = useMemo(() => createDropZoneCursorManager(props), []);

    return <DropZoneCursorManager {...props} />;
};