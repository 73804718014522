import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    let label = props.intl.formatMessage({ id: 'options.maximum width' });

    return (
        <Label
            labelIcon="sn-width"
            label={label}
            fontSize={12}
            style={{ marginTop: 8 }}
            clear={{
                onClear: () => props.onChange(),
                enabled: typeof props.inlineStyling !== 'undefined'
            }}
        >
            <NumberPicker
                disableUndoRedo
                name={label}
                icon="sn-width"
                unit="px"
                min={0}
                max={1200}
                tooltipPosition="right center"
                // placeholder={Math.floor(defaultValue * 100)}
                value={props.inlineStyling}
                onChange={props.onChange} //newValue => props.onChange(typeof newValue !== 'undefined' ? newValue / 100 : undefined)}
            />
        </Label>
    );
});
