import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { DropTarget } from 'react-dnd';
import dndInterface from '../interface';

class Portal extends PureComponent {
    constructor(props) {
        super(props);

        this.node = document.createElement('div');
        this.node.className = 'target-zone-scroll';
        this.node.id = "scrolling-targets-portal-" + props.position;
        this.node.style = `
            position: fixed; 
            z-index: 10000;
            height: 10vh;
            width: 100vw;
            ${props.position === 'top' ? 'top: 0;' : 'bottom: 0;'}
        `;
		document.body.appendChild(this.node);
	}

	componentWillUnmount() {
        document.body.removeChild(this.node);
    } 

    render() {
        return createPortal(
			this.props.children,
			this.node
		);
    }
}

const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver()
    };
};

const sources = Object.keys(dndInterface);

@DropTarget(sources, {}, collect)
class ScrollTarget extends PureComponent {
	componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    componentDidUpdate(prevProps) {
        const didEnter = !prevProps.isOver && this.props.isOver;
        const didLeave = prevProps.isOver && !this.props.isOver;

        if (didEnter) {
            this.interval = setInterval(() => {
                if (this.props.top) {
                    window.scrollBy(0, -this.props.speed);
                } else {
                    window.scrollBy(0, this.props.speed);
                }
            }, 5);
        } else if (didLeave) {
            if (this.interval) clearInterval(this.interval);
        }
    }

    render() {
        return this.props.connectDropTarget(
            <div className="scroll-target" style={{ width: '100%', flexGrow: 1 }}></div>
        );
    }
}

export default class ScrollTargets extends PureComponent {
    render() {
        return (
            <React.Fragment>
                <Portal
                    position="top"
                >
                    <ScrollTarget top speed={8} />
                    <ScrollTarget top speed={4} />
                    <ScrollTarget top speed={2} />
                </Portal>

                <Portal
                    position="bottom"
                >
                    <ScrollTarget speed={2} />
                    <ScrollTarget speed={4} />
                    <ScrollTarget speed={8} />
                </Portal>
            </React.Fragment>
        );
    }
}