import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import Section from '../Section';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import { injectIntl } from 'react-intl';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import BackgroundVideoOptions from 'components/editor-v2/editor-interface/options/common/background-video';
// import StylingOverrides from 'components/editor-v2/editor-interface/options/common/styling-overrides';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        });
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.grid}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label
                    labelIcon="sn-arrow-resize3"
                    label={intl.formatMessage({ id: 'options.section horizontal alignment label' })}
                    // labelDescription="Something about alignment"
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-vertical_align_top"
                            rotated="counterclockwise"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'flex-start'}
                            // value={['center', 'flex-end'].indexOf(node.props.horizontalAlign) === -1}
                            onChange={() => onChange('horizontalAlign', node.props.horizontalAlign === 'flex-start' ? undefined : 'flex-start', 'grid horizontal alignment', false)}
                        />
                        <Toggle
                            icon="sn-vertical_align_center"
                            rotated="clockwise"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'center'}
                            onChange={() => onChange('horizontalAlign', node.props.horizontalAlign === 'center' ? undefined : 'center', 'grid horizontal alignment', false)}
                        />
                        <Toggle
                            icon="sn-vertical_align_bottom"
                            rotated="counterclockwise"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'flex-end'}
                            onChange={() => onChange('horizontalAlign', node.props.horizontalAlign === 'flex-end' ? undefined : 'flex-end', 'grid horizontal alignment', false)}
                        />
                    </ToggleGroup>
                </Label>

                <Label
                    label={intl.formatMessage({ id: 'options.section vertical alignment label' })}
                    labelIcon="sn-arrow-resize4"
                    // labelDescription="Something about alignment"
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-vertical_align_top"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment top' })}
                            tooltipPosition="top center"
                            value={node.props.verticalAlign === 'flex-start'}
                            // value={['center', 'flex-end'].indexOf(node.props.verticalAlign) === -1}
                            onChange={() => onChange('verticalAlign', node.props.verticalAlign === 'flex-start' ? undefined : 'flex-start', 'grid vertical alignment', false)}
                        />
                        <Toggle
                            icon="sn-vertical_align_center"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment middle' })}
                            tooltipPosition="top center"
                            value={node.props.verticalAlign === 'center'}
                            onChange={() => onChange('verticalAlign', node.props.verticalAlign === 'center' ? undefined : 'center', 'grid vertical alignment', false)}
                        />
                        <Toggle
                            icon="sn-vertical_align_bottom"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment bottom' })}
                            tooltipPosition="top center"
                            value={node.props.verticalAlign === 'flex-end'}
                            onChange={() => onChange('verticalAlign', node.props.verticalAlign === 'flex-end' ? undefined : 'flex-end', 'grid vertical alignment', false)}
                        />
                    </ToggleGroup>
                </Label>
                
                {/* <Label
                    label="Align Grid Items Horizontally"
                    labelIcon="sn-arrow-resize3"
                    labelDescription="Something about alignment"
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-paragraph-left3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                            tooltipPosition="top center"
                            value={['center', 'flex-end'].indexOf(node.props.horizontalAlign) === -1}
                            onChange={() => onChange('horizontalAlign', 'flex-start', 'grid horizontal alignment', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-center3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'center'}
                            onChange={() => onChange('horizontalAlign', 'center', 'grid horizontal alignment', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-right3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'flex-end'}
                            onChange={() => onChange('horizontalAlign', 'flex-end', 'grid horizontal alignment', false)}
                        />
                    </ToggleGroup>
                </Label>

                <Label
                    label="Align Grid Items Vertically"
                    labelIcon="sn-arrow-resize4"
                    labelDescription="Something about alignment"
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-paragraph-left3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                            tooltipPosition="top center"
                            value={['center', 'flex-end'].indexOf(node.props.verticalAlign) === -1}
                            onChange={() => onChange('verticalAlign', 'flex-start', 'grid vertical alignment', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-center3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.verticalAlign === 'center'}
                            onChange={() => onChange('verticalAlign', 'center', 'grid vertical alignment', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-right3"
                            tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.verticalAlign === 'flex-end'}
                            onChange={() => onChange('verticalAlign', 'flex-end', 'grid vertical alignment', false)}
                        />
                    </ToggleGroup>
                </Label> */}
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <BackgroundVideoOptions {...props} />
                <BackgroundOptions {...props} />

                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                />
            </Accordion>
        </React.Fragment>
    );
}));