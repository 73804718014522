exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".label__option-wrapper--1fiYB{width:100%;padding-top:6px;padding-bottom:8px;border-bottom:1px dashed #d8d4d0;margin-bottom:12px}.label__option-wrapper--1fiYB.label__collapsed--1SN3_{padding-top:4px;padding-bottom:6px;margin-bottom:0;border-bottom:none}.label__option-label--3xQN9{display:flex;line-height:1.2;align-items:center;margin-bottom:6px;color:#4f4741}.label__option-label--3xQN9 .label__option-icon--TvWe9{color:#5f4575;opacity:0.8;vertical-align:middle}\n", ""]);

// exports
exports.locals = {
	"option-wrapper": "label__option-wrapper--1fiYB",
	"collapsed": "label__collapsed--1SN3_",
	"option-label": "label__option-label--3xQN9",
	"option-icon": "label__option-icon--TvWe9"
};