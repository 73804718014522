import React from 'react';
// import TextOptions from 'components/editor-v2/editor-interface/options/constellations/text/TextOptions';
import { Editor, EditorState, RichUtils, Modifier, getDefaultKeyBinding, KeyBindingUtil, CompositeDecorator, convertToRaw, convertFromRaw, DefaultDraftBlockRenderMap } from 'draft-js';
import createDebounce from 'utils/debounce';
// import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import Portal from 'components/editor-v2/editor-interface/Portal';
import EditorOptions from 'components/editor-v2/editor-interface/options';

const debounce = createDebounce(1000);

const createSetEditorState = (textInstance, commitData) => (editorState) => {
    debounce(() => commitData(editorState));

    /* 
        Don't try this at home, kids

        Refactor at some point (probably never, but in an ideal world...)
    */

    textInstance.setState({
        editorState
    });
}

// const suspendDictionary = () => {

// }

const createCommitData = textInstance => editorState => {
    const { node, updateState } = textInstance.props;

    const content = editorState.getCurrentContent();

    const rawRepresentation = convertToRaw(content);
    const oldRawRepresentation = node.props.text;

    const shouldSkip = JSON.stringify(rawRepresentation) === JSON.stringify(oldRawRepresentation);

    // No need to commit if nothing changed (can be triggered by a text selection for example)
    if (shouldSkip) return;

    const newNode = {
        ...node,
        props: {
            ...node.props,
            text: rawRepresentation
        }
    };
    
    updateState(context => {
        return {
            ...context,
            nodeMap: {
                ...context.nodeMap, 
                [newNode.id]: newNode
            }
        };
    }, 'text updated');
}

const getEditorProps = textInstance => {
    const { props } = textInstance;

    return {
        onChange: createSetEditorState(textInstance, createCommitData(textInstance)),
        readOnly: !props.isEditable
    };
}

export default rendererMode => {
    return {
        hocs: [],
        onLinkClick: () => console.log("link onClick handler admin extension"),
        getLinkDescription: () => 'link description',
        getEditorProps,
        // Have to render the side options on this level rather than at Renderer Root because we need editorState to be available in the side bar
        renderTextOptions: ({ editorState, setEditorState, updateState, isEditable, ContextConsumer }) => {
            if (!isEditable) return null;

            return (
                <EditorOptions 
                    ContextConsumer={ContextConsumer}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    updateState={updateState}
                />
            );
        },
    };
};