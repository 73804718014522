import WebFont from 'webfontloader';

// Simply keeps track of which fonts are loaded so we can prevent reloading ones we have
const loadedFonts = {};

const loadFont = (family) => {
	if (loadedFonts[family]) return;

	try {
		WebFont.load({
			google: {
				families: [family + ':100,200,300,400,500,600,700,800,900']
			},
			loading: () => {
				loadedFonts[family] = 'loading';
			},
			active: () => {
				loadedFonts[family] = 'loaded';
			},
			inactive: () => {
				delete loadedFonts[family];
			}
		});
	} catch (e) {
		delete loadedFonts[family];
	}
};

export { loadFont };
export default loadedFonts;