import React from 'react';

const Message = props => {
    const size = props.size || 14;

    return (
        <div
            // fallback message classnames added for us/overrides in Admin
            className={props.className ? `message ${className}` : 'message'}
            style={{
                position: 'relative',
                fontSize: size,
                padding: `${size/2}px ${size}px`,
                color: props.tintColor || 'white',
                backgroundColor: props.color || 'darkgrey',
                border: `1px solid ${props.tintColor || 'white'}`,
                boxShadow: props.shadeColor || '0 0 3px 0 darkgrey',
                borderRadius: props.borderRadius || 5,
                ...props.style
            }}
        >
            <div
                className="message-content"
                style={{
                    fontFamily: props.secondaryFont ? props.secondaryFont : 'Ubuntu, sans serif',
                    fontWeight: 300
                }}
            >
                {props.children || 'No content in this message...'}
            </div>
        </div>
    );
}
 
export default Message;
