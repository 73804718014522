import React, { Component } from 'react';
import FAQPanel from './FAQPanel';
import {Accordion, Icon, Header} from 'svift-ui'
//import { Sidebar, Segment } from 'semantic-ui-react'
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class FAQSection extends Component{
    constructor(props, context){
        super(props, context);
        //console.log(this.props);
    }

   render(){
        const { accordion, keyword, togglePanel, openPanels, style } = this.props;  
        const panels = keyword
            ? accordion.panels.filter(panel => {
                return [panel.question.toLowerCase(), panel.meta.toLowerCase()].some(text => text.includes(keyword));
            }) 
            : accordion.panels;
        
        return( 
            <div style={{ marginBottom: 24, textAlign: 'left' }}>
                <div style={{ fontSize: 22, marginBottom: 8 }}>
                    <Icon name={accordion.icon} style={{ marginRight: 8, opacity: 0.4 }} />
                    {accordion.title}
                </div>

                { panels.map((panel)=>
                    <FAQPanel 
                        key={panel.id} 
                        panel={panel}
                        togglePanel={togglePanel}
                        openPanels={openPanels}
                    />
                )}
            </div>                  
       );
   } 
}

export default injectIntl(FAQSection);
