import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/RightInfobox';

export default {
	name: 'templates.template right infobox',
	metaDescription: 'two (2) columns with text + right column info + headlines',
	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-infobox-template.png',
	type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
};
