import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/ContactInfo';

export default {
	name: 'templates.template contact info',
	metaDescription: 'contact + columns + map + info + address',
	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/contact-info-template.png',
	type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
};

