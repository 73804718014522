import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
import Slider from 'components/option-panel/options/Slider';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Dropdown from 'components/option-panel/options/Dropdown';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import ButtonOption from 'components/option-panel/options/Button';
import {
	Grid,
	GridColumn,
	Input as ImportedInput,
	RadiumInput,
	Menu,
	Popup,
	Icon
} from 'svift-ui';
import PresetContainer from '../../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import { googleFontParser } from 'utils/fonts/googleFontParser';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import { tooltip } from 'components/tooltip/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';

const getValueOrDefault = (inputPreset, preset, key) => {
	if (typeof inputPreset[key] === 'undefined') {
		return preset.default[key];
	}

	return inputPreset[key];
};

class Input extends PureComponent {
	constructor() {
		super();

		this.onChange = this.onChange.bind(this);

		this.getPresetOptionsLabel = (props) => {
			const { intl } = props;

			const computedStyles = props.labelStyle;

			const presetValues = props.errorMode ? {
				...props.preset.label,
				...props.preset.error.label
			} : props.preset.label;

			const color = presetValues.color;
			const font = presetValues.font;
			const fontSize = presetValues.fontSize;
			const lineHeight = presetValues.lineHeight;
			const letterSpacing = presetValues.letterSpacing;
			const noLetterSpacing = typeof letterSpacing === 'undefined';

			const options = [
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="sn-typography"
						options={Object.keys(props.theme.fonts).map(fontKey => {
							return {
								text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => {
							this.onChange('font', newFont.value, { isLabel: true });
						}}
						value={font}

						hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${props.theme.fonts[font].family})`}

						option="font-type-text"
						selectedOption={props.selectedPresetOption}
						onToggle={(select) => {
							props.selectPresetOption(select);
							//e.stopPropagation();
						}}
					/>
				</Menu>,
				<ColorPicker
					title={intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker"
					icons={['sn-typography']}
					colors={props.theme.colors}
					onChange={(newColor) => {
						this.onChange('color', newColor /*{ key: newColor }*/, { isLabel: true });
					}}
					value={color}

					option="color-picker-text"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="font-size"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
					onChange={(newValue) => {
						this.onChange('fontSize', newValue / 100, { isLabel: true });
					}}
					value={fontSize * 100}
					min={25}
					max={350}

					option="fontSize-text"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="line-height"
					icons={['sn-line_weight', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${computedStyles.lineHeight})`}
					onChange={(newValue) => {
						this.onChange('lineHeight', newValue / 100, { isLabel: true });
					}}
					value={lineHeight * 100}
					min={50}
					max={300}

					option="options.lineheight title-text"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="letter-spacing"
					icons={['sn-typography', 'sn-arrow-resize3']}
					title={`${intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
					onChange={(newValue) => {
						this.onChange('letterSpacing', newValue, { isLabel: true });
					}}
					value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
					min={0}
					max={50}
					optIn
					onClear={noLetterSpacing ? () => null : () => {
						this.onChange('letterSpacing', null, { isLabel: true });
					}}

					option="letterSpacing-text"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={presetValues.margin}
					preset={presetValues}
					theme={props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginBottom: 1
								}, { isLabel: true });
							} else {
								this.onChange('margin', {
									generalMargin: newValue / 100
								}, { isLabel: true });
							}

							return;
						}

						const newMargin = {
							...this.props.preset.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') {
							newMargin.marginBottom = 1;
						}

						this.onChange('margin', newMargin, { isLabel: true });
					}}

					option="Margin-text"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>
			];

			return options;
		};

		this.getPresetOptionsInput = (props) => {
			const inputPreset = this.props.errorMode ? this.props.preset.error.input : this.props.preset[this.props.inputState];
			const extendsDefault = this.props.inputState !== 'default';

			const preset = this.props.preset;

			/*
				Is object destructuring possible here? not sure, revisit
			*/
			const boxShadow = getValueOrDefault(inputPreset, preset, 'boxShadow');
			const backgroundColor = getValueOrDefault(inputPreset, preset, 'backgroundColor');
			const color = getValueOrDefault(inputPreset, preset, 'color');
			const font = getValueOrDefault(inputPreset, preset, 'font');
			const border = getValueOrDefault(inputPreset, preset, 'border');
			const borderRadius = getValueOrDefault(inputPreset, preset, 'borderRadius');
			const margin = getValueOrDefault(inputPreset, preset, 'margin');
			const padding = getValueOrDefault(inputPreset, preset, 'padding');
			const letterSpacing = getValueOrDefault(inputPreset, preset, 'letterSpacing');
			const fontSize = getValueOrDefault(inputPreset, preset, 'fontSize');
			const lineHeight = getValueOrDefault(inputPreset, preset, 'lineHeight');

			const noLetterSpacing = typeof letterSpacing === 'undefined';

			const options = [
				<span style={{ marginRight: 16, opacity: 0.6 }}>
					<Icon name="sn-pencil7" />
					<FormattedMessage id={createTranslationFromKey(props.presetTitle)} />
				</span>,
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="font"
						options={Object.keys(props.theme.fonts).map(fontKey => {
							return {
								text: `${this.props.intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => this.onChange('font', newFont.value)}
						value={font || {}}
						hoverContent={`${this.props.intl.formatMessage({ id: `options.font ${font} title` })} (${props.theme.fonts[font].family})`}
						option="color-picker"
						selectedOption={this.props.selectedPresetOption}
						onToggle={(select, e) => {
							this.props.selectPresetOption(select);

							if (e) {
								e.stopPropagation();
							}
						}}
					/>
				</Menu>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.background color title' })}
					key="color-picker-background"
					icons={['sn-paint-format']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('backgroundColor', newColor)}
					value={backgroundColor}

					option="color-picker-background"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="font-size-input"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${this.props.intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${fontSize})`}
					onChange={(newValue) => {
						this.onChange('fontSize', newValue / 100);
					}}
					value={fontSize * 100}
					min={25}
					max={350}

					option="fontSize"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="line-height-input"
					icons={['sn-line_weight', 'sn-arrow-resize4']}
					title={`${this.props.intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${lineHeight})`}
					onChange={(newValue) => {
						this.onChange('lineHeight', newValue / 100);
					}}
					value={lineHeight * 100}
					min={50}
					max={300}

					option="options.lineheight title"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="letter-spacing-input"
					icons={['sn-typography', 'sn-arrow-resize3']}
					title={`${this.props.intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
					onChange={(newValue) => {
						this.onChange('letterSpacing', newValue);
					}}
					value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
					min={0}
					max={50}
					optIn
					onClear={noLetterSpacing ? () => null : () => {
						this.onChange('letterSpacing', null);
					}}

					option="letterSpacing"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Border
					key="border-style"
					value={border}
					onChange={newBorder => this.onChange('border', newBorder)}
					colors={this.props.theme.colors}

					option="border-style"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<BorderRadius
					max={240}
					key="border-radius"
					value={borderRadius}
					onChange={(key, newValue) => {
						if (key === 'generalBorderRadius') {
							if (newValue === null) {
								this.onChange('borderRadius', {});
							} else {
								this.onChange('borderRadius', {
									generalBorderRadius: newValue
								});
							}

							return;
						}

						const newBorderRadius = {
							...borderRadius,
							[key]: newValue
						};

						if (newValue === null) {
							delete newBorderRadius[key];
						}

						delete newBorderRadius.generalBorderRadius;

						this.onChange('borderRadius', newBorderRadius);
					}}
					colors={this.props.theme.colors}

					option="border-radius"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={inputPreset}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						const value = inputPreset.margin;

						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginBottom: 1
								});

								return;
							}

							this.onChange('margin', {
								generalMargin: newValue / 100
							});

							return;
						}

						const newMargin = {
							...value,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') newMargin.marginBottom = 1;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>,
				<Padding
					key="padding-input"
					value={padding}
					preset={inputPreset}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalPadding') {
							if (newValue === null) {
								this.onChange('padding', {
									paddingBottom: 1
								});
							} else {
								this.onChange('padding', {
									generalPadding: newValue / 100
								});
							}

							return;
						}

						const newPadding = {
							...padding,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newPadding[key];
						}

						delete newPadding.generalPadding;

						if (typeof newPadding.paddingBottom === 'undefined') {
							newPadding.paddingBottom = 1;
						}

						this.onChange('padding', newPadding);
					}}

					option="Padding"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					optIn={{
						paddingBottom: false,
						paddingTop: true,
						paddingLeft: true,
						paddingRight: true,
						generalPadding: true
					}}
				/>,
				<Shadow
					key="shadow"
					value={boxShadow}
					colors={this.props.theme.colors}
					onChange={(newShadow) => {
						this.onChange('boxShadow', newShadow);
					}}

					option="Shadow"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>
			];

			if (extendsDefault) {
				options.push(
					<Icon
						style={{ fontSize: 16, marginLeft: 16, marginTop: 4 }}
						name="sn-cross"
						key="clear"
						link
						className="primary"
						disabled={Object.keys(inputPreset).length === 0}
						onClick={() => this.props.updatePresetOption(this.props.presetTitle, this.props.inputState, {})}
					/>
				);
			}

			return options;
		};

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	// Because of its internal data structure (nested a layer deeper to accomodate 4 input states), inputs have to take some extra steps to align with update logic
	onChange(property, newValue, { isLabel = false } = {}) {
		const inputState = isLabel ? 'label' : this.props.inputState;

		let value;

		/*
			Error mode is nested a level deeper, so it needs a custom case:
		*/
		if (this.props.errorMode) {
			const errorType = isLabel ? 'label' : 'input';

			value = {
				...this.props.preset.error,
				[errorType]: {
					...this.props.preset.error[errorType],
					[property]: newValue
				}
			};

			if (newValue === null) {
				delete value.error[errorType][property];
			}

			this.props.updatePresetOption(this.props.presetTitle, 'error', value);

			return;
		}

		value = {
			...this.props.preset[inputState],
			[property]: newValue
		};

		if (newValue === null) {
			delete value[property];
		}

		this.props.updatePresetOption(this.props.presetTitle, inputState, value);
	}

	getIdentifier() {
		return `${this.props.inputState}${this.props.presetTitle}`; // Need a specific identifier because the selectable inputs are nested a layer deeper
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.getIdentifier() === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const selected = this.getIdentifier() === this.props.selectedPreset;

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.input title"
					open={selected}
					target={this.optionTooltipRef.current}
				>
					<span className="editor-bar-title"><Icon name="sn-flag6" style={{ opacity: 0.4 }} /><FormattedMessage id="forms.label title" /> <InfoIcon description={this.props.intl.formatMessage({ id: 'forms.input label description' })} /></span>
					{this.getPresetOptionsLabel(this.props)}

					<div className="nested-panel">
						{this.getPresetOptionsInput(this.props)}
					</div>
				</OptionPanel>

				<PresetContainer
					selected={selected}
					disabled={this.props.liveMode}
					onClick={() => setTimeout(() => this.props.selectPreset(this.getIdentifier()), 0)}
					ref={this.optionTooltipRef}
					style={{ textAlign: 'left' }}
				>
					<p
						style={this.props.labelStyle}
					>
						<FormattedMessage id="elements.input label dummy title" /> <br />

						{this.props.errorMode && `*${this.props.intl.formatMessage({ id: 'elements.validation label dummy title' })}`}
					</p>

					<RadiumInput
						style={{
							...this.props.style,
							width: '100%'
						}}
					/>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

const EnhancedInput = injectIntl(onClickOutside(Input));

class InputPresets extends PureComponent {
	render() {
		const { intl } = this.props;
		const computedStyles = computePreset(this.props.preset, this.props.theme);
		const labelStyle = computePreset(this.props.preset.label, this.props.theme);

		computedStyles.outline = 'none';

		if (this.props.errorMode) {
			const errorStyle = {
				...computedStyles,
				...computePreset(this.props.preset.error.input, this.props.theme)
			};

			const errorLabelStyle = {
				...labelStyle,
				...computePreset(this.props.preset.error.label, this.props.theme)
			};

			return (
				<div style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 53, marginBottom: 24, textAlign: 'left' }}>
					<EnhancedInput
						errorMode
						liveMode={this.props.liveMode}
						theme={this.props.theme}
						selectPresetOption={this.props.selectPresetOption}
						updatePresetOption={this.props.updatePresetOption}
						selectedPresetOption={this.props.selectedPresetOption}
						preset={this.props.preset}
						presetTitle={this.props.presetTitle}
						selectPreset={this.props.selectPreset}
						selectedPreset={this.props.selectedPreset}
						style={errorStyle}
						labelStyle={errorLabelStyle}
					/>
				</div>
			);
		}

		if (this.props.liveMode) {
			computedStyles.width = '100%';

			return (
				<div style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 53, marginBottom: 24, width: '100%', textAlign: 'left' }}>
					<p
						style={labelStyle}
					>
						<FormattedMessage id="elements.input label dummy title" />
					</p>

					<RadiumInput textAlign="center" style={computedStyles} />
				</div>
			);
		}

		return (
			<Grid columns={4} style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 0, marginBottom: 16 }}>
				{['Default', 'Hover', 'Focus', 'Disabled'].map(inputState => {
					switch (inputState) {
						case 'Default':
							return {
								presetId: 'default',
								presetTitle: 'elements.input state default',
								presetDescription: 'elements.input state default description',
								inputState
							};
						case 'Hover':
							return {
								presetId: 'hover',
								presetTitle: 'elements.input state hover',
								presetDescription: 'elements.input state hover description',
								inputState
							};
						case 'Focus':
							return {
								presetId: 'focus',
								presetTitle: 'elements.input state focus',
								presetDescription: 'elements.input state focus description',
								inputState
							};
						case 'Disabled':
							return {
								presetId: 'disabled',
								presetTitle: 'elements.input state disabled',
								presetDescription: 'elements.input state disabled description',
								inputState
							};
					}
				}).map(({ inputState, presetId, presetTitle, presetDescription }) => {
					let styles = {
						...computedStyles,
					};

					delete styles[':hover'];
					delete styles[':focus'];
					delete styles[':disabled'];

					styles = {
						...styles,
						...computedStyles[':' + presetId]
					};

					return (
						<GridColumn key={inputState} style={{ paddingTop: 0, paddingBottom: 0 }}>
							<p className="preset-title"><FormattedMessage id={presetTitle} /><InfoIcon description={intl.formatMessage({ id: presetDescription })} /></p>

							<EnhancedInput
								liveMode={this.props.liveMode}
								theme={this.props.theme}
								selectPresetOption={this.props.selectPresetOption}
								updatePresetOption={this.props.updatePresetOption}
								selectedPresetOption={this.props.selectedPresetOption}
								preset={this.props.preset}
								presetTitle={this.props.presetTitle}
								selectPreset={this.props.selectPreset}
								selectedPreset={this.props.selectedPreset}
								inputState={presetId}
								style={styles}
								labelStyle={labelStyle}
							/>
						</GridColumn>
					);
				})}
			</Grid>
		);
	}
}

InputPresets.propTypes = {
	component: PropTypes.string,
	liveMode: PropTypes.bool,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string,
	updatePresetOption: PropTypes.func
};

export default injectIntl(InputPresets);