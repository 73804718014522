import React from 'react';
import { FormattedMessage } from 'react-intl';

const translateElement = key => {
    let elementKey = `countries.`+ key;

    return (
        <FormattedMessage id={elementKey} />
    );
};

const resolveLanguageName = countryCode => {
    const lowercaseCode = countryCode.toLowerCase();

    switch (lowercaseCode) {
        case 'da': {
            return translateElement('danish');
        }
        case 'en': {
            return translateElement('english');
        }
        case 'de': {
            return translateElement('german');
        }
        case 'fr': {
            return translateElement('french');
        }
        case 'es': {
            return translateElement('spanish');
        }
        case 'no': {
            return translateElement('norwegian');
        }
        case 'se': {
            return translateElement('swedish');
        }
        case 'nl': {
            return translateElement('dutch');
        }
        case 'is': {
            return translateElement('icelandic');
        }
        case 'fi': {
            return translateElement('finnish');
        }
        case 'it': {
            return translateElement('italian');
        }
        case 'pt': {
            return translateElement('portuguese');
        }
        case 'gl': {
            return translateElement('greenlandish');
        }
        case 'fo': {
            return translateElement('faroese');
        }
        case 'us': {
            return translateElement('american');
        }
        case 'jp': {
            return translateElement('japanese');
        }
        case 'th': {
            return translateElement('thai');
        }
        
        // no match fallback
        default: {
            return null;
        }
    }
};

export default resolveLanguageName;