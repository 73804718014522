import Grid from './GridOptions';
import Row from './RowOptions';
import Column from './ColumnOptions';
import Text from './text/TextOptions';
import Image from './ImageOptions';
import Button from './ButtonOptions';
import Video from './VideoOptions';
import Map from './MapOptions';
import Form from './FormOptions';
import Feed from './feed/FeedOptions';
import Body from './BodyOptions';
import IFrame from './IFrameOptions';
import Container from './ContainerOptions';
import LayoutContainer from './LayoutContainerOptions';
import Menu from './MenuOptions';
import SmartText from './SmartTextOptions';
import FormCheckbox from './form-options/CheckboxOptions';
import FormInput from './form-options/InputOptions';
import FormRadio from './form-options/RadioOptions';
import Logo from './LogoOptions';
import Divider from './DividerOptions';
import Follow from './some/FollowOptions';
import Share from './some/ShareOptions';
import Icon from './IconOptions';
import Gallery from './GalleryOptions';

export default {
    FormCheckbox,
    FormTextArea: FormInput,
    FormInput,
    FormRadio,
    Logo,
    SmartText,
    Menu,
    Body,
    Container,
    LayoutContainer,
    Grid,
    Row,
    Column,
    Text, 
    Image,
    Button,
    Video,
    Map,
    Form,
    Feed,
    IFrame,
    Divider,
    Follow,
    Share,
    Icon,
    Gallery
};