import React, { PureComponent } from 'react';
import BackButton from 'containers/dashboard/side-navigation/sidenavigation-content/__components/BackButton';
import { Icon, Message } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { themesSelector } from 'store/selectors/sites/themes';
import notification from 'components/hoc/notification';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themes = themesSelector(state, siteid);
	const layoutid = state.sites.layout.params.layoutid;

	const layout = state.sites.layout.layouts[layoutid];

	return {
		isBlog: layout && layout.type === 'blog',
		layoutid,
		siteid,
		defaultTheme: themes[state.sites.sites[siteid].activeTheme]._id,
		stagedLayout: state.sites.layout.stagedLayout
	};
};

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		// updateLayoutContent: (layoutID, tree) => dispatch(actions.updateLayoutContent(layoutID, tree)),
// 		// createLayout: (siteID, newLayout) => dispatch(actions.createLayout(siteID, newLayout)),
// 	};
// };

class SidenavigationLayouts extends PureComponent {
	constructor() {
		super();

		// this.state = {
		// 	layoutModalOpen: false
		// };

		// this.createNewLayout = this.createNewLayout.bind(this);
	}

	// async createNewLayout(values) {
	// 	this.setState({
	// 		loading: true
	// 	});

	// 	const siteid = this.props.siteid;

	// 	try {
	// 		await this.props.createLayout(siteid, values);

	// 		SviftGA.LAYOUTS.CREATED();

	// 		this.setState({
	// 			loading: false,
	// 			layoutModalOpen: false
	// 		});
	// 		this.props.createNotification({
    //             content: this.props.intl.formatMessage({ id: 'layouts.layout update notificaiton' }),
    //             className: 'positive',
    //             position: 'topCenter',
    //             icon: 'user',
    //             deleteTime: 2000
    //         });
	// 	} catch (e) {
	// 		this.setState({
	// 			loading: false
	// 		});

	// 		handleSubmitError(this.props.intl)(e);
	// 	}
	// }

	renderBackButton() {
		const { siteid, defaultTheme, intl } = this.props;

		return (
			<BackButton
				text={intl.formatMessage({ id: 'design.back to design' })}
				route={`/dashboard/${siteid}/design/${defaultTheme}`}
			/>
		);
	}

	render() {
		return (
            <React.Fragment>
                {this.renderBackButton()}
                
                {/* <Button
                    // id="layouts-new-layout-button"
                    icon
                    positive
                    fluid
                    size="small"
                    onClick={() => this.setState({ layoutModalOpen: true })}
                    style={{ marginBottom: 24 }}
                >
                    <Icon name="plus" />
                    <FormattedMessage id="layouts.create new layout" />
                </Button> */}
        
                <PortalHook id="editor-sidenav-portal" />
        
                <PortalHook id="building-blocks-sidenav-portal" />
                
                {/* <div> */}
                    {/* <div id="sidenav-header-section">
                        <Header
                            icon="sn-cog3"
                            header={
                                <span className="active-sidebar-header">
                                    <FormattedMessage id="settings.app title" />
                                </span>
                            }
                        />
                    </div> */}

             {/* <LayoutModal  what is this?
                loading={this.state.loading} 
                onClose={() => this.setState({ layoutModalOpen: false })} 
                onSubmit={this.createNewLayout} 
            />  */}
             
        
                    {/* {this.state.layoutModalOpen &&
                        <Modal
                            title={this.props.intl.formatMessage({ id: 'layouts.create layout' })}
                            width={1400}
                            onClick={() => this.setState({ layoutModalOpen: false })}
                            nodeAttributes={{
                                'data-ignore-click': true
                            }}
                        >
                            layout form kommer 
                             <LayoutForm
                                keywords={this.props.keywords}
                                onClick={() => this.setState({ layoutModalOpen: false })}
                                onSubmit={this.createPage}
                            /> 
                        </Modal>
                    } */}
                {/* </div> */}
            </React.Fragment>
		);
	}
}

export default connect(mapStateToProps, null /*mapDispatchToProps*/)(injectIntl(notification(SidenavigationLayouts)));


