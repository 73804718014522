import React, { memo, useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { Icon } from 'svift-ui';
import Tooltip, { Popup } from 'svift-library/client/tooltip/Tooltip';
import SelectableOptions from './SelectableOptionPanel';
import SelectableWrapper from './SelectableWrapper';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';
import { removeEmptyParents, removeChildrenRecursively } from '../updateUtils';
import { useDeleteKeybinding, useEnterKeybinding, useLeftArrowKeybinding, useRightArrowKeybinding } from '../keybindings/Keybindings';
import cantBeDeleted from './cantBeDeleted';
import { FormattedMessage, injectIntl } from 'react-intl';

/* 
    New idea:
        Instead of "digging down" by right/left arrow keys, maybe we should "stay" on the same level

        e.g.: If column is selected, right key would get the next column (even if they don't share parent)

        In addition to that, up and down key could just select the immediate parent

        This way should be way more intuitive
*/
const getSelection = (direction, nodeMap, nodeID, path, recursing = false) => {
    const currentNode = nodeMap[nodeID];

    if (!recursing) {
        const firstChild = currentNode.children && currentNode.children[0];
    
        if (firstChild) return firstChild;
    }
    
    const parentNodeInstance = path[path.length - 2];

    if (!parentNodeInstance) return null; // Escape case
    
    const parentNodeID = parentNodeInstance.props.nodeID;
    const parentNode = nodeMap[parentNodeID];

    const indexInParent = parentNode.children.findIndex(childID => childID === nodeID);

    const nextSelection = direction === 'left' ? parentNode.children[indexInParent - 1] : parentNode.children[indexInParent + 1];

    if (nextSelection) return nextSelection;

    return getSelection(direction, nodeMap, parentNodeID, path.slice(0, -1), true);
};

// const searchBranch = node => {
    
// }

// const getSelection = (direction, nodeMap, nodeID, path, depth = null) => {
//     const currentNode = nodeMap[currentNode];

//     if (depth === null) depth = path.length;

// }

// const getSelection = (direction, nodeMap, nodeID, path, recursing = false) => {
//     // const currentNode = nodeMap[nodeID];
    
//     const parentNodeInstance = path[path.length - 2];

//     if (!parentNodeInstance) return null;
    
//     const parentNodeID = parentNodeInstance.props.nodeID;
//     const parentNode = nodeMap[parentNodeID];

//     const indexInParent = parentNode.children.findIndex(childID => childID === nodeID);

//     const nextSelection = direction === 'left' ? parentNode.children[indexInParent - 1] : parentNode.children[indexInParent + 1];

//     if (nextSelection) return nextSelection;

//     return getSelection(direction, nodeMap, parentNodeID, path.slice(0, -1), true);

//     // return getSelection(direction, nodeMap, parentNodeID, path.slice(0, -1), true);
// };

const handleArrowKeySelection = (direction = 'left', nodeID, getPath, updateState) => {
    updateState(context => {
        const nextSelectedNode = getSelection(direction, context.nodeMap, nodeID, getPath());

        if (!nextSelectedNode) return;

        return {
            ...context,
            selectedNode: nextSelectedNode
        };
    });
};

/*
	onDragStart(e) {
		if (e.target === findDOMNode(this)) {
			window.CURRENT_DRAGGED_NODE = this.props.node.id;
		}
	}

	componentDidMount() {
		if (!this.props.draggingBlocked) {
			this.props.setDragSourceRef(this.node);
			this.props.setDragPreviewRef(this.node);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.draggingBlocked !== prevProps.draggingBlocked) {
			if (this.props.draggingBlocked) {
				this.props.setDragSourceRef(null);
			} else {
				this.props.setDragSourceRef(this.node);
				this.props.setDragPreviewRef(this.node);
			}
		}
	}
*/

export default (Component) => {
    const Selectable = memo(props => {
        const [draggable, setDraggable] = useState(true);
        const [revertToDraggableQueue, setRevertToDraggableQueue] = useState([]);
        const nodeRef = useRef(null);

        useEffect(() => {
            if (props.connectDragSource) {
                if (!window.__UNDRAGGABLE_DICTIONARY) window.__UNDRAGGABLE_DICTIONARY = {};

                if (!draggable) {
                    window.__UNDRAGGABLE_DICTIONARY[props.node.id] = true;
                    // console.log(props.node.component, 'X MADE UNDRAGGABLE');
                    // props.connectDragSource(null);
                } else {
                    window.__UNDRAGGABLE_DICTIONARY[props.node.id] = false;
                    // console.log(props.node.component, 'X MADE DRAGGABLE');
                    // props.connectDragSource(nodeRef.current);
                    // props.setDragPreviewRef(nodeRef.current);
                }
            }
        }, [draggable]);

        const preventDraggable = useCallback(() => {
            setDraggable(false);

            return () => setDraggable(true);
        }, []);

        const freezeDragPath = useCallback((path = []) => {
            path.unshift(preventDraggable());
            
            if (!props.freezeDragPath) return path;

            return props.freezeDragPath(path);
        }, []);

        if (props.currentNode.component === 'Text') {
            useEffect(() => {    
                if (props.isEditable) {
                    /*
                        Non-chrome browsers have trouble with selection in text inside HTML DND API, so we have to disable draggable on any parents of selected text nodes
                    */
                    setRevertToDraggableQueue(freezeDragPath());
                } else {
                    revertToDraggableQueue.forEach(revert => revert());

                    setRevertToDraggableQueue([]);
                }
            }, [props.isEditable]);
        }
        
        const onMouseMove = useCallback(e => {
            e.stopPropagation();

            if (e.target.attributes['data-ignore-click']) {
                return;
            }

            if ((e.currentTarget === nodeRef.current) && !props.isHovered) {
                props.updateState(context => {
                    return {
                        ...context,
                        hoveredNode: props.nodeID
                    }
                }, 'skip');
            }
        }, [props.isHovered]);

        const onClick = useCallback(e => {
            e.stopPropagation();

            /* 
                Workaround for react synthetic events and how they interact with portals
            */
            let ignoreClick = false;
            let nextElement = e.target;

            while (nextElement && nextElement.attributes) {
                if (nextElement.attributes['data-ignore-click']) {
                    ignoreClick = true;
                    nextElement = null;
                } else {
                    nextElement = nextElement.parentNode;
                }
            }

            if (ignoreClick) return;

            if (e.currentTarget === nodeRef.current) {
                props.updateState(context => {
                    const updates = {};

                    const nodeIsSelected = context.selectedNode === props.nodeID;
                    const nodeIsEditable = context.editableNode === props.nodeID;

                    if (!nodeIsSelected && !nodeIsEditable) {
                        updates.selectedNode = props.nodeID;
                        updates.editableNode = null;
                    } else if (nodeIsSelected) {
                        updates.selectedNode = null;
                        updates.editableNode = props.nodeID;
                    } else {
                        return null;
                    }

                    return {
                        ...context,
                        ...updates
                    };
                }, 'skip');
            }
        }, []);

        /*
			this.preventDraggable = () => {
				this.setState({
					draggingBlocked: true
				});

				return () => {
					this.setState({
						draggingBlocked: false
					});
				};
			}

			this.select = e => {
				if (e) {
					e.stopPropagation();
					// e.nativeEvent.stopImmediatePropagation();
				}
				
				const editState = getNextEditState(this.state.editState);
				
				if (editState !== this.state.editState) {		
					// document.activeElement.blur();

					// https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates 
					unstable_batchedUpdates(() => {
						this.registerSelect();
						
						/*
							Non-chrome browsers have trouble with selection in text inside HTML DND API, so we have to disable draggable on any parents of selected text nodes
						
						const isTextNode = this.props.node.component === 'Text';
						
						if (isTextNode && editState === 'editable') {
							this.queuedReverts = [];

							this.props.path.forEach(node => {
								if (node.preventDraggable) {
									const revertToDraggable = node.preventDraggable();
									
									this.queuedReverts.push(revertToDraggable);
								}
							});
							
							this.queuedReverts.push(this.preventDraggable());
						}
		
						this.setState({
							editState
						});
					});
				}
			};
        */

        const onMouseLeave = useCallback(() => {
            if (props.nodeID === 'root') {
                props.updateState(context => ({
                    ...context,
                    hoveredNode: null
                }), 'skip');
            }
        }, []);

        const select = useCallback((e, nodeID) => {
            props.updateState(context => ({
                ...context,
                selectedNode: nodeID,
                editableNode: null
            }), 'skip');
        }, []);

        const edit = useCallback(e => {
            props.updateState(context => ({
                ...context,
                selectedNode: null,
                editableNode: props.nodeID
            }), 'skip');
        }, [props.nodeID]);

        const remove = useCallback((e, isKeyPress) => {
            props.updateState(context => {
                if (isKeyPress && context.selectedNode !== props.nodeID) return;

                if (cantBeDeleted(context.nodeMap, props.nodeID)) {
                    alert(props.intl.formatMessage({ id: 'general.cannot be deleted alert' }));

                    return;
                }
                
                return {
                    ...context,
                    nodeMap: (() => {
                        const newNodeMap = {
                            ...context.nodeMap
                        };
                                        
                        // Remove up the tree if containers would be empty after deleting
                        removeEmptyParents(newNodeMap, props.getPath().map(node => node.props.nodeID));
    
                        // Remove down the tree
                        removeChildrenRecursively(newNodeMap, props.nodeID);
    
                        return newNodeMap;
                    })(),
                    selectedNode: null,
                    editableNode: null
                };
            }, 'delete element');
        }, [props.nodeID]);

        useDeleteKeybinding(props.isSelected ? remove : null);

        useEnterKeybinding(props.isSelected 
            ? 
                () => {
                    props.updateState(context => {    
                        return {
                            ...context,
                            selectedNode: null,
                            editableNode: props.nodeID
                        }
                    }, 'skip');
                } 
            : 
                null
        );

        useLeftArrowKeybinding(props.isSelected ? () => handleArrowKeySelection('left', props.nodeID, props.getPath, props.updateState) : null);
        useRightArrowKeybinding(props.isSelected ? () => handleArrowKeySelection('right', props.nodeID, props.getPath, props.updateState) : null);
        
        const clear = useCallback(e => {
            props.updateState(context => ({
                ...context,
                selectedNode: null,
                editableNode: null
            }), 'skip');
        }, []);

        const selectableTargetProps = useMemo(() => ({
            onMouseMove,
            onClick,
            onMouseLeave,
            ref: nodeRef
        }), [nodeRef, onMouseMove]);

        // Have to extract margin and apply it to the selectable wrapper 
        // (s wrapper is a div surrounding the element - setting margin on the outermost box/div of the boxes/divs that make up an element is required to make it behave correctly)
        const { marginLeft, marginBottom, marginRight, marginTop, ...innerStyles } = props.computedStyling || {};

        return (
            <React.Fragment>
                <Tooltip 
                    position="top left"
                    id={props.nodeID}
                    open={props.isSelected}
                    target={nodeRef.current}
                    zIndex={1000}
                >
                    <SelectableOptions
                        connectDragSourceHandle={props.connectDragSourceHandle}
                        component={props.node && props.node.component}
                        type={props.node.props && props.node.props.type}
                        getPath={props.getPath}
                        select={select}
                        edit={edit}
                        clear={clear}
                        remove={remove}
                        // lock={lock}
                        // copy={copy}
                        // paste={paste}
                    />
                </Tooltip>

                {/* hovered element */}
                {!props.isDragging &&
                    <Tooltip
                        popup
                        position="top center"
                        target={nodeRef.current}
                        open={props.isHovered && !props.isSelected}
                        clearance={8}
                        zIndex={1000}
                    >
                        <Icon
                            name={resolveElementIcon(props.node.component, (props.node.props && props.node.props.type))}
                            style={{ margin: 0, marginRight: 6, opacity: 0.6 }}
                        />

                        {resolveElementName(props.node.component, props.node.props && props.node.props.type)}

                        <Icon name="move" style={{ marginLeft: 6, marginRight: 0, opacity: 0.6 }} />
                    </Tooltip> 
                }

                <SelectableWrapper
                    {...props}
                    draggable={draggable}
                    close={select}
                    getPath={props.getPath}
                    select={select}
                    targetProps={selectableTargetProps}
                    style={{
                        marginLeft,
                        marginRight,
                        marginTop,
                        marginBottom
                    }}
                >
                    <Component
                        {...props}
                        computedStyling={innerStyles}
                        freezeDragPath={freezeDragPath}
                    />
                </SelectableWrapper>
            </React.Fragment>
        );
    });

    return injectIntl(Selectable);
}