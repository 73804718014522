import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from 'components/editor-v2/editor-interface/options/common/Crop';
import Label from 'components/option-library/label';
import Section from 'components/editor-v2/editor-interface/options/Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset/Preset';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import { toggleInlineStyle, toggleLink, toggleBlockType } from './update-helpers';
import Link from 'components/editor-v2/editor-interface/options/common/link/Link';
import { injectIntl } from 'react-intl';

const TextOptions = injectIntl(memo(props => {
    const { editableNode: node, updateState, theme, intl, editorState, setEditorState, suspend } = props;
    const [state, dispatch] = useAccordion({
        // Fold out the Link subsection by default
        accordionWithLink: {
            0: true
        }
    });

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = contentState.getBlockForKey(selection.getStartKey());
    const blockType = block.getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    /*
        https://draftjs.org/docs/advanced-topics-entities.html
    */
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const linkKey = block.getEntityAt(startOffset);

    let entityData;

    if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);

        entityData = linkInstance.getData();
    }

    const selectedText = block.getText().slice(startOffset, endOffset);

    const presetKey = props.theme.presets.text[blockType] ? blockType : 'Normal'; // Legacy reasons (some old text nodes have "unstyled" as a preset, which doesn't exist)

    return (
        <React.Fragment>
            {/* {selectedText && 
                <p style={{ color: 'white' }}> Selected Text: <b> {selectedText} </b> </p>
            } */}
            <Section>
                <Preset
                    presets={props.theme.presets.text}
                    onChange={(key, value) => {
                        if (key === 'style') {
                            onChange(key, value); // Likely better to allow overrides for blocks?
                        } else {
                            setEditorState(toggleBlockType(value, editorState))
                        }
                    }}
                    presetKey={presetKey}
                    excludePresets={['Link', 'Ordered list', 'Unordered list']}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                {/* <Label
                    label="Type"
                    labelIcon="sn-typography"
                    labelDescription="Something about Text"
                >
                    <PresetPicker
                        presets={props.theme.presets.text}
                        onChange={useCallback(value => onChange('preset', value), [onChange])}
                    />

                    <StylingOverrides
                        keys={Object.keys(props.theme.presets.text.Normal)}
                        preset={props.theme.presets.text[node.props.preset] || props.theme.presets.text.Normal}
                        theme={props.theme} 
                        onChange={useCallback(value => onChange('style', value), [onChange])}
                        value={node.props.style}
                    />
                </Label> */}

                <Label>
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="bold"
                            tooltipLabel={intl.formatMessage({ id: 'options.bold text' })}
                            tooltipPosition="top center"
                            value={currentStyle.has('BOLD')}
                            onChange={() => setEditorState(toggleInlineStyle('BOLD', editorState))}
                            />
                        <Toggle
                            icon="italic"
                            tooltipLabel={intl.formatMessage({ id: 'options.italic text' })}
                            tooltipPosition="top center"
                            value={currentStyle.has('ITALIC')}
                            onChange={() => setEditorState(toggleInlineStyle('ITALIC', editorState))}
                            />
                        <Toggle
                            icon="underline"
                            tooltipLabel={intl.formatMessage({ id: 'options.underline text' })}
                            tooltipPosition="top center"
                            value={currentStyle.has('UNDERLINE')}
                            onChange={() => setEditorState(toggleInlineStyle('UNDERLINE', editorState))}
                        />
                        <Toggle
                            icon="strikethrough"
                            tooltipLabel={intl.formatMessage({ id: 'options.strikethrough text' })}
                            tooltipPosition="top center"
                            value={currentStyle.has('STRIKETHROUGH')}
                            onChange={() => setEditorState(toggleInlineStyle('STRIKETHROUGH', editorState))}
                        />
                    </ToggleGroup>
                </Label>

                <Label
                    label={intl.formatMessage({ id: 'options.text alignment'})}
                    labelIcon="sn-arrow-resize3"
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-paragraph-left3"
                            tooltipLabel={intl.formatMessage({ id: 'options.text alignment left' })}
                            tooltipPosition="top center"
                            value={['center', 'right', 'justify'].indexOf(node.props.textAlignment) === -1}
                            onChange={() => onChange('textAlignment', 'left', 'alignment text', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-center3"
                            tooltipLabel={intl.formatMessage({ id: 'options.text alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.textAlignment === 'center'}
                            onChange={() => onChange('textAlignment', 'center', 'alignment text', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-right3"
                            tooltipLabel={intl.formatMessage({ id: 'options.text alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.textAlignment === 'right'}
                            onChange={() => onChange('textAlignment', 'right', 'alignment text', false)}
                        />

                        {/* TO DO: extend props for text alignment (NOT CONNECTED) */}
                        {/* Can't be done with DraftJS without significant complexity */}
                        {/* <Toggle
                            icon="sn-paragraph-justify3"
                            tooltipLabel={intl.formatMessage({ id: 'options.text alignment justify' })}
                            tooltipPosition="top center"
                            value={node.props.textAlignment === 'justify'}
                            onChange={() => onChange('textAlignment', 'justify')}
                        /> */}
                    </ToggleGroup>
                </Label>
            </Section>

            <Accordion state={state} dispatch={dispatch} id="accordionWithLink">
                <Link
                    key={!entityData ? 'bla' : entityData.url}
                    disabled={!selectedText && !entityData} // disabled link option if no text is selected
                    onChange={value => {                    
                        setEditorState(toggleLink(value, editorState, entityData, block, linkKey));
                    }}
                    value={entityData}
                />
            </Accordion>
        </React.Fragment>
    );
}));

export default props => {
    if (!props.editorState) return null;
    
    return <TextOptions {...props} />;
};