import React, { useRef, useState, useEffect } from 'react';
import { Icon, Label } from 'svift-ui';
import css from './zones.scss';
import { FormattedMessage } from 'react-intl';
import resolveDropTargetTranslation from './resolveDropTargetTranslation';

const resolveDropzone = componentTarget => {
    switch (componentTarget) {
        case 'gridZoneTopEscape': 
        case 'gridZoneTop': {
            return {
                dropzoneKey: 'grid',
                dropzoneClass: 'section-dropzone-top',
                dropzoneIconClass: 'section-dropzone-icon',
                dropzoneIcon: 'sn-grid-2',
            };
        }
        case 'gridZoneBottomEscape': 
        case 'gridZoneBottom': {
            return {
                dropzoneKey: 'grid',
                dropzoneClass: 'section-dropzone-bottom',
                dropzoneIconClass: 'section-dropzone-icon',
                dropzoneIcon: 'sn-grid-2',
            };
        }
        case 'rowZoneTopEscape': 
        case 'rowZoneTop': {
            return {
                dropzoneKey: 'row',
                dropzoneClass: 'row-dropzone-top',
                dropzoneIconClass: 'row-dropzone-icon',
                dropzoneIcon: 'sn-columns',
            };
        }
        case 'rowZoneBottomEscape': 
        case 'rowZoneBottom': {
            return {
                dropzoneKey: 'row',
                dropzoneClass: 'row-dropzone-bottom',
                dropzoneIconClass: 'row-dropzone-icon',
                dropzoneIcon: 'sn-columns',
            };
        }
        case 'rowZoneLeft':
        case 'columnZoneLeftEscape':
        case 'columnZoneLeft': {
            return {
                dropzoneKey: 'column',
                dropzoneClass: 'column-dropzone-left',
                dropzoneIconClass: 'column-dropzone-icon',
                dropzoneIcon: 'sn-marquee',
            };
        }
        case 'rowZoneRight':
        case 'columnZoneRightEscape':
        case 'columnZoneRight': {
            return {
                dropzoneKey: 'column',
                dropzoneClass: 'column-dropzone-right',
                dropzoneIconClass: 'column-dropzone-icon',
                dropzoneIcon: 'sn-marquee',
            };
        }
        case 'columnElementZoneTop': {
            return {
                dropzoneKey: 'columnelement',
                dropzoneClass: 'element-dropzone-top',
                dropzoneIconClass: 'element-dropzone-icon',
                dropzoneIcon: 'sn-marquee-plus',
            };
        }
        case 'columnElementZoneBottom': {
            return {
                dropzoneKey: 'columnelement',
                dropzoneClass: 'element-dropzone-bottom',
                dropzoneIconClass: 'element-dropzone-icon',
                dropzoneIcon: 'sn-marquee-plus',
            };
        }
        default: {
            return null;
        }
    }
};

const DropZoneVisualizer = props => {
    // const [isOver, setIsOver] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const iconRef = useRef();
    // const dropzoneRef = useRef();
    const [icon, setIcon] = useState(iconRef.current);

    useEffect(() => {
        const handler = active => {
            // console.log(active, 'drop zone handler called');

            setIsActive(active);
        };

        const unsub = props.dragState.subscribeDropZone(props.uuid, handler);

        return unsub;
    }, []);

    useEffect(() => {
        setIcon(iconRef.current);
        // props.dragState.subscribe(() => {
        //     setIsOverCollisionStack(true);
        // });
    }, [iconRef.current]);
    
    // useEffect(() => {
    //     // props.dragState.setActiveZone(props.id, isOver => {
    //     //     setIsOver(isOver);
    //     // });
    // }, [props.isOver])

    const { xStart, xEnd } = props;
    const { yStart, yEnd } = props;

    const { dropzoneClass, dropzoneIconClass, dropzoneIcon, dropzoneKey } = resolveDropzone(props.id);

    const style = {
        position: 'absolute',
        left: `${xStart}%`,
        top: `${yStart}%`,
        width: `${xEnd - xStart}%`,
        height: `${yEnd - yStart}%`,
        // background: isActive ? 'rgba(0, 0, 0, 0.5)' : null,// props.color || 'rgba(226, 199, 214, 0.5)',
        zIndex: props.zIndex || 900
    };

    if (props.maxLength) {
        const {
            px,
            position
        } = props.maxLength;

        if (position === 'top') {
            style.maxHeight = px;
            style.top = '0px';
        } else if (position === 'bottom') {
            delete style.top;

            style.maxHeight = px;
            style.bottom = '0px';
        } else if (position === 'left') {
            style.maxWidth = px;
            style.left = '0px';
        } else if (position === 'right') {
            delete style.left;
            
            style.maxWidth = px;
            style.right = '0px';
        }
    }
    
    return (
        <React.Fragment>
            <div
                className={`${css['targetzone']} ${props.id}`}
                // ref={ref => {
                //     props.connectDropTarget(ref);
                //     dropzoneRef.current = ref;
                // }}
                style={style}
            />

            {isActive &&
                <div className={`${css[dropzoneClass]} ${css['animated']} ${css['pulse']} ${css['faster']}`}>
                    <div style={{ position: 'relative' }}>
                        <Icon
                            circular
                            ref={iconRef}
                            className={css[dropzoneIconClass]}
                            name={dropzoneIcon}
                        />
                        
                        <div 
                            // className={`${css['animated']} ${css['fadeInUp']} ${css['fast']}`}
                            style={{ position: 'absolute', bottom: '100%', left: 0, right: 0, marginBottom: 12, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'visible', pointerEvents: 'none' }}
                        >
                            <Label basic size="small" className="primary" stlye={{ margin: 0, whiteSpace: 'nowrap'  }}>
                                {resolveDropTargetTranslation(dropzoneKey)}
                            </Label>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default DropZoneVisualizer;

/*
    <Selectable> 
        <DropZone
            node={node}
            x={[0, 100]}
            y={[0, 100]}
        />

        <DropZone
            node={node}
            x={[0, 100]}
            y={[0, 100]}
        />

        {this.props.resolveDropZones(node.component)}

        <Component />
    </Selectable>


*/

/*
			const didEnter = !prevProps.isOver && this.props.isOver;
			const hoverMode = this.props.hoverMode;

			if (didEnter && !hoverMode) {
				const { dragSourceType, dragSourceProps, targets } = this.props.draggedItem;

				const targetCoordinates = targets[type];

				const action = targetCoordinates && targetCoordinates[this.props.coordinates];

				if (action && action.hover) {
					action.hover({
						source: {
							props: dragSourceProps,
							type: dragSourceType
						},
						target: {
							props: this.props,
							type
						}
					});
				} else {
					console.warn(`SOURCE ${dragSourceType} DOES NOT HAVE A HOVER HANDLER FOR TARGET ${type} WITH COORDINATES ${this.props.coordinates}`);
				}
			}
*/

// const createDropZone = props => {
//     const DropZone = props => {
//         const [isOver, setIsOver] = useState(false);
//         const iconRef = useRef();
//         const dropzoneRef = useRef();
//         const [icon, setIcon] = useState(iconRef.current);

//         useEffect(() => {
//             setIcon(iconRef.current);
//             // props.dragState.subscribe(() => {
//             //     setIsOverCollisionStack(true);
//             // });
//         }, [iconRef.current]);
        
//         useEffect(() => {
//             props.dragState.setActiveZone(props.id, isOver => {
//                 setIsOver(isOver);
//             });
//         }, [props.isOver])

//         const { xStart, xEnd } = props;
//         const { yStart, yEnd } = props;

//         const activeDropzone = resolveDropzone(props.id);

//         const style = {
//             position: 'absolute',
//             left: `${xStart}%`,
//             top: `${yStart}%`,
//             width: `${xEnd - xStart}%`,
//             height: `${yEnd - yStart}%`,
//             background: isOver ? 'rgba(0, 0, 0, 0.5)' : props.color || 'rgba(226, 199, 214, 0.5)',
//             zIndex: props.zIndex || 900
//         };

//         if (props.maxLength) {
//             const {
//                 px,
//                 position
//             } = props.maxLength;

//             if (position === 'top') {
//                 style.maxHeight = px;
//                 style.top = '0px';
//             } else if (position === 'bottom') {
//                 delete style.top;

//                 style.maxHeight = px;
//                 style.bottom = '0px';
//             } else if (position === 'left') {
//                 style.maxWidth = px;
//                 style.left = '0px';
//             } else if (position === 'right') {
//                 delete style.left;
                
//                 style.maxWidth = px;
//                 style.right = '0px';
//             }
//         }
        
//         return (
//             <React.Fragment>
//                 <div
//                     className={`${css['targetzone']} ${props.id}`}
//                     ref={ref => {
//                         props.connectDropTarget(ref);
//                         dropzoneRef.current = ref;
//                     }}
//                     style={style}
//                 />

//                 {/* visible dropzone during DND */}
//                 {(isOver && activeDropzone) &&
//                     <div className={css[activeDropzone.dropzoneClass]}>
//                         <Tooltip 
//                             target={icon}
//                             open
//                             zIndex={10000}
//                         >
//                             {/* TO DO: display Tooltip with dragSource + target element when dropzone is visible */}
//                             Hej !!
//                         </Tooltip>

//                         <Icon
//                             circular
//                             ref={iconRef}
//                             className={css[activeDropzone.dropzoneIconClass]}
//                             name={activeDropzone.dropzoneIcon}
//                         />
//                     </div>
//                 }
//             </React.Fragment>
//         );
//     }

//     const targetInterface = props.dndInterface[props.node.component] || props.dndInterface.ColumnElement;

//     const sources = targetInterface.sources;
// 	const spec = {
// 		drop: (...args) => {
//             const [props, monitor] = args;
//             const dndInterface = props.dndInterface;
//             const droppedItem = monitor.getItem();
//             const { source } = droppedItem;
//             const { targets } = dndInterface[source] || dndInterface['ColumnElement'];
//             const dropHandler = targets[props.id];

//             dropHandler(...args);
//         },
//         hover: (props, monitor, component) => {
//             // if (props.node.component === 'Grid') console.log(monitor.isOver(), 'isOver')
//         }
// 	};
// 	const collect = (connect, monitor) => {
// 		return {
// 			connectDropTarget: connect.dropTarget(),
// 			isOver: monitor.isOver()
// 		};
// 	};

// 	return DropTarget(sources, spec, collect)(DropZone);
// }

// export default props => {
//     return null;
//     const DropZone = useRef(createDropZone(props)).current;

//     return <DropZone {...props} />;
// };