const createConfig = (deploymentType = 'development') => {
    const config = {
        protocol: 'http',
        coreDomain: 'http://dev.core.svift.net:3000',
        superadminDomain: 'http://dev.system.svift.net:3000',
    };

    if (
        deploymentType === 'production' &&
        !process.env
            .DEV_PRODUCTION /* Sometimes we want to test production build in dev environment */
    ) {
        config.protocol = 'https';
        config.coreDomain = 'https://core.svift.net';
        config.superadminDomain = 'https://system.svift.net';
    }

    if (deploymentType === 'sandbox') {
        config.protocol = 'https';
        config.coreDomain = 'https://test.core.svift.net';
        config.superadminDomain = 'https://test.system.svift.net';
    }

    return config;
};

export default createConfig(process.env.NODE_ENV);
