import constants from 'store/constants';

const actions = {
	toggleUserMenu: toggled => ({
		type: constants.TOGGLE_USER_MENU,
		data: toggled
	}),

	toggleSideMenu: toggled => ({
		type: constants.TOGGLE_SIDE_MENU,
		data: toggled
	}),

	toggleStepByStep: toggled => ({
		type: constants.TOGGLE_STEP_BY_STEP,
		data: toggled	
	})
};

export default actions;