
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

const predefinedNotifactions = {
	'submit-error-ok': {
		content: <FormattedMessage id='support.contact support notification' />,
		className: 'positive',
		position: 'topCenter',
		icon: 'sn-question3',
		deleteTime: 3000						
	}
}

export default predefinedNotifactions;