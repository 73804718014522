import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replaceRoute } from 'utils/routing';
import FormEditor from './FormEditor';
import { formsSelector } from 'store/selectors/sites/forms';

const mapStateToProps = state => {
	const formid = state.sites.forms.params.formid;
	const siteid = state.sites.params.siteid;
    const forms = formsSelector(state, siteid, null);
    
    return {
		siteid,
		formid,
		forms,
    };
}

const formExists = (forms, formid) => forms.find(form => formid === form._id);

class FormsContainer extends Component {
	handleRoute() {
		const {
			forms,
			siteid,
			formid
		} = this.props;

		if (!formExists(forms, formid)) {
			const defaultForm = forms[0] && forms[0]._id;

			replaceRoute(`/dashboard/${siteid}/design/forms/${defaultForm}`);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.formid !== this.props.formid || this.props.siteid !== prevProps.siteid) {
			this.handleRoute();
		}
	}

	componentDidMount() {
		this.handleRoute();
	}
	render() {
		// if (!this.props.stagedContent) return null;

		return (
			<div>
				{this.props.formid && this.props.siteid && 
					<FormEditor 
						key={this.props.formid}
						siteID={this.props.siteid}
						formID={this.props.formid}
					/>
				}

				{/* back to top button - used by portal in AppHeader */}
				<div
					id="sticky-top-button"
					style={{
						zIndex: 998,
						position: 'sticky',
						bottom: 60,
						marginRight: 16,
						height: 0,
						textAlign: 'right',
						pointerEvents: 'none'
					}}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(FormsContainer);