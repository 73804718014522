import { createSelector } from 'reselect';

const selectNavigationIds = (state, siteid) => state.sites.sites[siteid].navigation;
const selectNavigations = state => state.sites.navigation.navigations;
// const selectAutoNavigation = createSelector(
// 	(state, siteid, pages) => pages ? [{
// 		type: 'auto',
// 		entries: pages.map(page => ({
// 			id: page._id,
// 			parent: page.parentPage,
// 			hidden: false
// 		}))
// 	}] : []
// );
const selectAutoNavigation = (state, siteid, pages) => pages ? [{
	type: 'auto',
	entries: pages.map(page => ({
		id: page._id,
		parent: page.parentPage,
		hidden: false
	}))
}] : [];

const selector = createSelector(
	selectNavigationIds,
	selectNavigations,
	selectAutoNavigation,
	(navigationIds, navigations, pages) => {
		return pages.concat(navigationIds.map(navigationId => navigations[navigationId])).filter(entry => !!entry);
	}
);

export default selector;