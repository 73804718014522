import React, { PureComponent } from 'react';
import {
	Form,
	Grid,
	Icon,
	GridRow,
	GridColumn,
	Button,
	Message,
	DropdownMenu,
	Dropdown,
	DropdownItem,
	Dimmer, 
	Loader, 
	Divider, 
	Header, 
	Menu,
	Card, 
	CardGroup,
	Label
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import OptionLabel from 'components/option-library/label';

import input from 'components/forms/__util/field-components/input';
import dropdown from 'components/forms/__util/field-components/dropdown';
import checkbox from 'components/forms/__util/field-components/checkbox';
import AdaptedCardSelector from 'components/forms/__util/field-components/AdaptedCardSelector';
import validate, { string, valid, email } from 'components/forms/__util/validate';
import css from 'components/editor-v2/editor-interface/options/options.scss';

import { connect } from 'react-redux';
import timezoneSelector from 'store/selectors/system/timezones';
import { pagesSelector } from 'store/selectors/sites/pages';
// import preset from 'components/preset/util';
import { loadFont } from 'utils/fonts/loadedFonts';

import Alert from 'components/Alert';
import CopySiteForm from './CopySiteForm';
import ModalWrapper from 'components/modal';

import resolveLanguageName from 'utils/i18n/resolveLanguageName';
import resolveFlagIconName from 'utils/i18n/resolveFlagIconName';

const createLayoutCards = intl => [
	// {
	// 	id: 'empty-fallback-layout',
	// 	style: { padding: 6 },
	// 	noLabel: true,
	// 	name: intl.formatMessage({ id: 'templates.empty layout' }),
	// 	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/fallback-layout.png'
	// },
	{
		id: 'standard-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page standard layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-page-layout.png'
	},
	{
		id: 'standard-page-with-sidebar-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page standard with sidebar layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-page-with-sidebar-layout.png'
	},
	{
		id: 'sidebar-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page sidebar layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/sidebar-page-layout.png'
	},
	{
		id: 'centered-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page centered layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/centered-page-layout.png'
	},
	{
		id: 'fluid-page-layout',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.page fluid layout' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/fluid-page-layout.png'
	},
	// {
	// 	id: 'standard-post-layout',
	// 	style: { padding: 6 },
	// 	noLabel: true,
	// 	name: intl.formatMessage({ id: 'templates.post standard layout' }),
	// 	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/layouts/standard-post-layout.png'
	// }
];

const createTemplateCards = intl => [
	{
		id: 'empty-fallback',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template empty' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/fallback-template.png'
	},
	{
		id: 'standard-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template standard hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/standard-hero-template.png'
	},
	{
		id: 'background-image-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template background image hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/background-image-hero-template.png'
	},
	{
		id: 'banner-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template banner' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/banner-template.png'
	},
	{
		id: 'image-frame-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template image frame' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/image-frame-template.png'
	},
	{
		id: 'video-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template video hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/video-hero-template.png'
	},
	{
		id: '3-videos-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three videos' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-videos-template.png'
	},
	{
		id: '1-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template one column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/1-column-text-template.png'
	},
	{
		id: '2-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template two column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/2-column-text-template.png'
	},
	{
		id: '3-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-column-text-template.png'
	},
	{
		id: '4-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template four column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/4-column-text-template.png'
	},
	{
		id: 'left-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-infobox-template.png'
	},
	{
		id: 'right-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-infobox-template.png'
	},
	{
		id: 'broad-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template broad infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/broad-infobox-template.png'
	},
	{
		id: 'left-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-image-template.png'
	},
	{
		id: 'right-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-image-template.png'
	},
	{
		id: '3-round-features-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three round features' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-round-features-template.png'
	},
	{
		id: 'blog-list-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template blog list' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/blog-list-template.png'
	}
];

const fields = {
	name: {
		component: input({
			type: 'text'
		}),
		validate: string(1, 255)
	},
	timeZone: {
		component: dropdown({}),
		validate: string(1, 255)
	},
	standardLanguage: {
		component: dropdown({
			icon: 'sn-earth'
		}),
		validate: string(1, 255)
	},
	frontPage: {
		component: dropdown({
			icon: 'sn-file-text'
		}),
		validate: string(1, 255)
	},
	maintenanceMode: {
		component: checkbox({
			name: 'settings.site maintenance label',
			icon: 'sn-wrench2'
		}),
		validate: valid
	},
	blockCrawlers: {
		component: checkbox({
			name: 'settings.site block crawlers label',
			icon: 'sn-search4'
		}),
		validate: valid
	},
	homepageTitle: {
		component: input({
			type: 'text'
		}),
		validate: string(1, 255)
	},
	contactEmail: {
		component: input({
			type: 'text'
		}),
		validate: data => {
			if (data) {
				return email(data);
			}
			//if (!data) return intl => intl.formatMessage({ id: 'validation.field required' });
		}
	},
	layoutTemplate: {
		validate: valid
	},
	homepageTemplate: {
		validate: valid
	},
	theme: {
		validate: valid
	}
};

const mapStateToProps = (state) => {
	const roles = state.user.roles;
	const siteid = state.sites.params.siteid;

	return {
		roles,
		siteid,
		timezones: timezoneSelector(state),
		pages: pagesSelector(state, siteid),
		layoutTemplateValue: state.form && state.form.site && state.form.site.values && state.form.site.values.layoutTemplate || '',
		pageTemplateValue: state.form && state.form.site && state.form.site.values && state.form.site.values.pageTemplate || '',
		themes: state.system.themes,
		languages: [
			{ value: 'da', flag: resolveFlagIconName('da'), text: resolveLanguageName('da') },
			{ value: 'en', flag: resolveFlagIconName('en'), text: resolveLanguageName('en') },
			{ value: 'us', flag: resolveFlagIconName('us'), text: resolveLanguageName('us') },
			{ value: 'de', flag: resolveFlagIconName('de'), text: resolveLanguageName('de') },
			{ value: 'gl', flag: resolveFlagIconName('gl'), text: resolveLanguageName('gl') },
			{ value: 'fo', flag: resolveFlagIconName('fo'), text: resolveLanguageName('fo') },
			{ value: 'no', flag: resolveFlagIconName('no'), text: resolveLanguageName('no') },
			{ value: 'is', flag: resolveFlagIconName('is'), text: resolveLanguageName('is') },
			{ value: 'se', flag: resolveFlagIconName('se'), text: resolveLanguageName('se') },
			{ value: 'fi', flag: resolveFlagIconName('fi'), text: resolveLanguageName('fi') },
			{ value: 'nl', flag: resolveFlagIconName('nl'), text: resolveLanguageName('nl') },
			{ value: 'fr', flag: resolveFlagIconName('fr'), text: resolveLanguageName('fr') },
			{ value: 'es', flag: resolveFlagIconName('es'), text: resolveLanguageName('es') },
			{ value: 'it', flag: resolveFlagIconName('it'), text: resolveLanguageName('it') },
			{ value: 'pt', flag: resolveFlagIconName('pt'), text: resolveLanguageName('pt') },
			{ value: 'jp', flag: resolveFlagIconName('jp'), text: resolveLanguageName('jp') },
			{ value: 'th', flag: resolveFlagIconName('th'), text: resolveLanguageName('th') },
		]
	};
};

class SiteForm extends PureComponent {
	constructor() {
		super();

		this.state = {
			confirmDelete: false,
			showCopyForm: false
		};

		this.submit = this.submit.bind(this);
		// this.renderTemplateDropdowns = this.renderTemplateDropdowns.bind(this);
	}

	async submit(values) {
		const relevantFields = {
			...fields
		};

		const modifiedValues = {
			...values
		};

		if (!this.props.update) {
			delete relevantFields.frontPage;
			// modifiedValues.layoutTemplate = values.layoutTemplate[Object.keys(values.layoutTemplate)[0]].id;
		} else {
			delete relevantFields.layoutTemplate;
			delete relevantFields.homepageTitle;
			delete relevantFields.pageTemplate;
			delete relevantFields.theme;
		}

		validate(relevantFields)(modifiedValues);

		await this.props.onSubmit(modifiedValues);
	}
	
	// layout options
	renderLayoutOptions() {
		const { intl } = this.props;
		const layoutCards = createLayoutCards(intl);
		const selectedLayoutCard = layoutCards.find(card => card.id === this.props.layoutTemplateValue);

		return (
			<React.Fragment>
				<Header
					as="h4"
					style={{ marginTop: 0, marginBottom: 4 }}
					content={
						<React.Fragment>
							<Label
								circular
								className="secondary"
								size="big"
								style={{ marginLeft: 0, marginRight: 8 }}
							>
								1
							</Label>
							{/* transl8 */}
							<span>Layout</span>
						</React.Fragment>
					}
				/>
				<Divider style={{ marginTop: 0, marginBottom: 6 }} />
				<OptionLabel
					labelIcon="sn-web"
					label={intl.formatMessage({ id: 'layouts.select layout' })}
					labelDescription={intl.formatMessage({ id: 'layouts.starter layout description' })}
				>
					<div
						className="section-shade"
						style={{
							margin: 0,
							padding: '8px 12px',
							maxHeight: 240,
							overflowY: 'auto',
							overflowX: 'hidden',
							border: '1px solid #ccc'
						}}
					>
						<Field
							name="layoutTemplate"
							key="starter-layout-template"
							itemsPerRow={4}
							cards={layoutCards}
							component={AdaptedCardSelector}
						/>
					</div>

					{selectedLayoutCard &&
						<Message className="primary" style={{ marginTop: 4, padding: 8 }}>
							<span style={{ marginRight: 8 }}>
								<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
								<FormattedMessage id="general.you have chosen" />
							</span>
							<Label className="primary">
								<Icon name="sn-web" style={{ marginRight: 6 }} />
								{selectedLayoutCard.name}
							</Label>
						</Message>
					}
				</OptionLabel>
			</React.Fragment>
		)
	}

	renderHomepageOptions() {
		const { intl } = this.props;
		const templateCards = createTemplateCards(intl);
		const selectedTemplateCard = templateCards.find(card => card.id === this.props.pageTemplateValue);

		return (
			<React.Fragment>
				<Header
					as="h4"
					style={{ marginTop: 0, marginBottom: 4 }}
					content={
						<React.Fragment>
							<Label
								circular
								className="secondary"
								size="big"
								style={{ marginLeft: 0, marginRight: 8 }}
							>
								2
							</Label>
							<span><FormattedMessage id="settings.homepage label" /></span>
						</React.Fragment>
					}
				/>
				<Divider style={{ marginTop: 0, marginBottom: 6 }} />
				<OptionLabel
					labelIcon="sn-file-text"
					label={intl.formatMessage({ id: 'pages.homepage title' })}
					labelDescription={intl.formatMessage({ id: 'pages.homepage title description' })}
				>
					<Field
						fluid
						size="big"
						name="homepageTitle"
						intl={intl}
						icon="sn-file-text"
						iconPosition="left"
						placeholder={intl.formatMessage({ id: 'pages.homepage input placeholder' })}
						component={fields.homepageTitle.component}
					/>
				</OptionLabel>
				<OptionLabel
					labelIcon="sn-grid8"
					label={intl.formatMessage({ id: 'templates.starter homepage template label' })}
					labelDescription={intl.formatMessage({ id: 'templates.starter homepage template description' })}
				>
					<div
						className="section-shade"
						style={{
							margin: 0,
							padding: '8px 12px',
							maxHeight: 230,
							overflowY: 'auto',
							overflowX: 'hidden',
							border: '1px solid #ccc'
						}}
					>
						<Field
							name="pageTemplate"
							key="starter-homepage-template"
							itemsPerRow={3}
							cards={templateCards}
							component={AdaptedCardSelector}
						/>
					</div>
					
					{selectedTemplateCard &&
						<Message className="primary" style={{ marginTop: 4, padding: 8 }}>
							<span style={{ marginRight: 8 }}>
								<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
								<FormattedMessage id="general.you have chosen" />
							</span>
							<Label className="primary">
								<Icon name="sn-grid8" style={{ marginRight: 6 }} />
								{selectedTemplateCard.name}
							</Label>
						</Message>
					}
				</OptionLabel>
			</React.Fragment>
		)
	}

	renderThemeDropdown() {
		const { intl } = this.props;

		return (
			<React.Fragment>
				{/* theme options */}
				<Header
					as="h4"
					style={{ marginTop: 0, marginBottom: 4 }}
					content={
						<React.Fragment>
							<Label
								circular
								className="secondary"
								size="big"
								style={{ marginLeft: 0, marginRight: 8 }}
							>3</Label>
							<span>
								<FormattedMessage id="design.theme title" />
							</span>
						</React.Fragment>
					}
				/>
				<Divider style={{ marginTop: 0, marginBottom: 6 }} />
				<Field
					name="theme"
					key="theme-select"
					intl={intl}
					themes={this.props.themes}
					component={props => {
						const { input: field, meta: { error }, themes } = props;

						const formattedThemes = Object.keys(themes).map(themeKey => {
							const theme = themes[themeKey];

							return {
								...theme,
								value: theme._id,
								name: theme.name
							};
						});

						const selectedTheme = formattedThemes.find(({ value }) => field.value === value);
						loadFont(selectedTheme.fonts.primary.family);

						return (
							<React.Fragment>								
								<OptionLabel
									labelIcon="sn-bucket"
									label={intl.formatMessage({ id: 'design.starter theme label' })}
									labelDescription={intl.formatMessage({ id: 'design.starter theme description' })}
								>
									<Menu size="small">
										<Dropdown
											item
											fluid
											text={[
												<Icon name="sn-color-sampler" />,
												<span key="selection"> {selectedTheme.name} </span>
											]}
										>
											<DropdownMenu>
												{formattedThemes.map(({ name, value, icon }) => {
													return (
														<DropdownItem
															key={value}
															value={value}
															onClick={(param, { value: newValue }) => {
																field.onChange(newValue);
															}}
															className={value === field.value ? 'active' : ''}
														>
															<Icon name="sn-color-sampler" />
															{ name }
														</DropdownItem>
													);
												})}
											</DropdownMenu>
										</Dropdown>
									</Menu>
								</OptionLabel>
								
								<OptionLabel
									collapsed
									labelIcon="sn-eye"
									label={intl.formatMessage({ id: 'design.starter theme preview label' })}
								>
									<div
										className={css['option-frame-outline']}
										style={{
											display: 'block',
											backgroundColor: selectedTheme.colors.tint	
										}}
									>
										<Grid padded columns={3} style={{ borderRadius: 4, overflow: 'hidden' }}>
											<GridColumn textAlign="center" style={{ backgroundColor: selectedTheme.colors.primary }}>
												<Icon circular name="sn-droplet" style={{ fontSize: 14, margin: 0, color: selectedTheme.colors.tint, boxShadow: 'inset 0 0 0 1px white' }} />
											</GridColumn>
											<GridColumn textAlign="center" style={{ backgroundColor: selectedTheme.colors.secondary }}>
												<Icon circular name="sn-droplet" style={{ fontSize: 14, margin: 0, color: selectedTheme.colors.tint, boxShadow: 'inset 0 0 0 1px white' }} />
											</GridColumn>
											<GridColumn textAlign="center" style={{ backgroundColor: selectedTheme.colors.shade }}>
												<Icon circular name="sn-droplet" style={{ fontSize: 14, margin: 0, color: selectedTheme.colors.tint, boxShadow: 'inset 0 0 0 1px white' }} />
											</GridColumn>
										</Grid>

										<p style={{ fontSize: 32, marginTop: 16, marginBottom: 8, fontFamily: selectedTheme.fonts.primary.family, color: selectedTheme.colors.shade, textAlign: 'left' }}><FormattedMessage id="design.starter theme dummy title" /></p>

										<p style={{ fontSize: 16, fontFamily: selectedTheme.fonts.secondary.family, color: selectedTheme.colors.shade, textAlign: 'left' }}>
											<FormattedMessage id="design.starter theme dummy text" />
										</p>

										<Button
											onClick={e => {
												e.preventDefault();
												e.stopPropagation();
											}}
											// primary
											style={{ fontFamily: selectedTheme.fonts.primary.family, color: selectedTheme.colors.tint, backgroundColor: selectedTheme.colors.primary, boxShadow: 'none' }}
										>
											<FormattedMessage id="design.starter theme dummy button" />
										</Button>
									</div>
								</OptionLabel>
							</React.Fragment>
						);
					}}
				/>
			</React.Fragment>
		);
	}

	renderButtons(haveAccess) {
		const { intl, loading } = this.props;

		if (!this.props.update) {
			return (
				<Grid verticalAlign="middle">
					<GridColumn computer={8} textAlign="left">
						<Button positive icon>
							<Icon name="plus" style={{ marginRight: 8 }} />
							<FormattedMessage id="settings.create new site" />
						</Button>
					</GridColumn>
					<GridColumn computer={8} textAlign="right">
						<Button
							basic
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<FormattedMessage id="general.cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle" style={{ paddingTop: 16 }}>
				<GridColumn mobile={12} computer={10} textAlign="left">
					<Button positive disabled={loading}>
						<Icon name="sn-cloud-upload" style={{ marginRight: 8 }} />
						<FormattedMessage id="settings.save changes" />
					</Button>
					<Button
						basic
						size="tiny"
						disabled={loading}
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
						style={{ marginLeft: 16 }}
					>
						<Icon name="sn-cross2" style={{ marginRight: 8 }} />
						<FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>
				<GridColumn mobile={4} computer={6} textAlign="right">
					<Button
						basic
						negative
						disabled={(loading || !haveAccess)}
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'settings.delete site alert title' })}
							text={intl.formatMessage({ id: 'settings.delete site alert description' })}
							confirmTitle={intl.formatMessage({ id: 'settings.delete site alert confirm' })}
							confirmIcon="sn-sphere"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								this.props.handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						<Icon name="sn-bin" style={{ marginRight: 8 }} />
						<FormattedMessage id="settings.delete site" /> <Icon name="sn-sphere" style={{ marginLeft: 4 }} />
					</Button>

					{!haveAccess &&
						<InfoIcon
							description={intl.formatMessage({ id: 'settings.delete site access warning message' })}
							position="bottom right"
						/>
					}
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const { handleSubmit, intl, update, roles, siteid } = this.props;

		const rolesOnSite = roles.reduce((acc, { type, scope }) => {
			const scopeSiteMatch = scope === `sites/${siteid}`;

			if (scopeSiteMatch) {
				acc[type] = true;
			}

			return acc;
		}, {});

		return (
			<Form onSubmit={handleSubmit(this.submit)}>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}><Icon name="sn-sphere" /> <FormattedMessage id="general.saving changes" /></Loader>
					</Dimmer>
				}

				{!update &&
					[
						<Grid key="site-starter-configuration">
							<GridRow style={{ paddingBottom: 0 }}>
								<GridColumn computer={16}>
									{this.renderLayoutOptions()}
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn computer={10}>
									{this.renderHomepageOptions()}
								</GridColumn>
								<GridColumn computer={6}>
									{this.renderThemeDropdown()}
								</GridColumn>
							</GridRow>
						</Grid>,
						<Header
							as="h4"
							// textAlign="center"
							style={{ marginTop: 4, marginBottom: 4 }}
							content={
								<React.Fragment>
									<Label
										circular
										className="secondary"
										size="big"
										style={{ marginLeft: 0, marginRight: 8 }}
									>4</Label>
									<span>
										<FormattedMessage id="settings.general settings title" />
									</span>
								</React.Fragment>
							}
						/>,
						<Divider horizontal key="start-setup-divider-bottom" style={{ marginTop: 0, marginBottom: 6 }}>
							<Icon circular name="sn-sphere" styæe={{ fontSize: 16, opacity: 0.6 }} />
						</Divider>
						
					]
				}

				{/* main (general) site settings */}
				<Grid>
					<GridRow>
						<GridColumn computer={16}>
							{/* site name/title input */}
							<OptionLabel
								collapsed
								labelIcon="sn-sphere"
								label={intl.formatMessage({ id: 'general.new site title input' })}
								labelDescription={intl.formatMessage({ id: 'general.new site title input description' })}
							>
								<Field
									fluid
									name="name"
									size="massive"
									icon="sn-sphere"
									iconPosition="left"
									intl={intl}
									placeholder={intl.formatMessage({ id: 'general.new site title input' })}
									component={fields.name.component}
								/>
							</OptionLabel>

							<Divider horizontal style={{ marginBottom: 0 }}>
								<Icon circular name="setting" style={{ fontSize: 16, opacity: 0.6 }} />
							</Divider>
						</GridColumn>
					</GridRow>
					<GridRow style={{ paddingTop: 0 }}>
						<GridColumn computer={!this.props.update ? 16 : 8} style={{ textAlign: 'left' }}>
							{/* homepage (select) */}
							{this.props.update &&
								<OptionLabel
									labelIcon="sn-file-text"
									label={intl.formatMessage({ id: 'settings.site homepage label' })}
									labelDescription={intl.formatMessage({ id: 'settings.site homepage description' })}
								>
									<Field
										name="frontPage"
										intl={intl}
										values={this.props.pages/*.filter(page => page.parentPage === null)*/.map(page => {
											return {
												value: page._id,
												text: page.title
											};
										})}
										component={fields.frontPage.component}
									/>
								</OptionLabel>
							}

							{/* standard language */}
							<OptionLabel
								labelIcon="sn-earth2"
								label={intl.formatMessage({ id: 'settings.default language label' })}
								labelDescription={intl.formatMessage({ id: 'settings.default language description' })}
							>
								<Field
									name="standardLanguage"
									intl={intl}
									values={this.props.languages}
									component={fields.standardLanguage.component}
								/>
							</OptionLabel>

							{/* timezone */}
							<OptionLabel
								collapsed
								labelIcon="sn-clock"
								label={intl.formatMessage({ id: 'settings.timezone label' })}
								labelDescription={intl.formatMessage({ id: 'settings.timezone description' })}
							>
								<Field
									name="timeZone"
									intl={intl}
									placeholder={intl.formatMessage({ id: 'settings.select timezone placeholder' })}
									icon="sn-clock"
									iconPosition="left"
									values={this.props.timezones}
									component={fields.timeZone.component}
								/>
							</OptionLabel>
						</GridColumn>

						{update &&
							<GridColumn computer={8}>
								{/* <Field
									name="maintenanceMode"
									infoIcon={intl.formatMessage({ id: 'settings.site maintenance description' })}
									intl={intl}
									disabled={!update}
									component={fields.maintenanceMode.component}
									disabled
								/> */}

								{/* <Field
									name="blockCrawlers"
									infoIcon={intl.formatMessage({ id: 'settings.site block crawlers description' })}
									intl={intl}
									disabled={!update}
									component={fields.blockCrawlers.component}
									disabled
								/> */}
								
								{/* contact mail */}
								<OptionLabel
									labelIcon="sn-envelop2"
									label={intl.formatMessage({ id: 'settings.contact email label' })}
									labelDescription={intl.formatMessage({ id: 'settings.contact email description' })}
								>
									<Field
										fluid
										size="large"
										name="contactEmail"
										intl={intl}
										icon="sn-envelop2"
										iconPosition="left"
										placeholder={intl.formatMessage({ id: 'settings.contact email placeholder' })}
										component={fields.contactEmail.component}
										textAlign="left"
									/>
								</OptionLabel>
								
								{/* copy site button */}
								<OptionLabel
									labelIcon="sn-sphere2"
									label={intl.formatMessage({ id: 'settings.site copy label' })}
									labelDescription={intl.formatMessage({ id: 'settings.site copy description' })}
								>
									<Button 
										basic 
										fluid
										disabled={!rolesOnSite.siteOwner ? true : false}
										className="secondary" 
										size="medium"
										onClick={(e) => {
											e.preventDefault();
			
											this.setState({
												showCopyForm: true
											});
										}}
									>
										<Icon name="copy" />
										<FormattedMessage id="settings.site copy button title" />
									</Button>
								</OptionLabel>

								{!rolesOnSite.siteOwner &&
									<Message
										className="info"
										style={{ marginTop: 8, padding: 8, fontSize: 14, display: 'block' }}
									>
										<Icon name="copy" style={{ opacity: 0.6, marginRight: 6 }} />
										<FormattedMessage id="settings.copy site warning message" />
									</Message>
								}

								<ModalWrapper
									style={{ maxWidth: 480 }}
									header="settings.site copy title"
									// header={intl.formatMessage({ id: 'settings.site copy duplicate title' })}
									open={this.state.showCopyForm}
									handleClose={() => this.setState({ showCopyForm: false })}
								>
									<CopySiteForm 
										onSubmit={data => {
											this.setState({
												showCopyForm: false
											});

											this.props.copy(data.newSiteName);
										}}
									/>
								</ModalWrapper>
							</GridColumn>
						}
					</GridRow>
				</Grid>

				<Divider />

				{this.props.error &&
					<Message negative>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				{this.renderButtons(rolesOnSite.siteOwner)}
			</Form>
		);
	}
}

const ReduxSiteForm = reduxForm({
	form: 'site'
})(injectIntl(SiteForm));

ReduxSiteForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

ReduxSiteForm.defaultProps = {
	initialValues: {
		maintenanceMode: true,
		standardLanguage: 'da',
		timeZone: 'Europe/Copenhagen',
		blockCrawlers: true,
		layoutTemplate: 'standard-page-layout',
		pageTemplate: 'empty-fallback',
		theme: 'skeleton',
		contactEmail: ''
	}
};

export default connect(mapStateToProps)(ReduxSiteForm);
