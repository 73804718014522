import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import PropTypes from 'prop-types';

class FormCheckbox extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, formObj } = props.context;
		
		return shallowCompare({
			theme,
			setField: formObj && formObj.setField
		}, state);
	}

	constructor(props) {
		super(props);

		this.state = {
			checked: false,
			validationError: null
		};

		this.renderElement = this.renderElement.bind(this);
		this.check = this.check.bind(this);
		this.setError = this.setError.bind(this);
	}

	componentDidMount() {
		if (this.props.setField) this.props.setField(this);
	}

	setError(error) {
		this.setState({
			validationError: error
		});
	}

	validate() {
		if (!this.state.checked && this.props.node.props.required) {
			this.setError('Dette felt er påkrævet');

			return false;
		}

		return true;
	}

	check() {
		this.setState({
			checked: !this.state.checked,
			validationError: null
		});
	}

	renderElement() {
		const { theme, node } = this.props;

		const {
			label
		} = node.props;

		let labelStyle = this.props.computePreset({ ...theme.presets.checkbox[node.props.preset].label, ...node.props && node.props.style && node.props.style.label }, theme);

		const style = this.props.computedStyling;

		if (this.state.validationError) {
			const errorLabelStyle = this.props.computePreset(theme.presets.checkbox[node.props.preset].error.label, theme);

			labelStyle = {
				...labelStyle,
				...errorLabelStyle
			};
		}

		return (
			<div style={{ display: 'flex', width: '100%' }}>
				<label htmlFor="text">
					<input
						name="text"
						type="checkbox"
						checked={this.state.checked}
						onChange={this.check}
						style={style}
					/>
				</label>

				<p
					style={labelStyle}
				>
					{label}<br />

					{this.state.validationError && `*${this.state.validationError}`}
				</p>
			</div>
		);
	}

	render() {
		// const { node } = this.props;

		return this.renderElement();
	}
}

export default FormCheckbox;
