import React, {	PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	WizardControls,
	Box,
	Header,
	Grid,
	GridRow,
	GridColumn,
	Icon,
	Form,
	FormInput,
	HeaderSubheader,
	Accordion,
	AccordionTitle,
	Message,
	Label,
	Button,
	Divider
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import actions from 'store/actions/domainWizard';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Checkbox from 'components/option-library/primitives/checkbox';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import OptionLabel from 'components/option-library/label';
// import InfoIcon from 'components/InfoIcon';

const mapStateToProps = (state) => {
	return {
		name: `${state.user.firstName} ${state.user.lastName}`,
		email: state.user.email,
		orgDetails: state.wizard.orgDetails
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setCompanyInputText: (field, value) => dispatch(actions.setCompanyInputText(field, value))
	};
};

class VerifyOwner extends PureComponent {
	constructor() {
		super();

		this.state = {
			isCompany: false,
		};
	}

	render() {
		const {
			orgDetails,
			setCompanyInputText,
			intl
		} = this.props;

		const {
			orgName,
			vatNumber,
			address,
			zipCode,
			city,
			contactEmail,
			contactPhoneNumber
		} = orgDetails;

		return (
			<div>
				<Header as="h1" textAlign="center">
					<FormattedMessage id="activation.owner wizard step header" />
				</Header>

				{/* <Grid centered>
					<GridColumn computer={8} mobile={16}>
						<p style={{ textAlign: 'left' }}>
							<FormattedMessage id="activation.owner wizard step description" />
						</p>
					</GridColumn>
				</Grid> */}

				<Box style={{ maxWidth: 560, textAlign: 'left' }}>
					<div style={{ textAlign: 'center' }}>
						<div className="box-icon-container">
							<Icon circular name="star" className="box-icon" />
						</div>

						<Icon name="sn-account_circle" className="user-icon" style={{ opacity: 0.6, fontSize: 80, marginTop: 48, marginRight: 0 }} />

						<Header as="h3" style={{ opacity: 0.8, marginTop: 8, marginBottom: 24 }}>
							{this.props.name}

							<HeaderSubheader>
								<p style={{ fontSize: 20, opacity: 0.6 }}>{this.props.email}</p>
							</HeaderSubheader>
						</Header>
					</div>

					{/* transl8 */}
					{/* TO DO: display form when organization checkmark selected */}
					<Divider horizontal><Icon name="sn-info" style={{ opacity: 0.6, fontSize: 24 }} /></Divider>

					{/* <OptionLabel
						labelIcon="sn-earth"
						label="Are you an international customer (located outside Denmark)?"
					>
						<Checkbox
							// icon="sn-earth"
							// value={node.props.vertical}
							label="international customer"
							// onChange={val => onChange('vertical', val, 'toggle vertical', false)}
						/>
						<div>
							[ SELECT COUNTRY ]
						</div>
					</OptionLabel>

					<div>
					</div>

					<OptionLabel
						collapsed
						labelIcon="building outline"
						label="Are you part of an organization?"
					>
						<Checkbox
							// value={node.props.vertical}
							label="I'm part of an organization"
							// onChange={val => onChange('vertical', val, 'toggle vertical', false)}
						/>
					</OptionLabel>

						<Form style={{ marginTop: 16, padding: '16px 16px 24px 16px', borderRadius: 4, border: '2px solid #CCC', backgroundColor: 'white' }}>
						<Grid textAlign="left">
							<GridRow>
								<GridColumn computer={16} mobile={16}>
									<FormInput
										size="big"
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation title' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert title' })}
										fluid
										value={orgName || ''}
										onChange={(e, { value }) => setCompanyInputText('orgName', value)}
										/>
								</GridColumn>
							</GridRow>
							<GridRow style={{ paddingTop: 0 }}>
								<GridColumn computer={9} mobile={12}>
									<FormInput
										size="big"
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation vat number' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert vat number' })}
										fluid
										type="number"
										value={vatNumber || ''}
										onChange={(e, { value }) => setCompanyInputText('vatNumber', value)}
										/>
								</GridColumn>
							</GridRow>
							<GridRow style={{ paddingTop: 0 }}>
								<GridColumn computer={16}>
									<FormInput
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation address' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert address' })}
										fluid
										value={address || ''}
										onChange={(e, { value }) => setCompanyInputText('address', value)}
										/>
								</GridColumn>
							</GridRow>
							<GridRow style={{ paddingTop: 0 }}>
								<GridColumn computer={10}>
									<FormInput
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation city' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert city' })}
										fluid
										value={city || ''}
										onChange={(e, { value }) => setCompanyInputText('city', value)}
									/>
								</GridColumn>
								<GridColumn computer={6}>
									<FormInput
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation zip code' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation zip code placeholder' })}
										fluid
										type="number"
										value={zipCode || ''}
										onChange={(e, { value }) => setCompanyInputText('zipCode', value)}
										/>
								</GridColumn>
							</GridRow>
							<GridRow style={{ paddingTop: 0 }}>
								<GridColumn computer={8}>
									<FormInput
										type="email"
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation email' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert email' })}
										fluid
										value={contactEmail || ''}
										onChange={(e, { value }) => setCompanyInputText('contactEmail', value)}
										/>
								</GridColumn>
								<GridColumn computer={8}>
									<FormInput
										label={intl.formatMessage({ id: 'activation.owner wizard step organisation phone number' })}
										placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert phone number' })}
										fluid
										type="number"
										value={contactPhoneNumber || ''}
										onChange={(e, { value }) => setCompanyInputText('contactPhoneNumber', value)}
									/>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn computer={16}>
									<Message
										className="info"
										content={<span style={{ fontSize: 14 }}><FormattedMessage id="activation.owner wizard step organisation details description" /></span>}
										style={{ textAlign: 'left', display: 'block' }}
										/>
								</GridColumn>
							</GridRow>
						</Grid>
					</Form> */}

					<div style={{ textAlign: 'center' }}>
						<React.Fragment>
							<p style={{ fontSize: 16 }}>
								{/* <Icon name="building outline" className="info-color" style={{ fontSize: 44, float: 'left', marginTop: 4, marginBottom: 44, opacity: 0.6 }} /> */}
								<span style={{ fontWeight: 'bold', display: 'block', fontSize: 18, marginBottom: 8 }}>
									<FormattedMessage id="activation.owner wizard step organisation details message title" />
								</span>
								<FormattedMessage id="activation.owner wizard step organisation details message description" />
							</p>
							<Accordion
								style={{ marginTop: 16 }}
								onClick={() => {
									this.setState({
										isCompany: !this.state.isCompany
									});
								}}
							>
								<AccordionTitle style={{ textAlign: 'center', verticalAlign: 'middle' }}>
									<Icon
										name={this.state.isCompany ? 'sn-minus-circle2' : 'sn-plus-circle'}
										className="primary"
										style={{ fontSize: 28, marginRight: 8, verticalAlign: 'middle' }}
									/>
									<span style={{ fontSize: 18, verticalAlign: 'middle' }}>
										<FormattedMessage id={`activation.owner wizard step ${this.state.isCompany ? 'hide' : 'show'} organisation details`} />
									</span>
								</AccordionTitle>
							</Accordion>
		
							{this.state.isCompany &&
								<Form style={{ marginTop: 16, padding: '16px 16px 24px 16px', borderRadius: 4, border: '2px solid #CCC', backgroundColor: 'white' }}>
									<Grid textAlign="left">
										<GridRow>
											<GridColumn computer={16} mobile={16}>
												<FormInput
													size="big"
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation title' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert title' })}
													fluid
													value={orgName || ''}
													onChange={(e, { value }) => setCompanyInputText('orgName', value)}
													/>
											</GridColumn>
										</GridRow>
										<GridRow style={{ paddingTop: 0 }}>
											<GridColumn computer={9} mobile={12}>
												<FormInput
													size="big"
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation vat number' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert vat number' })}
													fluid
													type="number"
													value={vatNumber || ''}
													onChange={(e, { value }) => setCompanyInputText('vatNumber', value)}
													/>
											</GridColumn>
										</GridRow>
										<GridRow style={{ paddingTop: 0 }}>
											<GridColumn computer={16}>
												<FormInput
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation address' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert address' })}
													fluid
													value={address || ''}
													onChange={(e, { value }) => setCompanyInputText('address', value)}
													/>
											</GridColumn>
										</GridRow>
										<GridRow style={{ paddingTop: 0 }}>
											<GridColumn computer={10}>
												<FormInput
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation city' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert city' })}
													fluid
													value={city || ''}
													onChange={(e, { value }) => setCompanyInputText('city', value)}
												/>
											</GridColumn>
											<GridColumn computer={6}>
												<FormInput
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation zip code' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation zip code placeholder' })}
													fluid
													type="number"
													value={zipCode || ''}
													onChange={(e, { value }) => setCompanyInputText('zipCode', value)}
													/>
											</GridColumn>
										</GridRow>
										<GridRow style={{ paddingTop: 0 }}>
											<GridColumn computer={8}>
												<FormInput
													type="email"
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation email' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert email' })}
													fluid
													value={contactEmail || ''}
													onChange={(e, { value }) => setCompanyInputText('contactEmail', value)}
													/>
											</GridColumn>
											<GridColumn computer={8}>
												<FormInput
													label={intl.formatMessage({ id: 'activation.owner wizard step organisation phone number' })}
													placeholder={intl.formatMessage({ id: 'activation.owner wizard step organisation insert phone number' })}
													fluid
													type="number"
													value={contactPhoneNumber || ''}
													onChange={(e, { value }) => setCompanyInputText('contactPhoneNumber', value)}
												/>
											</GridColumn>
										</GridRow>
										<GridRow>
											<GridColumn computer={16}>
												<Message
													className="info"
													content={<span style={{ fontSize: 14 }}><FormattedMessage id="activation.owner wizard step organisation details description" /></span>}
													style={{ textAlign: 'left', display: 'block' }}
													/>
											</GridColumn>
										</GridRow>
									</Grid>
								</Form>
							}
						</React.Fragment>
					</div>
					
					{/* confirm ownership message */}
					<Divider horizontal><Icon circular name="sn-checkmark" style={{ opacity: 0.6, fontSize: 24 }} /></Divider>
					<div style={{ textAlign: 'center' }}>
						<div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 8 }}>
							<FormattedMessage id="activation.confirm ownership message title" />
						</div>
						<div style={{ fontSize: 16 }}>
							<FormattedMessage id="activation.confirm ownership message description" />
						</div>
					</div>

					{/* <p style={{ fontSize: 14, textAlign: 'left', fontStyle: 'italic' }}>
						<span style={{ fontWeight: 'bold' }}><FormattedMessage id="general.note label" /></span> <FormattedMessage id="activation.confirm ownership message disclaimer" />
					</p> */}
				</Box>

				<WizardControls
					nextButton
					renderNextButton={() => (
						<Button
							size="massive"
							className="primary"
							onClick={() => this.props.setStep(1)}
						>
							<FormattedMessage id="activation.owner wizard step next" />
							<Icon name="sn-arrow-right8" style={{ marginLeft: 8 }} />
						</Button>
					)}
					onNext={() => this.props.setStep(1)}
					styles={{ marginTop: 48 }}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerifyOwner));
