import React, { PureComponent } from 'react';
import { Message, IconGroup, Icon, Header, Button, Divider } from 'svift-ui';
import BlogSettings from './BlogSettings';
import BlogEditor from './BlogEditor';
import { connect } from 'react-redux';
import { replaceRoute } from 'utils/routing';
import { FormattedMessage } from 'react-intl';
import key from 'utils/key';
import { blogsSelector } from 'store/selectors/sites/blogs';

const mapStateToProps = state => {
	const siteID = state.sites.params.siteid;
	const site = state.sites.sites[siteID];
	const blogID = state.sites.blogs.params.blogid;
	const blogs = blogsSelector(state, siteID);

	return {
		siteID,
		// routeMatchesBlog: !!state.sites.blogs.blogs[blogID],
		routeMatchesBlog: blogs.some(({ _id }) => _id === blogID),
		blogID: state.sites.blogs.params.blogid,
		allBlogs: blogs,
		// allBlogIDs: (site.blogs && blogs: blogsSelector(state, siteid) || [],
		blogModuleEnabled: key('modules.blog')(site)
	};
}

const stickyHeaderStyling = {
	position: 'sticky',
	top: 56,
	left: 0,
	right: 0,
	height: 0,
	pointerEvents: 'none'
};

class BlogContainer extends PureComponent {
	handleRoute = () => {
		if (this.props.routeMatchesBlog) return;

		const fallbackBlog = this.props.allBlogs.length > 0 && this.props.allBlogs.sort((a, b) => {
			if (typeof a.publishAt === 'undefined') return 1;
			if (typeof b.publishAt === 'undefined') return -1;

			return new Date(b.publishAt) - new Date(a.publishAt);
		})[0];

		if (fallbackBlog) replaceRoute(`/dashboard/${this.props.siteID}/blog-posts/${fallbackBlog._id}`);
	}
	
	componentDidUpdate() {
		this.handleRoute();
	}

	componentDidMount() {
		this.handleRoute();
	}

	renderNoBlogsMessage = () => {
		return (
			<Message
				className="warning"
				style={{
					margin: 24,
					padding: '40px 32px 64px 32px',
					minHeight: 320,
					textAlign: 'center'
				}}
			>
				{/* No blog posts info message */}
				<IconGroup style={{ marginBottom: 16 }}>
					<Icon
						name="sn-blog"
						style={{
							opacity: 0.6,
							fontSize: 64
						}}
					/>
					<Icon
						corner
						name="add"
						className="positive-color"
						style={{
							fontSize: 32,
							marginRight: -8,
							marginBottom: -8
						}}
					/>
				</IconGroup>
				<Header as="h1" style={{ marginBottom: 8 }}>
					<FormattedMessage id="blog.no posts message title" />
				</Header>
				
				<div style={{ maxWidth: 440, margin:  '0 auto 24px auto', textAlign: 'left' }}>
					<Divider style={{ marginTop: 0 }} />
					<FormattedMessage id="blog.no posts message description" />
				</div>

				<BlogSettings
					renderButton={toggleFormOpen => {
						return (
							<Button
								className="positive"
								size="huge"
								onClick={() => toggleFormOpen('create')}
								style={{ margin: 0 }}
							>
								<Icon name="sn-plus3" style={{ verticalAlign: 'middle', marginRight: 2, opacity: 0.6 }} />
								<Icon name="sn-magazine" style={{ verticalAlign: 'middle', marginLeft: 0, marginRight: 6 }} />
								<span style={{ verticalAlign: 'middle' }}>
									<FormattedMessage id="blog.create post button" />
								</span>
							</Button>
						);
					}}
				/>
			</Message>
		);
	}

	render() {
		if (!this.props.blogModuleEnabled) return (
			<Message className="error" style={{ textAlign: 'center', padding: '32px 40px 64px 40px' }}>
				<Icon name="sn-warning2" style={{ fontSize: 80, opacity: 0.6, margin: 0, marginBottom: 12 }} />
				<Header as="h3" style={{ fontSize: 32, marginBottom: 16 }}>
					<FormattedMessage id="general.access denied" />
				</Header>
				<div style={{ maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
					<p><FormattedMessage id="messages.blog module not activated" /></p>
				</div>
			</Message>
		);

		if (this.props.allBlogs.length === 0) return this.renderNoBlogsMessage();
		if (!this.props.routeMatchesBlog) return null;

		return (
			<React.Fragment>
				{/* sticky app header - used by portal in AppHeader */}
				{/* sticky header is raised but underneath top-navigation */}
				<div
					id="sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 997 }}
				/>
				{/* fullscreen raises sticky header above everything else */}
				<div
					id="fullscreen-sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 1009 }}
				/>

				{/* <BlogHeader /> */}

				{this.props.blogID && this.props.siteID && 
					<BlogEditor 
						key={this.props.blogID}
						siteID={this.props.siteID}
						blogID={this.props.blogID}
					/>
				}

				{/* back to top button - used by portal in AppHeader */}
				<div
					id="sticky-top-button"
					style={{
						zIndex: 998,
						position: 'sticky',
						bottom: 60,
						marginRight: 16,
						height: 0,
						textAlign: 'right',
						pointerEvents: 'none'
					}}
				/>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(BlogContainer);