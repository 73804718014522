import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ItemContent, Icon } from 'svift-ui';
import { connect } from 'react-redux';
import actions from './redux/actions';

import asyncAnchor from 'svift-library/client/routing/asyncAnchor';

const mapStateToProps = state => {
	return {
		sections: state.sections.sections,
		currentSection: state.sections.currentSection,
		selectSection: state.sections.selectSection
	};
};

const mapDispatchToProps = dispatch => {
	return {
		selectSection: (section) => dispatch(actions.selectSection(section))
	};
};

class SectionMenu extends PureComponent {
	render() {
		return (
			<Menu vertical fluid>
				{this.props.sections.map((section, index) => {
					const className = section.name === this.props.currentSection ? 'active-item' : '';

					return (
						<MenuItem key={index} link className={className} onClick={() => {
							window.location = `#${section.anchor}`; /*this.props.selectSection(section)*/

							asyncAnchor();
						}}>
							<ItemContent>
								<Icon name={section.icon} /> {section.name}
							</ItemContent>
						</MenuItem>
					);
				})}
			</Menu>
		);
	}
}

SectionMenu.propTypes = {
	menuIcon: PropTypes.string
};

SectionMenu.defaultProps = {
	menuIcon: 'cube'
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionMenu);