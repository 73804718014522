import React, { PureComponent } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		translations: state.system.translations,
		defaultLocale: state.system.defaultLocale,
		locale: state.system.locale
	};
};

const TranslateHOC = Component => {
	const clazz = class Translate extends PureComponent {
		render() {
			const { locale, translations, defaultLocale } = this.props;
			const messages = translations[locale] || translations[defaultLocale];

			return (
				<IntlProvider
					locale={locale}
					messages={messages}
				>
					<Component {...this.props} />
				</IntlProvider>
			);
		}
	};

	return connect(mapStateToProps)(clazz);
};

export default TranslateHOC;
/*
class Translate extends PureComponent {
	createMessages(locale) {
		const translations = this.props.translations[this.props.translateKey];

		const messages = Object.keys(translations).reduce((messageDescriptors, key) => {
			const message = {
				id: `${this.props.translateKey}.${key}`,
				defaultMessage: translations[key][locale] || `No translation supplied for locale '${locale}'`,
				description: translations[key].description
			};

			messageDescriptors[`${this.props.translateKey}.${key}`] = message;

			return messageDescriptors;
		}, {});

		return defineMessages(messages);
	}

	render() {
		const { locale, translations, defaultLocale } = this.props;
		const messages = translations[locale] || translations[defaultLocale];

		return (
			<IntlProvider
				locale={locale}
				messages={messages}
			>
				{this.props.children}
			</IntlProvider>
		);
	}
}

export default connect(mapStateToProps)(Translate);*/