import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	pages: {},
	params: {
		pageid: null
	}
});

const defaultState = getDefaultState();

const pagesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_PAGES: {
			return {
				...state,
				pages: {
					...state.pages,
					...(action.data || {})
				}
			};
		}

		case constants.CREATE_PAGE: {
			const { page } = action.data;

			return {
				...state,
				pages: {
					...state.pages,
					[page._id]: {
						...page,
						content: page._id
					}
				}
			};
		}

		case constants.UPDATE_PAGE: {
			const { page } = action.data;

			return {
				...state,
				pages: {
					...state.pages,
					[page._id]: {
						...action.page
					}
				}
			};
		}

		case constants.DELETE_PAGE: {
			const newState = {
				...state,
				pages: {
					...state.pages
				}
			};

			delete newState.pages[action.data.pageid];

			return newState;
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/pages/:pageid?') || { pageid: null };

			let stagedPage = state.stagedPage;

			if (params.pageid !== state.params.pageid) {
				stagedPage = null;
			}

			return {
				...state,
				stagedPage,
				stagedFrom: stagedPage,
				params: {
					pageid: params.pageid
				}
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default pagesReducer;
