import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

// Not sure if we are communicating ideal units to the user; revisit later
export default injectIntl(props => {
    // const { intl, value, preset, computedPreset, computedStyles, onChange } = props;
    // const defaultValue = props.preset.lineHeight;
    // const valueIsNumber = !isNaN(props.value);
    // const valueIsNotDefault = valueIsNumber && props.value !== defaultValue;

    let text = props.intl.formatMessage({ id: 'options.line height title' });

    // if (valueIsNotDefault) {
    //     text += `: ${props.computedStyles.lineHeight}`;
    // } else {
    //     text += `: ${props.computedPreset.lineHeight}`;
    // }
    
    return (
        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>
            <Label
                collapsed
                labelIcon="sn-text-height"
                label={text}
                fontSize={12}
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <NumberPicker 
                    disableUndoRedo
                    name={text}
                    unit="%"
                    min={10}
                    max={500}
                    tooltipPosition="right center"
                    // placeholder={Math.floor(defaultValue * 100)}
                    value={Math.round(props.inlineStyling * 100)}
                    onChange={newValue => props.onChange(typeof newValue !== 'undefined' ? newValue / 100 : undefined)}					
                />
            </Label>
        </div>
    );
});