import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import { withRouter } from 'react-router';

class SmartText extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { blogs, siteName, pages, users, extend, theme, content, frontPage } = props.context;

		// cono

		return shallowCompare({
			blogs,
			siteName,
			frontPage,
			content,
			// users,
			// author: content.author,
			// contentTitle: content.title,
			// description: content.description,
			theme,
			pages,
			users,
			computeLink: extend.SmartText.computeLink,
			onNavigate: extend.SmartText.onNavigate,
			resolveBlogPublicationDate: extend.SmartText.resolveBlogPublicationDate,
			resolveBlogDescription: extend.SmartText.resolveBlogDescription,
			resolveBlogAuthor: extend.SmartText.resolveBlogAuthor,
			resolveBlogTitle: extend.SmartText.resolveBlogTitle,
			resolvePageTitle: extend.SmartText.resolvePageTitle
		}, state);
	}

	constructor(props) {
		super(props);

		this.state = {
			srcNotFound: false
		};

		this.getText = this.getText.bind(this);
		// this.onClick = this.onClick.bind(this);
	}

	getText() {
		const { node, siteName } = this.props;

		// console.log(this.props, 'proppas smarty texty');

		switch (node.props.type) {
			case 'BlogPublicationDate': {
				return this.props.resolveBlogPublicationDate(this.props);
			}

			case 'BlogDescription': {
				return this.props.resolveBlogDescription(this.props);
			}

			case 'BlogAuthor': {
				return this.props.resolveBlogAuthor(this.props);
			}

			case 'BlogTitle': {
				return this.props.resolveBlogTitle(this.props);
			}

			case 'SiteTitle':
				return siteName;

			case 'PageTitle': {
				return this.props.resolvePageTitle(this.props);
			}

			default:
				return null;
		}
	}

	render() {
		// console.log(this.props, 'huh?')

		// return 'smart text goes here';

		const { node } = this.props;

		const text = this.getText();

		// console.log(this.props, 'props')

		const style = {
			...this.props.computedStyling,
			display: 'flex'
		};

		if (node.props.type === 'SiteTitle') {
			style.cursor = 'pointer';
		}

		if (node.props.bold) {
			style.fontWeight = 'bold';
		}

		if (node.props.italic) {
			style.fontStyle = 'italic';
		}

		if (node.props.strikethrough) {
			style.textDecoration = 'line-through';
		}

		style.justifyContent = node.props.alignment || 'flex-start';

		style.textAlign = (() => {
			switch (node.props.alignment) {
				case 'flex-start':
					return 'left';
				case 'flex-end':
					return 'right';
				
				case 'center':
				default: {
					return 'center';
				}
			}
		})();

		const computedLink = this.props.node.props.type === 'SiteTitle' && this.props.computeLink && this.props.computeLink(this.props);

		if (computedLink) {
			return (
				<a
					{...computedLink.anchorAttributes}
					{...this.props.cropProps}
					style={style}
					title={computedLink.title}
					href={computedLink.url}
					onClick={e => {
						if (event.ctrlKey || event.metaKey || event.shiftKey) {
							return;
						}

						e.preventDefault();

						this.props.onNavigate(computedLink);
					}}
				>
					{node.props.underline 
						?
							<span style={{ textDecoration: 'underline' }}>
								{text}
							</span>
						:
							text
					}
				</a>
			);
		}

		return (
			<div
				{...this.props.cropProps}
				style={style}
				// onClick={() => this.onClick(computedLink)}
			>
				{node.props.underline 
					?
						<span style={{ textDecoration: 'underline' }}>
							{text}
						</span>
					:
						text
				}
			</div>
		);
	}
}

export default withRouter(SmartText);

