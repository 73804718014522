import React, { useCallback, useRef, useMemo } from 'react';
import { Icon } from 'svift-ui';
import css from './resize.scss';
// import createStaggerUpdates from '../staggerHistoryUpdates';
// import { useHistoryHelper } from '../historyHelper';
// import Tooltip, { useTooltip } from 'svift-library/client/tooltip';

const findClosestRow = target => {
    let node = target;
    let rowFound = false;

    while (!rowFound) {
        const isRow = node.attributes['data-type'] && node.attributes['data-type'].value === 'row';

        if (isRow) {
            rowFound = true;
        } else {
            node = node.parentNode;
        }
    }

    if (!rowFound) return null;

    return node;
}

export default props => {
    const resizing = useRef();
    const initialCursorX = useRef();
    const initialWidth = useRef();
    const rowWidth = useRef();
    const column = useRef();
    const resizeDirection = useRef();

    const suspend = useMemo(() => props.suspend({ 
        onChange: (shouldSkip, value) => {
            props.updateState(context => {
                return {
                    ...context,
                    nodeMap: {
                        ...context.nodeMap,
                        [column.current.id]: {
                            ...context.nodeMap[column.current.id],
                            width: value
                        }
                    }
                };
            }, shouldSkip ? 'skip' : 'horizontal resize');
        }}
    ), []);

    // const historyHelper = useHistoryHelper(
    //     props.getState, 
    //     context => context.nodeMap[column.current.id].width, 
    //     value => context => {
    //         return {
    //             ...context,
    //             nodeMap: {
    //                 ...context.nodeMap,
    //                 [column.current.id]: {
    //                     ...context.nodeMap[column.current.id],
    //                     width: value
    //                 }
    //             }
    //         };
    //     },
    //     'resize horizontal'
    // ); 

    // React Y U DO THIS - cant disable clicks 
    // const onClick = useCallback(e => {
    //     e.stopImmediatePropagation();
    //     e.stopPropagation();
    //     e.preventDefault();
        
    //     console.log(e.target, 'e target');
    //     console.log("selectable click")
    // }, []);

    const onMouseUp = useCallback(e => {
        // historyHelper.commit('Column resized');
        suspend.flush();

        setTimeout(() => window.RESIZING_COLUMN = false, 0); // onclick fires after mouseup, so we wait

        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', onMouseMove);
        // setTimeout(() => document.removeEventListener('click', onClick), 0);

        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const onMouseMove = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();

        // if (this.props.siteContainer) {
        //     this.smoothResize(e, initialWidth, cursorX);

        //     return;
        // }

        const columnWidth = column.current.width;
        const oneColumnWidth = rowWidth.current / 16;

        const deltaX = e.clientX - initialCursorX.current;

        const columnsMoved = Math.floor(deltaX / oneColumnWidth);

        let newColumnWidth = resizeDirection.current === 'right' ? (columnWidth + columnsMoved) : (columnWidth - columnsMoved);

        if (newColumnWidth < 1) {
            newColumnWidth = 1;
        }

        if (newColumnWidth > 16) {
            newColumnWidth = 16;
        }   

        suspend.onChange({
            currentValue: column.current.width,
            nextValue: newColumnWidth
        });

        // historyHelper({
        //     initialNodeMap: props.getState().nodeMap,
        //     initialValue: column.current.width,
        //     nextValue: newColumnWidth,
        //     // currentValue: column.current.width,
        //     // onChange: undoDescription => props.getState().updateState(context => {
        //     //     return {
        //     //         ...context,
        //     //         nodeMap: {
        //     //             ...context.nodeMap,
        //     //             [column.current.id]: {
        //     //                 ...context.nodeMap[column.current.id],
        //     //                 width: value
        //     //             }
        //     //         }
        //     //     };
        //     // }, undoDescription)
        //     onChange: undoDescription => /*props.getState().updateState(context => {
        //         return {
        //             ...context,
        //             nodeMap: {
        //                 ...context.nodeMap,
        //                 [column.current.id]: {
        //                     ...context.nodeMap[column.current.id],
        //                     width: value
        //                 }
        //             }
        //         };
        //     }, undoDescription)*/
        // });
    }, []);

    const onMouseDown = useCallback((e, direction) => {
        e.stopPropagation();
        e.preventDefault();

        resizing.current = true;
        window.RESIZING_COLUMN = true; // Revisit later?
        resizeDirection.current = direction;

        const parentRow = props.target && findClosestRow(props.target);

        if (!parentRow) {
            console.error('No parent row for selectable handle');
         
            return;
        }

        initialWidth.current = props.target.getBoundingClientRect().width;
        rowWidth.current = parentRow.getBoundingClientRect().width;
        initialCursorX.current = e.clientX;

        let nodeToUpdate;

        if (props.node.component === 'Column') {
            nodeToUpdate = props.node;
        } else {
            const path = props.getPath();

            const columnInstance = path[path.length - 2];

            nodeToUpdate = columnInstance.state.node;
        }

        column.current = nodeToUpdate;

        // historyHelper.start(); 

        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousemove', onMouseMove);
        // document.addEventListener('click', onClick);
        
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
            // document.removeEventListener('click', onClick);
        };
    }, [props.target]);

    const leftCallback = useCallback(e => onMouseDown(e, 'left'), [onMouseDown])
    const rightCallback = useCallback(e => onMouseDown(e, 'right'), [onMouseDown]) 

    if (props.device) {
        return null;
    }

    return (
        <React.Fragment>
            {/* left resize handle */}
            <Icon
                circular
                name="sn-arrow-resize3"
                onMouseDown={leftCallback}
                className={`${css['left-handle']} ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
                style={{ cursor: 'w-resize' }}
            />

            {/* right resize handle */}
            <Icon
                circular
                name="sn-arrow-resize3"
                onMouseDown={rightCallback}
                className={`${css['right-handle']} ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
                style={{ cursor: 'w-resize' }}
            />
        </React.Fragment>
    );
}