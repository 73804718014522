import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	Icon,
	Label,
	Popup,
	Button,
	Input,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Breadcrumb,
	BreadcrumbSection,
	BreadcrumbDivider,
	
} from 'svift-ui';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { StandaloneSlider } from 'components/option-panel/options/Slider';
import { connect } from 'react-redux';
import onClickOutside from 'components/hoc/onClickOutside';

import { injectIntl, FormattedMessage } from 'react-intl';

import fileActions from 'store/actions/sites/files';
import FilterMenu from 'components/filter-menu/FilterMenu';
import UploadField from 'containers/dashboard/files/__components/UploadField';
import ListAndThumb from 'components/app-header-buttons/ListAndThumb';

const mapStateToProps = state => {
	const siteName = state.sites.sites[state.sites.params.siteid].name;
	const folders = state.sites.files.folders;
	const activeFolderId = state.sites.files.activeFolder;
	const activeFolder = folders.filter(folder => folder._id === activeFolderId)[0];

	return {
		siteName,
		activeFolderId,
		activeFolder, 
		folders,
		view: state.sites.files.view,
		// filesObject: state.sites.files,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleView: view => dispatch(fileActions.toggleView(view)),
		setActiveFolder: id => dispatch(fileActions.setActiveFolder(id))
	};
};

const filterIcons = [
	{
		filter: file => file.meta.mimeType.startsWith('image'), 
		translationKey: 'general.file type images',
		icon: 'file image outline'
	}, 
	{
		filter: file => file.meta.mimeType.startsWith('video'), 
		translationKey: 'general.file type videos',
		icon: 'file video outline'
	}, 
	{	
		filter: file => file.meta.mimeType === 'application/pdf', 
		translationKey: 'general.file type pdfs',
		icon: 'file pdf outline'
	}, 
	{
		filter: file => [
			'application/msword', // .doc
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
		].includes(file.meta.mimeType), 
		translationKey: 'general.file type word',
		icon: 'file word outline'
	}, 
	{	
		filter: file => [
			'application/vnd.ms-excel', // .xls
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
		].includes(file.meta.mimeType), 
		translationKey: 'general.file type excel',
		icon: 'file excel outline'
	}, 
	{
		filter: file => [
			'application/vnd.ms-powerpoint', // .ppt
			'application/vnd.openxmlformats-officedocument.presentationml.presentation' // .pptx
		].includes(file.meta.mimeType), 
		translationKey: 'general.file type powerpoint',
		icon: 'file powerpoint outline'
	}, 
	{	
		filter: file => file.meta.mimeType.startsWith('text'), 
		translationKey: 'general.file type text',
		icon: 'file text outline'
	}, 
	{
		filter: file => file.meta.mimeType.startsWith('audio'), 
		translationKey: 'general.file type audio',
		icon: 'file audio outline'
	}
];

const dropDownArr = intl => [
	{
		"id": 'name',
		"text": intl.formatMessage({ id: 'name' }),
		"decreaseIcon": 'sort alphabet ascending',
		"increaseIcon": 'sort alphabet descending',
	}, 
	{
		"id": 'ext',
		"text": intl.formatMessage({ id: 'extension' }),
		"decreaseIcon": 'sort alphabet ascending',
		"increaseIcon": 'sort alphabet descending',
	}, 
	{	
		"id": 'size',
		"text": intl.formatMessage({ id: 'size' }),
		"decreaseIcon": 'sort numeric ascending',
		"increaseIcon": 'sort numeric descending',
	},
	{
		"id": 'lastUpdated',
		"text": intl.formatMessage({ id: 'last updated' }), 
		"decreaseIcon": 'sort content ascending',
		"increaseIcon": 'sort content descending',
	}
];

class FilesHeader extends PureComponent {
	constructor(props){
		super(props);
		this.state={
			sortListOpen: false,
			folderPathArray: []
		}
	}

	getSortIcon = (sort, sortDirection) => {
		if (sortDirection === 'ascending'){
			switch (sort) {
				case 'name':
					return 'sort alphabet ascending';
				case 'ext':
					return 'sort alphabet ascending';
				case 'size':
					return 'sort numeric ascending';
				case 'lastUpdated':
					return 'sort content ascending';
				default:
					return 'sort alphabet ascending';
			}
		} 

		switch (sort) {
			case 'name':
				return 'sort alphabet descending';
			case 'ext':
				return 'sort alphabet descending';
			case 'size':
				return 'sort numeric descending';
			case 'lastUpdated':
				return 'sort content descending';
			default:
				return 'sort alphabet descending';
		}
	}

	// generate styling for slider indicators (position = percentage)
	sliderIndicatorStyling(position) {
		const indicatorHeight = 4;
		const indicatorWidth = 2;
		let indicatorPosition = position;
		let negativeMargin = -(indicatorWidth/2);

		return {
			width: indicatorWidth,
			height: indicatorHeight,
			position: 'absolute',
			// top: indicatorHeight,
			bottom: 0,
			left: `${indicatorPosition}%`,
			marginLeft: negativeMargin,
			background: '#000',
			opacity: 0.2,
			content: ''
		};
	}

	onClickOutside(e) {
		this.setState({sortListOpen: false})
	}

	generateBreadcrumb(currentFolderId, allFolders, breadcrumb = []) {
		const currentFolder = allFolders.find(({ _id }) => _id === currentFolderId);

		if (!currentFolder) return;

		// console.log(allFolders, 'all folders');

		if (currentFolder.parent === "") {
			return (
				<Breadcrumb>
					{breadcrumb.map((renderBreadcrumbItem, i) => {
						const isLastItem = i === breadcrumb.length - 1;

						return renderBreadcrumbItem(isLastItem);
					})}
				</Breadcrumb>
			);
		}

		breadcrumb.unshift(isLastItem => (
			<React.Fragment key={currentFolderId}>
				<BreadcrumbDivider />

				<BreadcrumbSection
					link={!isLastItem}
					onClick={e => {
						this.props.setActiveFolder(currentFolderId);
	
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<Icon
						// last section should have an open icon
						name={isLastItem ? 'sn-folder-open2' : 'sn-folder6'}
						style={{ marginRight: 4, opacity: 0.4 }}
					/>
					{currentFolder.name}
				</BreadcrumbSection>

			</React.Fragment>
		));

		return this.generateBreadcrumb(currentFolder.parent, allFolders, breadcrumb);
	}

	render() {
		const {
			intl,
			view,
			searchTerm,
			thumbSliderValue,
			thumbnailHandleChange,
			fileListValue,
			fileListHandleChange,
			fileSize,
			sortKey,
			sortDirection,
			activeFolder, 
			activeFolderId,
		} = this.props;

		const sliderLineStyling = {
			position: 'absolute',
			background: 'transparent',
			borderBottom: '2px dashed #000',
			opacity: 0.05,
			height: 2,
			bottom: 8,
			left: -2,
			right: -2,
			content: ''
		};

		const activeItemClass="active-item"
		const activeInputClass = searchTerm ? "active-input" : "";
		
		const deleteButtonAction = searchTerm 
			?
				<Popup
					trigger={
						<Button primary icon onClick={this.props.deleteSearchTerm}>
							<Icon name="sn-cross2" />
						</Button>
					}
					content={intl.formatMessage({ id: 'general.clear search button popup' })}
					position="top right"
				/> 
			: null;

		return (
			<AppNavigation
				appIcon="sn-folder6"
				appTitle={this.props.intl.formatMessage({ id: 'files.app title' })}
			>
				<div className="left-panel">
					{/* site root icon */}
					{/* <Icon name="sn-sphere" className="shade-color" style={{ fontSize: 16, marginRight: 4, opacity: 0.4, verticalAlign: 'middle' }} />
					<span style={{ opacity: 0.6, verticalAlign: 'middle' }}>/</span> */}
					
					{/* TO DO: add icon with dropdown of parent folders */}
					{/* breadcrumb menu */}
					{/* <Icon link name="sn-menu" className="info-color" style={{ fontSize: 16, marginLeft: 4, marginRight: 4, verticalAlign: 'middle' }} />
					<span style={{ opacity: 0.6, verticalAlign: 'middle' }}>/</span> */}

					{/* active folder name */}
					<Icon name="sn-folder-open2" className="primary-color" style={{ fontSize: 16, fontWeight: 500, marginLeft: 4, marginRight: 6, verticalAlign: 'middle' }} />
					<span style={{ verticalAlign: 'middle' }}>
						{activeFolder &&
							[activeFolder.parent === '' ? this.props.siteName : activeFolder.name]
						}
					</span>
				</div>

				<div className="right-panel">
				{/* search + filter options */}
				<Input
					icon="search"
					iconPosition="left"
					size="small"
					action={deleteButtonAction}
					placeholder={intl.formatMessage({ id: 'files.search files placeholder' })}
					className={activeInputClass}
					type="text"
					value={searchTerm || ''}
					onChange={this.props.onChange}
					style={{ flexGrow: 1, maxWidth: 400, marginLeft: 'auto' }}
				/>
				<Popup
					trigger={
						<FilterMenu
							size="mini"
							icons={filterIcons}
							filter={this.props.fileFilter}
							setFilter={this.props.setFileFilter}
							style={{ marginLeft: 8, marginRight: 16 }}
						/>
					}
					content={intl.formatMessage({ id: 'files.filter files popup' })}
					position="bottom center"
				/>
					
				{/* list or thumbnail switch */}
				<ListAndThumb
					view={this.props.view}
					toggleView={this.props.toggleView}
					listPopup={intl.formatMessage({ id: 'files.list view popup' })}
					thumnbPopup={intl.formatMessage({ id: 'files.thumbnail view popup' })}
				/>

				<div style={{ position: 'relative', flexGrow: 1, minWidth: 40, maxWidth: 80, marginLeft: 16, marginRight: 16 }}>
					{view === 'list' ? 
						<React.Fragment>
							<div style={this.sliderIndicatorStyling(0)} />
							<div style={this.sliderIndicatorStyling(100)} />
							<div style={sliderLineStyling} />
							{/* <div style={{ fontSize: 14, textAlign: 'justify', display: 'inline-block' }}>
								<FormattedMessage id="files.list view size" /> <Label basic circular className="info" size="tiny"><FormattedMessage id={`general.size ${fileSize}`} /></Label>
							</div> */}
							<StandaloneSlider
								outsidePanel
								// icons={["sn-list"]}
								min={1}
								max={2}
								step={1}
								value={fileListValue}
								onChange={fileListHandleChange}
							/>
						</React.Fragment>
					:
						<React.Fragment>
							<div style={this.sliderIndicatorStyling(0)} />
							<div style={this.sliderIndicatorStyling(33.33)} />
							<div style={this.sliderIndicatorStyling(66.66)} />
							<div style={this.sliderIndicatorStyling(100)} />
							<div style={sliderLineStyling} />
							{/* <span style={{ fontSize: 14, textAlign: 'justify' }}>
								<FormattedMessage id="files.thumbnail view size" /> <Label basic circular className="info" size="tiny">{9-thumbSliderValue}</Label>
							</span> */}
							<StandaloneSlider
								outsidePanel
								// icons={["sn-view_module"]}
								min={3}
								max={6}
								step={1}
								value={thumbSliderValue}
								onChange={thumbnailHandleChange}
							/>
						</React.Fragment>
					}
				</div>
				
				{view === 'thumb' &&
					<Popup
						trigger= {  
							<div style={{ position: 'relative', marginLeft: 8, marginRight: 16 }}>
								<Dropdown 
									icon={this.state.sortListOpen ? 'angle up' : 'angle down'}
									text={ 
										<span style={{ fontSize: 12 }}>
											<FormattedMessage id="general.sorted by" /> {(() => {
												switch (sortKey) {
													case 'name':
														return intl.formatMessage({ id: 'files.sort name label' });
													case 'ext':
														return intl.formatMessage({ id: 'files.sort extension label' });
													case 'size':
														return intl.formatMessage({ id: 'files.sort size label' });
													case 'lastUpdated':
														return intl.formatMessage({ id: 'files.sort last updated label' });
												}
											})()}
											<Icon name={this.getSortIcon(sortKey, sortDirection)} size="large" style={{ margin: '0 0 0 5px'}}/>
										</span>
									} 
									onClick={() => this.setState({sortListOpen: !this.state.sortListOpen})}
									multiple 
								>
									<DropdownMenu style={{ right: 0 }}>
										{
											dropDownArr(intl).map(item => {
												return (
													<React.Fragment key={item.id}>
														<DropdownItem 
															icon={item.decreaseIcon} 
															text={<span><FormattedMessage id={`files.sort ${item.text} label`} /></span>} 
															onClick={() => this.props.setSorting({ sortKey: item.id, sortDirection: 'ascending' })}
															className={(sortKey === item.id && sortDirection === 'ascending') ? activeItemClass : null} 
														/>
														<DropdownItem 
															icon={item.increaseIcon} 
															text={<span><FormattedMessage id={`files.sort ${item.text} label`} /> (<FormattedMessage id="general.reverse" />)</span>}
															onClick={() => this.props.setSorting({ sortKey: item.id, sortDirection: 'descending' })}
															className={(sortKey === item.id && sortDirection === 'descending') ? activeItemClass : null} 
														/>
													</React.Fragment>
												);
											})
										}
									</DropdownMenu>
								</Dropdown>
							</div> 
						}
						content={intl.formatMessage({ id: 'files.sort menu popup' })}
						position="top center"
					/>
				}

				{/* upload files button */}
				<UploadField addBlobs={this.props.addBlobs}>
					<label
						className="ui button basic mini positive"
						htmlFor="uploadFile"
						style={{
							width: '100%',
							// margin: '4px 0 4px 0',
							// maxWidth: 240
						}}
					>
						<Icon name="upload" className="positive" />
						<FormattedMessage id="files.upload files" className="positive" />
					</label>
				</UploadField>
				</div>
			</AppNavigation>
		);
	}}


FilesHeader.propTypes = {
	dropDownArr: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(onClickOutside(FilesHeader)));
