import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import Text from 'components/option-library/primitives/input/text';
import Checkbox from 'components/option-library/primitives/checkbox';
import Dropdown from 'components/option-library/primitives/dropdown/simple';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
	}, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
					multipleStates
                    presets={props.theme.presets.checkbox}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

				<Label
                    label={intl.formatMessage({ id: 'forms.checkbox label title' })}
                    labelIcon="sn-typography"
                    // labelDescription="Something about checkbox labels"
                >
                    <Text
                        value={node.props.label}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('label', nextValue);
                        }}
                        placeholder={intl.formatMessage({ id: 'forms.checkbox label placeholder' })}
                    />
                </Label>
                
				<Label>
					<Checkbox
						icon="sn-warning"
						label={intl.formatMessage({ id: 'forms.required field label' })}
						value={node.props.required}
						onChange={val => onChange('required', val, 'toggle required', false)}
					/>
				</Label>
            </Section>
        </React.Fragment>
    );
}));


/*
					<Dropdown
						uncontrolled
						key="presets"
						option="preset"
						options={Object.keys(getEditorGlobals().theme.presets.checkbox || []).map(preset => {
							return {
								text: preset,
								value: preset
							};
						})}
						hoverContent={this.props.intl.formatMessage({ id: 'design.type' }) + ` (${computedProps.preset})`}
						onChange={preset => {
							const newNode = {
								...node,
								props: {
									...node.props,
									preset: preset.value
								},
							};

							getEditorGlobals().onChange({
								newNode,
								path
							});
						}}
						value={computedProps.preset}
					/>,
					<Popup
						key="form checkbox label input"
						trigger={
							<Input
								//autoFocus
								key="form checkbox label"
								icon="sn-flag6"
								iconPosition="left"
								placeholder={intl.formatMessage({ id: 'forms.checkbox label placeholder' })}
								value={node.props.label || ''}
								onChange={(e, data) => {
									const newNode = {
										...node,
										props: {
											...node.props,
											label: data.value
										}
									};

									getEditorGlobals().onChange({
										newNode,
										path
									});
								}}
								style={{ width: 160, fontSize: 12, marginRight: 16 }}
							/>
						}
						content={intl.formatMessage({ id: 'forms.checkbox label popup' })}
						position="top center"
					/>,
								<Checkbox
									checked={computedProps.required}
									label={
										<label style={{ fontSize: 12 }}>
											{intl.formatMessage({ id: 'general.required' })}
										</label>
									}
									onChange={() => {
										const newNode = {
											...node,
											props: {
												...node.props,
												required: !node.props.required
											}
										};

										getEditorGlobals().onChange({
											newNode,
											path
										});
									}}
								/>
*/