import Text from './Text';
import Image from './Image';
import IFrame from './IFrame';
import Video from './Video';
import Map from './Map';
import Button from './Button';
import Row from './Row';
import Section from './Section';
import Menu from './Menu';
import Column from './Column';
import Body from './Body';
import Container from './Container';
import Input from './form/Input';
import Checkbox from './form/Checkbox';
import Radio from './form/Radio';
import Divider from './divider';

export default {
	divider: Divider,
	menu: Menu,
	grid: Section,
	row: Row,
	text: Text,
	image: Image,
	button: Button,
	column: Column,
	body: Body,
	container: Container,
	input: Input,
	checkbox: Checkbox,
	radio: Radio,
	map: Map,
	iframe: IFrame,
	video: Video
};