import React, { memo, useCallback } from 'react'
import Checkbox from 'components/option-library/primitives/checkbox'
import Crop from '../../common/Crop'
import Accordion, {
    useAccordion
} from 'svift-library/client/accordion/Accordion'
import Label from 'components/option-library/label'
import Section from '../../Section'
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Preset from 'components/editor-v2/editor-interface/options/common/preset'
import Text from 'components/option-library/primitives/input/text'
import key from 'utils/key'
import { injectIntl } from 'react-intl'
import NumberPicker from 'components/option-library/primitives/input/number'
import BlogOptions from './blog/BlogOptions';
// import getTags from './blog/getTags';
// import { getAuthors, resolveAuthor } from './blog/getAuthors';

const ShowFeedSectionCheckboxes = injectIntl(props => {
    const nodeProps = props.node.props

    const onChange = (section, key, value, ...args) => {
        const newValue = {
            ...(nodeProps && nodeProps[section]),
            [key]: value
        }

        props.onChange(section, newValue, ...args)
    }

    /*
        {
            text: this.props.intl.formatMessage({ id: 'elements.post title title' }),
            value: 'postTitle'
        },
        {
            text: this.props.intl.formatMessage({ id: 'elements.post publication date title' }),
            value: 'postPublicationDate'
        },																				
        {
            text: this.props.intl.formatMessage({ id: 'elements.post thumbnail title' }),
            value: 'thumbnail'
        },
        {
            text: this.props.intl.formatMessage({ id: 'elements.post description title' }),
            value: 'postDescription'
        },
        {
            text: this.props.intl.formatMessage({ id: 'elements.post author title' }),
            value: 'postAuthor'
        }
    */
    return (
        <React.Fragment>
            <Checkbox
                label={props.intl.formatMessage({
                    id: 'blog.post title label'
                })}
                icon="sn-magazine"
                style={{ marginBottom: 4 }}
                value={key('postTitle.visible')(nodeProps)}
                onChange={checked =>
                    onChange(
                        'postTitle',
                        'visible',
                        checked,
                        'feed element visible',
                        false
                    )
                }
            />
            <Checkbox
                label={props.intl.formatMessage({
                    id: 'blog.post publication date label'
                })}
                icon="sn-calendar4"
                style={{ marginBottom: 4 }}
                value={key('postPublicationDate.visible')(nodeProps)}
                onChange={checked =>
                    onChange(
                        'postPublicationDate',
                        'visible',
                        checked,
                        'feed element visible',
                        false
                    )
                }
            />
            <Checkbox
                label={props.intl.formatMessage({
                    id: 'blog.post thumbnail label'
                })}
                icon="sn-image"
                style={{ marginBottom: 4 }}
                value={key('thumbnail.visible')(nodeProps)}
                onChange={checked =>
                    onChange(
                        'thumbnail',
                        'visible',
                        checked,
                        'feed element visible',
                        false
                    )
                }
            />
            <Checkbox
                label={props.intl.formatMessage({
                    id: 'blog.post description label'
                })}
                icon="sn-quotes-left"
                style={{ marginBottom: 4 }}
                value={key('postDescription.visible')(nodeProps)}
                onChange={checked =>
                    onChange(
                        'postDescription',
                        'visible',
                        checked,
                        'feed element visible',
                        false
                    )
                }
            />
            <Checkbox
                label={props.intl.formatMessage({
                    id: 'blog.post author label'
                })}
                icon="sn-user5"
                value={key('postAuthor.visible')(nodeProps)}
                onChange={checked =>
                    onChange(
                        'postAuthor',
                        'visible',
                        checked,
                        'feed element visible',
                        false
                    )
                }
            />
        </React.Fragment>
    )
})

export default injectIntl(
    memo(props => {
        const { editableNode: node, updateState, intl } = props;
        const [state, dispatch] = useAccordion();
        // const [tags, setTags] = useState(getTags(props.blogs));

        const onChange = useCallback((key, value, ...args) => {
            updateState(context => {
                return {
                    ...context,
                    nodeMap: {
                        ...context.nodeMap,
                        [node.id]: {
                            ...context.nodeMap[node.id],
                            props: {
                                ...context.nodeMap[node.id].props,
                                [key]: value
                            }
                        }
                    }
                }
            }, ...args)
        }, [])

        return (
            <React.Fragment>
                <Section>
                    <Label
                        label={intl.formatMessage({
                            id: 'elements.feed number of posts label'
                        })}
                        labelIcon="sn-magazine"
                        labelDescription={intl.formatMessage({
                            id: 'elements.feed number of posts description'
                        })}
                    >
                        <NumberPicker
                            disableUndoRedo
                            min={1}
                            max={12}
                            placeholder={intl.formatMessage({
                                id:
                                    'elements.feed number of posts input placeholder'
                            })}
                            onChange={value =>
                                onChange(
                                    'itemsPerPage',
                                    value,
                                    'feed items per page'
                                )
                            }
                            value={node.props.itemsPerPage}
                        />
                    </Label>

                    <Label
                        label={intl.formatMessage({
                            id: 'elements.feed read more link label'
                        })}
                        labelIcon="sn-link"
                        labelDescription={intl.formatMessage({
                            id: 'elements.feed read more link description'
                        })}
                    >
                        <Text
                            fluid
                            disableUndoRedo
                            value={node.props.readMoreText}
                            onChange={nextValue => {
                                onChange(
                                    'readMoreText',
                                    nextValue,
                                    'feed read more link text'
                                )
                            }}
                            placeholder={props.intl.formatMessage({
                                id:
                                    'elements.feed read more link input placeholder'
                            })}
                        />
                    </Label>
                    <Label
                        label={intl.formatMessage({
                            id: 'elements.feed visible elements label'
                        })}
                        labelIcon="sn-eye"
                        labelDescription={intl.formatMessage({
                            id: 'elements.feed visible elements description'
                        })}
                    >
                        <ShowFeedSectionCheckboxes
                            node={node}
                            onChange={onChange}
                        />

                        {/* <span style={{ color: 'red' }}>* display sub panels for each part of Feed (Type + styling override)</span> */}
                    </Label>

                    {/* Almost all existing options should be moved in here when we start adding new types to feed */}
                    <BlogOptions
                        {...props}
                        onChange={(...args) => onChange(...args)}
                    /> 
                </Section>

                <Accordion state={state} dispatch={dispatch}>
                    <Section
                        title={intl.formatMessage({
                            id: 'elements.post title title'
                        })}
                        icon="sn-magazine"
                    >
                        <Preset
                            presets={props.theme.presets.text}
                            onChange={(key, val) => {
                                const newPostTitle = {
                                    ...node.props.postTitle,
                                    [key]: val
                                }

                                onChange('postTitle', newPostTitle)
                            }}
                            excludePresets={[
                                'Link',
                                'Ordered list',
                                'Unordered list'
                            ]}
                            presetKey={
                                key('props.postTitle.preset')(node) || 'Normal'
                            }
                            theme={props.theme}
                            inlineStyling={key('props.postTitle.style')(node)}
                        />

                        {/* <Label
                        label="Type"
                        labelIcon="sn-magazine"
                        labelDescription="Something about Post title"
                    >
                        Type (WingedDropdown)
                    </Label>
                    <Accordion state={state} dispatch={dispatch}>
                        <Section
                            nested
                            title="Styling"
                            icon="sn-bucket"
                        >
                            Here will be preset options
                        </Section>
                    </Accordion> */}
                    </Section>

                    <Section
                        title={intl.formatMessage({
                            id: 'elements.post publication date title'
                        })}
                        icon="sn-calendar4"
                    >
                        <Preset
                            presets={props.theme.presets.text}
                            onChange={(key, val) => {
                                const newPostPublicationDate = {
                                    ...node.props.postPublicationDate,
                                    [key]: val
                                }

                                onChange(
                                    'postPublicationDate',
                                    newPostPublicationDate
                                )
                            }}
                            excludePresets={[
                                'Link',
                                'Ordered list',
                                'Unordered list'
                            ]}
                            presetKey={
                                key('props.postPublicationDate.preset')(node) ||
                                'Normal'
                            }
                            theme={props.theme}
                            inlineStyling={key(
                                'props.postPublicationDate.style'
                            )(node)}
                        />
                        {/* <Label
                        label="Type"
                        labelIcon="sn-magazine"
                        labelDescription="Something about this"
                    >
                        Type (WingedDropdown)
                    </Label>
                    <Accordion state={state} dispatch={dispatch}>
                        <Section
                            nested
                            title="Styling"
                            icon="sn-bucket"
                        >
                            Here will be preset options
                        </Section>
                    </Accordion> */}
                    </Section>

                    <Section
                        title={intl.formatMessage({
                            id: 'elements.post thumbnail title'
                        })}
                        icon="sn-image"
                    >
                        <Preset
                            presets={props.theme.presets.image}
                            onChange={(key, val) => {
                                const newThumbnail = {
                                    ...node.props.thumbnail,
                                    [key]: val
                                }

                                onChange('thumbnail', newThumbnail)
                            }}
                            presetKey={
                                key('props.thumbnail.preset')(node) || 'Normal'
                            }
                            theme={props.theme}
                            inlineStyling={key('props.thumbnail.style')(node)}
                        />
                        {/* <Label
                        label="Type"
                        labelIcon="sn-magazine"
                        labelDescription="Something about Post title"
                    >
                        Type (WingedDropdown)
                    </Label>
                    <Accordion state={state} dispatch={dispatch}>
                        <Section
                            nested
                            title="Styling"
                            icon="sn-bucket"
                        >
                            Here will be preset options
                        </Section>
                    </Accordion> */}
                    </Section>

                    <Section
                        title={intl.formatMessage({
                            id: 'elements.post description title'
                        })}
                        icon="sn-quotes-left"
                    >
                        <Preset
                            presets={props.theme.presets.text}
                            onChange={(key, val) => {
                                const newPostDescription = {
                                    ...node.props.postDescription,
                                    [key]: val
                                }

                                onChange('postDescription', newPostDescription)
                            }}
                            excludePresets={[
                                'Link',
                                'Ordered list',
                                'Unordered list'
                            ]}
                            presetKey={
                                key('props.postDescription.preset')(node) ||
                                'Normal'
                            }
                            theme={props.theme}
                            inlineStyling={key('props.postDescription.style')(
                                node
                            )}
                        />

                        {/* <Label
                        label="Type"
                        labelIcon="sn-magazine"
                        labelDescription="Something about this"
                    >
                        Type (WingedDropdown)
                    </Label>
                    <Accordion state={state} dispatch={dispatch}>
                        <Section
                            nested
                            title="Styling"
                            icon="sn-bucket"
                        >
                            Here will be preset options
                        </Section>
                    </Accordion> */}
                    </Section>

                    <Section
                        title={intl.formatMessage({
                            id: 'elements.post author title'
                        })}
                        icon="sn-user5"
                    >
                        <Preset
                            presets={props.theme.presets.text}
                            onChange={(key, val) => {
                                const newPostAuthor = {
                                    ...node.props.postAuthor,
                                    [key]: val
                                }

                                onChange('postAuthor', newPostAuthor)
                            }}
                            excludePresets={[
                                'Link',
                                'Ordered list',
                                'Unordered list'
                            ]}
                            presetKey={
                                key('props.postAuthor.preset')(node) || 'Normal'
                            }
                            theme={props.theme}
                            inlineStyling={key('props.postAuthor.style')(node)}
                        />
                        {/* <Label
                        label="Type"
                        labelIcon="sn-magazine"
                        labelDescription="Something about this"
                    >
                        Type (WingedDropdown)
                    </Label>
                    <Accordion state={state} dispatch={dispatch}>
                        <Section
                            nested
                            title="Styling"
                            icon="sn-bucket"
                        >
                            Here will be preset options
                        </Section>
                    </Accordion> */}
                    </Section>
                </Accordion>
            </React.Fragment>
        )
    })
)

/*
    props: {
        thumbnail: {
            preset: 'xyz',
            visible: true || false,
            style: {
                color: ...
            }
        },
        filter: null | {
            authors: [...]
        }
    }
*/

/*
		<Dropdown
			uncontrolled
			key="items"
			option="items"
			icon="sn-magazine"
			options={itemsPerPageDropdownOptions.concat({
				text: intl.formatMessage({ id: 'options.unlimited feed items' }),
				value: 'unlimited'
			})}
			content={intl.formatMessage({ id: 'options.feed items per page' })}
			value={computedProps.itemsPerPage}
			onChange={itemsPerPage => {
				const newNode = {
					...node,
					props: {
						...node.props,
						itemsPerPage: itemsPerPage.value
					},
				};

				getEditorGlobals().onChange({
					newNode,
					path
				});
			}}
		/>,
		<Input
			key="feed read more text"
			icon="sn-link"
			iconPosition="left"
			placeholder={intl.formatMessage({ id: 'options.feed read more link placeholder' })}
			value={node.props.readMoreText || ''}
			onChange={(e, data) => {
				const newNode = {
					...node,
					props: {
						...node.props,
						readMoreText: data.value
					}
				};

				getEditorGlobals().onChange({
					newNode,
					path
				});
			}}
			style={{ width: 160, fontSize: 12, marginRight: 16 }}
		/>
*/
