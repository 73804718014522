import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
// import { computePreset } from 'svift-library/client/renderer/utils/computePreset';from 'components/preset/util';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import PresetContainer from '../../PresetContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import { Icon, Menu } from 'svift-ui';
import Dropdown from 'components/option-panel/options/Dropdown';
import InfoIcon from 'components/InfoIcon';
import ColorPicker from 'components/option-panel/options/ColorPicker';

const TempInput = ({ title, value, onChange }) => {
    return (
        <div style={{ display: 'inline-block', marginLeft: 6 }}>
            {title}

            <input
                type="number"
                // className="input"
                style={{ maxWidth: 80, marginLeft: 4 }}
                onChange={e => onChange(e.target.value)}
                value={value}
            />
        </div>
	);
	}

const lineTypes = intl => [
	{
		text: `${intl.formatMessage({ id: 'options.border style solid title' })}`,
		value: 'solid'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style dashed title' })}`,
		value: 'dashed'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style dotted title' })}`,
		value: 'dotted'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style double title' })}`,
		value: 'double'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style groove title' })}`,
		value: 'groove'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style ridge title' })}`,
		value: 'ridge'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style inset title' })}`,
		value: 'inset'
	},
	{
		text: `${intl.formatMessage({ id: 'options.border style outset title' })}`,
		value: 'outset'
	}
];

class DividerLinePresets extends PureComponent {
	constructor(props) {
		super(props);

		this.getPresetOptions = (style) => {
			const { intl } = this.props;
			
			const { color, margin, lineType } = this.props.preset.line;

			return [
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="lineType"
						icon="sn-typography"
						options={lineTypes(intl)}
						onChange={(newType) => {
							this.onChange('lineType', newType.value);
						}}
						value={lineType || ''}
						option="lineType"
						selectedOption={this.props.selectedPresetOption}
						onToggle={(select) => {
							this.props.selectPresetOption(select);
						}}
					/>
				</Menu>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.divider line color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={this.props.preset.line}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						const value = this.props.preset.line.margin;

						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginLeft: 5,
									marginRight: 5
								});

								return;
							}

							this.onChange('margin', {
								generalMargin: newValue / 100
							});

							return;
						}

						const newMargin = {
							...value,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					// optIn={{
					// 	marginBottom: false,
					// 	marginTop: true,
					// 	marginLeft: true,
					// 	marginRight: true,
					// 	generalMargin: true
					// }}
				/>,
				<TempInput
					key="lineHeight"
					style={{ marginLeft: 8 }}
					title={`${this.props.intl.formatMessage({
						id: 'options.divider line height'
					})} (px)`}
					value={
						this.props.preset.line.height === 0
							? undefined
							: this.props.preset.line.height
					}
					onChange={val => {
						let nextVal = val;

						if (val === '' || val === 'undefined' /* wat? */) {
							nextVal = 0;
						}

						this.onChange('height', parseInt(nextVal));
					}}
				/>
			];
		}

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}
	
	getIdentifier() {
		return `dividerline${this.props.presetTitle}`; // Need a specific identifier because the selectable buttons are nested a layer deeper
	}

	onChange = (property, newValue) => {
		const newLinePreset = {
			...this.props.preset.line,
			[property]: newValue
		}
		this.props.updatePresetOption(this.props.presetTitle, 'line', newLinePreset);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			this.props.selectPreset(null);
		}
    }

	render() {
		const id = this.getIdentifier();
        const selected = id === this.props.selectedPreset;

		const computedStyles = {
			...computePreset(this.props.preset.line, this.props.theme),
			height: 0 + 'px'
		};

		const borderHeight = (this.props.preset.line.height || 1) + 'px';
        const borderType = this.props.preset.line.lineType || 'solid';
        computedStyles.borderTop = `${borderHeight} ${borderType} ${computedStyles.color}`;

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.section title"
					options={this.getPresetOptions(computedStyles)}
					open={selected}
					target={this.optionTooltipRef.current}
				/>

				<p className="preset-title">
					<FormattedMessage id="elements.hr title" />
				</p>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(id), 0)}
					ref={this.optionTooltipRef}
				>
                    <hr style={computedStyles} />
				</PresetContainer>
			</React.Fragment>
		);
	}
}

DividerLinePresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(DividerLinePresets));