import React, { PureComponent } from 'react';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import css from './divider.scss';
import key from 'svift-library/key';

class Divider extends PureComponent {
    static mapContextToProps = (props, state) => {
		const { theme } = props.context;
	
        return shallowCompare({
			theme,
        }, state);
    }

    getStyling = dividerElement => {
        const preset = this.props.theme.presets.divider[this.props.node.props.preset][dividerElement];
        const inlineStyling = key(`props.style.${dividerElement}`)(this.props.node);

        let styling = preset;

        if (inlineStyling) {
            styling = {
                ...styling,
                ...inlineStyling
            };
        }

        return this.props.computePreset(styling, this.props.theme);
    }

    renderLabel = () => {
        const hasIcon = this.props.node.props.showIcon && this.props.node.props.icon.name;
        const hasLabel = this.props.node.props.showLabel && this.props.node.props.label.text;

        if (!hasIcon && !hasLabel) return null;

        let order;

        switch(this.props.node.props.labelAlignment) {
            case 'left':
                order = -1;
                
                break;
            case 'right':
                order = 1;

                break;
            case 'center':
            default:
                order = 0;
        }

        return (
            <div className={css['label-container']} style={{ order }}>
                {hasIcon && <i style={this.getStyling('icon')} className={this.props.node.props.icon.name} />}
                {hasLabel && <span style={this.getStyling('label')}> {this.props.node.props.label.text} </span>}
            </div>
        );
    }

    renderLine = (direction, lineStyling) => {
        const preset = this.props.theme.presets.divider[this.props.node.props.preset].line;
        const inlineStyling = key(`props.style.line`)(this.props.node);

        // There's always two lines (even if it looks like there's only one), and we don't want their margins to the sides to create space between them
        // if (direction === 'left') lineStyling.marginRight = '0px';
        // if (direction === 'right') lineStyling.marginLeft = '0px';

        const borderHeight = ((inlineStyling && inlineStyling.height) || preset.height || 1) + 'px';
        const borderType = (inlineStyling && inlineStyling.lineType) || preset.lineType || 'solid';
        lineStyling.borderTop = `${borderHeight} ${borderType} ${lineStyling.color}`;

        return (
            <hr className={css['line']} style={lineStyling} />
        );
    }
    
    render() {
        const lineStyling = this.getStyling('line');
        const { margin, marginTop, marginRight, marginLeft, marginBottom, ...lineStylingNoMargin } = lineStyling;
        const containerMargins = {};

        if (marginTop) containerMargins.marginTop = marginTop;
        if (marginRight) containerMargins.marginRight = marginRight;
        if (marginBottom) containerMargins.marginBottom = marginBottom;
        if (marginLeft) containerMargins.marginLeft = marginLeft;
        if (margin) containerMargins.margin = margin;

        return (
            <div className={css['divider']} style={containerMargins}>
                {this.renderLine('left', lineStylingNoMargin)}
                {this.renderLabel()}
                {this.renderLine('right', lineStylingNoMargin)}
            </div>
        );
    }
}

export default Divider;