import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import fileActions from 'store/actions/sites/files';
import axios from 'axios';
import createApi from 'api';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Grid,
	Message,
	Button,
	Icon,
	GridColumn,
	Label,
	Loader,
	Divider
} from 'svift-ui';
import Modal from 'svift-library/client/modal';
import UploadField from 'containers/dashboard/files/__components/UploadField';
import DropUploadHOC from 'containers/dashboard/files/__components/DropUploadHOC';
import { getFileExtByMimeType } from 'utils/files/format.js';
import FileSettings from 'containers/dashboard/files/__components/FileSettings';
import SviftGA from 'utils/svift-ga';
const api = createApi();

const mapStateToProps = state => {
	return {
		siteid: state.sites.params.siteid,
		folderId: state.sites.files.activeFolder,
		filetypes: state.system.filetypes
	};
};

const mapDispatchToProps = dispatch => ({
	upload: (filenames, siteid) => dispatch(fileActions.upload(filenames, siteid)),
	ensureFolders: (siteid) => dispatch(fileActions.ensureFolders(siteid))
});

// Have to extract content because we can't get the DOM ref of a modal rendered in a portal - and we need it for DropUploadHOC to work
class Content extends PureComponent {
	render() {
		const { blobs, renderDropMessage, Intl } = this.props;
		
		return (
			<div ref={this.props.connectDropTarget}>
				<Grid centered celled verticalAlign="middle">
					{renderDropMessage()}
			
					{blobs.map((blob, index) => {
						const { blob: file, meta, url } = blob;

						return (
							<FileSettings 
								key={url} 
								fileName={file.name}
								credit={meta.credit}
								description={meta.description}
								name={meta.name}
								size={file.size}
								mimeType={file.type}
								url={url}
								onChange={e => {
									this.props.updateBlob({
										...blob,
										meta: {
											...blob.meta,
											[e.target.name]: e.target.value
										}
									}, index);
								}}
								onDelete={() => this.props.updateBlob(null, index)}
							/>
						);
					})}
				</Grid>

				{this.props.warningVisible &&
					<Message className="warning">
						<span style={{ display: 'block', marginBottom: 8 }}>
							<Icon name="sn-warning" /> <FormattedMessage id="files.upload validation message unsupported files description" />
						</span>
						<span style={{ fontSize: 10 }}>
							<span style={{ display: 'block', fontWeight: 'bold', marginBottom: 4 }}>
								<Icon name="sn-info2" /> <FormattedMessage id="files.upload validation message supported files label" />
							</span>
							{this.props.filetypes.join(', ')}
						</span>
					</Message>
				}

				<Message className="info" size="mini">
					<span><Icon name="sn-info" /> <span style={{ fontWeight: 'bold' }}><FormattedMessage id="general.obs label" /></span> <FormattedMessage id="files.upload public disclaimer message description" /></span>
				</Message>

				<Divider />
			</div>
		);
	}
}

const EnhancedContent = DropUploadHOC(Content);

class UploadModal extends PureComponent {
	constructor() {
		super();

		this.state = {};

		this.uploadBlobs = this.uploadBlobs.bind(this);
	}

	uploadBlob = async blob => {
		const { type } = blob.blob;
		
		try {
			const nameWithExtension = blob.meta.name + '.' + blob.blob.name.split('.').pop();

			const uploadUrl = await api.files.createResumableUploadURL(this.props.siteid, nameWithExtension, type, blob.blob.size); 

			const response = await axios.post(uploadUrl, blob.blob);

			const body = {
				folderId: this.props.folderId,
				file: {
					id: response.data.name,
					meta: {
						name: nameWithExtension || blob.blob.name,
						description: blob.meta.description,
						credit: blob.meta.credit,
						mimeType: type
					}
				}
			};

			if (type.startsWith('image')) {
				const urlCreator = window.URL || window.webkitURL;
				const url = urlCreator.createObjectURL(blob.blob);
				const img = new Image;
				
				const imageDescriptor = await new Promise(resolve => {
					img.onload = () => {
						resolve(img);
					};
					
					img.src = url;
				});

				const { width, height } = imageDescriptor;

				body.file.meta.dimensions = {
					width,
					height,
					aspect: `${(width/height).toFixed(3)}:1`
				}
			}
			
			await api.files.finalizeFileUpload(this.props.siteid, body);

			return {
				success: true,
				name: blob.meta.name || blob.blob.name,
				extension: getFileExtByMimeType(type)
			};

		} catch(e) {
			console.log(e, 'e');

			return {
				success: false,
				name: blob.blob.name
			};
		} finally {
			this.setState({
				uploading: {
					...this.state.uploading,
					filesCompleted: this.state.uploading.filesCompleted + 1,
				}
			});
		}
	}

	uploadBlobs() {
		this.setState({
			uploading: {
				filesCompleted: 0,
				uploadStatuses: [],
				filesTotal: this.props.blobs.length
			}
		}, () => {
			Promise.all(this.props.blobs.map(this.uploadBlob))
				.then(uploadStatuses => {
					SviftGA.FILES.CREATED();

					this.setState({
						uploading: {
							...this.state.uploading,
							uploadStatuses,
							uploadComplete: true
						}
					});
				});
		});
	}

	renderUploadStatus = () => {
		if (this.state.uploading.uploadComplete) {
			const errors = this.state.uploading.uploadStatuses.filter(status => !status.success);
			const successes = this.state.uploading.uploadStatuses.filter(status => status.success);
			
			console.log(successes, 'successes hello')

			const noErrors = errors.length === 0;

			return (
				<React.Fragment>
					{successes.length > 0 &&
						<Message className="positive">
							<div style={{ fontSize: 16, marginBottom: 12 }}>
								<Icon name="sn-cloud-upload" className="positive" /> {`${noErrors ? this.props.intl.formatMessage({ id: 'general.all label' }) : this.props.intl.formatMessage({ id: 'general.the following label' })} ${this.props.intl.formatMessage({ id: 'files.files uploaded message title' })}:`}
							</div>

							{successes.map(({ name, extension }) => {
								return <div key={name}><Label basic size="small" className="positive" style={{ marginRight: 0, marginBottom: 6 }}><Icon name="sn-file-check2" /> {name} ({extension})</Label></div>
							})}
						</Message>
					}

					{errors.map(({ name }, i) => {
						return (
							<Message key={i} className="negative">
								<div style={{ fontSize: 16, marginBottom: 12 }}>
									<Icon name="sn-cloud2" className="negative" /> <FormattedMessage id="files.upload error message title" />
								</div>
								<div>
									<Label basic size="small" className="negative" style={{ marginRight: 0, marginBottom: 6 }}>
										<Icon name="sn-file-minus2" /> {name}
									</Label>
								</div>
							</Message>
						);
					})}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Loader size="huge" style={{ fontSize: 32, marginTop: 24, marginBottom: 32 }}>
					<Icon name="cloud upload" className="primary" /> 
					<span style={{ display: 'block', marginBottom: 8 }}><FormattedMessage id="files.uploading files" /></span>
					<Label basic style={{ fontSize: 20 }} className="primary">
						<span style={{ fontWeight: 'bold' }}>{this.state.uploading.filesCompleted}</span> <FormattedMessage id="general.out of label" /> {this.state.uploading.filesTotal}
					</Label>
				</Loader>
			</React.Fragment>
		);
	}

	render() {
		const { blobs, clearBlobs, intl } = this.props;

		return (
			// <ModalWrapper
			// 	open={blobs.length > 0}
			// 	handleClose={async () => {
			// 		if (this.state.uploading && !this.state.uploading.uploadComplete) return;

			// 		if (this.state.uploading && this.state.uploading.uploadComplete) {
			// 			await this.props.ensureFolders(this.props.siteid);
			// 		}

			// 		clearBlobs();
			// 	}}
			// 	header="files.upload title"
			// >
			<Modal
				title={intl.formatMessage({ id: 'files.upload title' })}
				width={640}
				toggleModal={clearBlobs}
				nodeAttributes={{
					'data-ignore-click': true
				}}
			>
				{!this.state.uploading 
					? 
						<EnhancedContent {...this.props} />
					:
						// <ModalContent>
						this.renderUploadStatus()
						// </ModalContent>
				}

				<Grid centered verticalAlign="top">
					{this.state.uploading 
						?
							<GridColumn textAlign="center">
								<Button
									size="large"
									className="primary" 
									disabled={!this.state.uploading.uploadComplete} 
									onClick={async () => {
										await this.props.ensureFolders(this.props.siteid);

										clearBlobs();
									}}
								>
									<Icon name="sn-checkmark" style={{ marginRight: 8 }} />
									<FormattedMessage id="general.ok button" />
								</Button>
							</GridColumn>
						:
							<React.Fragment>
								<GridColumn computer={5} tablet={5} mobile={12} textAlign="center">
									<UploadField
										addBlobs={this.props.addBlobs}
									>
										<label className={`ui button basic fluid positive medium ${this.state.loading ? 'disabled' : ''}`}>
											<Icon name="plus" />
											<FormattedMessage id="files.upload more files" />
										</label>
									</UploadField>
								</GridColumn>

								<GridColumn computer={5} tablet={5} mobile={12} textAlign="center">
									<Button fluid size="large" className="positive" disabled={this.state.loading} onClick={this.uploadBlobs}>
										<Icon name="sn-cloud-upload" />
										<FormattedMessage id="files.save files" />
									</Button>
								</GridColumn>

								<GridColumn computer={5} tablet={5} mobile={12} textAlign="center">
									<Button fluid size="medium" basic disabled={this.state.loading} onClick={clearBlobs}>
										<Icon name="sn-bin" />
										<FormattedMessage id="files.cancel file upload" />
									</Button>
								</GridColumn>
							</React.Fragment>
					}
				</Grid>
			</Modal>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadModal));
// export default connect(mapStateToProps, mapDispatchToProps)(DropUploadHOC(injectIntl(UploadModal)));
