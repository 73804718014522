exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".building-blocks__building-block--2xvO7{display:inline-block;text-align:center;padding:16px;margin:4px;line-height:1;width:calc(50% - 8px);opacity:0.65;background-color:#f7f3f0;border-radius:8px;border:solid 2px #d8d4d0}.building-blocks__building-block--2xvO7 .building-blocks__building-block-icon--1ucW6{width:auto;height:32px;font-size:32px;margin:0}.building-blocks__building-block--2xvO7.building-blocks__big-block--3Rk3l{width:calc(100% - 8px)}.building-blocks__building-block--2xvO7.building-blocks__big-block--3Rk3l .building-blocks__template-image--1kElD{width:100%;height:auto}.building-blocks__building-block--2xvO7:hover,.building-blocks__building-block--2xvO7.building-blocks__dragged--1Isb3{opacity:1;cursor:move}.building-blocks__building-block--2xvO7:hover .building-blocks__building-block-icon--1ucW6,.building-blocks__building-block--2xvO7.building-blocks__dragged--1Isb3 .building-blocks__building-block-icon--1ucW6{color:#443d38}.building-blocks__building-block--2xvO7:hover{background-color:#f2f8fd;border:solid 2px #4aa7e2;box-shadow:0 2px 8px 0 rgba(79,71,65,0.4)}.building-blocks__building-block--2xvO7.building-blocks__dragged--1Isb3{opacity:0.4;background:rgba(252,251,250,0.55);border:dashed 2px #5f4575}.building-blocks__building-block--2xvO7.building-blocks__disabled--y-RDg{cursor:not-allowed}.building-blocks__building-block--2xvO7.building-blocks__disabled--y-RDg,.building-blocks__building-block--2xvO7.building-blocks__disabled--y-RDg:hover{opacity:0.35;color:#4f4741;user-select:none;background-color:transparent;border:solid 2px #d8d4d0}.building-blocks__sticky-search-input--3_u10{padding:4px 8px;margin-top:-16px;margin-left:-16px;margin-right:-16px;margin-bottom:4px;width:calc(100% + 32px);position:sticky;top:-16px;z-index:2;background-color:#f7f3f0;box-shadow:inset 0 0 0 1px #d8d4d0}\n", ""]);

// exports
exports.locals = {
	"building-block": "building-blocks__building-block--2xvO7",
	"building-block-icon": "building-blocks__building-block-icon--1ucW6",
	"big-block": "building-blocks__big-block--3Rk3l",
	"template-image": "building-blocks__template-image--1kElD",
	"dragged": "building-blocks__dragged--1Isb3",
	"disabled": "building-blocks__disabled--y-RDg",
	"sticky-search-input": "building-blocks__sticky-search-input--3_u10"
};