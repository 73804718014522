import React from 'react';
import NotificationMessage from './NotificationMessage';
// import css from './notification.scss';

class NotificationList extends React.Component {
	render() {
		const { notifications } = this.props;

		return (
			<React.Fragment>
				{
					notifications.map(notification =>
						<NotificationMessage
							key={notification.id} 
							notification={notification} 
							onClick={this.props.onClick}
						/>
					)
				}
			</React.Fragment>
		);
	}
}

export default NotificationList;
