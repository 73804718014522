import React, { PureComponent } from 'react';
import {
	Form,
	Icon,
	Button,
	Message,
	Grid,
	GridColumn,
	Dimmer,
	Dropdown,
	Loader
} from 'svift-ui';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import InfoIcon from 'components/InfoIcon';
import OptionLabel from 'components/option-library/label';

import input from 'components/forms/__util/field-components/input';
import textarea from 'components/forms/__util/field-components/textarea';
import dropdown from 'components/forms/__util/field-components/dropdown';
import validate, { string, valid, emailRegEx } from 'components/forms/__util/validate';
import MultipleSelect from 'components/forms/__util/field-components/multipleSelect';

import { connect } from 'react-redux';

const fields = {
	title: {
		component: input({
			type: 'text',
		}),
		validate: string(1, 170)
	},
	contacts: {
		component: MultipleSelect,
		validate: value => {
			if (Array.isArray(value)) {
				const validEntries = value.every(val => emailRegEx.test(val));

				if (!validEntries) {
					return 'forms.invalid email format';
				}
			}

			//return 'forms.contacts required';
		}
	},
	/*
		Message to be displayed on top of form overlay on form submit (prevents multiple submits/notifies user that the form has been submitted)
	*/
	afterSubmitMessage: {
		component: textarea({
			placeholder: 'forms.form submit message placeholder',
			name: 'forms.form submit message label',
			icon: 'sn-checkmark',
			type: 'text',
		}),
		validate: string(1)
	}
};

const mapStateToProps = () => ({});

/*
	FormForm...................

	:-)
*/
class FormForm extends PureComponent {
	constructor() {
		super();

		this.state = {
			confirmDelete: false
		};

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validate(fields)(values);

		await this.props.onSubmit(values);
	}

	renderButtons() {
		const {
			update,
			handleSubmit,
			intl
		} = this.props;

		if (!update) {
			return (
				<Grid>
					<GridColumn mobile={8} textAlign="left">
						<Button
							positive
							onClick={handleSubmit(this.submit)}
						>
							<Icon name="plus" style={{ marginRight: 8 }} /> <FormattedMessage id="forms.create new form" />
						</Button>
					</GridColumn>
					<GridColumn mobile={8} textAlign="right">
						<Button
							basic
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<Icon name="sn-cross2" style={{ marginRight: 8 }} /> <FormattedMessage id="general.cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle">
				<GridColumn mobile={16} computer={5} textAlign="left">
					<Button
						positive
						onClick={handleSubmit(this.submit)}
					>
						<Icon name="sn-cloud-upload" /> <FormattedMessage id="settings.save changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={6} textAlign="center">
					<Button
						basic
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
					>
						<Icon name="sn-cross2" /> <FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={5} textAlign="right">
					<Button
						basic
						negative
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'forms.delete form alert title' })}
							text={intl.formatMessage({ id: 'forms.delete form alert description' })}
							confirmTitle={intl.formatMessage({ id: 'forms.delete form alert confirm' })}
							confirmIcon="trash"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						
						<Icon name="sn-bin" /> <FormattedMessage id="forms.delete form" /> <Icon name="send outline" style={{ marginLeft: 4 }} />
					</Button>
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const {
			intl,
			update
		} = this.props;

		return (
			<Form>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}><Icon name="send outline" /> <FormattedMessage id="general.saving changes" /></Loader>
					</Dimmer>
				}

				<OptionLabel
					label={intl.formatMessage({ id: 'forms.form title label' })}
					labelIcon="send outline"
				>
					<Field
						fluid
						name="title"
						size="big"
						placeholder={intl.formatMessage({ id: 'forms.form title placeholder' })}
						intl={intl}
						component={fields.title.component}
					/>
				</OptionLabel>
				
				<OptionLabel
					label={intl.formatMessage({ id: 'forms.form mail label' })}
					labelDescription={intl.formatMessage({ id: 'forms.form mail description' })}
					labelIcon="sn-user3"
				>
					<Field
						component={fields.contacts.component}
						name="contacts"
						intl={intl}
						// labelText={intl.formatMessage({ id: 'forms.form mail label' })}
						// labelDescription={intl.formatMessage({ id: 'forms.form mail description' })}
						placeholder={intl.formatMessage({ id: 'forms.form mail placeholder' })}
						noResultsMessage={intl.formatMessage({ id: 'forms.no form mail message' })}
					/>
				</OptionLabel>
				
				<OptionLabel
					label={intl.formatMessage({ id: 'forms.form submit message label' })}
					labelDescription={intl.formatMessage({ id: 'forms.form submit message description' })}
					labelIcon="sn-checkmark"
				>
					<Field
						name="afterSubmitMessage"
						intl={intl}
						// infoIcon={intl.formatMessage({ id: 'forms.form submit message description' })}
						component={fields.afterSubmitMessage.component}
					/>
				</OptionLabel>

				{this.props.error &&
					<Message negative>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				{this.renderButtons()}
			</Form>
		);
	}
}

const ReduxFormForm = reduxForm({
	form: 'form'
})(injectIntl(FormForm));

ReduxFormForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

ReduxFormForm.defaultProps = {
	initialValues: {

	}
};

export default connect(mapStateToProps)(ReduxFormForm);
