import React, { PureComponent } from 'react';
import {
	Icon,
	Header,
	Message,
	Label,
	Button,
	Divider
} from 'svift-ui';
import { setRoute } from 'utils/routing';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

const mapStateToProps = state => {
	return {
		siteid: state.sites.params.siteid
	};
};

class CreateSiteComplete extends PureComponent {
	render() {
		return (
			<div ref={ref => this.container = ref}>
				<Message className="positive" style={{ textAlign: "center", paddingTop: 32, paddingBottom: 40 }}>
					<Icon name="sn-flag5" className="info-color" style={{ fontSize: 80, marginBottom: 16, marginRight: 0, opacity: 0.6 }} />
					<Header as="h1" style={{ marginBottom: 16 }}>
						<FormattedMessage id="activation.order complete title" />
					</Header>
					<Label basic size="big" className="positive" style={{ marginBottom: 32 }}>
						<Icon name="send outline" /> <FormattedMessage id="activation.order complete receipt sent description" />
					</Label>

					<Divider horizontal><Icon circular name="sn-sphere" style={{ opacity: 0.6, fontSize: 32 }} /></Divider>

					<Header as="h2" style={{ marginBottom: 24 }}>
						<FormattedMessage id="activation.order complete site activated title" />
					</Header>
					<p style={{ marginBottom: 16, textAlign: 'left', maxWidth: 520, marginLeft: 'auto', marginRight: 'auto' }}>
						<FormattedMessage id="activation.order complete description" /> *
					</p>
					<p style={{ marginBottom: 32, textAlign: 'left', maxWidth: 520, marginLeft: 'auto', marginRight: 'auto' }}>
						<FormattedMessage id="activation.order complete continue message" />
					</p>
					<Button
						onClick={() => {
							window.location.href = `/dashboard/${this.props.siteid}`
							// setTimeout(() => location.reload(true), 0) // XD
						} /*setRoute(`/dashboard/${this.props.siteid}`)*/}
						primary
						size="massive"
						style={{ marginBottom: 24 }}
					>
						<Icon name="sn-logo" style={{ fontSize: 24, float: 'left' }} /> <FormattedMessage id="activation.order complete go to dashboard" />
					</Button>
					<p className="small-text" style={{ textAlign: 'left', fontSize: 14, maxWidth: 520, marginBottom: 24, marginLeft: 'auto', marginRight: 'auto' }}>
						* <FormattedMessage id="activation.order complete receipt disclaimer" />
					</p>
				</Message>
			</div>
		);
	}
}


export default injectIntl(connect(mapStateToProps)(CreateSiteComplete));
