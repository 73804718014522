import React, { PureComponent } from 'react';
import { Popup } from 'svift-ui';
import { injectIntl } from 'react-intl';

class Upload extends PureComponent {
	constructor() {
		super();

		this.addNewBlobs = this.addNewBlobs.bind(this);
	}

	addNewBlobs() {
		this.props.addBlobs(this.filesInput.files);

		this.filesInput.value = ''; // Gotta clear the value or it won't respond to onChange when uploading the same file as before
	}

	render() {
		return (
			<span>
				<input
					hidden
					ref={ref => this.filesInput = ref}
					name="uploadFile"
					id="uploadFile"
					type="file"
					multiple
					onChange={this.addNewBlobs}
				/>

				<Popup
					trigger={
						React.cloneElement(this.props.children, {
							htmlFor: 'uploadFile'
						})
						// <label className="ui tiny basic positive button" htmlFor="uploadFile"><Icon name="upload" />
						// 	<FormattedMessage id="files.upload files" />
						// </label>
					}
					content={this.props.intl.formatMessage({ id: 'files.upload files popup' })}
					position="bottom right"
				/>
			</span>
		);
	}
}

export default injectIntl(Upload);
