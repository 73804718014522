import React, { memo } from 'react';
// import css from './crop.scss';

const CroppedComponent = (props) => {
    const { node } = props;
    const crop = node.props.crop || {};
    
    const heightType = crop.heightMode;
    // const contentHeight = props.contentHeight;
    const cropHeight = isNaN(props.cropHeight) ? crop.height : props.cropHeight;

    const cropStyling = { 
        display: 'flex',
        // justifyContent: crop.verticalAlign || 'center',
        overflowY: 'auto',
        overflowX: 'none', // To prevent selectable borders creating width and forcing scroll, looks weird
        // [heightType]: cropHeight,
    };

    if (heightType === 'minHeight') {
        cropStyling[heightType] = cropHeight;
    } else if (heightType === 'maxHeight') {
        cropStyling[heightType] = cropHeight;
    } else {
        cropStyling.height = cropHeight;
    }

    // if (cropHeight && contentHeight) {
    //     // While editing in maxheight mode we can't use maxHeight directly or things get weird
    //     if (heightType === 'maxHeight') {
    //         heightType = 'height';
    //     }

    //     // const height = cropHeight || contentHeight; /*true //props.cutContentWhileEditing 
    //         ? 
    //             (cropHeight || contentHeight)
    //         :
    //             isNaN(cropHeight) ? contentHeight : Math.max(cropHeight, contentHeight)*/

    //     cropStyling[heightType] = `${height}px`;
    // } else {
    //     cropStyling[heightType] = `${cropHeight}px`;
    // }

    return (
        <props.Component 
            {...props}
            isCropped
            cropRef={props.cropContentRef}
            cropStyling={cropStyling}
            cropAlignment={crop.verticalAlign}
        />
    );
}

export default Component => {    
    const WrappedComponent = memo(props => {
        const crop = props.node.props.crop || {};

        if (crop.heightMode) return <CroppedComponent {...props} Component={Component} />;

        return <Component {...props} />;
    });

    WrappedComponent.mapContextToProps = Component.mapContextToProps;

    return WrappedComponent;
};

// import React, { memo } from 'react';
// import css from './crop.scss';

// const CroppedComponent = (props) => {
//     const { node } = props;
//     const crop = node.props.crop || {};
    
//     let heightType = crop.heightMode;
//     const contentHeight = props.contentHeight;
//     const cropHeight = isNaN(props.cropHeight) ? crop.height : props.cropHeight;

//     const { regularStyling, cropInnerStyling, cropOuterStyling } = props.computedStyling || {};
//     const innerStyling = { 
//         justifyContent: crop.verticalAlign || 'center',
//         overflow: 'hidden',
//         ...cropInnerStyling
//     };

//     if (cropHeight && contentHeight) {
//         // While editing in maxheight mode we can't use maxHeight directly or things get weird
//         if (heightType === 'maxHeight') {
//             heightType = 'height';
//         }

//         const height = props.cutContentWhileEditing 
//             ? 
//                 (cropHeight || contentHeight)
//             :
//                 isNaN(cropHeight) ? contentHeight : Math.max(cropHeight, contentHeight)
//             // ? Math.max(cropHeight, contentHeight) 
//             // :  //props.cutContentWhileEditing ? cropHeight : Math.min(cropHeight, contentHeight); 

//         innerStyling[heightType] = `${height}px`;
//     } else {
//         innerStyling[heightType] = `${cropHeight}px`;
//     }

//     if (crop.allowScroll && !props.cropTargetRef) cropInnerStyling.overflowY = 'scroll';

//     return (
//         <div
//             data-type="crop-outer"
//             className={css['outer']}
//             ref={props.cropTargetRef}
//             style={cropOuterStyling}
//         >
//             <div
//                 data-type="crop-inner"
//                 className={css['inner']}
//                 style={innerStyling}
//             >
//                 <props.Component 
//                     {...props}
//                     isCropped
//                     ref={props.cropContentRef}
//                     // cropProps={{ ref: props.cropContentRef }}
//                     computedStyling={regularStyling}
//                 />
//             </div>
//         </div>
//     );
// }

// export default (Component, cutContentWhileEditing = true) => {    
//     const WrappedComponent = memo(props => {
//         const crop = props.node.props.crop || {};

//         // If a specific height mode is set (exact, min, max) we have to wrap the component in a few layers to facilitate alignment, scroll and so forth
//         if (crop.heightMode) return <CroppedComponent {...props} cutContentWhileEditing={cutContentWhileEditing} Component={Component} />;

//         // If the component isn't cropped we don't have to render any wrappers, speeding up parsing a bit for those SEO gainz
//         return (
//             <Component 
//                 {...props}
//                 computedStyling={props.computedStyling}
//             />  
//         );
//     });

//     WrappedComponent.mapContextToProps = Component.mapContextToProps;

//     return WrappedComponent;
// };

// import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

// class ElementWrapper extends PureComponent {
// 	getStyling = () => {
// 		const {
// 			horizontalAlign,
// 			verticalAlign,
// 			nodeProps,
// 			defaultAlignment,
// 			noCrop
// 		} = this.props;

// 		let justifyContent;
// 		let alignItems;

// 		switch (nodeProps && nodeProps.alignment) {
// 			case 'left':
// 				justifyContent = 'flex-start';

// 				break;
// 			case 'right':
// 				justifyContent = 'flex-end';

// 				break;
// 			case 'center':
// 			default:
// 				if (defaultAlignment && (nodeProps && !nodeProps.alignment)) {
// 					justifyContent = defaultAlignment;
// 				} else {
// 					justifyContent = 'center';
// 				}
// 		}

// 		if (nodeProps && nodeProps.selected && !nodeProps.selected.autoHeight && !isNaN(nodeProps.selected.height)) {
// 			switch (nodeProps.selected.verticalAlign) {
// 				case 'top':
// 					alignItems = 'flex-start';

// 					break;
// 				case 'bottom':
// 					alignItems = 'flex-end';

// 					break;
// 				case 'center':
// 				default:
// 					alignItems = 'center';
// 			}
// 		}

// 		const wrapperStyle = {
// 			verticalAlign,
// 			textAlign: horizontalAlign,
// 			position: 'relative',
// 			width: '100%',
// 		};

// 		const outerWrapperStyle = {
// 			width: '100%',
// 			height: '100%',
// 		};

// 		if (justifyContent && !this.props.disableHorizontalAlignment) {
// 			wrapperStyle.display = 'flex';

// 			wrapperStyle.justifyContent = justifyContent;
// 		}

// 		if (alignItems) {
// 			outerWrapperStyle.display = 'flex';

// 			outerWrapperStyle.alignItems = alignItems;
// 		}

// 		// if noCrop = no hidden overflow (relevant for forms)
// 		const cropStyle = noCrop ? null : { overflow: 'hidden' };

// 		return {
// 			cropStyle,
// 			wrapperStyle,
// 			outerWrapperStyle
// 		};
// 	}

// 	render() {
// 		const {
// 			style,
// 			minimized,
// 		} = this.props;

// 		const {
// 			outerWrapperStyle,
// 			cropStyle,
// 			wrapperStyle 
// 		} = this.getStyling();

// 		return (
// 			<div
// 				data-type="element-wrapper-outer"
// 				style={Object.assign(outerWrapperStyle, cropStyle)}
// 			>
// 				<div
// 					data-type="element-wrapper-inner"
// 					/* wtf is going on here - is there a reason cropstyle is only included if style is defined or is that an error? */
// 					style={style ? { ...wrapperStyle, ...cropStyle, ...style } : wrapperStyle}
// 					className={minimized ? 'minimize-element' : ''}
// 				>
// 					{this.props.children}
// 				</div>
// 			</div>
// 		);
// 	}
// }

// ElementWrapper.propTypes = {
// 	children: PropTypes.node,
// 	horizontalAlign: PropTypes.string,
// 	verticalAlign: PropTypes.string
// };

// ElementWrapper.defaultProps = {
// 	children: <p>No element...</p>,
// 	horizontalAlign: 'left',
// 	verticalAlign: 'top'
// };

// export default ElementWrapper;