import constants from 'store/constants';

// Subreducers
import layoutReducer from './layouts';
import contentReducer from './content';
import pagesReducer from './pages';
import themesReducer from './themes';
import filesReducer from './files';
import blogsReducer from './blogs';
import navigationReducer from './navigation';
import formsReducer from './forms';

import extractParams from 'utils/routing/extractParams';
// import layoutsReducer from './layouts/reducer';

const getDefaultState = () => ({
	siteList: [], // list of site ids
	sites: {},
	content: contentReducer(undefined, { type: null }),
	navigation: navigationReducer(undefined, { type: null }),
	layout: layoutReducer(undefined, { type: null }),
	pages: pagesReducer(undefined, { type: null }),
	themes: themesReducer(undefined, { type: null }),
	files: filesReducer(undefined, { type: null }),
	forms: formsReducer(undefined, { type: null }),
	blogs: blogsReducer(undefined, { type: null }),
	params: {
		siteid: null
	}
});

const defaultState = getDefaultState();

const siteReducer = (state = defaultState, action) => {
	let newState;

	switch (action.type) {

		case constants.SET_SITES: {
			return {
				...state,
				siteList: action.data
			};
		}

		case constants.SET_SITE: {
			const { entities, result } = action.data;
			const { 
				sites, 
				pages, 
				content, 
				themes, 
				layouts, 
				files, 
				layoutContent, 
				navigation, 
				forms, 
				formContent, 
				blogs,
				blogContent
			} = entities;

			const newSite = Object.values(sites)[0];

			return {
				...state,
				siteList: state.siteList.map(entry => {
					if (entry._id === newSite._id) {
						return {
							...entry,
							name: newSite.name
						};
					}

					return entry;
				}),
				//index: result,
				layout: layoutReducer(state.layout, { type: constants.SET_LAYOUTS, data: { layouts, layoutContent } }),
				sites: sites || {},
				navigation: navigationReducer(state.navigation, { type: constants.SET_NAVIGATIONS, data: navigation || {} }),
				pages: pagesReducer(state.pages, { type: constants.SET_PAGES, data: pages }),
				content: contentReducer(state.content, { type: constants.SET_PAGES_CONTENT, data: content }),
				themes: themesReducer(state.themes, { type: constants.SET_THEMES, data: themes }),
				files: filesReducer(state.files, { type: constants.SET_FILES, data: files }),
				forms: formsReducer(state.forms, { type: constants.SET_FORMS, data: { forms, formContent } || {} }),
				blogs: blogsReducer(state.blogs, { type: constants.SET_BLOGS, data: { blogs, blogContent } })
			};
		}

		case constants.DELETE_SITE: {
			const siteid = action.data;
			const siteIndex = state.siteList.findIndex(id => id === siteid);

			newState = {
				...state,
				siteList: [
					...state.siteList.slice(0, siteIndex),
					...state.siteList.slice(siteIndex + 1)
				],
			};

			return newState;
		}

		case constants.UPDATE_SITE: {
			newState = {
				...state,
				sites: {
					...state.sites,
					[action.data._id]: {
						...state.sites[action.data._id],
						...action.data
					}
				}
			};

			return newState;
		}

		/* Pages - may be wise to move into own reducer at some point */
		case constants.CREATE_PAGE: {
			const { page, siteid } = action.data;

			newState = {
				...state,
				content: contentReducer(state.content, action),
				pages: pagesReducer(state.pages, action),
				sites: {
					...state.sites,
					[siteid]: {
						...state.sites[siteid],
						pages: [
							...state.sites[siteid].pages,
							page._id
						]
					}
				}
			};

			return newState;
		}

		case constants.UPDATE_SITE_SETTIMGS:
			newState = {
				...state,
				sites: {
					...state.sites,
					[action.siteID]: {
						...state.sites[action.siteID],
						[action.key]: action.value,
					}
				}
			};

			return newState;

		case constants.FILTER_FILES:
			newState = {
				...state,
				bucket: action.data,
			};

			return newState;

		// BLOGS
		case constants.UPDATE_BLOG:
		case constants.TOGGLE_BLOG_CONTENT_VIEW: 
		case constants.UPDATE_BLOG_CONTENT:
		case constants.PUBLISH_BLOG_CONTENT:
		case constants.SAVE_BLOG_CONTENT:
			return {
				...state,
				blogs: blogsReducer(state.blogs, action)
			};
		
		case constants.DELETE_BLOG: {
			const { siteID, blogID } = action.data;

			return {
				...state,
				blogs: blogsReducer(state.blogs, action),
				sites: {
					...state.sites,
					[siteID]: {
						...state.sites[siteID],
						blogs: state.sites[siteID].blogs.filter(blog => blog !== blogID)
					}
				}
			};
		}

		case constants.CREATE_BLOG: {
			const { newBlog, siteID } = action.data;

			newState = {
				...state,
				blogs: blogsReducer(state.blogs, action),
				sites: {
					...state.sites,
					[siteID]: {
						...state.sites[siteID],
						blogs: [
							...state.sites[siteID].blogs || [],
							newBlog._id
						]
					}
				}
			};

			return newState;
		}

		// Navigation
		case constants.SET_NAVIGATIONS:
		case constants.UPDATE_NAVIGATION_CONTENT:
		case constants.STAGE_NAVIGATION:
		case constants.SAVE_NAVIGATION:
			return {
				...state,
				navigation: navigationReducer(state.navigation, action)
			};

		case constants.PUBLISH_FORM:
		case constants.SAVE_FORM:
		case constants.SET_FORM_EDIT_MODE:
		case constants.SET_FORMS:
		case constants.STAGE_FORM:
		case constants.UPDATE_FORM_CONTENT:
		case constants.TOGGLE_FORM_VIEW:
			return {
				...state,
				forms: formsReducer(state.forms, action)
			};

		// Layout
		case constants.PUBLISH_LAYOUT:
		case constants.SAVE_LAYOUT:
		case constants.SET_LAYOUT_EDIT_MODE:
		case constants.SET_LAYOUTS:
		case constants.STAGE_LAYOUT:
		case constants.UPDATE_LAYOUT_CONTENT:
		case constants.TOGGLE_LAYOUT_VIEW:
			return {
				...state,
				layout: layoutReducer(state.layout, action)
			};

		// FILES
		case constants.SET_BUCKET:
		case constants.SET_FILES:
		case constants.SET_FOLDERS:
			return {
				...state,
				sites: !action.data.files ? state.sites : { // Only update file references if files are provided
					...state.sites,
					[action.data.siteid]: {
						...state.sites[action.data.siteid],
						files: action.data.files.map(file => file._id)
					}
				},
				files: filesReducer(state.files, action)
			};		
		case constants.FILES_SET_ACTIVE_FOLDER:
		case constants.SET_FOLDER_HOVERED:
		case constants.TOGGLE_FILESITE_VIEW:
		case constants.TOGGLE_FOLDERS_LOADING:
			return {
				...state,
				files: filesReducer(state.files, action)
			};	

		// Content
		case constants.STAGE_CONTENT:
		case constants.UPDATE_PAGE_CONTENT:
		case constants.TOGGLE_CONTENT_VIEW:
		case constants.SAVE_CONTENT:
		case constants.PUBLISH_CONTENT:
			return {
				...state,
				content: contentReducer(state.content, action)
			};

		// Themes
		case constants.TOGGLE_THEME_VIEW:
		case constants.CLEAR_STAGED_THEME:
		case constants.UPDATE_THEME_SETTING:
		case constants.STAGE_THEME:
		case constants.SAVE_THEME:
		case constants.UPDATE_PRESET:
			return {
				...state,
				themes: themesReducer(state.themes, action)
			};

		case constants.DELETE_THEME: {
			const deletedTheme = state.themes.themes[action.data.themeID];
			const activeThemeId = state.sites[action.data.siteID].activeTheme;

			const forceNewActiveTheme = deletedTheme._id === activeThemeId;

			return {
				...state,
				sites: { 
					...state.sites,
					[action.data.siteID]: {
						...state.sites[action.data.siteID],
						themes: state.sites[action.data.siteID].themes.filter(theme => theme !== action.data.themeID),
						activeTheme: forceNewActiveTheme ? deletedTheme.origin : activeThemeId
					}
				},
				themes: themesReducer(state.themes, action)
			};
		}

		// MODULES
		case constants.SET_MODULES: {
			const { siteID, modules: newModules, defaultBlogLayout } = action.data;

			const newState = {
				...state,
				modulesLoading: false,
				sites: {
					...state.sites,
					[siteID]: {
						...state.sites[siteID],
						modules: {
							...state.sites[siteID].modules,
							...newModules
						}
					}
				},
				layout: layoutReducer(state.layout, action)
			};

			if (defaultBlogLayout) {
				newState.sites[siteID].layouts = [
					...newState.sites[siteID].layouts,
					defaultBlogLayout._id
				];
			}
			
			return newState;
		}

		case constants.SET_MODULES_LOADING: {
			return {
				...state,
				modulesLoading: action.data
			}	
		}

		case constants.PUBLISH_SYSTEM_THEME: {
			const { siteid, themeid } = action.data;

			return {
				...state,
				sites: {
					...state.sites,
					[siteid]: {
						...state.sites[siteid],
						activeTheme: themeid
					}
				}
			};
		}

		case constants.PUBLISH_THEME: {
			const { siteid, theme } = action.data;

			return {
				...state,
				sites: {
					...state.sites,
					[siteid]: {
						...state.sites[siteid],
						activeTheme: theme._id
					}
				},
				themes: themesReducer(state.themes, action)
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location } = action.payload;

			const pathname = location.pathname;

			const params = extractParams(pathname, '/dashboard/:siteid?') || { siteid: null };

			return {
				...state,
				params: {
					siteid: params.siteid
				},
				pages: pagesReducer(state.pages, action),
				layout: layoutReducer(state.layout, action),
				themes: themesReducer(state.themes, action),
				content: contentReducer(state.content, action),
				navigation: navigationReducer(state.navigation, action),
				files: filesReducer(state.files, action),
				forms: formsReducer(state.forms, action),
				blogs: blogsReducer(state.blogs, action)
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default siteReducer;
