import constants from '../constants';
import createApi from 'api';

const shouldClearDomainSearch = (prev, next) => {
	/* If free subscription -> paid subscription */
	if (prev === 0 && next !== 0) {
		return true;
	}

	/* If paid subscription -> free subscription */
	if (prev > 0 && next === 0) {
		return true;
	}

	return false;
};

export default {
	setStep: (step) => {
		return {
			type: constants.SET_WIZARD_STEP,
			data: step,
		};
	},

	setSubscriptionTypes: ({ siteID }) => async (dispatch) => {
		const api = createApi().activation;

		const plans = await api.getPlans({ siteID });

		dispatch({
			type: constants.SET_SUBSCRIPTION_TYPES,
			data: plans
		});
	},

	setSubscription: subscriptionID => (dispatch, getState) => {
		const state = getState();

		//const { selectedSubscription: currSubscriptionIndex } = state.wizard;

		/*if (shouldClearDomainSearch(currSubscriptionIndex, subscriptionID)) {
			dispatch({
				type: constants.CLEAR_DOMAIN_SEARCH_RESULTS,
			});
		}*/

		dispatch({
			type: constants.SET_SUBSCRIPTION,
			data: subscriptionID,
		});
	},

	toggleAgreeTerms: () => {
		return {
			type: constants.TOGGLE_AGREE_TERMS
		};
	},

	setCompanyInputText: (field, value) => {
		return {
			type: constants.SET_COMPANY_INPUT_TEXT,
			data: { field, value },
		};
	},

	/* Attempt to buy the domain */
	/*finalize: token => (dispatch, getState) => {
		const state = getState();
	},*/
};
