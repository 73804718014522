import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	navigations: {},
	params: {
		navid: null,
		siteid: null
	},
	stagedNavigation: null,
	stagedFrom: null
});

const defaultState = getDefaultState();

const navigationsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.CREATE_NAVIGATION: {
			const { newNavigation } = action.data;

			const newState = {
				...state,
				navigations: {
					...state.navigations,
					[newNavigation._id]: newNavigation
				}
			};

			return newState;
		}

		case constants.SET_NAVIGATIONS: {
			const navigations = action.data;

			const newState = {
				...state,
				navigations: {
					...state.navigations,
					...navigations
				}
			};

			return newState;
		}

		case constants.UPDATE_NAVIGATION_CONTENT: {
			const newNavigation = action.data;

			const newState = {
				...state,
				stagedNavigation: newNavigation
			};

			return newState;
		}

		case constants.STAGE_NAVIGATION: {
			return {
				...state,
				stagedFrom: action.data,
				stagedNavigation: action.data
			};
		}

		case constants.SAVE_NAVIGATION: {
			const { entries, navid } = action.data;

			return {
				...state,
				navigations: {
					...state.navigations,
					[navid]: {
						...state.navigations[navid],
						entries
					}
				},
				stagedFrom: entries,
				stagedNavigation: entries
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/design/navigation/:navid?') || { navid: null, siteid: null };

			let stagedNavigation = state.stagedNavigation;

			if (params.navid !== state.params.navid || params.siteid !== state.params.siteid) {
				stagedNavigation = null;
			}

			return {
				...state,
				stagedNavigation,
				stagedFrom: stagedNavigation,
				liveView: false,
				params: {
					navid: params.navid,
					siteid: params.siteid
				}
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default navigationsReducer;
