import constants from 'store/constants';

import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	themes: {},
	view: 'dashboard',
	stagedFrom: null,
	stagedTheme: null, // When a theme is selected, that theme will be referenced - if any changes are made to this reference, we can do an equality check with the originating theme; if they aren't the same, save is enabled.
	params: {
		themeid: null,
		siteid: null
	}
});

const defaultState = getDefaultState();

const themesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_THEMES: {
			return {
				...state,
				themes: {
					...state.themes,
					...(action.data || {})
				}
			};
		}

		case constants.TOGGLE_THEME_VIEW: {

			return {
				...state,
				view: action.data
			};
		}

		case constants.STAGE_THEME: {
			return {
				...state,
				stagedTheme: action.data,
				stagedFrom: action.data
			};
		}

		case constants.CLEAR_STAGED_THEME: {
			return {
				...state,
				stagedTheme: null,
				stagedFrom: null
			};
		}

		case constants.UPDATE_PRESET: {
			const { presetType, presetElement, presetElementOption, value } = action.data;

			const updatedPreset = {
				...state,
				stagedTheme: {
					...state.stagedTheme,
					presets: {
						...state.stagedTheme.presets,
						[presetType]: {
							...state.stagedTheme.presets[presetType],
							[presetElement]: {
								...state.stagedTheme.presets[presetType][presetElement],
								[presetElementOption]: value
							}
						}
					}
				}
			};

			if (value === null) {
				delete updatedPreset.stagedTheme.presets[presetType][presetElement][presetElementOption];
			}

			return updatedPreset;
		}

		case constants.UPDATE_THEME_SETTING: {
			const { outerKey, innerKey, value } = action.data;

			const updatedTheme = {
				...state,
				stagedTheme: {
					...state.stagedTheme,
					[outerKey]: {
						...state.stagedTheme[outerKey],
						[innerKey]: value
					}
				}
			};

			// If we change font, we clear all the existing font weight values for each preset who was using that font
			if (outerKey === 'fonts' && value.family !== state.stagedTheme.fonts[innerKey].family) {
				const presets = {
					...updatedTheme.stagedTheme.presets
				};

				Object.keys(presets).forEach(preset => {
					presets[preset] = {
						...presets[preset]
					};

					Object.keys(presets[preset]).forEach(presetKey => {
						presets[preset][presetKey] = {
							...presets[preset][presetKey]
						};

						if (presets[preset][presetKey].font === innerKey) {
							delete presets[preset][presetKey].fontWeight;
						}
					});
				});

				updatedTheme.stagedTheme.presets = presets;
			}

			return updatedTheme;
		}

		case constants.PUBLISH_THEME: {
			const { theme, themeid } = action.data;

			const newTheme = {
				...theme
			};

			return {
				...state,
				themes: {
					...state.themes,
					[themeid]: {
						...state.themes[themeid],
						draft: newTheme,
						live: newTheme
					}
				},
				stagedFrom: newTheme,
				stagedTheme: newTheme
			};
		}

		case constants.SAVE_THEME: {
			const { theme, themeid } = action.data;

			const newTheme = {
				...theme
			};

			return {
				...state,
				themes: {
					...state.themes,
					[themeid]: {
						...state.themes[themeid],
						draft: newTheme
					}
				},
				stagedFrom: newTheme,
				stagedTheme: newTheme
			};
		}

		case constants.DELETE_THEME: {
			const { themeID } = action.data;

			const themes = { ...state.themes };

			delete themes[themeID];

			return {
				...state,
				themes
			}
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/design/:themeid?') || { siteid: null, themeid: null };

			let stagedTheme = state.stagedTheme;

			// If either theme id or site id changes, we reset the staged theme, and expect the relevant routes to restage a theme
			if (params.themeid !== state.params.themeid || params.siteid !== state.params.siteid) {
				stagedTheme	= null;
			}

			return {
				...state,
				view: 'dashboard',
				stagedTheme,
				stagedFrom: state.stagedFrom || stagedTheme,
				params: {
					themeid: params.themeid,
					siteid: params.siteid
				}
			};
		}


		default:
			return state;
	}
};

export default themesReducer;
