import React, { PureComponent } from 'react';
import api from 'api';
import { setRoute } from 'utils/routing';
import CardPicker from 'components/stripe/CardPicker';
// import Stripe from 'components/stripe/Stripe';
import {
    Input,
    Icon,
    Loader,
    Dimmer,
    WizardControls,
    Box,
    FormCheckbox,
    Message,
    Table,
    Header,
    Grid,
    GridRow,
    GridColumn,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    HeaderContent,
    Label,
    HeaderSubheader,
    Button,
    Divider,
} from 'svift-ui';
import TermsAndConditions from 'components/TermsAndConditions';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import actions from 'store/actions/domainWizard';
import ReactGA from 'react-ga';

const stripe = Stripe(
    process.env.NODE_ENV === 'production' && !process.env.DEV_PRODUCTION
        ? 'pk_live_v0T32s3r3FJxgIMEggDSKcfo'
        : 'pk_test_he13Cq9EK7Oj8s1CG6rK8vqE'
);

const mapStateToProps = (state) => {
    return {
        siteid: state.sites.params.siteid,
        name: `${state.user.firstName} ${state.user.lastName}`,
        email: state.user.email,
        orgDetails: state.wizard.orgDetails,
        selectedSubscription: state.wizard.selectedSubscription,
        subscriptionTypes: state.wizard.subscriptionTypes,
        agreeTerms: state.wizard.agreeTerms,
        domain:
            (Object.keys(state.domainsearch.pickedDomain).length > 0 &&
                state.domainsearch.pickedDomain.name) ||
            'placeholder.dk',
        domains: state.system.domains.domainPlans,
        userDiscountPercentage:
            state.user.coupons && state.user.coupons.customerDiscount
                ? parseInt(
                      state.user.coupons.customerDiscount.split('-').pop(),
                      10
                  ) /* site-partner-30 => 30 */
                : 0,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAgreeTerms: () => dispatch(actions.toggleAgreeTerms()),
    };
};

class CreateSiteOrder extends PureComponent {
    constructor(props) {
        super(props);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        this.state = {
            date: new Date().toLocaleDateString('dk-DK', options),
            error: null,
        };
    }

    async finalizePayment(cardToken, cardID) {
        const {
            name,
            email,
            orgDetails,
            domain,
            selectedSubscription,
            siteid,
        } = this.props;

        const tld = domain.substring(domain.lastIndexOf('.'));
        const plan = this.props.domains.find(
            ({ name: planName }) => planName.indexOf(tld.toUpperCase()) === 0
        );

        // Create payment method / Do we already have that (card?)? :P Not sure
        // const result = await stripe
        // .createPaymentMethod({
        // 	type: 'card',
        // 	card: cardToken,
        // 	billing_details: {
        // 		name: 'testopesto',
        // 	},
        // })

        // Create payment intent, pass it to server
        // Create subscription on backend, pass invoice back
        // Invoice has paymentIntentSecret, pass it to confirmCardPayment, and Stripe will handle SCA for us

        /* Collecting all the data for the final step */
        const data = {
            siteID: siteid,
            token: cardToken,
            owner: name,
            email,
            orgDetails,
            domain,
            subscription: selectedSubscription,
            domainSubscription: plan.id,
            cardID,
        };

        const paymentIntentID = await api().activation.finalize(data);

        try {
            const response = await stripe.confirmCardPayment(paymentIntentID);

            if (response.error) throw response.error;
            
            return 'success';
        } catch (e) {
            throw e;
        }
    }

    async validate(onValidate) {
        if (!this.props.agreeTerms) {
            this.setState({
                error: 'activation.order validation message accept order',
            });

            return;
        }

        this.setState({
            loading: true,
        });

        try {
            let token;

            if (!this.card) {
                const response = await this.generateToken();

                if (response.error) {
                    response.error.stripe = true;

                    throw response.error;
                }

                token = response.token.id;
            }

            const status = await this.finalizePayment(token, this.card);

            if (status === 'success') {
                ReactGA.event({
                    category: 'Activation',
                    action: 'Completed',
                    label: `site_${this.props.siteid}`,
                });

                onValidate();
            } else {
                this.setState({
                    error:
                        'activation.order validation message subscription error',
                });
            }
        } catch (e) {
            console.log(e, 'caught validation error');

            if (e.criticalActivationError) {
                setRoute(
                    `/dashboard/${this.props.siteid}/activation/critical-error`
                );

                return;
            }

            if (e.type === 'ClientError') {
                this.setState({
                    error: e.intlReadable,
                });

                return;
            }

            if (e.stripe) {
                /*
					To-do: potentially add error handling for more cases

					https://stripe.com/docs/api#errors
				*/
                if (e.type === 'validation_error') {
                    this.setState({
                        error:
                            'activation.order validation message invalid credit card',
                    });
                } else {
                    this.setState({
                        error:
                            'activation.order validation message payment error',
                    });
                }

                return;
            }

            this.setState({
                error: 'activation.unknown error',
            });
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    renderLoader() {
        return (
            <Dimmer active inverted>
                <Loader size="massive" style={{ fontSize: 40 }}>
                    <FormattedMessage id="activation.order processing activation" />
                </Loader>
            </Dimmer>
        );
    }

    render() {
        const { domain: selectedDomain, domains, intl } = this.props;

        const plans = this.props.subscriptionTypes;

        const formattedPlans = plans.map((plan) => {
            const amount = plan.amount / 100;

            return {
                ...plan,
                perMonthCost: amount / 12,
                perYearCost: amount,
            };
        });

        formattedPlans.push({
            id: 'free-site',
            name: 'Free',
            perMonthCost: 0,
            perYearCost: 0,
        });

        const userDiscountMultiplier =
            1 - this.props.userDiscountPercentage / 100;

        const activeSubscription = formattedPlans.find(
            (plan) => this.props.selectedSubscription === plan.id
        );

        const selectedTld = selectedDomain.substring(
            selectedDomain.lastIndexOf('.')
        );
        const domainDetails = domains.find(
            ({ name }) =>
                selectedTld ===
                name.split(' ')[0].toLowerCase() /* .DK domain => .dk */
        );
        const domainCost = domainDetails.amount / 100;
        const perYearCost = activeSubscription.perYearCost;

        const total = (domainCost + perYearCost) * 1.25;
        const vat = total - (domainCost + perYearCost);
        const totalWithDiscount = total * userDiscountMultiplier;

        return (
            <div ref={(ref) => (this.container = ref)}>
                {this.state.loading && this.renderLoader()}

                <Header as="h1" textAlign="center">
                    <FormattedMessage id="activation.order wizard step header" />
                </Header>

                {/* <Grid centered>
					<GridColumn computer={8} mobile={16}>
						<p style={{ textAlign: 'left' }}>
							<FormattedMessage id="activation.order wizard step description" />
						</p>
					</GridColumn>
				</Grid> */}

                <Divider horizontal>
                    <Icon
                        circular
                        name="sn-sphere"
                        style={{ opacity: 0.6, fontSize: 32 }}
                    />
                </Divider>

                <Grid key="one" centered>
                    <GridColumn
                        computer={8}
                        mobile={16}
                        style={{ marginBottom: 24 }}
                    >
                        <Header as="h4" style={{ marginTop: 0 }}>
                            <Icon
                                name="sn-avatar-male-5"
                                style={{ opacity: 0.6, fontSize: 64 }}
                            />
                            <HeaderContent>
                                {this.props.name}
                                <HeaderSubheader>
                                    <Label
                                        basic
                                        size="large"
                                        className="info"
                                        style={{ marginLeft: 0, marginTop: 4 }}
                                    >
                                        <Icon name="star" />
                                        <FormattedMessage id="general.user site owner" />
                                    </Label>
                                </HeaderSubheader>
                            </HeaderContent>
                        </Header>
                    </GridColumn>
                    <GridColumn computer={8} mobile={16}>
                        <Grid padded>
                            {Object.keys(this.props.orgDetails).map((key) => {
                                const value = this.props.orgDetails[key];

                                if (!value) return null;

                                const displayKey = (() => {
                                    switch (key) {
                                        case 'orgName':
                                            return 'organisation';

                                        case 'vatNumber':
                                            return 'vat number';

                                        case 'address':
                                            return 'address';

                                        case 'zipCode':
                                            return 'zip code';

                                        case 'city':
                                            return 'city';

                                        case 'contactEmail':
                                            return 'contact email';

                                        case 'contactPhoneNumber':
                                            return 'contact phone number';

                                        default:
                                            return 'N/A';
                                    }
                                })();

                                return [
                                    <GridRow
                                        style={{
                                            paddingTop: 4,
                                            paddingBottom: 4,
                                        }}
                                    >
                                        <GridColumn
                                            computer={8}
                                            textAlign="right"
                                        >
                                            <p>
                                                <FormattedMessage
                                                    id={`general.organisation details ${displayKey} title`}
                                                />
                                                :
                                            </p>
                                        </GridColumn>
                                        <GridColumn computer={8}>
                                            <p
                                                key={`val-${key}`}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {value}
                                            </p>
                                        </GridColumn>
                                    </GridRow>,
                                ];
                            })}
                        </Grid>
                    </GridColumn>
                </Grid>

                <Table
                    key="two"
                    stackable
                    striped
                    size="large"
                    style={{ marginBottom: 40 }}
                >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>
                                <FormattedMessage id="activation.order overview item" />
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <FormattedMessage id="activation.order overview type" />
                            </TableHeaderCell>
                            <TableHeaderCell textAlign="right">
                                <FormattedMessage id="activation.order overview price" />
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 24, paddingBottom: 24 }}
                            >
                                <Header as="h5">
                                    <Icon
                                        name="sn-subscription-base"
                                        style={{ fontSize: 40, opacity: 0.6 }}
                                    />
                                    <HeaderContent>
                                        <span style={{ fontWeight: 300 }}>
                                            <FormattedMessage id="general.company title" />
                                        </span>
                                        <br />
                                        <span>
                                            {activeSubscription.nickname}
                                        </span>
                                    </HeaderContent>
                                </Header>
                            </TableCell>
                            <TableCell>
                                <Label
                                    basic
                                    circular
                                    size="large"
                                    className="primary-color"
                                >
                                    <FormattedMessage id="general.subscription title" />
                                </Label>
                            </TableCell>
                            <TableCell textAlign="right">
                                {perYearCost.toLocaleString('dkk')} DKK
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 24, paddingBottom: 24 }}
                            >
                                <Header as="h5" style={{ marginLeft: 8 }}>
                                    <Icon
                                        name="plug"
                                        style={{ fontSize: 24, opacity: 0.6 }}
                                    />
                                    <HeaderContent>
                                        {selectedDomain}
                                    </HeaderContent>
                                </Header>
                            </TableCell>
                            <TableCell>
                                <Label
                                    basic
                                    circular
                                    size="large"
                                    className="info"
                                >
                                    <FormattedMessage id="general.domain title" />
                                </Label>
                            </TableCell>
                            <TableCell textAlign="right">
                                {domainCost.toLocaleString('dkk')} DKK
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 24, paddingBottom: 24 }}
                            >
                                <span style={{ marginLeft: 8 }}>
                                    <FormattedMessage id="general.vat title" />
                                </span>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell textAlign="right">
                                {vat.toLocaleString('dkk')} DKK
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 24, paddingBottom: 24 }}
                            >
                                <Header as="h5" style={{ marginLeft: 10 }}>
                                    <HeaderContent>
                                        <span style={{ fontSize: 20 }}>
                                            <FormattedMessage id="activation.order total cost" />
                                        </span>
                                        <HeaderSubheader>
                                            <FormattedMessage id="activation.order including vat" />
                                        </HeaderSubheader>
                                    </HeaderContent>
                                </Header>
                            </TableCell>
                            <TableCell>
                                {this.props.userDiscountPercentage > 0 && (
                                    <Label
                                        basic
                                        circular
                                        size="large"
                                        className="positive"
                                    >
                                        {`${intl.formatMessage({
                                            id: 'general.discount',
                                        })}: -${
                                            this.props.userDiscountPercentage
                                        }%`}
                                    </Label>
                                )}
                            </TableCell>
                            <TableCell textAlign="right">
                                <span
                                    style={{ fontSize: 20, fontWeight: 'bold' }}
                                >
                                    {totalWithDiscount.toLocaleString('dkk')}{' '}
                                    DKK
                                </span>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {/* payment method section */}
                <div
                    key="three"
                    className="section-shade"
                    style={{ padding: '44px 32px 24px 32px', margin: 0 }}
                >
                    <Box
                        style={{
                            maxWidth: 520,
                            paddingBottom: 32,
                            margin: 'auto',
                        }}
                    >
                        <div className="box-icon-container">
                            <Icon
                                circular
                                name="payment"
                                className="box-icon"
                            />
                        </div>

                        {/* choose payment method title */}
                        <Header as="h3">
                            <FormattedMessage id="activation.order enter credit card info" />
                        </Header>

                        {/* Credit card picker component */}
                        <CardPicker
                            // handleErrors={e => {
                            // 	if (e.type === 'ClientError') {
                            // 		this.setState({
                            // 			error: e.intlReadable
                            // 		});
                            // 	} else {
                            // 		this.setState({
                            // 			error: 'activation.unknown error'
                            // 		});
                            // 	}
                            // }}
                            agreeTerms={this.props.agreeTerms}
                            toggleAgreeTerms={this.props.toggleAgreeTerms}
                            setTokenGenerator={(fn) => {
                                this.generateToken = fn;
                            }}
                            setCard={(card) => (this.card = card)}
                        />

                        <Message
                            icon="sn-rotate-cw3"
                            size="large"
                            content={
                                <div>
                                    <div style={{ marginBottom: 8 }}>
                                        <FormattedMessage id="activation.order payment message" />
                                    </div>
                                    <div>
                                        <FormattedMessage id="activation.order per year from date" />
                                    </div>
                                    <Label
                                        basic
                                        size="large"
                                        className="info"
                                        style={{
                                            marginLeft: 0,
                                            marginTop: 4,
                                        }}
                                    >
                                        {this.state.date}
                                    </Label>
                                </div>
                            }
                            className="shade"
                            style={{ textAlign: 'left', maxWidth: 520 }}
                        />
                    </Box>
                </div>

                {/* general activation order error: */}
                {this.state.error && (
                    <Message
                        className="error"
                        size="large"
                        style={{ textAlign: 'left', marginTop: 8 }}
                        icon="sn-warning"
                        content={
                            <span>
                                <FormattedMessage id={this.state.error} />
                            </span>
                        }
                    />
                )}

                {/* Commented out section here is from the renderer branch - not sure what to keep from it, so I just saved it here for future use */}
                {/* <div key="three" className="section-shade" style={{ margin: 0 }}>
					<Box style={{ maxWidth: 520, paddingBottom: 16 }}>
						<div className="box-icon-container">
							<Icon circular name="payment" className="box-icon" />
						</div>

						<Grid padded centered style={{ paddingTop: 16 }}>

							<GridColumn computer={16} textAlign="center">
								<Header as="h4"><FormattedMessage id="activation.order enter credit card info" /></Header>
							</GridColumn>
							<GridColumn computer={16} textAlign="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
								<Stripe
									onError={console.log}
									setTokenGenerator={fn => { this.generateToken = fn; }}
								/>
								<p className="small-text">
									<Icon name="sn-info" />
									<FormattedMessage id="activation.order payment disclaimer" />
								</p>
							</GridColumn>

							<GridColumn computer={16} textAlign="left" style={{ paddingTop: 0 }}>
								<div style={{ marginBottom: 12 }}>
									<TermsAndConditions />
								</div>

								<FormCheckbox
									label={intl.formatMessage({ id: 'activation.order accept order' })}
									checked={this.props.agreeTerms}
									onChange={this.props.toggleAgreeTerms}
									style={{ marginLeft: 2, marginBottom: 8 }}
								/>
							</GridColumn>

							{this.state.error &&
								<GridColumn computer={16} textAlign="center">
									<Message
										className="error"
										size="large"
										style={{ textAlign: 'left' }}
										icon="sn-warning"
										content={
											<span><FormattedMessage id={this.state.error} /></span>
										}
									/>
								</GridColumn>
							}
						</Grid>
					</Box>

					{/ Recurring payments info message /}
					<Message
						icon="sn-rotate-cw3"
						size="large"
						content={
							<div>
								<div style={{ marginBottom: 8 }}><FormattedMessage id="activation.order payment message" /></div>
								<div style={{ fontWeight: 'bold' }}><FormattedMessage id="activation.order per year from date" /></div>
								<Label
									basic
									size="large"
									className="info"
									style={{ marginLeft: 0, marginTop: 4 }}
								>
									{this.state.date}
								</Label>
							</div>
						}
						className="shade"
						style={{ textAlign: 'left', maxWidth: 520 }}
					/>

					{this.state.submitError &&
						<Message
							className="error"
							size="big"
							style={{ marginTop: 24, textAlign: 'left' }}
							icon="sn-warning"
							content={
								<p>{this.state.submitError}</p>
							}
						/>
					}
				</div> */}

                <WizardControls
                    prevButton
                    nextButton
                    renderPrevButton={() => (
                        <Button
                            floated="left"
                            size="huge"
                            onClick={() => this.props.setStep(1)}
                        >
                            <Icon
                                name="sn-arrow-left8"
                                style={{ marginRight: 8 }}
                            />
                            <FormattedMessage id="general.back" />
                        </Button>
                    )}
                    renderNextButton={() => (
                        <Button
                            floated="right"
                            size="huge"
                            className="positive"
                            onClick={() => {
                                this.validate(() => {
                                    this.props.setStep(3);
                                });
                            }}
                        >
                            <Icon
                                name="sn-checkmark"
                                style={{ marginRight: 8 }}
                            />
                            <FormattedMessage id="activation.order confirm order" />
                        </Button>
                    )}
                    onPrev={() => this.props.setStep(0)}
                    onNext={() => {
                        this.validate(() => {
                            this.props.setStep(3);
                        });
                    }}
                    styles={{ marginTop: 48 }}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CreateSiteOrder));
