import React from 'react';
import { setRoute } from 'utils/routing';
import {
	Icon,
	Message,
    ListItem,
    Button,
	Label
} from 'svift-ui';
import { connect } from 'react-redux';
import { createFilter } from 'react-search-input';
import ListMenu from 'components/list-menu';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import { blogsSelector } from 'store/selectors/sites/blogs';
import InfoIcon from 'components/InfoIcon';
import css from 'components/app-layout/app-navigation/app-navigation.scss'

const BlogList = injectIntl(props => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const filteredBlogs = props.blogs.filter(createFilter(searchTerm, ['title']));

    return (
        <ListMenu
            dataSource={props.blogs}
            handleFilter={(e, d) => setSearchTerm(e.target.value)}
            onClose={() => props.setListOpen(false)}
            // modalText={props.intl.formatMessage({ id: 'blogs.create new blog' })}
            // onModalClick={toggleBlogModal}
            icon="sn-blog"
            title={props.intl.formatMessage({ id: 'blog.posts title' })}
        >
            {filteredBlogs.length === 0
                ?
                    // transl8
                    <Message
                        size="mini"    
                        icon="sn-search3"
                        className="info"
                        content="No blog posts found..."
                    />
                :
                    filteredBlogs.map(blog => {
                        const isUnpublished = !blog.content.live;
                        const unsavedChanges = blog.content.live && blog.content.live.version !== blog.content.draft.version;

                        return (
                            <ListItem 
                                key={blog._id} 
                                onClick={e => {
                                    if (blog._id !== props.blogid) {
                                        const siteid = props.siteid;
                            
                                        setRoute(`/dashboard/${siteid}/blog-posts/${blog._id}`);
                                    }
                                }}
                                className={blog._id === props.blogid ? 'active-item' : ''}
                            >
                                <span style={{ verticalAlign: 'middle' }}>
                                    <Icon name="sn-magazine" style={{ verticalAlign: 'middle' }} />

                                    <span style={{ verticalAlign: 'middle', marginRight: 8 }}>
                                        {blog.title}<br />

                                        {blog.publishAt && 
                                            <Label
                                                basic
                                                size="mini"
                                                className="secondary"
                                                style={{ padding: '4px 6px', fontSize: 12, marginTop: 6, marginLeft: 20 }}
                                            >
                                                <Icon name="sn-calendar4" style={{ marginRight: 6 }} />
                                                {/* TO DO: insert formatted date here */}

                                                <FormattedDate
                                                    value={new Date(blog.publishAt)}
                                                    year='numeric'
                                                    month='long'
                                                    day='2-digit'
                                                />
                                            </Label>
                                        }
                                    </span>

                                    <span style={{ float: 'right', verticalAlign: 'middle' }}>
                                        {/* has draft content (and is online) */}
                                        {(unsavedChanges && !isUnpublished) &&
                                            <Label
                                                size="mini"
                                                className="info"
                                                style={{ marginRight: 4 }}
                                            >
                                                <Icon name="sn-quill3" style={{ marginRight: 6 }} />
                                                <FormattedMessage id="general.draft title" />
                                            </Label>									
                                        }

                                        {isUnpublished
                                            ?
                                                <Label
                                                    size="mini" 
                                                    className="warning" 
                                                    style={{ marginRight: 0 }}
                                                >
                                                    <Icon name="sn-eye-blocked" style={{ marginRight: 6 }} />
                                                    <FormattedMessage id="general.offline title" />
                                                </Label>
                                            :
                                                <React.Fragment>
                                                    {/* {unsavedChanges &&
                                                        <Label
                                                            size="mini"
                                                            className="info"
                                                            style={{ marginRight: 4 }}
                                                        >
                                                            <Icon name="sn-quill3" style={{ marginRight: 6 }} />
                                                            <FormattedMessage id="general.draft title" />
                                                        </Label>
                                                    } */}

                                                    <Label
                                                        size="mini" 
                                                        className="positive" 
                                                        style={{ marginRight: 0 }}
                                                    >
                                                        <Icon name="sn-earth2" style={{ marginRight: 0 }} />
                                                        {/* <FormattedMessage id="general.online title" /> */}
                                                    </Label>

                                                    <InfoIcon
                                                        description={props.intl.formatMessage({ id: 'general.online description' })}// <FormattedMessage id="general.online description" />}
                                                        position="right center"
                                                        className="positive-color"
                                                        style={{ pointerEvents: 'auto' }}
                                                    />
                                                </React.Fragment>
                                        }
                                    </span>
                                </span>
                            </ListItem>
                        );
                    })
            }
        </ListMenu>
    );
});

const SelectBlogButton = props => {
    const [listOpen, setListOpen] = React.useState(false);
    const selectedBlog = props.blogs.find(blog => blog._id === props.blogid);
    
    const draftVersion = props.draftContent && props.draftContent.version;
    const liveVersion = props.liveContent && props.liveContent.version; 
    const isUnpublished = !props.liveContent;

    return (
        <React.Fragment>
            <Button 
                icon 
                basic
                size="small" 
                onClick={() => setListOpen(true)}
                id="blog-post-menu-title"
                className={!listOpen ? 'primary' : 'primary active-button'} 
                style={{ marginRight: 8, padding: '6px 8px' }}
            >
                <Icon name="sn-magazine" className="primary-color" style={{ fontSize: 16, verticalAlign: 'middle', height: 16 }} />
                <span style={{ marginLeft: 6, marginRight: 4, verticalAlign: 'middle' }}>
                    {selectedBlog ? selectedBlog.title : ''}
                </span>
                <Icon
                    name={!listOpen ? 'sn-arrow-down2' : 'sn-arrow-up2'}
                    style={{ verticalAlign: 'middle', fontSize: 10, height: 8 }}
                />
            </Button>

            {/* post status labels */}
            {/* publication date */}
            <Label
                basic
                size="mini"
                className="secondary"
                style={{ marginRight: 4 }}
            >
                <Icon name="sn-calendar4" style={{ marginRight: 6 }} />
                {/* TO DO: insert formatted date here */}
                <FormattedDate
                    value={new Date(selectedBlog.publishAt)}
                    year='numeric'
                    month='long'
                    day='2-digit'
                />
            </Label>

            {/* draft status label */}
            {(props.hasChanged || draftVersion !== liveVersion) &&
                <Label className="info" size="mini">
                    <Icon name="sn-quill3" style={{ marginRight: 4 }} />
                    <FormattedMessage id="general.draft title" />
                </Label>
            }
            
            {/* online/offline status label */}
            <Label size="mini" className={!isUnpublished ? 'positive' : 'warning'}>
                <Icon
                    name={!isUnpublished ? 'sn-earth2' : 'sn-eye-blocked'}
                    style={{ marginRight: 4 }}
                />

                <FormattedMessage id={`general.${!isUnpublished ? 'online' : 'offline'} title`}/>
            </Label>

            {!isUnpublished &&
                <InfoIcon
                    description={<FormattedMessage id="general.online description" />}
                    position="bottom center"
                    className="positive-color"
                    style={{ pointerEvents: 'auto' }}
                />
            }

            {listOpen && 
                <BlogList setListOpen={setListOpen} {...props} /> 
            }
        </React.Fragment>
    );
}

export default connect(state => {
    const blogid = state.sites.blogs.params.blogid;
	const siteid = state.sites.params.siteid;
    const blogs = blogsSelector(state, siteid, null);
    
    return {
		siteid,
		blogid,
		blogs,
    };
})(SelectBlogButton);
