import React, {
    PureComponent,
    useState,
    useMemo,
    useRef,
    createContext
} from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import tinycolor from 'tinycolor2';
import { FormattedMessage } from 'react-intl';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';

import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';

const formFactory = () => {
    const fields = [];

    return {
        setField: field => fields.push(field),
        fields
    };
};

/*
	OuterRenderer
		OuterRenderer context (+ forms lookup) 
			Form takes full context, deletes aspects that aren't needed, and passes new state and context into a Renderer instance 

	1) step 1 => get data out
	2) step 2 => mutate outer state before passing it in
*/

const FormContextModifierHOC = Component => {
    const WrappedComponent = props => {
        // const [recaptchaToken, setRecaptchaToken] = useState(null);
        const FormContext = useMemo(() => createContext(), []);
        const formObj = useMemo(() => formFactory(), []);
        const recaptchaRef = useRef();
        const [formState, setFormState] = useState({ submitted: false });

        const {
            all, // Removes selectable, draggable, etc.
            ...extend
        } = props.context.extend;

        const modifiedContext = {
            ...props.context,
            extend,
            nodeMap:
                (props.selectedForm &&
                    props.selectedForm.content.live &&
                    props.selectedForm.content.live.nodeMap) ||
                {},
            // form, formObj, recpatchaRef, setRecaptchaToken are all used by form elements and have to be shared globally
            form: props.selectedForm,
            formObj,
            recaptchaRef,
            setRecaptchaToken: recaptchaToken => props.onSetRecaptachaToken(recaptchaToken, formState, setFormState)
        };

        if (!modifiedContext.nodeMap.root) return 'no form';

        return (
            <Component
                {...props}
                RendererContext={FormContext}
                recaptchaRef={recaptchaRef}
                formState={formState}
                setFormState={setFormState}
                formObj={formObj}
                context={modifiedContext}
            />
        );
    };

    WrappedComponent.mapContextToProps = Component.mapContextToProps;

    return WrappedComponent;
};

class Form extends PureComponent {
    static mapContextToProps = (props, state, node) => {
        const { forms, extend, theme } = props.context;

        let form =
            forms.lookup[
                node.props.selectedForm && node.props.selectedForm.toString()
            ]; //forms.find(form => form._id.toString() === node.props.selectedForm && node.props.selectedForm.toString());

        if (!form)
            form = Object.values(forms.lookup).find(form => form.default);

        return shallowCompare(
            {
                theme,
                siteID: props.context.siteID,
                onSubmit: extend.Form.onSubmit,
                onSetRecaptachaToken: extend.Form.onSetRecaptchaToken,
                selectedForm: form
            },
            state
        );
    }

    formSubmit = event =>  {
        event.preventDefault();

        this.props.onSubmit(this.props.siteID, this.props.selectedForm, this.props.formObj, this.props.recaptchaRef, this.props.formState, this.props.setFormState);
    }

    renderDimmer() {
        if (
            !this.props.formState.submitted &&
            !this.props.formState.submitting &&
            !this.props.formState.error
        )
            return null;

        const { theme } = this.props;
        const backgroundColor = theme.colors.tint;
        const themeColors = theme.colors;
        const statusColor = this.props.formState.error
            ? themeColors.error
            : this.props.formState.submitting
            ? themeColors.warning
            : themeColors.success;

        return (
            <div
                key="dimmer"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 2,
                    background: tinycolor(backgroundColor).setAlpha(0.85),
                    boxShadow: `inset 0 0 0 5px ${tinycolor(
                        statusColor
                    ).setAlpha(0.85)}`
                }}
            />
        );
    }

    renderSubmitMessage() {
        if (
            !this.props.formState.submitted &&
            !this.props.formState.submitting &&
            !this.props.formState.error
        )
            return null;

        const form = this.props.selectedForm;

        const { theme, computePreset } = this.props;

        const headerPreset = theme.presets.text['Heading 4'];
        const headerStyle = computePreset(headerPreset, theme);
        const textPreset = theme.presets.text['Big'];
        const textStyle = computePreset(textPreset, theme);

        return (
            <div
                key="sticky-submit-message"
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: theme.measurements.padding,
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3
                }}
            >
                {/* status message after submit: */}
                {this.props.formState.error ? (
                    <h4
                        style={Object.assign(headerStyle, {
                            color: tinycolor(theme.colors.error).darken(15)
                        })}
                    >
                        <FormattedMessage id="forms.submit message error header" />
                    </h4>
                ) : this.props.formState.submitting ? (
                    <h4
                        style={Object.assign(headerStyle, {
                            color: tinycolor(theme.colors.warning).darken(15)
                        })}
                    >
                        <FormattedMessage id="forms.submit message processing header" />
                    </h4>
                ) : (
                    <div>
                        <h4
                            style={Object.assign(headerStyle, {
                                color: tinycolor(theme.colors.success).darken(
                                    15
                                )
                            })}
                        >
                            <FormattedMessage id="forms.submit message header" />
                        </h4>
                        <p style={textStyle}>{form.afterSubmitMessage}</p>
                    </div>
                )}
            </div>
        );
    }

    render() {
        // return 'form goes here';
        // return null;
        // const { node } = this.props;
        const formStyle = {
            width: '100%',
            position: 'relative'
            // pointerEvents: 'none' // this prevents interactions in form elements + recaptcha
        };

        // const form = this.props.form;

        // We don't want "all" to be included in forms (because it will apply draggable, selectable, etc. to the form elements)
        // const {
        // 	all,
        // 	...extend
        // } = this.props.extend;

        return (
            <form style={formStyle} onSubmit={this.formSubmit}>
                {/* <Node
					nodeID="root"
					onClickOutsideRef={this.props.rendererContainerRef}
					ContextConsumer={this.props.RendererContext.Consumer}
					getPath={this.props.getPath}
					getParent={this.props.getParent}
				/> */}

                <Renderer
                    rendererState={this.props.context}
                    RendererContext={this.props.RendererContext}
                />

                {/* <Renderer
					nodeMap={treeToNodeMap(form.content.live.tree)}
					breakpoints={form.content.live.breakpoints}
					extend={extend}
					data={{
						files: this.props.files,
						site: this.props.site,
						pages: this.props.pages,
						blogs: this.props.blogs,
						theme: this.props.theme,
						forms: this.props.forms
					}}
				/>
				 */}
                {this.renderDimmer()}
                {this.renderSubmitMessage()}
            </form>
        );
    }
}

export default FormContextModifierHOC(Form);
