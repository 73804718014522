export default (moduleId, enable) => {
    let modules = {};

    switch (moduleId) {
        case 'blog':
            modules.blog = enable;

            break;
        // Paid modules come later, right now they aren't toggleable
        case 'future':
        case 'shop':
        case 'booking':
            alert('Under udvikling; Kan ikke aktiveres endnu')
    }

    return modules;
}
