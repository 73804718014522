import React, { PureComponent } from 'react';
import { DropdownItem, Icon, Popup, Button, Header, Divider } from 'svift-ui';
import actions from 'store/actions/ui';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Switch, Route } from 'react-router';
import StepByStep from 'components/step-by-step/StepByStep';
import SviftGA from 'utils/svift-ga';

const mapStateToProps = state => ({
    stepByStepVisible: state.ui.stepByStepVisible,
    location: state.router.location.pathname // Required to propagate state properly. Don't know why exactly; seems to be a buggy interaction between react-router and react-redux.
});

const mapDispatchToProps = dispatch => ({
	toggleStepByStep: bool => dispatch(actions.toggleStepByStep(bool)), // data => dispatch(toggleSideNav(data)),
});

class StepByStepButton extends PureComponent {
    renderDropDownItem = props => {
        const { toggleStepByStep, stepByStepVisible, intl } = this.props;

        return (
            <DropdownItem
                id="step-by-step-button"
                className={stepByStepVisible ? "active-item" : null} onClick={() => {
                    if (!stepByStepVisible) SviftGA.SUPPORT.INTRODUCTION_STARTED();

                    toggleStepByStep(true);//!stepByStepVisible)
                }}
            >
                <span>
                    <Icon name="sn-play3" />
                    <FormattedMessage id="support.guided tour title" />
                </span>
            </DropdownItem>
        );
    }    

	render() {
		const { intl } = this.props;
		// const content = `${stepByStepVisible ? 'general.hide step by step' : 'general.show step by step'}`;

		return (
            <Switch>
                {/* Dashboard step by step introduction */}
                <Route
                    exact
                    path="/dashboard/:siteid/"
                    render={() => (
                        <React.Fragment>
                            <this.renderDropDownItem />
                    
                            <StepByStep
                                id="dashboard-step-by-step"
                                modalHeaderTranslationKey="support.want a guided tour title"
                                modalContent={
                                    <div style={{ textAlign: 'center' }}>
                                        <Icon
                                            circular
                                            name="sn-view_module"
                                            className="primary-color"
                                            style={{
                                                fontSize: 64,
                                                margin: 0,
                                                marginBottom: 8,
                                                opacity: 0.6
                                            }}
                                        />
                                        <Header as="h3" style={{ marginTop: 0 }}>
                                            <FormattedMessage id="dashboard.the dashboard title" />
                                        </Header>
                                        <Divider />
                                        <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                            <FormattedMessage id="support.dashboard step by step welcome description" />
                                        </p>
                                    </div>
                                }
                                steps={[
                                    {
                                        header: intl.formatMessage({ id: 'dashboard.the dashboard title' }),
                                        position: 'bottom left',
                                        icon: 'sn-view_module',
                                        targetElementId: 'dashboard-section-label',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step the dashboard description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'pages.app title' }),
                                        position: 'bottom left',
                                        icon: 'sn-stack-text',
                                        targetElementId: 'pages-app-tile',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step pages description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'design.app title' }),
                                        position: 'bottom center',
                                        icon: 'sn-bucket',
                                        targetElementId: 'design-app-tile',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step design description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'files.app title' }),
                                        position: 'bottom center',
                                        icon: 'sn-folder6',
                                        targetElementId: 'files-app-tile',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step files description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'settings.app title' }),
                                        position: 'bottom center',
                                        icon: 'sn-cog3',
                                        targetElementId: 'settings-app-tile',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step settings description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'blog.app title' }),
                                        position: 'bottom left',
                                        icon: 'sn-blog',
                                        targetElementId: 'blogs-app-tile',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step blog description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.dashboard step by step site menu title' }),
                                        position: 'bottom left',
                                        icon: 'sn-sphere',
                                        targetElementId: 'site-menu-title',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step site menu description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.dashboard step by step your profile title' }),
                                        position: 'bottom right',
                                        icon: 'sn-account_circle',
                                        targetElementId: 'user-settings-menu-button',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step your profile description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.dashboard step by step the support menu title' }),
                                        position: 'bottom right',
                                        icon: 'sn-question3',
                                        required: true,
                                        targetElementId: 'support-menu-dropdown',
                                        render: () => (
                                            <FormattedMessage id="support.dashboard step by step the support menu description" />
                                        )
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )} 
                />
                
                {/* Pages step by step introduction */}
                <Route 
                    path="/dashboard/:siteid/pages"
                    render={() => (
                        <React.Fragment>
                            <this.renderDropDownItem />

                            <StepByStep
                                id="pages-step-by-step"
                                modalHeaderTranslationKey="support.want a guided tour title"
                                modalContent={
                                    <div style={{ textAlign: 'center' }}>
                                        <Icon
                                            circular
                                            name="sn-stack-text"
                                            className="primary-color"
                                            style={{
                                                fontSize: 64,
                                                margin: 0,
                                                marginBottom: 8,
                                                opacity: 0.6
                                            }}
                                        />
                                        <Header as="h3" style={{ marginTop: 0 }}>
                                            <FormattedMessage id="pages.app title" />
                                        </Header>
                                        <Divider />
                                        <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                            <FormattedMessage id="support.pages step by step welcome description" />
                                        </p>
                                    </div>
                                }
                                steps={[
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step the pages menu title' }),
                                        position: 'bottom left',
                                        icon: 'sn-file-text',
                                        required: true,
                                        targetElementId: 'pages-page-menu-title',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step the pages menu description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step page settings title' }),
                                        position: 'bottom left',
                                        icon: 'setting',
                                        required: true,
                                        targetElementId: 'pages-page-menu-settings',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step page settings description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step create new pages title' }),
                                        position: 'bottom left',
                                        icon: 'plus',
                                        required: true,
                                        targetElementId: 'pages-new-page-button',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step create new pages description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step elements title' }),
                                        position: 'right top',
                                        icon: 'sn-marquee-plus',
                                        required: true,
                                        targetElementId: 'elements-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step elements description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step templates title' }),
                                        position: 'right bottom',
                                        icon: 'sn-grid8',
                                        required: true,
                                        targetElementId: 'templates-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step templates description" />    
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step preview title' }),
                                        position: 'bottom right',
                                        icon: 'sn-eye',
                                        required: true,
                                        targetElementId: 'preview-switch',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step preview description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step save publish title' }),
                                        position: 'bottom right',
                                        icon: 'sn-cloud-upload',
                                        required: true,
                                        targetElementId: 'save-and-publish-buttons',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step save publish description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.pages step by step the support menu title' }),
                                        position: 'bottom right',
                                        icon: 'sn-question3',
                                        required: true,
                                        targetElementId: 'support-menu-dropdown',
                                        render: () => (
                                            <FormattedMessage id="support.pages step by step the support menu description" />
                                        )
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )} 
                /> {/* END - Pages step by step introduction */}
                
                {/* Blog step by step introduction */}
                <Route 
                    path="/dashboard/:siteid/blog-posts"
                    render={() => (
                        <React.Fragment>
                            <this.renderDropDownItem />

                            <StepByStep
                                id="blog-step-by-step"
                                modalHeaderTranslationKey="support.want a guided tour title"
                                modalContent={
                                    <div style={{ textAlign: 'center' }}>
                                        <Icon
                                            circular
                                            name="sn-blog"
                                            className="primary-color"
                                            style={{
                                                fontSize: 64,
                                                margin: 0,
                                                marginBottom: 8,
                                                opacity: 0.6
                                            }}
                                        />
                                        <Header as="h3" style={{ marginTop: 0 }}>
                                            <FormattedMessage id="blog.app title" />
                                        </Header>
                                        <Divider />
                                        <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                            <FormattedMessage id="support.blog step by step welcome description" />
                                        </p>
                                    </div>
                                }
                                steps={[
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step elements title' }),
                                        position: 'right top',
                                        icon: 'sn-marquee-plus',
                                        required: true,
                                        targetElementId: 'elements-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step elements description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step templates title' }),
                                        position: 'right bottom',
                                        icon: 'sn-grid8',
                                        required: true,
                                        targetElementId: 'templates-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step templates description" />    
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step create new post title' }),
                                        position: 'bottom left',
                                        icon: 'plus',
                                        required: true,
                                        targetElementId: 'blog-new-post-button',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step create new post description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step posts menu title' }),
                                        position: 'bottom left',
                                        icon: 'sn-magazine',
                                        required: true,
                                        targetElementId: 'blog-post-menu-title',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step posts menu description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step settings title' }),
                                        position: 'bottom left',
                                        icon: 'setting',
                                        required: true,
                                        targetElementId: 'blog-post-menu-settings',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step settings description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step preview title' }),
                                        position: 'bottom right',
                                        icon: 'sn-eye',
                                        required: true,
                                        targetElementId: 'preview-switch',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step preview description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step save publish title' }),
                                        position: 'bottom right',
                                        icon: 'sn-cloud-upload',
                                        required: true,
                                        targetElementId: 'save-and-publish-buttons',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step save publish description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.blog step by step the support menu title' }),
                                        position: 'bottom right',
                                        icon: 'sn-question3',
                                        required: true,
                                        targetElementId: 'support-menu-dropdown',
                                        render: () => (
                                            <FormattedMessage id="support.blog step by step the support menu description" />
                                        )
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )} 
                /> {/* END - Blog step by step introduction */}

                {/* Layouts step by step introduction */}
                <Route 
                    path="/dashboard/:siteid/design/layouts"
                    render={() => (
                        <React.Fragment>
                            <this.renderDropDownItem />

                            <StepByStep
                                key="layouts"
                                id="layouts-step-by-step"
                                modalHeaderTranslationKey="support.want a guided tour title"
                                modalContent={
                                    <div style={{ textAlign: 'center' }}>
                                        <Icon
                                            circular
                                            name="sn-web"
                                            className="primary-color"
                                            style={{
                                                fontSize: 64,
                                                margin: 0,
                                                marginBottom: 8,
                                                opacity: 0.6
                                            }}
                                        />
                                        <Header as="h3" style={{ marginTop: 0 }}>
                                            <FormattedMessage id="layouts.title" />
                                        </Header>
                                        <Divider />
                                        <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                            <FormattedMessage id="support.layouts step by step welcome description" />
                                        </p>
                                    </div>
                                }
                                steps={[
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step layout elements title' }),
                                        position: 'right top',
                                        icon: 'sn-marquee-plus',
                                        required: true,
                                        targetElementId: 'elements-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step layout elements description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step layout templates title' }),
                                        position: 'right bottom',
                                        icon: 'sn-grid8',
                                        required: true,
                                        targetElementId: 'templates-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step layout templates description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step the frame title' }),
                                        position: 'top center',
                                        icon: 'sn-quill4',
                                        required: true,
                                        targetElementId: 'content-frame',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step the frame description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step create new layout title' }),
                                        position: 'bottom left',
                                        icon: 'sn-plus2',
                                        required: true,
                                        targetElementId: 'layouts-new-layout-button',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step create new layout description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step layout menu title' }),
                                        position: 'bottom left',
                                        icon: 'sn-web',
                                        required: true,
                                        targetElementId: 'layouts-layout-menu',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step layout menu description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step layout settings title' }),
                                        position: 'bottom left',
                                        icon: 'setting',
                                        required: true,
                                        targetElementId: 'layouts-layout-menu-settings',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step layout settings description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step preview title' }),
                                        position: 'bottom right',
                                        icon: 'sn-eye',
                                        required: true,
                                        targetElementId: 'preview-switch',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step preview description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step save publish title' }),
                                        position: 'bottom right',
                                        icon: 'sn-cloud-upload',
                                        required: true,
                                        targetElementId: 'save-and-publish-buttons',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step save publish description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.layouts step by step the support menu title' }),
                                        position: 'bottom right',
                                        icon: 'sn-question3',
                                        required: true,
                                        targetElementId: 'support-menu-dropdown',
                                        render: () => (
                                            <FormattedMessage id="support.layouts step by step the support menu description" />
                                        )
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )} 
                /> {/* END - Layouts step by step introduction */}

                {/* Forms step by step introduction */}
                <Route 
                    path="/dashboard/:siteid/design/forms"
                    render={() => (
                        <React.Fragment>
                            <this.renderDropDownItem />

                            <StepByStep
                                id="forms-step-by-step"
                                modalHeaderTranslationKey="support.want a guided tour title"
                                modalContent={
                                    <div style={{ textAlign: 'center' }}>
                                        <Icon
                                            circular
                                            name="send outline"
                                            className="primary-color"
                                            style={{
                                                fontSize: 64,
                                                margin: 0,
                                                marginBottom: 8,
                                                opacity: 0.6
                                            }}
                                        />
                                        <Header as="h3" style={{ marginTop: 0 }}>
                                            <FormattedMessage id="forms.title" />
                                        </Header>
                                        <Divider />
                                        <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                            <FormattedMessage id="support.forms step by step welcome description" />
                                        </p>
                                    </div>
                                }
                                steps={[
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step form elements title' }),
                                        position: 'right top',
                                        icon: 'sn-marquee-plus',
                                        required: true,
                                        targetElementId: 'elements-accordion-title',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step form elements description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step create new form title' }),
                                        position: 'bottom left',
                                        icon: 'sn-plus2',
                                        required: true,
                                        targetElementId: 'forms-new-form-button',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step create new form description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step form menu title' }),
                                        position: 'bottom left',
                                        icon: 'send outline',
                                        required: true,
                                        targetElementId: 'forms-form-menu',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step form menu description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step form settings title' }),
                                        position: 'bottom left',
                                        icon: 'setting',
                                        required: true,
                                        targetElementId: 'forms-form-menu-settings',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step form settings description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step save publish title' }),
                                        position: 'bottom right',
                                        icon: 'sn-cloud-upload',
                                        required: true,
                                        targetElementId: 'save-and-publish-buttons',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step save publish description" />
                                        )
                                    },
                                    {
                                        header: intl.formatMessage({ id: 'support.forms step by step the support menu title' }),
                                        position: 'bottom right',
                                        icon: 'sn-question3',
                                        required: true,
                                        targetElementId: 'support-menu-dropdown',
                                        render: () => (
                                            <FormattedMessage id="support.forms step by step the support menu description" />
                                        )
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )} 
                /> {/* END - Forms step by step introduction */}
                
                {/* Design step by step introduction */}
                <Route 
                    path="/dashboard/:siteid/design"
                    render={({ location }) => {
                        const splitLocation = location.pathname.split('/');

                        if (['navigation', 'layouts', 'forms'].some(path => location.pathname.endsWith(path))) return null;
                        if (splitLocation[splitLocation.length - 2] === 'elements') return null;

                        return (
                            <React.Fragment>
                                <this.renderDropDownItem />
                                
                                <StepByStep
                                    id="design-step-by-step"
                                    modalHeaderTranslationKey="support.want a guided tour title"
                                    modalContent={
                                        <div style={{ textAlign: 'center' }}>
                                            <Icon
                                                circular
                                                name="sn-bucket"
                                                className="primary-color"
                                                style={{
                                                    fontSize: 64,
                                                    margin: 0,
                                                    marginBottom: 8,
                                                    opacity: 0.6
                                                }}
                                            />
                                            <Header as="h3" style={{ marginTop: 0 }}>
                                                <FormattedMessage id="design.app title" />
                                            </Header>
                                            <Divider />
                                            <p style={{ fontWeight: 30, fontSize: 18, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
                                                <FormattedMessage id="support.design step by step welcome description" />
                                            </p>
                                        </div>
                                    }
                                    steps={[
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step design overview title' }),
                                            conditional: () => false,
                                            position: 'bottom center',
                                            icon: 'sn-bucket',
                                            required: true,
                                            targetElementId: ['design-overview-title'],
                                            render: () => (
                                                <FormattedMessage id="support.design step by step design overview description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step theme menu title' }),
                                            conditional: () => true,
                                            position: 'bottom left',
                                            icon: 'sn-color-sampler',
                                            required: true,
                                            targetElementId: 'theme-dropdown-menu',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step theme menu description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step design variables title' }),
                                            conditional: () => false,
                                            position: 'right top',
                                            icon: 'sn-palette',
                                            required: true,
                                            targetElementId: 'theme-variables-button',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step design variables description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step elements title' }),
                                            position: 'right center',
                                            icon: 'sn-marquee-plus',
                                            required: true,
                                            targetElementId: 'design-elements-menu-item',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step elements description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step layouts title' }),
                                            position: 'right center',
                                            icon: 'sn-web',
                                            required: true,
                                            targetElementId: 'design-layout-menu-item',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step layouts description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step navigation title' }),
                                            position: 'right center',
                                            icon: 'sn-direction',
                                            required: true,
                                            targetElementId: 'design-navigation-menu-item',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step navigation description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step forms title' }),
                                            position: 'right center',
                                            icon: 'send outline',
                                            required: true,
                                            targetElementId: 'design-forms-menu-item',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step forms description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step preview title' }),
                                            position: 'bottom right',
                                            icon: 'sn-eye',
                                            required: true,
                                            targetElementId: 'design-preview-button',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step preview description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step save publish title' }),
                                            position: 'bottom right',
                                            icon: 'sn-cloud-upload',
                                            required: true,
                                            targetElementId: 'save-publish-button',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step save publish description" />
                                            )
                                        },
                                        {
                                            header: intl.formatMessage({ id: 'support.design step by step the support menu title' }),
                                            position: 'bottom right',
                                            icon: 'sn-question3',
                                            required: true,
                                            targetElementId: 'support-menu-dropdown',
                                            render: () => (
                                                <FormattedMessage id="support.design step by step the support menu description" />
                                            )
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        );
                    }}
                /> {/* END - Design step by step introduction */}
            </Switch>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StepByStepButton));