import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
import Slider from 'components/option-panel/options/Slider';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Dropdown from 'components/option-panel/options/Dropdown';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import ButtonOption from 'components/option-panel/options/Button';
import { Grid, GridColumn, Button as ImportedButton, Menu, Icon } from 'svift-ui';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import { googleFontParser } from 'utils/fonts/googleFontParser';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import { tooltip } from 'components/tooltip/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';

const getValueOrDefault = (buttonPreset, preset, key) => {
	if (typeof buttonPreset[key] === 'undefined') {
		return preset.default[key];
	}

	return buttonPreset[key];
};

class Button extends PureComponent {
	constructor() {
		super();

		this.onChange = this.onChange.bind(this);

		this.getPresetOptions = (props) => {
			const buttonPreset = this.props.preset[this.props.buttonState];
			const extendsDefault = this.props.buttonState !== 'default';

			const boxShadow = getValueOrDefault(buttonPreset, this.props.preset, 'boxShadow');
			const backgroundColor = getValueOrDefault(buttonPreset, this.props.preset, 'backgroundColor');
			const color = getValueOrDefault(buttonPreset, this.props.preset, 'color');
			const font = getValueOrDefault(buttonPreset, this.props.preset, 'font');
			const border = getValueOrDefault(buttonPreset, this.props.preset, 'border');
			const borderRadius = getValueOrDefault(buttonPreset, this.props.preset, 'borderRadius');
			const margin = getValueOrDefault(buttonPreset, this.props.preset, 'margin');
			const fontWeight = props.preset.fontWeight; // update later

			const options = [
				<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="hand pointer" /><FormattedMessage id={createTranslationFromKey(props.presetTitle)} /></span>,
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="font"
						options={Object.keys(props.theme.fonts).map(fontKey => {
							return {
								text: `${this.props.intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => this.onChange('font', newFont.value)}
						value={font || {}}
						hoverContent={`${this.props.intl.formatMessage({ id: `options.font ${font} title` })} (${props.theme.fonts[font].family})`}
						option="color-picker"
						selectedOption={this.props.selectedPresetOption}
						onToggle={(select, e) => {
							this.props.selectPresetOption(select);

							if (e) {
								e.stopPropagation();
							}
						}}
					/>
				</Menu>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.background color title' })}
					key="color-picker-background"
					icons={['sn-paint-format']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('backgroundColor', newColor)}
					value={backgroundColor}

					option="color-picker-background"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Border
					key="border-style"
					value={border}
					onChange={newBorder => this.onChange('border', newBorder)}
					colors={this.props.theme.colors}

					option="border-style"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<BorderRadius
					max={240}
					key="border-radius"
					value={borderRadius}
					onChange={(key, newValue) => {
						if (key === 'generalBorderRadius') {
							if (newValue === null) {
								this.onChange('borderRadius', {});
							} else {
								this.onChange('borderRadius', {
									generalBorderRadius: newValue
								});
							}

							return;
						}

						const newBorderRadius = {
							...borderRadius,
							[key]: newValue
						};

						if (newValue === null) {
							delete newBorderRadius[key];
						}

						delete newBorderRadius.generalBorderRadius;

						this.onChange('borderRadius', newBorderRadius);
					}}
					colors={this.props.theme.colors}

					option="border-radius"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={buttonPreset}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						const value = buttonPreset.margin;

						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginBottom: 1
								});

								return;
							}

							this.onChange('margin', {
								generalMargin: newValue / 100
							});

							return;
						}

						const newMargin = {
							...value,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') newMargin.marginBottom = 1;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>,
				<Shadow
					key="shadow"
					value={boxShadow}
					colors={this.props.theme.colors}
					onChange={(newShadow) => {
						this.onChange('boxShadow', newShadow);
					}}

					option="Shadow"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>
			];

			if (extendsDefault) {
				options.push(
					<Icon
						style={{ fontSize: 16, marginLeft: 16, marginTop: 4 }}
						name="sn-cross"
						key="clear"
						link
						className="primary"
						key="clear"
						disabled={Object.keys(buttonPreset).length === 0}
						onClick={() => this.props.updatePresetOption(this.props.presetTitle, this.props.buttonState, {})}
					/>
				);
			}

			return options;
		};

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	// Because of its internal data structure (nested a layer deeper to accomodate 4 button states), buttons have to take some extra steps to align with update logic
	onChange(property, newValue) {
		const buttonState = this.props.buttonState;

		const value = {
			...this.props.preset[buttonState],
			[property]: newValue
		};

		if (newValue === null) {
			delete value[property];
		}

		this.props.updatePresetOption(this.props.presetTitle, buttonState, value);
	}

	getIdentifier() {
		return `${this.props.buttonState}${this.props.presetTitle}`; // Need a specific identifier because the selectable buttons are nested a layer deeper
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.getIdentifier() === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const selected = this.getIdentifier() === this.props.selectedPreset;

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.button title"
					open={selected}
					target={this.optionTooltipRef.current}//.getTarget()}
					options={this.getPresetOptions(this.props)}
				/>

				<PresetContainer
					selected={selected}
					disabled={this.props.liveMode}
					onClick={() => setTimeout(() => this.props.selectPreset(this.getIdentifier()), 0)}
					ref={this.optionTooltipRef}
				>
					<ImportedButton style={this.props.style}>
						<FormattedMessage id="elements.button dummy title" />
					</ImportedButton>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

const EnhancedButton = injectIntl(onClickOutside(Button));

class ButtonPresets extends PureComponent {
	render() {
		const { intl } = this.props;
		const computedStyles = computePreset(this.props.preset, this.props.theme);

		if (this.props.liveMode) {
			return (
				<div style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 53, marginBottom: 24 }}>
					<ImportedButton textAlign="center" style={computedStyles}>
						<FormattedMessage id="elements.button dummy title" />
					</ImportedButton>
				</div>
			);
		}

		return (
			<Grid columns={4} style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 0, marginBottom: 16 }}>
				{['Default', 'Hover', 'Active', 'Disabled'].map(buttonState => {
					switch (buttonState) {
						case 'Default':
							return {
								presetId: 'default',
								presetTitle: 'elements.button state default',
								presetDescription: 'elements.button state default description',
								buttonState
							};
						case 'Hover':
							return {
								presetId: 'hover',
								presetTitle: 'elements.button state hover',
								presetDescription: 'elements.button state hover description',
								buttonState
							};
						case 'Active':
							return {
								presetId: 'active',
								presetTitle: 'elements.button state active',
								presetDescription: 'elements.button state active description',
								buttonState
							};
						case 'Disabled':
							return {
								presetId: 'disabled',
								presetTitle: 'elements.button state disabled',
								presetDescription: 'elements.button state disabled description',
								buttonState
							};
					}
				}).map(({ buttonState, presetId, presetTitle, presetDescription }) => {
					let styles = {
						...computedStyles,
					};

					delete styles[':hover'];
					delete styles[':active'];
					delete styles[':disabled'];

					styles = {
						...styles,
						...computedStyles[':' + presetId]
					};

					return (
						<GridColumn key={buttonState} textAlign="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
							<p className="preset-title"><FormattedMessage id={presetTitle} /><InfoIcon description={intl.formatMessage({ id: presetDescription })} /></p>
							<EnhancedButton
								liveMode={this.props.liveMode}
								theme={this.props.theme}
								selectPresetOption={this.props.selectPresetOption}
								updatePresetOption={this.props.updatePresetOption}
								selectedPresetOption={this.props.selectedPresetOption}
								preset={this.props.preset}
								presetTitle={this.props.presetTitle}
								selectPreset={this.props.selectPreset}
								selectedPreset={this.props.selectedPreset}
								buttonState={presetId}
								style={styles}
							/>
						</GridColumn>
					);
				})}
			</Grid>
		);
	}
}

ButtonPresets.propTypes = {
	component: PropTypes.string,
	liveMode: PropTypes.bool,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string,
	updatePresetOption: PropTypes.func
};

export default injectIntl(ButtonPresets);