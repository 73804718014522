import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	Icon,
	Menu,
	Popup
} from 'svift-ui';
import Dropdown from 'components/option-panel/options/Dropdown';
import Slider from 'components/option-panel/options/Slider';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';
import PresetContainer from '../../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import { tooltip } from 'components/tooltip/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

class CheckboxPreset extends PureComponent {
	constructor() {
		super();

		this.getPresetOptionsLabel = (labelStyle) => {
			const intl = this.props.intl;
			const computedStyles = labelStyle;

			const presetValues = this.props.errorMode ? {
				...this.props.preset.label,
				...this.props.preset.error.label
			} : this.props.preset.label;

			const color = presetValues.color;
			const font = presetValues.font;
			const fontSize = presetValues.fontSize;
			const lineHeight = presetValues.lineHeight;
			const letterSpacing = presetValues.letterSpacing;
			const noLetterSpacing = typeof letterSpacing === 'undefined';

			const options = [
				<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-typography" /><FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} /></span>,
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="sn-typography"
						options={Object.keys(this.props.theme.fonts).map(fontKey => {
							return {
								text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${this.props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => {
							this.onChange('font', newFont.value, 'label');
						}}
						value={font}

						hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${this.props.theme.fonts[font].family})`}

						option="font-type-text"
						selectedOption={this.props.selectedPresetOption}
						onToggle={(select) => {
							this.props.selectPresetOption(select);
							//e.stopPropagation();
						}}
					/>
				</Menu>,
				<ColorPicker
					title={intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => {
						this.onChange('color', newColor /*{ key: newColor }*/, 'label');
					}}
					value={color}

					option="color-picker-text"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="font-size"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
					onChange={(newValue) => {
						this.onChange('fontSize', newValue / 100, 'label');
					}}
					value={fontSize * 100}
					min={25}
					max={350}

					option="fontSize-text"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="line-height"
					icons={['sn-line_weight', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${computedStyles.lineHeight})`}
					onChange={(newValue) => {
						this.onChange('lineHeight', newValue / 100, 'label');
					}}
					value={lineHeight * 100}
					min={50}
					max={300}

					option="options.lineheight title-text"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="letter-spacing"
					icons={['sn-typography', 'sn-arrow-resize3']}
					title={`${intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
					onChange={(newValue) => {
						this.onChange('letterSpacing', newValue, 'label');
					}}
					value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
					min={0}
					max={50}
					optIn
					onClear={noLetterSpacing ? () => null : () => {
						this.onChange('letterSpacing', null, 'label');
					}}

					option="letterSpacing-text"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={this.props.preset.label.margin}
					preset={this.props.preset.label}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginBottom: 1
								}, 'label');
							} else {
								this.onChange('margin', {
									generalMargin: newValue / 100
								}, 'label');
							}

							return;
						}

						const newMargin = {
							...this.props.preset.label.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') {
							newMargin.marginBottom = 1;
						}

						this.onChange('margin', newMargin, 'label');
					}}

					option="Margin-text"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>
			];

			return options;
		};

		this.getPresetOptionsCheckbox = (checkboxStyle) => {
			const options = [
				<Margin
					key="margin"
					value={this.props.preset.default.margin}
					preset={this.props.preset.default}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginBottom: 1
								}, 'default');
							} else {
								this.onChange('margin', {
									generalMargin: newValue / 100
								}, 'default');
							}

							return;
						}

						const newMargin = {
							...this.props.preset.default.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') {
							newMargin.marginBottom = 1;
						}

						this.onChange('margin', newMargin, 'default');
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>
			];

			return options;
		};

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }


	onChange(property, newValue, type) {
		let value;

		if (this.props.errorMode) {
			const errorType = type;

			value = {
				...this.props.preset.error,
				[errorType]: {
					...this.props.preset.error[errorType],
					[property]: newValue
				}
			};

			if (newValue === null) {
				delete value.error[errorType][property];
			}

			this.props.updatePresetOption(this.props.presetTitle, 'error', value);

			return;
		}

		value = {
			...this.props.preset[type],
			[property]: newValue
		};

		this.props.updatePresetOption(this.props.presetTitle, type, value);
	}

	render() {
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const checkboxStyle = computePreset(this.props.preset.default, this.props.theme);
		let labelStyle = computePreset(this.props.preset.label, this.props.theme);

		if (this.props.errorMode) {
			labelStyle = {
				...labelStyle,
				...computePreset(this.props.preset.error.label, this.props.theme)
			};
		}

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.image title"
					open={selected}
					target={this.optionTooltipRef.current}
				>
					<span className="editor-bar-title"><Icon name="sn-pencil7" style={{ opacity: 0.4 }} /><FormattedMessage id="elements.checkbox title" /></span>
					{this.getPresetOptionsCheckbox(checkboxStyle)}

					<div className="nested-panel">
						<span className="editor-bar-title"><Icon name="sn-flag6" style={{ opacity: 0.4 }} /><FormattedMessage id="forms.label title" /></span>
						{this.getPresetOptionsLabel(labelStyle)}
					</div>
				</OptionPanel>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
					ref={this.optionTooltipRef}
				>
					<div style={{ display: 'flex', maxWidth: 240, textAlign: 'left', marginLeft: 'auto', marginRight: 'auto' }}>
						<label htmlFor="text">
							<input
								name="text"
								type="checkbox"
								checked={false}
								style={checkboxStyle}
							/>
						</label>

						<p style={labelStyle}>
							<FormattedMessage id="elements.checkbox label dummy title" /> <br />

							{this.props.errorMode && `*${this.props.intl.formatMessage({ id: 'elements.validation label dummy title' })}`}
						</p>
					</div>
				</PresetContainer>

			</React.Fragment>
		);
	}
}

CheckboxPreset.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(CheckboxPreset));
