/*
	To really optimize finding the right locale, we can also look at Accept-Language http header and return that information from backend when we request system settings
*/
const determineLocale = () => {
	const clientLocale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.browserLanguage;

	/*
		Catch-all for browsers specifiying 'en-US' rather than 'en'; we only have one version of english translations
	*/
	if (clientLocale.startsWith('en')) {
		return 'en';
	}

	if (clientLocale.startsWith('da')) {
		return 'da';
	}

	return clientLocale;

	// Only use the clients locale if it's available
	// if (['da', 'en'].find(availableLocale => availableLocale === clientLocale)) {
	//	return clientLocale;
	//}

	//return null;
};

module.exports = determineLocale;