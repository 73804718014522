// "label": {
//     "margin": {
//         "marginLeft": 0.5,
//         "marginRight": 0.5
//     },
//     "color": {
//         "key": "shade"
//     },
//     "fontSize": 1,
//     "font": "primary",
//     "lineHeight": 1,
//     "letterSpacing": 0
// }
import React, { PureComponent } from 'react';
import { Grid, GridColumn, Icon, Menu, Button, ButtonGroup } from 'svift-ui';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
// import { computePreset } from 'svift-library/client/renderer/utils/computePreset';from 'components/preset/util';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import PresetContainer from '../../PresetContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Dropdown from 'components/option-panel/options/Dropdown';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Slider from 'components/option-panel/options/Slider';

class DividerLabelPresets extends PureComponent {
	constructor(props) {
		super(props);

		this.getPresetOptions = () => {
			const { intl } = this.props; 
			const { margin, color, fontSize, font, letterSpacing, lineHeight } = this.props.preset.label;

            const computedStyles = computePreset(this.props.preset.label, this.props.theme);
            const noLetterSpacing = typeof letterSpacing === 'undefined';

			return [
                <Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
                    <Dropdown
                        unwrapped
                        key="font-type"
                        icon="sn-typography"
                        options={Object.keys(this.props.theme.fonts).map(fontKey => {
                            return {
                                text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${this.props.theme.fonts[fontKey].family})`,
                                value: fontKey
                            };
                        })}
                        onChange={(newFont) => {
                            this.onChange('font', newFont.value);
                        }}
                        value={font}

                        hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${this.props.theme.fonts[font].family})`}

                        option="font-type"
                        selectedOption={this.props.selectedPresetOption}
                        onToggle={(select) => {
                            this.props.selectPresetOption(select);
                            //e.stopPropagation();
                        }}
                    />
                </Menu>,
                <Slider
                    key="line-height"
                    icons={['sn-line_weight', 'sn-arrow-resize4']}
                    title={`${intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${computedStyles.lineHeight})`}
                    onChange={(newValue) => {
                        this.onChange('lineHeight', newValue / 100);
                        // props.updatePresetOption(props.presetTitle, 'lineHeight', newValue / 100);
                    }}
                    value={lineHeight * 100}
                    min={50}
                    max={300}

                    option="options.lineheight title"
                    selectedOption={this.props.selectedPresetOption}
                    onToggle={(select, e) => {
                        this.props.selectPresetOption(select);
                        e.stopPropagation();
                    }}
                />,
                <Slider
                    key="letter-spacing"
                    icons={['sn-typography', 'sn-arrow-resize3']}
                    title={`${intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
                    onChange={(newValue) => {
                        this.onChange('letterSpacing', newValue);
                        // props.updatePresetOption(props.presetTitle, 'letterSpacing', newValue);
                    }}
                    value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
                    min={0}
                    max={50}
                    optIn
                    onClear={noLetterSpacing ? noop : () => {
                        this.onChange('letterSpacing', null);
                        // props.updatePresetOption(props.presetTitle, 'letterSpacing', null);
                    }}

                    option="letterSpacing"
                    selectedOption={this.props.selectedPresetOption}
                    onToggle={(select, e) => {
                        this.props.selectPresetOption(select);
                        e.stopPropagation();
                    }}
                />,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.divider icon color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={this.props.preset.label}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						const value = this.props.preset.label.margin;

						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginLeft: 5,
									marginRight: 5
								});

								return;
							}

							this.onChange('margin', {
								generalMargin: newValue / 100
							});

							return;
						}

						const newMargin = {
							...value,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					// optIn={{
					// 	marginBottom: false,
					// 	marginTop: true,
					// 	marginLeft: true,
					// 	marginRight: true,
					// 	generalMargin: true
					// }}
				/>,
				<Slider
					key="font-size"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
					onChange={(newValue) => {
						this.onChange('fontSize', newValue / 100);
					}}
					value={fontSize * 100}
					min={25}
					max={350}
					option="fontSize"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>
			];
		}

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}
	
	getIdentifier() {
		return `DividerLabel${this.props.presetTitle}`; // Need a specific identifier because the selectable buttons are nested a layer deeper
	}

	onChange = (property, newValue) => {
		const newLabelPreset = {
			...this.props.preset.label,
			[property]: newValue
		}
		this.props.updatePresetOption(this.props.presetTitle, 'label', newLabelPreset);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const id = this.getIdentifier();
        const selected = id === this.props.selectedPreset;

		const computedStyles = {
			...computePreset(this.props.preset.label, this.props.theme)
		};

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.section title"
					options={this.getPresetOptions(computedStyles)} 
					open={selected}
					target={this.optionTooltipRef.current}
				/>

				<p className="preset-title">
					<FormattedMessage id="elements.label title" />
				</p>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(id), 0)}
					ref={this.optionTooltipRef}
				>
					<span style={computedStyles}>{this.props.intl.formatMessage({ id: 'elements.label example title' })} </span>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

DividerLabelPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(DividerLabelPresets));