import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { findDOMNode } from 'react-dom';
import Slider from '../../slider';
import Dropdown from '../../dropdown/simple';
import PropTypes from 'prop-types';
import Tooltip from 'svift-library/client/tooltip';
import css from './number.scss';
import { Input, Label, Icon } from 'svift-ui';

const NumberPicker = props => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const inputRef = useRef();
    const tooltipRef = useRef();

    const onChange = useCallback(e => {
        // if (!tooltipOpen) return;

        const number = parseFloat(e.target.value); //parseInt(e.target.value);

        // console.log(typeof number, number, 'number');

        if (isNaN(number)) return props.onChange(undefined, e);

        props.onChange(number, e);
    }, [props.onChange, tooltipOpen]);

    const onChangeSlider = useCallback(number => {
        props.onChange(number);
    }, [props.onChange])

    useEffect(() => {
        if (tooltipOpen) {
            const handler = e => {
                if (tooltipRef.current) {
                    const tooltipContainer = tooltipRef.current.getContainer();
                    const inputContainer = findDOMNode(inputRef.current); // kill this later when we no longer use SUI
                
                    if (tooltipContainer.contains(e.target) || inputContainer.contains(e.target)) {
                        return;
                    }
                }

                // const target = e.target;

                // const clickExceptions = document.body.querySelectorAll('[data-ignore-click-internal]');

                // const clickedOnAnException = [].slice.call(clickExceptions).some(container => container.contains(target));

                // if (clickedOnAnException) {
                //     return;
                // }

                setTooltipOpen(false);

                // props.onBlur();
            }
            
            document.addEventListener('click', handler);
            
            return () => {
                document.removeEventListener('click', handler);
            }
        }
    }, [tooltipOpen]);

    /*
        Cutting corners here; probably some minor bugs and weird experiences waiting to happen here - fix later if there is a need
    */
    useEffect(() => {
        if (tooltipOpen) {
            return props.onBlur;
        }
    }, [tooltipOpen]);

    // console.log(tooltipOpen, 'tooltip open')

    // const [id, blabla] = useRef(() => id++);

    // We use dom attributes to check if we have to ignore click events
    // const attributes = {
    //     'data-ignore-internal-click': instanceID
    // };

    return (
        <React.Fragment>
            {!props.noSlider && 
                <Tooltip
                    ref={tooltipRef}
                    target={inputRef.current}
                    position={props.tooltipPosition}
                    open={tooltipOpen}
                    popup
                    clearance={12}
                    nodeAttributes={{ 
                        'data-ignore-click': true
                    }}
                    zIndex={1000000}
                >
                    {props.name &&
                        <div style={{ textAlign: 'center', marginBottom: 4 }}>
                            <label>
                                {props.icon && <Icon name={props.icon} style={{ marginRight: 6 }} />}
                                {props.name}
                            </label>
                        </div>
                    }

                    <div className={css['range-indicator']}>
                        <Label basic size="mini" style={{ zIndex: 2, margin: 0 }}>
                            {props.min}
                        </Label>
                        <Label className="primary" size="small" style={{ zIndex: 2, margin: 0 }}>
                            {props.value ? props.value : 0}
                            {props.unit && 
                                <span style={{ marginLeft: 2, opacity: 0.7 }}>
                                    {props.unit}
                                </span>
                            }
                        </Label>
                        <Label basic size="mini" style={{ zIndex: 2, margin: 0 }}>
                            {props.max}
                        </Label>
                    </div>

                    <Slider
                        {...props}
                        onChange={onChangeSlider}
                        style={{ marginLeft: 8, marginRight: 8, marginBottom: 6, width: 'calc(100% - 16px)' }}
                    />  
                </Tooltip>
            }

            <Input
                type="number"
                ref={inputRef}
                data-ignore-click-internal="true"
                size={props.size || 'mini'}
                fluid={props.fluid || true}
                value={isNaN(props.value) ? '' : props.value}
                // icon={props.icon} // revisit - sometimes icon is only visible in label (Tooltip)
                label={props.units 
                    ? 
                        <Dropdown
                            style={{ width: '64px', borderTopLeftRadius: '0px' }}
                            items={props.units.map(unit => {
                                return {
                                    value: unit,
                                    text: unit
                                };
                            })}
                            value={props.unit || props.units[0]}
                            onChange={props.onUnitChange}
                        />
                    :
                        props.unit
                }
                labelPosition={(props.unit || props.units) && 'right'}
                placeholder={props.placeholder} 
                onChange={onChange}
                // Couldnt make this work and no time to spend more time on it, but would be a neat feature
                // Basically the idea is to let us immediately refocus the input field automatically when using the slider, 
                // so you don't have to click forth and back
                // onFocus={e => console.log('focused')}
                // onBlur={e => {
                //     console.log('blur')
                //     setTimeout(() => {
                //         if (tooltipOpen) {
                //             // SUI forces me to use findDOMNode - remove it later
                //             const inputElement = findDOMNode(inputRef.current);

                //             console.dir(inputElement, 'input ele')

                //             inputElement.focus();
                //         }
                //     }, 20)
                // }}
                onClick={() => setTooltipOpen(true)}
                onKeyDown={e => {
                    if (props.disableUndoRedo) {
                        if (e.ctrlKey) {
                            if (e.keyCode === 90 || e.keyCode === 89) {
                                e.preventDefault()
                            }
                        }
                    }
                }}
                // onFocus={() => setTooltipOpen(true)}
                // onBlur={e => {
                //     props.onBlur()
                // }}//console.log('blur') || setTooltipOpen(false)}
                // className={css['number-picker']} TO DO: styling inputs via CSS Module
                style={props.style}
            />
        </React.Fragment>
    );
};

NumberPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    unit: PropTypes.string,
    units: PropTypes.array
};

export default NumberPicker;