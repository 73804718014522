import React from 'react';
import css from './file-preview.scss';
import { connect } from 'react-redux';
import { filesDictionarySelector } from 'store/selectors/sites/files';
import { Label, Message, Icon } from 'svift-ui';
import SmartImage from 'svift-library/client/image';
import { getIconByMimeType } from 'utils/files/format';

const resolveFileInfo = file => {
    switch (file && file.provider) {
        case 'youtube': {
            return {
                title: file.video_id,
                type: 'YouTube',
                renderPreview: () => {
                    return (
                        <Message className="info" style={{ textAlign: 'center' }}>
                            <Icon name="sn-youtube" style={{ fontSize: 80, margin: 0 }} />
                        </Message>
                    );
                }
            };
        }

        default: {
            /*
                Files can be two things:
                    1) a string (file ID)
                    2) an object with a provider key

                If no providers were matched and file is defined, we can assume "file" is an id referencing a user uploaded file

                If file is neither a string or an object (maybe the file was deleted or something), we return default values (user can then choose to remove it)
            */

            if (file) {
                if (file.meta.mimeType.startsWith('image')) {
                    return {
                        title: file.meta.name,
                        type: file.meta.mimeType,
                        renderPreview: () => {
                            return (
                                // 'INCOMING'
                                <SmartImage
                                    file={file}
                                    style={{ maxWidth: '100%', margin: 'auto' }}
                                />
                                // TO DO: replace with component that supports serving_URLs + connect to selected file
                                // <Image src='https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg' />
                            );
                        }
                    };
                } else if (file.meta.mimeType.startsWith('video')) {
                    return {
                        title: file.meta.name,
                        type: file.meta.mimeType,
                        renderPreview: () => {
                            return (
                                <Message className="info" style={{ textAlign: 'center' }}>
                                    <Icon name="sn-film4" style={{ fontSize: 80, margin: 0 }} />
                                </Message>
                            );
                        }
                    };
                }

                // return other file types
                return {
                    title: file.meta.name,
                    type: file.meta.mimeType,
                    renderPreview: () => {
                        return (
                            <Message className="info" style={{ textAlign: 'center' }}>
                                <Icon name={getIconByMimeType(file.meta.mimeType)} style={{ fontSize: 80, margin: 0 }} />
                            </Message>
                        );
                    }
                };
            }

            // transl8
            return {
                title: 'File not found',
                renderPreview: () => {
                    return (
                        <Message className="info" style={{ textAlign: 'center' }}>
                            File not found
                            {/* <Icon name={getIconByMimeType(file.meta.mimeType)} style={{ fontSize: 80, margin: 0 }} /> */}
                        </Message>
                    );
                }
            };
        }
    }
}

const mapStateToProps = state => {
	return {
		filesDictionary: filesDictionarySelector(state, state.sites.params.siteid)
	};
};

const FilePreview = ({ file, renderActions, filesDictionary, multiple, dragRef, isDragging }) => {
    let resolvedFile = file;

    const isFile = typeof file === 'string';

    if (isFile) resolvedFile = filesDictionary[file];

    const {
        title,
        type,
        renderPreview
    } = resolveFileInfo(resolvedFile);

    const previewStyle = {};

    if (dragRef) previewStyle.cursor = 'move';
    if (isDragging) previewStyle.opacity = 0;

    if (multiple) {
        return (
            <div ref={dragRef} className={css['preview-container-slim']} title={dragRef && 'Move file to another location'} style={previewStyle}>
                 <div className={css['file-preview']}>
                    {renderPreview()}
                </div>

                {/* transl8 */}
                {(resolvedFile && resolvedFile.meta && resolvedFile.meta.name) || 'intet navn'}

                {/* file actions */}
                {renderActions && renderActions()}
            </div>
        );
    }

    return (
        <div ref={dragRef} className={css['preview-container']} style={previewStyle}>
            {/* file preview */}
            <div className={css['file-preview']}>
                {/* Example: image preview would be a small visualization of the selected image */}
                {renderPreview()}
            </div>

            {/* file meta info */}
            <div className={css['file-title']}>
                {title}
                {/* TO DO: add meta info in a InfoIcon after file name */}
            </div>
            
            {type && 
                <div className={css['file-type']}>
                    <Label basic size="mini" className="secondary">
                        {type}
                    </Label>
                </div>
            }

            {/* file actions */}
            {renderActions && renderActions()}
        </div>
    );
}

export default connect(mapStateToProps)(FilePreview);
