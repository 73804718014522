import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import Slider from 'components/option-panel/options/Slider';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Dropdown from 'components/option-panel/options/Dropdown';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Toggle from 'components/option-panel/options/Toggle';
import PropTypes from 'prop-types';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import radium from 'radium';
import { Grid, GridColumn, Icon, Menu, Button, ButtonGroup } from 'svift-ui';
import { googleFontParser/*, toFontWeight*/ } from 'utils/fonts/googleFontParser';
import { tooltip } from 'components/tooltip/Tooltip';
import InfoIcon from 'components/InfoIcon';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import { FormattedMessage, injectIntl } from 'react-intl';
//import DashboardEditor from './DashboardEditor';

const getValueOrDefault = (linkPreset, preset, key) => {
	if (typeof linkPreset[key] === 'undefined') {
		return preset.default[key];
	}

	return linkPreset[key];
};

const createDummyContentFromPreset = presetTitle => {
	return `elements.${presetTitle.toLowerCase()} text dummy content`;
};

const noop = () => undefined;

class Text extends PureComponent {
	constructor() {
		super();

		this.elementResolver = (key) => {
			switch (key) {
				case 'title':
				case 'h1':
					return 'h1';
				case 'h2':
					return 'h2';
				case 'h3':
					return 'h3';
				case 'h4':
					return 'h4';
				case 'h5':
					return 'h5';
				case 'h6':
					return 'h6';
				case 'link':
					return 'a';
				default:
					return 'p';
			}
		};

		this.getPresetOptions = (props, computedStyles) => {
			const { intl } = this.props;
			const color = props.preset.color;
			const font = props.preset.font;
			const fontWeight = props.preset.fontWeight; 
			const fontSize = props.preset.fontSize;
			const lineHeight = props.preset.lineHeight;
			const letterSpacing = props.preset.letterSpacing;

			const noLetterSpacing = typeof letterSpacing === 'undefined';

			const options = [
				<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-typography" /><FormattedMessage id={createTranslationFromKey(props.presetTitle)} /></span>,
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="sn-typography"
						options={Object.keys(props.theme.fonts).map(fontKey => {
							return {
								text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => {
							props.updatePresetOption(props.presetTitle, 'font', newFont.value);
						}}
						value={font}

						hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${props.theme.fonts[font].family})`}

						option="font-type"
						selectedOption={props.selectedPresetOption}
						onToggle={(select) => {
							props.selectPresetOption(select);
							//e.stopPropagation();
						}}
					/>

					<Dropdown
						unwrapped
						key="font-weight"
						options={Array(9).fill().map((_, i) => {
							const fontWeight = (i + 1) * 100;
							
							return {
								text: fontWeight,
								value: fontWeight
							};
						})}
						onChange={(newFontWeight) => {
							props.updatePresetOption(props.presetTitle, 'fontWeight', newFontWeight.value);
						}}
						value={fontWeight}

						hoverContent={intl.formatMessage({ id: 'options.font weight' })}

						option="font-weight"
						selectedOption={props.selectedPresetOption}
						onToggle={(select) => {
							props.selectPresetOption(select);
							//e.stopPropagation();
						}}
					/>
				</Menu>,
				<ColorPicker
					title={intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker"
					icons={['sn-typography']}
					colors={props.theme.colors}
					onChange={(newColor) => {
						props.updatePresetOption(props.presetTitle, 'color', newColor /*{ key: newColor }*/);
					}}
					value={color}

					option="color-picker"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="font-size"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
					onChange={(newValue) => {
						props.updatePresetOption(props.presetTitle, 'fontSize', newValue / 100);
					}}
					value={fontSize * 100}
					min={25}
					max={350}

					option="fontSize"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="line-height"
					icons={['sn-line_weight', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.line height title' })}: ${Math.floor(lineHeight * 100)} % (${computedStyles.lineHeight})`}
					onChange={(newValue) => {
						props.updatePresetOption(props.presetTitle, 'lineHeight', newValue / 100);
					}}
					value={lineHeight * 100}
					min={50}
					max={300}

					option="options.lineheight title"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Slider
					key="letter-spacing"
					icons={['sn-typography', 'sn-arrow-resize3']}
					title={`${intl.formatMessage({ id: 'options.letter spacing title' })}${noLetterSpacing ? '' : `: ${letterSpacing} px`}`}
					onChange={(newValue) => {
						props.updatePresetOption(props.presetTitle, 'letterSpacing', newValue);
					}}
					value={noLetterSpacing ? 1 : letterSpacing} // no clue why, but if I set it to 0, the slider handle doesnt reset... it works if I set it to 1
					min={0}
					max={50}
					optIn
					onClear={noLetterSpacing ? noop : () => {
						props.updatePresetOption(props.presetTitle, 'letterSpacing', null);
					}}

					option="letterSpacing"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Toggle
					key="underline"
					icons={['underline']}
					hoverContent="Toggle underline"
					toggled={props.preset.textDecoration === 'underline'}
					onToggle={(toggled) => props.updatePresetOption(props.presetTitle, 'textDecoration', toggled ? 'underline' : null)}
				/>,
				<Toggle
					key="italic"
					icons={['italic']}
					hoverContent="Toggle italic"
					toggled={props.preset.fontStyle === 'italic'}
					onToggle={(toggled) => props.updatePresetOption(props.presetTitle, 'fontStyle', toggled ? 'italic' : null)}
				/>,
				<Toggle
					key="uppercase"
					icons={['uppercase']}
					hoverContent="Toggle uppercase"
					toggled={props.preset.textTransform === 'uppercase'}
					onToggle={(toggled) => props.updatePresetOption(props.presetTitle, 'textTransform', toggled ? 'uppercase' : null)}
				/>,
				<Margin
					key="margin"
					value={props.preset.margin}
					preset={props.preset}
					theme={props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								props.updatePresetOption(props.presetTitle, 'margin', {
									marginBottom: 1
								});
							} else {
								props.updatePresetOption(props.presetTitle, 'margin', {
									generalMargin: newValue / 100
								});
							}

							return;
						}

						const newMargin = {
							...props.preset.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						if (typeof newMargin.marginBottom === 'undefined') {
							newMargin.marginBottom = 1;
						}

						props.updatePresetOption(props.presetTitle, 'margin', newMargin);
					}}

					option="Margin"
					selectedOption={props.selectedPresetOption}
					onToggle={(select, e) => {
						props.selectPresetOption(select);
						e.stopPropagation();
					}}

					optIn={{
						marginBottom: false,
						marginTop: true,
						marginLeft: true,
						marginRight: true,
						generalMargin: true
					}}
				/>
			];

			return options;
		};

		// this.optionTooltip = tooltip.call(this);

		this.optionTooltipRef = React.createRef();
	}

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }


	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.presetTitle === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	render() {
		const { presetTitle, preset, selectedPreset, theme, view } = this.props;

		// if (view === 'preview') {
		// 	return  <DashboardEditor/>
		// }

		if (presetTitle === 'Link') return <LinkTypes {...this.props} />;

		const selected = presetTitle === selectedPreset;

		// if (presetTitle === 'Normal') console.log(selected, this.props.selectedPresetOption, 'selected');

		const computedStyles = computePreset(preset, theme);

		const element = React.createElement(this.elementResolver(presetTitle), {
			style: computedStyles
		},
			<FormattedMessage id={createDummyContentFromPreset(presetTitle)} />
		);

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.text title"
					// target={this.optionTooltip.getTarget()}
					target={this.optionTooltipRef.current}
					open={selected}
					options={this.getPresetOptions(this.props, computedStyles)}
				/>

				<PresetContainer
					selected={selected}
					onClick={e => {
						// console.log(presetTitle, 'preset title');
						// this.props.selectPreset(presetTitle)
						setTimeout(() => this.props.selectPreset(presetTitle), 0);
						
						// e.stopPropagation();
					}}
					ref={this.optionTooltipRef}
				>
					<div style={{ maxWidth: '70%', textAlign: 'left', margin: 'auto', borderLeft: '1px dashed lightgrey', borderRight: '1px dashed lightgrey' }}>
						{element}
					</div>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

// Link doesn't follow the regular Text pattern because it has multiple states - it gets it's own component
class LinkTypes extends PureComponent {
	render() {
		const { intl } = this.props;
		if (this.props.liveMode) {
			const stylesWithButtonStates = computePreset(this.props.preset.default, this.props.theme);

			stylesWithButtonStates[':hover'] = computePreset(this.props.preset.hover, this.props.theme);
			stylesWithButtonStates[':active'] = computePreset(this.props.preset.active, this.props.theme);
			stylesWithButtonStates[':disabled'] = computePreset(this.props.preset.disabled, this.props.theme);

			return (
				<EnhancedStandaloneLink style={stylesWithButtonStates}>
					<FormattedMessage id="elements.link text dummy content" />
				</EnhancedStandaloneLink>
			);
		}

		return (
			<Grid columns={4} style={{ marginTop: 0, marginBottom: 24 }}>
				{['Default', 'Hover', 'Active', 'Disabled'].map(linkState => {
					switch (linkState) {
						case 'Default':
							return {
								presetId: 'default',
								presetTitle: 'elements.link state default',
								presetDescription: 'elements.link state default description',
								linkState
							};
						case 'Hover':
							return {
								presetId: 'hover',
								presetTitle: 'elements.link state hover',
								presetDescription: 'elements.link state hover description',
								linkState
							};
						case 'Active':
							return {
								presetId: 'active',
								presetTitle: 'elements.link state active',
								presetDescription: 'elements.link state active description',
								linkState
							};
						case 'Disabled':
							return {
								presetId: 'disabled',
								presetTitle: 'elements.link state disabled',
								presetDescription: 'elements.link state disabled description',
								linkState
							};
					}
				}).map(({ linkState, presetId, presetTitle, presetDescription }) => {
					const computedStyles = {
						...computePreset(this.props.preset.default, this.props.theme),
						...computePreset(this.props.preset[presetId], this.props.theme)
					};

					return (
						<GridColumn key={linkState} textAlign="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
							<p className="preset-title"><FormattedMessage id={presetTitle} /><InfoIcon description={intl.formatMessage({ id: presetDescription })} /></p>
							{/* <p>{ linkState }</p> */}
							<EnhancedLink
								{...this.props}
								style={computedStyles}
								linkState={presetId}
							/>
						</GridColumn>
					);
				})}
			</Grid>
		);
	}
}

class Link extends PureComponent {
	constructor() {
		super();

		this.updateLinkOption = this.updateLinkOption.bind(this);

		this.getPresetOptions = () => {
			const extendsDefault = this.props.linkState !== 'default';

			const linkPreset = this.props.preset[this.props.linkState];

			const color = getValueOrDefault(linkPreset, this.props.preset, 'color');

			const options = [
				<span style={{ marginRight: 16, opacity: 0.6 }}>
					<Icon name="sn-link" /> <FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} />
				</span>,
				<ColorPicker
					key="color-picker"
					title="options.color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => {
						this.updateLinkOption('color', newColor);
					}}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<ButtonGroup size="tiny">
					<Button basic icon="bold" />
					<Button basic icon="italic" />
					<Toggle
						key="underline"
						icons={['underline']}
						hoverContent="Toggle underline"
						toggled={!!linkPreset.textDecoration}
						onToggle={(toggled) => this.updateLinkOption('textDecoration', !toggled ? null : 'underline')}
					/>
				</ButtonGroup>
			];

			if (extendsDefault) {
				options.push(
					<Icon
						disabled={Object.keys(this.props.preset[this.props.linkState]).length === 0}
						name="sn-cross2"
						key="clear"
						link
						className="primary"
						onClick={() => this.props.updatePresetOption(this.props.presetTitle, this.props.linkState, {})}
						style={{ fontSize: 16, marginLeft: 16, marginTop: 4 }}
					/>
				);
			}

			return options;
		};

		// this.optionTooltip = tooltip.call(this);
		this.optionTooltipRef = React.createRef();
	}

	updateLinkOption(property, newValue) {
		const linkState = this.props.linkState;

		const value = {
			...this.props.preset[linkState],
			[property]: newValue
		};

		if (newValue === null) {
			delete value[property];
		}

		this.props.updatePresetOption(this.props.presetTitle, linkState, value);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			// console.log(e, "??")
			this.props.selectPreset(null);
		}
    }

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.getIdentifier() === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	getIdentifier() {
		return `${this.props.linkState}${this.props.presetTitle}`;
	}

	render() {
		const selected = this.props.selectedPreset === this.getIdentifier();

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.text title"
					options={this.getPresetOptions()}
					open={selected}
					target={this.optionTooltipRef.current/*getTarget()*/}
				/>

				<PresetContainer
					selected={selected}
					onClick={() => this.props.selectPreset(this.getIdentifier(this.props.linkState))}
					// ref={this.optionTooltip.setTarget}
					ref={this.optionTooltipRef}
				>
					<a style={this.props.style}><FormattedMessage id="elements.link text dummy content" /></a>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

const StandaloneLink = (props) => {
	return <a style={props.style}>{props.children}</a>;
};

const EnhancedStandaloneLink = radium(StandaloneLink);
const EnhancedLink = onClickOutside(Link);

Text.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(Text));
