module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Big',
            style: {
                backgroundColor: {
                    key: 'primary'
                }
            },
            backgroundImage: null
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Big',
                    horizontalAlign: 'center'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 8,
                        props: {
                            style: {
                                backgroundColor: {
                                    key: 'tint',
                                    opacity: 0.85
                                },
                                padding: {
                                    generalPadding: 0.99
                                }
                            },
                            preset: 'Big'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Hero med baggrund!',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'center',
                                    style: {}
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Lad baggrunden tale!',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    textAlignment: 'center'
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Valg af farver og skrifttyper har stor betydning for, hvordan teksten på hjemmesiden opleves. Sørg altid for at baggrunden fremhæver ordene. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Button',
                                props: {
                                    style: {},
                                    preset: 'Primary',
                                    text: 'Call to action',
                                    size: 'huge'
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
