import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const styles = {
    container: {
        marginTop: 15,
        // paddingTop: 15,
        borderTop: 'solid 1px rgba(0, 0, 0, 0.20)',
        // justifyContent: 'space-between',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

class Pagination extends PureComponent {
    setOffset = direction => {
        let newOffset;

        if (direction === 'left') {
            newOffset = this.props.offset - this.props.itemsPerPage;
        } else {
            newOffset = this.props.offset + this.props.itemsPerPage;
        }

        this.props.onOffsetChange(newOffset);
    };

    renderHandle = direction => {
        let canClick;

        if (direction === 'left') {
            const notFirstPage = this.props.offset > 0;

            canClick = notFirstPage;
        } else if (direction === 'right') {
            const itemsRemaining = this.props.itemCount - this.props.offset;

            if (this.props.itemsPerPage >= itemsRemaining) {
                canClick = false;
            } else {
                canClick = true;
            }
        }

        return this.props.renderHandle(direction, canClick, () => {
            if (canClick) this.setOffset(direction);
        });
    };

    renderPageNumber = () => {
        const pageNumber = this.props.offset / this.props.itemsPerPage;

        return this.props.renderPageNumber(pageNumber + 1);
    };

    render() {
        if (
            isNaN(this.props.itemsPerPage) ||
            this.props.itemsPerPage >= this.props.itemCount
        )
            return null;

        return (
            <div style={styles.container}>
                {this.renderHandle('left')}

                {/* <hr
                    style={{
                        flexGrow: 1,
                        border: 'dashed 1px rgba(0, 0, 0, 0.10)',
                        margin: '0 10px'
                    }}
                />

                {this.renderPageNumber()}

                <hr
                    style={{
                        flexGrow: 1,
                        border: 'dashed 1px rgba(0, 0, 0, 0.10)',
                        margin: '0 10px'
                    }}
                /> */}

                {this.renderHandle('right')}
            </div>
        );
    }
}

Pagination.propTypes = {
    offset: PropTypes.number,
    itemsPerPage: PropTypes.number,
    itemCount: PropTypes.number,
    onOffsetChange: PropTypes.func
};

export default Pagination;
