import React from 'react';
import css from './notification.scss';

// TO DO: import Message + Icon component from local Library
import { Message, Icon} from 'svift-ui';

class NotificationMessage extends React.Component {
	constructor() {
		super();

		this.state = {
			visible: true
		};
	}

	componentDidMount() {
		const { deleteTime, id } = this.props.notification;

		if (deleteTime || deleteTime === 0) {
			setTimeout(() => {
				this.delete(id);
			}, deleteTime);
		}
	}

	delete(id) { 
		this.props.onClick(id);
	}

	render() {
		const {
			icon,
			color,
			id,
			content,
			className
		} = this.props.notification;
		
		const classNames  = ["sn-message-notification", css['animated'], css['fadeInDown'], css['faster']];
		className && classNames.unshift(className);
	
		return (
			<Message
				className= {classNames.join(` `)}
				color={color}
				size= 'small'
				onClick={() => this.delete(id)}
				style={{ pointerEvents: 'all' }}
			>
				{icon &&
					<Icon
						name={icon}
						style={{ marginRight: 8 }}
					/>
				}

				{content}

				<Icon
					link
					name="close"
					style={{
						marginLeft: 'auto',
						marginRight: 0,
						fontSize: 16
					}}
				/>
			</Message>
		);
	}
}

export default NotificationMessage;
