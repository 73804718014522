import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/user';
import wizardReducer from './reducers/domainWizard';
import domainSearchReducer from './reducers/domainSearch';
import sitesReducer from './reducers/sites';
import systemReducer from './reducers/system';
import dndReducer from './reducers/dnd';
import uiReducer from './reducers/ui';
import sizeReducer from './reducers/size';
import editorReducer from './reducers/editor';

// REDUX FORMS REDUCER
import { reducer as formReducer } from 'redux-form';
import notificationsReducer from 'svift-library/client/notifications/reducer';

import browserHistory from 'utils/routing/browserHistory';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import sectionsReducer from 'components/sections/redux/reducer';

const createAppReducer = history => combineReducers({
	editor: editorReducer,
	user: userReducer,
	wizard: wizardReducer,
	domainsearch: domainSearchReducer,
	sites: sitesReducer,
	system: systemReducer,
	ui: uiReducer,
	dnd: dndReducer,
	sections: sectionsReducer,
	size: sizeReducer,
	form: formReducer,
    router: connectRouter(history),
    notifications: notificationsReducer
});

const staticReducers = createAppReducer(browserHistory);

const rootReducer = (state, action) => {
	if (action.payload &&
		action.payload.location &&
		action.payload.location.state &&
		action.payload.location.state.data &&
		action.payload.location.state.data[0] &&
		action.payload.location.state.data[0].isLogout
	) {
		/*
			We don't have full control of how connected-react-router updates its location state, so we allow regular state changes first, and then
			afterwards we fire another 'fake' state change with type 'LOGOUT' specified to get the default state from all reducers
		*/

		// state = staticReducers(state, action);

		// state = {
		// 	system: state.system,
		// };

		// state = rootReducer(state, { type: 'LOGOUT' });

		// return state;
		// console.log(rootReducer(staticReducers(state, action), { type: 'LOGOUT' }), 'Getting here step one')
		return rootReducer(staticReducers(state, action), { type: 'LOGOUT' });
	}

	if (action.payload &&
		action.payload.location &&
		action.payload.location.state &&
		action.payload.location.state.data &&
		action.payload.location.state.data[0] &&
		action.payload.location.state.data[0].delete
	) {
		/*
			Deleting and rerouting in the same render cycle prevents double render, meaning we don't have to deal with incomplete state
		*/
		// state = staticReducers(state, action);

		return rootReducer(staticReducers(state, action), { type: action.payload.location.state.data[0].delete, data: action.payload.location.state.data[0].data });
	}

	// console.log('static reducers called')

	return staticReducers(state, action);
};

// const defaultState = {};

const composeEnhancers = /*process.env.NODE_ENV !== 'production' && */window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // Remember to remove devtools in production build

const enhancers = composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk));

// const store = createStore(staticReducers, defaultState, enhancers);
// const store = createStore(connectRouter(browserHistory)(rootReducer), defaultState, enhancers);

export default () => {
    const initialState = {};
    const store = createStore(rootReducer, initialState, enhancers);

    // Add a dictionary to keep track of the registered async reducers
    // store.asyncReducers = {}
   
    // // Create an inject reducer function
    // // This function adds the async reducer, and creates a new combined reducer
    // store.injectReducer = (key, asyncReducer) => {
    //     store.asyncReducers[key] = asyncReducer
    //     store.replaceReducer({
	// 		// ...rootReducer,
    //         // ...staticReducers,
    //         ...asyncReducers
    //     })
    // }
   
    return store;
};