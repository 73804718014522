import domainSearch from './domainSearch';
import domainWizard from './domainWizard';
import sites from './sites';
import user from './user';

const actions = {
	domainSearch,
	domainWizard,
	sites,
	user
};

export default actions;