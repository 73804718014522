import React, { Component } from 'react'
import {
    Grid,
    GridColumn,
    CardGroup,
    Card,
    Label,
    Popup,
    Icon,
    Image,
    Button,
    Modal,
    ModalContent,
    ModalActions,
    Dimmer,
    Loader
} from 'svift-ui'
import ModalWrapper from 'components/modal'
import FileSettings from '../FileSettings'
import Alert from 'components/Alert'

import { injectIntl, FormattedMessage } from 'react-intl'
import { getIconByMimeType, getFileExtByMimeType } from 'utils/files/format'
import notification from 'components/hoc/notification'

import { connect } from 'react-redux'
import fileActions from 'store/actions/sites/files'

import DraggableFile from '../DraggableFileHOC'

const mapStateToProps = state => {
    return {
        siteid: state.sites.params.siteid,
        activeFolder: state.sites.files.activeFolder
    }
}

const mapDispatchToProps = dispatch => ({
    setHoveredFolder: id => dispatch(fileActions.setHoveredFolder(id)),
    delete: (siteid, fileid, folderid) =>
        dispatch(fileActions.delete(siteid, fileid, folderid)),
    updateFile: (siteid, newFile) =>
        dispatch(fileActions.update(siteid, newFile))
})
class FileCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showDelete: false,
            showFileSettings: false,
            file: {
                // For update purposes
                ...props.file,
                meta: {
                    ...props.file.meta,
                    name: props.file.meta.name.substring(
                        0,
                        props.file.meta.name.indexOf('.')
                    )
                }
            },
            loading: false,
            loadingImage: true
        }
    }

    delete = async () => {
        await this.props.delete(
            this.props.siteid,
            this.props.file._id,
            this.props.activeFolder
        )

        this.props.createNotification({
            content: this.props.intl.formatMessage({
                id: 'files.file deleted notification'
            }),
            className: 'info',
            position: 'topCenter',
            icon: 'sn-bin',
            deleteTime: 2000
        })
    }

    thumbnailGenerator = (file, imageLink, isloading) => {
        if (file.meta.mimeType.startsWith('image')) {
            return this.state.loadingImage ? (
                <Loader
                    key="loader"
                    style={{ margin: '24px auto 40px', opacity: 0.4 }}
                />
            ) : (
                <Image
                    src={imageLink}
                    key="image"
                    className="image-thumbnail"
                />
            )
        }

        const iconName = getIconByMimeType(file.meta.mimeType)

        return (
            <Icon
                name={iconName}
                style={{
                    width: '100%',
                    fontSize: 56,
                    opacity: 0.3,
                    margin: '24px 0 40px 0'
                }}
            />
        )
    }

    render() {
        const { file, siteid, intl, connectDragSource } = this.props
        const { loadingImage, name } = this.state
        const ext = getFileExtByMimeType(file.meta.mimeType)
        const src = file.url //`https://storage.googleapis.com/svift-${siteid}/${file.id}`;
        const isloading = loadingImage

        return (
            <GridColumn className={this.props.isDragging && 'force-hidden'}>
                <CardGroup style={{ flexGrow: 0 }}>
                    <Card
                        style={{ cursor: 'move', width: '100%', minHeight: 64 }}
                    >
                        {connectDragSource(
                            <div>
                                {/* fullscreen preview (modal) of image */}
                                {file.meta.mimeType.startsWith('image') && (
                                    <div className="file-info top left">
                                        <Modal
                                            size="fullscreen"
                                            closeIcon
                                            closeOnDimmerClick
                                            closeOnDocumentClick
                                            dimmer="inverted"
                                            trigger={
                                                <Icon
                                                    link
                                                    name="sn-enlarge2"
                                                    className="full-screen"
                                                />
                                            }
                                            header={`${intl.formatMessage({
                                                id:
                                                    'general.fullscreen preview of'
                                            })}: ${file.meta.name}`}
                                            content={
                                                <Image
                                                    src={src}
                                                    onLoad={() =>
                                                        this.setState({
                                                            loadingImage: false
                                                        })
                                                    }
                                                    style={{
                                                        maxWidth: '90%',
                                                        margin: 'auto',
                                                        padding: 32
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                )}

                                <div className="file-info top">
                                    <Label basic size="mini" className="info">
                                        {ext}
                                    </Label>
                                </div>

                                <div
                                    className="file-info bottom"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {/* File name */}
                                    <div
                                        className="file-name"
                                        style={{
                                            paddingLeft: 4,
                                            marginRight: 4,
                                            textAlign: 'left'
                                        }}
                                    >
                                        {file.meta.name}
                                    </div>
                                    <Popup
                                        trigger={
                                            <Icon
                                                link
                                                name="sn-cog3"
                                                className="tint-color"
                                                style={{ marginLeft: 'auto' }}
                                                onClick={() => {
                                                    this.setState({
                                                        showFileSettings: true,
                                                        name: file.meta.name.substring(
                                                            0,
                                                            file.meta.name.lastIndexOf(
                                                                '.'
                                                            )
                                                        )
                                                    })
                                                }}
                                            />
                                        }
                                        content={this.props.intl.formatMessage({
                                            id: 'files.file settings'
                                        })}
                                        position="bottom right"
                                    />
                                </div>

                                {isloading && (
                                    <Image
                                        style={{ display: 'none' }}
                                        src={src}
                                        onLoad={() =>
                                            this.setState({
                                                loadingImage: false
                                            })
                                        }
                                    />
                                )}

                                {this.thumbnailGenerator(
                                    file,
                                    src,
                                    loadingImage
                                )}
                            </div>
                        )}
                    </Card>
                </CardGroup>

                <ModalWrapper
                    open={this.state.showFileSettings}
                    handleClose={() => {
                        this.setState({
                            showFileSettings: false
                        })
                    }}
                    header="files.file settings"
                >
                    {this.state.loading && (
                        <Dimmer active inverted>
                            <Loader size="huge" style={{ fontSize: 32 }}>
                                <Icon name="cloud upload" />{' '}
                                <FormattedMessage id="general.saving changes" />
                            </Loader>
                        </Dimmer>
                    )}

                    <Grid centered celled verticalAlign="middle">
                        <FileSettings
                            update // may not be needed but whatever
                            url={src}
                            file={file}
                            ext={ext}
                            mimeType={this.state.file.meta.mimeType}
                            credit={this.state.file.meta.credit}
                            description={this.state.file.meta.description}
                            size={this.state.file.meta.size}
                            name={this.state.file.meta.name}
                            onDelete={() => this.setState({ showDelete: true })}
                            onChange={e => {
                                this.setState({
                                    file: {
                                        ...this.state.file,
                                        meta: {
                                            ...this.state.file.meta,
                                            [e.target.name]: e.target.value
                                        }
                                    }
                                })
                            }}
                        />
                    </Grid>

                    <Button
                        positive
                        onClick={() => {
                            this.setState(
                                {
                                    loading: true
                                },
                                async () => {
                                    const newFile = {
                                        ...this.state.file,
                                        meta: {
                                            ...this.state.file.meta,
                                            name: `${this.state.file.meta.name}.${ext}`
                                        }
                                    }

                                    await this.props.updateFile(
                                        this.props.siteid,
                                        newFile
                                    )

                                    this.setState({
                                        loading: false,
                                        showFileSettings: false
                                    })
                                }
                            )
                        }}
                        disabled={this.state.loading}
                    >
                        <FormattedMessage id="files.save file settings" />
                    </Button>
                </ModalWrapper>

                <Alert
                    show={this.state.showDelete}
                    onCancel={() => this.setState({ showDelete: false })}
                    showCancelButton
                    title={this.props.intl.formatMessage({
                        id: 'files.delete file alert title'
                    })}
                    text={
                        <span>
                            <span style={{ fontWeight: 'bold' }}>
                                {file.meta.name}
                            </span>{' '}
                            <FormattedMessage id="general.will be deleted" />,{' '}
                            <FormattedMessage id="files.delete file alert description" />
                        </span>
                    }
                    confirmTitle={this.props.intl.formatMessage({
                        id: 'files.delete file alert confirm'
                    })}
                    confirmIcon="trash"
                    confirmClass="negative"
                    cancelTitle={this.props.intl.formatMessage({
                        id: 'general.cancel'
                    })}
                    onConfirm={this.delete}
                />
            </GridColumn>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DraggableFile(notification(injectIntl(FileCard))))
