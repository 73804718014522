import React, { Component } from 'react';
import {
	Icon,
	Popup,
	Label,
	Button,
	Message,
	ListItem,
	Flag
} from 'svift-ui';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { connect } from 'react-redux';
import ListMenu from 'components/list-menu';
import NoResult from 'components/NoResult';
import { createFilter } from 'react-search-input';
import { setRoute } from 'utils/routing';
import actions from 'store/actions';
import ModalWrapper from 'components/modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import SviftGA from 'utils/svift-ga';
import resolveFlagIconName from 'utils/i18n/resolveFlagIconName';

// Forms
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import SiteForm from 'components/forms/site';

const mapStateToProps = (state, ownProps) => {
	const { sites } = state.sites;
    const siteid = state.sites.params.siteid;
    const site = sites && sites[siteid];
	const roles = state.user.roles;

	return {
		roles,
		siteid,
		language: site && site.standardLanguage,
		selectedSiteName: site && site.name,
		domains: site.domains,
		failedActivation: site.failedActivation,
		sitesList: state.sites.siteList,
	};
};

const mapDispatchToProps = dispatch => ({
	createSite: data => dispatch(actions.sites.createSite(data)),
});

const KEYS_TO_FILTERS = ['name'];

@connect(mapStateToProps, mapDispatchToProps)
class DashboardHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sitesListOpen: false,
			sitesModalOpen: false,
			searchTerm: ''
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.renderActivation = this.renderActivation.bind(this);
	}

	setSelectedSite = (id, e) => {
		const { siteid } = this.props;

		if (id !== siteid) {
			this.setState({ sitesListOpen: false });

			setRoute(`/dashboard/${id}`);
		}

		e.stopPropagation();
		e.preventDefault();
	}

	toggleSitesModal = () => {
		this.setState({
			sitesModalOpen: !this.state.sitesModalOpen,
		}, function() {
			if (this.state.sitesModalOpen) {
				this.setState({ sitesListOpen: false });
			}
		});
	}

	toggleSitesList = () => {
		this.setState({
			sitesListOpen: !this.state.sitesListOpen
		});
	}

	handleSettings(id, e) {
		this.setState({ sitesListOpen: false });

		setRoute(`/dashboard/${id}/settings`);

		e.stopPropagation();
		e.preventDefault();
	}

	handleFilter = e => {
		this.setState({ searchTerm: e.target.value });
	}

	async onSubmit(data) {
		this.setState({
			loading: true
		});

		try {
			await this.props.createSite(data);

			SviftGA.SITES.CREATED();

			this.setState({
				loading: false,
				sitesModalOpen: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	renderListMenu() {
		const filteredSites = this.props.sitesList.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
		const { intl } = this.props;

		if (!this.state.sitesListOpen) return false;

		return (
			<ListMenu
				settings
				position="left"
				searchPlaceholder={intl.formatMessage({ id: 'dashboard.search sites placeholder' })}
				dataSource={this.props.sitesList}
				handleFilter={(e, d) => this.handleFilter(e, d)}
				onClose={this.toggleSitesList}
				modalText={intl.formatMessage({ id: 'dashboard.new site' })}
				onModalClick={this.toggleSitesModal}
				title={intl.formatMessage({ id: 'dashboard.sites' })}
			>
				{filteredSites.length === 0
					?
						<NoResult />
					:
						filteredSites.map(site => {
							const isLiveSite = site.failedActivation || (site.domains && site.domains.length > 0);

							return (
								<ListItem
									key={site._id}
									onClick={(e) => this.setSelectedSite(site._id, e)}
									className={site._id === this.props.siteid ? 'active-item' : ''}
								>
									<span>
										<Icon
											name="sn-sphere"
											className={isLiveSite ? 'positive' : 'warning-color'}
											style={{ verticalAlign: 'middle' }}
										/>
										<span style={{ verticalAlign: 'middle' }}>
											{site.name}
										</span>
										<Label
											basic
											size="mini"
											className={isLiveSite ? 'positive' : 'warning'}
											style={{ float: 'right', verticalAlign: 'middle' }}
										>
											<Icon name="plug" style={{ marginRight: 4 }} />
											{isLiveSite ? 'online' : 'offline'}
										</Label>
									</span>
								</ListItem>
							)
						})
				}
			</ListMenu>
		);
	}

	renderActivation() {
		const {
			domains,
			siteid,
			intl,
			roles,
			failedActivation
		} = this.props;

		const rolesOnSite = roles.reduce((acc, { type, scope }) => {
			const scopeSiteMatch = scope === `sites/${siteid}`;

			if (scopeSiteMatch) {
				acc[type] = true;
			}

			return acc;
		}, {});

		const isLiveSite = failedActivation || (domains && domains.length > 0);

		const activationMessageStyle ={
			padding: '8px 12px',
			fontSize: 12,
			width: 'auto',
			whiteSpace: 'nowrap',
		}

		if (isLiveSite) {
			return (
				<Message className="positive" style={activationMessageStyle}>
					<Icon name="sn-earth2" style={{ fontSize: 16, marginLeft: 0, marginRight: 4, verticalAlign: 'middle' }} />
					<span style={{ verticalAlign: 'middle' }}>
						<FormattedMessage id="general.online title" />
					</span>
					<Icon name="sn-lan3" style={{ fontSize: 12, marginLeft: 4, marginRight: 4, verticalAlign: 'middle' }} />
					{domains && 
						<span style={{ verticalAlign: 'middle' }}>
							<Icon name="lock" className="positive-color" style={{ marginRight: 0 }} />
							
							<span style={{ opacity: 0.6 }}>https://</span>
				
							<Label className="positive" size="mini" style={{ marginLeft: 4, verticalAlign: 'middle' }}>
								{domains.map(({ name }) => name).join(', ')}
							</Label>
						</span>
					}
				</Message>
			);
		}

		return (
			<Message className="warning" style={activationMessageStyle}>
				<Icon
					name="plug"
					style={{ fontSize: 16, marginLeft: 0, marginRight: 4, verticalAlign: 'middle' }}
				/>
				<span style={{ verticalAlign: 'middle' }}>
					<FormattedMessage id="dashboard.site offline message" />
				</span>

				<Popup
					content={intl.formatMessage({ id: 'settings.subscription activate site popup' })}
					position="bottom center"
					trigger={
						<Button
							className="positive"
							disabled={!rolesOnSite.siteOwner ? true : false}
							style={{ marginLeft: 12, fontSize: 12, padding: '6px 12px', verticalAlign: 'middle' }}
							onClick={() => setRoute(`/dashboard/${siteid}/activation`)}
						>
							<Icon name="sn-flag6" />
							<FormattedMessage id="dashboard.site activation" />
						</Button>
					}
				/>

				{!rolesOnSite.siteOwner &&
					<InfoIcon
						description={intl.formatMessage({ id: 'settings.activate site warning message' })}
						position="right center"
					/>
				}
			</Message>
		);
	}

	render() {
		const {
			selectedSiteName,
			language,
			intl,
			domains,
			failedActivation
		} = this.props;

		const isLiveSite = failedActivation || (domains && domains.length > 0);

		return (
			<AppNavigation
				appIcon="sn-view_module"
				appTitle={intl.formatMessage({ id: 'dashboard.app title' })}
			>
				<div className="left-panel">
					{/* sites menu */}
					<Button
						icon
						id="site-menu-title"
						basic={this.state.sitesListOpen ? false : true}
						className={isLiveSite
							?
								this.state.sitesListOpen ? 'positive active-button' : 'positive'
							:
								this.state.sitesListOpen ? 'warning active-button' : 'warning'
						}
						onClick={!this.state.sitesListOpen ? this.toggleSitesList : null}
						style={{
							fontSize: 16,
							position: 'relative',
							marginRight: 6,
							padding: '10px 16px',
						}}
					>
						<Icon
							name="sn-sphere"
							// className={isLiveSite ? 'positive-color' : 'warning-color'}
							style={{ fontSize: 16, verticalAlign: 'middle', height: 16 }}
						/>
						<span style={{ marginLeft: 4, marginRight: 8, verticalAlign: 'middle' }}>
							{selectedSiteName}
						</span>
						<Icon name={this.state.sitesListOpen ? 'sn-arrow-up2' : 'sn-arrow-down2'} style={{ verticalAlign: 'middle', fontSize: 10, height: 8 }} />
					</Button>
					{this.renderListMenu()}
							
					{/* activation status message */}
					{this.renderActivation()}
				</div>
				
				{/* language indicator */}
				{/* TO DO: make this into a language menu (future feature) */}
				<div className="right-panel">
					<Icon
						// link
						name="sn-earth3"
						className="primary-color"
						style={{ marginRight: 8, fontSize: 24, opacity: 0.6 }}
					/>
					<span style={{ textTransform: 'uppercase', fontSize: 16 }}>
						<Flag name={resolveFlagIconName(language)} style={{ marginRight: 6 }} />
						<span style={{ fontWeight: 500 }}>
							{language}
						</span>
					</span>
				</div>

				{this.state.sitesModalOpen &&
					<ModalWrapper
						open={this.state.sitesModalOpen}
						handleClose={this.toggleSitesModal}
						header={intl.formatMessage({ id: 'general.new site title' })}
						// style={{ maxWidth: 800 }}
					>
						<SiteForm
							loading={this.state.loading}
							onClose={this.toggleSitesModal}
							onSubmit={this.onSubmit}
						/>
					</ModalWrapper>
				}
			</AppNavigation>
		);
	}
}

export default injectIntl(DashboardHeader);