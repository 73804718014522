import { setRoute } from 'utils/routing';
import api from 'api';

const logout = async () => {
	await api().logout(); // asks backend to clear httpOnly cookie (we can't do it in the front end because we don't have access to httpOnly cookies)

	setRoute('/', { isLogout: true });
};

export { logout };
