/*
    Playing with the idea of making Gallery a general component (maybe for eventual use in Editor context), but placing it here for now
*/

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Gallery extends PureComponent {
    render() {
        return this.props.items.map(item => this.props.renderItem(item, this.props.context));
    }
}

Gallery.propTypes = {
    context: PropTypes.any,
    renderItem: PropTypes.func.isRequired
};

export default Gallery;