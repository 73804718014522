import createApi from 'api';

const saveAnalytics = (siteid, gaToken) => {
	const api = createApi();

	api.updateSitePartial(siteid, {
		statistics: {
			googleAnalytics: gaToken
		}
	});
};

export default saveAnalytics;