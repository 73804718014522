import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    GridColumn,
    IconGroup,
    Icon,
    Label,
    Button
} from 'svift-ui';
// import { FormattedMessage } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { FormattedMessage } from 'react-intl';

class ModuleColumn extends Component {
	render() {
        const {
            name,
            icon,
            color,
            style,
            size,
            fontSize,
            textAlign,
            link,
            labelIcon,
            label,
            infoLabelIcon,
            infoLabel,
            infoLabelDescription,
            actionNameIntl,
            actionDisabled,
            onClick
        } = this.props;

        const tileStyle = {
            width: '100%',
            position: 'relative',
            textAlign: 'center',
            padding: '20px 4px 16px 4px',
            backgroundColor: 'white',
            boxShadow: '0 2px 16px 0 lightgrey',
            border: `2px solid ${color ? color : 'grey'}`,
            // overflow: 'hidden',
            borderRadius: 8,
            minHeight: 80,
            cursor: link ? 'pointer' : 'default'
        }

        // General icon styling:
        const iconStyle = {
            color: color ? color : 'green',
            display: 'block'
        };

        const bigIconStyle = {
            fontSize: size ? size * 2.2 : 96,
            color: color ? color : 'black',
            opacity: 0.3,
            margin: 0
        };

        const innerIconStyle = {
            fontSize: size ? size : 48,
            margin: 0
        };

        const labelStyle = {
            fontSize: fontSize ? fontSize : 18,
            textAlign: textAlign ? textAlign : 'center',
            fontWeight: 400,
            margin: '4px 0 0 0'
        }

        const ribbonStyle = {
            paddingLeft: 12,
            position: 'absolute',
            top: -14,
            left: -15,
            color: color && 'white',
            backgroundColor: color && color
        }

		return (
			<GridColumn>
                <div style={style ? style : tileStyle}>
                    {this.props.renderTopRightIcon && (
                        <div style={{ position: 'absolute', right: 6, top: 8 }}>
                            {this.props.renderTopRightIcon({ fontSize: '16px' })}
                        </div>
                    )}

                    {/* Module ribbon label */}
                    {label &&
                        <Label
                            size="small"
                            ribbon
                            // color={color}
                            style={ribbonStyle}
                        >
                            {labelIcon &&
                                <Icon
                                    name={labelIcon}
                                    style={{ marginRight: label === "" ? 8 : 0 }}
                                />
                            }
                            <span>{(label === "" || label === null) ? null : label}</span>
                        </Label>
                    }

                    {/* Icon group: */}
                    <IconGroup style={iconStyle}>
                        {/* Outer frame icon */}
                        <Icon
                            style={bigIconStyle}
                            name='sn-subscription-frame' />
                        {/* Module icon (inner icon) */}
                        <Icon
                            name={icon}
                            style={innerIconStyle}
                        />
                    </IconGroup>

                    {/* Module */}
                    <div style={labelStyle}>
                        {/* Module name */}
                        {name}

                        {/* Module info */}
                        {infoLabel &&
                            <div
                                style={{
                                    marginTop: 8,
                                    marginBottom: -10,
                                    paddingTop: 6,
                                    borderTop: 'solid 1px #DDD',
                                    display: 'flex',
                                    textAlign: 'left'
                                }}
                            >
                                <span style={{ flexGrow: 1 }}>
                                    <Label
                                        basic
                                        size="tiny"
                                        className="info"
                                        style={{ margin: 0, marginLeft: 6, verticalAlign: 'middle' }}
                                    >
                                        {infoLabelIcon && <Icon name={infoLabelIcon} style={{ margin: 0, marginRight: 6, verticalAlign: 'middle' }} />}
                                        <span style={{ verticalAlign: 'middle' }}>{infoLabel}</span>
                                    </Label>
                                </span>
                                {infoLabelDescription &&
                                    <InfoIcon
                                        description={infoLabelDescription}
                                        position="bottom center"
                                        className="info-color"
                                    />
                                }
                            </div>
                        }

                        {/* Module action */}
                        {actionNameIntl && 
                            <div style={{ width: '100%', paddingLeft: 6, paddingRight: 6 }}>
                                <Button
                                    fluid
                                    size="tiny"
                                    disabled={actionDisabled}
                                    className="info"
                                    onClick={onClick}
                                    style={{ marginTop: 16 }}
                                >
                                    <Icon name="sn-plus2" />
                                    <FormattedMessage id={actionNameIntl} /> 
                                </Button>
                            </div>
                        }
                    </div>
                </div>
			</GridColumn>
		);
	}
}

ModuleColumn.propTypes = {
    style: PropTypes.object,
    textAlign: PropTypes.string,
	name: PropTypes.string,
	icon: PropTypes.string,
};

ModuleColumn.defaultProps = {
    textAlign: 'center',
    name: 'MODULE X',
    icon: 'cube'
};

export default ModuleColumn;
