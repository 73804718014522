import store from 'store';
// import history from 'utils/routing/browserHistory';
import { setRoute } from 'utils/routing';
// import { matchPath } from 'react-router';

const userLoggedIn = state => Object.keys(state.user).length > 0;

const setInitialRoute = () => {
	const state = store.getState();
	//const currentRoute = history.location.pathname;

	const userIsLoggedIn = userLoggedIn(state);

	/*
		If user isn't logged in, we redirect the user to the login page - but we also have to allow '/register' to be reached, so we have to make an exception for that one
	*/
	if (!userIsLoggedIn) {
		const pathname = state.router.location.pathname;

		const exceptions = ['/register', '/reset-password'/*'/register/success'*/];

		if (exceptions.some(exception => pathname.startsWith(exception))) {
			return;
		}
		setRoute('/');
		//}
	}
};

export default setInitialRoute;