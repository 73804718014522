import React, { PureComponent } from 'react';
import { createFilter } from 'react-search-input';
import { getIconByMimeType } from 'utils/files/format';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { setRoute } from 'utils/routing';
import UploadModal from 'containers/dashboard/files/__components/upload-modal/UploadModal';
import DropToUpload from 'containers/dashboard/files/__components/DropUploadHOC';
import {
    Loader,
    Grid,
    GridColumn,
    Label,
	CardGroup,
	Card,
    Image,
    Icon,
} from 'svift-ui';

import fileActions from 'store/actions/sites/files';
import Tree from 'components/tree/Tree';
import FolderComponentWrapper from 'containers/dashboard/side-navigation/sidenavigation-content/files/folders/__components/FolderComponentWrapper';
import SegmentGroupWrapper from 'containers/dashboard/side-navigation/sidenavigation-content/files/folders/__components/SegmentGroupWrapper';
import SegmentWrapper from 'containers/dashboard/side-navigation/sidenavigation-content/files/folders/__components/SegmentWrapper';
import { filesInActiveFolder } from 'store/selectors/sites/files';
import NoItems from 'components/no-items/NoItems';

const mapStateToProps = (state) => {
    const siteid = state.sites.params.siteid;
 
    return {
        siteid,
        activeFolder: state.sites.files.activeFolder,
		hoveredFolder: state.sites.files.hoveredFolder,
        folders: state.sites.files.folders,
        foldersLoading: state.sites.files.foldersLoading,
        files: filesInActiveFolder(state)
    };
};

const mapDispatchToProps = dispatch => ({
    ensureFolders: async siteid => dispatch(fileActions.ensureFolders(siteid)),
    setHoveredFolder: id => dispatch(fileActions.setHoveredFolder(id)),
    setActiveFolder: id => dispatch(fileActions.setActiveFolder(id))
});

class FilesTab extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingImages: {}
        };
    }

    select = selection => {
        // const mimeType = selection.meta.mimeType;
        // let fileType;

        // if (mimeType.startsWith('video')) {
        //     fileType = 'video';
        // }

        // console.log(selection, 'selection');

        const alreadySelected = this.props.selectedFiles.includes(selection._id);

        if (alreadySelected) {
            this.props.select(selection._id, true);
        } else {
            this.props.select(selection._id);
        }

        // this.props.select(selection._id); //, selection.id, selection.meta.mimeType);
        // console.log(selection, 'selection');
        // const { _id } = selection;

        // const currentSelection = this.props.selected || {};
		
        // const newSelected = {
        //     ...currentSelection
        // };

        // if (currentSelection[_id]) { // Unselect if reselecting a file
        //     delete newSelected[_id];

        //     if (Object.keys(newSelected).length === 0) {
        //         this.props.select(null);

        //         return;
        //     }

        //     this.props.select(newSelected);
        // } else if (this.props.multiple) { // Add file to selected if multiple allowed
        //     newSelected[_id] = selection;

        //     this.props.select(newSelected);
        // } else { // Implicitly remove any already selected files
        //     this.props.select({
        //         [selection._id]: selection
        //     });
        // }
    }

	componentDidMount() {
		this.setState({
			loading: false,
			loadingImages: this.props.files.reduce((acc, file) => {
				acc[file._id] = 'loading';

				return acc;
			}, {})
        });
        
        this.props.ensureFolders(this.props.siteid);
	}

	thumbnailGenerator = (file, imageLink, isLoading) =>{
		if (file.meta.mimeType.startsWith('image')){
			if (isLoading) {
				return <Loader style={{ marginTop: 32, marginBottom: 32 }} />
			} else {
				return <Image src={imageLink} className="image-thumbnail" />
			}
		} else {
            const iconName = getIconByMimeType(file.meta.mimeType);
            
			return <Icon name={iconName} style={{ width: '100%', fontSize: 56, opacity: 0.3, margin: '24px 0 40px 0' }} />
		}
	}

    formatFiles = files => {
        // console.log(files, formattedFiles, 'formatted');
        const formattedFiles = files.filter(this.props.filter);

    
        const filteredFiles = this.props.searchTerm === '' ? formattedFiles : formattedFiles.filter(createFilter(this.props.searchTerm, ['meta.name']));       

        return filteredFiles;
    }

    onImageLoaded = id => {
        const newLoadingImages = {
            ...this.state.loadingImages
        };

        delete newLoadingImages[id];

        this.setState({
            loadingImages: newLoadingImages
        });
    };

    formatFolders(folders = [], parent = null) {
		const relevantFolders = folders.filter(folder => {
			if (parent === null && !folder.parent) return true; // Failsafe in case parent is falsy but not exactly equal to null (gives leeway in backend)

			return folder.parent === parent;
		});

		return relevantFolders.map(folder => {
			return {
				text: folder.name,
				data: folder,
				items: this.formatFolders(folders, folder._id)
			};
		});
	}

    renderers = {
        renderFolders: () => {
            return (
                <div id="folder-tree" style={{ position: 'sticky', top: 80 }}>
                    <Tree
                        items={this.formatFolders(this.props.folders)}
                        TextComponent={FolderComponentWrapper}
                        ItemGroupComponent={SegmentGroupWrapper}
                        ItemComponent={SegmentWrapper}
                        context={{
                            noSettings: true,
                            hovered: this.props.hoveredFolder,
                            active: this.props.activeFolder,
                            setActive: this.props.setActiveFolder
                        }}
                        noPath
                    />
                </div>
            );
        },
        renderFile: (file, selectedFilesDict) =>  {
            // const { 
            //     selected 
            // } = this.props;

            const isSelected = selectedFilesDict[file._id]; //selected && selected[file._id];
            const isLoading = this.state.loadingImages[file._id];
            const ext = getIconByMimeType(file.meta.mimeType);
            const src = file.url; 

            return (
                <GridColumn key={file._id}>
                    <CardGroup style={{ flexGrow: 0 }}>
                        <Card 
                            raised
                            style={{ width: '100%', minHeight: 64 }} 
                            className={isSelected ? 'active-item' : ''} 
                            onClick={() => this.select(file)}
                        >
                            {isSelected &&
                                <Icon
                                    name="sn-checkmark-circle"
                                    className="selected-icon"
                                    style={{ position: 'absolute', top: 8, left: 8, zIndex: 2, fontSize: 20 }}
                                />
                            }
                            {/* Using a hidden image tag to load the file initially - when it is done loading, we will tell the normal picture to load */}
                            <div className="file-info top">
                                <Label basic size="mini" className="info" style={{ float: 'right' }}>{ext}</Label>
                            </div>
                            <div className="file-info bottom">
                                <span>{file.meta.name}</span>
                            </div>
                            
                            {isLoading && 
                                <Image
                                    style={{ display: 'none' }}
                                    className="image-thumbnail" 
                                    src={src}
                                    onLoad={() => {
                                        this.onImageLoaded(file._id);
                                    }} 
                                /> 
                            }
                            
                            {/*This is used to judge image after loading*/}
                            { this.renderers.thumbnail(file, src, isLoading) }
                        </Card>
                    </CardGroup>
                </GridColumn>	
            );
        },
        thumbnail: (file, imageLink, isLoading) => {
            if (file.meta.mimeType.startsWith('image')){
                if (isLoading) {
                    return <Loader style={{ marginTop: 32, marginBottom: 32 }} />
                } else {
                    return (
                        <Image
                            src={imageLink}
                            className="image-thumbnail"
                            style={{
                                width: '100%',
                                maxWidth: file.meta.dimensions.width,
                                maxHeight: file.meta.dimensions.height,
                                margin: 'auto'
                            }}
                        />
                    )
                }
            } else {
                const iconName = getIconByMimeType(file.meta.mimeType);
                
                return <Icon name={iconName} style={{ width: '100%', fontSize: 56, opacity: 0.3, margin: '24px 0 40px 0' }} />
            }
        }
    }

    render() {
        const { files, intl } = this.props;
    
        const formattedFiles = this.formatFiles(files);

        // Converting array of selected files into a dictionary for easier lookups
        const selectedFilesDict = this.props.selectedFiles.reduce((dict, file) => {
            // if file isnt a string (a file id) we'll ignore it (it means it has an external provider like youtube instead, and is irrelevant for this component)
            if (typeof file === 'string') {
                dict[file] = true;
            }
            
            return dict;
        }, {});

        return (
            <div style={{ position: 'relative' }} ref={this.props.connectDropTarget}> {/* drop to upload ref */}
                {this.props.renderDropMessage()}
                
                {(this.state.loading || this.props.foldersLoading) 
                    ?
                        <Loader>
                            <p><FormattedMessage id="files.loading images" /></p>
                        </Loader>
                    :
                        <Grid padded>
                            <GridColumn computer={5} tablet={7} mobile={8} style={{ paddingTop: 0 }}>
                                {this.renderers.renderFolders(formattedFiles)}
                            </GridColumn>
                            <GridColumn computer={11} tablet={9} mobile={8} className="shade-outline">
                                {formattedFiles.length === 0 
                                    ?
                                        <NoItems
                                            icon={!this.props.searchTerm ? "sn-file-empty" : "sn-folder-search"}
                                            title={intl.formatMessage({ id: `${!this.props.searchTerm ? 'messages.no files uploaded title' : 'general.no search results title'}` })}
                                            content={intl.formatMessage({ id: `${!this.props.searchTerm ? 'messages.no files uploaded description' : 'general.no search results description'}` })}
                                            className={!this.props.searchTerm ? 'secondary' : 'warning'}
                                        />
                                    :
                                        <Grid
                                            padded
                                            columns={3}
                                            stretched
                                            className="card-selector"
                                        >
                                            {formattedFiles.map(file => this.renderers.renderFile(file, selectedFilesDict))}
                                        </Grid>
                                }
                            </GridColumn>
                        </Grid>
                }
 
                {this.props.blobProps.blobs && this.props.blobProps.blobs.length > 0 &&
                    <UploadModal
                        blobs={this.props.blobProps.blobs}
                        warningVisible={this.props.blobProps.unsupportedFile}
                        updateBlob={this.props.blobProps.updateBlob}
                        addBlobs={this.props.blobProps.addBlobs}
                        clearBlobs={this.props.blobProps.clearBlobs}
                    />
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DropToUpload(FilesTab)));
