import React, { memo, useEffect } from 'react';
import { Button, Icon, ButtonGroup } from 'svift-ui';
import Portal from 'components/editor-v2/editor-interface/Portal'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';

const ContextProvider = Component => {
    return class Composer extends React.PureComponent {
        render() {
            const { ContextConsumer } = this.props;
    
            return (
                <ContextConsumer>
                    {context => {
                        const { 
                            hasChanged,
                            resolveContent,
                            // updateState,
                            onSave,
                            onPublish,
                            getState
                        } = context; 

                        const { draft, live } = resolveContent(context);

                        // console.log(draft, live, 'draft live');

                        const draftVersion = draft && draft.version;
                        const liveVersion = live && live.version; 

                        const explicitProps = {
                            hasChanged,
                            draftVersion,
                            liveVersion,
                            // updateState,
                            getState,
                            onSave, 
                            onPublish
                        };

                        return (
                            <Component 
                                {...explicitProps}
                            />
                        );
                    }}
                </ContextConsumer>
            );
        }
    }
};

const SaveOrPublish = injectIntl(memo(props => {
    const { hasChanged, draftVersion, liveVersion, onSave, onPublish, getState } = props;

    const canSave = hasChanged;
    const canPublish = canSave || draftVersion !== liveVersion;

    const saveButtonPopup = useTooltip();
    const publishButtonPopup = useTooltip();
    
    useEffect(() => {
        // Used to see if we should display alert when navigating while there are unsaved changes to editor content
        if (canSave) window.__EDITOR_UNSAVED_CONTENT = true;

        return () => delete window.__EDITOR_UNSAVED_CONTENT;
    }, [canSave]);

    return (
        <Portal target="save-or-publish-portal">
            {/* save button */}
            <Popup
                target={saveButtonPopup.current}
                position="bottom right"
                zIndex={997}
            >
                <FormattedMessage id="general.save draft popup" />
            </Popup>
            <Button
                icon
                ref={saveButtonPopup}
                size="mini"
                className="positive"
                style={{ padding: '6px 8px', marginRight: 4 }}
                disabled={!canSave}
                onClick={() => onSave(getState)}
            >
                <Icon name="sn-cloud-upload" style={{ fontSize: 16, height: 16, verticalAlign: 'middle' }} />

                <span style={{ marginLeft: 6, verticalAlign: 'middle' }}>
                    <FormattedMessage id="general.save draft" />
                </span>
            </Button>

            {/* publish button */}
            <Popup
                target={publishButtonPopup.current}
                position="bottom right"
                zIndex={997}
            >
                <FormattedMessage id="general.publish popup" />
            </Popup>
            <Button
                icon
                id="save-and-publish-buttons"
                ref={publishButtonPopup}
                size="mini"
                className="warning"
                style={{ padding: '6px 8px' }}
                disabled={!canPublish}
                onClick={() => onPublish(getState)}
            >
                <Icon name="sn-earth2" style={{ fontSize: 16, height: 16, verticalAlign: 'middle' }} />

                <span style={{ marginLeft: 6, verticalAlign: 'middle' }}>
                    <FormattedMessage id="general.publish" />
                </span>
            </Button>
        </Portal>
    );
}));

export default ContextProvider(SaveOrPublish);

/*
    TO DO: bring back notifications when seving and publishing
	async saveDraft() {
		const { siteid, pageid } = this.props;
	
		await this.props.saveDraft(siteid, pageid);

		SviftGA.PAGES.DRAFT_CONTENT_MODIFIED();

		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'pages.save notification' }),
			className: 'positive',
			position: 'topCenter',
			icon: 'sn-cloud-upload',
		   deleteTime: 2000
		});
	}

	async publishDraft() {
		const { siteid, pageid } = this.props;

		await this.props.publishDraft(siteid, pageid);

		SviftGA.PAGES.LIVE_CONTENT_MODIFIED();

		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'pages.publish notification' }),
			className: 'warning',
			position: 'topCenter',
			icon: 'sn-sphere',
		   deleteTime: 2000
		});
	}
*/
