import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Targeted imports to reduce size impact
import Slider from 'rc-slider/lib/Slider';
// import Handle from 'rc-slider/lib/Handle';
// import 'rc-slider/assets/index.css';

// const CustomHandle = props => {
//     return <Handle value={props.value} offset={props.offset} />;
// };

/*
    If we don't ensure value is within bounds of the slider min/max values, it will automatically trigger
    an onChange with the max/min value whenever we provide it with a value outside the bounds (from an input for example)

    For this reason we have to trick the slider into thinking the value isn't breaking bounds
*/
const getBoundedValue = props => {
    if (isNaN(props.value)) return props.value;

    if (props.value > props.max) {
        return props.max;
    } else if (props.value < props.min) {
        return props.min;
    }

    return props.value;
}

const CustomSlider = props => {
    const valueIsNumber = !isNaN(props.value);
    const boundedValue = getBoundedValue(props);

    // useImperativeHandle()

    return (
        <Slider
            {...props}
            value={valueIsNumber ? boundedValue : 0}
            onChange={(...args) => props.onChange(...args) }
            // min={props.min} 
            // max={props.max} 
            // value={props.value} 
            // onChange={props.onChange} 
            // style={props.style} 
        />
    );
};

CustomSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default CustomSlider;
