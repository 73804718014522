import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Checkbox from 'components/option-library/primitives/checkbox';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import OptionLabel from 'components/option-library/label';
import { injectIntl } from 'react-intl';

const sizeOptions = intl => [
    {
        text: intl.formatMessage({ id: 'general.size mini' }),
        value: 'mini'
    },
    {
        text: intl.formatMessage({ id: 'general.size tiny' }),
        value: 'tiny'
    },
    {
        text: intl.formatMessage({ id: 'general.size small' }),
        value: 'small'
    },
    {
        text: intl.formatMessage({ id: 'general.size medium' }),
        value: 'medium'
    },
    {
        text: intl.formatMessage({ id: 'general.size large' }),
        value: 'large'
    },
    {
        text: intl.formatMessage({ id: 'general.size big' }),
        value: 'big'
    },
    {
        text: intl.formatMessage({ id: 'general.size huge' }),
        value: 'huge'
    },
    {
        text: intl.formatMessage({ id: 'general.size massive' }),
        value: 'massive'
    },
];

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();
    // console.log(props, 'props menu options')

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    // console.log(node.props, 'node props menu options');
    // console.log(props, 'props menu')

    return (
        <React.Fragment>
            <Section>
                <OptionLabel
                    labelIcon="sn-direction"
                    label={intl.formatMessage({ id: 'menus.title' })}
                    labelDescription={intl.formatMessage({ id: 'options.menu navigation description' })}
                >
                    <Dropdown
                        wings
                        items={[{ text: intl.formatMessage({ id: 'menus.auto navigation title' }), value: null }].concat(props.navigation.order.map(navID => {
                            const nav = props.navigation.lookup[navID];

                            return {
                                text: nav.title,
                                value: nav._id
                            }
                        }))}
                        value={node.props.navigation}
                        onChange={value => onChange('navigation', value)}
                    />
                </OptionLabel>

                <Section nested>
                    <Preset
                        multipleStates
                        presets={props.theme.presets.menu}
                        onChange={onChange}
                        presetKey={node.props.preset || 'Primary'}
                        theme={props.theme} 
                        inlineStyling={node.props.style}
                    />

                    <OptionLabel label={intl.formatMessage({ id: 'menus.menu size' })}>
                        <Dropdown
                            wings
                            items={sizeOptions(intl)}
                            value={node.props.size}
                            onChange={value => onChange('size', value)}
                        />
                    </OptionLabel>

                <OptionLabel>
                    <Checkbox
                        icon="sn-arrow-resize4"
                        value={node.props.vertical}
                        label={intl.formatMessage({ id: 'menus.vertical menu' })}
                        onChange={val => onChange('vertical', val, 'toggle vertical', false)}
                    />
                </OptionLabel>

                <OptionLabel>
                    <Checkbox
                        icon="sn-arrow-resize3"
                        value={node.props.fluid}
                        label={intl.formatMessage({ id: 'options.button full width' })}
                        onChange={val => onChange('fluid', val, 'toggle full width', false)}
                    />
                </OptionLabel>


                <OptionLabel
                    labelIcon="sn-arrow-resize3"
                    label={intl.formatMessage({ id: 'options.button alignment' })}
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-align-left"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.alignment left' })}
                            tooltipPosition="top center"
                            value={['center', 'flex-end'].indexOf(node.props.alignment) === -1}
                            onChange={() => onChange('alignment', 'flex-start', 'menu alignment', false)}
                        />
                        <Toggle
                            icon="sn-align-center-horizontal"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.alignment === 'center'}
                            onChange={() => onChange('alignment', 'center', 'menu alignment', false)}
                        />
                        <Toggle
                            icon="sn-align-right"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.alignment === 'flex-end'}
                            onChange={() => onChange('alignment', 'flex-end', 'menu alignment', false)}
                        />
                    </ToggleGroup>
                </OptionLabel>
                </Section>
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <BackgroundOptions {...props} />
            </Accordion>
        </React.Fragment>
    );
}));

{/* <Dropdown
uncontrolled
key="navtype"
option="navtype"
// icon="sn-typography"
hoverContent={props.intl.formatMessage({ id: 'menus.title' }) + ` (${activeText})`}
options={[{ text: 'auto', value: null }].concat(this.props.navigations.slice(1).map(navigation => {
    return {
        text: navigation.title,
        value: navigation._id
    };
}))}
onChange={navigation => {
    const newNode = {
        ...node,
        props: {
            ...node.props,
            navigation: navigation.value
        },
    };

    getEditorGlobals().onChange({
        newNode,
        path
    });
}}
value={computedProps.navigation || null}
/>,
<ImportedMenu key="preset/size" size="mini" className="primary" compact style={{ marginRight: 8 }}>
<Dropdown
    unwrapped
    uncontrolled
    key="presets"
    option="preset"
    icon="sn-direction"
    options={Object.keys(getEditorGlobals().theme.presets.menu).map(preset => {
        return {
            text: preset,
            value: preset
        };
    })}
    hoverContent={props.intl.formatMessage({ id: 'design.type' }) + ` (${computedProps.preset})`}
    onChange={preset => {
        const newNode = {
            ...node,
            props: {
                ...node.props,
                preset: preset.value
            },
        };

        getEditorGlobals().onChange({
            newNode,
            path
        });
    }}
    value={computedProps.preset}
/>
<Dropdown
    unwrapped
    uncontrolled
    key="font-type"
    // icon="hand pointer"
    hoverContent={props.intl.formatMessage({ id: 'options.size title' }) + ` (${computedProps.size})`}
    option="size"
    options={[
        {
            text: props.intl.formatMessage({ id: 'options.size mini' }),
            value: 'mini'
        },
        {
            text: props.intl.formatMessage({ id: 'options.size tiny' }),
            value: 'tiny'
        },
        {
            text: props.intl.formatMessage({ id: 'options.size small' }),
            value: 'small'
        },
        {
            text: props.intl.formatMessage({ id: 'options.size large' }),
            value: 'large'
        },
        {
            text: props.intl.formatMessage({ id: 'options.size huge' }),
            value: 'huge'
        },
        {
            text: props.intl.formatMessage({ id: 'options.size massive' }),
            value: 'massive'
        }
    ]}
    onChange={(newSize) => {
        const newNode = {
            ...node,
            props: {
                ...node.props,
                size: newSize.value
            },
        };

        getEditorGlobals().onChange({
            newNode,
            path
        });
    }}
    value={computedProps.size || 'medium'}
/>
</ImportedMenu>,
<ButtonGroup key="width" size="tiny">
<Toggle
    key="horizontal"
    hoverContent={props.intl.formatMessage({ id: 'menus.horizontal menu' })}
    icons={['sn-direction', 'resize horizontal']}
    toggled={!computedProps.vertical}
    onToggle={(toggled, e) => {
        const newNode = {
            ...node,
            props: {
                ...node.props
            }
        };

        delete newNode.props.vertical;

        getEditorGlobals().onChange({
            newNode,
            path
        });
    }}
/>
<Toggle
    key="vertical"
    hoverContent={props.intl.formatMessage({ id: 'menus.vertical menu' })}
    icons={['sn-direction', 'resize vertical']}
    toggled={computedProps.vertical}
    onToggle={(toggled, e) => {
        const newNode = {
            ...node,
            props: {
                ...node.props,
                vertical: true
            }
        };

        getEditorGlobals().onChange({
            newNode,
            path
        });
    }}
/>
</ButtonGroup>,
<Toggle
key="full-widthee"
hoverContent={props.intl.formatMessage({ id: 'menus.full width' })}
icons={['sn-direction', 'resize horizontal']}
toggled={computedProps.fluid}
onToggle={(toggled, e) => {
    const newNode = {
        ...node,
        props: {
            ...node.props,
            fluid: true
        }
    };

    if (!toggled) {
        delete newNode.props.fluid;
    }

    getEditorGlobals().onChange({
        newNode,
        path
    });
}}
/>,
<Basic
key="alignmentee"
icons={[
    {
        name: (() => {
            switch (node.props.alignment) {
                case 'left':
                    return 'sn-align-left';
                case 'right':
                    return 'sn-align-right';
                case 'center':
                default:
                    return 'sn-align-center-horizontal';
            }
        })()
    }
]}
hoverContent={props.intl.formatMessage({ id: 'menus.menu alignment' })}
>
<ButtonGroup>
    <Toggle
        key="left"
        hoverContent="Left"
        icons={['sn-align-left']}
        toggled={node.props.alignment === 'left'}
        onToggle={(toggled, e) => this.toggleAlignment(e, 'left')}
    />
    <Toggle
        key="center"
        hoverContent="Center"
        icons={['sn-align-center-horizontal']}
        toggled={['left', 'right'].indexOf(node.props.alignment) === -1}
        onToggle={(toggled, e) => this.toggleAlignment(e, 'center')}
    />
    <Toggle
        key="underline"
        hoverContent="Right"
        icons={['sn-align-right']}
        toggled={node.props.alignment === 'right'}
        onToggle={(toggled, e) => this.toggleAlignment(e, 'right')}
    />
</ButtonGroup>
</Basic> */}
