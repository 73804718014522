import React, { Component } from 'react';
import {
	Header,
	Divider,
	Message,
	IconGroup,
	Icon
} from 'svift-ui';
// import AvatarList from 'containers/authorize/registration/content/AvatarList';
import { setRoute } from 'utils/routing';
import api from 'api';
import { injectIntl, FormattedMessage } from 'react-intl';

import RegistrationForm from 'components/forms/registration';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import { connect } from 'react-redux';
import { objFromQueryString } from 'utils/routing/extractParams';
import { Loader } from 'svift-ui';
import SviftGA from 'utils/svift-ga';

const mapStateToProps = (state) => ({
	search: state.router.location.search
});

class RegistrationContainer extends Component {
	constructor(props) {
		super(props);

		const query = objFromQueryString(this.props.search);

		this.state = {
			checkingToken: !!query.token,
			invitation: false
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	async componentDidMount() {
		if (this.state.checkingToken) {
			const query = objFromQueryString(this.props.search);

			try {
				const invitation = await api().getInvitation({ token: query.token });

				this.setState({
					invitation,
					checkingToken: false
				});
			} catch (e) {
				this.setState({
					invalidInvitation: true,
					checkingToken: false
				});
			}
		}
	}

	async onSubmit(values) {
		try {
			SviftGA.AUTHORIZATION.REGISTER_STARTED();

			const query = objFromQueryString(this.props.search);

			await api().register({
				email: values.email,
				password: values.password,
				firstName: values.firstname,
				lastName: values.lastname,
				inviteToken: query.token
			});

			setRoute('register/confirm');
		} catch (e) {
			handleSubmitError(this.props.intl)(e);
		}
	}


	render() {
		if (this.state.checkingToken) {
			return <Loader />;
		}

		if (this.state.invalidInvitation) {
			return (
				<p>
					<FormattedMessage id="registration.invitation is invalid" />
				</p>
			);
		}

		const { intl } = this.props;

		if (!this.state.invitation) {
			return (
				<div style={{ textAlign: 'center' }}>
					{/* login icon */}
					<IconGroup style={{ marginBottom: 32 }}>
						<Icon
							name="sn-account_circle"
							className="light-shade-color"
							style={{ fontSize: 80, margin: 0 }}
							/>
						<Icon
							corner
							name="sn-plus2"
							className="positive-color"
							style={{
								fontSize: 40,
								marginRight: 16,
								marginBottom: -16
							}}
							/>
					</IconGroup>

					{/* transl8 */}
					<Header
						as="h1"
						content={intl.formatMessage({ id: 'messages.registration title' })}
						textAlign="center"
						className="dark-primary-color"
						style={{ marginTop: 0, marginBottom: 16 }}
					/>

					<p style={{ marginBottom: 24 }}><FormattedMessage id="messages.registration description" /></p>

					<a
						href="https://svift.net/kom-i-gang"
						title="Skriv dig op og få en invitation"
						target="_blank"
						className="ui button positive big"
					>
						<Icon name="sn-logo" style={{ marginRight: 8, verticalAlign: 'middle' }} />
						<span style={{ verticalAlign: 'middle' }}>
							<FormattedMessage id="messages.registration button" />
						</span>
					</a>
				</div>
			);
		}

		return (
			<React.Fragment>
				{/* register icon */}
				<IconGroup style={{ marginBottom: 32 }}>
					<Icon
						name="sn-account_circle"
						className="light-shade-color"
						style={{ fontSize: 80, margin: 0 }}
						/>
					<Icon
						corner
						name="sn-plus2"
						className="secondary-color"
						style={{
							fontSize: 40,
							marginRight: 16,
							marginBottom: -16
						}}
					/>
				</IconGroup>

				<Header
					as="h1"
					textAlign="center"
					content={intl.formatMessage({ id: 'registration.registration title' })}
					className="dark-secondary-color"
					style={{ marginTop: 0, marginBottom: 16 }}
				/>

				<Message
					className="secondary"
					style={{ padding: 16, marginBottom: 16 }}
				>
					<div>
						<Icon
							name="sn-logo"
							className="secondary-color"
							style={{
								fontSize: 40,
								marginRight: 0,
								marginBottom: 6
							}}
						/>
					</div>
					<FormattedMessage id="registration.registration description" />
					<Divider style={{ marginTop: 6, marginBottom: 6 }} />
					<div style={{ fontSize: 12, textAlign: 'left' }}>
						<FormattedMessage id="registration.registration disclaimer" />
					</div>
				</Message>

				<RegistrationForm
					onSubmit={this.onSubmit}
					invitation
					initialValues={{
						terms: false,
						email: this.state.invitation.details.email
					}}
				/>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(RegistrationContainer));