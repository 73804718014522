import React, { useReducer, useEffect, useCallback, useRef } from 'react';

/*
    Accordion is implementation context agnostic; it provides only logic to show and hide elements
*/

const accordionReducer = (state, action) => {
    switch (action.type) {
        case 'register': {
            return {
                ...state,
                [action.value]: {}
            }
        }

        case 'toggle': {
            return {
                ...state,
                [action.value.id]: {
                    ...state[action.value.id],
                    [action.value.index]: !state[action.value.id][action.value.index]
                }
            }
        }

        // case 'toggleSingle': {

        // }

        default: throw new Error('Unexpected action');
    }
}

const useAccordion = (initialState = {}) => useReducer(accordionReducer, initialState);

const Accordion = ({ state, toggle, children }) => {
    if (!state) return null;

    return React.Children.map(children, (child, index) => {
        if (!child) return null;

        return React.cloneElement(child, {
            accordion: {
                toggle: () => toggle(index),
                open: state[index]
            }
        });
    });
};

const Selector = props => {
    const id = useRef(props.id || Symbol()); // supply ID to persist state between renders
    const accordionState = props.state[id.current];

    useEffect(() => {
        if (!accordionState) {
            props.dispatch({
                type: 'register',
                value: id.current
            });
        }
    }, []);

    const toggle = useCallback(index => {
        props.dispatch({
            type: 'toggle',
            value: {
                index,
                id: id.current
            }
        });
    }, []);

    /* 
        return <Accordion state={accordionState} toggle={toggle}> {props.children} </Accordion>;

        ^ notice the two spaces before and after "{props.children}"

        React.Children.map registers those two spaces as components, and throws an invariant violation because of it

        leaving it here as a cautionary tale, because it was a horrible debugging experience
    */
    return <Accordion state={accordionState} toggle={toggle}>{props.children}</Accordion>;
};

export { useAccordion };
export default Selector;