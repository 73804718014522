import { createSelector } from 'reselect';

const selectSiteIds = state => state.sites.index;
const selectSites = state => state.sites.sites;
const selectThemes = state => state.themesList;

const makeGetSites = () => {
	return createSelector(
		selectSiteIds,
		selectSites,
		selectThemes,
		(siteIds, sites) => siteIds.map(siteid => sites[siteid]),
	);
};

export { makeGetSites };