import React from 'react';
import { FormattedMessage } from 'react-intl';

const translateElement = key => {
    let elementKey = `elements.`+ key + ' title';

    return (
        <FormattedMessage id={elementKey} />
    );
};

const resolveElementName = (component, type) => {
    switch (component && component.toLowerCase()) {

        case 'grid': {
            return translateElement('section');
        }

        case 'row': {
            return translateElement('row');
        }

        case 'column': {
            return translateElement('column');
        }
        
        case 'image': {
            return translateElement('image');
        }
        
        case 'text': {
            return translateElement('text');
        }
        
        case 'video': {
            return translateElement('video');
        }
        
        case 'button': {
            return translateElement('button');
        }
        
        case 'feed': {
            return translateElement('feed');
        }
        
        case 'map': {
            return translateElement('map');
        }
        
        case 'form': {
            return translateElement('form');
        }
        
        case 'iframe': {
            return translateElement('iframe');
        }

        case 'container': {
            return translateElement('container');
        }

        case 'layoutcontainer': {
            return translateElement('site container');
        }
        
        case 'body': {
            return translateElement('body');
        }

        case 'placeholder': {
            return translateElement('placeholder');
        }

        case 'menu': {
            return translateElement('menu');
        }

        case 'smarttext': {
            switch (type.toLowerCase()) {
                case 'blogpublicationdate': {
                    return translateElement('post publication date');
                }

                case 'blogdescription': {
                    return translateElement('post description');
                }

                case 'blogauthor': {
                    return translateElement('post author');
                }

                case 'blogtitle': {
                    return translateElement('post title');
                }

                case 'sitetitle': {
                    return translateElement('site');
                }

                case 'pagetitle': {
                    return translateElement('page');
                }
            }
        }

        case 'sitetitle': {
            return translateElement('site');
        }
        
        case 'pagetitle': {
            return translateElement('page');
        }


        case 'blogtitle': {
            return translateElement('post title');
        }
        
        case 'blogauthor': {
            return translateElement('post author');
        }
        
        case 'blogdescription': {
            return translateElement('post description');
        }

        case 'blogpublicationdate': {
            return translateElement('post publication date');
        }
        
        case 'logo': {
            return translateElement('logo');
        }
        
        case 'forminput': 
        case 'formtextarea': {
            return translateElement('input');
        }
        
        case 'formcheckbox': {
            return translateElement('checkbox');
        }
        
        case 'formradio': {
            return translateElement('radio');
        }

        /* TRANSL8 */
        case 'follow': {
            return translateElement('follow');
        }

        case 'share': {
            return translateElement('share');
        }

        case 'gallery': {
            return translateElement('gallery');
        }

        case 'icon': {
            return translateElement('icon');
        }

        case 'divider': {
            return translateElement('divider');
        }
        
        // no match fallback
        default: {
            // console.log(component, 'component')

            return 'NO KEY...';
        }
    }
};

export default resolveElementName;