import React from 'react';
import useServingUrl from './useServingUrl';
import getBackgroundStyling from './getBackgroundStyling';
import { resolveFileUrl } from 'svift-library/client/fileHelper';

const RegularBackgroundImage = props => {
    const backgroundStyles = getBackgroundStyling(props.nodeProps, props.src);

    return props.render(backgroundStyles);
};

const SmartBackgroundImage = props => {
    const { backgroundWidth, backgroundHeight } = props;
    const { state, imgRef } = useServingUrl(props.file, { fixedHeight: backgroundWidth, fixedWidth: backgroundHeight });
    const src = resolveFileUrl(props.file, state.urlOptionsFinal || state.urlOptions);

    const backgroundStyles = getBackgroundStyling(props.nodeProps, src);
  
    return props.render(backgroundStyles, imgRef);
};

export default props => {
    const { file } = props;

    if (file) {
        if (file.serving_url) {
            return <SmartBackgroundImage key={file.serving_url} {...props} />;
        } else {
            return <RegularBackgroundImage key={file.url} src={file.url} {...props} />;
        }
    }

    return <RegularBackgroundImage {...props} />;
};