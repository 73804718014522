import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const onClickOutside = (ComposableComponent, eventType = 'click') => {
	return class Composer extends Component {
		constructor() {
			super();

			this.clickHandler = this.onClickOutside.bind(this);
		}

		componentDidMount() {
			document.addEventListener(eventType, this.clickHandler);
		}

		componentWillUnmount() {
			document.removeEventListener(eventType, this.clickHandler);
		}

		onClickOutside(e) {
			const target = e.target;
			const rootNode = ReactDOM.findDOMNode(this.composedComponent);

			if (this.composedComponent.onClickOutside && !rootNode.contains(target)) {
				this.composedComponent.onClickOutside(e);
			}
		}

		render() {
			return <ComposableComponent {...this.props} ref={el => this.composedComponent = el} />;
		}
	};
};

/*
	React has it's own internal event system which triggers after the actual event is fired and propagated; that has the consequence that
	listeners on document (like the one used by this component) trigger before the actual event targets, making us unable to stopPropagation, which
	means the event will always fire for this listener - this gave us problems when clicking on a tooltip which is rendered outside the editable container.

	To work around the problem, we also check if any of the tooltip containers contain the clicked element, and then we can choose to do nothing. Doesn't feel
	very nice, but is the best solution I could come up with given the circumstances.

	Thoughts: Another fix could be to add an event handler outside React on didMount in the individual tooltips, and then prevent propagation there - this is a
	bit hacky, and may not even work, but could be worth experimenting with.
*/
const clickedOnTooltip = (e, className = 'svift-tooltip') => {
	const clickedElement = e.target;
	const tooltipContainers = document.body.getElementsByClassName(className);

	return [].slice.call(tooltipContainers).some(container => container.contains(clickedElement));
};

const clickedOnColumn = (e, className = 'svift-column') => {
	const clickedElement = e.target;
	const columnContainers = document.body.getElementsByClassName(className);

	return [].slice.call(columnContainers).some(container => container.contains(clickedElement));
};

export { clickedOnTooltip, clickedOnColumn };
export default onClickOutside;