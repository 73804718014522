import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	formContent: {},
	forms: {},
	view: 'draft',
	params: {
		formid: null,
		siteid: null
	},
	/*
		Form/From :-P

		kind of a coincidence there's almost a name clash here, the difference is clearer under form or page reducer, but stagedFrom is basically how the Form looked
		when the user first loaded the page - we save it so we can use it to check if any changes on the staged item have happened since the user loaded the page.

		If the state has changed in any way, stagedForm === stagedFrom will return false
	*/
	stagedForm: null,
	stagedFrom: null
});

const defaultState = getDefaultState();

const formsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.CREATE_FORM: {
			const { newForm } = action.data;

			const newState = {
				...state,
				formContent: {
					...state.formContent,
					[newForm._id]: newForm.content
				},
				forms: {
					...state.forms,
					[newForm._id]: {
						...newForm,
						content: newForm._id
					}
				}
			};

			return newState;
		}

		case constants.TOGGLE_FORM_VIEW: {
			return {
				...state,
				view: action.data
			};
		}

		case constants.SET_FORMS: {
			const { forms, formContent } = action.data;

			const newState = {
				...state,
				forms: {
					...state.forms,
					...forms
				},
				formContent: {
					...state.formContent,
					...formContent
				}
			};

			return newState;
		}

		case constants.UPDATE_FORM_CONTENT: {
			const { /*formID, */tree } = action.data;

			const newState = {
				...state,
				stagedForm: {
					...state.stagedForm,
					tree
				},
			};

			return newState;
		}

		case constants.STAGE_FORM: {
			return {
				...state,
				stagedFrom: action.data,
				stagedForm: action.data
			};
		}

		case constants.PUBLISH_FORM: {
			const { draft, formid } = action.data;

			console.log(draft, formid, 'helolo')

			return {
				...state,
				formContent: {
					...state.formContent,
					[formid]: {
						...state.formContent[formid],
						draft,
						live: draft
					}
				},
				stagedFrom: draft,
				stagedForm: draft
			};
		}

		case constants.SAVE_FORM: {
			const { draft, formid } = action.data;

			return {
				...state,
				formContent: {
					...state.formContent,
					[formid]: {
						...state.formContent[formid],
						draft
					}
				},
				stagedFrom: draft,
				stagedForm: draft
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/design/forms/:formid?') || { formid: null, siteid: null };

			let stagedForm = state.stagedForm;

			if (params.formid !== state.params.formid || params.siteid !== state.params.siteid) {
				stagedForm = null;
			}

			return {
				...state,
				stagedForm,
				stagedFrom: stagedForm,
				view: 'draft',
				params: {
					formid: params.formid,
					siteid: params.siteid
				}
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default formsReducer;
