import React, { PureComponent } from 'react';
import { DragSource } from 'react-dnd';

export default Component => {
    class Composer extends PureComponent {
        render() {
            return <Component {...this.props} />;
        }
    }

    return DragSource(
        'ITEM', 
        {
            beginDrag: (props, monitor, component) => {
				console.log('BEGIN DRAG DRAGGABLE FILE', props);

                return {
                    fileID: props.file._id 
                };
			},
			endDrag: (props, monitor, component) => {
                props.setHoveredFolder(null);
                
                // console.log(monitor.get)

				// console.log(props, monitor.getDropResult(), 'ay');
				// component.props.setHoveredFolder(null);
				// props.onDragEndCallback(monitor.didDrop(), props.item);
			},
        }, 
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        })
    )(Composer);
}