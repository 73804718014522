exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".color-palette__color-preview--2dHaQ{background-color:#FFF;background-size:16px 16px;background-position:0 0, 8px 8px;background-image:linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black)}.color-palette__color-item--13gIb{display:flex;border-radius:8px;box-shadow:0 0 2px 2px #d8d4d0;cursor:pointer;z-index:1}.color-palette__color-item--13gIb:hover{box-shadow:inset 0 0 0 2px #f7f3f0,0 0 3px 2px #5f4575;z-index:2}.color-palette__color-item--13gIb.color-palette__selected-color--1thEB{box-shadow:inset 0 0 0 2px #fcfbfa,0 0 0 4px #523c65;z-index:3;cursor:default}.color-palette__palette--_SeyB{display:flex;flex-wrap:wrap}.color-palette__palette--_SeyB .color-palette__color-item--13gIb{justify-content:center;padding:16px;width:calc(25% - 8px);margin:4px}.color-palette__palette--_SeyB .color-palette__color-item--13gIb.color-palette__selected-color--1thEB{box-shadow:inset 0 0 0 2px #fcfbfa,0 0 0 4px #523c65;z-index:3;cursor:default}.color-palette__filter-outline--bPCk5{display:flex;padding:4px;margin:4px;background-color:#fcfbfa;border:1px dashed #5f4575;border-radius:4px}.color-palette__color-preview--2dHaQ{border-radius:4px;height:100%;border:2px solid #d8d4d0;margin-bottom:4px;overflow:hidden}.color-palette__color-preview--2dHaQ .color-palette__preview-background--2HxdF{position:relative;padding:16px 8px;text-align:center;height:100%;cursor:help}.color-palette__color-preview--2dHaQ .color-palette__preview-background--2HxdF .color-palette__preview-icon--3PNF9{width:100%;text-align:center;position:absolute;z-index:2;top:50%;margin-top:-16px;left:0;margin-left:0;font-size:32px;height:32px;opacity:0.85}\n", ""]);

// exports
exports.locals = {
	"color-preview": "color-palette__color-preview--2dHaQ",
	"color-item": "color-palette__color-item--13gIb",
	"selected-color": "color-palette__selected-color--1thEB",
	"palette": "color-palette__palette--_SeyB",
	"filter-outline": "color-palette__filter-outline--bPCk5",
	"preview-background": "color-palette__preview-background--2HxdF",
	"preview-icon": "color-palette__preview-icon--3PNF9"
};