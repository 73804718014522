import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
// import { computePreset } from 'svift-library/client/renderer/utils/computePreset';from 'components/preset/util';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import PresetContainer from '../../PresetContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import { Icon, Menu } from 'svift-ui';
import Dropdown from 'components/option-panel/options/Dropdown';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Slider from 'components/option-panel/options/Slider';

class DividerIconPresets extends PureComponent {
	constructor(props) {
		super(props);

		this.getPresetOptions = () => {
			const { intl } = this.props; 
			const { margin, color, fontSize } = this.props.preset.icon;

			const computedStyles = computePreset(this.props.preset.icon, this.props.theme);

			return [
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.divider icon color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={this.props.preset.icon}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						const value = this.props.preset.icon.margin;

						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {
									marginLeft: 5,
									marginRight: 5
								});

								return;
							}

							this.onChange('margin', {
								generalMargin: newValue / 100
							});

							return;
						}

						const newMargin = {
							...value,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}

					// optIn={{
					// 	marginBottom: false,
					// 	marginTop: true,
					// 	marginLeft: true,
					// 	marginRight: true,
					// 	generalMargin: true
					// }}
				/>,
				<Slider
					key="font-size"
					icons={['sn-font-size', 'sn-arrow-resize4']}
					title={`${intl.formatMessage({ id: 'options.font size title' })}: ${Math.floor(fontSize * 100)}% (${computedStyles.fontSize})`}
					onChange={(newValue) => {
						this.onChange('fontSize', newValue / 100);
					}}
					value={fontSize * 100}
					min={25}
					max={350}
					option="fontSize"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>
			];
		}

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}
	
	getIdentifier() {
		return `DividerIcon${this.props.presetTitle}`; // Need a specific identifier because the selectable buttons are nested a layer deeper
	}

	onChange = (property, newValue) => {
		const newIconPreset = {
			...this.props.preset.icon,
			[property]: newValue
		}
		this.props.updatePresetOption(this.props.presetTitle, 'icon', newIconPreset);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const id = this.getIdentifier();
        const selected = id === this.props.selectedPreset;

		const computedStyles = {
			...computePreset(this.props.preset.icon, this.props.theme)
		};

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.section title"
					options={this.getPresetOptions(computedStyles)} 
					open={selected}
					target={this.optionTooltipRef.current}
				/>

				<p className="preset-title">
					<FormattedMessage id="elements.icon title" />
				</p>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(id), 0)}
					ref={this.optionTooltipRef}
				>
					<i className="fa fa-star-o" style={computedStyles} />
				</PresetContainer>
			</React.Fragment>
		);
	}
}

DividerIconPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(DividerIconPresets));