import React, { PureComponent } from 'react';
import { Slider, Button, Icon, FormField, Divider} from 'svift-ui';
// import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import InfoIcon from 'components/InfoIcon';
import Tooltip from 'svift-library/client/tooltip';

class StandaloneSlider extends PureComponent {
	render() {
		const { value, min, max, onChange, onClear, title, optIn, icons, outsidePanel, optionalDisabled, infoIcon, step } = this.props;

		const className = outsidePanel ? '' : 'option-panel small-panel';

		return (
			<FormField style={{ fontSize: 12 }} className={className}>
				{optIn &&
					<Icon
						link
						disabled={optionalDisabled}
						name="sn-cross"
						className="primary"
						style={{ fontSize: 12, marginTop: 4, float: 'right' }}
						onClick={!onClear ? () => {} : onClear} 
					/>
				}

				<label>
					{icons && <Icon name={icons[0] || 'sn-eye'} style={{ fontSize: 14 }} />}
					{title} {infoIcon ? <InfoIcon description={infoIcon} /> : null}
				</label>

				<Slider
					key="slider"
					value={value}
					min={min}
					max={max}
					step={step}
					onChange={onChange}
					style={{ marginTop: 0, marginBottom: 16 }}
				/>
			</FormField>
		);
	}
}

class SliderOption extends PureComponent {
	constructor() {
		super();

		this.state = {
			hover: false
		};

		// this.tooltip = tooltip.call(this);
		this.tooltipRef = React.createRef();
	}

	render() {
		const { option, onToggle, selectedOption, icons, activeButton } = this.props;

		const selected = option === selectedOption;

		return (
			<div className="option" style={{ display: 'inline-block' }}>
				<Button
					icon
					basic
					size="tiny"
					primary={activeButton}
					className={activeButton ? "active-button" : ''}
					// ref={this.tooltip.setTarget}
					ref={this.tooltipRef}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				>
					{icons.map((icon, index) => {
						if (index === 0) {
							return <Icon key={index} corner name={icon} />;
						}

						return (
							<Icon key={index} corner name={icon} style={{ marginLeft: 8 }} />
						);
					})}
				</Button>

				<Tooltip
					target={this.tooltipRef.current}
					open={selected}
					// open={true}
					popup
					position="top center"
					zIndex="999"
				>
					<StandaloneSlider {...this.props} />
				</Tooltip>

				{/* <Tooltip
					target={this.tooltip.getTarget()}
					logs
					onClick={(e) => {
						e.stopPropagation();
						e.nativeEvent.stopImmediatePropagation()
					}}
					open={selected}
					hover={this.state.hover}
					hoverContent={this.props.title}
					content={<StandaloneSlider key="slider" {...this.props} />}
					style={{ zIndex: 999 }}
				/> */}
			</div>
		);
	}
}

SliderOption.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func,
	title: PropTypes.string,
	optIn: PropTypes.bool,
	onClear: PropTypes.func,

	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func
};

export { StandaloneSlider };
export default SliderOption;
