import React, { PureComponent } from 'react';
import { GridColumn, Slider, Grid, GridRow, Button } from 'svift-ui';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
// import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import BorderSubOption from './BorderSubOption';

class BorderOption extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			selectedOption: null
		};

		this.tooltip = tooltip.call(this);

		this.onChange = this.onChange.bind(this);
		this.selectOption = this.selectOption.bind(this);
	}

	onChange(key, newValue) {
		const { value } = this.props;

		if (key === 'borderGeneral') {
			if (newValue === null) {
				this.props.onChange({});

				return;
			}

			this.props.onChange({
				borderGeneral: newValue
			});

			return;
		}

		const newBorder = {
			...value,
			[key]: newValue
		};

		if (newValue === null) {
			delete newBorder[key];
		}

		delete newBorder.borderGeneral;

		this.props.onChange(newBorder);
	}

	componentWillReceiveProps(nextProps) {
		// Resets the open tooltips whenever this component gets selected or unselected
		if (this.props.selectedOption !== nextProps.selectedOption) {
			this.selectOption(null);
		}
	}

	selectOption(option) {
		this.setState({
			selectedOption: option
		});
	}

	render() {
		const { option, onToggle, selectedOption, value, intl } = this.props;

		const selected = option === selectedOption; // Not to be confused with selectedOption in state

		const { borderBottom, borderGeneral, borderLeft, borderRight, borderTop } = value;

		return (
			<div className="option" style={{ display: 'inline-block' }} >
				<Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					position="top"
					content={
						<Grid columns={16} centered verticalAlign="middle" className="manipulator tiny-panel border-options">
							<p className="option-title"><FormattedMessage id="options.border title" /></p>
							<GridRow columns={3}>
								<GridColumn style={{ height: '50%' }} className="top left" />
								<GridColumn textAlign="center">
									<BorderSubOption
										icon="sn-border_top"
										title={intl.formatMessage({ id: 'options.border top title' })}
										colors={this.props.colors}
										onChange={newValue => this.onChange('borderTop', newValue)}
										value={borderTop}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderTop"
									/>

{/*									<Slider
										optIn
										onClear={() => this.onChange('borderTop', null)}
										optionalDisabled={typeof borderTop === 'undefined'}
										activeButton={typeof borderTop !== 'undefined'}

										icons={['sn-border_top']}
										title={
											`
												Top Border${typeof borderTop === 'undefined' ? '' : `: ${Math.floor(borderTop * 100)}%`}
											`
										}
										onChange={newValue => {
											this.onChange('borderTop', newValue);
										}}

										value={borderTop * 100 || 50}
										min={50}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="border top"
									/>*/}
								</GridColumn>
								<GridColumn style={{ height: '50%' }} className="top right" />
							</GridRow>

							<GridRow centered columns={3} className="left right">
								<GridColumn textAlign="center">
									<BorderSubOption
										icon="sn-border_left"
										title={intl.formatMessage({ id: 'options.border left title' })}
										colors={this.props.colors}
										onChange={newValue => this.onChange('borderLeft', newValue)}
										value={borderLeft}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderLeft"
									/>
{/*									<Slider
										optIn
										onClear={() => this.onChange('borderLeft', null)}
										optionalDisabled={typeof borderLeft === 'undefined'}
										activeButton={typeof borderLeft !== 'undefined'}

										icons={['sn-border_left']}
										title={
											`
												Left Border${typeof borderLeft === 'undefined' ? '' : `: ${Math.floor(borderLeft * 100)}%`}
											`
										}
										onChange={newValue => {
											this.onChange('borderLeft', newValue);
										}}

										value={borderLeft * 100 || 50}
										min={50}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="border left"
									/>*/}
								</GridColumn>
								<GridColumn textAlign="center">
									<BorderSubOption
										icon="sn-border_outer"
										title={intl.formatMessage({ id: 'options.border general title' })}
										colors={this.props.colors}
										onChange={newValue => this.onChange('borderGeneral', newValue)}
										value={borderGeneral}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderGeneral"
									/>
									{/*<Slider
										optIn
										onClear={() => this.onChange('borderGeneral', null)}
										optionalDisabled={typeof borderGeneral === 'undefined'}
										activeButton={typeof borderGeneral !== 'undefined'}

										icons={['sn-border_outer']}
										title={
											`
												General Border${typeof borderGeneral === 'undefined' ? '' : `: ${Math.floor(borderGeneral * 100)}%`}
											`
										}
										onChange={newValue => {
											this.onChange('borderGeneral', newValue);
										}}
										value={borderGeneral * 100 || 50}
										min={50}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="general border"
									/>*/}
								</GridColumn>
								<GridColumn textAlign="center">
									<BorderSubOption
										icon="sn-border_right"
										title={intl.formatMessage({ id: 'options.border right title' })}
										colors={this.props.colors}
										onChange={newValue => this.onChange('borderRight', newValue)}
										value={borderRight}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderRight"
									/>
								</GridColumn>
							</GridRow>

							<GridRow columns={3}>
								<GridColumn style={{ height: '50%' }} className="bottom left" />
								<GridColumn textAlign="center">
									<BorderSubOption
										icon="sn-border_bottom"
										title={intl.formatMessage({ id: 'options.border bottom title' })}
										colors={this.props.colors}
										onChange={newValue => this.onChange('borderBottom', newValue)}
										value={borderBottom}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderBottom"
									/>
								</GridColumn>
								<GridColumn style={{ height: '50%' }} className="bottom right" />
							</GridRow>
						</Grid>
					}
					hover={this.state.hover}
					hoverContent={intl.formatMessage({ id: 'options.border title' })}
					style={{ zIndex: 999 }}
				/>

				<Button
					ref={this.tooltip.setTarget}
					basic
					size="tiny"
					icon="sn-border_outer"
					className={`primary ${value && Object.keys(value).length > 0 ? 'active-button' : ''}`}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				/>
			</div>
		);
	}
}

BorderOption.propTypes = {
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func
};

export default injectIntl(BorderOption);