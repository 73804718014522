import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import WithBackground from 'svift-library/client/image/WithBackground';
import css from './grid.scss';
import VideoBackground from 'svift-library/client/renderer/nodes/components/library/__components/background-video';

class EditorGrid extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, files } = props.context;

		return shallowCompare({
			theme,
			files,
			// device
		}, state);
	}

	getStyling = () => {
		return {
			position: 'relative',
			flexDirection: 'column', // Forcing rows to stack vertically
			alignItems: this.props.node.props.horizontalAlign || 'center',
			justifyContent: this.props.cropAlignment || this.props.node.props.verticalAlign || 'flex-start',
			...this.props.computedStyling,
			...this.props.cropStyling
		};
	}

	render() {
		const { node } = this.props;;

		const file = this.props.files.lookup[node.props.backgroundImage];

		return (
			<WithBackground
				nodeProps={node.props}
				file={file}
				render={(backgroundStyles, imageRef) => {
					let className = css['grid'];

					const style = {
						...this.getStyling(),
						...backgroundStyles
					}

					if (backgroundStyles) className = className + ' editor-background-image';
					// if (this.props.isWrapped) className = className + ` ${css['collapsing-margin-fix']}`;

					return (
						<section 
							className={className}
							ref={ref => {
								if (imageRef) imageRef.current = ref;
								if (this.props.cropRef) this.props.cropRef.current = ref;
							}}
							style={style}
						>
							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}

							<VideoBackground {...this.props} />

							{
								node.children.map(childID => {
									return (
										<Node
											freezeDragPath={this.props.freezeDragPath}	
											key={childID}
											nodeID={childID}
											ContextConsumer={this.props.ContextConsumer}
											getPath={this.props.getPath}
										/>
									);
								})
							}

							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}
						</section>
					)
				}}
			/>
		);
	}
}

export default Crop(EditorGrid);