import React, { PureComponent } from 'react';
import {
	Icon,
	Popup,
	ListItem,
	Button
} from 'svift-ui';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { connect } from 'react-redux';
import { replaceRoute, setRoute } from 'utils/routing';
import ListMenu from 'components/list-menu';
import NoResult from 'components/NoResult';
import { injectIntl } from 'react-intl';
import navigationActions from 'store/actions/sites/navigation';
import navigationSelector from 'store/selectors/sites/navigation';
import { pagesSelector } from 'store/selectors/sites/pages';
import ModalWrapper from 'components/modal';
import NavigationForm from 'components/forms/navigation';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import SviftGA from 'utils/svift-ga';
// import Alert from 'components/Alert';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const navid = state.sites.navigation.params.navid;

	const { stagedNavigation, stagedFrom } = state.sites.navigation;
	const pages = pagesSelector(state, siteid);
	const navigations = navigationSelector(state, siteid, pages);

	return {
		stagedNavigation,
		stagedFrom,
		navigation: navigations.find(navigation => navigation._id === navid),
		navid,
		siteid,
		navigations
	};
};

const mapDispatchToProps = dispatch => {
	return {
		stageNavigation: (...args) => dispatch(navigationActions.stageNavigation(...args)),
		createNavigation: (...args) => dispatch(navigationActions.createNavigation(...args)),
		deleteNavigation: (siteid, navid) => dispatch(navigationActions.deleteNavigation(siteid, navid)),
		updateNavigation: (...args) => dispatch(navigationActions.updateNavigation(...args)),
		save: (siteID, navID) => dispatch(navigationActions.save(siteID, navID))
	};
};

const navigationExists = (navigations, navigationid) => navigations.find(navigation => navigationid === navigation._id);

class MenuHeader extends PureComponent {
	constructor() {
		super();

		this.state = {
			loading: false,
			deleteAlertIsOpen: false,
			navigationListOpen: false,
			navigationModalOpen: false,
			navigationSettingsModalOpen: false
		};

		this.handleRoute = this.handleRoute.bind(this);
		this.renderListMenu = this.renderListMenu.bind(this);
		this.toggleNavigationModal = this.toggleNavigationModal.bind(this);
		this.toggleNavigationList = this.toggleNavigationList.bind(this);
		this.setSelectedNavigation = this.setSelectedNavigation.bind(this);
		this.toggleNavigationSettingsModal = this.toggleNavigationSettingsModal.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.createNavigation = this.createNavigation.bind(this);
		this.updateNavigation = this.updateNavigation.bind(this);
		this.deleteNavigation = this.deleteNavigation.bind(this);
		this.save = this.save.bind(this);
	}

	handleRoute() {
		const { navigations, siteid, navid, stagedNavigation } = this.props;

		if (!navid || !navigationExists(navigations, navid)) {
			replaceRoute(`/dashboard/${siteid}/design/navigation`);

			/*
				Automatically generated menu
			*/
			this.props.stageNavigation({
				navigation: navigations[0].entries
			});

			return;
		}

		if (stagedNavigation === null) {
			this.props.stageNavigation({
				navigation: navigations.find(navigation => navigation._id === navid).entries
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.navid !== this.props.navid || this.props.siteid !== prevProps.siteid) {
			this.handleRoute();
		}
	}

	componentDidMount() {
		this.handleRoute();
	}

	toggleNavigationModal() {
		this.setState({
			navigationModalOpen: !this.state.navigationModalOpen
		});
	}

	toggleNavigationSettingsModal() {
		this.setState({
			navigationSettingsModalOpen: !this.state.navigationSettingsModalOpen
		});
	}

	toggleNavigationList() {
		this.setState({
			navigationListOpen: !this.state.navigationListOpen
		});
	}

	handleModalClose() {
		this.setState({
			navigationModalOpen: false,
			navigationSettingsModalOpen: false,
			isAuto: false
		});
	}

	async createNavigation(values) {
		const { siteid, navigations } = this.props;

		this.setState({
			loading: true
		});

		try {
			await this.props.createNavigation(siteid, {
				...values,
				entries: this.state.isAuto ? this.props.stagedNavigation : navigations[0].entries
			});

			SviftGA.NAVIGATION.CREATED();

			this.setState({
				loading: false,
				isAuto: false,
				navigationModalOpen: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});
			handleSubmitError(this.props.intl)(e);
		}
	}

	async updateNavigation(values) {
		const { siteid, navid } = this.props;

		this.setState({
			loading: true
		});

		try {
			await this.props.updateNavigation({
				navID: navid,
				siteID: siteid,
				newNavigation: values
			});

			SviftGA.NAVIGATION.MODIFIED();

			this.setState({
				loading: false,
				navigationSettingsModalOpen: false
			});
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'design.navigation settings notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'setting',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deleteNavigation() {
		this.setState({
			loading: true
		});

		try {
			await this.props.deleteNavigation(this.props.siteid, this.props.navid);

			SviftGA.NAVIGATION.DELETED();

			this.setState({
				loading: false,
				navigationSettingsModalOpen: false
			});
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'design.delete navigation notification' }),
                className: 'info',
                position: 'topCenter',
                icon: 'trash',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async save() {
		const { siteid, navid } = this.props;

		/*
			If we are trying to save the automatically generated navigation, then prompt user to create custom navigation based on the automatically generated one
		*/
		if (!navid) {
			this.setState({
				navigationModalOpen: true,
				isAuto: true
			});

			return;
		}

		await this.props.save(siteid, navid);
		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'design.save navigation notification' }),
			className: 'positive',
			position: 'topCenter',
			icon: 'sn-cloud-upload',
		   deleteTime: 2000
		});
	}

	setSelectedNavigation(navID, e) {
		const siteid = this.props.siteid;

		if (navID === 'auto') {
			setRoute(`/dashboard/${siteid}/design/navigation`);

			return;
		}

		if (navID !== this.props.navid) {
			setRoute(`/dashboard/${siteid}/design/navigation/${navID}`);
		}

		e.stopPropagation();
		e.preventDefault();
	}

	renderListMenu() {
		const { navigations, intl } = this.props;

		// const KEYS_TO_FILTERS = ['title'];
		// const filteredLayouts = this.props.layouts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));

		if (!this.state.navigationListOpen) return null;

		return (
			<ListMenu
				dataSource={this.props.navigations}
				handleFilter={(e, d) => this.handleFilter(e, d)}
				onClose={this.toggleNavigationList}
				modalText={this.props.intl.formatMessage({ id: 'menus.create new navigation' })}
				onModalClick={this.toggleNavigationModal}
				icon="sn-direction"
				title={this.props.intl.formatMessage({ id: 'menus.navigations title' })}
			>
				{navigations.length === 0 ? <NoResult />
					:
					navigations.map(navigation => {
						if (navigation.type === 'auto') {
							return (
								<ListItem
									key={navigation.type}
									onClick={(e) => {
										this.toggleNavigationList();

										this.setSelectedNavigation('auto', e);
									}}
									className={!this.props.navid ? 'active-item' : ''}
								>
									<span><Icon name="sn-direction" />{intl.formatMessage({ id: 'menus.auto navigation title' })}</span>
								</ListItem>
							);
						}

						return (
							<ListItem
								key={navigation._id}
								className={navigation._id === this.props.navid ? 'active-item' : ''}
								onClick={(e) => {
									this.toggleNavigationList();

									this.setSelectedNavigation(navigation._id, e);
								}}
							>
								<p><Icon name="sn-direction" />{navigation.title}</p>
							</ListItem>
						);
					})
				}
			</ListMenu>
		);
	}

	render() {
		const { intl, navigation, stagedNavigation, stagedFrom } = this.props;

		if (!stagedNavigation) return null;

		return (
			<AppNavigation
				appIcon="sn-direction"
				appTitle={intl.formatMessage({ id: 'menus.title' })}
			>
				<div className="left-panel">
				<Button
					basic
					size="small"
					className="primary"
					onClick={this.toggleNavigationList}
				>
					<Icon name="sn-direction" style={{ marginRight: 4 }} />
					<span>
						{navigation.title || intl.formatMessage({ id: 'menus.auto navigation title' })}
					</span>
					<Icon
						name={this.state.navigationListOpen ? 'sn-arrow-down3' : 'sn-arrow-up3'}
						style={{ fontSize: 10, marginLeft: 6, marginRight: 0 }}
					/>
				</Button>

				{this.renderListMenu()}

				{navigation.type !== 'auto' &&
					<Popup
						trigger={
							<Icon
								link
								className="primary"
								name="setting"
								style={{ fontSize: 24, marginLeft: 8, marginRight: 16 }}
								onClick={() => {
									this.setState({
										navigationListOpen: false,
										navigationSettingsModalOpen: true
									});
								}}
							/>
						}
						content={intl.formatMessage({ id: 'menus.navigation settings' })}
						position="bottom center"
					/>
				}
				</div>
				
				<div className="right-panel">
				<Popup
					trigger={
						<Button
							disabled={stagedFrom === stagedNavigation}
							positive
							icon="sn-cloud-upload"
							size="mini"
							content={intl.formatMessage({ id: 'menus.save navigation' })}
							onClick={() => this.save()}
							style={{ padding: '8px 12px', marginLeft: 'auto' }}
						/>
					}
					content={intl.formatMessage({ id: 'menus.save navigation popup' })}
					position="bottom right"
				/>
				</div>

				{navigation &&
					<ModalWrapper
						open={this.state.navigationSettingsModalOpen}
						handleClose={this.handleModalClose}
						header="menus.navigation settings"
						style={{ maxWidth: 400 }}
					>
						<NavigationForm
							update
							loading={this.state.loading}
							enableReinitialize
							initialValues={{
								title: navigation.title
							}}
							onClose={this.handleModalClose}
							delete={this.deleteNavigation}
							onSubmit={this.updateNavigation}
						/>
					</ModalWrapper>
				}

				<ModalWrapper
					open={this.state.navigationModalOpen}
					handleClose={this.handleModalClose}
					header="menus.new navigation"
					style={{ maxWidth: 400 }}
				>
					<NavigationForm
						loading={this.state.loading}
						onClose={this.handleModalClose}
						onSubmit={this.createNavigation}
					/>
				</ModalWrapper>

 				{/* <Alert
 					show={this.state.deleteAlertIsOpen}
 					title="Delete this navigation"
 					text="You cannot undo this!"
 					onConfirm={() => this.deleteNavigation()}
 					showCancelButton
 					onCancel={() => this.showDeleteAlert()}
 				/> */}
			</AppNavigation>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(MenuHeader)));