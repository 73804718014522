import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './app-content.scss';

class AppContent extends Component {
	render() {
		return (
			<div id={css['app-content']} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}

AppContent.propTypes = {
	children: PropTypes.node,
};

AppContent.defaultProps = {
	children: <p>No content in AppContent...</p>,
};

export default AppContent;
