import React, { PureComponent } from 'react';
import { Icon, Header, Divider } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
// import FileModal from 'components/option-panel/options/file-picker/modal/FileModal';
import { connect } from 'react-redux';
import siteActions from 'store/actions/sites';
import { filesSelector } from 'store/selectors/sites/files';
import FilePicker from 'components/option-library/primitives/file';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	const { favicon, logoHorizontal, logo } = site;

	return {
		favicon,
		logoHorizontal,
		logo,
		siteid,
		files: filesSelector(state, siteid)
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setBranding: (...data) => dispatch(siteActions.setBranding(...data))
	};
};

@connect(mapStateToProps, mapDispatchToProps)
class Branding extends PureComponent {
	constructor() {
		super();

		this.state = {
			open: false,
			loading: false,
		};

		this.renderModal = this.renderModal.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.onFileReady = this.onFileReady.bind(this);
		
	}

	toggleModal(fileLogo) {
		this.setState({
			open: !this.state.open && fileLogo
		});
	}

	clearImg =(fileType)=>{
		this.props.setBranding({
			fileID: null,
			siteID: this.props.siteid,
			type: fileType
		});
	}


	// async onFileReady(files, closeModal) {
	// 	// const { _id } = files[0];

	// 	// console.log(files, 'files');
	// 	const { _id } = Object.values(files)[0];

	// 	this.setState({
	// 		loading: true
	// 	}, async () => {
	// 		try {
	// 			await this.props.setBranding({
	// 				fileID: _id,
	// 				siteID: this.props.siteid,
	// 				type: this.state.open
	// 			});

	// 			this.setState({
	// 				loading: false,
	// 				open: false
	// 			});
	// 		} catch (e) {
	// 			this.setState({
	// 				loading: false,
	// 				open: false
	// 			});
	// 		}
	// 	});
	// }

	async onFileReady(files, type) {
		this.setState({
			loading: true
		}, async () => {
			try {
				await this.props.setBranding({
					fileID: files,
					siteID: this.props.siteid,
					type
				});

				this.setState({
                    loading: false,
                    open: false
				});
			} catch (e) {
				this.setState({
                    loading: false,
                    open: false
				});
			}
		});
	}

	renderModal() {
		const {
			intl,
			siteid
		} = this.props;

		let selected;

		switch (this.state.open) {
			case 'logo':
				selected = this.props.logo;

				break;
			case 'logoHorizontal':
				selected = this.props.logoHorizontal;
			
				break;
			case 'favicon':
				selected = this.props.favicon;
		}

		return this.state.open && (
			<FileModal
				intl={intl}
				toggleModal={() => this.setState({ open: false })}
				siteid={siteid}
				tabs={['files']}
				value={selected}
				onChange={this.onFileReady}
				filter={file => file.meta.mimeType.startsWith('image')}
			/>
		);
	}

	render() {
		const { favicon, logo, logoHorizontal, files, intl } = this.props;

		// const faviconFile = files.find(({ _id }) => _id === favicon);
		// const logoFile = files.find(({ _id }) => _id === logo);
		// const logoHorizontalFile = files.find(({ _id }) => _id === logoHorizontal);

		// const faviconSrc = faviconFile ? faviconFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/favicon.png';
		// const logoSrc = logoFile ? logoFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg';
		// const horizontalLogoSrc = logoHorizontalFile ? logoHorizontalFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/horizontal-logo.svg';

		return (
			<React.Fragment>
				{this.renderModal()}

				{/* favicon */}
				<Header as="h6" style={{ margin: 4 }}>
					<span>
						<Icon name="sn-star-empty" style={{ opacity: 0.4, marginRight: 6 }} />
						<FormattedMessage id="options.branding favicon title" />
						<InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding favicon description' })} />
					</span>
				</Header>
				<FilePicker 
				 	title={<FormattedMessage id="options.branding title" />}
				 	tabs={['files']}
				 	filter={file => file.meta.mimeType.startsWith('image')}
				 	onChange={(files) => this.onFileReady(files, 'favicon')}
				 	value={favicon}
				 />

				<Divider />

				{/* square logo */}
				<Header as="h6" style={{ margin: 4 }}>
					<span>
						<Icon name="sn-star-empty" style={{ opacity: 0.4, marginRight: 6 }} />
						<FormattedMessage id="options.branding square logo title" />
						<InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding square logo description' })} />
					</span>
				</Header>
				<FilePicker 
					title={<FormattedMessage id="options.branding title" />}
					tabs={['files']}
					filter={file => file.meta.mimeType.startsWith('image')}
					onChange={(files) => this.onFileReady(files, 'logo')}
					value={logo}
				/>

				<Divider />

				{/* horizontal logo */}
				<Header as="h6" style={{ margin: 4 }}>
					<span>
						<Icon name="sn-star-empty" style={{ opacity: 0.4, marginRight: 6 }} />
						<FormattedMessage id="options.branding horizontal logo title" />
						<InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding horizontal logo description' })} />
					</span>
				</Header>
				<FilePicker 
					title={<FormattedMessage id="options.branding title" />}
					tabs={['files']}
					filter={file => file.meta.mimeType.startsWith('image')}
					onChange={(files) => this.onFileReady(files, 'logoHorizontal')}
					value={logoHorizontal}
				/>
			</React.Fragment>
		);
	}
}
export default injectIntl(Branding);