module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    backgroundCover: true
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Overskrift her',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 10,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Feed',
                                props: {
                                    type: 'BlogPosts',
                                    viewType: 'List',
                                    itemsPerPage: 3,
                                    filter: null,
                                    thumbnail: {
                                        preset: 'Normal',
                                        visible: true,
                                        style: {
                                            margin: {
                                                marginRight: 1
                                            }
                                        }
                                    },
                                    postTitle: {
                                        preset: 'Heading 3',
                                        visible: true,
                                        style: {
                                            margin: {
                                                marginBottom: 0.2
                                            }
                                        }
                                    },
                                    postPublicationDate: {
                                        preset: 'Small',
                                        visible: true,
                                        style: {}
                                    },
                                    postDescription: {
                                        preset: 'Normal',
                                        visible: true,
                                        style: {
                                            margin: {
                                                marginBottom: 0.75
                                            }
                                        }
                                    },
                                    postAuthor: {
                                        preset: 'Small',
                                        visible: true,
                                        style: {
                                            margin: {
                                                marginBottom: 0.5
                                            }
                                        }
                                    }
                                }
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 6,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Her kan du give en overordnet præsentation af den blog som optræder med listede indlæg i Feed-elementet ved siden af.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
