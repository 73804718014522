exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".logo__img-wrapper--MVRef{display:flex;justify-content:center;align-items:center}\n", ""]);

// exports
exports.locals = {
	"img-wrapper": "logo__img-wrapper--MVRef"
};