import React, { memo, useCallback } from 'react';
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Radio from 'components/option-library/primitives/radio';
import NumberPicker from 'components/option-library/primitives/input/number';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import Label from 'components/option-library/label';
import FilePicker from 'components/option-library/primitives/file';
import Checkbox from 'components/option-library/primitives/checkbox';
import Section from 'components/editor-v2/editor-interface/options/Section';
import { injectIntl } from 'react-intl';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => { 
        updateState(context => {
            let nextValue = {
                ...context.nodeMap[node.id].props,
                [key]: value
            }
    
            if (typeof value === 'undefined') {
                delete nextValue[key];
            }
            
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: nextValue
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                icon="sn-image-compare"
                title={intl.formatMessage({ id: 'options.background image title' })}
            >
                <Label>
                    <FilePicker 
                        tabs={['files']}
                        filter={file => file.meta.mimeType.startsWith('image')}
                        onChange={file => {
                            onChange('backgroundImage', file, 'Set background image', false);
                        }}
                        value={node.props.backgroundImage}
                    />
                </Label>

                <Label
                    collapsed
                    labelIcon="sn-eye"
                    label={intl.formatMessage({ id: 'options.background image opacity title' })}
                >
                    <NumberPicker 
                        name={intl.formatMessage({ id: 'options.background image opacity title' })}
                        disableUndoRedo
                        unit="%"
                        min={0}
                        max={100}
                        value={node.props.backgroundOpacity}
                        placeholder={intl.formatMessage({ id: 'options.background image opacity input placeholder' })}
                        onChange={val => onChange('backgroundOpacity', val, 'background image opacity')}
                    />
                </Label>
                <Label
                    labelIcon="sn-grab"
                    label={intl.formatMessage({ id: 'options.background image fixed label' })}
                >
                    <Checkbox
                        icon="sn-grab"
                        label={intl.formatMessage({ id: 'options.background image fixed title' })}
                        value={node.props.backgroundFixed}
                        onChange={val => onChange('backgroundFixed', val, 'background image fixed', false)}
                    />
                </Label>

                <Label>
                    <Label
                        collapsed
                        labelIcon="sn-rulers"
                        label={intl.formatMessage({ id: 'options.background image dimensions label' })}
                    >
                        <Radio 
                            options={[
                                {
                                    value: true,
                                    label: intl.formatMessage({ id: 'options.background image dimensions auto label' })
                                },
                                {
                                    value: false,
                                    label: intl.formatMessage({ id: 'options.background image dimensions custom label' })
                                }
                            ]}
                            onChange={nextVal => onChange('backgroundCover', nextVal, 'background image setting', false)}
                            value={typeof node.props.backgroundCover === 'undefined' ? true : node.props.backgroundCover}
                        />
                    </Label>

                    {!node.props.backgroundCover && typeof node.props.backgroundCover !== 'undefined' &&
                        <Section nested>
                            <Label
                                labelIcon="sn-width"
                                label={intl.formatMessage({ id: 'options.background image width title' })}
                            >
                                <NumberPicker 
                                    name={intl.formatMessage({ id: 'options.background image width title' })}
                                    unit={node.props.backgroundWidthUnit}
                                    units={['px', '%']}
                                    onUnitChange={newUnit => onChange('backgroundWidthUnit', newUnit, 'background image width unit')}
                                    min={0}
                                    disableUndoRedo
                                    max={1000}
                                    value={node.props.backgroundWidth}
                                    placeholder={intl.formatMessage({ id: 'options.background image width input placeholder' })}
                                    onChange={val => onChange('backgroundWidth', val, 'background image width')}
                                />
                            </Label>

                            <Label 
                                labelIcon="sn-height2"
                                label={intl.formatMessage({ id: 'options.background image height title' })}
                            >
                                <NumberPicker 
                                    name={intl.formatMessage({ id: 'options.background image height title' })}
                                    unit={node.props.backgroundHeightUnit}
                                    units={['px', '%']}
                                    onUnitChange={newUnit => onChange('backgroundHeightUnit', newUnit, 'background image height unit')}
                                    min={0}
                                    disableUndoRedo
                                    max={1000}
                                    value={node.props.backgroundHeight}
                                    placeholder={intl.formatMessage({ id: 'options.background image height input placeholder' })}
                                    onChange={val => onChange('backgroundHeight', val, 'background image height')}
                                />
                            </Label>

                            <Checkbox
                                icon="sn-arrow-resize3"
                                label={intl.formatMessage({ id: 'options.background image repeat-x title' })}
                                value={node.props.backgroundRepeatX}
                                onChange={val => onChange('backgroundRepeatX', val, 'background image repeat horizontally', false)}
                            />

                            <Checkbox
                                icon="sn-arrow-resize4"
                                label={intl.formatMessage({ id: 'options.background image repeat-y title' })}
                                value={node.props.backgroundRepeatY}
                                onChange={val => onChange('backgroundRepeatY', val, 'background image repeat vertically', false)}
                            />
                        </Section>
                    }
                </Label>
                
                <Label 
                    label={intl.formatMessage({ id: 'options.background image horizontal position title' })}
                    labelDescription={intl.formatMessage({ id: 'options.background image horizontal position description' })}
                >
                    <NumberPicker 
                        name={intl.formatMessage({ id: 'options.background image horizontal position title' })}
                        disableUndoRedo
                        unit="%"
                        min={0}
                        max={100}
                        value={node.props.backgroundHorizontalAlignment}
                        placeholder={intl.formatMessage({ id: 'options.background image horizontal position input placeholder' })}
                        onChange={val => onChange('backgroundHorizontalAlignment', val, 'background image horizontal crop')}
                    />
                </Label>

                <Label 
                    label={intl.formatMessage({ id: 'options.background image vertical position title' })}
                    labelDescription={intl.formatMessage({ id: 'options.background image vertical position description' })}
                >
                    <NumberPicker 
                        name={intl.formatMessage({ id: 'options.background image vertical position title' })}
                        disableUndoRedo
                        unit="%"
                        min={0}
                        max={100}
                        value={node.props.backgroundVerticalAlignment}
                        placeholder={intl.formatMessage({ id: 'options.background image vertical position input placeholder' })}
                        onChange={val => onChange('backgroundVerticalAlignment', val, 'background image vertical crop')}
                    />
                </Label>
            </Section>
        </Accordion>
    );
}));

/*
{
    opacity,
    backgroundImage,
    backgroundOpacity,
    backgroundHeight,
    backgroundWidth,
    backgroundFixed,
    backgroundCover,
    backgroundRepeatX,
    backgroundRepeatY,
    backgroundVerticalAlignment,
    backgroundHorizontalAlignment
}
*/