import React, { Component } from 'react';
import { Message, Container, Icon, Header, Button, Label } from 'svift-ui';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import { FormattedMessage } from 'react-intl';
import { setRoute } from 'utils/routing';

export default class Notification extends Component {
	render() {
		const { loginLink } = this.props;

		if (loginLink) {
			return (
				<AppContentSection>
					<Message className="positive">
						<Container text textAlign="center" style={{ paddingTop: 32, paddingBottom: 40 }}>
							<Icon name="sn-star-empty" className="info-color" size="huge" style={{ marginBottom: 16, opacity: 0.6 }} />
							<Header as="h1" style={{ marginBottom: 24 }}><FormattedMessage id="messages.profile activation title" /></Header>
							<p style={{ marginBottom: 32 }}><FormattedMessage id="messages.profile activation description" /></p>
							<Button primary size="big" style={{ marginBottom: 24 }} onClick={() => setRoute('/')}><Icon name="lock" /> <FormattedMessage id="messages.profile activation button" /></Button>
						</Container>
					</Message>
				</AppContentSection>
			);
		}

		return (
			<AppContentSection>
				<Message className="info">
					<Container text textAlign="center" style={{ paddingTop: 32, paddingBottom: 40 }}>
						<Icon name="send outline" className="info-color" size="huge" style={{ marginBottom: 16, opacity: 0.6 }} />
						<Header as="h1" style={{ marginBottom: 24 }}><FormattedMessage id="messages.email confirmation title" /></Header>
						<p style={{ marginBottom: 32 }}><FormattedMessage id="messages.email confirmation description" /></p>
						<Label basic className="info" size="big" style={{ fontWeight: 400 }}><FormattedMessage id="messages.email confirmation label" /></Label>
					</Container>
				</Message>
			</AppContentSection>
		);
	}
}