import { createSelector } from 'reselect';

export default type => {
    let resolvers;
    
    if (type === 'blog') {
        resolvers = [
            state => state.sites.blogs.blogs,
            (state, siteid) => {
                const site = state.sites.sites[siteid];

                return site && site.blogs || [];
            }
        ];
    } else {
        resolvers = [
            state => state.sites.pages.pages,
            (state, siteid) => {
                const site = state.sites.sites[siteid];

                return site && site.pages || [];
            }
        ];
    }

    return createSelector(
        ...resolvers,
        (items, IDs) => {
            const validItems = IDs.map(ID => {
                const item = items[ID];
    
                return item;
            });

            return validItems.reduce((acc, item) => {
                if (!Array.isArray(item.keywords)) return acc;

                return acc.concat(item.keywords);
            }, [])
        }
    );
};
