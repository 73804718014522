import React, { PureComponent } from 'react';
import {
	Form,
	Button,
	Message,
	Divider,
	Icon
} from 'svift-ui';
import Label from 'components/option-library/label';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import input from 'components/forms/__util/field-components/input';
import checkbox from 'components/forms/__util/field-components/checkbox';
import validate, { string, email } from 'components/forms/__util/validate';
import generateTranslatedErrors from 'components/forms/__util/validate/validators';
import TermsAndConditions from 'components/TermsAndConditions';

const fields = {
	firstname: {
		component: input({
			type: 'text',
		}),
		validate: string(1, 255)
	},
	lastname: {
		component: input({
			type: 'text'
		}),
		validate: string(1, 255)
	},
	email: {
		component: input({
			type: 'email'
		}),
		validate: email
	},
	password: {
		component: input({
			type: 'password'
		}),
		validate: string(4, 255)
	},
	passwordrepeat: {
		component: input({
			type: 'password'
		}),
		validate: string(4, 255)
	},
	terms: {
		component: checkbox({
			name: 'registration.accept user agreement'
		}),
		validate: terms => {
			if (!terms) {
				return generateTranslatedErrors(
					[
						{ id: 'registration.accept user agreement validation' }
					]
				);
			}
		}
	}
};

const validateFields = validate(fields);

class RegistrationForm extends PureComponent {
	constructor() {
		super();

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validateFields(values);

		const { password, passwordrepeat } = values;

		if (password !== passwordrepeat) {
			throw new SubmissionError({
				_error: this.props.intl.formatMessage({ id: 'login.make sure to repeat your password correctly' })
			});
		}

		await this.props.onSubmit(values);
	}

	render() {
		const { handleSubmit, intl, invitation } = this.props;

		return (
			<Form onSubmit={handleSubmit(this.submit)}>
				<Label
					label={intl.formatMessage({ id: 'registration.enter name' })}
					labelIcon="sn-user3"
				>
					<Field
						fluid
						size="huge"
						name="firstname"
						intl={intl}
						placeholder={intl.formatMessage({ id: 'registration.enter first name' })}
						icon="sn-user4"
						iconPosition="left"
						component={fields.firstname.component}
						/>
					<Field
						fluid
						size="huge"
						name="lastname"
						intl={intl}
						placeholder={intl.formatMessage({ id: 'registration.enter last name' })}
						icon="sn-user4"
						iconPosition="left"
						component={fields.lastname.component}
					/>
				</Label>

				<Label
					label={intl.formatMessage({ id: 'registration.your email' })}
					labelIcon="sn-at-sign"
				>
					<Field
						fluid
						size="massive"
						name="email"
						intl={intl}
						disabled={invitation}
						icon="sn-at-sign"
						iconPosition="left"
						placeholder={intl.formatMessage({ id: 'registration.enter email' })}
						component={fields.email.component}
						style={{ marginBottom: 4 }}
					/>
				</Label>

				<Label
					label={intl.formatMessage({ id: 'registration.pick password' })}
					labelIcon="sn-lock5"
				>
					<Field
						fluid
						size="big"
						name="password"
						intl={intl}
						icon="sn-key"
						iconPosition="left"
						placeholder={intl.formatMessage({ id: 'registration.enter password' })}
						component={fields.password.component}
					/>
					<Field
						fluid
						size="big"
						name="passwordrepeat"
						intl={intl}
						icon="sn-key"
						iconPosition="left"
						placeholder={intl.formatMessage({ id: 'registration.reenter password' })}
						component={fields.passwordrepeat.component}
					/>
				</Label>
				
				<div style={{ textAlign: 'left' }}>
					<TermsAndConditions />
					<Field
						intl={intl}
						name="terms"
						// icon="sn-checkmark3"
						component={fields.terms.component}
					/>
				</div>
				
				{this.props.error &&
					<Message negative style={{ marginTop: 12 }}>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				<Divider />

				<Button
					type="submit"
					size="big"
					className="positive"
					style={{ marginTop: 16 }}
				>
					<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
					<FormattedMessage id="registration.register" />
				</Button>
			</Form>
		);
	}
}

const ReduxRegistrationForm = reduxForm({
	form: 'registration'
})(injectIntl(RegistrationForm));

ReduxRegistrationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	invite: PropTypes.bool
};

ReduxRegistrationForm.defaultProps = {
	initialValues: {
		terms: false
	}
};

export default ReduxRegistrationForm;