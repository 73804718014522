import React, { PureComponent } from 'react';
import Translate from 'components/hoc/translate';
import { connect } from 'react-redux';
import { Message, Container, Icon, Header, Button, Loader, ModalContent } from 'svift-ui';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import ModalWrapper from 'components/modal';
import ContactSupport from 'components/forms/contact-support';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Notifications } from 'svift-library/client/notifications';
import predefinedNotifactions from 'utils/routing/notificationMap';
import setInitialRoute from 'utils/routing/setInitialRoute';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import createApi from 'api';
import notification from 'components/hoc/notification';
import { objFromQueryString, objToQueryString } from 'utils/routing/extractParams';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';

const api = createApi();

const mapStateToProps = (state) => {
	const siteID = state.sites.params.siteid;
	const site =  state.sites.sites[siteID];
	
	return {
		siteID,
		siteName: site && site.name,
	};
};

class Root extends PureComponent {
	constructor() {
		super();

		this.state = {
			mounting: true,
			jsError: null,
			contactModalOpen: false,
		};
	}

	initReactGA = () => {
		ReactGA.initialize('UA-86188033-4', { debug: process.env.NODE_ENV === 'development' });
		// ReactGA.ga('send', {
		// 	hitType: 'pageview',
		// 	page: location.pathname
		// });
		// ReactGA.event({
		// 	category: 'Site',
		// 	action: 'Site loaded',
		// 	label: 'Site initialized'
		// });

		this.props.history.listen(location => {
			ReactGA.ga('set', 'page', location.pathname);
			ReactGA.ga('send', {
				hitType: 'pageview',
				page: location.pathname
			});
		});
	}

	componentDidMount() {
		const queryString = this.props.location.search;

		const queryObj = objFromQueryString(queryString);
		let pendingNotification;

		if (queryObj.notification) {
			pendingNotification = predefinedNotifactions[queryObj.notification];
		}

		setInitialRoute();

		this.initReactGA();

		// Use sparringly - normal components should use injectIntl or FormattedMessage
		window.__intlRef = this.props.intl;

		this.setState({
			mounting: false,
			pendingNotification
		}, () => {
			if (this.state.pendingNotification) {
				this.props.createNotification(pendingNotification);

				const newQueryObj = {
					...queryObj
				};

				delete newQueryObj.notification;

				let cleanedQueryString = objToQueryString(newQueryObj);

				if (cleanedQueryString.length > 0) cleanedQueryString = `?${cleanedQueryString}`;

				const newURL = `${this.props.history.location.pathname}${cleanedQueryString}`;
				
				this.props.history.replace(newURL);
			}
		});
	}

	async componentDidCatch(error, info, ...rest) {
		const api = createApi();

		console.log(error, info);

		this.setState({
			jsError: error
		});

		const errorData = {
			name: error.name, // e.g. ReferenceError
			message: error.line, // e.g. x is undefined
			url: document.location.href,
			stack: error.stack,
		};

		// console.log(errorData, 'error data')

		if (process.env.NODE_ENV === 'production') {
			await api.submitError({
				siteName: this.props.siteName,
				siteID: this.props.siteID, 
				...info,
				...errorData,
				appVersion: navigator.appVersion,
				userAgent: navigator.userAgent,
				cookieEnabled: navigator.cookieEnabled,
				vendor: navigator.vendor
			});
		}
	}

	onSubmit = ({ subject, question }) => {
        this.setState({
            submittingContactForm: true
        }, async () => {
            try {
                await api.submitContactForm({ 
                    subject, 
                    question, 
                    siteID: this.props.siteID,
                    siteName: this.props.siteName
                });

				window.location.href = `/dashboard?notification=submit-error-ok`;
            } catch(e) {
                this.setState({
                    submittingContactForm: false
                });

                handleSubmitError(this.props.intl)(e);
            }
        });
	}
	
	renderJSError() {
		const { contactModalOpen } = this.state;

		return (
			<AppContentSection>
				<Message className="negative">
					<Container text textAlign="center" style={{ paddingTop: 32, paddingBottom: 40 }}>
						<Icon name="sn-warning" className="info-color" size="huge" style={{ marginBottom: 16, opacity: 0.6 }} />

						<Header as="h1" style={{ marginBottom: 24 }}><FormattedMessage id="messages.system error title" /></Header>

						<p style={{ marginBottom: 32 }}><FormattedMessage id="messages.system error description" /></p>

						<Button
							icon
							primary
							size="big"
							style={{ marginBottom: 24 }}
							onClick={e => {
								window.location.href = '/dashboard';
							}}
						>
							<Icon name="sn-logo" />
							<FormattedMessage id="messages.system error back to dashboard" />
						</Button>

						<Button 
							icon basic
							size="big" 
							className="positive" 
							style={{ marginBottom: 24 }}
							onClick={ e => {
								this.setState({contactModalOpen: !contactModalOpen})
							}}
						>
							<Icon name="mail outline" /> 
							<FormattedMessage id="messages.system error contact support" />
						</Button>

						<ModalWrapper 
                                open={this.state.contactModalOpen} 
                                handleClose={ e => {
									this.setState({contactModalOpen: false})
								}} 
                                header="support.contact support title"
                            >
                                {this.state.submittingContactForm 
                                    ?
                                        <ModalContent>
                                            <Loader size="massive" style={{ marginTop: 24, marginBottom: 32 }}> 
                                                <FormattedMessage id="general.sending loader title" />
                                            </Loader>
                                        </ModalContent>
                                    :
                                        <ContactSupport                                  
                                            onSubmit={this.onSubmit}
                                        />
                                }
                        </ModalWrapper>
					</Container>
				</Message>
			</AppContentSection>
		);
	}

	render() {
		if (this.state.jsError) return this.renderJSError();
		if (this.state.mounting) return null;

		return (
			<React.Fragment>
				{ this.props.children }
				<Notifications />
			</React.Fragment>
		);
	}
}

export default withRouter(connect(mapStateToProps)(Translate(injectIntl(notification(Root)))));
