import React, { Component } from 'react';
import {
		Input,
		Button,
		Icon,
		Popup,
		Message,
		Header
	} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import FAQSection from './FAQSection';

class FAQAccordion extends Component{
	constructor(props, context){
		super(props, context);
	}
																																							  
   render() {
		const { 
			intl, 
			keyword, 
			togglePanel, 
			openPanels, 
			accordions, 
			search, 
			deleteKeyword 
		} = this.props;
		const className = keyword ? "active-input" : "";
		const deleteButtonAction = keyword
		? <Popup
			trigger={
				<Button primary icon onClick={deleteKeyword}>
					<Icon name="sn-cross2" />
				</Button>
			}
			content={intl.formatMessage({ id: 'general.clear search button popup' })}
			position="top right"
		/> 
		: null;

		return(
			<React.Fragment>  
				<div style={{ marginTop: 8, marginBottom: 16, textAlign: 'center' }}>
					<Input
						fluid
						icon="search"
						iconPosition="left"
						action={deleteButtonAction}
						placeholder={intl.formatMessage({ id: 'support.faq search placeholder' })}
						className={className}
						type="text"
						size="big"
						value={this.props.keyword}
						onChange={search}
						style={{ marginBottom: 16 }}
					/>
				</div>
				<div>
					{accordions.map((accordion) =>
						<FAQSection 
							key={ accordion.id} 
							accordion={accordion}
							keyword={keyword}
							togglePanel={togglePanel}
							openPanels={openPanels}
						/>
					)}
					{ accordions.length === 0 
					&& <Message className="info" style={{ textAlign: 'left' }}>
							<Header as="h4" className="dark-info-color">
								<Icon name="search" style={{ opacity: 0.4 }} />
								<FormattedMessage id="general.no search results title" />
							</Header>
							<p><FormattedMessage id="general.no search results description" /></p>
					   </Message>
					}
				</div>
			</React.Fragment>
	   );
   } 
}
export default (injectIntl(FAQAccordion));