import { createBrowserHistory } from 'history';
import asyncAnchor from 'svift-library/client/routing/asyncAnchor';

const history = createBrowserHistory();

// For initial load
asyncAnchor();

history.listen(asyncAnchor);

history.listen(() => console.log(window.location.pathname, 'pname'))

export default history;