import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './app-container.scss';

class AppContainer extends Component {
	render() {
		return (
			<div
				id="app-container" // ID used in scroll method in Settings
				className={css['container']}
			>
				{this.props.children}
			</div>
		);
	}
}

AppContainer.propTypes = {
	children: PropTypes.node,
};

AppContainer.defaultProps = {
	children: <p>No app content in AppContainer...</p>,
};

export default AppContainer;
