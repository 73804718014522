const ensureValidNumber = value => {
    const parsedValue = parseInt(value, 10);

    if (isNaN(parsedValue)) {
        throw `Money util: ${value} could not be parsed to a valid number`;
    }

    return parsedValue;
};

const Money = (amount = 0, options = {}) => {
    const {
        currency = 'DKK'
    } = options;

    amount = ensureValidNumber(amount);

    const api = {
        amount,
        add: value => Money(amount + ensureValidNumber(value), options),
        subtract: value => Money(amount - ensureValidNumber(value), options),
        multiply: value => Money(amount * ensureValidNumber(value), options),
        divide: value => Money(amount / ensureValidNumber(value), options),
        addPercentages: (percentage = 0) => {
            const modifier = (100 + ensureValidNumber(percentage)) / 100;

            return Money(amount * modifier, options);
        },
        subtractPercentages: (percentage = 0) => {
            const modifier = (100 - ensureValidNumber(percentage)) / 100;

            return Money(amount * modifier, options);
        }, 
        readable: () => `${amount.toLocaleString(currency)} ${currency}`
    };

    return api;
};

module.exports = Money;