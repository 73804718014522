import React from 'react';
import { Popup, Button, Icon } from 'svift-ui';
import renderIcons from './Icons';

export default (props) => {
	const { icons, onToggle, toggled, hoverContent } = props;

	return (
		<Popup
			content={hoverContent || 'No hover text specified'}
			position="top center"
			trigger={
				<Button
					icon
					primary={props.toggled || toggled}
					basic
					circular
					className={toggled ? 'active-button' : ''}
					size="tiny"
					onClick={(e) => {
						onToggle(!toggled, e);
					}}
				>
					{renderIcons(icons)}
				</Button>
			}
		/>
	);
};