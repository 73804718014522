import React, { PureComponent } from 'react'
import { createPortal } from 'react-dom'
import Tree from 'components/tree/Tree'
import { withRouter } from 'react-router'
// import urlConverter from 'svift-library/format/url'
import MenuButton from '../components/MenuButton'
import MenuContainer from '../components/MenuContainer'

class Portal extends PureComponent {
    constructor(props) {
        super(props)

        this.node = document.createElement('div')
        this.node.id = 'hamburger-portal'
        this.node.style = `
            position: fixed;
            z-index: 10000000;
            display: flex;
            flex-direction: column;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
        `
        document.body.style.overflow = 'hidden'
        document.body.appendChild(this.node)
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
        document.body.removeChild(this.node)

        // debugger;

        // console.log('unmounted node');
    }

    render() {
        return createPortal(this.props.children, this.node)
    }
}

class Hamburger extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            openItems: {}
        }
    }

    renderOpenButton = ({ style, containerStyle }) => {
        const alignment = (alignment => {
            switch (alignment) {
                case 'right':
                    return 'flex-end'
                case 'center':
                    return 'center'
                case 'left':
                default:
                    return 'flex-start'
            }
        })(this.props.node.props.alignment)

        const theme = this.props.theme
        const generalPadding = theme.measurements.padding

        return (
            <MenuContainer
                {...this.props.cropProps}
                style={{
                    ...containerStyle,
                    justifyContent: alignment
                }}
            >
                <MenuButton
                    onClick={() => this.setState({ open: true })}
                    style={{
                        ...style,
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: generalPadding / 2
                    }}
                >
                    <i className="icon-menu7" style={{ fontSize: 30 }} />
                </MenuButton>
            </MenuContainer>
        )
    }

    renderMenuButtons = ({ style, containerStyle }) => {
        // set theme variables
        const theme = this.props.theme
        const generalPadding = theme.measurements.padding

        // console.log(this.props.menuStructure, 'menu structure')

        const onNavigate = (...args) => {
            this.setState({
                open: false
            })

            this.props.onNavigate(...args);
        }

        return (
            <div
                style={{
                    flexDirection: 'column',
                    paddingBottom: 150, // make sure the close button doesnt hide the last item om longer menus
                    flexGrow: 1,
                    overflowY: 'auto',
                    height: '100%'
                }}
            >
                <MenuContainer
                    fluid
                    vertical
                    size="massive"
                    style={{
                        ...containerStyle,
                        borderRadius: 0,
                        padding: 10,
                        margin: 0
                    }}
                >
                    <Tree
                        shouldRenderSubtree={item => {
                            const isOpen = this.state.openItems[item.data._id]

                            return isOpen
                        }}
                        items={this.props.menuStructure}
                        TextComponent={props => {
                            const { item } = props
                            const { items } = item

                            const hasChildren = items.length > 0

                            const isOpen = this.state.openItems[item.data._id]

                            const activePageUrlIdentifier = this.props.location.pathname
                                .split('/')
                                .pop()

                            let isActivePage =
                                activePageUrlIdentifier === item.data.url

                            const isRoot = activePageUrlIdentifier.length === 0

                            if (isRoot) {
                                const frontPageId = this.props.frontPage

                                isActivePage =
                                    frontPageId.toString() ===
                                    item.data._id.toString()
                            }

                            let baseStyle = {
                                ...style
                            }

                            if (isActivePage) {
                                baseStyle = {
                                    ...baseStyle,
                                    ...style[':active']
                                }
                            }

                            baseStyle.padding = generalPadding

                            if (!hasChildren) {
                                return (
                                    <MenuButton
                                        style={{
                                            ...baseStyle,
                                            padding: generalPadding,
                                            marginLeft: 0,
                                            marginRight: 0
                                        }}
                                        computedLink={item.data.computedLink}
                                        onNavigate={onNavigate}
                                    >
                                        {props.text}
                                    </MenuButton>
                                )
                            }
                            return (
                                <div
                                    className="svift-menu-group"
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    {/* parent page menu item */}
                                    <MenuButton
                                        style={{
                                            ...baseStyle,
                                            position: 'relative',
                                            padding: generalPadding,
                                            marginLeft: 0,
                                            marginRight: 0
                                        }}
                                        computedLink={item.data.computedLink}
                                        onNavigate={onNavigate}
                                        className="svift-menu-parent"
                                        // onClick={onClick}
                                    >
                                        {/* parent / expand divider */}
                                        <div
                                            style={{
                                                content: ' ',
                                                position: 'absolute',
                                                zIndex: 2,
                                                top: 0,
                                                right: -1,
                                                width:
                                                    style.borderRightWidth /
                                                        2 || 1,
                                                height: '100%',
                                                background: theme.colors.tint,
                                                opacity: 0.5
                                            }}
                                        />
                                        {props.text}
                                    </MenuButton>

                                    {/* expand menu item */}
                                    <MenuButton
                                        className="svift-menu-submenu"
                                        style={{
                                            ...baseStyle,
                                            padding: generalPadding,
                                            marginLeft: 0,
                                            marginRight: 0
                                        }}
                                        onClick={e => {
                                            // Some confusing stuff here if hamburger menu is opened in admin outside preview mode
                                            // Clicks are interefed with from the selectable/editable logic, so it looks like theres a bug that isn't there
                                            // Should maybe prevent opening menu outside preview mode in Admin
                                            e.stopPropagation()

                                            this.setState({
                                                openItems: {
                                                    ...this.state.openItems,
                                                    [item.data._id]: !isOpen
                                                }
                                            })
                                        }}
                                    >
                                        {/* expand icon */}
                                        <span
                                            style={{
                                                fontSize: 15,
                                                paddingLeft: generalPadding / 2,
                                                paddingRight: generalPadding / 2
                                            }}
                                        >
                                            <i
                                                className={
                                                    isOpen
                                                        ? 'icon-minus3'
                                                        : 'icon-plus3'
                                                }
                                            />
                                        </span>
                                    </MenuButton>
                                </div>
                            )
                        }}
                        ItemComponent={props => {
                            const { children } = props

                            return children
                        }}
                        ItemGroupComponent={props => {
                            const { isRoot } = props

                            // nested submenu
                            if (!isRoot) {
                                return (
                                    <div
                                        className="svift-nested-stacked-submenu"
                                        style={{
                                            ...containerStyle,
                                            position: 'relative',
                                            width: '100%',
                                            paddingTop: 3,
                                            paddingLeft: 10,
                                            paddingRight: 0,
                                            paddingBottom: 15,
                                            marginTop: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                            marginBottom: 10,
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {/* nested menu */}
                                        <MenuContainer
                                            vertical
                                            style={{ flex: 1 }}
                                        >
                                            {props.children}
                                        </MenuContainer>
                                    </div>
                                )
                            }

                            return props.children
                        }}
                    />
                </MenuContainer>
            </div>
        )
    }

    renderClose = ({ style, containerStyle }) => {
        const theme = this.props.theme
        const generalPadding = theme.measurements.padding

        return (
            <div
                style={{
                    position: 'sticky',
                    zIndex: 99,
                    flex: 1,
                    alignSelf: 'flex-end',
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: theme.colors.tint,
                    boxShadow: `0px -1px ${generalPadding}px 0px ${theme.colors.shade}`,
                    border: 'none',
                    borderRadius: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    marginTop: 'auto',
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 0
                }}
            >
                <MenuContainer
                    style={{
                        ...containerStyle,
                        margin: 0,
                        padding: 10,
                        borderRadius: 0
                    }}
                >
                    <MenuButton
                        onClick={() => {
                            this.setState({
                                open: false
                            })
                        }}
                        style={{
                            ...style,
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: generalPadding / 2
                        }}
                    >
                        <i className="icon-cross2" style={{ fontSize: 30 }} />
                    </MenuButton>
                </MenuContainer>
            </div>
        )
    }

    render() {
        const theme = this.props.theme;

        const style = {
            ...this.props.computedStyling
        }

        const containerStyle = {
            ...(this.props.computedStyling &&
                this.props.computedStyling.container)
        }

        if (!this.state.open) {
            return this.renderOpenButton({ style, containerStyle })
        }

        return (
            <Portal>
                <div
                    style={{
                        padding: 0,
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.colors.tint // fallback underlying cover - shields off site content underneath
                    }}
                >
                    {this.renderMenuButtons({ style, containerStyle })}

                    {this.renderClose({ style, containerStyle })}
                </div>
            </Portal>
        )
    }
}

export default withRouter(Hamburger)