import React, { PureComponent } from 'react';
import { Button, Icon } from 'svift-ui';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
// import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import { StandaloneSlider } from 'components/option-panel/options/Slider';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Dropdown from 'components/option-panel/options/Dropdown';

class BorderSubOption extends PureComponent {
	constructor(props){
		super(props);

		this.tooltip = tooltip.call(this);

		this.state = {
			hover: false,
			selectedOption: null
		};


		this.onChange = this.onChange.bind(this);
		this.selectOption = this.selectOption.bind(this);
	}

	onChange(key, newValue) {
		const { value } = this.props;

		const newBorder = {
			...value,
			[key]: newValue
		};

		if (key === 'width') {
			if (typeof newBorder.color === 'undefined') {
				newBorder.color = {
					key: 'shade'
				};
			}
		}

		this.props.onChange(newBorder);
	}

	componentWillReceiveProps(nextProps) {
		// Resets the open tooltips whenever this component gets selected or unselected
		if (this.props.selectedOption !== nextProps.selectedOption) {
			this.selectOption(null);
		}
	}

	selectOption(option) {
		this.setState({
			selectedOption: option
		});
	}

	render() {
		const { title, icon, option, onToggle, selectedOption, value, intl } = this.props;

		const selected = option === selectedOption;

		const data = value || {};

		const { style, color, width } = data;

		const optionalDisabled = Object.keys(data).length === 0;

		return (
			<div className="option" style={{ display: 'inline-block' }} >
				<Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					position="top"
					content={
						<div>
							<p className="option-title">{title}</p>

							<Icon
								link
								disabled={optionalDisabled}
								name="sn-cross"
								className="primary"
								style={{ fontSize: 12, marginTop: 4, float: 'right' }}
								onClick={() => this.props.onChange(null)}
							/>

							<StandaloneSlider
								title={`${intl.formatMessage({ id: 'options.border width title' })}${typeof width !== 'undefined' ? `: ${width} px` : ''}`}
								onChange={newValue => {
									this.onChange('width', newValue);
								}}

								value={width || 1}
								min={1}
								max={50}

								selectedOption={this.state.selectedOption}
								onToggle={(option, e) => {
									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();

									this.selectOption(option);
								}}
								option="borderWidth"
							/>

							<Dropdown
								//uncontrolled
								//icon="sn-typography"
								options={[
									{
										text: `${intl.formatMessage({ id: 'options.border style solid title' })}`,
										value: 'solid'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style dashed title' })}`,
										value: 'dashed'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style dotted title' })}`,
										value: 'dotted'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style double title' })}`,
										value: 'double'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style groove title' })}`,
										value: 'groove'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style ridge title' })}`,
										value: 'ridge'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style inset title' })}`,
										value: 'inset'
									},
									{
										text: `${intl.formatMessage({ id: 'options.border style outset title' })}`,
										value: 'outset'
									}
								]}
								onChange={(newStyle) => this.onChange('style', newStyle.value)}
								value={style || 'solid'}

								hoverContent={intl.formatMessage({ id: `options.border style ${style} title` })}

								selectedOption={this.state.selectedOption}
								onToggle={(select, e) => {
									this.selectOption(select);

									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
								}}
								option="style"
							/>

							<ColorPicker
								title={intl.formatMessage({ id: 'options.border color title' })}
								key="color-picker"
								icons={['tint']}
								colors={this.props.colors}
								onChange={(newColor) => this.onChange('color', newColor)}
								value={color || { key: 'shade' }}

								selectedOption={this.state.selectedOption}
								onToggle={(option, e) => {
									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();

									this.selectOption(option);
								}}
								style={{ marginRight: 8 }}
								option="borderColor"
							/>

						</div>
					}
					hover={this.state.hover}
					hoverContent={title}
					style={{ zIndex: 999 }}
				/>

				<Button
					ref={this.tooltip.setTarget}
					basic
					size="small"
					icon={icon}
					primary={!optionalDisabled}
					className={optionalDisabled ? '' : 'active-button'}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				/>
			</div>
		);
	}
}

BorderSubOption.propTypes = {
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func
};

export default injectIntl(BorderSubOption);