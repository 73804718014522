import createRenderBlocks from '../createRenderBlocks';

export default createRenderBlocks({ 
    elements: [
        'Text',
        'Image',
        'Video',
        'Button',
        'Gallery',
        'Divider',
        'Map',
        'Form',
        'Feed',
        'IFrame'
        // 'Follow',
        // 'Share',
        // 'Icon'
    ], 
    sectionTemplates: [
        'OneColumnText',
        'TwoColumnText',
        'ThreeColumnText',
        'FourColumnText',
        
        'StandardHero',
        'BackgroundImageHero',
        
        'LeftInfobox',
        'RightInfobox',
        'BroadInfobox',

        'LeftImage',
        'RightImage',
        'ThreeRoundFeatures',
        'Banner',
        'ImageFrame',
        
        'VideoHero',
        'ThreeVideos',

        'StandardContactForm',
        'ContactFormWithMap',
        'ContactInfo',

        'BlogList'
    ]
});
