import React, { Component } from 'react';
import { Message, Icon } from 'svift-ui';
import SectionMenu from 'components/sections/Menu';
import { FormattedMessage, injectIntl } from 'react-intl';
import Header from 'containers/dashboard/side-navigation/sidenavigation-content/__components/Header';

class SidenavigationSettings extends Component {
	render() {
		return (
			<div>
				{/* <div id="sidenav-header-section">
					<Header
						icon="sn-cog3"
						header={
							<span className="active-sidebar-header">
								<FormattedMessage id="settings.app title" />
							</span>
						}
					/>
				</div> */}

				<SectionMenu />
			</div>
		);
	}
}

export default injectIntl(SidenavigationSettings);