import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import onClickOutside, {
    clickedOnTooltip
} from 'components/hoc/onClickOutside';
import { Grid, GridRow, GridColumn, IconGroup, Icon, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

// Replacing later anyway
const TempInput = ({ title, value, onChange }) => {
    return (
        <div style={{ display: 'inline-block', marginLeft: 6 }}>
            {title}

            <input
                type="number"
                // className="input"
                style={{ maxWidth: 80, marginLeft: 4 }}
                onChange={e => onChange(e.target.value)}
                value={value}
            />
        </div>
    );
};

class RowPresets extends PureComponent {
    constructor(props) {
        super(props);

        this.getPresetOptions = style => [
            <span style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-columns" />
                <FormattedMessage
                    id={createTranslationFromKey(this.props.presetTitle)}
                />
            </span>,
            <ColorPicker
                title={this.props.intl.formatMessage({
                    id: 'options.background color title'
                })}
                key="color-picker-background"
                icons={['sn-paint-format']}
                colors={this.props.theme.colors}
                onChange={newColor =>
                    this.props.updatePresetOption(
                        this.props.presetTitle,
                        'backgroundColor',
                        newColor
                    )
                }
                value={this.props.preset.backgroundColor}
                option="color-picker-background"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Padding
                key="padding"
                value={this.props.preset.padding}
                preset={this.props.preset}
                theme={this.props.theme}
                onChange={(key, newValue) => {
                    if (key === 'generalPadding') {
                        if (newValue === null) {
                            this.props.updatePresetOption(
                                this.props.presetTitle,
                                'padding',
                                {
                                    paddingBottom: 1
                                }
                            );
                        } else {
                            this.props.updatePresetOption(
                                this.props.presetTitle,
                                'padding',
                                {
                                    generalPadding: newValue / 100
                                }
                            );
                        }

                        return;
                    }

                    const newPadding = {
                        ...this.props.preset.padding,
                        [key]: newValue / 100
                    };

                    if (newValue === null) {
                        delete newPadding[key];
                    }

                    delete newPadding.generalPadding;

                    if (typeof newPadding.paddingBottom === 'undefined') {
                        newPadding.paddingBottom = 1;
                    }

                    this.props.updatePresetOption(
                        this.props.presetTitle,
                        'padding',
                        newPadding
                    );
                }}
                option="Padding"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOption(select);
                    e.stopPropagation();
                }}
                optIn={{
                    paddingBottom: false,
                    paddingTop: true,
                    paddingLeft: true,
                    paddingRight: true,
                    generalPadding: true
                }}
            />,
            <Shadow
                key="shadow"
                value={this.props.preset.boxShadow || {}}
                colors={this.props.theme.colors}
                onChange={newShadow => {
                    this.props.updatePresetOption(
                        this.props.presetTitle,
                        'boxShadow',
                        newShadow
                    ); //onChange('boxShadow', newShadow);
                }}
                option="Shadow"
                selectedOption={this.props.selectedPresetOption}
                onToggle={(select, e) => {
                    this.props.selectPresetOption(select);

                    e.stopPropagation();
                }}
            />,
            <TempInput
                key="columnSpacing"
                title={`${this.props.intl.formatMessage({
                    id: 'options.space between columns label'
                })} (%)`}
                value={
                    this.props.preset.columnSpacing === 0
                        ? undefined
                        : Math.floor(this.props.preset.columnSpacing * 100)
                }
                onChange={val => {
                    let nextVal = val;

                    if (val === '' || val === 'undefined') {
                        nextVal = 0;
                    }

                    this.props.updatePresetOption(
                        this.props.presetTitle,
                        'columnSpacing',
                        parseInt(nextVal) / 100
                    );
                }}
            />,
            <TempInput
                key="maxWidth"
                style={{ marginLeft: 8 }}
                title={`${this.props.intl.formatMessage({
                    id: 'options.maximum width'
                })} (px)`}
                value={
                    this.props.preset.maxWidth === 0
                        ? undefined
                        : this.props.preset.maxWidth
                }
                onChange={val => {
                    let nextVal = val;

                    if (val === '' || val === 'undefined') {
                        nextVal = 0;
                    }

                    this.props.updatePresetOption(
                        this.props.presetTitle,
                        'maxWidth',
                        parseInt(nextVal)
                    );
                }}
            />
        ];

        this.optionTooltipRef = React.createRef(); //tooltip.call(this);
    }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

    render() {
        const { intl } = this.props;
        const selected = this.props.presetTitle === this.props.selectedPreset;

        const computedStyles = computePreset(
            this.props.preset,
            this.props.theme
        );

        return (
            <div
                style={{
                    paddingBottom: 16,
                    marginBottom: 24,
                    borderBottom: '1px dashed lightgrey'
                }}
            >
                <OptionPanel
                    icon="sn-bucket"
                    title="elements.row title"
                    target={this.optionTooltipRef.current}
                    open={selected}
                    options={this.getPresetOptions(computedStyles)}
                />
                <PresetContainer
                    selected={selected}
                    onClick={() =>
                        setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)
                    }
                    ref={this.optionTooltipRef}
                >
                    <Grid padded centered columns={3}>
                        <GridRow className="sub-target" style={computedStyles}>
                            <Popup
                                trigger={
                                    <IconGroup
                                        style={{
                                            position: 'absolute',
                                            top: 32,
                                            left: 12,
                                            zIndex: 2
                                        }}
                                    >
                                        <Icon
                                            name="sn-columns"
                                            className="primary"
                                            style={{ fontSize: 40 }}
                                        />
                                        <Icon
                                            corner
                                            name="sn-info"
                                            className="primary"
                                            style={{
                                                fontSize: 24,
                                                bottom: -6,
                                                right: -6,
                                                borderRadius: '50%',
                                                background: 'white'
                                            }}
                                        />
                                    </IconGroup>
                                }
                                content={intl.formatMessage({
                                    id: 'elements.row dummy content'
                                })}
                                position="bottom left"
                            />
                            {[...Array(3)].map((x, i) => (
                                <GridColumn
                                    textAlign="center"
                                    style={{
                                        height: 160,
                                        border: '1px dashed grey'
                                    }}
                                >
                                    <Popup
                                        trigger={
                                            <IconGroup style={{ opacity: 0.1 }}>
                                                <Icon
                                                    name="sn-marquee"
                                                    style={{
                                                        fontSize: 56,
                                                        margin: 0
                                                    }}
                                                />
                                                <Icon
                                                    corner
                                                    name="sn-info"
                                                    style={{
                                                        fontSize: 24,
                                                        bottom: -8,
                                                        right: -8,
                                                        borderRadius: '50%',
                                                        background: 'white'
                                                    }}
                                                />
                                            </IconGroup>
                                        }
                                        content={intl.formatMessage({
                                            id:
                                                'elements.row dummy column description'
                                        })}
                                        position="bottom center"
                                    />
                                </GridColumn>
                            ))}
                        </GridRow>
                    </Grid>
                </PresetContainer>
            </div>
        );
    }
}

RowPresets.propTypes = {
    component: PropTypes.string,
    presetTitle: PropTypes.string,
    preset: PropTypes.object,
    theme: PropTypes.object,
    style: PropTypes.object,
    selectPreset: PropTypes.func,
    selectedPreset: PropTypes.string,
    selectPresetOption: PropTypes.func,
    selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(RowPresets));
