import React, { useMemo, useEffect } from 'react';
import { DragSource, DragPreviewImage } from 'react-dnd';
// import { getEmptyImage } from 'react-dnd-html5-backend';
import DropZoneCursorManager from './drop-zone/DropZoneCursorManager';
import addStyleToHead, { clearStyles } from 'utils/addStyleToHead';
import DropZoneVisualizer from './drop-zone/DropZoneVisualizer';
import getDragImage from 'components/editor-v2/dragImageDictionary';

const dndScrollCase = `
	.target-zone-scroll { display: flex; flex-direction: column; }
`;
let styleIds = [];
const addTargetZoneClasses = targets => {
    clearStyles(styleIds);

    const targetIds = Object.keys(targets);
    
    let css = targetIds.reduce((acc, key, index) => {
        return `${acc} .${key}${index !== targetIds.length - 1 ? ',' : ''}`;
    }, '');

    css += '{ display: block; }';
    css += dndScrollCase;

	styleIds.push(addStyleToHead(css));
};

/*
    Known error (no effect I think?) when drag and dropping text

    https://github.com/facebook/draft-js/pull/1603
*/
export default dndInterface => {
    const DraggableHOC = (Component, isHandle) => {
        const getType = props => dndInterface[props.node.component] ? props.node.component : 'ColumnElement';

        const spec = {
            beginDrag: (props, monitor) => {
                props.dragState.beginDrag();

                const { targets } = dndInterface[props.node.component] || dndInterface['ColumnElement'];

                setTimeout(() => addTargetZoneClasses(targets), 0);

                return {
                    source: props.node.component,
                    sourceType: dndInterface[props.node.component] ? props.node.component : 'ColumnElement',
                    getParentPath: props.getPath,
                    id: props.nodeID
                };
            },
            canDrag: (props) => {
                if (props.isEditable) return false;

                if (window.__UNDRAGGABLE_DICTIONARY && window.__UNDRAGGABLE_DICTIONARY[props.node.id]) return false;

                return !props.dragState.isDragging();
            },
            endDrag: (props, monitor) => {
                clearStyles(styleIds);

                const { targets } = dndInterface[props.node.component] || dndInterface['ColumnElement'];

                // console.log('end drag', monitor.getItem(), targets)

                props.dragState.endDrag(monitor.getItem(), targets);
            }
        };
    
        const collect = (connect, monitor) => {
            if (isHandle) {
                return {
                    connectDragSourceHandle: connect.dragSource(),
                    connectDragPreviewHandle: connect.dragPreview(),
                    isDragging: monitor.isDragging()
                };
            }

            return {
                connectDragSource: connect.dragSource(),
                connectDragPreview: connect.dragPreview(),
                isDragging: monitor.isDragging()
            };
        };

        const DraggableComponent = props => {
            const { node } = props;

            const renderDropZones = useMemo(() => {
                const type = node.component || 'ColumnElement';
                const { cursorZones } = dndInterface[node.component] || dndInterface['ColumnElement'];

                return () => (
                    <React.Fragment>
                        {cursorZones.map((zone, i) => {
                            return (
                                <DropZoneVisualizer
                                    key={i}
                                    {...zone}
                                    uuid={zone.id + node.id}
                                    dragState={props.dragState}
                                    type={type}
                                />
                            )
                        })}
                    </React.Fragment>
                );
            }, []);

            React.useLayoutEffect(() => {
                const { connectDragPreview, connectDragPreviewHandle } = props;

                if (connectDragPreviewHandle) {
                    const img = document.createElement('img');
                    img.onload = () => connectDragPreviewHandle(img)
                    img.src = getDragImage(node.component) //'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg'
                    // Use empty image as a drag preview so browsers don't draw it
                    // and we can draw whatever we want on the custom drag layer instead.
                    // connectDragPreviewHandle(getEmptyImage(), {
                    // // IE fallback: specify that we'd rather screenshot the node
                    // // when it already knows it's being dragged so we can hide it with CSS.
                    //     // captureDraggingState: true,
                    // })
                }

                if (connectDragPreview) {
                    const img = document.createElement('img');
                    img.onload = () => connectDragPreview(img)
                    img.src = getDragImage(node.component) //'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg'
                    // Use empty image as a drag preview so browsers don't draw it
                    // and we can draw whatever we want on the custom drag layer instead.
                    // connectDragPreview(getEmptyImage(), {
                    // // IE fallback: specify that we'd rather screenshot the node
                    // // when it already knows it's being dragged so we can hide it with CSS.
                    //     // captureDraggingState: true,
                    // })
                }
            }, []);

            // const { renderDropZones } = dndInterface[node.component] || dndInterface['ColumnElement'];

            // if (isHandle) {
            //     return (
            //         <Component 
            //             {...props}
            //         />
            //     );
            // }

            return (
                <React.Fragment>
                    {/* {props.connectDragPreviewHandle &&
                        <DragPreviewImage 
                            connect={props.connectDragPreviewHandle} 
                            src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg" 
                        />
                    }

                    {props.connectDragPreview &&
                        <DragPreviewImage 
                            connect={props.connectDragPreview} 
                            src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg" 
                        />
                    } */}

                    <DropZoneCursorManager 
                        {...props}
                        dndInterface={dndInterface}
                    >
                        <Component 
                            {...props} 
                            dndInterface={dndInterface} 
                            renderDropZones={renderDropZones} 
                        />
                        

                    </DropZoneCursorManager>
                </React.Fragment>
            );
        }

        if (isHandle) {
            return DragSource(getType, spec, collect)(DraggableComponent);
        }

        return DraggableHOC(DragSource(getType, spec, collect)(DraggableComponent), true);
    }

    DraggableHOC.exceptions = ['Container', 'Body', 'LayoutContainer'];

    return DraggableHOC;
};