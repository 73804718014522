const resolveFlagIconName = countryCode => {
    const lowercaseCode = countryCode.toLowerCase();

    switch (lowercaseCode) {
        case 'da': {
            return 'dk';
        }
        case 'en': {
            return 'gb';
        }
        case 'de': {
            return 'de';
        }
        case 'is': {
            return 'is';
        }
        case 'no': {
            return 'no';
        }
        case 'se': {
            return 'se';
        }
        case 'fi': {
            return 'fi';
        }
        case 'nl': {
            return 'nl';
        }
        case 'fr': {
            return 'fr';
        }
        case 'es': {
            return 'es';
        }
        case 'it': {
            return 'it';
        }
        case 'gl': {
            return 'gl';
        }
        case 'fo': {
            return 'fo';
        }
        case 'us': {
            return 'us';
        }
        case 'jp': {
            return 'jp';
        }
        case 'au': {
            return 'au';
        }
        case 'nz': {
            return 'nz';
        }
        case 'th': {
            return 'th';
        }
        case 'th': {
            return 'th';
        }
        case 'pt': {
            return 'pt';
        }
        
        // no match fallback
        default: {
            return null;
        }
    }
};

export default resolveFlagIconName;