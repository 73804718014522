import React from 'react';
import { connect } from 'react-redux';
import { createNotificationAction } from 'svift-library/client/notifications'

const NotificationHOC = Component => {
    const mapDispatchToProps = dispatch => {
        return {
            createNotification: notification => dispatch(createNotificationAction(notification))
        };
    };    

    const clazz = class Notification extends React.Component {   
        render() {
            return (
                <Component 
                    {...this.props}
                    createNotification={this.props.createNotification} 
                />
            );
        }
    };

    return connect(null, mapDispatchToProps)(clazz);
};

module.exports = NotificationHOC;