import { createSelector } from 'reselect';

const selectFormIds = (state, siteid) => state.sites.sites[siteid].forms;
const selectForms = state => state.sites.forms.forms;
const selectFormContent = state => state.sites.forms.formContent;

const formsSelector = createSelector(
	selectFormIds,
	selectForms,
	selectFormContent,
	(formIds, forms, formContent) => {
		return formIds.map(formId => {
			const form = {
				...forms[formId],
				content: formContent[forms[formId].content]
			};

			return form;
		});
	}
);

export { formsSelector };