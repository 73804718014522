import React, { PureComponent } from 'react';
import { Dimmer, Loader, Icon } from 'svift-ui';
import Tree from 'components/tree/Tree';
import FolderComponentWrapper from './FolderComponentWrapper';
import SegmentGroupWrapper from './SegmentGroupWrapper';
import SegmentWrapper from './SegmentWrapper';
import notification from 'components/hoc/notification';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import fileActions from 'store/actions/sites/files';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;

	return {
		siteid,
		activeFolder: state.sites.files.activeFolder,
		hoveredFolder: state.sites.files.hoveredFolder,
		folders: state.sites.files.folders,
		foldersLoading: state.sites.files.foldersLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setHoveredFolder: id => dispatch(fileActions.setHoveredFolder(id)),
		setActiveFolder: id => dispatch(fileActions.setActiveFolder(id)),
		moveFolder: (folderId, parentId, siteid) => dispatch(fileActions.moveFolder(folderId, parentId, siteid)),
		moveFile: (siteID, fileID, newFolderID, oldFolderID) => dispatch(fileActions.moveFile(siteID, fileID, newFolderID, oldFolderID))
	};
};

class FoldersContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			folders: this.props.folders.slice().sort((a, b) => a.name > b.name)
			// loadingFolders: true,
		};

		this.sortFile = this.sortFile.bind(this);
		this.sort = this.sort.bind(this);
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.folders !== this.props.folders) {
			this.setState({
				folders: nextProps.folders.slice().sort((a, b) => a.name > b.name)
			});
		}
	}

	formatFolders(folders = [], parent = null) {
		const relevantFolders = folders.filter(folder => {
			if (parent === null && !folder.parent) return true; // Failsafe in case parent is falsy but not exactly equal to null (gives leeway in backend)

			return folder.parent === parent;
		});

		return relevantFolders.map(folder => {
			return {
				text: folder.name,
				data: folder,
				items: this.formatFolders(folders, folder._id)
			};
		});
	}

	sortFile(target) {
		this.props.setHoveredFolder(target.item.data._id);
	}

	sort(source, target, area) {
		// If we are dragging a file we have to use a separate handler
		if (source.fileID) return this.sortFile(target);
		
		const {
			item: sourceItem,
			path: sourcePath,
			index: sourceIndex
		} = source;
		const {
			item: targetItem,
			path: targetPath,
			index: targetIndex
		} = target;	

		if (sourceItem.data._id === targetItem.data._id) return;
		if (!sourceItem.data.parent) return; // Dragging root should not do anything

		const newFolders = this.startingPoint.concat({
			...sourceItem.data,
			parent: targetItem.data._id
		});

		this.props.setHoveredFolder(targetItem.data._id)

		this.setState({
			folders: newFolders.slice().sort((a, b) => a.name > b.name),
			// hoveredFolder: targetItem.data._id
		});
	}	

	render() {
		if (this.props.foldersLoading) {
			return (
				<Dimmer active inverted>
					<Icon name="sn-folder6" className="primary" style={{ fontSize: 64, margin: '0 0 8px 0', opacity: 0.2 }} />
					<Loader size="big" style={{ opacity: 0.5 }} />
				</Dimmer>
			);
		}

		return (
			<div id="folder-tree">
				<Tree
					items={this.formatFolders(this.state.folders)}
					TextComponent={FolderComponentWrapper}
					ItemGroupComponent={SegmentGroupWrapper}
					ItemComponent={SegmentWrapper}

					context={{
						hovered: this.props.hoveredFolder,
						active: this.props.activeFolder,
						setActive: this.props.setActiveFolder
					}}

					noPath
					dnd
					targetZones={[
						{	
							area: 'any',
							y: '0-100',
							x: '0-100',
							color: 'pink',
							didEnter: this.sort,
							didLeave: (source, target) => {
								if (!target.item.data.parent) {
									this.props.setHoveredFolder(null);
								}
							},
							// READY FOR HOOK UP TO BACKEND
							didDrop: async droppedItem => {
								// console.log(droppedItem, 'dropped Item', this.props.hoveredFolder, 'hovered folder');
								if (droppedItem.fileID) {
									try {
										await this.props.moveFile(this.props.siteid, droppedItem.fileID, this.props.hoveredFolder, this.props.activeFolder);
									} catch(e) {
										console.log(e, 'err in didDrop');
									}
								}
							},
							zIndex: 1
						}
					]}

					onDragBeginCallback={({ item }) => {						
						this.startingPoint = this.state.folders.filter(folder => folder._id !== item.data._id);
						this.foldersAtDragTime = this.state.folders;
					}}
					onDragEndCallback={async (dropWasHandled, item) => {
						this.props.setHoveredFolder(null);

						if (!dropWasHandled) {
							this.setState({
								folders: this.props.folders.slice().sort((a, b) => a.name > b.name)
							});
						
							return;
						}

						const newFolder = this.state.folders.find(folder => folder._id === item.data._id);
						
						try {
							await this.props.moveFolder(item.data._id, newFolder.parent, this.props.siteid);

							const childFolderName = this.state.folders.filter(folder => folder._id === item.data._id)[0].name;
							const parentFolderName = this.state.folders.filter(folder => folder._id === newFolder.parent)[0].name;

							this.props.createNotification({
								content: `${this.props.intl.formatMessage({ id: 'files.folder title' })}: "${childFolderName}" ${this.props.intl.formatMessage({ id: 'general.was moved to label' })} "${parentFolderName==='' ? this.props.intl.formatMessage({ id: 'files.root folder title' }) : parentFolderName}" `,
								className: 'info',
								position: 'topLeft',
								icon: 'setting',
								deleteTime: 4000
							});
						} catch (e) {
							console.log(e, 'e');

							alert('error');
						}
					}}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(FoldersContainer)));