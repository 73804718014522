import React from 'react';
import { Icon, Button } from 'svift-ui';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';

export default injectIntl(props => {
    // const isPreviewActive = !!props.value;
    const openPreviewPopup = useTooltip();
    
    return (
        <React.Fragment>
            <Popup
                target={openPreviewPopup.current}
                position="bottom center"
                zIndex={997}
            >
                {props.popup || <FormattedMessage id="general.open preview popup" />}
            </Popup>
            <Button
                icon
                size="mini"
                id="preview-switch"
                className="info"
                ref={openPreviewPopup}
                style={{ padding: '6px 8px', ...props.style }}
                onClick={() => {
                    const currentMode = props.value;
                    const nextMode = currentMode === null ? 'draft' : null;
                    props.onChange(nextMode);
                }}
            >
                <span style={{ marginRight: 6 }}>
                    <Icon name="sn-eye" style={{ fontSize: 16, height: 16, verticalAlign: 'middle' }} />
                </span>
                <Icon
                    name="sn-enlarge2"
                    style={{ opacity: 0.6, fontSize: 12, height: 12, verticalAlign: 'middle' }}
                />
            </Button>
        </React.Fragment>
    );
});
