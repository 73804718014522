import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import ActivationWizard from 'components/activate-site/ActivateSiteWizard';

const mapDispatchToProps = dispatch => ({

});

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		siteid,
		failedActivation: site.failedActivation,
		domains: site.domains
	};
};

@connect(mapStateToProps, mapDispatchToProps)
export default class ActivationContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		// Don't need anything fancy here just a failsafe if someone manually navigates to the url
		if (this.props.failedActivation || (this.props.domains && this.props.domains.length > 0)) return 'Site already activated';

		return (
			<AppContentSection style={{ paddingTop: 0, marginTop: -98 }}>
				<ActivationWizard />
			</AppContentSection>
		);
	}
}
