/*
    Extracts and translates ValidationErrors and ClientErrors thrown from Core

    throws if neither is found
*/
export default intl => error => {
    if (Array.isArray(error)) {
        return error.map(err => intl.formatMessage({ id: err.intl }, { ...err.context }));
    }
    
    if (error && error.intlReadable) {
        return [intl.formatMessage({ id: error.intlReadable })];
    }

    throw error;
};
