import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Input,
	Dropdown,
	Loader,
	Button,
	List,
	ListItem,
	Label,
	Segment,
	Icon,
	Grid,
	GridRow,
	GridColumn,
	Message,
	Popup,
	Header,
	Checkbox,
	Divider,
	Accordion,
	AccordionTitle
} from 'svift-ui'; //lib/export';
import { injectIntl, FormattedMessage } from 'react-intl';
import actions from 'store/actions/domainSearch';
import InfoIcon from 'components/InfoIcon';

const renderLabel = (label, index, props) => ({
	color: 'blue',
	content: `${label.text}`,
});

const mapStateToProps = (state, ownProps) => {
	const isFreeSubscription = !ownProps.tldEnabled;

	return {
		siteid: state.sites.params.siteid,
		subdomain: state.domainsearch.subdomain,
		pickedDomain: state.domainsearch.pickedDomain,
		availableTlds: state.system.domains.domainPlans,
		checkedDomains: isFreeSubscription ? state.domainsearch.checkedSubdomains : state.domainsearch.checkedDomains,
		agreeTransferTerms: state.domainsearch.agreeTransferTerms
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAvailableTlds: () => dispatch(actions.setAvailableTlds()),
		checkDomains: domainsObj => dispatch(actions.checkDomains(domainsObj)),
		setPickedDomain: pickedDomain => dispatch(actions.setPickedDomain(pickedDomain)),
		setAgreeTransferTerms: bool => dispatch(actions.setAgreeTransferTerms(bool))
	};
};

class DomainSearch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			domainText: '',
			checkedTlds: [],
			tldsToCheck: [],
			formattedTlds: this.formatTlds(props.availableTlds),
			showUnoeuroDetails: false,
			showMailDetails: false,
			showBackupDetails: false,
			showDnsDetails: false
		};

		this.onTextChange = this.onTextChange.bind(this);
		this.onDropdownChange = this.onDropdownChange.bind(this);
		this.onDomainSearch = this.onDomainSearch.bind(this);
	}

	formatTlds(tlds) {
		return tlds.map(({ name }) => {
			const formattedTld = name.split(' ')[0].toLowerCase(); // '.DK domain' => '.dk'

			return {
				key: name,
				text: formattedTld,
				value: formattedTld
			};
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.checkedDomains !== this.props.checkDomains) {
			this.setState({
				loading: false
			});
		}

		/*
			Takes a while to loop over 4xx domains on every render (especially when typing in the search field), so we hold the formatted Tlds in state and update them only when needed
		*/
		if (nextProps.availableTlds !== this.props.availableTlds) {
			this.setState({
				formattedTlds: this.formatTlds(nextProps.availableTlds)
			});
		}
	}

	onDomainSearch() {
		if (this.state.loading) return;

		const domains = this.state.tldsToCheck.map(tld => `${this.state.domainText}${tld}`);
		const subdomain = this.state.domainText + this.props.subdomain;

		this.setState({
			loading: true
		});

		this.props.checkDomains({ domains, subdomain, siteID: this.props.siteid });
	}

	onTextChange(e) {
		this.setState({
			domainText: e.target.value,
		});
	}

	onDropdownChange(proxy, descriptor) {
		const { value } = descriptor;

		this.setState({
			tldsToCheck: value,
		});
	}

	renderResultRow(domain) {
		const { name, available, status } = domain;

		const isValidSearch = status !== 'error';

		let availableText = available ? 'available' : 'taken';
		let availableClass = available ? 'positive' : 'warning';
		let actionText = available ? 'select' : 'transfer';
		let actionDescription = available ? 'available' : 'taken'; // used to generate description for info icon popup
		let actionIcon = available ? 'sn-plus2' : 'sn-arrow-right8';

		if (!isValidSearch) {
			availableText = 'invalid';
			availableClass = 'error';
			actionText = false;
		}

		const tld = name.substring(name.lastIndexOf('.'));
		const plan = this.props.availableTlds.find(({ name: planName }) => planName.indexOf(tld.toUpperCase()) === 0);
		const price = (plan.amount / 100) * 1.25;

		return (
			<ListItem
				key={name}
				style={{ cursor: 'default', opacity: 1 }}
			>

				<span>
					<Icon circular name="plug" style={{ opacity: 0.6, marginRight: 8 }} className={availableClass} />
					{name}
					<Label basic circular style={{ marginLeft: 16 }} className={availableClass}>
						<FormattedMessage id={`activation.subscription domain search result ${availableText} label`} />
					</Label>
					<Label basic className="primary" style={{ marginLeft: 8 }}>
						{price.toLocaleString('dkk')} <FormattedMessage id="general.price per year" />
					</Label>
				</span>

				{actionText &&
					<div style={{ display: 'inline-block', float: 'right' }}>
						<InfoIcon
							description={this.props.intl.formatMessage({ id: `activation.subscription domain search result ${actionDescription} description` })}
							position="bottom right"
							style={{ verticalAlign: 'middle' }}
						/>
						<Button
							circular
							size="tiny"
							floated="right"
							className={availableClass}
							onClick={() => this.props.setPickedDomain(domain)}
							style={{ marginLeft: 0, verticalAlign: 'middle' }}
						>
							<Icon name={actionIcon} />
							<FormattedMessage id={`activation.subscription domain search result ${actionText}`} />
						</Button>
					</div>
				}

			</ListItem>
		);
	}

	renderResults() {
		return (
			<div>
				<Header as="h4" style={{ opacity: 0.6, marginTop: 16, marginBottom: 16 }}>
					<span>
						<Icon name="search" />
						<FormattedMessage id="activation.subscription domain search results" />
					</span>
				</Header>
				<List
					divided
					selection
					size="big"
					verticalAlign="middle"
					className="svift-list"
					style={{ textAlign: 'left', marginTop: 0, marginBottom: 40 }}
				>

					{this.props.checkedDomains.map((option) => {
						return this.renderResultRow(option);
					})}

				</List>
			</div>
		);
	}

	renderInputGroup() {
		const { tldEnabled, availableTlds, subdomain, intl } = this.props;

		return (
			<Grid centered padded verticalAlign="top" style={{ marginBottom: 8 }}>
				<GridRow style={{ border: 'solid 1px #ccc', backgroundColor: '#eee', boxShadow: 'inset 0 2px #ddd', borderRadius: 4 }}>
					<Icon name="sn-sphere" style={{ position: 'absolute', left: 24, top: 25, fontSize: 32, opacity: 0.2 }} />

					<GridColumn style={{ marginRight: -16 }} computer={3} textAlign="right">
						<p style={{ fontSize: 20, marginTop: 12, opacity: 0.4 }}><Icon name="lock" />https://</p>
					</GridColumn>

					<GridColumn computer={6}>
						<Input
							size="huge"
							fluid
							type="text"
							placeholder={intl.formatMessage({ id: 'activation.subscription domain insert domain name' })}
							focus={false}
							onChange={this.onTextChange}
						/>
					</GridColumn>

					<GridColumn computer={5} style={{ marginLeft: -16 }}>
						{tldEnabled &&
							<Dropdown
								search
								multiple
								selection
								placeholder={intl.formatMessage({ id: 'activation.subscription domain domain types' })}
								//mapBy="text"
								options={this.state.formattedTlds}
								icon={false}
								renderLabel={renderLabel}
								onChange={this.onDropdownChange}
								style={{
									fontSize: 16,
									padding: 9,
									width: '100%'
								}}
							/>
						}

						{!tldEnabled &&
							<p>{subdomain}</p>
						}
					</GridColumn>

					<GridColumn computer={2}>
						<Popup
							trigger={
								<Button
									size="huge"
									positive
									icon="search"
									fluid
									onClick={this.onDomainSearch}
								/>
							}
							content={intl.formatMessage({ id: 'activation.subscription search domains' })}
							position="top right"
						/>
					</GridColumn>
				</GridRow>
			</Grid>
		);
	}

	renderPickedDomain() {
		const { pickedDomain, intl } = this.props;

		const segmentClass = pickedDomain.available ? 'positive' : 'warning';
		const orderType = pickedDomain.available ? 'ordered' : 'transfered';
		const selectedAction = pickedDomain.available ? 'order' : 'transfer';

		const name = pickedDomain.name;

		const tld = name.substring(name.lastIndexOf('.'));
		const plan = this.props.availableTlds.find(({ name: planName }) => planName.indexOf(tld.toUpperCase()) === 0);
		const price = (plan.amount / 100) * 1.25;

		return (
			<div>
				{/* Selected domain name */}
				<Segment
					size="massive"
					className={segmentClass}
					style={{ paddingTop: 40, paddingBottom: 40, marginBottom: 16 }}
				>
					<Grid>
						<GridRow verticalAlign="middle">
							<GridColumn computer={7} tablet={6} mobile={16} textAlign="left">
								<Icon name="plug" style={{ marginRight: 12 }} />
								{pickedDomain.name}
								<Label
									circular
									basic
									size="big"
									className={segmentClass}
									style={{ marginLeft: 16 }}
								>
									<FormattedMessage id={`activation.subscription domain to be ${orderType}`} />
								</Label>
							</GridColumn>
							<GridColumn computer={9} tablet={10} mobile={16} textAlign="right">
								<Label
									basic
									size="big"
									className="primary"
									style={{ marginLeft: 8 }}
								>
									{price.toLocaleString('dkk')} <FormattedMessage id="general.price per year" />
								</Label>
								{/* Clear selected domain name */}
								<Popup
									trigger={
										<Icon
											link
											circular
											name="sn-cross3"
											onClick={() => this.props.setPickedDomain({})}
											style={{ marginLeft: 24 }}
										/>
									}
									content={intl.formatMessage({ id: 'activation.subscription domain reset popup' })}
									position="top right"
								/>
							</GridColumn>
						</GridRow>
					</Grid>
				</Segment>

				{/* transfers existing domain warning message */}
				{orderType === 'transfered' &&
					<Message
						className="warning"
						style={{
							fontSize: 18,
							marginTop: 8,
							textAlign: 'center'
						}}
					>
						<Header as="h4" style={{ marginTop: 0, marginBottom: 8 }}>
							<FormattedMessage id="activation.transfer domain warning message title" />
						</Header>
						<p style={{ marginBottom: 24 }}>
							<span style={{ fontWeight: 'bold' }}><FormattedMessage id="general.obs label" /></span> <FormattedMessage id="activation.transfer domain warning message description" />
						</p>
						<Grid centered textAlign="left" style={{ marginBottom: 8 }}>
							<GridRow>
								<GridColumn computer={5} tablet={8} mobile={16} style={{ paddingLeft: 4, paddingRight: 4 }}>
									{/* Unoeuro customer warning */}
									<Message style={{ padding: '24px 16px 32px 16px' }}>
										<div style={{ textAlign: 'left', fontSize: 32, paddingBottom: 16, verticalAlign: 'middle' }}>
											<span style={{ marginRight: 8, fontWeight: 'bold', verticalAlign: 'middle' }}>1.</span>
											<Icon name="sn-earth" style={{ opacity: 0.4, margin: 0, verticalAlign: 'middle' }} />
										</div>
										<span style={{ fontWeight: 'bold' }}>
											<FormattedMessage id="activation.transfer domain warning message unoeuro title" /> <a href="https://unoeuro.com" target="_blank" rel="noopener">Unoeuro.com</a>?</span><br />
										<Divider />
										<Accordion
											onClick={() => {
												this.setState({
													showUnoeuroDetails: !this.state.showUnoeuroDetails
												});
											}}
										>
											<AccordionTitle style={{ textAlign: 'left', verticalAlign: 'middle', padding: 0 }}>
												<Icon
													name={this.state.showUnoeuroDetails ? 'sn-minus-circle2' : 'sn-plus-circle'}
													className="primary"
													style={{ fontSize: 18, marginRight: 8, verticalAlign: 'middle' }}
												/>
												<span style={{ fontSize: 14, verticalAlign: 'middle' }}>
													{this.state.showDnsDetails ? 'Vis detaljer' : 'skjul detaljer'}
												</span>
											</AccordionTitle>
										</Accordion>
										{this.state.showUnoeuroDetails &&
											<React.Fragment>
												<div style={{ fontSize: 14, marginBottom: 8 }}>
													<FormattedMessage id="activation.transfer domain warning message unoeuro description" />
												</div>
												<div style={{ fontWeight: 'bold', fontSize: 14 }}>
													<FormattedMessage id="activation.transfer domain warning message unoeuro options title" />
												</div>
												<ol style={{ fontSize: 14 }}>
													<li>
														<FormattedMessage id="activation.transfer domain warning message unoeuro option 1" />
													</li>
													<li>
														<FormattedMessage id="activation.transfer domain warning message unoeuro option 2" />
													</li>
												</ol>
											</React.Fragment>
										}
									</Message>
								</GridColumn>
								<GridColumn computer={5} tablet={8} mobile={16} style={{ paddingLeft: 4, paddingRight: 4 }}>
									{/* Mail addresses with existing domain warning */}
									<Message style={{ padding: '24px 16px 32px 16px' }}>
										<div style={{ textAlign: 'left', fontSize: 32, paddingBottom: 16, verticalAlign: 'middle' }}>
											<span style={{ marginRight: 8, fontWeight: 'bold', verticalAlign: 'middle' }}>2.</span>
											<Icon name="sn-at-sign" style={{ opacity: 0.4, margin: 0, verticalAlign: 'middle' }} />
										</div>
										<span style={{ fontWeight: 'bold' }}>
											<FormattedMessage id="activation.transfer domain warning message emails title" />
										</span><br />
										<Divider />
										<Accordion
											onClick={() => {
												this.setState({
													showMailDetails: !this.state.showMailDetails
												});
											}}
										>
											<AccordionTitle style={{ textAlign: 'left', verticalAlign: 'middle', padding: 0 }}>
												<Icon
													name={this.state.showMailDetails ? 'sn-minus-circle2' : 'sn-plus-circle'}
													className="primary"
													style={{ fontSize: 18, marginRight: 8, verticalAlign: 'middle' }}
												/>
												<span style={{ fontSize: 14, verticalAlign: 'middle' }}>
													{this.state.showDnsDetails ? 'Vis detaljer' : 'skjul detaljer'}
												</span>
											</AccordionTitle>
										</Accordion>
										{this.state.showMailDetails &&
											<React.Fragment>
												<div style={{ fontSize: 14, marginBottom: 8 }}>
													<FormattedMessage id="activation.subscription message warning mail existing domain description" />
												</div>
												<div style={{ fontSize: 14 }}>
													<FormattedMessage id="activation.subscription message warning mail existing domain suggestions" />
												</div>
												<ol style={{ fontSize: 14 }}>
													<li>
														<a href="https://gsuite.google.com/" target="_blank" title="Google G Suite">
															<FormattedMessage id="general.google g suite" />
														</a>
													</li>
													<li>
														<a href="https://microsoft.com/microsoft-365" target="_blank" title="Microsoft Office 365">
															<FormattedMessage id="general.microsoft office 365" />
														</a>
													</li>
												</ol>
											</React.Fragment>
										}
									</Message>
								</GridColumn>
								<GridColumn computer={5} tablet={8} mobile={16} style={{ paddingLeft: 4, paddingRight: 4 }}>
									{/* backup of existing domain warning */}
									<Message style={{ padding: '24px 16px 32px 16px' }}>
										<div style={{ textAlign: 'left', fontSize: 32, paddingBottom: 16, verticalAlign: 'middle' }}>
											<span style={{ marginRight: 8, fontWeight: 'bold', verticalAlign: 'middle' }}>3.</span>
											<Icon name="sn-database-export" style={{ opacity: 0.4, margin: 0, verticalAlign: 'middle' }} />
										</div>
										<span style={{ fontWeight: 'bold' }}>
											<FormattedMessage id="activation.transfer domain warning message backup title" />
										</span><br />
										<Divider />
										<Accordion
											onClick={() => {
												this.setState({
													showBackupDetails: !this.state.showBackupDetails
												});
											}}
										>
											<AccordionTitle style={{ textAlign: 'left', verticalAlign: 'middle', padding: 0 }}>
												<Icon
													name={this.state.showBackupDetails ? 'sn-minus-circle2' : 'sn-plus-circle'}
													className="primary"
													style={{ fontSize: 18, marginRight: 8, verticalAlign: 'middle' }}
												/>
												<span style={{ fontSize: 14, verticalAlign: 'middle' }}>
													{this.state.showDnsDetails ? 'Vis detaljer' : 'skjul detaljer'}
												</span>
											</AccordionTitle>
										</Accordion>
										{this.state.showBackupDetails &&
											<React.Fragment>
												<ol style={{ fontSize: 14 }}>
													<li>
														<FormattedMessage id="activation.transfer domain warning message backup todo 1" />
													</li>
													<li>
														<FormattedMessage id="activation.transfer domain warning message backup todo 2" />
													</li>
													<li>
														<FormattedMessage id="activation.transfer domain warning message backup todo 3" />
													</li>
												</ol>
												<div style={{ fontSize: 14 }}>
													<span style={{ fontWeight: 'bold' }}><FormattedMessage id="general.obs label" /></span> <FormattedMessage id="activation.transfer domain warning message backup cancellation disclaimer" />
												</div>
											</React.Fragment>
										}
									</Message>
								</GridColumn>
							</GridRow>
						</Grid>
						
						<div style={{ backgroundColor: 'white', padding: 16, borderRadius: 4 }}>
							{/* ready to continue transfer of domain */}
							<Header as="h5">
								<FormattedMessage id="activation.transfer domain warning message confirmation title" />
							</Header>
							
							{/* agree to transfer terms */}
							<Checkbox
								label={intl.formatMessage({ id: 'activation.transfer domain warning message confirmation checkbox label' })}
								value={this.props.agreeTransferTerms}
								onChange={() => this.props.setAgreeTransferTerms(!this.props.agreeTransferTerms)}
							/>
						</div>
					</Message>
				}
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return (
				<div style={{ paddingTop: 24, paddingBottom: 32, border: 'solid 1px #ccc', backgroundColor: '#eee', boxShadow: 'inset 0 2px #ddd', borderRadius: 4 }}>
					<Loader size="huge" style={{ fontSize: 32 }}>
						<Header as="h3">
							<span>
								<Icon name="plug" style={{ fontSize: 24, opacity: 0.6 }} /> <FormattedMessage id="activation.subscription domain loading search results" />
							</span>
						</Header>
					</Loader>
				</div>
			);
		}

		if (Object.keys(this.props.pickedDomain).length !== 0) {
			return this.renderPickedDomain();
		}

		return (
			<div>

				{this.renderInputGroup()}

				{this.props.checkedDomains.length > 0 && this.renderResults()}

			</div>
		);
	}
}

DomainSearch.propTypes = {
	tldEnabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DomainSearch));
