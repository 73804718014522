import React, { PureComponent } from 'react';
import Site from './sections/site/Site';
import SettingsHeader from './SettingsHeader';
import Sections from 'components/sections/Sections';
import Section from 'components/sections/Section';
import SubscriptionSection from './sections/subscription/Subscription';
import StatisticsSection from './sections/statistics/Statistics';
import UsersSection from './sections/users/Users';
import MemberSection from './sections/membership/Membership';
import DnsSection from './sections/dns/Dns';
import Modules from './sections/modules/Modules';
import { injectIntl } from 'react-intl';
import { Message } from 'svift-ui'

import { connect } from 'react-redux';

const mapStateToProps = state => {
	return {
		roles: state.user.roles,
		siteid: state.sites.params.siteid
	};
};

class SettingsContainer extends PureComponent {
	state = {
		users: null
	};

	setUsers = users => {
		this.setState({ users });
	}

	render() {
		const {
			intl,
			roles,
			siteid
		} = this.props;

		const rolesOnSite = roles.reduce((acc, { type, scope }) => {
			const scopeSiteMatch = scope === `sites/${siteid}`;

			if (scopeSiteMatch) {
				acc[type] = true;
			}

			return acc;
		}, {});

		return (
			<React.Fragment>
				<SettingsHeader />

				<Sections key={this.props.siteid}>
					{!(rolesOnSite.siteOwner || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'settings.membership section title' })}
							icon="user"
							anchor="membership"
						>
							<MemberSection />
						</Section>
					}

					{(rolesOnSite.siteOwner || rolesOnSite.siteAdmin || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'settings.site section title' })}
							icon="sn-sphere"
							infoIcon={intl.formatMessage({ id: 'settings.site settings description' })}
							anchor="site"
						>
							<Site />
						</Section>
					}

					{(rolesOnSite.siteOwner || rolesOnSite.siteAdmin || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'settings.users section title' })}
							icon="sn-user5"
							anchor="users"
						>
							<UsersSection
								users={this.state.users}
								setUsers={this.setUsers}
							/>
						</Section>
					}

					{(rolesOnSite.siteOwner) &&
						<Section
							name={intl.formatMessage({ id: 'settings.subscription section title' })}
							icon="sn-subscription-base"
							anchor="subscription"
							// infoIcon={intl.formatMessage({ id: 'settings.subscription section description' })}
						>
							<SubscriptionSection 
								users={this.state.users}
							/>
						</Section>
					}

					{(rolesOnSite.siteOwner || rolesOnSite.siteAdmin || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'modules.title' })}
							icon="sn-subscription-general"
							anchor="modules"
							infoIcon={intl.formatMessage({ id: 'modules.description' })}
						>
							<Modules />
						</Section>
					}

					{(rolesOnSite.siteOwner || rolesOnSite.siteAdmin || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'settings.google section title' })}
							icon="google"
							anchor="statistics"
						>
							<StatisticsSection />
						</Section>
					}

					{(rolesOnSite.siteOwner || rolesOnSite.siteAdmin || rolesOnSite.superadmin) &&
						<Section
							name={intl.formatMessage({ id: 'settings.domain section title' })}
							icon="plug"
							anchor="domain"
						>							
							<DnsSection />
						</Section>
					}

				</Sections>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(SettingsContainer));
