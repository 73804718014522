import React, { PureComponent } from 'react';
import { Button, Icon, Message } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import { connect } from 'react-redux';
import PageForm from 'components/forms/page';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import Modal from 'svift-library/client/modal';
import makeKeywordsSelector  from 'store/selectors/sites/keywords';
import SviftGA from 'utils/svift-ga';
const keywordsSelector = makeKeywordsSelector('page');
// import Header from 'containers/dashboard/side-navigation/sidenavigation-content/__components/Header';
// import notification from 'components/hoc/notification';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const pageid = state.sites.pages.params.pageid;

	return {
		user: state.user,
		site: state.sites.sites[siteid],
		pageid,
		siteid,
		// keywords: keywordsSelector(state, siteid),
		// stagedContent: state.sites.content.stagedContent
	};
};

// const mapDispatchToProps = (dispatch) => ({
// 	// updatePageContent: (pageid, tree) => dispatch(actions.updatePageContent(pageid, tree)),
// 	createPage: (site, page) => dispatch(actions.createPage(site, page))
// });

class SideNavPages extends PureComponent {
    // constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		showCreatePageModal: false,
	// 	};

	// 	this.createPage = this.createPage.bind(this);
	// }

    // toggleModal = () => {
    //     this.setState({
	// 		showCreatePageModal: !this.state.showCreatePageModal
	// 	});
    // }

	// async createPage(data) {
	// 	const { createPage, siteid } = this.props;

	// 	try {
	// 		await createPage(siteid, data);

	// 		SviftGA.PAGES.CREATED();

	// 		this.setState({ showCreatePageModal: false });
	// 	} catch (e) {
	// 		console.log(e, "error");
	// 		handleSubmitError(this.props.intl)(e);
	// 	}
	// }
    
	render() {
        // console.log(this.state.showCreatePageModal, 'modal bool')

		return (
			<React.Fragment>
                {/* <Button
					id="pages-new-page-button"
					icon
					positive
					fluid
					size="small"
					onClick={this.toggleModal}
					style={{ marginBottom: 24 }}
				>
					<Icon name="plus" /> <FormattedMessage id="pages.create page action" />
				</Button> */}

				<PortalHook id="editor-sidenav-portal" />

				<PortalHook id="building-blocks-sidenav-portal" />
				
				<div>
					{/* <div id="sidenav-header-section">
						<Header
							icon="sn-cog3"
							header={
								<span className="active-sidebar-header">
									<FormattedMessage id="settings.app title" />
								</span>
							}
						/>
					</div> */}

                    {/* {this.state.showCreatePageModal &&
                        <Modal
                            title={this.props.intl.formatMessage({ id: 'pages.create page' })}
                            width={1400}
                            toggleModal={this.toggleModal}
                            nodeAttributes={{
                                'data-ignore-click': true
                            }}
                        >
                            <PageForm
                                keywords={this.props.keywords}
                                onClose={this.toggleModal}
                                onSubmit={this.createPage}
                            />
                        </Modal>
                    } */}
				</div>
			</React.Fragment>
		);
	}
}

export default SideNavPages;

// export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SideNavPages));