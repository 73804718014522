import React from 'react';
import Label from 'components/option-library/label';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    const text = props.intl.formatMessage({ id: 'options.font weight title' });

    return (
        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>
            <Label
                collapsed
                labelIcon="sn-arrow-resize3"
                label={text}
                fontSize={12}
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <Dropdown
                    wings
                    items={Array(9).fill().map((_, i) => {
                        const fontWeight = (i + 1) * 100;
                        
                        return {
                            text: fontWeight,
                            value: fontWeight
                        };
                    })}
                    value={props.inlineStyling}
                    onChange={props.onChange} //newValue => props.onChange('font', newValue)}
                />
            </Label>
        </div>
    );
});
