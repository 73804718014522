import React, { useMemo } from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import NumberPicker from 'components/option-library/primitives/input/number';
import Color from 'components/editor-v2/editor-interface/options/common/color/ColorPalette';
import { colorResolver } from 'svift-library/client/renderer/utils/computePreset';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import key from 'utils/key';
import css from '../../../../options.scss';

const deleteEmptyObjects = (inlineStyling) => {
    Object.keys(inlineStyling).forEach(borderDirection => {
        if (Object.keys(inlineStyling[borderDirection]).length === 0) delete inlineStyling[borderDirection];
    });

    if (Object.keys(inlineStyling).length === 0) return undefined;

    return inlineStyling;
}

const isValueTypeClearable = (inlineStyling, valueType /* width|type|color */) => {
    if (typeof inlineStyling !== 'undefined') {
        // checks every border direction for any values of the given valueType; may seem weird but we have to adhere to legacy data requirements
        return Object.keys(inlineStyling).some(borderDirection => typeof inlineStyling[borderDirection][valueType] !== 'undefined');
    }

    return false;
};

const clearValueType = (inlineStyling, valueType /* width|type|color */) => {
    Object.keys(inlineStyling).forEach(borderDirection => delete inlineStyling[borderDirection][valueType]);

    return deleteEmptyObjects(inlineStyling);
};

const clearKeyFromExplicitDirections = (nextBorder, key) => {
    if (nextBorder.borderLeft) delete nextBorder.borderLeft[key];
    if (nextBorder.borderTop) delete nextBorder.borderTop[key];
    if (nextBorder.borderRight) delete nextBorder.borderRight[key];
    if (nextBorder.borderBottom) delete nextBorder.borderBottom[key];
}

const Border = injectIntl(props => {
    const { accordionState, intl } = props;
    const [state, dispatch] = accordionState;

    // const onChange = (direction, key, value, action, shouldSuspend) => {
    //     console.log("calling onchange")
    //     const nextBorder = {
    //         ...props.inlineStyling
    //     };

    //     if (!nextBorder[direction]) nextBorder[direction] = {};

    //     nextBorder[direction] = {
    //         ...nextBorder[direction],
    //         [key]: value
    //     };

    //     if (typeof value === 'undefined') delete nextBorder[direction][key];

    //     props.onChange(deleteEmptyObjects(nextBorder), action, shouldSuspend);
    // };

    const onChange = (direction, key, value, action, autoHistory = true) => {
        const nextBorder = {
            ...props.inlineStyling
        };

        if (!nextBorder[direction]) nextBorder[direction] = {};

        nextBorder[direction] = {
            ...nextBorder[direction],
            [key]: value
        };

        if (direction === 'borderGeneral') {
            clearKeyFromExplicitDirections(nextBorder, key); 
        } else if (nextBorder.borderGeneral) {
            delete nextBorder.borderGeneral[key];
        }

        // if (key === '')

        // let nextRadius = {
        //     ...props.inlineStyling,
        //     [key]: value
        // };

        // if (key === 'generalBorderRadius') {
        //     nextRadius = {
        //         [key]: value
        //     };
        // } else {
        //     delete nextRadius.generalBorderRadius;
        // }

        if (typeof value === 'undefined') delete nextBorder[direction][key];

        props.onChange(deleteEmptyObjects(nextBorder), action, autoHistory);
    };

    const borderTypes = useMemo(() => {
        return [
            {
                text: `${intl.formatMessage({ id: 'options.border style solid title' })}`,
                value: 'solid'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style dashed title' })}`,
                value: 'dashed'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style dotted title' })}`,
                value: 'dotted'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style double title' })}`,
                value: 'double'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style groove title' })}`,
                value: 'groove'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style ridge title' })}`,
                value: 'ridge'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style inset title' })}`,
                value: 'inset'
            },
            {
                text: `${intl.formatMessage({ id: 'options.border style outset title' })}`,
                value: 'outset'
            }
        ];
    }, [])

    // Can't deduce which color the general color was by looking at the computed styling object because general color is set explicitly on each side
    // Therefore we compute here separately so we can show the color preview
    const computedGeneralColor = colorResolver(props.theme, key('inlineStyling.borderGeneral.color')(props) || {});

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                nested
                icon="sn-border_outer"
                title={props.intl.formatMessage({ id: 'options.border title' })} 
                clear={{
                    enabled: typeof props.inlineStyling !== 'undefined',
                    onClear: () => props.onChange() // ???
                }}
            >
                <Accordion state={state} dispatch={dispatch}>
                    <Section 
                        nested
                        icon="sn-rulers"
                        title={props.intl.formatMessage({ id: 'options.border width title' })}
                        clear={{
                            enabled: isValueTypeClearable(props.inlineStyling, 'width'),
                            onClear: () => props.onChange(clearValueType(props.inlineStyling, 'width', 'Set width', false))
                        }}
                    >
                        {/* general border width */}
                        <Label
                            labelIcon="sn-border_outer"
                            label={props.intl.formatMessage({ id: 'options.border general title' })}
                            clear={{
                                onClear: () => onChange('borderGeneral', 'width', undefined, 'general border width clear'),
                                enabled: typeof key('inlineStyling.borderGeneral.width')(props) !== 'undefined'
                            }}
                        >
                            <NumberPicker
                                disableUndoRedo
                                // onBlur={props.suspend.flush} 
                                name={props.intl.formatMessage({ id: 'options.border general title' })}
                                unit="px"
                                min={0}
                                max={50}
                                tooltipPosition="right center"
                                value={key('inlineStyling.borderGeneral.width')(props)}
                                onChange={val => onChange('borderGeneral', 'width', val, 'border width', true)}					
                            />
                        </Label>

                        <div className={css['filter-outline']} style={{ display: 'block' }}>
                            {/* top border option */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_top"
                                        label={props.intl.formatMessage({ id: 'general.top' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderTop', 'width', undefined, 'border top', false),
                                            enabled: typeof key('inlineStyling.borderTop.width')(props) !== 'undefined'
                                        }}
                                    >
                                        <NumberPicker
                                            disableUndoRedo
                                            // onBlur={props.suspend.flush} 
                                            name={props.intl.formatMessage({ id: 'options.border top title' })}
                                            unit="px"
                                            min={0}
                                            max={50}
                                            tooltipPosition="right center"
                                            value={key('inlineStyling.borderTop.width')(props)}
                                            onChange={val => onChange('borderTop', 'width', val, 'border width', true)}						
                                        />
                                    </Label>
                                </div>
                            </div>

                            {/* left / right border options */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', paddingRight: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_left"
                                        label={props.intl.formatMessage({ id: 'general.left' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderLeft', 'width', undefined, 'border top', false),
                                            enabled: typeof key('inlineStyling.borderLeft.width')(props) !== 'undefined'
                                        }}
                                    >
                                        <NumberPicker
                                            disableUndoRedo
                                            // onBlur={props.suspend.flush} 
                                            name={props.intl.formatMessage({ id: 'options.border left title' })}
                                            unit="px"
                                            min={0}
                                            max={50}
                                            tooltipPosition="right center"
                                            value={key('inlineStyling.borderLeft.width')(props)}
                                            onChange={val => onChange('borderLeft', 'width', val, 'border width', true)}						
                                        />
                                    </Label>
                                </div>
                                <div style={{ display: 'inline-flex', width: '50%', paddingLeft: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_right"
                                        label={props.intl.formatMessage({ id: 'general.right' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderRight', 'width', undefined, 'border right', false),
                                            enabled: typeof key('inlineStyling.borderRight.width')(props) !== 'undefined'
                                        }}
                                    >
                                        <NumberPicker
                                            disableUndoRedo
                                            // onBlur={props.suspend.flush} 
                                            name={props.intl.formatMessage({ id: 'options.border right title' })}
                                            unit="px"
                                            min={0}
                                            max={50}
                                            tooltipPosition="right center"
                                            value={key('inlineStyling.borderRight.width')(props)}
                                            onChange={val => onChange('borderRight', 'width', val, 'border width', true)}						
                                        />
                                    </Label>
                                </div>
                            </div>

                            {/* bottom border option */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_bottom"
                                        label={props.intl.formatMessage({ id: 'general.bottom' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderBottom', 'width', undefined, 'border bottom', false),
                                            enabled: typeof key('inlineStyling.borderBottom.width')(props) !== 'undefined'
                                        }}
                                    >
                                        <NumberPicker
                                            disableUndoRedo
                                            // onBlur={props.suspend.flush} 
                                            name={props.intl.formatMessage({ id: 'options.border bottom title' })}
                                            unit="px"
                                            min={0}
                                            max={50}
                                            tooltipPosition="right center"
                                            value={key('inlineStyling.borderBottom.width')(props)}
                                            onChange={val => onChange('borderBottom', 'width', val, 'border width', true)}						
                                        />
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </Section>
            
                    <Section 
                        nested
                        icon="sn-border_clear"
                        title={intl.formatMessage({ id: 'options.border type title' })}
                        clear={{
                            enabled: isValueTypeClearable(props.inlineStyling, 'style'),
                            onClear: () => props.onChange(clearValueType(props.inlineStyling, 'style', undefined, 'border clear', false))
                        }}                        
                    >
                        {/* general border type */}
                        <Label
                            labelIcon="sn-border_outer"
                            label={props.intl.formatMessage({ id: 'options.border general title' })}
                            clear={{
                                onClear: () => onChange('borderGeneral', 'style', undefined, 'border clear', false),
                                enabled: typeof key('inlineStyling.borderGeneral.style')(props) !== 'undefined'
                            }}
                        >
                            <Dropdown
                                wings
                                items={borderTypes}
                                value={key('inlineStyling.borderGeneral.style')(props)}
                                onChange={val => onChange('borderGeneral', 'style', val, 'border clear', false)}
                            />
                        </Label>

                        <div className={css['filter-outline']} style={{ display: 'block' }}>
                            {/* top border option */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_top"
                                        label={props.intl.formatMessage({ id: 'general.top' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderTop', 'style', undefined, 'border style clear', false),
                                            enabled: typeof key('inlineStyling.borderTop.style')(props) !== 'undefined'
                                        }}
                                    >
                                        <Dropdown
                                            wings
                                            collapsed
                                            items={borderTypes}
                                            value={key('inlineStyling.borderTop.style')(props)}
                                            onChange={val => onChange('borderTop', 'style', val, 'border style')}
                                        />
                                    </Label>
                                </div>
                            </div>

                            {/* left / right border options */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', paddingRight: 12, marginLeft: 'auto', marginRight: 'auto' }}>
                                <Label
                                    collapsed
                                    labelIcon="sn-border_left"
                                    label={props.intl.formatMessage({ id: 'general.left' })}
                                    fontSize={12}
                                    clear={{
                                        onClear: () => onChange('borderLeft', 'style', undefined, 'border style clear', false),
                                        enabled: typeof key('inlineStyling.borderLeft.style')(props) !== 'undefined'
                                    }}
                                >
                                    <Dropdown
                                        wings
                                        collapsed
                                        items={borderTypes}
                                        value={key('inlineStyling.borderLeft.style')(props)}
                                        onChange={val => onChange('borderLeft', 'style', val, 'border style')}
                                    />
                                </Label>
                                </div>
                                <div style={{ display: 'inline-flex', width: '50%', paddingLeft: 12, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_right"
                                        label={props.intl.formatMessage({ id: 'general.right' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderRight', 'style', undefined, 'border style clear', false),
                                            enabled: typeof key('inlineStyling.borderRight.style')(props) !== 'undefined'
                                        }}
                                    >
                                        <Dropdown
                                            wings
                                            collapsed
                                            items={borderTypes}
                                            value={key('inlineStyling.borderRight.style')(props)}
                                            onChange={val => onChange('borderRight', 'style', val, 'border style')}
                                        />
                                    </Label>
                                </div>
                            </div>

                            {/* bottom border option */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Label
                                        collapsed
                                        labelIcon="sn-border_bottom"
                                        label={props.intl.formatMessage({ id: 'general.bottom' })}
                                        fontSize={12}
                                        clear={{
                                            onClear: () => onChange('borderBottom', 'style', undefined, 'border style clear', false),
                                            enabled: typeof key('inlineStyling.borderBottom.style')(props) !== 'undefined'
                                        }}
                                    >
                                        <Dropdown
                                            wings
                                            collapsed
                                            items={borderTypes}
                                            value={key('inlineStyling.borderBottom.style')(props)}
                                            onChange={val => onChange('borderBottom', 'style', val, 'border style')}
                                        />
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </Section>
                    
                    {/* border color options */}
                    <Section 
                        nested
                        icon="sn-eyedropper2"
                        title={intl.formatMessage({ id: 'options.border color title' })}
                        clear={{
                            enabled: isValueTypeClearable(props.inlineStyling, 'color'),
                            onClear: () => props.onChange(clearValueType(props.inlineStyling, 'color', undefined, 'clear border color', false))
                        }}                        
                    >
                        <Label
                            labelIcon="sn-border_outer"
                            label={props.intl.formatMessage({ id: 'options.border general title' })}
                            clear={{
                                onClear: () => onChange('borderGeneral', 'color', undefined, 'clear border color', false),
                                enabled: typeof key('inlineStyling.borderGeneral.color')(props) !== 'undefined'
                            }}
                        >
                            <Color
                                icon="sn-border_outer"
                                colors={props.theme.colors}
                                theme={props.theme}
                                inlineStyling={key('inlineStyling.borderGeneral.color')(props)}
                                presetStyling={key('presetStyling.borderGeneral.color')(props)}
                                // inlineStyling={key('inlineStyling.borderGeneral.color')(props)}
                                computedStyling={computedGeneralColor}
                                onChange={(...args) => onChange('borderGeneral', 'color', ...args)}
                                accordionState={accordionState}
                            />
                        </Label>

                        <Accordion state={state} dispatch={dispatch}>
                            <Section 
                                nested
                                icon="sn-border_left"
                                title={props.intl.formatMessage({ id: 'options.border left title' })}
                                clear={{
                                    onClear: () => onChange('borderLeft', 'color', undefined, 'clear border color', false),
                                    enabled: typeof key('inlineStyling.borderLeft.color')(props) !== 'undefined'
                                }}
                            >
                                <Color
                                    icon="sn-border_left"
                                    colors={props.theme.colors}
                                    theme={props.theme}
                                    inlineStyling={key('inlineStyling.borderLeft.color')(props)}
                                    presetStyling={key('presetStyling.borderLeft.color')(props)}
                                    // inlineStyling={key('inlineStyling.borderLeft.color')(props)}
                                    computedStyling={key('computedStyling.borderLeftColor')(props)}
                                    onChange={(...args) => onChange('borderLeft', 'color', ...args)}
                                    accordionState={accordionState}
                                />
                            </Section>

                            <Section 
                                nested
                                icon="sn-border_top"
                                title={props.intl.formatMessage({ id: 'options.border top title' })}
                                clear={{
                                    onClear: () => onChange('borderTop', 'color', undefined, 'clear border color', false),
                                    enabled: typeof key('inlineStyling.borderTop.color')(props) !== 'undefined'
                                }}
                            >
                                <Color
                                    icon="sn-border_top"
                                    colors={props.theme.colors}
                                    theme={props.theme}
                                    inlineStyling={key('inlineStyling.borderTop.color')(props)}
                                    presetStyling={key('presetStyling.borderTop.color')(props)}
                                    // inlineStyling={key('inlineStyling.borderTop.color')(props)}
                                    computedStyling={key('computedStyling.borderTopColor')(props)}
                                    onChange={(...args) => onChange('borderTop', 'color', ...args)}
                                    accordionState={accordionState}
                                />
                            </Section>

                            <Section 
                                nested
                                icon="sn-border_right"
                                title={props.intl.formatMessage({ id: 'options.border right title' })}
                                clear={{
                                    onClear: () => onChange('borderRight', 'color', undefined, 'clear border color', false),
                                    enabled: typeof key('inlineStyling.borderRight.color')(props) !== 'undefined'
                                }}
                            >
                                <Color
                                    icon="sn-border_right"
                                    colors={props.theme.colors}
                                    theme={props.theme}
                                    inlineStyling={key('inlineStyling.borderRight.color')(props)}
                                    presetStyling={key('presetStyling.borderRight.color')(props)}
                                    // inlineStyling={key('inlineStyling.borderRight.color')(props)}
                                    computedStyling={key('computedStyling.borderRightColor')(props)}
                                    onChange={(...args) => onChange('borderRight', 'color', ...args)}
                                    accordionState={accordionState}
                                />
                            </Section>

                            <Section 
                                nested
                                icon="sn-border_bottom"
                                title={props.intl.formatMessage({ id: 'options.border bottom title' })}
                                clear={{
                                    onClear: () => onChange('borderBottom', 'color', undefined, 'clear border color', false),
                                    enabled: typeof key('inlineStyling.borderBottom.color')(props) !== 'undefined'
                                }}
                            >
                                <Color
                                    icon="sn-border_bottom"
                                    colors={props.theme.colors}
                                    theme={props.theme}
                                    inlineStyling={key('inlineStyling.borderBottom.color')(props)}
                                    presetStyling={key('presetStyling.borderBottom.color')(props)}
                                    // inlineStyling={key('inlineStyling.borderBottom.color')(props)}
                                    computedStyling={key('computedStyling.borderBottomColor')(props)}
                                    onChange={(...args) => onChange('borderBottom', 'color', ...args)}
                                    accordionState={accordionState}
                                />
                            </Section>
                        </Accordion>           
                    </Section>
                </Accordion>
            </Section>
        </Accordion>
    );
});

export default Border;