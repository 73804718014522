import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    const text = props.intl.formatMessage({ id: 'options.border width title' });

    return (
        <Label
            collapsed
            labelIcon="sn-rulers"
            label={text}
            fontSize={12}
            clear={{
                onClear: () => props.onChange(),
                enabled: typeof props.inlineStyling !== 'undefined'
            }}
        >
            <NumberPicker 
                disableUndoRedo
                name={text}
                unit="px"
                min={1}
                max={25}
                tooltipPosition="right center"
                value={props.inlineStyling}
                onChange={newValue => props.onChange(newValue)}					
            />
        </Label>
    );
});