exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".row__row--S7cux{display:flex;flex-wrap:wrap}.row__collapsing-margin-fix--1joLn{clear:both;content:\"\";display:table;background:transparent;margin-top:-1px;height:0}\n", ""]);

// exports
exports.locals = {
	"row": "row__row--S7cux",
	"collapsing-margin-fix": "row__collapsing-margin-fix--1joLn"
};