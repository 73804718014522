import React, { PureComponent } from 'react';
import {
    GridColumn,
    Grid,
    GridRow,
    Popup,
    Button,
    Icon,
    Menu,
    Dropdown,
    FormCheckbox
} from 'svift-ui';
// import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tooltip from 'svift-library/client/tooltip';

class ShadowOption extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            selectedOption: null
        };

        // this.tooltip = tooltip.call(this);
        this.tooltipRef = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    onChange(key, newValue) {
        const { value } = this.props;

        const newShadow = {
            ...value,
            [key]: newValue
        };

        if (newValue === null) {
            delete newShadow[key];
        }

        this.props.onChange(newShadow);
    }

    componentWillReceiveProps(nextProps) {
        // Resets the open tooltips whenever this component gets selected or unselected
        if (this.props.selectedOption !== nextProps.selectedOption) {
            this.selectOption(null);
        }
    }

    selectOption(option) {
        this.setState({
            selectedOption: option
        });
    }

    render() {
        const {
            option,
            onToggle,
            selectedOption,
            value,
            colors,
            intl
        } = this.props;

        const selected = option === selectedOption; // Not to be confused with selectedOption in state

        const { yShadow, xShadow, blur, spread, color, inset } = value || {};

        return (
            <div className="option" style={{ display: 'inline-block' }}>
                {/* <Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					content={

					}
					hover={this.state.hover}
					hoverContent={intl.formatMessage({ id: 'options.shadow title' })}
					style={{ zIndex: 999 }}
				/> */}

                <Tooltip
                    open={selected}
                    target={this.tooltipRef.current}
                    popup
                    zIndex="999"
                >
                    <Grid
                        padded
                        columns={3}
                        centered
                        verticalAlign="middle"
                        className="manipulator mini-panel shadow-options"
                    >
                        {/* Panel row */}
                        <GridRow centered columns={2}>
                            <GridColumn textAlign="left">
                                <p className="option-title">
                                    <Icon name="sn-subtract" />{' '}
                                    <FormattedMessage id="options.shadow title" />
                                </p>
                            </GridColumn>
                            <GridColumn textAlign="right">
                                <Slider
                                    optIn={typeof blur !== 'undefined'}
                                    onClear={() => {
                                        if (typeof blur === 'undefined') return;

                                        this.onChange('blur', null);
                                    }}
                                    optionalDisabled={
                                        typeof blur === 'undefined'
                                    }
                                    activeButton={typeof blur !== 'undefined'}
                                    icons={['sn-spray']}
                                    title={`${intl.formatMessage({
                                        id: 'options.shadow blur title'
                                    })}${
                                        typeof blur === 'undefined'
                                            ? ''
                                            : `: ${Math.floor(blur)}px`
                                    }`}
                                    onChange={newValue => {
                                        this.onChange('blur', newValue);
                                    }}
                                    value={blur || 1}
                                    min={0}
                                    max={50}
                                    selectedOption={this.state.selectedOption}
                                    onToggle={(option, e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();

                                        this.selectOption(option);
                                    }}
                                    option="blur"
                                />
                            </GridColumn>
                        </GridRow>

                        {/* Panel row */}
                        <GridRow centered columns={2}>
                            <GridColumn textAlign="left">
                                <FormCheckbox
                                    label={intl.formatMessage({
                                        id: 'options.shadow inset title'
                                    })}
                                    checked={!!inset}
                                    onChange={() => {
                                        this.onChange('inset', !inset);
                                    }}
                                />
                            </GridColumn>
                            <GridColumn textAlign="right">
                                <Slider
                                    optIn={typeof xShadow !== 'undefined'}
                                    onClear={e => {
                                        if (typeof xShadow === 'undefined')
                                            return;

                                        this.onChange('xShadow', null);
                                    }}
                                    optionalDisabled={
                                        typeof xShadow === 'undefined'
                                    }
                                    activeButton={
                                        typeof xShadow !== 'undefined'
                                    }
                                    icons={['sn-arrow-resize3']}
                                    title={`${intl.formatMessage({
                                        id:
                                            'options.shadow horizontal position title'
                                    })}${
                                        typeof xShadow === 'undefined'
                                            ? ''
                                            : `: ${Math.floor(xShadow)}px`
                                    }`}
                                    onChange={newValue => {
                                        this.onChange('xShadow', newValue);
                                    }}
                                    value={xShadow || 1}
                                    min={-50}
                                    max={50}
                                    selectedOption={this.state.selectedOption}
                                    onToggle={(option, e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();

                                        this.selectOption(option);
                                    }}
                                    option="xShadow"
                                />
                            </GridColumn>
                        </GridRow>

                        {/* Panel row */}
                        <GridRow centered columns={3}>
                            <GridColumn textAlign="left">
                                <ColorPicker
                                    title={intl.formatMessage({
                                        id: 'options.shadow color title'
                                    })}
                                    key="color-picker"
                                    icons={['sn-subtract']}
                                    colors={colors}
                                    onChange={newValue => {
                                        this.onChange('color', newValue);
                                    }}
                                    value={color}
                                    option="color-picker"
                                    selectedOption={this.state.selectedOption}
                                    onToggle={(option, e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();

                                        this.selectOption(option);
                                    }}
                                />
                            </GridColumn>

                            <GridColumn textAlign="center">
                                <Slider
                                    optIn={typeof yShadow !== 'undefined'}
                                    onClear={() => {
                                        if (typeof yShadow === 'undefined')
                                            return;

                                        this.onChange('yShadow', null);
                                    }}
                                    optionalDisabled={
                                        typeof yShadow === 'undefined'
                                    }
                                    activeButton={
                                        typeof yShadow !== 'undefined'
                                    }
                                    icons={['sn-arrow-resize4']}
                                    title={`${intl.formatMessage({
                                        id:
                                            'options.shadow vertical position title'
                                    })}${
                                        typeof yShadow === 'undefined'
                                            ? ''
                                            : `: ${Math.floor(yShadow)}px`
                                    }`}
                                    onChange={newValue => {
                                        this.onChange('yShadow', newValue);
                                    }}
                                    value={yShadow || 1}
                                    min={-50}
                                    max={50}
                                    selectedOption={this.state.selectedOption}
                                    onToggle={(option, e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();

                                        this.selectOption(option);
                                    }}
                                    option="yShadow"
                                />
                            </GridColumn>

                            <GridColumn textAlign="right">
                                <Slider
                                    optIn={typeof spread !== 'undefined'}
                                    onClear={() => {
                                        if (typeof spread === 'undefined')
                                            return;

                                        this.onChange('spread', null);
                                    }}
                                    optionalDisabled={
                                        typeof spread === 'undefined'
                                    }
                                    activeButton={typeof spread !== 'undefined'}
                                    icons={['sn-arrow-resize2']}
                                    title={`${intl.formatMessage({
                                        id: 'options.shadow spread title'
                                    })}${
                                        typeof spread === 'undefined'
                                            ? ''
                                            : `: ${Math.floor(spread)}px`
                                    }`}
                                    onChange={newValue => {
                                        this.onChange('spread', newValue);
                                    }}
                                    value={spread || 1}
                                    min={0}
                                    max={50}
                                    selectedOption={this.state.selectedOption}
                                    onToggle={(option, e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();

                                        this.selectOption(option);
                                    }}
                                    option="spread"
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Tooltip>

                <Button
                    ref={this.tooltipRef} //.setTarget}
                    basic
                    size="small"
                    icon="sn-subtract"
                    className={`primary ${
                        value && Object.keys(value).length > 0
                            ? 'active-button'
                            : ''
                    }`}
                    onClick={e => onToggle(selected ? null : option, e)}
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                />
            </div>
        );
    }
}

ShadowOption.propTypes = {
    option: PropTypes.string,
    selectedOption: PropTypes.string,
    onToggle: PropTypes.func
};

export default injectIntl(ShadowOption);
