import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/StandardContactForm';

export default {
	name: 'templates.template standard contact form',
	metaDescription: 'contact + columns with form + info + address + opening hours',
	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/standard-contact-form-template.png',
	type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
};
