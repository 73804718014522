import React, { memo, useState } from 'react';
import FilePreview from '../file-preview';
import css from './selected-files.scss';
// import { connect } from 'react-redux';
// import { filesDictionarySelector } from 'store/selectors/sites/files';
import Label from 'components/option-library/label';
import { Button, Message, Icon, Divider } from 'svift-ui';
import { FormattedDate, injectIntl, FormattedMessage, intlShape } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import Sortable from 'components/sortable';

const NoFileSummary = injectIntl(props => {
    return (
        <div className={css['no-selection-title']}>
            <Icon name="sn-file-add" style={{ marginRight: 6 }} />
			<FormattedMessage id={`options.no ${!props.multiple ? 'file' : 'files'} selected`} />
			{/* <InfoIcon description={props.intl.formatMessage({ id: 'options.link file description' })} /> */}
        </div>
    );
});

const FileSummary = props => {
	// Only allow sortable inside the modal and when multiple mode is true
	if (props.multiple && !props.preview) {
		return (
			<Sortable
				items={props.selectedFiles}
				onChange={sortedSelectedFiles => props.setSelectedFiles(sortedSelectedFiles)}
				resolveItemID={item => item.id || item}
				renderItem={(dndProps, index) => {
					const { dragRef, isDragging, item } = dndProps;
					const file = item;
		
					return (
						<FilePreview 
							dragRef={dragRef}
							isDragging={isDragging}
							key={file.id || file} 
							file={file}
							multiple
							renderActions={!props.preview && (() => {
								return (
									<Icon 
										name="sn-cross" 
										onClick={() => {
											props.setSelectedFiles(props.selectedFiles.filter((_, i) => i !== index))
										}}
										title="Remove file" // transl8
										style={{ marginLeft: 'auto', marginRight: 8, fontSize: 12, cursor: 'pointer' }}
									/>
								);
							})}
						/>
					);
				}}
			/>
		);
	};

    return props.selectedFiles.map((file, index) => {
		return (
			<FilePreview 
				key={file.id || file} 
				file={file}
				multiple={props.multiple}
				renderActions={!props.preview && (() => {
					return (
						<Button
							fluid
							basic
							size="mini"
							onClick={() => {
								props.setSelectedFiles(props.selectedFiles.filter((_, i) => i !== index))
							}}
							style={{ marginBottom: 6 }}
						>
							<Icon name="sn-cross" style={{ marginRight: 6 }} />
							<FormattedMessage id="options.link file remove button" />
						</Button>
					);
				})}
			/>
		);
	});
};

const SelectedFiles = injectIntl(props => {
	const noSelectedFiles = props.selectedFiles.length === 0;

	return (
		<Label
			collapsed
			label={props.title || props.intl.formatMessage({ id: 'options.link file label' })} // TO DO: expose label + icon as props for FilePicker + transl8
			labelIcon="file outline"
			// labelDescription={props.intl.formatMessage({ id: 'options.link file description' })}
		>
			<Message
				size="small"
				className={props.selectedFiles ? 'info' : 'shade'}
				style={{ padding: 16, textAlign: 'center' }}
			>
				{!noSelectedFiles &&
					<FileSummary 
						preview={props.preview}
						multiple={props.multiple} // whether or not multiple files can be selected - we want a compact version for the multiple case
						selectedFiles={props.selectedFiles}
						setSelectedFiles={props.setSelectedFiles}
					/>
				}

				{/* <Divider style={{ marginTop: 0, marginBottom: 8 }} /> */}

				{props.preview 
					?
						// select/change file button
						<React.Fragment>
							<Button
								fluid
								size={noSelectedFiles ? 'small' : 'mini'}
								className={noSelectedFiles ? 'info' : 'warning'}
								onClick={() => props.setModalOpen(true)}
							>
								<Icon name={props.value ? 'file outline' : 'sn-folder6'} style={{ marginRight: 6 }} />

								<span>
									<FormattedMessage id={`options.${noSelectedFiles ? 'select' : 'change'} file button`} />
								</span>
							</Button>

							{/* TO DO: connect to Link options (display message if file selected + is part of link option) */}
							{/* {!noSelectedFiles && <Message className="info">Complete insertion by clicking "Add link below"</Message>} */}
						</React.Fragment>
					:
						// save selected file(s)
						<Button
							fluid
							size="small"
							className="positive"
							// disabled={noSelectedFiles}
							onClick={props.onChange}
						>
							<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
							<FormattedMessage id="general.save" />
						</Button>
				}
			</Message>
		</Label>
	);
});

export default SelectedFiles;