import React, { PureComponent } from 'react';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import { connect } from 'react-redux';
import actions from './redux/actions';

const mapStateToProps = state => {
	return {
		currentSection: state.sections.currentSection, // Used by menu
		selectSection: state.sections.selectSection // Called by menu
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setSections: ({sections, currentSection}) => dispatch(actions.setSections({sections, currentSection})),
		setCurrentSection: (section) => dispatch(actions.setCurrentSection(section))
	};
};

const isFullyScrolled = () => {
	// For some reason, html and body height doesn't extend with the page content, so we have to use the app root as the container
	const scrollContainer = document.getElementById('app-container');
	const { height: appHeight } = scrollContainer.getBoundingClientRect();

	if (document.body.offsetHeight + window.scrollY + 5 > appHeight) return true;

	return false;
};

class Sections extends PureComponent {
	constructor() {
		super();

		this.sectionRefs = [];
		this.onScroll = e => {
			const activeSection = this.findActiveSection();

			if (activeSection !== this.props.currentSection) {
				this.props.setCurrentSection(activeSection);
			}
		};
	}

	findActiveSection() {
		if (isFullyScrolled()) {
			const keys = Object.keys(this.sectionRefs);
			let max = 0;
			let match = null;

			// Finds the bottom-most section
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const top = this.sectionRefs[key].getTop();

				if (top > max) {
					max = top;
					match = key;
				}
			}

			return match;
		}

		// Finds the section with the smallest distance to the top of the browser
		const match = Object.keys(this.sectionRefs).reduce((candidateKey, key) => {
			if (candidateKey === null) return key;

			const newCandidate = this.sectionRefs[key];
			const deltaTop = Math.abs(newCandidate.getTop());

			const currentCandidate = this.sectionRefs[candidateKey];
			const currentDeltaTop = Math.abs(currentCandidate.getTop());

			if (deltaTop < currentDeltaTop) {
				return key;
			}

			return candidateKey;
		}, null);

		return match;
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll);

		const activeSection = this.findActiveSection();

		this.props.setSections({
			sections: this.props.children.filter(child => !!child).map(child => ({
				name: child.props.name,
				anchor: child.props.anchor,
				icon: child.props.icon
			})),
			currentSection: activeSection
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}

	componentDidUpdate(prevProps) {
		const { selectSection } = this.props;

		if (selectSection) {
			this.sectionRefs[selectSection].goToSection();
			setTimeout(() => this.props.setCurrentSection(selectSection)); // Delaying the selection so the scroll listener doesn't override the selection
		}
	}

	renderSections() {
		const { children } = this.props;
		this.sectionRefs = [];

		return React.Children.map(children, child => {
			if (!child) return null;

			return React.cloneElement(child, {
				ref: el => {
					this.sectionRefs[child.props.name] = el;
				}
			});
		});
	}

	render() {
		return (
			<AppContentSection
				ref={el => {
					this.container = el;
				}}
			>
				{this.renderSections()}
			</AppContentSection>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sections);