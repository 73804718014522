import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    let label = props.intl.formatMessage({
        id: 'options.space between columns label'
    });
    // let placeholder = props.intl.formatMessage({ id: 'options.space between columns label' });

    return (
        <Label
            labelIcon="sn-menu4"
            labelIconRotated="clockwise"
            label={label}
            fontSize={12}
            clear={{
                onClear: () => props.onChange(),
                enabled: typeof props.inlineStyling !== 'undefined'
            }}
        >
            <NumberPicker
                disableUndoRedo
                name={label}
                // icon="sn-menu4"
                unit="%"
                min={0}
                max={200}
                tooltipPosition="right center"
                // placeholder={Math.floor(defaultValue * 100)}
                value={Math.round(props.inlineStyling * 100)}
                onChange={newValue => props.onChange(typeof newValue !== 'undefined' ? newValue / 100 : undefined)}	
            />
        </Label>
    );
});
