import React, { useState, useMemo, useRef } from 'react';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { Icon, Message, Loader, Label } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import config from 'config';
import uuid from 'uuid/v4';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import useRendererData from 'svift-library/client/renderer/data/client/useRendererData';
import useRendererState from 'svift-library/client/renderer/useRendererState';
import PreviewContainer from 'components/editor-v2/editor-interface/preview/PreviewContainer';
import ToggleDraftAndLivePreview from 'components/editor-v2/editor-interface/preview/ToggleDraftAndLivePreview';
import OpenPreview from 'components/editor-v2/editor-interface/preview/OpenPreview';
import createExtensions from 'components/editor-v2/extend';
import { connect } from 'react-redux';
import SviftGA from 'utils/svift-ga';
import pageActions from 'store/actions/sites/pages';
import SelectPage from './SelectPage';
// import EditPage from './EditPage';
// import PageForm from './PageForm';
import PageSettings from './PageSettings';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import useDimensions from 'svift-library/client/renderer/useDimensions';
import { filesInActiveFolder } from 'store/selectors/sites/files';
import Notification from 'components/hoc/notification';

const mapStateToProps = (state, ownProps) => {
    const page = state.sites.pages.pages[ownProps.pageID];

    return {
        files: filesInActiveFolder(state),
        // page: SelectPage(state),
        // files: selectFiles(state),
        // pages: selectPages(state),
        // blogs: selectBlogs(state),
        page,
        // layoutID: page.layout
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        publishDraft: (siteid, pageid, draft) => dispatch(pageActions.publishDraft(siteid, pageid, draft)),
        saveDraft: (siteid, pageid, draft) => dispatch(pageActions.saveDraft(siteid, pageid, draft))
    };
}

const RendererData = connect(mapStateToProps, mapDispatchToProps)(props => {
    // console.log(props.page, 'page in REndererData')
    const [previewMode, setPreviewMode] = useState(null);
    const { data, forceRefetch, error, errorMessage, loading } = useRendererData({
        siteID: props.siteID,
        contentID: props.pageID,
        contentType: 'page',
        isAdmin: true,
        host: config.coreDomain,
        basePath: '/sites/renderer',
        refetchOnValueChange: [/*props.siteID, props.pageID, props.layoutID, props.themeID,*/ props.files, props.page],
    });

    // const data= fetchedData;

    // const data = useMemo(() => {
    //     return {
    //         ...fetchedData,
    //         files: props.files
    //     };
    // }, [props, fetchedData]);

    const deviceRef = useRef();
    const device = useDimensions(deviceRef);

    if (loading) return (
        <Message size="massive" className="info" style={{ padding: '64px 24px 96px 24px', textAlign: 'center' }}>
            {/* loading message */}
            <div style={{ marginTop: 0, marginBottom: 40 }}>
                <div>
                    <Icon
                        name="sn-file-text"
                        style={{
                            margin: '0 0 24px 0',
                            fontSize: 96,
                            width: 96,
                            opacity: 0.5
                        }}
                    />
                </div>
                <span style={{ fontSize: 40 }}>
                    <FormattedMessage id="pages.loading page" />
                </span>
            </div>
            {/* load spinner */}
            <Loader size="massive" className="info" />
        </Message>
    );
    if (error) return errorMessage;

    // console.log(data.layout)

    return React.cloneElement(props.children, {
        data,
        device,
        deviceRef,
        previewMode,
        forceRefetch,
        setPreviewMode,
        ...props
    });
});

const PageRenderer = Notification(injectIntl(props => {
    const { pageID, siteID, data, createNotification, previewMode, setPreviewMode, publishDraft, saveDraft, deviceRef, device, intl, forceRefetch } = props;
    const { extend, extendAll, renderAtRoot } = useMemo(() => {
        return createExtensions(previewMode);
    }, [previewMode]);

    const contentProps = useMemo(() => {    
        const props = {
            ...data,
            device,
            staticKeys: ['content'],
            resolveContent: state => state.content.content,
            onSave: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await saveDraft(siteID, pageID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.PAGES.DRAFT_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'pages.save notification' }),
                    className: 'positive',
                    position: 'topCenter',
                    icon: 'sn-cloud-upload',
                    deleteTime: 2000
                });

                forceRefetch();
                // console.log('hello');

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            onPublish: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await publishDraft(siteID, pageID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.PAGES.LIVE_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'pages.publish notification' }),
                    className: 'warning',
                    position: 'topCenter',
                    icon: 'sn-sphere',
                    deleteTime: 2000
                });

                forceRefetch(); // Updates the renderer so it retrieves the new 

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            },
                            live: {
                                ...state.content.content.live,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            extend
        };

        if (previewMode === null) {
            props.extend = {
                ...extend,
                all: extendAll,
            };
        }

        if (previewMode === 'draft' || previewMode === null) {
            props.useDraft = true;
        }

        return props;
    }, [data, previewMode, device]);

    // Content props overrule state always - this is problematic for certain keys that have to stay consistent across renders...
    const contentState = useRendererState(contentProps, 'content');

    const resolvePlaceholder = useMemo(() => { 
        // console.log('new placeholder props')
        return () => (
            <Renderer 
                {...contentState}
                renderAtRoot={renderAtRoot}
            />
        );
    }, [contentState, renderAtRoot]);
    
    const layoutProps = useMemo(() => {
        // console.log('new layout props')
        return {
            ...data,
            device,
            extend,
            shouldSwapNodeMap: (state, newProps) => {
                if (newProps.layout !== state.layout) return true;
            },
            resolveContent: state => state.layout.content,
            resolvePlaceholder
        };
    }, [data, resolvePlaceholder, device]);

    const layoutState = useRendererState(layoutProps, 'layout');
    // console.log(contentState.rendererState.content.content.live, 'live stat');

    const renderPreview = useMemo(() => {
        return () => (
            <Renderer
                key={previewMode}
                {...{
                    ...layoutState,
                    rendererState: {
                        ...layoutState.rendererState,
                        resolvePlaceholder: () => {
                            const { all, ...rest } = contentState.rendererState.extend;

                            if (previewMode === 'live') {
                                return (
                                    <Renderer 
                                        {...{
                                            ...contentState,
                                            rendererState: {
                                                ...contentState.rendererState,
                                                nodeMap: contentState.rendererState.content.content.live.nodeMap, //data.content.content.live.nodeMap,
                                                breakpoints: contentState.rendererState.content.content.live.breakpoints,
                                                extend: rest
                                            }
                                            // ...contentState,
                                            // rendererState: {
                                            //     ...contentState.rendererState,
                                            //     nodeMap: data.content.content.live.nodeMap,
                                            //     breakpoints: data.content.content.live.breakpoints,
                                            //     extend: rest
                                            // }
                                        }}
                                    />
                                );
                            }

                            return (
                                <Renderer 
                                    {...{
                                        ...contentState,
                                        rendererState: {
                                            ...contentState.rendererState,
                                            extend: rest
                                        }
                                    }}
                                />
                            )
                        }
                    }
                }}
            />
        );
    }, [layoutState, contentState, previewMode])

    const pageTitle = data.content.title;
    const createPagePopup = useTooltip();
    const closePreviewPopup = useTooltip();
    const pageSettingsPopup = useTooltip();

    return (
        <PreviewContainer active={previewMode} ref={deviceRef}>
            {/* create page / close preview popups */}
            <Popup
                target={createPagePopup.current}
                disabled={previewMode !== null}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="pages.create new page" />
            </Popup>
            <Popup
                target={closePreviewPopup.current}
                disabled={previewMode === null}
                position="bottom center"
                zIndex={999999} // above preview container
            >
                <FormattedMessage id="general.close preview popup" />
            </Popup>
            
            <AppNavigation
                previewMode={previewMode}
                appIcon={previewMode === null ? 'sn-stack-text' : 'sn-eye'}
                appTitle={intl.formatMessage({ id: `${previewMode === null ? 'pages.app title' : 'general.preview title'}`})}
                appActionClassName={previewMode === null ? 'positive' : 'info'}
                appActionStyle={{ padding: 0 }} // fix to facilitate the alternative rendering method of create new page button (padding is set on each button)
                appAction={
                    previewMode === null
                        ?
                            // create new page button
                            <PageSettings
                                renderButton={toggleFormOpen => {
                                    return (
                                        <div
                                            id="pages-new-page-button"
                                            ref={createPagePopup}
                                            onClick={() => toggleFormOpen('create')}
                                            style={{ padding: 6 }}
                                        >
                                            <Icon name="sn-plus3" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                            <span style={{ verticalAlign: 'middle' }}>
                                                <FormattedMessage id="pages.new" />
                                            </span>
                                            <Icon name="sn-file-text" style={{ fontSize: 16, height: 16, marginLeft: 4, marginRight: 0, verticalAlign: 'middle' }} />
                                        </div>
                                    );
                                }}
                            />
                        :
                            // close preview button
                            <div
                                ref={closePreviewPopup}
                                onClick={() => {setPreviewMode(null)}}
                                style={{ padding: 6 }}
                            >
                                <Icon name="sn-cross2" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                {/* <span style={{ verticalAlign: 'middle' }}>
                                    <FormattedMessage id="general.close" />
                                </span> */}
                                <Icon name="sn-eye" style={{ fontSize: 16, height: 16, marginRight: 0, verticalAlign: 'middle' }} />
                            </div>
                }
            >
                <div className="left-panel">
                {previewMode === null
                    ?
                        <React.Fragment>
                            {/* page menu (edit mode) */}
                            <SelectPage
                                draftContent={contentState.rendererState.content.content.draft}
                                liveContent={contentState.rendererState.content.content.live}
                                hasChanged={contentState.rendererState.hasChanged}
                            />
                        </React.Fragment>
                    :
                        // active page title + draft/live (preview mode)
                        <React.Fragment>
                            {/* active page title */}
                            <Icon name="sn-file-text" className="primary-color" style={{ fontSize: 24, marginRight: 6, opacity: 0.6 }} />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginRight: 16
                                }}
                            >
                                {pageTitle}
                            </span>
                            
                            {/* preview toggle */}
                            <ToggleDraftAndLivePreview
                                onChange={setPreviewMode}
                                hasLiveContent={!!contentState.rendererState.content.content.live}
                                value={previewMode}
                            />
                        </React.Fragment>
                }

                {/* pages breadcrumb */}
                {/* TO DO: make this work later
                const generateBreadcrumb = ({ currentPage, pages, breadcrumb = '' }) => {
                        if (!currentPage.parentPage) {
                            if (!breadcrumb.startsWith('/')) return '/' + breadcrumb ;

                            return breadcrumb;
                        }

                        const nextCurrentPage = pages.find(page => page._id === currentPage.parentPage);

                        breadcrumb = `${nextCurrentPage.title}/${breadcrumb}`;

                        return generateBreadcrumb({ currentPage: nextCurrentPage, pages, breadcrumb });
                };
                renderBreadCrumb() {
                    const breadcrumb = generateBreadcrumb({ currentPage: this.props.page, pages: this.props.pages, breadcrumb: this.props.page.title });

                    const breadcrumbAsArray = breadcrumb.split('/').slice(1);

                    return (
                        <Breadcrumb>
                            {breadcrumbAsArray.map(crumb => {
                                return [
                                    <BreadcrumbDivider key={crumb} />,
                                    crumb
                                ];
                            })}
                        </Breadcrumb>
                    );
                }*/}

                {/* <EditPage /> */}
                {previewMode === null &&
                    <React.Fragment>

                        {/* page settings */}
                        <Popup
                            target={pageSettingsPopup.current}
                            position="bottom center"
                            zIndex={997}
                            >
                            <FormattedMessage id="pages.settings" />
                        </Popup>
                        <PageSettings
                            renderButton={toggleFormOpen => {
                                return (
                                    <Icon
                                        link
                                        name="setting"
                                        id="pages-page-menu-settings"
                                        ref={pageSettingsPopup}
                                        onClick={() => toggleFormOpen('update')}
                                        className="primary-color"
                                        style={{ fontSize: 24, marginLeft: 0, marginRight: 16 }}
                                        />
                                        );
                                    }}
                                    />

                        {/* undo + redo */}
                        <PortalHook id="undo-redo-portal" />
                    </React.Fragment>
                }
                </div>
                
                <div className="right-panel">
                    {/* device picker */}
                    <PortalHook id="device-picker-portal" />


                    {/* open preview + save and publish */}
                    {previewMode === null && 
                        <React.Fragment>
                            <OpenPreview
                                onChange={setPreviewMode}
                                value={previewMode}
                                style={{ marginRight: 16 }}
                            />
                            <PortalHook id="save-or-publish-portal" />
                        </React.Fragment>
                    }
                </div>
            </AppNavigation>
            
            {/* renderer (edit vs. preview mode) */}
            {previewMode !== null 
                ?
                    renderPreview()
                :
                    <Renderer
                        {...layoutState}
                    />
            }
        </PreviewContainer>
    );
}));

export default props => {
    return (
        <RendererData {...props}>
            <PageRenderer />
        </RendererData>
    );
};