import constants from '../constants';

const getDefaultState = () => ({
	availableTlds: [],
	checkedDomains: [],
	checkedSubdomains: [],
	subdomain: '.svift.net',
	pickedDomain: {},
	agreeTransferTerms: false
});

const defaultState = getDefaultState();

export default (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_AVAILABLE_TLDS:
			return Object.assign({}, state, {
				availableTlds: action.data,
			});

		case constants.SET_CHECKED_DOMAINS:
			return Object.assign({}, state, {
				checkedDomains: action.data,
			});

		case constants.SET_CHECKED_SUBDOMAINS:
			return Object.assign({}, state, {
				checkedSubdomains: action.data,
			});

		case constants.SET_AGREE_TRANSFER_TERMS: {
			return {
				...state,
				agreeTransferTerms: action.data
			};
		}

		case constants.SET_PICKED_DOMAIN:
			return Object.assign({}, state, {
				pickedDomain: action.data,
			});

		case constants.CLEAR_DOMAIN_SEARCH_RESULTS:
			return Object.assign({}, state, {
				pickedDomain: {},
				checkedDomains: [],
				checkedSubdomains: [],
			});

		default:
			return state;
	}
};
