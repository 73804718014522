import constants from 'store/constants';
import createApi from 'api';
import { setRoute } from 'utils/routing';
import sites from 'store/actions/sites';
import uuid from 'uuid';

export default {
	createPage: (siteid, page) => async (dispatch, getState) => {
		const api = createApi();

		const newPage = await api.createPage(siteid, page);

		await sites.fetchSite(siteid)(dispatch, getState);

/*		dispatch({
			type: constants.CREATE_PAGE,
			data: {
				page: newPage,
				siteid
			},
		});*/

		setRoute(`/dashboard/${siteid}/pages/${newPage._id}`);
	},

	updatePage: (page, siteid, pageid) => async (dispatch, getState) => {
		const api = createApi();

		await api.updatePage(siteid, pageid, page);

		await sites.fetchSite(siteid)(dispatch, getState);
/*		dispatch({
			type: constants.UPDATE_PAGE,
			data: {
				page: newPage,
				siteid,
				pageid
			}
		});*/
	},

	updatePageContent: (page, tree) => ({
		data: {
			page,
			tree,
		},
		type: constants.UPDATE_PAGE_CONTENT,
	}),

	deletePage: (siteid, pageid) => async (dispatch, getState) => {
		const api = createApi();

		await api.deletePage(siteid, pageid);

		const state = getState();

		const frontPage = state.sites.sites[siteid].frontPage;

		setRoute(`/dashboard/${siteid}/pages/${frontPage}`, { delete: constants.DELETE_PAGE });

		await sites.fetchSite(siteid)(dispatch, getState);
	},

	publishDraft: (siteid, pageid, draft) => async (dispatch, getState) => {
		const api = createApi();
		// const draft = getState().sites.content.stagedContent;

		draft.version = uuid();

		await api.publishDraft(draft, siteid, pageid);

		dispatch({
			type: constants.PUBLISH_CONTENT,
			data: {
				draft,
				siteid,
				pageid
			}
		});
	},

	saveDraft: (siteid, pageid, draft) => async (dispatch, getState) => {
		const api = createApi();
		// const draft = getState().sites.content.stagedContent;

		draft.version = uuid();

		await api.saveDraft(draft, siteid, pageid);

		dispatch({
			type: constants.SAVE_CONTENT,
			data: {
				draft,
				siteid,
				pageid
			}
		});
	}
};
