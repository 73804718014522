import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
// import urlConverter from 'svift-library/format/url';
import { withRouter } from 'react-router';
import Hamburger from './mobile-menu/Hamburger';
import MenuButton from './components/MenuButton';
import MenuContainer from './components/MenuContainer';
import { pagesToMenuTree } from 'svift-library/client/renderer/utils/formatPages';
// import { pagesToMenuTree } from 'utils/pages/format';
import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import onClickOutside from 'components/hoc/onClickOutside';

const weaveEntriesWithPages = (entries, pages) => {
	return entries.reduce((acc, entry) => {
		const page = pages.lookup[entry.id]; //find(page => page._id === entry.id);

		if (!page) return acc;

		acc[entry.id] = {
			page,
			navigationEntry: entry,
		};

		return acc;
	}, {});
};
const isHidden = ({ navigationEntry: entry, page }, entriesAndPages) => {
	if (entry.hidden /* || !page.content.live <--- dont think is needed; offline pages are filtered in the backend now*/) return true;

	if (entry.parent) {
		const parentPage = entriesAndPages[entry.parent && entry.parent.toString()];

		if (!parentPage) return true;
	
		return isHidden(parentPage, entriesAndPages);
	}

	return false;
};
const filterHiddenEntries = entriesAndPages => Object.values(entriesAndPages).filter(entryAndPage => !isHidden(entryAndPage, entriesAndPages));

class Menu extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, navigation, pages, site, device, extend, frontPage } = props.context;

		return shallowCompare({
			device,
			navigation,
			pages,
			theme,
			site,
			frontPage,
			computeLink: extend.Menu.computeLink,
			onNavigate: extend.Menu.onNavigate
		}, state);
	}

	constructor(props) {
		super(props);

		this.getMenuStructure = props => {
			const activeNav = props.node.props.navigation;

			const navigation = props.navigation.lookup[activeNav]; 

			const entries = navigation 
				? 
					navigation.entries 
				:
					/* If navigation doesn't exist, we automatically generate one based on page hierarchy */
					props.pages.order.map(pageID => {
						const page = props.pages.lookup[pageID.toString()];
						
						return { 
							id: page._id, 
							parent: page.parentPage, 
							hidden: page.hidden
						};
					});
				
			const entriesAndPagesAsObject = weaveEntriesWithPages(entries, props.pages);

			const filteredEntriesAndPages = filterHiddenEntries(entriesAndPagesAsObject)

			const formattedPages = filteredEntriesAndPages.map(({ page, navigationEntry: entry }) => {
				// const computedUrl = this.props.computeUrl && this.props.computeUrl(page._id, props.pages);

				// console.log(props.frontPage, 'props front page???')

				const computedLink = this.props.computeLink && this.props.computeLink(page._id, props.pages, props.frontPage);

				return {
					...page,
					title: page.navigationTitle || page.title,
					parentPage: entriesAndPagesAsObject[entry.parent && entry.parent.toString()] ? entry.parent.toString() : null,
					computedLink
					// computedUrl: this.props.computeUrl && this.props.computeUrl(page._id, props.pages)
				};
			});
			
			// const test = pagesToMenuTree(formattedPages, null, page => page.navigationTitle || page.title);

			// debugger;

			return pagesToMenuTree(formattedPages, null, page => page.navigationTitle || page.title);
		};

		this.state = {
			hover: null,
			open: {},
			menuStructure: this.getMenuStructure(props)
		};

		this.editorTooltip = tooltip.call(this);
		this.menuTooltips = {};

		const createTooltips = menuStructure => {
			menuStructure.forEach(menuEntry => {
				if (menuEntry.items) {
					this.menuTooltips[menuEntry.text] = tooltip.call(this);

					createTooltips(menuEntry.items);
				}
			});
		};

		createTooltips(this.state.menuStructure);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.pages !== this.props.pages || nextProps.node.props.navigation !== this.props.node.props.navigation || this.props.location.pathname !== nextProps.location.pathname) {
			this.setState({
				menuStructure: this.getMenuStructure(nextProps)
			});
		}
	}

	onClickOutside(e) {
		const clickedElement = e.target;
		const tooltipContainers = document.body.getElementsByClassName('svift-tooltip-no-class');
		const clickedOnTooltip = [].slice.call(tooltipContainers).some(container => container.contains(clickedElement));

		if (clickedOnTooltip) return;

		if (Object.keys(this.state.open).length > 0) {
			this.setState({
				open: {}
			});
		}
	}

	renderMenuButton(menuEntry, index, { depth }) {
		const isVertical = depth > 0 || this.props.node.props.vertical;

		const style = this.props.computedStyling;

		const activePageUrlIdentifier = this.props.location.pathname.split('/').pop();

		let isActivePage = activePageUrlIdentifier === menuEntry.data.url;

		const isRoot = activePageUrlIdentifier.length === 0;

		if (isRoot) {
			const frontPageId = this.props.frontPage;

			isActivePage = frontPageId.toString() === menuEntry.data._id.toString();
		}

		let menuItemStyle = style;

		if (isActivePage) {
			// console.log(menuEntry.data.title, 'active page')

			menuItemStyle = {
				...menuItemStyle,
				...style[':active']
			};
		}

		const onNavigate = (...args) => {
			this.setState({
				open: {}
			});

			this.props.onNavigate(...args);
		}

		// console.log(menuEntry.data.computedLink, 'hello')

		if (menuEntry.items.length === 0) {
			return (
				<MenuButton
					key={menuEntry.data._id}
					style={{
						...menuItemStyle,
						marginLeft: !isVertical ? this.props.computedStyling.marginLeft : 0, // remove horizontal margin when vertical
						marginRight: !isVertical ? this.props.computedStyling.marginRight : 0 // remove horizontal margin when vertical
					}}
					computedLink={menuEntry.data.computedLink}
					onNavigate={onNavigate}
					// onClick={this.props.onClick && this.props.onClick(computedUrl)}
					size={this.props.node.props.size}
				>
					{menuEntry.text}
				</MenuButton>
			);
		}

		return (
			<div
				className="svift-menu-group"
				ref={this.menuTooltips[menuEntry.text].setTarget}
				key={menuEntry.data._id + 'group'}
				style={{
					marginTop: this.props.computedStyling.marginTop,
					marginLeft: !isVertical ? this.props.computedStyling.marginLeft : 0, // remove horizontal margin when vertical
					marginRight: !isVertical ? this.props.computedStyling.marginRight : 0, // remove horizontal margin when vertical
					marginBottom: this.props.computedStyling.marginBottom
				}}
			>
				<MenuButton
					className="svift-menu-parent"
					style={menuItemStyle}
					computedLink={menuEntry.data.computedLink}
					onNavigate={onNavigate}
					size={this.props.node.props.size}
				>
					{menuEntry.text}
				</MenuButton>
					
				<MenuButton
					foldOutButton
					className="svift-menu-submenu"
					style={menuItemStyle}
					size={this.props.node.props.size}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();

						if (this.state.open[depth] === menuEntry.text) {
							const newOpenState = {};

							for (let i = 0; i < depth; i++) {
								newOpenState[i] = this.state.open[i];
							}

							this.setState({
								open: newOpenState
							});

							return;
						}

						this.setState({
							open: {
								...this.state.open,
								[depth]: menuEntry.text
							}
						});
					}}
				>
					<i
						className={
							this.state.open[depth] === menuEntry.text
								?
									(isVertical ? 'icon-arrow-left3' : 'icon-arrow-up3')
								:
									(isVertical ? 'icon-arrow-right3' : 'icon-arrow-down3')
						}
						style={{ fontSize: 10 }}
					/>
				</MenuButton>

				<Tooltip
					noClass
					zIndex={100000000}
					position={isVertical ? 'right' : 'bottom'}
					target={this.menuTooltips[menuEntry.text].getTarget()}
					open={this.state.open[depth] === menuEntry.text}
					content={this.renderMenuButtons(menuEntry.items, { depth: depth + 1 })}
					calculateOffsetTop={positions => {
						const { defaultOffset, scrollTop, targetTop } = positions;

						if (isVertical) {
							return targetTop + scrollTop;
						}

						return defaultOffset;
					}}

					calculateOffsetLeft={positions => {
						const { defaultOffset, targetLeft } = positions;

						if (!isVertical) {
							return targetLeft;
						}

						return defaultOffset;
					}}
					style={{
						margin: 0,
						padding: 0
					}}
				/>
			</div>
		);
	}

	renderMenuButtons(menuStructure, { depth }) {
		const { node } = this.props;

		const isVertical = depth > 0 || node.props.vertical;

		const style = { ...this.props.computedStyling && this.props.computedStyling.container };

		if (!isVertical) {
			const menuWrapperStyle = {
				display: 'flex',
				justifyContent: node.props.alignment || 'center'
			};
			
			if (node.props.fluid) {
				style.width = '100%';
			}

			return (
				<div style={menuWrapperStyle}>
					<MenuContainer
						{...this.props.cropProps}
						vertical={isVertical}
						style={{
							...style,
							justifyContent: node.props.alignment || 'center'
						}}
					>
						{menuStructure.map((menuEntry, index) => {
							return this.renderMenuButton(menuEntry, index, { depth });
						})}
					</MenuContainer>
				</div>
			);
		}

		style.width = '100%';

		return (
			<MenuContainer
				{...this.props.cropProps}
				vertical={isVertical}
				style={style}
			>
				{menuStructure.map((menuEntry, index) => {
					return this.renderMenuButton(menuEntry, index, { depth });
				})}
			</MenuContainer>
		);
	}

	render() {
		// console.log(this.state.menuStructure, 'hello');
		// console.log(this.props.device, 'device');

		return ['phone', 'tablet'].includes(this.props.device) 
			?
				<Hamburger
					{...this.props}
					menuStructure={this.state.menuStructure}
				/>
			:
				this.renderMenuButtons(this.state.menuStructure, { depth: 0 })

	}
}

Menu.contextTypes = {
	device: PropTypes.string
};

const WrappedMenu = withRouter(onClickOutside(Menu));

WrappedMenu.mapContextToProps = Menu.mapContextToProps;
// export default () => 'menu here';
export default WrappedMenu;
