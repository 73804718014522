import config from 'config';
import axios from 'axios';

// TO-DO/Legacy: I don't see a reason to wrap this in a function; at the very least we should export the result instead of calling createApi everywhere in the app
const createApi = () => {
    const headers = {};

    const externalRequest = axios.create({
        validateStatus: (status) => status >= 200 && status < 300,
    });

    const googleRequst = axios.create({
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        validateStatus: (status) => status >= 200 && status < 300,
    });

    // May want to create some kind of wrapper for external requests at some point
    /*	externalRequest.interceptors.response.use((response) => {

	})
*/
    const handledRequest = axios.create({
        baseURL: `${config.coreDomain}`, //'http://localhost:3000',
        validateStatus: (status) => status >= 200 && status < 300,
        withCredentials: true,
        headers,
    });

    // Reduce response to something easier to work with
    handledRequest.interceptors.response.use(
        (response) => {
            if (
                !(
                    response &&
                    response.data &&
                    typeof response.data.result !== 'undefined'
                )
            ) {
                throw `Developer error. The response should always have a data object with a result attached.`;
            }

            return response.data.result; // Return only the result in happy path case (status code 2xx)
        },
        (err) => {
            if (err.response.data.error) {
                return Promise.reject(err.response.data.error); // If this is defined, we assume the error is handled (and has a 'readable' value we can output to the user from the backend)
            }

            return Promise.reject(err.response);
        }
    );

    const apiCalls = {
        /* new structure */
        navigation: {
            post: ({ siteID, navigation }) =>
                handledRequest.post(`/sites/${siteID}/navigation`, {
                    title: navigation.title,
                    entries: navigation.entries,
                }),
            update: ({ siteID, navID, navigation }) =>
                handledRequest.put(`/sites/${siteID}/navigation/${navID}`, {
                    title: navigation.title,
                }),
            remove: ({ siteID, navID }) =>
                handledRequest.delete(`/sites/${siteID}/navigation/${navID}`),
            save: ({ siteID, navID, entries }) =>
                handledRequest.put(
                    `/sites/${siteID}/navigation/${navID}/save`,
                    entries
                ),
        },

        forms: {
            post: ({ siteID, form }) =>
                handledRequest.post(`/sites/${siteID}/forms`, {
                    title: form.title,
                    afterSubmitMessage: form.afterSubmitMessage,
                    contacts: form.contacts,
                }),
            update: ({ siteID, formID, form }) =>
                handledRequest.put(`/sites/${siteID}/forms/${formID}`, {
                    title: form.title,
                    afterSubmitMessage: form.afterSubmitMessage,
                    contacts: form.contacts,
                }),
            remove: ({ siteID, formID }) =>
                handledRequest.delete(`/sites/${siteID}/forms/${formID}`),
            saveDraft: (draft, siteID, formID) =>
                handledRequest.put(
                    `/sites/${siteID}/forms/${formID}/savedraft`,
                    draft
                ),
            publishDraft: (draft, siteID, formID) =>
                handledRequest.put(
                    `/sites/${siteID}/forms/${formID}/publish`,
                    draft
                ),
        },

        getSite: (id) => handledRequest.get(`/sites/${id}`),
        getSitesByUser: ({ userId, admin, siteId }) => {
            /*
				Allows superadmins to view sites that they do not own
			*/
            if (admin && siteId) {
                return handledRequest.get(
                    `/sites/user/${userId}?site=${siteId}`
                );
            }

            return handledRequest.get(`/sites/user/${userId}`);
        },
        postSite: (data) =>
            handledRequest.post('/sites', {
                name: data.name,
                standardLanguage: data.standardLanguage,
                timeZone: data.timeZone,
                layoutTemplate: data.layoutTemplate,
                homepageTitle: data.homepageTitle,
                theme: data.theme,
                pageTemplate: data.pageTemplate,
                contactEmail: data.contactEmail,
            }),
        updateSitePartial: (siteid, data) =>
            handledRequest.put(`/sites/${siteid}`, data),

        /* USERS NEW */
        inviteUser: (siteid, role) =>
            handledRequest.post(`sites/${siteid}/invite`, role),

        getInvitation: ({ token: inviteToken }) =>
            handledRequest.get(`users/invite/${inviteToken}`),

        register: ({ email, firstName, lastName, password, inviteToken }) => {
            let url = '/users/register';

            if (inviteToken) {
                url += `?token=${inviteToken}`;
            }

            return handledRequest.post(url, {
                email,
                password,
                firstName,
                lastName,
            });
        },

        createResetToken: ({ email }) =>
            handledRequest.post('/users/initiate-password-reset', { email }),
        setNewPassword: ({ newPassword, resetToken }) =>
            handledRequest.post(`/users/reset-password`, {
                newPassword,
                resetToken,
            }),
        changePassword: ({ email, oldPassword, newPassword }) =>
            handledRequest.post(`/users/reset-password`, {
                email,
                oldPassword,
                newPassword,
            }),

        login: ({ email, password }) =>
            handledRequest.post('users/login', {
                email,
                password,
            }),

        logout: () => handledRequest.get('users/logout'),

        submitContactForm: ({ subject, question, siteName }) =>
            handledRequest.post('system/submit-contact-form', {
                subject,
                question,
                siteName,
            }),

        // Deprecate updateSite; better to use the partial version to allow for more flexiblity when you don't need to update everything simultaneously
        updateSite: (data) => handledRequest.put(`/sites/${data._id}`, data),
        setBranding: ({ siteID, fileID, type }) =>
            handledRequest.put(`sites/${siteID}/branding/${type}`, {
                fileID,
            }),

        deleteSite: (site) => handledRequest.delete(`/sites/${site}`),
        copySite: ({ siteID, newSiteName }) =>
            handledRequest.post(`/sites/${siteID}/copy`, { newSiteName }),
        transferSite: ({ siteID, newOwnerEmail }) =>
            handledRequest.post(`/sites/${siteID}/transfer`, { newOwnerEmail }),

        getSystemSettings: () => handledRequest.get('/system'),

        // Themes
        createTheme: (theme, siteID, shouldPublish) =>
            handledRequest.post(
                `sites/${siteID}/themes${shouldPublish ? '/publish' : ''}`,
                theme
            ),
        updateTheme: (theme, siteID, themeID, shouldPublish) =>
            handledRequest.put(
                `sites/${siteID}/themes/${themeID}${
                    shouldPublish ? '/publish' : ''
                }`,
                theme
            ),
        deleteTheme: (siteID, themeID) =>
            handledRequest.delete(`/sites/${siteID}/themes/${themeID}`),
        publishSystemTheme: (siteID, themeID) =>
            handledRequest.put(
                `sites/${siteID}/themes/${themeID}/publishsystem`
            ),

        // User
        getUser: () => handledRequest.get('/users/me'),
        updateUserSettings: (data) =>
            handledRequest.put(`/users/${data._id}`, data),
        canDeleteUser: () => handledRequest.get('users/candelete'),
        deleteUser: (data) => handledRequest.delete(`/users/${data._id}`),

        // Pages
        createPage: (siteID, data) =>
            handledRequest.post(`/sites/${siteID}/pages`, {
                title: data.title,
                // keywords: data.keywords,
                navigationTitle: data.navigationTitle,
                parentPage: data.parentPage || null,
                description: data.description || null,
                layout: data.layout,
                author: data.author || null,
                pageTemplate: data.pageTemplate,
                thumbnail: data.thumbnail,
            }),
        updatePage: (siteID, pageID, data) =>
            handledRequest.put(`/sites/${siteID}/pages/${pageID}`, {
                title: data.title,
                navigationTitle: data.navigationTitle,
                parentPage: data.parentPage,
                description: data.description,
                layout: data.layout,
                author: data.author,
                keywords: data.keywords,
                thumbnail: data.thumbnail,
            }),
        saveDraft: (draft, siteID, pageID) =>
            handledRequest.put(
                `/sites/${siteID}/pages/${pageID}/savedraft`,
                draft
            ),
        publishDraft: (draft, siteID, pageID) =>
            handledRequest.put(
                `/sites/${siteID}/pages/${pageID}/publish`,
                draft
            ),
        deletePage: (siteID, pageID) =>
            handledRequest.delete(`/sites/${siteID}/pages/${pageID}`),

        // Layouts
        createLayout: (siteID, { title, type, layoutTemplate }) =>
            handledRequest.post(`/sites/${siteID}/layouts`, {
                title,
                type,
                layoutTemplate,
            }),
        deleteLayout: (siteID, layoutID) =>
            handledRequest.delete(`/sites/${siteID}/layouts/${layoutID}`),
        updateLayout: (siteID, layoutID, { title }) =>
            handledRequest.put(`/sites/${siteID}/layouts/${layoutID}`, {
                title,
            }),
        saveLayoutDraft: (draft, siteID, layoutID) =>
            handledRequest.put(
                `/sites/${siteID}/layouts/${layoutID}/savedraft`,
                draft
            ),
        publishLayoutDraft: (draft, siteID, layoutID) =>
            handledRequest.put(
                `/sites/${siteID}/layouts/${layoutID}/publish`,
                draft
            ),

        files: {
            createResumableUploadURL: (siteID, fileName, mimeType, size) =>
                handledRequest.post(`/storage/${siteID}/file/init-upload`, {
                    file: fileName,
                    mimeType,
                    size,
                }),
            finalizeFileUpload: (siteID, body) =>
                handledRequest.post(`/storage/${siteID}/file/created`, body),
            // replaceFile: (siteID, file) => handledRequest.put(`/storage/${siteID}/file`),
            updateFile: (siteID, file) =>
                handledRequest.patch(`/storage/${siteID}/file`, { file }),
            deleteFile: (siteID, fileID, folderID) =>
                handledRequest.delete(
                    `/storage/${siteID}/file/${fileID}?folderId=${folderID}`
                ),
            moveFolder: (folderID, parentID, siteID) =>
                handledRequest.put(`/storage/${siteID}/folder/move`, {
                    id: folderID,
                    parentID,
                }),
            moveFile: (siteID, fileID, newFolderID, oldFolderID) =>
                handledRequest.put(`/storage/${siteID}/file/move`, {
                    fileId: fileID,
                    oldFolderId: oldFolderID,
                    newFolderId: newFolderID,
                }),
            renameFolder: (newfoldername, folderId, siteID) =>
                handledRequest.put(`/storage/${siteID}/folder/rename`, {
                    id: folderId,
                    name: newfoldername,
                }),
            getFoldersAndFiles: (siteid) =>
                handledRequest.get(`storage/${siteid}/folders`),
            createFolder: (name, parent, siteid) =>
                handledRequest.post(`storage/${siteid}/folder`, {
                    name,
                    parentId: parent,
                }), //unknown mistakes about the url
            deleteFolder: (folderid, siteid) =>
                handledRequest.delete(`/storage/${siteid}/folder/${folderid}`, {
                    id: folderid,
                }),
        },

        getFilesForSite: (siteid) => {
            return handledRequest.get(`/files/buckets/${siteid}`);
        },
        submitError: (data) =>
            handledRequest.post(`/system/client-error`, { ...data }),

        fetchGoogleFonts: () =>
            externalRequest.get(
                'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDC5sGBl1l78WmCRlClZF1E8mrIwE_PFm0'
            ),

        activation: {
            checkDomainAvailability: ({ siteID, domains, subdomain }) =>
                handledRequest.post(
                    `sites/${siteID}/activation/domain-availability`,
                    { domains, subdomain }
                ),
            finalize: (data) =>
                handledRequest.post(
                    `sites/${data.siteID}/activation/finalize`,
                    data
                ),
            getPlans: ({ siteID }) =>
                handledRequest.get(`sites/${siteID}/activation/base-plans`),
            getSubscription: ({ siteID }) =>
                handledRequest.get(`/sites/${siteID}/activation/subscription`),
            getCards: ({ siteID }) =>
                handledRequest.get(`/sites/${siteID}/activation/cards`),
            swapSubscriptionCard: ({
                siteID,
                sourceToken,
                cardID,
                subscriptionID,
            }) =>
                handledRequest.post(
                    `/sites/${siteID}/activation/subscription/swap-card`,
                    { sourceToken, cardID, subscriptionID }
                ),
        },

        siteUsers: {
            grantRole: ({ siteID, role, email }) =>
                handledRequest.post(`users/site/${siteID}/invite`, {
                    role,
                    email,
                }),
            swapRole: ({ siteID, email, newRole }) =>
                handledRequest.put(`users/site/${siteID}/${email}`, {
                    newRole,
                }),
            deleteUser: ({ siteID, email }) =>
                handledRequest.delete(`users/site/${siteID}/${email}`),
            fetch: ({ siteID }) => handledRequest.get(`/users/site/${siteID}`),
        },

        google: {
            getAuthUrl: (siteID) =>
                handledRequest.get(`/google/${siteID}/oauthurl`),
            saveAnalyticsId: (siteID, id) =>
                handledRequest.post(`/google/${siteID}/analytics`, {
                    siteID: siteID,
                    analyticsId: id,
                }),
            getAnalyticsId: (siteID) =>
                handledRequest.get(`/google/${siteID}/analytics`),
            saveGSuiteID: (siteID, id) =>
                handledRequest.post(`/google/${siteID}/gsuite`, {
                    gsuiteID: id,
                }),
            getGsuiteId: (siteID) =>
                handledRequest.get(`/google/${siteID}/gsuite`),
            bucket: {
                upload: (siteID, name) => {
                    externalRequest.post(
                        `https://www.googleapis.com/upload/storage/v1/b/${siteID}/o?uploadType=resumable&name=${name}`
                    );
                },
            },
        },

        dns: {
            getRecords: (siteID, domain) =>
                handledRequest.get(`/dns/${siteID}/records/${domain}`),
            addRecord: (siteID, domain, record) =>
                handledRequest.post(`/dns/${siteID}/records`, {
                    domain,
                    record,
                }),
            editRecord: (siteID, domain, recordId, data) =>
                handledRequest.put(`/dns/${siteID}/records`, {
                    domain,
                    recordId,
                    data,
                }),
            deleteRecord: (siteID, domain, recordId) =>
                handledRequest.delete(
                    `/dns/${siteID}/records/${domain}/${recordId}`
                ),
        },

        blogPosts: {
            post: (siteID, blogPost) =>
                handledRequest.post(`/sites/${siteID}/blog-posts`, blogPost),
            patch: (siteID, blogPostID, blogPost) =>
                handledRequest.patch(
                    `/sites/${siteID}/blog-posts/${blogPostID}`,
                    blogPost
                ),
            delete: (siteID, blogID) =>
                handledRequest.delete(`/sites/${siteID}/blog-posts/${blogID}`),
            saveContent: (siteID, blogPostID, draft, shouldPublish = false) =>
                handledRequest.put(
                    `/sites/${siteID}/blog-posts/${blogPostID}/content`,
                    { draft, shouldPublish }
                ),
        },
        modules: {
            // post: (siteID, modules) => handledRequest.post(`/sites/${siteID}/modules`, modules),
            patch: (siteID, modules) =>
                handledRequest.patch(`/sites/${siteID}/modules`, modules),
        },
    };

    return apiCalls;
};

export default createApi;
