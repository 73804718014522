import React, { PureComponent } from 'react';
import { Menu, MenuMenu, MenuItem, Icon } from 'svift-ui';

import SideMenuButton from 'containers/dashboard/top-navigation/__components/SideMenuButton';
import UserMenuButton from 'containers/dashboard/top-navigation/__components/UserMenuButton';
import DashboardButton from 'containers/dashboard/top-navigation/__components/DashboardButton';
// import StepByStepButton from 'containers/dashboard/top-navigation/__components/StepByStepButton';
import LanguageList from 'containers/dashboard/top-navigation/__components/LanguageList';
import ShortcutSection from 'containers/dashboard/top-navigation/__components/ShortcutSection';
import SitesList from 'containers/dashboard/top-navigation/__components/SitesList';
import SupportMenu from 'containers/dashboard/top-navigation/__components/support/SupportMenu';

import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		siteid: state.sites.params.siteid,
		pathname: state.router.location.pathname,
		user: state.user
	};
};

class TopNavigationContainer extends PureComponent {
	render() {
		const isAdministrator = this.props.user.roles.find(role => role.type === 'superadmin');

		return (
			<Switch>
				{/* no site route */}
				<Route
					exact
					path="/dashboard"
					render={() => (
						<Menu id="top-navigation">
							{isAdministrator && <DashboardButton admin />}
							<MenuMenu position="right">
								<SupportMenu/>
								<UserMenuButton userFirstName={this.props.user.firstName} />
							</MenuMenu>
						</Menu>
					)}
				/>

				{/* dashboard route */}
				<Route
					exact
					path="/dashboard/:siteid"
					render={() => (
						<Menu id="top-navigation">
							{isAdministrator && <DashboardButton admin />}
							<MenuItem disabled style={{ background: this.props.admin ? 'none' : 'none' }}>
								<Icon name="sn-logo" style={{ fontSize: 28, margin: 0 }} />
							</MenuItem>
							<MenuMenu position="right">
								{/* <StepByStepButton /> */}
								<SupportMenu/>
								<UserMenuButton userFirstName={this.props.user.firstName} />
							</MenuMenu>
						</Menu>
					)}
				/>

				{/* site activation route */}
				<Route
					exact
					path="/dashboard/:siteid/activation"
					render={() => (
						<Menu id="top-navigation">
							<DashboardButton />
							{isAdministrator && <DashboardButton admin />}
							<MenuMenu position="right">
								<SupportMenu/>
								<UserMenuButton userFirstName={this.props.user.firstName} />
							</MenuMenu>
						</Menu>
					)}
				/>

				{/* all other routes */}
				<Route
					path="/dashboard"
					render={() => (
						<Menu id="top-navigation">
							<SideMenuButton />
							{isAdministrator && <DashboardButton admin />}
							<DashboardButton />
							<ShortcutSection />
							<MenuMenu position="right">
								<SitesList />
								<LanguageList />
								{/* <StepByStepButton /> */}
								<SupportMenu/>
								<UserMenuButton userFirstName={this.props.user.firstName} />
							</MenuMenu>
						</Menu>
					)}
				/>
			</Switch>
		);
	}
}

export default connect(mapStateToProps)(TopNavigationContainer);