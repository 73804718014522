exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".selected-files__no-selection-title--228fr{padding-top:8px;margin-bottom:16px;font-size:16px;opacity:0.6}\n", ""]);

// exports
exports.locals = {
	"no-selection-title": "selected-files__no-selection-title--228fr"
};