import React from 'react';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { FormField, FormSelect, Message, FormGroup, Icon } from 'svift-ui';
import InfoIcon from 'components/InfoIcon';

const generateDropdown = options => props => {
	const { icon, name, placeholder, flag } = options;
	const { title, values, intl, infoIcon, meta: { error }, disabled, size, fluid } = props; // Provided by Field component from redux-form

	const className = error && 'error';

	return (
		<React.Fragment>
 			{/* {(name || icon) &&
				<label>
					<Icon name={icon} className="shade" /> {intl.formatMessage({ id: name })}{infoIcon ? <InfoIcon description={infoIcon} />  : null}
				</label>
			} */}

			<Dropdown
				icon={icon}
				flag={flag}
				wings={props.wings}
				title={title} // || intl.formatMessage({ id: placeholder })} // never shows anyway
				items={values}
				size={size}
				fluid={fluid}
				className={className}
				value={props.input.value}
				onChange={(...args) => {
					if (props.onChangeListener) props.onChangeListener(...args); // hack..
					props.input.onChange(...args)
				}}
			/>

			{error &&
				<Message negative>
					{error(intl)}
				</Message>
			}
		</React.Fragment>
	);

	// return (
	// 	<FormField>
	// 		<FormGroup inline style={{ marginTop: 16 }}>
	// 			{(name || icon) &&
	// 				<label>
	// 					<Icon name={icon} className="shade" /> {intl.formatMessage({ id: name })}{infoIcon ? <InfoIcon description={infoIcon} />  : null}
	// 				</label>
	// 			}

	// 			<FormSelect
	// 				className={className}
	// 				disabled={disabled}
	// 				value={props.input.value}
	// 				options={values}
	// 				onChange={(param, data) => {
	// 					props.input.onChange(data.value);
	// 				}}
	// 				placeholder={intl.formatMessage({ id: placeholder })}
	// 			/>
	// 		</FormGroup>

	// 		{error &&
	// 			<Message negative>
	// 				{error(intl)}
	// 			</Message>
	// 		}
	// 	</FormField>
	// );

	// return (
	// 	<FormField>
	// 		<FormGroup inline style={{ marginTop: 16 }}>
	// 			{(name || icon) &&
	// 				<label>
	// 					<Icon name={icon} className="shade" /> {intl.formatMessage({ id: name })}{infoIcon ? <InfoIcon description={infoIcon} />  : null}
	// 				</label>
	// 			}

	// 			<FormSelect
	// 				className={className}
	// 				disabled={disabled}
	// 				value={props.input.value}
	// 				options={values}
	// 				onChange={(param, data) => {
	// 					props.input.onChange(data.value);
	// 				}}
	// 				placeholder={intl.formatMessage({ id: placeholder })}
	// 			/>
	// 		</FormGroup>

	// 		{error &&
	// 			<Message negative>
	// 				{error(intl)}
	// 			</Message>
	// 		}
	// 	</FormField>
	// );
};

export default generateDropdown;