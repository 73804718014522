import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Portal from 'components/editor-v2/editor-interface/Portal';
import PageBlocks from './blocks-by-route/PageBlocks';
import PageLayoutBlocks from './blocks-by-route/PageLayoutBlocks';
import BlogLayoutBlocks from './blocks-by-route/BlogLayoutBlocks';
import BlogBlocks from './blocks-by-route/BlogBlocks';
import FormBlocks from './blocks-by-route/FormBlocks';

export default React.memo(props => {
    return (
        <Portal target="building-blocks-sidenav-portal">
            <Switch>
                <Route 
                    path="/dashboard/:siteid/renderer" 
                    component={() => <PageBlocks dragState={props.dragState} />} 
                />

                <Route 
                    path="/dashboard/:siteid/pages" 
                    component={() => <PageBlocks dragState={props.dragState} />} 
                />

                <Route 
                    path="/dashboard/:siteid/blog-posts" 
                    component={() => {
                        return <BlogBlocks dragState={props.dragState} />;
                    }}
                />
 
                <Route 
                    path="/dashboard/:siteid/design/layouts" 
                    component={() => {
                        if (props.isBlogLayout) {
                            return <BlogLayoutBlocks dragState={props.dragState} />;
                        } 

                        return <PageLayoutBlocks dragState={props.dragState} />;
                    }}
                />

                <Route 
                    path="/dashboard/:siteid/design/forms" 
                    component={() => {
                        return <FormBlocks dragState={props.dragState} />;
                    }}
                />
            </Switch>
        </Portal>
    );
});