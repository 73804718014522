import React from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import NumberPicker from 'components/option-library/primitives/input/number';
// import Dropdown from 'components/option-library/primitives/dropdown/simple';
// import { ColorPalette } from 'components/option-library/primitives/color-picker';
// import { useHistoryHelper } from 'components/editor-v2/extend/historyHelper';
// import PaddingDirection from './PaddingDirection';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import key from 'utils/key';
import css from '../../../../options.scss';

// const historyHelper = useHistoryHelper(
//     props.getState, 
//     'resize horizontal', 
//     context => context.nodeMap[column.current.id].width, 
//     value => context => {
//         return {
//             ...context,
//             nodeMap: {
//                 ...context.nodeMap,
//                 [column.current.id]: {
//                     ...context.nodeMap[column.current.id],
//                     width: value
//                 }
//             }
//         };
//     }
// ); 
// historyHelper.commit();

const Padding = React.memo(props => {
    const { accordionState, intl, value, getState, theme } = props;
    const [state, dispatch] = accordionState;

    // const padding = value || {};
    // const presetPadding = props.preset.padding || {};

    const onChange = (key, value, ...args) => {
        let nextPadding = {
            ...props.inlineStyling,
            [key]: value
        };

        if (key === 'generalPadding') {
            nextPadding = {
               [key]: value
            };
        } else {
            delete nextPadding.generalPadding;
        }

        if (typeof value === 'undefined') delete nextPadding[key];

        if (Object.keys(nextPadding).length === 0) {
            props.onChange(undefined, ...args);
        } else {
            props.onChange(nextPadding, ...args);
        }
    };

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                nested
                icon="sn-shrink3"
                title={props.intl.formatMessage({ id: 'options.padding title' })}
                clear={{
                    onClear: () => props.onChange(undefined, 'clear all padding', false),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <Label
                    labelIcon="sn-shrink2"
                    label={props.intl.formatMessage({ id: 'options.padding general title' })}
                    clear={{
                        onClear: () => onChange('generalPadding', undefined, 'clear padding', false),
                        enabled: typeof key('inlineStyling.generalPadding')(props) !== 'undefined'
                    }}
                >
                    <NumberPicker
                        disableUndoRedo
                        fluid={false}
                        name={props.intl.formatMessage({ id: 'options.padding general title' })}
                        unit="%"
                        min={0}
                        max={500}
                        tooltipPosition="right center"
                        value={Math.round(key('inlineStyling.generalPadding')(props) * 100)}
                        onChange={newValue => onChange('generalPadding', typeof newValue !== 'undefined' ? newValue / 100 : undefined, 'padding')}					
                    />
                </Label>
                
                <div className={css['filter-outline']} style={{ display: 'block' }}>
                    {/* top padding option */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-down8"
                                label={props.intl.formatMessage({ id: 'general.top' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('paddingTop', undefined, 'clear padding top', false),
                                    enabled: typeof key('inlineStyling.paddingTop')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={props.intl.formatMessage({ id: 'options.padding top title' })}
                                    unit="%"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={Math.round(key('inlineStyling.paddingTop')(props) * 100)}
                                    onChange={newValue => onChange('paddingTop', typeof newValue !== 'undefined' ? newValue / 100 : undefined, 'padding top')}					
                                />
                            </Label>
                        </div>
                    </div>

                    {/* left / right margin options */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', paddingRight: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-right8"
                                label={props.intl.formatMessage({ id: 'general.left' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('paddingLeft', undefined, 'clear padding left', false),
                                    enabled: typeof key('inlineStyling.paddingLeft')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={props.intl.formatMessage({ id: 'options.padding left title' })}
                                    unit="%"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={Math.round(key('inlineStyling.paddingLeft')(props) * 100)}
                                    onChange={newValue => onChange('paddingLeft', typeof newValue !== 'undefined' ? newValue / 100 : undefined, 'padding left')}					
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', paddingRight: 4, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-left8"
                                label={props.intl.formatMessage({ id: 'general.right' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('paddingRight', undefined, 'clear padding right', false),
                                    enabled: typeof key('inlineStyling.paddingRight')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={props.intl.formatMessage({ id: 'options.padding right title' })}
                                    unit="%"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={Math.round(key('inlineStyling.paddingRight')(props) * 100)}
                                    onChange={newValue => onChange('paddingRight', typeof newValue !== 'undefined' ? newValue / 100 : undefined, 'padding right')}					
                                />
                            </Label>
                        </div>
                    </div>

                    {/* bottom padding option */}
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Label
                                collapsed
                                labelIcon="sn-arrow-up8"
                                label={props.intl.formatMessage({ id: 'general.bottom' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('paddingBottom', undefined, 'clear padding bottom', false),
                                    enabled: typeof key('inlineStyling.paddingBottom')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name={props.intl.formatMessage({ id: 'options.padding bottom title' })}
                                    unit="%"
                                    min={0}
                                    max={500}
                                    tooltipPosition="right center"
                                    value={Math.round(key('inlineStyling.paddingBottom')(props) * 100)}
                                    onChange={newValue => onChange('paddingBottom', typeof newValue !== 'undefined' ? newValue / 100 : undefined, 'padding bottom')}					
                                />
                            </Label>
                        </div>
                    </div>
                </div>

                {/* <PaddingDirection
                    type="generalPadding"
                    translationKey="options.padding general title"
                    preset={presetPadding}
                    theme={props.theme}
                    value={padding}
                    onChange={onChange}
                />

                <PaddingDirection
                    type="paddingLeft"
                    translationKey="options.padding left title"
                    preset={presetPadding}
                    theme={props.theme}
                    value={padding}
                    onChange={onChange}
                />

                <PaddingDirection
                    type="paddingRight"
                    translationKey="options.padding right title"
                    preset={presetPadding}
                    theme={props.theme}
                    value={padding}
                    onChange={onChange}
                />

                <PaddingDirection
                    type="paddingTop"
                    translationKey="options.padding top title"
                    preset={presetPadding}
                    theme={props.theme}
                    value={padding}
                    onChange={onChange}
                />

                <PaddingDirection
                    type="paddingBottom"
                    translationKey="options.padding bottom title"
                    preset={presetPadding}
                    theme={props.theme}
                    value={padding}
                    onChange={onChange}
                /> */}
            </Section>
        </Accordion>
    );

    // const historyHelper = useHistoryHelper(
    //     getState, 
    //     'resize horizontal', 
    //     context => context.nodeMap[column.current.id].width, 
    //     value => context => {
    //         return {
    //             ...context,
    //             nodeMap: {
    //                 ...context.nodeMap,
    //                 [column.current.id]: {
    //                     ...context.nodeMap[column.current.id],
    //                     width: value
    //                 }
    //             }
    //         };
    //     }
    // );

    // return (
    //     <Accordion {...accordionState}>
    //         <Section 
    //             title="Clearance"
    //             nested
    //         >
    //             <Label
    //                 label="General Clearance"
    //                 labelIcon="cube"
    //                 labelDescription="some description"
    //             >
    //                 <NumberPicker 
    //                     name="General Clearance"
    //                     unit="px"
    //                     min={0}
    //                     max={400}
    //                     value={value}
    //                     onChange={onChange}						
    //                 />
    //             </Label>

    //             <Label
    //                 label="Left Clearance"
    //                 labelIcon="cube"
    //                 labelDescription="some description"
    //             >
    //                 <NumberPicker 
    //                     name="Left Clearance"
    //                     unit="px"
    //                     min={0}
    //                     max={400}
    //                     value={value}
    //                     onChange={onChange}						
    //                 />
    //             </Label>

    //             <Label
    //                 label="Top Clearance"
    //                 labelIcon="cube"
    //                 labelDescription="some description"
    //             >
    //                 <NumberPicker 
    //                     name="Top Clearance"
    //                     unit="px"
    //                     min={0}
    //                     max={400}
    //                     value={value}
    //                     onChange={onChange}						
    //                 />
    //             </Label>

    //             <Label
    //                 label="Right Clearance"
    //                 labelIcon="cube"
    //                 labelDescription="some description"
    //             >
    //                 <NumberPicker 
    //                     name="Right Clearance"
    //                     unit="px"
    //                     min={0}
    //                     max={400}
    //                     value={value}
    //                     onChange={onChange}						
    //                 />
    //             </Label>

    //             <Label
    //                 label="Bottom Clearance"
    //                 labelIcon="cube"
    //                 labelDescription="some description"
    //             >
    //                 <NumberPicker 
    //                     name="Bottom Clearance"
    //                     unit="px"
    //                     min={0}
    //                     max={400}
    //                     value={value}
    //                     onChange={onChange}						
    //                 />
    //             </Label>
    //         </Section>
    //     </Accordion>
    // );
});

export default injectIntl(Padding);