import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import Toggle, {
    ToggleGroup
} from 'components/option-library/primitives/toggle';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import BackgroundVideoOptions from 'components/editor-v2/editor-interface/options/common/background-video';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.body}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label
                    labelIcon="sn-arrow-resize3"
                    label={intl.formatMessage({
                        id: 'options.body horizontal alignment label'
                    })}
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">
                        <Toggle
                            icon="sn-vertical_align_top"
                            rotated="counterclockwise"
                            tooltipLabel={intl.formatMessage({
                                id: 'options.alignment left'
                            })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'flex-start'}
                            // value={
                            //     ['center', 'flex-end'].indexOf(
                            //         node.props.horizontalAlign
                            //     ) === -1
                            // }
                            onChange={() =>
                                onChange(
                                    'horizontalAlign',
                                    node.props.horizontalAlign === 'flex-start' ? undefined : 'flex-start',
                                    'body horizontal alignment',
                                    false
                                )
                            }
                        />
                        <Toggle
                            icon="sn-vertical_align_center"
                            rotated="clockwise"
                            tooltipLabel={intl.formatMessage({
                                id: 'options.alignment center'
                            })}
                            tooltipPosition="top center"
                            value={node.props.horizontalAlign === 'center'}
                            onChange={() =>
                                onChange(
                                    'horizontalAlign',
                                    node.props.horizontalAlign === 'center' ? undefined : 'center',
                                    'body horizontal alignment',
                                    false
                                )
                            }
                        />
                        <Toggle
                            icon="sn-vertical_align_bottom"
                            rotated="counterclockwise"
                            tooltipLabel={intl.formatMessage({
                                id: 'options.alignment right'
                            })}
                            tooltipPosition="top center"
                            value={
                                node.props.horizontalAlign === 'flex-end'
                            }
                            onChange={() =>
                                onChange(
                                    'horizontalAlign',
                                    node.props.horizontalAlign === 'flex-end' ? undefined : 'flex-end',
                                    'body horizontal alignment',
                                    false
                                )
                            }
                        />
                    </ToggleGroup>
                </Label>
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <BackgroundVideoOptions {...props} />
                <BackgroundOptions {...props} />
            </Accordion>
        </React.Fragment>
    );
}));