import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';
import WithBackground from 'svift-library/client/image/WithBackground';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import VideoBackground from 'svift-library/client/renderer/nodes/components/library/__components/background-video';
class Body extends PureComponent {
    static mapContextToProps = (props, state) => {
        const { files, theme, deviceRef } = props.context;

        return shallowCompare(
            {
                files,
                theme,
                deviceRef
            },
            state
        );
    };

    getStyling = () => {
        const justifyContent = this.props.node.props.horizontalAlign || 'center';
        const alignItems = this.props.node.props.verticalAlign || 'flex-start';
        
        return {
            position: 'relative',
            display: 'flex',
            justifyContent,
            alignItems,
            ...this.props.computedStyling,
            width: 'auto',
            minHeight: '100%'
        };
    };

    render() {
        const { node } = this.props;

        const file = this.props.files.lookup[node.props.backgroundImage];

        return (
            <WithBackground
                nodeProps={node.props}
                file={file}
                render={(backgroundStyles, imageRef) => {
                    let className = '';

                    if (backgroundStyles) className = 'editor-background-image';

                    return (
                        <div
                            data-type="body"
                            className={className}
                            ref={ref => {
                                if (imageRef) imageRef.current = ref;
                                if (this.props.deviceRef) this.props.deviceRef.current = ref; 
                            }}
                            style={{
                                ...this.getStyling(),
                                ...backgroundStyles
                            }}
                        >
                            <VideoBackground {...this.props} />
                            
                            {node.children.map(childID => {
                                return (
                                    <Node
                                        key={childID}
                                        nodeID={childID}
                                        ContextConsumer={
                                            this.props.ContextConsumer
                                        }
                                        freezeDragPath={
                                            this.props.freezeDragPath
                                        }
                                        getPath={this.props.getPath}
                                    />
                                );
                            })}
                        </div>
                    );
                }}
            />
        );
    }
}

export default Body;
