import React, { memo } from 'react';

export default memo(props => {
    return (
        <div className="ui textarea">
            <textarea
                value={props.value || ''}
                onChange={e => {
                    props.onChange(e.target.value);
                }}
            />
        </div>
    );
});