import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	Icon,
	Loader,
	Header,
	Message
} from 'svift-ui';
import { setRoute } from 'utils/routing';
import createApi from 'api';
const api = createApi();
import { injectIntl, FormattedMessage } from 'react-intl';
import BeforeActivation from './BeforeActivation';
import siteActions from 'store/actions/sites';
import CostOverview from './CostOverview';
import CardPicker from 'components/stripe/CardPicker';
import notification from 'components/hoc/notification';

const mapStateToProps = state => {
	return {
		siteid: state.sites.params.siteid
	};
};

const mapDispatchToProps = dispatch => ({
	transferSite: (...args) => dispatch(siteActions.transferSite(...args))
});

class SubscriptionSection extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			subscription: null,
			transferingSite: false,
			fetchingSubscription: true,
			agreeTerms: false
		};

		this.transferSite = this.transferSite.bind(this);
		this.swapSubscriptionCard = this.swapSubscriptionCard.bind(this);
	}

	async swapSubscriptionCard() {
		if (this.swapping) return;
		this.swapping = true;

		try {
			let token;

			if (!this.card) {
				const response = await this.generateToken();

				if (response.error) {
					response.error.stripe = true;

					throw response.error;
				}

				token = response.token.id;
			}

			await api.activation.swapSubscriptionCard({ siteID: this.props.siteid, subscriptionID: this.state.subscription.id, cardID: this.card, sourceToken: token });

			this.props.createNotification({
				content: this.props.intl.formatMessage({ id: 'settings.subscription payment method updated notification' }),
				className: 'success',
				position: 'topCenter',
				icon: 'credit card outline',
				deleteTime: 5000
			});

			this.setState({
				fetchingSubscription: true
			}, this.fetchActivation);

			// window.location.reload();
		} catch(e) {
			console.log(e);

			if (e.stripe) {
				/*
					To-do: potentially add error handling for more cases

					https://stripe.com/docs/api#errors
				*/
				if (e.type === 'validation_error') {
					this.setState({
						error: 'activation.order validation message invalid credit card'
					});
				} else {
					this.setState({
						error: 'activation.order validation message payment error'
					});
				}

				return;
			}

			this.setState({
				swappingSubscriptionCard: true,
				error: e.intlReadable
			});
		} finally {
			this.swapping = false;
		}
	}

	async transferSite(newOwnerEmail) {
		this.setState({
			transferingSite: true
		});

		try {
			await this.props.transferSite({ siteID: this.props.siteid, newOwnerEmail });
		} catch(e) {
			console.log(e);

			this.setState({
				transferingSite: false,
				transferSiteError: e.intlReadable
			});
		}
	}

	async fetchActivation() {
		if (this.state.fetchingSubscription) {
			const subscription = await api.activation.getSubscription({ siteID: this.props.siteid });

			if (this.unmounted) return;

			this.setState({
				subscription,
				fetchingSubscription: false
			});
		}
	}

	componentDidMount() {
		this.fetchActivation();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		if (this.state.fetchingSubscription || this.state.transferingSite) {
			return <Loader size="massive" style={{ marginTop: 24, marginBottom: 32 }}> <FormattedMessage id="settings.subscription loading" /> </Loader>;
		}

		return (
			<React.Fragment>
				<div className="box-icon-container">
					<Icon circular name="sn-subscription-base" className="box-icon" />
				</div>

				<BeforeActivation
					show={this.state.subscription === null}
					users={this.props.users}
					goToActivation={() => setRoute(`/dashboard/${this.props.siteid}/activation`)}
					transferSite={this.transferSite}
					transferSiteError={this.state.transfterSiteError}
				/>

				<CostOverview
					subscription={this.state.subscription}
				/>

				{/* payment method section */}
				{this.state.subscription &&
					<div className="section-shade" style={{ paddingTop: 16, paddingBottom: 32 }}>
						<Header as="h4">
							<FormattedMessage id="settings.subscription payment method title" />
						</Header>

						<div style={{ maxWidth: 480, margin: 'auto' }}>
							<p className="small-text" style={{ textAlign: 'left' }}>
								<FormattedMessage id="settings.subscription payment method description" />
							</p>
							<CardPicker
								key={this.state.subscription.default_source}
								initialCard={this.state.subscription.default_source}
								handleSetCard={this.swapSubscriptionCard}
								setTokenGenerator={fn => this.generateToken = fn}
								setCard={card => this.card = card}
							/>
						</div>
					</div>
				}

				{this.state.error &&
					<Message
						className="error"
						size="large"
						style={{ textAlign: 'left' }}
						icon="sn-warning"
						content={<FormattedMessage id={this.state.error} />}
					/>
				}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(SubscriptionSection)));
