import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import key from 'svift-library/key';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';

class Map extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, extend } = props.context;
	
        return shallowCompare({
			theme,
			mapStyling: extend.Map && extend.Map.styling
        }, state);
    }
    
	constructor(props) {
		super(props);

		this.state = {
			address: props.node.props.address || ''
		};
	}

	renderMap = computedStyling => {
		const { node } = this.props;

		const address = node.props.address;
		const mapStyling = { ...computedStyling, ...this.props.mapStyling};

		if (!address) {
			return (
				<img
					ref={ref => {
						if (this.props.cropRef) this.props.cropRef.current = ref
					}}
					style={{ ...mapStyling, ...this.props.cropStyling }}
					width="100%"
					height="100%"
					src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-map.svg"
				/>
			);
		}

		if (!this.props.isCropped) {
			return (
				<div 
					style={{
						position: 'relative',
						width: '100%',
						paddingTop: '87.5%'
					}}
				>
					<div
						style={{
							position: 'absolute',
							overflow: 'hidden',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0
						}}
					>
						<iframe 
							src={`https://maps.google.com/maps?&q="${encodeURIComponent(address)}"&output=embed`}
							style={mapStyling}
							width="100%" 
							height="100%" 
							frameBorder="0" 
							allowFullScreen 
						/>
					</div>
				</div>
			)
		}

		return (
			<iframe 
				ref={ref => {
					if (this.props.cropRef) this.props.cropRef.current = ref
				}}
				src={`https://maps.google.com/maps?&q="${encodeURIComponent(address)}"&output=embed`}
				width="100%"
				height="100%"
				style={{ ...mapStyling, ...this.props.cropStyling }}
				frameBorder="0" 
				allowFullScreen 
			/>
		);
	}

	render() {
		return this.renderMap(this.props.computedStyling);
	}
}

export default Crop(Map);