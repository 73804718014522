import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'svift-library/client/modal';
import {
	Icon,
    Header,
	Button,
    FormSelect,
    Message,
    Label,
    Divider
} from 'svift-ui';
import Section from 'components/sections/Section';
import { FormattedMessage, injectIntl } from 'react-intl';
import OptionLabel from 'components/option-library/label';

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid;
	const siteName = state.sites.sites[siteid].name;

	return {
		siteName: siteName
	};
};

const formatUserOptions = users => {
    return users.filter(user => {
        const { 
            type, 
            confirmationID 
        } = user.roles[0];
        
        // Can't transfer site to person with a pending invitation, or to the siteOwner itself (obviously :-P)
        return type !== 'siteOwner' && !confirmationID;
    }).map(user => ({
        value: user.email,
        text: user.email
    }))
}

class BeforeActivation extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            users: formatUserOptions(props.users || []),
            selectedUser: null,
            modalOpen: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.users !== nextProps.users) {
            this.setState({
                users: formatUserOptions(nextProps.users || []),
                selectedUser: null
            });
        }
    }

    transferSite = () => this.props.transferSite(this.state.selectedUser)

    render() {
        if (!this.props.show) return null;
        
        const { siteName, intl } = this.props;
        
        return (
            <React.Fragment>
                {/* site offline message */}
                <Message
                    // size="massive"
                    className="warning"
                    style={{ textAlign: 'left', paddingTop: 24, marginBottom: 16 }}
                >
                    <Divider horizontal><Icon circular name="plug" style={{ opacity: 0.6, fontSize: 24 }} /></Divider>
                    <Header 
                        as="h4"
                        // icon={<Icon name="plug" style={{ opacity: 0.4 }} />}
                        content={intl.formatMessage({ id: 'settings.subscription site offline title' })} style={{ marginBottom: 8 }}
                        textAlign="center"
                    />
                    <p style={{ maxWidth: 400, margin: 'auto' }}>
                        <FormattedMessage id="settings.subscription site offline description" />
                    </p>

                    <Divider horizontal>
                        {/* <Icon circular name="sn-flag5" className="dark-positive-color" style={{ fontSize: 24 }} /> */}
                        <Button
                            size="large"
                            positive
                            style={{ marginRight: 0, marginBottom: 16 }}
                            onClick={this.props.goToActivation}
                        >
                            <Icon name="sn-flag6" />
                            <FormattedMessage id="settings.subscription activate site button" />
                        </Button>
                    </Divider>
                </Message>

                {/* transfer ownership */}
                <div style={{ textAlign: 'left' }}>
                    <Divider horizontal><Icon circular name="sn-star-empty" style={{ opacity: 0.6, fontSize: 24 }} /></Divider>

                    <Header
                        as="h4"
                        icon="sn-sphere"
                        // icon={<Icon name="sn-star-empty" style={{ opacity: 0.4 }} />}
                        content={intl.formatMessage({ id: 'settings.transfer ownership modal title' })}
                        style={{ marginBottom: 8 }}
                    />
                    <p>
                        <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                            <FormattedMessage id="general.obs label" />
                        </span>
                        <FormattedMessage id="settings.transfer ownership summary" />
                    </p>
                    <Button size="medium" className="warning" onClick={() => this.setState({ modalOpen: true })}>
                        <Icon name="sn-account_box" />
                        <FormattedMessage id="settings.transfer ownership button" />
                    </Button>
                    
                    {this.state.modalOpen &&
                        <Modal
                            title={intl.formatMessage({ id: 'settings.transfer ownership modal title' })}
                            style={{ maxWidth: 520 }}
                            toggleModal={() => this.setState({ modalOpen: false })}
                            nodeAttributes={{
                                'data-ignore-click': true
                            }}
                        >
                            <Section icon="sn-star-full">
                                <Header as="h2" content={intl.formatMessage({ id: 'settings.transfer ownership title' })} />
                                        
                                <Label basic className="primary" size="large" style={{ marginBottom: 16 }}>
                                    <Icon name="sn-sphere" style={{ marginRight: 6 }} />
                                    <span>
                                        {siteName}
                                    </span>
                                </Label>
                                
                                <p><FormattedMessage id="settings.transfer ownership description" /></p>

                                <Divider />
                                
                                {this.state.users.length === 0 
                                    ? 
                                        <Message
                                            className="negative"
                                            style={{ textAlign: 'left' }}
                                        >
                                            <FormattedMessage id="settings.transfer ownership no users message" />
                                        </Message>
                                    :
                                        <OptionLabel
                                            icon="sn-account_circle"
                                            label={intl.formatMessage({ id: 'settings.transfer ownership select label' })}
                                        >
                                            <FormSelect
                                                fluid
                                                placeholder={intl.formatMessage({ id: 'settings.transfer ownership select user placeholder' })}
                                                options={this.state.users}
                                                value={this.state.selectedUser}
                                                onChange={(e, { value }) => {
                                                    this.setState({
                                                        selectedUser: value
                                                    });
                                                }}
                                            />
                                        </OptionLabel>
                                }

                                <Message size="small" className="info" style={{ textAlign: 'left' }}>
                                    <FormattedMessage id="settings.transfer ownership message description" />
                                </Message>

                                {this.props.transferSiteError && 
                                    <Message negative style={{ marginTop: 16, textAlign: 'left' }}>
                                        <Icon name="sn-warning" /> <FormattedMessage id={this.props.transferSiteError} />
                                    </Message>
                                }

                                <Divider />

                                <Button
                                    fluid
                                    type="submit"
                                    onClick={this.transferSite}
                                    size="huge" 
                                    disabled={this.state.users.length === 0}
                                    className="warning" 
                                >
                                    {/* <Icon name="sn-account_box" /> */}
                                    <FormattedMessage id="settings.transfer ownership button" />
                                </Button>
                            </Section>
                        </Modal>
                    }
                    {/* <Modal
                        // closeOnDimmer
                        dimmer="inverted"
                        closeIcon
                        title={intl.formatMessage({ id: 'settings.transfer ownership title' })}
                        size="tiny"
                        // trigger={
                        //     <Button size="big" className="warning">
                        //         {/* <Icon name="sn-account_box" /> 
                        //         <FormattedMessage id="settings.transfer ownership button" />
                        //     </Button>
                        // }
                    >                        
                    </Modal> */}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(injectIntl(BeforeActivation));