import React, { PureComponent } from 'react';
import {
	Form,
	IconGroup,
	Icon,
	Button,
	Message,
	Grid,
	GridRow,
	GridColumn,
	Divider,
	Dimmer,
	Loader,
	Label,
	Accordion,
	AccordionTitle,
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import OptionLabel from 'components/option-library/label';
import css from 'components/editor-v2/editor-interface/options/options.scss';

import AdaptedCardSelector from 'components/forms/__util/field-components/AdaptedCardSelector';
import AdaptedFilePicker from 'components/forms/__util/field-components/AdaptedFilePicker';
import textarea from 'components/forms/__util/field-components/textarea';
import input from 'components/forms/__util/field-components/input';
import checkbox from 'components/forms/__util/field-components/checkbox';
import datepicker from 'components/forms/__util/field-components/datepicker';
import dropdown from 'components/forms/__util/field-components/dropdown';
import Alert from 'components/Alert';
import MultipleSelect from 'components/forms/__util/field-components/multipleSelect';

import validate, { string, valid } from 'components/forms/__util/validate';
import urlConverter from 'components/forms/__util/validate/urlConverter';
import { filesSelector } from 'store/selectors/sites/files';
import { connect } from 'react-redux';
import layoutsSelector from 'store/selectors/sites/layouts';

import key from 'utils/key';
 
// import { blogsSelector } from 'store/selectors/sites/blogs';

const createTemplateCards = intl => [
	{
		id: 'empty-fallback',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template empty' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/fallback-template.png'
	},
	{
		id: 'standard-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template standard hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/standard-hero-template.png'
	},
	{
		id: 'background-image-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template background image hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/background-image-hero-template.png'
	},
	{
		id: 'banner-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template banner' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/banner-template.png'
	},
	{
		id: 'image-frame-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template image frame' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/image-frame-template.png'
	},
	{
		id: 'video-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template video hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/video-hero-template.png'
	},
	{
		id: '3-videos-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three videos' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-videos-template.png'
	},
	{
		id: '1-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template one column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/1-column-text-template.png'
	},
	{
		id: '2-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template two column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/2-column-text-template.png'
	},
	{
		id: '3-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-column-text-template.png'
	},
	{
		id: '4-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template four column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/4-column-text-template.png'
	},
	{
		id: 'left-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-infobox-template.png'
	},
	{
		id: 'right-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-infobox-template.png'
	},
	{
		id: 'broad-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template broad infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/broad-infobox-template.png'
	},
	{
		id: 'left-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-image-template.png'
	},
	{
		id: 'right-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-image-template.png'
	},
	{
		id: '3-round-features-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three round features' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-round-features-template.png'
	},
	{
		id: 'blog-list-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template blog list' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/blog-list-template.png'
	}
];

const fields = {
	title: {
		component: input({
			placeholder: 'blog.post title placeholder',
			// name: 'blog.post title label',
			icon: 'sn-magazine',
			type: 'text',
		}),
		validate: string(1, 70)
	},
	layout: {
		component: dropdown({
			placeholder: 'blog.post layout placeholder',
			name: 'blog.post layout label',
			icon: 'sn-web'
		}),
		validate: valid //string(1, 255)
	},
	publishAt: {
		component: datepicker,
		validate: valid
	},
	author: {
		component: dropdown({
			placeholder: 'blog.post author placeholder',
			name: 'blog.post author label',
			icon: 'user'
		}),
		validate: valid
	},
	keywords: {
		component: MultipleSelect,
		validate: valid
	},
	tags: {
		component: MultipleSelect,
		validate: valid
	},
	description: {
		component: textarea({
			placeholder: 'blog.post description placeholder',
			name: 'blog.post description label'
		}),
		validate: string(0, 160)
	},
	thumbnail: {
		component: AdaptedFilePicker,
		validate: valid
	},
	blogTemplate: {
		validate: valid
	}
};

const mapStateToProps = (state, ownProps) => {
	const siteID = state.sites.params.siteid;

	const layouts = layoutsSelector(state, siteID, 'blog');
	const defaultLayout = layouts.find(layout => layout.type === 'blog' && layout.default);
	const site = state.sites.sites[siteID];
	const users = site.users.map(user => {
		return {
			text: `${user.firstName} ${user.lastName}`,
			value: user._id
		};
	}).concat({
		text: site.name,
		value: '__siteIsAuthor__'
	});

	/* 
		SUI dropdowns are funky as hell and can't have null values, so we cast null values to a string ("__siteIsAuthor__")

		This means we also have to convert it *back* into the same string when the value is provided in update forms

		TO DO: find a clean solution to this mess
	*/
	const initialAuthor = key('initialValues.author')(ownProps) === null 
		? 
			'__siteIsAuthor__' 
		: 
			key('initialValues.author')(ownProps) || state.user._id;

	const isPublishedBlog = ownProps.update && state.sites.blogs.blogContent[ownProps.blog].live;

	return {
		siteID,
		files: filesSelector(state, siteID),
		layouts,
		subscription: key(`sites.sites.${siteID}.subscription.type`)(state),
		isPublishedBlog,
		blogTitleValue: state.form && state.form.blog && state.form.blog.values && state.form.blog.values.title || '', // we are interested in this value because we have to transform it on the fly
		blogTemplateValue: state.form && state.form.blog && state.form.blog.values && state.form.blog.values.blogTemplate || '',
		initialValues: {
			author: state.user._id,
			layout: defaultLayout._id,
			keywords: [],
			tags: [],
			publishAt: null,
			thumbnail: null,
			blogTemplate: 'empty-fallback', /*{
				blank: {
					id: 'blank'
				}
			},*/
			...ownProps.initialValues,
			author: initialAuthor,
			description: (ownProps.initialValues && ownProps.initialValues.description) || '',
		},
		users
	};
};

class BlogForm extends PureComponent {
	constructor() {
		super();

		this.state = {
			showOptionalFields: false,
			confirmDelete: false,
			titleInputFocused: false
		};

		this.submit = this.submit.bind(this);
		this.toggleOptionalFields = this.toggleOptionalFields.bind(this);
	}
	
	setTitleInputFocused = () => {
		if (this.props.update) {
			this.setState({
				titleInputFocused: true
			});
		}
	}

	async submit(values) {
		const relevantFields = {
			...fields
		};

		const modifiedValues = {
			...values
		};

		if (!this.props.update) {
			// modifiedValues.blogTemplate = values.blogTemplate[Object.keys(values.blogTemplate)[0]].id;
		} else {
			delete relevantFields.blogTemplate;
			delete modifiedValues.blogTemplate;
		}

		validate(relevantFields)(modifiedValues);

		if (modifiedValues.author === '__siteIsAuthor__') {
			modifiedValues.author = null;
		}

		await this.props.onSubmit(modifiedValues);
	}

	toggleOptionalFields() {
		this.setState({
			showOptionalFields: !this.state.showOptionalFields
		});
	}

	renderButtons() {
		const { update, handleSubmit, intl } = this.props;

		if (!update) {
			return (
				<Grid>
					<GridColumn mobile={8} textAlign="left">
						<Button
							positive
							onClick={handleSubmit(this.submit)}
						>
							<Icon name="plus" style={{ marginRight: 8 }} />
							<FormattedMessage id="blog.create post button" />
						</Button>
					</GridColumn>
					<GridColumn mobile={8} textAlign="right">
						<Button
							basic
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<Icon name="sn-cross2" style={{ marginRight: 8 }} /> <FormattedMessage id="general.cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle">
				<GridColumn mobile={16} computer={5} textAlign="left">
					<Button
						positive
						onClick={handleSubmit(this.submit)}
					>
						<Icon name="sn-cloud-upload" /> <FormattedMessage id="settings.save changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={6} textAlign="center">
					<Button
						basic
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
					>
						<Icon name="sn-cross2" /> <FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>
				<GridColumn mobile={8} computer={5} textAlign="right">
					<Button
						basic
						negative
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'blog.delete post alert title' })}
							text={intl.formatMessage({ id: 'blog.delete post alert description' })}
							confirmTitle={intl.formatMessage({ id: 'blog.delete post alert confirm' })}
							confirmIcon="trash"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						<Icon name="sn-bin" /> <FormattedMessage id="blog.delete post button" /> <Icon name="sn-file-text" style={{ marginLeft: 4 }} />
					</Button>
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const { intl } = this.props;
		const { showOptionalFields } = this.state;
		const cards = createTemplateCards(intl);
		const selectedCard = cards.find(card => card.id === this.props.blogTemplateValue);

		return (
			<Form>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}>
							<Icon name="sn-file-text" /> 
							<FormattedMessage id="general.saving changes" />
						</Loader>
					</Dimmer>
				}

				{!this.props.update &&
					<React.Fragment>
						<Divider horizontal>
							<Icon circular name="sn-grid8" style={{ opacity: 0.6, fontSize: 24 }} />

							<p style={{ marginTop: 4, marginBottom: 16 }}>
								<FormattedMessage id="blog.post starter template label" />
								<InfoIcon description={intl.formatMessage({ id: 'blog.post starter template description' })} />
							</p>
						</Divider>

						<div
							className="section-shade"
							style={{
								padding: '12px 16px',
								maxHeight: 220,
								overflowY: 'auto',
								overflowX: 'hidden',
								border: '1px solid #ccc'
							}}
						>
							<Field
								key="starter-post-template"
								name="blogTemplate"
								itemsPerRow={5}
								cards={cards}
								component={AdaptedCardSelector}
							/>
						</div>

						{selectedCard && 
							<Message className="primary" style={{ marginTop: 8, padding: 8, textAlign: 'center' }}>
								<span style={{ marginRight: 8 }}>
									<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
									<FormattedMessage id="general.you have chosen" />
								</span>
								<Label className="primary">
									<Icon name="sn-magazine" style={{ marginRight: 6 }} />
									{selectedCard.name}
								</Label>
							</Message>
						}
					</React.Fragment>
				}

				<Divider horizontal>
					<Icon circular name="sn-magazine" className="primary-color" />
				</Divider>
				
				<Grid
					padded
					className={css['option-frame-outline']}
					style={{ padding: '24px 16px' }}
				>
					<GridRow
						verticalAlign="top"
						style={{ padding: 0 }}
					>
						<GridColumn
							computer={4}
							style={{ textAlign: 'center', padding: '32px 16px 44px', margin: 0 }}
						>
							{/* new post + post settings icon */}
							<IconGroup style={{ marginBottom: 4 }}>
								<Icon
									name="sn-magazine"
									style={{
										opacity: 0.4,
										fontSize: 64
									}}
								/>
								<Icon
									corner
									name={!this.props.update ? "add" : "setting"}
									className={!this.props.update ? "positive-color" : "info-color"}
									style={{
										fontSize: 44,
										marginRight: -16,
										marginBottom: -20
									}}
								/>
							</IconGroup>
						</GridColumn>
						<GridColumn computer={12}>
							{/* post title */}
							<OptionLabel
								label={intl.formatMessage({ id: 'blog.post title label' })}
								labelIcon="sn-bookmark2"
								labelDescription={intl.formatMessage({ id: 'blog.post title description' })}
							>
								{/* post title input */}
								<Field
									fluid
									size="huge"
									name="title"
									placeholder={intl.formatMessage({ id: 'blog.post title placeholder' })}
									icon="sn-magazine"
									iconPosition="left"
									intl={intl}
									component={fields.title.component}
								/>
							</OptionLabel>
							
							<Message style={{ fontSize: 14, padding: '8px 18px', textAlign: 'left' }}>
								<span style={{ verticalAlign: 'middle' }}>
									<Label basic className="positive" style={{ padding: 6, margin: 0, marginRight: 4, verticalAlign: 'middle' }}>
										<span><Icon name="sn-lock5" style={{ margin: 0 }} /> https://</span>
									</Label>
									<Label basic className="warning" style={{ padding: 6, margin: 0, marginRight: 4, verticalAlign: 'middle' }}>
										blog.
									</Label>
									<Label basic className="info" style={{ padding: 6, margin: 0, verticalAlign: 'middle' }}>
										<FormattedMessage id="general.domain-name label" />
									</Label> /
								</span>
								<span>
									{this.props.blogTitleValue === ""
										?
											<span>...</span>
										:
											urlConverter(this.props.blogTitleValue)
									}/
									<InfoIcon
										description={intl.formatMessage({ id: 'pages.post navigation link description' })}
										position="bottom center"
									/>
								</span>
							</Message>
						</GridColumn>
					</GridRow>

					<GridRow style={{ padding: 0 }}>
						<GridColumn computer={16}>
							<Divider horizontal>
								<Icon circular name="setting" style={{ opacity: 0.3 }} />
							</Divider>
						</GridColumn>
					</GridRow>

					<GridRow style={{ padding: 0 }}>
						<GridColumn computer={5}>
							<OptionLabel
								collapsed
								label={intl.formatMessage({ id: 'blog.post publication date label' })}
								labelIcon="sn-calendar4"
								labelDescription={intl.formatMessage({ id: 'blog.post publication date description' })}
							>
								<Field
									fluid
									size="big"
									name="publishAt"
									icon="sn-calendar4"
									iconPosition="left"
									placeholder={intl.formatMessage({ id: 'blog.post publication date placeholder' })}
									component={fields.publishAt.component}
								/>
							</OptionLabel>
						</GridColumn>
						<GridColumn computer={6}>
							{/* post categories (tags) */}
							<OptionLabel
								collapsed
								label={intl.formatMessage({ id: 'blog.post categories label' })}
								labelIcon="sn-price-tags"
								labelDescription={intl.formatMessage({ id: 'blog.post categories description' })}
							>
								<Field
									component={fields.tags.component}
									name="tags"
									intl={intl}
									placeholder={intl.formatMessage({ id: 'blog.post categories placeholder' })}
									options={this.props.tags}
									noResultsMessage={intl.formatMessage({ id: 'blog.no post categories message' })}
								/>
							</OptionLabel>
						</GridColumn>
						<GridColumn computer={5}>
							{/* post layout select */}
							<OptionLabel
								collapsed
								label={intl.formatMessage({ id: 'blog.post layout label' })}
								labelIcon="sn-web"
								labelDescription={intl.formatMessage({ id: 'pages.page parent description' })}
							>
								<Field
									name="layout"
									placeholder={intl.formatMessage({ id: 'blog.post layout label' })}
									values={this.props.layouts.map(layout => ({
										text: layout.title,
										value: layout._id
									}))}
									intl={intl}
									component={fields.layout.component}
								/>
							</OptionLabel>
						</GridColumn>
					</GridRow>
				</Grid>

				<Divider horizontal>
					<Icon circular name="sn-plus2" style={{ opacity: 0.3 }} />
				</Divider>
				
				{/* more options panel */}
				<Grid padded className="section-shade">
					<GridRow>
						<GridColumn computer={16}>

							{/* show more options accordion */}
							<Accordion
								style={{ marginTop: 0 }}
								onClick={this.toggleOptionalFields}
								>
								<AccordionTitle style={{ textAlign: 'left', verticalAlign: 'middle' }}>
									<Icon
										name={!showOptionalFields ? 'sn-plus-circle' : 'sn-minus-circle2'}
										className="primary"
										style={{ fontSize: 24, marginRight: 8, verticalAlign: 'middle' }}
										/>
									<span style={{ fontSize: 16, verticalAlign: 'middle' }}>
										<FormattedMessage id={`general.${!showOptionalFields ? 'show' : 'hide'} options`} />
									</span>
								</AccordionTitle>
							</Accordion>
						</GridColumn>
					</GridRow>
					
					{/* more options panel */}
					{showOptionalFields &&
						<React.Fragment>
							<GridRow style={{ padding: 0 }}>
								<GridColumn computer={16}>
									<Divider style={{ marginTop: 0 }} />
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn mobile={16} tablet={6} computer={6}>
									<Field
										name="thumbnail"
										fallBackImgSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg"
										selectionTitle="blog.post thumbnail selection title"
										selectionDescription="blog.post thumbnail selection description"
										buttonInfo="blog.post thumbnail button info"
										clearSelectionDescription="blog.post thumbnail clear selection description"
										intl={intl}
										component={fields.thumbnail.component}
										siteid={this.props.siteID}
										files={this.props.files}
									/>
								</GridColumn>

								<GridColumn mobile={16} tablet={10} computer={10} textAlign="left">
									
									{/* post description textarea */}
									<OptionLabel
										label={intl.formatMessage({ id: 'blog.post description label' })}
										labelIcon="sn-quill2"
										labelDescription={intl.formatMessage({ id: 'blog.post summary description' })}
									>
										<Field
											name="description"
											intl={intl}
											component={fields.description.component}
											// infoIcon={intl.formatMessage({ id: 'blog.post summary description' })}
										/>
									</OptionLabel>

									{/* post meta keywords multiple select */}
									<OptionLabel
										label={intl.formatMessage({ id: 'blog.post keyword label' })}
										labelIcon="sn-bubble-link"
										labelDescription={intl.formatMessage({ id: 'blog.post keyword description' })}
									>
										<Field
											component={fields.keywords.component}
											name="keywords"
											intl={intl}
											// labelText={intl.formatMessage({ id: 'blog.post keyword label' })}
											// labelDescription={intl.formatMessage({ id: 'blog.post keyword description' })}
											// options={this.props.keywords}
											placeholder={intl.formatMessage({ id: 'blog.post keyword placeholder' })}
											noResultsMessage={intl.formatMessage({ id: 'blog.no post keyword message' })}
										/>
									</OptionLabel>

									{/* post author select */}
									<OptionLabel
										collapsed
										label={intl.formatMessage({ id: 'blog.post author label' })}
										labelIcon="sn-user3"
										labelDescription={intl.formatMessage({ id: 'blog.post author description' })}
									>
										<Field
											name="author"
											intl={intl}
											format={null}
											values={this.props.users}
											component={fields.author.component}
											// infoIcon={intl.formatMessage({ id: 'blog.post author description' })}
										/>
									</OptionLabel>
								</GridColumn>
							</GridRow>
						</React.Fragment>
					}
				</Grid>

				{this.props.error &&
					<Message negative style={{ marginTop: 16 }}>
						<Icon name="sn-warning" /> 
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				{this.renderButtons()}
			</Form>
		);
	}
}

const ReduxBlogForm = reduxForm({
	form: 'blog'
})(injectIntl(BlogForm));

ReduxBlogForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(ReduxBlogForm);
