import React from 'react';
import Radium from 'radium';

export default Radium(class RadiumButton extends React.Component {
    render() {
        return <button {...this.props}>{this.props.children}</button>;
    }
});

// export default radium(props => {
//     // console.log(props, 'props');

//     return <button {...props}>{props.children}</button>;
// });