import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import notification from 'components/hoc/notification';
import { storageCapacity as capacitySelector, storageLeft, filesInActiveFolder } from 'store/selectors/sites/files';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;

	const files = filesInActiveFolder(state);
	const storageCapacity = capacitySelector(state);

	return {
		siteid,
		filetypes: state.system.filetypes,
		view: state.sites.files.view,
		foldersLoading: state.sites.files.foldersLoading,
		files,
		storageLeft: storageLeft(state, storageCapacity)
	};
};

const BlobsHOC = Component => {
    const ComponentWithBlobs = props => {
        const [blobs, setBlobs] = useState([]);
        const [unsupportedFile, setUnsupportedFile] = useState(false);

        const addBlobs = useCallback(blobsToAdd => {
            const urlCreator = window.URL || window.webkitURL;

            const newBlobs = [];
            let unsupportedFile = false;
            let bytesAvailable = props.storageLeft;
            
            for (let i = 0; i < blobsToAdd.length; i++) {
                if (!props.filetypes.some(filetype => filetype === blobsToAdd[i].name.split('.').pop().toLowerCase())) { // Probably better to look at mimetype, but whatever for now
                    unsupportedFile = true;
        
                    props.createNotification({
                        content: props.intl.formatMessage({ id: 'files.file type not supported error notification' }),
                        className: 'error',
                        position: 'topCenter',
                        icon: 'sn-file-minus2',
                        deleteTime: 3000
                    });
        
                    continue;
                }
        
                bytesAvailable = bytesAvailable - blobsToAdd[i].size;
        
                if (bytesAvailable < 0) {
                    props.createNotification({
                        content: props.intl.formatMessage({ id: 'files.uploading this file would cause you to exceed your storage capacity' }),
                        className: 'error',
                        position: 'topCenter',
                        icon: 'sn-file-minus2',
                        deleteTime: 3000
                    });
        
                    continue;
                }
        
                const name = blobsToAdd[i].name;
                const imageUrl = urlCreator.createObjectURL(blobsToAdd[i]);
        
                newBlobs.push({
                    url: imageUrl,
                    meta: {
                        name: name.substring(0, name.lastIndexOf('.')),
                    },
                    blob: blobsToAdd[i]
                });
            }

            setBlobs(blobs.concat(newBlobs));
            setUnsupportedFile(unsupportedFile);
        }, [blobs]);

        const updateBlob = useCallback((blob, index) => {
            let nextBlobs;

            if (blob === null) {
                nextBlobs = blobs.slice(0, index).concat(blobs.slice(index + 1));
            } else {
                nextBlobs = [...blobs];
        
                nextBlobs[index] = blob;
            }
        
            setBlobs(nextBlobs);
        }, [blobs]);

        const clearBlobs = useCallback(() => {
            setBlobs([]);
        }, []);

        return (
            <Component 
                {...props} 
                blobProps={{
                    blobs,
                    unsupportedFile,
                    addBlobs,
                    updateBlob,
                    clearBlobs
                }} 
            />
        );
    };

    return injectIntl(notification(connect(mapStateToProps)(ComponentWithBlobs)));
};

export default BlobsHOC;

// this.addBlobs = blobs => {
//     const urlCreator = window.URL || window.webkitURL;

//     const newBlobs = [];
//     let unsupportedFile = false;
//     let bytesAvailable = this.props.storageLeft;
    
//     for (let i = 0; i < blobs.length; i++) {
//         if (!this.props.filetypes.some(filetype => filetype === blobs[i].name.split('.').pop().toLowerCase())) { // Probably better to look at mimetype, but whatever for now
//             unsupportedFile = true;

//             this.props.createNotification({
//                 content: this.props.intl.formatMessage({ id: 'files.file type not supported error notification' }),
//                 className: 'error',
//                 position: 'topCenter',
//                 icon: 'sn-file-minus2',
//                 deleteTime: 3000
//             });

//             continue;
//         }

//         bytesAvailable = bytesAvailable - blobs[i].size;

//         if (bytesAvailable < 0) {
//             this.props.createNotification({
//                 content: this.props.intl.formatMessage({ id: 'files.uploading this file would cause you to exceed your storage capacity' }),
//                 className: 'error',
//                 position: 'topCenter',
//                 icon: 'sn-file-minus2',
//                 deleteTime: 3000
//             });

//             continue;
//         }

//         const name = blobs[i].name;
//         const imageUrl = urlCreator.createObjectURL(blobs[i]);

//         newBlobs.push({
//             url: imageUrl,
//             meta: {
//                 name: name.substring(0, name.lastIndexOf('.')),
//             },
//             blob: blobs[i]
//         });
//     }

//     this.setState({
//         blobs: this.state.blobs.concat(newBlobs),
//         unsupportedFile
//     });
// };

// this.updateBlob = (blob, index) => {
//     let blobs = this.state.blobs;

//     if (blob === null) {
//         blobs = blobs.slice(0, index).concat(blobs.slice(index + 1));
//     } else {
//         blobs = [...blobs];

//         blobs[index] = blob;
//     }

//     this.setState({
//         blobs
//     });
// };
// this.clearBlobs = () => this.setState({ blobs: [], unsupportedFile: false });