import constants from '../constants';

const getDefaultState = () => ({
	step: 0,
	subscriptionTypes: [],
	selectedSubscription: null,
	orgDetails: {},
	agreeTerms: false
});

const defaultState = getDefaultState();

export default (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_WIZARD_STEP:
			return Object.assign({}, state, {
				step: action.data,
			});

		case constants.SET_SUBSCRIPTION_TYPES:
			return Object.assign({}, state, {
				subscriptionTypes: action.data,
				selectedSubscription: action.data.find(subscription => subscription.id === 'base-subscription-pro-dkk').id
			});

		case constants.SET_SUBSCRIPTION:
			return Object.assign({}, state, {
				selectedSubscription: action.data,
			});


		case constants.TOGGLE_AGREE_TERMS:
			return {
				...state,
				agreeTerms: !state.agreeTerms
			};

		case constants.SET_COMPANY_INPUT_TEXT:
			return {
				...state,
				orgDetails: {
					...state.orgDetails,
					[action.data.field]: action.data.value
				}
			};

		default:
			return state;
	}
};
