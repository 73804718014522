exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".grid__grid--1YSgW{display:flex;flex-wrap:wrap}.grid__collapsing-margin-fix--3reNw{clear:both;content:\"\";display:table;background:transparent;margin-top:-1px;height:0}\n", ""]);

// exports
exports.locals = {
	"grid": "grid__grid--1YSgW",
	"collapsing-margin-fix": "grid__collapsing-margin-fix--3reNw"
};