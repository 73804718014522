import React from 'react';
import Radium from 'radium';
import SmartImage from 'svift-library/client/image';
import css from './feed.scss';

const RadiumTitle = Radium(
    class Title extends React.PureComponent {
        render() {
            return (
                <a
                    {...this.props.titleProps}
                    className={css['feed-link']}
                    /*style={this.props.style} onClick={this.props.onClick}*/
                >
                    {this.props.children}
                </a>
            );
        }
    }
);

const RadiumLink = Radium(
    class Link extends React.PureComponent {
        render() {
            return (
                <a
                    {...this.props.linkProps}
                    className={css['feed-link']}
                    /*style={this.props.style} onClick={this.props.onClick}*/
                >
                    {this.props.children}
                </a>
            );
        }
    }
);

const containerStyle = {
    paddingTop: 15,
    display: 'flex',
    alignItems: 'flex-start',
    borderTop: '1px solid rgba(0, 0, 0, 0.10)'
};

const textContainerStyle = {
    flexGrow: 1
};

export default props => {
    const {
        thumbnailStyle,
        titleStyle,
        descriptionStyle,
        authorStyle,
        publicationDateStyle,
        author,
        thumbnail,
        publishAt,
        description,
        isLastItem,
        theme,
        linkStyle,
        readMoreText,
        computedLink,
        onNavigate
    } = props;

    const style = {
        ...containerStyle
    };

    if (!isLastItem) {
        style.marginBottom = theme.measurements.margin;
    }

    const linkProps = computedLink && {
        title: computedLink.title,
        href: computedLink.url,
        onClick: event => {
            if (event.ctrlKey || event.metaKey || event.shiftKey) {
                return;
            }

            event.preventDefault();

            onNavigate(computedLink); // this.props, link);
        },
        ...computedLink.anchorAttributes
    };

    // console.log(description, thumbnail, 'desc/thumb feed item')

    // console.log()

    return (
        <div style={style}>
            {/* item thumbnail */}
            {thumbnailStyle && (
                <SmartImage
                    {...linkProps}
                    addedUrlOptions={['c']}
                    file={thumbnail}
                    style={{
                        ...thumbnailStyle,
                        width: '33%',
                        flexShrink: 0,
                        cursor: 'pointer'
                    }}
                />
            )}

            {/* item content container */}
            <div style={textContainerStyle}>
                {/* item title */}
                {titleStyle &&
                    <RadiumTitle
                        // onClick={onClick}
                        // title={title}
                        titleProps={{
                            ...linkProps,
                            style: titleStyle
                        }}
                    >
                        {linkProps ? linkProps.title : props.title}
                    </RadiumTitle>
                }

                {/* item publication date */}
                {publicationDateStyle && (
                    <div style={publicationDateStyle}>{publishAt}</div>
                )}

                {/* item description */}
                {descriptionStyle && (
                    <div style={descriptionStyle}>{description} (...)</div>
                )}

                {/* item author */}
                {authorStyle && <div style={authorStyle}>- {author}</div>}

                {/* read more link */}
                {readMoreText && (
                    <div>
                        <RadiumLink
                            linkProps={{
                                ...linkProps,
                                style: linkStyle
                            }}
                        >
                            {readMoreText}
                        </RadiumLink>
                    </div>
                )}
            </div>
        </div>
    );
};
