import React, { Component } from 'react';
import { MenuItem, MenuMenu } from 'svift-ui';
import actions from 'store/actions';
import { connect } from 'react-redux';
import ShortcutSectionItem from './ShortcutSectionItem';
import { matchPath } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import key from 'utils/key';

const mapStateToProps = (state) => {
	// const { sites } = state.sites;
	// const siteid = state.sites.params.siteid;
	const siteID = state.sites.params.siteid;
	const site = state.sites.sites[siteID];

	// const site = state.sites.siteList.find(({ _id }) => _id === siteid);

	return {
		siteid: siteID,
		roles: state.user.roles,
		themeid: state.sites.themes.params.themeid,
		frontPage: site.frontPage,
		liveTheme: site.activeTheme,
		pathname: state.router.location.pathname,
		blogActive: key('modules.blog')(site)
	};
};

const mapDispatchToProps = dispatch => ({
	toggleSideMenu: bool => dispatch(actions.sideMenu.toggleSideMenu(bool))
});

class ShortcutSection extends Component {
	getRoleOnSite({ roles, siteid }) {
		const siteScope = `sites/${siteid}`;

		let role = roles.find(({ scope }) => {
			return scope === siteScope;
		});

		if (!role) {
			role = roles.find(({ type }) => type === 'superadmin');
		}

		return role.type;
	}

	render() {
		const {
			siteid,
			liveTheme,
			themeid,
			frontPage,
			changeRoute,
			pathname,
			intl,
			roles
		} = this.props;

		const roleOnSite = this.getRoleOnSite({ roles, siteid });

		return (
			<MenuMenu id="shortcut-section">
				<MenuItem>
					{['siteWriter', 'siteOwner', 'siteAdmin', 'siteDesigner', 'superadmin'].indexOf(roleOnSite) > -1 &&
						<ShortcutSectionItem
							match={matchPath(pathname, '/dashboard/:siteid/pages')}
							changeRoute={changeRoute}
							icon="sn-stack-text"
							name={intl.formatMessage({ id: 'pages.app title' })}
							path={`/dashboard/${siteid}/pages/${frontPage}`}
						/>
					}

					{this.props.blogActive && 
						<ShortcutSectionItem
							match={matchPath(pathname, '/dashboard/:siteid/blog-posts')}
							changeRoute={changeRoute}
							icon="sn-blog"
							name={intl.formatMessage({ id: 'blog.app title' })}
							path={`/dashboard/${siteid}/blog-posts`}
						/>
					}

					{['siteOwner', 'siteAdmin', 'siteDesigner', 'superadmin'].indexOf(roleOnSite) > -1 &&
						<ShortcutSectionItem
							match={matchPath(pathname, '/dashboard/:siteid/design')}
							changeRoute={changeRoute}
							icon="sn-bucket"
							name={intl.formatMessage({ id: 'design.app title' })}
							path={`/dashboard/${siteid}/design/${liveTheme}`}
						/>
					}

					<ShortcutSectionItem
						match={matchPath(pathname, '/dashboard/:siteid/files')}
						changeRoute={changeRoute}
						icon="sn-folder6"
						name={intl.formatMessage({ id: 'files.app title' })}
						path={`/dashboard/${siteid}/files`}
					/>

					<ShortcutSectionItem
						match={matchPath(pathname, '/dashboard/:siteid/settings')}
						changeRoute={changeRoute}
						icon="sn-cog3"
						name={intl.formatMessage({ id: 'settings.app title' })}
						path={`/dashboard/${siteid}/settings`}
					/>
				</MenuItem>
			</MenuMenu>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShortcutSection));