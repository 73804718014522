import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { IconGroup, Icon, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import ColorPicker from 'components/option-panel/options/ColorPicker';

class BodyPresets extends PureComponent {
	constructor() {
		super();

		this.getPresetOptions = style => [
			<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-web_asset" /><FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} /></span>,
			<Padding
				key="padding"
				value={this.props.preset.padding}
				preset={this.props.preset}
				theme={this.props.theme}
				onChange={(key, newValue) => {
					if (key === 'generalPadding') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								paddingBottom: 1
							});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								generalPadding: newValue / 100
							});
						}

						return;
					}

					const newPadding = {
						...this.props.preset.padding,
						[key]: newValue / 100
					};

					if (newValue === null) {
						delete newPadding[key];
					}

					delete newPadding.generalPadding;

					if (typeof newPadding.paddingBottom === 'undefined') {
						newPadding.paddingBottom = 1;
					}

					this.props.updatePresetOption(this.props.presetTitle, 'padding', newPadding);
				}}

				option="Padding"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}

				optIn={{
					paddingBottom: false,
					paddingTop: true,
					paddingLeft: true,
					paddingRight: true,
					generalPadding: true
				}}
			/>,
			<ColorPicker
				title={this.props.intl.formatMessage({ id: 'options.background color title' })}
				key="color-picker-background"
				icons={['sn-paint-format']}
				colors={this.props.theme.colors}
				onChange={(newColor) => this.props.updatePresetOption(this.props.presetTitle, 'backgroundColor', newColor)}
				value={this.props.preset.backgroundColor}

				option="color-picker-background"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>
		];

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const { intl } = this.props;
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const computedStyles = computePreset(this.props.preset, this.props.theme);
		// console.log(computedStyles)
		return (
			<div style={{ paddingBottom: 16, marginBottom: 24, borderBottom: '1px dashed lightgrey' }}>
				<OptionPanel
					icon="sn-bucket"
					title="elements.row title"
					target={this.optionTooltipRef.current}
					open={selected}
					options={this.getPresetOptions(computedStyles)}
				/>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
					ref={this.optionTooltipRef}
				>
					<Popup
						trigger={
							<IconGroup style={{ position: 'absolute', top: 32, left: 12 }}>
								<Icon name="sn-web_asset" className="primary" style={{ fontSize: 72, margin: 0 }} />
								<Icon corner name="sn-info" className="primary" style={{ fontSize: 32, bottom: 0, right: -7, borderRadius: '50%', background: 'white' }} />
							</IconGroup>
						}
						content={intl.formatMessage({ id: 'elements.body dummy content' })}
						position="bottom left"
					/>

					<div className="sub-target" style={Object.assign({ width: '100%', height: 400 }, computedStyles)}>
						<div style={{ height: '100%', textAlign: 'center', border: '1px dashed grey', margin: 'auto', background: 'transparent' }}>
							<Popup
								trigger={
									<IconGroup style={{ opacity: 0.1, margin: '0 0 40px 0' }}>
										<Icon name="sn-enlarge" style={{ fontSize: 96, margin: 0 }} />
										<Icon corner name="sn-info" style={{ fontSize: 48, bottom: 24, right: 19, borderRadius: '50%', background: 'white' }} />
									</IconGroup>
								}
								content={intl.formatMessage({ id: 'elements.body dummy container description' })}
								position="bottom center"
							/>
						</div>
					</div>
				</PresetContainer>
			</div>
		);
	}
}

BodyPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(BodyPresets));