exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".file-picker__file-picker--15JRZ{width:80%}\n", ""]);

// exports
exports.locals = {
	"file-picker": "file-picker__file-picker--15JRZ"
};