import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
// import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import { withRouter } from 'react-router';
import ReCAPTCHA from 'svift-library/client/renderer/nodes/components/library/__components/recaptcha';
import PropTypes from 'prop-types';
import RadiumButton from './RadiumButton';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import css from './button.scss';
// import key from 'svift-library/key';

const getPadding = (nIncrement = 0) => {
    const textSize = 11 + 1.5 * nIncrement + 'px';
    const leftRight = 16.5 + 1.5 * nIncrement + 'px';
    const upDown = (16.5 + 1.5 * nIncrement) / 2 + 'px';

    return {
        fontSize: textSize,
        paddingLeft: leftRight,
        paddingRight: leftRight,
        paddingTop: upDown,
        paddingBottom: upDown
    };
};

const resolveButtonSize = size => {
    switch (size) {
        case 'mini': {
            return getPadding(0);
        }

        case 'tiny': {
            return getPadding(1);
        }

        case 'small': {
            return getPadding(2);
        }

        case 'large': {
            return getPadding(5);
        }

        case 'big': {
            return getPadding(7);
        }

        case 'huge': {
            return getPadding(9);
        }

        case 'massive': {
            return getPadding(11);
        }

        case 'medium':
        default: {
            return getPadding(3);
        }
    }
};

class Button extends PureComponent {
    static mapContextToProps = (props, state) => {
		const { pages, blogs, files, theme, extend, recaptchaRef, setRecaptchaToken } = props.context;
		const { onNavigate, computeLink } = extend.Button;

		return shallowCompare({
			recaptchaRef,
			setRecaptchaToken,
			pages,
			blogs,
			files,
			theme,
			onNavigate,
			computeLink
		}, state);
	}
	
	getStyling = () => {
		const { node, theme, computedStyling } = this.props;
		const style = {
			...computedStyling,
			// TO-DO: LET SIZE (PADDING) BE OVERRIDEN BY INLINE STYLING YES OR NO?
			...resolveButtonSize(node.props.size, theme),
			cursor: 'pointer'
		};

		if (node.props.fluid) style.width = '100%';
		
		return {
			buttonAlignment: {
				justifyContent: node.props.alignment || 'center'
			},
			style
		};
	}

	// computeLink = () => {
	// 	return this.props.node.props.link; // fix later
	// }

	renderButton = (style, link) => {
		const { node } = this.props;

		// console.log(style, 'style button');

		let buttonText = node.props.text || ''; 

		if (!node.props.link) style.pointerEvents = 'none';

		return (
			<RadiumButton
				// key={node.props.id}
				// compute link in admin too so disabled logic is more accurate
				disabled={!link} // can be confusing in admin because if button is disabled it looks like inline styling doesnt work
				style={style}
				onClick={event => {
					if (event.ctrlKey || event.metaKey || event.shiftKey) {
						return;
					}
					
					event.preventDefault();
		
					this.props.onNavigate(link);
				}}
			>
				{buttonText}
			</RadiumButton>
		);
	}

    renderFormButton = style => {
        const { node } = this.props;

        // const formButtonStyles = {
        //     ...style,
        //     cursor: 'pointer'
        // };

        const buttonText = node.props.text || 'Submit';

        // console.log(this.props, 'props form button');

        return (
            <RadiumButton type="submit" style={style}>
                {buttonText}
            </RadiumButton>
        );
    };

	// renderFormButton = style => {
	// 	const { node } = this.props;

	// 	const buttonText = node.props.text || 'Submit'; 

	// 	// console.log(this.props, 'props form button');

	// 	return (
	// 		<RadiumButton
	// 			style={style}
	// 		>
	// 			{buttonText}
	// 		</RadiumButton>
	// 	);
	// }

	render() {
		const { buttonAlignment, style } = this.getStyling();
		const computedLink = this.props.computeLink && this.props.computeLink(this.props);
		// const linkTitle = this.props.getTitle(this.props);
		const isFormButton = this.props.node.props.submitButton;
		const linkStyle = this.props.node.props.fluid ? { width: '100%' } : {};

		return (
			<div {...this.props.cropProps} className={css['button-wrapper']} style={buttonAlignment}>
				{isFormButton && 
					<ReCAPTCHA
						style={{ marginBottom: '10px' }}
						ref={this.props.recaptchaRef}
						badge="bottomleft"
						size="invisible"
						sitekey="6Lc713UUAAAAALJJPx0e3TADFvdoYngNt7HdejQY"
						onResolved={clientResponseToken => this.props.setRecaptchaToken(clientResponseToken)}
					/>
				}

				{isFormButton 
					?
						this.renderFormButton(style)
					:
						computedLink
							?
								<a href={computedLink.url} style={linkStyle} title={computedLink.title} {...computedLink.anchorAttributes}>
									{this.renderButton(style, computedLink)}
								</a>
							:
								this.renderButton(style)
				}
			</div>
		);
	}
}

Button.contextTypes = {
    formSubmit: PropTypes.func,
    setRecaptchaToken: PropTypes.func,
    recaptchaRef: PropTypes.object
};

const WrappedButton = withRouter(Button);

WrappedButton.mapContextToProps = Button.mapContextToProps;

export default WrappedButton;
