import React, { PureComponent } from 'react';
import createApi from 'api';
import { 
    Loader
} from 'svift-ui';
import Notification from 'components/hoc/notification';
import PasswordForm from 'components/forms/registration/partialForms/Password';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const api = createApi();

const mapStateToProps = state => ({
    email: state.user.email
});

class ChangePassword extends PureComponent {
    state = {};

    setNewPassword = async (values) => {
        this.setState({
            submitting: true
        });

        try {
            await api.changePassword({ oldPassword: values.password, newPassword: values.newPassword, email: this.props.email });

            this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'profile.password changed notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'sn-question3',
                deleteTime: 5000	
            });
        } catch(e) {
            // Just an extremely simple error in case server is dead
            alert(this.props.intl.formatMessage({ id: 'general.generic server error message' }));
        } finally {
            this.setState({
                submitting: false
            });
        }
    }

    render() {
        if (this.state.submitting) {
            return <Loader />;
        }

        return (
            <PasswordForm
                onSubmit={this.setNewPassword}
            />
        );
    }
}

export default injectIntl(connect(mapStateToProps)(Notification(ChangePassword)));