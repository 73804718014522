import React, { PureComponent } from 'react';
import { Icon, Message, Divider } from 'svift-ui';
// import Header from 'containers/dashboard/side-navigation/sidenavigation-content/__components/Header';
import BackButton from 'containers/dashboard/side-navigation/sidenavigation-content/__components/BackButton';
import ModalWrapper from 'components/modal';
import NavigationForm from 'components/forms/navigation';
import { connect } from 'react-redux';
import { themesSelector } from 'store/selectors/sites/themes';
import { FormattedMessage, injectIntl } from 'react-intl';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import navigationActions from 'store/actions/sites/navigation';
import navigationSelector from 'store/selectors/sites/navigation';
import { pagesSelector } from 'store/selectors/sites/pages';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	// const navid = state.sites.navigation.params.navid;
	const themes = themesSelector(state, siteid);
	// const pages = pagesSelector(state, siteid);
	// const navigations = navigationSelector(state, siteid, pages);
	// const { stagedNavigation } = state.sites.navigation;

	return {
		siteid,
		// stagedNavigation,
		defaultTheme: themes[state.sites.sites[siteid].activeTheme]._id,
		// isAutoNavigation: !!navid,
		// navigations
	};
};

// const mapDispatchToProps = dispatch => {
// 	return {
// 		createNavigation: (...args) => dispatch(navigationActions.createNavigation(...args))
// 	};
// };

class Navigation extends PureComponent {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		navigationModalOpen: false
	// 	};

	// 	this.createNavigation = this.createNavigation.bind(this);
	// }

	renderBackButton() {
		const { siteid, defaultTheme, intl } = this.props;

		return (
			<BackButton
				text={intl.formatMessage({ id: 'design.back to design' })}
				route={`/dashboard/${siteid}/design/${defaultTheme}`}
			/>
		);
	}

	// async createNavigation(values) {
	// 	const { siteid, navigations } = this.props;

	// 	this.setState({
	// 		loading: true
	// 	});

	// 	try {
	// 		await this.props.createNavigation(siteid, {
	// 			...values,
	// 			entries: this.props.isAutoNavigation ? this.props.stagedNavigation : navigations[0].entries
	// 		});

	// 		this.setState({
	// 			loading: false,
	// 			isAuto: false,
	// 			navigationModalOpen: false
	// 		});
	// 	} catch (e) {
	// 		this.setState({
	// 			loading: false
	// 		});

	// 		handleSubmitError(this.props.intl)(e);
	// 	}
	// }

	render() {
		const { intl } = this.props;

		return (
			<React.Fragment>
				{/* back to design overview */}
				{this.renderBackButton()}
			
				{/* navigation usage message */}
				<Message className="primary">
					<Icon		
						name="sn-info"
						style={{
							marginLeft: 'auto',
							marginRight: 'auto',
							marginBottom: 8,
							display: 'block',
							fontSize: 24
						}}
					/>
					<Divider style={{ marginTop: 0, marginBottom: 6 }} />
					<div style={{ marginBottom: 12 }}>
						<FormattedMessage id="menus.navigation usage message description" />
					</div>
				</Message>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, null)(injectIntl(Navigation));