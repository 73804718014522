import React, { PureComponent } from 'react';
import { DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Message, Header, Icon } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';

export default Component => {
	class EnhancedComponent extends PureComponent {
		constructor() {
			super();

			this.renderDropMessage = this.renderDropMessage.bind(this);
		}

		renderDropMessage() {
			if (this.props.canDrop) {
				return (
					<Message className="positive overlay-message">
						<Header as="h3" textAlign="center">
							<span><Icon name="plus" className="success" /> <FormattedMessage id="files.drop files to upload title" /></span>
						</Header>
					</Message>
				);
			}

			return null;
		}

		render() {
			return (
				<Component
					// connectDropTarget={this.props.connectDropTarget}
					// ref={this.props.connectDropTarget}
					// ref={ref => this.props.connectDropTarget(findDOMNode(ref))}
					renderDropMessage={this.renderDropMessage}
					{...this.props}
				/>
			);
		}
	}

	const targetSpec = {
		drop: (props, monitor, component) => {
			const item = monitor.getItem();

			component.props.addBlobs(item.files);
		}
	};

	return DropTarget(NativeTypes.FILE, targetSpec, (connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		canDrop: monitor.canDrop() && monitor.isOver()
	}))(injectIntl(EnhancedComponent));
};