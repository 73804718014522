export default type => {
    switch (type) {
        case 'gridZoneTopEscape': 
        case 'gridZoneTop': {
            return 'sn-grid-2';
        }
        case 'gridZoneBottomEscape': 
        case 'gridZoneBottom': {
            return 'sn-grid-2';
        }
        case 'rowZoneTopEscape': 
        case 'rowZoneTop': {
            return 'sn-columns';
        }
        case 'rowZoneBottomEscape': 
        case 'rowZoneBottom': {
            return 'sn-columns';
        }
        case 'rowZoneLeft':
        // case 'columnZoneLeftEscape':
        case 'columnZoneLeft': {
            return 'sn-marquee';
        }
        case 'rowZoneRight':
        // case 'columnZoneRightEscape':
        case 'columnZoneRight': {
            return 'sn-marquee';
        }
        case 'columnElementZoneTop': {
            return 'sn-marquee-plus';
        }
        case 'columnElementZoneBottom': {
            return 'sn-marquee-plus';
        }
        default: {
            return null;
        }
    }
};