import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import Section from '../Section';
import FilePicker from 'components/option-library/primitives/file';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import Checkbox from 'components/option-library/primitives/checkbox';
import { injectIntl } from 'react-intl';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    // const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.video}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label>
                    <FilePicker 
                        tabs={['files', 'youtube']}
                        filter={file => file.meta.mimeType.startsWith('video')}
                        onChange={file => {
                            onChange('src', file, 'Set video', false);
                        }}
                        value={node.props.src}
                    />
                </Label>
                
                <Label
                    labelIcon="sn-play"
                    label={intl.formatMessage({ id: 'elements.video centered play button label' })}
                >
                    <Checkbox
                        label={intl.formatMessage({ id: 'elements.video centered play button checkbox' })}
                        value={node.props.useBigPlayButton}
                        onChange={val => onChange('useBigPlayButton', val, 'push column right', false)}
                    />
                </Label>
                
                <Label
                    labelIcon="sn-image"
                    label={intl.formatMessage({ id: 'elements.video cover image label' })}
                >
                    <FilePicker 
                        tabs={['files']}
                        filter={file => file.meta.mimeType.startsWith('image')}
                        onChange={file => {
                            onChange('coverImage', file, 'Set cover image', false);
                        }}
                        value={node.props.coverImage}
                    />
                </Label>

            </Section>

            {/* <Accordion state={state} dispatch={dispatch}>
                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                />
            </Accordion> */}
        </React.Fragment>
    );
}));