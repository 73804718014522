import createApi from 'api';
import constants from 'store/constants';

const actions = {
	toggleView: view => {
		return {
			type: constants.TOGGLE_FILESITE_VIEW,
			data: view	
		};
	},

	setHoveredFolder: folderID => {
		return {
			type: constants.SET_FOLDER_HOVERED,
			data: folderID
		};
	},
	
	setActiveFolder: folderID => {
		return {
			type: constants.FILES_SET_ACTIVE_FOLDER,
			data: folderID
		};
	},

	upload: (blobs, siteid) => async dispatch => {
		const api = createApi();

		try {
			await api.uploadToBucket(blobs, siteid);

			await dispatch(actions.ensureFolders(siteid));

			// await dispatch(actions.ensureFiles(siteid));
		} catch (e) {
			console.log(e);
		}
	},
	
	delete: (siteid, fileid, folderid) => async dispatch => {
		const api = createApi();

		try {
			await api.files.deleteFile(siteid, fileid, folderid);

			await dispatch(actions.ensureFolders(siteid));
		} catch (e) {
			console.log(e);
		}
	},

	update: (siteid, file) => async dispatch => {
		const api = createApi();

		try {
			await api.files.updateFile(siteid, file);

			await dispatch(actions.ensureFolders(siteid));
		} catch (e) {
			console.log(e);
		}
	},

	moveFile: (siteID, fileID, newFolderID, oldFolderID) => async dispatch => {
		const api = createApi();

		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const { folders } = await api.files.moveFile(siteID, fileID, newFolderID, oldFolderID);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid: siteID,
					folders
				}
			});
		} catch (e) {
			console.log(e, 'folders fetch err');
		} finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},

	ensureFiles: siteid => async (dispatch, getState) => {
		const api = createApi();

		try {
			const files = await api.getFilesForSite(siteid);

			dispatch({
				type: constants.SET_FILES,
				data: {
					files,
					siteid
				}
			});
		} catch (e) {
			console.log(e, 'files fetch err');
		}
	},

	ensureFolders: siteid => async (dispatch, getState) => {
		const api = createApi();

		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const { files, folders } = await api.files.getFoldersAndFiles(siteid);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid,
					folders,
					files
				}
			});
		} catch (e) {
			console.log(e, 'folders fetch err');
		} finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},

	createFolder: (name, parent, siteid) => async (dispatch, getState) => {
		const api = createApi();
		
		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const folders = await api.files.createFolder(name, parent, siteid);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid,
					folders
				}
			});
		} catch (e) {
			console.log(e);
		}finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},

	renameFolder: (newFoldername, folderid, siteid) => async dispatch => {
		const api = createApi();

		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const { folders } = await api.files.renameFolder(newFoldername, folderid, siteid);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid,
					folders
				}
			});
		} catch (e) {
			console.log(e);
		}finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},

	moveFolder: (folderId, parentId, siteid) => async dispatch => {
		const api = createApi();

		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const { folders } = await api.files.moveFolder(folderId, parentId, siteid);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid,
					folders
				}
			});
		} catch (e) {
			console.log(e);
		} finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},

	deleteFolder: (folderid, siteid) => async dispatch => {
		const api = createApi();

		dispatch({
			type: constants.TOGGLE_FOLDERS_LOADING,
			data: true
		});

		try {
			const { folders, files } = await api.files.deleteFolder(folderid, siteid);

			dispatch({
				type: constants.SET_FOLDERS,
				data: {
					siteid,
					folderid,
					files,
					folders
				}
			});
		} catch (e) {
			console.log(e);
		}finally {
			dispatch({
				type: constants.TOGGLE_FOLDERS_LOADING,
				data: false
			});
		}
	},
};

export default actions;
