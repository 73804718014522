import constants from 'store/constants';

const getDefaultState = () => ({
	subscriptionTypes: [],
	subdomains: [],
	fonts: []
});

const defaultState = getDefaultState();

export default (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_SYSTEM_SETTINGS:
			return Object.assign({}, state, action.data);

		case constants.SET_GOOGLE_FONTS: {
			return {
				...state,
				fonts: {
					...state.fonts,
					google: action.data
				}
			};
		}

		default:
			return state;
	}
};
