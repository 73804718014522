import React, { memo, useCallback, useMemo } from "react";
import { injectIntl } from 'react-intl';
import Label from 'components/option-library/label';
import Section from '../Section';
import Checkbox from 'components/option-library/primitives/checkbox';
import FilePicker from 'components/option-library/primitives/file';
// import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    // const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => { 
        updateState(context => {
            let nextValue = {
                ...context.nodeMap[node.id].props,
                [key]: value
            }
    
            if (typeof value === 'undefined') {
                delete nextValue[key];
            }
            
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: nextValue
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <Section>
            <FilePicker 
                multiple
                tabs={['files']}
                filter={file => file.meta.mimeType.startsWith('image')}
                onChange={files => {
                    onChange('images', files, 'select image', false);
                }}
                value={node.props.images}
            />

            <Label
                labelIcon="sn-play3"
                label={intl.formatMessage({ id: 'options.gallery autoplay label' })}
            >
                <Checkbox
                    label={intl.formatMessage({ id: 'options.gallery autoplay checkbox' })}
                    value={node.props.autoplay}
                    onChange={val => onChange('autoplay', val, 'push column right', false)}
                />

                {node.props.autoplay &&
                    <div style={{ marginTop: 6 }}>
                        <Checkbox
                            label={intl.formatMessage({ id: 'options.gallery autoplay play button checkbox' })}
                            value={node.props.autoplayControls}
                            onChange={val => onChange('autoplayControls', val, 'push column right', false)}
                        />
                    </div>
                }
            </Label>

            <Label
                labelIcon="sn-arrow-resize"
                label={intl.formatMessage({ id: 'options.gallery fullscreen label' })}
            >
                <Checkbox
                    label={intl.formatMessage({ id: 'options.gallery fullscreen checkbox' })}
                    value={node.props.fullscreen}
                    onChange={val => onChange('fullscreen', val, 'push column right', false)}
                />
            </Label>

            <Label
                labelIcon="sn-arrow-right2"
                label={intl.formatMessage({ id: 'options.gallery arrows label' })}
            >
                <Checkbox
                    label={intl.formatMessage({ id: 'options.gallery arrows checkbox' })}
                    value={node.props.arrows}
                    onChange={val => onChange('arrows', val, 'push column right', false)}
                />
            </Label>

            <Label
                labelIcon="sn-menu"
                label={intl.formatMessage({ id: 'options.gallery dots label' })}
            >
                <Checkbox
                    label={intl.formatMessage({ id: 'options.gallery dots checkbox' })}
                    value={node.props.dots}
                    onChange={val => onChange('dots', val, 'push column right', false)}
                />
            </Label>
        </Section>
    );
}));