import React, { PureComponent } from 'react';
import { Icon, Menu, MenuItem, ItemContent } from 'svift-ui';
import { setRoute } from 'utils/routing';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import BackButton from 'containers/dashboard/side-navigation/sidenavigation-content/__components/BackButton';
import Label from 'components/option-library/label';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const themeid = state.sites.themes.params.themeid;

	return {
		themeid,
		siteid,
		pathname: state.router.location.pathname
	};
};

class Elements extends PureComponent {
	isSelected(routeDescriptor) {
		const match = matchPath(this.props.pathname, routeDescriptor);

		return match !== null;
	}

	renderBackButton() {
		const { siteid, defaultTheme, intl } = this.props;

		return (
			<BackButton
				text={intl.formatMessage({ id: 'design.back to design' })}
				route={`/dashboard/${siteid}/design/${defaultTheme}`}
			/>
		);
	}

	render() {
		const { siteid, themeid, intl } = this.props;

		const baseRoute = `/dashboard/${siteid}/design/${themeid}/elements`;

		return (
			<React.Fragment>
				{this.renderBackButton()}
				
				<Label
					label={intl.formatMessage({ id: 'elements.title'})}
					labelIcon="sn-marquee-plus"
					labelDescription={intl.formatMessage({ id: 'elements.elements overview description'})}
				>
					{/* <p className="sidenav-subheader"><Icon name="sn-marquee-plus" /> <FormattedMessage id="elements.title" /><InfoIcon description={intl.formatMessage({ id: 'elements.elements overview description' })} /></p> */}

					<Menu vertical fluid>
						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/text') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/text`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Text')} /> <FormattedMessage id="elements.texts title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/image') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/image`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Image')} /> <FormattedMessage id="elements.images title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/button') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/button`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Button')} /> <FormattedMessage id="elements.buttons title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/video') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/video`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Video')} /> <FormattedMessage id="elements.videos title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/map') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/map`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Map')} /> <FormattedMessage id="elements.maps title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/menu') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/menu`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Menu')} /> <FormattedMessage id="elements.menus title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/input') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/input`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('FormInput')} /> <FormattedMessage id="elements.inputs title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/checkbox') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/checkbox`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('FormCheckbox')} /> <FormattedMessage id="elements.checkboxs title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/radio') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/radio`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('FormRadio')} /> <FormattedMessage id="elements.radios title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/iframe') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/iframe`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('IFrame')} /> <FormattedMessage id="elements.iframes title" />
							</ItemContent>
						</MenuItem>
						
						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/divider') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/divider`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Divider')} /> <FormattedMessage id="elements.divider title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/column') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/column`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Column')} /> <FormattedMessage id="elements.columns title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/row') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/row`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Row')} /> <FormattedMessage id="elements.rows title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/section') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/section`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Grid')} /> <FormattedMessage id="elements.sections title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/container') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/container`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('LayoutContainer')} /> <FormattedMessage id="elements.containers title" />
							</ItemContent>
						</MenuItem>

						<MenuItem className={this.isSelected('/dashboard/:siteid/design/:themeid/elements/body') ? 'active-item' : ''} link onClick={() => setRoute(`${baseRoute}/body`)}>
							<ItemContent>
								<Icon name={resolveElementIcon('Body')} /> <FormattedMessage id="elements.bodys title" />
							</ItemContent>
						</MenuItem>
					</Menu>

				</Label>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(Elements));
