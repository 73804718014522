import { cache as sizeCache } from './sizeCache';

export default ({ file, lastAppliedResolution, elementHeight, elementWidth }) => {
    const imageAspect = file.meta.dimensions.width / file.meta.dimensions.height;
    let direction;
    let px; 
    
    /*
        We check the required resolution for both directions, then take the higher one to ensure it's crisp

        We can't just use the highest dimension because some of that dimension could be clipped
    */
    const yMatchXAspect = elementWidth / imageAspect;
    const xMatchYAspect = elementHeight * imageAspect;

    const horizontalRequiredResolution = yMatchXAspect * elementWidth;
    const verticalRequiredResolution = xMatchYAspect * elementHeight;

    const { resolution: cachedResolution, direction: cachedDirection, px: cachedPx } = sizeCache[file.id] || {};

    /*
        TO-DO: 
        Make the cache smart enough to handle "shared fetching" when window resize happens; 
        currently all images individually try to fetch a new image, 
        they should communicate to determine the highest resolution and then agree to only fetch that one
    */
    // Don't refetch if we already have a higher resolution fetched for this file
    if (!isNaN(cachedResolution) && Math.max(horizontalRequiredResolution, verticalRequiredResolution, cachedResolution) === cachedResolution) {
        if ((cachedResolution - lastAppliedResolution) > -100 /* Giving some arbitrary space for rounding errors */) {
            return {
                urlOptions: ['e364', `${cachedDirection}${cachedPx.toFixed(0)}`],
                lastAppliedResolution: cachedResolution
            };
        }

        return;
    }

    const pixelDensity = (window && window.devicePixelRatio) ? window.devicePixelRatio : 1;

    if (horizontalRequiredResolution > verticalRequiredResolution) {
        direction = 'w';
        px = elementWidth * pixelDensity;
        sizeCache[file.id] = {
            direction: 'w',
            px: elementWidth * pixelDensity,
            resolution: horizontalRequiredResolution
        };
    } else {
        direction = 'h';
        px = elementHeight * pixelDensity;
        sizeCache[file.id] = {
            direction: 'h',
            px: elementHeight * pixelDensity,
            resolution: verticalRequiredResolution
        };
    }

    return {
        urlOptions: ['e364', `${direction}${px.toFixed(0)}`],
        lastAppliedResolution: sizeCache[file.id].resolution
    };
};