import React, { Component } from 'react';
import {
	Icon,
	Grid,
	TableRow,
	TableCell,
	Label,
	Image,
	Button,
	Message,
	Divider,
	Popup,
	Dimmer,
	Loader
} from 'svift-ui';
import ModalWrapper from 'components/modal';
import Alert from 'components/Alert';
import fileActions from 'store/actions/sites/files';
import { connect } from 'react-redux';
import { sizeToReadable, getIconByMimeType, getFileExtByMimeType } from 'utils/files/format';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import notification from 'components/hoc/notification';
import FileSettings from '../FileSettings';
import DraggableFile from '../DraggableFileHOC';
import Modal from 'svift-library/client/modal';

const mapStateToProps = state => {
	return {
		siteid: state.sites.params.siteid,
		activeFolder: state.sites.files.activeFolder
	};
};

const mapDispatchToProps = dispatch => ({
	setHoveredFolder: id => dispatch(fileActions.setHoveredFolder(id)),
	delete: (siteid, fileid, folderid) => dispatch(fileActions.delete(siteid, fileid, folderid)),
	updateFile: (siteid, newFile) => dispatch(fileActions.update(siteid, newFile))
});

@connect(mapStateToProps, mapDispatchToProps)
@DraggableFile
@injectIntl
class FileRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showDelete: false,
			showFileSettings: false,
			loading: false,
			loadingImage: true,
			file: { // For update purposes
				...props.file,
				meta: {
					...props.file.meta,
					name: props.file.meta.name.substring(0, props.file.meta.name.indexOf('.'))
				}
			}
		};

		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	delete = async () => {
		await this.props.delete(this.props.siteid, this.props.file._id, this.props.activeFolder);

		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'files.file deleted notification'}),
			className: 'info',
			position: 'topCenter',
			icon: 'sn-bin',
			deleteTime: 2000
		});
	}

	copyToClipboard() {
		const textField = document.createElement('textarea');

		textField.innerText = this.props.file.url;

		document.body.appendChild(textField);

		textField.select();

		document.execCommand('copy');

		textField.remove();

		this.props.createNotification({
			content: this.props.intl.formatMessage({ id: 'files.copy file link notification' }),
			className: 'info',
			position: 'topCenter',
			icon: 'sn-link',
			deleteTime: 2000
		});
	}

	render() {
		const { file, intl, size, siteid, key } = this.props;

		const ext = getFileExtByMimeType(file.meta.mimeType);
		const isLoading = this.state.loadingImage;

		const url = file.url;

		return this.props.connectDragSource(
			<tr style={this.props.isDragging ? { display: 'none' } : { cursor: 'move' }}>
				<TableCell>
				<div style={{ display: 'flex', alignItems: 'center' }}>
						{size === 'small' ?
							// output small icon
							<Icon name={getIconByMimeType(file.meta.mimeType)} style={{ opacity: 0.3, fontSize: 16, margin: 0 }} />
						:
							<React.Fragment>
								{isLoading &&
									<Image
										style={{ display: 'none' }}
										src={url}
										onLoad={() =>this.setState({loadingImage: false}) } 
									/> 
								}
								{file.meta.mimeType.startsWith('image')
									?
										this.state.loadingImage
											?
												<div style={{ width: 64, textAlign: 'center' }}>
													<Loader key="loader" style={{ opacity: 0.5 }} />
												</div>
											:
												<div
													style={{
														width: 64,
														height: 40,
														display: 'flex',
														alignItems: 'center',
														overflow: 'hidden',
														margin: 0,
														background: 'white',
														border: '1px dashed #DDD'
													}}
												>
													<Image 
														key="image" 
														src={url} 
														style={{ width: '100%', cursor: 'pointer' }}
														onClick={e => {
															this.setState({
																imagePreviewID: file._id
															});
														}}
													/>

													{this.state.imagePreviewID === file._id && 
														<Modal
															toggleModal={() => this.setState({ imagePreviewID: null })}
															title={file.meta.name}
															// title={`${intl.formatMessage({ id: 'general.fullscreen preview of' })}: ${file.meta.name}`}
															style={{ width: '100%' }}
														>
															<Image
																src={url}
																style={{ maxWidth: '90%', margin: 'auto' }}
															/>
															<div
																style={{
																	padding: '8px 12px',
																	position: 'absolute',
																	background: 'rgba(255, 255, 255, 0.9)',
																	zIndex: 2,
																	bottom: 16,
																	right: 16,
																	maxWidth: 240,
																	borderRadius: 4
																}}
															>
																<div>
																	<Icon name={getIconByMimeType(file.meta.mimeType)} style={{ marginRight: 6 }} />
																	{file.meta.name}
																</div>
																{file.meta.description &&
																	<div>
																		<Divider style={{ marginTop: 4, marginBottom: 6 }} />
																		<Icon name="sn-bubble-lines4" style={{ marginRight: 6 }} />
																		{file.meta.description}
																	</div>
																}
																{file.meta.credit &&
																	<div>
																		<Divider style={{ marginTop: 4, marginBottom: 6 }} />
																		<Icon name="sn-user3" style={{ marginRight: 6 }} />
																		{file.meta.credit}
																	</div>
																}
															</div>
														</Modal>
													}
												</div>
									:
										// output large icon
										<Icon name={getIconByMimeType(file.meta.mimeType)} style={{ opacity: 0.2, fontSize: 48, marginRight: 4, margin: '8px 0 0 0' }} />
								}
							</React.Fragment>
						}
						<div style={{ marginLeft: 8, marginBottom: 2 }}>
							{file.meta.name.substring(0, file.meta.name.lastIndexOf('.'))}
						</div>
						
						<div style={{ marginLeft: 'auto' }}>
							<Popup
								trigger={
									<Button
										basic
										circular
										size="mini"
										icon="sn-link"
										onClick={this.copyToClipboard}
										className="secondary"
										style={{ padding: 6, marginLeft: 8 }}
									/>
								}
								content={intl.formatMessage({ id: 'files.copy file link' })}
								position="top center"
							/>
						</div>
					</div>
				</TableCell>

				<TableCell>
					<Label
						basic
						size="mini"
						style={{ textTransform: 'uppercase' }}
					>
						<Icon name={getIconByMimeType(file.meta.mimeType)} style={{ marginRight: 6 }} />
						{ext}
					</Label>
				</TableCell>

				<TableCell textAlign="right" style={{ fontSize: 12 }}>
					{sizeToReadable(file.meta.size)}
					<Icon name="sn-database4" style={{ marginLeft: 4, marginRight: 0, opacity: 0.4 }} />
				</TableCell>

				{/* Last updated timestamp */}
				<TableCell textAlign="right" style={{ fontSize: 12 }}>
					<Icon name="clock" style={{ marginRight: 6, opacity: 0.4 }} />
					<FormattedDate
						value={file.lastUpdated}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				</TableCell>
				
				{/* Edit file / options */}
				<TableCell textAlign="right">
					<Popup
						trigger={
							<Icon
								link
								name="setting"
								className="primary-color"
								style={{ marginRight: 4, opacity: 0.5 }}
								onClick={() => {
									this.setState({
										showFileSettings: true,
										name: file.meta.name.substring(0, file.meta.name.lastIndexOf('.'))
									});
								}}
							/>
						}
						content={this.props.intl.formatMessage({ id: 'files.file settings' })}
						position="bottom right"
					/>
					<Popup
						trigger={
							<Icon
								link
								name="sn-bin"
								className="negative-color"
								style={{ marginRight: 0, opacity: 0.5 }}
								onClick={() => {
									this.setState({
										showDelete: true
									});
								}}
							/>
						}
						content={this.props.intl.formatMessage({ id: 'files.delete file' })}
						position="bottom right"
					/>
				</TableCell>

				<ModalWrapper
					open={this.state.showFileSettings}
					handleClose={() => {
						this.setState({
							showFileSettings: false
						});
					}}
					header="files.file settings"
				>
					{this.state.loading &&
						<Dimmer active inverted>
							<Loader size="huge" style={{ fontSize: 32 }}>
								<Icon name="cloud upload" /> 
								<FormattedMessage id="general.saving changes" />
							</Loader>
						</Dimmer>
					}

					<Grid centered celled verticalAlign="middle">
						<FileSettings
							update // may not be needed but whatever
							o
							url={url}
							file={file}
							ext={ext}
							mimeType={this.state.file.meta.mimeType}
							credit={this.state.file.meta.credit}
							description={this.state.file.meta.description}
							size={this.state.file.meta.size}
							name={this.state.file.meta.name}
							onDelete={() => this.setState({ showDelete: true })}
							onChange={e => {
								this.setState({
									file: {
										...this.state.file,
										meta: {
											...this.state.file.meta,
											[e.target.name]: e.target.value
										}
									}
								});
							}}
						/>
					</Grid>

					<Button
						positive
						onClick={() => {
							this.setState({
								loading: true
							}, async () => {
								const newFile = {
									...this.state.file,
									meta: {
										...this.state.file.meta,
										name: `${this.state.file.meta.name}.${ext}`
									}
								};

								await this.props.updateFile(this.props.siteid, newFile);

								this.setState({
									loading: false,
									showFileSettings: false
								});
							});
						}}
						disabled={this.state.loading}
					>
						<FormattedMessage id="files.save file settings" />
						</Button>
				</ModalWrapper>

				<Alert
					show={this.state.showDelete}
					onCancel={() => this.setState({ showDelete: false })}
					showCancelButton
					title={this.props.intl.formatMessage({ id: 'files.delete file alert title' })}
					text={<span><span style={{ fontWeight: 'bold' }}>{file.meta.name}</span> <FormattedMessage id="general.will be deleted" />, <FormattedMessage id="files.delete file alert description" /></span>}
					confirmTitle={this.props.intl.formatMessage({ id: 'files.delete file alert confirm' })}
					confirmIcon="trash"
					confirmClass="negative"
					cancelTitle={this.props.intl.formatMessage({ id: 'general.cancel' })}
					onConfirm={this.delete}
				/>
			</tr>
		);
	}
}

export default notification(FileRow);
