const debounce = waitLength => {
	let debounced;
	let timeOut;

	const api = fn => {
		debounced = fn;

		clearTimeout(timeOut);

		timeOut = setTimeout(() => {
			if (debounced) debounced();

			debounced = null;
		}, waitLength)
	};

	api.finish = () => {
		if (debounced) debounced();

		clearTimeout(timeOut);
	};

	return api;
}

export default debounce;