import React, { PureComponent } from 'react';
import {
	Table,
	TableHeader,
	TableRow,
	TableHeaderCell,
	TableBody,
	Icon,
} from 'svift-ui';
import FileRow from './FileRow';
import NoItems from 'components/no-items/NoItems';
import { injectIntl, FormattedMessage } from 'react-intl';
import DropUploadHOC from 'containers/dashboard/files/__components/DropUploadHOC';

class FilesTable extends PureComponent {
	constructor(props) {
		super(props);
		this.state={};
	}

	iconRender = (key, sortKey, sortDirection) => {
		const disabledStyling = { fontSize: 12, opacity: 0.2 };
		const activeStyling = { fontSize: 12, opacity: 1 };
		const leftArrowStyling = { margin: 0 };
		const rightArrowStyling = { margin: '0 0 0 -2px' };

		return (
			key !== sortKey ?
				<React.Fragment>
					<span style={{ marginLeft: 8, marginRight: 2, verticalAlign: 'middle' }}>
						<Icon
							name="sn-arrow-down2"
							style={Object.assign(disabledStyling, leftArrowStyling)}
						/>
						<Icon
							name="sn-arrow-up2"
							style={Object.assign(disabledStyling, rightArrowStyling)}
						/>
					</span>
				</React.Fragment>
			:
				<React.Fragment>
					<span style={{ marginLeft: 8, verticalAlign: 'middle' }}>
						<Icon
							name={sortDirection === 'descending' ? 'sn-arrow-down' : 'sn-arrow-down2'}
							style= {
								sortDirection === 'descending' ?
									Object.assign(activeStyling, leftArrowStyling)
								:
									Object.assign(disabledStyling, leftArrowStyling)
								}
						/>
						<Icon
							name={sortDirection === 'ascending' ? 'sn-arrow-up' : 'sn-arrow-up2'}
							style= {
								sortDirection === 'ascending' ?
									Object.assign(activeStyling, rightArrowStyling)
								:
									Object.assign(disabledStyling, rightArrowStyling)
							}
						/>
					</span>
				</React.Fragment>
		)
	}

	render() {
		const { noFiles, noFilteredItems, fileSize, files, sortKey, sortDirection, intl } = this.props;

		return (
			<div ref={this.props.connectDropTarget}>
				{/* Show message when dragging files for upload */}
				{this.props.renderDropMessage()}

				{noFilteredItems
					?
						<Table
							// size={fileSize}
							size="small"
							padded
							stackable
							selectable
							striped
						>
							<TableHeader>
								<TableRow>
									{/* File name */}
									<TableHeaderCell
										className={sortKey === 'name' ? 'sortable active-item' : 'sortable'}
										onClick={() => this.props.setSorting({ sortKey: 'name' })}
									>
										<FormattedMessage id="files.file name" />
										{this.iconRender('name', sortKey, sortDirection)}
									</TableHeaderCell>

									{/* File type */}
									<TableHeaderCell
										className={sortKey === 'ext' ? 'sortable active-item' : 'sortable'}
										onClick={() => this.props.setSorting({ sortKey: 'ext' })}
									>
										<FormattedMessage id="files.file type" />
										{this.iconRender('ext', sortKey, sortDirection)}
									</TableHeaderCell>

									{/* File size */}
									<TableHeaderCell
										textAlign="right"
										className={sortKey === 'size' ? 'sortable active-item' : 'sortable'}
										onClick={() => this.props.setSorting({ sortKey: 'size' })}
									>
										<FormattedMessage id="files.file size" />
										{this.iconRender('size', sortKey, sortDirection)}
									</TableHeaderCell>
									
									{/* Last updated */}
									<TableHeaderCell
										textAlign="right"
										className={sortKey === 'lastUpdated' ? 'sortable active-item' : 'sortable'}
										onClick={() => this.props.setSorting({ sortKey: 'lastUpdated' })}
									>
										<FormattedMessage id="files.file last updated" />
										{this.iconRender('lastUpdated', sortKey, sortDirection)}
									</TableHeaderCell>
									
									{/* Edit file */}
									<TableHeaderCell textAlign="center">
										<Icon name="sn-cog3" style={{ margin: 0 }} />
										{/* <FormattedMessage id="files.file edit" /> */}
									</TableHeaderCell>
								</TableRow>
							</TableHeader>
							
							{/* File rows: */}
							<TableBody>
								{Object.values(files).map((file) => <FileRow size={fileSize} key={file._id} file={file} />)}
							</TableBody>
						</Table>
					:
						<NoItems
							icon="sn-folder-search"
							title={intl.formatMessage({ id: 'messages.no filtered files title' })}
							content={intl.formatMessage({ id: 'messages.no filtered files description' })}
							className="warning"
						/>
				}
			</div>
		);
	}
}

export default injectIntl(DropUploadHOC(FilesTable));