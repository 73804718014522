/*
	TO-DO: Sorting logic could probably benefit from a refactoring
*/

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Loader, Message, Dimmer, Icon, Box } from 'svift-ui';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import { createFilter } from 'react-search-input';
import fileActions from 'store/actions/sites/files';
import { injectIntl, FormattedMessage } from 'react-intl';

import UploadModal from './__components/upload-modal/UploadModal';
import FilesHeader from './__components/FilesHeader';
import FilesTable from './__components/files-table/FilesTable';
import FileThumbnails from './__components/file-thumbnails';
import { getFileExtByMimeType } from 'utils/files/format';

import NoItems from 'components/no-items/NoItems';
import DropUploadHOC from 'containers/dashboard/files/__components/DropUploadHOC';
import notification from 'components/hoc/notification';
import { storageCapacity as capacitySelector, storageLeft, filesInActiveFolder } from 'store/selectors/sites/files';

@DropUploadHOC
class NoItemsDroppable extends PureComponent {
	render() {
		const { intl } = this.props;

		return (
			<div ref={this.props.connectDropTarget} style={{ position: 'relative' }}>
				{this.props.renderDropMessage()}

				<NoItems
					icon="sn-folder-open3"
					title={intl.formatMessage({ id: 'messages.no files title' })}
					content={intl.formatMessage({ id: 'messages.no files description' })}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	// ensureFiles: async siteid => dispatch(fileActions.ensureFiles(siteid)),
	ensureFolders: async siteid => dispatch(fileActions.ensureFolders(siteid)),
});

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;

	/* Move to memoized selector? */
	// const site = state.sites.sites[siteid];
	// const fileIds = site.files;
	// const activeFolderId = state.sites.files.activeFolder;
	// const activeFolder = state.sites.files.folders.find(folder => folder._id === activeFolderId) || state.sites.files.folders.find(folder => !folder.parent);
	// const fileIdsInFolder = activeFolder ? activeFolder.files.filter(file => fileIds.includes(file)) : fileIds;
	// const files = fileIdsInFolder.map(id => state.sites.files.files[id]);
	const files = filesInActiveFolder(state);

	const storageCapacity = capacitySelector(state);

	return {
		siteid,
		filetypes: state.system.filetypes,
		view: state.sites.files.view,
		foldersLoading: state.sites.files.foldersLoading,
		files,
		storageLeft: storageLeft(state, storageCapacity)
	};
};

@notification
@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class Files extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			searchTerm: null,
			blobs: [],
			unsupportedFile: false,
			thumbSliderValue: 5,
			fileListValue: 2,
			fileSize: 'large',
			sortDirection: 'ascending',
			sortKey: 'name'
		};
		
		this.state.sortedFiles = this.sortFiles(this.props.files, this.state.sortKey, this.state.sortDirection);

		// To-do: replace blobs functionality with BlobsHOC (Blobs.js)
		this.addBlobs = blobs => {
			const urlCreator = window.URL || window.webkitURL;

			const newBlobs = [];
			let unsupportedFile = false;
			let bytesAvailable = this.props.storageLeft;
			
			for (let i = 0; i < blobs.length; i++) {
				if (!this.props.filetypes.some(filetype => filetype === blobs[i].name.split('.').pop().toLowerCase())) { // Probably better to look at mimetype, but whatever for now
					unsupportedFile = true;

					this.props.createNotification({
						content: this.props.intl.formatMessage({ id: 'files.file type not supported error notification' }),
						className: 'error',
						position: 'topCenter',
						icon: 'sn-file-minus2',
						deleteTime: 3000
					});

					continue;
				}

				bytesAvailable = bytesAvailable - blobs[i].size;

				if (bytesAvailable < 0) {
					this.props.createNotification({
						content: this.props.intl.formatMessage({ id: 'files.uploading this file would cause you to exceed your storage capacity' }),
						className: 'error',
						position: 'topCenter',
						icon: 'sn-file-minus2',
						deleteTime: 3000
					});

					continue;
				}

				const name = blobs[i].name;
				const imageUrl = urlCreator.createObjectURL(blobs[i]);

				newBlobs.push({
					url: imageUrl,
					meta: {
						name: name.substring(0, name.lastIndexOf('.')),
					},
					blob: blobs[i]
				});
			}

			this.setState({
				blobs: this.state.blobs.concat(newBlobs),
				unsupportedFile
			});
		};

		this.updateBlob = (blob, index) => {
			let blobs = this.state.blobs;

			if (blob === null) {
				blobs = blobs.slice(0, index).concat(blobs.slice(index + 1));
			} else {
				blobs = [...blobs];

				blobs[index] = blob;
			}

			this.setState({
				blobs
			});
		};
		this.clearBlobs = () => this.setState({ blobs: [], unsupportedFile: false });

		this.onSearchTermChange = this.onSearchTermChange.bind(this);
	}

	componentDidMount() {
		this.props.ensureFolders(this.props.siteid);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.files !== nextProps.files) {
			this.setState({
				sortedFiles: this.sortFiles(nextProps.files, this.state.sortKey, this.state.sortDirection)
			});
		}

		if (nextProps.siteid !== this.props.siteid) {
			this.setState({
				searchTerm: null,
				blobs: [],
				loading: true,
				unsupportedFile: false,
				sortedFiles: this.sortFiles(nextProps.files, this.state.sortKey, this.state.sortDirection)
			});
		}
	}

	sortFiles = (files, sortKey, sortDirection) => {
		const shallowFilesCopy = files.slice(); // [].sort modifies the existing array; we'll slice in advance to ensure immutability

		const resolveValue = (file, sortKey) => {
			switch (sortKey) {
				case 'name':
					return file.meta.name.toLowerCase();
				case 'ext':
					return getFileExtByMimeType(file.meta.mimeType);
				case 'size':
					return file.meta.size;
				case 'lastUpdated':
					return new Date(file.lastUpdated).valueOf();
			}
		};

		return shallowFilesCopy.sort((a, b) => {
			if (resolveValue(a, sortKey) === resolveValue(b, sortKey)) return 0;

			if (sortDirection === 'descending') {
				return resolveValue(a, sortKey) > resolveValue(b, sortKey) ? -1 : 1;
			}
		
			return resolveValue(a, sortKey) > resolveValue(b, sortKey) ? 1 : -1;
		});
	}

	setSorting = ({ sortKey, sortDirection }) => {
		const stateMutation = {
			sortKey
		};
		
		if (sortDirection) {
			stateMutation.sortDirection = sortDirection;
		} else if (sortKey === this.state.sortKey) { 
			stateMutation.sortDirection = this.state.sortDirection === 'ascending' ? 'descending' : 'ascending'; 
		} else {
			stateMutation.sortDirection = 'ascending';
		}

		stateMutation.sortedFiles = this.sortFiles(this.props.files, stateMutation.sortKey, stateMutation.sortDirection)

		this.setState(stateMutation);
	}

	fileListHandleChange = (value) => {
		this.setState({
			fileListValue: value,
			fileSize: value === 2 ? 'large' : 'small'
		});
	}

	thumbnailHandleChange = (value) => {
		this.setState({
			thumbSliderValue: value
		});
	}

	setFileFilter = filter =>{
		this.setState({
			fileFilter: filter
		});
	}

	onSearchTermChange(e) {
		this.setState({
			searchTerm: e.target.value.length === 0 ? null : e.target.value
		});
	}

	deleteSearchTerm = () =>{
		this.setState({ searchTerm: "" });
	}

	render() {
		if (this.props.foldersLoading) {
			return (
				<Dimmer active inverted>
					<Icon name="sn-folder6" className="primary" style={{ fontSize: 64, margin: '0 0 8px 0', opacity: 0.2 }} />
					<Loader size="big" style={{ opacity: 0.5 }} />	
				</Dimmer>
			);
		}

		const { files, view, siteid } = this.props;
		const { searchTerm, blobs, fileSize, fileFilter } = this.state;

		const loadedFiles = fileFilter ? this.state.sortedFiles.filter(fileFilter) : this.state.sortedFiles;
		
		const filterBy = ['meta.name'];
		const filteredFiles = searchTerm === null ? loadedFiles : Object.values(loadedFiles).filter(createFilter(searchTerm, filterBy));	

		const noFiles = files.length === 0;

		return (
			<Box style={{ padding: 0, width: '100%', marginTop: 0 }}>
				<FilesHeader
					addBlobs={this.addBlobs}
					onChange={this.onSearchTermChange}
					searchTerm={searchTerm}
					deleteSearchTerm={this.deleteSearchTerm}

					thumbSliderValue={this.state.thumbSliderValue}
					thumbnailHandleChange={this.thumbnailHandleChange}
					fileListValue={this.state.fileListValue}
					fileSize={fileSize}
					fileListHandleChange={this.fileListHandleChange}

					setFileFilter={this.setFileFilter}
					fileFilter={fileFilter}
					
					setSorting={this.setSorting}
					sortDirection={this.state.sortDirection}
					sortKey={this.state.sortKey}
				/>

				{this.state.loading 
					?
						<AppContentSection>
							<Message style={{ padding: '24px 32px' }}>
								<Loader>
									<p><FormattedMessage id="files.loading images" /></p>
								</Loader>
							</Message>
						</AppContentSection>
					:
						noFiles ?
							<NoItemsDroppable addBlobs={this.addBlobs} />
						:
							<AppContentSection>
								{view === 'list' /* These two components are identical (except visually), so there is a ton of code duplication. Needs fix (raise props or combine components). */
									?
										<FilesTable
											noFiles={noFiles}
											addBlobs={this.addBlobs}
											files={filteredFiles}
											fileSize={fileSize}
											noFilteredItems={filteredFiles.length === 0 ? false : true} // pass bool if search or filter is active
											setSorting={this.setSorting}
											sortKey={this.state.sortKey}
											sortDirection={this.state.sortDirection}
										/>
									:
										<FileThumbnails
											noFiles={noFiles}
											addBlobs={this.addBlobs}
											files={filteredFiles}
											noFilteredItems={filteredFiles.length === 0 ? false : true} // pass bool if search or filter is active
											siteid={siteid}
											columnValue={this.state.thumbSliderValue}
										/>
								}
							</AppContentSection>
				}

				{blobs && blobs.length > 0 &&
					<UploadModal
						blobs={blobs}
						warningVisible={this.state.unsupportedFile}
						updateBlob={this.updateBlob}
						addBlobs={this.addBlobs}
						clearBlobs={this.clearBlobs}
					/>
				}
			</Box>
		);
	}
}
