import React, { memo } from 'react';
import { Icon } from 'svift-ui';
import css from './options.scss';
import ClearValue from 'components/editor-v2/editor-interface/options/common/clear-value/ClearValue';
import InfoIcon from 'components/InfoIcon';

export default props => {
    let containerClassName = css['accordion-container'];
    let headerClassName = css['accordion-header'];

    if (props.nested) {
        headerClassName += ` ${css.nested}`;
        containerClassName += ` ${css.nested}`;
    }
    if (props.accordion && props.accordion.open) {
        containerClassName += ` ${css.active}`;
        headerClassName += ` ${css.active}`;
    };

    if (props.accordion) {
        return (
            <div className={containerClassName}>
                <div
                    className={headerClassName}
                    onClick={props.accordion.toggle}
                >
                    <span>
                        {/* open/close icon */}
                        <Icon 
                            link 
                            name={props.accordion.open ? 'sn-minus-circle2' : 'sn-plus-circle'}
                            style={{ margin: 0, marginLeft: 4, float: 'right' }}
                        />
                        
                        {/* option icon */}
                        <Icon
                            name={props.icon || 'cube'}
                            style={{
                                margin: 0,
                                marginRight: 6,
                                opacity: props.accordion.open ? 1 : 0.5
                            }}
                        />

                        {/* section/option title */}
                        {props.title}

                        {props.description &&
                            <InfoIcon
                                description={props.description}
                                className={props.infoIconClassName || "tint-color"}
                                position="right center"
                            />
                        }

                        {props.clear &&
                            <ClearValue {...props.clear} />
                        }
                    </span>
                </div>

                {props.accordion.open &&
                    <div className={`${css['accordion-content']} ${css['animated']} ${css['fadeIn']} ${css['faster']}`}>
                        {props.children}
                    </div>
                }
            </div>
        );
    } 

    return (
        <div className={css['accordion-content']}>
            {props.children}
        </div>
    );
};