import React from 'react';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';



export default injectIntl(props => {
    const draftTogglePopup = useTooltip();
    const liveTogglePopup = useTooltip();
    
    return (
        <React.Fragment>
            <Popup
                target={draftTogglePopup.current}
                position="bottom center"
                zIndex={999999} // above preview container
            >
                {props.draftPopup || <FormattedMessage id="general.draft title" />}
            </Popup>
            <Popup
                target={liveTogglePopup.current}
                position="bottom center"
                zIndex={999999} // above preview container
            >
                {props.livePopup || <FormattedMessage id="general.live title" />}
            </Popup>

            <ToggleGroup size={props.size || 'mini'}>	
                {/* draft toggle */}
                <Toggle
                    icon="sn-quill3"
                    ref={draftTogglePopup}
                    tooltipLabel={props.intl.formatMessage({ id: 'general.draft title' })}
                    tooltipPosition="bottom center"
                    value={props.value === 'draft'}
                    onChange={() => props.onChange('draft')}
                >
                    <span style={{ marginLeft: 6 }}>
                        <FormattedMessage id="general.draft title" />
                    </span>
                </Toggle>

                {/* TO DO: disable live button if content is offline */}
                {/* live toggle */}
                <Toggle
                    icon="sn-earth"
                    ref={liveTogglePopup}
                    tooltipLabel={props.intl.formatMessage({ id: 'general.live title' })}
                    tooltipPosition="bottom center"
                    value={props.value === 'live'}
                    onChange={() => !props.hasLiveContent ? alert(props.intl.formatMessage({ id: 'general.content isnt published' })) : props.onChange('live')}
                >
                    <span style={{ marginLeft: 6 }}>
                        <FormattedMessage id="general.live title" />
                    </span>
                </Toggle>
            </ToggleGroup>
        </React.Fragment>
    );
});
