const compareValues = (a, b) => JSON.stringify(a) !== JSON.stringify(b);

export default ({ getState, updateState }) => {
    return ({ onChange }) => {
        let storedState;
        let storedValue;
        let lastAppliedValue;
        let lastAppliedArguments;

        return {
            active: () => {
                return !!storedState;
            },
            flush: () => {
                if (!storedState) return;

                const valueChangedWhileSuspended = compareValues(storedValue, lastAppliedValue);
    
                if (valueChangedWhileSuspended) {
                    // Have to copy this one because it'll be unassigned (see bottom of this function) before react calls setState - that was fun to debug
                    const tmpStoredState = storedState;

                    updateState(() => {
                        return {
                            ...getState(),
                            nodeMap: tmpStoredState.nodeMap
                        };
                    }, 'skip');
                    console.log('setting stored state');
                    onChange(false, lastAppliedValue, ...lastAppliedArguments);
                    console.log('setting new state');
                }

                storedState = undefined;
                storedValue = undefined;
                lastAppliedValue = undefined;
                lastAppliedArguments = undefined;
            },
            // TO-DO: make opt in timer that flushes every x milliseconds? should be fairly trivial but probably can't make it default
            onChange: ({ currentValue, nextValue }, ...args) => {
                if (!storedState) {
                    storedValue = currentValue;
                    storedState = getState();
                }
    
                lastAppliedValue = nextValue;
                lastAppliedArguments = args;
    
                onChange(true, nextValue, ...args);
            }
        }
    }
};