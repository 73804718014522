import React, { memo, useCallback } from "react";
// import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
// import Crop from '../common/Crop';
// import Label from 'components/option-library/label';
// import FilePicker from 'components/option-library/primitives/file';
// import Preset from 'components/editor-v2/editor-interface/options/common/preset';
// import Checkbox from 'components/option-library/primitives/checkbox';
import Section from '../Section';
import { injectIntl } from 'react-intl';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <Section>
            Icon options lol
        </Section>
    );
}));