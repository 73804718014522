export default [
	"sn-avatar-anonymous",
	"sn-avatar-ninja",
	"sn-avatar-female-0",
	"sn-avatar-female-1",
	"sn-avatar-female-2",
	"sn-avatar-female-3",
	"sn-avatar-female-4",
	"sn-avatar-female-5",
	"sn-avatar-female-6",
	"sn-avatar-female-7",
	"sn-avatar-female-8",
	"sn-avatar-female-9",
	"sn-avatar-female-10",
	"sn-avatar-female-11",
	"sn-avatar-female-12",
	"sn-avatar-female-13",
	"sn-avatar-female-14",
	"sn-avatar-female-15",
	"sn-avatar-female-16",
	"sn-avatar-kid-1",
	"sn-avatar-kid-2",
	"sn-avatar-nun",
	"sn-avatar-samurai-1",
	"sn-avatar-male-0",
	"sn-avatar-male-1",
	"sn-avatar-male-2",
	"sn-avatar-male-3",
	"sn-avatar-male-4",
	"sn-avatar-male-5",
	"sn-avatar-male-6",
	"sn-avatar-male-7",
	"sn-avatar-male-8",
	"sn-avatar-male-9",
	"sn-avatar-male-10",
	"sn-avatar-male-11",
	"sn-avatar-male-12",
	"sn-avatar-male-13",
	"sn-avatar-male-14",
	"sn-avatar-male-15",
	"sn-avatar-male-16",
	"sn-avatar-punker",
	"sn-avatar-kid-0",
	"sn-avatar-samurai-0",
	"sn-avatar-cyborg",
	"sn-avatar-dracula",
	"sn-avatar-frankenstein",
	"sn-avatar-hannibal",
	"sn-avatar-walter",
	"sn-avatar-pirate",
	"sn-avatar-kitty",
];