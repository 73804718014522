import React, { PureComponent } from 'react';
import {
    Grid,
    GridColumn,
    Button,
    Icon,
    Dimmer,
    Loader,
    Message,
    Label,
    Divider,
    Header
} from 'svift-ui';
import ModuleColumn from '../__components/ModuleColumn.js';
import Gallery from '../__components/Gallery';
import availableAddOns from './misc/addonData';
import { injectIntl, FormattedMessage } from 'react-intl';
import Money from 'svift-library/format/money';
import ModalWrapper from 'components/modal';
import InfoIcon from 'components/InfoIcon';

class AddOns extends PureComponent {
    renderGalleryItem = (item) => {
        const isEnabled = this.props.modules[item.id];

        const infoLabelProps = {};

        if (isEnabled) {
            return null;
        } else if (item.amount) {
            if (item.amount === '0') {
                infoLabelProps.infoLabel = 'TBA';
            } else {
                infoLabelProps.infoLabel = Money(item.amount, { currency: 'DKK' }).readable();
            }
        } else {
            infoLabelProps.infoLabel = this.props.intl.formatMessage({ id: 'general.free title' }).toUpperCase();
        }

        return (
            <ModuleColumn
                key={item.id}
                name={this.props.intl.formatMessage({ id: item.intl })}
                infoLabelDescription={this.props.intl.formatMessage({ id: item.descriptionIntl })}
                size={32}
                fontSize={24}
                actionDisabled={item.actionDisabled}
                actionNameIntl={item.actionNameIntl}
                icon={item.icon}
                color={item.color}
                {...infoLabelProps}
                onClick={() => {
                    this.props.setClickedModule(item.id, true);
                }}
            />
        );
    }

    renderToggleModal = () => {
        const clickedModuleInfo = availableAddOns[this.props.clickedModule];

        return (
            <ModalWrapper 
                open={this.props.clickedModule} 
                handleClose={() => this.props.setClickedModule(null)} 
                header="modules.activation title"
                style={{ maxWidth: 640 }}
            >
                {this.props.modulesLoading &&
                    <Dimmer active inverted>
                        <Loader size="huge" style={{ fontSize: 32 }}>
                            <Icon name="sn-sphere" /> 
                            <FormattedMessage id="general.saving changes" />
                        </Loader>
                    </Dimmer>
                }

                {/* transl8 */}
                {/* Vil du aktivere {clickedModuleInfo && clickedModuleInfo.intl}? */}

                {clickedModuleInfo && clickedModuleInfo.renderContent && clickedModuleInfo.renderContent()}

                {this.props.errors &&
                    <Message
                        negative
                        key="err"
                    >
                        {this.props.errors.map(e => {
                            return (
                                <p key={e}> {e} </p>
                            );
                        })}
                    </Message>
                }

                <Button 
                    className="positive" 
                    onClick={() => this.props.toggleModules(this.props.clickedModule, true)}                 
                >
                    <Icon name="sn-checkmark" style={{ marginRight: 8 }} /> 
                    <FormattedMessage id="modules.activate button" />
                </Button>
    
                <Button
                    basic
                    onClick={() => this.props.setClickedModule(null)}
                >
                    <Icon name="sn-cross2" style={{ marginRight: 8 }} /> 
                    <FormattedMessage id="general.cancel" />
                </Button>
            </ModalWrapper>
        );
    }

    render() {
        const { intl } = this.props;

        return (
            <Grid
                centered
                padded
                className="section-shade"
                columns={3}
                style={{
                    paddingBottom: 32,
                    border: 'solid 2px rgba(82, 60, 101, 0.2)'
                }}
            >
                <GridColumn computer={16} textAlign="center">
                    <Divider horizontal style={{ margin: 0 }}>
                        <Icon circular name="sn-subscription-general" className="primary-color" style={{ fontSize: 24, margin: 0 }} />
                    </Divider>

                    <Header
                        as="h4"
                        className="dark-primary-color"
                        style={{ marginTop: 8 }}
                        content={
                            <span>
                                {/* <Icon name="sn-checkmark" /> */}
                                <FormattedMessage id="modules.inactive modules title" />
                                <InfoIcon
                                    description={intl.formatMessage({ id: 'modules.inactive modules description' })}
                                    className="primary-color"
                                />
                            </span>
                        }
                    />
                </GridColumn>

                <Gallery 
                    items={Object.values(availableAddOns)}
                    renderItem={this.renderGalleryItem}
                />

                {/* <GridColumn>
                    <div>
                        Hav an idea for a module? - write a mail to contact@svift.net
                    </div>
                </GridColumn> */}

                {this.renderToggleModal()}
            </Grid>
        );
    }
}

export default injectIntl(AddOns);