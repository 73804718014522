import React from 'react';
import LayoutForm from 'components/forms/layout';
import Modal from 'svift-library/client/modal';
import { injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import { connect } from 'react-redux';
import layoutsSelector from 'store/selectors/sites/layouts';
import actions from 'store/actions/sites/layouts';

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid;

    return {
        siteid,
        layoutid: state.sites.layout.params.layoutid,
        layouts: layoutsSelector(state, siteid, null)
    };
}

const mapDispatchToProps = (dispatch) => {
	return {
		createLayout: (siteID, newLayout) => dispatch(actions.createLayout(siteID, newLayout)),
		deleteLayout: (siteID, layoutID) => dispatch(actions.deleteLayout(siteID, layoutID)),
		updateLayout: (siteID, layoutID, newValues) => dispatch(actions.updateLayout(siteID, layoutID, newValues))
	};
};

class LayoutsContainer extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			loading: false
		};

		this.createNewLayout = this.createNewLayout.bind(this);
		this.updateLayout = this.updateLayout.bind(this);
		this.deleteLayout = this.deleteLayout.bind(this);
	}

    async createNewLayout(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;

		try {
			await this.props.createLayout(siteid, values);

			SviftGA.FORMS.CREATED();

			this.setState({
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
	
	async updateLayout(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;
		const layoutid = this.props.layoutid;

		try {
			await this.props.updateLayout(siteid, layoutid, values);

			SviftGA.FORMS.MODIFIED();

			this.setState({
				loading: false,
				modalOpen: false
			});

			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'layouts.settings notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'setting',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deleteLayout() {
		this.setState({
			loading: true
		});

		try {
			await this.props.deleteLayout(this.props.siteid, this.props.layoutid);

			SviftGA.FORMS.DELETED();

			this.setState({
				loading: false
            });
            
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'layouts.delete notification' }),
                className: 'info',
                position: 'topCenter',
                icon: 'trash',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
    // if (!modalOpen) return null;

    render() {
		const { intl } = this.props;
        const currentLayout = this.props.layouts.find(layout => layout._id === this.props.layoutid);

        return (
            <React.Fragment>
                {this.props.renderButton(open => this.setState({ modalOpen: open }))}
    
                {this.state.modalOpen &&
                    <Modal
                        title={this.props.intl.formatMessage({ id: `layouts.${(this.state.modalOpen === 'create') ? 'new layout' : 'settings'}`})}
                        toggleModal={open => this.setState({ modalOpen: open })}
                        nodeAttributes={{
                            'data-ignore-click': true
						}}
						style={{ maxWidth: 800 }}
                    >
						{this.state.modalOpen === 'update' && currentLayout &&
							<LayoutForm
								loading={this.state.loading}
                                update
                                enableReinitialize
								initialValues={{
                                    title: currentLayout.title,
                                    type: currentLayout.type
                                }}
								onSubmit={this.updateLayout}
								onClose={() => this.setState({ modalOpen: false })}
								delete={this.deleteLayout}
							/>
						}

						{this.state.modalOpen === 'create' &&
							<LayoutForm
								loading={this.state.loading}
								onSubmit={this.createNewLayout}
								onClose={() => this.setState({ modalOpen: false })}
							/>
						}
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(LayoutsContainer)));
