module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact',
            horizontalAlign: 'center'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Kontakt os',
                                                type: 'Heading 2',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Skriv en venlig og imødekommende tekst, der inviterer til deltagelse. Understøt den gode kommunikation med en indbydende kontaktformular.  ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    horizontalAlign: 'flex-start'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 11,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Form',
                                props: {
                                    selectedForm: null
                                }
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 5,
                        props: {
                            preset: 'Big',
                            style: {}
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Her kan du skrive noget information om åbningstider og svartider på henvendelser.',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    style: {
                                        margin: {
                                            marginTop: 0.55
                                        }
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
