// Feed resolve description, thumbnail
// Meta tags resolve description thumbnail
// searchNodesImage(content)
// searchNodesDescription(content) 

export const searchDescription = (nodeMap) => searchNodes(nodeMap, node => {
    if (node.component === 'Text') {
        const description = node.props.text.blocks.reduce((acc, block) => (block.type === 'Normal' && acc.length === 0) ? block.text : acc, '');

        return description.length > 0 && description;
    }

    return null;
});

export const searchImage = (nodeMap, files) => searchNodes(nodeMap, node => {
    return node.component === 'Image' && files.lookup[node && node.props && node.props.src && node.props.src.toString()];
}); 

const searchNodes = (nodeMap, predicate, nodeID) => {
    const node = nodeMap[nodeID || 'root'];

    if (!node) return null; // Handles some edge cases from legacy data

    let match = predicate(node);

    if (match) {
        return match;
    } else if (node.children) {
        let match;
        
        node.children.some(childNodeID => {
            match = searchNodes(nodeMap, predicate, childNodeID);

            if (match) return true;
        });

        return match;
        // const match = node.children.find(childNodeID => searchNodes(nodeMap, predicate, childNodeID));

        // return nodeMap[match];
        // return node.children.find(childNodeID => searchNodes(nodeMap, predicate, childNodeID));
    } else {
        return null;
    }
};

// export const searchNodesDescription = (nodeMap, nodeID) => {
//     const node = nodeMap[nodeID || 'root'];

//     return node;
// };

export default searchNodes;