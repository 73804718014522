import React, { PureComponent } from 'react';
import {
    Grid,
    GridRow,
    GridColumn,
    Icon,
    Label,
    Image,
    Button,
    ButtonGroup,
    Segment,
    Popup,
    Divider,
    Message,
    Header
} from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import InfoIcon from 'components/InfoIcon';
// import FileModal from 'components/option-panel/options/file-picker/modal/FileModal';
import FilePicker from 'components/option-library/primitives/file';
import { connect } from 'react-redux';
import siteActions from 'store/actions/sites';
import themeActions from 'store/actions/sites/themes';
import { filesSelector } from 'store/selectors/sites/files';
import OverviewColor from './OverviewColor';
import OverviewText from './OverviewText';
import OverviewBody from './OverviewBody';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
    const site = state.sites.sites[siteid];
    const themeid = state.sites.themes.params.themeid;
	const { favicon, logoHorizontal, logo } = site;

	return {
		favicon,
		logoHorizontal,
		logo,
        siteid,
        themeid,
        site ,
        liveThemeId: state.sites.sites[siteid].activeTheme,
		files: filesSelector(state, siteid)
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
        setBranding: (...data) => dispatch(siteActions.setBranding(...data)),
        updateThemeSetting: (outerKey, innerKey, value) => dispatch(themeActions.updateThemeSetting(outerKey, innerKey, value)),
	};
};

@connect(mapStateToProps, mapDispatchToProps)
class DesignOverview extends PureComponent{
    constructor(props){
        super(props)
        this.state={
            open: false,
            loading: false,
            selectedPreset: null,
            selectedPresetOption: null
        };
        // this.renderModal = this.renderModal.bind(this);
		// this.toggleModal = this.toggleModal.bind(this);
		this.onFileReady = this.onFileReady.bind(this);
    }

    selectPreset = (preset) => this.setState({ selectedPreset: preset || null });
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });

    // toggleModal(fileType) {
	// 	this.setState({
	// 		open: !this.state.open && fileType
	// 	});
    // }

    clearImg =( fileType) => {
        this.props.setBranding({
            fileID: null,
            siteID: this.props.siteid,
            type: fileType
        });
	}

	async onFileReady(files, type) {
		this.setState({
			loading: true
		}, async () => {
			try {
				await this.props.setBranding({
					fileID: files,
					siteID: this.props.siteid,
					type
				});

				this.setState({
                    loading: false,
                    open: false
				});
			} catch (e) {
				this.setState({
                    loading: false,
                    open: false
				});
			}
		});
	}

	// renderModal() {
	// 	const {
	// 		intl,
	// 		siteid
    //     } = this.props;
        
    //     let selected;
    //     let text;

	// 	switch (this.state.open) {
	// 		case 'logo':
    //             selected = this.props.logo;
    //             // text = intl.formatMessage({ id: 'options.branding square logo title' });

	// 			break;
	// 		case 'logoHorizontal':
    //             selected = this.props.logoHorizontal;
    //             // text = intl.formatMessage({ id: 'options.branding horizontal logo title' });
			
	// 			break;
	// 		case 'favicon':
    //             selected = this.props.favicon;
    //             // text = intl.formatMessage({ id: 'options.branding favicon title' })
    //     }

	// 	return this.state.open && (
    //         <FilePicker 
    //             title={text}
    //             tabs={['files']}
    //             filter={file => file.meta.mimeType.startsWith('image')}
    //             onChange={this.onFileReady}
    //             value={selected}
    //         />
	// 		// <FileModal
    //         //     intl={intl}
    //         //     tabs={['files']}
	// 		// 	toggleModal={() => this.setState({ open: false })}
	// 		// 	siteid={siteid}
    //         //     onChange={this.onFileReady}
    //         //     value={selected}
    //         //     filter={file => file.meta.mimeType.startsWith('image')}
	// 		// />
	// 	);
    // }
 
    render(){
        const { siteid, favicon, logo, logoHorizontal, theme, themeid, intl, files } = this.props;

		// const faviconFile = files.find(({ _id }) => _id === favicon);
		// const logoFile = files.find(({ _id }) => _id === logo);
		// const logoHorizontalFile = files.find(({ _id }) => _id === logoHorizontal);

		// const faviconSrc = faviconFile ? faviconFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/favicon.png';
		// const logoSrc = logoFile ? logoFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg';
		// const horizontalLogoSrc = logoHorizontalFile ? logoHorizontalFile.url : 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/horizontal-logo.svg';
 
        const themeColorsArr= [];
        const themeTextArr = [];
        const themeColors = theme.colors;
        const themeTexts = theme.presets.text;

        for (let i=0; i < Object.keys(themeColors).length; i++){
            themeColorsArr[i] = {'id': Object.keys(themeColors)[i],
            'color': Object.values(themeColors)[i]}  
        }
        
        //generate Text array
        for (let i=0; i < Object.keys(themeTexts).length; i++){
            themeTextArr[i] = {'id': Object.keys(themeTexts)[i],
            'textProperty': Object.values(themeTexts)[i]}  
        }
        
        // for (let i=0; i<=5; i++){
        //     const  themeTextFilterArr = [];
        //     const idObj={
        //         0: 'Title',
        //         1: 'Normal',
        //         2: 'Heading 2',
        //         3: 'Big',
        //         4: 'Small',
        //         5: 'Quote'
        //     }
        //     themeTextFilterArr [i] = themeTextArr.filter( text => text.id === idObj[i])[0];
        // }
        
        const themeTextFilterArr = [];
        themeTextFilterArr [0] = themeTextArr.filter( text => text.id==='Title')[0];
        themeTextFilterArr [1] = themeTextArr.filter( text => text.id==='Normal')[0];
        themeTextFilterArr [2] = themeTextArr.filter( text => text.id==='Heading 2')[0];
        themeTextFilterArr [3] = themeTextArr.filter( text => text.id==='Big')[0];
        themeTextFilterArr [4] = themeTextArr.filter( text => text.id==='Small')[0];
        themeTextFilterArr [5] = themeTextArr.filter( text => text.id==='Quote')[0];
        //themeTextArr.filter( text => text.id==='Title' || text.id==='Normal' || text.id==='Heading 2'|| text.id==='Big' || text.id==='Small' || text.id==='Quote')

        return(
            <div style={{ backgroundColor: themeColors.tint }}>

                {/* theme preview options */}
                <Grid padded>
                    {/* <GridColumn computer={16} style={{ paddingBottom: 0 }}>
                        <Message className="secondary" id="design-overview-title">
                            <Icon name="sn-eye" style={{ fontSize: 24, opacity: 0.6, marginRight: 6, float: 'left' }} />
                            <span>
                                <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                                    <FormattedMessage id="design.overview message title" />:
                                </span>
                                <FormattedMessage id="design.overview message description" />
                                <InfoIcon className="secondary-color" description={intl.formatMessage({ id: 'design.overview message popup' })} position="bottom center" />
                            </span>
                        </Message>
                    </GridColumn> */}
                    
                    {/* Color palette */}
                    <GridColumn computer={16} tablet={16} mobile={16}>
                        <Grid celled verticalAlign="middle" style={{ margin: 0, borderRadius: 4 }}>
                            <div style={{ width: '100%', padding: 8, backgroundColor: themeColors.secondary }}>
                                <p style={{ width: '100%', color: themeColors.tint, fontSize: 16, margin: 0 }}>
                                    {/* <span style={{ float: 'right', fontSize: 14 }}>
                                        <FormattedMessage id="options.color palette examples title" />
                                    </span>  */}

                                    <Icon name="sn-droplet" />
                                    <FormattedMessage id="options.colors title" />
                                </p>
                            </div>
                    
                            {themeColorsArr.map(themeColor =>
                                <OverviewColor 
                                    key={themeColor.id} 
                                    themeColor={themeColor} 
                                    intl={this.props.intl}
                                    onChange={(e, d) => this.props.updateThemeSetting('colors', `${themeColor.id}`, d.value)}
                                />
                            )}
                        </Grid>
                    </GridColumn>

                    <GridColumn computer={10} tablet={10} mobile={16}>
                        {/* Text types overview */}
                        <Grid celled style={{ margin: 0, borderRadius: 4, overflow: 'hidden' }}>
                            <div style={{ width: '100%', padding: 8, backgroundColor: themeColors.shade }}>
                                <p style={{ fontSize: 16, margin: 0, display: 'inline-block', color: themeColors.tint }}>
                                    <Icon name="sn-typography" style={{ verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        <FormattedMessage id="design.typography overview title" />
                                    </span>
                                    {/* <FormattedMessage id="design.overview content structure title" /> */}
                                    {/* <InfoIcon description={intl.formatMessage({ id: 'design.overview content structure description' })} /> */}
                                </p>
                            </div>
                            <GridRow>
                                <GridColumn computer={16}>
                                    <Label basic size="medium" style={{ position: 'absolute', top: 8, right: 4, opacity: 0.8, zIndex: 2 }}>
                                        <Icon name="sn-typography" /> <FormattedMessage id="elements.texts title" />
                                    </Label>
                                    {themeTextFilterArr.map(text => <OverviewText
                                                                        key={text.id}
                                                                        presetTitle ={text.id}
                                                                        text={text}
                                                                        theme={theme}
                                                                        intl={intl}
                                                                        clearSelectedPreset={() => this.selectPreset(null)}
                                                                        selectPresetfunc={() => this.selectPreset(text.id)}
                                                                        selectedPreset={this.state.selectedPreset}
                                                                        
                                                                        selectPresetOptionfunc={this.selectPresetOption}
                                                                        selectedPresetOption={this.state.selectedPresetOption}
                                                                        
                                                                        />
                                    )}
                               </GridColumn>
                               <GridColumn computer={16} textAlign="center">
                                    <Link to={`/dashboard/${siteid}/design/${themeid}/elements/text`} style={{textDecoration: 'none'}} >
                                        <Button basic size="mini" fluid >
                                            <Icon name="sn-typography" />
                                            <FormattedMessage id="design.go to elements text button" />
                                        </Button>
                                    </Link>
                                </GridColumn>
                            </GridRow>  
                        </Grid>
                    </GridColumn>

                    <GridColumn computer={6} tablet={6} mobile={16}>
                        {/* Branding options */}
                        <Grid celled verticalAlign="top" style={{ margin: 0, borderRadius: 4, overflow: 'hidden' }}>
                            <div style={{ width: '100%', padding: 8, backgroundColor: themeColors.primary }}>
                                <p style={{ fontSize: 16, color: themeColors.tint, margin: 0, display: 'inline-block' }}>
                                    <Icon name="sn-star-full" style={{ verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        <FormattedMessage id="design.site brand overview title" />
                                    </span>
                                    {/* <FormattedMessage id="design.overview content structure title" /> */}
                                    {/* <InfoIcon description={intl.formatMessage({ id: 'design.overview content structure description' })} /> */}
                                </p>
                            </div>
                            <GridRow verticalAlign="top">
                                {/* {this.renderModal()} */}
                                {/* <Label basic size="medium" style={{ position: 'absolute', top: 8, right: 4, opacity: 0.8, zIndex: 2 }}>
                                    <Icon name="sn-star-full" /> <FormattedMessage id="options.branding title" />
                                </Label> */}

                                <GridColumn computer={8} tablet={8} mobile={8} textAlign="center">
                                    <Header
                                        as="h4"
                                        textAlign="left"
                                        icon="sn-star-empty"
                                        style={{ fontSize: 18, fontWeight: 500, marginBottom: 4 }}
                                        content={
                                            <React.Fragment>
                                                <FormattedMessage id="options.branding favicon title" />
                                                <InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding favicon description' })} />
                                            </React.Fragment>
                                        }
                                    />
                                    <FilePicker 
                                        title={<FormattedMessage id="options.branding title" />}
                                        tabs={['files']}
                                        filter={file => file.meta.mimeType.startsWith('image')}
                                        onChange={(files) => this.onFileReady(files, 'favicon')}
                                        value={favicon}
                                    />
                                    {/* <Divider style={{ marginBottom: 0 }} /> */}

                                    {/* <Segment className="branding-preview">
                                        <Image src={faviconSrc} width="16px" height="16px" style={{ display: 'inline-block', marginBottom: 8 }} />
                                        <p><FormattedMessage id="options.branding favicon title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding favicon description' })} /></p>
                                    </Segment> */}
                                    {/* <ButtonGroup size="tiny" fluid>
                                        <Popup
                                            trigger={
                                                <Button basic className="positive" onClick={() => this.toggleModal('favicon')}>
                                                    <Icon name="sn-star-empty" />
                                                    <FormattedMessage id="general.change" />
                                                </Button>
                                            }
                                            content={this.props.intl.formatMessage({ id: 'options.branding change favicon description' })}
                                            position="bottom center"
                                        />
                                        <Popup
                                            trigger={
                                                <Button
                                                basic
                                                    icon="sn-cross"
                                                    className="negative"
                                                    onClick={() => this.clearImg('favicon')}
                                                    style={{ maxWidth: 40 }}
                                                    />
                                                }
                                            content={intl.formatMessage({ id: 'options.branding clear favicon popup' })}
                                            position="bottom center"
                                        />
                                    </ButtonGroup> */}
                                </GridColumn>

                                <GridColumn computer={8} tablet={8} mobile={8} textAlign="center">
                                    {/* <Segment className="branding-preview">
                                        <Image src={logoSrc} style={{ display: 'inline-block', marginBottom: 8 }} />
                                        <p><FormattedMessage id="options.branding square logo title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding square logo description' })} /></p>
                                    </Segment> */}
                                    
                                    <Header
                                        as="h4"
                                        textAlign="left"
                                        icon="sn-star-empty"
                                        style={{ fontSize: 18, fontWeight: 500, marginBottom: 4 }}
                                        content={
                                            <React.Fragment>
                                                <FormattedMessage id="options.branding square logo title" />
                                                <InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding square logo description' })} />
                                            </React.Fragment>
                                        }
                                    />
                                    <FilePicker 
                                        title={<FormattedMessage id="options.branding title" />}
                                        tabs={['files']}
                                        filter={file => file.meta.mimeType.startsWith('image')}
                                        onChange={(files) => this.onFileReady(files, 'logo')}
                                        value={logo}
                                    />
                                    {/* <Divider style={{ marginBottom: 0 }} /> */}
                                    {/* <ButtonGroup size="tiny" fluid>
                                        <Popup
                                            trigger={
                                                <Button basic size="tiny" className="positive" onClick={() => this.toggleModal('logo')}>
                                                    <Icon name="sn-star-empty" />
                                                    <FormattedMessage id="general.change" />
                                                </Button>
                                            }
                                            content={this.props.intl.formatMessage({ id: 'options.branding change square logo description' })}
                                            position="bottom center"
                                        />
                                        <Popup
                                            trigger={
                                                <Button
                                                basic
                                                icon="sn-cross"
                                                className="negative"
                                                onClick={() => this.clearImg('logo')}
                                                style={{ maxWidth: 40 }}
                                                />
                                            }
                                            content={intl.formatMessage({ id: 'options.branding clear square logo popup' })}
                                            position="bottom center"
                                        />
                                    </ButtonGroup> */}
                                </GridColumn>
                                
                                <GridColumn computer={16} tablet={16} mobile={16} textAlign="center">
                                    {/* <Segment className="branding-preview">
                                        <Image src={horizontalLogoSrc} style={{ display: 'inline-block', marginBottom: 8 }} />
                                        <p><FormattedMessage id="options.branding horizontal logo title" /><InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding horizontal logo description' })} /></p>
                                    </Segment> */}
                                    
                                    <Divider style={{ margin: 0 }} />

                                    <Header
                                        as="h4"
                                        textAlign="left"
                                        icon="sn-star-empty"
                                        style={{ fontSize: 18, fontWeight: 500, marginBottom: 4 }}
                                        content={
                                            <React.Fragment>
                                                <FormattedMessage id="options.branding horizontal logo title" />
                                                <InfoIcon description={this.props.intl.formatMessage({ id: 'options.branding horizontal logo description' })} />
                                            </React.Fragment>
                                        }
                                    />
                                    <FilePicker 
                                        title={<FormattedMessage id="options.branding title" />}
                                        tabs={['files']}
                                        filter={file => file.meta.mimeType.startsWith('image')}
                                        onChange={(files) => this.onFileReady(files, 'logoHorizontal')}
                                        value={logoHorizontal}
                                    />
                                    
                                    {/* <ButtonGroup size="tiny" fluid>
                                        <Popup
                                            trigger={
                                                <Button basic size="tiny" className="positive" onClick={() => this.toggleModal('logoHorizontal')}>
                                                    <Icon name="sn-star-empty" />
                                                    <FormattedMessage id="general.change" />
                                                </Button>
                                            }
                                            content={this.props.intl.formatMessage({ id: 'options.branding change horizontal logo description' })}
                                            position="bottom center"
                                        />
                                        <Popup
                                            trigger={
                                                <Button
                                                basic
                                                icon="sn-cross"
                                                className="negative"
                                                onClick={() => this.clearImg('logoHorizontal')}
                                                style={{ maxWidth: 40 }}
                                                />
                                            }
                                            content={intl.formatMessage({ id: 'options.branding clear horizontal logo popup' })}
                                            position="bottom center"
                                        />
                                    </ButtonGroup> */}
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </GridColumn>
                </Grid>
                
                {/* horizontal divider */}
                <Divider horizontal>
                    <Icon circular name="plus" className="primary" style={{ fontSize: 24, opacity: 0.6, margin: 0 }} />
                </Divider>
                
                <OverviewBody
                    intl={intl}
                    theme={theme}
                    selectPresetOptionfunc={this.selectPresetOption}
                    selectedPresetOption={this.state.selectedPresetOption}
                    presetTitle='Primary'
                    preset={theme.presets.body.Primary}
                />
            </div>
        )
    }
}
export default injectIntl(DesignOverview);