import React, { PureComponent } from 'react';
import {
	Icon,
	Header,
	Grid,
	GridColumn,
	GridRow,
	Label,
	Form,
	FormInput,
	FormGroup
} from 'svift-ui';
import PropTypes from 'prop-types';
import Money from 'svift-library/format/money';
import { FormattedMessage, injectIntl } from 'react-intl';

class CostOverview extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showMetaData: false
		};

		this.renderSubscriptionRows = this.renderSubscriptionRows.bind(this);
		this.renderSubscriptionRow = this.renderSubscriptionRow.bind(this);
	}
		
	getDiscountPercent = () => {
		const discount = this.props.subscription.discount;

		return (discount && discount.coupon && discount.coupon.percent_off) || 0;
	}
	
	getYearlyBaseCost = () => {
		const cost = this.props.subscription.items.data.reduce((accumulatedAmount, item) => {
			const itemCost = Money(item.plan.amount, { currency: 'DKK' })
				.divide(100) // ØRE -> DKK
				.amount;

			return accumulatedAmount.add(itemCost);
		}, Money(0, { currency: 'DKK' }));
		
		return cost;
	}

	renderSubscriptionRows(items) {
		const rows = items.map(this.renderSubscriptionRow);

		const discount = this.getDiscountPercent();
		const cost = this.getYearlyBaseCost();

		const costWithVAT = cost.addPercentages(this.props.subscription.tax_percent);
		const VAT = costWithVAT.subtract(cost.amount);

		rows.push(
			<GridRow key="VATLINE">
				<GridColumn computer={16} style={{ display: 'flex', alignItems: 'center' }}>
					<FormattedMessage id="general.vat title" />
					<Label basic circular size="tiny" style={{ marginLeft: 'auto' }}>
						{VAT.readable()} / <FormattedMessage id="general.year" />
					</Label>
				</GridColumn>
			</GridRow>
		);

		return rows;
	}

	renderSubscriptionRow(item) {
		const {
			plan
		} = item;

		const {
			amount,
			currency,
			nickname
		} = plan;

		console.log(item, 'item')

		const money = Money(amount)
			.divide(100); // ØRE -> DKK

		return (
			<GridRow key={item.id}>
				<GridColumn computer={16} style={{ display: 'flex', alignItems: 'center' }}>
					{/* subscription icom */}
					<Icon name="sn-subscription-general" className="light-color" style={{ marginRight: 8, fontSize: 24 }} />

					{/* item name */}
					{nickname}

					{/* price tag */}
					<Label
						basic
						circular
						size="tiny"
						className="info"
						style={{
							marginLeft: 'auto',
						}}
					>
						{/* cost per year */}
						{money.readable()} / <FormattedMessage id="general.year" />
					</Label>
				</GridColumn>
			</GridRow>
		);
	}

	renderTotalCost() {
		const discount = this.getDiscountPercent();
		const cost = this.getYearlyBaseCost();

		let displayCost = cost
			.addPercentages(this.props.subscription.tax_percent)
			.subtractPercentages(discount)
			.readable();

		if (discount) displayCost = `${displayCost} (-${discount}%)`;

		return displayCost;
	}

	render() {
		if (!this.props.subscription) return null;

		const {
			orgName,
			vatNumber,
			address,
			zipCode,
			city,
			contactEmail,
			contactPhoneNumber
		} = this.props.subscription.metadata;

		return [
			<p key="zero">
				<FormattedMessage id="settings.subscription section description" />
			</p>,

			<Grid key="one" celled style={{ textAlign: 'left', marginBottom: 8 }}>
				<GridRow>
					<GridColumn computer={16} style={{ display: 'flex', alignItems: 'center' }}>
						<span style={{ opacity: 0.6 }}>
							<Icon name="sn-subscription-base" style={{ marginRight: 8, fontSize: 16 }} />
							<FormattedMessage id="settings.subscription unit overview title" />
						</span>

						{/*<Button size="mini" icon className="positive" style={{ marginLeft: 'auto' }}><Icon name="plus" /> Add extra</Button>*/}
					</GridColumn>
				</GridRow>

				{this.renderSubscriptionRows(this.props.subscription.items.data)}

				{/*<GridRow>
					<GridColumn computer={16} style={{ display: 'flex', alignItems: 'center' }}>
						<Icon name="plug" className="info" style={{ marginRight: 16, fontSize: 16 }} />
						domain-name.do
						<Label basic circular size="tiny" className="info" style={{ marginLeft: 16 }}>domain</Label>
						<Label basic circular size="tiny" style={{ marginLeft: 8 }}>Included in price</Label>
						<Icon link name="setting" className="primary" style={{ marginLeft: 'auto', fontSize: 20 }} />
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn computer={16} style={{ display: 'flex', alignItems: 'center' }}>
						<Icon name="newspaper" style={{ marginRight: 16, fontSize: 16 }} />
						Blog
						<Label basic circular size="tiny" className="info" style={{ marginLeft: 16 }}>Addon</Label>
						<Label basic circular size="tiny" className="free-color" style={{ marginLeft: 8 }}>Free</Label>
						<Icon link name="setting" className="primary" style={{ marginLeft: 'auto', fontSize: 20 }} />
					</GridColumn>
				</GridRow>*/}
			</Grid>,

			<p key="two" style={{ marginBottom: 4 }}>
				<FormattedMessage id="settings.subscription total cost per year" />:
			</p>,

			<Label
				basic
				key="three"
				size="big"
				className="info"
				style={{ marginBottom: 16 }}
			>
				{this.renderTotalCost()} / <FormattedMessage id="general.year" />
			</Label>,

			<div
				key="four"
				style={{ marginBottom: 16 }}
				onClick={() => {
					this.setState({
						showMetaData: !this.state.showMetaData
					});
				}}
			>
				<Icon circular name={this.state.showMetaData ? 'minus' : 'plus'} style={{ marginRight: 8, fontSize: 10 }} />
				<a><FormattedMessage id="settings.subscription show organization details link" /></a>
			</div>,

			this.state.showMetaData &&
			<div style={{ marginBottom: 16 }}>
				<Form key="five">
					<Header as="h4" style={{ marginBottom: 24 }}>
						<FormattedMessage id="settings.subscription organization details title" />
					</Header>

					<FormInput
						value={orgName}
						size="big"
						fluid
						label={this.props.intl.formatMessage({ id: 'settings.subscription organization details name label' })}
						placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details name placeholder' })}
					/>

					<FormInput
						value={vatNumber}
						label={this.props.intl.formatMessage({ id: 'settings.subscription organization details vat number label' })}
						placeholder="12345678"
					/>

					<FormGroup>
						<FormInput
							value={address}
							label={this.props.intl.formatMessage({ id: 'settings.subscription organization details street name label' })}
							placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details street name placeholder' })}
						/>
						<FormInput
							value={zipCode}

							label={this.props.intl.formatMessage({ id: 'settings.subscription organization details street number label' })}
							placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details street number placeholder' })}
						/>
					</FormGroup>

					<FormInput
						value={city}
						label={this.props.intl.formatMessage({ id: 'settings.subscription organization details city label' })}
						placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details city placeholder' })}
					/>
					<FormInput
						value={contactEmail}
						label={this.props.intl.formatMessage({ id: 'settings.subscription organization details email label' })}
						placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details email placeholder' })}
					/>
					<FormInput
						value={contactPhoneNumber}
						label={this.props.intl.formatMessage({ id: 'settings.subscription organization details phone number label' })}
						placeholder={this.props.intl.formatMessage({ id: 'settings.subscription organization details phone number placeholder' })}
					/>
					
					{/* TO DO (LATER) */}
					{/*<Button positive ><Icon name="sn-cloud-upload" /> Save organisation details</Button> */}
				</Form>
			</div>
		];
	}
}

CostOverview.propTypes = {
	subscription: PropTypes.object
};

export default injectIntl(CostOverview);
