import React, { PureComponent } from 'react';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
// import Styling from 'svift-library/client/renderer/nodes/Styling.js';
import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import PropTypes from 'prop-types';
import Radium from 'radium';
// import {
// 	RadiumInput,
// 	RadiumTextArea
// } from 'svift-ui';

// const RadiumInput = Radium(props => {
// 	return <input {...props} />;
// });
const RadiumInput = Radium(class Input extends React.PureComponent {
	render() {
		return <input {...this.props} />;
	}
});

const RadiumTextArea = Radium(class TextArea extends React.PureComponent {
	render() {
		return <textarea {...this.props} />;
	}
});

// const RadiumTextArea = Radium(props => {
// 	return <textarea {...props} />;
// });

class FormInput extends PureComponent {
	static mapContextToProps = (props, state) => {
		const { theme, formObj } = props.context;
		
		return shallowCompare({
			theme,
			setField: formObj && formObj.setField
		}, state);
	}

	constructor(props) {
		super(props);

		this.state = {
			text: ''
		};

		this.setText = this.setText.bind(this);
		this.setError = this.setError.bind(this);
		this.renderElement = this.renderElement.bind(this);
	}

	setText(e) {
		const value = e.target.value;

		this.setState({
			text: value,
			validationError: null
		});
	}

	setError(error) {
		this.setState({
			validationError: error
		});
	}

	validate() {
		const { node } = this.props;

		const {
			mode,
			required
		} = node.props;

		const text = this.state.text;

		if (text.length === 0 && required) {
			this.setError('Dette felt er påkrævet');

			return false;
		}

		if (mode === 'number') {
			if (isNaN(text)) {
				this.setError('valid number required');

				return false;
			}
		} else if (mode === 'email') {
			if (!required && this.state.text.length === 0) {
				return true;
			}

			const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			const valid = emailRegEx.test(this.state.text);

			if (!valid) {
				this.setError('invalid email format');

				return false;
			}
		}

		return true;
	}

	componentDidMount() {
		if (this.props.setField) this.props.setField(this);
		// if (this.context.form) this.context.form.setField(this);
	}

	renderElement() {
		const { theme, node, computePreset } = this.props;

		const {
			mode,
			label,
			placeholder
		} = node.props;

		const presetKey = node.props.preset;

		let labelStyle = computePreset({ ...theme.presets.input[presetKey].label, ...node.props && node.props.style && node.props.style.label }, theme);

		// console.log(theme.presets.input[presetKey].label, 'hello')

		let style = {
			...this.props.computedStyling,
			resize: 'vertical',
			outline: 'none',
			width: '100%'
		};

		
		if (this.state.validationError) {
			const errorStyle = computePreset(theme.presets.input[presetKey].error.input, theme);
			const errorLabelStyle = computePreset(theme.presets.input[presetKey].error.label, theme);

			style = {
				...style,
				...errorStyle
			};

			labelStyle = {
				...labelStyle,
				...errorLabelStyle
			};
		}

		// console.log(labelStyle, 'label style');

		if (mode === 'textArea' || typeof mode === 'undefined') {
			return (
				<label htmlFor="text" style={{ width: '100%' }}>
					<p
						style={labelStyle}
					>
						{label}<br />

						{this.state.validationError && `*${this.state.validationError}`}
					</p>

					<RadiumTextArea
						name="text"
						style={style}
						onChange={this.setText}
						value={this.state.text}
						placeholder={placeholder}
					/>
				</label>
			);
		}

		return (
			<label htmlFor="text" style={{ width: '100%' }}>
				<p
					style={labelStyle}
				>
					{label}<br />

					{this.state.validationError && `*${this.state.validationError}`}
				</p>

				<RadiumInput
					name="text"
					type={mode}
					style={style}
					size={node.props.size || 'medium'}
					onChange={this.setText}
					placeholder={placeholder}
					value={this.state.text}
				/>
			</label>
		);
	}

	render() {
		const { node } = this.props;

		return this.renderElement();
	}
}

// FormInput.contextTypes = {
// 	form: PropTypes.object
// };

export default FormInput;
