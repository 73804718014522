import React, { PureComponent } from 'react';
import { GridColumn, Grid, GridRow, Popup, Button, Icon, Menu, Dropdown } from 'svift-ui';
// import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import Tooltip from 'svift-library/client/tooltip';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';

class BorderRadiusOption extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			selectedOption: null
		};

		// this.tooltip = tooltip.call(this);
		this.tooltipRef = React.createRef();

		this.onChange = this.onChange.bind(this);
		this.selectOption = this.selectOption.bind(this);
	}

	onChange(key, newValue) {
		this.props.onChange(key, newValue);
	}

	componentWillReceiveProps(nextProps) {
		// Resets the open tooltips whenever this component gets selected or unselected
		if (this.props.selectedOption !== nextProps.selectedOption) {
			this.selectOption(null);
		}
	}

	selectOption(option) {
		this.setState({
			selectedOption: option
		});
	}

	render() {
		const { option, onToggle, selectedOption, value, min = 1, max = 32, intl } = this.props;

		const selected = option === selectedOption; // Not to be confused with selectedOption in state

		const { generalBorderRadius, borderBottomLeftRadius, borderTopLeftRadius, borderTopRightRadius, borderBottomRightRadius } = value;

		return (
			<div className="option" style={{ display: 'inline-block' }} >
				{/* <Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					position="top"
					content={

					}
					hover={this.state.hover}
					hoverContent={intl.formatMessage({ id: 'options.border radius title' })}
					style={{ zIndex: 999 }}
				/> */}

				<Tooltip
					popup
					open={selected}
					target={this.tooltipRef.current}
					zIndex="999"
				>
					<div className="mini-panel">
						<p className="option-title" style={{ textAlign: 'center' }}><FormattedMessage id="options.border radius title" /></p>
						<Grid columns={16} centered verticalAlign="middle" className="manipulator border-radius-options">
							<GridRow centered columns={3} style={{ paddingTop: 4 }}>
								<GridColumn textAlign="right">
									<Slider
										optIn={this.props.optIn.borderTopLeftRadius}
										onClear={() => {
											if (typeof borderTopLeftRadius === 'undefined') return;

											this.props.onChange('borderTopLeftRadius', null);
										}}
										optionalDisabled={typeof borderTopLeftRadius === 'undefined'}

										activeButton={typeof borderTopLeftRadius !== 'undefined'}

										icons={['sn-enlarge']}
										title={`${intl.formatMessage({ id: 'options.border radius top left title' })} ${typeof borderTopLeftRadius === 'undefined' ? '' : `: ${Math.floor(borderTopLeftRadius)}px`}`}
										onChange={newValue => {
											this.props.onChange('borderTopLeftRadius', newValue);
										}}
										value={borderTopLeftRadius || 1}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderTopLeftRadius"
									/>
								</GridColumn>
								<GridColumn />
								<GridColumn textAlign="left">
									<Slider
										optIn={this.props.optIn.borderTopRightRadius}
										onClear={() => {
											if (typeof borderTopRightRadius === 'undefined') return;

											this.props.onChange('borderTopRightRadius', null);
										}}
										optionalDisabled={typeof borderTopRightRadius === 'undefined'}

										activeButton={typeof borderTopRightRadius !== 'undefined'}

										icons={['sn-enlarge']}
										title={`${intl.formatMessage({ id: 'options.border radius top right title' })} ${typeof borderTopRightRadius === 'undefined' ? '' : `: ${Math.floor(borderTopRightRadius)}px`}`}
										onChange={newValue => {
											this.props.onChange('borderTopRightRadius', newValue);
										}}
										value={borderTopRightRadius || 1}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderTopRightRadius"
									/>
								</GridColumn>
							</GridRow>

							{/* Panel row */}
							<GridRow centered columns={3}>
								<GridColumn />
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.generalBorderRadius}
										onClear={() => {
											if (typeof generalBorderRadius === 'undefined') return;

											this.props.onChange('generalBorderRadius', null);
										}}
										optionalDisabled={typeof generalBorderRadius === 'undefined'}

										activeButton={typeof generalBorderRadius !== 'undefined'}

										icons={['sn-enlarge']}
										title={`${intl.formatMessage({ id: 'options.border radius general title' })} ${typeof generalBorderRadius === 'undefined' ? '' : `: ${Math.floor(generalBorderRadius)}px`}`}
										onChange={newValue => {
											this.props.onChange('generalBorderRadius', newValue);
										}}
										value={generalBorderRadius || 1}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderRadius"
									/>
								</GridColumn>
								<GridColumn />
							</GridRow>

							{/* Panel row */}
							<GridRow centered columns={3} style={{ paddingBottom: 4 }}>
								<GridColumn textAlign="right">
									<Slider
										optIn={this.props.optIn.borderBottomLeftRadius}
										onClear={() => {
											if (typeof borderBottomLeftRadius === 'undefined') return;

											this.props.onChange('borderBottomLeftRadius', null);
										}}
										optionalDisabled={typeof borderBottomLeftRadius === 'undefined'}

										activeButton={typeof borderBottomLeftRadius !== 'undefined'}

										icons={['sn-enlarge']}
										title={`${intl.formatMessage({ id: 'options.border radius bottom left title' })} ${typeof borderBottomLeftRadius === 'undefined' ? '' : `: ${Math.floor(borderBottomLeftRadius)}px`}`}
										onChange={newValue => {
											this.props.onChange('borderBottomLeftRadius', newValue);
										}}
										value={borderBottomLeftRadius || 1}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderBottomLeftRadius"
									/>
								</GridColumn>
								<GridColumn />
								<GridColumn textAlign="left">
									<Slider
										optIn={this.props.optIn.borderBottomRightRadius}
										onClear={() => {
											if (typeof borderBottomRightRadius === 'undefined') return;

											this.props.onChange('borderBottomRightRadius', null);
										}}
										optionalDisabled={typeof borderBottomRightRadius === 'undefined'}

										activeButton={typeof borderBottomRightRadius !== 'undefined'}

										icons={['sn-enlarge']}
										title={`${intl.formatMessage({ id: 'options.border radius bottom right title' })} ${typeof borderBottomRightRadius === 'undefined' ? '' : `: ${Math.floor(borderBottomRightRadius)}px`}`}
										onChange={newValue => {
											this.props.onChange('borderBottomRightRadius', newValue);
										}}
										value={borderBottomRightRadius || 1}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="borderBottomRightRadius"
									/>
								</GridColumn>
							</GridRow>
						</Grid>
					</div>
				</Tooltip>

				<Button
					ref={this.tooltipRef} //.setTarget}
					basic
					size="tiny"
					icon="sn-enlarge"
					className={`primary ${value && Object.keys(value).length > 0 ? 'active-button' : ''}`}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				/>
			</div>
		);
	}
}

BorderRadiusOption.propTypes = {
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func,
	optIn: PropTypes.object
};

BorderRadiusOption.defaultProps = {
	optIn: {
		borderBottomLeftRadius: true,
		borderBottomRightRadius: true,
		borderTopLeftRadius: true,
		borderTopRightRadius: true,
		generalBorderRadius: true
	}
};

export default injectIntl(BorderRadiusOption);