import React from 'react';
import { FormField, FormTextArea, Message } from 'svift-ui';
import InfoIcon from 'components/InfoIcon';

const generateInput = options => props => {
	const { icon, placeholder, name, label } = options;
	const { input, infoIcon, intl, meta: { error }, style } = props; // Provided by Field component from redux-form

	const className = error && 'error';

	return (
		<FormField>
			<FormTextArea
				rows={3}
				{...input}
				className={className}
				label={label &&
					<label>
						{intl.formatMessage({ id: label })}
						{infoIcon ? <InfoIcon description={infoIcon} /> : null}
					</label>
				}
				placeholder={intl.formatMessage({ id: placeholder })}
				style={style}
			/>
			{error &&
				<Message negative>
					{error(intl)}
				</Message>
			}
		</FormField>
	);
};

export default generateInput;

