let id = 1;

const addStyle = css => {
	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');

	style.type = 'text/css';
	style.id = `INJECTED_STYLE_${id++}`;

	if (style.styleSheet) {
		style.styleSheet.cssText = css;
	} else {
		style.appendChild(document.createTextNode(css));
	}

	head.appendChild(style);

	return style.id;
};

const removeStyle = id => {
	const injectedStyleElement = document.getElementById(id);

	if (injectedStyleElement) {
		injectedStyleElement.parentNode.removeChild(injectedStyleElement);
	}
};

const clearStyles = (injectedStyleIds) => {
	return injectedStyleIds.filter(id => {
		removeStyle(id);

		return false;
	});
};
// const dndScrollCase = `
// 	.target-zone-scroll-top,
// 	.target-zone.scroll-bottom
// `;

const gridCase = `
	.grid > .target-zone-top,
	.grid > .target-zone-bottom { display: block; }
`;
const rowCase = `
	.target-zone-top,
	.target-zone-bottom { display: block; }
`;

const columnCase = `
	.target-zone-top,
	.target-zone-bottom,
	.target-zone-left,
	.target-zone-right,
	.target-zone-center-left-parent,
	.target-zone-center-right-parent,
	.target-zone-top-parent,
	.target-zone-bottom-parent { display: block; }
`;


export { rowCase };
export { gridCase };
export { columnCase };
export { removeStyle };
export { clearStyles };

export default addStyle;

