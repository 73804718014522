import React, { useState, useMemo } from 'react';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { Icon, Message, Loader } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import config from 'config';
import uuid from 'uuid/v4';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import useRendererData from 'svift-library/client/renderer/data/client/useRendererData';
import useRendererState from 'svift-library/client/renderer/useRendererState';
import PreviewContainer from 'components/editor-v2/editor-interface/preview/PreviewContainer';
import ToggleDraftAndLivePreview from 'components/editor-v2/editor-interface/preview/ToggleDraftAndLivePreview';
import createExtensions from 'components/editor-v2/extend';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import formActions from 'store/actions/sites/forms';
import SelectForm from './SelectForm';
import FormSettings from './FormSettings';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import OpenPreview from 'components/editor-v2/editor-interface/preview/OpenPreview';
import Notification from 'components/hoc/notification';

const RendererData = props => {
    const [previewMode, setPreviewMode] = useState(null);

    const { data, error, errorMessage, loading } = useRendererData({
        siteID: props.siteID,
        contentID: props.formID, 
        contentType: 'form',
        isAdmin: true,
        host: config.coreDomain,
        basePath: '/sites/renderer',
        refetchOnValueChange: [props.siteID, props.formID]
    });

    if (loading) return (
        <Message size="massive" className="info" style={{ padding: '64px 24px 96px 24px', textAlign: 'center' }}>
            {/* loading message */}
            <div style={{ marginTop: 0, marginBottom: 40 }}>
                <div>
                    <Icon
                        name="send outline"
                        style={{
                            margin: '0 0 24px 0',
                            fontSize: 96,
                            width: 96,
                            opacity: 0.5
                        }}
                    />
                </div>
                <span style={{ fontSize: 40 }}>
                    <FormattedMessage id="forms.loading form" />
                </span>
            </div>
            {/* load spinner */}
            <Loader size="massive" className="info" />
        </Message>
    );
    if (error) return errorMessage;

    // console.log(data, 'form data');

    return React.cloneElement(props.children, {
        data,
        previewMode,
        setPreviewMode,
        ...props
    });
}

const mapDispatchToProps = dispatch => ({
	publishDraft: (siteid, formid, nodeMap) => dispatch(formActions.publishDraft(siteid, formid, nodeMap)),
	saveDraft: (siteid, formid, nodeMap) => dispatch(formActions.saveDraft(siteid, formid, nodeMap))
});

const FormRenderer = Notification(injectIntl(connect(null, mapDispatchToProps)(props => {
    const { data, previewMode, setPreviewMode, publishDraft, saveDraft, intl, createNotification, device } = props;
    const { extend, extendAll, renderAtRoot } = useMemo(() => {
        return createExtensions(previewMode);
    }, [previewMode]); 

    const formProps = useMemo(() => {
        const props = {
            ...data,
            resolveContent: state => state.content.content,
            staticKeys: ['content'],
            // shouldSwapNodeMap: (state, newProps) => {
            //     if (newProps.content !== state.content) return true;
            // },
            onSave: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await saveDraft(props.siteID, data.content._id, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.FORMS.DRAFT_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'forms.save notification' }),
                    className: 'positive',
                    position: 'topCenter',
                    icon: 'sn-cloud-upload',
                    deleteTime: 2000
                });

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            onPublish: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await publishDraft(props.siteID, data.content._id, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.FORMS.LIVE_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'forms.publish notification' }),
                    className: 'warning',
                    position: 'topCenter',
                    icon: 'sn-sphere',
                    deleteTime: 2000
                });

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            },
                            live: {
                                ...state.content.content.live,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            extend
        };

        if (previewMode === null) {
            props.extend = {
                ...extend,
                all: extendAll,
            };
        }

        if (previewMode === 'draft' || previewMode === null) {
            props.useDraft = true;
        }

        return props;
    }, [data, previewMode, device]);

    const contentState = useRendererState(formProps);

    // const resolvePlaceholder = useMemo(() => { 
    //     return () => (
    //         'PAGE GOES HERE'
    //         // <Renderer 
    //         //     {...contentState}
    //         //     renderAtRoot={renderAtRoot}
    //         // />
    //     );
    // }, []);
    
    // const formProps = useMemo(() => {
    //     return {
    //         ...data,
    //         extend,
    //         resolveContent: state => state.form.content,
    //         resolvePlaceholder
    //     };
    // }, [data, resolvePlaceholder]);

    // const contentState = useRendererState(formProps);

    const renderPreview = useMemo(() => {
        const { all, ...rest } = contentState.rendererState.extend;

        if (previewMode === 'live') {
            return () => (
                <Renderer
                    key={previewMode}
                    {...{
                        ...contentState,
                        rendererState: {
                            ...contentState.rendererState,
                            nodeMap: contentState.rendererState.content.content.live.nodeMap,
                            breakpoints: contentState.rendererState.content.content.live.breakpoints,
                            extend: rest
                        }
                    }}
                />
            );
        }

        return () => (
            <Renderer
                key={previewMode}
                {...{
                    ...contentState,
                    rendererState: {
                        ...contentState.rendererState,
                        extend: rest
                    }
                }}
            />
        );
    }, [previewMode, contentState])

    const formTitle = data.content.title;
    const createFormPopup = useTooltip();
    const formSettingsPopup = useTooltip();
    const closePreviewPopup = useTooltip();

    return (
        <PreviewContainer active={previewMode}>
            {/* create form popup */}
            <Popup
                target={createFormPopup.current}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="forms.create new form" />
            </Popup>

            <AppNavigation
                previewMode={previewMode}
                appIcon="send outline"
                appTitle={intl.formatMessage({ id: 'forms.title' })}
                appActionStyle={{ padding: 0 }} // fix to facilitate the alternative rendering method of create new page button (padding is set on each button)
                appAction={
                    previewMode === null 
                        ?
                            <FormSettings
                                renderButton={toggleFormOpen => {
                                    return (
                                        <div
                                            id="forms-new-form-button"
                                            ref={createFormPopup}
                                            onClick={() => toggleFormOpen('create')}
                                            style={{ padding: 6 }}
                                        >
                                            <Icon name="sn-plus3" style={{ fontSize: 12, height: 12, verticalAlign: 'middle' }} />
                                            <span style={{ marginLeft: 4, marginRight: 4, verticalAlign: 'middle' }}>
                                                <FormattedMessage id="forms.new" />
                                            </span>
                                            <Icon name="send outline" style={{ fontSize: 16, height: 16, verticalAlign: 'middle' }} />
                                        </div>
                                    );
                                }}
                            />
                        :
                            <div
                                ref={closePreviewPopup}
                                onClick={() => setPreviewMode(null)}
                                style={{ padding: 6 }}
                            >
                                <Icon name="sn-cross2" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                <Icon name="sn-eye" style={{ fontSize: 16, height: 16, marginRight: 0, verticalAlign: 'middle' }} />
                            </div>
                }
            >
                {previewMode === null
                    ?
                        <React.Fragment>
                        <div className="left-panel">
                            <SelectForm 
                                draftContent={contentState.rendererState.content.content.draft}
                                liveContent={contentState.rendererState.content.content.live}
                                hasChanged={contentState.rendererState.hasChanged}
                            />

                            {/* form settings */}
                            <Popup
                                target={formSettingsPopup.current}
                                position="bottom center"
                                zIndex={997}
                            >
                                <FormattedMessage id="forms.form settings" />
                            </Popup>

                            <FormSettings
                                renderButton={toggleFormOpen => {
                                    return (
                                        <Icon
                                            link
                                            name="setting"
                                            id="forms-form-menu-settings"
                                            ref={formSettingsPopup}
                                            onClick={() => toggleFormOpen('update')}
                                            className="primary-color"
                                            style={{ fontSize: 24, marginLeft: 0, marginRight: 16 }}
                                        />
                                    );
                                }}
                            />

                            <PortalHook id="undo-redo-portal" />
                        </div>
                        
                        <div className="right-panel">
                            {/* <PortalHook id="device-picker-portal" style={{ marginLeft: 'auto' }} /> */}
            
                            <OpenPreview
                                onChange={setPreviewMode}
                                value={previewMode}
                                style={{ marginRight: 16 }}
                            />

                            <PortalHook id="save-or-publish-portal" />
                        </div>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {/* active form title */}
                            <Icon name="sn-file-text" className="primary-color" style={{ fontSize: 24, marginRight: 6, opacity: 0.6 }} />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginRight: 16
                                }}
                            >
                                {formTitle}
                            </span>
                            
                            {/* preview toggle */}
                            <ToggleDraftAndLivePreview
                                onChange={setPreviewMode}
                                hasLiveContent={!!contentState.rendererState.content.content.live}
                                value={previewMode}
                            />
                        </React.Fragment>
                }
            </AppNavigation>

            <div style={{ paddingTop: 16, paddingBottom: 32, margin: '0 auto', maxWidth: 864 }}>
                <Message className="secondary" style={{ padding: 8, marginBottom: 4 }}>
                    <span style={{ fontWeight: 'bold', marginRight: 8 }}>
                        <FormattedMessage id="general.obs label" />
                    </span>
                    <FormattedMessage id="forms.form canvas disclaimer" />
                </Message>    

                <div style={{ border: '4px #1B73AB dashed' }}>    
                    {previewMode !== null 
                        ?
                            renderPreview()
                        :
                            <Renderer
                                {...contentState}
                                renderAtRoot={renderAtRoot}
                            />
                    }
                </div>
            </div>
        </PreviewContainer>
    );
})));

export default props => {
    return (
        <RendererData {...props}>
            <FormRenderer />
        </RendererData>
    );
};