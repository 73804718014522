import React, { PureComponent } from 'react';
import { Form, Icon, Button, Message, ModalContent, ModalActions, Grid, GridColumn } from 'svift-ui';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate, { string } from 'components/forms/__util/validate';
import OptionLabel from 'components/option-library/label';

import input from 'components/forms/__util/field-components/input';
import InfoIcon from 'components/InfoIcon';

const fields = {
	newSiteName: {
		component: input({
			type: 'text'
		}),
		validate: string(1, 255)
	}
};

const validateFields = validate(fields);

class CopySiteForm extends PureComponent {
	constructor() {
		super();

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validateFields(values);

		await this.props.onSubmit(values);
	}

	render() {
		const { handleSubmit, intl } = this.props;

		return (
			<Form onSubmit={handleSubmit(this.submit)}>

				<OptionLabel
					label={intl.formatMessage({ id: 'settings.site copy duplicate label' })}
					icon="sn-sphere2"
				>
					<Field
						fluid
						size="massive"
						name="newSiteName"
						icon="sn-sphere2"
						iconPosition="left"
						placeholder={intl.formatMessage({ id: 'settings.site copy duplicate placeholder' })}
						intl={intl}
						component={fields.newSiteName.component}
					/>
				</OptionLabel>

				{this.props.error &&
					<Message negative>
						<FormattedMessage id={this.props.error} />
					</Message>
				}

				<Message className="warning" style={{ display: 'block', marginBottom: 16 }}>
					<span style={{ fontWeight: 'bold', marginRight: 8 }}>
						<FormattedMessage id="general.obs label" />
					</span>
					<FormattedMessage id="settings.site copy duplicate message description" />
				</Message>

				<Button size="huge" className="positive">
					<Icon name="sn-sphere2" style={{ marginRight: 8 }} /> 
					<FormattedMessage id="settings.site copy duplicate button" />
				</Button>
			</Form>
		);
	}
}

const ReduxCopySiteForm = reduxForm({
	form: 'copySiteForm'
})(injectIntl(CopySiteForm));

ReduxCopySiteForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default ReduxCopySiteForm;