import React, { memo, useCallback, useMemo } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import Section from '../Section';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { intlShape, injectIntl } from "react-intl";

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);
    
    return (
        <React.Fragment>
            <Section>
                <Label
                    label={intl.formatMessage({ id: 'elements.form select label' })}
                    labelIcon="send outline"
                    labelDescription={intl.formatMessage({ id: 'elements.form select description' })}
                >
                    <Dropdown
                        wings
                        items={useMemo(() => {
                            return props.forms.order.map(formID => {
                                const form = props.forms.lookup[formID];
                                
                                return {
                                    text: form.title,
                                    value: form._id
                                };
                            });
                        }, [props.forms])}
                        value={node.props.selectedForm || props.forms.order[0]}
                        onChange={value => onChange('selectedForm', value, 'selected form')}
                    />
                </Label>
            </Section>

            {/* <Accordion state={state} dispatch={dispatch}>
                <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                />
            </Accordion> */}
        </React.Fragment>
    );
}));