exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".divider__label-container--3K3Bn{display:flex;align-items:center}.divider__line--17YZ7{height:0px;flex-grow:1;display:block;height:1px;border:0;margin:1em 0;padding:0}.divider__divider--3f7cp{position:relative;display:flex;justify-content:space-between;align-items:center}\n", ""]);

// exports
exports.locals = {
	"label-container": "divider__label-container--3K3Bn",
	"line": "divider__line--17YZ7",
	"divider": "divider__divider--3f7cp"
};