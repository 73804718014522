import api from 'api';
import constants from '../constants';

export default {
	setAvailableTlds: () => (dispatch) => {
		const options = ['dk', 'com'];

		/* Mocking API call */
		setTimeout(() => {
			dispatch({
				type: constants.SET_AVAILABLE_TLDS,
				data: options,
			});
		}, 50);
	},

	checkDomains: ({ domains, subdomain, siteID }) => async (dispatch, getState) => {
		const state = getState();

		// const checkSubdomain = state.wizard.subscription === 0;

		const status = await api().activation.checkDomainAvailability({ siteID, domains }); //checkSubdomain ? null : domains, checkSubdomain ? subdomain : null);

		dispatch({
			type: constants.SET_CHECKED_DOMAINS,
			data: status,
		});

		// if (checkSubdomain) {
		// 	dispatch({
		// 		type: constants.SET_CHECKED_SUBDOMAINS,
		// 		data: status,
		// 	});
		// } else {
		// 	dispatch({
		// 		type: constants.SET_CHECKED_DOMAINS,
		// 		data: status,
		// 	});
		// }

		// api().checkDomains(checkSubdomain ? null : domains, checkSubdomain ? subdomain : null).then((response) => {
		// 	if (response.status === 200) {
		// 		if (checkSubdomain) {
		// 			dispatch({
		// 				type: constants.SET_CHECKED_SUBDOMAINS,
		// 				data: response.data,
		// 			});
		// 		} else {
		// 			dispatch({
		// 				type: constants.SET_CHECKED_DOMAINS,
		// 				data: response.data,
		// 			});
		// 		}
		// 	} else {
		// 		console.log(response, response.status, 'error response on domaincheck');
		// 	}
		// }).catch((err) => {
		// 	console.log(err, 'err2');
		// });
	},

	setPickedDomain: domain => ({
		type: constants.SET_PICKED_DOMAIN,
		data: domain,
	}),

	setAgreeTransferTerms: bool => ({
		type: constants.SET_AGREE_TRANSFER_TERMS,
		data: bool
	}),
};
