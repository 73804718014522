import React from 'react';
import { FormField, FormTextArea, Message } from 'svift-ui';
import CardSelector from 'components/CardSelector';

/*
	Wraps the card selector to make it fit within a redux-form context
*/
const AdaptedCardSelector = props => {
	const { input, intl, style, itemsPerRow, cards, meta: { error } } = props;

	// console.log(input.value, 'input value card selector')

	return (
		<CardSelector
			required
			itemsPerRow={itemsPerRow}
			value={input.value || cards[0].id}
			onChange={(obj, id) => {
				input.onChange(id);
			}}
			cards={cards}
			style={style}
		/>
	);

	const renderArray = [
		<CardSelector
			key="cards"
			required
			itemsPerRow={itemsPerRow}
			value={input.value || cards[0].id}
			onChange={(obj, id) => {
				input.onChange(id);
			}}
			cards={cards}
			style={style}
		/>
	];

	// if (error) {
	// 	renderArray.push(
	// 		<Message negative key="error">
	// 			{intl.formatMessage({ id: error })}
	// 		</Message>
	// 	);
	// }

	return renderArray;
};

AdaptedCardSelector.defaultProps = {
	itemsPerRow: 2,
};

export default AdaptedCardSelector;