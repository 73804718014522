import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getDefaultState = () => ({
	content: {},
	stagedContent: null,
	stagedFrom: null,
	view: 'draft',
	params: {
		pageid: null
	}
});

const defaultState = getDefaultState();

const contentReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_PAGES_CONTENT: {
			return {
				...state,
				content: {
					...state.content,
					...(action.data || {})
				}
			};
		}

		case constants.TOGGLE_CONTENT_VIEW:
			return {
				...state,
				view: action.data
			};

		case constants.STAGE_CONTENT: {
			return {
				...state,
				stagedFrom: action.data,
				stagedContent: action.data
			};
		}

		case constants.CREATE_PAGE: {
			const { page } = action.data;

			return {
				...state,
				[page._id]: page.content
			};
		}

		case constants.UPDATE_PAGE_CONTENT: {
			const { tree } = action.data;

			return {
				...state,
				stagedContent: {
					...state.stagedContent,
					tree
				}
			};
		}

		case constants.PUBLISH_CONTENT: {
			const { draft, pageid } = action.data;

			return {
				...state,
				content: {
					...state.content,
					[pageid]: {
						...state.content[pageid],
						draft,
						live: draft
					}
				},
				stagedFrom: draft,
				stagedContent: draft
			};
		}

		case constants.SAVE_CONTENT: {
			const { draft, pageid } = action.data;

			return {
				...state,
				content: {
					...state.content,
					[pageid]: {
						...state.content[pageid],
						draft,
						//live: draft
					}
				},
				stagedFrom: draft,
				stagedContent: draft
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/pages/:pageid?') || { pageid: null };

			let stagedContent = state.stagedContent;

			if (params.pageid !== state.params.pageid) {
				stagedContent = null;
			}

			return {
				...state,
				stagedContent,
				view: 'draft',
				stagedFrom: stagedContent,
				params: {
					pageid: params.pageid
				}
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default contentReducer;
