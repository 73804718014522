/* TO-DO: MOVE TO UTILS FOLDER. THIS IS NOT A COMPONENT ANYMORE. */

/*
	This file helps convert stored style objects into inline-able javascript, specifically in order to convert theme styles into something components can use
*/

import tinycolor from 'tinycolor2';
import { loadFont } from 'utils/fonts/loadedFonts';
import { toFontWeight } from 'utils/fonts/googleFontParser';

export const measurementResolver = ({ measurements }, multiplier, key, decimals = 0) => `${(measurements[key] * multiplier).toFixed(decimals)}px`;
const measurementResolverNoPx = ({ measurements }, multiplier, key, decimals = 0) => `${(measurements[key] * multiplier).toFixed(decimals)}`;
const fontVariantResolver = ({}, fontVariant) => toFontWeight(fontVariant);
const fontResolver = ({ fonts }, fontKey) => {
	const font = fonts[fontKey];
	const { family, websafe } = font;

	if (!websafe) {
		loadFont(family);
	}

	return family;
};
const colorResolver = ({ colors }, colorObj) => {
	const { key, greyscale, brightness, saturation, opacity } = colorObj;

	if (!colors[key]) return;

	const color = tinycolor(colors[key]);

	if (greyscale) color.greyscale();
	if (typeof brightness !== 'undefined') color.lighten(brightness);
	if (typeof saturation !== 'undefined') color.saturate(saturation);
	if (typeof opacity !== 'undefined') color.setAlpha(opacity);

	return color.toRgbString();
};
const noop = ({}, attribute) => attribute;

const converters = {
	font: fontResolver,
	fontSize: measurementResolver,
	fontWeight: fontVariantResolver,
	lineHeight: (...args) => measurementResolverNoPx(...args, 1),
	letterSpacing: noop,
	textDecoration: noop,
	color: colorResolver,
	backgroundColor: colorResolver,
	boxShadow: (theme, shadow) => {
		const { yShadow, xShadow, spread, color, blur, inset } = shadow;

		const boxShadow = [
			typeof xShadow === 'undefined' ? '0px' : `${xShadow}px`,
			typeof yShadow === 'undefined' ? '0px' : `${yShadow}px`,
			typeof blur === 'undefined' ? '0px' : `${blur}px`,
			typeof spread === 'undefined' ? '0px' : `${spread}px`,
			typeof color === 'undefined' ? 'black' : colorResolver(theme, color)
		];

		if (inset) boxShadow.push('inset');

		return {
			boxShadow: boxShadow.join(' ')
		};
	},
	margin: (theme, margins) => {
		const keys = Object.keys(margins);

		if (keys.length === 0) return {};

		const margin = {};

		if (typeof margins.marginGeneral !== 'undefined') {
			const calculatedGeneralMargin = measurementResolver(theme, margins.marginGeneral, 'margin');

			margin.marginTop = calculatedGeneralMargin;
			margin.marginLeft = calculatedGeneralMargin;
			margin.marginRight = calculatedGeneralMargin;
			margin.marginBottom = calculatedGeneralMargin;
		}

		if (typeof margins.marginTop !== 'undefined') margin.marginTop = measurementResolver(theme, margins.marginTop, 'margin');
		if (typeof margins.marginLeft !== 'undefined') margin.marginLeft = measurementResolver(theme, margins.marginLeft, 'margin');
		if (typeof margins.marginRight !== 'undefined') margin.marginRight = measurementResolver(theme, margins.marginRight, 'margin');
		if (typeof margins.marginBotom !== 'undefined') margin.marginBotom = measurementResolver(theme, margins.marginBotom, 'margin');

		// const margin = keys.reduce((acc, marginKey) => {
		// 	const calculatedMargin = measurementResolver(theme, margins[marginKey], 'margin');

		// 	if (marginKey === 'generalMargin') {
		// 		acc.marginTop = calculatedMargin;
		// 		acc.marginBottom = calculatedMargin;
		// 		acc.marginRight = calculatedMargin;
		// 		acc.marginLeft = calculatedMargin;
		// 		// acc.marginGeneral = calculatedMargin;
		// 	} else {
		// 		acc[marginKey] = measurementResolver(theme, margins[marginKey], 'margin');
		// 	}

		// 	return acc;
		// }, {
		// 	marginTop: '0px',
		// 	marginRight: '0px',
		// 	marginBottom: '0px',
		// 	marginLeft: '0px',
		// 	marginGeneral: '0px'
		// });

		return margin;
	},
	borderRadius: (theme, borderRadiuses) => {
		const keys = Object.keys(borderRadiuses);

		if (keys.length === 0) return {};

		return keys.reduce((acc, borderRadiusKey) => {
			if (borderRadiusKey === 'generalBorderRadius') {
				const calculatedRadius = `${borderRadiuses[borderRadiusKey]}px`; //measurementResolver(theme, borderRadiuses[borderRadiusKey], 'borderRadius');

				acc.borderTopRightRadius = calculatedRadius;
				acc.borderTopLeftRadius = calculatedRadius;
				acc.borderBottomRightRadius = calculatedRadius;
				acc.borderBottomLeftRadius = calculatedRadius;
			} else {
				acc[borderRadiusKey] = `${borderRadiuses[borderRadiusKey]}px`; //measurementResolver(theme, borderRadiuses[borderRadiusKey], 'borderRadius');
			}

			return acc;
		}, {
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
			borderBottomLeftRadius: '0px',
			borderBottomRightRadius: '0px'
		});
	},
	padding: (theme, paddings) => {
		const keys = Object.keys(paddings);

		if (keys.length === 0) return {};

		return keys.reduce((acc, paddingKey) => {
			if (paddingKey === 'generalPadding') {
				const calculatedPadding = measurementResolver(theme, paddings[paddingKey], 'padding');

				acc.paddingTop = calculatedPadding;
				acc.paddingBottom = calculatedPadding;
				acc.paddingRight = calculatedPadding;
				acc.paddingLeft = calculatedPadding;
				acc.paddingGeneral = calculatedPadding;
			} else {
				acc[paddingKey] = measurementResolver(theme, paddings[paddingKey], 'padding');
			}

			return acc;
		}, {
			paddingTop: '0px',
			paddingRight: '0px',
			paddingBottom: '0px',
			paddingLeft: '0px',
			paddingGeneral: '0px'
		});
	},
	border: (theme, borders) => {
		// can't use shorthand because we can't mix shorthand borders with longhand borders (fml)
		// Also can't use it because if we don't explicitly set all borders to none, we can't overrule default styles when secondary states are active
		const borderStyles = {
			borderTopStyle: 'none',
			borderBottomStyle: 'none',
			borderLeftStyle: 'none',
			borderRightStyle: 'none'
		};

		Object.keys(borders).forEach(borderKey => {
			const borderObj = borders[borderKey];

			switch (borderKey) {
				case 'borderGeneral':
					if (borderObj.style) {
						borderStyles.borderTopStyle = borderObj.style;
						borderStyles.borderBottomStyle = borderObj.style;
						borderStyles.borderRightStyle = borderObj.style;
						borderStyles.borderLeftStyle = borderObj.style;
					} else {
						borderStyles.borderTopStyle = 'solid';
						borderStyles.borderBottomStyle = 'solid';
						borderStyles.borderRightStyle = 'solid';
						borderStyles.borderLeftStyle = 'solid';
					}

					if (borderObj.width) {
						borderStyles.borderTopWidth = borderObj.width;
						borderStyles.borderBottomWidth = borderObj.width;
						borderStyles.borderRightWidth = borderObj.width;
						borderStyles.borderLeftWidth = borderObj.width;
					}

					if (borderObj.color) {
						const color = colorResolver(theme, borderObj.color);

						borderStyles.borderTopColor = color;
						borderStyles.borderBottomColor = color;
						borderStyles.borderRightColor = color;
						borderStyles.borderLeftColor = color;
					}

					break;
				case 'borderTop':
					if (borderObj.style) {
						borderStyles.borderTopStyle = borderObj.style;
					} else {
						borderStyles.borderTopStyle = 'solid';
					}
					if (borderObj.width) borderStyles.borderTopWidth = borderObj.width;
					if (borderObj.color) borderStyles.borderTopColor = colorResolver(theme, borderObj.color);

					break;
				case 'borderBottom':
					if (borderObj.style) {
						borderStyles.borderBottomStyle = borderObj.style;
					} else {
						borderStyles.borderBottomStyle = 'solid';
					}
					if (borderObj.width) borderStyles.borderBottomWidth = borderObj.width;
					if (borderObj.color) borderStyles.borderBottomColor = colorResolver(theme, borderObj.color);

					break;
				case 'borderLeft':
					if (borderObj.style) {
						borderStyles.borderLeftStyle = borderObj.style;
					} else {
						borderStyles.borderLeftStyle = 'solid';
					}
					if (borderObj.width) borderStyles.borderLeftWidth = borderObj.width;
					if (borderObj.color) borderStyles.borderLeftColor = colorResolver(theme, borderObj.color);

					break;
				case 'borderRight':
					if (borderObj.style) {
						borderStyles.borderRightStyle = borderObj.style;
					} else {
						borderStyles.borderRightStyle = 'solid';
					}
					if (borderObj.width) borderStyles.borderRightWidth = borderObj.width;
					if (borderObj.color) borderStyles.borderRightColor = colorResolver(theme, borderObj.color);

					break;
			}
		});

		return borderStyles;
	},
	height: measurementResolver
};

const computePreset = (preset, theme) => {
	let style = {};

	if (!preset) return style;

	// Some presets are not directly mappable to an inline style because they have several states
	if (preset.default) {
		const statefulStyles = computePreset(preset.default, theme);

		statefulStyles[':hover'] = computePreset(preset.hover, theme);
		statefulStyles[':active'] = computePreset(preset.active, theme);
		statefulStyles[':focus'] = computePreset(preset.focus, theme);
		statefulStyles[':disabled'] = computePreset(preset.disabled, theme);

		if (preset.container) {
			statefulStyles.container = computePreset(preset.container, theme);
		}

		return statefulStyles;
	}

	for (const key in preset) {
		const modifier = preset[key];
		const settingKey = key;
		const converter = converters[settingKey];

		if (!converter) {
			//if (settingKey !== 'props') console.log("UNHANDLED KEY: ", settingKey);

			continue;
		}

		switch (key) {
			case 'font':
				style.fontFamily = converters[key](theme, modifier, settingKey);

				break;
			// Advanced options resolve to many keys:
			case 'margin':
			case 'border':
			case 'borderRadius':
			case 'boxShadow':
			case 'padding':
				style = {
					...style,
					...converter(theme, modifier, settingKey)
				};

				break;

			default:
				style[key] = converter(theme, modifier, settingKey);
		}
	}

	return style;
};

const presetLookup = {
	Text: 'text',
	Image: 'image',
	Grid: 'grid',
	Row: 'row',
	Button: 'button',
	LockedContent: 'locked-content',
	Menu: 'menu',
	SmartText: 'text',
	Video: 'video',
	Map: 'map',
	IFrame: 'iframe',
	Logo: 'image',
	Column: 'column',
	Container: 'container',
	Body: 'body',
	FormTextArea: 'input', // legacy, its the same as FormInput, but some older sites use FormTextArea
	FormInput: 'input',
	FormRadio: 'radio',
	FormCheckbox: 'checkbox'
};

export { computePreset, presetLookup, colorResolver };