const dictionary = {
    Grid: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/section-element-draglayer.png',
    Row: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/row-element-draglayer.png',
    Column: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/column-element-draglayer.png',
    Text: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/text-element-draglayer.png',
    Image: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/image-element-draglayer.png',
    Video: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/video-element-draglayer.png',
    Button: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/button-element-draglayer.png',
    Feed: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/feed-element-draglayer.png',
    Map: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/map-element-draglayer.png',
    Form: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/form-element-draglayer.png',
    IFrame: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/iframe-element-draglayer.png',
    
    SiteTitle: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/site-title-element-draglayer.png',
    Menu: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/menu-element-draglayer.png',
    Logo: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/logo-element-draglayer.png',
    
    PageTitle: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/page-title-element-draglayer.png',
    
    Input: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/input-element-draglayer.png',
    Checkbox: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/checkbox-element-draglayer.png',
    Radio: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/radio-element-draglayer.png',
    
    BlogTitle: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/post-title-element-draglayer.png',
    BlogAuthor: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/post-author-element-draglayer.png',
    BlogDescription: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/post-description-element-draglayer.png',
    BlogPublicationDate: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/post-publication-date-element-draglayer.png',

    OneColumnText: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/1-column-text-template-draglayer.png',
    TwoColumnText: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/2-column-text-template-draglayer.png',
    ThreeColumnText: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/3-column-text-template-draglayer.png',
    FourColumnText: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/4-column-text-template-draglayer.png',
    
    StandardHero: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/standard-hero-template-draglayer.png',
    BackgroundImageHero: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/background-image-hero-template-draglayer.png',

    LeftInfobox: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/left-infobox-template-draglayer.png',
    RightInfobox: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/right-infobox-template-draglayer.png',
    BroadInfobox: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/broad-infobox-template-draglayer.png',
    
    LeftImage: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/left-image-template-draglayer.png',
    RightImage: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/right-image-template-draglayer.png',
    ThreeRoundFeatures: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/3-round-features-template-draglayer.png',
    Banner: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/banner-template-draglayer.png',
    ImageFrame: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/image-frame-template-draglayer.png',

    VideoHero: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/video-hero-template-draglayer.png',
    ThreeVideos: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/3-videos-template-draglayer.png',
    
    StandardContactForm: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/standard-contact-form-template-draglayer.png',
    ContactFormWithMap: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/contact-form-with-map-template-draglayer.png',
    ContactInfo: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/contact-info-template-draglayer.png',
    
    BlogList: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/templates/blog-list-template-draglayer.png',


    // TRANSL8 - add image links for new building blocks
    // Follow: 
    // Share:
    // Divider:
    // Gallery:
};

export default elementName => {
    const imageLink = dictionary[elementName] || 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/dnd/elements/fallback-element-draglayer.png'

    return imageLink;
};