import React, { memo, useCallback } from "react";
import Section from '../../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';
import Text from 'components/option-library/primitives/input/text';
import Checkbox from 'components/option-library/primitives/checkbox';
import Dropdown from 'components/option-library/primitives/dropdown/simple';

const modes = [
    {
        text: 'text',
        value: 'single'
    },
    {
        text: 'textarea',
        value: 'textArea'
    },
    {
        text: 'email',
        value: 'email'
    },
    {
        text: 'number',
        value: 'number'
    }
];

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

	// console.log(node.props, 'node props label options');
	
	console.log(props, 'props radio options');

    return (
        <React.Fragment>
            <Section>
                <Preset
                    multipleStates
                    presets={props.theme.presets.input}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

				<Label
                    labelIcon="sn-typography"
                    label={intl.formatMessage({ id: 'forms.input label title' })}
                    // labelDescription={intl.formatMessage({ id: 'forms.input label description' })}
                >
                    <Text
                        value={node.props.label}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('label', nextValue);
                        }}
                        placeholder={intl.formatMessage({ id: 'forms.input label placeholder' })}
                    />
                </Label>

                
				<Label
                    labelIcon="sn-pencil7"
                    label={intl.formatMessage({ id: 'forms.input label placeholder' })}
                    // labelDescription="Something about input placeholders"
                >
                    <Text
                        value={node.props.placeholder}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('placeholder', nextValue);
                        }}
                        placeholder={intl.formatMessage({ id: 'forms.input placeholder placeholder' })}
                    />
                </Label>
				
				<Label>
					<Dropdown
						wings
						items={modes}
						value={node.props.mode}
						onChange={value => onChange('mode', value)}
					/>
				</Label>
                
				<Label>
					<Checkbox
						icon="sn-warning"
						label={intl.formatMessage({ id: 'forms.required field label' })}
						value={node.props.required}
						onChange={val => onChange('required', val, 'toggle required', false)}
					/>
				</Label>
            </Section>
        </React.Fragment>
    );
}));

/*
					<Dropdown
						uncontrolled
						key="mode"
						option="preset"
						options={modes}
						hoverContent={`${currentMode.text}`}
						onChange={data => {
							const newNode = {
								...node,
								props: {
									...node.props,
									mode: data.value
								},
							};

							getEditorGlobals().onChange({
								newNode,
								path
							});
						}}
						value={computedProps.mode}
					/>,
					<Dropdown
						uncontrolled
						key="presets"
						option="preset"
						icon="hand pointer"
						options={Object.keys(getEditorGlobals().theme.presets.input || []).map(preset => {
							return {
								text: preset,
								value: preset
							};
						})}
						hoverContent={intl.formatMessage({ id: 'design.type' }) + ` (${computedProps.preset})`}
						onChange={preset => {
							const newNode = {
								...node,
								props: {
									...node.props,
									preset: preset.value
								},
							};

							getEditorGlobals().onChange({
								newNode,
								path
							});
						}}
						value={computedProps.preset}
					/>,
					<Popup
						key="form input label input"
						trigger={
							<Input
								//autoFocus
								key="form input label"
								icon="sn-flag6"
								iconPosition="left"
								placeholder={intl.formatMessage({ id: 'forms.input label placeholder' })}
								value={node.props.label || ''}
								onChange={(e, data) => {
									const newNode = {
										...node,
										props: {
											...node.props,
											label: data.value
										}
									};

									getEditorGlobals().onChange({
										newNode,
										path
									});
								}}
								style={{ width: 160, fontSize: 12, marginLeft: 8, marginRight: 8 }}
							/>
						}
						content={intl.formatMessage({ id: 'forms.input label popup' })}
						position="top center"
					/>,
					<Popup
						key="form input placeholder input"
						trigger={
							<Input
								//autoFocus
								key="form input placeholder"
								icon="sn-pencil7"
								iconPosition="left"
								placeholder={intl.formatMessage({ id: 'forms.input placeholder placeholder' })}
								value={node.props.placeholder || ''}
								onChange={(e, data) => {
									const newNode = {
										...node,
										props: {
											...node.props,
											placeholder: data.value
										}
									};

									getEditorGlobals().onChange({
										newNode,
										path
									});
								}}
								style={{ width: 160, fontSize: 12, marginRight: 16 }}
							/>
						}
						content={intl.formatMessage({ id: 'forms.input placeholder popup' })}
						position="top center"
					/>,
					<Popup
						key="required checkbox"
						trigger={
							<div
								key="required"
								style={{
									display: 'inline-flex',
									flexDirection: 'row',
									justifyContent: 'center',
									marginRight: 10
								}}
							>
								<Checkbox
									checked={computedProps.required}
									label={
										<label style={{ fontSize: 12 }}>
											{intl.formatMessage({ id: 'general.required' })}
										</label>
									}
									onChange={() => {
										const newNode = {
											...node,
											props: {
												...node.props,
												required: !node.props.required
											}
										};

										getEditorGlobals().onChange({
											newNode,
											path
										});
									}}
								/>
							</div>
						}
						content={intl.formatMessage({ id: 'forms.required field checkbox popup' })}
						position="top center"
					/>
*/