import React, { useMemo } from 'react';
import { computePreset, presetLookup } from 'svift-library/client/renderer/utils/computePreset';
import key from 'svift-library/key';
// import deepmerge from 'svift-library/deepmerge';

const mergeStyles = (baseStyling = {}, inlineStyling = {}) => {
	// console.log(baseStyling, inlineStyling, 'base/inline');
	if (!baseStyling.default) {
		return {
			...baseStyling,
			...inlineStyling
		};
	}

	const nestedStyling = ['default', 'hover', 'focus', 'active', 'disabled', 'container', 'label'].reduce((acc, nestedStyleKey) => {
		const baseStyle = baseStyling[nestedStyleKey];
		const inlineStyle = inlineStyling[nestedStyleKey];

		// If inline style doesnt exist there is nothing to merge into the base styling
		if (!inlineStyle) return acc; 

		return {
			...acc,
			[nestedStyleKey]: {
				...baseStyle,
				...inlineStyle
			}
		}
	}, {});

	return {
		...baseStyling,
		...inlineStyling,
		...nestedStyling
		// default: {
		// 	...baseStyling.default,
		// 	...inlineStyling.default
		// },
		// hover: {
		// 	...baseStyling.hover,
		// 	...inlineStyling.hover
		// },
		// focus: {
		// 	...baseStyling.focus,
		// 	...inlineStyling.focus
		// },
		// active: {
		// 	...baseStyling.active,
		// 	...inlineStyling.active
		// },
		// disabled: {
		// 	...baseStyling.disabled,
		// 	...inlineStyling.disabled
		// },
		// container: {
		// 	...baseStyling.container,
		// 	...inlineStyling.container
		// },½
	}
};

// const convertMarginToPaddingForCrop = (margin) => {
// 	const padding = {};

// 	if (margin.generalMargin) padding.generalPadding = margin.generalMargin;
// 	if (margin.marginBottom) padding.paddingBottom = margin.marginBottom;
// 	if (margin.marginTop) padding.paddingTop = margin.marginTop;
// 	if (margin.marginLeft) padding.paddingLeft = margin.marginLeft;
// 	if (margin.marginRight) padding.paddingRight = margin.marginRight;

// 	return padding;
// }

// const splitStylingForCrop = (theme, preset, presetOverrides) => {
// 	let presetCopy = { ...preset };
// 	let presetOverrideCopy = { ...presetOverrides };
// 	let cropOuterPreset = {};
// 	let cropInnerPreset = {};

// 	if (presetCopy.margin) {
// 		cropOuterPreset.padding = convertMarginToPaddingForCrop(presetCopy.margin);
// 		delete presetCopy.margin;
// 	}

// 	if (presetOverrideCopy.margin) {
// 		cropOuterPreset.padding = convertMarginToPaddingForCrop(presetOverrideCopy.margin);
// 		delete presetOverrideCopy.margin;
// 	}

// 	if (presetCopy.border) {
// 		cropInnerPreset.border = presetCopy.border;
// 		delete presetCopy.border;
// 	}

// 	if (presetOverrideCopy.border) {
// 		cropInnerPreset.border = presetOverrideCopy.border;
// 		delete presetOverrideCopy.border;
// 	}
	
// 	if (presetCopy.boxShadow) {
// 		cropInnerPreset.boxShadow = presetCopy.boxShadow;
// 		delete presetCopy.boxShadow;
// 	}

// 	if (presetOverrideCopy.boxShadow) {
// 		cropInnerPreset.boxShadow = presetOverrideCopy.boxShadow;
// 		delete presetOverrideCopy.boxShadow;
// 	}

// 	return {
// 		// regularStyling: computePreset({ ...presetCopy, ...presetOverrideCopy }, theme),
// 		regularStyling: computePreset(mergeStyles(presetCopy, presetOverrideCopy), theme),
// 		// regularStyling: {
// 		// 	...computePreset({ ...presetCopy, ...presetOverrideCopy }, theme),
// 		// 	// ...computePreset(presetOverrideCopy, theme)
// 		// 	// computePreset(deepmerge(presetCopy, presetOverrideCopy), theme), /*...computePreset(presetCopy, theme), ...computePreset(presetOverrideCopy, theme), */
// 		// },
// 		cropInnerStyling: computePreset(cropInnerPreset, theme),
// 		cropOuterStyling: computePreset(cropOuterPreset, theme),
// 	};
// }

export default Component => {
	const StyledComponent = props => {
		const computedStyling = useMemo(() => {
			const { theme, node } = props;

			if (!theme) {
				console.warn('theme undefined');
			
				return null;
			}
			
			const { presets } = theme;
			
			const preset = node.props.preset;
			
			const componentPresets = presets[presetLookup[node.component]];
			
			let activePreset;
			
			if (preset && componentPresets) {
				activePreset = componentPresets[preset];
			}
			
			if (!activePreset && componentPresets) {
				activePreset = componentPresets.Normal || componentPresets.Primary;
			}
			
			/*	
				If the component is cropped we need to extract "outer" styling types to the crop container - things like border, margin, shadows and outlines.
				
				The least ugly way to do that is for this component to split the styling in two, and let the Crop component distribute the styles as needed
			*/
			// const isCropped = key('crop.heightMode')(node.props);// && node.props.crop.active;

			// Remove empty keys e.g. padding: {} => delete padding - legacy reasons
			const inlineStyling = node.props.style && Object.entries(node.props.style).reduce((acc, [key, val]) => {
				if (typeof val === 'object' && val !== null && Object.keys(val).length === 0) delete acc[key];

				return acc;
			}, { ...node.props.style })
			
			// if (isCropped) {
			// 	return splitStylingForCrop(theme, activePreset, inlineStyling);
			// }

			let style;

			if (activePreset) {
				if (inlineStyling) {
					// style = computePreset(deepmerge(activePreset, inlineStyling), theme);

					// Logic here is that if a key is defined in inline styling, it completely overrides the corresponding key in the preset
					/*
						1) Find all possible nested styling keys
							['default', hover', 'focus', 'active', 'disabled', 'container']
						2) For each of these keys, do not merge over them, instead access them and merge their properties shallowly
						3) Collect data structures for Menu, Buttons, Inputs, Checkbox, TextArea
					*/

					// if (['Menu', 'Button', 'Input', 'FormInput', 'FormCheckbox', 'FormTextArea'].includes(node.component)) {
					// 	console.log(node.component, 'component styles:');
					// 	console.log(activePreset, inlineStyling, 'ACTIVE/INLINE');
					// }

					// style = computePreset({ 
					// 	...activePreset, 
					// 	...inlineStyling 
					// }, theme);

					// console.log(mergeStyles(activePreset, inlineStyling), 'merged');

					style = computePreset(mergeStyles(activePreset, inlineStyling), theme);

					// BUG: support elements with multiple states

					// style = {
					// 	...computePreset(activePreset, theme),
					// 	...computePreset(inlineStyling, theme)
					// }
				} else {
					style = computePreset(activePreset, theme);
				}
			}

			return style;
		}, [props.theme, props.node])

		return <Component {...props} computedStyling={computedStyling} computePreset={computePreset} />;
	}

	StyledComponent.mapContextToProps = Component.mapContextToProps;

	return StyledComponent;
};

// class Styling extends PureComponent {
// 	computeStyling = () => {
// 		const { theme, node } = this.props;

// 		if (!theme) return null;

// 		const { presets } = theme;

// 		const inlineStyling = node.props.style;
// 		const preset = node.props.preset;

// 		const componentPresets = presets[presetLookup[node.component]];

// 		let activePreset;

// 		if (preset && componentPresets) {
// 			activePreset = componentPresets[preset];
// 		}

// 		if (!activePreset && node.component !== 'Editor' && componentPresets) {
// 			activePreset = componentPresets.Normal || componentPresets.Primary;
// 		}

// 		let style = {};

// 		if (activePreset) {
// 			style = computePreset(activePreset, theme);
// 		}

// 		if (inlineStyling) {
// 			style = {
// 				...style,
// 				...computePreset(inlineStyling, theme)
// 			}
// 		}

// 		return style;
// 	}

// 	render() {
// 		const computedStyling = useMemo(() => this.computeStyling(), [this.props.theme, this.props.node]); 

// 		return React.cloneElement(this.props.children,
// 			{
// 				computedStyling,
// 				computePreset
// 			}
// 		);
// 	}
// }

// export default Styling;