import React, { PureComponent } from 'react';
import Node from 'svift-library/client/renderer/nodes/Node';
import Crop from 'svift-library/client/renderer/nodes/components/library/__components/crop/Crop';
import css from './row.scss';
import shallowCompare, { deriveAndCompare } from 'svift-library/client/renderer/nodes/shallowCompare';
import WithBackground from 'svift-library/client/image/WithBackground';
import VideoBackground from 'svift-library/client/renderer/nodes/components/library/__components/background-video';

const widthByDevice = {
	tablet: 16,
	phone: 16
};

class EditorRow extends PureComponent {
	static mapContextToProps = (props, state, currentNode) => {
		const { theme, files, nodeMap, extend, device } = props.context;

		return shallowCompare({
			theme,
			files,
			device,
			rowClasses: extend.Row && extend.Row.css,
			columnWidths: deriveAndCompare.object(() => currentNode.children.reduce((acc, columnID) => {
				const column = nodeMap[columnID];

				acc[columnID] = column.width

				return acc;
			}, {}), state.columnWidths)
		}, state);
	}
		
	getStyling = () => {
		const justifyContent = this.props.node.props.horizontalAlign || /*this.props.gridHorizontalAlign || */'flex-start';
		const alignItems = this.props.cropAlignment || this.props.node.props.verticalAlign || /*this.props.gridVerticalAlign ||*/ 'flex-start';

		return {
			justifyContent,
			alignItems,
			position: 'relative',
			width: '100%',
			...this.props.computedStyling,
			...this.props.cropStyling
		};
	}
	
	calcDividers = (columns, columnsPerRow) => {
		const dividerDistribution = {};
		let currentRowIndex = 0;
		let currentRowDepth = 0;
		
		columns.forEach((columnID, index) => {
			const columnWidth = widthByDevice[this.props.device] || this.props.columnWidths[columnID];

			let currentRow = dividerDistribution[currentRowIndex];

			if (!currentRow) {
				dividerDistribution[currentRowIndex] = {
					dividers: 0,
					firstColumn: index,
					lastColumn: index
				};

				currentRow = dividerDistribution[currentRowIndex];
			}

			if ((columnWidth + currentRowDepth) > columnsPerRow) {
				currentRowDepth = columnWidth;
				currentRowIndex++;

				dividerDistribution[currentRowIndex] = {
					dividers: 0,
					firstColumn: index,
					lastColumn: index
				};
			} else {
				if (currentRow.firstColumn < index) {
					currentRow.dividers++;
				}

				currentRowDepth += columnWidth;
				currentRow.lastColumn = index;
			}
		});

		return dividerDistribution;
	}

	renderColumns = () => {
		const { node } = this.props;
		const columnsPerRow = 16;

		const dividers = this.props.computedStyling.columnSpacing && this.calcDividers(node.children, columnsPerRow);

		let currentRow = 0;
		let newRow = false;

		const columnDividerWidth = this.props.computedStyling.columnSpacing;

		return node.children.map((childID, index) => {
			if (newRow) {
				currentRow++;
				newRow = false;
			}

			let shouldPrependDivider;

			if (dividers) {
				const rowDescriptor = dividers[currentRow];

				shouldPrependDivider = index > rowDescriptor.firstColumn && index <= rowDescriptor.lastColumn;

				if (index === rowDescriptor.lastColumn) newRow = true;	
			}

			return (
				<React.Fragment key={childID}>
					{shouldPrependDivider && 
						<div className={this.props.rowClasses && this.props.rowClasses['divider']} style={{ width: `${columnDividerWidth}px`, alignSelf: 'stretch' }} />
					}

					<Node
						freezeDragPath={this.props.freezeDragPath}
						columns={columnsPerRow}
						dividerWidth={columnDividerWidth}
						rowDividers={dividers && dividers[currentRow]}
						nodeID={childID}
						ContextConsumer={this.props.ContextConsumer}
						getPath={this.props.getPath}
					/>
				</React.Fragment>
			);
		})
	}

	render() {
		const {
			node,
			files
		} = this.props;

		const backgroundFile = this.props.files.lookup[node.props.backgroundImage]; //resolveFile(this.props.files, node.props.backgroundImage); 

		return (
			<WithBackground
				nodeProps={node.props}
				file={backgroundFile}
				render={(backgroundStyles, imageRef) => {
					let className = css['row'];

					if (backgroundStyles) className = className + ' editor-background-image';
					// if (this.props.isWrapped) className = className + ` ${css['collapsing-margin-fix']}`;

					return (
						<div 
							data-type="row" 
							style={{ ...this.getStyling(), ...backgroundStyles }} 
							className={className}
							ref={ref => {
								if (imageRef) imageRef.current = ref;
								if (this.props.cropRef) this.props.cropRef.current = ref;
							}} 
						>
							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}

							<VideoBackground {...this.props} />

							{this.renderColumns()}
							{this.props.isWrapped && <div className={css['collapsing-margin-fix']}></div>}
						</div>
					);
				}}
			/>
		);
	}
}

export default Crop(EditorRow, false);