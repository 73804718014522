import React from 'react';
import { Message, Icon } from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';

const NoResult = props => (
	<Message
		className="info"
		size={props.size || 'large'}
		style={{ textAlign: 'left' }}
	>
		<Icon name="sn-search3" style={{ marginRight: 8 }} />
		<span>
			{props.noResultsMessage || <FormattedMessage id="general.no search results message" />}
		</span>
	</Message>
);

export default injectIntl(NoResult);
