import Image from './block-library/elements/Image';
import Text from './block-library/elements/Text';
import Button from './block-library/elements/Button';
import Menu from './block-library/elements/Menu';
import Gallery from './block-library/elements/Gallery';
import Video from './block-library/elements/Video';
import Map from './block-library/elements/Map';
import IFrame from './block-library/elements/IFrame';
import Form from './block-library/elements/Form';
import Feed from './block-library/elements/Feed';
import Divider from './block-library/elements/Divider';

/* some */
import Follow from './block-library/elements/some/Follow';
import Share from './block-library/elements/some/Share';

/* smart blocks */
import BlogTitle from './block-library/elements/smart-blocks/BlogTitle';
import BlogPublicationDate from './block-library/elements/smart-blocks/BlogPublicationDate';
import BlogAuthor from './block-library/elements/smart-blocks/BlogAuthor';
import BlogDescription from './block-library/elements/smart-blocks/BlogDescription';
import PageTitle from './block-library/elements/smart-blocks/PageTitle';
import SiteTitle from './block-library/elements/smart-blocks/SiteTitle';
import Logo from './block-library/elements/smart-blocks/Logo';

/* form blocks */
import Input from './block-library/elements/form-blocks/Input';
import Checkbox from './block-library/elements/form-blocks/Checkbox';
import Radio from './block-library/elements/form-blocks/Radio';

/* templates */
import OneColumnText from './block-library/section-templates/OneColumnText';
import TwoColumnText from './block-library/section-templates/TwoColumnText';
import ThreeColumnText from './block-library/section-templates/ThreeColumnText';
import FourColumnText from './block-library/section-templates/FourColumnText';

import StandardHero from './block-library/section-templates/StandardHero';
import BackgroundImageHero from './block-library/section-templates/BackgroundImageHero';

import LeftInfobox from './block-library/section-templates/LeftInfobox';
import RightInfobox from './block-library/section-templates/RightInfobox';
import BroadInfobox from './block-library/section-templates/BroadInfobox';

import LeftImage from './block-library/section-templates/LeftImage';
import RightImage from './block-library/section-templates/RightImage';
import ThreeRoundFeatures from './block-library/section-templates/ThreeRoundFeatures';
import Banner from './block-library/section-templates/Banner';
import ImageFrame from './block-library/section-templates/ImageFrame';

import VideoHero from './block-library/section-templates/VideoHero';
import ThreeVideos from './block-library/section-templates/ThreeVideos';

import StandardContactForm from './block-library/section-templates/StandardContactForm';
import ContactInfo from './block-library/section-templates/ContactInfo';
import ContactFormWithMap from './block-library/section-templates/ContactFormWithMap';

import BlogList from './block-library/section-templates/BlogList';

export default {
	/* Templates */
	OneColumnText,
	TwoColumnText,
	ThreeColumnText,
	FourColumnText,
	
	StandardHero,
	BackgroundImageHero,
	
	LeftInfobox,
	BroadInfobox,
	RightInfobox,

	LeftImage,
	RightImage,
	ThreeRoundFeatures,
	ImageFrame,
	Banner,
	
	VideoHero,
	ThreeVideos,
	
	StandardContactForm,
	ContactInfo,
	ContactFormWithMap,

	BlogList,

	/* Columns */
	Image,
	Text,
	Button,
	Menu,
	Gallery,
	Video,
	Map,
	IFrame,
	Feed,

	/* Form blocks */
	// Input as TextArea, // legacy
	Form,
	Input,
	Checkbox,
	Radio,

	/* Smart blocks */
	BlogAuthor,
	BlogDescription,
	BlogPublicationDate,
	BlogTitle,
	PageTitle,
	SiteTitle,
	Logo,

	/* Add back when elements are ready */
	Divider,
	// Follow,
	// Share
	// Icon
	Gallery
};