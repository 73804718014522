import createApi from 'api';
import { normalizeSites as normalize } from 'store/normalizers/sites';
import constants from 'store/constants';
import { setRoute } from 'utils/routing';
const api = createApi();

const actions = {
	// Async
	fetchSites: () => async (dispatch, getState) => {
		const { user, sites } = getState();

		const siteIds = await api.getSitesByUser({
			userId: user._id,
			admin: user.roles.find(({ type }) => type === 'superadmin'),
			siteId: sites.params.siteid
		});

		// const normalizedSites = normalize(sites);

		dispatch(actions.setSites(siteIds));
	},

	fetchSite: (siteID) => async (dispatch, getState) => {
		const site = await api.getSite(siteID);

		const normalizedSite = normalize([site]);

		dispatch(actions.setSite(normalizedSite));
	},

	createSite: data => async (dispatch, getState) => {
		const site = await api.postSite(data);

		window.location.href = `/dashboard/${site._id}`; 
		// await actions.fetchSites()(dispatch, getState);

		// setRoute(`/dashboard/${site._id}`);
	},

	deleteSite: site => async (dispatch, getState) => {
		await api.deleteSite(site);

		setRoute('/dashboard', { delete: 'DELETE_SITE' });

		await actions.fetchSites()(dispatch, getState);
	},

	copySite: ({ siteID, newSiteName }) => async (dispatch, getState) => {
		const copiedSiteID = await api.copySite({ siteID, newSiteName });

		window.location.href = `/dashboard/${copiedSiteID}`;

		// await actions.fetchSites()(dispatch, getState);	

		// setRoute(`/dashboard/${siteID}`);
	},

	transferSite: ({ siteID, newOwnerEmail }) => async (dispatch, getState) => {
		const copiedSite = await api.transferSite({ siteID, newOwnerEmail });

		location.reload();
	},

	updateSite: (siteid, data) => async (dispatch, getState) => {
		await api.updateSitePartial(siteid, data);

		await actions.fetchSite(siteid)(dispatch, getState);
	},

	setBranding: ({ siteID, fileID, type }) => async (dispatch, getState) => {
		await api.setBranding({ siteID, fileID, type });

		await actions.fetchSite(siteID)(dispatch, getState);
	},

	toggleModules: (siteID, modules) => async (dispatch, getState) => {
		dispatch({
			data: true,
			type: constants.SET_MODULES_LOADING
		});

		let result;

		try {
			result = await api.modules.patch(siteID, modules);
		} catch (e) {
			dispatch({
				data: false,
				type: constants.SET_MODULES_LOADING
			});

			throw e;
		}

		dispatch({
			data: {
				siteID,
				defaultBlogLayout: isNaN(result) && result,
				modules
			},
			type: constants.SET_MODULES
		});
	},

	updateSiteSettings: (siteID, key, value) => ({
		siteID,
		key,
		value,
		type: constants.UPDATE_SITE_SETTIMGS,
	}),

	setSite: site => ({
		data: site,
		type: constants.SET_SITE,
	}),

	setSites: sites => ({
		data: sites,
		type: constants.SET_SITES,
	}),

	removeSite: site => ({
		data: site,
		type: constants.DELETE_SITE,
	}),

	changeSite: site => ({
		data: site,
		type: constants.UPDATE_SITE,
	}),

	updateLayoutContent: (layoutID, tree) => ({
		data: {
			layoutID,
			tree
		},
		type: constants.UPDATE_LAYOUT_CONTENT
	}),

	selectLayout: (layoutID) => ({
		data: {
			layoutID
		},
		type: constants.SELECT_LAYOUT
	})
};

export default actions;
