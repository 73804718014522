import React from 'react';
import { FormattedMessage } from 'react-intl';

const translateDropzone = key => {
    let dropKey = `options.`+ key + ' dropzone label';

    return (
        <FormattedMessage id={dropKey} />
    );
};

const translateDropzoneName = (type) => {
    switch (type && type.toLowerCase()) {

        // grid dropzones
        case 'grid': 
        case 'gridescape': 
        case 'gridzonetopescape': 
        case 'gridzonetop': 
        case 'gridzonebottomescape': 
        case 'gridzonebottom': {
            return translateDropzone('section');
        }

        // row dropzones
        case 'row':
        case 'rowescape':
        case 'rowzonetopescape': 
        case 'rowzonetop': 
        case 'rowZonebottomescape': 
        case 'rowZonebottom': 
        case 'rowZoneright':
        case 'rowZoneleft': {
            return translateDropzone('row');
        }
        
        // column dropzones
        case 'column':
        case 'columnescape':
        case 'columnzoneleftescape':
        case 'columnzoneleft': 
        case 'columnzonerightescape':
        case 'columnzoneright': {
            return translateDropzone('column');
        }
        
        // column element dropzones
        case 'columnelement': 
        case 'columnelementescape': 
        case 'columnelementzoneleft': 
        case 'columnelementzoneright': 
        case 'columnelementzonetop': 
        case 'columnelementzonebottom': {
            return translateDropzone('column element');
        }
        
        default: {
            return null;
        }
    }
};

export default translateDropzoneName;