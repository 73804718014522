import React, { Component } from 'react';
import {
	Icon,
	IconGroup,
	Header,
	Message,
	Divider
} from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';

import actions from 'store/actions/user';

import { connect } from 'react-redux';

import LoginForm from 'components/forms/login';
// import { SubmissionError } from 'redux-form';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import SviftGA from 'utils/svift-ga';

// Internet Explorer 6-11 detection for legacy browser warning
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// Firefox 1.0+
// const isFirefox = typeof InstallTrigger !== 'undefined';

const mapDispatchToProps = (dispatch) => {
	return {
		login: data => dispatch(actions.login(data))
	};
};

class Login extends Component {
	constructor(props) {
		super(props);

		this.handleLogin = this.handleLogin.bind(this);
	}

	async handleLogin({ email, password }) {
		try {
			SviftGA.AUTHORIZATION.LOG_IN_STARTED();

			await this.props.login({ email, password });
		} catch (e) {
			handleSubmitError(this.props.intl)(e);
			// throw new SubmissionError({
			// 	_error: 'validation.unknown error'
			// });
		}
	}

	render() {
		const { intl } = this.props;

		return (
			<React.Fragment>
				{/* login icon */}
				<IconGroup style={{ marginBottom: 32 }}>
					<Icon
						name="sn-account_circle"
						className="light-shade-color"
						style={{ fontSize: 80, margin: 0 }}
						/>
					<Icon
						corner
						name="sn-lock5"
						className="primary-color"
						style={{
							fontSize: 48,
							marginRight: 3,
							marginBottom: -16
						}}
						/>
				</IconGroup>

				{/* login title + description */}
				<Header
					as="h1"
					content={intl.formatMessage({ id: 'login.title' })}
					textAlign="center"
					className="dark-primary-color"
					style={{ marginTop: 0, marginBottom: 16 }}
				/>
				<Message className="primary" style={{ position: 'relative', padding: 16, marginBottom: 16 }}>
					<Icon
						name="sn-logo"
						className="primary-color"
						style={{
							position: 'absolute',
							left: 12,
							top: 9,
							fontSize: 32,
							marginRight: 6
						}}
					/>
					<FormattedMessage id="login.description" />
				</Message>
				
				{/* Legacy browser warning */}
				{isIE &&
					<div style={{ padding: 0, marginTop: 16, marginBottom: 24 }}>
						<Message className="warning" style={{ textAlign: 'center', display: 'block' }}>
							<Header as="h4">
								<FormattedMessage id="messages.legacy browser warning title" /> <span style={{ opacity: 0.5 }}>*</span>
							</Header>
							<p style={{ marginBottom: 8 }}>
								<FormattedMessage id="messages.legacy browser warning description" />
							</p>

							{/* Browser alternatives */}
							<div>
								<div style={{ display: 'inline-block', width: '40%', textAlign: 'right', verticalAlign: 'middle' }}>
									<a
										href="https://www.google.com/chrome/"
										title="Google Chrome"
										target="_blank"
										style={{ textAlign: 'right' }}
									>
										<Icon name="chrome" style={{ opacity: 1, fontSize: 40, marginBottom: 8, marginLeft: 'auto', textDecoration: 'none' }} />
										<span style={{ fontWeight: 'bold', display: 'block' }}>Chrome</span>
									</a>
								</div>
								<div style={{ display: 'inline-block', width: '20%', verticalAlign: 'middle' }}>
									<p><FormattedMessage id="general.or" /></p>
								</div>
								<div style={{ display: 'inline-block', width: '40%', textAlign: 'left', verticalAlign: 'middle' }}>
									<a
										href="https://www.mozilla.org/firefox/"
										title="Mozilla Firefox"
										target="_blank"
										style={{ textAlign: 'left' }}
									>
										<Icon name="firefox" style={{ opacity: 1, fontSize: 40, marginBottom: 8, textDecoration: 'none' }} />
										<span style={{ fontWeight: 'bold', display: 'block' }}>Firefox</span>
									</a>
								</div>
							</div>

							<p style={{ fontSize: 12, textAlign: 'left' }}>
								* <FormattedMessage id="messages.legacy browser warning ie disclaimer" />
							</p>
						</Message>
					</div>
				}

				<LoginForm
					onSubmit={this.handleLogin}
				/>
			</React.Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(injectIntl(Login));