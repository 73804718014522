import React, { PureComponent } from 'react';
import {
    Grid,
    GridColumn,
    Loader,
    Dimmer,
    Button,
    Icon,
    Message,
    Label,
    Popup,
    Header,
    Divider
} from 'svift-ui';
import ModuleColumn from './__components/ModuleColumn.js';
import { injectIntl, FormattedMessage } from 'react-intl';
import AddOns from './add-ons';
import { connect } from 'react-redux';
import ModalWrapper from 'components/modal';
import siteActions from 'store/actions/sites';
import availableAddOns from './add-ons/misc/addonData';
import formatModuleUpdates from './add-ons/misc/formatModuleUpdates';
import errorHandler from 'utils/errorHandler';
import InfoIcon from 'components/InfoIcon';
import Money from 'svift-library/format/money';
import Notification from 'components/hoc/notification';
import { storageCapacity as capacitySelector } from 'store/selectors/sites/files';
import SviftGA from 'utils/svift-ga';

// import makeMemoize from 'svift-library/memoize';
// const memoizeIconRender = makeMemoize();

const mapStateToProps = (state) => {
    const siteID = state.sites.params.siteid;
    const site = state.sites.sites[state.sites.params.siteid];

    const storageCapacity = capacitySelector(state);

    return {
        siteID,
        storageCapacity,
        modules: site.modules || {},
        modulesLoading: state.sites.modulesLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModules: (siteID, modules) => dispatch(siteActions.toggleModules(siteID, modules))
    };
};

class Modules extends PureComponent {
    constructor() {
        super();
        
        this.state = {
            clickedModule: null,
            errors: null
        };
    }

    setClickedModule = (clickedModule, enabling = false) => {
        this.setState({
            clickedModule,
            enabling,
            errors: null
        });
    }

    toggleModulesHandler = async (moduleId, enable = false) => {
        const modules = formatModuleUpdates(moduleId, enable);

        try {
            await this.props.toggleModules(this.props.siteID, modules)

            this.setState({
                clickedModule: null,
                errors: null
            });

            if (enable) {
                this.props.createNotification({
                    content: this.props.intl.formatMessage({ id: 'modules.module activated notification' }, { moduleName: '"Blog"' }),
                    className: 'success',
                    position: 'topCenter',
                    icon: 'sn-blog',
                    deleteTime: 5000
                });

                SviftGA.MODULES.BLOG_ACTIVATED();
            } else {
                this.props.createNotification({
                    content: this.props.intl.formatMessage({ id: 'modules.module deactivated notification' }, { moduleName: '"Blog"' }),
                    className: 'info',
                    position: 'topCenter',
                    icon: 'sn-blog',
                    deleteTime: 5000
                });  

                SviftGA.MODULES.BLOG_DEACTIVATED();
            }
        } catch(e) {
            const errors = errorHandler(this.props.intl)(e);
            
            this.setState({
                errors
            });
        }
    }

    renderToggleModal = () => {
        const clickedModuleInfo = availableAddOns[this.state.clickedModule];

        return (
            <ModalWrapper
                open={this.state.clickedModule && !this.state.enabling} 
                handleClose={() => this.setState({ clickedModule: null })} 
                header="modules.deactivation title"
                style={{ maxWidth: 520 }}
            >
                {this.props.modulesLoading &&
                    <Dimmer active inverted>
                        <Loader size="huge" style={{ fontSize: 32 }}>
                            <Icon name="sn-sphere" /> 
                            <FormattedMessage id="general.saving changes" />
                        </Loader>
                    </Dimmer>
                }

                <Icon
                    name="sn-warning"
                    style={{ display: 'block', fontSize: 64, opacity: 0.5, margin: 'auto', marginBottom: 16 }}
                    className="warning-color"
                />
                <div style={{ textAlign: 'center', fontSize: 20, marginBottom: 6, fontWeight: 400 }}>
                    <FormattedMessage id="modules.confirm deactivation title" />:
                </div>
                <Header
                    as="h2"
                    className="dark-warning-color"
                    style={{ textAlign: 'center', marginTop: 0 }}
                >
                    {/* <Icon name="sn-blog" style={{ verticalAlign: 'middle', opacity: 0.4 }} /> TO DO: insert module icon */}
                    <FormattedMessage id={clickedModuleInfo && clickedModuleInfo.intl} />?
                </Header>
                <Divider />
                <p>
                    <FormattedMessage id="modules.confirm deactivation description" />
                </p>
                <Message className="info">
                    <span style={{ fontSize: 14 }}>
                        <span style={{ fontWeight: 500, marginRight: 6 }}><FormattedMessage id="general.info label" />:</span>
                        <FormattedMessage id="modules.deactivation disclaimer" />
                    </span>
                </Message>
                
                <Divider />

                {this.state.errors &&
                    <Message
                        negative
                        key="err"
                    >
                        {this.state.errors.map(e => {
                            return (
                                <p key={e}> {e} </p>
                            );
                        })}
                    </Message>
                }
                
                <Button
                    className="negative" 
                    onClick={() => this.toggleModulesHandler(this.state.clickedModule, false)}                 
                >
                    <Icon name="sn-cancel-square" style={{ marginRight: 8 }} /> 
                    <FormattedMessage id="modules.deactivate module button" />
                </Button>

                <Button
                    onClick={() => this.setState({ clickedModule: null })}
                >
                    <Icon name="sn-cross" style={{ marginRight: 8 }} /> 
                    <FormattedMessage id="general.cancel" />
                </Button>
            </ModalWrapper>
        );
    }

	render() {
        const { intl } = this.props;

		return (
            <React.Fragment>
                {/* Addon (inactive modules) overview */}
                <AddOns
                    modules={this.props.modules}
                    setClickedModule={this.setClickedModule}
                    clickedModule={this.state.enabling && this.state.clickedModule}
                    errors={this.state.errors}
                    toggleModules={this.toggleModulesHandler}
                    modulesLoading={this.props.modulesLoading}
                />

                <Divider />

                {/* Active modules overview */}
                <Grid
                    padded
                    columns={4}
                    className="section-shade"
                    style={{
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 40,
                        border: 'solid 2px rgba(88, 115, 37, 0.2)',
                        maxHeight: 320,
                        overflowY: 'auto'
                    }}
                >
                    <GridColumn computer={16} textAlign="center">
                        <Divider horizontal style={{ margin: 0 }}>
                            <Icon circular name="sn-checkmark" className="positive-color" style={{ margin: 0 }} />
                        </Divider>

                        <Header
                            as="h4"
                            className="dark-positive-color"
                            style={{ marginTop: 8 }}
                            content={
                                <span>
                                    {/* <Icon name="sn-checkmark" /> */}
                                    <FormattedMessage id="modules.active modules title" />
                                    <InfoIcon description={intl.formatMessage({ id: 'modules.active modules description' })} className="positive-color" />
                                </span>
                            }
                        />
                    </GridColumn>

                    {this.renderToggleModal()}

                    {Object.keys(this.props.modules).map(moduleKey => {
                        const module = this.props.modules[moduleKey];
                        
                        if (!module) return null;

                        const moduleInfo = availableAddOns[moduleKey];

                        // TO DO: Insert settings icon (dropdown menu) with item/button for deactivating module
                        if (moduleInfo) {
                            let priceInfo = intl.formatMessage({ id: 'general.free title' }).toUpperCase();

                            if (moduleInfo.amount) {
                                priceInfo = Money(moduleInfo.amount, { currency: moduleInfo.currency }).readable(); 
                            }

                            return (
                                <ModuleColumn
                                    key={moduleInfo.id}
                                    icon={moduleInfo.icon}
                                    name={intl.formatMessage({ id: moduleInfo.intl })}
                                    renderTopRightIcon={style => {
                                        return (
                                            <Popup
                                                trigger={
                                                    <Icon
                                                        link
                                                        style={style}
                                                        className="error-color"
                                                        name={this.state.iconHovered ? 'sn-cancel-circle2' : 'sn-cancel-circle'}
                                                        onMouseEnter={e => this.setState({ iconHovered: true })}
                                                        onMouseLeave={e => this.setState({ iconHovered: false })}
                                                        onClick={() => {
                                                            this.setClickedModule(moduleInfo.id);
                                                        }}
                                                    />
                                                }
                                                content={intl.formatMessage({ id: 'modules.deactivate module popup' })}
                                                position="top center"
                                            />
                                        );
                                    }}
                                    infoLabelIcon="sn-plus2"
                                    // TO DO: Insert infoLabel from moduleInfo
                                    infoLabel={priceInfo}
                                    infoLabelDescription={this.props.intl.formatMessage({ id: moduleInfo.descriptionIntl })}
                                    label
                                    labelIcon="sn-checkmark"
                                    color={moduleInfo.color}
                                    size={24}
                                    fontSize={16}
                                    // onClick={() => {
                                    //     this.setClickedModule(moduleInfo.id);
                                    // }}
                                />
                            );
                        }
                    })}

                    {/* Editor */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.editor title' })}
                        icon="sn-quill3"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-sphere"
                        infoLabel="Site"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.editor description' })}
                        color="#7C569E"
                        size={24}
                        fontSize={16}
                    />
                    {/* Design */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.design title' })}
                        icon="sn-bucket"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-sphere"
                        infoLabel="Site"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.design description' })}
                        color="#D15B55"
                        size={24}
                        fontSize={16}
                    />
                    {/* Forms */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.forms title' })}
                        icon="send outline"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-sphere"
                        infoLabel="Site"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.forms description' })}
                        color="#9b651e"
                        size={24}
                        fontSize={16}
                    />
                    {/* Navigation */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.navigation title' })}
                        icon="sn-direction"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-sphere"
                        infoLabel="Site"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.navigation description' })}
                        color="#864691"
                        size={24}
                        fontSize={16}
                    />
                    {/* Users */}
                    {/* TO DO: show how many users are allowed on the site: */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.users title' })}
                        icon="users"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-sphere"
                        infoLabel="Site"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.users description' })}
                        color="#912C2A"
                        size={24}
                        fontSize={16}
                    />
                    {/* Cookie banner */}
                    {/* TO DO: hide when google analytics is not used: */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.cookie banner title' })}
                        icon="bullseye"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="google"
                        infoLabel="Analytics"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.cookie banner description' })}
                        color="#BF7727"
                        size={24}
                        fontSize={16}
                    />
                    {/* SSL encryption */}
                    {/* TO DO: hide when site is not activated: */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.ssl title' })}
                        icon="shield"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-flag6"
                        infoLabel="Activation"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.ssl description' })}
                        color="#557431"
                        size={24}
                        fontSize={16}
                    />
                    {/* DNS / Domain */}
                    {/* TO DO: hide when site is not activated: */}
                    <ModuleColumn
                        name={intl.formatMessage({ id: 'modules.dns title' })}
                        icon="plug"
                        label
                        labelIcon="sn-checkmark"
                        infoLabelIcon="sn-flag6"
                        infoLabel="Activation"
                        infoLabelDescription={intl.formatMessage({ id: 'modules.dns description' })}
                        color="#2F65AB"
                        size={24}
                        fontSize={16}
                    />
                    {/* Storage */}
                    {this.props.storageCapacity &&
                        <ModuleColumn
                            // TO DO: extract amount of storage for site:
                            name={`${this.props.storageCapacity / 1000 / 1000 / 1000} GB ${intl.formatMessage({ id: 'modules.storage title' })}`}
                            icon="sn-database3"
                            label
                            labelIcon="sn-checkmark"
                            infoLabelIcon="sn-flag6"
                            infoLabel="Activation"
                            infoLabelDescription={intl.formatMessage({ id: 'modules.storage description' })}
                            color="#8C7954"
                            size={24}
                            fontSize={16}
                        />
                    }
                </Grid>
            </React.Fragment>
		);
	}
} 

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Notification(Modules)));