exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".icon-picker__icon-preview--3MwAn>.icon-picker__icon-text--2LCE2{font-family:'Ubuntu', sans-serif !important}.icon-picker__icon-preview--3MwAn>.icon-picker__icon-text--2LCE2{font-weight:400}.icon-picker__selected-icon--3AEDy{font-size:64px}.icon-picker__selected-icon--3AEDy>.icon-picker__icon-text--2LCE2{font-size:14px}.icon-picker__icon-preview--3MwAn{cursor:pointer;padding:6px;text-align:center;flex-grow:1;display:flex;flex-direction:column;border:2px solid #fcfbfa;border-radius:4px;margin:2px}.icon-picker__icon-preview--3MwAn>i{color:#4f4741;font-size:32px;margin-bottom:8px}.icon-picker__icon-preview--3MwAn>.icon-picker__icon-text--2LCE2{font-weight:400;font-size:9px}.icon-picker__icon-preview--3MwAn:hover{color:#5f4575;border:2px solid #9273ad}.icon-picker__icon-preview--3MwAn.icon-picker__selected--1BxfC{color:#fcfbfa;background-color:#523c65;border:2px solid #9273ad;cursor:default}.icon-picker__icon-preview--3MwAn.icon-picker__selected--1BxfC>i{color:#fcfbfa}.icon-picker__icon-list--2HZKf{max-height:320px;max-width:520px;overflow-y:auto;display:flex;flex-wrap:wrap;justify-content:space-between;background-color:#f3eee9;border-radius:4px;border:solid 1px #d8d4d0}\n", ""]);

// exports
exports.locals = {
	"icon-preview": "icon-picker__icon-preview--3MwAn",
	"icon-text": "icon-picker__icon-text--2LCE2",
	"selected-icon": "icon-picker__selected-icon--3AEDy",
	"selected": "icon-picker__selected--1BxfC",
	"icon-list": "icon-picker__icon-list--2HZKf"
};