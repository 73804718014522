import React, { PureComponent  } from 'react';
import ReactDOM from 'react-dom';
import {
	Header,
	HeaderSubheader,
	Form,
	FormInput,
	Button,
	Grid,
	GridColumn,
	Message,
	Icon,
	FormSelect,
	Divider,
	Image,
	List,
	ListItem
} from 'svift-ui';
import { replaceRoute } from 'utils/routing';
import saveGaToken from './api';
import createApi from 'api';
import AnalyticsForm from 'components/forms/analytics';
import GoogleGsuiteVerificationCodeAnswer from 'containers/dashboard/top-navigation/__components/support/answerComponents/GoogleGsuiteVerificationCodeAnswer';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
// import siteActions from 'store/actions/sites';
import InfoIcon from 'components/InfoIcon';
import SviftGA from 'utils/svift-ga';

import { connect } from 'react-redux';

import { FormattedMessage, injectIntl } from 'react-intl';

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	return {
		siteid,
		gaToken: site.statistics && site.statistics.googleAnalytics,
		site
	};
};

class Statistics extends PureComponent {

	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.handleGoogleConnect = this.handleGoogleConnect.bind(this);
		this.handleGSuite = this.handleGSuite.bind(this);
		this.addAnalyticAccount = this.addAnalyticAccount.bind(this);
		this.unBindAnalyitcs = this.unBindAnalyitcs.bind(this);
		this.unBindGsuiteId = this.unBindGsuiteId.bind(this);
		this.toggleInstructions = this.toggleInstructions.bind(this);

		this.state = {
			isActivated: (this.props.site.domains),
			loading: true,
			showHelpForGsuite: false,
			analyticsDomains: null,
			authUrl: null,
			currentAccount: null,
			accounts: this.getAnalyticsAccounts(),
			gsuiteID: null,
			gsuiteIDToSave: null,
			showInstructions: false,
			error: null
		};
	}

	toggleInstructions() {
		this.setState({
			showInstructions: !this.state.showInstructions
		});
	}

	toggleAccordion = () => {
		this.setState({
			showHelpForGsuite: !this.state.showHelpForGsuite,
		})
	}

	renderGsuiteHelp = () => {
		if (this.state.showHelpForGsuite) {
			return (
				<div>
					{/* Accordion with help for Google G Suite: */}
					<div style={{ marginTop: 16, marginBottom: 24, fontSize: 18 }}>
						<GoogleGsuiteVerificationCodeAnswer />
					</div>
				</div>
			)
		}
	}

	async onSubmit(values) {
		const { gaToken } = values;

		try {
			await saveGaToken(this.props.siteid, gaToken);

			SviftGA.SITES.GA_CREATED();

			//await this.props.fetchSites();
		} catch (e) {
			handleSubmitError(this.props.intl)(e);
		}
	}

	componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
		if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
			if (isScriptLoadSucceed) {
				this.initClient();
			}
			else this.props.onError()
		}
	}

	initClient() {
	}

	handleGoogleConnect(e) {
		e.preventDefault();
		window.location = this.state.authUrl;
	}

	async handleGSuite(e) {
		e.preventDefault();
		if (!/^[\w,-]+$/.test(this.state.gsuiteID)) {
			return this.setState({
				error: 'Not a valid GSuite ID'
			});
		} else {
			this.setState({
				error: null
			});
		}
		const resp = await createApi().google.saveGSuiteID(this.props.siteid, this.state.gsuiteIDToSave);

		SviftGA.SITES.GSUITE_CREATED();
		
		this.setState({
			gsuiteID: resp
		});
	}

	componentDidMount() {
		this.setInitState();
	}

	async setInitState() {
		try {
			if (!this.state.isActivated) {
				return;
			}
			if (this.state.accounts) {
				const googleDiv = ReactDOM.findDOMNode(this.refs.googlediv);				
				this.setState({
					authUrl: null,
					currentAccount: null
				});
				replaceRoute('settings#statistics');
				return;
			}
			const analyticsId = await createApi().google.getAnalyticsId(this.props.siteid);
			if (analyticsId) {
				this.setState({
					authUrl: null,
					currentAccount: analyticsId,
					accounts: null
				})
			} else {
				const oauthUrl = await createApi().google.getAuthUrl(this.props.siteid);
				this.setState({
					authUrl: oauthUrl,
					currentAccount: null,
					accounts: null
				});
			}
			this.setState({
				gsuiteID: await createApi().google.getGsuiteId(this.props.siteid)
			});			

		} catch (error) {
			console.error(error);
		}
	}

	getAnalyticsAccounts() {
		const accountsSting = this.getParameterByName('accounts');
		if (!accountsSting) return null;

		const accounts = accountsSting.split(',').map(a => {
			return {
				text: a.split(';')[0],
				value: a.split(';')[1]
			};
		});
		return accounts;
	}

	async addAnalyticAccount() {
		const resp = await createApi().google.saveAnalyticsId(this.props.siteid, this.state.currentAccount);
		
		SviftGA.SITES.GA_CREATED();

		this.setState({
			accounts: null,
			authUrl: null,
			currentAccount: resp
		});
		replaceRoute('settings#statistics');
	}

	async unBindAnalyitcs() {
		const resp = await createApi().google.saveAnalyticsId(this.props.siteid, null);

		SviftGA.SITES.GA_DELETED();
		
		this.setInitState();
	}

	async unBindGsuiteId() {
		const resp = await createApi().google.saveGSuiteID(this.props.siteid, null);

		SviftGA.SITES.GSUITE_DELETED();

		this.setState({
			gsuiteID: null
		});
	}

	getParameterByName(name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	renderGoogleMessage() {
		if (this.state.isActivated) return null;
		return (
		<Message
				key="google-offline-message"
				className="info"
				size="large"
				icon={<Icon name="sn-info" style={{ fontSize: 28 }} />}
				content={
					<Header as="h4">
						<FormattedMessage id="settings.google section offline message title" />
						<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
							<FormattedMessage id="settings.google section offline message description" />
						</HeaderSubheader>
					</Header>
				}
				style={{ textAlign: 'left', marginBottom: 32 }}
			/>
		)
	}

	renderAnalytics() {
		if (!this.state.isActivated) return null;
		return (
		<div key="two">
				<Divider />
				<Header as="h3">
					<FormattedMessage id="settings.google analytics title" />
				</Header>
				<p style={{ textAlign: 'left', marginBottom: 16 }}>
					<FormattedMessage id="settings.google analytics description" /><br />
					<a href="https://analytics.google.com" target="_blank">
						<FormattedMessage id="settings.google analytics link title" />
					</a>
				</p>
			</div>)
	}

	renderOauthButton() {
		if (!this.state.authUrl) return null;
		return [
			<Message
				key="oauth-one"
				className="info"
				size="large"
				icon={<Icon name="sn-info" style={{ fontSize: 28 }} />}
				content={
					<Header as="h4">
						<FormattedMessage id="general.important title" />
						<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
							<FormattedMessage id="settings.google analytics message initial warning description" />
						</HeaderSubheader>
					</Header>
				}
				style={{ textAlign: 'left', marginBottom: 32 }}
			/>,
			<Button key='oauth-two' size="huge" className="primary" style={{ marginBottom: 16 }} onClick={this.handleGoogleConnect}>
				<Icon name="sn-google" />
				<FormattedMessage id="settings.google analytics button connect site with analytics title" />
			</Button>
		]
	}

	renderSiteForm() {
		if (!this.state.accounts) return null;
		return [
			<Message
				className="info"
				size="large"
				icon={<Icon name="sn-warning" style={{ fontSize: 32 }} />}
				content={
					<Header as="h4" className="regular-text">
						<FormattedMessage id="settings.google analytics message complete connection title" />
						<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
							<FormattedMessage id="settings.google analytics message complete connection description" />
						</HeaderSubheader>
					</Header>
				}
				style={{ textAlign: 'left', marginBottom: 16 }}
			/>,
			<FormSelect
				key="siteform-one"
				fluid
				options={this.state.accounts}
				value={this.currentAccount}
				onChange={(e, { value }) => {
					this.setState({
						currentAccount: value
					});
				}}
				placeholder={this.props.intl.formatMessage({ id: 'settings.google analytics website select placeholder' })}
				style={{ fontSize: 26, marginBottom: 16 }}
			/>,
			<Button key="siteform-two" positive size="massive" style={{ marginBottom: 32 }} onClick={this.addAnalyticAccount}><Icon name="checkmark" /> Connect my site to Analytics</Button>
		]
	}

	renderSiteBinded() {
		if (this.state.accounts) return null;
		if (!this.state.currentAccount) return null;
		return (
			<Message
				key="sitebinded-one"
				className="positive"
				size="huge"
				icon={<Icon name="sn-stats-bars2" style={{ fontSize: 40 }} />}
				content={
					<div>
						<Icon onClick={this.unBindAnalyitcs} circular link name="sn-cross" style={{ float: 'right', fontSize: 20 }} />
						<Header as="h3" className="regular-text">
							<FormattedMessage id="settings.google analytics connected message title" />
							<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
								<FormattedMessage id="settings.google analytics connected message label" />: {this.state.currentAccount}
							</HeaderSubheader>
						</Header>
					</div>
				}
				style={{ textAlign: 'left' }}
			/>
		)
	}

	renderGSuite(intl, showHelpForGsuite) {
		if (!this.state.isActivated) return null;
		const { showInstructions } = this.state;

		return (
			<Message style={{ marginTop: 44, textAlign: 'left' }}>
				<Header
					as="h4"
					icon="sn-google"
					className="regular-text"
					content={
						<React.Fragment>
							<FormattedMessage id="settings.google gsuite title" />
							<InfoIcon description={intl.formatMessage({ id: 'settings.google gsuite description' })} />
						</React.Fragment>
					}
				/>
				
				<div style={{ marginTop: 8, marginBottom: 12 }}>
					<FormattedMessage id="settings.google gsuite introduction" />
				</div>
				
				<div className="section-shade" style={{ padding: 24 }}>
					<Divider horizontal style={{ marginBottom: 24 }}>
						<Button
							basic
							circular
							size="large"
							className={!showInstructions ? 'info' : 'info active-button'}
							onClick={this.toggleInstructions}
						>
							<Icon
								name={!showInstructions ? 'sn-plus-circle' : 'sn-minus-circle2'}
								style={{ marginRight: 8 }} />
							<FormattedMessage id={`general.${!showInstructions ? 'show' : 'hide'} instructions`} />
						</Button>
					</Divider>
					
					{showInstructions &&
						<GoogleGsuiteVerificationCodeAnswer />
					}
				</div>
			</Message>
		);
		
		// Commented out until we're sure it works ()
		return (this.state.gsuiteID) ? 
		(
			<Form key="last">
				<Divider />
				<Header as="h3">
					<FormattedMessage id="settings.google gsuite title" />
				</Header>
				<p style={{ textAlign: 'left' }}>
					<FormattedMessage id="settings.google gsuite description" /><br />
					<a href="https://gsuite.google.com" target="_blank">
						<FormattedMessage id="settings.google gsuite link title" />
					</a>
				</p>
				<Message
					key="sitebinded-gsuite"
					className="positive"
					size="huge"
					icon={<Icon name="google" style={{ fontSize: 40 }} />}
					content={
						<div>
							<Icon onClick={this.unBindGsuiteId} circular link name="sn-cross" style={{ float: 'right', fontSize: 20 }} />
							<Header as="h3">
								<FormattedMessage id="settings.google gsuite connected message title" />
								<HeaderSubheader style={{ marginTop: 4, fontSize: 18, opacity: 0.6 }}>
									<FormattedMessage id="settings.google gsuite connected message label" />: {this.state.gsuiteID}
								</HeaderSubheader>
							</Header>
						</div>
					}
					style={{ textAlign: 'left' }}
				/>
			</Form>
		)
		: (
		<Form key="last">
				<Divider />
				<Header as="h3">
					<FormattedMessage id="settings.google gsuite title" />
				</Header>
				<p style={{ textAlign: 'left' }}>
					<FormattedMessage id="settings.google gsuite description" /><br />
					<a href="https://gsuite.google.com" target="_blank">
						<FormattedMessage id="settings.google gsuite link title" />
					</a>
				</p>
				<FormInput
					size="huge"
					// value={this.state.gsuiteID}
					onChange={(e, { value }) => this.setState({ gsuiteIDToSave: value })}
					label={intl.formatMessage({ id: 'settings.google gsuite verification input label' })}
					placeholder={intl.formatMessage({ id: 'settings.google gsuite verification input placeholder' })}
				/>

				<div style={{ marginBottom: 16 }}>
					<a onClick={this.toggleAccordion} style={{ fontSize: 20 }}>
						<Icon circular name={showHelpForGsuite ? 'minus' : 'plus'} style={{ marginRight: 8, fontSize: 18, textDecoration: 'none' }} />
						<FormattedMessage id={showHelpForGsuite ? 'support.faq question settings google gsuite where to find verification' : 'support.faq question settings google gsuite where to find verification'} />
					</a>
				</div>

				{this.renderGsuiteHelp()}

				<Button size="huge" positive onClick={this.handleGSuite}>
					<Icon name="sn-cloud-upload" />
					<FormattedMessage id="settings.google gsuite save button title" />
				</Button>
			</Form>)
	}

	renderError() {
		if (!this.state.error) return null;

		return (
			<Message key="error" className="error">
				{this.state.error}
			</Message>
		);
	}

	render() {
		const { intl } = this.props;
		const { showHelpForGsuite } = this.state;

		return [,
			<div key="one" ref={ref => this.googlediv = ref}></div>,
			,
			this.renderGoogleMessage(),
			this.renderAnalytics(),
			this.renderOauthButton(),
			this.renderSiteForm(),
			this.renderSiteBinded(),
			this.renderGSuite(intl, showHelpForGsuite),
			this.renderError()
		];
	}
}

export default connect(mapStateToProps)(injectIntl(Statistics));