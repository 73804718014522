import React, { memo, useState } from "react";
import css from './options.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon } from 'svift-ui';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import resolveElementIcon from 'utils/editor/resolveElementIcon';
import resolveElementName from 'utils/editor/resolveElementName';

export default injectIntl(memo(props => {
    const closeEditingPopup = useTooltip();
    const [hovered, setHovered] = useState(false);

    return (
        <div className={css['header-container']}>
            <div className={css['active-element-label']}>
                <FormattedMessage id="options.now editing label" />
            </div>

            <div className={`${css['active-element-title']} ${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
                {/* close active element icon */}
                <Popup
                    target={closeEditingPopup.current}
                    position="right center"
                    zIndex={998}
                >
                    <FormattedMessage id="options.close editing popup" /> {props.component}
                </Popup>
                <Icon
                    link
                    ref={closeEditingPopup}
                    name={hovered ? 'sn-cancel-circle2' : 'sn-cancel-circle'}
                    onMouseEnter={e => setHovered(true)} 
                    onMouseLeave={e => setHovered(false)} 
                    onClick={props.close}
                    className={css['close-active-element-icon']}
                />

                {/* active element icon */}
                <Icon
                    circular
                    name={resolveElementIcon(props.component, props.type)}
                    className={css['active-element-icon']}
                />

                {/* active element name/type */}
                <span>{resolveElementName(props.component, props.type)}</span>
            </div>
        </div>         
    );
}));