import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'Share';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {

		}
	})
};