import React from 'react';
import { FormattedMessage } from 'react-intl';

const translateElement = key => {
    let elementKey = `cities.`+ key;

    return (
        <FormattedMessage id={elementKey} />
    );
};

const resolveCityName = countryCode => {
    const lowercaseCode = countryCode.toLowerCase();

    switch (lowercaseCode) {
        case 'copenhagen': {
            return translateElement('copenhagen');
        }
        case 'london': {
            return translateElement('london');
        }
        case 'berlin': {
            return translateElement('berlin');
        }
        case 'oslo': {
            return translateElement('oslo');
        }
        case 'reykjavik': {
            return translateElement('reykjavik');
        }
        case 'stockholm': {
            return translateElement('stockholm');
        }
        case 'helsinki': {
            return translateElement('helsinki');
        }
        case 'amsterdam': {
            return translateElement('amsterdam');
        }
        case 'paris': {
            return translateElement('paris');
        }
        case 'madrid': {
            return translateElement('madrid');
        }
        case 'rome': {
            return translateElement('rome');
        }
        case 'lisbon': {
            return translateElement('lisbon');
        }
        case 'new york': {
            return translateElement('new york');
        }
        case 'los angeles': {
            return translateElement('los angeles');
        }
        case 'tokyo': {
            return translateElement('tokyo');
        }
        case 'bangkok': {
            return translateElement('bangkok');
        }
        case 'auckland': {
            return translateElement('auckland');
        }
        case 'sydney': {
            return translateElement('sydney');
        }
        case 'faroe': {
            return translateElement('faroe');
        }
        case 'godthab': {
            return translateElement('godthab');
        }
        
        // no match fallback
        default: {
            return null;
        }
    }
};

export default resolveCityName;