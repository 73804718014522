import { createSelector } from 'reselect';

const selectCustomThemes = state => state.sites.themes.themes;
const selectSystemThemes = state => state.system.themes;
const selectedSiteThemes = (state, site) => state.sites.sites[site].themes;

// usage => themesSelector(state, siteid)
const themesSelector = createSelector(
	selectCustomThemes,
	selectSystemThemes,
	selectedSiteThemes,
	(customThemes, systemThemes, siteThemes = []) => {
		const themes = {};

		siteThemes.forEach(themeID => {
			themes[themeID] = customThemes[themeID];
		});

		return {
			...themes,
			...systemThemes
		};
	}
);

// Staging refers to the currently staged theme, rather than the current live theme (pages will call staging = false, because it is only interested in the live theme)
const selectLiveTheme = (state, site) => {
	const themes = themesSelector(state, site);
	const liveTheme = state.sites.sites[site].activeTheme;

	const theme = themes[liveTheme];

	if (theme && theme.live) {
		return theme.live;
	}

	return theme;
};

// Staging refers to the currently staged theme, rather than the current live theme (pages will call staging = false, because it is only interested in the live theme)
const selectStagedTheme = (state, site) => {
	const themes = themesSelector(state, site);
	const theme = state.sites.themes.stagedTheme;

	return theme;
};

export { themesSelector, selectLiveTheme, selectStagedTheme };