import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'svift-ui';
import PresetComponents from './preset-components';
import themeActions from 'store/actions/sites/themes';
import { connect } from 'react-redux';
import ViewMode from 'components/view-mode/ViewMode';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import DashboardEditor from './preset-components/DashboardEditor'

const createTranslationFromKey = key => {
	return `elements.${key.toLowerCase()} title`;
};

const mapStateToProps = (state) => {
	const view = state.sites.themes.view;
	return {
		view,
		theme: state.sites.themes.stagedTheme
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
class Presets extends PureComponent {
	constructor() {
		super();

		this.state = {
			selectedPreset: null,
			selectedPresetOption: null,
			viewModeToggle: {},
			errorModeToggle: {} // Form components have a secondary error mode where you can style how the input should look when form submit validation fails
		};

		this.selectPreset = (preset) => this.setState({ selectedPreset: preset || null, selectedPresetOption: null });
		this.selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.preset !== this.props.preset) {
			this.setState({
				selectedPreset: null,
				selectedPresetOption: null,
				viewModeToggle: {},
				errorModeToggle: {}
			});
		}
	}

	updatePresetOption(presetType) {
		// customPresetType (optional 4th argument) is used by elements that address multiple preset keys
		return (presetElement, presetElementOption, newValue, customPresetType) => {
			// console.log(newValue, 'called');

			this.props.updatePreset(customPresetType || presetType, presetElement, presetElementOption, newValue);
		};
	}

	render() {
		const { theme, infoIcon, intl, view } = this.props;
		const component = this.props.preset; // Defined in routes.js

		if (!theme || !component) return null;

		if (view === 'preview') {
			return  <DashboardEditor view='preview'/>
		}
		if (view === 'live') {
			return  <DashboardEditor view='live'/>
		}

		const presetType = theme.presets[component];

		if (!presetType) return null; // Only happens if conversion scripts haven't updated existing themes properly

		const presetElements = Object.keys(presetType).map(preset => {
			const PresetComponent = PresetComponents[component];

			const hasMultipleStates = component === 'button' || component === 'menu' || preset === 'Link' || component === 'input';

			/*
				Form components have secondary modes depending on submit (error, success (not implemented yet), warning(not implemented yet)) - we have to be able to toggle into this mode in order to edit it
			*/
			const hasSecondaryMode = component === 'input' || component === 'checkbox' || component === 'radio';

			/*
				Add when Editor supports these options
			*/
			if (preset === 'Ordered list' || preset === 'Unordered list') {
				return null;
			}

			return (
				<div key={preset} className="preset-section" style={{ padding: '64px 0 0 0' }}>
					<Label basic icon size="medium" className="shade preset-label top-left-corner">
						<FormattedMessage id={createTranslationFromKey(preset) || preset} /> {infoIcon ? <InfoIcon description={infoIcon} /> : null}
					</Label>

					{hasMultipleStates &&
						<ViewMode
							first={intl.formatMessage({ id: 'options.edit title' })}
							firstPopup={intl.formatMessage({ id: 'options.edit popup' })}
							second={intl.formatMessage({ id: 'options.preview title' })}
							secondPopup={intl.formatMessage({ id: 'options.preview popup' })}
							toggled={this.state.viewModeToggle[preset]}
							onToggle={(toggled) => {
								this.setState({
									viewModeToggle: {
										...this.state.viewModeToggle,
										[preset]: toggled
									}
								});
							}}
						/>
					}

					{hasSecondaryMode &&
						<ViewMode
							first={intl.formatMessage({ id: 'options.default mode title' })}
							firstIcon="sn-eye"
							firstPopup={intl.formatMessage({ id: 'options.default mode popup' })}
							second={intl.formatMessage({ id: 'options.validation mode title' })}
							secondIcon="sn-warning"
							secondPopup={intl.formatMessage({ id: 'options.validation mode popup' })}
							style={{ zIndex: 2, position: 'absolute', top: 0, right: 0 }}
							toggled={this.state.errorModeToggle[preset]}
							onToggle={(toggled) => {
								this.setState({
									errorModeToggle: {
										...this.state.errorModeToggle,
										[preset]: toggled
									}
								});
							}}
						/>
					}

					<PresetComponent
						liveMode={this.state.viewModeToggle[preset]}
						errorMode={this.state.errorModeToggle[preset]}
						component={component}
						presetTitle={preset}
						preset={presetType[preset]}
						theme={theme}

						updatePresetOption={this.updatePresetOption(component)}

						selectedPreset={this.state.selectedPreset}
						selectedPresetOption={this.state.selectedPresetOption}

						selectPreset={this.selectPreset}
						selectPresetOption={this.selectPresetOption}
					/>
				</div>
			);
		});

		return (
			<div style={{ backgroundColor: theme.colors.tint }} id="preset-canvas">
				{presetElements}
			</div>
		);
	}
}

export { createTranslationFromKey };

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Presets));