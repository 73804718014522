import React, { Component } from 'react';
import AccordionContentComponent from 'components/accordion/AccordionContentComponent';
//import { Sidebar, Segment } from 'semantic-ui-react'
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class FAQPanel extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    toggelAccordion = (id) => {
        this.props.togglePanel(id);
    }
        
    render() {
        const { panel, openPanels } = this.props;
         //console.log(openPanels, 'hello open panels')
        const isOpen = openPanels[panel.id];

        return (
            <AccordionContentComponent
                handleOpen={() => this.toggelAccordion(panel.id)} 
                icon={panel.icon}
                active={isOpen} 
                title={panel.question}
            >
                {panel.component}   
            </AccordionContentComponent>
        );   
    }
}

export default FAQPanel;