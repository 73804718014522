import React from 'react';
import { ButtonGroup, Button, Icon, Popup } from 'svift-ui';

export default (props) => {
	const { first, firstIcon, firstPopup, second, secondIcon, secondPopup, toggled, onToggle, style } = props;

	return (
		<div className="preset-options" style={style}>
			<ButtonGroup basic size="mini">
				<Popup
					trigger={
						<Button
							onClick={() => onToggle(false)}
							className={toggled || 'active-button'}
						>
							<Icon name={firstIcon || 'sn-quill3'} />
							{first}
						</Button>
					}
					content={firstPopup}
					position="top center"
				/>
				<Popup
					trigger={
						<Button
							onClick={() => onToggle(true)}
							className={!toggled || 'active-button'}
						>
							<Icon name={secondIcon || 'sn-eye'} />
							{second}
						</Button>
					}
					content={secondPopup}
					position="top center"
				/>
			</ButtonGroup>
		</div>
	);
};