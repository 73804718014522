import constants from '../constants';

const getDefaultState = () => ({});

const defaultState = getDefaultState();

export default (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_USER_SETTINGS: {
			return {
				...state,
				...action.data
			};
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};
