import { useEffect } from 'react';
import addKeybinding from 'svift-library/client/utils/addKeybinding';

/*
    We need some kind of system to "suspend" keybindings when other things are listening to those keys, e.g. we don't want the delete keybinding to work
    while editing text in an input somewhere

    Ideas:
        1) When components mount, they can tell existing listeners to ignore certain keybindings
*/

export const useDeleteKeybinding = handler => {
    useEffect(() => {   
        if (!handler) return;

        return addKeybinding(['Backspace', 8, 'Delete', 46], /*false*/ ['ctrlKey'], e => console.log(e) || handler(e));
    }, [handler]);
};

export const useEnterKeybinding = handler => {
    useEffect(() => {
        if (!handler) return;

        return addKeybinding(['Enter', 13], /*false*/ ['ctrlKey'], handler);
    }, [handler]);
};

export const useLeftArrowKeybinding = handler => {
    useEffect(() => {
        if (!handler) return;

        return addKeybinding(['ArrowLeft', 37], /*false*/ ['ctrlKey'], handler);
    }, [handler]);
};

export const useRightArrowKeybinding = handler => {
    useEffect(() => {
        if (!handler) return;

        return addKeybinding(['ArrowRight', 39], /*false*/ ['ctrlKey'], handler);
    }, [handler]);
};

// export const useCopyKeybinding = handler => {
//     useEffect(() => {   
//         if (!handler) return;

//         return addKeybinding(['c', 67], ['ctrlKey'], handler);
//     }, [handler]);
// };

// export const usePasteKeybinding = handler => {
//     useEffect(() => {
//         if (!handler) return;

//         return addKeybinding(['v', 86], ['ctrlKey'], handler);
//     }, [handler]);
// };

export default ({ rendererProps }) => {
    // useEffect(() => {   
    //     return addKeybinding(['z', 90], ['ctrlKey'], rendererProps.undo);
    // }, []);

    // useEffect(() => {   
    //     return addKeybinding(['y', 89], ['ctrlKey'], rendererProps.redo);
    // }, []);

    useEffect(() => {   
        return addKeybinding(['Escape', 27], false/* ['ctrlKey']*/, () => {
            rendererProps.updateState(state => {
                if (state.editableNode) {
                    return {
                        ...state,
                        selectedNode: state.editableNode,
                        editableNode: null
                    };
                }

                return {
                    ...state,
                    selectedNode: null,
                    editableNode: null
                };
            }, 'skip');
        });
    }, []);

    return null;
};