const googleFontParser = variants => {
	// Italic is set inline, so we remove the option before returning the variants we care about
	return variants.filter(variant => variant.indexOf('italic') === -1).map(variant => {
		switch (variant) {
			case '100':
				return 'Thin';
			case '200':
				return 'Extra-Light';
			case '300':
				return 'Light';
			case '400':
			case 'regular':
			case 'Regular':
				return 'Regular';
			case '500':
				return 'Medium';
			case '600':
				return 'Semi-Bold';
			case '700':
				return 'Bold';
			case '800':
				return 'Extra-Bold';
			case '900':
				return 'Black';
			default:
				console.log(variant, 'unknown variant');
				return 'Unknown';
		}
	});
};

const toFontWeight = variant => {
	switch(variant) {
		case 'Thin':
			return '100';
		case 'Extra-Light':
			return '200';
		case 'Light':
			return '300';
		case 'Regular':
			return '400';
		case 'Medium':
			return '500';
		case 'Semi-Bold':
			return '600';
		case 'Bold':
			return '700';
		case 'Extra-Bold':
			return '800';
		case 'Black':
			return '900';
		default:
			return '400';
	}
}

export { googleFontParser, toFontWeight };