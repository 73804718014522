const pagesToMenuTree = (pages, targetParentPage = null, resolveDisplayText = null) => {
	const relevantPages = pages.filter(page => (page.parentPage && page.parentPage.toString()) === (targetParentPage && targetParentPage.toString()));

	const formatted = relevantPages.map(page => ({
		text: resolveDisplayText ? resolveDisplayText(page) : page.title,
		data: page,
		items: pagesToMenuTree(pages, page._id, resolveDisplayText)
	}));

	return formatted;
};

export { pagesToMenuTree };