import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Icon } from 'svift-ui';
import css from './app-footer.scss';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';

class AppFooter extends Component {
	render() {
		const { children, versionNumber } = this.props;
		return (
			<div id={css['app-footer']}>
				<div style={{ marginBottom: 16 }}>
					{children}
				</div>

				{/* company branding */}
				<Icon
					name="sn-logo"
					className="primary-color"
					style={{ fontSize: 40, margin: 0 }}
				/>

				<div style={{ marginTop: 4, marginBottom: 8, fontSize: 14, fontWeight: 400 }}>
					{/* copyright + company title */}
					<span style={{ fontWeight: 400 }}>
						&copy; <FormattedMessage id="general.company title" /> ApS | <span style={{ textTransform: 'capitalize' }}><FormattedMessage id="countries.denmark" /></span> (<FormattedDate value={new Date()} year="numeric" />)
					</span>
				</div>

				<Label circular basic className="primary" style={{ margin: 0 }}>
					<FormattedMessage id="general.version" /> {versionNumber}
				</Label>
			</div>
		);
	}
}

AppFooter.propTypes = {
	children: PropTypes.node,
	versionNumber: PropTypes.string,
};

AppFooter.defaultProps = {
	versionNumber: 'X.Y.Z',
};

export default injectIntl(AppFooter);
