import React from 'react';
import css from './menu.scss';

const MenuContainer = props => {
    const className = props.vertical ? `${css['container']} ${css['vertical']}` : css['container'];

    return (
        <div style={props.style} className={className}>
            {props.children}
        </div>
    )
};

export default MenuContainer;