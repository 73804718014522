import React from 'react';
import { Button, Icon } from 'svift-ui';
import { setRoute } from 'utils/routing';
import Label from 'components/option-library/label';

const BackButton = props => {
	return (
		<Label>
			<Button
				className="secondary"
				fluid size="tiny"
				onClick={() => setRoute(props.route)}
			>
				<Icon name="arrow left" style={{ marginRight: 8 }} />
				<Icon name={props.icon ? props.icon : "sn-bucket"} style={{ marginRight: 4 }} />
				{props.text}
			</Button>
		</Label>
	);
};

export default BackButton;
