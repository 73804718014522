import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { Grid, GridRow, GridColumn, IconGroup, Icon, Divider, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

class VideoPresets extends PureComponent {
	constructor() {
		super();

		this.getPresetOptions = (style) => [
			<span style={{ marginRight: 16, opacity: 0.6 }}>
				<Icon name="sn-grid-2" /><FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} />
			</span>,
			<Border
				key="border-style"
				value={this.props.preset.border}
				onChange={newBorder => this.props.updatePresetOption(this.props.presetTitle, 'border', newBorder)}
				colors={this.props.theme.colors}

				option="border-style"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<BorderRadius
				max={240}
				key="border-radius"
				value={this.props.preset.borderRadius}
				onChange={(key, newValue) => {
					if (key === 'generalBorderRadius') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', {});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', {
								generalBorderRadius: newValue
							});
						}

						return;
					}

					const newBorderRadius = {
						...this.props.preset.borderRadius,
						[key]: newValue
					};

					if (newValue === null) {
						delete newBorderRadius[key];
					}

					delete newBorderRadius.generalBorderRadius;

					this.props.updatePresetOption(this.props.presetTitle, 'borderRadius', newBorderRadius);
				}}
				colors={this.props.theme.colors}

				option="border-radius"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<Margin
				key="margin"
				value={this.props.preset.margin}
				onChange={(key, newValue) => {
					if (key === 'generalMargin') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'margin', {
								marginBottom: 1
							});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'margin', {
								generalMargin: newValue / 100
							});
						}

						return;
					}

					const newMargin = {
						...this.props.preset.margin,
						[key]: newValue / 100
					};

					if (newValue === null) {
						delete newMargin[key];
					}

					delete newMargin.generalMargin;

					if (typeof newMargin.marginBottom === 'undefined') {
						newMargin.marginBottom = 1;
					}

					this.props.updatePresetOption(this.props.presetTitle, 'margin', newMargin);
				}}

				option="Margin"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}

				optIn={{
					marginBottom: false,
					marginTop: true,
					marginLeft: true,
					marginRight: true,
					generalMargin: true
				}}
			/>,
			<Shadow
				key="shadow"
				value={this.props.preset.boxShadow || {}}
				colors={this.props.theme.colors}
				onChange={(newShadow) => {
					this.props.updatePresetOption(this.props.presetTitle, 'boxShadow', newShadow); //onChange('boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);

					e.stopPropagation();
				}}
			/>
		];

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.presetTitle === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const { intl } = this.props;
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const computedStyles = computePreset(this.props.preset, this.props.theme);

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="elements.section title"
					options={this.getPresetOptions(computedStyles)}
					open={selected}
					target={this.optionTooltipRef.current}
				/>

				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
					ref={this.optionTooltipRef}
				>
					<img
						height="300px"
						style={computedStyles}
						src="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-video.svg"
					/>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

VideoPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(VideoPresets));