import React from 'react';
import FormForm from 'components/forms/form';
import Modal from 'svift-library/client/modal';
import { injectIntl } from 'react-intl';
import SviftGA from 'utils/svift-ga';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import notification from 'components/hoc/notification';
import { connect } from 'react-redux';
import { formsSelector } from 'store/selectors/sites/forms';
import actions from 'store/actions/sites/forms';

const mapStateToProps = state => {
    const siteid = state.sites.params.siteid;

    return {
        siteid,
        formid: state.sites.forms.params.formid,
        forms: formsSelector(state, siteid)
    };
}

const mapDispatchToProps = (dispatch) => {
	return {
		createForm: (siteID, newForm) => dispatch(actions.createForm(siteID, newForm)),
		deleteForm: (siteID, formID) => dispatch(actions.deleteForm(siteID, formID)),
		updateForm: (siteID, formID, newValues) => dispatch(actions.updateForm(siteID, formID, newValues))
	};
};

class FormsContainer extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			loading: false
		};

		this.createNewForm = this.createNewForm.bind(this);
		this.updateForm = this.updateForm.bind(this);
		this.deleteForm = this.deleteForm.bind(this);
	}

    async createNewForm(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;

		try {
			await this.props.createForm(siteid, values);

			SviftGA.FORMS.CREATED();

			this.setState({
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
	
	async updateForm(values) {
		this.setState({
			loading: true
		});

		const siteid = this.props.siteid;
		const formid = this.props.formid;

		try {
			await this.props.updateForm(siteid, formid, values);

			SviftGA.FORMS.MODIFIED();

			this.setState({
				loading: false,
				modalOpen: false
			});

			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'forms.settings notification' }),
                className: 'positive',
                position: 'topCenter',
                icon: 'setting',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	async deleteForm() {
		this.setState({
			loading: true
		});

		try {
			await this.props.deleteForm(this.props.siteid, this.props.formid);

			SviftGA.FORMS.DELETED();

			this.setState({
				loading: false
            });
            
			this.props.createNotification({
                content: this.props.intl.formatMessage({ id: 'forms.delete notification' }),
                className: 'info',
                position: 'topCenter',
                icon: 'trash',
                deleteTime: 2000
            });
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}
    // if (!modalOpen) return null;

    render() {
        const currentForm = this.props.forms.find(form => form._id === this.props.formid);

        return (
            <React.Fragment>
                {this.props.renderButton(open => this.setState({ modalOpen: open }))}
    
                {this.state.modalOpen &&
                    <Modal
					title={this.props.intl.formatMessage({ id: `forms.${(this.state.modalOpen === 'create') ? 'new form' : 'form settings'}`})}
						// width={1400}
						style={{ maxWidth: 640 }}
                        toggleModal={open => this.setState({ modalOpen: open })}
                        nodeAttributes={{
                            'data-ignore-click': true
                        }}
                    >
						{this.state.modalOpen === 'update' && currentForm &&
							<FormForm
								loading={this.state.loading}
								update
								enableReinitialize
								initialValues={{
                                    title: currentForm.title,
                                    contacts: currentForm.contacts,
                                    afterSubmitMessage: currentForm.afterSubmitMessage
                                }}
								onSubmit={this.updateForm}
								onClose={() => this.setState({ modalOpen: false })}
								delete={this.deleteForm}
							/>
						}

						{this.state.modalOpen === 'create' &&
							<FormForm
								loading={this.state.loading}
								onSubmit={this.createNewForm}
								onClose={() => this.setState({ modalOpen: false })}
							/>
						}
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(FormsContainer)));

/*
    { this.state.formModalOpen && 
        <FormModal 
            loading={this.state.loading} 
            onClose={this.toggleFormModal} 
            onSubmit={this.createNewForm} 
        /> 
    }

    { this.state.formUpdateModalOpen &&
        <FormModal
            loading={this.state.loading}
            update
            initialValues={{
                title: currentForm.title,
                contacts: currentForm.contacts,
                afterSubmitMessage: currentForm.afterSubmitMessage
            }}
            onClose={() => this.setState({ formUpdateModalOpen: false })}
            onDelete={this.deleteForm}
            onSubmit={this.updateForm}
        />
    }
*/
