import React, { memo } from 'react';
import BetterResize from './better-resize/BetterResize';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

export default memo(({ height = 0, setHeight, setHeightBegin, setHeightDone, element }) => {
    return (
        <BetterResize
            icon={element && resolveElementIcon(element)}
            element={element /*&& resolveElementName(element)*/}
            bottom
            height={height}
            onResizeBegin={setHeightBegin}
            onResize={({ direction, nextSize }) => {
                if (nextSize < 10) return; // Minimum size = 10 pixels

                setHeight(nextSize);
            }}
            onResizeDone={setHeightDone}
        />
    );
});

