import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { Grid, GridRow, GridColumn, IconGroup, Icon, Popup } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

class ColumnPresets extends PureComponent {
	constructor() {
		super();

		/*
				"padding": {
					"paddingBottom": 0.5
				},
				"margin": {},
				"backgroundColor": {
					"key": "tint",
					"opacity": 0
				},
				"boxShadow": {}
		*/

		this.getPresetOptions = style => [
			<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-marquee" /><FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} /></span>,
			<Padding
				key="padding"
				value={this.props.preset.padding}
				preset={this.props.preset}
				theme={this.props.theme}
				onChange={(key, newValue) => {
					if (key === 'generalPadding') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								paddingBottom: 1
							});
						} else {
							this.props.updatePresetOption(this.props.presetTitle, 'padding', {
								generalPadding: newValue / 100
							});
						}

						return;
					}

					const newPadding = {
						...this.props.preset.padding,
						[key]: newValue / 100
					};

					if (newValue === null) {
						delete newPadding[key];
					}

					delete newPadding.generalPadding;

					if (typeof newPadding.paddingBottom === 'undefined') {
						newPadding.paddingBottom = 1;
					}

					this.props.updatePresetOption(this.props.presetTitle, 'padding', newPadding);
				}}

				option="Padding"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}

				optIn={{
					paddingBottom: false,
					paddingTop: true,
					paddingLeft: true,
					paddingRight: true,
					generalPadding: true
				}}
			/>,
			<ColorPicker
				title={this.props.intl.formatMessage({ id: 'options.background color title' })}
				key="color-picker-background"
				icons={['sn-paint-format']}
				colors={this.props.theme.colors}
				onChange={(newColor) => this.props.updatePresetOption(this.props.presetTitle, 'backgroundColor', newColor)}
				value={this.props.preset.backgroundColor}

				option="color-picker-background"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);
					e.stopPropagation();
				}}
			/>,
			<Margin
				key="margin"
				value={this.props.preset.margin}
				preset={this.props.preset}
				theme={this.props.theme}
				onChange={(key, newValue) => {
					const value = this.props.preset.margin;

					if (key === 'generalMargin') {
						if (newValue === null) {
							this.props.updatePresetOption(this.props.presetTitle, 'margin', {
								marginBottom: 1
							});

							return;
						}

						this.props.updatePresetOption(this.props.presetTitle, 'margin', {
							generalMargin: newValue / 100
						});

						// this.onChange('margin', {
						// 	generalMargin: newValue / 100
						// });

						return;
					}

					const newMargin = {
						...value,
						[key]: newValue / 100
					};

					if (newValue === null) {
						delete newMargin[key];
					}

					delete newMargin.generalMargin;

					if (typeof newMargin.marginBottom === 'undefined') newMargin.marginBottom = 1;

					this.props.updatePresetOption(this.props.presetTitle, 'margin', newMargin);
				}}

				option="Margin"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);

					e.stopPropagation();
				}}

				optIn={{
					marginBottom: false,
					marginTop: true,
					marginLeft: true,
					marginRight: true,
					generalMargin: true
				}}
			/>,
			<Shadow
				key="shadow"
				value={this.props.preset.boxShadow}
				colors={this.props.theme.colors}
				onChange={(newShadow) => {
					this.props.updatePresetOption(this.props.presetTitle, 'boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={this.props.selectedPresetOption}
				onToggle={(select, e) => {
					this.props.selectPresetOption(select);

					e.stopPropagation();
				}}
			/>
		];

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.presetTitle === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.presetTitle === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }


	render() {
		const { intl } = this.props;
		const selected = this.props.presetTitle === this.props.selectedPreset;

		const computedStyles = computePreset(this.props.preset, this.props.theme);

		return (
			<div style={{ paddingBottom: 16, marginBottom: 24, borderBottom: '1px dashed lightgrey' }}>
				<OptionPanel
					icon="sn-bucket"
					title="elements.row title"
					target={this.optionTooltipRef.current}//.getTarget()}
					open={selected}
					options={this.getPresetOptions(computedStyles)}
				/>
				
				<PresetContainer
					selected={selected}
					onClick={() => setTimeout(() => this.props.selectPreset(this.props.presetTitle), 0)}
					ref={this.optionTooltipRef}
				>
					<Popup
						trigger={
							<IconGroup style={{ position: 'absolute', top: 32, left: 12, zIndex: 2 }}>
								<Icon name="sn-marquee" className="primary" style={{ fontSize: 40 }} />
								<Icon corner name="sn-info" className="primary" style={{ fontSize: 24, bottom: -6, right: -6, borderRadius: '50%', background: 'white' }} />
							</IconGroup>
						}
						content={intl.formatMessage({ id: 'elements.column dummy content' })}
						position="bottom left"
					/>

					<Grid padded centered>
						<GridRow>
							{[...Array(3)].map((x, i) => (
								<GridColumn computer={4} className="sub-target" style={Object.assign(computedStyles, { border: '1px dashed lightgrey' })}>
									{[...Array(3)].map((x, i) => (
										<div style={{ paddingBottom: 24, textAlign: 'center', border: '1px dashed grey' }}>
											<Popup
												trigger={
													<IconGroup style={{ opacity: 0.1 }}>
														<Icon name="sn-marquee-plus" style={{ fontSize: 48, margin: 0 }} />
														<Icon corner name="sn-info" style={{ fontSize: 20, bottom: -6, right: -6, borderRadius: '50%', background: 'white' }} />
													</IconGroup>
												}
												content={intl.formatMessage({ id: 'elements.column dummy element description' })}
												position="bottom center"
											/>
										</div>
									))}
								</GridColumn>
							))}
						</GridRow>
					</Grid>
				</PresetContainer>
			</div>
		);
	}
}

ColumnPresets.propTypes = {
	component: PropTypes.string,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	style: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string
};

export default injectIntl(onClickOutside(ColumnPresets));