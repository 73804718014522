import createApi from 'api';
import constants from '../constants';

import { logout } from 'utils/authenticationHandler';
import { setRoute } from 'utils/routing';

const actions = {
    // ASYNC
    login: (data) => async (dispatch) => {
        const api = createApi();

        const user = await api.login(data);

        dispatch(actions.setUser(user));

        setRoute('/dashboard');
    },

    deleteUserAccount: (user) => async () => {
        const api = createApi();

        try {
            await api.deleteUser(user);

            logout();
        } catch (e) {
            console.log('error ', e);
        }
    },

    canDeleteUserAccount: () => async (dispatch) => {
        const api = createApi();

        try {
            const result = await api.canDeleteUser();

            dispatch(actions.ableToDeleteUser(result));
        } catch (e) {
            console.log('error ', e);
        }
    },

    updateUserSettings: (user) => async (dispatch) => {
        const api = createApi();

        await api.updateUserSettings(user);

        dispatch(actions.setUser(user));
    },

    // SYNC
    setUser: (data) => ({
        type: constants.SET_USER_SETTINGS,
        data,
    }),

    ableToDeleteUser: (data) => ({
        type: constants.ABLE_TO_DELETE_USER_ACCOUNT,
        data,
    }),
};

export default actions;
