exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".file-preview__preview-container--GO68H{display:flex;flex-direction:column}.file-preview__preview-container--GO68H .file-preview__file-preview--3h5qx{width:100%;margin-bottom:4px}.file-preview__preview-container--GO68H .file-preview__file-title--1DcJn{font-size:12px;line-height:1.2;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-bottom:4px}.file-preview__preview-container--GO68H .file-preview__file-type--1C92u{margin-bottom:8px;text-align:left}.file-preview__preview-container-slim--2jCLY{display:flex;flex-direction:row;align-items:center;text-align:left;padding:0px;border:1px solid #d8d4d0;background:#f7f3f0;border-radius:4px;margin-bottom:2px}.file-preview__preview-container-slim--2jCLY .file-preview__file-preview--3h5qx{width:20%;flex-shrink:0;margin-bottom:0;margin-right:4px}.file-preview__preview-container-slim--2jCLY .file-preview__file-title--1DcJn{width:80%;font-size:12px;line-height:1.2;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-bottom:4px}.file-preview__preview-container-slim--2jCLY .file-preview__file-type--1C92u{margin-bottom:8px;text-align:left}.file-preview__preview-container-slim--2jCLY.file-preview__draggable--3Htl8:hover{background:red}\n", ""]);

// exports
exports.locals = {
	"preview-container": "file-preview__preview-container--GO68H",
	"file-preview": "file-preview__file-preview--3h5qx",
	"file-title": "file-preview__file-title--1DcJn",
	"file-type": "file-preview__file-type--1C92u",
	"preview-container-slim": "file-preview__preview-container-slim--2jCLY",
	"draggable": "file-preview__draggable--3Htl8"
};