import constants from 'store/constants';

const getDefaultState = () => null;

const reducer = (state = getDefaultState(), action) => {
    switch(action.type) {
        case constants.SET_EDITOR_CONTEXT: {
            return action.data;
        }

        default:
            return state;
    }
}

export default reducer;