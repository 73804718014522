import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OptionPanel from 'components/option-panel';
// import Slider from 'components/option-panel/options/Slider';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Margin from 'components/option-panel/options/advanced/options/Margin';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import Border from 'components/option-panel/options/advanced/options/border/Border';
import BorderRadius from 'components/option-panel/options/advanced/options/BorderRadius';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import Dropdown from 'components/option-panel/options/Dropdown';
// import MenuItemOption from 'components/option-panel/options/MenuItem';
import { Grid, GridColumn, Menu, Icon, Container, MenuItem, Popup } from 'svift-ui';
import PresetContainer from '../PresetContainer';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { computePreset } from 'svift-library/client/renderer/utils/computePreset';
import { googleFontParser } from 'utils/fonts/googleFontParser';
import { tooltip } from 'components/tooltip/Tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';

const getValueOrDefault = (buttonPreset, preset, key) => {
	if (typeof buttonPreset[key] === 'undefined') {
		return preset.default[key];
	}

	return buttonPreset[key];
};

class MenuItemPreset extends PureComponent {
	constructor() {
		super();

		this.onChange = this.onChange.bind(this);

		this.getPresetOptions = (props) => {
			const { intl } = this.props;
			const buttonPreset = this.props.preset[this.props.buttonState];
			const extendsDefault = this.props.buttonState !== 'default';

			// const { backgroundColor, color, font, border } = buttonPreset;

			const backgroundColor = getValueOrDefault(buttonPreset, this.props.preset, 'backgroundColor');
			const boxShadow = getValueOrDefault(buttonPreset, this.props.preset, 'boxShadow');
			const color = getValueOrDefault(buttonPreset, this.props.preset, 'color');
			const font = getValueOrDefault(buttonPreset, this.props.preset, 'font');
			const borderRadius = getValueOrDefault(buttonPreset, this.props.preset, 'borderRadius');
			const border = getValueOrDefault(buttonPreset, this.props.preset, 'border');
			const margin = getValueOrDefault(buttonPreset, this.props.preset, 'margin');
			const padding = getValueOrDefault(buttonPreset, this.props.preset, 'padding');
			const fontWeight = props.preset.fontWeight; // update later

			const options = [
				<Popup
					trigger={
						<span style={{ marginRight: 16, opacity: 0.6 }}>
							<Icon name="sn-direction" /><FormattedMessage id={createTranslationFromKey(props.presetTitle)} />
						</span>
					}
					position="top center"
					content={intl.formatMessage({ id: 'options.preset menu item label description' })}
				/>,
				<Menu key="fontz" size="mini" className="primary" compact style={{ marginRight: 8 }}>
					<Dropdown
						unwrapped
						key="font-type"
						icon="font"
						options={Object.keys(props.theme.fonts).map(fontKey => {
							return {
								text: `${intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
								value: fontKey
							};
						})}
						onChange={(newFont) => this.onChange('font', newFont.value)}
						value={font}
						hoverContent={`${intl.formatMessage({ id: `options.font ${font} title` })} (${props.theme.fonts[font].family})`}
						option="color-picker"
						selectedOption={this.props.selectedPresetOption}
						onToggle={(select, e) => {
							this.props.selectPresetOption(select);

							if (e) {
								e.stopPropagation();
							}
						}}
					/>
				</Menu>,
				<ColorPicker
					title={intl.formatMessage({ id: 'options.font color title' })}
					// {intl.formatMessage({ id: 'options.font color title' })}
					key="color-picker-color"
					icons={['sn-typography']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('color', newColor)}
					value={color}

					option="color-picker"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<ColorPicker
					title={intl.formatMessage({ id: 'options.background color title' })}
					key="color-picker-background"
					icons={['sn-paint-format']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('backgroundColor', newColor)}
					value={backgroundColor}

					option="color-picker-background"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Border
					key="border-style"
					value={border}
					onChange={newBorder => this.onChange('border', newBorder)}
					colors={this.props.theme.colors}

					option="border-style"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<BorderRadius
					key="border-radius"
					value={borderRadius}
					onChange={(key, newValue) => {
						if (key === 'generalBorderRadius') {
							if (newValue === null) {
								this.onChange('borderRadius', {});
							} else {
								this.onChange('borderRadius', {
									generalBorderRadius: newValue
								});
							}

							return;
						}

						const newBorderRadius = {
							...buttonPreset.borderRadius,
							[key]: newValue
						};

						if (newValue === null) {
							delete newBorderRadius[key];
						}

						delete newBorderRadius.generalBorderRadius;

						this.onChange('borderRadius', newBorderRadius);
					}}
					colors={this.props.theme.colors}

					option="border-radius"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={margin}
					preset={this.props.preset}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {});
							} else {
								this.onChange('margin', {
									generalMargin: newValue / 100
								});
							}

							return;
						}

						const newMargin = {
							...buttonPreset.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>,
				<Shadow
					key="shadow"
					value={boxShadow}
					colors={this.props.theme.colors}
					onChange={(newShadow) => {
						this.onChange('boxShadow', newShadow);
					}}

					option="Shadow"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>
			];

			if (extendsDefault) {
				options.push(
					<Popup
						trigger={
							<Icon
								style={{ fontSize: 14, marginLeft: 16}}
								name="sn-cross"
								circular
								key="clear"
								link
								className="negative"
								disabled={!buttonPreset.margin}/*(() => {
									const marginIsDefault = !buttonPreset.margin;
									return Object.keys(buttonPreset).length === 1 && marginIsDefault;
								})()}*/
								onClick={() => {
									this.props.updatePresetOption(this.props.presetTitle, this.props.buttonState, {});
								}}
							/>
						}
						content={intl.formatMessage({ id: 'options.clear preset popup' })}
						position="top right"
					/>
				);
			}

			return options;
		};

		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	// Because of its internal data structure (nested a layer deeper to accomodate 4 button states), buttons have to take some extra steps to align with update logic
	onChange(property, newValue) {
		const buttonState = this.props.buttonState;

		const value = {
			...this.props.preset[buttonState],
			[property]: newValue
		};

		if (newValue === null) {
			delete value[property];
		}

		this.props.updatePresetOption(this.props.presetTitle, buttonState, value);
	}

	getIdentifier() {
		return `${this.props.buttonState}${this.props.presetTitle}`; // Need a specific identifier because the selectable buttons are nested a layer deeper
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.getIdentifier() === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.getIdentifier() === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const selected = this.getIdentifier() === this.props.selectedPreset;

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="options.button"
					target={this.optionTooltipRef.current}
					open={selected}
					options={this.getPresetOptions(this.props)}
				/>

				<PresetContainer
					selected={selected}
					disabled={this.props.liveMode}
					onClick={() => setTimeout(() => this.props.selectPreset(this.getIdentifier()), 0)}
					ref={this.optionTooltipRef}
				>
					<Container fluid textAlign="center">
						<Menu style={{ backgroundColor: 'transparent' }}>
							<MenuItem
								style={this.props.style}
							>
								<FormattedMessage id="elements.menu item dummy title" />
							</MenuItem>
						</Menu>
					</Container>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

class MenuContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.getPresetOptions = () => {
			const menuContainerPreset = this.props.preset.container;

			const options = [
				<Popup
					trigger={
						<span style={{ marginRight: 16, opacity: 0.6 }}><Icon name="sn-direction" /><FormattedMessage id={createTranslationFromKey(props.presetTitle)} /></span>
					}
					position="top center"
					content={this.props.intl.formatMessage({ id: 'options.preset menu label description' })}
				/>,
				<Padding
					key="padding"
					value={menuContainerPreset.padding || {}}
					onChange={(key, newValue) => {
						if (key === 'generalPadding') {
							if (newValue === null) {
								this.onChange('padding', {});
							} else {
								this.onChange('padding', {
									generalPadding: newValue / 100
								});
							}

							return;
						}

						const newPadding = {
							...menuContainerPreset.padding,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newPadding[key];
						}

						delete newPadding.generalPadding;

						this.onChange('padding', newPadding);
					}}

					option="Padding"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>,
				<ColorPicker
					title={this.props.intl.formatMessage({ id: 'options.background color title' })}
					key="color-picker-background"
					icons={['sn-paint-format']}
					colors={this.props.theme.colors}
					onChange={(newColor) => this.onChange('backgroundColor', newColor)}
					value={menuContainerPreset.backgroundColor}

					option="color-picker-background"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<BorderRadius
					key="border-radius"
					value={menuContainerPreset.borderRadius || {}}
					onChange={(key, newValue) => {
						if (key === 'generalBorderRadius') {
							if (newValue === null) {
								this.onChange('borderRadius', {});
							} else {
								this.onChange('borderRadius', {
									generalBorderRadius: newValue
								});
							}

							return;
						}

						const newBorderRadius = {
							...menuContainerPreset.borderRadius,
							[key]: newValue
						};

						if (newValue === null) {
							delete newBorderRadius[key];
						}

						delete newBorderRadius.generalBorderRadius;

						this.onChange('borderRadius', newBorderRadius);
					}}
					colors={this.props.theme.colors}

					option="border-radius"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);
						e.stopPropagation();
					}}
				/>,
				<Margin
					key="margin"
					value={menuContainerPreset.margin || {}}
					preset={menuContainerPreset}
					theme={this.props.theme}
					onChange={(key, newValue) => {
						if (key === 'generalMargin') {
							if (newValue === null) {
								this.onChange('margin', {});
							} else {
								this.onChange('margin', {
									generalMargin: newValue / 100
								});
							}

							return;
						}

						const newMargin = {
							...menuContainerPreset.margin,
							[key]: newValue / 100
						};

						if (newValue === null) {
							delete newMargin[key];
						}

						delete newMargin.generalMargin;

						this.onChange('margin', newMargin);
					}}

					option="Margin"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>,
				<Shadow
					key="shadow"
					value={menuContainerPreset.boxShadow || {}}
					colors={this.props.theme.colors}
					onChange={(newShadow) => {
						this.onChange('boxShadow', newShadow);
					}}

					option="Shadow"
					selectedOption={this.props.selectedPresetOption}
					onToggle={(select, e) => {
						this.props.selectPresetOption(select);

						e.stopPropagation();
					}}
				/>
			];

			return options;
		};

		this.identifier = `${props.presetTitle}MenuContainer`;
		this.optionTooltipRef = React.createRef(); //tooltip.call(this);
	}

	onChange(property, newValue) {
		const value = {
			...this.props.preset.container,
			[property]: newValue
		};

		if (newValue === null) {
			delete value[property];
		}

		this.props.updatePresetOption(this.props.presetTitle, 'container', value);
	}

	// onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.identifier === this.props.selectedPreset) {
	// 		this.props.selectPreset(null);
	// 	}
	// }

	onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.identifier === this.props.selectedPreset) {
			// debugger;
			this.props.selectPreset(null);
		}
    }

	render() {
		const selected = this.identifier === this.props.selectedPreset;

		return (
			<React.Fragment>
				<OptionPanel
					icon="sn-bucket"
					title="options.menu"
					open={selected}
					target={this.optionTooltipRef.current} //.getTarget()}
					options={this.getPresetOptions(this.props)}
				/>

				<PresetContainer
					selected={selected}
					disabled={this.props.liveMode}
					onClick={() => setTimeout(() => this.props.selectPreset(this.identifier), 0)}
					ref={this.optionTooltipRef} //.setTarget}
				>
					<Container fluid textAlign="center">
						<Menu size="large" style={this.props.containerStyle}>
							<MenuItem style={this.props.menuItemStyles}>
								<FormattedMessage id="elements.menu item dummy title 1" />
							</MenuItem>
							<MenuItem style={this.props.menuItemStyles}>
								<FormattedMessage id="elements.menu item dummy title 2" />
							</MenuItem>
							<MenuItem style={this.props.menuItemStyles}>
								<FormattedMessage id="elements.menu item dummy title 3" />
							</MenuItem>
							<MenuItem style={this.props.menuItemStyles}>
								<FormattedMessage id="elements.menu item dummy title 4" />
							</MenuItem>
							<MenuItem style={this.props.menuItemStyles}>
								<FormattedMessage id="elements.menu item dummy title 5" />
							</MenuItem>
						</Menu>
					</Container>
				</PresetContainer>
			</React.Fragment>
		);
	}
}

const EnhancedMenuItem = injectIntl(onClickOutside(MenuItemPreset));
const EnhancedMenuContainer = injectIntl(onClickOutside(MenuContainer));

class MenuPresets extends PureComponent {
	render() {
		const { intl } = this.props;
		const computedStyles = computePreset(this.props.preset, this.props.theme);

		if (this.props.liveMode) {
			return (
				<div style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 44, marginBottom: 16 }}>
					<Menu size="large" style={computedStyles.container}>
						<MenuItem style={computedStyles}>
							<FormattedMessage id="elements.menu item dummy title 1" />
						</MenuItem>
						<MenuItem style={computedStyles}>
							<FormattedMessage id="elements.menu item dummy title 2" />
						</MenuItem>
						<MenuItem style={computedStyles}>
							<FormattedMessage id="elements.menu item dummy title 3" />
						</MenuItem>
						<MenuItem style={computedStyles}>
							<FormattedMessage id="elements.menu item dummy title 4" />
						</MenuItem>
						<MenuItem style={computedStyles}>
							<FormattedMessage id="elements.menu item dummy title 5" />
						</MenuItem>
					</Menu>
				</div>
			);
		}

		return (
			<Grid columns={4} centered style={{ borderBottom: '1px dashed lightgrey', paddingBottom: 8, marginTop: 0, marginBottom: 16 }}>
				<GridColumn width={16} textAlign="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
					<p className="preset-title"><FormattedMessage id="elements.menu container title" /><InfoIcon description={intl.formatMessage({ id: 'elements.menu container description' })} /></p>

					<EnhancedMenuContainer
						liveMode={this.props.liveMode}
						theme={this.props.theme}
						selectPresetOption={this.props.selectPresetOption}
						updatePresetOption={this.props.updatePresetOption}
						selectedPresetOption={this.props.selectedPresetOption}
						preset={this.props.preset}
						presetTitle={this.props.presetTitle}
						selectPreset={this.props.selectPreset}
						selectedPreset={this.props.selectedPreset}
						//buttonState={presetId}
						containerStyle={computedStyles.container}
						menuItemStyles={computedStyles}
					/>
				</GridColumn>

				{['Default', 'Hover', 'Active', 'Disabled'].map(buttonState => {
					switch (buttonState) {
						case 'Default':
							return {
								presetId: 'default',
								presetTitle: 'elements.menu item state default',
								presetDescription: 'elements.menu item state default description',
								buttonState
							};
						case 'Hover':
							return {
								presetId: 'hover',
								presetTitle: 'elements.menu item state hover',
								presetDescription: 'elements.menu item state hover description',
								buttonState
							};
						case 'Active':
							return {
								presetId: 'active',
								presetTitle: 'elements.menu item state active',
								presetDescription: 'elements.menu item state active description',
								buttonState
							};
						case 'Disabled':
							return {
								presetId: 'disabled',
								presetTitle: 'elements.menu item state disabled',
								presetDescription: 'elements.menu item state disabled description',
								buttonState
							};
					}
				}).map(({ buttonState, presetId, presetTitle, presetDescription }) => {
					let styles = {
						...computedStyles,
					};

					delete styles[':hover'];
					delete styles[':active'];
					delete styles[':disabled'];

					styles = {
						...styles,
						...computedStyles[':' + presetId]
					};

					return (
						<GridColumn key={buttonState} textAlign="left" style={{ paddingTop: 0, paddingBottom: 0 }}>
							<p className="preset-title"><FormattedMessage id={presetTitle} /><InfoIcon description={intl.formatMessage({ id: presetDescription })} /></p>

							<EnhancedMenuItem
								liveMode={this.props.liveMode}
								theme={this.props.theme}
								selectPresetOption={this.props.selectPresetOption}
								updatePresetOption={this.props.updatePresetOption}
								selectedPresetOption={this.props.selectedPresetOption}
								preset={this.props.preset}
								presetTitle={this.props.presetTitle}
								selectPreset={this.props.selectPreset}
								selectedPreset={this.props.selectedPreset}
								buttonState={presetId}
								style={styles}
							/>
						</GridColumn>
					);
				})}
			</Grid>
		);
	}
}

MenuPresets.propTypes = {
	component: PropTypes.string,
	liveMode: PropTypes.bool,
	presetTitle: PropTypes.string,
	preset: PropTypes.object,
	theme: PropTypes.object,
	selectPreset: PropTypes.func,
	selectedPreset: PropTypes.string,
	selectPresetOption: PropTypes.func,
	selectedPresetOption: PropTypes.string,
	updatePresetOption: PropTypes.func
};

export default injectIntl(MenuPresets);
