/*
    Note: FilePicker is likely admin specific, figure out where to put it later...
*/

import React, { memo, useState } from 'react';
import Modal from 'svift-library/client/modal';
// SelectedFilesPreview = preview in side nav/editor options
// import SelectedFilesPreview from './SelectedFilesPreview';
// import SelectedFilesPreview from './SelectedFilesPreview';
// SelectedFiles = right column inside modal with selected file summary
import SelectedFiles from './selected-files';
import FilePicker from './file-picker';
import css from './file.scss';
import { injectIntl } from 'react-intl';

/*
	FileTypes:
		Youtube: {
			provider: 'youtube',
			id: 'xyz',
			video_id: 'xyz
		}

		Files (image/video): 'file id'
*/

// const createFileDictionary = selected => {
// 	if (typeof selected === 'array') {
// 		const dict = selected.reduce((acc, file) => {
// 			acc[file.id] = file;
	
// 			return acc;
// 		}, {});

// 		return dict;
// 	}

// 	if (selected) {
// 		return {
// 			[selected.id]: selected
// 		};
// 	}

// 	return {};
// };

const getValueAsArray = props => {
	/*
		We want to handle selected files in the same way whether or not multiple files can be selected

		For that reason we convert existing values into an array (if it isn't aren't already an array)
	*/
	if (Array.isArray(props.value)) return props.value;

	if (props.value) return [props.value];

	return [];
}

export default injectIntl(memo(props => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState(() => getValueAsArray(props));
	const [lastValue, setLastValue] = useState(props.value);
	const [previewFiles, setPreviewFiles] = useState(selectedFiles);

	/*
		In the file preview we don't want to show any selections that haven't been "committed"

		Every time the "props.value" changes it also means new values have been committed, so we recompute which files should be shown 
		in the preview
	*/
	if (lastValue !== props.value) {
		setLastValue(props.value);

		setPreviewFiles(getValueAsArray(props));
	}

	const onChange = () => {
		setModalOpen(false);

		props.onChange(props.multiple ? selectedFiles : selectedFiles[0]);
	};

    return (
        <div> 
			<SelectedFiles
				preview
				multiple={props.multiple}
				selectedFiles={previewFiles}
                setModalOpen={setModalOpen}                
            />

            {modalOpen &&
                <Modal
					title={props.intl.formatMessage({ id: 'options.select file modal title' })}
					width={1400}
                    toggleModal={setModalOpen}
                    nodeAttributes={{
                        'data-ignore-click': true
                    }}
                >
                    <div className={css['content-wrapper']}>
						<FilePicker 
							multiple={props.multiple}
							filter={props.filter}
							tabs={props.tabs}
							selectedFiles={selectedFiles}
							setSelectedFiles={setSelectedFiles}
						/>

						<div className={css['selected-files']}>
							<SelectedFiles 
								multiple={props.multiple}
								onChange={onChange}
								setSelectedFiles={setSelectedFiles}
								selectedFiles={selectedFiles}
							/>
						</div>
                    </div>
                </Modal>
            }
        </div>
    );
}));

/*
	<FilePicker
		filter={['image', 'video', 'youtube']}
		onChange={() => props.value}
		value={props.value}
	/>
*/
/*
import React, { PureComponent } from 'react';
import {
	Button,
	Icon,
	Popup,
	ModalContent,
	ModalActions,
	Menu,
	MenuItem,
	MenuMenu,
	Image,
	Input,
	Message
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ModalWrapper from 'components/modal';
import { getFileTypeByExt, getIconByExtension } from 'utils/files/format';
import { connect } from 'react-redux';
import { filesSelector, filesInActiveFolder } from 'store/selectors/sites/files';
import FilesTab from './tabs/Files';
import YouTubeTab from './tabs/YouTube';
import key from 'utils/key';

const mapStateToProps = (state, ownProps) => {	
	return {
		siteName: state.sites.sites[ownProps.siteid] && state.sites.sites[ownProps.siteid].name,
		files: filesInActiveFolder(state),
		// files: state.sites.files.files
	}
};

class FileModal extends PureComponent {
	constructor(props) {
		super(props);

		const value = props.value;
		const provider = key('value.provider')(props);
	
		let initialTab = props.tabs[0];
		// let initialSelected = null;

		const selected = {
			youtube: null,
			files: null
		};

		if (provider === 'youtube') {
			initialTab = 'youtube';
			selected.youtube = value;
		}

		this.state = {
			loading: true,
			selected,
			selectedTab: initialTab,
			changingSelected: false,
			searchTerm: null
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) {
			this.setState({
				changingSelected: false
			});
		}
	}

	select = (tab, value, provider) => {
		if (provider) value.provider = provider;

		this.setState({
			selected: {
				...this.state.selected,
				[tab]: value
			}
		});
	}

	onSearchTermChange = e => {
		this.setState({
			searchTerm: e.target.value
		});	
	};
	
	clearSearchTerm = () => {
		this.setState({ 
			searchTerm: '' 
		});
	}

	getEnabledTabs = () => {
		const allTabs = ['files', 'youtube', 'onsplash', 'vimeo', 'etc'];
		const enabledTabs = allTabs.filter(tab => this.props.tabs.includes(tab));
		
		return enabledTabs;
	}

	onSave = () => {
		this.props.onChange(this.state.selected[this.state.selectedTab]);
	}

	renderers = {
		selectedOverlay: () => {
			const provider = key('value.provider')(this.props);
			
			let message;

			if (provider === 'youtube') {
				return (
					<div>
						<Message className="info">
							<Icon name="sn-eye" style={{ marginRight: 8 }} />
							<span><FormattedMessage id="elements.video inserted message description" /></span>
						</Message>
						<div 
							style={{
								position: 'relative',
								width: '100%',
								paddingTop: '56.25%',
								marginBottom: 8
							}}
						>
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0
								}}
							>
								<iframe 
									width="100%"
									height="100%" 
									src={`https://www.youtube.com/embed/${this.props.value.video_id}`}
									frameBorder="0" 
									allowFullScreen
								/>
							</div>
						</div>
					</div>
				);
			} else {
				const file = this.props.files[this.props.value];

				// const fileType = getFileTypeByExt(this.props.value.split('.').pop());

				const src = file.url; //`https://storage.googleapis.com/svift-${this.props.siteid}/${this.props.value}`;

				if (file.meta.mimeType.startsWith('video')) {
					return (
						<video 
							width="100%"
							// height={height}
							controls
						>
							<source src={src} />
							Your browser does not support the video tag.
						</video>
					);
				}

				if (file.meta.mimeType.startsWith('image')) {
					return (
						<Image
							src={src}
						/> 					
					);
				}
			}

			return null;
		},
		tabMenu: () => {
			const { intl } = this.props;
			const tabs = this.getEnabledTabs();
			const selectedTab = this.state.selectedTab;
			const hasSearch = ['files'].includes(selectedTab);
			const createSelectTab = tab => () => {
				this.setState({
					selectedTab: tab
				});				
				// if (!tab !== this.state.selectedTab) {
				// 	this.setState({
				// 		selectedTab: tab,
				// 		selected: null
				// 	});
				// } else {
				// 	this.setState({
				// 		selectedTab: tab
				// 	});
				// }
			};

			const clearSearchTerm = this.state.searchTerm 
				? 
					<Popup
						trigger={
							<Button primary icon onClick={this.clearSearchTerm} >
								<Icon name="sn-cross2" />
							</Button>
						}
						content={intl.formatMessage({ id: 'general.clear search button popup' })}
						position="top right"
					/> 
				: 
					null; 

			return (
				<React.Fragment>
					<Menu size="small">
						{tabs.map(tab => {
							const isSelected = selectedTab === tab;

							switch (tab) {
								case 'files':
									return (
										<MenuItem key={tab} onClick={createSelectTab(tab)}>
											<Popup
												trigger={
													<Button icon="sn-folder6" className={isSelected ? 'primary active-button' : ' shade basic'} />
												}
												content={intl.formatMessage({ id: 'files.app title' })}
												position="top center"
											/>
										</MenuItem>
									);
								case 'youtube':
									return (
										<MenuItem key={tab} onClick={createSelectTab(tab)}>
											<Popup
												trigger={
													<Button icon="sn-youtube" className={isSelected ? 'primary active-button' : 'shade bbasic'} />
												}
												content={intl.formatMessage({ id: 'general.youtube title' })}
												position="top center"
											/>
										</MenuItem>									
									);
								default:
									return null;
							}
						})}

						{hasSearch &&
							<MenuMenu position="right">
								<MenuItem>
									<Input
										onChange={this.onSearchTermChange}
										icon="search"
										iconPosition="left"
										action={clearSearchTerm}
										className={this.state.searchTerm && 'active-input'}
										value={this.state.searchTerm || ''}
										placeholder={intl.formatMessage({ id: 'files.search files placeholder' })}
									/>
								</MenuItem>
							</MenuMenu>
						}
					</Menu>
				</React.Fragment>
			);
		},
		tabContent: () => {
			const selectedTab = this.state.selectedTab;

			switch (selectedTab) {
				case 'files':
					return (
						<FilesTab
							filter={this.props.filter}
							// fileType={this.props.fileType} // Rename later... fileType should be plural; we can easily imagine a case where multiple fileTypes are allowed
							searchTerm={this.state.searchTerm}
							siteid={this.props.siteid}
							selected={this.state.selected[selectedTab]}
							select={(...args) => this.select(selectedTab, ...args)}
						/>
					);
				case 'youtube':
					return (
						<YouTubeTab
							selected={this.state.selected[selectedTab]}
							select={(...args) => this.select(selectedTab, ...args)}
						/>
					);
				default: 
					return 'No tab selected';
			}
		},
		actions: () => {
			const file = this.props.files[this.props.value];

			return (
				<ModalActions>
					{(this.state.changingSelected || !this.props.value || !file) &&
						<Button
							size="big"
							className="positive"
							disabled={!this.state.selected[this.state.selectedTab]}
							onClick={this.onSave}
						>
							{/* <Icon name="plus" style={{ marginRight: 8 }} /> 
							<FormattedMessage id="options.save selection button" />
						</Button>
					}
					
					{this.props.value && file && 
						<Button
							size="big"
							className={this.state.changingSelected ? 'basic shade' : 'primary'}
							onClick={() => {
								this.setState({
									changingSelected: !this.state.changingSelected
								});
							}}
						>
							{this.state.changingSelected
								? 
									<FormattedMessage id="general.cancel" />	
								:
									(() => {
										// TO-DO: not working after fileType is replaced with filter function
										switch(this.props.fileType) {
											case 'image':
												return <FormattedMessage id="elements.image change button" />;
											case 'video':
												return <FormattedMessage id="elements.video change button" />;
											default:
												return <FormattedMessage id="elements.element change button" />;
										}
									})()
							}
						</Button>
					}
				</ModalActions>				
			);
		}
	}

	render() {
		const { intl, toggleModal, header } = this.props;		
		
		const file = this.props.files[this.props.value]; 

		return (
			<ModalWrapper
				open
				header={intl.formatMessage({ id: header || 'options.select image' })}
				handleClose={toggleModal}
				size="large"
			>
				<ModalContent>
					{this.state.changingSelected || !this.props.value || !file // File can be deleted, so even if value is set there may be no file 
						?
							<React.Fragment>
								{this.renderers.tabMenu()}
								{this.renderers.tabContent()}
							</React.Fragment>			
						:
							this.renderers.selectedOverlay()
					}
				</ModalContent>
				
				{this.renderers.actions()}
			</ModalWrapper>
		);
	}
}

FileModal.propTypes = {
	tabs: PropTypes.array.isRequired,
	multiple: PropTypes.bool,
	siteid: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(injectIntl(FileModal));
*/