import constants from 'store/constants';

const getDefaultState = () => ({
    snapshot: null,
    snapshot_with_source: null
});

const initialState = getDefaultState();

const activeAppReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.DND_SET_SNAPSHOT:
            return {
                snapshot_with_source: action.data.snapshot_with_source || null,
                snapshot: action.data.snapshot || null
            };

        case constants.DND_CLEAR_SNAPSHOT:
            return {
                snapshot_with_source: null,
                snapshot: null
            };

        default:
            return state;
    }
};

export default activeAppReducer;
