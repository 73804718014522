import React, { useEffect } from 'react';
import { Icon, Image } from 'svift-ui';;
import { FormattedMessage } from 'react-intl';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import { DragSource, DragPreviewImage } from 'react-dnd';
import dndInterface from '../../extend/drag-and-drop/interface';
import css from './building-blocks.scss';
import getDragImage from 'components/editor-v2/dragImageDictionary';

import addStyleToHead, { clearStyles } from 'utils/addStyleToHead';
const dndScrollCase = `
	.target-zone-scroll { display: flex; flex-direction: column; }
`;
let styleIds = [];
const addTargetZoneClasses = targets => {
    clearStyles(styleIds);

    const targetIds = Object.keys(targets);
    
    let css = targetIds.reduce((acc, key, index) => {
        return `${acc} .${key}${index !== targetIds.length - 1 ? ',' : ''}`;
    }, '');

    css += '{ display: block; }';
    css += dndScrollCase;

	styleIds.push(addStyleToHead(css));
};

const getType = props => props.type;

const spec = {
    beginDrag: (props, monitor) => {
        props.dragState.beginDrag();

        const { targets } = dndInterface[props.type]; // || dndInterface['ColumnElement'];

        setTimeout(() => addTargetZoneClasses(targets), 0);

        return {
            source: props.type,
            icon: props.icon,
            imageUrl: props.imageUrl,
            createBuildingBlock: props.create
        };
    },
    canDrag: (props) => {
        return !props.dragState.isDragging();
    },
    endDrag: (props, monitor) => {
        clearStyles(styleIds);

        const { targets } = dndInterface[props.type]; // type === 'BuildingBlock || SectionTemplate'

        // console.log(monitor.getItem(), targets, 'BB DROP')

        props.dragState.endDrag(monitor.getItem(), targets);
    }
};

const collect = (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging()
    };
};

/*
    1) Source = 'BuildingBlock/SectionTemplate' (set via props)
    2) DragState needs to be available = passed to "PageBlocks (example)"
*/

export default DragSource(getType, spec, collect)(props => {
    const { 
        name = 'no name specified', 
        icon, 
        className,
        imageUrl,
    } = props;

    let classNames = css['building-block'];

    if(props.isDragging) {
        classNames += ` ${css['dragged']}`;
    }

    if(className) {
        classNames += ` ${css[className]}`;
    }

    // console.log(props, 'props building block')

    const buildingBlockPopup = useTooltip();

    useEffect(() => {
        if (props.connectDragPreview) {
            // const img = new Image();
            const img = document.createElement('img');
            img.onload = () => {
                props.connectDragPreview(img)
            };
            img.src = getDragImage(props.elementID); 
            // img.crossOrigin = '';
            // setTimeout(() => {
            //     console.log('src being set lul');
                
            //     img.src = 'https://images.unsplash.com/photo-1576093797722-c7343d605b1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80';
            // }, 2000)
            // img.src = // getDragImage(props.elementID); //'https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/square-logo.svg';

            // props.connectDragPreview(img);

            // props.connectDragPreview(bla);

            // console.log("here")
            // Use empty image as a drag preview so browsers don't draw it
            // and we can draw whatever we want on the custom drag layer instead.
            // props.connectDragPreview(getEmptyImage(), {
            // // IE fallback: specify that we'd rather screenshot the node
            // // when it already knows it's being dragged so we can hide it with CSS.
            //     // captureDraggingState: true,
            // });
        }
    }, []);

    return (
        <React.Fragment>
            {/* <Popup
                position="top center"
                target={buildingBlockPopup.current}
                zIndex={2}
                >
                {typeof name === 'string' 
                    ? // not sure why this can be both a string and a component - throws warnings in console so just hacking out a fix
                    <FormattedMessage id={name} />
                    :   
                    name
                }
                
            </Popup> */}

            {/* <DragPreviewImage 
                connect={props.connectDragPreview} 
                src={getDragImage(props.elementID)}
            /> */}
{/* 
            <img    
            src={getDragImage(props.elementID)}
            ref={props.connectDragPreview}
            // onLoad={blabla => connectDragPreview()} 
        /> */}

            <div
                ref={ref => {
                    props.connectDragSource(ref);
                    buildingBlockPopup.current = ref;
                }}
                className={classNames}
                >
                {/* check if image URL is defined on building block (e.g. templates) */}
                {!imageUrl ?
                    <Icon
                    name={icon}
                    className={css['building-block-icon']}
                    />
                    :
                    <Image
                    // TO DO: fix alt description on template building blocks
                    alt={name}   
                    src={imageUrl}
                    className={css['template-image']}
                    />
                }
                <div style={{ fontSize: 12, marginTop: 6, height: 4 }}>
                    {typeof name === 'string' 
                        ? // not sure why this can be both a string and a component - throws warnings in console so just hacking out a fix
                        <FormattedMessage id={name} />
                        :   
                        name
                    }
                </div>
            </div>
        </React.Fragment>
    );
});

/*
    Block examples:
    


    <PageBlocks />:

    return elements.map(elementID => {
        const element = blocks.elements[elementID];

        return (
            <BuildingBlock {...element} />
        );
    })
    

*/