import constants from 'store/constants';
import createApi from 'api';
import { setRoute } from 'utils/routing';
import uuid from 'uuid';
const api = createApi();

const actions = {
	createBlog: (siteID, data) => async (dispatch, getState) => {
		const newBlog = await api.blogPosts.post(siteID, data);

		dispatch({
			type: constants.CREATE_BLOG,
			data: {
				siteID,
				newBlog
			}
		});

		setRoute(`/dashboard/${siteID}/blog-posts/${newBlog._id}`);
	},
	updateBlog: (siteID, blogID, data) => async (dispatch, getState) => {
		await api.blogPosts.patch(siteID, blogID, data);

		dispatch({
			type: constants.UPDATE_BLOG,
			data: {
				newBlog: data,
				blogID
			}
		});
	},
	deleteBlog: (siteID, blogID) => async (dispatch, getState) => {
		await api.blogPosts.delete(siteID, blogID);

		const state = getState();

		const deletedBlog = state.sites.blogs.blogs[blogID];
		const selectedBlogId = state.sites.blogs.params.blogid;
		
		const shouldRedirect = selectedBlogId === deletedBlog._id;

		if (shouldRedirect) {
			setRoute(`/dashboard/${siteID}/blog-posts`, { delete: constants.DELETE_BLOG, data: { blogID, siteID } });	
		} else {
			dispatch({
				type: constants.DELETE_BLOG,
				data: {
					blogID,
					siteID
				}
			});
		}
	},
	updateBlogContent: tree => {
		return {
			type: constants.UPDATE_BLOG_CONTENT,
			data: {
				tree
			}
		};
	},
	saveContent: (siteid, blogid, draft, publish = false) => async (dispatch, getState) => {
		// const draft = getState().sites.blogs.stagedContent;
		
		draft.version = uuid();

		const publishedAt = await api.blogPosts.saveContent(siteid, blogid, draft, publish);

		if (publish) {
			dispatch({
				type: constants.PUBLISH_BLOG_CONTENT,
				data: {
					publishAt: isNaN(publishedAt) && publishedAt, 
					draft,
					siteid,
					blogid
				}
			});
		} else {
			dispatch({
				type: constants.SAVE_BLOG_CONTENT,
				data: {
					draft,
					siteid,
					blogid
				}
			});
		}
	},
	// stageContent: (blogID) => (dispatch, getState) => {
	// 	const content = getState().sites.content.content;

	// 	dispatch({
	// 		type: constants.STAGE_BLOG_CONTENT,
	// 		data: content[blogID].draft
	// 	});
	// },
	toggleView: (view) => {
		return {
			type: constants.TOGGLE_BLOG_CONTENT_VIEW,
			data: view
		};
	}
};

export default actions;
