import React, { memo } from "react";
import Checkbox from 'components/option-library/primitives/checkbox';
import NumberPicker from 'components/option-library/primitives/input/number';
import Radio from 'components/option-library/primitives/radio';
import Label from 'components/option-library/label';
// import Toggle from 'components/option-library/primitives/toggle/single';
import { FormattedMessage, injectIntl } from 'react-intl';

import Section from '../Section';

const heightModeOptions = intl => [
    {
        label: intl.formatMessage({ id: 'options.auto height' }),
        value: false
    },
    {
        label: intl.formatMessage({ id: 'options.static height' }),
        value: 'height'
    },
    { 
        label: intl.formatMessage({ id: 'options.minimum height' }),
        value: 'minHeight'
    },
    {
        label: intl.formatMessage({ id: 'options.maximum height' }),
        value: 'maxHeight'
    }
];

const alignmentOptions = intl => [
    {
        label: intl.formatMessage({ id: 'general.top' }),
        value: 'flex-start'
    },
    {
        label: intl.formatMessage({ id: 'general.center' }),
        value: 'center'
    },
    {
        label: intl.formatMessage({ id: 'general.bottom' }),
        value: 'flex-end'
    }
];

export default injectIntl(memo(props => {
    const values = props.value || {};
    const { intl } = props;

    const onChange = (key, value, ...args) => {
        const newCropOptions = {
            ...values,
            [key]: value
        };

        props.onChange(newCropOptions, ...args);
        // Should probably use the one commented out below; right now all consumers of Crop specify the exact same thing (the key 'crop') when it could just be done here
        // props.onChange('crop', newCropOptions, action, autoHistory);
    };

    // console.log('Height mode: ', values.heightMode);

    return (
        <Section
            {...props}
            icon="sn-height2"
            title={intl.formatMessage({ id: 'options.height label' })}
        >
            <Label
                label={intl.formatMessage({ id: 'options.height mode label' })}
                labelIcon="sn-height2"
                // labelDescription="some description"
            >
                <Radio
                    onChange={value => onChange('heightMode', value, 'crop height mode', false)}
                    value={values.heightMode || false}
                    options={heightModeOptions(intl)}
                />
            </Label>
            {/* <Toggle 
                onChange={value => onChange('active', value)}
                value={value.active} 
                options={[
                    {
                        icon: 'sn-check',
                        description: 'Automatic height',
                        value: false
                    },
                    {
                        icon: 'sn-check',
                        description: 'Specific height',
                        value: true
                    }
                ]}
            /> */}
            
            {/* TO DO: Make Radio buttons for selecting height mode: 1. Auto, 2. Minimum, 3. Maximum or 4. Exact. */}
            {/* <Label
                label="Height mode"
                labelIcon="sn-height2"
                labelDescription="some description"
            >
                <Checkbox 
                    onChange={checked => onChange('active', checked)}
                    value={values.active}
                    label="Fixed height"
                />
            </Label> */}

            {/* if height mode is not auto, display options */}
            {values.heightMode &&
                <React.Fragment>
                    <Label
                        label={`${intl.formatMessage({ id: 'options.height label' })} (px)`}
                        labelIcon="sn-height2"
                        // labelDescription="some description"
                    >
                        <NumberPicker
                            disableUndoRedo
                            min={0}
                            max={1000}
                            unit="px"
                            name="px"
                            placeholder={`${intl.formatMessage({ id: 'options.height input placeholder' })} (px)`}
                            onChange={number => onChange('height', number, 'crop height')}
                            value={values.height}
                        />
                    </Label>

                    <Label
                        labelIcon="sn-arrow-resize4"
                        label={intl.formatMessage({ id: 'options.height vertical adjustment label' })}
                        // labelDescription="some description"
                    >
                        <Radio
                            onChange={value => onChange('verticalAlign', value, 'crop vertical align', false)}
                            value={values.verticalAlign || 'center'}
                            options={alignmentOptions(intl)}
                        />
                    </Label>

                    {/* <Label
                        label={intl.formatMessage({ id: 'options.height show scrollbar label' })}
                        labelIcon="sn-menu8"
                        labelDescription={intl.formatMessage({ id: 'options.height show scrollbar description' })}
                    >
                        <Checkbox 
                            label={intl.formatMessage({ id: 'options.height show scrollbar checkbox' })}
                            onChange={checked => onChange('allowScroll', checked, 'crop show scroll', false)}
                            value={values.allowScroll}
                        />
                    </Label> */}
                </React.Fragment>
            }
        </Section>
    );
}));