import React from 'react';
import Radium from 'radium';
import css from './menu.scss';
import Loader from 'svift-library/client/loader';

const getPadding = (nIncrement = 0) => {
    const textSize = 11 + 1.5 * nIncrement + 'px';
    const leftRight = 16.5 + 1.5 * nIncrement + 'px';
    const upDown = (16.5 + 1.5 * nIncrement) / 2 + 'px';

    return {
        fontSize: textSize,
        paddingLeft: leftRight,
        paddingRight: leftRight,
        paddingTop: upDown,
        paddingBottom: upDown
    };
};

const resolveButtonSize = size => {
    switch (size) {
        case 'mini': {
            return getPadding(0);
        }

        case 'tiny': {
            return getPadding(1);
        }

        case 'small': {
            return getPadding(2);
        }

        case 'large': {
            return getPadding(5);
        }

        case 'big': {
            return getPadding(7);
        }

        case 'huge': {
            return getPadding(9);
        }

        case 'massive': {
            return getPadding(11);
        }

        case 'medium':
        case 'default': {
            return getPadding(3);
        }
    }
};

class MenuButton extends React.Component {
    state = { loading: false };

    render() {
        const props = this.props; 
        const { computedLink, style } = props;
        const className = `${css['button']} ${props.className}`;

        if (computedLink) {
            const onClick = event => {
                if (event.ctrlKey || event.metaKey || event.shiftKey) {
                    return;
                }
    
                event.preventDefault();
    
                props.onNavigate(computedLink);
    
                this.setState({ loading: true })
                // setLoading(true);
    
                if (window.__onDataFetched) window.__onDataFetched();
    
                window.__onDataFetched = () => {
                    // setLoading(false);
                    this.setState({ loading: false });
    
                    delete window.__onDataFetched;
                };
    
                // setLoading(true);
    
                // window.location.push('/')
    
                // Router.push(
                //     `/thing/_single?id=${id}`, // "real" url
                //     `/thing/${id}` // URL the browser shows
                // );
            };
    
            const buttonSize = resolveButtonSize(props.size);
    
            if (this.state.loading) {
                return (
                    <a style={{ ...style, ...buttonSize }}>
                        <Loader size={buttonSize.fontSize} style={{ margin: 0 }} />
                    </a>
                );
            }
    
            return (
                <a
                    {...computedLink.anchorAttributes}
                    href={computedLink.url}
                    onClick={onClick}
                    className={className}
                    title={computedLink.title}
                    style={[style, buttonSize]} //{ ...style, ...buttonSize }}
                >
                    {props.children}
                </a>
            );
        }
    
        if (props.foldOutButton) {
            return (
                <div
                    className={className}
                    onClick={props.onClick}
                    style={{ ...style, ...resolveButtonSize(props.size) }}
                >
                    {props.children}
                </div>
            );
        }
    
        return (
            <div
                className={className}
                onClick={props.onClick}
                style={{ ...style, ...resolveButtonSize(props.size) }}
            >
                {props.children}
            </div>
        );
    }
}

// const MenuButton = props => {
//     const { computedLink, style, onNavigate } = props;

//     const [loading, setLoading] = React.useState(false);

//     // React.useEffect(() => {
//     //     if (isLoading) {
//     //         // sub to data loaded event

//     //         return // unsub to data loaded event
//     //     }
//     // }, [isLoading]);

//     const className = `${css['button']} ${props.className}`;

//     // console.log(computedLink, 'computed link menu button')
//     if (computedLink) {
//         const onClick = event => {
//             if (event.ctrlKey || event.metaKey || event.shiftKey) {
//                 return;
//             }

//             event.preventDefault();

//             // console.log(computedLink, 'computed link');

//             props.onNavigate(computedLink);

//             setLoading(true);

//             if (window.__onDataFetched) window.__onDataFetched();

//             window.__onDataFetched = () => {
//                 setLoading(false);

//                 delete window.__onDataFetched;
//             };

//             // setLoading(true);

//             // window.location.push('/')

//             // Router.push(
//             //     `/thing/_single?id=${id}`, // "real" url
//             //     `/thing/${id}` // URL the browser shows
//             // );
//         };

//         const buttonSize = resolveButtonSize(props.size);

//         if (loading) {
//             return (
//                 <a style={{ ...style, ...buttonSize }}>
//                     <Loader size={buttonSize.fontSize} style={{ margin: 0 }} />
//                 </a>
//             );
//         }

//         console.log(style, 'style STYLE WTFFF RADIUM')

//         // delete style[':focus'];
//         // delete style.container;
//         // delete style[':active'];

//         return (
//             <a
//                 href={computedLink.url}
//                 onClick={onClick}
//                 className={className}
//                 title={computedLink.title}
//                 style={[style, buttonSize]} //{ ...style, ...buttonSize }}
//             >
//                 {props.children}
//             </a>
//         );
//     }

//     if (props.foldOutButton) {
//         return (
//             <div
//                 className={className}
//                 onClick={props.onClick}
//                 style={{ ...style, ...resolveButtonSize(props.size) }}
//             >
//                 {props.children}
//             </div>
//         );
//     }

//     return (
//         <div
//             className={className}
//             onClick={props.onClick}
//             style={{ ...style, ...resolveButtonSize(props.size) }}
//         >
//             {props.children}
//         </div>
//     );
// };

export default Radium(MenuButton);
