import React from 'react';
import { FormattedMessage } from 'react-intl';

const translateElement = key => {
    let presetKey = 'general.type '+ key;

    return (
        <FormattedMessage id={presetKey} />
    );
};

const resolvePresetName = preset => {
    const lowercaseKey = preset.toLowerCase();
    
    switch (lowercaseKey) {
        case 'primary': {
            return translateElement('primary');
        }
        case 'secondary': {
            return translateElement('secondary');
        }
        case 'info': {
            return translateElement('info');
        }
        case 'positive': {
            return translateElement('positive');
        }
        case 'warning': {
            return translateElement('warning');
        }
        case 'negative': {
            return translateElement('negative');
        }
        case 'framed': {
            return translateElement('framed');
        }
        case 'circular': {
            return translateElement('circular');
        }
        case 'normal': {
            return translateElement('normal');
        }
        case 'big': {
            return translateElement('big');
        }
        case 'compact': {
            return translateElement('compact');
        }
        case 'small': {
            return translateElement('small');
        }
        case 'quote': {
            return translateElement('quote');
        }
        case 'title': {
            return translateElement('title');
        }
        case 'heading 2': {
            return translateElement('heading 2');
        }
        case 'heading 3': {
            return translateElement('heading 3');
        }
        case 'heading 4': {
            return translateElement('heading 4');
        }
        case 'heading 5': {
            return translateElement('heading 5');
        }
        
        // no match fallback
        default: {
            // console.log(component, 'component')

            return preset;
        }
    }
};

export default resolvePresetName;