import React, { PureComponent } from 'react';
import { Form, Icon, Button, Message, Grid, GridColumn, Divider, Dimmer, Loader } from 'svift-ui';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import OptionLabel from 'components/option-library/label';
import input from 'components/forms/__util/field-components/input';

// import textarea from 'components/forms/__util/field-components/textarea';
// import checkbox from 'components/forms/__util/field-components/checkbox';
import dropdown from 'components/forms/__util/field-components/dropdown';
import checkbox from 'components/forms/__util/field-components/checkbox';

import validate, { string, valid } from 'components/forms/__util/validate';
import AdaptedCardSelector from 'components/forms/__util/field-components/AdaptedCardSelector';

import { connect } from 'react-redux';

const fields = {
	title: {
		component: input({
			// placeholder: 'menus.navigation title',
			// name: 'menus.navigation title',
			// icon: 'sn-web',
			type: 'text',
		}),
		validate: string(1, 170)
	}
};

// const validateFields = validate(fields);

const mapStateToProps = () => ({});

class NavigationForm extends PureComponent {
	constructor() {
		super();

		this.state = {
			confirmDelete: false
		};

		this.submit = this.submit.bind(this);
	}

	async submit(values) {
		validate(fields)(values);

		await this.props.onSubmit(values);
	}

	renderButtons() {
		const { update, handleSubmit, intl } = this.props;

		if (!update) {
			return (
				<Grid>
					<GridColumn mobile={8} textAlign="left">
						<Button
							positive
							onClick={handleSubmit(this.submit)}
						>
							<Icon name="plus" style={{ marginRight: 8 }} /> <FormattedMessage id="menus.create new navigation action" />
						</Button>
					</GridColumn>
					<GridColumn mobile={8} textAlign="right">
						<Button
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<Icon name="sn-cross2" style={{ marginRight: 8 }} /> <FormattedMessage id="general.cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle">
				<GridColumn mobile={16} computer={5} textAlign="left">
					<Button
						positive
						onClick={handleSubmit(this.submit)}
					>
						<Icon name="sn-cloud-upload" /> <FormattedMessage id="settings.save changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={6} textAlign="center">
					<Button
						basic
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
					>
						<Icon name="sn-cross2" /> <FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={5} textAlign="right">
					<Button
						basic
						negative
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'menus.delete navigation alert title' })}
							text={intl.formatMessage({ id: 'menus.delete navigation alert description' })}
							confirmTitle={intl.formatMessage({ id: 'menus.delete navigation alert confirm' })}
							confirmIcon="trash"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						<Icon name="sn-bin" /> <FormattedMessage id="menus.delete navigation" /> <Icon name="sn-web" style={{ marginLeft: 4 }} />
					</Button>
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const { intl, update } = this.props;

		return (
			<Form>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}><Icon name="sn-web" /> <FormattedMessage id="general.saving changes" /></Loader>
					</Dimmer>
				}

				<OptionLabel
					labelIcon="sn-direction"
					label={intl.formatMessage({ id: 'menus.navigation title' })}
				>
					<Field
						fluid
						size="big"
						name="title"
						placeholder={intl.formatMessage({ id: 'menus.navigation title placeholder' })}
						component={fields.title.component}
					/>
					{this.props.error &&
						<Message negative>
							<FormattedMessage id={this.props.error} />
						</Message>
					}
				</OptionLabel>

				{this.renderButtons()}
			</Form>
		);
	}
}

const ReduxNavigationForm = reduxForm({
	form: 'navigation'
})(injectIntl(NavigationForm));

ReduxNavigationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

ReduxNavigationForm.defaultProps = {
	initialValues: {
		title: ''
	}
};

export default connect(mapStateToProps)(ReduxNavigationForm);
