import React, { Component } from 'react';
import { MenuItem, Icon, Button, ListItem, Label, Popup } from 'svift-ui';
import ListMenu from 'components/list-menu';
import NoResult from 'components/NoResult';
import actions from 'store/actions';
import { createFilter } from 'react-search-input';
import { connect } from 'react-redux';
import { setRoute } from 'utils/routing';
import ModalWrapper from 'components/modal';
import { makeGetSites } from 'store/selectors/sites';
import SiteForm from 'components/forms/site';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import { injectIntl, FormattedMessage } from 'react-intl';
import SviftGA from 'utils/svift-ga';

// const getSites = makeGetSites();

const mapStateToProps = (state) => {
	const { sites } = state.sites;
	const siteid = state.sites.params.siteid;
	//const site = sites && sites[siteid];

	const site = state.sites.siteList.find(({ _id }) => _id === siteid);

	return {
		site,
		siteid,
		sites: state.sites.siteList, //getSites(state),
		pathname: state.router.location.pathname
	};
};

const mapDispatchToProps = dispatch => ({
	createSite: data => dispatch(actions.sites.createSite(data)),
});

class SitesList extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			sitesListOpen: false,
			sitesModalOpen: false,
			searchTerm: ''
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	handleModalOpen = () => {
		this.setState({ sitesModalOpen: true, sitesListOpen: false });
	}

	handleModalClose = () => {
		this.setState({ sitesModalOpen: false });
	}

	toggleSitesList = () => {
		this.setState({ sitesListOpen: !this.state.sitesListOpen });
	}

	setSelectedSite = (id, e) => {
		const { siteid, pathname } = this.props;

		// This allows us to switch sites but stay in the same application section (layout, pages, design, etc.)
		if (id !== siteid) {
			const matchSiteID = /\/dashboard\/(\S{24})(\S*)/; // Matches the first 24 characters after /dashboard/ (which is the site id)
			const currentLocation = pathname;
			const match = currentLocation.match(matchSiteID);

			const newLocation = match[0].replace(match[1], id);

			setRoute(newLocation);
		}

		e.stopPropagation();
		e.preventDefault();
	}

	handleFilter = e => {
		this.setState({ searchTerm: e.target.value });
	}

	async onSubmit(data) {
		this.setState({
			loading: true
		});

		try {
			await this.props.createSite(data);

			SviftGA.SITES.CREATED();

			this.setState({
				loading: false
			});

			this.handleModalClose();
		} catch (e) {
			this.setState({
				loading: false
			});

			handleSubmitError(this.props.intl)(e);
		}
	}

	renderListMenu = () => {
		const filteredSites = this.props.sites.filter(createFilter(this.state.searchTerm, ['name']));
		const { intl } = this.props;

		if (!this.state.sitesListOpen) return false;

		return (
			<ListMenu
				position="right"
				dataSource={this.props.sites}
				handleFilter={(e, d) => this.handleFilter(e, d)}
				onClose={this.toggleSitesList}
				modalText="Create new site"
				onModalClick={this.toggleSitesModal}
				title={intl.formatMessage({ id: 'dashboard.sites' })}
				button={
					<MenuItem position="right">
						<Button onClick={this.handleModalOpen} size="mini" className="positive">
							<Icon name="plus" /> <FormattedMessage id="settings.create new site" />
						</Button>
					</MenuItem>
				}
			>
				{filteredSites.length === 0 ? <NoResult /> : filteredSites.map(site => {
					const isLiveSite = site.failedActivation || (site.domains && site.domains.length > 0);

					return (
						<ListItem
							key={site._id}
							onClick={(e) => {
								this.toggleSitesList();
								this.setSelectedSite(site._id, e);
							}}
							className={site._id === this.props.siteid ? 'active-item' : ''}
						>
							<Popup
								trigger={
									<Icon
										link
										name="setting"
										className="svift-menu-settings"
										onClick={(e) => {
											e.stopPropagation();
											this.toggleSitesList();
											setRoute(`/dashboard/${site._id}/settings`);
										}}
										style={{
											fontSize: 20,
											float: "right",
											marginRight: 0
										}}
									/>
								}
								position="right center"
								content={intl.formatMessage({ id: 'general.go to site settings popup' })}
							/>
							<span>
								<Icon
									name="sn-sphere"
									className={isLiveSite ? 'positive' : 'warning-color'}
									style={{ verticalAlign: 'middle' }}
								/>
								<span style={{ verticalAlign: 'middle' }}>
									{site.name}
								</span>

								<Label
									basic
									size="mini"
									className={isLiveSite ? 'positive' : 'warning'}
									style={{ float: 'right', marginRight: 8, verticalAlign: 'middle' }}
								>
									<Icon
										name={isLiveSite ? 'sn-earth2' : 'plug'}
										style={{ marginRight: 4 }}
									/>
									{isLiveSite ? 'online' : 'offline'}
								</Label>
							</span>
						</ListItem>
					)
				})}
			</ListMenu>
		);
	}

	render() {
		const { site, intl } = this.props;

		if (!site) return null;

		const isLiveSite = site.failedActivation || (site.domains && site.domains.length > 0);

		return (
			<MenuItem
				link
				className="svift-menu"
				onClick={!this.state.sitesListOpen ? this.toggleSitesList : null}
				style={{ position: 'relative', display: 'flex' }}
			>
				<Icon
					name="sn-sphere"
					className={isLiveSite ? 'positive-color' : 'warning-color'}
					style={{ fontSize: 24, marginRight: 6 }}
				/>
				
				<span>
					{site.name}
				</span>

				{/* <Popup
					trigger={
						<Label
							basic
							className={isLiveSite ? 'positive' : 'warning'}
							style={{
								marginLeft: 8,
								marginRight: 0,
								fontSize: 8,
								padding: 4
							}}
						>
							<Icon name={isLiveSite ? 'sn-earth2' : 'plug'} style={{ margin: 0 }} />
						</Label>
					}
					position="bottom center"
					// transl8
					content={isLiveSite ? 'online' : 'offline'}
				/> */}

				<Icon
					name={!this.state.sitesListOpen ? 'sn-arrow-down2' : 'sn-arrow-up2'}
					style={{
						fontSize: 10,
						marginLeft: 12,
						marginRight: 0
					}}
				/>

				{this.renderListMenu()}

				<ModalWrapper
					open={this.state.sitesModalOpen}
					handleClose={this.handleModalClose}
					header={intl.formatMessage({ id: 'settings.create new site' })}
					style={{ maxWidth: 800 }}
				>
					<SiteForm
						loading={this.state.loading}
						onClose={this.handleModalClose}
						onSubmit={this.onSubmit}
					/>
				</ModalWrapper>
			</MenuItem>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SitesList));