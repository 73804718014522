import React from 'react';
import { matchPath } from 'react-router';
import { FormattedDate, injectIntl } from 'react-intl';
// import key from 'svift-library/key';
// import { findNormalText } from 'svift-library/client/renderer/utils/traverseNodes';
import { searchDescription } from 'svift-library/client/renderer/utils/searchNodes';

export default previewMode => {
    return {
        hocs: [injectIntl],
        onRedirect: () => console.log('redirect admin'),
        resolveBlogPublicationDate: ({ blogs, intl }) => {
            const match = matchPath(window.location.pathname, '/dashboard/:siteid/blog-posts/:blogid?');

            if (match) {
                const blog = blogs.lookup[match.params.blogid]; //blogs.find(({ _id }) => _id === match.params.blogid);

                if (blog) {
                    if (blog.publishAt) {
                        return (
                            <FormattedDate
                                value={new Date(blog.publishAt)}
                                year='numeric'
                                month='long'
                                day='2-digit'
                            />
                        );
                    } 
                    
                    return intl.formatMessage({ id: 'elements.post publication date fallback' });   
                }
            }

            return intl.formatMessage({ id: 'elements.post publication date dummy content' });
        },
        resolveBlogDescription: ({ blogs, intl }) => {
            const match = matchPath(window.location.pathname, '/dashboard/:siteid/blog-posts/:blogid?');

            if (match) {
                const blog = blogs.lookup[match.params.blogid]; //find(({ _id }) => _id === match.params.blogid);

                if (blog) {
                    const blogContent = previewMode !== 'live' ? blog.content.draft.nodeMap : blog.content.live.nodeMap

                    const description = blog.description || searchDescription(blogContent);
                    
                    return description;

                    // const description = blog.description || findNormalText(key('content.live.nodeMap')(blog) || blog.content.draft.nodeMap) // may want to revisit in editor 2 to get more accurate depictions
                    
                    // return description;
                }
            }

            return intl.formatMessage({ id: 'elements.post description dummy content' });	
        },
        resolveBlogTitle: ({ blogs, intl }) => {
            const match = matchPath(window.location.pathname, '/dashboard/:siteid/blog-posts/:blogid?');

            if (match) {
                // TO-DO: Find out how to weave in draft blogs without breaking other things (like the menu)
                const blog = blogs.lookup[match.params.blogid]; //blogs.find(({ _id }) => _id === match.params.blogid);

                if (blog) {
                    return blog.title;
                }
            }
            
            return intl.formatMessage({ id: 'elements.post title dummy content' });
        },
        resolveBlogAuthor: ({ blogs, users, intl }) => {
            const match = matchPath(window.location.pathname, '/dashboard/:siteid/blog-posts/:blogid?');

            if (match) {
                const blog = blogs.lookup[match.params.blogid]; //blogs.find(({ _id }) => _id === match.params.blogid);

                if (blog) {
                    const author = users && users.lookup[blog.author] //find(user => user._id === blog.author);
                    
                    if (author) {
                        return `${author.firstName} ${author.lastName}`;
                    }
                }
            }

            return intl.formatMessage({ id: 'elements.post author dummy content' });
        },
        resolvePageTitle: ({ pages, intl }) => {
            const match = matchPath(window.location.pathname, '/dashboard/:siteid/pages/:pageid?');

            if (match) {
                const page = pages.lookup[match.params.pageid]; //pages.find(({ _id }) => _id === match.params.pageid);

                if (page) {
                    return page.title;
                }
            }

            return intl.formatMessage({ id: 'elements.page title dummy content' });
        }
    };
};

/*
        resolveBlogPublicationDate: ({ content }) => {
            if (content.publishAt) {
                return (
                    <FormattedDate
                        value={new Date(content.publishAt)}
                        year='numeric'
                        month='long'
                        day='2-digit'
                    />
                )
            }

            return 'Published: N/A';
        },
        resolveBlogDescription: ({ content }) => {
            let description = content.description;

            if (!description) {
                description = searchDescription(content.content.live.nodeMap);
            }

            if (!description) return content.title;

            return description;
        },
        resolveBlogAuthor: ({ content, users, siteName }) => {
            const author = users.lookup[content.author && content.author.toString()];

            return author ? `${author.firstName} ${author.lastName}` : siteName;
        },
        resolveBlogTitle: ({ content }) => content.title,
        resolvePageTitle: ({ content }) => content.title
*/