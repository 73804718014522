import React, { PureComponent } from 'react';
import { GridColumn, Grid, GridRow, Popup, Button, Icon, Menu, Dropdown } from 'svift-ui';
// import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { computePreset } from 'components/preset/util';
import Tooltip from 'svift-library/client/tooltip';

class PaddingOption extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			selectedOption: null
		};

		// this.tooltip = tooltip.call(this);

		this.tooltipRef = React.createRef();

		this.onChange = this.onChange.bind(this);
		this.selectOption = this.selectOption.bind(this);
	}

	onChange(key, newValue) {
		this.props.onChange(key, newValue);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.selectedOption !== nextProps.selectedOption) {
			this.selectOption(null);
		}
	}

	selectOption(option) {
		this.setState({
			selectedOption: option
		});
	}

	render() {
		const { option, onToggle, selectedOption, value = {}, intl, theme, preset } = this.props;

		const selected = option === selectedOption; 

		const { paddingBottom, generalPadding, paddingLeft, paddingRight, paddingTop } = value;
		const computedStyles = computePreset(preset, theme)

		return (
			<div className="option" style={{ display: 'inline-block' }} >
				{/* <Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					position="top"
					content={
						<div className="tiny-panel">
							<p className="option-title" style={{ textAlign: 'center' }}><FormattedMessage id="options.padding title" /> <InfoIcon description={intl.formatMessage({ id: 'options.padding description' })} /></p>
							<Grid padded columns={16} centered verticalAlign="middle" className="manipulator tiny-panel padding-options">
								<GridRow centered columns={3} style={{ paddingTop: 4 }}>
									<GridColumn />
									<GridColumn textAlign="center">
										<Slider
											optIn={this.props.optIn.paddingTop}
											onClear={() => {
												if (typeof paddingTop === 'undefined') return;

												this.props.onChange('paddingTop', null);
											}}
											optionalDisabled={typeof paddingTop === 'undefined'}
											activeButton={typeof paddingTop !== 'undefined'}

											icons={['sn-arrow-up8']}
											title={`${intl.formatMessage({ id: 'options.padding top title' })}${typeof paddingTop === 'undefined' ? '' : `: ${Math.floor(paddingTop * 100)}%`} 
													${(computedStyles.paddingTop === '0px' || typeof computedStyles.paddingTop === 'undefined')? '': `(${computedStyles.paddingTop})`}`}
											onChange={newValue => {
												this.onChange('paddingTop', newValue);
											}}
											value={paddingTop * 100 || 50}
											min={10}
											max={350}

											selectedOption={this.state.selectedOption}
											onToggle={(option, e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();

												this.selectOption(option);
											}}
											option="paddingTop"
										/>
									</GridColumn>
									<GridColumn />
								</GridRow>

								<GridRow centered columns={3}>
									<GridColumn textAlign="right">
										<Slider
											optIn={this.props.optIn.paddingLeft}
											onClear={() => {
												if (typeof paddingLeft === 'undefined') return;

												this.props.onChange('paddingLeft', null);
											}}
											optionalDisabled={typeof paddingLeft === 'undefined'}
											activeButton={typeof paddingLeft !== 'undefined'}

											icons={['sn-arrow-left8']}
											title={`${intl.formatMessage({ id: 'options.padding left title' })}${typeof paddingLeft === 'undefined' ? '' : `: ${Math.floor(paddingLeft * 100)}%`}
											        ${(computedStyles.paddingLeft === '0px' || typeof computedStyles.paddingLeft === 'undefined')? '': `(${computedStyles.paddingLeft})`}`}
											onChange={newValue => {
												this.onChange('paddingLeft', newValue);
											}}

											value={paddingLeft * 100 || 50}
											min={10}
											max={350}

											selectedOption={this.state.selectedOption}
											onToggle={(option, e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();

												this.selectOption(option);
											}}
											option="paddingLeft"
										/>
									</GridColumn>
									<GridColumn textAlign="center">
										<Slider
											optIn={this.props.optIn.generalPadding}
											onClear={() => {
												if (typeof generalPadding === 'undefined') return;

												this.props.onChange('generalPadding', null);
											}}
											optionalDisabled={typeof generalPadding === 'undefined'}
											activeButton={typeof generalPadding !== 'undefined'}

											icons={['move']}
											title={`${intl.formatMessage({ id: 'options.padding general title' })}${typeof generalPadding === 'undefined' ? '' : `: ${Math.floor(generalPadding * 100)}%`} 
											        ${(computedStyles.paddingGeneral === '0px' || typeof computedStyles.paddingGeneral === 'undefined') ? '': `(${computedStyles.paddingGeneral})`}`}
											onChange={newValue => {
												this.onChange('generalPadding', newValue);
											}}
											value={generalPadding * 100 || 50}
											min={10}
											max={350}

											selectedOption={this.state.selectedOption}
											onToggle={(option, e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();

												this.selectOption(option);
											}}
											option="paddingGeneral"
										/>
									</GridColumn>
									<GridColumn>
										<Slider
											optIn={this.props.optIn.paddingRight}
											onClear={() => {
												if (typeof paddingRight === 'undefined') return;

												this.props.onChange('paddingRight', null);
											}}
											optionalDisabled={typeof paddingRight === 'undefined'}
											activeButton={typeof paddingRight !== 'undefined'}

											icons={['sn-arrow-right8']}
											title={`${intl.formatMessage({ id: 'options.padding right title' })}${typeof paddingRight === 'undefined' ? '' : `: ${Math.floor(paddingRight * 100)}%`}
												    ${(computedStyles.paddingRight === '0px'|| typeof computedStyles.paddingRight === 'undefined') ? '': `(${computedStyles.paddingRight})`}`}
											onChange={newValue => {
												this.onChange('paddingRight', newValue);
											}}

											value={paddingRight * 100 || 50}
											min={10}
											max={350}

											selectedOption={this.state.selectedOption}
											onToggle={(option, e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();

												this.selectOption(option);
											}}
											option="paddingRight"
										/>
									</GridColumn>
								</GridRow>

								<GridRow centered columns={3} style={{ paddingBottom: 4 }}>
									<GridColumn />
									<GridColumn textAlign="center">
										<Slider
											optIn={this.props.optIn.paddingBottom}
											onClear={() => {
												if (typeof paddingBottom === 'undefined') return;

												this.props.onChange('paddingBottom', null);
											}}

											activeButton={typeof paddingBottom !== 'undefined'}
											icons={['sn-arrow-down8']}
											title={`${intl.formatMessage({ id: 'options.padding bottom title' })}${typeof paddingBottom === 'undefined' ? '' : `: ${Math.floor(paddingBottom * 100)}%`} 
													${(computedStyles.paddingBottom === '0px' || typeof computedStyles.paddingBottom === 'undefined') ? '': `(${computedStyles.paddingBottom})`}`}
											onChange={newValue => {
												this.onChange('paddingBottom', newValue);
											}}

											value={paddingBottom * 100}
											min={10}
											max={350}

											selectedOption={this.state.selectedOption}
											onToggle={(option, e) => {
												e.stopPropagation();
												e.nativeEvent.stopImmediatePropagation();

												this.selectOption(option);
											}}
											option="paddingBottom"
										/>
									</GridColumn>
									<GridColumn />
								</GridRow>
							</Grid>
						</div>
					}
					hover={this.state.hover}
					hoverContent={intl.formatMessage({ id: 'options.padding title' })}
					style={{ zIndex: 999 }}
				/> */}

				<Tooltip
					open={selected}
					target={this.tooltipRef.current}
					popup
					zIndex="998"
				>
					<div className="tiny-panel">
						<p className="option-title" style={{ textAlign: 'center' }}><FormattedMessage id="options.padding title" /> <InfoIcon description={intl.formatMessage({ id: 'options.padding description' })} /></p>
						<Grid padded columns={16} centered verticalAlign="middle" className="manipulator tiny-panel padding-options">
							<GridRow centered columns={3} style={{ paddingTop: 4 }}>
								<GridColumn />
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.paddingTop}
										onClear={() => {
											if (typeof paddingTop === 'undefined') return;

											this.props.onChange('paddingTop', null);
										}}
										optionalDisabled={typeof paddingTop === 'undefined'}
										activeButton={typeof paddingTop !== 'undefined'}

										icons={['sn-arrow-up8']}
										title={`${intl.formatMessage({ id: 'options.padding top title' })}${typeof paddingTop === 'undefined' ? '' : `: ${Math.floor(paddingTop * 100)}%`} 
												${(computedStyles.paddingTop === '0px' || typeof computedStyles.paddingTop === 'undefined')? '': `(${computedStyles.paddingTop})`}`}
										onChange={newValue => {
											this.onChange('paddingTop', newValue);
										}}
										value={paddingTop * 100 || 50}
										min={10}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="paddingTop"
									/>
								</GridColumn>
								<GridColumn />
							</GridRow>

							<GridRow centered columns={3}>
								<GridColumn textAlign="right">
									<Slider
										optIn={this.props.optIn.paddingLeft}
										onClear={() => {
											if (typeof paddingLeft === 'undefined') return;

											this.props.onChange('paddingLeft', null);
										}}
										optionalDisabled={typeof paddingLeft === 'undefined'}
										activeButton={typeof paddingLeft !== 'undefined'}

										icons={['sn-arrow-left8']}
										title={`${intl.formatMessage({ id: 'options.padding left title' })}${typeof paddingLeft === 'undefined' ? '' : `: ${Math.floor(paddingLeft * 100)}%`}
												${(computedStyles.paddingLeft === '0px' || typeof computedStyles.paddingLeft === 'undefined')? '': `(${computedStyles.paddingLeft})`}`}
										onChange={newValue => {
											this.onChange('paddingLeft', newValue);
										}}

										value={paddingLeft * 100 || 50}
										min={10}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="paddingLeft"
									/>
								</GridColumn>
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.generalPadding}
										onClear={() => {
											if (typeof generalPadding === 'undefined') return;

											this.props.onChange('generalPadding', null);
										}}
										optionalDisabled={typeof generalPadding === 'undefined'}
										activeButton={typeof generalPadding !== 'undefined'}

										icons={['move']}
										title={`${intl.formatMessage({ id: 'options.padding general title' })}${typeof generalPadding === 'undefined' ? '' : `: ${Math.floor(generalPadding * 100)}%`} 
												${(computedStyles.paddingGeneral === '0px' || typeof computedStyles.paddingGeneral === 'undefined') ? '': `(${computedStyles.paddingGeneral})`}`}
										onChange={newValue => {
											this.onChange('generalPadding', newValue);
										}}
										value={generalPadding * 100 || 50}
										min={10}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="paddingGeneral"
									/>
								</GridColumn>
								<GridColumn>
									<Slider
										optIn={this.props.optIn.paddingRight}
										onClear={() => {
											if (typeof paddingRight === 'undefined') return;

											this.props.onChange('paddingRight', null);
										}}
										optionalDisabled={typeof paddingRight === 'undefined'}
										activeButton={typeof paddingRight !== 'undefined'}

										icons={['sn-arrow-right8']}
										title={`${intl.formatMessage({ id: 'options.padding right title' })}${typeof paddingRight === 'undefined' ? '' : `: ${Math.floor(paddingRight * 100)}%`}
												${(computedStyles.paddingRight === '0px'|| typeof computedStyles.paddingRight === 'undefined') ? '': `(${computedStyles.paddingRight})`}`}
										onChange={newValue => {
											this.onChange('paddingRight', newValue);
										}}

										value={paddingRight * 100 || 50}
										min={10}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="paddingRight"
									/>
								</GridColumn>
							</GridRow>

							<GridRow centered columns={3} style={{ paddingBottom: 4 }}>
								<GridColumn />
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.paddingBottom}
										onClear={() => {
											if (typeof paddingBottom === 'undefined') return;

											this.props.onChange('paddingBottom', null);
										}}

										activeButton={typeof paddingBottom !== 'undefined'}
										icons={['sn-arrow-down8']}
										title={`${intl.formatMessage({ id: 'options.padding bottom title' })}${typeof paddingBottom === 'undefined' ? '' : `: ${Math.floor(paddingBottom * 100)}%`} 
												${(computedStyles.paddingBottom === '0px' || typeof computedStyles.paddingBottom === 'undefined') ? '': `(${computedStyles.paddingBottom})`}`}
										onChange={newValue => {
											this.onChange('paddingBottom', newValue);
										}}

										value={paddingBottom * 100}
										min={10}
										max={350}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="paddingBottom"
									/>
								</GridColumn>
								<GridColumn />
							</GridRow>
						</Grid>
					</div>
				</Tooltip>

				<Button
					// ref={this.tooltip.setTarget}
					ref={this.tooltipRef}
					basic
					size="tiny"
					icon="sn-shrink3"
					className={`primary ${value && Object.keys(value).length > 0 ? 'active-button' : ''}`}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				/>
			</div>
		);
	}
}

PaddingOption.propTypes = {
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func,
	optIn: PropTypes.object
};

PaddingOption.defaultProps = {
	optIn: {
		paddingBottom: true,
		paddingTop: true,
		paddingLeft: true,
		paddingRight: true,
		generalPadding: true
	}
};

export default injectIntl(PaddingOption);