import React from 'react';
import { Popup, Button } from 'svift-ui';

export default (props) => {
    return (
        <Popup
            trigger={
                <div className="option" style={{ display: 'inline-block' }}>
                    <Button
                        basic
                        disabled={props.disabled}
                        icon={props.icon}
                        size="small"
                        className={`primary`}
                        onClick={props.onClick}
                    />
                </div>
            }
            content={props.content}
            position="top center"
        />
    );
};