import React, { PureComponent } from 'react';
import {
	Form,
	IconGroup,
	Icon,
	Button,
	Message,
	Grid,
	GridRow,
	GridColumn,
	Divider,
	Dimmer,
	Loader,
	Label,
	Accordion,
	AccordionTitle,
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AdaptedCardSelector from 'components/forms/__util/field-components/AdaptedCardSelector';
import OptionLabel from 'components/option-library/label';
import css from 'components/editor-v2/editor-interface/options/options.scss';

import textarea from 'components/forms/__util/field-components/textarea';
import input from 'components/forms/__util/field-components/input';
import checkbox from 'components/forms/__util/field-components/checkbox';
import dropdown from 'components/forms/__util/field-components/dropdown';
import Alert from 'components/Alert';
import AdaptedFilePicker from 'components/forms/__util/field-components/AdaptedFilePicker';
import MultipleSelect from 'components/forms/__util/field-components/multipleSelect';

import validate, { string, valid } from 'components/forms/__util/validate';
import urlConverter from 'components/forms/__util/validate/urlConverter';

import { connect } from 'react-redux';
import layoutsSelector from 'store/selectors/sites/layouts';
import { filesSelector } from 'store/selectors/sites/files';
import { pagesSelector } from 'store/selectors/sites/pages';

import key from 'utils/key';

const createTemplateCards = intl => [
	{
		id: 'empty-fallback',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template empty' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/fallback-template.png'
	},
	{
		id: '1-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template one column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/1-column-text-template.png'
	},
	{
		id: '2-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template two column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/2-column-text-template.png'
	},
	{
		id: '3-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-column-text-template.png'
	},
	{
		id: '4-column-text-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template four column text' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/4-column-text-template.png'
	},
	{
		id: 'standard-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template standard hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/standard-hero-template.png'
	},
	{
		id: 'background-image-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template background image hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/background-image-hero-template.png'
	},
	{
		id: 'left-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-infobox-template.png'
	},
	{
		id: 'right-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-infobox-template.png'
	},
	{
		id: 'broad-infobox-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template broad infobox' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/broad-infobox-template.png'
	},
	{
		id: 'left-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template left image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/left-image-template.png'
	},
	{
		id: 'right-image-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template right image' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/right-image-template.png'
	},
	{
		id: '3-round-features-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three round features' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-round-features-template.png'
	},
	{
		id: 'banner-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template banner' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/banner-template.png'
	},
	{
		id: 'image-frame-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template image frame' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/image-frame-template.png'
	},
	{
		id: 'video-hero-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template video hero' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/video-hero-template.png'
	},
	{
		id: '3-videos-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template three videos' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/3-videos-template.png'
	},
	{
		id: 'standard-contact-form-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template standard contact form' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/standard-contact-form-template.png'
	},
	{
		id: 'contact-form-with-map-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template contact form with map' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/contact-form-with-map-template.png'
	},
	{
		id: 'contact-info-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template contact info' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/contact-info-template.png'
	},
	{
		id: 'blog-list-template',
		style: { padding: 6 },
		noLabel: true,
		name: intl.formatMessage({ id: 'templates.template blog list' }),
		imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/blog-list-template.png'
	}
];

const fields = {
	title: {
		component: input({
			placeholder: 'pages.page title',
			// name: 'pages.page title',
			icon: 'sn-file-text',
			type: 'text',
		}),
		validate: string(1, 70)
	},
	navigationTitle: {
		component: input({
			placeholder: 'pages.page navigation title',
			// name: 'pages.page navigation title',
			iconDirection: 'left',
			type: 'text',
		}),
		validate: string(0, 70), //string(1, 70) // should be optional
	},
	parentPage: {
		component: dropdown({
			placeholder: 'pages.page parent',
			name: 'pages.page parent',
			icon: 'sn-file-text'
		}),
		validate: string(1, 255)
	},
	layout: {
		component: dropdown({
			placeholder: 'pages.page layout',
			name: 'pages.page layout',
			icon: 'sn-web'
		}),
		validate: string(1, 255)
	},
	author: {
		component: dropdown({
			placeholder: 'pages.page author',
			name: 'pages.page author',
			icon: 'user'
		}),
		validate: valid
	},
	description: {
		component: textarea({
			placeholder: 'pages.page description',
			name: 'pages.page description'
		}),
		validate: string(0, 160)
	},
	thumbnail: {
		component: AdaptedFilePicker,
		validate: valid
	},
	keywords: {
		component: MultipleSelect,
		validate: valid
	},
	pageTemplate: {
		validate: valid
	}
};

const mapStateToProps = (state, ownProps) => {
	const siteid = state.sites.params.siteid;

	const layouts = layoutsSelector(state, siteid);
	const defaultLayout = layouts.find(layout => layout.type === 'page' && layout.default);

	/*
		SUI dropdowns are funky as hell and can't have null values, so we cast null values to a string ("__siteIsAuthor__")

		This means we also have to convert it *back* into the same string when the value is provided in update forms

		TO DO: find a clean solution to this mess
	*/
	const initialAuthor = key('initialValues.author')(ownProps) === null
		?
			'__siteIsAuthor__'
		:
			key('initialValues.author')(ownProps) || '__siteIsAuthor__'

	const isPublishedPage = ownProps.update && state.sites.content.content[ownProps.page].live;

	return {
		files: filesSelector(state, siteid),
		layouts,
		subscription: key(`sites.sites.${siteid}.subscription.type`)(state),
		isPublishedPage,
		pages: pagesSelector(state, siteid),
		frontPage: state.sites.sites[siteid].frontPage,
		keywordsValue: state.form && state.form.page && state.form.page.values && state.form.page.values.keywords || '',
		pageTemplateValue: state.form && state.form.page && state.form.page.values && state.form.page.values.pageTemplate || '',
		pageTitleValue: state.form && state.form.page && state.form.page.values && state.form.page.values.title || '', // we are interested in this value because we have to transform it on the fly
		initialValues: {
			parentPage: '__noParentPage__', // Dropdown component cant handle null value, so we use this string to represent a null value and convert it to null on submit if its selected
			keywords: [],
			layout: defaultLayout._id,
			pageTemplate: 'empty-fallback', /*{
				blank: {
					id: 'blank'
				}
			},*/
			...(ownProps.initialValues || {}),
			author: initialAuthor,
			navigationTitle: (ownProps.initialValues && ownProps.initialValues.navigationTitle) || '',
			description: (ownProps.initialValues && ownProps.initialValues.description) || '',
		},
		users: [
			{
				text: state.sites.sites[siteid].name,
				value: '__siteIsAuthor__'
			},
			{
				text: `${state.user.firstName} ${state.user.lastName}`,
				value: state.user._id
			}
		]
	};
};

class PageForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showOptionalFields: false,
			confirmDelete: false,
			titleInputFocused: false
		};

		this.submit = this.submit.bind(this);
		this.toggleOptionalFields = this.toggleOptionalFields.bind(this);
	}

	setTitleInputFocused = () => {
		if (this.props.update) {
			this.setState({
				titleInputFocused: true
			});
		}
	}

	_formatPages() {
		let parentPages = this.props.pages;

		if (this.props.update) {
			parentPages = this.props.pages.filter(page => {
				const isChildPage = page.parentPage === this.props.page;

				return page._id !== this.props.page && !isChildPage;
			});
		}

		const options = [
			{
				text: this.props.intl.formatMessage({ id: 'pages.no parent' }),
				value: '__noParentPage__'
			},
			...parentPages.map(page => ({
				text: page.title,
				value: page._id
			}))
		];

		return options;
	}

	async submit(values) {
		const relevantFields = {
			...fields
		};

		const modifiedValues = {
			...values
		};

		if (!this.props.update) {
			// console.log(values, 'values')

			// modifiedValues.pageTemplate = values.pageTemplate[Object.keys(values.pageTemplate)[0]].id;
		} else {
			delete relevantFields.pageTemplate;
			delete modifiedValues.pageTemplate; 
		}

		validate(relevantFields)(modifiedValues);

		if (modifiedValues.parentPage === '__noParentPage__') {
			modifiedValues.parentPage = null;
		}

		if (modifiedValues.author === '__siteIsAuthor__') {
			modifiedValues.author = null;
		}

		// if (!modifiedValues.description) {
		// 	modifiedValues.description = null;
		// }

		await this.props.onSubmit(modifiedValues);
	}

	toggleOptionalFields() {
		this.setState({
			showOptionalFields: !this.state.showOptionalFields
		});
	}

	/*
		Warns user if keywords are already used on another page
	*/
	// renderWarnKeywordUsedElsewhere = () => {
	// 	if (this.props.keywordsValue) {
	// 		/*
	// 			Runs through all keywords used on the given site across all pages and checks if they are already in use by another page
	// 		*/
	// 		let usedKeywords = this.props.keywords.reduce((acc, k1) => {
	// 			let { foundKeywords, memory } = acc;
	// 			const keywordAlreadyFound = memory[k1.toUpperCase()];

	// 			/* We remember our found keywords so no duplicates get added to the list of used keywords */
	// 			if (keywordAlreadyFound) return acc;

	// 			if (this.props.keywordsValue.find(k2 => k1.toUpperCase() === k2.toUpperCase())) {
	// 				memory[k1.toUpperCase()] = true;

	// 				acc.foundKeywords = foundKeywords.concat(k1);
	// 			}

	// 			return acc;
	// 		}, { foundKeywords: [], memory: {} }).foundKeywords;

	// 		if (this.props.update) {
	// 			// If we're updating, we want to make sure the keywords that were used by this page do not give a warning
	// 			usedKeywords = usedKeywords.filter(keyword => {
	// 				if (this.props.initialValues.keywords) {
	// 					return !this.props.initialValues.keywords.some(initialKeyword => initialKeyword.toUpperCase() === keyword.toUpperCase());
	// 				}
	// 			});
	// 		}

	// 		if (usedKeywords.length > 0) {
	// 			return (
	// 				<Message info style={{ marginTop: -10, fontSize: 14, opacity: 0.6, textAlign: 'left' }}>
	// 					<p> {this.props.intl.formatMessage({ id: 'pages.keywords already used by another page' })} </p>

	// 					{usedKeywords.map(usedKeyword => {
	// 						return (
	// 							<p> {usedKeyword} </p>
	// 						);
	// 					})}
	// 				</Message>
	// 			);
	// 		}
	// 	}

	// 	return null;
	// }

	renderButtons() {
		const { update, handleSubmit, intl } = this.props;

		if (!update) {
			return (
				<Grid>
					<GridColumn mobile={8} textAlign="left">
						<Button
							positive
							onClick={handleSubmit(this.submit)}
						>
							<Icon name="plus" style={{ marginRight: 8 }} /> <FormattedMessage id="pages.create page action" />
						</Button>
					</GridColumn>
					<GridColumn mobile={8} textAlign="right">
						<Button
							basic
							onClick={(e) => {
								e.preventDefault();

								this.props.onClose();
							}}
						>
							<Icon name="sn-cross2" style={{ marginRight: 8 }} /> <FormattedMessage id="pages.create page cancel" />
						</Button>
					</GridColumn>
				</Grid>
			);
		}

		return (
			<Grid verticalAlign="middle">
				<GridColumn mobile={16} computer={5} textAlign="left">
					<Button
						positive
						onClick={handleSubmit(this.submit)}
					>
						<Icon name="sn-cloud-upload" /> <FormattedMessage id="settings.save changes" />
					</Button>
				</GridColumn>

				<GridColumn mobile={8} computer={6} textAlign="center">
					<Button
						basic
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.props.reset();
						}}
					>
						<Icon name="sn-cross2" /> <FormattedMessage id="settings.clear changes" />
					</Button>
				</GridColumn>
				<GridColumn mobile={8} computer={5} textAlign="right">
					<Button
						basic
						negative
						size="tiny"
						onClick={(e) => {
							e.preventDefault();

							this.setState({
								confirmDelete: true
							});
						}}
					>
						<Alert
							show={this.state.confirmDelete}
							title={intl.formatMessage({ id: 'pages.delete page alert title' })}
							text={intl.formatMessage({ id: 'pages.delete page alert description' })}
							confirmTitle={intl.formatMessage({ id: 'pages.delete page alert confirm' })}
							confirmIcon="trash"
							confirmClass="negative"
							cancelTitle={intl.formatMessage({ id: 'general.cancel' })}
							onConfirm={() => {
								this.setState({
									confirmDelete: false
								});

								handleSubmit(this.props.delete)();
							}}
							showCancelButton
							onCancel={() => {
								this.setState({
									confirmDelete: false
								});
							}}
						/>
						<Icon name="sn-bin" /> <FormattedMessage id="pages.delete page" /> <Icon name="sn-file-text" style={{ marginLeft: 4 }} />
					</Button>
				</GridColumn>
			</Grid>
		);
	}

	render() {
		const { intl, subscription, isPublishedPage } = this.props;
		const { showOptionalFields, titleInputFocused } = this.state;
		const isFrontPage = this.props.page === this.props.frontPage;
		const cards = createTemplateCards(intl);
        const selectedCard = cards.find(card => card.id === this.props.pageTemplateValue);

		const showChangePageUrlWarning = titleInputFocused && !isFrontPage && isPublishedPage && subscription;

		return (
			<Form>
				{this.props.loading &&
					<Dimmer active inverted>
						<Loader size="huge" style={{ fontSize: 32 }}><Icon name="sn-file-text" /> <FormattedMessage id="general.saving changes" /></Loader>
					</Dimmer>
				}

				<div style={{ textAlign: 'center' }}>
					{!this.props.update &&
						<React.Fragment>
							<Divider horizontal>
								<Icon circular name="sn-grid8" style={{ opacity: 0.6, fontSize: 24 }} />

								<p style={{ marginTop: 4, marginBottom: 16 }}>
									<FormattedMessage id="templates.page starter template" />
									<InfoIcon description={intl.formatMessage({ id: 'templates.page starter template description' })} />
								</p>
							</Divider>

							<div
								className="section-shade"
								style={{
									padding: '12px 16px',
									maxHeight: 220,
									overflowY: 'auto',
									overflowX: 'hidden',
									border: '1px solid #ccc'
								}}
							>
								<Field
									name="pageTemplate"
									key="starter-page-template"
									itemsPerRow={5}
									cards={cards}
									component={AdaptedCardSelector}
								/>
							</div>

							{selectedCard &&
								<Message className="primary" style={{ marginTop: 8, padding: 8 }}>
									<span style={{ marginRight: 8 }}>
										<Icon name="sn-checkmark" style={{ marginRight: 6 }} />
										<FormattedMessage id="general.you have chosen" />
									</span>
									<Label className="primary">
										<Icon name="sn-file-text" style={{ marginRight: 6 }} />

										{selectedCard.name}
									</Label>
								</Message>
							}
						</React.Fragment>
					}

					{this.props.error &&
						<Message negative style={{ marginTop: 16 }}>
							<Icon name="sn-warning" /> <FormattedMessage id={this.props.error} />
						</Message>
					}
				</div>

				<Divider horizontal>
					<Icon circular name="sn-file-text" className="primary-color" />
				</Divider>

				<Grid
					padded
					className={css['option-frame-outline']}
					style={{ padding: '24px 16px' }}
				>
					<GridRow
						verticalAlign="top"
						style={{ padding: 0 }}
					>
						<GridColumn
							computer={4}
							style={{ textAlign: 'center', padding: '32px 16px 44px', margin: 0 }}
						>
							{/* new page + page settings icon */}
							<IconGroup style={{ marginBottom: 4 }}>
								<Icon
									name="sn-file-text"
									style={{
										opacity: 0.4,
										fontSize: 64
									}}
								/>
								<Icon
									corner
									name={!this.props.update ? "add" : "setting"}
									className={!this.props.update ? "positive-color" : "info-color"}
									style={{
										fontSize: 44,
										marginRight: -16,
										marginBottom: -20
									}}
								/>
							</IconGroup>
						</GridColumn>
						<GridColumn computer={12}>
							{/* page title (meta) */}
							<OptionLabel
								label={intl.formatMessage({ id: 'pages.page title' })}
								labelIcon="sn-bookmark2"
								labelDescription={intl.formatMessage({ id: 'pages.page title description' })}
							>
								<Field
									fluid
									size="huge"
									name="title"
									placeholder={intl.formatMessage({ id: 'pages.page title input placeholder' })}
									icon="sn-file-text"
									iconPosition="left"
									intl={intl}
									component={fields.title.component}
								/>
							</OptionLabel>

							{/* page url output */}
							{/* TO DO: add parent page to urlConverter */}
							<Message style={{ fontSize: 14, padding: '8px 18px', textAlign: 'left' }}>
								<label>
									{isFrontPage &&
										<Icon name="sn-star-full" />
									}
									{/* <FormattedMessage id="pages.page url message label" />:<br /> */}
									<Label basic className="positive" style={{ padding: 6, margin: 0, marginRight: 4 }}>
										<span style={{ fontWeight: 'bold' }}><Icon name="sn-lock5" style={{ margin: 0 }} /> https://</span>
									</Label>
									<Label basic className="info" style={{ padding: 6, margin: 0 }}>
										<FormattedMessage id="general.domain-name label" />
									</Label> /
								</label>

								{isFrontPage ?
									<span>
										<InfoIcon
											description={intl.formatMessage({ id: 'pages.homepage forced navigation link description' })}
											position="bottom center"
										/>
									</span>
								:
									<span>
										{this.props.pageTitleValue === ""
											?
												<span>...</span>
											:
												urlConverter(this.props.pageTitleValue)
										}/
										<InfoIcon
											description={intl.formatMessage({ id: 'pages.page navigation link description' })}
											position="bottom center"
										/>
									</span>
								}
							</Message>

							<Divider />

							<Grid>
								<GridColumn computer={8}>
									{/* page layout select */}
									<OptionLabel
										collapsed
										label={intl.formatMessage({ id: 'pages.page layout' })}
										labelIcon="sn-web"
										labelDescription={intl.formatMessage({ id: 'pages.page layout description' })}
									>
										<Field
											name="layout"
											values={this.props.layouts.map(layout => ({
												text: layout.title,
												value: layout._id
											}))}
											intl={intl}
											component={fields.layout.component}
										/>
									</OptionLabel>
								</GridColumn>
								<GridColumn computer={8}>
									{/* parent page select */}
									<OptionLabel
										collapsed
										label={intl.formatMessage({ id: 'pages.page parent' })}
										labelIcon="sn-file-text"
										labelDescription={intl.formatMessage({ id: 'pages.page parent description' })}
									>
										<Field
											name="parentPage"
											values={this._formatPages()}
											format={null}
											intl={intl}
											component={fields.parentPage.component}
										/>
									</OptionLabel>
								</GridColumn>
							</Grid>
						</GridColumn>
					</GridRow>
				</Grid>

				<Divider horizontal>
					<Icon circular name="sn-plus2" style={{ opacity: 0.3 }} />
				</Divider>

				{/* more options */}
				<Grid padded className="section-shade">
					<GridRow>
						<GridColumn computer={16}>

							{/* show more options accordion */}
							<Accordion
								style={{ marginTop: 0 }}
								onClick={this.toggleOptionalFields}
							>
								<AccordionTitle style={{ textAlign: 'left', verticalAlign: 'middle' }}>
									<Icon
										name={!showOptionalFields ? 'sn-plus-circle' : 'sn-minus-circle2'}
										className="primary"
										style={{ fontSize: 24, marginRight: 8, verticalAlign: 'middle' }}
										/>
									<span style={{ fontSize: 16, verticalAlign: 'middle' }}>
										<FormattedMessage id={`general.${!showOptionalFields ? 'show' : 'hide'} options`} />
									</span>
								</AccordionTitle>
							</Accordion>
						</GridColumn>
					</GridRow>

					{/* more options panel */}
					{showOptionalFields &&
						<React.Fragment>
							<GridRow style={{ padding: 0 }}>
								<GridColumn computer={16}>
									<Divider style={{ marginTop: 0 }} />
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn
									computer={6}
									style={{ textAlign: 'center' }}
								>
									{/* page thumbnail */}
									<OptionLabel
										label={intl.formatMessage({ id: 'pages.page thumbnail selector title' })}
										labelIcon="sn-image"
										labelDescription={intl.formatMessage({ id: 'pages.page thumbnail selector description' })}
									>
										{/* TO DO: replace with Filepicker */}
										<Field
											name="thumbnail"
											selectionTitle="pages.page thumbnail selector label"
											fallBackImgSrc="https://storage.googleapis.com/svift-net-master-bucket/assets/img/sites/dummy-image.svg"
											buttonInfo="pages.thumbnail button info"
											clearSelectionDescription="pages.thumbnail clear selection description"
											intl={intl}
											component={fields.thumbnail.component}
											siteid={this.props.siteID}
											files={this.props.files}
											style={{ marginTop: 8 }}
										/>
									</OptionLabel>
								</GridColumn>
								
								<GridColumn
									computer={10}
									style={{ textAlign: 'left' }}
								>
									{/* navigation title input */}
									<OptionLabel
										label={intl.formatMessage({ id: 'pages.page navigation title' })}
										labelIcon="sn-direction"
										labelDescription={intl.formatMessage({ id: 'pages.page navigation title description' })}
									>
										<Field
											fluid
											icon="sn-direction"
											iconPosition="left"
											placeholder={intl.formatMessage({ id: 'pages.page navigation title input placeholder' })}
											name="navigationTitle"
											intl={intl}
											component={fields.navigationTitle.component}
										/>
									</OptionLabel>

									{/* page description textarea */}
									<OptionLabel
										label={intl.formatMessage({ id: 'pages.page description' })}
										labelIcon="sn-quill2"
										labelDescription={intl.formatMessage({ id: 'pages.page description description' })}
									>
										<Field
											name="description"
											placeholde={intl.formatMessage({ id: 'pages.page description input placeholder' })}
											intl={intl}
											component={fields.description.component}
										/>
									</OptionLabel>

									{/* page keywords multiple select input	 */}
									<OptionLabel
										label={intl.formatMessage({ id: 'pages.keywords label' })}
										labelIcon="sn-price-tags"
										labelDescription={intl.formatMessage({ id: 'pages.keywords description' })}
									>
										<Field
											name="keywords"
											component={fields.keywords.component}
											intl={intl}
											placeholder={intl.formatMessage({ id: 'pages.keywords placeholder' })}
											noResultsMessage={intl.formatMessage({ id: 'pages.no keywords message' })}
										/>
										{/* {this.renderWarnKeywordUsedElsewhere()} */}
									</OptionLabel>

									{/* page author select */}
									<OptionLabel
										collapsed
										label={intl.formatMessage({ id: 'pages.page author' })}
										labelIcon="sn-user3"
										labelDescription={intl.formatMessage({ id: 'pages.page author description' })}
									>
										<Field
											name="author"
											intl={intl}
											format={null}
											values={this.props.users}
											component={fields.author.component}
										/>
									</OptionLabel>
								</GridColumn>
							</GridRow>
						</React.Fragment>
					}
				</Grid>

				{this.renderButtons()}
			</Form>
		);
	}
}

const ReduxPageForm = reduxForm({
	form: 'page'
})(injectIntl(PageForm));

ReduxPageForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(ReduxPageForm);
