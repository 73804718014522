import React from 'react';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    // const defaultValue = props.preset.letterSpacing;
    // const valueIsNumber = !isNaN(props.value);
    // const valueIsNotDefault = valueIsNumber && props.value !== props.preset.letterSpacing;

    let text = props.intl.formatMessage({ id: 'options.letter spacing title' });

    // if (valueIsNotDefault) {
    //     text += `: ${props.computedStyles.letterSpacing}px`;
    // } else {
    //     text += `: ${props.computedPreset.letterSpacing}px`;
    // }

    return (
        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>
            <Label
                collapsed
                labelIcon="sn-arrow-resize3"
                label={text}
                fontSize={12}
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <NumberPicker 
                    disableUndoRedo
                    name={text}
                    unit="px"
                    min={0}
                    max={50}
                    tooltipPosition="right center"
                    // placeholder={defaultValue}
                    value={props.inlineStyling}
                    onChange={props.onChange}					
                />
            </Label>
        </div>
    );
});
