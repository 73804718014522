import React from 'react';
// import { Router, Route, browserHistory } from 'react-router';

// import history from 'utils/routing/browserHistory';
import { Route, Switch, withRouter } from 'react-router-dom';

// import Root from 'containers/Root';
import Authorization from 'containers/authorize/Authorization';
import ForgotPassword from 'containers/authorize/forgot-password/ForgotPassword';

import Dashboard from 'containers/dashboard';

// import Pages from 'containers/dashboard/pages';
import Pages from 'containers/dashboard/pages-new';
import Settings from 'containers/dashboard/settings';

import Design from 'containers/dashboard/design/Design';

import Blog from 'containers/dashboard/blog-new';
// import Blog from 'containers/dashboard/blog-new';
import Files from 'containers/dashboard/files';
import Presets from 'containers/dashboard/design/elements/Presets';
// import Layouts from 'containers/dashboard/design/layouts/Layouts';
import Layouts from 'containers/dashboard/design/layouts-new';
import Forms from 'containers/dashboard/design/forms-new';
// import Example from 'containers/dashboard/example';
import Navigation from 'containers/dashboard/design/navigation/Navigation';
import DesignIndex from 'containers/dashboard/design/design-index/DesignIndex';
import Activation from 'containers/dashboard/activation';
import ActivationCriticalError from 'containers/dashboard/activation/ActivationCriticalError';
//import ActivateSiteContainer from 'containers/ActivateSiteContainer';

import RegistrationNotification from 'containers/authorize/registration/content/Notification';

// import { ConnectedRouter } from 'connected-react-router';

// import RendererTest from 'containers/renderer-test/Renderer';

/*
	Should probably be rewritten slightly eventually; made this way to quickly convert from react router v3 to react router v4
*/
export default () => (
	<Switch>
		<Route
			path="/dashboard/:siteid?"
			render={props => (
				<Dashboard {...props}>
					<Switch>
						<Route path="/dashboard/:siteid/pages/:pageid?" component={Pages} />
						{/* Test routes */}
						{/* <Route path="/dashboard/:siteid/example" component={Example} />
						<Route path="/dashboard/:siteid/renderer" component={RendererTest} /> */}

						<Route path="/dashboard/:siteid/blog-posts" component={Blog} />
						<Route path="/dashboard/:siteid/files" component={Files} />
						<Route path="/dashboard/:siteid/settings" component={Settings} />
						<Route path="/dashboard/:siteid/activation/critical-error" component={ActivationCriticalError} />
						<Route path="/dashboard/:siteid/activation" component={Activation} />

						<Route path="/dashboard/:siteid/design/layouts/:layoutid?" component={Layouts} />
						<Route path="/dashboard/:siteid/design/forms/:formid?" component={Forms} />
						<Route path="/dashboard/:siteid/design/navigation" component={Navigation} />

						<Route
							path="/dashboard/:siteid/design"
							render={props => (
								<Design {...props}>
									<Switch>
										<Route
											exact
											path="/dashboard/:siteid/design/:themeid/elements"
											render={props => (
												<Presets {...props} preset={null} />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/text"
											render={props => (
												<Presets {...props} preset="text" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/image"
											render={props => (
												<Presets {...props} preset="image" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/button"
											render={props => (
												<Presets {...props} preset="button" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/column"
											render={props => (
												<Presets {...props} preset="column" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/row"
											render={props => (
												<Presets {...props} preset="row" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/section"
											render={props => (
												<Presets {...props} preset="grid" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/container"
											render={props => (
												<Presets {...props} preset="container" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/body"
											render={props => (
												<Presets {...props} preset="body" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/menu"
											render={props => (
												<Presets {...props} preset="menu" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/input"
											render={props => (
												<Presets {...props} preset="input" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/checkbox"
											render={props => (
												<Presets {...props} preset="checkbox" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/radio"
											render={props => (
												<Presets {...props} preset="radio" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/video"
											render={props => (
												<Presets {...props} preset="video" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/map"
											render={props => (
												<Presets {...props} preset="map" />
											)}
										/>
										<Route
											path="/dashboard/:siteid/design/:themeid/elements/iframe"
											render={props => (
												<Presets {...props} preset="iframe" />
											)}
										/>

										<Route
											path="/dashboard/:siteid/design/:themeid/elements/divider"
											render={props => (
												<Presets {...props} preset="divider" />
											)}
										/>

										{/*<Route path="/dashboard/:siteid/forms" component={Forms} />*/}

										<Route component={DesignIndex} />
									</Switch>
								</Design>
							)}
						/>
					</Switch>
				</Dashboard>
			)}
		/>

		<Route path="/register/success" render={() => <RegistrationNotification message="register.your account has been activated" loginLink />} />
		<Route path="/register/confirm" render={() => <RegistrationNotification message="register.a verification mail has been sent" />} />
		<Route path="/reset-password" component={ForgotPassword} />

		<Route path="/" component={Authorization} />

		<Route path="error" component={() => <h1>An error occurred - sorry! :-(</h1>} />

		<Route component={() => <h1>404 - Not found</h1>} />
	</Switch>
);

/*export default (
	<Router history={browserHistory}>
		<Route path="/" component={Root}>
			<Route path="authorize" component={Authorization} />

			<Route path="registration-info" component={RegistrationInfo} />

			<Route path="dashboard(/:siteid)" component={Dashboard}>
				<Route path="pages(/:pageid)" component={Pages} />

				<Route path="blog" component={Blog} />
				<Route path="files" component={Files} />
				<Route path="settings" component={Settings} />
				<Route path="statistics" component={Statistics} />

				<Route path="design/forms(/:layoutid)" component={Layouts} />

				<Route path="design" component={Design}>
					<Route path="elements" component={Presets} />
					<Route path="elements/text" component={Presets} preset="text" />
					<Route path="elements/image" component={Presets} preset="image" />
					<Route path="elements/button" component={Presets} preset="button" />
					<Route path="forms" component={Forms} />
					<Route path="menus" component={Menus} />
				</Route>
			</Route>
		</Route>
		<Route path="error" component={() => <h1>An error occurred - sorry! :-(</h1>} />
		<Route path="*" component={() => <h1>404 - Resource doesn't exist</h1>} />
	</Router>
);
*/