import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import BackgroundVideoOptions from 'components/editor-v2/editor-interface/options/common/background-video';
import Label from 'components/option-library/label';
import NumberPicker from 'components/option-library/primitives/input/number';
import { injectIntl } from 'react-intl';

export default injectIntl(memo(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.container}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                {/* <Label
                    labelIcon="sn-width"
                    label={intl.formatMessage({ id: 'options.layout container width type label' })}
                >
                    <Radio 
                        options={[
                                {
                                    value: 'px',
                                    label: 'px'
                                },
                                {
                                    value: '%',
                                    label: '%'
                                }
                        ]}
                        onChange={nextVal => onChange('widthType', nextVal, 'width type', false)}
                        value={node.props.widthType || 'px'}
                    />
                </Label> */}

                <Label
                    labelIcon="sn-width"
                    label={intl.formatMessage({ id: 'options.layout container width label' })}
                >   
                    {/* <NumberPicker
                        name={intl.formatMessage({ id: 'options.layout container width label' })}
                        placeholder={intl.formatMessage({ id: 'options.layout container width input placeholder' })}
                        unit={node.props.widthType}
                        min={0}
                        max={node.props.widthType === '%' ? 100 : 1400}
                        value={node.props.maxWidth}
                        onChange={val => onChange('maxWidth', val)}
                    /> */}

                    <NumberPicker
                        name={intl.formatMessage({ id: 'options.layout container width label' })}
                        placeholder={intl.formatMessage({ id: 'options.layout container width input placeholder' })}
                        unit={node.props.widthType}
                        units={['px', '%']}
                        onUnitChange={newUnit => onChange('widthType', newUnit, 'width type', false)}
                        min={0}
                        max={node.props.widthType === '%' ? 100 : 1400}
                        value={node.props.maxWidth}
                        onChange={val => onChange('maxWidth', val)}
                    />
                </Label>
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <BackgroundVideoOptions {...props} />
                <BackgroundOptions {...props} />
            </Accordion>
        </React.Fragment>
    );
}));