import { createSelector } from 'reselect';

const selectWebsafeFonts = (state) => state.system.fonts.websafe;
const selectGoogleFonts = (state) => state.system.fonts.google;

const selector = createSelector(
	selectWebsafeFonts,
	selectGoogleFonts,
	(websafeFonts = [], googleFonts = []) => {
		const formattedWebsafe = websafeFonts.map(font => {
			return {
				family: font.family,
				websafe: true,
				variants: font.variants
			};
		});

		const formattedGoogle = googleFonts.map(font => {
			return {
				family: font.family,
				websafe: false,
				variants: font.variants,
				files: font.files
			};
		});

		return formattedWebsafe.concat(formattedGoogle); //formattedFonts;
	}
);

export default selector;