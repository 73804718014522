import React, { memo } from 'react';
import { Input } from 'svift-ui';

export default memo(props => {
    const { 
        size,
        fluid,
        type,
        placeholder,
        value,
        disableUndoRedo,
        onChange,
        ...rest
    } = props;

    return (
        <Input
            {...rest}
            type={type || 'text'}
            size={size}
            fluid={fluid}
            placeholder={placeholder}
            value={value || ''}
            onKeyDown={e => {
                if (disableUndoRedo) {
                    if (e.ctrlKey) {
                        if (e.keyCode === 90 || e.keyCode === 89) {
                            e.preventDefault()
                        }
                    }
                }
            }}
            onChange={e => {
                onChange(e.target.value, e);
            }}
        />
    );
});