import React, { Component } from 'react';
import { Grid, GridRow, GridColumn, Popup, Icon, Header, Button } from 'svift-ui';
import avatarlist from './avatars';
import Avatar from './Avatar';
import { FormattedMessage } from 'react-intl';

export default class AvatarList extends Component {
	constructor() {
		super();

		this.state = {
			avatar: 'sn-avatar-male-5',
		};
	}

	setAvatar = name => {
		this.setState({ avatar: name });

		if (this.props.handleSetAvatar) this.props.handleSetAvatar(name);
	}

	render() {
		return (
			<Grid padded centered>
				<GridRow>
					<GridColumn computer={12} textAlign="center">
						<Popup
							trigger={
								<div>
									<p><FormattedMessage id="profile.user avatar" /></p>
									<Icon link name={this.state.avatar} style={{ fontSize: 80 }} className="primary" />
								</div>
							}
							content={
								<div className="picker-content medium-picker">
									<Grid columns={8}>
										{avatarlist.map(name => <Avatar onClick={this.setAvatar} key={name} name={name} />)}
									</Grid>
								</div>
							}
							on="click"
							hideOnScroll
							position={this.props.position || "bottom center"}
							className="svift-picker"
						/>

						{/*{
							!this.props.simple &&
							<Header as="h3" style={{ marginTop: 0, marginBottom: 16 }}>
								<FormattedMessage id="registration.first name" /> <FormattedMessage id="registration.last name" />
							</Header>
						}*/}
					</GridColumn>

					{!this.props.simple &&
						<GridColumn computer={12} textAlign="center">
							<Button circular primary icon="user" style={{ fontSize: 12, marginTop: 12, marginBottom: 4, backgroundColor: 'teal' }} />
							<p><FormattedMessage id="registration.user color" /></p>
						</GridColumn>
					}
				</GridRow>
			</Grid>
		);
	}
}