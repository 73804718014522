import React, { useState, useMemo, useRef } from 'react';
import AppNavigation from 'components/app-layout/app-navigation/AppNavigation';
import { Icon, Message, Loader } from 'svift-ui';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook';
import config from 'config';
import uuid from 'uuid/v4';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import useRendererData from 'svift-library/client/renderer/data/client/useRendererData';
import useRendererState from 'svift-library/client/renderer/useRendererState';
import PreviewContainer from 'components/editor-v2/editor-interface/preview/PreviewContainer';
import ToggleDraftAndLivePreview from 'components/editor-v2/editor-interface/preview/ToggleDraftAndLivePreview';
import OpenPreview from 'components/editor-v2/editor-interface/preview/OpenPreview';
import createExtensions from 'components/editor-v2/extend';
import { connect } from 'react-redux';
import SviftGA from 'utils/svift-ga';
import blogActions from 'store/actions/sites/blogs';
import SelectBlog from './SelectBlog';
// import EditBlog from './EditBlog';
import BlogSettings from './BlogSettings';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Popup, useTooltip } from 'svift-library/client/tooltip/Tooltip';
import Notification from 'components/hoc/notification';
import useDimensions from 'svift-library/client/renderer/useDimensions';
import { filesInActiveFolder } from 'store/selectors/sites/files';

const mapStateToProps = (state, ownProps) => {
    const blog = state.sites.blogs.blogs[ownProps.blogID];

    // console.log(blog, 'blogGG')
    
    return {
        files: filesInActiveFolder(state),
        blog
    };
};

const RendererData = connect(mapStateToProps)(props => {
    const [previewMode, setPreviewMode] = useState(null);

    const { data, forceRefetch, error, errorMessage, loading } = useRendererData({
        siteID: props.siteID, //'5a3cb2eee4f27b1f9c9953ad',
        contentID: props.blogID, //'5a3cb2eee4f27b1f9c9953ae',
        contentType: 'blog',
        isAdmin: true,
        host: config.coreDomain,
        basePath: '/sites/renderer',
        refetchOnValueChange: [props.files, props.blog]
    });

    const deviceRef = useRef();
    const device = useDimensions(deviceRef);

    if (loading) return (
        <Message size="massive" className="info" style={{ padding: '64px 24px 96px 24px', textAlign: 'center' }}>
            {/* loading message */}
            <div style={{ marginTop: 0, marginBottom: 40 }}>
                <div>
                    <Icon
                        name="sn-magazine"
                        style={{
                            margin: '0 0 24px 0',
                            fontSize: 96,
                            width: 96,
                            opacity: 0.5
                        }}
                    />
                </div>
                <span style={{ fontSize: 40 }}>
                    <FormattedMessage id="blog.loading post" />
                </span>
            </div>

            {/* load spinner */}
            <Loader size="massive" className="info" />
        </Message>
    );

    if (error) return errorMessage;

    return React.cloneElement(props.children, {
        data,
        device,
        deviceRef,
        forceRefetch,
        previewMode,
        setPreviewMode,
        ...props
    });
});

const mapDispatchToProps = dispatch => ({
	publishDraft: (siteid, blogid, nodeMap) => dispatch(blogActions.saveContent(siteid, blogid, nodeMap, true)),
	saveDraft: (siteid, blogid, nodeMap) => dispatch(blogActions.saveContent(siteid, blogid, nodeMap))
});

const BlogRenderer = Notification(connect(null, mapDispatchToProps)(props => {
    const { data, previewMode, createNotification, setPreviewMode, deviceRef, device, publishDraft, saveDraft, intl, siteID, blogID, forceRefetch } = props;
    const { extend, extendAll, renderAtRoot } = useMemo(() => {
        return createExtensions(previewMode);
    }, [previewMode]);

    // const { extend, extendAll, renderAtRoot } = useMemo(() => {
    //     return createExtensions(previewMode, { isBlogLayout: true });
    // }, [previewMode, isBlog])

    const contentProps = useMemo(() => {
        const props = {
            ...data,
            staticKeys: ['content'],
            device,
            resolveContent: state => state.content.content,
            onSave: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await saveDraft(siteID, blogID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.BLOGS.DRAFT_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'blogs.save notification' }),
                    className: 'positive',
                    position: 'topCenter',
                    icon: 'sn-cloud-upload',
                    deleteTime: 2000
                });

                forceRefetch();

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            onPublish: async getState => {
                if (getState().locked) return;

                getState().updateState(() => ({
                    locked: true
                }), 'skip');

                const nextVersion = uuid();
                const nextNodeMap = getState().nodeMap;

                await publishDraft(siteID, blogID, {
                    breakpoints: {},
                    version: nextVersion,
                    nodeMap: nextNodeMap
                });

                SviftGA.BLOGS.LIVE_CONTENT_MODIFIED();

                createNotification({
                    content: intl.formatMessage({ id: 'blogs.publish notification' }),
                    className: 'warning',
                    position: 'topCenter',
                    icon: 'sn-sphere',
                    deleteTime: 2000
                });

                forceRefetch();

                getState().updateState(state => ({
                    ...state,
                    hasChanged: false,
                    locked: false,
                    content: {
                        ...state.content,
                        content: {
                            ...state.content.content,
                            draft: {
                                ...state.content.content.draft,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            },
                            live: {
                                ...state.content.content.live,
                                nodeMap: nextNodeMap,
                                version: nextVersion
                            }
                        }
                    }
                }), 'skip', false, true);
            },
            extend
        };

        if (previewMode === null) {
            props.extend = {
                ...extend,
                all: extendAll,
            };
        }

        if (previewMode === 'draft' || previewMode === null) {
            props.useDraft = true;
        }

        return props;
    }, [data, previewMode, device]);

    const contentState = useRendererState(contentProps);

    const resolvePlaceholder = useMemo(() => { 
        return () => (
            <Renderer 
                {...contentState}
                renderAtRoot={renderAtRoot}
            />
        );
    }, [contentState]);
    
    const layoutProps = useMemo(() => {
        return {
            ...data,
            extend,
            device,
            shouldSwapNodeMap: (state, newProps) => {
                if (newProps.layout !== state.layout) return true;
            },
            resolveContent: state => state.layout.content,
            resolvePlaceholder
        };
    }, [data, resolvePlaceholder, device]);

    const layoutState = useRendererState(layoutProps);

    const renderPreview = useMemo(() => {
        return () => (
            <Renderer
                key={previewMode}
                {...{
                    ...layoutState,
                    rendererState: {
                        ...layoutState.rendererState,
                        resolvePlaceholder: () => {
                            const { all, ...rest } = contentState.rendererState.extend;

                            if (previewMode === 'live') {
                                return (
                                    <Renderer 
                                        {...{
                                            ...contentState,
                                            rendererState: {
                                                ...contentState.rendererState,
                                                nodeMap: contentState.rendererState.content.content.live.nodeMap,
                                                breakpoints: contentState.rendererState.content.content.live.breakpoints,
                                                extend: rest
                                            }
                                        }}
                                    />
                                );
                            }

                            return (
                                <Renderer 
                                    {...{
                                        ...contentState,
                                        rendererState: {
                                            ...contentState.rendererState,
                                            extend: rest
                                        }
                                    }}
                                />
                            )
                        }
                    }
                }}
            />
        );
    }, [contentState, previewMode]);

    const postTitle = data.content.title;
    const createPostPopup = useTooltip();
    const closePreviewPopup = useTooltip();
    const postSettingsPopup = useTooltip();

    return (
        <PreviewContainer active={previewMode} ref={deviceRef}>
            <Popup
                target={createPostPopup.current}
                disabled={previewMode !== null}
                position="bottom center"
                zIndex={997}
            >
                <FormattedMessage id="blog.create post button" />
            </Popup>
            <Popup
                target={closePreviewPopup.current}
                disabled={previewMode === null}
                position="bottom center"
                zIndex={999999} // above preview container
            >
                <FormattedMessage id="general.close preview popup" />
            </Popup>

            <AppNavigation
                previewMode={previewMode}
                appIcon={previewMode === null ? 'sn-magazine' : 'sn-eye'}
                appTitle={intl.formatMessage({ id: `${previewMode === null ? 'blog.app title' : 'general.preview title'}`})}
                appActionClassName={previewMode === null ? 'positive' : 'info'}
                appActionStyle={{ padding: 0 }} // fix to facilitate the alternative rendering method of create new post button (padding is set on each button)
                appAction={
                    previewMode === null
                        ?
                            // create new post button
                            <BlogSettings
                                renderButton={toggleFormOpen => {
                                    return (
                                        <div
                                            ref={createPostPopup}
                                            id="blog-new-post-button"
                                            onClick={() => toggleFormOpen('create')}
                                            style={{ padding: 6 }}
                                        >
                                            <Icon name="sn-plus3" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                            <span style={{ verticalAlign: 'middle' }}>
                                                <FormattedMessage id="blog.new" />
                                            </span>
                                            <Icon name="sn-magazine" style={{ fontSize: 16, height: 16, marginLeft: 4, marginRight: 0, verticalAlign: 'middle' }} />
                                        </div>
                                    );
                                }}
                            />
                        :
                            // close preview button
                            <div
                                ref={closePreviewPopup}
                                onClick={() => {setPreviewMode(null)}}
                                style={{ padding: 6 }}
                            >
                                <Icon name="sn-cross2" style={{ fontSize: 12, height: 12, marginRight: 4, verticalAlign: 'middle' }} />
                                {/* <span style={{ verticalAlign: 'middle' }}>
                                    <FormattedMessage id="general.close" />
                                </span> */}
                                <Icon name="sn-eye" style={{ fontSize: 16, height: 16, marginRight: 0, verticalAlign: 'middle' }} />
                            </div>
                }
            >
            <div className="left-panel">
                {previewMode === null
                    ?
                        <React.Fragment>
                            {/* post menu (edit mode) */}

                            <SelectBlog 
                                draftContent={contentState.rendererState.content.content.draft}
                                liveContent={contentState.rendererState.content.content.live}
                                hasChanged={contentState.rendererState.hasChanged}
                            />
                        </React.Fragment>
                    :
                        // active page title + draft/live (preview mode)
                        <React.Fragment>
                            {/* active post title */}
                            <Icon name="sn-magazine" className="primary-color" style={{ fontSize: 24, marginRight: 6, opacity: 0.6 }} />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginRight: 16
                                }}
                            >
                                {postTitle}
                            </span>
                            
                            {/* preview toggle */}
                            <ToggleDraftAndLivePreview
                                onChange={setPreviewMode}
                                hasLiveContent={!!contentState.rendererState.content.content.live}
                                value={previewMode}
                            />
                        </React.Fragment>
                }
                

                {/* <EditBlog /> */}
                {previewMode === null &&
                    <React.Fragment>
                        {/* post settings */}
                        <Popup
                            target={postSettingsPopup.current}
                            position="bottom center"
                            zIndex={997}
                        >
                            <FormattedMessage id="post.settings" />
                        </Popup>
                        <BlogSettings
                            renderButton={toggleFormOpen => {
                                return (
                                    <Icon
                                        link
                                        ref={postSettingsPopup}
                                        onClick={() => toggleFormOpen('update')}
                                        name="setting"
                                        id="blog-post-menu-settings"
                                        className="primary-color"
                                        style={{ fontSize: 24, marginLeft: 0, marginRight: 16 }}
                                    />
                                );
                            }}
                        />

                        {/* undo + redo */}
                        <PortalHook id="undo-redo-portal" />
                    </React.Fragment>
                }

            </div>
            
            <div className="right-panel">
                {/* device picker */}
                <PortalHook id="device-picker-portal" />

                {/* open preview + save and publish */}
                {previewMode === null && 
                    <React.Fragment>
                        <OpenPreview
                            onChange={setPreviewMode}
                            value={previewMode}
                            style={{ marginRight: 16 }}
                        />
                        <PortalHook id="save-or-publish-portal" />
                    </React.Fragment>
                }
            </div>
            </AppNavigation>

            {previewMode !== null 
                ?
                    renderPreview()
                :
                    <Renderer
                        {...layoutState}
                    />
            }
        </PreviewContainer>
    );
}));

export default injectIntl(props => {
    return (
        <RendererData {...props}>
            <BlogRenderer />
        </RendererData>
    );
});