import React, { PureComponent } from 'react';

export default class PresetContainer extends PureComponent {
	constructor() {
		super();

		this.state = {
			hover: false
		};
	}

	render() {
		const { selected, onClick, disabled, style } = this.props;

		if (disabled) return <div>{this.props.children}</div>;

		//const className = selected ? 'edit-frame-active' : (this.state.hover ? 'edit-frame-hover' : 'edit-frame');

		const className = selected ? 'preset-target active-preset-target' : 'preset-target'; //(this.state.hover ? 'edit-frame-hover' : 'preset-target');

		return (
			<div
				className={className}
				onMouseEnter={() => this.setState({ hover: true })}
				onMouseLeave={() => this.setState({ hover: false })}
				onClick={onClick}
				style={style}
			>
				{this.props.children}
			</div>
		);
	}
}