import React, { Component } from 'react';
import { 
    Form,
    Button,
    Icon,
    Message,
    Grid,
    GridRow,
    GridColumn,
    Header,
    Divider
} from 'svift-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import input from 'components/forms/__util/field-components/input';
import textarea from 'components/forms/__util/field-components/textarea';
import validate, { string } from 'components/forms/__util/validate';
import OptionLabel from 'components/option-library/label';

const fields = {
	subject: {
		component: input({
			// name: 'support.contact support subject label',
			placeholder: 'support.contact support subject placeholder',
			icon: 'sn-bubble-dots4',
			type: 'text',
			size: 'huge'
		}),
		validate: string(1, 255)
    },
	question: {
		component: textarea({
			// name: 'support.contact support question label',
			placeholder: 'support.contact support question placeholder'
		}),
		validate: string(1, 1000)
	}    
};

const validateFields = validate(fields);

class ContactSupportForm extends Component {
    constructor() {
        super();

        this.submit = this.submit.bind(this);
    }

    async submit(values) {
        validateFields(values);

        await this.props.onSubmit(values);         
    }

    render(){
        const {
            handleSubmit,
            intl
        } = this.props;

        return(
            <Form onSubmit={handleSubmit(this.submit)}>
                <Grid padded centered>
                    <GridRow style={{ paddingBottom: 0 }}>
                        <GridColumn computer={16}>
                            <Header
                                as="h1"
                                icon="sn-question4"
                                content={intl.formatMessage({ id: 'support.contact support title' })}
                                className="light-text"
                            />
                            <Divider />
                            <div>
                                <FormattedMessage id="support.contact support description" />
                            </div>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn computer={16}>
                            {/* support subject input */}
                            <OptionLabel
                                label={intl.formatMessage({ id: 'support.contact support subject label' })}
                                labelDescription={intl.formatMessage({ id: 'support.contact support subject description' })}
                                labelIcon="sn-bubble-notification2"
                            >
                                <Field
                                    name="subject"
                                    fluid
                                    intl={intl}
                                    component={fields.subject.component}
                                />
                                <Message
                                    className="info"
                                    style={{ padding: 8, fontSize: 14 }}
                                >
                                    <FormattedMessage id="support.contact support message" />
                                </Message>
                            </OptionLabel>

                            {/* support subject input */}
                            <OptionLabel
                                label={intl.formatMessage({ id: 'support.contact support question label' })}
                                labelDescription={intl.formatMessage({ id: 'support.contact support question description' })}
                                labelIcon="sn-quill2"
                            >
                                <Field
                                    name="question"
                                    intl={intl}
                                    component={fields.question.component}
                                    style={{ fontSize: 20 }}
                                />
                            </OptionLabel>
                            {this.props.error &&
                                <Message negative>
                                    <FormattedMessage id={this.props.error} />
                                </Message>
                            }
                        </GridColumn>
                    </GridRow>
                </Grid>
                
                <div style={{ textAlign: 'center' }}>
                    <Button type="submit" size="huge" className="positive">
                        <Icon name="send" style={{ marginRight: 8 }} />
                        <FormattedMessage id="support.contact support button" />
                    </Button>
                </div>
            </Form>
        );
    }
}

const ReduxContactSupportForm = reduxForm({
	form: 'contactSupport'
})(injectIntl(ContactSupportForm));

ReduxContactSupportForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default injectIntl(ReduxContactSupportForm);