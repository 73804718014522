import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button, Icon } from 'svift-ui';
import { setRoute } from 'utils/routing';

export default class ShortcutSectionItem extends Component {
	render() {
		const { path, match, icon, name, changeRoute } = this.props;

		const isActive = match !== null;

		// const appNameStyle = {
		// 	marginLeft: 4,
		// 	fontSize: 12,
		// 	color: 'white',
		// 	fontWeight: 300
		// };
		
		return (
			<Popup
				trigger={
					<Button
						icon
						onClick={() => match === null && setRoute(path)}
						className={isActive ? 'active-app' : ''}
					>
						{/* if active => display name */}
						<Icon name={icon} />
						{/* {isActive ? <span style={appNameStyle}>{name}</span> : ''} */}
					</Button>
				}
				content={name}
				position="bottom center"
			/>
		);
	}
}

ShortcutSectionItem.propTypes = {
	icon: PropTypes.string,
	name: PropTypes.string
};

ShortcutSectionItem.defaultProps = {
	icon: 'cube',
	name: 'App shortcut'
};