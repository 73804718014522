import constants from 'store/constants';
import createApi from 'api';

const actions = {
	fetchGoogleFonts: () => async (dispatch, getState) => {
		const { system } = getState();

		if (system.googleFonts) return;

		const api = createApi();

		const fonts = await api.fetchGoogleFonts();

		if (fonts && fonts.data && fonts.data.items) {
			dispatch({
				type: constants.SET_GOOGLE_FONTS,
				data: fonts.data.items
			});
		}
	}
};

export default actions;