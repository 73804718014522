import React from 'react';
import Body from './library/body/Body';
import LayoutContainer from './library/layout-container/LayoutContainer';
import Container from './library/container/Container';
import Grid from './library/grid/Grid';
import Row from './library/grid/row/Row';
import Column from './library/grid/row/column/Column';
import Placeholder from './library/placeholder/Placeholder';
import Button from './library/button/Button';
import IFrame from './library/iframe/IFrame';
import Feed from './library/feed/Feed';
import Menu from './library/menu/Menu';
import Image from './library/image/Image';
import Text from './library/text/Editor';
import Follow from './library/some/follow/Follow';
import Share from './library/some/share/Share';
// import { mapContextToProps as VideoMapContextToProps } from './library/video/Video';
import SmartText from './library/smart-text/SmartText';
import Logo from './library/logo/Logo';
import Map from './library/map/Map';
import Form from './library/form/Form';
import Input from './library/form/input/Input';
import Checkbox from './library/form/checkbox/Checkbox';
import Radio from './library/form/radio/Radio';
import Missing from './library/Missing';
import Divider from './library/divider/Divider';
import Icon from './library/icon/Icon';

import shallowCompare from 'svift-library/client/renderer/nodes/shallowCompare';
import Loader from 'svift-library/client/loader'

const splitModule = (importComponent, mapContextToProps, CustomLoader) => {
    const Component = props => {
        const [_, setComponent] = React.useState(null);
        const LoadedComponent = React.useRef();

        React.useEffect(() => {
            importComponent().then(loadedComponent => {
                LoadedComponent.current = loadedComponent.default;

                setComponent('ready');
            })
        }, []);

        if (!LoadedComponent.current) return CustomLoader ? <CustomLoader /> : <Loader />;
    
        return <LoadedComponent.current {...props} />;
    }

    Component.mapContextToProps = mapContextToProps;

    return Component;
}

const components = {
    Grid,
    Body,
    LayoutContainer,
    Row,
    Column,
    Container,
    Placeholder,
    Button,
    IFrame,
    Feed,
    Logo,
    Icon,
    Menu,
    Image,
    Text,
    Follow,
    Share,
    SmartText,
    Map,
    Form,
    FormTextArea: Input,
    FormCheckbox: Checkbox,
    FormInput: Input,
    Divider,
    FormRadio: Radio,
    Gallery: splitModule(
        () => import(/* webpackChunkName: "renderer-element-gallery" */ './library/gallery/Gallery'),
        (props, state) => {
            const { files, extend, theme } = props.context;
        
            return shallowCompare({
                files,
                theme,
                galleryStyling: extend.Gallery && extend.Gallery.styling
            }, state);
        },
        () => (
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '56.25%'
                }}
            >
                <div
					style={{
						position: 'absolute',
                        top: 0,
                        bottom: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
					}}
				>
                    <Loader />
                </div>
            </div>
        )
    ),
    Video: splitModule(
        () => import(/* webpackChunkName: "renderer-element-video" */ './library/video/Video'),
        (props, state) => {
            const { files, site, extend, theme } = props.context;
        
            return shallowCompare({
                files,
                site,
                theme,
                playerStyling: extend.Video.playerStyling
            }, state);
        },
        () => (
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '56.25%'
                }}
            >
                <div
					style={{
						position: 'absolute',
                        top: 0,
                        bottom: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
					}}
				>
                    <Loader />
                    {/* LOAD LOAD LOAD SPIN SPIN SPIN */}
                </div>
            </div>
        )
    )
};

export default componentName => {
    const component = components[componentName];

    if (!component) return console.warn(componentName, 'component missing') || Missing;

    return component;
}