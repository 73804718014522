import React, { PureComponent } from 'react';
import {connect} from 'react-redux';
import { GridColumn, Grid, GridRow, Button } from 'svift-ui';
// import Tooltip, { tooltip } from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import Slider from 'components/option-panel/options/Slider';
import { injectIntl, FormattedMessage } from 'react-intl';
import InfoIcon from 'components/InfoIcon';
import { computePreset } from 'components/preset/util';
import Tooltip from 'svift-library/client/tooltip';

// https://github.com/facebook/react/issues/2231 - NO OVERLAPPING STYLES ALLOWED
class MarginOption extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			selectedOption: null
		};

		// this.tooltip = tooltip.call(this);
		this.tooltipRef = React.createRef();

		this.selectOption = this.selectOption.bind(this);
	}

	onChange(key, newValue) {
		this.props.onChange(key, newValue);
	}

	componentWillReceiveProps(nextProps) {
		// Resets the open tooltips whenever this component gets selected or unselected
		if (this.props.selectedOption !== nextProps.selectedOption) {
			this.selectOption(null);
		}
	}

	selectOption(option) {
		this.setState({
			selectedOption: option
		});
	}

	render() {
		const { option, onToggle, selectedOption, value, min = 1, max = 350, intl, theme, preset } = this.props;

		const selected = option === selectedOption; // Not to be confused with selectedOption in state

		const { marginBottom, generalMargin, marginLeft, marginRight, marginTop } = value || {};
		const computedStyles = computePreset(preset, theme)

		return (
			<div className="option" style={{ display: 'inline-block' }} >
				{/* <Tooltip
					target={this.tooltip.getTarget()}
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation();

						if (this.state.selectOption !== null) {
							this.selectOption(null);
						}
					}}
					open={selected}
					position="top"
					content={

					}
					hover={this.state.hover}
					hoverContent={intl.formatMessage({ id: 'options.margin title' })}
					style={{ zIndex: 999 }}
				/> */}

				<Tooltip
					popup
					open={selected}
					target={this.tooltipRef.current}
					zIndex="999"
				>
					<div className="manipulator tiny-panel">
						<p className="option-title" style={{ textAlign: 'center' }}><FormattedMessage id="options.margin title" /> <InfoIcon description={intl.formatMessage({ id: 'options.margin description' })} /></p>
						<Grid padded columns={3} centered verticalAlign="middle" className="margin-options">
							<GridRow>
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.generalMargin}
										onClear={() => {
											if (typeof generalMargin === 'undefined') return;

											this.props.onChange('generalMargin', null);
										}}
										optionalDisabled={typeof generalMargin === 'undefined'}
										activeButton={typeof generalMargin !== 'undefined'}

										icons={['move']}
										title={`${intl.formatMessage({ id: 'options.margin general title' })}${typeof generalMargin === 'undefined' ? '' : `: ${Math.floor(generalMargin * 100)}%`} 
												${(computedStyles.marginGeneral === '0px' || typeof computedStyles.marginGeneral === 'undefined' )? '': `(${computedStyles.marginGeneral})`}`}
										onChange={newValue => {
											this.props.onChange('generalMargin', newValue);
										}}
										value={generalMargin * 100 || 50}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="marginGeneral"
									/>
								</GridColumn>
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.marginTop}
										onClear={() => {
											if (typeof marginTop === 'undefined') return;

											this.props.onChange('marginTop', null);
										}}
										optionalDisabled={typeof marginTop === 'undefined'}
										activeButton={typeof marginTop !== 'undefined'}

										icons={['sn-arrow-up8']}
										title={`${intl.formatMessage({ id: 'options.margin top title' })} ${typeof marginTop === 'undefined' ? '' : `: ${Math.floor(marginTop * 100)}%`} 
												${(computedStyles.marginTop === '0px' || typeof computedStyles.marginTop === 'undefined')? '': `(${computedStyles.marginTop})`}`}
										onChange={newValue => {
											this.props.onChange('marginTop', newValue);
										}}
										value={marginTop * 100 || 50}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="marginTop"
									/>
								</GridColumn>
								<GridColumn textAlign="center">
								</GridColumn>
							</GridRow>

							<GridRow>
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.marginLeft}
										onClear={() => {
											if (typeof marginLeft === 'undefined') return;

											this.props.onChange('marginLeft', null);
										}}
										optionalDisabled={typeof marginLeft === 'undefined'}
										activeButton={typeof marginLeft !== 'undefined'}

										icons={['sn-arrow-left8']}
										title={`${intl.formatMessage({ id: 'options.margin left title' })} ${typeof marginLeft === 'undefined' ? '' : `: ${Math.floor(marginLeft * 100)}%`} 
												${(computedStyles.marginLeft === '0px' || typeof computedStyles.marginLeft === 'undefined')? '': `(${computedStyles.marginLeft})`}`}
										onChange={newValue => {
											this.props.onChange('marginLeft', newValue);
										}}

										value={marginLeft * 100 || 50}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="marginLeft"
									/>
								</GridColumn>
								<GridColumn textAlign="center">
									<div className="center-box" />
								</GridColumn>
								<GridColumn textAlign="center">
									<Slider
										optIn={this.props.optIn.marginRight}
										onClear={() => {
											if (typeof marginRight === 'undefined') return;

											this.props.onChange('marginRight', null);
										}}
										optionalDisabled={typeof marginRight === 'undefined'}
										activeButton={typeof marginRight !== 'undefined'}

										icons={['sn-arrow-right8']}
										title={`${intl.formatMessage({ id: 'options.margin right title' })} ${typeof marginRight === 'undefined' ? '' : `: ${Math.floor(marginRight * 100)}%`} 
												${(computedStyles.marginRight === '0px' || typeof computedStyles.marginRight === 'undefined' )? '': `(${computedStyles.marginRight})`}`}
										onChange={newValue => {
											this.props.onChange('marginRight', newValue);
										}}

										value={marginRight * 100 || 50}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="marginRight"
									/>
								</GridColumn>
							</GridRow>

							<GridRow>
								<GridColumn />
								<GridColumn textAlign="center">
									<Slider
										// optIn={this.props.optIn.marginBottom}
										// onClear={() => {
										// 	if (typeof marginBottom === 'undefined') return;

										// 	this.props.onChange('marginBottom', null);
										// }}

										activeButton={typeof marginBottom !== 'undefined'}
										icons={['sn-arrow-down8']}
										title={`${intl.formatMessage({ id: 'options.margin bottom title' })}${typeof marginBottom === 'undefined' ? '' : `: ${Math.floor(marginBottom * 100)}%`} 
												${(computedStyles.marginBottom === '0px' || typeof computedStyles.marginBottom === 'undefined' )? '': `(${computedStyles.marginBottom})`}`}
																	
										onChange={newValue => {
											this.props.onChange('marginBottom', newValue);
										}}

										value={marginBottom * 100}
										min={min}
										max={max}

										selectedOption={this.state.selectedOption}
										onToggle={(option, e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();

											this.selectOption(option);
										}}
										option="marginBottom"
									/>
								</GridColumn>
								<GridColumn />
							</GridRow>

						</Grid>
					</div>
				</Tooltip>

				<Button
					ref={this.tooltipRef}//.setTarget}
					basic
					size="tiny"
					icon="sn-enlarge3"
					className={`primary ${value && Object.keys(value).length > 0 ? 'active-button' : ''}`}
					onClick={(e) => onToggle(selected ? null : option, e)}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				/>
			</div>
		);
	}
}

MarginOption.propTypes = {
	option: PropTypes.string,
	selectedOption: PropTypes.string,
	onToggle: PropTypes.func,
	optIn: PropTypes.object
};

MarginOption.defaultProps = {
	optIn: {
		marginBottom: true,
		marginTop: true,
		marginLeft: true,
		marginRight: true,
		generalMargin: true
	}
};

export default (injectIntl(MarginOption));