import React, { PureComponent } from 'react';
import {
    Message,
	Dropdown
} from 'svift-ui';
import InfoIcon from 'components/InfoIcon';

class MultipleSelect extends PureComponent {
	constructor(props) {
		super(props);

		const value = props.input.value || [];

		let options = value.map(contact => ({
			text: contact,
			value: contact
		}));

		if (props.options) {
			options = options.concat(props.options.map(option => ({
				text: option,
				value: option
			})));
		}

		this.state = {
			options
		};
	}

	handleAddition = (e, { value }) => {
		this.setState({
			options: [
				{ text: value, value }, 
				...this.state.options
			],
		});
	}

	handleChange = (e, { value }) => {
		const {
			input
		} = this.props;

		input.onChange(value);
	}

	render() {
		const {
			input,
			meta: { error },
            intl,
            placeholder,
            noResultsMessage,
            labelText,
            labelDescription
		} = this.props;

		const content = [
			<React.Fragment key="label-tag">
				{(labelText || labelDescription) &&
					<label style={{ display: 'block', marginBottom: 8 }}>
						{labelText}
						{labelDescription && <InfoIcon description={labelDescription} />}
					</label>
				}
			</React.Fragment>,
			<Dropdown
				key="dropdown"
				options={this.state.options}
				placeholder={placeholder}
				noResultsMessage={noResultsMessage}
				search
				selection
				fluid
				multiple
				allowAdditions
				value={input.value}
				onAddItem={this.handleAddition}
				onChange={this.handleChange}
				style={{ marginBottom: 16 }}
			/>
		];

		if (typeof error === 'string') {
			content.push(
				<Message
					negative
					key="err"
				>
					{intl.formatMessage({ id: error })}
				</Message>
			);
		}

		return content;
	}
}

export default MultipleSelect;