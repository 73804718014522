import React, { PureComponent } from 'react';
import { Menu, MenuItem, Icon, List, ListItem, ListContent, Button } from 'svift-ui';
import Search from 'components/Search';
import css from './menu.scss';

export default class ListMenu extends PureComponent {
	constructor() {
		super();

		this.clickHandler = this.onClickedOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.clickHandler);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.clickHandler);
	}

	onClickedOutside(e) {
		const target = e.target;
		const rootNode = this.rootElement;

		if (this.props.onClose && !rootNode.contains(target)) {
			this.props.onClose();
		}
	}

	render() {
		const {
			title,
			children,
			modalText,
			onModalClick,
			icon,
			button,
			position,
			searchPlaceholder,
		} = this.props;

		let setPosition = "left-aligned";

		switch (position) {
			case "center": setPosition = "center-aligned";
				break;
			case "right": setPosition = "right-aligned";
				break;
			case "left": setPosition = "left-aligned";
				break;
			default: setPosition = "";
		}

		// default : left

		return (
			<div className={`svift-menu-content ${css['animated']} ${css['fadeIn']} ${css['faster']} ${setPosition}`} ref={el => this.rootElement = el}>
				<Menu size="mini">
					<MenuItem className="svift-menu-title">
					<Icon name={icon || "sn-sphere"} /> {title}
					</MenuItem>

					<Search
						searchPlaceholder={searchPlaceholder}
						dataSource={this.props.dataSource}
						handleFilter={this.props.handleFilter}
					/>

					{button}

					{(modalText && onModalClick) &&
						<MenuItem position="right">
							<Button onClick={onModalClick} size="mini" className="positive">
								<Icon name="plus" /> {modalText}
							</Button>
						</MenuItem>
					}
				</Menu>
				<List divided>
					{children}
{/*					{
						React.Children.map(children, (child) => {
							return (
								{child}
							);
						})
					}*/}
				</List>
			</div>
		);
	}
}