const youtube = (() => {
	const matchYoutubeID = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;

	return {
		extractIDFromString: someString => {
			const match = someString.match(matchYoutubeID);

            const id = match && match[1];

			if (id) return id;

			return null;
		}
	};
})();

export default youtube;
