import React, { PureComponent } from 'react';
import { createFilter } from 'react-search-input';
import {
	CardContent,
	CardGroup,
	Card,
	CardMeta,
	Icon,
	Image
} from 'svift-ui';
import PropTypes from 'prop-types';
import { Popup } from 'svift-library/client/tooltip/Tooltip';

export default class CardSelector extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			//selectedCards: {},
			searchTerm: null,
			cardRefs: this.createCardRefs(this.props.cards)
		};

		this.onSearchTermChange = e => {
			this.setState({
				searchTerm: e.target.value.length === 0 ? null : e.target.value
			});
		};

		this.selectCard = card => {
			const { onChange, value, required, multiple } = this.props;
			const { id } = card;

			const newSelectedCards = {
				...value
			};

			if (value[id]) { // Unselect if reselecting a Card (unless required is defined; in which case unselect is not possible)
				if (!required) {
					delete newSelectedCards[id];

					onChange(newSelectedCards, id);
				}
			} else if (multiple) { // Add Card to selectedCards if multiple allowed
				newSelectedCards[id] = card;

				onChange(newSelectedCards);
			} else { // Implicitly remove any already selected Cards
				// This is just weird... should fix later...
				onChange({
					[id]: card
				}, id);
			}

			// if (this.state.selectedCards[name]) { // Unselect if reselecting a Card
			// 	delete newSelectedCards[name];

			// 	this.setState({
			// 		selectedCards: newSelectedCards
			// 	});
			// } else if (this.props.multiple) { // Add Card to selectedCards if multiple allowed
			// 	newSelectedCards[name] = card;

			// 	this.setState({
			// 		selectedCards: newSelectedCards
			// 	});
			// } else { // Implicitly remove any already selected Cards
			// 	this.setState({
			// 		selectedCards: {
			// 			[name]: card
			// 		}
			// 	});
			// }
		};

		this.renderCard = this.renderCard.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.cards !== this.props.cards) {
			this.setState({
				cardRefs: this.createCardRefs(nextProps.cards)
			})
		}
	}

	createCardRefs = cards => {
		return cards.reduce((acc, card) => {
			acc[card.id] = React.createRef();
			
			return acc;
		}, {});
	}

	renderCard(card) {
		const isSelected = this.props.value && this.props.value[card.id] || this.props.value === card.id;

		if (this.props.renderCard) {
			return this.props.renderCard({ card, onSelect: this.selectCard });
		}

		return (
			<React.Fragment>
				<Popup
					target={this.state.cardRefs[card.id].current}
					position="top center"
					zIndex={1999}
					open={this.state.openCardPopup === card.id}
				>
					{card.name}
				</Popup>

				<Card
					key={card.id}
					ref={this.state.cardRefs[card.id]}
					onMouseEnter={e => this.setState({ openCardPopup: card.id })}
					onMouseLeave={e => this.setState({ openCardPopup: null })}
					className={isSelected ? 'primary active-card' : 'primary'}
					onClick={() => this.selectCard(card)}
				>
					{isSelected && <Icon name="checkmark" className="selected-icon" style={{ zIndex: 99 }} />}

					<div className="card-selector-content" style={card.style}>
						{card.icon &&
							<Icon name={card.icon} style={{ fontSize: 80, maxWidth: '100%' }} />
						}

						{(card.name && !card.noLabel) &&
							<p>{card.name}</p>
						}

						{card.imageUrl &&
							<Image src={card.imageUrl} alt={card.imageAlt || 'card'} />
						}

						{this.props.renderExtraCardContent && this.props.renderExtraCardContent(card)}
					</div>
				</Card>
			</React.Fragment>

		);
	}

	render() {
		const { cards, size } = this.props;
		const { searchTerm } = this.state;
		const filteredCards = searchTerm === null ? cards : Object.values(cards).filter(createFilter(searchTerm, ['name']));
		const className = `card-selector ${size}`;

		return (
			<CardGroup
				itemsPerRow={this.props.itemsPerRow}
				className={className}
				style={{ maxHeight: 480, overflow: 'auto' }}
			>
				{filteredCards.map(this.renderCard)}
			</CardGroup>
		);
	}
}

CardSelector.propTypes = {
	itemsPerRow: PropTypes.number,
	multiple: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.object,
	size: PropTypes.string,
	required: PropTypes.bool
};

CardSelector.defaultProps = {
	itemsPerRow: 3,
	multiple: false,
	required: false,
	cards: [],
	size: ''
};
