import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentType = 'BlogPublicationDate';

export default {
	name: resolveElementName(componentType),
	icon: resolveElementIcon(componentType),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: 'SmartText',
		id: uuid(),
		props: {
			type: componentType,
			preset: 'Normal',
			style: {}
		},
		width: 4
	})
};