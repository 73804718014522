import React, {	PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	Accordion,
	Button,
	AccordionTitle,
	AccordionContent,
	Card,
	Label,
	Icon,
	Grid,
	GridColumn,
	Message,
	Segment,
	SegmentGroup,
	Loader,
	Header,
	Divider,
	WizardControls,
	Popup
} from 'svift-ui';
import CardSelector from 'components/CardSelector';
import InfoIcon from 'components/InfoIcon';
import { injectIntl, FormattedMessage } from 'react-intl';

import actions from 'store/actions/domainWizard';
import DomainSearch from '../DomainSearch';

const mapStateToProps = (state) => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];

	const pageCount = site.pages.length;

	return {
		siteName: site.name,
		pageCount,
		siteid,
		subscriptionTypes: state.wizard.subscriptionTypes,
		selectedSubscription: state.wizard.selectedSubscription,
		subscriptionDetails: state.system.subscriptions,
		isCompany: state.wizard.isCompany,
		orgName: state.wizard.orgName,
		vatNumber: state.wizard.vatNumber,
		pickedDomain: state.domainsearch.pickedDomain,
		agreeTransferTerms: state.domainsearch.agreeTransferTerms
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSubscriptionTypes: data => dispatch(actions.setSubscriptionTypes(data)),
		setSubscription: id => dispatch(actions.setSubscription(id)),
	};
};

class CreateSiteDetails extends PureComponent {
	constructor() {
		super();

		this.state = {
			showSubscriptionDetails: false,
			errors: null
		};
	}

	componentDidMount() {
		if (this.props.subscriptionTypes.length === 0) {
			this.props.setSubscriptionTypes({ siteID: this.props.siteid });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.pickedDomain !== this.props.pickedDomain) {
			this.setState({
				errors: null
			});
		}
	}

	validate(onValidated) {
		const pickedDomain = this.props.pickedDomain;

		console.log(pickedDomain, 'picked domain');

		const errors = [];

		/* Validate data in the client first */
		if (Object.keys(pickedDomain).length === 0) {
			errors.push('activation.subscription domain error message');
		}

		if (pickedDomain.status === 'taken' && !this.props.agreeTransferTerms) {
			errors.push('activation.agree transfer terms error message');
		}

		const stepValidated = errors.length === 0;

		if (!stepValidated) {
			this.setState({
				errors
			});

			return;
		}

		onValidated();
	}

	render() {
		if (this.props.subscriptionTypes.length === 0) {
			return (
				<Loader size="huge" style={{ marginTop: 40, marginBottom: 64, fontSize: 32 }}>
					<Header as="h3">
						<FormattedMessage id="activation.subscription wizard step loading" />
					</Header>
				</Loader>
			);
		}

		const {
			free: freePlanDetails,
			light: lightPlanDetails,
			pro: proPlanDetails
		} = this.props.subscriptionDetails;

		const plans = this.props.subscriptionTypes;

		// Plans have to appear in a specific order!
		const planOrder = ['base-subscription-light-dkk', 'base-subscription-pro-dkk'];

		const formattedPlans = plans
			.sort((plan1, plan2) => {
				const indexPlan1 = planOrder.findIndex(planName => planName === plan1.id);
				const indexPlan2 = planOrder.findIndex(planName => planName === plan2.id);

				if (indexPlan1 > indexPlan2) return 1;

				return -1;
			})
			.map(plan => {
				const vatRate = 1.25;
				const amount = (plan.amount * vatRate) / 100;

				return {
					...plan,
					perMonthCost: Math.floor(amount / 12),
					perYearCost: amount
				};
			});

		// Free plan comes first
		formattedPlans.unshift({
			id: 'free-site',
			nickname: 'Free',
			perMonthCost: 0,
			perYearCost: 0
		});

		const activeSubscription = formattedPlans.find(plan => this.props.selectedSubscription === plan.id);

		const lightPlan = formattedPlans.find(plan => plan.id === 'base-subscription-light-dkk');
		const proPlan = formattedPlans.find(plan => plan.id === 'base-subscription-pro-dkk');

		//const activeSubscriptionDetails = activeSubscription && this.props.subscriptionDetails[activeSubscription.name.toLowerCase()];
		const tooManyPages = activeSubscription && activeSubscription.id !== 'base-subscription-pro-dkk' && this.props.pageCount > 1;

		const { siteName, intl } = this.props;

		return (
			<div style={{ color: '#7190d1' }} ref={ref => this.container = ref}>
				<Header as="h1" textAlign="center">
					<FormattedMessage id="activation.subscription wizard step header" />
				</Header>

				{/* <Grid centered>
					<GridColumn computer={8} mobile={16}>
						<p style={{ textAlign: 'left' }}>
							<FormattedMessage id="activation.subscription wizard step description" />
						</p>
					</GridColumn>
				</Grid> */}

				<Divider horizontal><Icon circular name="plug" style={{ opacity: 0.6, fontSize: 24 }} /></Divider>

				<Header as="h3">
					<FormattedMessage id="activation.subscription domain name title" />
				</Header>

				<Grid centered style={{ marginBottom: 16 }}>
					<GridColumn computer={8} mobile={16} textAlign="center">
						<p style={{ textAlign: 'left' }}>
							<FormattedMessage id="activation.subscription domain name description" />
						</p>
					</GridColumn>
				</Grid>

				<DomainSearch
					tldEnabled={!(activeSubscription && activeSubscription.nickname === 'Free')}
				/>

				<Divider horizontal><Icon circular name="sn-subscription-base" style={{ opacity: 0.6, fontSize: 32 }} /></Divider>

				<Header as="h2" style={{ marginBottom: 32 }}>
					<FormattedMessage id="activation.subscription wizard step select subscription label" />: <span style={{ fontWeight: 'bold' }}>{siteName}</span>
				</Header>

				<CardSelector
					itemsPerRow={3}
					size="large-cards"
					required
					cards={formattedPlans}
					value={activeSubscription || {}}
					onChange={subscription => this.props.setSubscription(Object.values(subscription)[0].id)}
					renderCard={({ card, onSelect }) => {
						const isSelected = activeSubscription === card; //this.state.selectedSubscription[card.id];

						const {
							perMonthCost
						} = card;

						switch (card.id) {
							case 'free-site': {
								const className = isSelected ? 'free-subscription active-card' : 'free-subscription';

								return (
									<Card
										key="free"
										className={className}
										style={{ opacity: 0.3 }} // disabled until supported
										//onClick={() => onSelect(card)}
									>
										{isSelected && <Icon name="checkmark" className="selected-icon" />}
										{isSelected && <Label circular basic className="free-color" size="large" style={{ position: 'absolute', top: 27, left: 58 }}><FormattedMessage id="general.selected" /></Label>}

										<div className="card-selector-content">
											<Icon name="sn-subscription-base" />

											<p><FormattedMessage id="general.free subscription title" /></p>
										</div>

										<Label basic className="free-color" size="massive">0 <span style={{ fontSize: 16, opacity: 0.8, fontWeight: 300 }}>DKK</span></Label>

										<Label className="free-subscription" size="large" style={{ lineHeight: 1.3, backgroundColor: 'transparent' }}>
											<FormattedMessage id="general.free subscription tagline" />
										</Label>
									</Card>
								);
							}

							case 'base-subscription-light-dkk': {
								const className = isSelected ? 'light-subscription active-card' : 'light-subscription';

								return (
									<Card
										key="base-sub-light"
										className={className}
										onClick={() => onSelect(card)}
									>
										{isSelected && <Icon name="checkmark" className="selected-icon" />}
										{isSelected && <Label circular basic className="light-color" size="large" style={{ position: 'absolute', top: 27, left: 58 }}><FormattedMessage id="general.selected" /></Label>}

										<div className="card-selector-content">
											<Icon name="sn-subscription-base" />
											<p><FormattedMessage id="general.light subscription title" /></p>
										</div>

										<Label basic className="light-color" size="massive">{perMonthCost.toLocaleString('dkk')} <span style={{ fontSize: 16, opacity: 0.8, marginRight: -52, fontWeight: 300 }}><FormattedMessage id="general.price per month" /> *</span></Label>

										<Label className="light-subscription" size="large" style={{ lineHeight: 1.3, backgroundColor: 'transparent' }}>
											<FormattedMessage id="general.light subscription tagline" />
										</Label>
									</Card>
								);
							}

							case 'base-subscription-pro-dkk': {
								const className = isSelected ? 'pro-subscription active-card' : 'pro-subscription';

								return (
									<Card
										key="base-sub-pro"
										className={className}
										onClick={() => onSelect(card)}
									>
										{isSelected && <Icon name="checkmark" className="selected-icon" />}
										{isSelected && <Label circular basic className="pro-color" size="large" style={{ position: 'absolute', top: 27, left: 58 }}><FormattedMessage id="general.selected" /></Label>}

										<div className="card-selector-content">
											<Icon name="sn-subscription-base" />
											<p><FormattedMessage id="general.pro subscription title" /></p>
										</div>

										<Label basic className="pro-color" size="massive">{perMonthCost.toLocaleString('dkk')} <span style={{ fontSize: 16, opacity: 0.8, marginRight: -52, fontWeight: 300 }}><FormattedMessage id="general.price per month" /> *</span></Label>

										<Label className="pro-subscription" size="large" style={{ lineHeight: 1.3, backgroundColor: 'transparent' }}>
											<FormattedMessage id="general.pro subscription tagline" />
										</Label>
									</Card>
								);
							}
						}
					}}
				/>

				<Grid divided columns={3} centered style={{ marginTop: 16, marginBottom: 8 }}>
					<GridColumn>
						<Message
							icon="sn-bullhorn"
							content={intl.formatMessage({ id: 'general.free subscription coming soon message' })}
							style={{ textAlign: 'left' }}
						/>
					</GridColumn>

					<GridColumn textAlign="center">
						<p style={{ textAlign: 'center', marginBottom: 8 }}>
							<FormattedMessage id="activation.yearly cost title" /> <FormattedMessage id="general.light subscription title" />:
						</p>

						<Label basic size="huge" className="light-color">{lightPlan.perYearCost.toLocaleString('dkk')} <FormattedMessage id="general.price per year" /> <span style={{ fontWeight: 300, opacity: 0.8 }}>**</span></Label>

					</GridColumn>

					<GridColumn textAlign="center">
						<p style={{ textAlign: 'center', marginBottom: 8 }}>
							<FormattedMessage id="activation.yearly cost title" /> <FormattedMessage id="general.pro subscription title" />:
						</p>
						<Label basic size="huge" className="pro-color">{proPlan.perYearCost.toLocaleString('dkk')} <FormattedMessage id="general.price per year" /> <span style={{ fontWeight: 300, opacity: 0.8 }}>**</span></Label>
					</GridColumn>
				</Grid>

				<Grid columns={3} centered>
					<GridColumn computer={16} textAlign="center" style={{ marginBottom: 16, paddingTop: 0 }}>

						{tooManyPages &&
							<Message
								icon="sn-warning"
								className="warning"
								size="large"
								header={intl.formatMessage({ id: 'activation.subscription wizard step message warning multiple pages header' })}
								content={
									<span>
										<FormattedMessage id="activation.subscription wizard step message warning multiple pages" />
										<InfoIcon description={intl.formatMessage({ id: 'activation.subscription wizard step message warning multiple pages description' })} />
									</span>
								}
								style={{ textAlign: 'left' }}
							/>
						}

						<Accordion
							style={{ marginTop: 16, fontSize: 18 }}
							onClick={() => {
								this.setState({
									showSubscriptionDetails: !this.state.showSubscriptionDetails
								});
							}}
						>
							<AccordionTitle className="active" style={{ fontSize: 24 }}>
								<Icon circular name={this.state.showSubscriptionDetails ? 'minus' : 'plus'} className="primary" style={{ fontSize: 20, marginRight: 8 }} />
								<FormattedMessage id={`activation.subscription wizard step ${this.state.showSubscriptionDetails ? 'hide' : 'show'} subscription details`} />
							</AccordionTitle>
						</Accordion>

						{this.state.showSubscriptionDetails &&
							<AccordionContent>
								<p>
									<FormattedMessage id="activation.subscription wizard step subscription details description" />
								</p>
								<Grid columns={3} centered>

									{/* FREE Subscription details */}
									<GridColumn>
										<SegmentGroup raised size="large" textAlign="left" className="free-subscription">
											<Segment textAlign="center" className="free-subscription">
												<FormattedMessage id="subscriptions.free subscription title" />
											</Segment>
											<Segment>
												<Icon name="sn-sphere" />
												<FormattedMessage id="subscriptions.free subscription detail domain name" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.free subscription detail domain name description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Popup
													trigger={
														<Icon name="sn-warning" style={{ opacity: 0.4, float: 'right' }} />
													}
													content={intl.formatMessage({ id: 'general.limitation popup' })}
													position="bottom center"
												/>
												<Icon name="sn-file-text" />
												<FormattedMessage id="subscriptions.free subscription detail pages" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.free subscription detail pages description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Icon name="users" />
												{freePlanDetails.users} <FormattedMessage id="general.details users" />
											</Segment>
											<Segment>
												<Icon name="sn-earth" />
												{freePlanDetails.languages} <FormattedMessage id="general.details languages" />
											</Segment>
											<Segment>
												<Icon name="database" />
												{freePlanDetails.storage} <FormattedMessage id="general.details gb storage" />
											</Segment>
											<Segment>
												<Icon name="star" />
												<Popup
													trigger={
														<Icon name="sn-warning" style={{ opacity: 0.4, float: 'right' }} />
													}
													content={intl.formatMessage({ id: 'general.limitation popup' })}
													position="bottom center"
												/>
												<FormattedMessage id="subscriptions.free subscription detail branding" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.free subscription detail branding description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Popup
													trigger={
														<Icon name="sn-warning" style={{ opacity: 0.4, float: 'right' }} />
													}
													content={intl.formatMessage({ id: 'general.limitation popup' })}
													position="bottom center"
												/>
												<Icon name="cubes" />
												<FormattedMessage id="subscriptions.free subscription detail addons" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.free subscription detail addons description' })} position="bottom center" />
											</Segment>
										</SegmentGroup>
									</GridColumn>

									{/* LIGHT Subscription details */}
									<GridColumn>
										<SegmentGroup raised size="large" textAlign="left" className="light-subscription">
											<Segment textAlign="center" className="light-subscription">
												<FormattedMessage id="subscriptions.light subscription title" />
											</Segment>
											<Segment>
												<Icon name="sn-sphere" />
												<FormattedMessage id="subscriptions.light subscription detail domain name" /> **
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.light subscription detail domain name description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Icon name="sn-file-text" />
												<Popup
													trigger={
														<Icon name="sn-warning" style={{ opacity: 0.4, float: 'right' }} />
													}
													content={intl.formatMessage({ id: 'general.limitation popup' })}
													position="bottom center"
												/>
												<FormattedMessage id="subscriptions.light subscription detail pages" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.light subscription detail pages description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Icon name="users" />
												{lightPlanDetails.users} <FormattedMessage id="general.details users" />
											</Segment>
											<Segment>
												<Icon name="sn-earth" />
												{lightPlanDetails.languages} <FormattedMessage id="general.details languages" />
											</Segment>
											<Segment>
												<Icon name="database" />
												{lightPlanDetails.storage} <FormattedMessage id="general.details gb storage" />
											</Segment>
											<Segment>
												<Icon name="empty star" />
												<FormattedMessage id="subscriptions.light subscription detail branding" />
											</Segment>
											<Segment>
												<Icon name="cubes" />
												<FormattedMessage id="subscriptions.light subscription detail addons" />
											</Segment>
											<Segment>
												<Icon name="google" />
												<FormattedMessage id="subscriptions.light subscription detail google integrations" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.light subscription detail google integrations description' })} position="bottom center" />
											</Segment>
										</SegmentGroup>
									</GridColumn>

									{/* PRO Subscription details */}
									<GridColumn>
										<SegmentGroup raised size="large" textAlign="left" className="pro-subscription">
											<Segment textAlign="center" className="pro-subscription">
												<FormattedMessage id="subscriptions.pro subscription title" />
											</Segment>
											<Segment>
												<Icon name="sn-sphere" />
												<FormattedMessage id="subscriptions.pro subscription detail domain name" /> **
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.pro subscription detail domain name description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Icon name="sn-stack-text" />
												<FormattedMessage id="subscriptions.pro subscription detail pages" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.pro subscription detail pages description' })} position="bottom center" />
											</Segment>
											<Segment>
												<Icon name="users" />
												{proPlanDetails.users} <FormattedMessage id="general.details users" />
											</Segment>
											<Segment>
												<Icon name="sn-earth" />
												{proPlanDetails.languages} <FormattedMessage id="general.details languages" />
											</Segment>
											<Segment>
												<Icon name="database" />
												{proPlanDetails.storage} <FormattedMessage id="general.details gb storage" />
											</Segment>
											<Segment>
												<Icon name="empty star" />
												<FormattedMessage id="subscriptions.pro subscription detail branding" />
											</Segment>
											<Segment>
												<Icon name="cubes" />
												<FormattedMessage id="subscriptions.pro subscription detail addons" />
											</Segment>
											<Segment>
												<Icon name="google" />
												<FormattedMessage id="subscriptions.pro subscription detail google integrations" />
												<InfoIcon description={intl.formatMessage({ id: 'subscriptions.pro subscription detail google integrations description' })} position="bottom center" />
											</Segment>
										</SegmentGroup>
									</GridColumn>

								</Grid>

								<p style={{ textAlign: 'center', marginTop: 32 }}><FormattedMessage id="activation.subscription wizard step more details label" /> <a href="http://svift.net/abonnementer" target="_blank"><FormattedMessage id="activation.subscription wizard step more details link" /> <Icon name="sn-new-tab" /></a></p>
							</AccordionContent>
						}
					</GridColumn>

					<GridColumn computer={16} mobile={16} textAlign="left" style={{ marginTop: 0, marginBottom: 40 }}>
						<p className="small-text">
							* <FormattedMessage id="general.price per month disclaimer" />
						</p>
						<p className="small-text">
							** <FormattedMessage id="general.subscription price disclaimer" />
						</p>
						<p className="small-text">
							+ <FormattedMessage id="general.all prices includes vat" />
						</p>
						<p className="small-text">
							+ <FormattedMessage id="general.independent dk domain owner disclaimer" />
							{/* <a>DK-Hostmaster <Icon name="sn-new-tab" /></a> */}
						</p>
						<p className="small-text">
							+ <FormattedMessage id="general.no mailserver included disclaimer" />
							{/* <a>Google G Suite <Icon name="sn-new-tab" /></a> */}
						</p>
					</GridColumn>
				</Grid>

				{this.state.errors &&
					<Message
						className="error"
						size="large"
						style={{ marginTop: 16, textAlign: 'left' }}
						icon="sn-warning"
						content={
							<FormattedMessage id={this.state.errors.join('')} />
						}
					/>
				}

				<WizardControls
					prevButton
					nextButton
					renderPrevButton={() => (
						<Button
							floated="left"
							size="huge"
							onClick={() => this.props.setStep(0)}
						>
							<Icon name="sn-arrow-left8" style={{ marginRight: 8 }} />
							<FormattedMessage id="general.back" />
						</Button>
					)}
					renderNextButton={() => (
						<Button
							floated="right"
							size="huge"
							className="primary"
							onClick={() => {
								this.validate(() => {
									this.props.setStep(2);
								});
							}}
						>
							<Icon name="sn-eye" style={{ marginRight: 8 }} />
							<FormattedMessage id="activation.subscription ready to order" />
							<Icon name="sn-arrow-right8" style={{ marginLeft: 8 }} />
						</Button>
					)}
					onPrev={() => this.props.setStep(0)}
					onNext={() => {
						this.validate(() => {
							this.props.setStep(2);
						});
					}}
					styles={{ marginTop: 48 }}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateSiteDetails));
