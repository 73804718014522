exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".svift-nested-stacked-submenu{width:100%;display:flex;height:auto;background:'yellow';padding:5}.svift-menu-group{display:flex;justify-content:center;height:auto}.svift-menu-group .svift-menu-parent,.svift-nested-stacked-submenu .svift-menu-parent{height:auto;border-top-right-radius:0 !important;border-bottom-right-radius:0 !important;border-right:none !important;margin:0 !important;flex-grow:1 !important}.svift-menu-group .svift-menu-submenu,.svift-nested-stacked-submenu .svift-menu-submenu{height:auto;border-top-left-radius:0 !important;border-bottom-left-radius:0 !important;border-left:none !important;margin:0 !important;padding-left:0.5em !important;padding-right:0.5em !important}.svift-menu-group .svift-menu-submenu .icon,.svift-nested-stacked-submenu .svift-menu-submenu .icon{margin:0 !important;height:auto !important}.ui.vertical.menu{width:auto !important}.ui.vertical.menu .svift-menu-group{flex:0 0 100% !important}.ui.vertical.menu .svift-menu-group a.item.svift-menu-parent{flex-grow:1}.menu__container--2Qngk{display:flex;justify-content:center;flex-wrap:wrap;flex-direction:row}.menu__container--2Qngk.menu__vertical--3ICZg{flex-direction:column}.menu__container--2Qngk.menu__vertical--3ICZg>*{width:100%}.menu__container--2Qngk>a{text-decoration:none;color:inherit}.menu__button--GkqhM{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;cursor:pointer}\n", ""]);

// exports
exports.locals = {
	"container": "menu__container--2Qngk",
	"vertical": "menu__vertical--3ICZg",
	"button": "menu__button--GkqhM"
};