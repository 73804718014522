import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Section from '../Section';
import Preset from 'components/editor-v2/editor-interface/options/common/preset';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import Label from 'components/option-library/label';
import { injectIntl } from 'react-intl';

export default memo(injectIntl(props => {
    const { editableNode: node, updateState } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, ...args) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, ...args);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    presets={props.theme.presets.text}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Normal'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label>
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="bold"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.bold text' })}
                            tooltipPosition="top center"
                            value={node.props.bold}
                            onChange={value => onChange('bold', value)}
                            />
                        <Toggle
                            icon="italic"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.italic text' })}
                            tooltipPosition="top center"
                            value={node.props.italic}
                            onChange={value => onChange('italic', value)}
                            />
                        <Toggle
                            icon="underline"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.underline text' })}
                            tooltipPosition="top center"
                            value={node.props.underline}
                            onChange={value => onChange('underline', value)}
                        />
                        <Toggle
                            icon="strikethrough"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.strikethrough text' })}
                            tooltipPosition="top center"
                            value={node.props.strikethrough}
                            onChange={value => onChange('strikethrough', value)}
                        />
                    </ToggleGroup>
                </Label>

                <Label
                    labelIcon="sn-arrow-resize3"
                    label={props.intl.formatMessage({ id: 'options.text alignment'})}
                >
                    <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                        <Toggle
                            icon="sn-paragraph-left3"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.text alignment left' })}
                            tooltipPosition="top center"
                            value={['center', 'flex-end'].indexOf(node.props.alignment) === -1}
                            onChange={() => onChange('alignment', 'flex-start', 'alignment text', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-center3"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.text alignment center' })}
                            tooltipPosition="top center"
                            value={node.props.alignment === 'center'}
                            onChange={() => onChange('alignment', 'center', 'alignment text', false)}
                        />
                        <Toggle
                            icon="sn-paragraph-right3"
                            tooltipLabel={props.intl.formatMessage({ id: 'options.text alignment right' })}
                            tooltipPosition="top center"
                            value={node.props.alignment === 'flex-end'}
                            onChange={() => onChange('alignment', 'flex-end', 'alignment text', false)}
                        />
                    </ToggleGroup>
                </Label>
            </Section>
        </React.Fragment>
    );
}));