exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".file__content-wrapper--3_Kf0{display:flex}.file__selected-files--2weMx{width:20%;padding:10px}.file__selected-files--2weMx>:first-child{position:sticky;top:80px}\n", ""]);

// exports
exports.locals = {
	"content-wrapper": "file__content-wrapper--3_Kf0",
	"selected-files": "file__selected-files--2weMx"
};