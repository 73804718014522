import React, { Component } from 'react';
import { Accordion, AccordionTitle, Icon, AccordionContent } from 'svift-ui';

class AccordionContentComponent extends Component {
    render() {
        const { title, children, icon, handleOpen, active } = this.props;

        // console.log(this.props, 'props accordion content')

        return (
            <Accordion styled style={{ width: '100%' }}>
                <AccordionTitle
                    id={this.props.id}
                    onClick={() => handleOpen()}
                    className={active && 'active-item'}
                    style={{ position: 'relative', paddingTop: 12, paddingBottom: 16 }}
                >
                    <Icon
                        circular
                        name={active ? 'minus' : 'plus'}
                        style={{ fontSize: 12, position: 'absolute', right: 8, top: '50%', marginTop: -12 }}
                    />
                    <Icon name={icon} circular style={{ marginRight: 4 }} />
                    {title}
                </AccordionTitle>
                <AccordionContent active={active}>
                    {children}
                </AccordionContent>
            </Accordion>
        );
    }
}

export default AccordionContentComponent;


