const createThrottle = speed => {
    let nextFn;
    let timeout;

    const run = () => {
        if (!timeout) {
            if (nextFn) {
                nextFn();
                
                nextFn = null;
            }

            timeout = setTimeout(() => {
                timeout = null;

                if (nextFn) {
                    nextFn();
                    
                    nextFn = null;
                    
                    run(); 
                } 
            }, speed);
        }
    };
    
    return {
        flush: (callQueuedFunction = true) => {
            if (nextFn && callQueuedFunction) nextFn();
            if (timeout) clearTimeout(timeout);

            timeout = null;
            nextFn = null;
        },
        queue: fn => {
            nextFn = fn;
            
            run();
        }
    };
}

export default createThrottle;