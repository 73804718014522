import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import css from './checkbox.scss';
import { Icon } from 'svift-ui'; // TO-DO: replace SUI

const CheckboxOptions = props => {
    const onChange = useCallback(() => {
        return props.onChange(!props.value);
    }, [props.onChange, props.value]);

    const [hovered, setHovered] = useState(false);

    let defaultIcon = 'sn-checkbox-unchecked2';
    // let hoverIcon = 'sn-checkbox-partial2';
    let activeIcon = 'sn-checkbox-checked2';

    if(props.radio) {
        defaultIcon = 'sn-radio-unchecked';
        // hoverIcon = 'sn-radio-checked';
        activeIcon = 'sn-radio-checked2';
    }

    return (
        <div
            onMouseEnter={e => setHovered(true)} 
            onMouseLeave={e => setHovered(false)} 
            onClick={() => onChange()}
            className={props.radio ? css['radio'] : css['checkbox']}
            style={props.style}
        >
            <Icon
                name={props.value ? activeIcon : defaultIcon}
                className={(hovered || props.value) ? 'primary-color' : 'shade-color'}
                style={{
                    verticalAlign: 'middle',
                    fontSize: 20,
                    height: 20,
                    marginRight: 6,
                    opacity: (hovered || props.value) ? 1 : 0.5,
                }}
            />

            <label
                style={{
                    fontSize: props.fontSize ? props.fontSize : 14,
                    verticalAlign: 'middle',
                    // fontWeight: (hovered || props.value) && 500,
                }}
            >
                <span style={{ verticalAlign: 'middle' }}>
                    {props.label}
                </span>
                
                {props.icon &&
                    <Icon name={props.icon} style={{ marginRight: 0, marginLeft: 4, verticalAlign: 'middle', opacity: 0.5 }} />
                }
            </label>
        </div>
    );
};

CheckboxOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
};

export default CheckboxOptions;