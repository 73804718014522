import React, { memo, useCallback } from 'react';
import {
    Icon,
    Message,
    Header as MessageHeader,
    Divider
} from 'svift-ui';
import optionConstellations from './constellations';
import css from './options.scss';
import Portal from 'components/editor-v2/editor-interface/Portal';
import Header from './Header';
import { FormattedMessage, injectIntl } from 'react-intl';

const ContextProvider = Component => props => {
    const { ContextConsumer } = props;

    return (
        <ContextConsumer>
            {context => {
                const { suspend, theme, forms, getState, pages, navigation, users, blogs, updateState } = context;
                const explicitProps = { suspend, theme, pages, forms, navigation, users, blogs, getState, updateState };
                const editableNode = context.nodeMap && context.nodeMap[context.editableNode];

                return (
                    <Component 
                        {...explicitProps}
                        editableNode={editableNode}
                        // setEditorState and editorState are "hacks" that allow us to lift DraftJS editor state into the side men
                        setEditorState={props.setEditorState}
                        editorState={props.editorState}
                    />
                );
            }}
        </ContextConsumer>
    );
};

export default ContextProvider(injectIntl(memo(props => {
    if (!props.editableNode) return null;
    if (props.editableNode.component === 'Text' && !props.editorState) return null; // Text options are rendered explicitly inside the Text node (can't lift text editor state without perf problems)

    const Options = optionConstellations[props.editableNode.component];

    const closeEditing = useCallback(e => {
        e.stopPropagation();
        
        props.updateState(context => ({
            ...context,
            selectedNode: props.editableNode.id,
            editableNode: null
        }));    
    }, []);

    if (!Options) return (
        <div className={css['option-frame']}>
            <div style={{ borderRadius: 4, margin: '0 16px', backgroundColor: 'white' }}>
                <Message className="secondary" style={{ padding: '24px 16px 32px 16px' }}>
                    <MessageHeader
                        as="h4"
                        icon="sn-quill4"
                        content={props.intl.formatMessage({ id: 'layouts.no options message title' })}
                        className="dark-warning-color"
                        style={{ fontSize: 20 }}
                    />
                    <Divider />

                    <p>
                        <FormattedMessage id="layouts.no options message description" />
                    </p>
                </Message>
            </div>
        </div>
    );

    return (
        <Portal target="editor-sidenav-portal">
            <div className={css['options-fallback-bg']}>
                <div className={`${css['option-frame']} ${css['animated']} ${css['fadeInRight']} ${css['fadeIn']} ${css['faster']}`}>
                    <Header close={closeEditing} component={props.editableNode.component} type={props.editableNode.props && props.editableNode.props.type}/> 

                    {process.env.NODE_ENV !== 'production' && <div onClick={() => console.log(props.editableNode)}>click me for node info</div>}
                    {/* {process.env.NODE_ENV !== 'production' && 
                        <div 
                            onClick={() => {
                                props.updateState(context => {
                                    const nodeMap = context.nodeMap;

                                    const convertToInlineChildren = (node) => {
                                        if (node.children && node.children.length > 0) {
                                            node.children = node.children.map(id => convertToInlineChildren({ ...nodeMap[id] }))
                                        }

                                        return node;
                                    }

                                    console.log(JSON.stringify(convertToInlineChildren({ ...props.editableNode })));

                                    return undefined; // not needed but nice reminder that we dont update any state here - this is purely for internal use in dev mode
                                }, 'skip');
                            }}
                        >
                            node + children as JSON
                        </div>
                    }

                    {/* sidenav background graphic */}
                    <div style={{ position: 'relative', width: '100%', height: 0, pointerEvents: 'none' }}>
                        <Icon circular name="sn-quill3" style={{ position: 'absolute', bottom: -40, right: -40, fontSize: 80, opacity: 0.15, color: 'white', boxShadow: '0 0 0 8px white', margin: 0 }} />
                    </div>

                    <Options 
                        {...props}
                    />
                </div>
            </div>
        </Portal>
    );
})));