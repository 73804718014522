const containsKey = (nodeMap, node, keys) => {
	if (keys.some(key => node.props[key])) {
        return true;
    }
	if (!node.children) return false;

	if (Array.isArray(node.children)) {
		return node.children.some((childObj) => containsKey(nodeMap, nodeMap[childObj], keys));
	}

	return false;
};

const cantBeDeleted = (nodeMap, nodeID) => {
    return containsKey(nodeMap, nodeMap[nodeID], [/*'locked',*/ 'undeletable', 'submitButton']);
};

export default cantBeDeleted;