import React, { Component } from 'react';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { setRoute } from 'utils/routing';
import { connect } from 'react-redux';
import css from './dashboard.scss';

import notification from 'components/hoc/notification';
import ModalWrapper from 'components/modal';
import ContactSupport from 'components/forms/contact-support';
import handleSubmitError from 'components/forms/__util/handleSubmitError';
import {
	Tile,
	Icon,
	Grid,
	GridRow,
	GridColumn,
	Message,
	Button,
	Header,
	Loader,
	Divider,
	Label,
	Box
} from 'svift-ui';
import AppContentSection from 'components/app-layout/app-content-section/AppContentSection';
import InfoIcon from 'components/InfoIcon';
import createApi from 'api';
import key from 'utils/key';

const api = createApi();

const mapStateToProps = (state) => {
	const siteID = state.sites.params.siteid;
	const site = state.sites.sites[siteID];

	return {
		blogActive: key('modules.blog')(site),
		siteid: siteID,
		pathname: state.router.location.pathname,
		roles: state.user.roles,
		contactModalOpen: false,
	};
};

class DashboardContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contactModalOpen: false
		};
	}

	// user role check
	getRoleOnSite({ roles, siteid }) {
		const siteScope = `sites/${siteid}`;

		let role = roles.find(({ scope }) => {
			return scope === siteScope;
		});

		if (!role) {
			role = roles.find(({ type }) => type === 'superadmin');
		}

		return role.type;
	}

	// contact support form modal
	contactModalOpen = () => {
		this.setState({ contactModalOpen: true});
	}

	handleModalClose = () => {
		this.setState({ FAQModalOpen: false, contactModalOpen: false });
	}

	onSubmit = ({ subject, question }) => {
		this.setState({
			submittingContactForm: true
		}, async () => {
			try {
				await api.submitContactForm({
					subject,
					question,
					siteID: this.props.siteID,
					siteName: this.props.siteName
				});

				this.setState({
					submittingContactForm: false,
					contactModalOpen: false
				});

				this.props.createNotification({
					content: this.props.intl.formatMessage({ id: 'support.contact support notification' }),
					className: 'positive',
					position: 'topCenter',
					icon: 'sn-question3',
					deleteTime: 5000
				});

			} catch(e) {
				this.setState({
					submittingContactForm: false
				});

				handleSubmitError(this.props.intl)(e);
			}
		});
	}

	render() {
		const { siteid, intl, roles } = this.props;

		const roleOnSite = this.getRoleOnSite({ roles, siteid });
		const newsTextStyle = { fontSize: 14, lineHeight: 1.4, marginBottom: 8 };

		return (
			<AppContentSection style={{ paddingTop: 24, maxWidth: 1200, margin: 'auto' }}>
				{/* main dashboard grid */}
				<Grid padded>
					<GridColumn computer={16} tablet={16} mobile={16}>
						{/* Dashboard grid */}
						<Box style={{ width: '100%', marginTop: 4 }}>
							<div className="box-icon-container">
								<Icon circular name="sn-sphere" className="box-icon light-primary-color" />
							</div>
							
							{/* dashboard buttons */}
							<Grid style={{ position: 'relative', paddingTop: 0, marginTop: 0, paddingBottom: 32, marginBottom: 0 }}>
								<GridRow>
									<GridColumn computer={16}>
										<Divider horizontal>
											<Header
												as="h2"
												id="dashboard-section-label"
												icon="sn-view_module"
												content={
													<span style={{ textAlign: 'center' }}>
														<FormattedMessage id="dashboard.site apps title" />
														<InfoIcon description={intl.formatMessage({ id: 'dashboard.app description' })} />
													</span>
												}
												className="medium-text dark-primary-color"
												style={{ fontSize: 28 }}
												/>
										</Divider>
									</GridColumn>
									
									{/* content app */}
									{['siteWriter', 'siteOwner', 'siteAdmin', 'siteDesigner', 'superadmin'].indexOf(roleOnSite) > -1 &&
										<GridColumn textAlign="left" computer={4} tablet={8} mobile={16} className={`${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
											<Tile
												id="pages-app-tile"
												className="app-button medium-tile"
												onClick={() => setRoute(`/dashboard/${siteid}/pages`)}
											>
												<Icon name="sn-stack-text" className="app-icon" />
												<InfoIcon
													description={intl.formatMessage({ id: 'pages.app description' })}
													position="bottom center"
													className="tint-color"
													style={{ position: 'absolute', bottom: 8, right: 6, margin: 0 }}
												/>
												<p>
													<FormattedMessage id="general.pages app title" />
												</p>
											</Tile>
										</GridColumn>
									}


									{/* design app */}
									{['siteOwner', 'siteAdmin', 'siteDesigner', 'superadmin'].indexOf(roleOnSite) > -1 &&
										<GridColumn textAlign="left" computer={4} tablet={8} mobile={16} className={`${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
											<Tile
												id="design-app-tile"
												className="app-button medium-tile"
												onClick={() => setRoute(`/dashboard/${siteid}/design`)}
											>
												<Icon name="sn-bucket" className="app-icon" />
												<InfoIcon
													description={intl.formatMessage({ id: 'design.app description' })}
													position="bottom center"
													className="tint-color"
													style={{ position: 'absolute', bottom: 8, right: 6, margin: 0 }}
												/>
												<p>
													<FormattedMessage id="general.design app title" />
												</p>
											</Tile>
										</GridColumn>
									}

									{/* files app */}
									<GridColumn textAlign="left" computer={4} tablet={8} mobile={16} className={`${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
										<Tile
											id="files-app-tile"
											className="app-button medium-tile"
											onClick={() => setRoute(`/dashboard/${siteid}/files`)}
											>
											<Icon name="sn-folder6" className="app-icon" />
											<InfoIcon
												description={intl.formatMessage({ id: 'files.app description' })}
												position="bottom center"
												className="tint-color"
												style={{ position: 'absolute', bottom: 8, right: 6, margin: 0 }}
											/>
											<p>
												<FormattedMessage id="general.files app title" />
											</p>
										</Tile>
									</GridColumn>

									{/* settings app */}
									<GridColumn textAlign="left" computer={4} tablet={8} mobile={16} className={`${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
										<Tile
											id="settings-app-tile"
											className="app-button medium-tile"
											onClick={() => setRoute(`/dashboard/${siteid}/settings`)}
											>
											<Icon name="sn-cog3" className="app-icon" />
											<InfoIcon
												description={intl.formatMessage({ id: 'settings.app description' })}
												position="bottom center"
												className="tint-color"
												style={{ position: 'absolute', bottom: 8, right: 6, margin: 0 }}
											/>
											<p>
												<FormattedMessage id="general.settings app title" />
											</p>
										</Tile>
									</GridColumn>

									{/* blog app */}
									{this.props.blogActive &&
										<GridColumn textAlign="left" computer={4} tablet={8} mobile={16} className={`${css['animated']} ${css['fadeIn']} ${css['slow']}`}>
											<Tile
												id="blogs-app-tile"
												className="app-button medium-tile"
												onClick={() => setRoute(`/dashboard/${siteid}/blog-posts`)}
											>
												<Icon name="sn-blog" className="app-icon" />
												<InfoIcon
													description={intl.formatMessage({ id: 'blog.app description' })}
													position="bottom center"
													className="tint-color"
													style={{ position: 'absolute', bottom: 8, right: 6, margin: 0 }}
												/>
												<p>
													<FormattedMessage id="blog.app title" />
												</p>
											</Tile>
										</GridColumn>
									}

									{/* TO DO: if admin + site-owner show: */}
									{/* <GridColumn textAlign="left" computer={4} tablet={8} mobile={16}>
										<Tile
											disabled
											id="add-new-app-tile"
											className="app-button info medium-tile"
											// TO DO: on click go to modules section under settings
											// onClick={() => setRoute(`/dashboard/${siteid}/settings`)}
										>
											<Icon name="sn-plus3" className="app-icon" />
											<p>
												Add
											</p>
										</Tile>
									</GridColumn> */}

									{/* user role label */}
									{/* <div
										style={{
											position: 'absolute',
											display: 'inline-flex',
											alignItems: 'center',
											bottom: 0,
											right: 16,
											zIndex: 2
										}}
									>
										<span style={{ marginRight: 8 }}>
											Your role on this site:
										</span>

										<Label
											basic
											circular
											size="tiny"
											className="primary"
											style={{
												display: 'inline-flex',
												alignItems: 'center'
											}}
										>
											<Icon
												name="sn-user3"
												className="light-primary-color"
												style={{ fontSize: 16, marginRight: 6 }}
											/>
											<span>Administrator</span>
										</Label>
									</div> */}

								</GridRow>

								{/* Shared site board */}
								{/* TO DO: display correct site name */}
								{/* <GridRow className="section-shade" style={{ paddingTop: 0 }}>
									<GridColumn computer={16}>
										<Divider horizontal style={{ marginTop: 16, fontSize: 20 }}>
											<div>
												<Icon name="sn-pushpin2" className="light-primary-color" style={{ verticalAlign: 'middle', fontSize: 24, opacity: 0.4, marginRight: 6 }} />
												<span className="dark-primary-color" style={{ fontWeight: 500, textTransform: 'none' }}>
													<FormattedMessage id="dashboard.shared board title" />
												</span>
												<InfoIcon description={intl.formatMessage({ id: 'dashboard.shared board message details' })} />
											</div>
										</Divider>
									</GridColumn>
									<GridColumn computer={16}>
										<Button
											disabled
											fluid
											size="massive"
											basic
											icon="plus"
											href="#"
										/>
									</GridColumn>
									
									<GridColumn computer={16}>
										<div style={{ textAlign: 'right', fontSize: 14, opacity: 0.6, marginTop: 16 }}>
											<Icon name="sn-sphere" style={{ marginRight: 6, verticalAlign: 'middle' }} />
											{siteid} (<FormattedDate value={new Date()} year="numeric" />)
										</div>
									</GridColumn>
								</GridRow> */}

								<GridRow style={{ paddingTop: 32 }}>
									<GridColumn computer={8} tablet={16} mobile={16}>

										{/* Need assistance? message */}
										<div style={{ textAlign: 'left' }}>
											<Message size="large" className="secondary" style={{ paddingTop: 24, paddingBottom: 32, marginBottom: 16 }}>
												<Divider horizontal>
													<Header
														as="h4"
														// icon="handshake outline"
														content={intl.formatMessage({ id: 'support.expert assistance message header' })}
														className="dark-secondary-color medium-text"
													/>
												</Divider>

												<div style={{ fontSize: 16, fontWeight: 300, lineHeight: 1.4, marginTop: 16, marginBottom: 16 }}>
													<Icon name="handshake outline" style={{ fontSize: 80, float: 'right', opacity: 0.2, marginLeft: 6, marginRight: 0, marginTop: -16, marginBottom: -16 }} />
													<FormattedMessage id="support.expert assistance message description" />	
												</div>
												<div style={{ fontWeight: 500, marginBottom: 8, textAlign: 'center', clear: 'both' }}>
													<FormattedMessage id="support.expert assistance button label" />
												</div>
												
												{/* link to suppliers */}
												{/* transl8 title attribute */}
												<div style={{ textAlign: 'center' }}>
													<a
														href="https://svift.net/samarbejde/leverandoerer"
														title="Se oversigten over vores leverandører af forskellige ydelser"
														target="_blank"
														className="ui button secondary big"
													>
														<Icon name="lightbulb" style={{ marginRight: 8 }} />
														<FormattedMessage id="support.expert assistance button" />
														<Icon name="sn-new-tab" style={{ marginLeft: 8, marginRight: 0 }} />
													</a>
												</div>

												{/* <Label
													basic
													size="big"
													className="secondary"
													style={{ marginLeft: 0 }}
												>
													<a
														href="mailto:contact@svift.net"
														// transl8 - add more description about contact link
														title="contact@svift.net"
														target="_blank"
														style={{ opacity: 1 }}
													>
														<Icon name="envelope outline" style={{ marginLeft: 0, marginRight: 6 }} />
														contact@svift.net
													</a>
												</Label> */}

											</Message>
										</div>
										
										{/* Need support? message */}
										<div style={{ textAlign: 'left' }}>
											<Message size="large" className="primary" style={{ paddingTop: 24, paddingBottom: 32, marginBottom: 16 }}>
												<Divider horizontal>
													<Header
														as="h4"
														// icon="sn-question3"
														content={intl.formatMessage({ id: 'support.need help message header' })}
														className="dark-primary-color medium-text"
													/>
												</Divider>

												<div style={{ fontSize: 16, fontWeight: 300, lineHeight: 1.4, marginTop: 16, marginBottom: 16 }}>
													<Icon name="sn-question3" style={{ fontSize: 64, float: 'right', opacity: 0.2, marginLeft: 6, marginRight: 0, marginTop: -4, marginBottom: -16 }} />
													<FormattedMessage id="support.need help message description" />
													
												</div>
												<div style={{ textAlign: 'center' }}>
													{/* contact support button */}
													<Button
														className="primary"
														size="medium"
														onClick={this.contactModalOpen}
													>
														<span>
															<Icon name="sn-bubble-notification2" style={{ verticalAlign: 'middle', marginLeft: 0, marginRight: 8 }} />
															<FormattedMessage id="support.need help message support button" />
														</span>
													</Button>
												</div>
											</Message>
										</div>

									</GridColumn>

									{/* News */}
									{/* TO DO: make a News component for a more effective way of generating news on the dashboard */}
									<GridColumn computer={8} tablet={16} mobile={16} floated="right">
										<Box
											style={{
												width: '100%',
												marginTop: 0,
												background: 'transparent',
												padding: 0
											}}
										>
											<div className="box-icon-container">
												<Icon circular name="sn-planet2" className="box-icon light-secondary-color" />
											</div>

											<div style={{ padding: 24, textAlign: 'left' }}>
												<Divider horizontal style={{ marginTop: 32, marginBottom: 24 }}>
													<Header
														as="h4"
														content={intl.formatMessage({ id: 'news.title' })}
														style={{ fontSize: 24 }}
														className="medium-text dark-secondary-color"
													/>
												</Divider>
												
												<div className="section-shade" style={{ maxHeight: 320, margin: 0, padding: 16, overflowY: 'auto', border: 'solid 1px #4aa7e2', borderRadius: 4 }}>
													{/* START - News list */}
													
													{/* NEWS #14 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-images3"
															content={intl.formatMessage({ id: 'news.news headline 14 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1580811213118)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 14 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #13 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-page-break2"
															content={intl.formatMessage({ id: 'news.news headline 13 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1580811213118)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 13 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #12 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-spray"
															content={intl.formatMessage({ id: 'news.news headline 12 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 12 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #11 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-play"
															content={intl.formatMessage({ id: 'news.news headline 11 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 11 description" />
														</p>
														<Divider />
													</div>
													
													{/* NEWS #10 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-height2"
															content={intl.formatMessage({ id: 'news.news headline 10 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 10 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #9 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-flag6"
															content={intl.formatMessage({ id: 'news.news headline 9 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 9 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #8 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-rulers"
															content={intl.formatMessage({ id: 'news.news headline 8 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 8 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #7 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-lan3"
															content={intl.formatMessage({ id: 'news.news headline 7 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1576787984146)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 7 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #6 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-color-sampler"
															content={intl.formatMessage({ id: 'news.news headline 6 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1574636400000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 6 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #5 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-google3"
															content={intl.formatMessage({ id: 'news.news headline 5 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1574636400000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 5 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #4 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-quill3"
															content={intl.formatMessage({ id: 'news.news headline 4 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1573426800000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 4 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #3 */}
													<div
														className={`info ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-folder6"
															content={intl.formatMessage({ id: 'news.news headline 3 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1573426800000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 3 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #2 */}
													<div
														className={`${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-link"
															content={intl.formatMessage({ id: 'news.news headline 2 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1573426800000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 2 description" />
														</p>
														<Divider />
													</div>

													{/* NEWS #1 */}
													<div
														className={`warning ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-drag-right"
															content={intl.formatMessage({ id: 'news.news headline 1 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1572908400000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 1 description" />
														</p>
														<Divider />
													</div>
													
													{/* NEWS #0 */}
													<div
														className={`warning ${css['animated']} ${css['fadeIn']} ${css['fast']}`}
													>
														<Header
															as="h5"
															icon="sn-undo"
															content={intl.formatMessage({ id: 'news.news headline 0 title' })}
															style={{ marginBottom: 4 }}
														/>
														<p style={newsTextStyle}>
															<span style={{ fontWeight: 500, marginRight: 8 }}>
																<FormattedDate
																	value={new Date(1572908400000)}
																	year="numeric"
																	month="long"
																	day="numeric"
																/>:
															</span>
															<FormattedMessage id="news.news headline 0 description" />
														</p>
													</div>

													{/* END - News list */}
												</div>
											
												{/* READ MORE */}
												<div>
													<Divider horizontal style={{ marginTop: 24, marginBottom: 24 }}>
														<Header
															as="h5"
															content={intl.formatMessage({ id: 'news.more news title' })}
															style={{ fontSize: 20 }}
															className="medium-text dark-secondary-color"
														/>
													</Divider>
													
													{/* link to svift.net */}
													<a
														href="https://svift.net/nyheder"
														title="Nyheder fra svift.net"
														target="_blank"
														className="ui button basic secondary medium fluid"
													>
														<Icon name="sn-logo" style={{ fontSize: 22, marginRight: 8, verticalAlign: 'middle' }} />
														<span style={{ verticalAlign: 'middle' }}>
															<FormattedMessage id="news.read more on website" />
														</span>
													</a>
												</div>
											</div>
										</Box>
									</GridColumn>

								</GridRow>
							</Grid>

						</Box>
					</GridColumn>

				</Grid>

				{/* Contact support modal */}
				<ModalWrapper
					header="support.contact support title"
					open={this.state.contactModalOpen}
					handleClose={this.handleModalClose}
					style={{ maxWidth: 520 }}
				>
					{this.state.submittingContactForm
						?
							<div>
								<Loader size="massive" style={{ marginTop: 24, marginBottom: 32 }}>
									<FormattedMessage id="general.sending loader title" />
								</Loader>
							</div>
						:
							<ContactSupport
								onSubmit={this.onSubmit}
							/>
					}
				</ModalWrapper>
			</AppContentSection>
		);
	}
}

export default connect(mapStateToProps)(injectIntl(notification(DashboardContent)));
