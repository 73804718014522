exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".preview__preview-container--20R9v{background:white;min-height:100%}.preview__preview-container--20R9v.preview__active--380jq{position:absolute;top:0px;left:0px;right:0px;z-index:1007}\n", ""]);

// exports
exports.locals = {
	"preview-container": "preview__preview-container--20R9v",
	"active": "preview__active--380jq"
};