import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	UserNav,
	Divider,
	Header,
	Icon,
	Menu,
	MenuHeader,
	MenuItem,
	ItemContent,
	Button
} from 'svift-ui';
import Label from 'components/option-library/label';
import actions from 'store/actions';
import AccordionContentComponent from 'components/accordion/AccordionContentComponent';
import { logout } from 'utils/authenticationHandler';
import AvatarList from 'containers/authorize/registration/content/AvatarList';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from 'components/editor-v2/editor-interface/options/options.scss';

import { SubmissionError } from 'redux-form';
// import PasswordForm from 'components/forms/registration/partialForms/Password';
import ChangePassword from './ChangePassword';

const mapStateToProps = state => ({
	user: state.user,
	userMenuIsVisible: state.ui.userMenuVisible,
	colors: state.system.colors.ui
});

const mapDispatchToProps = dispatch => ({
	updateUserSettings: data => dispatch(actions.user.updateUserSettings(data)),
	deleteUserAccount: user => dispatch(actions.user.deleteUserAccount(user)),
	// canDeleteUserAccount: () => dispatch(actions.user.canDeleteUserAccount()),
});

@connect(mapStateToProps, mapDispatchToProps)
class UserNavigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPath: '/', // usernav path should be in redux state
			accordions: {
				name: false,
				email: false,
				password: false,
				settings: false,
			},
			showSwal: false,
			canDelete: false,
			colorPickerOpen: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	// This is pretty weird, revisit when more time
	componentDidMount() {
		const { email, firstname, lastname, _id } = this.props.user;
		const user = { email, firstname, lastname, _id };
		user.oldPassword = '';
		user.password = '';
		user.reNewPassword = '';

		this.setState({ user });
	}

	handleAccordion = accordion => {
		const accordions = this.state.accordions;

		Object.keys(accordions).forEach(accord => {
			if (accord === accordion) {
				accordions[accord] = true;
			} else {
				accordions[accord] = false;
			}
		});

		this.setState({ accordions });
	}

	handleAccordion = accordion => {
		const accordions = this.state.accordions;

		Object.keys(this.state.accordions).forEach(acc => {
			if (acc !== accordion) {
				accordions[acc] = false;
			} else {
				accordions[accordion] = !accordions[accordion];
			}

			this.setState({ accordions });
		});
	}

	handleInputChange(e, key) {
		const user = this.state.user;

		user[key] = e.target.value;

		this.setState({ user });
	}

	async onSubmit(data) {
		//const { password, oldPassword, reNewPassword } = data.user;
		console.log(data, 'submit data');


/*		Object.keys(data.user).forEach(key => {
			if (!data.user[key]) delete data.user[key];
		});

		this.props.updateUserSettings(data.user);*/
	}

	setAvatar(avatar) {
		const data = {
			_id: this.props.user._id,
			data: { avatar }
		};

		this.props.updateUserSettings(data);
	}

	setUserColor(color) {
		const data = {
			...this.props.user,
			data: { color }
		};

		this.props.updateUserSettings(data);
	}

	rootMenu() {
		const userProfileClick = () => { this.setState({ currentPath: '/user-profile' }); };
		const { firstName, lastName } = this.props.user;
		const { intl } = this.props;

		return (
			<UserNav>
				{/* login outline + logout */}
				<div
					className={css['option-frame-outline']}
					style={{
						display: 'block',
						margin: '0 0 24px 0',
						padding: '16px 8px'
					}}
				>
					<Label
						// transl8
						label="Du er logget ind som:"
						labelIcon="sn-unlocked2"
					>
						<Header
							as="h5"
							icon="sn-account_circle"
							content={`${firstName} ${lastName}`}
							className="dark-primary-color"
							style={{ marginTop: 8 }}
						/>
					</Label>
					
					{/* log out button */}
					<Button
						basic
						fluid
						size="large"
						className="secondary"
						onClick={async () => await logout()}
						style={{ marginTop: 4 }}
					>
						<Icon name="sn-lock5" style={{ marginRight: 6 }} />
						<FormattedMessage id="profile.logout" />
					</Button>
				</div>
				
				{/* user navigation */}
				<Label
					collapsed
					label={intl.formatMessage({ id: 'profile.user navigation title' })}
					labelIcon="sn-user3"
				>
					<Menu vertical fluid>
						<MenuItem link onClick={userProfileClick}>
							<ItemContent>
								<Icon
									name="sn-account_circle"
									style={{ marginRight: 6 }}
								/>
								<FormattedMessage id="profile.user profile title" />
							</ItemContent>
						</MenuItem>
					</Menu>
				</Label>

			</UserNav>
		);
	}

	userSettingsMenu() {
		const backClick = () => { this.setState({ currentPath: '/' }); };
		const { firstName, lastName, email } = this.props.user;
		const { intl } = this.props;
		const { name, password, settings } = this.state.accordions;

		return (
			<UserNav>
				<Button
					fluid
					size="tiny"
					className="secondary"
					onClick={backClick}
				>
					<Icon name="sn-arrow-left8" style={{ marginRight: 8 }} />
					<FormattedMessage id="profile.back to user profile" />
				</Button>

				<div
					className={css['option-frame-outline']}
					style={{
						display: 'block',
						margin: '8px 0 16px 0',
						padding: '16px 8px',
						textAlign: 'center'
					}}
				>
					<Header
						as="h4"
						textAlign="center"
						content={intl.formatMessage({ id: 'profile.user profile title' })}
						className="dark-primary-color"
					/>
					
					<Divider style={{ margin: 0 }} />

					<Icon
						name="sn-account_circle"
						className="primary-color"
						style={{ 
							fontSize: 80,
							opacity: 0.6,
							margin: '0 0 16px 0'
						}}
					/>

					<Header
						as="h3"
						content={`${firstName} ${lastName}`}
						className="dark-primary-color"
						style={{ marginTop: 0, marginBottom: 0 }}
					/>

					<Divider horizontal style={{ margin: '4px 16px 6px 16px' }}>
						<Icon circular name="sn-at-sign" style={{ opacity: 0.6, margin: 0 }} />
					</Divider>

					<div style={{ marginBottom: 16, opacity: 0.6 }}>
						{email}
					</div>
				</div>

				{/* TO DO: revisit avar menu + UI color selector */}
				{/* <AvatarList simple handleSetAvatar={(avatar) => this.setAvatar(avatar)} position="bottom left" /> */}
				{/*							
					<SimpleColorPicker
						colors={this.props.colors}
						onChange={newColor => this.setUserColor(newColor)}
						//value={data && data.color ? data.color : 'default'}
					/>*

					<p style={{ marginBottom: 32 }}><FormattedMessage id="profile.user color" /></p>
				*/}
				
				<Divider horizontal style={{ margin: 0 }}>
					<Icon circular name="setting" style={{ opacity: 0.6, margin: '0 0 16px 0' }} />
				</Divider>

				<div className="accordions">
					{/* <AccordionContentComponent
						handleOpen={() => this.handleAccordion('name')}
						active={name}
						title={this.props.intl.formatMessage({ id: 'profile.change name' })}
						icon="user"
					>
						<UpdateUserName data={this.state} handleInputChange={this.handleInputChange} onSubmit={data => this.onSubmit(data)} />
					</AccordionContentComponent> */}
					{/* <AccordionContentComponent
						handleOpen={() => this.handleAccordion('email')}
						active={this.state.accordions.email}
						title={this.props.intl.formatMessage({ id: 'profile.change email' })}
						icon="at"
					>
							<UpdateUserEmail data={this.state} handleInputChange={this.handleInputChange} onSubmit={data => this.onSubmit(data)} />
					</AccordionContentComponent> */}
					
					<AccordionContentComponent
						handleOpen={() => this.handleAccordion('password')}
						active={password}
						title={intl.formatMessage({ id: 'profile.change password' })}
						icon="lock"
					>
						<ChangePassword />
					</AccordionContentComponent>

					{/* <AccordionContentComponent
						handleOpen={() => this.handleAccordion('settings')}
						active={settings} title={this.props.intl.formatMessage({ id: 'profile.user profile settings' })}
						icon="setting"
					>
						<p style={{ marginBottom: 32 }}>Here will be select for UI language + link to Modal with user and customer agreement (Modal)</p>
						<Button
							disabled={!this.props.user.canDeleteAccount}
							fluid
							negative
							size="small"
							onClick={() => this.setState({ showSwal: true })}
						>
							<span><Icon name="trash outline" /> <FormattedMessage id="profile.delete user profile" /> <Icon name="user" /></span>
						</Button>
						{
							this.state.canDelete &&
							<Alert
								show={this.state.showSwal}
								title="Delete profile??"
								text="You cannot undo this"
								onConfirm={() => this.deleteUserAccount(this.state.user)}
								showCancelButton
								onCancel={() => this.setState({ showSwal: false })}
							/>
						}
					</AccordionContentComponent> */}
				</div>
			</UserNav>
		);
	}

	renderMenu = () => {
		const { currentPath } = this.state;

		if (currentPath === '/user-profile') {
			return this.userSettingsMenu();
		}

		return this.rootMenu();
	}

	render() {
		const { userMenuIsVisible } = this.props;

		if (userMenuIsVisible) {
			return (
				<React.Fragment>
					{this.renderMenu()}
				</React.Fragment>
			);
		}

		return null;
	}
}

export default injectIntl(UserNavigation);