const actions = {
    CREATED: 'Created',
    MODIFIED: 'Modified',
    DELETED: 'Deleted',
    COPIED: 'Copied',
    STARTED: 'Started',
    FAILED: 'Failed'
};

const makeSendEvent = (ReactGA, category, action) => label => {
    const event = {
        category,
        action
    };

    if (label) event.label = label;

    ReactGA.event(event);
};

const makeCategory = (ReactGA, categoryKey, actions) => {
    const actionKeys = Object.keys(actions);
    
    return actionKeys.reduce((acc, key) => {
        const action = `${categoryKey} | ${actions[key]}`;
        
        acc[key] = makeSendEvent(ReactGA, categoryKey, action);

        return acc;
    }, {});
};

const init = ReactGA => categories => {
    const categoryKeys = Object.keys(categories);

    return categoryKeys.reduce((acc, categoryKey) => {
        const categoryActions = categories[categoryKey];
        
        acc[categoryKey] = makeCategory(ReactGA, categoryKey, categoryActions);

        return acc;
    }, {});
}

const extendAction = (key, action) => `${key} - ${action}`;

module.exports = {
    init,
    extendAction,
    actions
};
