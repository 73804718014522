import React, { PureComponent } from 'react';
import { MenuItem, Input } from 'svift-ui';
import { injectIntl } from 'react-intl';

class Search extends PureComponent {
	render() {
		const { handleFilter, dataSource, searchPlaceholder, intl } = this.props;

		if (dataSource && dataSource.length >= 10) {
			return (
				<MenuItem position="left" style={{ flex: 1 }}>
					<Input
						icon="sn-search3"
						size="tiny"
						placeholder={searchPlaceholder ? searchPlaceholder : intl.formatMessage({ id: 'general.search title' })}
						onChange={(e, d) => handleFilter(e, d)}
						style={{ minWidth: 160 }}
					/>
				</MenuItem>
			);
		}

		return null;
	}
}

export default injectIntl(Search);