import React from 'react';
import { SubmissionError } from 'redux-form';

/*
	You can throw a SubmissionError when submitting to display the error within the form; generally when an error happens due to a form submit,
	Core will submit an error with a readable field that can be output to the user, otherwise it will just throw an 'Unknown error'
*/
export default intl => error => {
	console.log(error)

	if (Array.isArray(error)) {
		throw new SubmissionError({
			_error: error.map(err => {
				return <p> {intl.formatMessage({ id: err.intl }, { ...err.context })} </p>;
			})
		})
	}

	if (error && error.intlReadable) {
		throw new SubmissionError({
			_error: intl.formatMessage({ id: error.intlReadable }, {...error.context })
		});
	}

	throw new SubmissionError({
		_error: intl.formatMessage({ id: 'validation.unknown error' })
	});
};