import uuid from 'uuid/v4';
import resolveElementName from 'utils/editor/resolveElementName';
import resolveElementIcon from 'utils/editor/resolveElementIcon';

const componentName = 'Divider';

export default {
	name: resolveElementName(componentName),
	icon: resolveElementIcon(componentName),
	type: 'ElementBuildingBlock',
	create: () => ({
		component: componentName,
		id: uuid(),
		props: {
			preset: 'Primary',
			labelAlignment: 'center',
			showIcon: false,
			showLabel: false,
			line: {},
			icon: {
				name: 'fa fa-star-o'
			},
			label: {
				text: 'Label'
			}
		}
	})
};