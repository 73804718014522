import createNodes from 'svift-library/client/renderer/node-templates/createNodes';
import template from 'svift-library/client/renderer/node-templates/library/sections/ContactFormWithMap';

export default {
	name: 'templates.template contact form with map',
	metaDescription: 'contact + columns with form + map + info + address',
	imageUrl: 'https://storage.googleapis.com/svift-net-master-bucket/assets/img/system/editor/templates/contact-form-with-map-template.png',
	type: 'SectionTemplateBuildingBlock',
    create: () => createNodes(template)
};

