import React from 'react';
import Accordion from 'svift-library/client/accordion/Accordion';
import Section from 'components/editor-v2/editor-interface/options/Section';
import NumberPicker from 'components/option-library/primitives/input/number';
import Checkbox from 'components/option-library/primitives/checkbox';
import Color from 'components/editor-v2/editor-interface/options/common/color/ColorPaletteWrapper';
import { colorResolver } from 'svift-library/client/renderer/utils/computePreset';
import Label from 'components/option-library/label';
import { injectIntl, FormattedMessage } from 'react-intl';
import key from 'utils/key';
import ClearValue from 'components/editor-v2/editor-interface/options/common/clear-value/ClearValue';
import css from '../../../../options.scss';

/*
xShadow(pin): 21
yShadow(pin): 16
spread(pin): 22
inset(pin): true
color: blabla

*/

const BoxShadow = injectIntl(props => {
    const { accordionState, intl, value, theme } = props;
    const [state, dispatch] = accordionState;

    // const onChange = (key, value, action, shouldSuspend) => {
    //     const nextShadow = {
    //         ...props.inlineStyling,
    //         [key]: value
    //     };

    //     if (typeof value === 'undefined') delete nextShadow[key];
    //     if (Object.keys(nextShadow).length === 0) {
    //         props.onChange(undefined, action, shouldSuspend);
    //     } else {
    //         props.onChange(nextShadow, action, shouldSuspend);
    //     }
    // };

    // console.log(props.inlineStyling, 'value box shadow')

    const onChange = (key, value, ...args) => {
        const nextShadow = {
            ...props.inlineStyling,
            [key]: value
        };

        if (typeof value === 'undefined') delete nextShadow[key];

        if (Object.keys(nextShadow).length === 0) {
            props.onChange(undefined, ...args);
        } else {
            props.onChange(nextShadow, ...args);
        }
    };

    // Can't deduce which color the general color was by looking at the computed styling object because general color is set explicitly on each side
    // Therefore we compute here separately so we can show the color preview
    const computedGeneralColor = colorResolver(props.theme, key('inlineStyling.color')(props) || {});

    return (
        <Accordion state={state} dispatch={dispatch}>
            <Section
                icon="sn-subtract"
                title={intl.formatMessage({ id: 'options.shadow title' })} 
                nested
                clear={{
                    onClear: () => props.onChange(),
                    enabled: typeof props.inlineStyling !== 'undefined'
                }}
            >
                <div style={{ marginBottom: 6 }}>
                    {/* inset shadow */}
                    <Checkbox
                        icon="sn-shrink2"
                        label={
                            <React.Fragment>
                                <span><FormattedMessage id="options.shadow inset title" /></span>
                                <ClearValue
                                    enabled={typeof key('inlineStyling.inset')(props) !== 'undefined'}
                                    onClear={() => onChange('inset', undefined, 'shadow inset cleared', false)}
                                />
                            </React.Fragment>
                        }
                        value={key('inlineStyling.inset')(props)}
                        onChange={newValue => onChange('inset', newValue, 'shadow inset', false)}
                    />
                </div>
                
                {/* shadow directions */}
                <div className={css['filter-outline']} style={{ display: 'block' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>  
                            {/* vertical position option */}
                            <Label
                                collapsed
                                labelIcon="sn-arrow-resize4"
                                label={intl.formatMessage({ id: 'options.shadow vertical position title' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('yShadow', undefined, 'vertical shadow cleared', false),
                                    enabled: typeof key('inlineStyling.yShadow')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name="Vertical Offset"
                                    unit="px"
                                    min={-250}
                                    max={250}
                                    tooltipPosition="right center"
                                    // onBlur={props.suspend.flush}
                                    value={key('inlineStyling.yShadow')(props)}
                                    onChange={val => onChange('yShadow', val, 'vertical shadow', true)}					
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>
                            {/* horizontal position option */}
                            <Label
                                collapsed
                                labelIcon="sn-arrow-resize3"
                                label={intl.formatMessage({ id: 'options.shadow horizontal position title' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('xShadow', undefined, 'horizontal shadow cleared', false),
                                    enabled: typeof key('inlineStyling.xShadow')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name="Horizontal Offset"
                                    unit="px"
                                    min={-250}
                                    max={250}
                                    // onBlur={props.suspend.flush}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.xShadow')(props)}
                                    onChange={val => onChange('xShadow', val, 'horizontal shadow', true)}					
                                />
                            </Label>
                        </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingRight: 4 }}>
                            {/* spread option */}
                            <Label
                                collapsed
                                labelIcon="move"
                                label={intl.formatMessage({ id: 'options.shadow spread title' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('spread', undefined, 'shadow spread cleared', false),
                                    enabled: typeof key('inlineStyling.spread')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker
                                    // transl8
                                    name="Spread"
                                    unit="px"
                                    min={0}
                                    max={250}
                                    // onBlur={props.suspend.flush}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.spread')(props)}
                                    onChange={val => onChange('spread', val, 'shadow spread', true)}						
                                />
                            </Label>
                        </div>
                        <div style={{ display: 'inline-flex', width: '50%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: 4 }}>  
                            {/* blur option */}
                            <Label
                                collapsed
                                labelIcon="sn-spray"
                                label={intl.formatMessage({ id: 'options.shadow blur title' })}
                                fontSize={12}
                                clear={{
                                    onClear: () => onChange('blur', undefined, 'shadow blur cleared', false),
                                    enabled: typeof key('inlineStyling.blur')(props) !== 'undefined'
                                }}
                            >
                                <NumberPicker 
                                    disableUndoRedo
                                    name="Blur"
                                    unit="px"
                                    min={0}
                                    max={100}
                                    // onBlur={props.suspend.flush}
                                    tooltipPosition="right center"
                                    value={key('inlineStyling.blur')(props)}
                                    onChange={val => onChange('blur', val, 'shadow blur', true)}				
                                />
                            </Label>
                        </div>
                    </div>
                </div>
                
                {/* shadow color */}
                <Color
                    icon="sn-eyedropper2"
                    title={intl.formatMessage({ id: 'options.shadow color title' })}
                    theme={props.theme}
                    inlineStyling={key('inlineStyling.color')(props)}
                    presetStyling={key('presetStyling.color')(props)}
                    // inlineStyling={key('inlineStyling.color')(props)}
                    computedStyling={computedGeneralColor}
                    onChange={(...args) => onChange('color', ...args)}
                    accordionState={accordionState}
                />
            </Section>
        </Accordion>
    );
});

export default injectIntl(BoxShadow);