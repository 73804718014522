import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Icon, Header, Button } from 'svift-ui';

class Alert extends PureComponent {
	constructor() {
		super();

		this.renderAlert = this.renderAlert.bind(this);
	}

	componentWillMount() {
		this.node = document.createElement('div');

		// this.node.className = this.props.noClass ? 'svift-tooltip-no-class' : 'svift-tooltip';

		document.body.appendChild(this.node);
	}

	componentWillUnmount() {
		// ReactDOM.unmountComponentAtNode(this.node);

		document.body.removeChild(this.node);
	}

	handleClick = fn => e => {
		e.stopPropagation();

		fn();
	}

	renderAlertFooter() {
		const { confirmTitle, confirmIcon, confirmClass, confirmStyle, onConfirm, cancelTitle, cancelStyle, onCancel } = this.props;

		return (
			<div className="alert-footer">
				<Button size="large" className={confirmClass} onClick={this.handleClick(onConfirm)} style={confirmStyle ? confirmStyle : null}><Icon name={confirmIcon} /> {confirmTitle}</Button>
				<Button size="large" className="shade" basic onClick={this.handleClick(onCancel)} style={cancelStyle ? cancelStyle : null}><Icon name="sn-cross2" /> {cancelTitle}</Button>
			</div>
		);
	}

	renderAlert() {
		const { title, text } = this.props;

		return (
			<div className="svift-alert" style={{ zIndex: 100000000000000000000 }}> {/* this is getting out of hand =D */}
				<div className="alert-frame">
					<div className="alert-content">
						{this.props.children ||
							<div>
								<Icon name="warning sign" className="alert-icon negative" />
								<Header as="h3" textAlign="center">{title}</Header>
								<p>{text}</p>
							</div>
						}
					</div>

					{this.renderAlertFooter()}
				</div>
			</div>
		);
	}

	render() {
		if (!this.props.show) return null;

		return ReactDOM.createPortal(
			this.renderAlert(),
			this.node
		);
	}
}

Alert.defaultProps = {
	title: 'Alert!',
	text: 'Something has happened. Want to proceed?',
	confirmTitle: 'OK',
	confirmIcon: 'checkmark',
	confirmClass: 'positive',
	cancelTitle: 'Cancel'
};

Alert.propTypes = {
	show: PropTypes.bool,
	title: PropTypes.string,
	onConfirm: PropTypes.func,
	confirmTitle: PropTypes.string,
	cancelTitle: PropTypes.string,
	onCancel: PropTypes.func
};

export default Alert;