const scrollIntoViewIfAnchorFound = () => {
    const { hash } = window.location;

	if (hash !== '') {
		let retries = 0;
		const id = hash.replace('#', '');

		const scroll = () => {
			retries += 0;

			// If component doesn't exist yet while the target doesn't exist, we'll retry until it does, up to 50 times (5 second limit)
			if (retries > 50) return;

			const element = document.getElementById(id);

			if (element) {
				setTimeout(() => element.scrollIntoView(), 0);
			} else {
				setTimeout(scroll, 100);
			}
		};

		scroll();
	} else {
		window.scrollTo(0, 0);
	}
}

module.exports = scrollIntoViewIfAnchorFound;