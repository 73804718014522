import React, { Component } from 'react';
import { MenuItem, Button, Icon, Popup } from 'svift-ui';
import actions from 'store/actions/ui';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const mapStateToProps = state => ({
	userMenuVisible: state.ui.userMenuVisible,
});

const mapDispatchToProps = dispatch => ({
	toggleUserMenu: bool => dispatch(actions.toggleUserMenu(bool)),
});

@connect(mapStateToProps, mapDispatchToProps)
class SideMenuButton extends Component {

	render() {
		const { toggleUserMenu, userMenuVisible, intl, userFirstName } = this.props;
		const content = `${userMenuVisible ? 'profile.hide user navigation' : 'profile.show user navigation'}`;

		return (
			<Popup
				trigger={
					<MenuItem
						link
						className="shade-item"
						id="user-settings-menu-button"
						onClick={() => toggleUserMenu(!userMenuVisible)}
					>

							<Icon
								name="sn-account_circle"
								className={!userMenuVisible ? 'tint-color' : 'light-tint-color'}
								style={{
									fontSize: 20,
									marginRight: 2,
									verticalAlign: 'middle'
								}}
							/>

							{!userMenuVisible &&
								<span
									style={{
										marginLeft: 2,
										marginRight: 2,
										fontSize: 12,
										verticalAlign: 'middle'
									}}
								>
									{userFirstName}
								</span>
							}

							<Icon
								name={!userMenuVisible ? 'sn-arrow-left8' : 'sn-arrow-right8'}
								className={!userMenuVisible ? 'tint-color' : 'light-tint-color'}
								style={{
									fontSize: 14,
									verticalAlign: 'middle',
									marginLeft: 4,
									marginRight: 0
								}}
							/>
						</MenuItem>
					}
					content={intl.formatMessage({ id: content })}
					positioning="bottom right"
				/>
		);
	}
}

export default injectIntl(SideMenuButton);