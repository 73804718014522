import React, { Component } from 'react';
import { Grid, Card } from 'svift-ui';
import FileCard from './FileCard';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';

import DropUploadHOC from 'containers/dashboard/files/__components/DropUploadHOC';
import { getFileTypeByExt } from 'utils/files/format';
import NoItems from 'components/no-items/NoItems';

class FileThumbnail extends Component {
	render() {
		const { files, noFilteredItems, siteid, columnValue, intl } = this.props;

		return (
			<div ref={this.props.connectDropTarget}>
				{this.props.renderDropMessage()}

				{noFilteredItems
					?
						<Grid padded columns={9-columnValue} stretched style={{ minWidth: 680 }}>
							{Object.values(files).map((file) => 
								<FileCard 
									key={file.id} 
									file={file} 
									siteid={siteid}
								/>
							)}
						</Grid>
					:
						<NoItems
							icon="sn-folder-search"
							title={intl.formatMessage({ id: 'messages.no filtered files title' })}
							content={intl.formatMessage({ id: 'messages.no filtered files description' })}
							className="warning"
						/>
				}
			</div>
		);
	}
}

export default injectIntl(DropUploadHOC(FileThumbnail));