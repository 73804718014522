import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replaceRoute } from 'utils/routing';
import LayoutEditor from './LayoutEditor';
import layoutsSelector from 'store/selectors/sites/layouts';

const stickyHeaderStyling = {
	position: 'sticky',
	top: 56,
	left: 0,
	right: 0,
	height: 0,
	pointerEvents: 'none'
}

const mapStateToProps = state => {
    const layoutid = state.sites.layout.params.layoutid;
	const siteid = state.sites.params.siteid;
    const layouts = layoutsSelector(state, siteid, null);
    
    return {
		siteid,
		layoutid,
		layouts,
    };
}

// const mapStateToProps = () => {
// 	return {
// 		layoutid,
// 		siteid:

// 	}
// }

// const mapDispatchToProps = (dispatch) => ({
// 	stageContent: (layoutid) => dispatch(contentActions.stageContent(layoutid))
// });

const layoutExists = (layouts, layoutid) => layouts.find(layout => layoutid === layout._id);

class LayoutsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			layoutSettingsModalOpen: false,
			deleteAlertIsOpen: false,
			listIsVisible: false,
			warningModalOpen: false,
			liveMode: false
		};
	}

	handleRoute() {
		const { layouts, siteid, layoutid } = this.props;

		if (!layoutExists(layouts, layoutid)) {
			const defaultLayout = layouts[0] && layouts[0]._id;

			replaceRoute(`/dashboard/${siteid}/design/layouts/${defaultLayout}`);

			return;
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.layoutid !== this.props.layoutid || this.props.siteid !== prevProps.siteid) {
			this.handleRoute();
		}
	}

	componentDidMount() {
		this.handleRoute();
	}

	render() {
		// if (!this.props.stagedContent) return null;

		console.log(this.props.siteid, this.props.layoutid, 'siteid/layoutid')

		return (
			<div>
				{/* sticky app header - used by portal in AppHeader */}
				{/* sticky header is raised but underneath top-navigation */}
				<div
					id="sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 997 }}
				/>

				{/* fullscreen raises sticky header above everything else */}
				<div
					id="fullscreen-sticky-app-header"
					style={{...stickyHeaderStyling, zIndex: 1009 }}
				/>

				{/* <LayoutsHeader /> */}

				{this.props.layoutid && this.props.siteid && 
					<LayoutEditor 
						key={this.props.layoutid}
						siteID={this.props.siteid}
						layoutID={this.props.layoutid}
					/>
				}

				{/* back to top button - used by portal in AppHeader */}
				<div
					id="sticky-top-button"
					style={{
						zIndex: 998,
						position: 'sticky',
						bottom: 60,
						marginRight: 16,
						height: 0,
						textAlign: 'right',
						pointerEvents: 'none'
					}}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, null /*mapDispatchToProps*/)(LayoutsContainer);