import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import { Container, Icon, Label } from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'components/preset/util';
// import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import OverviewContainer from './OverviewContainer';
import themeActions from 'store/actions/sites/themes';
import { connect } from 'react-redux';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import InfoIcon from 'components/InfoIcon';
// import Tooltip from 'svift-library/client/tooltip';

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
class OverviewBody extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            selectedPreset: null,
            selectedPresetOption: null
        };
        // this.optionTooltip = tooltip.call(this);
        this.tooltipRef = React.createRef();
    }
 

    selectPreset = (preset)=> e => {
        this.setState({ selectedPreset: preset || null })
        e.stopPropagation();
    }
    clearSelectPreset = () =>{
        this.setState({ selectedPreset:  null })
    }
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });

    getPresetOptions = style => {
        const {presetTitle, preset, selectedPresetOption, selectPresetOptionfunc, theme} = this.props;
       
        const options = [
                <span key="body-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                    <Icon name="sn-web_asset" />
                    <FormattedMessage id="elements.body title" />: <FormattedMessage id={createTranslationFromKey(presetTitle)} />
                </span>,
                <Padding
                    key="padding"
                    value={preset.padding}
                    preset={preset}
                    theme={theme}
                    onChange={(key, newValue) => {
                        if (key === 'generalPadding') {
                            if (newValue === null) {
                                this.props.updatePreset('body', presetTitle, 'padding', {
                                    paddingBottom: 1
                                });
                            } else {
                                this.props.updatePreset('body', presetTitle, 'padding', {
                                    generalPadding: newValue / 100
                                });
                            }
        
                            return;
                        }
        
                        const newPadding = {
                            ...preset.padding,
                            [key]: newValue / 100
                        };
        
                        if (newValue === null) {
                            delete newPadding[key];
                        }
        
                        delete newPadding.generalPadding;
        
                        if (typeof newPadding.paddingBottom === 'undefined') {
                            newPadding.paddingBottom = 1;
                        }
        
                        this.props.updatePreset('body', presetTitle, 'padding', newPadding);
                    }}
        
                    option="Padding"
                    selectedOption={selectedPresetOption}
                    onToggle={(select, e) => {
                        selectPresetOptionfunc(select);
                        e.stopPropagation();
                    }}
        
                    optIn={{
                        paddingBottom: false,
                        paddingTop: true,
                        paddingLeft: true,
                        paddingRight: true,
                        generalPadding: true
                    }}
                />,
                <ColorPicker
                    title={this.props.intl.formatMessage({ id: 'options.background color title' })}
                    key="color-picker-background"
                    icons={['sn-paint-format']}
                    colors={theme.colors}
                    onChange={(newColor) => this.props.updatePreset('body', presetTitle, 'backgroundColor', newColor)}
                    value={preset.backgroundColor}
        
                    option="color-picker-background"
                    selectedOption={selectedPresetOption}
                    onToggle={(select, e) => {
                        selectPresetOptionfunc(select);
                        e.stopPropagation();
                    }}
                />
             ]   
         return options;
    }

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.state.selectedPreset === 'body') {
			this.clearSelectPreset();
		}
    }

    render(){
        const { intl, preset, theme} = this.props;
        const { selectedPreset } = this.state
        const selected = selectedPreset === 'body';
        const className = selected ? 'preset-target active-preset-target' : 'preset-target';
        const computedStyles = computePreset(preset, theme);

        const pointingStyle = {
            position: 'absolute',
            top: -24,
            left: 25,
            width: 4,
            height: 24,
            background: 'orange'
        };

        const labelStyle = {
            position: 'absolute',
            top: -52,
            left: 8,
            zIndex: 3
        };
        
        return(
            <div className="preset-section" style={{ position: 'relative' }}>
                {/* content structure overview */}
                <p style={{ fontSize: 22, margin: '16px auto 48px auto', display: 'inline-block' }}>
                    <Icon name="sn-web_asset" style={{ verticalAlign: 'middle', fontSize: 24 }} />
                    <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="design.overview content structure title" /></span>
                    <span style={{ verticalAlign: 'middle' }}><InfoIcon description={intl.formatMessage({ id: 'design.overview content structure description' })} /></span>
                </p>
                
                <Container
                    className={className}

                    style={Object.assign({ width: '100%' }, computedStyles)}
                    selected={selected}
                    
                    onClick={this.selectPreset('body')}
                    ref={this.tooltipRef}
                    // ref={this.optionTooltip.setTarget}
                >
                    <OptionPanel
						icon="sn-bucket"
                        title="elements.row title"
                        target={this.tooltipRef.current}
						// target={this.optionTooltip.getTarget()}
						open={selected}
						options={this.getPresetOptions(computedStyles)}
					/>
                    <Label basic size="large" className="warning" style={labelStyle}>
                        <Icon name="sn-web_asset" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.body title" /></span>
                    </Label>

                    <div style={pointingStyle} />

                    <OverviewContainer
                        intl={intl}
                        theme={theme}

                        clearSelectPreset={this.clearSelectPreset}
                        selectPreset={this.selectPreset}
                        selectedPreset={this.state.selectedPreset}

                        containerSelectPresetOption={this.selectPresetOption}
                        containerSelectedPresetOption={this.state.selectedPresetOption}

                        presetTitle='Primary'
                        preset={theme.presets.container.Primary}
                    />                      
                </Container>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps) (injectIntl(onClickOutside(OverviewBody)));