exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "#app-content__app-content--6hQ4I{padding:99px 0 0 0;min-width:0;flex-grow:1;height:100%;background-color:transparent}#app-content__app-content--6hQ4I:before{position:absolute;content:' ';top:0;left:0;right:0;height:99px;background-color:#f3eee9;box-shadow:inset 0 -2px 12px 0 rgba(79,71,65,0.2)}\n", ""]);

// exports
exports.locals = {
	"app-content": "app-content__app-content--6hQ4I"
};