import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button, Icon } from 'svift-ui';

class SaveAndPublish extends Component {
	render() {
		return (
			<React.Fragment>
				<Popup
					trigger={
						<Button
							icon
							disabled={this.props.saveDisabled || false}
							positive
							onClick={() => this.props.onSave()}
							style={{ padding: '8px 12px', fontSize: 14 }}
							>
							<Icon name="sn-cloud-upload" />
							{this.props.saveLabel &&
								<span style={{ marginLeft: 8 }}>{this.props.saveLabel}</span>
							}
						</Button>
					}
					content={this.props.savePopup}
					position="bottom center"
				/>
				{!this.props.noPublish &&
					<Popup
						trigger={
							<Button
								icon
								size="mini"
								id="save-publish-button"
								disabled={this.props.publishDisabled || false}
								className="warning"
								onClick={() => this.props.onPublish()}
								style={{ padding: '8px 12px', fontSize: 14 }}
							>
								<Icon name="sn-sphere" />
								{this.props.publishLabel &&
									<span style={{ marginLeft: 8 }}>{this.props.publishLabel}</span>
								}
							</Button>
						}
						content={this.props.publishPopup}
						position="bottom right"
					/>
				}
			</React.Fragment>
		);
	}
}

SaveAndPublish.propTypes = {
	saveLabel: PropTypes.string,
	savePopup: PropTypes.string,
	publishLabel: PropTypes.string,
	publishPopup: PropTypes.string,
};

SaveAndPublish.defaultProps = {
	savePopup: 'Save draft',
	publishPopup: 'Save and publish',
};

export default SaveAndPublish;
