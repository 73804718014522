import createRenderBlocks from '../createRenderBlocks';

export default createRenderBlocks({ 
    elements: [
        'Input',
        'Checkbox',
        'Radio',

        'Text',
        'Image',
        'Video',
        'Button',
        'Gallery',
        'Divider',
        'Map',
        'Form',
        'Feed',
        'IFrame'
        // 'Follow',
        // 'Share',
        // 'Icon'
    ], 
    sectionTemplates: [
        'OneColumnText',
        'TwoColumnText',
        'ThreeColumnText',
        'FourColumnText',
        
        'StandardHero',
        'BackgroundImageHero',
        
        'LeftInfobox',
        'RightInfobox',
        'BroadInfobox',

        'LeftImage',
        'RightImage',
        'ThreeRoundFeatures',
        'Banner',
        'ImageFrame',
        
        'VideoHero',
        'ThreeVideos',

        'BlogList'
    ]
});

{/* <TextAreaBlock
{...sharedBlockProps}
/>
<CheckboxBlock
{...sharedBlockProps}
/>
<RadioBlock
{...sharedBlockProps}
/>
<TextBlock
{...sharedBlockProps}
/>
<ImageBlock
{...sharedBlockProps}
/>
<VideoBlock 
{...sharedBlockProps}
/>
<ButtonBlock
{...sharedBlockProps}
/>
<FeedBlock 
{...sharedBlockProps}
/>
<MapBlock 
{...sharedBlockProps}
/>
<IFrameBlock 
{...sharedBlockProps}
/> */}