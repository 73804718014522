module.exports = createNode =>
    createNode({
        component: 'Grid',
        props: {
            preset: 'Compact'
        },
        children: [
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Kontaktoplysninger',
                                                type: 'Heading 3',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact',
                    verticalAlign: 'flex-start'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 9,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Noget om åbningstider:',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [
                                                    {
                                                        offset: 0,
                                                        length: 22,
                                                        style: 'BOLD'
                                                    }
                                                ],
                                                entityRanges: [],
                                                data: {}
                                            },
                                            {
                                                key: '6vj74',
                                                text: 'Hverdage: 9:00 - 17:00',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            },
                                            {
                                                key: '3o4t8',
                                                text:
                                                    'Weekender + helligdage: lukket',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    }),
                    createNode({
                        component: 'Column',
                        width: 7,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Hjælp besøgende på din hjemmeside med at komme i kontakt med dig ude i den virkelige verden. Tlf.nr., adresse og måske et kort, der viser vej. ',
                                                type: 'Normal',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            }),
            createNode({
                component: 'Row',
                props: {
                    preset: 'Compact'
                },
                children: [
                    createNode({
                        component: 'Column',
                        width: 16,
                        props: {
                            preset: 'Compact'
                        },
                        children: [
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text: 'Her finder du os',
                                                type: 'Big',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    },
                                    style: {}
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Text',
                                props: {
                                    text: {
                                        blocks: [
                                            {
                                                key: '32423',
                                                text:
                                                    'Mere information om adressen',
                                                type: 'Small',
                                                depth: 0,
                                                inlineStyleRanges: [],
                                                entityRanges: [],
                                                data: {}
                                            }
                                        ],
                                        entityMap: {}
                                    }
                                },
                                width: 4
                            }),
                            createNode({
                                component: 'Map',
                                props: {
                                    style: {},
                                    preset: 'Primary',
                                    address: 'KBH',
                                    crop: {
                                        autoHeight: false,
                                        height: 200,
                                        heightMode: 'height'
                                    }
                                },
                                width: 4
                            })
                        ]
                    })
                ]
            })
        ]
    })
