import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'svift-ui';

class InfoIcon extends PureComponent {
	constructor() {
			super();
	
			this.state = {
				iconHovered: false
			};
		}
	
		mouseEnter = e => {
			this.setState({
				iconHovered: true
			});
		}
	
		mouseLeave = e => {
			this.setState({
				iconHovered: false
			});
		}
	
	render() {
		const { position, description, style, className } = this.props;

		return (
			<Popup
				trigger={
					<Icon
						name={this.state.iconHovered ? `sn-info2` : 'sn-info'}
						className={className ? `info-icon ${className}` : 'info-icon'}
						onMouseEnter={this.mouseEnter}
						onMouseLeave={this.mouseLeave}
						style={Object.assign({ marginLeft: 8 }, style)}
					/>
				}
				position={position}
				content={
					<p style={{ fontSize: 14, maxWidth: 240 }}>
						{description}
					</p>
				}
			/>
		);
	}
}

InfoIcon.propTypes = {
	description: PropTypes.string,
	position: PropTypes.string
};

InfoIcon.defaultProps = {
	description: 'Insert help text description here...',
	position: 'right center'
};

export default InfoIcon;
