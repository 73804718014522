import React from 'react';
import Label from 'components/option-library/label';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import { injectIntl } from 'react-intl';

export default injectIntl(props => {
    return (
        <Label
            collapsed    
            labelIcon="sn-typography"
            label={props.intl.formatMessage({ id: 'options.font title' })}
            clear={{
                onClear: () => props.onChange(),
                enabled: typeof props.inlineStyling !== 'undefined'
            }}
        >
            <Dropdown
                wings
                items={Object.keys(props.theme.fonts).map(fontKey => {
                    return {
                        text: `${props.intl.formatMessage({ id: `options.font ${fontKey} title` })} (${props.theme.fonts[fontKey].family})`,
                        value: fontKey
                    };
                })}
                value={props.inlineStyling}
                onChange={props.onChange} //newValue => props.onChange('font', newValue)}
            />
        </Label>
    );
});


