import React from 'react';
import { DropTarget } from 'react-dnd';
import { IconGroup, Icon, Message, Header } from 'svift-ui';
import { FormattedMessage } from 'react-intl';
import { createContainer, createGrid, createRow, createColumn } from '../createStandardContainers';

const EmptyTree = props =>  {
	return (
		<div
			style={{ position: 'relative' }}
			ref={props.connectDropTarget}
		>
			<Message
				size="massive"
				className={props.isOver && 'positive'}
				style={{ padding: '64px 24px 96px 24px', height: 520, textAlign: 'center' }}
			>
					{/* loading message */}
					<div style={{ marginTop: 0, marginBottom: 40 }}>
						<div>
							<IconGroup
								style={{
									margin: '0 0 24px 0',
									width: 96,
								}}
								>
								<Icon
									name="sn-quill3"
									style={{
										fontSize: 96,
										opacity: 0.3
									}}
									/>
								<Icon
									corner
									name={!props.isOver ? 'sn-move' : 'sn-plus3'} 
									className={!props.isOver ? 'info-color' : 'positive-color'} 
									style={{
										fontSize: 44,
										marginRight: -8,
										marginBottom: -8,
										opacity: 0.75
									}}
								/>
							</IconGroup>
						</div>
						<span style={{ fontSize: 40 }}>
							{!props.isOver
								?
									<FormattedMessage id="messages.no content title" />
								:
									<FormattedMessage id="messages.no content add content title" />
							}
						</span>
						<p
							style={{
								maxWidth: 400,
								margin: '16px auto 24px auto',
								textAlign: !props.isOver ? 'left' : 'center'
							}}
						>
							{!props.isOver
									?
										<FormattedMessage id="messages.no content description" />
									:
										<FormattedMessage id="messages.no content add content description" />
							}
						</p>
						<div style={{ marginBottom: 24 }}>
							<Icon
								name={!props.isOver ? 'sn-drag-right' : 'sn-plus-circle2'}
								className={!props.isOver ? 'info-color' : 'positive-color'} 
								style={{ margin: 0, fontSize: 64 }}
							/>
						</div>
					</div>
				</Message>
		</div>
	);
}

const addBuildingBlockNodesToNodeMap = (nodeMap, node) => {
    nodeMap[node.id] = node;

    if (node.children) {
        // Add all the child nodes (and their children) to the nodemap
        node.children.forEach(childNode => addBuildingBlockNodesToNodeMap(nodeMap, childNode));

        // Change the ids from full-blown nodes to ids (we keep our data flat)
        node.children = node.children.map(childNode => childNode.id);
    }
};

export default DropTarget(
	['ElementBuildingBlock', 'SectionTemplateBuildingBlock'],
	{
		drop: (props, monitor) => {
			const item = monitor.getItem();
			const { updateState } = props;
			const { createBuildingBlock } = item;
		
			return updateState(context => {
				let newNodeMap = {};
				let rootContainer;
				const newNode = createBuildingBlock();

				/*
        const newNode = createBuildingBlock();

        if (newNode.rootID) { // If it's a template
            newNodeMap = {
                ...newNodeMap,
                ...newNode.nodes // merging in template nodes
            };
        } else {
            newNodeMap[newNode.id] = newNode;
        }

        // addBuildingBlockNodesToNodeMap(newNodeMap, newNode);
				*/

				// const isTemplate = newNode.component === 'Grid';

				if (newNode.rootID) { // If it's a template
					newNodeMap = newNode.nodes;

					newNodeMap['root'] = createContainer([newNode.rootID]); 
				} else {
					const column = createColumn([newNode]);
					const row = createRow([column]);
					const grid = createGrid([row]);

					rootContainer = createContainer([grid]);
					
					addBuildingBlockNodesToNodeMap(newNodeMap, rootContainer);
				}
		
		
				return {
					...context,
					nodeMap: newNodeMap
				};
			}, 'add first content');
		}
	},
	(connect, monitor) => {
		return {
			connectDropTarget: connect.dropTarget(),
			isOver: monitor.isOver()
		};
	}
)(EmptyTree);
