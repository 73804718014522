const uuid = require('uuid/v4');
// import uuid from 'uuid/v4'

// Utility that helps us create templates
const createNodes = handler => {
    const accumulator = {}
    let rootID;

    const createNode = node => {
        node.id = uuid();
        rootID = node.id; // Outermost node 

        if (!rootID) rootID = node.id;

        if (node.children && node.children.length > 0) {
            node.children = node.children.map(childNode => {
                return childNode.id
            })
        }

        accumulator[node.id] = node;

        return node;
    }

    handler(createNode);

    return { nodes: accumulator, rootID }; //accumulator
};

module.exports = createNodes;