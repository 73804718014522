import React, { PureComponent } from 'react';
import {
    Form,
    Icon,
    Button,
    Message,
    ModalContent,
    ModalActions,
    Grid,
    GridColumn,
    Dimmer,
    Loader
} from 'svift-ui';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate, { string } from 'components/forms/__util/validate';
import Alert from 'components/Alert';

import input from 'components/forms/__util/field-components/input';
// import InfoIcon from 'components/InfoIcon';
import OptionLabel from 'components/option-library/label';

const fields = {
    name: {
        component: input({
            // name: 'design.theme title input label',
            // placeholder: 'design.theme title input placeholder',
            // icon: 'sn-color-sampler',
            // type: 'text',
            // size: 'huge'
        }),
        validate: string(1, 255)
    }
};

const validateFields = validate(fields);

class ThemeForm extends PureComponent {
    constructor() {
        super();

        this.state = {
            confirmDelete: false
        };

        this.submit = this.submit.bind(this);
    }

    async submit(values) {
        validateFields(values);

        await this.props.onSubmit(
            values,
            this.props.publishing,
            this.props.themeid
        );
    }

    renderButtons() {
        if (!this.props.update) {
            return (
                <Grid>
                    <GridColumn mobile={8} textAlign="left">
                        <Button positive icon>
                            <Icon
                                name="sn-checkmark"
                                style={{ marginRight: 16 }}
                            />{' '}
                            <FormattedMessage id="design.save new theme title" />
                        </Button>
                    </GridColumn>
                    <GridColumn mobile={8} textAlign="right">
                        <Button
                            onClick={e => {
                                e.preventDefault();
                                this.props.onClose();
                            }}
                        >
                            <Icon name="sn-cross2" style={{ marginRight: 8 }} />{' '}
                            <FormattedMessage id="general.cancel" />
                        </Button>
                    </GridColumn>
                </Grid>
            );
        }

        return (
            <Grid>
                <GridColumn computer={5} textAlign="left">
                    <Button positive>
                        <Icon
                            name="sn-cloud-upload"
                            style={{ marginRight: 8 }}
                        />{' '}
                        <FormattedMessage id="design.save changes" />
                    </Button>
                </GridColumn>
                <GridColumn computer={6} textAlign="center">
                    <Button
                        basic
                        size="tiny"
                        onClick={e => {
                            e.preventDefault();

                            this.props.reset();
                        }}
                    >
                        <Icon name="sn-cross2" />{' '}
                        <FormattedMessage id="design.clear changes" />
                    </Button>
                </GridColumn>
                <GridColumn computer={5} textAlign="right">
                    <Button
                        basic
                        size="tiny"
                        className="negative"
                        onClick={e => {
                            e.preventDefault();

                            this.setState({
                                confirmDelete: true
                            });
                        }}
                    >
                        <Alert
                            show={this.state.confirmDelete}
                            title={this.props.intl.formatMessage({
                                id: 'design.delete theme'
                            })}
                            text={this.props.intl.formatMessage({
                                id: 'design.delete theme alert description'
                            })}
                            confirmTitle={this.props.intl.formatMessage({
                                id: 'design.delete theme'
                            })}
                            confirmIcon="trash"
                            confirmClass="negative"
                            cancelTitle={this.props.intl.formatMessage({
                                id: 'general.cancel'
                            })}
                            onConfirm={() => {
                                this.setState({
                                    confirmDelete: false
                                });

                                this.props.handleSubmit(this.props.delete)();
                            }}
                            showCancelButton
                            onCancel={() => {
                                this.setState({
                                    confirmDelete: false
                                });
                            }}
                        />
                        <Icon name="sn-bin" />{' '}
                        <FormattedMessage id="design.delete theme" />{' '}
                        <Icon
                            name="sn-color-sampler"
                            style={{ marginLeft: 4 }}
                        />
                    </Button>
                </GridColumn>
            </Grid>
        );
    }

    render() {
        const { handleSubmit, intl } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.submit)}>
                {this.props.loading && (
                    <Dimmer active inverted>
                        <Loader size="huge" style={{ fontSize: 32 }}>
                            <Icon name="sn-file-text" />{' '}
                            <FormattedMessage id="general.saving changes" />
                        </Loader>
                    </Dimmer>
                )}

                <div>
                    <OptionLabel
                        labelIcon="sn-color-sampler"
                        label={intl.formatMessage({
                            id: 'design.theme title input label'
                        })}
                        labelDescription={intl.formatMessage({
                            id: 'design.custom theme description'
                        })}
                    >
                        <Field
                            fluid
                            intl={intl}
                            type="text"
                            name="name"
                            icon="sn-color-sampler"
                            iconPosition="left"
                            size="huge"
                            placeholder={intl.formatMessage({
                                id: 'design.theme title input placeholder'
                            })}
                            component={fields.name.component}
                        />
                    </OptionLabel>
                </div>

                {this.props.error && (
                    <Message negative style={{ marginTop: 16 }}>
                        <Icon name="sn-warning" />{' '}
                        <FormattedMessage id={this.props.error} />
                    </Message>
                )}

                {this.renderButtons()}
            </Form>
        );
    }
}

const ReduxThemeForm = reduxForm({
    form: 'registration'
})(injectIntl(ThemeForm));

ReduxThemeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ReduxThemeForm;
