import React, { memo, useMemo, useCallback, useEffect } from 'react';
import Accordion, {
    useAccordion
} from 'svift-library/client/accordion/Accordion';
import BackgroundVideoOptions from 'components/editor-v2/editor-interface/options/common/background-video';
import Crop from '../common/Crop';
import Label from 'components/option-library/label';
import Preset from 'components/editor-v2/editor-interface/options/common/preset/Preset';
import Section from '../Section';
import BackgroundOptions from 'components/editor-v2/editor-interface/options/common/background';
import Toggle, {
    ToggleGroup
} from 'components/option-library/primitives/toggle';
import { injectIntl } from 'react-intl';
// import { createSuspendHistory } from 'components/editor-v2/editor-interface/suspendHistory'
// import { useHistoryHelper } from '../historyHelper';

export default memo(
    injectIntl(props => {
        const { editableNode: node, updateState, intl } = props;
        const [state, dispatch] = useAccordion();
        // const historyHelper = useHistoryHelper(
        //     props.getState,
        //     context => context.
        // );

        // historyHelper(something)
        // historyHelper.commit();

        /* 
        onChange(key, value, history => {
            
        })
    */

        const onChange = useCallback((key, value, ...args) => {
            updateState(context => {
                return {
                    ...context,
                    nodeMap: {
                        ...context.nodeMap,
                        [node.id]: {
                            ...context.nodeMap[node.id],
                            props: {
                                ...context.nodeMap[node.id].props,
                                [key]: value
                            }
                        }
                    }
                };
            }, ...args);
        }, []);

        const suspend = useMemo(
            () =>
                props.suspend({
                    onChange: (shouldSkip, value, key, action) => {
                        onChange(key, value, shouldSkip ? 'skip' : action);
                    }
                }),
            []
        );

        // // const suspendedOnChange = () => {
        // //     suspend
        // // }

        // // suspend.flush();

        // // console.log(props, 'hello')

        // /*
        //     onChangeWithHistory = (...args) => {

        //     }

        //     const suspend = createSuspend(handler);

        //     suspend.flush();

        //     suspend({ currentState, currentValue, value }); // => handler(action, value)

        //     suspend.commit();
        // */

        // const createOnChangeWithSuspendedHistory = (key, action) => {
        //     const suspendHistory = createSuspendHistory({
        //         action,
        //         onAction: (action, value) => onChange(key, value, action),
        //         onCommit: initialContext => updateState(() => initialContext, 'skip')
        //     });

        //     return ({ value, comparand, nextComparand, commit }) => {
        //         if (commit) {
        //             suspendHistory.commit();
        //         } else {
        //             suspendHistory({
        //                 context: props.getState(),
        //                 value,
        //                 comparand,
        //                 nextComparand
        //             });
        //         }
        //     };
        // };

        // const columnDividerOnChange = useMemo(() => createOnChangeWithSuspendedHistory('columnDividerWidth', 'row column width'), []);

        // console.log(props.getState().nodeMap[node.id], 'ID')

        // // useEffect(() => {
        // //     return () => columnDividerOnChange({ commit: true });
        // // }, [columnDividerOnChange])

        // useEffect(() => {
        //     return () => suspend.flush();
        // }, [])

        const presetKey = props.theme.presets.row[node.props.preset] ? node.props.preset : 'Normal'; // Accomodates legacy data

        return (
            <React.Fragment>
                <Section>
                    <Preset
                        presets={props.theme.presets.row}
                        onChange={onChange}
                        suspend={suspend}
                        // createOnChangeWithSuspendedHistory={createOnChangeWithSuspendedHistory}
                        presetKey={presetKey}
                        theme={props.theme}
                        inlineStyling={node.props.style}
                    />

                    <Label
                        labelIcon="sn-arrow-resize3"
                        label={intl.formatMessage({
                            id: 'options.row horizontal alignment label'
                        })}
                        // labelDescription="Something about alignment"
                    >
                        <ToggleGroup fluid size="mini" key="toggleGroupTest">
                            <Toggle
                                icon="sn-vertical_align_top"
                                rotated="counterclockwise"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment left'
                                })}
                                tooltipPosition="top center"
                                value={node.props.horizontalAlign === 'flex-start'}
                                // value={
                                //     ['center', 'flex-end'].indexOf(
                                //         node.props.horizontalAlign
                                //     ) === -1
                                // }
                                onChange={() =>
                                    onChange(
                                        'horizontalAlign',
                                        node.props.horizontalAlign === 'flex-start' ? undefined : 'flex-start',
                                        'row horizontal alignment',
                                        false
                                    )
                                }
                            />
                            <Toggle
                                icon="sn-vertical_align_center"
                                rotated="clockwise"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment center'
                                })}
                                tooltipPosition="top center"
                                value={node.props.horizontalAlign === 'center'}
                                onChange={() =>
                                    onChange(
                                        'horizontalAlign',
                                        node.props.horizontalAlign === 'center' ? undefined : 'center',
                                        'row horizontal alignment',
                                        false
                                    )
                                }
                            />
                            <Toggle
                                icon="sn-vertical_align_bottom"
                                rotated="counterclockwise"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment right'
                                })}
                                tooltipPosition="top center"
                                value={
                                    node.props.horizontalAlign === 'flex-end'
                                }
                                onChange={() =>
                                    onChange(
                                        'horizontalAlign',
                                        node.props.horizontalAlign === 'flex-end' ? undefined : 'flex-end',
                                        'row horizontal alignment',
                                        false
                                    )
                                }
                            />
                        </ToggleGroup>
                    </Label>

                    <Label
                        label={intl.formatMessage({
                            id: 'options.row vertical alignment label'
                        })}
                        labelIcon="sn-arrow-resize4"
                        // labelDescription="Something about alignment"
                    >
                        <ToggleGroup fluid size="mini" key="toggleGroupTest">
                            <Toggle
                                icon="sn-vertical_align_top"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment top'
                                })}
                                tooltipPosition="top center"
                                value={node.props.verticalAlign === 'flex-start'}
                                // value={
                                //     ['center', 'flex-end'].indexOf(
                                //         node.props.verticalAlign
                                //     ) === -1
                                // }
                                onChange={() =>
                                    onChange(
                                        'verticalAlign',
                                        node.props.verticalAlign === 'flex-start' ? undefined : 'flex-start',
                                        'row vertical alignment',
                                        false
                                    )
                                }
                            />
                            <Toggle
                                icon="sn-vertical_align_center"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment middle'
                                })}
                                tooltipPosition="top center"
                                value={node.props.verticalAlign === 'center'}
                                onChange={() =>
                                    onChange(
                                        'verticalAlign',
                                        node.props.verticalAlign === 'center' ? undefined : 'center',
                                        'row vertical alignment',
                                        false
                                    )
                                }
                            />
                            <Toggle
                                icon="sn-vertical_align_bottom"
                                tooltipLabel={intl.formatMessage({
                                    id: 'options.alignment bottom'
                                })}
                                tooltipPosition="top center"
                                value={node.props.verticalAlign === 'flex-end'}
                                onChange={() =>
                                    onChange(
                                        'verticalAlign',
                                        node.props.verticalAlign === 'flex-end' ? undefined : 'flex-end',
                                        'row vertical alignment',
                                        false
                                    )
                                }
                            />
                        </ToggleGroup>
                    </Label>
                </Section>

                <Accordion state={state} dispatch={dispatch}>
                    <BackgroundVideoOptions {...props} />
                    <BackgroundOptions {...props} />

                    <Crop
                        onChange={useCallback(
                            (...args) => onChange('crop', ...args),
                            [onChange]
                        )}
                        value={node.props.crop}
                    />
                </Accordion>
            </React.Fragment>
        );
    })
);
