import React from 'react';
import Renderer from 'svift-library/client/renderer/RendererExternalStateTest';
import { FormattedMessage, injectIntl } from 'react-intl';

const DividerPreview = injectIntl(({ intl, theme, presetTitle }) => {
    const rendererContext = React.createContext();

    return (
        <React.Fragment>
            <p className="preset-title">
                <FormattedMessage id="general.preview title" />
            </p>

            <Renderer
                rendererState={{
                    theme,
                    nodeMap: {
                        'root': {
                            id: 'root',
                            component: 'Divider',
                            props: {
                                preset: presetTitle,
                                showIcon: true,
                                showLabel: true,
                                labelAlignment: 'center',
                                line: {
                                    
                                },
                                icon: {
                                    name: 'fa fa-star-o'
                                },
                                label: {
                                    text: intl.formatMessage({ id: 'elements.label example title'})
                                }
                            }
                        }
                    },
                    extend: {}
                }}
                RendererContext={rendererContext}
            />
        </React.Fragment>
    );
});

export default DividerPreview;