import React, { PureComponent } from 'react';
import OptionPanel from 'components/option-panel';
import { Grid, Icon, Label } from 'svift-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { computePreset } from 'components/preset/util';
import { tooltip } from 'components/tooltip/Tooltip';
import { createTranslationFromKey } from 'containers/dashboard/design/elements/Presets';
import ColorPicker from 'components/option-panel/options/ColorPicker';
import Padding from 'components/option-panel/options/advanced/options/Padding';
import Shadow from 'components/option-panel/options/advanced/options/Shadow';
import themeActions from 'store/actions/sites/themes';
import onClickOutside, { clickedOnTooltip } from 'components/hoc/onClickOutside';
import { connect } from 'react-redux';
import OverviewRow from './OverviewRow';

const mapDispatchToProps = (dispatch) => {
	return {
		// Never use defaults; they're just there to help you remember what each variable name refers to
		updatePreset: (presetType = 'text', presetElement = 'big', presetElementOption = 'fontSize', value) => {
			dispatch(themeActions.updatePreset(presetType, presetElement, presetElementOption, value));
		},
	};
};
class OverviewSection extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            selectedPreset: null,
            selectedPresetOption: null
        }
        // this.optionTooltip = tooltip.call(this);
        this.optionTooltipRef = React.createRef();
    }

    selectPreset = (preset) => {
        this.setState({ selectedPreset: preset || null });
        e.stopPropagation();
    }
    selectPresetOption = (presetOption) => this.setState({ selectedPresetOption: presetOption || null });

    getPresetOptions = style => {
        const {presetTitle, preset, sectionSelectedPresetOption, sectionSelectPresetOption, theme} = this.props;
       
        const options = [
            <span key="section-preset-options" style={{ marginRight: 16, opacity: 0.6 }}>
                <Icon name="sn-grid-2" /> <FormattedMessage id="elements.section title" />: <FormattedMessage id={createTranslationFromKey(this.props.presetTitle)} />
            </span>,
            <ColorPicker
                title={this.props.intl.formatMessage({ id: 'options.background color title' })}
                key="color-picker-background"
                icons={['sn-paint-format']}
                colors={theme.colors}
                onChange={(newColor) => this.props.updatePreset('grid', presetTitle, 'backgroundColor', newColor)}
                value={preset.backgroundColor}

                option="color-picker-background"
                selectedOption={sectionSelectedPresetOption}
                onToggle={(select, e) => {
                    sectionSelectPresetOption(select);
                    e.stopPropagation();
                }}
            />,
            <Padding
                key="padding"
                value={preset.padding}
                preset={preset}
                theme={theme}
                onChange={(key, newValue) => {
                    if (key === 'generalPadding') {
                        if (newValue === null) {
                            this.props.updatePreset('grid', presetTitle, 'padding', {
                                paddingBottom: 1,
                                paddingTop: 1
                            });
                        } else {
                            this.props.updatePreset('grid', presetTitle, 'padding', {
                                generalPadding: newValue / 100
                            });
                        }

                        return;
                    }

                    const newPadding = {
                        ...preset.padding,
                        [key]: newValue / 100
                    };

                    if (newValue === null) {
                        delete newPadding[key];
                    }

                    delete newPadding.generalPadding;

                    if (typeof newPadding.paddingBottom === 'undefined') {
                        newPadding.paddingBottom = 1;
                        newPadding.paddingTop = 1;
                    }

                    this.props.updatePreset('grid', presetTitle, 'padding', newPadding);
                }}

                option="Padding"
                selectedOption={sectionSelectedPresetOption}
                onToggle={(select, e) => {
                    sectionSelectPresetOption(select);
                    e.stopPropagation();
                }}

                optIn={{
                    paddingBottom: false,
                    paddingTop: false,
                    paddingLeft: true,
                    paddingRight: true,
                    generalPadding: true
                }}
            />,
            <Shadow
				key="shadow"
				value={preset.boxShadow || {}}
				colors={theme.colors}
				onChange={(newShadow) => {
					this.props.updatePreset('grid', presetTitle, 'boxShadow', newShadow);
				}}

				option="Shadow"
				selectedOption={sectionSelectedPresetOption}
				onToggle={(select, e) => {
					sectionSelectPresetOption(select);

					e.stopPropagation();
				}}
			/>
        ]   
         return options;
    }

    onClickOutside(e) {
        const tooltipNodes = document.querySelectorAll('[data-type="tooltip-container"]');

        if (Array.from(tooltipNodes).some(tooltipNode => tooltipNode.contains(e.target))) return;

		if (this.props.selectedPreset === 'section') {
			this.props.clearSelectPreset();
		}
    }

    // onClickOutside(e) {
	// 	if (clickedOnTooltip(e, 'svift-tooltip-no-class')) return;

	// 	if (this.props.selectedPreset === 'section') {
	// 		this.props.clearSelectPreset();
    //     }
    // }

    render() {
        const { intl, selectedPreset, preset, theme } = this.props;
        const selected = selectedPreset === 'section';
        const className = selected ? 'preset-target active-preset-target' : 'preset-target';

        const computedStyles = computePreset(preset, theme);
        
        const labelStyling = {
            position: 'absolute',
            top: -44,
            left: 204,
            zIndex: 4
        };
        
        const pointingStyle = {
            position: 'absolute',
            top: -32,
            left: 224,
            width: 4,
            height: 32,
            background: 'lightblue'
        };

        return(
            <div style={{ position: 'relative' }}>
                <div style={pointingStyle} />
                <Label basic size="large" className="secondary" style={labelStyling} onClick={this.props.selectPreset('section')}>
                    <Icon name="sn-grid-2" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                    <span style={{ verticalAlign: 'middle' }}><FormattedMessage id="elements.section title" /></span>
                </Label>

                <Grid padded centered 
                    className={className} 
                    style={computedStyles}
                    selected={selected}
                    onClick={this.props.selectPreset('section')}
                    // ref={this.optionTooltip.setTarget}
                    ref={this.optionTooltipRef}
                >   
                    <OptionPanel
                        icon="sn-bucket"
                        title="elements.row title"
                        // target={this.optionTooltip.getTarget()}
                        target={this.optionTooltipRef.current}
                        open={selected}
                        options={this.getPresetOptions(computedStyles)}
                    />
                    <OverviewRow  
                        intl={intl}
                        theme={theme}

                        clearSelectPreset={this.props.clearSelectPreset}
                        selectPreset={this.props.selectPreset}
                        selectedPreset={this.props.selectedPreset}

                        rowSelectPresetOption={this.selectPresetOption}
                        rowSelectedPresetOption={this.state.selectedPresetOption}

                        presetTitle='Normal'
                        preset={theme.presets.row.Normal}
                    />
                </Grid>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps) (injectIntl(onClickOutside(OverviewSection)));