import constants from 'store/constants';
import extractParams from 'utils/routing/extractParams';

const getStagedContent = state => {
	let stagedContent = state.stagedContent;
	const blog = state.blogs[state.params.blogid];
				
	if (blog) {
		const blogContent = state.blogContent[blog.content];

		if (blogContent && blogContent.draft) {
			stagedContent = blogContent.draft;
		}
	} else {
		stagedContent = null;
	}

	return {
		stagedContent,
		stagedFrom: stagedContent
	};
}

const getDefaultState = () => ({
	blogContent: {},
	blogs: {},
	stagedContent: null,
	stagedFrom: null,
	view: 'draft',
	params: {
		blogid: null
	}
});

const defaultState = getDefaultState();

const blogReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.CREATE_BLOG: {
			const { newBlog } = action.data;

			return {
				...state,
				blogs: {
					...state.blogs,
					[newBlog._id]: {
						...newBlog,
						content: newBlog._id
					}
				},
				blogContent: {
					...state.blogContent,
					[newBlog._id]: newBlog.content
				}
			};
		}

		case constants.UPDATE_BLOG: {
			const { blogID, newBlog } = action.data;

			return {
				...state,
				blogs: {
					...state.blogs,
					[blogID]: {
						...state.blogs[blogID],
						...newBlog
					}
				}
			};
		}

		case constants.DELETE_BLOG: {
			const { blogID } = action.data;

			const blogs = {
				...state.blogs
			};

			const blogContent = {
				...state.blogContent
			};

			delete blogs[blogID];
			delete blogContent[blogID];

			return {
				...state,
				blogs,
				blogContent
			};
		}

		case constants.TOGGLE_BLOG_CONTENT_VIEW: {
			return {
				...state,
				view: action.data
			};
		}

		case constants.SET_BLOGS: {
			const { blogs, blogContent } = action.data;
			
			let newState = {
				...state,
				blogs: {
					...state.blogs,
					...blogs
				},
				blogContent: {
					...state.blogContent,
					...blogContent
				},
			};

			newState = {
				...newState,
				...getStagedContent(newState)
			};
			
			return newState;
		}

		case constants.UPDATE_BLOG_CONTENT: {
			const { tree } = action.data;

			const newState = {
				...state,
				stagedContent: {
					...state.stagedContent,
					tree
				},
			};

			return newState;
		}

		case constants.PUBLISH_BLOG_CONTENT: {
			const { publishAt, draft, blogid } = action.data;

			const newState = {
				...state,
				blogContent: {
					...state.blogContent,
					[blogid]: {
						...state.blogContent[blogid],
						draft,
						live: draft
					}
				},
				stagedFrom: draft,
				stagedContent: draft				
			};

			if (publishAt) {
				newState.blogs = {
					...state.blogs,
					[blogid]: {
						...state.blogs[blogid],
						publishAt
					}
				}
			}

			return newState;
		}

		case constants.SAVE_BLOG_CONTENT: {
			const { draft, blogid } = action.data;

			return {
				...state,
				blogContent: {
					...state.blogContent,
					[blogid]: {
						...state.blogContent[blogid],
						draft
					}
				},
				stagedFrom: draft,
				stagedContent: draft
			};
		}

		case constants.SETUP:
		case constants.ROUTE_CHANGE: {
			const { location: { pathname } } = action.payload;

			const params = extractParams(pathname, '/dashboard/:siteid?/blog-posts/:blogid?') || { blogid: null };
			
			let newState = {
				...state,
				view: 'draft',
				params: {
					blogid: params.blogid
				}	
			};
			
			if (params.blogid !== state.params.blogid) {
				newState = {
					...newState,
					...getStagedContent(newState)
				};
			}

			return newState;
		}

		case constants.LOGOUT:
			return getDefaultState();

		default:
			return state;
	}
};

export default blogReducer;
