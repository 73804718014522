import React, { useState, useEffect, useCallback } from 'react';

const deviceTargets = [768, 576]; // numbers come from theme
const deviceLookup = {
    576: 'phone', // 16 columns
    768: 'tablet' // 8 columns
};

const useDimensions = containerRef => {
    // const [containerWidth, setContainerWidth] = useState(null);
    const [device, setDevice] = useState(null);
    // const latestWidth = useRef(containerWidth);

    const onResize = useCallback(() => {
        const container = containerRef ? containerRef.current : document.body;

        if (!container) return;

        const { width: containerWidth } = container.getBoundingClientRect();
        const sideNavEl = document.getElementById('sidenav');
        const userNavEl = document.getElementById('usernav');
    
        let sideNavWidth = 0;
        let userNavWidth = 0;
    
        if (sideNavEl) {
            sideNavWidth = sideNavEl.getBoundingClientRect().width;
        }
    
        if (userNavEl) {
            userNavWidth = userNavEl.getBoundingClientRect().width;
        }
    
        const contentWidth = containerWidth - sideNavWidth - userNavWidth;
    
        let device = null;
    
        deviceTargets.forEach(target => {
            if (contentWidth < target) {
                device = deviceLookup[target];
            }
        });
    
        setDevice(device);
        // setContainerWidth(contentWidth);
        // latestWidth.current = contentWidth;
    }, []);


    useEffect(() => {
        window.addEventListener('resize', onResize);

        onResize(); // Will flicker - fix later when time allows; kinda small thing (probably wont even feel it in production)

        return () => window.removeEventListener('resize', onResize);
    }, []);

    return device;
};

export default useDimensions;