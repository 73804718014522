exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".button__button-wrapper--3FvyH{position:relative;display:flex}\n", ""]);

// exports
exports.locals = {
	"button-wrapper": "button__button-wrapper--3FvyH"
};