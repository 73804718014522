import {
    init,
    extendAction,
    actions
} from 'svift-library/google-analytics/svift-ga';
import ReactGA from 'react-ga';

export default init(ReactGA)({
    AUTHORIZATION: {
        LOG_IN_STARTED: extendAction('Log in', actions.STARTED),
        REGISTER_STARTED: extendAction('Registered', actions.STARTED),
        RESET_PASSWORD_STARTED: extendAction('Reset password', actions.STARTED) // Authorization | Reset password - Started
    },
    SITES: {
        CREATED: actions.CREATED, // Sites | Started
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        COPIED: actions.COPIED,
        USER_INVITE_STARTED: extendAction('User invite', actions.STARTED), // Sites | User invite - Started
        GA_CREATED: extendAction('Google analytics', actions.CREATED),
        GA_DELETED: extendAction('Google analytics', actions.DELETED),
        GSUITE_CREATED: extendAction('G Suite', actions.CREATED),
        GSUITE_DELETED: extendAction('G Suite', actions.DELETED),
        DNS_RECORD_CREATED: extendAction('DNS record', actions.CREATED),
        DNS_RECORD_MODIFIED: extendAction('DNS record', actions.MODIFIED),
        DNS_RECORD_DELETED: extendAction('DNS record', actions.DELETED)
    },
    PAGES: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        LIVE_CONTENT_MODIFIED: extendAction('Live content', actions.MODIFIED),
        DRAFT_CONTENT_MODIFIED: extendAction('Draft content', actions.MODIFIED)
    },
    BLOGS: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        LIVE_CONTENT_MODIFIED: extendAction('Live content', actions.MODIFIED),
        DRAFT_CONTENT_MODIFIED: extendAction('Draft content', actions.MODIFIED)
    },
    MODULES: {
        BLOG_ACTIVATED: extendAction('Blog activated', actions.MODIFIED),
        BLOG_DEACTIVATED: extendAction('Blog deactivated', actions.MODIFIED) 
    },
    THEMES: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        LIVE_CONTENT_MODIFIED: extendAction('Live content', actions.MODIFIED),
        DRAFT_CONTENT_MODIFIED: extendAction('Draft content', actions.MODIFIED)
    },
    LAYOUTS: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        LIVE_CONTENT_MODIFIED: extendAction('Live content', actions.MODIFIED),
        DRAFT_CONTENT_MODIFIED: extendAction('Draft content', actions.MODIFIED)
    },
    FORMS: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED,
        LIVE_CONTENT_MODIFIED: extendAction('Live content', actions.MODIFIED),
        DRAFT_CONTENT_MODIFIED: extendAction('Draft content', actions.MODIFIED)
    },
    NAVIGATION: {
        CREATED: actions.CREATED,
        DELETED: actions.DELETED,
        MODIFIED: actions.MODIFIED
    },
    FILES: {
        CREATED: actions.CREATED,
        FOLDER_CREATED: extendAction('Folder', actions.CREATED)
    },
    ACTIVATION: {
        STARTED: actions.STARTED
    },
    SUPPORT: {
        INTRODUCTION_STARTED: extendAction('Introduction', actions.STARTED),
        CONTACT_SUPPORT_STARTED: extendAction('Contact support', actions.STARTED),
        OPEN_FAQ_STARTED: extendAction('Open FAQ', actions.STARTED) 
    }
});
