import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useAddNode from '../hooks/useAddNode';
import css from './modal.scss';
// import addKeybinding from 'svift-library/client/utils/addKeybinding';

let openModals = 0;

export default React.forwardRef((props, ref) => {
    // const modalNode = useAddNode('modal-container', props.nodeAttributes);

    const modalNode = useAddNode('modal-container', {
        ...props.nodeAttributes,
        // class: css['modal-backdrop']
        class: css['modal-dimmer']
    });

    // Postponed because it collides with editor
    // useEffect(() => {
    //     const closeModal = e => {
    //         props.setModalOpen(false);
    //     };

    //     return addKeybinding(['Escape', 27], null, closeModal);
    // }, []);

    useEffect(() => {
        document.body.className = css['modal-open'];
        openModals++;

        return () => {
            if (openModals === 1) {
                document.body.className = document.body.className.replace(
                    css['modal-open'],
                    ''
                );
            }

            openModals--;
        };
    }, []);

    return createPortal(
        <div
            ref={ref}
            className={`modal-dimmer ${css['modal-dimmer']} ${
                css['animated']
            } ${css['fadeIn']} ${css['fast']}`}
            onClick={() => props.toggleModal(false)}
        >
            {/* modal wrapper */}
            <div
                className={`modal-wrapper ${css['modal-wrapper']} ${
                    css['animated']
                } ${css['animated.delay-1s']} ${css['fadeInUp']} ${
                    css['faster']
                }`}
                onClick={e => e.stopPropagation()}
                style={{
                    border: `2px solid ${
                        props.primaryColor ? props.primaryColor : 'grey'
                    }`,
                    backgroundColor: props.tintColor
                        ? props.tintColor
                        : 'white',
                    width: props.width ? props.width : 1024,
                    ...props.style
                }}
            >
                {/* modal header */}
                <div
                    className={`modal-header ${css['modal-header']}`}
                    style={{
                        color: props.primaryColor
                            ? props.primaryColor
                            : 'black',
                        fontFamily: props.primaryFont
                            ? props.primaryFont
                            : 'Ubuntu, Arial, Helvetica, sans-serif',
                        borderBottom: `1px solid ${
                            props.shadeColor ? props.shadeColor : 'grey'
                        }`,
                        backgroundColor: props.tintColor
                            ? props.tintColor
                            : 'white'
                    }}
                >
                    {/* modal title (prop) */}
                    {props.title || 'NO "TITLE" PROP PROVIDED...'}

                    {/* close modal icon */}
                    <div
                        onClick={() => props.toggleModal(false)}
                        className={`close-icon ${css['close-icon']}`}
                        style={{
                            color: props.primaryColor
                                ? props.primaryColor
                                : 'grey'
                        }}
                    >
                        <i className="icon-cross3" style={{ margin: 0 }} />
                    </div>
                </div>

                {/* modal content */}
                <div
                    className={
                        !props.collapsed
                            ? `modal-content ${css['modal-content']}`
                            : `modal-content collapsed ${
                                  css['modal-content']
                              } ${css['collapsed']}`
                    }
                    style={{
                        color: props.primaryColor
                            ? props.primaryColor
                            : 'black',
                        fontFamily: props.secondaryFont
                            ? props.secondaryFont
                            : 'Ubuntu, Arial, Helvetica, sans-serif',
                        backgroundColor: props.tintColor
                            ? props.tintColor
                            : 'white'
                    }}
                >
                    {props.children}
                </div>

                {/* TO DO: revisit modal actions section */}
                {/* modal actions */}
                {/* {props.modalActions &&
                    <div className={css['modal-actions']}>
                        {props.modalActions}
                    </div>
                } */}
            </div>
        </div>,
        modalNode
    );
});
