import React, { PureComponent } from 'react';
import {
	Icon,
	Button,
	Popup,
	ButtonGroup,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownHeader,
	DropdownDivider, 
	Message
} from 'svift-ui';
import Alert from 'components/Alert';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fileActions from 'store/actions/sites/files';
import notification from 'components/hoc/notification';

const mapDispatchToProps = dispatch => {
	return {
		setActiveFolder: id => dispatch(fileActions.setActiveFolder(id)),
		renameFolder: (newFoldername, folderid, siteid) => dispatch(fileActions.renameFolder(newFoldername, folderid, siteid)),
		deleteFolder: (folderid, siteid) => dispatch(fileActions.deleteFolder(folderid, siteid)),
	};
};

const mapStateToProps = state => {
	const siteid = state.sites.params.siteid;
	const site = state.sites.sites[siteid];
	const folders= state.sites.files.folders;
	const activeFolderId = state.sites.files.activeFolder;
	const activeFolder = folders.filter(folder => folder._id === activeFolderId)[0]

	return {
		siteid,
		siteName: site && site.name,
		activeFolderId,
		activeFolder,
		folders
	};
};

class FolderComponentWrapper extends PureComponent {
	constructor() {
		super();

		this.state = {
			//loading: false,
			open:"", 
			showDelete: false,
			name:"", 
			RegExp: false, 
			errorLetter:"",
			showCover: false
		};
	}

	getRenameValue=(e)=>{
		const regex = RegExp(/[^A-Za-z0-9-._ ]/g);
		const testBool = regex.test(e.target.value);
		const errorLetter = e.target.value.match(regex)

		this.setState({
			regExp: testBool, 
			errorLetter,
			name: e.target.value
		});
	}

	renameFolder = item => {
		const { _id } = item;

		const editText = this.state.name;

		try {
			this.props.renameFolder( editText, _id, this.props.siteid);

		} catch (e) {
			alert('error');
		} finally {
			this.setState({
				[`${_id}input`]: undefined, 
				name: ""
			});
			this.props.createNotification({
				content: `${this.props.intl.formatMessage({ id: 'files.folder title' })}: "${item.name}" ${this.props.intl.formatMessage({ id: 'general.was renamed to label' })} "${editText}"`,
				className: 'info',
				position: 'topCenter',
				icon: 'setting',
				deleteTime: 4000
			});
		}
	};

	deleteFolder = item => {
		const { _id } = item;

		try {
			this.props.deleteFolder( _id, this.props.siteid);
		} catch (e) {
			console.log(e);

			alert('error');
		} 
	};

	render() {
		const { item, intl, activeFolderId} = this.props;

		// We know root is the only folder with no name and can safely fall back to site name
		const text = this.props.text || this.props.siteName;	

		//if (this.state.loading) return 'loading';
		const disabled = (this.state.name ==="" || this.state.regExp=== true) ? true: false;
		// console.log(this.state.name)

		const coverStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: 'white',
			opacity: 0.6,
			zIndex: 89,
			content: ' '
		};

		return (
			<div key={item.data._id}>
				<Icon
					name={item.data._id=== activeFolderId ? 'sn-folder-open2' : "sn-folder6"}
					style={{ marginRight: 4, position: 'relative' }}
				/>

				<Alert
					show={this.state.showDelete}
					onCancel={() => this.setState({ showDelete: false })}
					showCancelButton
					title={intl.formatMessage({ id: 'files.alert delete folder title' })}
					text={
						<span>
							{/* <FormattedMessage id="files.folder title" />:  */}
							<span style={{ fontWeight: 'bold' }}><Icon name="sn-folder6" style={{ verticalAlign: 'middle', marginRight: 0 }} /> {item.data.name}</span> <FormattedMessage id="files.alert folder will be deleted" />
						</span>
					}
					confirmTitle={intl.formatMessage({ id: 'files.alert confirm delete folder button' })}
					confirmIcon="trash"
					confirmClass="negative"
					cancelTitle={this.props.intl.formatMessage({ id: 'general.cancel' })}
					onConfirm={() => {
						this.setState({
							showDelete: false,
						}, 
						() => {
							this.deleteFolder(item.data);
							this.props.createNotification({
								content: `${intl.formatMessage({ id: 'files.folder title' })}: "${item.data.name}" ${intl.formatMessage({ id: 'general.was deleted label' })}`,
								className: 'info',
								position: 'topCenter',
								icon: 'sn-bin',
								deleteTime: 2000
							});
						});
					}}
				/>

				{typeof this.state[`${item.data._id}input`] === 'undefined' ?
					text
				:
					<React.Fragment>
						<div
							// style={this.state.showCover ? coverStyle : { display: 'none' }}
							style={coverStyle}
							onClick={() => {
								const currentState = {
									...this.state
								};
								currentState[`${item.data._id}input`] = undefined;
								this.setState(currentState);
							}}
						/>
						<span style={{ zIndex: 91 }}>
							{text}
						</span>
						<div style={{ width: '100%', marginTop: 4, marginLeft: -6, marginBottom: 16 }}>
							<div className="ui input mini" style={{ display: 'flex', zIndex: 90, marginRight: 64 }}>
								<input
									key={item.data._id}
									placeholder={intl.formatMessage({ id: 'files.folder rename placeholder' })}
									ref={function(input) {
										if (input != null) {
											input.focus();
										}
									}}
									style={{ flexGrow: 1, zIndex: 99 }}
									value={this.state[`${item.data._id}input}`]}
									//pattern="[a-z0-9]$"
									onChange={(e) => this.getRenameValue(e)}
									onKeyPress={(e)=> {
										if (e.key === 'Enter'){
											if(this.state.regExp === false && name !== ''){
												this.createFolder()
											}
										}
									}}
								/>		
								<ButtonGroup size="mini" style={{ zIndex: 99, marginLeft: 4 }}>
									<Popup
										key="one"
										trigger={
											<Button
												icon
												className="positive"
												style={{ opacity: 1 }}
												disabled={disabled}
												type="submit"
												onClick={() => this.renameFolder(item.data)}
											>
												<Icon name="sn-checkmark" />
											</Button>
										}
										content={intl.formatMessage({ id: 'files.folder rename popup' })}
										position="bottom center"
									/>
									<Popup
										key="two"
										trigger={
											<Button
												icon
												style={{ opacity: 1, color: '#EEE' }}
												onClick={() => {
													const currentState = {
														...this.state
													};
													currentState[`${item.data._id}input`] = undefined;
													this.setState(currentState);
												}}
											>
												<Icon name="sn-cross" />
											</Button>
										}
										content={intl.formatMessage({ id: 'general.cancel' })}
										position="bottom center"
									/>
								</ButtonGroup>
							</div>
							{ this.state.regExp === true &&
								<Message className="error" style={{ marginTop: 4, zIndex: 92 }}>
									<span style={{ fontWeight: 'bold' }}>{this.state.errorLetter}</span> - <FormattedMessage id="files.message not valid folder name characters" />
								</Message>
							}
						</div>
					</React.Fragment>
				}

				{typeof this.state[`${item.data._id}input`] === 'undefined' && !this.props.context.noSettings && 
					<div
						className="segment-option"
						style={this.state.showCover ? { float: 'right', opacity: 1, zIndex: 87 } : { float: 'right' }}
						onClick={() => {
							this.setState(showCover, true);
						}}
					>
						{/* TO DO: NOT WORKING YET */}
						{/* {this.state.showCover &&
							<div
								// style={this.state.showCover ? coverStyle : { display: 'none' }}
								style={coverStyle}
								onClick={() => {
									const currentState = {
										...this.state
									};
									currentState[`${item.data._id}input`] = undefined;
									this.setState(currentState);
								}}
							/>
						} */}
						<Dropdown
							icon={
								<Popup
									trigger={
										<Icon
											link
											name="setting"
											className="primary"
											style={{ marginRight: 0 }}
										/>
									}
									content={intl.formatMessage({ id: 'files.folder settings title' })}
									position="right center"
								/>
							}
						>
							<DropdownMenu style={{ right: 0, left: 'auto', zIndex: 90 }}>
								<DropdownHeader
									icon="setting"
									content={intl.formatMessage({ id: 'files.folder settings title' })}
								/>
								<DropdownDivider />
								<DropdownItem onClick={() => {this.setState({[`${item.data._id}input`]: text });}}>
									<Icon name="sn-quill" style={{ marginRight: 4 }} />
									<FormattedMessage id="files.folder rename title" />
								</DropdownItem>
								<DropdownItem onClick={() => this.setState({showDelete: true})}>
									<Icon
										name="trash alternate outline"
										className="negative"
										style={{ marginRight: 4 }}
									/>
									<FormattedMessage id="files.folder delete title" />
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				}
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(notification(FolderComponentWrapper)));