import React from 'react';
import { Grid, GridColumn } from 'svift-ui';
import NotificationList from './NotificationList';
// import css from './notification.scss';

class NotificationGrid extends React.Component {
	render() {
		const { notifications, onClick, top } = this.props;

		const {
			topLeft,
			topCenter,
			topRight,
			bottomLeft,
			bottomCenter,
			bottomRight
		} = notifications.reduce((acc, notification) => {
			acc[notification.position].push(notification);

			return acc;
		}, {
			topLeft: [],
			topCenter: [],
			topRight: [],
			bottomLeft: [],
			bottomCenter: [],
			bottomRight: []
		});

		return (
			<React.Fragment>
				{/* top */}
				<Grid
					verticalAlign="top"
					style={{
						position: 'fixed',
						width: '100%',
						height: 'auto',
						top: top || 104, // default: height of topnav + appnav + 8
						left: 8,
						right: 8,
						zIndex: 1009,
						pointerEvents: 'none',
						background: 'transparent'
					}}
				>
					{/* top right */}
					<GridColumn computer={4} tablet={5} mobile={16}>
						<NotificationList 
							notifications={topLeft}
							onClick={onClick}
							/>
					</GridColumn>

					{/* top center */}
					<GridColumn computer={8} tablet={5} mobile={16}>
						<NotificationList 
							notifications={topCenter}
							onClick={onClick}
							/>
					</GridColumn>

					{/* top left */}
					<GridColumn computer={4} tablet={5} mobile={16}>
						<NotificationList 
							notifications={topRight}
							onClick={onClick}
						/>
					</GridColumn>
				</Grid>
				
				{/* bottom */}
				<Grid
					verticalAlign="bottom"
					style={{
						position: 'fixed',
						width: '100%',
						height: 'auto',
						bottom: 8,
						left: 8,
						right: 8,
						zIndex: 1009,
						pointerEvents: 'none',
						background: 'transparent'
					}}
				>
					{/* bottom left */}
					<GridColumn computer={4} tablet={5} mobile={16}>
						<NotificationList 
							notifications={bottomLeft}
							onClick={onClick}
							/>
					</GridColumn>

					{/* bottom center */}
					<GridColumn computer={8} tablet={5} mobile={16}>
						<NotificationList 
							notifications={bottomCenter}
							onClick={onClick}
							/>
					</GridColumn>

					{/* bottom right */}
					<GridColumn computer={4} tablet={5} mobile={16}>
						<NotificationList 
							notifications={bottomRight}
							onClick={onClick}
						/>
					</GridColumn>
				</Grid>
			</React.Fragment>
		);
	}
}

export default NotificationGrid;
