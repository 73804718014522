exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "#app-footer__app-footer--3lzmA{font-family:'Ubuntu', sans-serif;font-weight:300;position:relative;text-align:center;padding-top:16px;padding-bottom:40px;border-top:1px solid rgba(79,71,65,0.1)}\n", ""]);

// exports
exports.locals = {
	"app-footer": "app-footer__app-footer--3lzmA"
};