import React, { PureComponent } from 'react';
import {
	Header,
	Icon,
	Divider
} from 'svift-ui';
import Modal from 'svift-library/client/modal';
import { injectIntl, FormattedMessage } from 'react-intl';

class TermsAndConditions extends PureComponent {
	state = {
		openModal: false
	}

	render() {
		const { intl } = this.props;

		// Markup functions
		// #1 - Title
		const createMarkupTitle1='terms.customer and user conditions title';
		function createMarkupDescription1() { return {__html: intl.formatMessage({ id: 'terms.customer and user conditions description' })}; };
		// #2
		const createMarkupTitle2='terms.accept user conditions title';
		function createMarkupDescription2() { return {__html: intl.formatMessage({ id: 'terms.accept user conditions description' })}; };
		// #3
		const createMarkupTitle3='terms.prices and transactions title';
		function createMarkupDescription3() { return {__html: intl.formatMessage({ id: 'terms.prices and transactions description' })}; };
		// #4
		const createMarkupTitle4='terms.orders and payment title';
		function createMarkupDescription4() { return {__html: intl.formatMessage({ id: 'terms.orders and payment description' })}; };
		// #5
		const createMarkupTitle5='terms.who can see payment details title';
		function createMarkupDescription5() { return {__html: intl.formatMessage({ id: 'terms.who can see payment details description' })}; };
		// #6
		const createMarkupTitle6='terms.cancellation title';
		function createMarkupDescription6() { return {__html: intl.formatMessage({ id: 'terms.cancellation description' })}; };
		// #7
		const createMarkupTitle7='terms.subscription terms title';
		function createMarkupDescription7() { return {__html: intl.formatMessage({ id: 'terms.subscription terms description' })}; };
		// #8
		const createMarkupTitle8='terms.payment of subscription title';
		function createMarkupDescription8() { return {__html: intl.formatMessage({ id: 'terms.payment of subscription description' })}; };
		// #9
		const createMarkupTitle9='terms.change subscription title';
		function createMarkupDescription9() { return {__html: intl.formatMessage({ id: 'terms.change subscription description' })}; };
		// #10
		const createMarkupTitle10='terms.cancel subscription title';
		function createMarkupDescription10() { return {__html: intl.formatMessage({ id: 'terms.cancel subscription description' })}; };
		// #11
		const createMarkupTitle11='terms.transfer and resale title';
		function createMarkupDescription11() { return {__html: intl.formatMessage({ id: 'terms.transfer and resale description' })}; };
		// #12
		const createMarkupTitle12='terms.domain names title';
		function createMarkupDescription12() { return {__html: intl.formatMessage({ id: 'terms.domain names description' })}; };
		function createMarkupDescription122() { return {__html: intl.formatMessage({ id: 'terms.domain transfer description' })}; };
		// #13
		const createMarkupTitle13='terms.emails and mailservers title';
		function createMarkupDescription13() { return {__html: intl.formatMessage({ id: 'terms.emails and mailservers description' })}; };
		// #14
		const createMarkupTitle14='terms.when moving from other subscription agreements title';
		function createMarkupDescription14() { return {__html: intl.formatMessage({ id: 'terms.when moving from other subscription agreements description' })}; };
		// #15
		const createMarkupTitle15='terms.everything in the cloud title';
		function createMarkupDescription15() { return {__html: intl.formatMessage({ id: 'terms.everything in the cloud description' })}; };
		// #16
		const createMarkupTitle16='terms.privacy and ownership title';
		function createMarkupDescription16() { return {__html: intl.formatMessage({ id: 'terms.privacy and ownership description' })}; };
		// #17
		const createMarkupTitle17='terms.terms of use title';
		function createMarkupDescription17() { return {__html: intl.formatMessage({ id: 'terms.terms of use description' })}; };
		// #18
		const createMarkupTitle18='terms.user profile title';
		function createMarkupDescription18() { return {__html: intl.formatMessage({ id: 'terms.user profile description' })}; };
		// #19
		const createMarkupTitle19='terms.liabilities title';
		function createMarkupDescription19() { return {__html: intl.formatMessage({ id: 'terms.liabilities description' })}; };
		// #20
		const createMarkupTitle20='terms.sworn statement title';
		function createMarkupDescription20() { return {__html: intl.formatMessage({ id: 'terms.sworn statement description' })}; };
		// #21
		const createMarkupTitle21='terms.accept title';
		function createMarkupDescription21() { return {__html: intl.formatMessage({ id: 'terms.accept description' })}; };
		// #22
		const createMarkupTitle22='terms.you must avoid title';
		function createMarkupDescription22() { return {__html: intl.formatMessage({ id: 'terms.you must avoid description' })}; };
		// #23
		const createMarkupTitle23='terms.support title';
		function createMarkupDescription23() { return {__html: intl.formatMessage({ id: 'terms.support description' })}; };
		// #24
		const createMarkupTitle24='terms.your data title';
		function createMarkupDescription24() { return {__html: intl.formatMessage({ id: 'terms.your data description' })}; };
		// #25
		const createMarkupTitle25='terms.who can see your password title';
		function createMarkupDescription25() { return {__html: intl.formatMessage({ id: 'terms.who can see your password description' })}; };
		// #26
		const createMarkupTitle26='terms.disclaimer title';
		function createMarkupDescription26() { return {__html: intl.formatMessage({ id: 'terms.disclaimer description' })}; };
		// #27
		const createMarkupTitle27='terms.limitation and termination of use title';
		function createMarkupDescription27() { return {__html: intl.formatMessage({ id: 'terms.limitation and termination of use description' })}; };
		// #28
		const createMarkupTitle28='terms.copyright infringement title';
		function createMarkupDescription28() { return {__html: intl.formatMessage({ id: 'terms.copyright infringement description' })}; };
		// #29
		const createMarkupTitle29='terms.liability and damages title';
		function createMarkupDescription29() { return {__html: intl.formatMessage({ id: 'terms.liability and damages description' })}; };
		// #30
		const createMarkupTitle30='terms.concluding remarks title';
		function createMarkupDescription30() { return {__html: intl.formatMessage({ id: 'terms.concluding remarks description' })}; };
		
		const paragraphStyle = {
			lineHeight: 1.6
		}

		const { children, style } = this.props;

		return (
			<React.Fragment>
				{this.props.children && 
					React.cloneElement(this.props.children, { onClick: () => this.setState({ openModal: true }) })
						||
							<a style={{ fontSize: 18 }} onClick={() => this.setState({ openModal: !this.state.openModal })}>
								<Icon name="sn-vcard" style={{ fontSize: 32, marginTop: -6, marginRight: 8, float: 'left', textDecoration: 'none' }} />
								<FormattedMessage id="registration.user agreement link" />
							</a>
				}

				{this.state.openModal && 
					<Modal
						toggleModal={() => this.setState({ openModal: !this.state.openModal })}
						title={intl.formatMessage({ id: 'agreement.user agreement title' })}
						style={style || { maxWidth: 520 }}
						// target={children || <a style={{ fontSize: 18 }}><Icon name="sn-vcard" style={{ fontSize: 32, marginTop: -6, marginRight: 8, float: 'left', textDecoration: 'none' }} /><FormattedMessage id="registration.user agreement link" /></a>}
					>
						<div style={{ padding: '24px 16px 32px 16px' }}>

							{/* #1 - Customer and user conditions */}
							<Header
								as="h1"
								content={intl.formatMessage({ id: createMarkupTitle1 })}
								icon="sn-account_circle"
							/>
							<p dangerouslySetInnerHTML={createMarkupDescription1()} style={paragraphStyle} />
							<Divider />

							{/* #2  - accept of terms */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle2} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription2()} style={paragraphStyle} />
							<Divider />

							{/* #3 - prices and transactions */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle3} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription3()} style={paragraphStyle} />
							<Divider />

							{/* #4 - orders and payments */}
							<Header as="h3">
								<FormattedMessage id={createMarkupTitle4} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription4()} style={paragraphStyle} />
							<Divider />

							{/* #5 - who can see my payment info */}
							<Header as="h4">
								<FormattedMessage id={createMarkupTitle5} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription5()} style={paragraphStyle} />
							<Divider />

							{/* #6 - cancellation of order */}
							<Header as="h3">
								<FormattedMessage id={createMarkupTitle6} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription6()} style={paragraphStyle} />
							<Divider />

							{/* #7 - subscription conditions */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle7} />	
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription7()} style={paragraphStyle} />
							<Divider />

							{/* #8 - payment of subscription */}
							<Header as="h3">
								<FormattedMessage id={createMarkupTitle8} />	
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription8()} style={paragraphStyle} />
							<Divider />

							{/* #9 - change subscription */}
							<Header as="h3">
								<FormattedMessage id={createMarkupTitle9} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription9()} style={paragraphStyle} />
							<Divider />

							{/* #10 - termination of subscription */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle10} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription10()} style={paragraphStyle} />
							<Divider />
							
							{/* #11 - transfer and resell sites */}
							<Header as="h3">
								<FormattedMessage id={createMarkupTitle11} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription11()} style={paragraphStyle} />
							<Divider />

							{/* #12 - domain names */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle12} />	
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription12()} style={paragraphStyle} />
							<p dangerouslySetInnerHTML={createMarkupDescription122()} style={paragraphStyle} />
							<Divider />

							{/* #13 - email addresses */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle13} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription13()} style={paragraphStyle} />
							<Divider />

							{/* #14 - everything in the cloud */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle14} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription14()} style={paragraphStyle} />
							<Divider />

							{/* #15 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle15} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription15()} style={paragraphStyle} />
							<Divider />

							{/* #16 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle16} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription16()} style={paragraphStyle} />
							<Divider />

							{/* #17 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle17} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription17()} style={paragraphStyle} />
							<Divider />

							{/* #18 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle18} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription18()} style={paragraphStyle} />
							<Divider />

							{/* #19 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle19} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription19()} style={paragraphStyle} />
							<Divider />

							{/* #20 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle20} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription20()} style={paragraphStyle} />
							<Divider />

							{/* #21 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle21} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription21()} style={paragraphStyle} />
							<Divider />

							{/* #22 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle22} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription22()} style={paragraphStyle} />
							<Divider />

							{/* #23 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle23} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription23()} style={paragraphStyle} />
							<Divider />

							{/* #24 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle24} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription24()} style={paragraphStyle} />
							<Divider />

							{/* #25 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle25} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription25()} style={paragraphStyle} />
							<Divider />

							{/* #26 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle26} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription26()} style={paragraphStyle} />
							<Divider />

							{/* #27 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle27} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription27()} style={paragraphStyle} />
							<Divider />

							{/* #28 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle28} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription28()} style={paragraphStyle} />
							<Divider />

							{/* #29 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle29} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription29()} style={paragraphStyle} />
							
							{/* #30 */}
							<Header as="h2">
								<FormattedMessage id={createMarkupTitle30} />
							</Header>
							<p dangerouslySetInnerHTML={createMarkupDescription30()} style={paragraphStyle} />
						</div>
					</Modal>
				}
			</React.Fragment>
		);
	}
}

export default injectIntl(TermsAndConditions);
