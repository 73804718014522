import React, { Component } from 'react';
import css from './app-content-section.scss';

class AppContentSection extends Component {
	render() {
		return (
			<div
				className={this.props.className ? `${css['app-content-section']} ${css[this.props.className]}` : css['app-content-section']}
				style={this.props.style}
			>
				{ this.props.children }
			</div>
		);
	}
}

AppContentSection.defaultProps = {
	children: <p>No content in AppContenSection...</p>,
};

export default AppContentSection;
