import React, { memo, useCallback } from "react";
import Accordion, { useAccordion } from 'svift-library/client/accordion/Accordion';
import Crop from '../common/Crop';
import Preset from 'components/editor-v2/editor-interface/options/common/preset/Preset';
import Label from 'components/option-library/label';
import Section from '../Section';
import Text from 'components/option-library/primitives/input/text';
import Checkbox from 'components/option-library/primitives/checkbox';
import Toggle, { ToggleGroup } from 'components/option-library/primitives/toggle';
import { injectIntl } from 'react-intl';
import Dropdown from 'components/option-library/primitives/dropdown/simple';
import Link from '../common/link/Link';

const sizeOptions = intl => [
    {
        text: intl.formatMessage({ id: 'general.size mini' }),
        value: 'mini'
    },
    {
        text: intl.formatMessage({ id: 'general.size tiny' }),
        value: 'tiny'
    },
    {
        text: intl.formatMessage({ id: 'general.size small' }),
        value: 'small'
    },
    {
        text: intl.formatMessage({ id: 'general.size medium' }),
        value: 'medium'
    },
    {
        text: intl.formatMessage({ id: 'general.size large' }),
        value: 'large'
    },
    {
        text: intl.formatMessage({ id: 'general.size big' }),
        value: 'big'
    },
    {
        text: intl.formatMessage({ id: 'general.size huge' }),
        value: 'huge'
    },
    {
        text: intl.formatMessage({ id: 'general.size massive' }),
        value: 'massive'
    },
];

export default memo(injectIntl(props => {
    const { editableNode: node, updateState, intl } = props;
    const [state, dispatch] = useAccordion();

    const onChange = useCallback((key, value, action, autoHistory) => {
        updateState(context => {
            return {
                ...context,
                nodeMap: {
                    ...context.nodeMap,
                    [node.id]: {
                        ...context.nodeMap[node.id],
                        props: {
                            ...context.nodeMap[node.id].props,
                            [key]: value
                        }
                    }
                }
            };
        }, action, autoHistory);
    }, []);

    return (
        <React.Fragment>
            <Section>
                <Preset
                    multipleStates
                    presets={props.theme.presets.button}
                    onChange={onChange}
                    presetKey={node.props.preset || 'Primary'}
                    theme={props.theme} 
                    inlineStyling={node.props.style}
                />

                <Label
                    label={intl.formatMessage({ id: 'general.size label' })}
                >
                    <Dropdown
                        wings
                        items={sizeOptions(intl)}
                        value={node.props.size}
                        onChange={value => onChange('size', value, 'button size')}
                    />
                </Label>

                <Label
                    label={intl.formatMessage({ id: 'options.button input title' })}
                    labelIcon="sn-typography"
                >
                    <Text
                        fluid
                        placeholder={intl.formatMessage({ id: 'options.button input placeholder' })}
                        value={node.props.text}
                        disableUndoRedo
                        onChange={(nextValue, e) => {
                            e.preventDefault();

                            onChange('text', nextValue, 'button text');
                        }}
                    />
                </Label>
                
                <Label>
                    <Checkbox
                        icon="sn-width"
                        label={intl.formatMessage({ id: 'options.button full width' })}
                        value={node.props.fluid}
                        onChange={val => onChange('fluid', val, 'toggle button full width', false)}
                    />
                </Label>

                {!node.props.fluid &&
                    <Label
                        label={intl.formatMessage({ id: 'options.button alignment' })}
                        labelIcon="sn-arrow-resize3"
                    >
                        <ToggleGroup fluid size="mini" key="toggleGroupTest">	
                            <Toggle
                                icon="sn-align-left"
                                tooltipLabel={intl.formatMessage({ id: 'options.alignment left' })}
                                tooltipPosition="top center"
                                value={['center', 'flex-end'].indexOf(node.props.alignment) === -1}
                                onChange={() => onChange('alignment', 'flex-start', 'button alignment', false)}
                            />
                            <Toggle
                                icon="sn-align-center-horizontal"
                                tooltipLabel={intl.formatMessage({ id: 'options.alignment center' })}
                                tooltipPosition="top center"
                                value={node.props.alignment === 'center'}
                                onChange={() => onChange('alignment', 'center', 'button alignment', false)}
                            />
                            <Toggle
                                icon="sn-align-right"
                                tooltipLabel={intl.formatMessage({ id: 'options.alignment right' })}
                                tooltipPosition="top center"
                                value={node.props.alignment === 'flex-end'}
                                onChange={() => onChange('alignment', 'flex-end', 'button alignment', false)}
                            />
                        </ToggleGroup>
                    </Label>
                }
            </Section>

            <Accordion state={state} dispatch={dispatch}>
                <Link 
                    onChange={useCallback((...args) => onChange('link', ...args), [onChange])}
                    value={node.props.link}
                />
                {/* <Crop 
                    onChange={useCallback((...args) => onChange('crop', ...args), [onChange])}
                    value={node.props.crop}
                /> */}
            </Accordion>
        </React.Fragment>
    );
}));