import React, { Component } from 'react';
import { Message, Icon } from 'svift-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import Header from 'containers/dashboard/side-navigation/sidenavigation-content/__components/Header';
import PortalHook from 'components/editor-v2/editor-interface/PortalHook'

class SidenavigationSettings extends Component {
	render() {
		return (
			<React.Fragment>
				<PortalHook id="editor-sidenav-portal" />

				<PortalHook id="building-blocks-sidenav-portal" />
				
				<div>
					{/* <div id="sidenav-header-section">
						<Header
							icon="sn-cog3"
							header={
								<span className="active-sidebar-header">
									<FormattedMessage id="settings.app title" />
								</span>
							}
						/>
					</div> */}

					{/* Need help? */}
					<Message
						size="mini"
						className="info"
						style={{ marginTop: 16, padding: 16 }}
					>
						{/* <Icon		
							name="sn-question3"
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								marginBottom: 12,
								display: 'block',
								fontSize: 24
							}}
						/> */}
						<span style={{ fontSize: 18, display: 'block', marginBottom: 12 }}><FormattedMessage id="support.need help message header" /></span>
						<span style={{ fontSize: 12, lineHeight: 1.2 }}>
							<FormattedMessage id="support.need help message description" /> <Icon name="sn-question3" style={{ margin: 0 }} />
						</span>
					</Message>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(SidenavigationSettings);