import { createSelector } from 'reselect';
import resolveCityName from 'utils/i18n/resolveCityName';
import resolveFlagIconName from 'utils/i18n/resolveFlagIconName';

const selectTimezones = state => state.system.timezones;

const findCountryCode = timezone => {
	const cityName = timezone.split('/')[1].replace(/_/g, ' ');
    const lowercaseCity = cityName.toLowerCase();

    switch (lowercaseCity) {
        case 'copenhagen': {
            return 'da';
        }
        case 'london': {
            return 'en';
        }
        case 'berlin': {
            return 'de';
        }
        case 'reykjavik': {
            return 'is';
        }
        case 'oslo': {
            return 'no';
        }
        case 'faroe': {
            return 'fo';
        }
        case 'godthab': {
            return 'gl';
        }
        case 'stockholm': {
            return 'se';
        }
        case 'helsinki': {
            return 'fi';
        }
        case 'amsterdam': {
            return 'nl';
        }
        case 'paris': {
            return 'fr';
        }
        case 'madrid': {
            return 'es';
        }
        case 'lisbon': {
            return 'pt';
        }
        case 'rome': {
            return 'it';
        }
        case 'new york': {
            return 'us';
        }
        case 'los angeles': {
            return 'us';
        }
        case 'auckland': {
            return 'nz';
        }
        case 'sydney': {
            return 'au';
        }
        case 'tokyo': {
            return 'jp';
        }
        case 'bangkok': {
            return 'th';
        }
        
        // no match fallback
        default: {
            return null;
        }
    }
};

const selector = createSelector(
	selectTimezones,
	timezones => timezones.map(timezone => ({
		value: timezone,
		flag: resolveFlagIconName(findCountryCode(timezone)),
		text: resolveCityName(timezone.split('/')[1].replace(/_/g, ' '))
	}))
);

export default selector;